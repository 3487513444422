import {Card, Flex, Text} from '@sanity/ui'
import React from 'react'
import styled from 'styled-components'
import {split} from '@/utils/index'
import {Project} from '@/types/models'

const MAX_PROJECTS = 2

// TODO: use projects instead of members

type Props = {
  projects: Project[] | string[]
}

const Image = styled.img`
  height: 100%;
  object-fit: cover;
`

export function ProjectPreviewList({projects}: Props) {
  const [hidden, visible] = split(projects, MAX_PROJECTS)
  return (
    <Flex>
      {visible.map((project) => (
        <Card
          key={project.user.id}
          style={{height: 30, width: 30}}
          radius={2}
          shadow={1}
          marginRight={2}
          tone="transparent"
        >
          <Image src={project.user.imageUrl} />
        </Card>
      ))}

      {hidden.length > 0 && (
        <Card style={{height: 30, width: 30}} radius={2} shadow={1}>
          <Flex align="center" justify="center" style={{height: '100%'}}>
            <Text size={1} muted>
              {hidden.length}
            </Text>
          </Flex>
        </Card>
      )}
    </Flex>
  )
}
