import {type ResourceType} from '@sanity/access-api'
import {Stack, type StackProps, Text} from '@sanity/ui'
import {useRouter} from 'next/router'
import React, {useCallback, useEffect, useMemo, useState} from 'react'

import {type MembersV2} from '@/types/members_v2'

import {MemberInspect} from '../../features/member-inspect'
import {MembersTablesView} from '../../features/member-tables-view'
import {MembersInvitationFormDialog} from '../../features/members-invitation-form'
import {type InvitationCreateValue} from '../../features/members-invitation-form/types'
import {BulletList} from '../../generic/bullet-list'
import {Dialog} from '../../primitives/dialog'
import {type ResourceMembershipRemovePayload} from '../../types'
import {sortList} from '../../utils'
import {getRequestApprovalConcerns, type RequestToApprove} from '../../utils/requestApprovalUtils'
import {type InvitationScope, MembersSettingsScreenHeader} from './MembersSettingsScreenHeader'
import {type MembersSettingsScreenProps} from './types'

const ROOT_STACK_SPACE: StackProps['space'] = 4

export function MembersSettingsScreen(props: MembersSettingsScreenProps) {
  const {
    basePath,
    description,
    disabledFeatures,
    error,
    inspectedMemberId: memberIdToInspect,
    invitations: invitationsProp,
    inviteDialogOpen,
    isOrganizationPlan,
    loading,
    members: membersProp,
    onInspectMember,
    onInvitationRevoke,
    onInviteDialogClose,
    onInviteMembers,
    onMemberRemove,
    onRequestApprove,
    onRequestRevoke,
    onResourceMembershipAdd,
    onResourceMembershipRemove,
    onResourceRoleAdd,
    onResourceRoleRemove,
    onRetryFetch,
    planPricingModel,
    planType,
    projects: projectsProp,
    requests,
    resourceId,
    resourcesQuota,
    resourceType,
    roles: rolesProp,
    selectedView,
    subscriptionFeatures,
    title,
    referenceLinkComponent,
  } = props

  const [memberIdToDelete, setMemberIdToDelete] = useState<string | null>(null)

  const [invitationIdToRevoke, setInvitationIdToRevoke] = useState<string | null>(null)

  const [invitationScope, setInvitationScope] = useState<InvitationScope | null>(
    inviteDialogOpen ? resourceType : null
  )

  const router = useRouter()

  // If query param is present, open the invite dialog
  useEffect(() => {
    if (router.query.invite) {
      setInvitationScope(resourceType)
      // Remove the query param after opening to prevent reopening on page refresh
      const {invite: _, ...otherParams} = router.query
      router.replace({
        query: otherParams,
      })
    }
  }, [router, resourceType])

  const [requestToApprove, setRequestToApprove] = useState<RequestToApprove | null>(null)

  // Sort roles alphabetically
  const roles = useMemo(
    () =>
      sortList(
        rolesProp.filter((role) => role.appliesToUsers),
        ['title'],
        {fallbackPath: ['name']}
      ),
    [rolesProp]
  )

  // Sort projects alphabetically
  const projects = useMemo(() => sortList(projectsProp, ['displayName']), [projectsProp])

  // Sort members alphabetically
  const members = useMemo(() => sortList(membersProp, ['profile', 'displayName']), [membersProp])

  // This list contains only the members that are part of the current resource.
  // - If the resource is an organization, all members are included.
  // - If the resource is not an organization, only the members that are part of the resource are included.
  const resourcesMembers = useMemo(() => {
    if (resourceType === 'organization') return members

    return members.filter((member) => {
      return member.memberships.some(
        (membership) =>
          membership.resourceId === resourceId && membership.resourceType === resourceType
      )
    })
  }, [members, resourceId, resourceType])

  // Sort invitations by date in descending order
  const invitations = useMemo(
    () => sortList(invitationsProp, ['createdAt'], {order: 'desc'}),
    [invitationsProp]
  )

  // Group projects with their respective role schemas
  const projectsData = useMemo((): MembersV2['projectData'][] => {
    return projects.map((project) => {
      return {
        projectProfile: project,
        roleSchemas: roles.filter((role) => role.resourceId === project.id),
      }
    })
  }, [projects, roles])

  const organizationRoles = useMemo(() => {
    if (resourceType !== 'organization') return []

    return roles.filter(
      (role) => role.resourceId === resourceId && role.resourceType === 'organization'
    )
  }, [resourceId, resourceType, roles])

  const handleInvitationConfirm = useCallback(
    (invites: InvitationCreateValue[]) => {
      onInviteMembers(invites)
      setInvitationScope(null)
      onInviteDialogClose?.()
    },
    [onInviteMembers, onInviteDialogClose]
  )

  const handleInvitationCancel = useCallback(() => {
    setInvitationScope(null)
    onInviteDialogClose?.()
  }, [onInviteDialogClose])

  const handleSetMemberToRemove = useCallback((payload: ResourceMembershipRemovePayload) => {
    setMemberIdToDelete(payload.memberId)
  }, [])

  const handleRemoveMemberConfirm = useCallback(() => {
    if (!memberIdToDelete) return

    onMemberRemove({
      memberId: memberIdToDelete,
      resourceId,
      resourceType,
    })

    setMemberIdToDelete(null)
  }, [memberIdToDelete, onMemberRemove, resourceId, resourceType])

  const handleCloseDeleteDialog = useCallback(() => {
    setMemberIdToDelete(null)
  }, [])

  const handleInspectMember = useCallback(
    (memberId: string | null) => onInspectMember?.(memberId),
    [onInspectMember]
  )

  const handleCloseInspectDialog = useCallback(() => onInspectMember?.(null), [onInspectMember])

  const handleCloseRevokeInvitationDialog = useCallback(() => setInvitationIdToRevoke(null), [])

  // Find the invitation to be revoked from the invitations data based on the invitation ID
  const invitationToBeRevoked = useMemo(() => {
    if (!invitationIdToRevoke) return null

    return invitations?.find((d) => d.id === invitationIdToRevoke)
  }, [invitationIdToRevoke, invitations])

  const handleRevokeInvitationConfirm = useCallback(() => {
    if (!invitationToBeRevoked) return

    const inviteId = invitationToBeRevoked?.id
    const resourceId = invitationToBeRevoked?.resourceId
    const resourceType = invitationToBeRevoked?.resourceType

    if (!inviteId || !resourceId || !resourceType) return

    onInvitationRevoke(inviteId, resourceId, resourceType)
  }, [invitationToBeRevoked, onInvitationRevoke])

  // Find the member to be deleted from the members data based on the member ID
  const memberToBeDeleted = useMemo(() => {
    if (!memberIdToDelete) return null

    return resourcesMembers.find((d) => d.profile?.id === memberIdToDelete)
  }, [memberIdToDelete, resourcesMembers])

  // Find the member to be inspected from the members data based on the member ID
  const inspectedMember = useMemo(() => {
    if (!memberIdToInspect) return null

    return resourcesMembers.find((d) => d.profile?.id === memberIdToInspect)
  }, [memberIdToInspect, resourcesMembers])

  const invitationToBeRevokedProject = useMemo(() => {
    if (!invitationToBeRevoked) return null
    if (invitationToBeRevoked.resourceType !== 'project') return null

    return projectsData.find((d) => d.projectProfile.id === invitationToBeRevoked.resourceId)
  }, [invitationToBeRevoked, projectsData])

  const handleRequestApprove = useCallback(
    (
      requestId: string,
      _resourceId: string,
      _resourceType: ResourceType,
      requestedRole: string
    ) => {
      const request = requests.find((d) => d.id === requestId)

      if (!request) return

      // TODO: Ideally the callback would be async so we can await the result, but
      // we won't update the API for now.
      getRequestApprovalConcerns(
        {
          ...request,
          requestedRole,
        },
        resourcesMembers
      ).then((result) => {
        if (result) {
          setRequestToApprove(result)
        } else {
          onRequestApprove(
            request.id || '',
            request.resourceId || '',
            request.resourceType as ResourceType,
            requestedRole
          )
        }
      })
    },
    [resourcesMembers, onRequestApprove, requests]
  )

  const handleConfirmApproval = useCallback(() => {
    if (requestToApprove) {
      onRequestApprove(
        requestToApprove.request.id || '',
        requestToApprove.request.resourceId || '',
        requestToApprove.request.resourceType as ResourceType,
        requestToApprove.request.requestedRole || ''
      )
      setRequestToApprove(null)
    }
  }, [requestToApprove, onRequestApprove])

  const handleCancelApproval = useCallback(() => {
    setRequestToApprove(null)
  }, [])

  const hasAnySamlFeature =
    resourceType === 'project' && subscriptionFeatures?.some((feature) => feature.includes('saml'))

  return (
    <>
      <Stack flex={1} sizing="border" space={ROOT_STACK_SPACE} height="fill">
        {resourcesQuota?.members && (
          <MembersSettingsScreenHeader
            basePath={basePath}
            description={description}
            disabledFeatures={disabledFeatures}
            isOrganizationPlan={isOrganizationPlan}
            loading={loading}
            onInviteMembers={setInvitationScope}
            resourcesQuota={resourcesQuota}
            resourceType={resourceType}
            title={title}
            referenceLinkComponent={referenceLinkComponent}
          />
        )}

        <MembersTablesView
          basePath={basePath}
          disabledFeatures={disabledFeatures}
          error={error}
          invitations={invitations}
          loading={loading}
          // Pass only the `resourcesMembers` instead of `members` to only show members
          // that are part of the current resource.
          members={resourcesMembers}
          onInspectMember={handleInspectMember}
          onInvitationRevoke={setInvitationIdToRevoke}
          onMemberRemove={handleSetMemberToRemove}
          onRequestApprove={handleRequestApprove}
          onRequestRevoke={onRequestRevoke}
          onResourceRoleAdd={onResourceRoleAdd}
          onResourceRoleRemove={onResourceRoleRemove}
          onRetryFetch={onRetryFetch}
          projectsData={projectsData}
          requests={requests}
          resourceId={resourceId}
          resourceType={resourceType}
          roles={roles}
          selectedView={selectedView}
        />
      </Stack>

      {/* Remove member dialog */}
      {memberToBeDeleted && memberToBeDeleted?.profile && (
        <Dialog
          cancelButton={{
            onClick: handleCloseDeleteDialog,
          }}
          confirmButton={{
            text: 'Confirm',
            onClick: handleRemoveMemberConfirm,
            tone: 'critical',
          }}
          header={`Are you sure you want to remove ${memberToBeDeleted.profile.displayName}?`}
          id="member-delete-dialog"
          onClickOutside={handleCloseDeleteDialog}
          onClose={handleCloseDeleteDialog}
          width={1}
        >
          {resourceType === 'organization' && (
            <Text size={1}>
              This action will remove <b>{memberToBeDeleted.profile.displayName}</b> from the
              organization and all projects.
            </Text>
          )}

          {resourceType === 'project' && (
            <Text size={1}>
              This action will remove <b>{memberToBeDeleted.profile.displayName}</b> from the
              project.
            </Text>
          )}
        </Dialog>
      )}

      {/* Inspect member dialog */}
      {inspectedMember?.profile && resourceType === 'organization' && (
        <Dialog
          header={`Inspect ${inspectedMember.profile.displayName}'s roles`}
          id="member-inspect-dialog"
          onClickOutside={handleCloseInspectDialog}
          onClose={handleCloseInspectDialog}
          width={1}
        >
          <MemberInspect
            basePath={basePath}
            canEdit={!disabledFeatures?.editMembers.isDisabled}
            member={inspectedMember}
            onResourceMembershipAdd={onResourceMembershipAdd}
            onResourceMembershipRemove={onResourceMembershipRemove}
            onResourceRoleAdd={onResourceRoleAdd}
            onResourceRoleRemove={onResourceRoleRemove}
            organizationRoles={organizationRoles}
            projectsData={projectsData}
            resourceId={resourceId}
          />
        </Dialog>
      )}

      {/* Revoke invitation dialog */}
      {invitationToBeRevoked && (
        <Dialog
          confirmButton={{
            text: 'Confirm',
            onClick: handleRevokeInvitationConfirm,
            tone: 'critical',
          }}
          cancelButton={{
            onClick: handleCloseRevokeInvitationDialog,
          }}
          header={`Are you sure you want to revoke the invitation to ${invitationToBeRevoked.email}?`}
          id="invitation-revoke-dialog"
          onClickOutside={handleCloseRevokeInvitationDialog}
          onClose={handleCloseRevokeInvitationDialog}
          width={1}
        >
          <Text size={1}>
            This action will remove access for <b>{invitationToBeRevoked.email}</b> to join the{' '}
            {invitationToBeRevokedProject ? (
              <>
                <b>{invitationToBeRevokedProject?.projectProfile.displayName}</b> project
              </>
            ) : (
              'organization'
            )}
            .
          </Text>
        </Dialog>
      )}

      {/* Invite members dialog */}
      {invitationScope && resourceType === 'organization' && (
        <MembersInvitationFormDialog
          basePath={basePath}
          members={members}
          multiProjectInvite
          onClose={handleInvitationCancel}
          onConfirm={handleInvitationConfirm}
          organizationId={resourceId}
          organizationRoles={organizationRoles}
          projectsData={projectsData}
          scope={invitationScope}
        />
      )}

      {invitationScope && resourceType === 'project' && (
        <MembersInvitationFormDialog
          basePath={basePath}
          members={members}
          onClose={handleInvitationCancel}
          onConfirm={handleInvitationConfirm}
          projectsData={projectsData}
          scope={invitationScope}
          projectId={resourceId}
          showSamlBanner={!hasAnySamlFeature}
          isGrowthPlan={planType === 'growth'}
          planPricingModel={planPricingModel}
          resourcesQuota={resourcesQuota}
        />
      )}

      {/* Request approval warning dialog */}
      {requestToApprove && (
        <Dialog
          cancelButton={{
            onClick: handleCancelApproval,
          }}
          confirmButton={{
            text: 'Approve anyway',
            onClick: handleConfirmApproval,
            tone: 'critical',
          }}
          header={`Approve request from ${requestToApprove.request.requestedByUserProfile?.displayName} (${requestToApprove.request.requestedByUserProfile?.email})`}
          id="request-approval-warning-dialog"
          onClickOutside={handleCancelApproval}
          onClose={handleCancelApproval}
          width={1}
        >
          <Stack space={4}>
            <Text size={1}>There are potential security concerns with approving this request:</Text>
            <BulletList
              items={requestToApprove.warnings
                .map((warning) => {
                  switch (warning.type) {
                    case 'personal-email':
                      return (
                        <>
                          This user is using a personal email (<strong>{warning.value}</strong>).
                          Make sure they're not impersonating a team member before accepting their
                          request.
                        </>
                      )
                    case 'unknown-email-domain':
                      return (
                        <>
                          This user's email domain (<strong>{warning.value}</strong>) does not match
                          that of any other members of this {resourceType}.
                        </>
                      )
                    case 'unknown-login-provider':
                      return (
                        <>
                          This user is using a different authentication provider (
                          <strong>{warning.value}</strong>) than other members of this{' '}
                          {resourceType}.
                        </>
                      )
                    default:
                      return null
                  }
                })
                .filter(Boolean)}
              renderItem={(item) => <Text size={1}>{item}</Text>}
              space={3}
            />
            <Text size={1}>Are you sure you want to approve this request?</Text>
          </Stack>
        </Dialog>
      )}
    </>
  )
}
