import {Button, Card, Flex, Stack, Text} from '@sanity/ui'
import {useSwitchPlanStore} from '@/components/checkout/store'
import styled from 'styled-components'
import Link from 'next/link'
import {Overage} from '../hooks/overages'
import {getProjectPath} from '@/utils/getProjectPath'
import {useCurrentScopeContext} from '@/context/index'
import {ErrorOverlay} from './errorOverlay'

interface Props {
  /**
   * An array of overages that prevent the user from upgrading their plan.
   * This component only appears if there are blocking overages in this list.
   * The overages you include depend on whether the user's plan allows for overages:
   * - If overages are allowed: Include only the overages currently preventing an upgrade.
   * - If overages are not allowed: Include all existing overages.
   */
  blockingOverages: Overage[]
}

const List = styled.ul`
  padding-left: 1.2rem;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`

const ListItem = styled.li`
  font-size: ${({theme}) => `${theme.sanity.fonts.text.sizes[1].fontSize}px`};
  color: var(--card-muted-fg-color);
  line-height: 1.5;
`

const WrappedText = styled(Text)`
  text-wrap: pretty;
`

export function OverageAlert({blockingOverages}: Props) {
  const scope = useCurrentScopeContext()
  const {state, dispatch} = useSwitchPlanStore()

  if (!blockingOverages.length) return null

  return (
    <ErrorOverlay>
      <Stack space={4}>
        <Text size={1} weight="semibold">
          {state?.plan?.name} plan not available
        </Text>

        <List>
          {blockingOverages.map(({id, description, link}) => (
            <ListItem key={id} as="li">
              {description}{' '}
              {link && (
                <Link
                  href={`${getProjectPath(scope!.project!)}/${link[0]}`}
                  onClick={() => dispatch({type: 'switchPlan/close'})}
                >
                  {link[1]}
                </Link>
              )}
            </ListItem>
          ))}
        </List>

        <Card padding={2} paddingLeft={3} radius={2}>
          <Flex justify="space-between" align="center" gap={4}>
            <WrappedText size={1} weight="medium">
              Talk to our sales team about custom quotas and Enterprise features
            </WrappedText>
            <Button
              as={Link}
              text="Contact sales"
              href="https://www.sanity.io/contact/sales?ref=overage-alert"
              target="_blank"
            />
          </Flex>
        </Card>
      </Stack>
    </ErrorOverlay>
  )
}
