import {useQuery} from '@tanstack/react-query'
import {z} from 'zod'

const githubUrlSchema = z
  .string()
  .url()
  .regex(/github\.com/)

interface RepoStats {
  stars: number
  watchers: number
  forks: number
  lastUpdated: Date
}

async function fetchRepoStats(repoUrl: string): Promise<RepoStats> {
  // Validate URL
  githubUrlSchema.parse(repoUrl)

  // Extract owner/repo from URL
  const [owner, repo] = repoUrl.split('github.com/')[1].split('/')

  const response = await fetch(`https://api.github.com/repos/${owner}/${repo}`)
  const data = await response.json()

  return {
    watchers: data.subscribers_count,
    stars: data.stargazers_count,
    forks: data.forks_count,
    lastUpdated: new Date(data.updated_at),
  }
}

export function useGitHubRepoStats(repoUrl: string) {
  return useQuery({
    queryKey: ['repo-stats', repoUrl],
    queryFn: () => fetchRepoStats(repoUrl),
    enabled: Boolean(repoUrl),
    staleTime: 1000 * 60 * 30, // Consider data stale after 30 minutes
    retry: 2,
  })
}
