import {Box, Card, Flex, Text, Inline, Stack} from '@sanity/ui'
import {DatabaseIcon} from '@sanity/icons'
import React from 'react'
import Link from 'next/link'
import {useRoutePath} from '@/context/index'
import {Tag} from '@/types/index'
import {TagIcon} from '@/ui/index'

type Props = {
  tag: Tag
}
export function TagItem(props: Props) {
  const {basePath} = useRoutePath()

  const {tag} = props
  const datasets = tag.datasets || []

  return (
    <Link href={`${basePath}/datasets/tags?name=${tag.name}`}>
      <Card padding={4} border radius={2}>
        <Flex align="center" justify="space-between">
          <Flex flex={1}>
            <Stack space={3}>
              <Flex>
                <Inline space={1}>
                  <Text size={1}>
                    <Card
                      tone={tag.metadata.tone || 'default'}
                      paddingY={1}
                      paddingX={2}
                      radius={2}
                    >
                      <TagIcon />
                    </Card>
                  </Text>
                  <Text weight="semibold">{tag.title}</Text>
                </Inline>
              </Flex>
              <Text size={1} muted>
                {tag.description || 'No description'}
              </Text>
            </Stack>
          </Flex>
          <Inline flex={1} paddingY={1} space={2}>
            {datasets.map((dataset) => {
              return (
                <Card key={dataset.name} tone={'transparent'} padding={2} radius={2} marginLeft={2}>
                  <Flex>
                    <Box marginRight={2}>
                      <Text size={1}>
                        <DatabaseIcon />
                      </Text>
                    </Box>
                    <Text size={1}>{dataset.name}</Text>
                  </Flex>
                </Card>
              )
            })}
          </Inline>
        </Flex>
      </Card>
    </Link>
  )
}
