/* eslint-disable react/jsx-no-bind, react/jsx-handler-names */
import React, {useEffect, useCallback, useState} from 'react'
import {
  TextInput,
  Button,
  Dialog,
  Grid,
  Stack,
  TextArea,
  Card,
  CardTone,
  Inline,
  Radio,
  useToast,
} from '@sanity/ui'
import {Controller, useForm} from 'react-hook-form'
import {useRouter} from 'next/router'
import {tones} from './common'
import {Prompt, FormField} from '@/ui/index'
import {slugify} from '@/utils/index'
import {Project, Tag} from '@/types/index'
import {useCreateTag} from '@/data/index'
import {useRoutePath} from '@/context/index'

const tagNamePattern = /^[a-z0-9][-\w]{1,20}$/
const IDENTIFIER_MAXLENGTH = 20
interface Props {
  project: Project
  tag?: Tag
  mode?: 'edit' | 'create'
  closeDialog: () => void
}

type FormValues = {
  title: string
  name: string
  description: string
  tone: CardTone
}

export function UpdateTagDialog(props: Props) {
  const {project, closeDialog, tag, mode = 'edit'} = props
  const router = useRouter()
  const {basePath} = useRoutePath()
  const toast = useToast()
  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: {errors},
  } = useForm<FormValues>({
    defaultValues: {
      title: tag?.title || '',
      name: tag?.name || '',
      description: tag?.description || '',
      tone: tag?.metadata?.tone || 'default',
    },
  })
  const {isLoading, error: createError, createTag, editTag} = useCreateTag(project.id)
  const [nameIsModifiedByUser, setNameIsModifiedByUser] = useState(false)

  useEffect(() => {
    return () => reset()
  }, [reset])

  const handleSuccess = useCallback(
    (data: Tag, options?: {tagName?: string}) => {
      toast.push({
        title: mode === 'create' ? 'Tag created' : 'Tag updated',
        status: 'success',
      })

      closeDialog()

      // Redirect to the tag details page
      if (mode === 'create') {
        router.push(`${basePath}/datasets/tags?name=${data.name}`, undefined, {
          shallow: true,
        })
      }

      // If the tag name has changed, replace the URL param with the new tag name
      if (mode === 'edit' && options?.tagName !== data.name) {
        router.replace(`${basePath}/datasets/tags?name=${data.name}`, undefined, {
          shallow: true,
        })
      }
    },
    [toast, closeDialog, basePath, router, mode]
  )

  const handNameIsModified = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value && event.target.value.length > 0) {
        setNameIsModifiedByUser(true)
      } else {
        setNameIsModifiedByUser(false)
      }
    },
    [setNameIsModifiedByUser]
  )

  const handleTitleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (nameIsModifiedByUser) {
        return
      }
      setValue('name', slugify(event.target.value, IDENTIFIER_MAXLENGTH).trimEnd(), {
        shouldValidate: true,
      })
    },
    [nameIsModifiedByUser, setValue]
  )

  const onSubmit = useCallback(
    (data: FormValues) => {
      // The default value for tone is not persisted through the controller
      data.tone = data.tone || tag?.metadata?.tone
      if (mode === 'create') {
        createTag(data, {
          onError(error) {
            console.error(error)
          },
          onSuccess(data) {
            handleSuccess(data)
          },
        })
      } else if (tag) {
        editTag(
          {...data, tagName: tag.name},
          {
            onError(error) {
              console.error(error)
            },
            onSuccess(data, {tagName}) {
              handleSuccess(data, {tagName})
            },
          }
        )
      }
    },
    [tag, mode, createTag, handleSuccess, editTag]
  )

  const footer = (
    <Grid columns={2} gap={2} paddingX={4} paddingY={3}>
      <Button mode="bleed" onClick={closeDialog} text="Cancel" />
      <Button
        onClick={handleSubmit(onSubmit)}
        text={mode === 'create' ? 'Create new dataset tag' : 'Update dataset tag'}
        tone="primary"
        disabled={isLoading}
      />
    </Grid>
  )
  return (
    <Dialog
      header={mode === 'create' ? 'New dataset tag' : 'Edit dataset tag'}
      footer={footer}
      id="create-tag"
      onClose={closeDialog}
      zOffset={1000}
      width={1}
    >
      <Stack as="form" onSubmit={handleSubmit(onSubmit)} paddingX={4} paddingY={5} space={5}>
        {createError && (
          <Prompt tone="critical" icon="info-outline" description={createError.message} />
        )}

        <FormField
          label="Title"
          description="Give the tag a descriptive title"
          error={errors?.title?.message}
        >
          <TextInput
            padding={3}
            {...register('title', {required: 'A title is required'})}
            onChange={handleTitleChange}
          />
        </FormField>

        <FormField
          label="Identifier"
          description="Unique identifier for the tag"
          error={errors?.name?.message}
        >
          <TextInput
            padding={3}
            readOnly={mode === 'edit'}
            {...register('name', {
              required: 'An identifier is required',
              maxLength: {
                value: IDENTIFIER_MAXLENGTH,
                message: 'The identifier is limited to 20 characters',
              },
              pattern: {
                value: tagNamePattern,
                message: 'Invalid identifier',
              },
            })}
            onChange={handNameIsModified}
          />
        </FormField>

        <FormField
          label="Description"
          description="Describe the tag for the rest of your team"
          error={errors?.description?.message}
        >
          <TextArea radius={2} {...register('description')} />
        </FormField>

        <FormField
          label="Color"
          description="Select a color to identify this tag"
          error={errors?.tone?.message}
        >
          <Controller
            control={control}
            name="tone"
            render={({field}) => (
              <Inline space={2}>
                {tones.map(({tone, title}) => (
                  <Card key={tone} tone={tone} padding={2} border radius={2}>
                    <Radio
                      {...register('tone')}
                      value={tone}
                      checked={tone === field.value}
                      onChange={field.onChange}
                      id={title}
                      aria-label={title}
                    />
                  </Card>
                ))}
              </Inline>
            )}
          />
        </FormField>
      </Stack>
    </Dialog>
  )
}
