import {Box} from '@sanity/ui'
import {forwardRef, type ReactNode} from 'react'
import {css, styled} from 'styled-components'

const Root = styled(Box)<{$sticky?: boolean}>(({$sticky}) => {
  return css`
    position: ${$sticky ? 'sticky' : undefined};
    z-index: ${$sticky ? 1 : undefined};
    top: ${$sticky ? 0 : undefined};
    text-align: left;
  `
})

export interface TableHeaderProps
  extends Omit<React.HTMLProps<HTMLTableSectionElement>, 'as' | 'height' | 'ref'> {
  children: ReactNode
  sticky?: boolean
}

export const TableHeader = forwardRef(function TableHeader(
  props: TableHeaderProps,
  ref: React.Ref<HTMLTableSectionElement>
) {
  const {children, sticky, ...rest} = props

  return (
    <Root as="thead" $sticky={sticky} ref={ref} {...rest}>
      {children}
    </Root>
  )
})
