/* eslint-disable max-nested-callbacks */
import React, {useCallback, useState, useMemo} from 'react'
import {Badge, BadgeTone, Box, Button, Card, Flex, Inline, Stack, Text, Tooltip} from '@sanity/ui'
import {DatabaseIcon, ToggleArrowRightIcon, UsersIcon} from '@sanity/icons'
import Link from 'next/link'
import {ContentResource} from './contentResource'
import {Grant} from './contentResource/grant'
import {
  contentResourceCompare,
  getDocumentFilterAsModeOverview,
  isUserRoleResource,
} from './contentResource/util'
import {TableHeader} from './contentResource/tableUtil'
import {
  Dataset,
  Grant as TGrant,
  PermissionResource,
  Project,
  ProjectMemberRole,
  RoleResource,
  Tag,
} from '@/types/models'
import {useRoutePath} from '@/context/index'

function aclModeToTone(mode: string | undefined): BadgeTone {
  switch (mode) {
    case 'public':
      return 'positive'
    case 'custom':
      return 'critical'
    case 'private':
      return 'caution'
    default:
      return 'default'
  }
}

const modeWeight: Record<string, number> = {
  read: 1,
  update: 2,
  create: 3,
  publish: 4,
  unknown: 0,
}
function mostPermissiveModeGrant(acc: string | undefined, grantB: TGrant) {
  return modeWeight[acc || 'unknown'] > modeWeight[grantB.params.mode || 'unknown']
    ? acc
    : grantB.params.mode
}

type Props = {
  dataset: Dataset
  project: Project
  tags: Tag[]
  resources: RoleResource[]
  permissionResources?: PermissionResource[]
  role: ProjectMemberRole
  readOnly: boolean
  context?: 'role' | 'dataset'
}
export function DatasetPermissionSummary({
  dataset,
  project,
  resources,
  permissionResources,
  role,
  readOnly,
  context = 'role',
  tags = [],
}: Props) {
  const {basePath} = useRoutePath()
  const [explodedView, setExplodedView] = useState(false)
  const toggleExplodedView = useCallback(() => {
    setExplodedView(!explodedView)
  }, [setExplodedView, explodedView])

  const summaryResources = useMemo(() => {
    return resources
      .filter((r) => isUserRoleResource(r))
      .map((r) => {
        const haveGrants = r.grants.filter((g) => {
          if (g.params?.tagName && !dataset.tags?.some((tag) => tag.name === g.params.tagName))
            return false
          if (g.params?.dataset && dataset.name !== g.params.dataset) return false
          return true
        })
        let mode: string | undefined
        if (r.type === 'sanity.document.filter.mode' && haveGrants.length > 0) {
          mode = haveGrants.reduce(mostPermissiveModeGrant, mode)
        }
        if (r.type === 'sanity.document.filter' && haveGrants.length > 0) {
          const overview = getDocumentFilterAsModeOverview(haveGrants)
          if (overview.read && overview.update && overview.create) {
            mode = 'create'
          } else if (overview.read) {
            mode = 'read'
          } else {
            mode = 'unknown'
          }
        }
        return {
          id: r.id,
          name: r.name,
          description: r.description,
          title: r.title,
          mode,
        }
      })
      .filter((r) => r.mode)
      .sort(contentResourceCompare)
  }, [resources, dataset.name, dataset.tags])

  const datasetTags = useMemo(() => {
    return tags.filter((tag) => dataset.tags?.some((datasetTag) => datasetTag.name === tag.name))
  }, [tags, dataset.tags])

  return (
    <Card radius={2} shadow={1} overflow="hidden">
      <Flex align="center">
        <Stack flex={1} paddingTop={4} space={2}>
          <Flex justify="space-between" paddingX={4}>
            <Inline space={2}>
              <Text weight="semibold">Summary of permissions in</Text>
              <Box>
                {context === 'role' && (
                  <Inline space={2}>
                    <Link
                      href={`${basePath}/datasets?name=${dataset.name}`}
                      passHref
                      legacyBehavior
                    >
                      <Card padding={2} radius={2} tone="transparent" as="a">
                        <Flex>
                          <Box marginRight={2}>
                            <Text size={1}>
                              <DatabaseIcon />
                            </Text>
                          </Box>
                          <Text size={1} weight="medium">
                            {dataset.name}
                          </Text>
                        </Flex>
                      </Card>
                    </Link>
                    <Tooltip
                      portal
                      disabled={dataset.aclMode !== 'public'}
                      content={
                        <Box padding={2}>
                          <Text muted size={1}>
                            All users are able to read published documents on public datasets
                          </Text>
                        </Box>
                      }
                    >
                      <Badge mode="outline" tone={aclModeToTone(dataset.aclMode)}>
                        {dataset.aclMode}
                      </Badge>
                    </Tooltip>
                  </Inline>
                )}
                {context === 'dataset' && (
                  <Tooltip
                    content={
                      <Box padding={2}>
                        <Text muted size={1}>
                          {role.description || role.title}
                        </Text>
                      </Box>
                    }
                  >
                    <Link
                      href={`${basePath}/access/roles?role=${role.name}`}
                      passHref
                      legacyBehavior
                    >
                      <Card padding={2} radius={2} tone={'transparent'} as="a">
                        <Flex>
                          <Box marginRight={2}>
                            <Text size={1}>
                              <UsersIcon />
                            </Text>
                          </Box>
                          <Text size={1} weight="medium">
                            {role.title}
                          </Text>
                        </Flex>
                      </Card>
                    </Link>
                  </Tooltip>
                )}
              </Box>
            </Inline>
          </Flex>
          <Flex paddingX={4} paddingBottom={4}>
            <Inline space={1}>
              <Text muted size={1}>
                All permission granted for the role
              </Text>
              <Text weight="semibold" size={1}>
                {role.title}
              </Text>
              <Text muted size={1}>
                in the dataset
              </Text>
              <Text weight="semibold" size={1}>
                {dataset.name}
              </Text>
              <Text muted size={1}>
                across all sources
              </Text>
            </Inline>
          </Flex>
          <Stack>
            {summaryResources.length > 0 && (
              <Stack>
                <TableHeader />
                {summaryResources.map((cr, i) => {
                  return (
                    <Card
                      key={cr.id}
                      borderBottom={i > summaryResources.length - 1}
                      paddingY={2}
                      paddingX={4}
                    >
                      <Flex align="center">
                        <Flex flex={2}>
                          {cr.description ? (
                            <Tooltip
                              content={
                                <Card radius={2} padding={3} style={{maxWidth: 250}}>
                                  <Text size={1}>{cr.description}</Text>
                                </Card>
                              }
                            >
                              <Text size={1}>{cr.title}</Text>
                            </Tooltip>
                          ) : (
                            <Text size={1}>{cr.title}</Text>
                          )}
                        </Flex>
                        <Flex flex={2} align="center" justify="space-between">
                          <Box style={{minWidth: 180}}>
                            <Flex>
                              <Grant grant={cr.mode} />
                            </Flex>
                          </Box>
                        </Flex>
                      </Flex>
                    </Card>
                  )
                })}
              </Stack>
            )}

            {summaryResources.length == 0 && (
              <Card margin={4} padding={3}>
                <Text align="center" muted size={1}>
                  No permissions granted
                </Text>
              </Card>
            )}
          </Stack>
        </Stack>
      </Flex>
      <Card paddingX={4} paddingY={2} borderTop>
        <Flex>
          <Button mode="bleed" onClick={toggleExplodedView} padding={2}>
            <Flex>
              <Box marginRight={2}>
                <Text size={1}>
                  <ToggleArrowRightIcon
                    style={{transform: explodedView ? 'rotate(90deg)' : 'rotate(0)'}}
                  />
                </Text>
              </Box>
              <Text size={1} weight="medium">
                Show/edit permission sources
              </Text>
            </Flex>
          </Button>
        </Flex>
      </Card>
      {explodedView && (
        <Card tone="transparent" paddingX={3} paddingY={4}>
          <Stack space={4}>
            <ContentResource
              tag={undefined}
              dataset={dataset}
              resources={resources}
              permissionResources={permissionResources}
              projectId={project.id}
              role={role}
              readOnly={readOnly}
            />
            <ContentResource
              tag={undefined}
              dataset={undefined}
              resources={resources}
              permissionResources={permissionResources}
              projectId={project.id}
              role={role}
              readOnly={readOnly}
            />
            {datasetTags.map((tag) => (
              <ContentResource
                key={tag.name}
                tag={tag}
                dataset={undefined}
                resources={resources}
                permissionResources={permissionResources}
                projectId={project.id}
                role={role}
                readOnly={readOnly}
              />
            ))}
          </Stack>
        </Card>
      )}
    </Card>
  )
}
