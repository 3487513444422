import {Grid, Box, Flex, Heading, Stack, useTheme, Text, Inline} from '@sanity/ui'
import React from 'react'
import {AppLink} from '@/ui/index'

export function TabPageHeader({
  title,
  icon,
  children,
  link,
}: {
  title: string
  icon?: React.ReactNode
  children: React.ReactNode
  link?: {
    title: string
    href: string
  }
}) {
  const {sanity} = useTheme()
  const linkColor = sanity.color.card.enabled.link.fg
  return (
    <Flex align={['flex-start', 'flex-start', 'center', 'center']}>
      {!!icon && <Box marginRight={[3, 3, 5]}>{icon}</Box>}
      <Grid gap={[2, 2, 4]} autoFlow="column dense">
        <Box>
          <Flex>
            <Stack space={[3, 3, 4]}>
              {link && (
                <Inline>
                  <AppLink href={link.href}>
                    <Text size={1} style={{color: linkColor}}>
                      {link.title}
                    </Text>
                  </AppLink>
                </Inline>
              )}

              <Box>
                <Heading size={[2, 2, 3]} as="h1">
                  {title}
                </Heading>
              </Box>
              <Box>{children}</Box>
            </Stack>
          </Flex>
        </Box>
      </Grid>
    </Flex>
  )
}
