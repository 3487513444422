import React from 'react'
import {Card, Radio, Grid, Text} from '@sanity/ui'
import styled from 'styled-components'
import {uid} from 'react-uid'

const ToggleButton = styled.label<{checked: boolean}>`
  background-color: ${({checked}) => checked && `#fff`};
  position: relative;
  // padding: 8px 10px;
  text-align: center;

  [data-ui^='Radio'] {
    font-size: 0;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;

    input,
    span {
      opacity: 0;
      position: absolute;
    }
  }

  &:focus-within {
    box-shadow: inset 0 0 0 1px #b6bcc6;
  }

  /* input:focus:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: block;
    box-shadow: inset 0 0 0 1px #b6bcc6;
  } */
`

export function CumulativeToggle({
  onChange,
  mode = 'daily',
}: {
  mode?: string
  onChange: (newMode: string) => void
}) {
  const options = [
    {label: 'Daily', id: 'daily'},
    {label: 'Running total', id: 'runningTotal'},
  ]

  return (
    <Card padding={1} radius={2} tone="transparent" marginRight={4}>
      <Grid columns={2} gapX={1}>
        {options.map((option) => (
          // <ToggleButton key={uid(option.id)} checked={mode === option.id}>
          //   <Text muted={mode !== option.id}>{option.label}</Text>
          //   <Radio checked={mode === option.id} onChange={() => onChange(option.id)} />
          // </ToggleButton>
          <Card
            as={ToggleButton}
            padding={2}
            border={false}
            tone={mode === option.id ? 'default' : 'transparent'}
            radius={mode === option.id ? 2 : 0}
            shadow={mode === option.id ? 1 : 0}
            key={uid(option.id)}
            checked={mode === option.id}
          >
            <Text muted={mode !== option.id}>{option.label}</Text>
            <Radio checked={mode === option.id} onChange={() => onChange(option.id)} />
          </Card>
        ))}
      </Grid>
    </Card>
  )
}
