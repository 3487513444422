import {CogIcon} from '@sanity/icons'
import {Tab} from '@/types/index'
import {Telemetry} from '@/ui/views/org/account/telemetry'
import {PersonalDetails, DeleteAccount, AccountSettings} from '../../org'
import {Notifications} from '../../org/account/notifications'
import {getCommonTabs} from './getCommonTabs'

export function getOrgPersonalTabs(): Tab[] {
  const {projects} = getCommonTabs()

  return [
    {
      ...projects,
      isRoot: true,
      description: 'List of all your active projects.',
    },
    {
      title: 'Account settings',
      icon: CogIcon,
      path: 'account-settings',
      component: AccountSettings,
      headings: [
        {
          title: 'Account settings',
          description: 'Main settings of your account.',
          path: 'account-settings',
        },
        {
          title: 'Danger zone',
          description: 'Potentially destructive actions',
          path: 'danger-zone',
        },
      ],
      sections: [
        {
          title: 'Personal details',
          path: 'personal-details',
          heading: 'account-settings',
          isRoot: true,
          component: PersonalDetails,
        },
        {
          title: 'Telemetry',
          path: 'telemetry',
          heading: 'account-settings',
          isRoot: true,
          component: Telemetry,
        },
        {
          title: 'Notifications',
          path: 'notifications',
          heading: 'account-settings',
          isRoot: true,
          component: Notifications,
        },
        {
          title: 'Delete account',
          description: 'Deletes your account permanently. This cannot be undone.',
          path: 'delete-account',
          heading: 'danger-zone',
          component: DeleteAccount,
        },
      ],
    },
  ]
}
