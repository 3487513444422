import {useQuery} from '@tanstack/react-query'
import {minutesToMilliseconds} from '@/utils/time'
import {getCurrentUserPermissionsKey} from './cache'
import {useMemo} from 'react'
import {keyBy} from 'lodash'
import {getMyPermissions} from '@sanity/access-api'

export function useCurrentUserPermissions(options?: {enabled?: boolean}) {
  return useQuery({
    queryKey: getCurrentUserPermissionsKey(),
    queryFn: () => getMyPermissions(),
    staleTime: minutesToMilliseconds(5),
    enabled: options?.enabled ?? true,
    select: (data) => data.data,
  })
}

/**
 * This uses the new Access API to fetch the permissions for a project. It will over time replace the old project grants checks
 */
export function useProjectPermissions(projectId: string, organizationId?: string) {
  const {data: userPermissions} = useCurrentUserPermissions()
  const permissions = useMemo(() => {
    // We filter by resourceId, but if the user is a org admin, they can manage all projects belonging to that org
    return userPermissions?.filter(
      (permission) =>
        (permission.resourceId === projectId ||
          (permission.ownerOrganizationId === organizationId && permission.resourceId === '*')) &&
        permission.resourceType === 'project'
    )
  }, [userPermissions, projectId, organizationId])

  const grants = useMemo(() => {
    return Object.keys(keyBy(permissions, 'name'))
  }, [permissions])

  return {permissions, grants}
}

/**
 * This uses the new Access API to fetch the permissions for an organization. It will over time replace the old org grants checks
 */
export function useOrganizationPermissions(organizationId: string) {
  const {data: userPermissions} = useCurrentUserPermissions()
  const permissions = useMemo(() => {
    return userPermissions?.filter(
      (permission) =>
        permission.resourceId === organizationId && permission.resourceType === 'organization'
    )
  }, [userPermissions, organizationId])

  const grants = useMemo(() => {
    return Object.keys(keyBy(permissions, 'name'))
  }, [permissions])

  return {permissions, grants}
}
