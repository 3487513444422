import React, {Fragment, useEffect, useState} from 'react'
import {Box, Button, Card, Flex, Inline, Stack, Text} from '@sanity/ui'
import {Icon, IconSymbol} from '@sanity/icons'
import styled from 'styled-components'
import Link from 'next/link'
import {useQueryClient} from '@tanstack/react-query'
import router from 'next/router'
import {hues} from '@sanity/color'
import {BannerStatus, PaymentStateDisplay} from '@/components/arrears/utils'
import {Prompt} from '@/ui/index'
import {sendTrackingEvent} from '@/utils/tracking'
import {ArrearsResolutionDialog} from '@/components/arrears/arrearsResolutionDialog'
import {useCurrentScopeContext} from '@/context/index'

const Semibold = styled.span`
  font-weight: 600;
`
type BillingCardData = {
  tone: 'critical' | 'caution'
  iconSymbol: IconSymbol
  prompt: string
  explanation: JSX.Element
  solutionSteps: solutionStep[]
}

type solutionStep = {
  title: string
  description: JSX.Element
  action: JSX.Element
}

export function ArrearsStatusCard({
  paymentStateDisplay,
}: {
  paymentStateDisplay: PaymentStateDisplay
}) {
  const queryClient = useQueryClient()
  const context = useCurrentScopeContext()
  const currentUser = context?.currentUser

  const [showRestartFlow, setShowRestartFlow] = useState(false)
  const [showDowngradeFlow, setShowDowngradeFlow] = useState(false)
  const [resolutionSucceeded, setResolutionSucceeded] = useState(false)
  const [organizationId, setOrganizationId] = useState<string>(paymentStateDisplay.organizationId)
  const [paymentState, setPaymentState] = useState<BannerStatus>(paymentStateDisplay.status)

  const updatePaymentInfoRoute = `/organizations/${organizationId}/settings#payment`
  const invoicesRoute = `/organizations/${organizationId}/billing/invoices`

  useEffect(() => {
    sendTrackingEvent('ArrearsStatusCard-View', undefined, organizationId)

    // The purpose of this component is to move the payment state along.
    // Force a re-read of the payment state when this component loads to get the latest state.
    queryClient.invalidateQueries({
      queryKey: ['customers', 'paymentstate', currentUser ? currentUser.id : null],
    })
  }, [])

  useEffect(() => {
    setPaymentState(paymentStateDisplay.status)
    setOrganizationId(paymentStateDisplay.organizationId)
  }, [paymentStateDisplay])

  function startDowngradeFlow() {
    sendTrackingEvent('ArrearsStatusCard-Click-Downgrade', undefined, organizationId)
    downgradeSubscription()
  }

  function startRestartFlow() {
    sendTrackingEvent('ArrearsStatusCard-Click-RestartSubscription', undefined, organizationId)
    restartSubscription()
  }

  function restartSubscription() {
    setShowRestartFlow(true)
    setShowDowngradeFlow(false)
  }

  function downgradeSubscription() {
    setShowRestartFlow(false)
    setShowDowngradeFlow(true)
  }

  function reloadPaymentState() {
    setShowRestartFlow(false)
    setShowDowngradeFlow(false)
  }

  function restartSubscriptionComplete(success: boolean) {
    setResolutionSucceeded(success)
    reloadPaymentState()
  }

  let billingCardData: BillingCardData

  if (paymentState === 'warn') {
    billingCardData = {
      tone: 'caution',
      iconSymbol: 'warning-outline',
      prompt: 'Payment overdue',
      explanation: (
        <div>
          An unresolved payment will block all projects soon.
          <Semibold> This will prevent all usage and access to project data,</Semibold> including
          any website or other service using this data.
        </div>
      ),
      solutionSteps: [],
    }
  } else if (paymentState === 'block' || paymentState === 'can_resolve') {
    billingCardData = {
      tone: 'critical',
      iconSymbol: 'error-outline',
      prompt: 'Payment overdue: Projects blocked',
      explanation: (
        <div>
          Project data is safely stored, but an unresolved payment has blocked your projects.
          <Semibold> This prevents all usage and access to project data,</Semibold> including any
          website or other service using this data.
        </div>
      ),
      solutionSteps: [],
    }
  } else {
    return <></>
  }

  const payInvoiceAction =
    paymentState === 'can_resolve' ? (
      <Inline space={3} padding={3}>
        <Text>
          <Icon symbol={'checkmark'} color={hues.green[700].hex} />
        </Text>
        <Text weight="medium" style={{color: hues.green[700].hex}}>
          Complete
        </Text>
      </Inline>
    ) : (
      <Button
        tone="primary"
        text="Go to invoices"
        onClick={() => {
          sendTrackingEvent('ArrearsStatusCard-Click-PayInvoices', undefined, organizationId)
          router.push(invoicesRoute)
        }}
      />
    )

  billingCardData.solutionSteps.push({
    title: 'Pay overdue invoices',
    description: (
      <Fragment>
        Download the invoice PDF(s) and find the link to pay online.
        {paymentState !== 'warn' && (
          <> When all invoices are paid you can continue to the next step.</>
        )}
      </Fragment>
    ),
    action: payInvoiceAction,
  })

  if (paymentState !== 'warn') {
    billingCardData.solutionSteps.push({
      title: 'Unblock projects',
      description: (
        <Fragment>
          When overdue invoices are paid, unblock your projects by restarting your subscription.
          Alternatively,{' '}
          {paymentState === 'block' ? (
            <Fragment>downgrade all projects to the Free Plan</Fragment>
          ) : (
            <button
              type="button"
              onClick={startDowngradeFlow}
              style={{color: 'inherit', textDecoration: 'underline'}}
            >
              downgrade all projects to the Free Plan
            </button>
          )}
          .
        </Fragment>
      ),
      action: (
        <Button
          tone="primary"
          text="Restart subscription"
          disabled={paymentState !== 'can_resolve' || showDowngradeFlow || showRestartFlow}
          title={
            paymentState === 'can_resolve'
              ? ''
              : 'Unpaid invoices must be paid before restarting subscription'
          }
          onClick={() => {
            sendTrackingEvent(
              'ArrearsStatusCard-Click-RestartSubscription',
              undefined,
              organizationId
            )
            startRestartFlow()
          }}
        />
      ),
    })
  }

  const explanation = (
    <Stack space={2}>
      {billingCardData.explanation}
      <section>
        Questions?&nbsp;
        <a
          href={`https://www.${process.env.host}/contact/billing`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() =>
            sendTrackingEvent('ArrearsStatusCard-Click-ContactBilling', undefined, organizationId)
          }
          style={{color: 'inherit', textDecoration: 'underline'}}
        >
          Contact billing support
        </a>
      </section>
    </Stack>
  )

  if (resolutionSucceeded) {
    return (
      <Prompt
        tone="positive"
        cardProps={{
          padding: 3,
          radius: 1,
          shadow: 1,
        }}
        icon={'checkmark-circle'}
        title="Projects successfully unblocked"
        description={
          <Stack space={4} marginTop={3}>
            <Text>Projects are unblocked and access to project data is restored.</Text>
            <Text>
              To avoid failed payments in the future, make sure your{' '}
              <Link
                href={updatePaymentInfoRoute}
                style={{color: 'inherit', textDecoration: 'underline'}}
              >
                payment method
              </Link>{' '}
              are up to date.
            </Text>
          </Stack>
        }
        textSize={2}
        layout={'column'}
        verticaSpacing={4}
        as={'div'}
      />
    )
  }

  const steps = billingCardData.solutionSteps.map((step, index) => (
    <Card key={index} borderTop paddingTop={4}>
      <Flex gap={4} justify="space-between" direction={['column', 'column', 'row']}>
        <Stack space={3} flex={0.7}>
          <Text size={2} weight="semibold">
            {step.title}
          </Text>
          <Text size={1}>{step.description}</Text>
        </Stack>
        <Box>{step.action}</Box>
      </Flex>
    </Card>
  ))

  return (
    <Stack space={0}>
      <Prompt
        tone={billingCardData.tone}
        cardProps={{
          padding: 3,
          radius: 1,
          shadow: 1,
        }}
        icon={billingCardData.iconSymbol}
        title={billingCardData.prompt}
        description={explanation}
        textSize={2}
        layout={'column'}
        verticaSpacing={4}
        as={'div'}
      />
      <Card padding={4} shadow={1} radius={1}>
        <Stack space={4}>
          <Text weight="semibold" size={2}>
            How to resolve the payment issue:
          </Text>
          {steps}
        </Stack>
      </Card>
      {showRestartFlow && (
        <ArrearsResolutionDialog
          organizationId={organizationId}
          onClose={restartSubscriptionComplete}
          action="reinstate"
        />
      )}
      {showDowngradeFlow && (
        <ArrearsResolutionDialog
          organizationId={organizationId}
          onClose={restartSubscriptionComplete}
          action="downgrade"
        />
      )}
    </Stack>
  )
}
