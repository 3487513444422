/* eslint-disable max-statements */
/* eslint-disable complexity */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-unused-prop-types */
import React, {useEffect, useMemo, useState} from 'react'
import {Card, Text} from '@sanity/ui'
import {BillIcon} from '@sanity/icons'
import {format, isSameMonth, isSameYear} from 'date-fns'
import {hues} from '@sanity/color'
import {ChartSummaryItem, ChartSummaryList} from './chartSummaryList'
import {useProjectUsage} from '@/data/projects'
import {ProjectUsage} from '@/types/models'
import {ColumnChart, ChartSectionHeader, ChartContextualAction} from '@/ui/index'
import {
  useCurrentScopeContext,
  useUsageContext,
  useRoutePath,
  useRequiredOrganizationPermissions,
} from '@/context/index'
import {
  getCompareSeriesForChartSection,
  formatUnitType,
  useScrollMarginTop,
  getResourcesArray,
  getMaxAllowedResourceUsage,
} from '@/utils/index'

const currentComparePeriodColor = hues.blue[500].hex
const previousComparePeriodColor = hues.gray[200].hex

export type AccumulativeMode = 'daily' | 'runningTotal'

interface ChartSectionProps {
  id: string
  title?: string
  description?: string
  isPlayground?: boolean
}

function getSummaryHeaderData(id: string, summary?: ProjectUsage) {
  const resource = summary?.resources?.[id.toLowerCase()] || {}
  const {quota, usage, unit} = resource
  const visibleQuota = getMaxAllowedResourceUsage(resource, true) || 0
  return {
    quota: visibleQuota,
    quotaFormatted: formatUnitType(visibleQuota, unit, quota === null),
    usage: usage,
    usageFormatted: formatUnitType(usage || 0, unit),
    hasOverage: usage >= quota,
  }
}

const yaxisFormatMap: Record<
  string,
  'number' | 'size' | 'gigaBytes' | 'bytesPrecise' | 'simpleNumber'
> = {
  bandwidth: 'gigaBytes',
  assets: 'gigaBytes',
  datasets: 'simpleNumber',
}
const curveStroke = 'smooth'

export function ChartSectionProject({title, id}: ChartSectionProps) {
  const scrollMargin = useScrollMarginTop()
  const currentScope = useCurrentScopeContext()
  const [mode, setMode] = useState<AccumulativeMode>('daily')
  const {orgPath} = useRoutePath()
  const isLineChart = mode === 'runningTotal'
  const resourcechartIds = ['assets', 'documents', 'datasets']
  const isResourceChart = resourcechartIds.includes(id)
  const {currentPeriod, currentRange, compareRange} = useUsageContext()

  const {data: currentProjectUsage, isLoading: usageLoading} = useProjectUsage(
    currentScope?.project,
    {
      ...currentRange,
      period: currentPeriod,
    }
  )

  const {data: compareProjectUsage, isLoading: compareUsageLoading} = useProjectUsage(
    currentScope?.project,
    {
      ...compareRange,
      period: currentPeriod,
    }
  )

  const isLoading = usageLoading || compareUsageLoading

  useEffect(() => {
    setMode('daily')
  }, [currentPeriod?.history])

  const canManageBilling = useRequiredOrganizationPermissions([
    {permissionName: 'sanity.organization', grantName: 'billing'},
  ])

  const actions: ChartContextualAction[] = [
    {
      title: 'View associated costs',
      icon: BillIcon,
      tone: 'default',
      mode: 'bleed',
      href: `${orgPath}/billing`,
      hasPermission: canManageBilling,
    },
  ]

  const onChangeMode = (newMode: AccumulativeMode) => {
    setMode(newMode)
  }

  const isYearPeriod = currentPeriod?.history === 'month'
  const cumulative = !isYearPeriod && mode === 'runningTotal'
  const isCompareMode = typeof compareRange?.toDate !== 'undefined'
  const isDifferentCompareYear =
    currentRange?.fromDate &&
    compareRange?.toDate &&
    !isSameYear(currentRange?.fromDate, compareRange?.toDate)
  const seriesPerDay = useMemo(
    () =>
      getCompareSeriesForChartSection({
        id,
        projects: currentProjectUsage ? [currentProjectUsage] : [],
        compareProjects: compareProjectUsage ? [compareProjectUsage] : [],
        cumulative: false,
        isLineChart: false,
        currentRange,
        compareRange,
        mode: currentPeriod?.history,
      }),
    [
      compareRange,
      id,
      currentProjectUsage,
      compareProjectUsage,
      currentRange,
      currentPeriod?.history,
    ]
  )
  const seriesAccumulated = useMemo(
    () =>
      getCompareSeriesForChartSection({
        id,
        projects: currentProjectUsage ? [currentProjectUsage] : [],
        compareProjects: compareProjectUsage ? [compareProjectUsage] : [],
        cumulative: true,
        isLineChart: true,
        currentRange,
        compareRange,
        mode: currentPeriod?.history,
      }),
    [
      compareRange,
      id,
      currentProjectUsage,
      compareProjectUsage,
      currentRange,
      currentPeriod?.history,
    ]
  )
  const series = cumulative ? seriesAccumulated : seriesPerDay
  const {
    usageFormatted: summaryUsageFormatted,
    quota: summaryQuota,
    quotaFormatted: summaryQuotaFormatted,
    hasOverage: summaryHasOverage,
  } = getSummaryHeaderData(id, currentProjectUsage)

  const summaryList: ChartSummaryItem[] = (() => {
    const list: ChartSummaryItem[] = []

    if (isLoading) {
      return [
        {
          id: 'Placeholder',
          value: 0,
          unit: 'number',
          label: 'Placeholder',
        },
      ]
    }

    let periodSummaryValue = 0
    let comparePeriodSummaryValue = 0
    let periodItem: ChartSummaryItem | null = null
    let comparePeriodItem: ChartSummaryItem | null = null
    let isDifferentMonth =
      currentRange?.fromDate &&
      currentRange?.toDate &&
      !isSameMonth(currentRange?.fromDate, currentRange?.toDate)
    let isDifferentYear =
      currentRange?.fromDate &&
      currentRange?.toDate &&
      !isSameYear(currentRange?.fromDate, currentRange?.toDate)
    let periodDateKey = format(currentRange!.toDate!, 'MMMM yyyy')

    if (isDifferentMonth) {
      periodDateKey = `${format(currentRange!.fromDate!, 'MMMM')} - ${format(
        currentRange!.toDate!,
        'MMMM yyyy'
      )}`
    }

    if (isDifferentYear) {
      periodDateKey = `${format(currentRange!.fromDate!, 'MMMM yyyy')} - ${format(
        currentRange!.toDate!,
        'MMMM yyyy'
      )}`
    }

    // Get this project
    if (currentProjectUsage) {
      const resource =
        currentProjectUsage.resources?.[id] || currentProjectUsage.resources?.[id.toLowerCase()]
      periodSummaryValue = periodSummaryValue + resource?.usage || 0

      if (!periodItem) {
        periodItem = {
          color: currentComparePeriodColor,
          id: `${id}-${periodDateKey}`,
          value: 0,
          unit: resource?.unit || 'number',
          label: periodDateKey,
          sortOrder: 1,
        }
      }

      periodItem.value = periodSummaryValue
    }

    if (compareRange?.toDate && currentRange?.toDate) {
      isDifferentMonth =
        compareRange?.fromDate &&
        compareRange?.toDate &&
        !isSameMonth(compareRange?.fromDate, compareRange?.toDate)
      isDifferentYear =
        compareRange?.fromDate &&
        compareRange?.toDate &&
        !isSameYear(compareRange?.fromDate, compareRange?.toDate)
      periodDateKey = format(compareRange?.toDate, 'MMMM yyyy')

      if (isDifferentMonth) {
        periodDateKey = `${format(compareRange!.fromDate!, 'MMMM')} - ${format(
          compareRange?.toDate,
          'MMMM yyyy'
        )}`
      }

      if (isDifferentYear) {
        periodDateKey = `${format(compareRange!.fromDate!, 'MMMM yyyy')} - ${format(
          compareRange?.toDate,
          'MMMM yyyy'
        )}`
      }
      const comparePeriodDateKey = format(compareRange?.toDate, 'MMMM yyyy')
      if (compareProjectUsage) {
        const resource =
          compareProjectUsage.resources?.[id] || compareProjectUsage.resources?.[id.toLowerCase()]
        comparePeriodSummaryValue = comparePeriodSummaryValue + resource?.usage || 0

        if (!comparePeriodItem) {
          comparePeriodItem = {
            color: previousComparePeriodColor,
            id: `${id}-${comparePeriodDateKey}`,
            value: 0,
            unit: resource?.unit || 'number',
            label: comparePeriodDateKey,
            sortOrder: 2,
          }
        }

        comparePeriodItem.value = comparePeriodSummaryValue
      }
    }

    if (comparePeriodItem) {
      list.push(comparePeriodItem)
    }
    if (periodItem) {
      list.push(periodItem)
    }

    return list
  })()

  const description = (
    <>
      <Text as="span" size={2} color={summaryHasOverage ? '#3AB667' : undefined}>
        {summaryUsageFormatted || 'Placeholder'}
      </Text>
      {currentPeriod?.history === 'day' && (
        <Text as="span" size={1} muted>
          &nbsp;/ {summaryQuotaFormatted}
        </Text>
      )}
    </>
  )

  // Get all the project resources for the current period
  const currentResources = getResourcesArray(currentProjectUsage?.resources) || {}
  // Get all the project resources for the compare period
  const compareResources = getResourcesArray(compareProjectUsage?.resources) || {}
  // Get the usage and unit for the current usage
  const {usage = 0, unit} = currentResources.find((r) => r.id === id.toLowerCase()) || {}
  // Get the usage of the compare data
  const {usage: compare} = compareResources.find((r) => r.id === id.toLowerCase()) || {}

  return (
    <Card
      padding={4}
      radius={2}
      id={id?.toLowerCase()}
      border
      style={{scrollMarginTop: scrollMargin}}
    >
      <ChartSectionHeader
        actions={actions}
        title={title}
        description={description}
        showCumulativeToggle={!isResourceChart}
        onChangeMode={isYearPeriod ? undefined : onChangeMode}
        id={id}
        loading={isLoading}
        compareValue={{usage, compare, unit}}
      />
      <ColumnChart
        dataSeries={series}
        curveStroke={curveStroke}
        stacked={false}
        chartType={isLineChart ? 'line' : 'bar'}
        isLoading={isLoading}
        showQuotaAnnotation={cumulative && !isYearPeriod}
        currentPlanQuota={summaryQuota || 0}
        isCumulative={cumulative}
        isCompareMode={isCompareMode}
        isDifferentCompareYear={isDifferentCompareYear}
        period={currentPeriod?.history}
        yAxisFormat={yaxisFormatMap?.[id] || 'number'}
        usageType={id?.toLowerCase()}
        formatDataPointLabelAsISODate
      />
      <ChartSummaryList items={summaryList} loading={isLoading} showZeroValues={isCompareMode} />
    </Card>
  )
}
