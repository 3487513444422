import React, {useEffect, useState} from 'react'
import {Text, Box, Stack, Flex, useToast, Switch} from '@sanity/ui'
import {useOrganizationData} from '../../../../data'
import {Organization} from '@/types/index'
import {TabSectionHeader} from '@/ui/index'
import {useRequiredOrganizationPermissions} from '@/context/index'
import Link from 'next/link'

export function DashboardSettings({org, title}: {org: Organization; title: string}) {
  const {
    data: orgData,
    patchOrganizationData: editOrg,
    patchSuccess,
    patchError: error,
  } = useOrganizationData(org)

  const {push} = useToast()
  const [successMessage, setSuccessMessage] = useState('')

  useEffect(() => {
    if (patchSuccess) {
      if (successMessage) {
        push({
          status: 'info',
          description: successMessage,
          closable: true,
        })
      }
    } else if (error) {
      push({
        status: 'error',
        title: 'Failed to update dashboard settings',
        description: 'Failed to update dashboard settings',
      })
    }
  }, [patchSuccess, successMessage, error, push])

  const canManage = useRequiredOrganizationPermissions([
    {permissionName: 'sanity.organization', grantName: 'update'},
  ])

  function handleToggleNewDashboard(enabled: boolean) {
    const newStatus = enabled ? 'enabled' : 'disabled'
    editOrg({dashboardStatus: newStatus})
    setSuccessMessage(`New dashboard experience ${newStatus}`)
  }

  const hasNewDashboard = orgData?.dashboardStatus === 'enabled'

  return (
    <Box>
      <Stack space={5}>
        <TabSectionHeader title={title} />
        <Stack space={5}>
          <Flex>
            <Box flex={1}>
              <Stack space={3}>
                <Flex align="center" gap={3}>
                  <Switch
                    checked={hasNewDashboard}
                    onChange={(event) => handleToggleNewDashboard(event.currentTarget.checked)}
                    disabled={!canManage}
                  />
                  <Text size={1} weight="semibold">
                    Make Dashboard default experience for this organization
                  </Text>
                </Flex>
                <Text size={1} muted>
                  Studio links will open the Studio in Dashboard, and members signing in to Sanity
                  will land in Dashboard, not standalone Studio URLs.{' '}
                  <Link href="https://www.sanity.io/blog">Learn more about dashboard</Link>
                  {/* TODO UPDATE THIS LINK WHEN THE DASHBOARD BLOG POST IS LIVE */}
                </Text>

                {!canManage && (
                  <Text size={1} muted>
                    Your member role does not have access to organization settings
                  </Text>
                )}
              </Stack>
            </Box>
          </Flex>
        </Stack>
      </Stack>
    </Box>
  )
}
