import React from 'react'
import {Icon, IconSymbol} from '@sanity/icons'
import {Box, Card, Flex, Stack, Text, ThemeColorToneKey, useTheme} from '@sanity/ui'
import numeral from 'numeral'
import {isThisMonth} from 'date-fns'
import {ResourceIds, ResourceItem} from '@/types/models'
import {getResourceNameById} from '@/utils/general'
import {FIXED_RESOURCES, getUsageDifference, NO_GRAPHS_RESOURCES} from '@/utils/usage'
import {useUsageContext} from '@/context/index'
import {isInfinity} from '@/utils/number'

export interface UsageSummaryItem extends ResourceItem {
  overageTone?: ThemeColorToneKey | undefined
  overageIcon?: IconSymbol | undefined
  link?: React.ReactNode
  dense?: boolean
  compare?: number
  prefix?: React.ReactNode

  approachingQuota: boolean
  hasOverage: boolean
}

export function Item({
  id,
  quota,
  usage = 0,
  approachingQuota,
  hasOverage,
  name,
  unit,
  overageTone = 'positive',
  overageIcon = 'overage',
  link,
  dense,
  compare,
  prefix,
}: UsageSummaryItem) {
  const {sanity} = useTheme()
  const {currentPeriod, currentRange} = useUsageContext()
  const isCurrentMonthUsage = currentRange?.fromDate ? isThisMonth(currentRange?.fromDate) : false
  const isYearRangeAndFixed =
    currentPeriod?.snapshot === 'year' && !FIXED_RESOURCES.includes(id.toLowerCase())
  const showOverage =
    isCurrentMonthUsage && !isYearRangeAndFixed && (hasOverage || approachingQuota)
  const _overageTone = sanity.color.solid[overageTone].enabled.bg

  const format = {
    usage: numeral(usage).format(unit === 'byte' ? '0[.]0 b' : '0[.]0a'),
    quota: isInfinity(quota ?? Infinity)
      ? '∞'
      : numeral(quota).format(unit === 'byte' ? '0[.]0 b' : '0[.]0a'),
  }

  const tooltipFormat = numeral(usage).format(unit === 'byte' ? '0[.]00 b' : '0,0')

  const usageDiff = getUsageDifference(usage, unit, compare)
  const diffColor = usageDiff?.isPositive ? sanity.color.spot.green : sanity.color.spot.red

  return (
    <Card
      padding={3}
      style={dense ? {boxShadow: '0 0 0px 1px var(--card-border-color)'} : {}}
      radius={dense ? 0 : 2}
      border={!dense}
    >
      <Flex align="center" justify="space-between" paddingY={1}>
        <Stack space={3}>
          <Flex align="flex-end">
            <Text
              size={4}
              style={showOverage ? {color: _overageTone} : {}}
              title={usage > 1000 ? tooltipFormat : ''}
            >
              <Flex align={'center'}>
                {showOverage && (
                  <Box marginRight={3}>
                    <Text size={3} style={{color: 'inherit'}}>
                      {prefix ? <>{prefix}</> : <Icon symbol={overageIcon} />}
                    </Text>
                  </Box>
                )}
                {format.usage}
              </Flex>
            </Text>
            {typeof quota !== 'undefined' && !isYearRangeAndFixed && (
              <>
                <Box marginX={2}>
                  <Text size={1} muted>
                    /
                  </Text>
                </Box>
                <Text size={1} muted>
                  {format.quota}
                </Text>
              </>
            )}
            {isYearRangeAndFixed && (
              <Box marginX={2}>
                <Text size={1} muted>
                  total
                </Text>
              </Box>
            )}
            {usageDiff && (
              <Box marginX={2}>
                <Text size={1} style={{color: diffColor}}>
                  {usageDiff.value}
                </Text>
              </Box>
            )}
          </Flex>
          <Box marginTop={1}>
            <Text size={1} weight="semibold">
              {name || getResourceNameById(id as ResourceIds)}
            </Text>
          </Box>
        </Stack>
        {!NO_GRAPHS_RESOURCES.includes(id.toLowerCase()) && link}
      </Flex>
    </Card>
  )
}
