/* eslint-disable complexity */
import React from 'react'

export const Support = ({color}: {color?: string}) => (
  <svg
    width="242"
    height="120"
    viewBox="0 0 242 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="28.5"
      y="26.62"
      width="53"
      height="29"
      rx="2.5"
      stroke={color || '#2276FC'}
      strokeOpacity="0.25"
    />
    <rect
      x="5.5"
      y="62.62"
      width="23"
      height="13"
      rx="1.5"
      stroke={color || '#2276FC'}
      strokeOpacity="0.25"
    />
    <rect
      x="212.5"
      y="28.62"
      width="23"
      height="13"
      rx="1.5"
      stroke={color || '#2276FC'}
      strokeOpacity="0.25"
    />
    <rect
      x="168.5"
      y="47.62"
      width="57"
      height="34"
      rx="2.5"
      stroke={color || '#2276FC'}
      strokeOpacity="0.25"
    />
    <circle cx="139.5" cy="46.62" r="2.5" fill={color || '#2276FC'} fillOpacity="0.5" />
    <circle cx="148.5" cy="46.62" r="2.5" fill={color || '#2276FC'} fillOpacity="0.5" />
    <circle cx="130.5" cy="46.62" r="2.5" fill={color || '#2276FC'} fillOpacity="0.5" />
    <path
      d="M172 72.2H158.92V86.62L143.5 72.2H106.72C103.406 72.2 100.72 69.5137 100.72 66.2V23C100.72 19.6863 103.406 17 106.72 17H172C175.314 17 178 19.6863 178 23V66.2C178 69.5137 175.314 72.2 172 72.2Z"
      stroke={color || '#2276FC'}
      strokeOpacity="0.5"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M51 87.28H59V102.12L74.5 87.28H109.56C112.874 87.28 115.56 84.5937 115.56 81.28V42.88C115.56 39.5663 112.874 36.88 109.56 36.88H51C47.6863 36.88 45 39.5663 45 42.88V81.28C45 84.5937 47.6863 87.28 51 87.28Z"
      fill={color || '#2276FC'}
      fillOpacity="0.25"
      stroke={color || '#2276FC'}
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M80.8571 67.0976C80.8571 59.9431 86 61.7317 86 56.3659C86 54.0183 84.2857 51 80.8571 51C77.4286 51 75.7143 52.7886 74 54.5772M80.8571 76V72.4634"
      stroke="white"
      strokeWidth="4"
      strokeLinejoin="round"
    />
  </svg>
)
Support.displayName = 'Support'
