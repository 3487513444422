import {Card, Flex, Stack, Text, Inline} from '@sanity/ui'
import styled from 'styled-components'
import {motion} from 'framer-motion'

const DescriptionText = styled(Text)`
  max-width: 65ch;
  margin: 0 auto;
`

const MotionInline = motion(Inline)

export function AllSetHeading() {
  return (
    <Card marginTop={4} marginBottom={5}>
      <Stack space={4}>
        <Flex align="center" justify="center">
          <Text size={4} weight="bold" align="center">
            You’re all set up!
          </Text>
          <MotionInline
            initial={{scale: 0.5}}
            animate={{
              scale: [0.5, 1, 0.8, 1, 0.5],
              rotate: [0, -10, 10, -10, 0],
            }}
            transition={{duration: 1, delay: 1}}
            style={{fontSize: '3.2em'}}
          >
            🎉
          </MotionInline>
        </Flex>
        <DescriptionText size={2} align="center" muted>
          Here are a few recommended resources to help you get the most out of Sanity.
        </DescriptionText>
      </Stack>
    </Card>
  )
}
