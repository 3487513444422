import {MenuItem} from '@sanity/ui'
import {useCallback} from 'react'

import {useAnnouncements} from '@/context/announcements'
import {sendAmplitudeTrackingEvent} from '@/utils/tracking'

export function AnnouncementsMenuItem({text}: {text: string}) {
  const {onDialogOpen, announcements} = useAnnouncements()

  const handleOpenDialog = useCallback(() => {
    onDialogOpen('help_menu')
    sendAmplitudeTrackingEvent('Whats New Help Menu Item Clicked', undefined, undefined, {
      source: 'manage',
      announcement_id: announcements[0]?._id,
      announcement_title: announcements[0]?.title,
      announcement_internal_name: announcements[0]?.name,
    })
  }, [onDialogOpen, announcements])

  if (announcements.length === 0) return null
  return <MenuItem tone="default" text={text} onClick={handleOpenDialog} />
}
