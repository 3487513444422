import {useMemo} from 'react'
import {useCurrentScopeContext} from './useCurrentScopeContext'
import {Project} from '@/types/models'
import {useRequiredProjectPermissions, useProjectType} from '@/context/index'
import {useProjectSubscription} from '@/data/projects/useProjectSubscription'
import {useOrganizationCanPay} from '@/data/organizations'

export function useCanInviteProjectMember({project}: {project: Project}): boolean {
  const {org} = useCurrentScopeContext() ?? {}
  const {data: canPay} = useOrganizationCanPay(org?.id)
  const {data: subscription} = useProjectSubscription(project?.id)
  const hasPermission = useRequiredProjectPermissions([
    {permissionName: 'sanity.project.members', grantName: 'invite'},
  ])
  const projectType = useProjectType(project)

  const {members} = project
  const filteredMembers = useMemo(() => members.filter((m) => !m.isRobot), [members])

  const orgPlanResources =
    projectType === 'playground'
      ? org?.organizationPlan?.playgroundProjects?.maxResources
      : org?.organizationPlan?.resources

  const membersQuota =
    org?.organizationPlan && projectType !== 'regular'
      ? orgPlanResources.users.quota
      : subscription?.resources.users.quota

  const canOverage =
    org?.organizationPlan && projectType !== 'regular'
      ? orgPlanResources.users.overageAllowed
      : subscription?.resources.users.overageAllowed

  const hasInfiniteQuota = membersQuota === null
  const hasOverage = hasInfiniteQuota ? false : (filteredMembers || [])?.length >= membersQuota
  const disableInviteMembers = hasOverage && canPay?.status !== true && canOverage !== true

  return disableInviteMembers === false && hasPermission
}
