import {useQueries} from '@tanstack/react-query'
import {useCurrentScopeContext} from './useCurrentScopeContext'
import {request} from '@/data/util/promiseRequest'
import {Subscription, PlanTypeIds} from '@/types/models'
import {getProjectSubscriptionKey} from '@/data/projects/cache'

type Props = PlanTypeIds[] | undefined

export function useOrgHasProjectPlan(plans: Props) {
  const {org, scope} = useCurrentScopeContext() ?? {}

  const results = useQueries({
    queries:
      org?.projects.map((project) => ({
        queryKey: getProjectSubscriptionKey(project.id),
        queryFn: () =>
          request<Subscription>({
            url: `https://api.${process.env.host}/v2022-08-11/subscriptions/project/${project.id}`,
          }).then((res) => {
            if (!res.ok) {
              throw new Error(res.body.message)
            }

            return res.body
          }),
      })) || [],
  })

  if (plans && (scope === 'org' || scope === 'user')) {
    return results.some(({data: subscription}) =>
      plans.some((planTypeId) => planTypeId === subscription?.plan.planTypeId)
    )
  }

  return true
}
