import {timeToMilliseconds} from '@/utils/time'
import getIt from 'get-it'
import jsonRequest from 'get-it/lib/middleware/jsonRequest'
import jsonResponse from 'get-it/lib/middleware/jsonResponse'
import promise from 'get-it/lib/middleware/promise'
import ky from 'ky'
import {type Options, HTTPError} from 'ky'
import {APIError as LegacyAPIError} from './request'

export type RequestMethod = 'GET' | 'POST' | 'PUT' | 'HEAD' | 'DELETE' | 'OPTIONS' | 'PATCH'

export interface RequestOptions {
  url: string
  method?: RequestMethod
  headers?: Record<string, unknown>
  body?: string | ArrayBufferView | Blob | FormData | File | unknown
  bodySize?: number
  timeout?: {connect: number; socket: number}
  maxRedirects?: number
  rawBody?: boolean
  query?: Record<string, unknown>
  withCredentials?: boolean
  signal?: AbortSignal
}

export class APIError extends Error {
  response: string

  constructor(
    public statusCode: number,
    message: string,
    response: string,
    cause?: Error
  ) {
    super(message, {cause})
    Object.setPrototypeOf(this, APIError.prototype)
    this.statusCode = statusCode
    this.message = message
    this.response = response
  }
}

const getItRequester = getIt([jsonRequest(), jsonResponse(), promise()])
export const requester = ky.create({
  credentials: 'include',
  // Let react-query handle retries
  retry: 0,
  // Some of our requests can take a while to complete
  timeout: timeToMilliseconds(30, 'seconds'),
})

type Response<T = unknown> = {
  body: T
  headers: Record<string, string>
  method: RequestMethod
  statusCode: 200 | 201
  statusMessage: string
  url: string
  ok: true
}
export type ErrorResponse<E = APIBoomError> = {
  body: E
  headers: Record<string, string>
  method: RequestMethod
  statusCode: number
  statusMessage: string
  url: string
  ok: false
}
export type APIBoomError = {
  statusCode: number
  error: string
  message: string
}

export function request<T = unknown, E = APIBoomError>(
  options: RequestOptions
): Promise<Response<T> | ErrorResponse<E>> {
  return getItRequester({withCredentials: true, ...options}).then(
    (res: Response<T> | ErrorResponse<E>) => {
      if (res.statusCode >= 400) {
        return {
          ...res,
          ok: false,
        }
      }

      return {
        ...res,
        ok: true,
      }
    }
  )
}

export function isHttpError<T>(e: unknown): e is HTTPError<T> {
  return e instanceof HTTPError
}

export function isAPIError(e: unknown): e is APIError {
  return e instanceof APIError
}

export function isLegacyAPIError(e: unknown): e is LegacyAPIError {
  return e instanceof LegacyAPIError
}

export function isLegacyHttpError(e: unknown): e is Error & {response: {statusCode: number}} {
  return (
    e instanceof Error &&
    typeof (e as any).response === 'object' &&
    typeof (e as any).response.statusCode === 'number' &&
    (e as any).response.statusCode >= 400 &&
    (e as any).response.statusCode < 500
  )
}

export function isIgnorableError(e: unknown) {
  return isLegacyHttpError(e) || (e instanceof ProgressEvent && e.type === 'error')
}

export function promiseRequest<T = unknown, E extends APIBoomError = APIBoomError>(
  url: string,
  options?: Options
) {
  return requester<T>(url, options)
    .json()
    .catch(async (e) => {
      if (isHttpError<E>(e)) {
        if (e.response.status >= 400) {
          const res = await e.response.json()
          const apiError = new APIError(e.response.status, res.message, JSON.stringify(res), e)
          // Preserve the original stack trace
          apiError.stack = e.stack

          throw apiError
        }
      }

      throw e
    })
}
