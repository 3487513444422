import {type IconSymbol} from '@sanity/icons'
import {Box} from '@sanity/ui'
import React from 'react'
import {styled} from 'styled-components'

import {Tooltip, type TooltipProps} from '../../primitives/tooltip'
import {PermissionTooltipContent} from './PermissionTooltipContent'

export interface PermissionTooltipProps
  extends Pick<TooltipProps, 'disabled' | 'portal' | 'children'> {
  title?: string | React.ReactNode
  action?: string
  icon?: IconSymbol
  description?: string
}

const Wrapper = styled(Box)`
  &[data-disabled='true'] {
    cursor: not-allowed;

    > * {
      pointer-events: none;
    }
  }
`

export function PermissionTooltip(props: PermissionTooltipProps) {
  const {
    action,
    children,
    description: customDescription,
    disabled,
    icon = 'error-outline',
    title = 'Insufficient permissions',
  } = props

  const description =
    customDescription || `Your member role ${action || 'cannot perform this action'}`

  return (
    <Tooltip
      content={<PermissionTooltipContent icon={icon} title={title} description={description} />}
      disabled={disabled}
    >
      <Wrapper data-disabled={!disabled}>{children}</Wrapper>
    </Tooltip>
  )
}
