import React from 'react'

export const Error = ({color}: {color?: string}) => (
  <svg width="62" height="82" viewBox="0 0 62 82" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.7785 51.5776C23.997 54.6232 30.8043 54.7638 37.983 51.8917C45.0457 49.0661 49.8367 44.3772 48.8296 41.3261C48.7967 41.2264 48.7409 41.1359 48.6737 41.0552L21.7514 8.76009C21.1312 8.01615 19.9214 8.50018 19.9855 9.46659L22.7785 51.5776ZM22.7785 51.5776C22.7785 55.3092 24.4095 59.3888 26.7483 62.006C27.2841 62.6057 27.0413 63.6194 26.2745 63.8617C23.5147 64.7337 20.4464 66.075 22.2535 67.0006C25.0414 68.4285 31.0252 67.4086 31.7732 68.5645C32.5212 69.7205 27.1493 73.3244 36.873 72.8484C46.5967 72.3724 60.5362 70.1285 56.7963 68.5645C52.6427 66.8276 57.1212 64.0527 51.4241 63.4539C50.5051 63.3573 50.0186 62.1374 50.5984 61.4178C52.6378 58.8868 53.8527 55.7034 53.8527 52.2453C53.8527 47.8558 51.8951 43.9087 48.7786 41.1769M42.6085 49.5949C43.5684 45.2445 45.0053 36.5771 43.1426 34.4203M37.4026 52.1177C38.8011 45.7799 41.2363 32.0204 38.5226 28.8783M31.8756 53.6472C33.8031 44.9118 36.7118 26.5502 32.9716 22.2195M26.4254 53.8059C28.7058 43.4713 32.2546 21.205 27.8297 16.0814M22.8277 51.7997C24.5323 40.4326 28.0478 16.0025 22.1488 9.26929M21.1902 27.6305C21.5215 21.8728 21.1205 17.1735 20.2144 12.9188M29.5781 18.1487C29.5781 19.034 27.7235 19.7516 25.1753 19.7516C22.6271 19.7516 20.5613 19.034 20.5613 18.1487M42.9032 34.1331C42.9032 36.0838 38.2875 37.6681 32.358 37.6681C26.4285 37.6681 21.6217 36.0868 21.6217 34.136M47.7868 44.5646C48.0337 43.8481 48.7752 41.7521 48.0223 40.2739"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M46.2212 38.1973C46.2212 40.4064 40.7776 42.1973 34.0626 42.1973C27.3475 42.1973 21.9039 40.4064 21.9039 38.1973"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M48.857 42.6296C48.857 45.0513 42.8898 47.0144 35.529 47.0144C28.1681 47.0144 22.2009 45.0513 22.2009 42.6296"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M22.5582 47.4464C22.5582 49.868 28.5254 51.8311 35.8863 51.8311C36.7403 51.8311 37.5756 51.8047 38.385 51.7542"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M39.9912 30.3623C39.9912 32.1498 35.82 33.5988 30.6747 33.5988C25.5293 33.5988 21.3582 32.1498 21.3582 30.3623"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M36.6967 26.4888C36.6967 27.9846 33.206 29.1973 28.9 29.1973C24.594 29.1973 21.1033 27.9846 21.1033 26.4888"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M33.2566 22.3387C33.2566 23.5289 30.4793 24.4937 27.0534 24.4937C23.6275 24.4937 20.8502 23.5289 20.8502 22.3387"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M26.1884 13.8937C26.1884 14.4436 24.905 14.8895 23.3219 14.8895C21.7388 14.8895 20.4554 14.4436 20.4554 13.8937"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <ellipse
      cx="23.0632"
      cy="71.5175"
      rx="3.45658"
      ry="0.829173"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M45.8116 53.2395C45.8446 55.5194 45.5538 59.9205 44.1264 59.2861C42.3422 58.4931 42.5404 57.3036 40.7562 57.5019C38.972 57.7001 37.8816 60.8721 36.3948 60.0791"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
      strokeLinecap="round"
    />
  </svg>
)
Error.displayName = 'Error'
