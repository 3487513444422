import {Tab} from '@/types/components'
import {BillIcon} from '@sanity/icons'
import {overviewTab, projectsTab, membersTab, activityTab, settingsTab} from '../../config'
import {OrgOverview, OrgBilling, OrgInvoices} from '../../org'
import {OrgActivity} from '../../org/activity'
import {OrgMembersTable, OrgInvitations} from '../../org/members'
import {ActiveProjects, ArchivedProjects} from '../../org/projects'
import {
  SsoSAML,
  OrgDetails,
  ContactDetails,
  BillingDetails,
  PaymentMethod,
  DeleteOrg,
} from '../../org/settings'
import {Settings} from '../../project'
import {GetOrgTabsOptions} from '../types'
import {OrganizationMembersView} from '../../org/members_v2'
import {OrgDefaultRoleSetting} from '../../org/settings/OrgDefaultRoleSetting'
import {OrgAccessRequestSettings} from '../../org/settings/accessRequests'
import {OrganizationAccessRequestSettingsLink} from '../../org/settings/OrganizationAccessRequestSettingsLink'
import {DashboardSettings} from '../../org/settings/dashboardSettings'

const projects: Tab = {
  ...projectsTab,
  description: 'List of all the active projects you have access to in the organization.',
  headings: [
    {title: 'Active projects', path: 'active', component: ActiveProjects, isRoot: true},
    {
      title: 'Archived projects',
      path: 'archived',
      component: ArchivedProjects,
      description: 'List of all archived projects.',
    },
  ],
}

const members: Tab = {
  ...membersTab,
  description:
    'Organization members can administer organization settings such as billing, contacts and will be able to manage all project members in the organization from this view.',
  permissions: [
    {permissionName: 'sanity.organization.members', grantName: 'read'},
    {permissionName: 'sanity.organization.members', grantName: 'invite'},
  ],
  headings: [
    {
      title: 'Members',
      path: 'members',
      component: OrgMembersTable,
      isRoot: true,
      permissions: [{permissionName: 'sanity.organization.members', grantName: 'read'}],
    },
    {
      title: 'Invitations',
      path: 'invitations',
      component: OrgInvitations,
      permissions: [{permissionName: 'sanity.organization.members', grantName: 'invite'}],
    },
  ],
}

const activity: Tab = {
  ...activityTab,
  component: OrgActivity,
  description:
    'Find out what’s happening across your organization, including the projects you have access to.',
}

const members_v2: Tab = {
  ...members,
  title: 'Members',
  description:
    'Manage the members of your organization and projects. Administrators can grant and revoke access.',
  headings: [
    {
      title: 'Members',
      path: 'members',
      description:
        'Manage the members of your organization and projects. Administrators can grant and revoke access.',
      isRoot: true,
      component: OrganizationMembersView,
      permissions: [{permissionName: 'sanity.organization.members', grantName: 'read'}],
    },
    {
      title: 'Invitations',
      path: 'invitations',
      description:
        'Invitations to the organization and its projects that have been sent, but not accepted yet.',

      component: OrganizationMembersView,
      permissions: [{permissionName: 'sanity.organization.members', grantName: 'invite'}],
    },
    {
      title: 'Requests',
      description:
        "Users requesting access to the organization or projects. You can set the user's role before approving a request",
      path: 'requests',
      referenceLinkComponent: OrganizationAccessRequestSettingsLink,
      component: OrganizationMembersView,
      permissions: [{permissionName: 'sanity.organization.members', grantName: 'read'}],
    },
  ],
  permissions: [{permissionName: 'sanity.organization.members', grantName: 'read'}],
}

const billing: Tab = {
  title: 'Billing',
  icon: BillIcon,
  path: 'billing',
  permissions: [{permissionName: 'sanity.organization', grantName: 'billing'}],
  description:
    'An overview of all total costs on the organization. Breakdown of plan costs and overages per project. All costs are billed monthly.',
  referenceLink: {
    label: 'Learn more about plans and payments in Sanity Docs',
    url: `https://www.${process.env.host}/docs/plans-and-payments`,
  },
  headings: [
    {
      title: 'Costs',
      path: 'costs',
      isRoot: true,
      component: OrgBilling,
    },
    {
      title: 'Invoices',
      path: 'invoices',
      component: OrgInvoices,
      description: 'Download previous invoices.',
      referenceLink: {
        label: 'Learn more about plans and payments in Sanity Docs',
        url: `https://www.${process.env.host}/docs/plans-and-payments`,
      },
    },
  ],
}

function getOverviewTab(featureFlags?: {members_v2?: boolean}): Tab {
  return {
    ...overviewTab,
    component: (props) => OrgOverview({...props, membersV2: featureFlags?.members_v2}),
  }
}

function getSettingsTab(featureFlags?: {members_v2?: boolean; dashboard_rollout?: boolean}): Tab {
  const mergedSettingsTab: Tab = {
    ...settingsTab,
    component: Settings,
    permissions: {
      permissions: [
        {permissionName: 'sanity.organization', grantName: 'update'},
        {permissionName: 'sanity.organization', grantName: 'billing'},
      ],
      allowPartialPermissions: true,
    },
    headings: [
      {
        title: 'General settings',
        path: 'general',
        isRoot: true,
        description: 'Name your organization and set important contact information.',
        permissions: [{permissionName: 'sanity.organization', grantName: 'update'}],
      },
      {
        title: 'Billing',
        path: 'billing',
        description: 'Settings regarding billing and payments.',
        permissions: [{permissionName: 'sanity.organization', grantName: 'billing'}],
      },
      {
        title: 'SAML SSO',
        path: 'saml',
        pathLabel: 'Open SAML SSO configuration',
        component: SsoSAML,
        description: 'Configure your SAML SSO integration.',
        permissions: [{permissionName: 'sanity.organization', grantName: 'billing'}],
        disableTabButtonRender: ({org, scope}): boolean => {
          if (!org?.features || !scope || scope !== 'org') {
            return false
          }

          return org.features.includes('authSAML') === false
        },
      },
      {
        title: 'Danger zone',
        path: 'danger-zone',
        description: 'Destructive organization actions.',
      },
    ],
    sections: [
      {
        title: 'Organization details',
        isRoot: true,
        path: 'org-details',
        heading: 'general',
        component: OrgDetails,
      },
      {
        title: 'Contacts',
        path: 'contacts',
        heading: 'general',
        component: ContactDetails,
      },
      {
        title: 'Access requests',
        isRoot: true,
        path: 'access-requests',
        heading: 'general',
        component: OrgAccessRequestSettings,
      },
      {
        title: 'Billing address',
        description: 'Add postal address to every invoice.',
        isRoot: true,
        path: 'billing-address',
        heading: 'billing',
        component: BillingDetails,
      },
      {
        title: 'Payment method',
        description:
          'Charges from all projects within the organization will be deducted from the card shown below.',
        path: 'payment',
        heading: 'billing',
        component: PaymentMethod,
      },
      {
        title: 'Delete organization',
        description:
          'Delete your organization. You must first delete or remove all projects from this organization.',
        isRoot: true,
        path: 'delete',
        heading: 'danger-zone',
        component: DeleteOrg,
      },
    ],
  }

  const orgDetailsIndex = mergedSettingsTab.sections?.findIndex(
    (section) => section.path === 'org-details'
  )
  if (orgDetailsIndex !== undefined && orgDetailsIndex !== -1 && featureFlags?.members_v2) {
    mergedSettingsTab.sections?.splice(orgDetailsIndex + 1, 0, {
      title: 'Default organization role',
      description:
        'The default organization role that new members will be assigned when they join a project belonging to the organization without being an organization member.',
      isRoot: true,
      path: 'default-role',
      heading: 'general',
      component: OrgDefaultRoleSetting,
    })
  }

  if (featureFlags?.dashboard_rollout) {
    // Add dashboard settings to the settings tab in second position but don't remove anything
    mergedSettingsTab.sections?.splice(1, 0, {
      title: 'Dashboard Settings',
      path: 'dashboard-settings',
      heading: 'general',
      component: DashboardSettings,
      hideFromSidebar: !featureFlags?.dashboard_rollout,
    })
  }

  return mergedSettingsTab
}

interface GetCommonTabsValue {
  overview: Tab
  projects: Tab
  members: Tab
  activity: Tab
  billing: Tab
  settings: Tab
}

export function getCommonTabs(opts?: GetOrgTabsOptions): GetCommonTabsValue {
  const {featureFlags} = opts || {}

  return {
    overview: getOverviewTab(featureFlags),
    projects,
    members: featureFlags?.members_v2 ? members_v2 : members,
    activity,
    billing,
    settings: getSettingsTab(featureFlags),
  }
}
