import React, {useCallback, useMemo} from 'react'
import {Box, Text, Flex, useTheme, Tooltip, TooltipProps} from '@sanity/ui'
import {format, isThisYear, parseISO} from 'date-fns'
import styled, {css} from 'styled-components'
import {Icon} from '@sanity/icons'
import {flatten} from 'lodash'
import {nameToInitials} from '../../../members/utils'
import {AvatarWithFallback} from '@/ui/index'
import {useCurrentScopeContext, useProjectHasFeature, useSetFilter} from '@/context/index'
import {ActivityEvent, FilterPayload} from '@/types/models/activity'
import {AppLink} from '@/ui/app'
import {Project, User} from '@/types/models'
import {useUser} from '@/data/users'
import {ProjectIcon} from '@/components/project/projectIcon'
import {getProjectPath} from '@/utils/getProjectPath'

const avatarStyles = {
  height: '20px',
  width: '20px',
  overflow: 'hidden',
}

const HoverStyle = css`
  @media (hover: hover) {
    &:hover {
      color: ${({theme}) => theme.sanity.color.muted.transparent.hovered.fg};
    }
  }
`

const StyledAppLink = styled.a`
  color: ${({theme}) => theme.sanity.color.muted.transparent.enabled.fg};
  text-decoration: none;
  ${HoverStyle}
`

const StyledText = styled(Text)`
  color: ${({theme}) => theme.sanity.color.muted.transparent.enabled.fg};
  ${HoverStyle}
`

const Button = styled.button`
  border: 0;
  background: 0;

  &:disabled {
    cursor: inherit;
  }

  &:focus {
    outline: -webkit-focus-ring-color auto 1px;
    outline-offset: -1px;
  }

  &:focus:not(:focus-visible) {
    outline: 0;
  }

  color: ${({theme}) => theme.sanity.color.muted.transparent.enabled.fg};
  &:not(:disabled) {
    ${HoverStyle}
  }
`

const AvatarWrapper = styled.div`
  & [data-ui='Text'] {
    font-size: 8px;
  }
  & [data-ui='Avatar'] svg {
    height: 100%;
    width: 100%;
  }
`

type DetailLayoutProps = {
  title: React.ReactNode
  children?: React.ReactNode
  compact?: boolean
}

const DetailTooltip = ({
  profile,
  disabled,
  children,
  ...rest
}: {
  profile: {title: string; media: React.ReactNode}
  disabled: boolean
  children: React.ReactElement
} & TooltipProps) => {
  return (
    <Tooltip
      open
      content={
        <Box style={{maxWidth: 260}}>
          <Box padding={2}>
            <Flex wrap="wrap" align="center">
              <Box>{profile.media}</Box>
              <Box flex={1} paddingLeft={2}>
                <Text size={1} weight="semibold">
                  {profile.title}
                </Text>
              </Box>
            </Flex>
          </Box>
        </Box>
      }
      disabled={disabled}
      {...rest}
    >
      {children}
    </Tooltip>
  )
}

const DetailLayout = ({title, children, compact = false}: DetailLayoutProps) => {
  return (
    <Flex align="center" data-activity-project data-type={title} paddingY={1} style={{height: 30}}>
      {children && <Box marginRight={compact ? 0 : 2}>{children}</Box>}
      <>
        {!compact && (
          <>
            {typeof title === 'string' ? (
              <StyledText
                weight="semibold"
                size={1}
                textOverflow="ellipsis"
                style={{maxWidth: '100%'}}
              >
                {title}
              </StyledText>
            ) : (
              title
            )}
          </>
        )}
      </>
    </Flex>
  )
}

const MemberAvatar = ({
  user,
  name,
  compact = false,
}: {
  user: User | undefined
  name: string
  compact?: boolean
}) => {
  const displayName = user?.displayName || name || 'Unknown'
  const isRobot = displayName.includes('(Robot)')
  const avatar = (
    <AvatarWrapper>
      <AvatarWithFallback
        src={user?.imageUrl || undefined}
        initials={nameToInitials(displayName)}
        size={0}
        style={{
          ...avatarStyles,
          padding: isRobot || user ? 'initial' : '3px',
        }}
        robot={isRobot}
      />
    </AvatarWrapper>
  )
  return (
    <DetailTooltip
      disabled={!compact}
      profile={{
        title: displayName,
        media: avatar,
      }}
    >
      <div>
        <DetailLayout title={displayName} compact={compact} data-activity-member>
          {avatar}
        </DetailLayout>
      </div>
    </DetailTooltip>
  )
}

const Timestamp = ({timestamp}: {timestamp: string}) => {
  const parsedDate = parseISO(timestamp)
  const month = `${format(parsedDate, 'MMM d')}${
    isThisYear(parsedDate) ? '' : ` ${format(parsedDate, 'y')}`
  }`
  const time = format(parsedDate, 'p')

  return (
    <DetailLayout
      title={
        <StyledText>
          <Flex align="center" as="time" dateTime={timestamp} style={{minWidth: '120px'}}>
            <Text weight="semibold" size={1} muted style={{color: 'inherit'}}>
              {month}
            </Text>
            &nbsp;
            <Text size={1} muted style={{color: 'inherit'}}>
              at {time}
            </Text>
          </Flex>
        </StyledText>
      }
    />
  )
}

export const ActivityDetail = ({
  type,
  value,
  compact = false,
  marginRight,
  disableFilter,
  event,
}: {
  type: string
  value: string
  compact?: boolean
  marginRight?: number
  disableFilter?: boolean
  event: ActivityEvent
}) => {
  const setFilter = useSetFilter()
  const {scope, orgs = []} = useCurrentScopeContext()
  const projects: Project[] = useMemo(() => flatten(orgs.map((o) => o.projects)), [orgs])

  const theme = useTheme()
  const red = theme.sanity.color.spot.red

  const project = projects.find((p) => p.id === value)

  const hasActivityAccess = useProjectHasFeature(project, 'activityFeed', true)
  //Check if the project has activity feed enabled
  const prjActivityEnabled = project?.activityFeedEnabled && hasActivityAccess

  const handleOnClick = useCallback(
    (payload: FilterPayload) => {
      setFilter(payload)
    },
    [setFilter]
  )

  const {data: user} = useUser(type === 'actorId' && value !== 'sanity-system' ? value : undefined)

  if (type === 'actorId') {
    // Render a system avatar if the actor was an internal sanity system action
    if (value === 'sanity-system') {
      const avatar = (
        <AvatarWithFallback
          size={0}
          fallback
          style={{...avatarStyles, padding: '3px', background: red}}
        />
      )
      return (
        <DetailTooltip
          disabled={!compact}
          profile={{
            title: 'Sanity System',
            media: avatar,
          }}
        >
          <div>
            <Box
              marginRight={marginRight}
              as={Button}
              disabled={disableFilter}
              onClick={() => handleOnClick({type, payload: [value]})}
            >
              <DetailLayout title="Sanity System" compact={compact} data-sanity-system>
                {avatar}
              </DetailLayout>
            </Box>
          </div>
        </DetailTooltip>
      )
    }
    return (
      <Box
        marginRight={marginRight}
        as={Button}
        disabled={disableFilter}
        onClick={() => handleOnClick({type, payload: [value]})}
      >
        <MemberAvatar compact={compact} name={value} user={user || undefined} />
      </Box>
    )
  }

  if (type === 'timestamp') {
    return (
      <Box
        marginRight={marginRight}
        as={Button}
        disabled={disableFilter}
        onClick={() => handleOnClick({type: 'startTime', payload: value})}
      >
        <Timestamp timestamp={value} />
      </Box>
    )
  }

  //Check if the org has any projects with activity feed enabled
  const orgHasProjectsWithActivityEnabled =
    projects?.filter((p) => p?.organizationId === value)?.filter((p) => p?.activityFeedEnabled)
      .length > 0

  // Only render project info if we are in the global, org, or user scope
  if (['global', 'org', 'user'].includes(scope || '') && type === 'projectId' && project) {
    // If activity feed is enabled, link to activity, else to overview
    const href = getProjectPath(project) + (prjActivityEnabled ? '/activity' : '')

    return (
      <DetailTooltip
        disabled={!compact}
        profile={{
          title: project?.displayName || event?.projectDisplayName,
          media: <ProjectIcon project={project} size={0} />,
        }}
      >
        <Box marginRight={marginRight}>
          <StyledAppLink as={AppLink} href={href}>
            <DetailLayout
              title={project?.displayName || event?.projectDisplayName}
              compact={compact}
              data-activity-project
            >
              <ProjectIcon project={project} size={0} />
            </DetailLayout>
          </StyledAppLink>
        </Box>
      </DetailTooltip>
    )
  }

  // Only render org info if we are in the global or user scope
  if (['global', 'user'].includes(scope || '') && type === 'organizationId') {
    //If there is projects with activity feed enabled, link to activity, else to overview
    const href = `/organizations/${value}/${orgHasProjectsWithActivityEnabled ? 'activity' : ''}`
    return (
      <DetailTooltip
        disabled={!compact}
        profile={{
          title: event?.organizationDisplayName,
          media: <AvatarWithFallback size={0} fallback style={{...avatarStyles, padding: '3px'}} />,
        }}
      >
        <Box marginRight={marginRight}>
          <StyledAppLink href={href}>
            <DetailLayout
              title={event?.organizationDisplayName}
              compact={compact}
              data-activity-project
            >
              <AvatarWithFallback size={0} fallback style={{...avatarStyles, padding: '3px'}} />
            </DetailLayout>
          </StyledAppLink>
        </Box>
      </DetailTooltip>
    )
  }

  if (type === 'dataset') {
    return (
      <DetailTooltip
        disabled={!compact}
        profile={{
          title: event.datasetName,
          media: (
            <Box padding={1}>
              <Text muted size={1}>
                <Icon symbol="database" />
              </Text>
            </Box>
          ),
        }}
      >
        <div>
          <Box
            marginRight={marginRight}
            as={Button}
            disabled={disableFilter}
            onClick={() => handleOnClick({type: 'datasetName', payload: value})}
          >
            <DetailLayout title={value} compact={compact}>
              <Text muted size={1}>
                <Icon symbol="database" />
              </Text>
            </DetailLayout>
          </Box>
        </div>
      </DetailTooltip>
    )
  }

  return null
}
