import {CardTone} from '@sanity/ui'
import {Organization, Project, ProjectMember} from '..'
import {type GetuserPermissionsResponse} from '@sanity/access-api'
import {CurrentUser} from './user'
import {OrganizationPaymentState} from '@/types/models/paymentstate'

export * from './user'
export * from './project'
export * from './organization'
export * from './subscription'
export * from './invitation'
export * from './usage'
export * from './activity'

export type CurrentScope = {
  loading: boolean
  isLoadingOrgs: boolean
  isLoadingProjects: boolean
  isLoadingPermissions: boolean
  currentOrgId: string | undefined
  currentProjectId: string | undefined
  currentProjectFeatures: string[]
  currentUserPermissions: GetuserPermissionsResponse
  org: Organization | undefined
  orgs: Organization[]
  project: Project | undefined
  projectId: string | undefined
  scope: 'global' | 'user' | 'org' | 'project' | undefined
  currentUser: CurrentUser | undefined
  currentPaymentState: OrganizationPaymentState[]
  currentBillingAdminStatus: Set<string>
}

export type CorsOrigin = {
  id: string
  origin: string
  allowCredentials: boolean
  createdAt: string
  projectId: string
}

export type DatasetAclMode = 'public' | 'private' | 'custom'

export const DatasetFeatureSkipBilling = 'skipBilling'
export const DatasetFeatureComments = 'documentComments'
export const DatasetFeatureCrossDatasetReferences = 'crossDatasetReferences'
export type DatasetFeature =
  | typeof DatasetFeatureComments
  | typeof DatasetFeatureCrossDatasetReferences
  | typeof DatasetFeatureSkipBilling

export type DatasetTag = {name: string; title: string}

export type Dataset = {
  name: string
  aclMode?: DatasetAclMode
  createdAt?: string
  createdByUserId?: string | null
  datasetProfile?: string
  isDeleted?: boolean
  addonFor?: string | null
  tags?: DatasetTag[]
  features?: DatasetFeature[]
  stats?: {
    documents: {
      count: {
        value: number
        limit: number
        unit: 'number'
      }
      sizeSum: {
        value: number
        limit: number
        unit: 'byte'
      }
      jsonSizeSum: {
        value: number
        limit: number
        unit: 'byte'
      }
    }
    fields: {
      count: {
        value: number
        limit: number
        unit: 'number'
      }
    }
    types: {
      count: {
        value: number
        limit: number
        unit: 'number'
      }
    }
  }
}

export type Tag = {
  name: string
  title: string
  description: string
  metadata: {
    tone?: CardTone
  }
  datasets?: Dataset[]
  isDeleted?: boolean
  previousName?: string
}

export type Webhook = {
  id: string
  name: string
  dataset: string
  url: string
  createdByUserId: string
  createdAt: string
}

export type DocumentWebhook = Webhook & {
  type: 'document'
  rule: {
    on: Array<'create' | 'update' | 'delete'>
    filter?: string
    projection?: string
  } | null
  description: string | null
  apiVersion: string
  httpMethod: string
  includeDrafts: boolean
  headers: Record<string, string> | null
  secret: string | null
  isDisabled: boolean
  isDisabledByUser: boolean
}

/**
 * Fields of a webhook which can be edited. We've marked most of them as required
 * as we prefer to update all of them at the same time (as opposed to doing partial updates).
 */
export type DocumentWebhookFields = Pick<
  DocumentWebhook,
  | 'name'
  | 'description'
  | 'dataset'
  | 'rule'
  | 'url'
  | 'httpMethod'
  | 'apiVersion'
  | 'includeDrafts'
  | 'secret'
  | 'headers'
> & {isDisabledByUser?: boolean}

export type Token = {
  id: string
  label: string
  key?: string // Only there for newly created tokens
  projectUserId: string
  createdAt: string
  roles: {title: string; name: string}[]
}

export type TokenRoleDescription = {
  title: string
  description: string
  value: string
  requiresFeature?: string
}

export type SearchableData = ProjectMember | Organization | Project | Webhook | Dataset | any

export type GeoIp = {
  ip: string
  isoCode: string
  name: string
}
