import {PermissionResource, PermissionResourceSchema, ProjectMemberRole} from '../../types/models'
import {
  getProjectRoles,
  getProjectPermissionResources,
  getProjectPermissionResourceSchemas,
  getProjectTags,
} from './controller'
import {useQuery} from '@tanstack/react-query'
import {
  getProjectPermissionResourceSchemasKey,
  getProjectPermissionResourcesKey,
  getProjectRolesKey,
  getProjectTagsKey,
  STALE_TTL,
} from './cache'
import {useRequiredProjectPermissions, useRequiredOrganizationPermissions} from '../../context'
export function useProjectRoles(projectId: string) {
  const canReadProjectRoles = useRequiredProjectPermissions(
    [{permissionName: 'sanity.project.roles', grantName: 'read'}],
    projectId
  )

  return useQuery<ProjectMemberRole[]>({
    queryKey: getProjectRolesKey(projectId),
    queryFn: () => getProjectRoles(projectId),
    staleTime: STALE_TTL,
    enabled: !!projectId && canReadProjectRoles,
  })
}

export function useProjectPermissionResources(projectId: string) {
  const canReadProjectPermissionResources = useRequiredProjectPermissions(
    [{permissionName: 'sanity.project.roles', grantName: 'read'}],
    projectId
  )

  const canReadOrganizationProjects = useRequiredOrganizationPermissions([
    {permissionName: 'sanity.organization.projects', grantName: 'read'},
  ])

  return useQuery<PermissionResource[]>({
    queryKey: getProjectPermissionResourcesKey(projectId),
    queryFn: () => getProjectPermissionResources(projectId),
    staleTime: STALE_TTL,
    enabled: !!projectId && (canReadProjectPermissionResources || canReadOrganizationProjects),
  })
}

export function useProjectPermissionResourceSchemas(projectId: string) {
  return useQuery<PermissionResourceSchema[]>({
    queryKey: getProjectPermissionResourceSchemasKey(projectId),
    queryFn: () => getProjectPermissionResourceSchemas(projectId),
    staleTime: STALE_TTL,
  })
}

export function useProjectTags(projectId: string) {
  return useQuery({
    queryKey: getProjectTagsKey(projectId),
    queryFn: () => getProjectTags(projectId),
    staleTime: STALE_TTL,
  })
}
