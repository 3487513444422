import React from 'react'
import {BaseBadge} from './helpers/baseBadge'
import {usePlanBadgeVariant} from './helpers/usePlanBadgeVariant'
import {PlanTypeNames} from '@/types/models'

export function PlanBadge({title}: {title: PlanTypeNames | string}) {
  const variant = usePlanBadgeVariant(title)
  return <BaseBadge $variant={variant}>{title}</BaseBadge>
}
PlanBadge.displayName = 'PlanBadge'
