import {useCurrentScopeContext} from '@/context/index'

import {useProjectSubscription} from '@/data/projects/useProjectSubscription'
import {sendAmplitudeTrackingEvent} from '@/utils/tracking'
import {Stack, useToast} from '@sanity/ui'
import React, {useCallback, useEffect} from 'react'
import posthog from 'posthog-js'
import {useSwitchPlanStore} from './store'
import {PlanInfoCards} from './components/planInfoCards'
import {DowngradeSurvey} from './downgradeSurvey'
import {DowngradeSurveyFormData} from './types'
import {DowngradeOverageAlert} from './components/downgradeOverageAlert'
import {useUsageOverages} from './hooks/useUsageOverages'
import {Dialog} from '../general'

export function DowngradeDialog() {
  const {dispatch, state} = useSwitchPlanStore()
  const toast = useToast()
  const scope = useCurrentScopeContext()
  const {org, project, projectId} = useCurrentScopeContext()
  const {data: plan, refetch: refetchPlan} = useProjectSubscription(projectId)
  // Metered overages not checked as we currently always allow downgrades in these cases
  const {overages} = useUsageOverages(scope!.project!, state!.plan!)

  const skipSurvey = plan?.plan.planTypeId === 'free'

  const closeDialog = useCallback(() => {
    dispatch({type: 'switchPlan/close'})
  }, [dispatch, posthog])

  /* Send event when downgrade form loaded */
  useEffect(() => {
    sendAmplitudeTrackingEvent('Change Plan Flow Initiated', projectId, org?.id, {
      currentPlan: plan?.planId,
      targetPlan: state?.plan?.id,
      planIsIncompatible: Boolean(overages?.length),
      incompatibilityType: overages?.map((o) => o.id),
      newFlow: true,
      current_path: window.location.href,
    })
    posthog.capture('Downgrade Plan Flow Initiated')
  }, [projectId, org?.id, state?.plan?.id])

  const handleSubmitError = useCallback(
    (title: string, message: string) => {
      toast.push({
        status: 'error',
        title,
        description: message,
        closable: true,
        duration: 10000, // 10 seconds
      })
    },
    [toast]
  )

  /* If the plan is the same as the current plan, don't show the dialog */
  if (plan?.planId === state?.plan?.id) {
    return null
  }

  const onSuccess = async (formData: DowngradeSurveyFormData) => {
    if (formData.reason !== 'skip-survey') {
      sendAmplitudeTrackingEvent('Plan Downgraded', projectId, org?.id, {
        userId: scope?.currentUser?.id,
        projectName: project?.displayName,
        orgName: org?.name,
        currentPlan: plan?.planId,
        targetPlan: state?.plan?.id,
        downgradeReason: formData.reason,
        downgrade_reason_followup: formData.comment,
      })
    }
    refetchPlan()
    closeDialog()
  }

  return (
    <Dialog
      header={
        skipSurvey ? `Switch to ${state.plan?.name} plan` : `Downgrade to ${state.plan?.name} plan`
      }
      id="checkout"
      onClose={closeDialog}
      onClickOutside={closeDialog}
      width={1}
    >
      <Stack space={5} padding={4} paddingTop={2}>
        <DowngradeOverageAlert blockingOverages={overages} />
        <PlanInfoCards currentPlan={plan!.plan} targetPlan={state.plan!} />
        <DowngradeSurvey
          onSubmit={onSuccess}
          onSubmitError={handleSubmitError}
          onClose={closeDialog}
          skipSurvey={skipSurvey}
        />
      </Stack>
    </Dialog>
  )
}
