import {Icon} from '@sanity/icons'
import {Box, Flex, Stack, Text} from '@sanity/ui'
import styled from 'styled-components'

import {type PermissionTooltipProps} from './PermissionTooltip'

const Root = styled(Box)`
  max-width: 250px;
`

type PermissionTooltipContentProps = Pick<PermissionTooltipProps, 'description' | 'icon' | 'title'>

export function PermissionTooltipContent(props: PermissionTooltipContentProps) {
  const {description, icon, title} = props

  return (
    <Root padding={2} sizing="border">
      <Flex gap={3}>
        {icon && title && (
          <Box>
            <Text size={1} muted>
              <Icon symbol={icon} />
            </Text>
          </Box>
        )}

        <Stack space={3} flex={1}>
          {title && (
            <Flex>
              <Box>
                {typeof title === 'string' ? (
                  <Text as="h2" size={1} weight="semibold">
                    {title}
                  </Text>
                ) : (
                  title
                )}
              </Box>
            </Flex>
          )}

          {description && (
            <Text as="p" size={1} muted>
              {description}
            </Text>
          )}
        </Stack>
      </Flex>
    </Root>
  )
}
