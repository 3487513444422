import {useMutation} from '@tanstack/react-query'
import {acceptInvitation} from './controller'
import {useCacheInvalidation} from '@/data/base/useCacheInvalidation'

interface AcceptInvitationVariables {
  token: string
  version?: string
}

export function useAcceptInvitation({
  projectId,
  organizationId,
}: {
  projectId?: string
  organizationId?: string
}) {
  const {invalidateUserOnboardingData} = useCacheInvalidation()
  return useMutation({
    mutationKey: ['acceptInvitation', projectId ?? organizationId],
    mutationFn: ({token, version}: AcceptInvitationVariables) => acceptInvitation(token, version),
    onSuccess: () => {
      if (projectId) {
        invalidateUserOnboardingData()
      }
    },
    onError: (error) => {
      console.error('Failed to accept invitation:', error)
    },
  })
}
