import {
  type DeclineRequestData,
  type GetInvitesResponse,
  type GetUsersResponse,
  type Invite,
  type Request,
  type User,
} from '@sanity/access-api'
import {type InfiniteData} from '@tanstack/react-query'
import {
  type ResourceMembershipAddPayload,
  type ResourceMembershipRemovePayload,
  type ResourceRoleAddPayload,
  type ResourceRoleRemovePayload,
} from '@ui-components'

import {
  type InvitationRevokePayload,
  type InvitationsCreatePayload,
  type RequestApprovePayload,
} from './types'

export function invitationsCreate(
  prev: InfiniteData<GetInvitesResponse>,
  payload: InvitationsCreatePayload
): InfiniteData<GetInvitesResponse> {
  const {currentUserId, invites} = payload

  const createdAt = new Date().toISOString()

  // We do not want to send create invites for members that are already in the organization.
  const nonOrgMembers = invites.filter((i) => !i.invitee.memberId)

  const newInvites: Invite[] = nonOrgMembers.map((i, index) => ({
    createdAt,
    updatedAt: '',
    email: i.invitee.email,
    // This is only used when the invite is in an optimistic state.
    // The id will be overwritten when the server responds with the created invite.
    id: `${i.invitee.email}-${index}`,
    role: i.roleName,
    inviterType: 'user',
    resourceId: i.resourceId,
    resourceType: i.resourceType,
    status: 'pending',
    // Since this is an optimistic update, the `inviterId` is not
    // immediately available when creating the invite. This is available
    // first when the server responds with the created invite.
    // To avoid showing an unknown user as the inviter, we set the
    // `inviterId` to the current user's id.
    inviterId: currentUserId,
  }))

  return {
    ...prev,
    pages: prev.pages.map((page) => {
      return {
        ...page,
        data: [...(page.data || []), ...newInvites],
      }
    }),
  }
}

export function invitationRevoke(
  prev: InfiniteData<GetInvitesResponse>,
  payload: InvitationRevokePayload
): InfiniteData<GetInvitesResponse> {
  const {
    path: {inviteId, resourceId, resourceType},
  } = payload

  const filterInvite = (invite: Invite) => {
    return (
      invite.id !== inviteId ||
      invite.resourceId !== resourceId ||
      invite.resourceType !== resourceType
    )
  }

  return {
    ...prev,
    pages: prev.pages.map((page) => {
      return {
        ...page,
        data: (page.data || []).filter(filterInvite),
      }
    }),
  }
}

export function resourceMembershipAdd(
  prev: InfiniteData<GetUsersResponse>,
  payload: ResourceMembershipAddPayload
): InfiniteData<GetUsersResponse> {
  const {memberId, resourceId, resourceType, roleName} = payload

  const insertResourceMembership = (member: User) => {
    if (member.profile?.id === memberId) {
      return {
        ...member,
        memberships: member.memberships?.concat({
          resourceId,
          resourceType,
          roleNames: [roleName],
        }),
      }
    }

    return member
  }

  return {
    ...prev,
    pages: prev.pages.map((page) => {
      return {
        ...page,
        data: (page.data || []).map(insertResourceMembership),
      }
    }),
  }
}

export function resourceRoleAdd(
  prev: InfiniteData<GetUsersResponse>,
  payload: ResourceRoleAddPayload
): InfiniteData<GetUsersResponse> {
  const {memberId, resourceId, resourceType, roleName} = payload

  const addRoleToMember = (member: User) => {
    if (member.profile?.id === memberId) {
      const hasMembership = member.memberships?.some(
        (membership) =>
          membership.resourceId === resourceId && membership.resourceType === resourceType
      )

      // If the member gets a role added in a resource they are not a member of, we need to
      // add the resource with the role to the member.
      if (!hasMembership) {
        return {
          ...member,
          memberships: (member.memberships ?? []).concat({
            resourceId,
            resourceType,
            roleNames: [roleName],
          }),
        }
      }

      // If the member already has a membership for the resource, we add the role to the membership.
      return {
        ...member,
        memberships: member.memberships?.map((membership) => {
          if (membership.resourceId === resourceId && membership.resourceType === resourceType) {
            return {
              ...membership,
              roleNames: (membership.roleNames || []).concat(roleName),
            }
          }
          return membership
        }),
      }
    }

    return member
  }

  return {
    ...prev,
    pages: prev.pages.map((page) => {
      return {
        ...page,
        data: (page.data || []).map(addRoleToMember),
      }
    }),
  }
}

export function resourceRoleRemove(
  prev: InfiniteData<GetUsersResponse>,
  payload: ResourceRoleRemovePayload
): InfiniteData<GetUsersResponse> {
  const {memberId, resourceId, resourceType, roleName} = payload

  const removeRoleFromMember = (member: User) => {
    if (member.profile?.id === memberId) {
      return {
        ...member,
        memberships: member.memberships?.map((membership) => {
          if (membership.resourceId === resourceId && membership.resourceType === resourceType) {
            return {
              ...membership,
              roleNames: (membership.roleNames || []).filter((role) => role !== roleName),
            }
          }

          return membership
        }),
      }
    }

    return member
  }

  return {
    ...prev,
    pages: prev.pages.map((page) => {
      return {
        ...page,
        data: (page.data || []).map(removeRoleFromMember),
      }
    }),
  }
}

export function resourceMembershipRemove(
  prev: InfiniteData<GetUsersResponse>,
  payload: ResourceMembershipRemovePayload
): InfiniteData<GetUsersResponse> {
  const {memberId, resourceId, resourceType} = payload

  const removeResourceMembership = (member: User) => {
    if (member.profile?.id === memberId) {
      return {
        ...member,
        memberships: member.memberships?.filter(
          (membership) =>
            membership.resourceId !== resourceId || membership.resourceType !== resourceType
        ),
      }
    }

    return member
  }

  return {
    ...prev,
    pages: prev.pages.map((page) => {
      return {
        ...page,
        data: (page.data || []).map(removeResourceMembership),
      }
    }),
  }
}

export function requestApprove(prev: Request[], payload: RequestApprovePayload) {
  const {
    path: {requestId, resourceId, resourceType},
  } = payload

  return prev.filter((request) => {
    return (
      request.id !== requestId ||
      request.resourceId !== resourceId ||
      request.resourceType !== resourceType
    )
  })
}

export function requestRevoke(prev: Request[], payload: Pick<DeclineRequestData, 'path'>) {
  const {
    path: {requestId, resourceId, resourceType},
  } = payload

  return prev.filter((request) => {
    return (
      request.id !== requestId ||
      request.resourceId !== resourceId ||
      request.resourceType !== resourceType
    )
  })
}
