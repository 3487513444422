import React, {type ReactNode} from 'react'

import {renderList} from '../../utils'
import {type AppliedFilters} from './components/Filters'
import {type TableViews} from './MemberTablesView'

interface GetTableHeadingCopyProps {
  selectedView: TableViews
  tableFilters: AppliedFilters | null
  resultLen: number
  searchQuery?: string
}

function getFilteredHeadingCopy(props: {
  itemCount: number
  itemType: string
  filters: AppliedFilters | null
  searchQuery?: string
}): ReactNode {
  const {itemCount, itemType, filters, searchQuery} = props
  const {filter, project, projectRoles = [], roles = []} = filters ?? {}

  const itemText = itemCount === 1 ? itemType : `${itemType}s`
  const projectRolesText = projectRoles.length === 1 ? 'role' : 'roles'
  const resourceRolesText = roles.length === 1 ? 'role' : 'roles'

  let text: ReactNode = (
    <>
      <b>{itemCount}</b> {itemText} {searchQuery ? 'found' : ''}
    </>
  )

  if (filter === 'project-access' && project && projectRoles.length === 0) {
    text = (
      <>
        {itemCount ? <b>{itemCount}</b> : 'No'} {itemText} with access to{' '}
        <b>{project.displayName}</b> project found
      </>
    )
  }

  if (filter === 'project-access' && project && projectRoles.length > 0) {
    text = (
      <>
        {itemCount ? <b>{itemCount}</b> : 'No'} {itemText} with access to{' '}
        <b>{project.displayName}</b> project with {projectRolesText}{' '}
        {renderList({
          items: projectRoles.map((r) => r.title || r.name || ''),
          conjunction: 'or',
          renderItem: (item) => <b>{item}</b>,
          type: 'element',
        })}{' '}
        found
      </>
    )
  }

  if (filter === 'organization-roles' && roles.length > 0) {
    text = (
      <>
        {itemCount ? <b>{itemCount}</b> : 'No'} {itemText} with organization {resourceRolesText}{' '}
        {renderList({
          items: roles.map((r) => r.title || r.name || ''),
          conjunction: 'or',
          renderItem: (item) => <b>{item}</b>,
          type: 'element',
        })}{' '}
        found
      </>
    )
  }

  if (!filter && itemCount === 0) {
    text = (
      <>
        No {itemText} {searchQuery ? 'found' : ''}
      </>
    )
  }

  return text
}

export function getTableHeadingCopy(props: GetTableHeadingCopyProps): ReactNode {
  const {selectedView, tableFilters, resultLen, searchQuery} = props

  const viewTypeMap: Record<TableViews, string> = {
    members: 'member',
    invitations: 'invitation',
    requests: 'request',
  }

  const itemType = viewTypeMap[selectedView]

  return getFilteredHeadingCopy({
    itemCount: resultLen,
    itemType,
    filters: tableFilters,
    searchQuery,
  })
}
