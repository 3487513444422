import {type Role} from '@sanity/access-api'
import {Card, Text} from '@sanity/ui'
import {useCallback, useMemo} from 'react'

import {type MembersV2} from '@/types/members_v2'

import {BulletList} from '../../generic/bullet-list'
import {renderList} from '../../utils'
import {hasSelectedRoleInResource} from './invite-utils'
import {type InvitationScope, type LocalInvitee} from './types'

interface PromptProps {
  selectedRole: Role
  invitees: LocalInvitee[]
  members: MembersV2['member'][]
  scope: InvitationScope
  resourceId: string
  resourceRoles: Role[]
}

/**
 * A prompt that informs the user about:
 * - Members who are already part of the project or organization and have the selected role
 * - Members who are already part of the project or organization and will have the selected role added
 */
export function Prompt(props: PromptProps) {
  const {resourceId, resourceRoles, selectedRole, invitees, members} = props

  // Filter members belonging to the current resource and are part of the invitation
  const resourceMembers = useMemo(
    () =>
      members.filter(
        (m) =>
          m?.memberships?.some((mem) => mem.resourceId === resourceId) &&
          invitees.find((i) => i.memberId === m.profile?.id)
      ),
    [members, invitees, resourceId]
  )

  // Renders a list item for each member
  const renderItem = useCallback(
    (member: MembersV2['member']) => {
      const displayName = member.profile?.displayName || ''
      const selectedRoleTitle = selectedRole?.title || selectedRole?.name || ''

      // Check if the member already has the selected role in the project or organization
      const hasRole = hasSelectedRoleInResource({
        member,
        resourceId,
        roleName: selectedRole?.name || '',
      })

      // If the member already has the selected role, show a message indicating so
      if (hasRole) {
        return (
          <Text muted size={1}>
            {displayName} already has the <b>{selectedRoleTitle}</b> role. No changes will be made.
          </Text>
        )
      }

      // Get the existing role names for the member in the resource
      const memberRoleNames =
        member?.memberships?.find((mem) => mem.resourceId === resourceId)?.roleNames || []

      // Find the titles of the existing roles
      const existingRoleTitles = memberRoleNames.map((roleName) => {
        const role = resourceRoles?.find((r) => r.name === roleName)
        return role?.title || roleName
      })

      // Render a list of existing roles
      const list = renderList({
        items: existingRoleTitles,
        conjunction: 'and',
        renderItem: (roleTitle) => <b>{roleTitle}</b>,
        type: 'element',
      })

      // Decide if the term 'role' should be plural
      const roleOrRoles = existingRoleTitles.length > 1 ? 'roles' : 'role'

      // Show a message that indicates the selected role will be added to the existing roles
      return (
        <Text muted size={1}>
          {displayName} already has the {list} {roleOrRoles}. The <b>{selectedRoleTitle}</b> role
          will be added.
        </Text>
      )
    },
    [resourceId, resourceRoles, selectedRole?.name, selectedRole?.title]
  )

  if (!resourceMembers.length) return null

  return (
    <Card tone="caution" shadow={1} padding={4} radius={2}>
      <BulletList items={resourceMembers} renderItem={renderItem} />
    </Card>
  )
}
