import React, {createContext, useContext, useState, useEffect, useCallback, useMemo} from 'react'
import {startOfMonth, subMonths, endOfMonth} from 'date-fns'
import {useCurrentScopeContext} from './useCurrentScopeContext'
import {useRoutePath} from './useRoutePathContext'
import {Project, Period, Range} from '@/types/models'
import {exportProjectHistory} from '@/data/resources'

type UsageContextType = {
  projects?: Project[]
  currentRange?: Range
  compareRange?: Range
  currentPeriod?: Period
  setCurrentPeriod?: (range: Period) => void
  setCurrentRange?: (range: Range) => void
  setCompareRange?: (range?: Range) => void
  resetCompare?: () => void
  billingRange?: Range
  setBillingRange?: (range: Range) => void
  usageExportString?: string | undefined
}

export const UsageContext = createContext<UsageContextType>({
  projects: [],
})

const THIS_MONTH = {
  fromDate: startOfMonth(new Date()),
  toDate: new Date(),
}
const LAST_12_MONTHS = {
  fromDate: startOfMonth(subMonths(new Date(), 11)),
  toDate: endOfMonth(new Date()),
}

export const UsageContextProvider = ({children}: {children: React.ReactNode}) => {
  // The from and to date to show usage from
  const [currentRange, setCurrentRange] = useState<undefined | Range>(THIS_MONTH)
  // The period to show the usage
  // Snapshot period is used by overviews and summaries, and represents accumulated usage
  // History period is used by graphs, decides how the usage is split (per day or per month)
  const [currentPeriod, setCurrentPeriod] = useState<undefined | Period>({
    history: 'day',
    snapshot: 'month',
  })
  // States for billing specific usage
  const [billingRange, setBillingRange] = useState<undefined | Range>(LAST_12_MONTHS)

  // States for compare data
  const [compareRange, setCompareRange] = useState<undefined | Range>()

  const {basePath, tabId} = useRoutePath()

  // Data from the scope we are in
  const {project} = useCurrentScopeContext()

  const resetCompare = useCallback(() => {
    setCompareRange(undefined)
  }, [])

  const usageExportString = useMemo(() => {
    if (project) {
      return exportProjectHistory(project.id, {
        ...currentRange,
        period: currentPeriod?.history,
        cumulative: false,
      })
    }
    return undefined
  }, [currentPeriod, currentRange, project])

  // Reset range, period and compare values when switching tabs, orgs, or projects
  useEffect(() => {
    resetCompare()
    setCurrentPeriod({history: 'day', snapshot: 'month'})
    setCurrentRange(THIS_MONTH)
    setBillingRange(LAST_12_MONTHS)
  }, [basePath, tabId, resetCompare])

  const value: UsageContextType = {
    currentRange,
    compareRange,
    setCurrentRange,
    setCompareRange,
    currentPeriod,
    setCurrentPeriod,
    resetCompare,
    usageExportString,
    billingRange,
  }
  return <UsageContext.Provider value={value}>{children}</UsageContext.Provider>
}

export const useUsageContext = () => {
  return useContext(UsageContext)
}
