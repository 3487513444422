/* eslint-disable react/jsx-no-bind */
import {ChevronDownIcon} from '@sanity/icons'
import {
  Box,
  Button,
  ButtonProps,
  Card,
  Menu,
  MenuDivider,
  MenuItem,
  Popover as UIPopover,
  Stack,
  Text,
  useClickOutside,
  useGlobalKeyDown,
} from '@sanity/ui'
import {getYear, startOfMonth, parseISO, format, isAfter, isBefore, getMonth} from 'date-fns'
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import styled from 'styled-components'
import {Header} from './header'
import {Months} from './months'
import {SelectButton} from './selectButton'

type ButtonType = 'month' | 'year' | 'default'

export type MonthInputMenuItem = {
  title: string
  subTitle: string
  onClick: () => void
  disabled?: boolean
  selected?: boolean
  filterDate?: Date
}

type Props = {
  /** Returns the start of the month */
  onChange: (payload: Date) => void
  buttonType?: ButtonType
  buttonProps?: ButtonProps | unknown
  initialDate?: Date
  disabledDates?: Date[]
  menuItems?: MonthInputMenuItem[]
  label?: string
  min?: {
    year: number
    /** The month index, valid values between 0-11 */
    month: number
  }
  max?: {
    year: number
    /** The month index, valid values between 0-11 */
    month: number
  }
}

const Popover = styled(UIPopover)`
  z-index: 9999 !important; //@todo: remove hardcoded z-index value
`

export function MonthInput({
  onChange,
  initialDate,
  menuItems,
  buttonType = 'default',
  buttonProps,
  disabledDates,
  label,
  min,
  max,
}: Props) {
  const [open, setOpen] = useState<boolean>(false)
  const [selectedDate, setSelectedDate] = useState<Date>(
    initialDate ? startOfMonth(initialDate) : startOfMonth(new Date())
  )
  const [date, setDate] = useState<Date>(
    initialDate ? startOfMonth(initialDate) : startOfMonth(new Date())
  )
  const [minDate, setMinDate] = useState<Date>()
  const [maxDate, setMaxDate] = useState<Date>()
  const [popoverEl, setPopoverEl] = useState<HTMLDivElement | null>(null)
  const buttonRef = useRef<HTMLButtonElement | null>(null)
  const stackRef = useRef<HTMLDivElement>()

  //Add default min/max data
  const minYear = min?.year || 1970
  const minMonth = min?.month || 0
  const maxYear = max?.year || getYear(new Date())
  const maxMonth = max?.month || getMonth(new Date())

  const handleOpen = useCallback(() => {
    setOpen(true)
  }, [])
  const handleClose = useCallback(() => {
    setOpen(false)
    if (open) {
      requestAnimationFrame(() => buttonRef?.current?.focus())
    }
  }, [open])

  useClickOutside(() => handleClose(), [popoverEl])

  useGlobalKeyDown((e) => {
    if (e.key === 'Escape' && open) {
      handleClose()
    }
  })

  //Set focus on first focusable element
  useEffect(() => {
    const nodeToFocus = stackRef?.current?.querySelectorAll(
      '[data-disabled="false"]'
    )[0] as HTMLElement

    if (nodeToFocus) {
      requestAnimationFrame(() => nodeToFocus.focus())
    }
  }, [open, stackRef])

  // Change date to browse the calendar
  const handleChangeDate = useCallback((newDate: Date) => {
    setDate(newDate)
  }, [])

  // Select date and return it with onChange
  const handleSelectDate = useCallback(
    (newDate: Date) => {
      handleClose()

      if (maxDate && minDate) {
        const after = isAfter(newDate, maxDate)
        const before = isBefore(newDate, minDate)

        //Return max date if the new date is after max date
        if (after) {
          onChange(startOfMonth(maxDate))
          setSelectedDate(startOfMonth(maxDate))
          setDate(startOfMonth(maxDate))
          return
        }

        //Return min date if the new date is before min date
        if (before) {
          onChange(startOfMonth(minDate))
          setSelectedDate(startOfMonth(minDate))
          setDate(startOfMonth(minDate))
          return
        }

        onChange(startOfMonth(newDate))
        setSelectedDate(startOfMonth(newDate))
        setDate(startOfMonth(newDate))
      }
    },
    [handleClose, maxDate, minDate, onChange]
  )

  //Handle item click in menu
  const handleMenuItemClick = useCallback(
    (callback) => {
      handleClose()
      callback()
    },
    [handleClose]
  )

  //Update states when the initialDate prop changes
  useEffect(() => {
    if (initialDate) {
      setSelectedDate(startOfMonth(initialDate))
      setDate(startOfMonth(initialDate))
    }
  }, [initialDate])

  //Create date objects of the min and max props
  useEffect(() => {
    const formatMonth = (month: number) => (month < 10 ? `0${month}` : month)

    const _minDate = startOfMonth(parseISO(`${minYear}-${formatMonth(minMonth + 1)}-01`))
    const _maxDate = startOfMonth(parseISO(`${maxYear}-${formatMonth(maxMonth + 1)}-01`))
    setMinDate(_minDate)
    setMaxDate(_maxDate)
  }, [minYear, minMonth, maxYear, maxMonth])

  //Get the min month of the current year
  const getMinMonth = useMemo(() => {
    const currYear = getYear(date)
    const checkMonths = currYear === minYear
    return checkMonths ? minMonth : 11
  }, [date, minMonth, minYear])

  //Get the max month of the current year
  const getMaxMonth = useMemo(() => {
    const currYear = getYear(date)
    const checkMonths = currYear === maxYear
    return checkMonths ? maxMonth : 11
  }, [date, maxMonth, maxYear])

  if (!minDate || !maxDate) return null

  return (
    <Popover
      open={open}
      ref={setPopoverEl}
      portal
      content={
        <Stack space={1} ref={stackRef as any}>
          {menuItems && menuItems.length > 0 && (
            <Box>
              <Menu>
                {menuItems.map((item: MonthInputMenuItem) => (
                  <MenuItem
                    key={item.title}
                    onClick={() => handleMenuItemClick(item.onClick)}
                    disabled={item.disabled}
                    selected={item.selected}
                    tone={item.selected ? 'primary' : undefined}
                    padding={3}
                  >
                    <Stack space={2}>
                      {item.title && <Text>{item.title}</Text>}
                      {item.subTitle && (
                        <Text muted size={1}>
                          {item.subTitle}
                        </Text>
                      )}
                    </Stack>
                  </MenuItem>
                ))}
              </Menu>
              <MenuDivider />
            </Box>
          )}

          <Box padding={1} style={{minWidth: 216}}>
            <Stack space={2}>
              {label && (
                <Box marginY={1} marginTop={menuItems ? 1 : 2}>
                  <Text size={1} muted align="center">
                    {label}
                  </Text>
                </Box>
              )}
              <Header date={date} onChange={handleChangeDate} minDate={minDate} maxDate={maxDate} />
              <Months
                date={date}
                selectedDate={selectedDate}
                onChange={handleSelectDate}
                minMonth={getMinMonth}
                maxMonth={getMaxMonth}
                disabledDates={disabledDates}
              />
            </Stack>
          </Box>
        </Stack>
      }
    >
      <Box>
        {(buttonType === 'month' || buttonType === 'year') && (
          <Box style={{minWidth: 200}}>
            <SelectButton
              buttonProps={buttonProps}
              variant={buttonType}
              minMonth={getMinMonth}
              maxMonth={getMaxMonth}
              onClick={handleOpen}
              label={format(selectedDate, 'MMMM Y')}
              date={date}
              onChange={handleSelectDate}
              minDate={minDate}
              maxDate={maxDate}
              ref={buttonRef}
            />
          </Box>
        )}

        {buttonType === 'default' && (
          <Card style={{minWidth: 200}} border radius={2}>
            <Button
              ref={buttonRef}
              onClick={handleOpen}
              text={format(selectedDate, 'MMMM Y')}
              iconRight={ChevronDownIcon}
              mode="bleed"
              style={{width: '100%'}}
              {...(buttonProps as ButtonProps)}
            />
          </Card>
        )}
      </Box>
    </Popover>
  )
}
