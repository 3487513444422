import React from 'react'
import {Stack, Text, Card, Flex} from '@sanity/ui'
import styled from 'styled-components'
import {ErrorTooltip} from './inputs'
import {ThemeFontWeightKey} from '@sanity/ui/theme'

//Reset styling is required when Card is a fieldset
const StyledCard = styled(Card)`
  border: 0;
  padding: 0;
  margin: 0;
  min-width: 0;
  scroll-margin-block: 1rem;
`

type Props = {
  label?: string
  labelWeight?: ThemeFontWeightKey
  labelMuted?: boolean
  description?: string | React.ReactNode
  children?: React.ReactNode
  htmlFor?: string
  as?: 'fieldset'
  error?: string | false
  required?: boolean
  optional?: boolean
  labelSrOnly?: boolean
} & React.ComponentProps<typeof Card>

export const FormField = ({
  label,
  labelWeight,
  labelMuted,
  description,
  children,
  htmlFor,
  as,
  error,
  required,
  optional,
  ...rest
}: Props) => {
  return (
    <StyledCard
      as={as}
      aria-describedby={description ? `description-${htmlFor}` : undefined}
      aria-invalid={!!error}
      {...rest}
    >
      <Stack space={3}>
        <Stack space={3}>
          {label && (
            <Flex align="center">
              <Text
                size={1}
                weight={labelWeight || 'medium'}
                as={as ? 'legend' : 'label'}
                muted={labelMuted || false}
                htmlFor={htmlFor}
              >
                {label}
                {required && <span style={{color: 'red'}}> *</span>}
                {optional && <span style={{color: 'var(--card-muted-fg-color)'}}> (optional)</span>}
              </Text>
              {!!error && <ErrorTooltip message={error} />}
            </Flex>
          )}
          {description && (
            <Text size={1} muted id={`description-${htmlFor}`}>
              {description}
            </Text>
          )}
        </Stack>

        <Stack space={3}>{children}</Stack>
      </Stack>
    </StyledCard>
  )
}

export const FormFieldLike = ({label, labelWeight, labelMuted, description}: Props) => {
  return (
    <Stack space={3}>
      {label && (
        <Flex align="center">
          <Text size={1} weight={labelWeight || 'medium'} as="h3" muted={labelMuted || false}>
            {label}
          </Text>
        </Flex>
      )}
      {description && (
        <Text size={1} muted>
          {description}
        </Text>
      )}
    </Stack>
  )
}
