import {useQueryClient} from '@tanstack/react-query'
import {useCallback} from 'react'

type MutateFunction<T> = (fn: (prev: T) => T) => Promise<T | undefined>

export function useOptimisticUpdate<TData>(queryKey: string[]): MutateFunction<TData> {
  const queryClient = useQueryClient()

  const handleMutate = useCallback<MutateFunction<TData>>(
    async (fn) => {
      // Cancel ongoing queries for the provided key
      await queryClient.cancelQueries({queryKey})

      // Get the current data for the query
      const current = queryClient.getQueryData<TData>(queryKey)
      if (!current) return current // If there's no current data, return undefined

      // Set the new data using the provided mutation function
      queryClient.setQueryData<TData>(queryKey, (old) => {
        if (!old) return old // If old is undefined, do nothing
        return fn(old) // Apply the provided function to mutate the data
      })

      return current
    },
    [queryClient, queryKey]
  )

  return handleMutate
}
