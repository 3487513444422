/* eslint-disable complexity */
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {debounce} from 'lodash'
import router, {useRouter} from 'next/router'
import {
  Stack,
  Box,
  Button,
  TextInput,
  Flex,
  Card,
  MenuButton,
  MenuItem,
  Text,
  Menu,
} from '@sanity/ui'
import {
  AddIcon,
  CheckmarkIcon,
  ChevronDownIcon,
  CloseCircleIcon,
  RemoveCircleIcon,
  SearchIcon,
  UsersIcon,
} from '@sanity/icons'
import {InviteMembersWizard} from '../../wizards/inviteMembers'
import {MEMBERS_TABLE as headers} from './tableConfig'
import {getUserResourceName} from './common'
import {useUsers} from '@/data/users/useUsers'
import {
  Table,
  TableBody,
  TabHeader,
  TableEmpty,
  useConfirmDialog,
  RemoveProjectMembersDialog,
  PermissionButtonProject,
  InfoTooltip,
  ProjectMember,
  ProjectUsagePrompt,
  ProjectUsageStatusTooltip,
  EmptyBlock,
} from '@/ui/index'
import {useWizardStore} from '@/components/payment-wizard'
import {
  OrganizationSSOProvider,
  Project,
  ProjectMember as ProjectMemberType,
  ProjectMemberDecorated,
  ReferenceLink,
  TableRowAction,
  User,
} from '@/types/index'
import {sendAmplitudeTrackingEvent} from '@/utils/tracking'
import {
  useProjectType,
  useCurrentScopeContext,
  useRequiredProjectPermissions,
  useProjectUserRolesList,
} from '@/context/index'

import {useDefaultPlans} from '@/data/plan'
import {useProjectSubscription} from '@/data/projects/useProjectSubscription'
import {useOrganizationCanPay} from '@/data/organizations'
import {useProjectProviders} from '@/data/projects/useProjectProviders'
import {getProjectMemberState} from '@/utils/usage'

import {useWindowVirtualizer} from '@tanstack/react-virtual'
import styled, {css} from 'styled-components'

const TableWrapper = styled.div.attrs<{$height: string; $disabled: boolean}>(({$height}) => ({
  style: {
    height: $height,
  },
}))`
  overflow: auto;
  width: 100%;
  position: relative;

  /* This creates skeleton lines so it doesn't look empty when you scroll longer virtualized lists */
  ${({$disabled}) =>
    !$disabled &&
    css`
      background-image: repeating-linear-gradient(
        to bottom,
        transparent 0px,
        transparent 57px,
        var(--card-border-color) 57px,
        var(--card-border-color) 58px
      );
      background-position: 0 25px;
      background-size: 100% 58px;
    `}
`

export function ProjectMembersTable({
  project,
  description,
  referenceLink,
}: {
  project: Project
  description: string
  referenceLink?: ReferenceLink
}) {
  const parentRef = useRef<HTMLDivElement | null>(null)
  const {query} = useRouter()
  const {org, orgs = []} = useCurrentScopeContext()
  const {data: plans = []} = useDefaultPlans()
  const {data: subscription} = useProjectSubscription(project.id)
  const {roles: projectUserRoles} = useProjectUserRolesList(project.id)
  const {data: canPay} = useOrganizationCanPay(org?.id)
  const {data: providers = []} = useProjectProviders(project.id)

  const providersMap: Record<string, OrganizationSSOProvider> = useMemo(() => {
    return providers.reduce(
      (acc, val) => {
        acc[`${val.type}-${val.id}`] = val
        return acc
      },
      {} as Record<string, OrganizationSSOProvider>
    )
  }, [providers])

  const {
    showDialog: showRemoveDialog,
    hideDialog: hideRemoveDialog,
    Dialog: RemoveDialog,
  } = useConfirmDialog()

  const canReadMembers = useRequiredProjectPermissions([
    {permissionName: 'sanity.project.members', grantName: 'read'},
  ])
  const canInviteMembers = useRequiredProjectPermissions([
    {permissionName: 'sanity.project.members', grantName: 'invite'},
  ])
  const {dispatch, state: wizardState} = useWizardStore()

  const [rolesFilter, setRolesFilter] = useState<string[]>((query.roles as string[]) || [])
  const [textFilter, setTextFilter] = useState<string>('')
  const {data: users} = useUsers(project.members.map((member) => member.id))

  const usersMap = useMemo(() => {
    if (!users) return {}
    return users.reduce(
      (acc, user) => {
        if (user) {
          acc[user.id] = user
        }
        return acc
      },
      {} as Record<string, User>
    )
  }, [users])

  const allMembers = useMemo(
    () =>
      (project.members || [])
        .filter((m) => !m.isRobot)
        .sort((a, b) => (new Date(b.createdAt) as any) - (new Date(a.createdAt) as any)),
    [project]
  )

  const filteredMembers = useMemo(() => {
    const matchTextFilter = (member: ProjectMemberType) => {
      if (!textFilter) {
        return true
      }
      const user = usersMap[member.id]
      if (!user) return false
      const name = user.displayName?.toLowerCase() || ''
      const email = user.email?.toLowerCase() || ''
      return name.includes(textFilter.toLowerCase()) || email?.includes(textFilter.toLowerCase())
    }

    const matchRoleFilter = (member: ProjectMemberType) => {
      if (rolesFilter.length === 0) {
        return true
      }
      return member.roles.some((r) => rolesFilter.includes(r.name))
    }

    return allMembers.filter((m) => {
      return matchTextFilter(m) && matchRoleFilter(m)
    })
  }, [allMembers, textFilter, rolesFilter, usersMap])

  const [selectedMembers, setSelectedMembers] = useState<ProjectMemberDecorated[]>([])

  const projectType = useProjectType(project)
  const disabledTooltip = projectType === 'playground' || org?.type == 'personal' || !canPay?.status

  const memberState = getProjectMemberState(project, projectType, subscription?.resources, org)

  useEffect(() => {
    if (project?.id) {
      sendAmplitudeTrackingEvent('Project Members Page Viewed', project.id)
    }
  }, [project?.id])

  const handleRemoveMember = useCallback(
    (member: ProjectMemberDecorated) => {
      const payload = [member]
      setSelectedMembers(payload)
      showRemoveDialog(payload)
    },
    [showRemoveDialog]
  )

  const handleTextFilterChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setTextFilter(event.target.value)
  }, [])

  const debouncedHandleTextFilterChange = useMemo(
    () => debounce(handleTextFilterChange, 300),
    [handleTextFilterChange]
  )

  useEffect(() => {
    router.push(
      {
        pathname: router.asPath.split('?')[0],
        query: {roles: rolesFilter},
      },
      undefined,
      {shallow: true, scroll: false}
    )
  }, [rolesFilter])

  const handleRoleSelectionChange = useCallback(
    (role) => {
      if (rolesFilter.includes(role)) {
        setRolesFilter((current) => [...current.filter((r) => r !== role)])
      } else {
        setRolesFilter((current) => [...current, role])
      }
    },
    [rolesFilter]
  )

  const handleClearRoleFilter = useCallback(() => {
    setRolesFilter([])
  }, [])

  const rowActions: TableRowAction[] = [
    {
      title: 'Remove from project',
      icon: RemoveCircleIcon,
      tone: 'critical',
      mode: 'bleed',
      onAction: handleRemoveMember,
      permissions: [{permissionName: 'sanity.project.members', grantName: 'delete'}],
      dialog: (
        <RemoveProjectMembersDialog
          key="remove-member-dialog"
          Dialog={RemoveDialog}
          project={project}
          members={selectedMembers}
          hideDialog={hideRemoveDialog}
        />
      ),
    },
  ]
  const isDisabled = project?.isDisabled || project?.isDisabledByUser

  const handleInviteButtonClicked = useCallback(() => {
    dispatch({type: 'inviteMembersWizard/open'})
  }, [dispatch])

  useEffect(() => {
    if (query.invite && canInviteMembers) {
      handleInviteButtonClicked()
      // Remove the query param after opening to prevent reopening on page refresh
      const {invite: _, ...otherParams} = query
      router.replace({
        query: otherParams,
      })
    }
  }, [query, handleInviteButtonClicked, canInviteMembers])
  const disableVirtualizer = useMemo(() => {
    return (
      allMembers.length === 0 ||
      (filteredMembers.length === 0 && (textFilter.length > 0 || rolesFilter.length > 0))
    )
  }, [allMembers.length, filteredMembers.length, rolesFilter.length, textFilter])

  const virtualizer = useWindowVirtualizer({
    count: filteredMembers.length,
    scrollMargin: parentRef.current?.scrollTop ?? 0,
    estimateSize: useCallback(() => 58, []),
    overscan: 10,
    enabled: !disableVirtualizer,
    paddingStart: 25,
    isScrollingResetDelay: 1000,
  })

  return (
    <>
      <Stack space={[4, 4, 6]} ref={parentRef}>
        {rowActions.map((action) => typeof action !== 'string' && action.dialog)}

        <TabHeader
          title="Project members"
          button={
            isDisabled ? (
              <InfoTooltip
                title="Archived project"
                description="Reactivate the project in settings to invite more members"
              >
                <Button icon={AddIcon} disabled text="Invite project members" mode="ghost" />
              </InfoTooltip>
            ) : (
              <PermissionButtonProject
                onClick={handleInviteButtonClicked}
                title="Invite project members"
                icon={AddIcon}
                permissions={[{permissionName: 'sanity.project.members', grantName: 'invite'}]}
              />
            )
          }
          description={description}
          referenceLink={referenceLink}
          counter={{
            current: memberState.billable,
            quota: memberState.quota,
            max: memberState.max,
            resource: getUserResourceName(memberState.resource),
          }}
          showUpsell
          upsellResourceName="seats"
          tooltip={
            project && (
              <ProjectUsageStatusTooltip
                project={project}
                resourcesToCheck={['users', 'non_admin_users']}
                disabled={disabledTooltip}
              />
            )
          }
        />

        <ProjectUsagePrompt resourcesToCheck={['users', 'non_admin_users']} context="members" />

        {canReadMembers && (
          <Card radius={2}>
            <Card paddingY={2} marginBottom={2} radius={2}>
              <Flex>
                <Box flex={1} marginRight={2}>
                  <TextInput
                    icon={SearchIcon}
                    placeholder="Filter by name or email"
                    radius={2}
                    onChange={debouncedHandleTextFilterChange}
                  />
                </Box>
                <Card>
                  <MenuButton
                    id="filter-roles-menu-button"
                    button={
                      <Button
                        text={
                          rolesFilter.length > 0
                            ? `${rolesFilter.length} ${rolesFilter.length > 1 ? 'roles' : 'role'}`
                            : 'All roles'
                        }
                        mode={rolesFilter.length > 0 ? 'default' : 'ghost'}
                        tone={rolesFilter.length > 0 ? 'primary' : 'default'}
                        icon={UsersIcon}
                        iconRight={ChevronDownIcon}
                      />
                    }
                    placement="bottom-end"
                    portal
                    menu={
                      <Menu style={{maxHeight: '50vh'}}>
                        <Button
                          fontSize={1}
                          text="Clear selection"
                          onClick={handleClearRoleFilter}
                          disabled={rolesFilter.length === 0}
                          icon={CloseCircleIcon}
                          mode="bleed"
                          tone="primary"
                          tabIndex={-1}
                        />
                        {projectUserRoles.map((role) => (
                          <MenuItem
                            key={role.name}
                            // eslint-disable-next-line react/jsx-no-bind
                            onClick={() => handleRoleSelectionChange(role.name)}
                            tone={rolesFilter.includes(role.name) ? 'primary' : 'default'}
                          >
                            <Flex gap={2} justify="space-between">
                              <Text>{role.title}</Text>
                              <Box marginLeft={2}>
                                <Text>
                                  {rolesFilter.includes(role.name) ? (
                                    <CheckmarkIcon />
                                  ) : (
                                    <Box padding={2} />
                                  )}
                                </Text>
                              </Box>
                            </Flex>
                          </MenuItem>
                        ))}
                      </Menu>
                    }
                  />
                </Card>
              </Flex>
            </Card>

            <Box ref={parentRef}>
              <TableWrapper
                $disabled={disableVirtualizer}
                $height={!disableVirtualizer ? `${virtualizer.getTotalSize()}px` : `auto`}
              >
                <Table
                  headers={allMembers.length > 0 ? headers : []}
                  loadingText="Loading project members..."
                >
                  <TableBody>
                    {virtualizer.getVirtualItems().map((virtualRow, index) => {
                      const member = filteredMembers[virtualRow.index]

                      return (
                        <ProjectMember
                          projectId={project.id}
                          member={member}
                          rowActions={rowActions}
                          key={`member-${member.id}`}
                          isLast={filteredMembers.length === index + 1}
                          providersMap={providersMap}
                          projectRoles={projectUserRoles}
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: `${virtualRow.size}px`,
                            transform: `translateY(${virtualRow.start - virtualizer.options.scrollMargin}px)`,
                          }}
                        />
                      )
                    })}

                    {filteredMembers.length === 0 && (textFilter || rolesFilter.length > 0) && (
                      <Box marginTop={4}>
                        <TableEmpty title="No members matching the filter" />
                      </Box>
                    )}

                    {allMembers.length === 0 && (
                      <Box marginTop={4}>
                        <TableEmpty
                          title="There are no members in the project"
                          description="Maybe try inviting some new members?"
                        />
                      </Box>
                    )}
                  </TableBody>
                </Table>
              </TableWrapper>
            </Box>
          </Card>
        )}
        {!canReadMembers && (
          <EmptyBlock
            illustration="access-denied"
            title="Insufficient permissions"
            description="You don't have the right permissions to see member details."
          />
        )}
      </Stack>
      {wizardState.inviteMembersWizard.open && (
        <InviteMembersWizard
          params={{
            org,
            orgs,
            plans,
            project,
          }}
        />
      )}
    </>
  )
}
