import {SelectIcon, UsersIcon} from '@sanity/icons'
import {Inline, Text, Flex, Button, Menu, MenuButton, MenuItem} from '@sanity/ui'
import React, {useCallback} from 'react'

export function OrgSelector({
  currentItem,
  onChange,
  items,
  text,
}: {
  currentItem?: any
  onChange: (item: any) => void
  items: any[]
  text: string
}) {
  const handleChangeItem = useCallback(
    (item: any) => {
      onChange(item)
    },
    [onChange]
  )
  const selectedItem = currentItem
  return (
    <Flex align="center">
      <MenuButton
        button={
          <Button
            iconRight={SelectIcon}
            mode="bleed"
            text={
              <Inline>
                <Text weight={selectedItem.title ? 'semibold' : undefined}>
                  {selectedItem.title || text}
                </Text>
              </Inline>
            }
          />
        }
        id="org-selector-menu"
        menu={
          <Menu>
            {items.map((item) => (
              <MenuItem
                key={item.id}
                icon={UsersIcon}
                text={item.title}
                onClick={() => handleChangeItem(item)}
              />
            ))}
          </Menu>
        }
      />
    </Flex>
  )
}
