import {
  Stack,
  Heading,
  Text,
  Inline,
  Button,
  Grid,
  Label,
  HeadingSkeleton,
  Flex,
  Box,
  Card,
  Badge,
} from '@sanity/ui'
import React, {useCallback, useMemo, useState, memo} from 'react'
import styled from 'styled-components'
import {ChevronDownIcon, ChevronUpIcon} from '@sanity/icons'
import {useUser} from '../../../../data'
import {useRequiredProjectPermissions} from '@/context/index'
import {nameToInitials} from '../../members/utils'
import {LoginProvider} from '../../members/loginProviders'
import {
  AvatarWithFallback,
  TableCell,
  ProjectRoleSelect,
  TableRowMenuButton,
  MobileRowMenu,
  TableRow,
} from '@/ui/index'
import {useMatchesMedia, useTimeAgo} from '@/utils/index'
import {
  OrganizationSSOProvider,
  ProjectMember as MemberType,
  TableRowAction,
  ProjectMemberRole,
} from '@/types/index'
import {CSSProperties} from 'styled-components'

type Props = {
  projectId: string
  member: MemberType
  rowActions: TableRowAction[]
  isLast: boolean
  projectRoles?: ProjectMemberRole[]
  providersMap?: Record<string, OrganizationSSOProvider>
  style?: CSSProperties
}

const AvatarWithLoginProvider = styled.div`
  position: relative;
`

const LoadingSkeleton = styled(HeadingSkeleton).attrs({animated: true})`
  max-width: 100%;
  width: 220px;
`

export const ProjectMember = memo(
  ({projectId, member, rowActions, isLast, providersMap, projectRoles, style}: Props) => {
    const hasPermission = useRequiredProjectPermissions([
      {permissionName: 'sanity.project.members', grantName: 'update'},
    ])
    const [showDetails, setShowDetails] = useState<boolean>(false)
    const {data: user, isLoading: isLoadingUser} = useUser(member.id)
    const isMobile = useMatchesMedia(1)
    const handleToggleDetails = useCallback(() => {
      setShowDetails(!showDetails)
    }, [showDetails])
    const handleClickDetails = useCallback((ev: React.MouseEvent<HTMLElement>) => {
      ev.stopPropagation()
    }, [])

    const joined = useTimeAgo(member.createdAt, {minimal: true})

    const managedExternal: boolean =
      providersMap && user?.provider && providersMap[user?.provider]
        ? providersMap[user?.provider].enforceRolesOnLogin
        : false

    const roleSelect = useMemo(
      () => (
        <ProjectRoleSelect
          projectId={projectId}
          hasPermission={hasPermission}
          user={{id: member.id, isCurrentUser: member.isCurrentUser}}
          currentRoles={member.roles}
          projectRoles={projectRoles}
          managedExternal={managedExternal}
        />
      ),
      [projectId, hasPermission, member, managedExternal, projectRoles]
    )

    return (
      <TableRow
        paddingY={3}
        paddingX={2}
        borderBottom={!isLast}
        onClick={isMobile ? handleToggleDetails : undefined}
        style={style}
      >
        <TableCell>
          {isLoadingUser && <LoadingSkeleton />}
          <AvatarWithLoginProvider>
            {!isLoadingUser && user && (
              <AvatarWithFallback
                src={user.imageUrl || undefined}
                initials={nameToInitials(user.displayName)}
                size={1}
              />
            )}
            {user && user.provider !== 'sanity' && <LoginProvider provider={user.provider} />}
          </AvatarWithLoginProvider>
        </TableCell>
        <TableCell>
          {isLoadingUser && <LoadingSkeleton />}
          {!isLoadingUser && (
            <Stack space={2}>
              <Inline space={2}>
                <Heading size={1}>{user?.displayName}</Heading>
                {member.isCurrentUser && <Badge mode="outline">You</Badge>}
              </Inline>
              <Text size={1}>{user?.email}</Text>
            </Stack>
          )}
        </TableCell>
        <TableCell>{roleSelect}</TableCell>
        <TableCell>
          <Text size={1}>{joined}</Text>
        </TableCell>
        <TableCell $align="right">
          {isMobile ? (
            <Button
              label="Show more"
              icon={showDetails ? ChevronUpIcon : ChevronDownIcon}
              onClick={handleToggleDetails}
              mode="bleed"
            />
          ) : (
            <TableRowMenuButton
              menuId={member.id}
              label={`Edit ${member.id}`}
              rowItem={member}
              actions={rowActions}
              roleScope="project"
            />
          )}
        </TableCell>
        {isMobile && showDetails && (
          <TableCell
            style={{gridColumn: '1/-1', width: '100%'}}
            flexProps={{direction: 'column', align: 'flex-start'}}
            onClick={handleClickDetails}
          >
            <Card flex={1} paddingTop={3} style={{width: '100%'}}>
              <Stack space={4}>
                <Grid columns={2} gap={4} paddingX={3}>
                  <Stack space={3}>
                    <Label muted size={0}>
                      ROLES
                    </Label>
                    {roleSelect}
                  </Stack>
                  <Stack space={3}>
                    <Label muted size={0}>
                      Joined
                    </Label>
                    <Text size={2}>{joined}</Text>
                  </Stack>
                </Grid>
                <Flex style={{width: '100%'}}>
                  <Box flex={1}>
                    <MobileRowMenu actions={rowActions} roleScope="project" rowItem={member} />
                  </Box>
                </Flex>
              </Stack>
            </Card>
          </TableCell>
        )}
      </TableRow>
    )
  }
)
