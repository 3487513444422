import React from 'react'
import Head from 'next/head'
import getConfig from 'next/config'
import {hues} from '@sanity/color'

/* eslint-disable no-process-env */
const basePath = process.env.assetPrefix || ''
/* eslint-enable no-process-env */

const config = getConfig()
const {osano} = config.publicRuntimeConfig

// Use const since all of this is static
const pageHead = (
  <Head>
    <title>Manage | Sanity.io</title>
    <meta name="msapplication-tap-highlight" content="no" />
    <link rel="icon" href={`${basePath}/favicon.ico`} type="image/x-icon" />
    <meta name="theme-color" content={hues.red[500].hex} />
    <meta
      name="viewport"
      content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
    />
    <meta name="referrer" content="same-origin" />
    <link rel="stylesheet" href={osano.stylesUrl} />
    <meta name="msapplication-tap-highlight" content="no" />
    <link rel="apple-touch-icon" sizes="48x48" href={`${basePath}/sanity-favicon-48.png`} />
    <link rel="apple-touch-icon" sizes="72x72" href={`${basePath}/sanity-favicon-72.png`} />
    <link rel="apple-touch-icon" sizes="96x96" href={`${basePath}/sanity-favicon-96.png`} />
    <link rel="apple-touch-icon" sizes="144x144" href={`${basePath}/sanity-favicon-144.png`} />
    <link rel="apple-touch-icon" sizes="180x180" href={`${basePath}/sanity-favicon-180.png`} />
    <link rel="apple-touch-icon" sizes="192x192" href={`${basePath}/sanity-favicon-192.png`} />
    <link rel="apple-touch-icon" sizes="256x256" href={`${basePath}/sanity-favicon-256.png`} />
    <link rel="apple-touch-icon" sizes="384x384" href={`${basePath}/sanity-favicon-384.png`} />
    <link rel="apple-touch-icon" sizes="512x512" href={`${basePath}/sanity-favicon-512.png`} />
    <link rel="shortcut icon" href={`${basePath}/favicon.ico`} />
    <link rel="icon" type="image/png" sizes="16x16" href={`${basePath}/sanity-favicon-16.png`} />
    <link rel="icon" type="image/png" sizes="32x32" href={`${basePath}/sanity-favicon-32.png`} />
    <link rel="icon" type="image/png" sizes="48x48" href={`${basePath}/sanity-favicon-48.png`} />
    <link rel="apple-touch-icon" sizes="57x57" href={`${basePath}/sanity-favicon-57.png`} />
    <link rel="apple-touch-icon" sizes="60x60" href={`${basePath}/sanity-favicon-60.png`} />
    <link rel="apple-touch-icon" sizes="72x72" href={`${basePath}/sanity-favicon-72.png`} />
    <link rel="apple-touch-icon" sizes="76x76" href={`${basePath}/sanity-favicon-76.png`} />
    <link rel="apple-touch-icon" sizes="114x114" href={`${basePath}/sanity-favicon-114.png`} />
    <link rel="apple-touch-icon" sizes="120x120" href={`${basePath}/sanity-favicon-120.png`} />
    <link rel="apple-touch-icon" sizes="144x144" href={`${basePath}/sanity-favicon-144.png`} />
    <link rel="apple-touch-icon" sizes="152x152" href={`${basePath}/sanity-favicon-152.png`} />
    <link rel="apple-touch-icon" sizes="167x167" href={`${basePath}/sanity-favicon-167.png`} />
    <link rel="apple-touch-icon" sizes="180x180" href={`${basePath}/sanity-favicon-180.png`} />
    <link rel="apple-touch-icon" sizes="1024x1024" href={`${basePath}/sanity-favicon-1024.png`} />
  </Head>
)

export function PageHead() {
  return pageHead
}
