import {Card, Button} from '@sanity/ui'
import React from 'react'

type Props = {
  onClick: () => void
}

export function ShortcutButton({onClick}: Props) {
  return (
    <Card tone="transparent" radius={2}>
      <Button onClick={onClick} text="Select project or organization" mode="bleed" />
    </Card>
  )
}
