import {Screen} from './screen'
import {Package} from './package'
import {Error} from './error'
import {GettingStarted} from './gettingStarted'
import {AccessDenied} from './accessDenied'
import {ComingSoon} from './comingSoon'
import {Tada} from './tada'
import {Dataset} from './dataset'
import {Quota} from './quota'
import {SSO} from './sso'
import {Support} from './support'
import {Coupon} from '@/components/illustrations/drawings/Coupon'

export type IllustrationDrawing =
  | 'screen'
  | 'package'
  | 'error'
  | 'getting-started'
  | 'access-denied'
  | 'coming-soon'
  | 'tada'
  | 'dataset'
  | 'quota'
  | 'sso'
  | 'support'
  | 'coupon'

export default {
  screen: Screen,
  package: Package,
  coupon: Coupon,
  error: Error,
  'getting-started': GettingStarted,
  'access-denied': AccessDenied,
  'coming-soon': ComingSoon,
  tada: Tada,
  dataset: Dataset,
  quota: Quota,
  sso: SSO,
  support: Support,
}
