import {Card as UICard} from '@sanity/ui'
import React from 'react'
import styled from 'styled-components'
import {nameToInitials} from '../members/utils'
import {SanityLogoSmall} from '@/ui/app'
import {useMatchesMedia} from '@/utils/index'

const sizes = [0, 30, 50, 80]
const mobileSizes = [0, 30, 45, 50]

type ProfileImageCard = {
  $mobile?: boolean
  $size?: number
}

const Card = styled(UICard)<ProfileImageCard>`
  display: grid !important;
  place-items: center;
  background: ${(props) => props.color || props.theme.sanity.color.base.shadow.outline};
  color: ${(props) => props.theme.sanity.color.card.disabled.fg};
  width: ${({$mobile = false, size = 2}: {$mobile?: boolean; size?: number}) =>
    `${$mobile ? mobileSizes[size] : sizes[size]}px`};
  height: ${({$mobile = false, size = 2}: {$mobile?: boolean; size?: number}) =>
    `${$mobile ? mobileSizes[size] : sizes[size]}px`};
  font-size: ${({$mobile = false, size = 2}: {$mobile?: boolean; size?: number}) =>
    `${$mobile ? mobileSizes[size] / 2 : sizes[size] / 2}px`};
  box-sizing: border-box;
  transition: background 400ms ease;
  flex-shrink: 0;
`

const Initials = styled.span`
  display: inline-block;
  color: black;
  line-height: 0;
  font-weight: 600;
  text-transform: uppercase;
  opacity: 0.9;
`

export const ProfileImage = ({
  size = 1,
  color,
  round,
  name,
}: {
  size?: number
  color?: string
  round?: boolean
  name?: string
}) => {
  const isMobile = useMatchesMedia(1)
  return (
    <Card
      padding={name ? 0 : size === 1 ? 1 : [1, 2, 3]}
      color={color}
      size={size}
      radius={2}
      style={round ? {borderRadius: '100%'} : {}}
      $mobile={isMobile}
    >
      {name ? <Initials>{nameToInitials(name, true)}</Initials> : <SanityLogoSmall />}
    </Card>
  )
}
