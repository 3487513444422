import {Box, Button, Flex, Grid, Label, Stack, Text} from '@sanity/ui'
import React, {useState} from 'react'
import {ChevronDownIcon, ChevronUpIcon} from '@sanity/icons'
import {useProjectRolesList} from '@/context/index'
import {MobileRowMenu, TableCell, TableRowMenuButton, MemberAvatar, TableRow} from '@/ui/index'
import {ProjectInvitation as Invitation, TableRowAction} from '@/types/index'
import {useMatchesMedia, useTimeAgo} from '@/utils/index'

type Props = {
  projectId: string
  invitation: Invitation
  rowActions: TableRowAction[] | undefined
}

export const ProjectInvitation = ({projectId, invitation, rowActions}: Props) => {
  const [showDetails, setShowDetails] = useState<boolean>(false)
  const isMobile = useMatchesMedia(1)

  const handleToggleDetails = () => {
    setShowDetails(!showDetails)
  }
  const {roles} = useProjectRolesList(projectId)
  const sent = useTimeAgo(invitation.createdAt, {minimal: true})
  const roleTitle =
    roles?.find((rol) => rol.name === invitation.roles?.[0]?.name)?.title || 'Unknown'
  return (
    <TableRow
      paddingY={3}
      paddingX={2}
      borderBottom
      onClick={isMobile ? handleToggleDetails : undefined}
    >
      <TableCell>
        <Text size={1}>{invitation.email}</Text>
      </TableCell>
      <TableCell>
        <Text size={1}>{roleTitle}</Text>
      </TableCell>
      <TableCell>
        <Box paddingRight={2}>
          <MemberAvatar userId={invitation.invitedByUserId} />
        </Box>
        <Text size={1}>{sent}</Text>
      </TableCell>
      <TableCell $align="right">
        {isMobile ? (
          <Button
            label="Show more"
            icon={showDetails ? ChevronUpIcon : ChevronDownIcon}
            onClick={handleToggleDetails}
            mode="bleed"
          />
        ) : (
          <TableRowMenuButton
            menuId={invitation.id}
            label={`Edit ${invitation.id}`}
            rowItem={invitation}
            actions={rowActions}
            roleScope="project"
          />
        )}
      </TableCell>
      {isMobile && showDetails && (
        <>
          <TableCell
            style={{gridColumn: '2/-1', width: '100%'}}
            flexProps={{direction: 'column', align: 'flex-start'}}
          >
            <Grid columns={2} gap={5}>
              <Stack space={3}>
                <Label muted size={0}>
                  Role
                </Label>
                <Text size={2}>{roleTitle}</Text>
              </Stack>
              <Stack space={3}>
                <Label muted size={0}>
                  Sent
                </Label>
                <Text size={2}>{sent}</Text>
              </Stack>
            </Grid>
          </TableCell>
          <TableCell
            style={{gridColumn: '1/-1', width: '100%'}}
            flexProps={{direction: 'column', align: 'flex-start'}}
          >
            <Flex style={{width: '100%'}}>
              <Box flex={1}>
                <MobileRowMenu actions={rowActions} roleScope="project" rowItem={invitation} />
              </Box>
            </Flex>
          </TableCell>
        </>
      )}
    </TableRow>
  )
}
