import React from 'react'
import {Text, Dialog, Button, Grid, Card} from '@sanity/ui'

type ConfirmDisableSAMLDialogProps = {
  closeDialog: () => void
  disableSAML: () => void
}

export default function ConfirmDisableSAMLDialog({
  closeDialog,
  disableSAML,
}: ConfirmDisableSAMLDialogProps) {
  function handleConfirm() {
    disableSAML()
    closeDialog()
  }

  return (
    <Dialog
      id="confirm-disable-saml"
      width={1}
      footer={
        <Grid columns={2} gapX={2} padding={4}>
          <Button text="Cancel" mode="bleed" onClick={closeDialog} />
          <Button text="Disable SAML SSO" tone="critical" onClick={handleConfirm} />
        </Grid>
      }
      onClose={closeDialog}
      header="Disable SAML SSO for organization"
    >
      <Card padding={4} paddingY={5}>
        <Text>All users on all projects will be unable to sign in with SAML SSO.</Text>
      </Card>
    </Dialog>
  )
}
