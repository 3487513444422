import {Box, Button, ButtonProps, Flex, Stack, Text, TextArea, useToast} from '@sanity/ui'

import {ThumbsUpIcon, ThumbsDownIcon} from './icons/thumbs'
import {Icon} from '@sanity/icons'
import {motion, AnimatePresence} from 'framer-motion'
import {FormEvent, useRef, useState} from 'react'
import {sendAmplitudeTrackingEvent} from '@/utils/tracking'
import {useCurrentScopeContext} from '@/context/useCurrentScopeContext'
import {Lesson} from '@/data/index'

type FeedbackOptions = 'helpful' | 'not-helpful' | 'feedback'

type Props = {
  framework?: string
  currentLesson?: Lesson
}

const FEEDBACK_PLACEHOLDER: Record<FeedbackOptions, string> = {
  helpful: 'Please let us know how it was helpful',
  'not-helpful': 'Please let us know what we can improve',
  feedback: 'Please let us know what you think',
}

const commonFeedbackButtonProps: ButtonProps = {
  padding: 3,
  mode: 'bleed',
  tone: 'default',
}

const FeedbackContainer = motion(Box)

export function CourseFeedback({framework, currentLesson}: Props) {
  const {project, org, currentUser} = useCurrentScopeContext()
  const toast = useToast()
  const [feedbackType, setFeedbackType] = useState<FeedbackOptions>()
  const feedbackTextAreaRef = useRef<HTMLTextAreaElement>(null)

  // Reset feedback module when lesson changes
  const [prevLesson, setPrevLesson] = useState(currentLesson)
  if (currentLesson !== prevLesson) {
    setPrevLesson(currentLesson)
    setFeedbackType(undefined)
    feedbackTextAreaRef.current?.value && (feedbackTextAreaRef.current.value = '')
  }

  const handleFeedbackClick = (type: FeedbackOptions) => {
    if (type !== feedbackType) {
      setFeedbackType(type)
      sendAmplitudeTrackingEvent('Getting Started Course Feedback Clicked', project?.id, org?.id, {
        projectName: project?.displayName,
        orgName: org?.name,
        feedbackType: type,
        framework,
        currentLesson: currentLesson?.title,
        userId: currentUser?.id,
      })
    }
  }

  const handleFeedbackSubmission = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    sendAmplitudeTrackingEvent('Getting Started Course Feedback Submitted', project?.id, org?.id, {
      userId: currentUser?.id,
      projectName: project?.displayName,
      orgName: org?.name,
      feedbackType,
      framework,
      currentLesson: currentLesson?.title,
      feedbackMessage: feedbackTextAreaRef.current?.value,
    })
    toast.push({
      closable: true,
      status: 'success',
      title: 'Your feedback has been received!',
      description: 'Thank you for helping us improve the content.',
      duration: 10000,
    })
    setFeedbackType(undefined)
    feedbackTextAreaRef.current?.value && (feedbackTextAreaRef.current.value = '')
  }

  return (
    <AnimatePresence>
      <FeedbackContainer
        marginTop={5}
        paddingLeft={[4, 4, 5]}
        paddingRight={4}
        variants={{
          visible: {opacity: 1, height: 'auto'},
          hidden: {opacity: 0, height: 0},
        }}
        initial="hidden"
        animate="visible"
        exit="hidden"
      >
        <Flex
          justify="space-between"
          align="center"
          direction={['column', 'column', 'row']}
          gap={4}
        >
          <Text weight="medium" size={1}>
            What did you think of this content?
          </Text>

          <Flex wrap="wrap" justify="center" gap={1}>
            <Button
              {...commonFeedbackButtonProps}
              text="It was helpful"
              icon={<ThumbsUpIcon />}
              onClick={() => handleFeedbackClick('helpful')}
              selected={feedbackType === 'helpful'}
            />
            <Button
              {...commonFeedbackButtonProps}
              text="It was not helpful"
              icon={<ThumbsDownIcon />}
              onClick={() => handleFeedbackClick('not-helpful')}
              selected={feedbackType === 'not-helpful'}
            />
            <Button
              {...commonFeedbackButtonProps}
              text="I have feedback"
              icon={<Icon symbol="envelope" />}
              onClick={() => handleFeedbackClick('feedback')}
              selected={feedbackType === 'feedback'}
            />
          </Flex>
        </Flex>

        {feedbackType && (
          <motion.form
            onSubmit={handleFeedbackSubmission}
            variants={{
              hidden: {opacity: 0, height: 0, overflow: 'hidden'},
              visible: {opacity: 1, height: 'auto'},
            }}
            initial="hidden"
            animate="visible"
            exit="hidden"
            transition={{duration: 0.2}}
          >
            <Stack marginTop={3} space={3}>
              <TextArea
                autoFocus
                placeholder={FEEDBACK_PLACEHOLDER[feedbackType]}
                ref={feedbackTextAreaRef}
              />
              <Button text="Submit" mode="ghost" type="submit" style={{width: 'max-content'}} />
            </Stack>
          </motion.form>
        )}
      </FeedbackContainer>
    </AnimatePresence>
  )
}
