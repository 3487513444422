import {useMemo} from 'react'
import {getProjectRoles} from '@/data/projects/controller'
import {useQuery} from '@tanstack/react-query'
import {getProjectRolesKey} from '../data/projects/cache'
import {STALE_TTL} from '@/data/datasets/cache'
import {useRequiredProjectPermissions} from './useProjectPermissions'

export const useProjectRolesList = (projectId: string) => {
  const canReadProjectRoles = useRequiredProjectPermissions(
    [{permissionName: 'sanity.project.roles', grantName: 'read'}],
    projectId
  )

  const {isLoading, data, error} = useQuery({
    queryKey: getProjectRolesKey(projectId),
    queryFn: () => getProjectRoles(projectId),
    enabled: canReadProjectRoles,
    staleTime: STALE_TTL,
  })

  return {isLoading, roles: data || [], error}
}

export const useProjectUserRolesList = (projectId: string) => {
  const {roles, error} = useProjectRolesList(projectId)
  return {
    roles: useMemo(
      () =>
        roles.filter((role) => role.appliesToUsers).sort((a, b) => a.name.localeCompare(b.name)),
      [roles]
    ),
    error,
  }
}

export const useProjectRobotRolesList = (projectId: string) => {
  const {roles, error} = useProjectRolesList(projectId)
  return {
    roles: useMemo(
      () =>
        roles.filter((role) => role.appliesToRobots).sort((a, b) => a.name.localeCompare(b.name)),
      [roles]
    ),
    error,
  }
}
