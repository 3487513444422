import {LOCAL_STORAGE_KEY} from '../constants'
import type {Msg, GetStartedState} from './types'

export const INITIAL_STATE = (projectId: string): GetStartedState => ({
  persona: 'developer',
  framework: null,
  projectId,
  dismissed: false,
})

const returnAndSave = (state: GetStartedState) => {
  const localStorageState = {
    persona: state.persona,
    projectId: state.projectId,
  }
  localStorage.setItem(LOCAL_STORAGE_KEY(state.projectId), JSON.stringify(localStorageState))
  return state
}

export function reducer(state: GetStartedState, msg: Msg): GetStartedState {
  if (msg.type === 'reset') {
    return returnAndSave(msg.value ?? INITIAL_STATE(state.projectId))
  }
  if (msg.type === 'getStarted/switchPersona') {
    return returnAndSave({
      ...state,
      persona: state.persona === 'developer' ? 'non-developer' : 'developer',
    })
  }
  if (msg.type === 'getStarted/setPersona') {
    return returnAndSave({
      ...state,
      persona: msg.value,
    })
  }
  if (msg.type === 'getStarted/setFramework') {
    return returnAndSave({
      ...state,
      framework: msg.value,
    })
  }
  if (msg.type === 'getStarted/setDismissed') {
    return returnAndSave({
      ...state,
      dismissed: msg.value,
    })
  }

  return returnAndSave(state)
}
