import React from 'react'
import {Card, Grid} from '@sanity/ui'
import {EmptyBlock, Loader} from '../../general'

type Props = {
  children: React.ReactNode
  columns?: number | number[]
  loading?: boolean
  dense?: boolean
  empty?: boolean
}

export function Container({
  children,
  columns = [1, 1, 1, 3],
  dense,
  loading = true,
  empty = false,
}: Props) {
  if (!empty && !loading) {
    return (
      <Card radius={2} overflow="hidden">
        <Grid
          columns={columns}
          style={dense ? {gap: '1px', padding: '1px'} : {padding: '1px'}}
          gap={dense ? 0 : 3}
        >
          {children}
        </Grid>
      </Card>
    )
  }
  if (empty && !loading) {
    return (
      <EmptyBlock
        title="No usage found"
        description="An overview of your usage by resource will show up here."
        illustration="error"
      />
    )
  }
  return (
    <Loader
      text="Loading usage..."
      height={dense ? 268 : 300}
      cardProps={{border: true, radius: 2}}
    />
  )
}
