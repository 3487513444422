import {Box, Button, Card, Stack, Text} from '@sanity/ui'
import React, {useMemo} from 'react'
import {EllipsisHorizontalIcon} from '@sanity/icons'
import {Loader} from '..'
import {ActivityTile} from './activityTile'
import {AppLink} from '@/ui/app'
import {useActivityEventsQuery} from '@/context/useActivityEventsQuery'
import {sortEvents} from '@/utils/index'

export const ActivityOverview = ({
  path,
  showProjectEvents = false,
}: {
  path?: string
  showProjectEvents?: boolean
}) => {
  const {data: events, loading} = useActivityEventsQuery()

  const filteredEvents = useMemo(() => {
    const sorted = sortEvents(events)
    return showProjectEvents
      ? sorted.slice(0, 5)
      : sorted.filter((event) => event.organizationId).slice(0, 10)
  }, [events, showProjectEvents])

  const activityList =
    events.length > 0 ? (
      filteredEvents.map((event, index) => (
        <ActivityTile
          key={event.id}
          event={event}
          disableFilter
          compact
          borderBottom={index < filteredEvents.length - 1}
        />
      ))
    ) : (
      <Box padding={6}>
        <Text muted size={1} align="center">
          No activity found...
        </Text>
      </Box>
    )

  if (loading) {
    return <Loader height={268} cardProps={{border: true, radius: 2}} />
  }

  return (
    <Card border radius={2} paddingTop={1} padding={3}>
      <Stack space={2}>
        {activityList}
        {path && events.length > 0 && (
          <Card border radius={1}>
            <AppLink href={path} style={{paddingRight: 0}}>
              <Button
                as="span"
                mode="bleed"
                tone="default"
                icon={EllipsisHorizontalIcon}
                title="View all activity"
                style={{width: '100%'}}
              />
            </AppLink>
          </Card>
        )}
      </Stack>
    </Card>
  )
}
