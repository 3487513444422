import {Organization} from '../../../types/models'
import {isAPIError, promiseRequest} from '@/data/util/promiseRequest'

export * from './customer'
export * from './details'
export * from './payment'

export function getOrganizations(): Promise<Organization[]> {
  return promiseRequest<Organization[]>(
    `https://api.${process.env.host}/v2021-06-07/organizations?includeImplicitMemberships=true`
  ).catch((error) => {
    if (isAPIError(error) && error.statusCode === 401) {
      return []
    }
    throw error
  })
}
