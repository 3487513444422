import numeral from 'numeral'
import {format, setDate, startOfMonth /*isSameMonth */, endOfMonth, parseISO} from 'date-fns'
import {hues} from '@sanity/color'
import dotProp from 'dot-prop'
import {getDayFromDateTime} from './usage'
import {
  ApexAxisChartSerie,
  ApexAxisChartSeries,
  // CategorySeriesData,
  Range,
  // ChartDataStacked,
  AccumulatedPeriodUsage,
  HistoryPeriod,
  OrganizationPlan,
  ProjectUsage,
} from '@/types/index'
import {
  getStringColor,
  getTimeFromDateTime,
  getMonthAndYearFromDateTime,
  createDateRange,
  calculateOverageCost,
  getDateStringFromDateTime,
} from '@/utils/index'

interface ChartSeriesOptions {
  id?: string
  organizationPlan?: OrganizationPlan
  projects: ProjectUsage[]
  compareProjects?: ProjectUsage[]
  cumulative?: boolean
  isLineChart?: boolean
  mode?: HistoryPeriod
  currentRange?: Range
  compareRange?: Range
}

export interface BillingChartSeriesOptions extends ChartSeriesOptions {
  id?: string
}

export function formatRequestsValue(value: number): string {
  return numeral(value).format('0[.]00a')
}

export function formatDocumentsValue(value: number): string {
  return numeral(value).format('0a')
}

export function formatSimpleNumberValue(value: number): string {
  return numeral(value).format('0')
}

export function formatBytesValue(value: number): string {
  return numeral(value).format('0 b')
}

export function formatBytesValuePrecise(value: number): string {
  return numeral(value).format('0[.]00 b')
}

export function formatGigaBytesValue(value: number): string {
  if (value < 0 || isNaN(value)) {
    return `0 GB`
  }

  return numeral(value).format('0[.]00 b')
}

export function formatQuotaPercent(usage: number, quota: number | null): number {
  quota = quota || 0
  const percent = quota === 0 ? 0 : Math.floor((usage / quota) * 100)
  return percent > 100 ? 0 : percent
}

export function getMaxYValueFromSeries(
  series: ApexAxisChartSeries,
  quota: number | null | undefined = 0,
  stacked = false,
  isCumulative?: boolean
): number {
  if (stacked) {
    // Find the total of values in all data series
    const maxTotal = series
      .map((s) => Math.max(...s.data.map((d) => d.y || 0)))
      .reduce((sum, t) => sum + t, 0)
    const maxCumulativeTotal = isCumulative ? series.map((s) => s.total || 0) : [0]
    // Find the max between quota and data series
    return Math.max(quota || 0, ...maxCumulativeTotal, maxTotal)
  }

  const values = series.map((period) => {
    const {data = []} = period
    // If this is stacked, we need to summarize the values per date
    const dataValues: number[] = data.map((set) => {
      if (typeof set.y === 'string') {
        return 0
      }

      return set.y || 0
    })

    return Math.max(...dataValues)
  })

  return Math.max(quota || 0, ...values)
}

function fillDateGroupPeriodWithPeriods(
  series: Record<string, any> = {},
  mode: HistoryPeriod = 'day',
  fromDate: Date = new Date(),
  toDate: Date = new Date()
) {
  if (mode === 'month') {
    // If this date range is less than 12 months, we should take another parameter
    const range = createDateRange(fromDate, toDate, mode)
    range.forEach((date: Date) => {
      const period = format(date, 'yyyy-MM')
      series[period] = {
        x: period,
        date: period,
        y: null,
        timestamp: null,
      }
    })
    return series
  }

  const range = createDateRange(fromDate, endOfMonth(toDate), mode)
  range.forEach((date: Date) => {
    const day = format(date, 'd')
    const dateKey = format(date, 'yyyy-MM-dd')
    series[dateKey] = {
      x: day,
      date: dateKey,
      y: null,
      timestamp: null,
    }
  })

  return series
}

export function getSeriesForChartSection({
  id,
  projects,
  cumulative = false,
  currentRange,
  mode = 'day',
}: ChartSeriesOptions) {
  const fromDate = currentRange?.fromDate
  const toDate = currentRange?.toDate
  const dateGroups: Record<string, any> = {}
  const chartType = 'bar'

  if (!id) {
    return []
  }

  projects.forEach((project: ProjectUsage) => {
    const dataGroupKey = project.label || project.projectId
    const color = getStringColor(project.projectId) || 'gray'
    const resource = project.resources?.[id] || project.resources?.[id.toLowerCase()]
    let cumulativeValue = 0

    if (!dateGroups?.[dataGroupKey]) {
      dateGroups[dataGroupKey] = {
        id: project.projectId,
        name: dataGroupKey,
        type: chartType,
        color,
        total: resource?.usage || 0,
        data: [],
      }
    }

    const periodMap: Record<string, any> = {}
    fillDateGroupPeriodWithPeriods(periodMap, mode, fromDate, toDate)
    ;(project.history || [])
      .filter((period: AccumulatedPeriodUsage) => {
        return dotProp.get(period.timestamps, id) !== null
      })
      .forEach((period: AccumulatedPeriodUsage) => {
        const periodValue: number = dotProp.get(period.usage, id) || 0
        cumulativeValue += periodValue

        const categoryKey: number | string =
          mode === 'month'
            ? getMonthAndYearFromDateTime(period.period)
            : getDayFromDateTime(period.period).toString()
        const categoryDateKey: number | string =
          mode === 'month'
            ? getMonthAndYearFromDateTime(period.period)
            : getDateStringFromDateTime(period.period)
        const resultValue = cumulative ? cumulativeValue : periodValue
        periodMap[categoryDateKey] = {
          x: categoryKey,
          date: categoryDateKey,
          y: resultValue > 0 ? resultValue : null,
          timestamp: getTimeFromDateTime(period.period),
        }
      })

    dateGroups[dataGroupKey].data = Object.values(periodMap)
  })

  return Object.values(dateGroups).sort((a, b) => (a.total > b.total ? -1 : 1))
}

export function getCompareSeriesForChartSection({
  id,
  projects,
  compareProjects,
  cumulative = false,
  isLineChart = false,
  currentRange,
  compareRange,
  mode = 'day',
}: ChartSeriesOptions): ApexAxisChartSerie[] {
  const dateGroups: Record<string, ApexAxisChartSerie> = {}
  const periodDate = currentRange?.toDate || new Date()
  const compareFromDate = compareRange?.fromDate
  const compareToDate = compareRange?.toDate
  const dateGroupKey: string = format(periodDate, 'yyyy-MM')
  const dataGroupName = format(periodDate, 'MMMM yyyy')
  const currentComparePeriodColor = hues.blue[500].hex
  const previousComparePeriodColor = hues.gray[200].hex
  // const isDifferentMonth =
  //   currentRange?.fromDate &&
  //   currentRange?.toDate &&
  //   !isSameMonth(currentRange?.fromDate, currentRange?.toDate)

  if (!id) {
    return []
  }

  dateGroups[dateGroupKey] = {
    name: dataGroupName,
    type: cumulative || isLineChart ? 'line' : 'bar',
    color: currentComparePeriodColor,
    data: [],
  }

  // Period for projects
  const periodMap: Record<string, any> = {}
  fillDateGroupPeriodWithPeriods(periodMap, mode, currentRange?.fromDate, currentRange?.toDate)

  projects.forEach((project: ProjectUsage) => {
    ;(project.history || [])
      .filter((period: AccumulatedPeriodUsage) => {
        return dotProp.get(period.timestamps, id) !== null
      })
      .forEach((period: AccumulatedPeriodUsage) => {
        const periodValue: number = dotProp.get(period.usage, id) || 0
        const categoryKey: number | string =
          mode === 'month'
            ? getMonthAndYearFromDateTime(period.period)
            : getDayFromDateTime(period.period).toString()
        const categoryDateKey: number | string =
          mode === 'month'
            ? getMonthAndYearFromDateTime(period.period)
            : getDateStringFromDateTime(period.period)
        const currentPeriodValue = periodMap?.[categoryDateKey]?.y || 0
        const resultValue = currentPeriodValue + periodValue
        periodMap[categoryDateKey] = {
          x: categoryKey,
          date: categoryDateKey,
          y: resultValue > 0 ? resultValue : null,
          timestamp: getTimeFromDateTime(period.period),
        }
      })
  })

  dateGroups[dateGroupKey].data = Object.values(periodMap)

  if (cumulative) {
    let previousValue = 0
    dateGroups[dateGroupKey].data = dateGroups[dateGroupKey].data.map((entry: any) => {
      if (entry.y === null) {
        return entry
      }

      previousValue += entry.y

      return {
        ...entry,
        y: previousValue,
      }
    })
  }

  if (compareToDate && compareProjects) {
    const compareDataGroupName = format(compareToDate, 'MMMM yyyy')
    const compareDataGroupKey = format(compareToDate, 'yyyy-MM')
    const comparePeriodMap: Record<string, any> = {}
    fillDateGroupPeriodWithPeriods(comparePeriodMap, mode, compareFromDate, compareToDate)

    compareProjects.forEach((project: ProjectUsage) => {
      ;(project.history || [])
        .filter((period: AccumulatedPeriodUsage) => {
          return dotProp.get(period.timestamps, id) !== null
        })
        .forEach((period: AccumulatedPeriodUsage) => {
          const periodValue: number = dotProp.get(period.usage, id) || 0
          const categoryKey: number | string =
            mode === 'month'
              ? getMonthAndYearFromDateTime(period.period)
              : getDayFromDateTime(period.period).toString()
          const categoryDateKey: number | string =
            mode === 'month'
              ? getMonthAndYearFromDateTime(period.period)
              : getDateStringFromDateTime(period.period)
          const currentPeriodValue = comparePeriodMap?.[categoryDateKey]?.y || 0
          const resultValue = currentPeriodValue + periodValue
          comparePeriodMap[categoryDateKey] = {
            x: categoryKey,
            date: categoryDateKey,
            y: resultValue > 0 ? resultValue : null,
            timestamp: getTimeFromDateTime(period.period),
          }
        })
    })

    dateGroups[compareDataGroupKey] = {
      name: compareDataGroupName,
      type: cumulative || isLineChart ? 'line' : 'bar',
      color: previousComparePeriodColor,
      data: Object.values(comparePeriodMap),
    }

    if (cumulative) {
      let previousValue = 0
      dateGroups[compareDataGroupKey].data = dateGroups[compareDataGroupKey].data.map((entry) => {
        if (entry.y === null) {
          return entry
        }

        previousValue += entry.y

        return {
          ...entry,
          y: previousValue,
        }
      })
    }
  }

  return Object.values(dateGroups).reverse()
}

type PeriodResourceGroup = {
  id: string
  label: string
}

type OverageProjectInfo = {
  quota?: number
  basePrice?: number
  overageChunkPrice?: number
  overageChunkSize?: number
}

function getOverageProjectInfo(
  resourceId: string,
  projectUsage: ProjectUsage,
  orgPlan?: OrganizationPlan
): OverageProjectInfo {
  if (orgPlan) {
    const resource =
      orgPlan?.resources?.[resourceId] || orgPlan?.resources?.[resourceId.toLowerCase()]
    return {
      quota: resource?.quota,
      basePrice: undefined,
      overageChunkPrice: resource?.overageChunkPrice,
      overageChunkSize: resource?.overageChunkSize,
    }
  }

  const resources: any = projectUsage.resources
  const resource: any = resources?.[resourceId] || resources?.[resourceId.toLowerCase()] || {}

  return {
    basePrice: projectUsage.planCost,
    quota: resource?.quota,
    overageChunkPrice: resource?.overageChunkPrice,
    overageChunkSize: resource?.overageChunkSize,
  }
}

type PeriodQuotaInfo = {
  totalForGroup: number
  totalForPeriod: number
  quota: number
  hitQuota: boolean
  overageChunkSize?: number
  overageChunkPrice?: number
}

export function getCompareSeriesForBilling({
  organizationPlan,
  projects,
  currentRange,
  mode = 'month',
}: BillingChartSeriesOptions): ApexAxisChartSerie[] {
  const fromDate = currentRange?.fromDate || startOfMonth(new Date())
  const toDate = currentRange?.toDate || new Date()
  const isMonthMode = mode === 'month'
  const normalOrgPlanGroup: PeriodResourceGroup = {
    id: 'plans',
    label: 'Plans',
  }

  const resourceGroups: PeriodResourceGroup[] = [
    {
      id: 'bandwidth',
      label: 'Bandwidth',
    },
    {
      id: 'apiRequests',
      label: 'API Requests',
    },
    {
      id: 'apiCdnRequests',
      label: 'API CDN Requests',
    },
    {
      id: 'assets',
      label: 'Assets',
    },
    {
      id: 'documents',
      label: 'Documents',
    },
    {
      id: 'users',
      label: 'Users',
    },
    {
      id: 'datasets',
      label: 'Datasets',
    },
  ]

  const dateGroups: Record<string, ApexAxisChartSerie> = {}
  // const dateGroupKey: string = format(periodDate, 'yyyy-MM')
  const isNormalOrg = !organizationPlan
  const chartColors = [
    hues.green[700].hex,
    hues.green[600].hex,
    hues.green[500].hex,
    hues.green[400].hex,
    hues.green[300].hex,
    hues.green[200].hex,
    hues.green[100].hex,
    hues.green[50].hex,
  ]

  // For each project
  // - Loop through history
  // - Filter out only ones with overage
  // - Calculate overage based on price and overageChunkPrice / Unit
  if (isNormalOrg) {
    dateGroups[normalOrgPlanGroup.id] = {
      name: normalOrgPlanGroup.label,
      type: 'bar',
      color: chartColors[0],
      data: [],
    }

    const periodMap: Record<string, any> = {}
    fillDateGroupPeriodWithPeriods(periodMap, mode, fromDate, toDate)

    dateGroups[normalOrgPlanGroup.id].data = Object.values(periodMap)
  }

  resourceGroups.forEach((group, index) => {
    const dateGroupKey = group.id
    dateGroups[dateGroupKey] = {
      name: group.label,
      type: 'bar',
      color: isNormalOrg
        ? chartColors?.[index + 1] || chartColors[6]
        : chartColors?.[index] || chartColors[6],
      data: [],
    }
  })

  if (!organizationPlan) {
    const periodMap: Record<string, any> = {}
    const periodTotalPriceMap: Record<string, number> = {}
    fillDateGroupPeriodWithPeriods(periodMap, mode, fromDate, toDate)

    // Limit adding base prices total from starting data
    let totalBasePrice = 0
    let previousMonth: string | undefined

    // Summarize base price
    projects.forEach((project: ProjectUsage) => {
      if (project.planStartedAt) {
        const startingDate = format(parseISO(project.planStartedAt), 'yyyy-MM')

        // Once we switch period, we add running total I guess?
        // This will trigger when null and when switching month
        if (startingDate !== previousMonth) {
          // First we update running total from previous month
          if (previousMonth) {
            periodTotalPriceMap[startingDate] = periodTotalPriceMap?.[previousMonth]
          }

          // Now we set it to current month
          previousMonth = startingDate
        }

        periodTotalPriceMap[startingDate] =
          (periodTotalPriceMap?.[startingDate] || 0) + (project.planCost || 0)
      }

      totalBasePrice += project.planCost || 0
    })

    // Add project base price
    // Add this to every period from subscription `startedAt` and as long as `endedAt` is null not send end date
    // Here we loop through period map and get current running baseprice for every y
    if (totalBasePrice && totalBasePrice > 0) {
      let currentRunningTotal = 0

      dateGroups[normalOrgPlanGroup.id].data = Object.values(periodMap).map((p) => {
        if (periodTotalPriceMap?.[p.date]) {
          currentRunningTotal = periodTotalPriceMap[p.date]
        }

        p.y = currentRunningTotal

        return p
      })
    }
  }

  resourceGroups.forEach((group, index) => {
    const dateGroupKey = group.id
    dateGroups[dateGroupKey] = {
      name: group.label,
      type: 'bar',
      total: 0,
      color: chartColors?.[index] || chartColors[5],
      data: [],
    }
    let totalForGroup = 0
    // let totalForPeriod = 0
    // let hitQuota = false
    const periodMap: Record<string, any> = {}
    const periodQuotaInfo: Record<string, PeriodQuotaInfo> = {}
    fillDateGroupPeriodWithPeriods(periodMap, mode, fromDate, toDate)

    // If this is org plan, we gotta keep the

    projects.forEach((project: ProjectUsage) => {
      const {
        basePrice,
        quota,
        overageChunkPrice = 0,
        overageChunkSize = 0,
      } = getOverageProjectInfo(group.id, project, organizationPlan)

      // @todo Handle non-org plan
      if (!organizationPlan) {
        // If this isn't a organization plan: We reset the quota count for each project
        // hitQuota = false
        // totalForPeriod = 0
        // totalForGroup = 0

        // Add project base price
        // Add this to every period from subscription `startedAt` and as long as `endedAt` is null not send end date
        if (basePrice && !dateGroups[normalOrgPlanGroup.id].data?.[0]) {
          const firstDayOfMonth = setDate(fromDate, 1)
          const categoryKey: string =
            mode === 'month'
              ? getMonthAndYearFromDateTime(format(firstDayOfMonth, 'yyyy-MM-dd'))
              : getDateStringFromDateTime(format(firstDayOfMonth, 'yyyy-MM-dd'))
          dateGroups[normalOrgPlanGroup.id].data = [
            {
              date: categoryKey,
              x: categoryKey,
              y: basePrice > 0 ? basePrice : null,
              timestamp: getTimeFromDateTime(format(firstDayOfMonth, 'yyyy-MM-dd')),
            },
          ]
        }
      }

      // We are looping out the whole range per period, and it might cross different periods.
      // This means we probably need to reset per period across projects
      // So instead of one global variable for hitting the quote, we store it per period (YEAR-MONTH)

      ;(project.history || [])
        // .filter((period: AccumulatedPeriodUsage) => {
        //   return dotProp.get(period.timestamps, id) !== null
        // })
        .forEach((period: AccumulatedPeriodUsage) => {
          // If there isn't any period quota info, we add it
          const periodDateString = getMonthAndYearFromDateTime(period.period)
          const periodUsageValue: number = dotProp.get(period.usage, group.id) || 0
          const periodQuotaValue: number = quota || dotProp.get(period.quota, group.id) || 0

          if (!periodQuotaInfo?.[periodDateString]) {
            periodQuotaInfo[periodDateString] = {
              hitQuota: false,
              totalForPeriod: 0,
              quota: periodQuotaValue,
              overageChunkSize,
              overageChunkPrice,
              totalForGroup: 0,
            }
          }

          // If this is a organization plan, we fetch the quota from the plan
          let overageValue = 0
          periodQuotaInfo[periodDateString].totalForPeriod += periodUsageValue

          if (!organizationPlan) {
            // If this is a regular project, we calculate the overage per period as normal
            overageValue = calculateOverageCost(
              periodUsageValue,
              periodQuotaValue,
              overageChunkSize,
              overageChunkPrice
            )
            totalForGroup += Math.floor(overageValue)
          }

          // const periodUnitValue: number = dotProp.get(period.unit, group.id) || 0
          const categoryKey: number | string = isMonthMode
            ? getMonthAndYearFromDateTime(period.period)
            : getDateStringFromDateTime(period.period)
          // const currentPeriodValue = periodMap?.[categoryKey]?.y || 0
          const periodTotal = (periodMap?.[categoryKey]?.y || 0) + overageValue
          periodMap[categoryKey] = {
            date: categoryKey,
            x: categoryKey,
            y: periodTotal > 0 ? periodTotal : null,
            timestamp: getTimeFromDateTime(period.period),
          }
        })
    })

    // If this is a org plan, we summarize at the end because the quota is for all projects in total
    if (organizationPlan) {
      Object.entries(periodQuotaInfo).forEach(([key, info]) => {
        if (periodMap?.[key]) {
          const {overageChunkPrice, overageChunkSize, quota, totalForPeriod} = info
          const periodOverageCost = calculateOverageCost(
            totalForPeriod,
            quota,
            overageChunkSize || 0,
            overageChunkPrice || 0
          )
          periodMap[key].y = periodOverageCost > 0 ? periodOverageCost : null
          totalForGroup += periodOverageCost || 0
        }
      })
    }

    // Add series data
    dateGroups[dateGroupKey].data = Object.values(periodMap)
    dateGroups[dateGroupKey].total = totalForGroup
  })

  const values = Object.values(dateGroups)
    .reverse()
    .filter((group) => (group.total || 0) > 0)
    .map((group, index) => {
      group.color = chartColors?.[index] || chartColors[5]
      return group
    })

  return values
}

// export function getChartConfig({
//   dataSeries,
//   stacked = false,
//   chartType = 'bar',
//   options,
//   curveStroke = 'smooth',
//   period = 'day',
//   isCumulative = false,
//   isLoading = false,
//   isPlayground = false,
//   isFullHistory = false,
//   yAxisFormat,
//   currentPlanQuota,
//   chartRef,
//   showQuotaAnnotation = false,
//   usageType,
// }: ChartDataStacked) {
//   const formatters = new Map([
//     ['number', formatRequestsValue],
//     ['documents', formatDocumentsValue],
//     ['size', formatRequestsValue],
//     ['bytesPrecise', formatRequestsValue],
//     ['gigaBytes', formatGigaBytesValue],
//   ])
//   const yAxisFormatter = formatters.get(yAxisFormat || '') || formatters.get('number')
//   let xAxisFormatter = (value: number | string): string => {
//     if (period === 'month') {
//       if (value > 12) {
//         value = 12
//       }
//       if (value < 10) {
//         value = `0${value}`
//       }
//       return format(parseISO(`2020-${value || '01'}-01`), 'MMM')
//     }

//     return parseInt(`${value}`, 10).toString()
//   }
//   const yAxisAnnotations: YAxisAnnotations[] = []
//   const maxYValueFromSeries = getMaxYValueFromSeries(
//     dataSeries,
//     isCumulative ? currentPlanQuota : 0
//   )

//   if (isFullHistory) {
//     xAxisFormatter = (value: number | string): string => {
//       if (typeof value === 'number') {
//         return format(value, 'd. MMM')
//       }
//       return format(parse(`${value}`, 'T', new Date()), 'd. MMM')
//     }
//   }

//   if (currentPlanQuota && showQuotaAnnotation) {
//     yAxisAnnotations.push({
//       y: currentPlanQuota,
//       offsetX: 90,
//       borderColor: hues.green[400].hex,
//       strokeDashArray: 1,
//       label: {
//         position: 'left',
//         textAnchor: 'start',
//         borderColor: 'transparent',
//         offsetY: 6,
//         offsetX: -8,
//         style: {
//           color: hues.green[400].hex,
//           fontSize: '10px',
//           fontFamily: studioTheme.fonts.text.family,
//           padding: {
//             left: 5,
//             right: 5,
//             bottom: 5,
//           },
//         },
//         text: 'Current plan quota',
//       },
//     })
//   }

//   options = {
//     ...options,
//     chart: {
//       animations: {
//         enabled: false,
//       },
//       stacked,
//       fontFamily: studioTheme.fonts.text.family,
//       id: 'linechart',
//       toolbar: {
//         show: false,
//       },
//       zoom: {
//         enabled: false,
//       },
//       offsetX: -15,
//       events: {
//         mounted: (ctx) => {
//           if (chartRef && !chartRef.current) {
//             chartRef.current = ctx
//           }

//           if (isPlayground) {
//             const serie = dataSeries[dataSeries.length - 1]
//             const lastX = serie?.data.length - 1
//             const {x, y} = serie?.data[lastX]
//             ctx?.addPointAnnotation({
//               x,
//               y,
//               marker: {
//                 size: 3,
//                 strokeWidth: 12,
//                 fillColor: hues.blue[500].hex,
//                 strokeColor: hues.blue[500].hex,
//               },
//             })
//           }
//         },
//       },
//     },
//     // colors: dataSeries.length > 1 ? ['#F1F3F6', hues.blue[500].hex] : [hues.blue[500].hex],
//     colors: COLORS,
//     dataLabels: {
//       enabled: false,
//     },
//     fill: {
//       colors: COLORS,
//       type: 'solid',
//       opacity: 1,
//     },
//     stroke: {
//       width: 2,
//       curve: curveStroke,
//     },
//     grid: {
//       borderColor: hues.gray[100].hex,
//       yaxis: {
//         lines: {
//           show: true,
//         },
//       },
//     },
//     plotOptions: {
//       bar: {
//         horizontal: false,
//         columnWidth: period === 'month' ? '50%' : '50%',
//         // endingShape: 'rounded',
//         // This can't be 8 - it will create a too big of a border and cause a weird shape
//         // borderRadius: 0,
//       },
//     },
//     legend: {
//       show: false,
//       onItemClick: {
//         toggleDataSeries: false,
//       },
//     },
//     xaxis: {
//       tickAmount: 'dataPoints',
//       labels: {
//         style: {
//           colors: hues.gray[600].hex,
//         },
//         formatter: xAxisFormatter,
//       },
//       axisBorder: {
//         show: false,
//         color: hues.gray['100'].hex,
//         offsetX: 0,
//         offsetY: 0,
//       },
//       axisTicks: {
//         show: false,
//       },
//       // type: isFullHistory ? 'datetime' : 'category',
//       type: 'category',
//     },
//     yaxis: {
//       max: maxYValueFromSeries,
//       min: 0,
//       tickAmount: Math.min(2, maxYValueFromSeries / 10),
//       axisBorder: {
//         show: false,
//         color: hues.gray[100].hex,
//         offsetX: 0,
//         offsetY: 0,
//       },
//       labels: {
//         // align: 'left',
//         // offsetX: 10,
//         style: {
//           colors: hues.gray[600].hex,
//           fontFamily: studioTheme.fonts.text.family,
//         },
//         formatter: yAxisFormatter,
//       },
//     },
//     tooltip: {
//       custom: ({series, seriesIndex, dataPointIndex /* w */}) =>
//         renderToString(
//           <Tooltip
//             series={series}
//             seriesIndex={seriesIndex}
//             dataPointIndex={dataPointIndex}
//             formatter={yAxisFormatter}
//           />
//         ),
//       x: {
//         show: false,
//         formatter: (val: number, opts?: any) => {
//           const {seriesIndex} = opts
//           /**
//            * Because Apex wants to put timestamped series after eachother in the graph instead of overlayed,
//            * we 1) fallback to the category option 2) Set the day # (so 1-31) on the x axis 3) fetch the timestamp from the
//            * series object with the provided index when we want to display the tooltip
//            */
//           if (typeof seriesIndex !== 'undefined') {
//             const {dataPointIndex, w} = opts
//             const timestamp = w.config.series[seriesIndex].data[dataPointIndex].timestamp

//             if (timestamp) {
//               return format(parse(`${timestamp}`, 'T', new Date()), 'MMMM do yyyy')
//             }
//           }

//           return format(parse(`${val}`, 'T', new Date()), 'MMMM do yyyy')
//         },
//       },
//     },
//     annotations: {
//       yaxis: yAxisAnnotations,
//     },
//   }
//   const hasData = maxYValueFromSeries !== 0

//   return {
//     options,
//     hasData,
//   }
// }
