import {useConfig} from '@/context/index'
import {
  DownloadableFile,
  createRequestLogFile,
  getDownloadUrl,
  getRequestLogFile,
} from '@/data/projects'
import {isAPIError} from '@/data/util/promiseRequest'
import {Project} from '@/types/models'
import {ExternalLink, PermissionButtonProject, TabSectionHeader} from '@/ui/index'
import {IfFeatureEnabled} from '@growthbook/growthbook-react'
import {DocumentZipIcon, DownloadIcon, RetryIcon} from '@sanity/icons'
import {Card, CardTone, Flex, Heading, Spinner, Stack, Text, useToast} from '@sanity/ui'
import {useMutation, useQuery} from '@tanstack/react-query'
import {parseISO, subDays} from 'date-fns'
import {useEffect, useMemo, useState} from 'react'

interface LogDownloadContentProps {
  file: DownloadableFile | null
  onGenerate: () => void
  onDownload: () => void
  isLoading: boolean
}

interface LogStateContent {
  header: string
  description: string
  tone: CardTone
  button: React.ReactNode
}

function CenteredSpinner() {
  return (
    <Flex justify={'center'} align={'center'} padding={4}>
      <Spinner />
    </Flex>
  )
}

function LogDownloadHeader() {
  const {baseUrl} = useConfig()
  return (
    <>
      <Text>
        Export request log data (up to 1GB) from the last 7 days, not including today. This can be
        helpful in examining your API and bandwidth usage.
      </Text>
      <ExternalLink
        url={`${baseUrl}/docs/request-logs`}
        text={'Learn more about request logs in the Sanity Docs '}
      />
    </>
  )
}

function LogDownloadContent({file, onGenerate, onDownload, isLoading}: LogDownloadContentProps) {
  const getLogStateContent = useMemo((): LogStateContent => {
    if (!file || !file.metadata || file.metadata.state === 'expired') {
      return {
        header: '',
        description: '',
        tone: 'default',
        button: (
          <PermissionButtonProject
            title={'Generate'}
            icon={DocumentZipIcon}
            onClick={onGenerate}
            permissions={[{permissionName: 'sanity.project', grantName: 'read'}]}
          />
        ),
      }
    }

    const fromDate = parseISO(file.metadata.dateStart).toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
    })
    // Subtract one day from the end date to get the last full day of logs
    // This timestamp represents 00:00:00 of the last day included in the log
    const dateEndTimestamp = subDays(parseISO(file.metadata.dateEnd), 1).getTime()
    const toDate = new Date(dateEndTimestamp).toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
    })

    switch (file.metadata.state) {
      case 'requested':
        return {
          header: `Generating logs from ${fromDate} to ${toDate}`,
          description: 'Please wait, your export will be available soon.',
          tone: 'caution',
          button: (
            <PermissionButtonProject
              title={'Processing'}
              icon={
                <Flex padding={1}>
                  <Spinner />
                </Flex>
              }
              mode="default"
              tone="caution"
            />
          ),
        }
      case 'created':
        return {
          header: `Logs available from ${fromDate} to ${toDate}`,
          description: `This export contains the logs from the selected period. Click to download and inspect. A new export can be generated every 24 hours.`,
          tone: 'positive',
          button: (
            <PermissionButtonProject
              title={'Download'}
              icon={DownloadIcon}
              onClick={onDownload}
              mode="default"
              tone="positive"
            />
          ),
        }
      case 'failed':
        return {
          header: `Failed to generate export`,
          description: `Please try again later, if problem persists please contact support (Error code: ${file.metadata.errorCode}).`,
          tone: 'critical',
          button: (
            <PermissionButtonProject
              title={'Retry'}
              icon={RetryIcon}
              onClick={onGenerate}
              mode="default"
              tone="critical"
              disabled={isLoading}
            />
          ),
        }
      default:
        throw new Error(`Unknown state: ${file.metadata.state}`)
    }
  }, [file, onGenerate, onDownload, isLoading])

  const {header, description, tone, button} = getLogStateContent

  if (!file || !file.metadata || file.metadata.state === 'expired') {
    return (
      <PermissionButtonProject
        title={'Generate'}
        icon={DocumentZipIcon}
        onClick={onGenerate}
        permissions={[{permissionName: 'sanity.project', grantName: 'read'}]}
      />
    )
  }

  return (
    <Card tone={tone} padding={3} border>
      <Flex direction="row" style={{width: '100%', minHeight: '100%'}}>
        <Flex flex={1} padding={4}>
          <Stack space={4}>
            <Heading size={1}>{header}</Heading>
            <Text size={1} muted style={{maxWidth: 550}}>
              {description}
            </Text>
          </Stack>
        </Flex>
        <Flex padding={4}>{button}</Flex>
      </Flex>
    </Card>
  )
}

function DownloadControl({
  downloadableFile,
  projectId,
}: {
  downloadableFile: DownloadableFile | null
  projectId: string
}) {
  const [file, setFile] = useState<DownloadableFile | null>(downloadableFile)
  const toast = useToast()

  const generateMutation = useMutation({
    mutationKey: ['logDownload', 'generate'],
    mutationFn: () => createRequestLogFile(projectId),
    onSuccess: (result) => {
      setFile(result)
    },
    onError: (err: Error) => {
      toast.push({status: 'error', title: `Error generating file: ${err.message}.`})
    },
  })

  const {
    data: updatedFile,
    isLoading,
    error: pollError,
  } = useQuery({
    queryKey: ['logDownload', 'poll', projectId],
    queryFn: () => getRequestLogFile(projectId),
    enabled: !!file && file.metadata?.state === 'requested',
    refetchInterval: 2000,
  })

  useEffect(() => {
    if (!isLoading && pollError) {
      if (isAPIError(pollError)) {
        toast.push({
          status: 'error',
          title: 'Failed to get the request log file status.',
        })
      } else {
        toast.push({status: 'error', title: 'Error checking for file change.'})
      }
    }
  }, [pollError, isLoading, toast])

  useEffect(() => {
    if (updatedFile && !isLoading) {
      setFile(updatedFile)
    }
  }, [updatedFile, isLoading, setFile])

  const handleGenerateFileClick = () => {
    if (isLoading) {
      return
    }
    generateMutation.mutate()
  }

  const handleDownloadFileClick = () => {
    if (isLoading) {
      return
    }
    window.open(getDownloadUrl(projectId), '_blank')
  }

  if (isLoading) {
    return <CenteredSpinner />
  }

  return (
    <LogDownloadContent
      file={file}
      onGenerate={handleGenerateFileClick}
      onDownload={handleDownloadFileClick}
      isLoading={isLoading}
    />
  )
}

function RequestLogsDownload({project}: {project: Project}) {
  const {data, isLoading, isError, error} = useQuery({
    queryKey: ['logDownload', 'downloadFiles', project.id],
    queryFn: () => {
      try {
        const file = getRequestLogFile(project.id)
        return file
      } catch (err) {
        if (isAPIError(err) && err.statusCode === 404) {
          return null
        }

        throw error
      }
    },
  })

  if (isLoading) {
    return <CenteredSpinner />
  }

  if (isError && isAPIError(error) && error.statusCode !== 404) {
    return (
      <Text as="p">
        Feature not available, please try again later. If problem persists please contact support.
      </Text>
    )
  }

  return <DownloadControl downloadableFile={data ?? null} projectId={project.id} />
}

export default function LogDownloadSection({project, title}: {project: Project; title: string}) {
  return (
    <IfFeatureEnabled feature="manage-log-downloads">
      <TabSectionHeader title={title} />
      <LogDownloadHeader />
      <RequestLogsDownload project={project} />
    </IfFeatureEnabled>
  )
}
