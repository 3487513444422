import React from 'react'
import {useToast} from '@sanity/ui'
import {List, MemberPreview} from '@/ui/index'
import {DialogToast, Project, ProjectInvitation} from '@/types/index'
import {useProjectInvitations} from '@/data/invitations'

type Props = {
  project: Project
  invitations: ProjectInvitation[]
  hideDialog: (dialogToast: DialogToast) => void
  Dialog: React.ElementType
}

export const RevokeProjectInvitationsDialog = ({
  project,
  invitations,
  Dialog,
  hideDialog,
}: Props) => {
  const {revokeInvitations, isRevoking} = useProjectInvitations(project.id)
  const invitationPluralPrefixed = invitations.length === 1 ? 'invitation' : 'invitations'
  const toast = useToast()

  const handleRevokeInvitations = () => {
    revokeInvitations(invitations, {
      onSuccess: (results) => {
        const hasErrors = results.some((result) => !result.success)

        if (hasErrors) {
          const errorMap = new Map()
          results
            .filter((result) => result.error)
            .forEach((result) => {
              const {error} = result

              if (error?.statusCode === 402) {
                const body = JSON.parse(error.response)
                const {error: errorCode, message} = body
                errorMap.set(errorCode, message)
              }
            })

          errorMap.forEach((errorMessage, errorCode) => {
            toast.push({
              title: `${errorCode}`,
              description: `${errorMessage}`,
              status: 'error',
              duration: 5000,
            })
          })
        } else {
          hideDialog({
            title: 'Revoked invitation to project',
            status: 'success',
          })
        }
      },
      onError: () => {
        hideDialog({
          description: `Failed to revoke the ${invitationPluralPrefixed} from the project`,
          status: 'error',
        })
      },
    })
  }

  return (
    <Dialog
      id="remove-project-member"
      header={`Revoke ${invitationPluralPrefixed} to project`}
      title={
        invitations.length === 1
          ? `Do you really want to revoke the invitation sent to ${invitations[0]?.email}?`
          : `Do you really want to revoke ${invitations.length} invitation${
              invitations.length === 1 ? '' : 's'
            }?`
      }
      description={
        invitations.length === 1
          ? `${invitations[0].email} will no longer be able to join the project.`
          : `They will no longer be able to join the project.`
      }
      onConfirm={handleRevokeInvitations}
      loading={isRevoking}
    >
      <List>
        {invitations.map((invitation) => (
          <li key={invitation.id}>
            <MemberPreview
              title={invitation.email}
              userId={invitation.invitedByUserId}
              subtitle={invitation.roles ? invitation.roles[0].title : 'Unknown role'}
            />
          </li>
        ))}
      </List>
    </Dialog>
  )
}
