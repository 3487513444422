import {BlockHeading} from './blockHeading'
import {BlockList} from './blockList'
import {BlockText} from './blockText'
import {Box} from '@sanity/ui'
import {ListItemBullet} from './listItemBullet'
import {ListItemSection} from './listItemSection'
import {MarkCode} from './markCode'
import {MarkLink} from './markLink'
import {TypeCode} from './typeCode'
import {type PortableTextComponents} from '@portabletext/react'
import {ListItemNote} from './listItemNote'

export const portableTextComponents: PortableTextComponents = {
  block: {
    h2: ({children}) => <BlockHeading as="h2">{children}</BlockHeading>,
    h3: ({children}) => <BlockHeading as="h3">{children}</BlockHeading>,
    h4: ({children}) => <BlockHeading as="h4">{children}</BlockHeading>,
    normal: ({children}) => <BlockText>{children}</BlockText>,
    list: BlockList,
  },
  types: {
    code: TypeCode,
  },
  marks: {
    link: MarkLink,
    code: MarkCode,
  },
  list: {
    section: ({children}) => <Box>{children}</Box>,
  },
  listItem: {
    section: ListItemSection,
    // Map all list items to the same component
    bullet: ListItemBullet,
    number: ListItemBullet,
    action: ListItemBullet,
    task: ListItemBullet,
    note: ListItemNote,
  },
}
