export const ThumbsUpIcon = () => (
  <svg width="1em" height="1em" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="ThumbsUp">
      <path
        id="Vector"
        d="M22.8516 7.82422C22.6316 7.57493 22.3611 7.37529 22.058 7.23857C21.7549 7.10186 21.4262 7.03118 21.0938 7.03125H15.625V5.46875C15.625 4.43275 15.2134 3.43918 14.4809 2.70661C13.7483 1.97405 12.7548 1.5625 11.7188 1.5625C11.5736 1.5624 11.4313 1.60273 11.3078 1.67897C11.1843 1.75521 11.0845 1.86434 11.0195 1.99414L7.33008 9.375H3.125C2.7106 9.375 2.31317 9.53962 2.02015 9.83265C1.72712 10.1257 1.5625 10.5231 1.5625 10.9375V19.5312C1.5625 19.9457 1.72712 20.3431 2.02015 20.6361C2.31317 20.9291 2.7106 21.0938 3.125 21.0938H19.9219C20.4929 21.0939 21.0443 20.8857 21.4726 20.5081C21.9009 20.1305 22.1767 19.6095 22.248 19.043L23.4199 9.66797C23.4615 9.33791 23.4323 9.0028 23.3343 8.6849C23.2363 8.367 23.0717 8.07361 22.8516 7.82422ZM3.125 10.9375H7.03125V19.5312H3.125V10.9375ZM21.8691 9.47266L20.6973 18.8477C20.6735 19.0365 20.5816 19.2102 20.4388 19.336C20.296 19.4619 20.1122 19.5313 19.9219 19.5312H8.59375V10.3408L12.1787 3.16992C12.71 3.27627 13.1881 3.56343 13.5316 3.98256C13.875 4.40169 14.0627 4.92687 14.0625 5.46875V7.8125C14.0625 8.0197 14.1448 8.21841 14.2913 8.36493C14.4378 8.51144 14.6365 8.59375 14.8438 8.59375H21.0938C21.2046 8.59371 21.3142 8.61727 21.4153 8.66286C21.5163 8.70844 21.6065 8.77502 21.6799 8.85815C21.7532 8.94129 21.808 9.03908 21.8407 9.14503C21.8733 9.25098 21.883 9.36266 21.8691 9.47266Z"
        fill="var(--card-icon-color)"
      />
    </g>
  </svg>
)

export const ThumbsDownIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    transform="rotate(180)"
  >
    <g id="ThumbsUp">
      <path
        id="Vector"
        d="M22.8516 7.82422C22.6316 7.57493 22.3611 7.37529 22.058 7.23857C21.7549 7.10186 21.4262 7.03118 21.0938 7.03125H15.625V5.46875C15.625 4.43275 15.2134 3.43918 14.4809 2.70661C13.7483 1.97405 12.7548 1.5625 11.7188 1.5625C11.5736 1.5624 11.4313 1.60273 11.3078 1.67897C11.1843 1.75521 11.0845 1.86434 11.0195 1.99414L7.33008 9.375H3.125C2.7106 9.375 2.31317 9.53962 2.02015 9.83265C1.72712 10.1257 1.5625 10.5231 1.5625 10.9375V19.5312C1.5625 19.9457 1.72712 20.3431 2.02015 20.6361C2.31317 20.9291 2.7106 21.0938 3.125 21.0938H19.9219C20.4929 21.0939 21.0443 20.8857 21.4726 20.5081C21.9009 20.1305 22.1767 19.6095 22.248 19.043L23.4199 9.66797C23.4615 9.33791 23.4323 9.0028 23.3343 8.6849C23.2363 8.367 23.0717 8.07361 22.8516 7.82422ZM3.125 10.9375H7.03125V19.5312H3.125V10.9375ZM21.8691 9.47266L20.6973 18.8477C20.6735 19.0365 20.5816 19.2102 20.4388 19.336C20.296 19.4619 20.1122 19.5313 19.9219 19.5312H8.59375V10.3408L12.1787 3.16992C12.71 3.27627 13.1881 3.56343 13.5316 3.98256C13.875 4.40169 14.0627 4.92687 14.0625 5.46875V7.8125C14.0625 8.0197 14.1448 8.21841 14.2913 8.36493C14.4378 8.51144 14.6365 8.59375 14.8438 8.59375H21.0938C21.2046 8.59371 21.3142 8.61727 21.4153 8.66286C21.5163 8.70844 21.6065 8.77502 21.6799 8.85815C21.7532 8.94129 21.808 9.03908 21.8407 9.14503C21.8733 9.25098 21.883 9.36266 21.8691 9.47266Z"
        fill="var(--card-icon-color)"
      />
    </g>
  </svg>
)
