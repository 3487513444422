import {PermissionGrant} from '../../types/models'
import {request} from '../util/promiseRequest'

async function requestGrants(organizationId?: string) {
  if (organizationId === undefined || organizationId === 'personal') {
    return {}
  }

  const res = await request<PermissionGrant>({
    url: `https://api.${process.env.host}/v2022-08-11/organizations/${organizationId}/grants`,
  })

  if (res.statusCode === 401 || res.statusCode === 404) {
    return {}
  }

  if (!res.ok) {
    throw new Error(res.body.message)
  }
  return res.body
}

export {requestGrants as requestOrganizationGrants}
