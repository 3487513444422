import React, {useCallback, useMemo, useState} from 'react'
import {Box, ButtonProps, Card, Flex, Heading, Stack, Text} from '@sanity/ui'
import {CommentIcon, CheckmarkCircleIcon} from '@sanity/icons'
import {formatPrice} from '@/utils/general'
import {Plan} from '@/types/index'
import {
  ButtonWithExtendedTones,
  ButtonWithExtendedTonesProps,
} from '../../general/ButtonWithExtendedTones'
import styled from 'styled-components'
import {useRequestUpgradeDialog} from '@/data/experienceDialogs'
import {PermissionTooltip, RequestUpgradeDialog} from '../..'
import {useCurrentScopeContext} from '@/context/index'
import {sendAmplitudeTrackingEvent} from '@/utils/tracking'
import {useOrganizationCanPay, useProjectSubscription} from '@/data/index'
import {useCanOpenCheckout} from '@/context/useCanOpenCheckout'

type Props = {
  plan: Plan
  description?: string
  listHeader?: string
  listItems?: string[]
  paymentDescription: string | undefined
  onSelectPlan: (plan: Plan) => void
}

const PriceText = styled(Heading)`
  font-weight: 400;
`

export function PlanCard({plan, description, listHeader, listItems, onSelectPlan}: Props) {
  const {project, org} = useCurrentScopeContext()
  const {data: subscription} = useProjectSubscription(project?.id)
  const {data: canPay} = useOrganizationCanPay(org?.id)

  const [showRequestUpgradeDialog, setShowRequestUpgradeDialog] = useState(false)
  const {data: requestUpgradeDialog, isError} = useRequestUpgradeDialog()

  const {id, planTypeId, name: planName, price, pricingModel, isBasePlan} = plan
  const currentPlan = subscription?.plan
  const isCurrentPlan = id === currentPlan?.id
  const isCurrentPlanType = planTypeId === currentPlan?.planTypeId
  const currentPlanName = subscription?.plan?.name
  const isGrowthPlan = planTypeId === 'growth' || planTypeId === 'growth-trial'

  // Check if plan is a downgrade option
  const isDowngradeOption = useMemo(() => {
    if (planTypeId === 'enterprise' || pricingModel === 'per-seat') {
      // per-seat is always an upgrade for any non-enterprise plan
      return false
    }
    if (currentPlan?.planTypeId !== 'free' && planTypeId === 'free') return true
    return (currentPlan?.price ?? 0) > (price ?? 0)
  }, [currentPlan?.price])
  const willAutoDowngrade = currentPlan?.planTypeId === 'growth-trial'
  const isSwitch = planTypeId && isCurrentPlanType && isBasePlan

  const canOpenCheckout = useCanOpenCheckout()
  const hasPermission = canOpenCheckout || planTypeId === 'enterprise'

  const handleSelectPlan = useCallback(() => {
    sendAmplitudeTrackingEvent('Switch Plan CTA Clicked', project?.id, org?.id, {
      plan: planTypeId,
      source: 'plan_card',
      hasValidBilling: canPay?.status,
      canChangeToPlan: canOpenCheckout,
    })
    if (onSelectPlan && planTypeId !== 'enterprise') {
      onSelectPlan(plan)
    }
  }, [onSelectPlan])

  const openRequestUpgradeDialog = useCallback(() => {
    sendAmplitudeTrackingEvent('Request Upgrade Dialog Viewed', project?.id, org?.id, {
      requested_plan: planName,
      current_plan: currentPlanName,
    })
    setShowRequestUpgradeDialog(true)
  }, [project?.id, org?.id])

  const buttonProps = useMemo(() => {
    let buttonProps: ButtonWithExtendedTonesProps

    if (isCurrentPlan) {
      buttonProps = {text: 'Current plan', mode: 'ghost', disabled: true}
    } else if (isDowngradeOption) {
      buttonProps = {text: `Downgrade to ${planName}`, tone: 'critical', mode: 'ghost'}
    } else if (isSwitch) {
      buttonProps = {text: `Switch to ${planName}`, mode: 'ghost'}
    } else {
      buttonProps = {
        text: `Upgrade to ${planName}`,
        tone: 'purple',
        mode: 'ghost',
      }
    }

    if (currentPlan?.planTypeId === 'business' && planTypeId === 'growth') {
      buttonProps = {...buttonProps, text: `Switch to ${planName}`}
    }

    if (planTypeId === 'enterprise') {
      return {
        as: 'a',
        text: 'Contact sales',
        tone: 'default',
        target: '_blank',
        rel: 'noreferrer',
        href: `https://www.${process.env.host}/contact/sales?ref=manage-plan-options`,
      } as ButtonProps
    }
    return buttonProps
  }, [currentPlan?.planTypeId, isDowngradeOption, isSwitch, isCurrentPlan])

  return (
    <Card padding={3} border radius={3}>
      <Flex direction="column" justify="space-between" style={{height: '100%'}}>
        <Stack space={3} flex={1}>
          <Box paddingTop={2}>
            <Flex justify="space-between">
              <Box flex={9}>
                <Text weight="semibold" size={2} textOverflow="ellipsis">
                  {planName}
                </Text>
              </Box>
            </Flex>
          </Box>

          <Box style={{minHeight: '2.5em'}} display="flex" marginBottom={4}>
            <Text muted size={1} style={{alignSelf: 'center'}}>
              {description}
            </Text>
          </Box>

          <Flex gap={2} align={'baseline'}>
            <Flex style={{minHeight: '1.75rem'}} align={'flex-end'}>
              {typeof price === 'number' ? (
                <PriceText size={5}>{formatPrice(price)}</PriceText>
              ) : (
                <Flex align={'baseline'} gap={2}>
                  <Heading size={5} muted>
                    <CommentIcon />
                  </Heading>
                  <Text muted size={1}>
                    Custom pricing
                  </Text>
                </Flex>
              )}
            </Flex>

            <Text size={1} muted>
              {typeof price === 'number' ? (
                <>{pricingModel === 'per-seat' ? 'per seat / month' : 'per month'}</>
              ) : (
                <>&nbsp;</>
              )}
            </Text>
          </Flex>

          {isBasePlan && willAutoDowngrade ? (
            // If we're rendering the free plan and the current
            // plan will auto downgrade (i.e. is trial), then show text instead of button
            <Box paddingBottom={1} paddingTop={4}>
              <Text size={1} muted>
                You will automatically be downgraded to Free after the trial ends
              </Text>
            </Box>
          ) : (
            <Box paddingTop={4}>
              {/* Show request upgrade dialog for growth plan */}
              {isGrowthPlan ? (
                <>
                  <ButtonWithExtendedTones
                    {...buttonProps}
                    onClick={hasPermission ? handleSelectPlan : openRequestUpgradeDialog}
                    style={{width: '100%'}}
                  />
                  {showRequestUpgradeDialog && requestUpgradeDialog && !isError && (
                    <RequestUpgradeDialog
                      requestedPlanName={planName}
                      dialogContent={requestUpgradeDialog}
                      onClose={() => setShowRequestUpgradeDialog(false)}
                    />
                  )}
                </>
              ) : (
                <PermissionTooltip disabled={hasPermission || isCurrentPlan}>
                  <ButtonWithExtendedTones
                    {...buttonProps}
                    onClick={handleSelectPlan}
                    disabled={!hasPermission || isCurrentPlan}
                    style={{width: '100%'}}
                  />
                </PermissionTooltip>
              )}
            </Box>
          )}
          <Stack space={3} marginTop={4}>
            <Box style={{minHeight: '1rem'}}>
              <Text size={1} weight="semibold">
                {listHeader}
              </Text>
            </Box>
            {listItems && (
              <Box paddingLeft={1}>
                <Stack as="ul" space={4}>
                  {listItems?.map((item) => (
                    <Flex as="li" key={item}>
                      <Box marginRight={3}>
                        <Text muted size={1}>
                          <CheckmarkCircleIcon />
                        </Text>
                      </Box>
                      <Text muted size={1}>
                        {item}
                      </Text>
                    </Flex>
                  ))}
                </Stack>
              </Box>
            )}
          </Stack>
        </Stack>
      </Flex>
    </Card>
  )
}
