/* eslint-disable complexity */
import React from 'react'

export const Quota = ({color}: {color?: string}) => (
  <svg
    width="242"
    height="140"
    viewBox="0 0 242 140"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_439_5964)">
      <path
        d="M160.517 69.5005L200.647 46.3338L160.517 23.1671L120.388 46.3338L160.517 69.5005ZM160.517 69.5005L200.647 92.6672L160.518 115.834M160.517 69.5005L120.388 92.6672M80.2572 69.5005L120.387 46.3338L80.2572 23.1671L40.1278 46.3338L80.2572 69.5005ZM80.2572 69.5005L120.387 92.6672L80.2572 115.834L40.1278 92.6672L80.2572 69.5005ZM160.518 115.834L120.388 92.6672M160.518 115.834L120.388 139.001L80.2589 115.834L120.388 92.6672M200.645 46.3338L240.775 69.5005L200.645 92.6672L160.516 69.5005L200.645 46.3338ZM40.1294 46.3338L80.2587 69.5005L40.1294 92.6672L0 69.5005L40.1294 46.3338Z"
        stroke={color || '#2276FC'}
        strokeOpacity="0.25"
        strokeLinejoin="round"
      />
      <path
        d="M120.388 46.3338L160.518 23.1668L160.518 69.5005L120.388 92.6671L80.2588 69.5005L80.2588 23.1663L120.388 46.3338Z"
        fill={color || '#2276FC'}
        fillOpacity="0.25"
      />
      <path
        d="M120.388 46.3338L80.2588 23.1663L80.2588 69.5005L120.388 92.6671M120.388 46.3338L160.518 23.1668L160.518 69.5005L120.388 92.6671M120.388 46.3338L120.388 92.6671"
        stroke={color || '#2276FC'}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M120.386 0L160.516 23.1667L120.386 46.3334L80.2571 23.1667L120.386 0Z"
        fill={color || '#2276FC'}
        fillOpacity="0.25"
        stroke={color || '#2276FC'}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_439_5964">
        <rect width="242" height="140" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
Quota.displayName = 'Quota'
