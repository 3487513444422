import React from 'react'
import {CheckmarkIcon, InfoOutlineIcon} from '@sanity/icons'
import {Box, Card, Flex, Stack, Text, Label, Tooltip} from '@sanity/ui'
import {modeToIcon, modeToDescription, modeToTone} from './format'
import {PermissionGrant} from '@/types/index'

interface Props {
  grants: PermissionGrant
}

export function DatasetGrantsPermissionsTable(props: Props) {
  const {grants} = props

  const documentModeFilterGrants = grants['sanity.document.filter.mode'] || []
  const documentRawFilterGrants = grants['sanity.document.filter'] || []

  if (documentModeFilterGrants.length === 0 && documentRawFilterGrants.length === 0) {
    return null
  }

  return (
    <Card radius={1}>
      <Stack>
        {documentModeFilterGrants.length > 0 && (
          <>
            <Card tone="inherit" borderBottom>
              <Flex paddingX={[3, 3, 4, 4]} paddingBottom={3} paddingTop={4} gap={2}>
                <Box flex={2}>
                  <Label muted size={1}>
                    Content resource
                  </Label>
                </Box>
                <Box flex={1}>
                  <Label muted size={1}>
                    Permission
                  </Label>
                </Box>
                <Flex flex={1} align="flex-start" justify="center">
                  <Label muted size={1}>
                    History
                  </Label>
                  <Tooltip
                    content={
                      <Box padding={3} style={{maxWidth: 250}}>
                        <Text size={1}>
                          When history is enabled the role can read previous versions of the
                          resource
                        </Text>
                      </Box>
                    }
                  >
                    <Box marginLeft={1}>
                      <Text size={0} muted>
                        <InfoOutlineIcon />
                      </Text>
                    </Box>
                  </Tooltip>
                </Flex>
              </Flex>
            </Card>
            <Stack space={2}>
              {documentModeFilterGrants.map((permissionResource) => {
                const {id, title, description} = permissionResource
                const [{params}] = permissionResource.grants
                const mode = typeof params.mode === 'string' ? params.mode : 'default'
                const history = typeof params.history === 'boolean' ? params.history : false

                return (
                  <Card key={id} paddingX={[3, 3, 4, 4]} paddingY={2}>
                    <Flex align="center">
                      <Box flex={2}>
                        <Tooltip
                          content={
                            description &&
                            description.length > 0 && (
                              <Box padding={3} style={{maxWidth: 250}}>
                                <Card marginBottom={3} paddingBottom={3} borderBottom>
                                  <Text size={1}>{description || 'No description'}</Text>
                                </Card>
                              </Box>
                            )
                          }
                        >
                          <Text size={1}>{title}</Text>
                        </Tooltip>
                      </Box>
                      <Flex flex={1} align="center">
                        <Tooltip
                          content={
                            <Card radius={2} padding={3} style={{maxWidth: 250}}>
                              <Text size={1}>{modeToDescription(mode)}</Text>
                            </Card>
                          }
                        >
                          <Card tone={modeToTone(mode)} padding={2} radius={5}>
                            <Flex align="center" gap={2}>
                              <Text size={1}>{modeToIcon(mode)}</Text>
                              <Text size={1} weight="medium">
                                {mode.substr(0, 1).toUpperCase() + mode.substr(1)}
                              </Text>
                            </Flex>
                          </Card>
                        </Tooltip>
                      </Flex>

                      <Flex flex={1} align="center" justify="center">
                        <Text>{history && <CheckmarkIcon />}</Text>
                      </Flex>
                    </Flex>
                  </Card>
                )
              })}
            </Stack>
          </>
        )}
        {documentRawFilterGrants.length > 0 && (
          <>
            <Card tone="inherit" borderBottom>
              <Flex paddingX={[3, 3, 4, 4]} paddingBottom={3} paddingTop={4} gap={2}>
                <Box flex={1}>
                  <Label muted size={1}>
                    Content resource
                  </Label>
                </Box>
                <Box flex={2}>
                  <Label muted size={1}>
                    Permission
                  </Label>
                </Box>
              </Flex>
            </Card>

            <Stack space={2}>
              {documentRawFilterGrants.map((permissionResource) => {
                const {id, title, description} = permissionResource

                return (
                  <Card key={id} paddingX={[3, 3, 4, 4]} paddingY={2}>
                    <Flex align="center">
                      <Box flex={1}>
                        <Tooltip
                          content={
                            description &&
                            description.length > 0 && (
                              <Box padding={3} style={{maxWidth: 250}}>
                                <Card marginBottom={3} paddingBottom={3} borderBottom>
                                  <Text size={1}>{description}</Text>
                                </Card>
                              </Box>
                            )
                          }
                        >
                          <Text size={1}>{title}</Text>
                        </Tooltip>
                      </Box>
                      <Flex flex={2} align="center" wrap="wrap">
                        {permissionResource.grants.map((grant) => (
                          <Card
                            key={grant.name}
                            tone={'primary'}
                            padding={2}
                            radius={5}
                            marginRight={2}
                            marginBottom={[2, 2, 0, 0]}
                          >
                            <Text size={1} weight="medium">
                              {grant.name.substr(0, 1).toUpperCase() + grant.name.substr(1)}
                            </Text>
                          </Card>
                        ))}
                      </Flex>
                    </Flex>
                  </Card>
                )
              })}
            </Stack>
          </>
        )}
      </Stack>
    </Card>
  )
}
