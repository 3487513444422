import {Card, type ResponsivePaddingProps} from '@sanity/ui'
import {forwardRef} from 'react'
import styled, {css} from 'styled-components'

const Root = styled(Card)<{$cellMaxWidth?: number; $cellMinWidth?: number}>(({
  $cellMaxWidth,
  $cellMinWidth,
}) => {
  return css`
    &:not([hidden]) {
      display: table-cell;
    }

    min-width: ${$cellMinWidth ? `${$cellMinWidth}px` : undefined};
    max-width: ${$cellMaxWidth ? `${$cellMaxWidth}px` : undefined};
  `
})

export interface CommonCellProps extends ResponsivePaddingProps {
  as: string
  children?: React.ReactNode
  cellFlex?: number
  cellMaxWidth?: number
  cellMinWidth?: number
}

export const CommonCell = forwardRef(function Cell(
  props: CommonCellProps & Omit<React.HTMLProps<HTMLDivElement>, 'as' | 'height' | 'ref' | 'wrap'>,
  ref: React.Ref<HTMLDivElement>
) {
  const {as, children, cellMinWidth, cellFlex = 1, cellMaxWidth, ...rest} = props

  return (
    <Root
      $cellMinWidth={cellMinWidth}
      $cellMaxWidth={cellMaxWidth}
      flex={cellFlex}
      forwardedAs={as}
      overflow="hidden"
      ref={ref}
      sizing="border"
      {...rest}
    >
      {children}
    </Root>
  )
})
