import React, {useState, useMemo} from 'react'
import {Stack, Card, Text} from '@sanity/ui'
import {useContacts} from '../../../../data/organizations/useContacts'
import {OrganizationContactData, Organization} from '@/types/index'
import {EditablePreview, TabSectionHeader} from '@/ui/index'
import {ContactInput} from '@/components/form/edit-states'
import {useRequiredOrganizationPermissions} from '@/context/index'

export function ContactDetails({
  org,
  title,
}: {
  org: Organization & {organization: Organization}
  title: string
}) {
  const [isEditingEU, setIsEditingEU] = useState<boolean>(false)
  const [isEditingDPO, setIsEditingDPO] = useState<boolean>(false)
  const [contacts, updateContact] = useContacts(org?.id)
  const {data: contactsData} = contacts

  const canManage = useRequiredOrganizationPermissions([
    {permissionName: 'sanity.organization.legal', grantName: 'update'},
  ])

  function onSave(data: OrganizationContactData, role: 'eu-representative' | 'dpo') {
    updateContact({contact: data, role: role})

    if (role === 'eu-representative') {
      setIsEditingEU(false)
    }
    if (role === 'dpo') {
      setIsEditingDPO(false)
    }
  }

  const euRepContacts = useMemo(
    () => contactsData.find((contact) => contact.role === 'eu-representative'),
    [contactsData]
  )
  const dpoContacts = useMemo(
    () => contactsData.find((contact) => contact.role === 'dpo'),
    [contactsData]
  )

  return (
    <Stack space={5}>
      <TabSectionHeader title={title} />
      <EditablePreview
        onEdit={() => setIsEditingEU(true)}
        hasData={!!euRepContacts}
        title="EU Representative"
        description="Person designated to represent customers not established in the EU with regards to their obligations under the General Data Protection Regulation (GDPR)."
        isEditing={isEditingEU}
        addButtonText="Add EU Representative"
        canManage={canManage}
        noPermissionText="Your member role does not have access to contact settings"
      >
        <>
          {!isEditingEU && (
            <Stack space={4}>
              <Text weight="semibold">{euRepContacts?.name}</Text>
              <Stack space={2}>
                <Text>{euRepContacts?.address}</Text>
                <Text>{euRepContacts?.phoneNumber}</Text>
                <Text>{euRepContacts?.email}</Text>
              </Stack>
            </Stack>
          )}
          {isEditingEU && (
            <Card paddingY={3}>
              <ContactInput
                onCancel={() => setIsEditingEU(false)}
                onSave={(data) => onSave(data, 'eu-representative')}
                data={euRepContacts}
              />
            </Card>
          )}
        </>
      </EditablePreview>

      <EditablePreview
        onEdit={() => setIsEditingDPO(true)}
        hasData={!!dpoContacts}
        title="Data Protection Officer"
        description="Person designated to facilitate compliance with the provisions of the GDPR."
        isEditing={isEditingDPO}
        addButtonText="Add Data Protection Officer"
        canManage={canManage}
        noPermissionText="Your member role does not have access to contact settings"
      >
        <>
          {!isEditingDPO && (
            <Stack space={4}>
              <Text weight="semibold">{dpoContacts?.name}</Text>
              <Stack space={2}>
                <Text>{dpoContacts?.address}</Text>
                <Text>{dpoContacts?.phoneNumber}</Text>
                <Text>{dpoContacts?.email}</Text>
              </Stack>
            </Stack>
          )}
          {isEditingDPO && (
            <Card paddingY={3}>
              <ContactInput
                onCancel={() => setIsEditingDPO(false)}
                onSave={(data) => onSave(data, 'dpo')}
                data={dpoContacts}
              />
            </Card>
          )}
        </>
      </EditablePreview>
    </Stack>
  )
}
