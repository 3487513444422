import {Visa, Mastercard, Amex} from '@/components/index'
import {Card, Label} from '@sanity/ui'
import styled from 'styled-components'

type Variant = 'visa' | 'mastercard' | 'amex' | 'other' | string
type Mode = 'fill' | 'outline'

export interface CardProps {
  variant: Variant
  /**
   * @default fill
   */
  mode?: Mode
  style?: React.CSSProperties
}

export interface CardCollectionProps {
  /**
   * @default fill
   */
  mode?: Mode
  style?: React.CSSProperties
}

interface VariantMapItem {
  component: React.ReactElement
  style?: React.CSSProperties
}

const variantMap: Record<Variant, VariantMapItem> = {
  visa: {
    component: <Visa />,
  },
  mastercard: {
    component: <Mastercard />,
  },
  amex: {
    component: <Amex />,
    style: {
      background: '#016FD0',
      padding: 0,
    },
  },
  other: {
    component: <Label size={2}>Other</Label>,
  },
}

const Wrapper = styled(Card)<{mode: Mode}>`
  place-items: center;
  display: inline-grid !important;
  width: 44px;
  height: 23px;
  background: ${(p) => (p.mode === 'fill' ? 'var(--card-muted-bg-color)' : 'var(--card-bg-color)')};
  padding: 2px;

  svg {
    width: 100%;
    height: 100%;
  }
`

export function CardLogo({variant, mode = 'fill', style}: CardProps) {
  const {component, style: iconStyle} = variantMap[variant] || variantMap.other
  return (
    <Wrapper
      radius={1}
      mode={mode}
      style={{...iconStyle, ...style}}
      shadow={mode === 'fill' ? 0 : 1}
      aria-label={`${variant} logo`}
    >
      {component}
    </Wrapper>
  )
}
