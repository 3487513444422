import {AvatarProps} from '@sanity/ui'
import React from 'react'
import styled from 'styled-components'
import {LoginProvider} from './loginProviders'
import {nameToInitials} from './utils'
import {AvatarWithFallback} from '.'
import {MemberTooltip} from '@/ui/index'
import {User} from '@/types/models'
import {useUser} from '@/data/users'

interface Props extends AvatarProps {
  isLoading?: boolean
  user?: User
  userId: string
  showProvider?: boolean
  tooltip?: boolean
}

const AvatarWithLoginProvider = styled.div`
  position: relative;
`
export function MemberAvatar({
  userId,
  showProvider,
  isLoading: isLoadingProp,
  tooltip = false,
  ...restProps
}: Props) {
  const {data: user = restProps.user, isLoading, isError} = useUser(userId)

  if (isLoading || isLoadingProp) {
    return (
      <AvatarWithLoginProvider>
        <AvatarWithFallback src={undefined}></AvatarWithFallback>
      </AvatarWithLoginProvider>
    )
  }

  if (isError) {
    return null
  }

  return (
    <MemberTooltip user={user} disabled={!tooltip}>
      <AvatarWithLoginProvider>
        <AvatarWithFallback
          title={user?.displayName}
          src={user?.imageUrl || undefined}
          initials={nameToInitials(user?.displayName)}
          {...restProps}
        />
        {showProvider && user?.provider && <LoginProvider provider={user.provider} />}
      </AvatarWithLoginProvider>
    </MemberTooltip>
  )
}
MemberAvatar.displayName = 'MemberAvatar'
