import React, {useCallback, useState} from 'react'
import styled, {css} from 'styled-components'
import {Box, Button, Card, Flex, Label, Stack, Text} from '@sanity/ui'
import {ChevronLeftIcon, CloseIcon, SelectIcon, UsersIcon} from '@sanity/icons'
import {motion, AnimatePresence} from 'framer-motion'
import {SlideList, SlideItem} from '..'
import {Illustration} from '@/ui/index'

const Header = styled(Card)`
  width: 100%;
  height: 64px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  background: ${({theme}) => theme.sanity.color.base.bg};
  color: ${({theme}) => theme.sanity.color.base.fg};
`

const OuterCard = motion(styled(Card)<{open: boolean}>`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  max-width: 450px;
  overflow: hidden;
  pointer-events: ${({open}) => (open ? 'all' : 'none')};
  z-index: 9999;
`)

const SelectButton = styled(Button)<{$isSelected?: boolean}>`
  background: ${({theme}) => theme.sanity.color.muted.transparent.enabled.bg};
  color: ${({theme}) => theme.sanity.color.muted.transparent.enabled.fg};
  height: 35px;

  [data-ui='Box'] [data-ui='Flex'] [data-ui='Box'] {
    display: flex;
    align-items: center;

    span {
      max-width: 150px;
    }
  }

  & [data-ui='Text'] {
    font-weight: ${({theme}) => theme.sanity.fonts.text.weights.regular};
    color: ${({theme}) => theme.sanity.color.base.fg};
  }

  ${({$isSelected: isSelected}) =>
    isSelected &&
    css`
      background: transparent;
      & [data-ui='Text'] {
        font-weight: ${({theme}) => theme.sanity.fonts.text.weights.semibold};
      }
    `}
`

type Child = {
  title: string
  selected?: boolean
  id?: string | number
  handleClick: () => void
}

type Parent = {
  title: string
  selected?: boolean
  handleClick: () => void
  children?: Child[]
  id?: string | number
}

type Props = {
  data: Parent[] | Child[]
  buttonText: string
  isSelected?: boolean
}

export function SlideMenu({data, buttonText, isSelected}: Props) {
  const [open, setOpen] = useState(false)
  const [level, setLevel] = useState(0)
  const [currentChildren, setCurrentChildren] = useState<Child[] | null>(null)
  const [currentParent, setCurrentParent] = useState<Parent | null>(null)

  const handleParentClick = useCallback((parent: any) => {
    setCurrentChildren(parent?.children)
    setCurrentParent(parent)
    setLevel(1)
  }, [])

  const handleChildClick = useCallback((child: any) => {
    child?.handleClick()
    setOpen(false)
    setLevel(0)
  }, [])

  const handleGoToOrg = useCallback(() => {
    currentParent?.handleClick()
    setOpen(false)
    setLevel(0)
  }, [currentParent])

  const handleGoBack = useCallback(() => {
    setLevel(0)
    setCurrentParent(null)
  }, [])

  const handleClose = useCallback(() => {
    setOpen(false)
    setTimeout(() => {
      setLevel(0)
      setCurrentParent(null)
    }, 300)
  }, [])

  return (
    <>
      <SelectButton
        onClick={() => setOpen(!open)}
        $isSelected={isSelected}
        iconRight={isSelected ? SelectIcon : null}
        text={<span style={{textOverflow: 'ellipsis'}}>{buttonText}</span>}
        mode="bleed"
      />
      <AnimatePresence>
        {open && (
          <OuterCard
            transition={{ease: 'easeInOut', duration: 0.27}}
            initial={{x: '-100%'}}
            animate={{x: '0%'}}
            exit={{x: '-100%'}}
            open={open}
            sizing="border"
            shadow={open ? 3 : 0}
          >
            <Header padding={2} sizing="border">
              <Flex align="center" justify="space-between" style={{height: '100%'}}>
                <Button
                  onClick={() => handleGoBack()}
                  icon={ChevronLeftIcon}
                  mode="bleed"
                  disabled={level === 0}
                  style={{opacity: level === 0 ? 0 : 1}}
                />
                <Text weight="semibold">
                  <Box
                    paddingTop={2}
                    paddingBottom={2}
                    style={{
                      display: 'block',
                      textOverflow: 'ellipsis',
                      overflowX: 'hidden',
                      whiteSpace: 'nowrap',
                      maxWidth: 200,
                    }}
                  >
                    {currentParent ? currentParent?.title : 'Organizations'}
                  </Box>
                </Text>
                <Button onClick={() => handleClose()} icon={CloseIcon} mode="bleed" />
              </Flex>
            </Header>
            <SlideList level={0} visible={level === 0}>
              {(data as Parent[]).map((d: Parent) => (
                <SlideItem
                  key={d.id}
                  text={d.title}
                  selected={d.selected}
                  hasChildren
                  onClick={() => handleParentClick(d)}
                />
              ))}
            </SlideList>
            <SlideList level={1} visible={level === 1}>
              <Stack space={3}>
                <Flex>
                  <Card flex={1} padding={3} paddingTop={4} paddingBottom={4}>
                    <Button
                      text="View organization page"
                      onClick={() => handleGoToOrg()}
                      mode="ghost"
                      style={{width: '100%'}}
                      padding={4}
                      icon={UsersIcon}
                    />
                  </Card>
                </Flex>
                {currentChildren && currentChildren.length !== 0 && (
                  <Stack space={3}>
                    <Box paddingLeft={3}>
                      <Label weight="semibold" size={1} muted>
                        SELECT A PROJECT
                      </Label>
                    </Box>
                    <Stack>
                      {currentChildren.map((d: Child) => (
                        <SlideItem
                          key={d.id}
                          text={d.title}
                          selected={d.selected}
                          onClick={() => handleChildClick(d)}
                        />
                      ))}
                    </Stack>
                  </Stack>
                )}

                {!currentChildren ||
                  (currentChildren.length === 0 && (
                    <Card padding={4}>
                      <Stack space={4}>
                        <Flex justify="center" align="center">
                          <Illustration drawing="package" color="#7B8CA8" />
                        </Flex>
                        <Text weight="semibold" size={1} style={{textAlign: 'center'}}>
                          This organization does not have any projects yet
                        </Text>
                      </Stack>
                    </Card>
                  ))}
              </Stack>
            </SlideList>
          </OuterCard>
        )}
      </AnimatePresence>
    </>
  )
}
