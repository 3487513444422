import {Box, Flex, Stack, Text} from '@sanity/ui'
import {styled} from 'styled-components'

import {useRelativeTime} from '../../../hooks'
import {Badge} from '../../../primitives/badge'
import {CellContentWrap} from './CellContentWrap'

interface MemberCellProps {
  displayName: string | undefined
  email: string | undefined
  createdAt?: string | Date
  isCurrentUser?: boolean
  onClick?: () => void
}

const NameButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  width: 100%;
`

export function MemberCell(props: MemberCellProps) {
  const {displayName, email, createdAt, isCurrentUser, onClick} = props
  const timeAgo = useRelativeTime(createdAt || '', {
    minimal: true,
  })

  return (
    <CellContentWrap>
      <Stack space={2}>
        {displayName && (
          <Flex align="center">
            <Box>
              {onClick ? (
                <Text
                  align="left"
                  aria-label={`Inspect all roles for ${displayName}`}
                  as={NameButton}
                  onClick={onClick}
                  size={1}
                  textOverflow="ellipsis"
                  title={displayName}
                  weight="medium"
                >
                  {displayName}
                </Text>
              ) : (
                <Text size={1} weight="medium" textOverflow="ellipsis" title={displayName}>
                  {displayName}
                </Text>
              )}
            </Box>

            {isCurrentUser && (
              <Flex align="center" style={{minWidth: 'max-content'}} marginLeft={2}>
                <Badge aria-label="Current user">You</Badge>
              </Flex>
            )}
          </Flex>
        )}

        {email && (
          <Text size={1} muted textOverflow="ellipsis" title={email} aria-label={`Email: ${email}`}>
            {email}
          </Text>
        )}
        {createdAt && (
          <Box marginTop={1}>
            <Text
              size={0}
              muted
              weight={'semibold'}
              textOverflow="ellipsis"
              title={timeAgo}
              aria-label={`Sent: ${timeAgo}`}
            >
              {timeAgo}
            </Text>
          </Box>
        )}
      </Stack>
    </CellContentWrap>
  )
}
