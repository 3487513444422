import React from 'react'
import {Box, Flex, Stack, Text, useTheme} from '@sanity/ui'
import {ChartSectionActions, ChartContextualAction, Toggle, AccumulativeMode} from '@/ui/index'
import {getUsageDifference} from '@/utils/usage'
import {useMatchesMedia} from '@/utils/views'

interface ChartSectionHeaderProps {
  id: string
  title?: string
  loading?: boolean
  showCumulativeToggle?: boolean
  description?: string | React.ReactNode
  actions: ChartContextualAction[]
  // hasOverage?: boolean
  onChangeMode?: (mode: AccumulativeMode) => void
  compareValue?: {
    usage?: number
    compare?: number
    unit?: string
  }
}

export function ChartSectionHeader({
  actions = [],
  title,
  description,
  loading = false,
  showCumulativeToggle = true,
  id,
  onChangeMode,
  compareValue,
}: ChartSectionHeaderProps) {
  const isMobile = useMatchesMedia(1)
  const {sanity} = useTheme()
  const {usage = 0, compare, unit = 'number'} = compareValue || {}
  const usageDiff = getUsageDifference(usage, unit, compare)
  const diffColor = usageDiff?.isPositive ? sanity.color.spot.green : sanity.color.spot.red
  const handleChangeMode = (newMode: string) => {
    if (onChangeMode) {
      onChangeMode(newMode as AccumulativeMode)
    }
  }
  return (
    <Flex direction={['column', 'column', 'row']}>
      <Flex justify="space-between" flex={1}>
        <Stack space={3}>
          <Text as="h4" size={2} weight="bold">
            {title}
          </Text>
          {description && (
            <Flex muted align="center" style={{opacity: loading ? 0 : 1}}>
              {description}
              {usageDiff && (
                <Box marginLeft={2}>
                  <Text size={1} style={{color: diffColor}}>
                    {usageDiff.value}
                  </Text>
                </Box>
              )}
            </Flex>
          )}
        </Stack>
        {isMobile && (
          <Box marginLeft={2}>
            <ChartSectionActions chartId={id} actions={actions} />
          </Box>
        )}
      </Flex>
      {showCumulativeToggle && onChangeMode && (
        <Flex paddingTop={[3, 3, 0]}>
          <Toggle
            onChange={handleChangeMode}
            variant="border"
            options={[
              {
                name: 'cumulative',
                value: 'daily',
                label: 'Daily',
                id: 'daily',
                defaultChecked: true,
              },
              {
                name: 'cumulative',
                value: 'runningTotal',
                label: 'Running total',
                id: 'runningTotal',
              },
            ]}
          />
        </Flex>
      )}
      {!isMobile && (
        <Box marginLeft={3}>
          <ChartSectionActions chartId={id} actions={actions} />
        </Box>
      )}
    </Flex>
  )
}
