import React, {useMemo, useReducer} from 'react'
import {INITIAL_STATE} from '../constants'
import {reducer} from './reducer'
import {WizardStoreContext} from './storeContext'

export function WizardStoreProvider({children}: {children?: React.ReactNode}) {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE)

  const contextValue = useMemo(() => ({dispatch, state}), [dispatch, state])

  return <WizardStoreContext.Provider value={contextValue}>{children}</WizardStoreContext.Provider>
}
