import {Stack, Text} from '@sanity/ui'
import Link from 'next/link'
import {useCallback, useMemo, useState} from 'react'

import {type PricingModel} from '@/types/index'

import {Dialog} from '../../primitives/dialog'
import {type MembersSettingsScreenProps} from '../../screens'
import {InlineBanner} from './InlineBanner'
import {
  NO_QUOTA_AVAILABLE_MEMBER_COPY,
  NO_SEATS_AVAILABLE_MEMBER_COPY,
  noQuotaAvailable,
  noSeatsAvailable,
} from './invite-utils'
import {InviteScopeTabs} from './InviteScopeTabs'
import {MembersInvitationForm, type MembersInvitationFormProps} from './MembersInvitationForm'
import {SamlBanner} from './SamlBanner'
import {type InvitationCreateValue, type InvitationScope} from './types'

const ORG_DESCRIPTION = `Add existing members or invite new ones.
New members will receive an email invitation, while existing members are added directly with the selected role.`

const MULTI_PROJECT_DESCRIPTION = `Add existing members or invite new ones to one or more projects.
New members will receive an email invitation, while existing members are added directly with the selected role.`

const PROJECT_DESCRIPTION = `Add existing members or invite new ones to the project.
New members will receive an email invitation, while existing members are added directly with the selected role.`

interface MembersInvitationFormDialogProps
  extends Omit<MembersInvitationFormProps, 'onChange' | 'onCanInviteChange'> {
  basePath?: string
  onClose: () => void
  onConfirm: (invites: InvitationCreateValue[]) => void
  showSamlBanner?: boolean
  isGrowthPlan?: boolean
  planPricingModel?: PricingModel
  resourcesQuota?: MembersSettingsScreenProps['resourcesQuota']
}

export function MembersInvitationFormDialog(props: MembersInvitationFormDialogProps) {
  const {
    basePath,
    isGrowthPlan,
    onClose,
    onConfirm,
    planPricingModel,
    resourcesQuota,
    scope,
    showSamlBanner,
    multiProjectInvite: multiProjectInviteProp,
    ...rest
  } = props

  // We need two sets of state to manage the invites for the organization and project scopes.
  // This is because the user can switch between the the scopes when in an organization context,
  // and we want to keep the invites separate for each scope.
  const [canInviteOrg, setCanInviteOrg] = useState<boolean>(false)
  const [invitesOrg, setInvitesOrg] = useState<InvitationCreateValue[]>([])

  const [canInviteProject, setCanInviteProject] = useState<boolean>(false)
  const [invitesProject, setInvitesProject] = useState<InvitationCreateValue[]>([])

  // The current scope that is being managed in the dialog (organization or project)
  // This is used to determine which form to show when switching between tabs
  // when the user is in an organization context.
  const [localScope, setLocalScope] = useState<InvitationScope>(scope)

  // Determine if the user is in an organization context.
  // Note that the `scope` prop is passed down from the parent component
  // and is used to determine the initial scope of the dialog.
  // The `localScope` is only used locally to switch between organization and project tabs
  // when the user is in an organization context.
  const isOrganizationContext = scope === 'organization'

  // We want to allow multi-project invites if the user is in an organization context
  // or if the `multiProjectInvite` prop is set to true.
  const multiProjectInvite = isOrganizationContext || multiProjectInviteProp

  const samlBannerCta = isGrowthPlan ? 'View add-on' : 'Upgrade'
  const billableUserType = resourcesQuota?.members.billableUserType || 'users'

  const handleConfirm = useCallback(() => {
    if (localScope === 'organization') {
      onConfirm(invitesOrg)
    }

    if (localScope === 'project') {
      onConfirm(invitesProject)
    }
  }, [invitesOrg, invitesProject, onConfirm, localScope])

  const canInvite = useMemo(() => {
    if (localScope === 'organization') return canInviteOrg
    if (localScope === 'project') return canInviteProject

    return false
  }, [canInviteOrg, canInviteProject, localScope])

  const description = useMemo(() => {
    if (localScope === 'organization') return ORG_DESCRIPTION
    if (localScope === 'project' && multiProjectInvite) return MULTI_PROJECT_DESCRIPTION
    if (localScope === 'project') return PROJECT_DESCRIPTION

    return ''
  }, [multiProjectInvite, localScope])

  const isNoQuotaAvailable = useMemo(() => {
    if (!resourcesQuota || localScope !== 'project') return false
    return noQuotaAvailable(resourcesQuota.members, planPricingModel)
  }, [localScope, resourcesQuota, planPricingModel])

  const isNoSeatsAvailable = useMemo(() => {
    if (!resourcesQuota || localScope !== 'project') return false
    return noSeatsAvailable(resourcesQuota.members)
  }, [localScope, resourcesQuota])

  return (
    <Dialog
      onClose={onClose}
      onClickOutside={onClose}
      confirmButton={{
        disabled: !canInvite,
        onClick: handleConfirm,
        text: 'Add members',
        tone: 'primary',
        width: 'fill',
      }}
      footer={
        showSamlBanner && (
          <SamlBanner
            ctaLink={`${basePath}/plan?ref=saml-invite-cta&addon=accesscontrol-auth-saml-role-mapping`}
            ctaText={samlBannerCta}
          />
        )
      }
      width={1}
      header="Add members"
      id="organization-invite-members-dialog"
    >
      <Stack sizing="border" space={4}>
        {description && <Text size={1}>{description}</Text>}

        {isNoSeatsAvailable && (
          <InlineBanner
            tone="caution"
            buttonProps={{
              as: Link,
              text: 'View plans',
              href: `${basePath}/plan`,
              tone: 'primary',
            }}
          >
            <Text size={1}>
              Your project has reached its limits for{' '}
              {NO_SEATS_AVAILABLE_MEMBER_COPY[billableUserType].unavailableMemberType}. You can
              still invite {NO_SEATS_AVAILABLE_MEMBER_COPY[billableUserType].availableMemberType}.
            </Text>
          </InlineBanner>
        )}

        {isNoQuotaAvailable && (
          <InlineBanner
            tone="caution"
            buttonProps={{
              as: Link,
              text: 'View plans',
              href: `${basePath}/plan`,
              tone: 'primary',
            }}
          >
            <Text size={1}>
              Your project has reached its included member quota but allows for overages. You will
              be charged monthly per additional {NO_QUOTA_AVAILABLE_MEMBER_COPY[billableUserType]}.
            </Text>
          </InlineBanner>
        )}

        <Stack marginTop={2} space={2}>
          {isOrganizationContext && <InviteScopeTabs value={localScope} onChange={setLocalScope} />}

          {/* We use `hidden` here to avoid unmounting the form when switching between tabs as it would reset the form state. */}
          <Stack hidden={localScope !== 'organization'}>
            <MembersInvitationForm
              {...rest}
              onCanInviteChange={setCanInviteOrg}
              onChange={setInvitesOrg}
              scope="organization"
            />
          </Stack>

          {/* We use `hidden` here to avoid unmounting the form when switching between tabs as it would reset the form state. */}
          <Stack hidden={localScope !== 'project'}>
            <MembersInvitationForm
              {...rest}
              multiProjectInvite={multiProjectInvite}
              onCanInviteChange={setCanInviteProject}
              onChange={setInvitesProject}
              scope="project"
            />
          </Stack>
        </Stack>
      </Stack>
    </Dialog>
  )
}
