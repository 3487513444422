import {Button, ButtonTone, Card, Dialog, Flex, Stack, Text} from '@sanity/ui'
import React, {Fragment} from 'react'
import Link from 'next/link'
import {catchError} from 'rxjs/operators'
import {of} from 'rxjs'
import {resolvePaymentState} from '@/data/organizations'
import {Prompt} from '@/components/general'
import {sendTrackingEvent} from '@/utils/tracking'

type actionType = 'downgrade' | 'reinstate'

export function ArrearsResolutionDialog(props: {
  organizationId: string
  onClose: (success: boolean) => void
  action: actionType
}) {
  type CardError = {
    message: JSX.Element
    title: string
  }

  const {organizationId, onClose, action} = props
  const [error, setError] = React.useState<CardError | null>()
  const [requestInProgress, setRequestInProgress] = React.useState(false)

  function handleError(err: any) {
    if (err?.response?.body) {
      const errorContent = JSON.parse(err.response.body)
      const userError = errorContent.code >= 400 && errorContent.code < 500
      if (errorContent.statusCode === 402) {
        const updatePaymentInfoRoute = `/organizations/${organizationId}/settings#payment`
        setError({
          title: 'Payment failed',
          message: (
            <Fragment>
              There was an error processing your payment.
              <Link
                href={updatePaymentInfoRoute}
                onClick={() =>
                  sendTrackingEvent(
                    'ArrearsStatusCard-Click-UpdatePaymentCard',
                    undefined,
                    organizationId
                  )
                }
                style={{color: 'inherit', textDecoration: 'underline'}}
              >
                Update payment card details
              </Link>{' '}
              and try again. If the problem persists, contact your card provider.
            </Fragment>
          ),
        })
        return
      } else if (userError) {
        setError({title: errorContent.status, message: errorContent.message})
        return
      }
    }

    setError({
      title: 'Could change subscription',
      message: (
        <Fragment>
          Something went wrong. Please try again later. If the problem persists, contact support.
        </Fragment>
      ),
    })
  }

  async function resolveArrears() {
    setError(null)
    setRequestInProgress(true)
    resolvePaymentState(organizationId, action)
      .pipe(
        catchError((err) => {
          handleError(err)
          return of(undefined)
        })
      )
      .subscribe((next) => {
        if (next) {
          onClose(true)
        } else {
          setRequestInProgress(false)
        }
      })
  }

  function exitWithoutAction() {
    if (requestInProgress) {
      return
    }
    onClose(false)
  }

  let explanation
  let text
  let buttonTone: ButtonTone | undefined

  if (action === 'reinstate') {
    text = 'Restart subscription'
    buttonTone = 'primary'
    explanation = (
      <Text muted={false} size={2}>
        All projects will continue on their previous plan and work as before.
        <br />
        <br />
        Before restarting subscriptions make sure your payment method is up to date.
      </Text>
    )
  } else if (action === 'downgrade') {
    text = 'Downgrade all projects'
    buttonTone = 'critical'
    explanation = (
      <Text size={2}>
        All projects will be downgraded to the{' '}
        <a href={`https://www.${process.env.host}/pricing`} target="_blank" rel="noreferrer">
          Free Forever plan
        </a>
        .
        <br />
        <br />
        Please note:
        <ul>
          <li>Depending on usage you might accrue overage costs.</li>
          <li>
            If you have projects on legacy or promotional plans, you will NOT be able to return to
            these plans.
          </li>
        </ul>
      </Text>
    )
  }

  const footer = (
    <Flex gap={2} paddingX={4} paddingY={3}>
      <Button mode="bleed" onClick={exitWithoutAction} style={{flex: 1}} text="Cancel" />
      {requestInProgress ? (
        <Button style={{flex: 1}} tone="default" loading title="Processing request" />
      ) : (
        <Button
          onClick={resolveArrears}
          style={{flex: 1}}
          text={text}
          tone={buttonTone}
          autoFocus
        />
      )}
    </Flex>
  )

  return (
    <Dialog
      footer={footer}
      header={text}
      id="wizard"
      onClose={exitWithoutAction}
      onClickOutside={exitWithoutAction}
      width={1}
      zOffset={2500}
      cardShadow={5}
      autoFocus={false}
    >
      <Card borderTop flex={1} overflow="auto" paddingX={[3, 3, 3, 4]} paddingY={[4, 4, 4, 5]}>
        <Stack space={5}>
          {explanation}
          {error && (
            <Prompt
              tone={'critical'}
              cardProps={{
                padding: 3,
                radius: 1,
                shadow: 1,
              }}
              icon={'warning-outline'}
              title={error.title}
              description={error.message}
              textSize={2}
              layout={'column'}
              verticaSpacing={4}
              as={'div'}
            />
          )}
        </Stack>
      </Card>
    </Dialog>
  )
}
