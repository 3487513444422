import {focusFirstDescendant, Popover, type PopoverProps} from '@sanity/ui'
import {
  cloneElement,
  forwardRef,
  type KeyboardEvent,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import styled, {css} from 'styled-components'

import {PopoverButtonContent, type PopoverButtonContentProps} from './PopoverContent'

const StyledPopover = styled(Popover)<{
  $maxHeight?: number
  $maxWidth?: number
  $minHeight?: number
  $minWidth?: number
}>((props) => {
  const {$maxHeight, $maxWidth, $minHeight, $minWidth} = props

  return css`
    [data-ui='Popover__wrapper'] {
      // The same radius as the default radius for MenuButton from @sanity/ui
      border-radius: ${({theme}) => theme.sanity.radius[4]}px;
      display: flex;
      flex-direction: column;
      min-width: ${$minWidth}px;
      min-height: ${$minHeight}px;
      max-height: ${$maxHeight}px;
      max-width: ${$maxWidth}px;
    }
  `
})

export interface PopoverButtonProps
  extends Omit<PopoverProps, 'open' | 'children'>,
    Pick<PopoverButtonContentProps, 'focusLock'> {
  autoFocus?: boolean
  button: React.ReactElement
  conditionalWrapper?: {
    wrapper: (children: React.ReactNode) => React.ReactElement
    condition: boolean
  }
  content: React.ReactElement
  id: string
  maxHeight?: number
  maxWidth?: number
  minHeight?: number
  minWidth?: number
  onClose?: () => void
}

export interface PopoverButtonHandle {
  onClose: () => void
  focusButton: () => void
}

export const PopoverButton = forwardRef<PopoverButtonHandle, PopoverButtonProps>(
  function PopoverButton(props, ref) {
    const {
      autoFocus,
      button,
      conditionalWrapper,
      content,
      id,
      maxHeight,
      maxWidth,
      minHeight,
      minWidth,
      onClose,
      portal: portalProp = true,
      // we shouldn't pass this to the Popover component
      focusLock: _focusLock,
      ...restProps
    } = props

    const [open, setOpen] = useState<boolean>(false)
    const [buttonElement, setButtonElement] = useState<HTMLButtonElement | null>(null)
    const [isTopLayer, setIsTopLayer] = useState<boolean>(false)

    const innerRef = useRef<HTMLDivElement | null>(null)

    const handleClose = useCallback(() => {
      if (!open) return
      setOpen(false)

      if (onClose) {
        onClose()
      }
    }, [onClose, open])

    const handleClickOutsideContent = useCallback(
      (e: MouseEvent) => {
        const target = e.target as HTMLElement

        if (buttonElement?.contains(target)) return

        handleClose()
      },
      [buttonElement, handleClose]
    )

    const handleClick = useCallback(() => {
      setOpen((v) => {
        const next = !v

        // Run `onClose` when closing the popover
        if (next === false) {
          handleClose()
        }

        return next
      })
    }, [handleClose])

    const handleKeyDown = useCallback(
      (event: KeyboardEvent) => {
        const {key} = event

        if (key === 'ArrowDown' && !open) {
          setOpen(true)
        }

        if (key === 'Escape' && open && isTopLayer) {
          handleClose()
          buttonElement?.focus()
        }
      },
      [buttonElement, handleClose, isTopLayer, open]
    )

    const clonedButton = cloneElement(button, {
      'aria-expanded': open,
      'aria-haspopup': 'true',
      'data-testid': 'popover-button',
      id: id,
      ...button.props,
      onClick: (e: React.MouseEvent<HTMLElement>) => {
        button.props.onClick?.(e)
        handleClick()
      },
      onKeyDown: (e: KeyboardEvent<HTMLElement>) => {
        button.props.onKeyDown?.(e)

        handleKeyDown(e)
      },
      ref: (el: HTMLButtonElement) => {
        setButtonElement(el)
        button.props.ref?.(el)
      },
      selected: open,
    })

    const focusButton = useCallback(() => {
      buttonElement?.focus()
    }, [buttonElement])

    useEffect(() => {
      const raf = requestAnimationFrame(() => {
        if (open && innerRef.current && autoFocus) {
          focusFirstDescendant(innerRef.current)
        }
      })

      return () => {
        cancelAnimationFrame(raf)
      }
    }, [autoFocus, open])

    useImperativeHandle(
      ref,
      (): PopoverButtonHandle => {
        return {
          onClose: handleClose,
          focusButton,
        }
      },
      [focusButton, handleClose]
    )

    const renderedButton = useMemo(() => {
      if (conditionalWrapper?.wrapper && conditionalWrapper.condition) {
        return conditionalWrapper.wrapper(clonedButton)
      }

      return clonedButton
    }, [clonedButton, conditionalWrapper])

    return (
      <StyledPopover
        $maxHeight={maxHeight}
        $maxWidth={maxWidth}
        $minHeight={minHeight}
        $minWidth={minWidth}
        animate
        content={
          <PopoverButtonContent
            {...props}
            content={content}
            id={id}
            onClickOutside={handleClickOutsideContent}
            onIsTopLayerChange={setIsTopLayer}
            onKeyDown={handleKeyDown}
          />
        }
        floatingBoundary={document.body}
        open={open}
        portal={portalProp}
        {...restProps}
      >
        {renderedButton}
      </StyledPopover>
    )
  }
)
