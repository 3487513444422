import {PlanFeatures} from '@/data/plan/usePlanFeatures'
import {Plan} from '@/types/models'

export type PlanCopyConfig = {
  planTypeId: string
  description: string
  listItems: string[]
  listHeader?: string
  paymentDescription?: string
}

function getFreeCopy(id: string): PlanCopyConfig {
  switch (id) {
    case 'free-2023-10-19': // launching nov 15 '23
      return {
        planTypeId: 'free',
        description: 'For individuals experimenting or shipping smaller projects.',
        listHeader: 'Includes:',
        listItems: [
          '20 user seats included',
          '2 datasets (public)',
          'Unlimited content types and locales',
          'Customizable content workspaces',
          'Hosted, real-time content database',
          'Live previews and visual editing tools',
          'Generous usage quota',
        ],
        paymentDescription: 'forever',
      }
    case 'sanity-promo-01-10-2021':
    case 'jamstackconf-standard-2020-05-27':
    case 'syntax-dev-2018-08-21':
    case 'freecodecamp-dev-2018-09-06':
    case 'shoptalk-free-2021-08-15':
    case 'usg-dev-2018-09-12':
    case 'ladybug-standard-2019-08-02':
    case 'community-2019-07-08':
    case 'byteconf-dev-2018-09-14':
    case 'free-2017-11-08':
    case 'meetup-dev-2018-10-29':
    case 'mux-dev-2018-11-27':
    case 'software-eng-daily-dev-2020-10-20':
    case 'nextjsconf-dev-2020-10-20':
    case 'dev-high-attribute-2021-04-09':
    case 'shoptalk-dev-2018-10-14':
    case 'internal-dev-2020-11-05':
    case 'fireship-free-17-09-2021':
    case 'jamstackconf-free-01-10-2021':
    case 'atp-free-2021-07-29':
    case 'free-shopify-2021-11-05':
    case 'free-2021-05-01':
    case 'dev-2018-08-21':
    case 'dev-2018-08-05':
      return {
        planTypeId: 'free',
        description: 'Legacy plan for smaller projects with pay-as-you-go for higher usage.',
        listItems: [
          'Open source editing environment',
          'Hosted, real-time content database',
          'Great developer experience',
          '3 users, $10/mo for more',
          'Generous usage quota',
          'Pay-as-you-go for more resources or add-ons',
        ],
      }
    case 'free-2021-12-08':
    default:
      return {
        planTypeId: 'free',
        description: 'Legacy plan for smaller projects with pay-as-you-go for higher usage.',
        listItems: [
          'Open source editing environment',
          'Hosted, real-time content database',
          'Great developer experience',
          'Unlimited members*',
          'Generous usage quota',
          'Pay-as-you-go for more resources or add-ons',
        ],
      }
  }
}

function getProfessionalCopy(id: string): PlanCopyConfig {
  switch (id) {
    default:
      return {
        planTypeId: 'professional',
        description: 'Predictable pricing. Medium volume.',
        listItems: ['20+ users', 'Expanded quota', 'Pay-as-you-go for additional users and usage'],
      }
  }
}

function getTeamCopy(id: string): PlanCopyConfig {
  switch (id) {
    case 'team-2021-05-01':
      return {
        planTypeId: 'team',
        description:
          'Legacy plan for smaller teams or agencies with pay-as-you-go for higher usage.',
        listItems: ['10 users', '3 roles', 'Additional quota', 'Pay-as-you-go for higher usage'],
      }
    case 'team-2021-12-08':
    default:
      return {
        planTypeId: 'team',
        description:
          'Legacy plan for smaller teams or agencies with pay-as-you-go for higher usage.',
        listItems: [
          '10 non-admin users included',
          'Review changes (drafts) with 30 day history',
          'Additional quota',
          'Pay-as-you-go for higher usage',
        ],
      }
  }
}

function getBusinessCopy(id: string): PlanCopyConfig {
  switch (id) {
    case 'business-2021-05-01':
      return {
        planTypeId: 'business',
        description: 'Legacy plan for mid-sized companies with pay-as-you-go for higher usage.',
        listItems: [
          '20 users',
          '4 roles',
          'Review changes (all docs)',
          'Advanced dataset management',
          'High volume quota',
        ],
      }
    case 'business-2021-12-08':
    default:
      return {
        planTypeId: 'business',
        description: 'Legacy plan for mid-sized companies with pay-as-you-go for higher usage.',
        listItems: [
          '20 non-admin users included',
          'SAML SSO',
          'Advanced dataset management',
          '90 day historical retention',
          'High volume quota',
        ],
      }
  }
}

function getEnterpriseCopy(id: string): PlanCopyConfig {
  switch (id) {
    case 'enterprise-custom-plg-2021-10-19':
    case 'enterprise-2017-11-08':
    case 'enterprise-custom-2021-12-08':
    case 'enterprise-2019-09-19':
    case 'enterprise-2018-08-21':
    case 'enterprise-custom-2018-08-21':
    case 'internal-enterprise-2020-11-05':
    case 'enterprise-custom-2021-05-01':
    default:
      return {
        // for stage testing and to stay off production until launch. move to bottom if we cannot make a new enterprise plan
        // to gate these changes that mention other plans in the copy
        planTypeId: 'enterprise',
        description: 'For organizations with complex security, support, and performance needs.',
        listHeader: 'Everything in Growth, and...',
        listItems: [
          'Custom # user seats',
          'Custom roles and access control',
          'Custom # datasets',
          'Single sign-on with SAML',
          'Uptime SLA and dedicated support',
          'Onboarding program',
          'Custom historical retention',
          'Custom usage quota',
        ],
        paymentDescription: '',
      }
  }
}

function getGrowthCopy(id: string): PlanCopyConfig {
  switch (id) {
    case 'growth-2023-10-19': // launching nov 15 '23
    default:
      return {
        planTypeId: 'growth',
        description: 'For teams with scaling projects and increasing collaboration needs.',
        listHeader: 'Everything in Free, and...',
        listItems: [
          '50 user seats available',
          'Multiple user roles',
          '2 datasets (public or private)',
          'Comments',
          'Scheduled publishing',
          'AI Assist',
          'Additional usage quota',
          'Pay-as-you-go for higher usage',
        ],
      }
  }
}

function getTrialCopy(id: string): PlanCopyConfig {
  switch (id) {
    case 'test-trial-limit-2023-10-19':
    case 'test-trial-limit-1-day-2023-10-19':
    case 'growth-trial-2023-10-19': // launching nov 15 '23
    case 'growth-trial-boosted-2023-10-19': // launching nov 15 '23
    default:
      return {
        planTypeId: 'growth-trial',
        description:
          'During the trial you have access to even more features, all for free. Upgrade before the trial ends to keep access to all features.',
        listItems: [],
        paymentDescription: '', // show nothing, only used for planBlock current plan
      }
  }
}

export const getPlanCopy = (
  plan: Plan,
  planFeatures?: PlanFeatures
): PlanCopyConfig | undefined => {
  // Pass in plan features from marketing site
  // and use those instead of the hard copied content.
  // Eventually this file should be refactored.
  switch (plan?.planTypeId) {
    case 'free': {
      const freePlanCopy = getFreeCopy(plan.id)
      return {...freePlanCopy, listItems: planFeatures?.freePlanFeatures || freePlanCopy.listItems}
    }
    case 'growth': {
      const growthPlanCopy = getGrowthCopy(plan.id)
      return {
        ...growthPlanCopy,
        listItems:
          planFeatures?.growthPlanFeatures.filter(
            (x) => !planFeatures?.freePlanFeatures.includes(x)
          ) || growthPlanCopy.listItems,
      }
    }
    case 'growth-trial': {
      const growthTrialCopy = getTrialCopy(plan.id)
      return {
        ...growthTrialCopy,
        listItems: planFeatures?.growthPlanFeatures || growthTrialCopy.listItems,
      }
    }
    case 'professional':
      return getProfessionalCopy(plan.id)
    case 'team':
      return getTeamCopy(plan.id)
    case 'business':
      return getBusinessCopy(plan.id)
    case 'enterprise': {
      const enterprisePlanCopy = getEnterpriseCopy(plan.id)
      return {
        ...enterprisePlanCopy,
        listItems:
          planFeatures?.enterprisePlanFeatures.filter(
            (x) => !planFeatures?.growthPlanFeatures.includes(x)
          ) || enterprisePlanCopy.listItems,
      }
    }
    default:
      return undefined
  }
}
