import {SearchIcon} from '@sanity/icons'
import {Box, TextInput, type TextInputProps} from '@sanity/ui'
import {debounce} from 'lodash'
import {useCallback, useEffect, useMemo, useState} from 'react'
export interface SearchBarProps
  extends TextInputProps,
    Omit<React.HTMLProps<HTMLInputElement>, 'as' | 'prefix' | 'type' | 'onChange'> {
  onChange: (value: string) => void
}

export const SearchBar = ({onChange, value, ...restProps}: SearchBarProps) => {
  const [localValue, setLocalSearchQuery] = useState(value)

  // Debounce the search change callback to avoid re-rendering the table on every keystroke
  const debounceChange = useMemo(() => {
    return debounce(onChange, 200)
  }, [onChange])

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const query = event.currentTarget.value

      setLocalSearchQuery(query)
      debounceChange(query)
    },
    [debounceChange]
  )

  const handleClear = useCallback(() => {
    setLocalSearchQuery('')
    onChange('')
    debounceChange.cancel()
  }, [debounceChange, onChange])

  useEffect(() => {
    setLocalSearchQuery(value)
  }, [value])

  return (
    <Box flex={1}>
      <TextInput
        clearButton={localValue ? true : false}
        fontSize={1}
        icon={SearchIcon}
        padding={3}
        placeholder="Search for name or email"
        onClear={handleClear}
        onChange={handleChange}
        value={localValue}
        {...restProps}
      />
    </Box>
  )
}
