import React, {useCallback, useEffect} from 'react'
import {Box, Container, Stack, TextInput} from '@sanity/ui'
import {useForm} from 'react-hook-form'
import {FormField} from '../'
import {RadioInput} from '../inputs'
import {EditContainer} from './.'
import {ProjectMemberRole} from '@/types/models'

interface Props {
  roles: ProjectMemberRole[]
  onSave: ({role, label}: {role: string; label: string}) => void
  onCancel: () => void
  isLoading: boolean
}

export function ApiTokenInput({roles, onSave, onCancel, isLoading}: Props) {
  const {
    register,
    handleSubmit,
    formState: {errors},
    reset,
    setFocus,
  } = useForm<{role: string; label: string}>()

  useEffect(() => {
    return () => reset()
  }, [reset])

  useEffect(() => {
    setFocus('label')
  }, [setFocus])

  const onSubmit = (data: {role: string; label: string}) => {
    onSave(data)
  }

  const handleCancel = useCallback(() => {
    onCancel()
  }, [onCancel])

  return (
    <Container style={{margin: 0, maxWidth: 475}}>
      <EditContainer onSave={handleSubmit(onSubmit)} onCancel={handleCancel} loading={isLoading}>
        <FormField
          label="Name"
          description='Examples: "Employee import", "Website preview" or "PDF generator".'
          error={errors.label ? 'This field is required' : ''}
        >
          <TextInput
            padding={3}
            {...register('label', {
              required: true,
            })}
          />
        </FormField>
        <FormField
          label="Permissions"
          as="fieldset"
          description="Choose the access privileges for the token."
        >
          <Box marginTop={2}>
            <Stack space={4}>
              {roles.map((rol) => (
                <RadioInput
                  defaultChecked={rol.name === 'viewer'}
                  key={`${rol.name}-${rol.title}`}
                  label={rol.title}
                  description={rol.description}
                  value={rol.name}
                  {...register('role')}
                />
              ))}
            </Stack>
          </Box>
        </FormField>
      </EditContainer>
    </Container>
  )
}
