import {z} from 'zod'
import {Box, Button, Flex, Text, TextInput, useToast} from '@sanity/ui'
import {useCurrentScopeContext} from '@/context/index'
import {FormField} from '@/components/form'
import {zodResolver} from '@hookform/resolvers/zod'
import {useForm} from 'react-hook-form'
import {sendAmplitudeTrackingEvent} from '@/utils/tracking'
import {useProjectInvitations} from '@/data/invitations/useProjectInvitations'

const emailSchema = z.object({
  email: z.string().email('Invalid email address'),
})

type formValues = z.infer<typeof emailSchema>

export const InviteForm = () => {
  const toast = useToast()
  const {projectId, org} = useCurrentScopeContext()
  const {sendInvitations} = useProjectInvitations(projectId!)
  const form = useForm<formValues>({
    defaultValues: {
      email: '',
    },
    resolver: zodResolver(emailSchema),
  })

  const onSubmitHandler = async (data: formValues) => {
    sendAmplitudeTrackingEvent('Getting Started Developer Invited', projectId, org?.id, {})
    await sendInvitations(
      [
        {
          email: data.email,
          role: 'administrator',
        },
      ],
      {
        onSuccess: () => {
          toast.push({
            status: 'success',
            title: 'Invitation sent!',
            description: `${data.email} was invited to join the project`,
            closable: true,
            duration: 10000, // 10 seconds
          })
          form.reset()
        },
        onError: () => {
          toast.push({
            status: 'error',
            title: 'Failed to send invitation',
            description: `Please check the email address and try again`,
            closable: true,
            duration: 10000, // 10 seconds
          })
        },
      }
    )
  }

  return (
    <Box as={'form'} onSubmit={form.handleSubmit(onSubmitHandler)} marginTop={3}>
      <FormField
        label="Email address"
        labelWeight="semibold"
        labelMuted={true}
        error={form.formState.errors.email?.message}
      >
        <Flex justify="space-between" gap={1}>
          <TextInput {...form.register('email')} placeholder="name@company.com" padding={3} />
          <Button type="submit" tone="primary" padding={3} loading={form.formState.isSubmitting}>
            <Text>Invite</Text>
          </Button>
        </Flex>
      </FormField>
    </Box>
  )
}
