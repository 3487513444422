import React from 'react'
import {inviteConfig} from '../inviteConfig'
import {Organization} from '@/types/models'
import {InviteFormOrg} from '@/components/form/inviteFormOrg'
import {useOrganizationRolesList} from '@/data/organizations/useOrganizationRoleList'

export function InviteOrgMembers({org}: {org: Organization}) {
  const {data: roles = []} = useOrganizationRolesList(org?.id)
  if (!org) {
    return null
  }

  return <InviteFormOrg config={inviteConfig} id={org.id} org={org} roles={roles} />
}
