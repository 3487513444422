import {Fieldset} from '@/components/checkout/components/fieldset'
import {DowngradeRadioInput} from '@/components/checkout/components/downgradeRadioInput'
import {DowngradeSurveyFormData} from '@/components/checkout/types'

import {useFormContext} from 'react-hook-form'

type Props = {
  textSize?: number
}

export function DowngradeSurveyFieldset({textSize}: Props) {
  const {register} = useFormContext<DowngradeSurveyFormData>()

  return (
    <Fieldset legend="Can you let us know why you're downgrading?" space={3}>
      <DowngradeRadioInput
        value="price"
        label="Price is too high"
        register={register}
        textSize={textSize}
      />
      <DowngradeRadioInput
        value="functionality"
        label="Missing functionality"
        register={register}
        textSize={textSize}
      />
      <DowngradeRadioInput
        value="not-working"
        label="Not working as expected"
        register={register}
        textSize={textSize}
      />
      <DowngradeRadioInput
        value="dont-need-sanity"
        label="Don't need Sanity anymore"
        register={register}
        textSize={textSize}
      />
      <DowngradeRadioInput
        value="dont-need-paid-plan"
        label="Don't need paid features anymore"
        register={register}
        textSize={textSize}
      />
      <DowngradeRadioInput value="other" label="Other" register={register} textSize={textSize} />
    </Fieldset>
  )
}
