import {useQuery} from '@tanstack/react-query'
import {communityClient} from '../communityClient'

export type TemplateData = {
  title: string
  image: {
    _type: 'image'
    asset: {
      _ref: string
      _type: 'reference'
    }
  }
  description: string
  repository: string
  slug: {
    current: string
    _type: 'slug'
  }
}

export const useTemplateData = (templateSlug?: string | null) => {
  return useQuery({
    queryKey: ['templateData', templateSlug],
    queryFn: () =>
      communityClient.fetch<TemplateData>(
        `*[_type == "contribution.starter" && slug.current == $slug][0] {
          slug,
          title,
          image,
          description,
          repository
        }`,
        {slug: templateSlug}
      ),
    enabled: Boolean(templateSlug),
  })
}
