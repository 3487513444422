import {useMutation, useQueryClient} from '@tanstack/react-query'
import {deleteTag} from '.'
import {Tag} from '@/types/index'
import {getProjectTagsKey} from '../projects/cache'

export function useDeleteTag(projectId: string) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['tag', 'delete'],
    mutationFn: (tagName: string) => deleteTag(projectId, tagName),
    onMutate: async (tagName) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: getProjectTagsKey(projectId),
      })

      // Snapshot the previous value
      const previousTags = queryClient.getQueryData<Tag[]>(getProjectTagsKey(projectId))

      // Optimistically update to the new value
      queryClient.setQueryData<Tag[]>(getProjectTagsKey(projectId), (old) =>
        old ? old.map((tag) => (tag.name === tagName ? {...tag, isDeleted: true} : tag)) : []
      )

      // Return a context object with the snapshotted value
      return {previousTags}
    },
    onError: (err, _, context) => {
      // If the mutation fails, use the context returned from onMutate to roll back
      queryClient.setQueryData(getProjectTagsKey(projectId), context?.previousTags)
      console.error('Failed to delete tag:', err)
    },
    onSettled: () => {
      // Always refetch after error or success
      queryClient.invalidateQueries({
        queryKey: getProjectTagsKey(projectId),
      })
    },
  })
}
