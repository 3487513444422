/* eslint-disable react/jsx-no-bind */
import {Button, Flex, Grid} from '@sanity/ui'
import {format, getYear, setMonth} from 'date-fns'
import React, {useCallback, useRef} from 'react'
import styled, {css} from 'styled-components'

const MONTHS = [
  'January',
  'February',
  'Mars',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

type MonthsProps = {
  onClick: () => void
  month: string
  isSelected: boolean
  disabled?: boolean
  title?: string
}

const MonthButton = styled(Button)<{
  $isSelected: boolean | undefined
  $isDisabled: boolean | undefined
}>`
  border-radius: 50%;
  width: 40px;
  height: 40px;
  border: 0;
  box-shadow: 0;

  * {
    box-shadow: none;
  }

  [data-ui='Box'] {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  [data-ui='Text'] {
    color: inherit;
    font-weight: ${({theme}) => theme.sanity.fonts.text.weights.regular};
  }

  ${({$isDisabled: isDisabled}) =>
    !isDisabled &&
    css`
      background: ${({theme}) => theme.sanity.color.base.bg};
      color: ${({theme}) => theme.sanity.color.muted.transparent.enabled.fg};
    `}

  ${({$isSelected: isSelected, $isDisabled: isDisabled}) =>
    !isSelected &&
    !isDisabled &&
    css`
      &:focus,
      &:hover {
        background: ${({theme}) => theme.sanity.color.muted.primary.hovered.bg};
        color: ${({theme}) => theme.sanity.color.muted.primary.hovered.fg};
      }
    `}

  ${({$isSelected: isSelected, $isDisabled: isDisabled}) =>
    isSelected &&
    !isDisabled &&
    css`
      background: ${({theme}) => theme.sanity.color.muted.primary.hovered.bg};
      color: ${({theme}) => theme.sanity.color.muted.primary.hovered.fg};
    `}
`

function Month({onClick, month, isSelected, disabled, title}: MonthsProps) {
  return (
    <Flex justify="center" align="center">
      <MonthButton
        text={month.substring(0, 3)}
        tone="primary"
        disabled={disabled}
        $isDisabled={disabled}
        onClick={onClick}
        $isSelected={isSelected}
        tabIndex={isSelected ? -1 : 0}
        aria-label={title}
        mode="bleed"
      />
    </Flex>
  )
}

type MonthProps = {
  onChange: (date: Date) => void
  date: Date
  selectedDate: Date
  minMonth: number
  maxMonth: number
  disabledDates?: Date[]
}

export function Months({
  onChange,
  date,
  selectedDate,
  minMonth,
  maxMonth,
  disabledDates,
}: MonthProps) {
  const gridRef = useRef<HTMLDivElement | null>(null)
  const disabledDateStrings: string[] =
    disabledDates?.map((disabledDate) => format(disabledDate, 'yyyy-MM')) || []

  const handleChangeMonth = useCallback(
    (monthIndex) => {
      const newDate = setMonth(date, monthIndex)
      onChange(newDate)
    },
    [date, onChange]
  )

  return (
    <Grid columns={4} gap={2} paddingBottom={1} ref={gridRef}>
      {MONTHS.map((month, index) => {
        const isSelected = (idx: number) =>
          setMonth(date, idx).toISOString() === selectedDate.toISOString()

        const monthDate = setMonth(date, index)
        const disabled =
          (minMonth !== 11 && index < minMonth) ||
          (maxMonth !== 11 && index > maxMonth) ||
          disabledDateStrings.includes(format(monthDate, 'yyyy-MM'))

        return (
          <Month
            disabled={disabled}
            key={month}
            month={month}
            onClick={() => handleChangeMonth(index)}
            isSelected={isSelected(index)}
            title={`Select ${month}, ${getYear(date)}`}
          />
        )
      })}
    </Grid>
  )
}
