import React from 'react'

export const GettingStarted = ({color}: {color?: string}) => (
  <svg width="74" height="54" viewBox="0 0 74 54" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M47.8376 22.0881V24.0881C47.8376 24.3643 48.7331 24.5881 49.8376 24.5881C50.9422 24.5881 51.8376 24.3643 51.8376 24.0881V22.0881M51.8376 22.0881C51.8376 22.3643 50.9422 22.5881 49.8376 22.5881C48.7331 22.5881 47.8376 22.3643 47.8376 22.0881C47.8376 21.812 48.7331 21.5881 49.8376 21.5881C50.9422 21.5881 51.8376 21.812 51.8376 22.0881Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M53.8376 19.0881V21.0881C53.8376 21.3643 54.7331 21.5881 55.8376 21.5881C56.9422 21.5881 57.8376 21.3643 57.8376 21.0881V19.0881M57.8376 19.0881C57.8376 19.3643 56.9422 19.5881 55.8376 19.5881C54.7331 19.5881 53.8376 19.3643 53.8376 19.0881C53.8376 18.812 54.7331 18.5881 55.8376 18.5881C56.9422 18.5881 57.8376 18.812 57.8376 19.0881Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M53.8376 25.0881V27.0881C53.8376 27.3643 54.7331 27.5881 55.8376 27.5881C56.9422 27.5881 57.8376 27.3643 57.8376 27.0881V25.0881M57.8376 25.0881C57.8376 25.3643 56.9422 25.5881 55.8376 25.5881C54.7331 25.5881 53.8376 25.3643 53.8376 25.0881C53.8376 24.812 54.7331 24.5881 55.8376 24.5881C56.9422 24.5881 57.8376 24.812 57.8376 25.0881Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M59.8376 22.0881V24.0881C59.8376 24.3643 60.7331 24.5881 61.8376 24.5881C62.9422 24.5881 63.8376 24.3643 63.8376 24.0881V22.0881M63.8376 22.0881C63.8376 22.3643 62.9422 22.5881 61.8376 22.5881C60.7331 22.5881 59.8376 22.3643 59.8376 22.0881C59.8376 21.812 60.7331 21.5881 61.8376 21.5881C62.9422 21.5881 63.8376 21.812 63.8376 22.0881Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M34.1097 17.5881L34.1097 22.3161M43.8377 30.044L36 26.2348"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M53.4295 17.4963L65.5131 23.5207M65.5131 23.5207L55.7852 28.2486M65.5131 23.5207L65.5131 31.2486L62.2433 32.8377M55.7852 28.2486L43.7016 22.2242M55.7852 28.2486L55.7852 33.7179M43.7016 29.9521L53.4295 34.8021"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M4.4341 29.2068L16.5177 23.1824L26.2457 18.4545L35.9736 23.1824M4.4341 29.2068L14.162 33.9347M4.4341 29.2068L4.4341 36.9347L14.162 41.6626M14.162 33.9347L26.2456 27.9103L35.9736 23.1824M14.162 33.9347L14.162 41.6626M14.162 41.6626L22 37.7549M35.9736 23.1824L35.9736 30.9103L33.7316 32"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M34.1096 21.7743V23.7743C34.1096 24.0504 33.2142 24.2743 32.1096 24.2743C31.005 24.2743 30.1096 24.0504 30.1096 23.7743V21.7743M30.1096 21.7743C30.1096 22.0504 31.005 22.2743 32.1096 22.2743C33.2142 22.2743 34.1096 22.0504 34.1096 21.7743C34.1096 21.4981 33.2142 21.2743 32.1096 21.2743C31.005 21.2743 30.1096 21.4981 30.1096 21.7743Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M28.1096 18.7743V20.7743C28.1096 21.0504 27.2142 21.2743 26.1096 21.2743C25.005 21.2743 24.1096 21.0504 24.1096 20.7743V18.7743M24.1096 18.7743C24.1096 19.0504 25.005 19.2743 26.1096 19.2743C27.2142 19.2743 28.1096 19.0504 28.1096 18.7743C28.1096 18.4981 27.2142 18.2743 26.1096 18.2743C25.005 18.2743 24.1096 18.4981 24.1096 18.7743Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M28.1096 24.7743V26.7743C28.1096 27.0504 27.2142 27.2743 26.1096 27.2743C25.005 27.2743 24.1096 27.0504 24.1096 26.7743V24.7743M24.1096 24.7743C24.1096 25.0504 25.005 25.2743 26.1096 25.2743C27.2142 25.2743 28.1096 25.0504 28.1096 24.7743C28.1096 24.4981 27.2142 24.2743 26.1096 24.2743C25.005 24.2743 24.1096 24.4981 24.1096 24.7743Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M22.1096 21.7743V23.7743C22.1096 24.0504 21.2142 24.2743 20.1096 24.2743C19.005 24.2743 18.1096 24.0504 18.1096 23.7743V21.7743M18.1096 21.7743C18.1096 22.0504 19.005 22.2743 20.1096 22.2743C21.2142 22.2743 22.1096 22.0504 22.1096 21.7743C22.1096 21.4981 21.2142 21.2743 20.1096 21.2743C19.005 21.2743 18.1096 21.4981 18.1096 21.7743Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M22.1096 27.7743V29.7743C22.1096 30.0504 21.2142 30.2743 20.1096 30.2743C19.005 30.2743 18.1096 30.0504 18.1096 29.7743V27.7743M18.1096 27.7743C18.1096 28.0504 19.005 28.2743 20.1096 28.2743C21.2142 28.2743 22.1096 28.0504 22.1096 27.7743C22.1096 27.4981 21.2142 27.2743 20.1096 27.2743C19.005 27.2743 18.1096 27.4981 18.1096 27.7743Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M16.1096 24.7743V26.7743C16.1096 27.0504 15.2142 27.2743 14.1096 27.2743C13.005 27.2743 12.1096 27.0504 12.1096 26.7743V24.7743M12.1096 24.7743C12.1096 25.0504 13.005 25.2743 14.1096 25.2743C15.2142 25.2743 16.1096 25.0504 16.1096 24.7743C16.1096 24.4981 15.2142 24.2743 14.1096 24.2743C13.005 24.2743 12.1096 24.4981 12.1096 24.7743Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M16.1096 30.7743V32.7743C16.1096 33.0504 15.2142 33.2743 14.1096 33.2743C13.005 33.2743 12.1096 33.0504 12.1096 32.7743V30.7743M12.1096 30.7743C12.1096 31.0504 13.005 31.2743 14.1096 31.2743C15.2142 31.2743 16.1096 31.0504 16.1096 30.7743C16.1096 30.4981 15.2142 30.2743 14.1096 30.2743C13.005 30.2743 12.1096 30.4981 12.1096 30.7743Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M10.1096 27.7743V29.7743C10.1096 30.0504 9.21419 30.2743 8.10962 30.2743C7.00505 30.2743 6.10962 30.0504 6.10962 29.7743V27.7743M6.10962 27.7743C6.10962 28.0504 7.00505 28.2743 8.10962 28.2743C9.21419 28.2743 10.1096 28.0504 10.1096 27.7743C10.1096 27.4981 9.21419 27.2743 8.10962 27.2743C7.00505 27.2743 6.10962 27.4981 6.10962 27.7743Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M41.4295 35.8161L31.7015 31.0881L21.9736 35.8161M41.4295 35.8161L31.7015 40.544M41.4295 35.8161L41.4295 43.544L31.7015 48.2719M31.7015 40.544L21.9736 35.8161M31.7015 40.544L31.7015 48.2719M21.9736 35.8161L21.9736 43.544L31.7015 48.2719"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M23.7015 34.316V36.316C23.7015 36.5922 24.597 36.816 25.7015 36.816C26.8061 36.816 27.7015 36.5922 27.7015 36.316V34.316M27.7015 34.316C27.7015 34.5922 26.8061 34.816 25.7015 34.816C24.597 34.816 23.7015 34.5922 23.7015 34.316C23.7015 34.0399 24.597 33.816 25.7015 33.816C26.8061 33.816 27.7015 34.0399 27.7015 34.316Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M29.7015 31.316V33.316C29.7015 33.5922 30.597 33.816 31.7015 33.816C32.8061 33.816 33.7015 33.5922 33.7015 33.316V31.316M33.7015 31.316C33.7015 31.5922 32.8061 31.816 31.7015 31.816C30.597 31.816 29.7015 31.5922 29.7015 31.316C29.7015 31.0399 30.597 30.816 31.7015 30.816C32.8061 30.816 33.7015 31.0399 33.7015 31.316Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M29.7015 37.316V39.316C29.7015 39.5922 30.597 39.816 31.7015 39.816C32.8061 39.816 33.7015 39.5922 33.7015 39.316V37.316M33.7015 37.316C33.7015 37.5922 32.8061 37.816 31.7015 37.816C30.597 37.816 29.7015 37.5922 29.7015 37.316C29.7015 37.0399 30.597 36.816 31.7015 36.816C32.8061 36.816 33.7015 37.0399 33.7015 37.316Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M35.7015 34.316V36.316C35.7015 36.5922 36.597 36.816 37.7015 36.816C38.8061 36.816 39.7015 36.5922 39.7015 36.316V34.316M39.7015 34.316C39.7015 34.5922 38.8061 34.816 37.7015 34.816C36.597 34.816 35.7015 34.5922 35.7015 34.316C35.7015 34.0399 36.597 33.816 37.7015 33.816C38.8061 33.816 39.7015 34.0399 39.7015 34.316Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M69.5657 36.4644L59.8378 31.7365L50.1098 36.4644M69.5657 36.4644L59.8378 41.1923M69.5657 36.4644L69.5657 44.1923L59.8378 48.9202M59.8378 41.1923L50.1098 36.4644M59.8378 41.1923L59.8378 48.9202M50.1098 36.4644L50.1098 44.1923L59.8378 48.9202"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M51.8378 34.9644V36.9644C51.8378 37.2405 52.7332 37.4644 53.8378 37.4644C54.9423 37.4644 55.8378 37.2405 55.8378 36.9644V34.9644M55.8378 34.9644C55.8378 35.2405 54.9423 35.4644 53.8378 35.4644C52.7332 35.4644 51.8378 35.2405 51.8378 34.9644C51.8378 34.6882 52.7332 34.4644 53.8378 34.4644C54.9423 34.4644 55.8378 34.6882 55.8378 34.9644Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M57.8378 31.9644V33.9644C57.8378 34.2405 58.7332 34.4644 59.8378 34.4644C60.9423 34.4644 61.8378 34.2405 61.8378 33.9644V31.9644M61.8378 31.9644C61.8378 32.2405 60.9423 32.4644 59.8378 32.4644C58.7332 32.4644 57.8378 32.2405 57.8378 31.9644C57.8378 31.6882 58.7332 31.4644 59.8378 31.4644C60.9423 31.4644 61.8378 31.6882 61.8378 31.9644Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M57.8378 37.9644V39.9644C57.8378 40.2405 58.7332 40.4644 59.8378 40.4644C60.9423 40.4644 61.8378 40.2405 61.8378 39.9644V37.9644M61.8378 37.9644C61.8378 38.2405 60.9423 38.4644 59.8378 38.4644C58.7332 38.4644 57.8378 38.2405 57.8378 37.9644C57.8378 37.6882 58.7332 37.4644 59.8378 37.4644C60.9423 37.4644 61.8378 37.6882 61.8378 37.9644Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M63.8378 34.9644V36.9644C63.8378 37.2405 64.7332 37.4644 65.8378 37.4644C66.9423 37.4644 67.8378 37.2405 67.8378 36.9644V34.9644M67.8378 34.9644C67.8378 35.2405 66.9423 35.4644 65.8378 35.4644C64.7332 35.4644 63.8378 35.2405 63.8378 34.9644C63.8378 34.6882 64.7332 34.4644 65.8378 34.4644C66.9423 34.4644 67.8378 34.6882 67.8378 34.9644Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M53.5656 9.80763L43.8376 5.07971L34.1097 9.80763M53.5656 9.80763L43.8376 14.5356M53.5656 9.80763L53.5656 17.5356L43.8376 22.2635M43.8376 14.5356L34.1097 9.80763M43.8376 14.5356L43.8376 22.2635M34.1097 9.80763L34.1097 17.5356L43.8376 22.2635"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M35.8376 8.30762V10.3076C35.8376 10.5838 36.7331 10.8076 37.8376 10.8076C38.9422 10.8076 39.8376 10.5838 39.8376 10.3076V8.30762M39.8376 8.30762C39.8376 8.58376 38.9422 8.80762 37.8376 8.80762C36.7331 8.80762 35.8376 8.58376 35.8376 8.30762C35.8376 8.03147 36.7331 7.80762 37.8376 7.80762C38.9422 7.80762 39.8376 8.03147 39.8376 8.30762Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M41.8376 5.30762V7.30762C41.8376 7.58376 42.7331 7.80762 43.8376 7.80762C44.9422 7.80762 45.8376 7.58376 45.8376 7.30762V5.30762M45.8376 5.30762C45.8376 5.58376 44.9422 5.80762 43.8376 5.80762C42.7331 5.80762 41.8376 5.58376 41.8376 5.30762C41.8376 5.03147 42.7331 4.80762 43.8376 4.80762C44.9422 4.80762 45.8376 5.03147 45.8376 5.30762Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M41.8376 11.3076V13.3076C41.8376 13.5838 42.7331 13.8076 43.8376 13.8076C44.9422 13.8076 45.8376 13.5838 45.8376 13.3076V11.3076M45.8376 11.3076C45.8376 11.5838 44.9422 11.8076 43.8376 11.8076C42.7331 11.8076 41.8376 11.5838 41.8376 11.3076C41.8376 11.0315 42.7331 10.8076 43.8376 10.8076C44.9422 10.8076 45.8376 11.0315 45.8376 11.3076Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M47.8376 8.30762V10.3076C47.8376 10.5838 48.7331 10.8076 49.8376 10.8076C50.9422 10.8076 51.8376 10.5838 51.8376 10.3076V8.30762M51.8376 8.30762C51.8376 8.58376 50.9422 8.80762 49.8376 8.80762C48.7331 8.80762 47.8376 8.58376 47.8376 8.30762C47.8376 8.03147 48.7331 7.80762 49.8376 7.80762C50.9422 7.80762 51.8376 8.03147 51.8376 8.30762Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
  </svg>
)
GettingStarted.displayName = 'GettingStarted'
