import {useMutation, useQuery} from '@tanstack/react-query'
import {createContext, useContext} from 'react'

import {request} from '@/data/util/promiseRequest'
import {type AnnouncementDocument, type DialogMode} from '@/types/announcements'

export interface StudioAnnouncementsContextValue {
  announcements: AnnouncementDocument[]
  unseenAnnouncements: AnnouncementDocument[]
  onDialogOpen: (mode: DialogMode) => void
}

export const AnnouncementContext = createContext<StudioAnnouncementsContextValue | undefined>(
  undefined
)

export function useAnnouncements(): StudioAnnouncementsContextValue {
  const context = useContext(AnnouncementContext)
  if (!context) {
    throw new Error('useStudioAnnouncements: missing context value')
  }

  return context
}

const API_VERSION = 'v2024-09-19'

export function useProductAnnouncements() {
  const queryURL = `https://api.${process.env.host || 'sanity.work'}/${API_VERSION}/journey/announcements/seen`

  return useQuery({
    queryKey: ['announcements'],

    queryFn: async () => {
      const res = await request<
        | {
            allAnnouncements: AnnouncementDocument[]
            seenAnnouncements: string[] | null
          }
        | undefined
      >({
        url: queryURL,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      if (res.statusCode === 401) {
        return null
      }
      if (!res.ok) {
        throw new Error(res.body.message)
      }

      const allAnnouncements = res.body?.allAnnouncements || []
      const seenAnnouncements = res.body?.seenAnnouncements || []
      let unseenAnnouncements: AnnouncementDocument[] = []

      if (seenAnnouncements.length) {
        allAnnouncements.forEach((announcement) => {
          if (!seenAnnouncements.includes(announcement._id)) unseenAnnouncements.push(announcement)
        })
      } else {
        unseenAnnouncements = allAnnouncements
      }

      return {allAnnouncements, seenAnnouncements, unseenAnnouncements}
    },

    staleTime: 30 * 1000,
  })
}

export function useMarkAnnouncementsSeen() {
  return useMutation({
    mutationKey: ['announcements', 'markSeen'],
    mutationFn: async (data: AnnouncementDocument['_id'][]) => {
      const res = await request<string[] | null>({
        url: `https://api.${process.env.host || 'sanity.work'}/${API_VERSION}/journey/announcements/seen`,
        method: 'POST',
        body: {seenIds: data},
      })
      if (!res.ok) throw new Error(res.body.message)
    },
  })
}
