import {CustomerData, Customer} from '@/types/index'
import {promiseRequest} from '@/data/util/promiseRequest'
import {type OrganizationPaymentState} from '@/types/models/paymentstate'

export function patchCustomer(customerId: string, data: CustomerData) {
  return promiseRequest<Customer>(`https://api.${process.env.host}/v1/customers/${customerId}`, {
    json: data,
    method: 'PATCH',
  })
}

export function getPaymentState() {
  return promiseRequest<OrganizationPaymentState[]>(
    `https://api.${process.env.host}/v1/customers/paymentstate`
  )
}

export function getCustomer(id: string) {
  if (!id || id === 'personal') {
    return Promise.resolve(null)
  }
  return promiseRequest<Customer>(`https://api.${process.env.host}/v1/customers/${id}`)
}
