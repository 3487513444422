import {useQuery, useQueryClient, UseQueryResult} from '@tanstack/react-query'

import {CanPayStatus} from '@/types/models'
import {isAPIError, promiseRequest} from '@/data/util/promiseRequest'
import {useCallback, useMemo} from 'react'
import {timeToMilliseconds} from '@/utils/time'
import {getOrganizationCanPayKey} from './cache'

type useOrganizationCanPayReturn = UseQueryResult<CanPayStatus> & {
  invalidate: () => void
}
export function useOrganizationCanPay(organizationId?: string | null): useOrganizationCanPayReturn {
  const key = useMemo(() => getOrganizationCanPayKey(organizationId), [organizationId])
  const queryClient = useQueryClient()
  const query = useQuery({
    queryKey: key,
    queryFn: async () => {
      if (
        organizationId === undefined ||
        organizationId === null ||
        organizationId === 'personal'
      ) {
        return {status: false, reason: null}
      }

      try {
        await promiseRequest<CanPayStatus>(
          `https://api.${process.env.host}/v2022-08-11/customers/${organizationId}/canpay`
        )

        return {status: true, reason: null}
      } catch (error) {
        if (isAPIError(error)) {
          if (error.statusCode === 402) {
            return {
              status: false,
              reason: error.message,
            }
          }
        }

        return {
          status: false,
          reason: 'An error occurred while checking payment status',
        }
      }
    },

    placeholderData: {
      status: false,
      reason: null,
    },

    // 10 minutes of stale here should be ok
    staleTime: timeToMilliseconds(10, 'minutes'),
    gcTime: timeToMilliseconds(10, 'hours'),
  })

  const invalidate = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: key,
    })
  }, [queryClient, key])

  return {...query, invalidate}
}
