import {useReCaptchaContext} from '@/ui/recaptcha/ReCaptchaContext'
import {doWithRecaptcha} from '@/ui/recaptcha/recaptchaUtils'
import {CheckoutAlerts, Footer, LoadingOverlay} from '@/components/checkout/components'
import {
  BillingAddressFieldset,
  OrganizationFieldset,
  PaymentMethodFieldset,
  getStripeErrors,
} from '@/components/checkout/fieldsets'
import {useSwitchPlanStore} from '@/components/checkout/store'
import {CheckoutFormData} from '@/components/checkout/types'
import {useCurrentScopeContext} from '@/context/index'
import {
  createOrg,
  createSetupIntent,
  deleteOrg,
  setPaymentSourceForCustomer,
  switchProjectPlan,
  transferProject,
} from '@/data/index'
import {useCustomerData} from '@/data/organizations/useCustomerData'
import type {CustomerData, Project} from '@/types/index'
import {getProjectPath} from '@/utils/getProjectPath'
import {ensureSentenceStop, isNewPatch, stripEmptyKeys} from '@/utils/index'
import {zodResolver} from '@hookform/resolvers/zod'
import {Button, Card, Stack} from '@sanity/ui'
import * as Sentry from '@sentry/nextjs'
import {
  CardNumberElement,
  useStripe,
  useElements as useStripeElements,
} from '@stripe/react-stripe-js'
import {useRouter} from 'next/router'
import {useCallback, useEffect, useRef, useState} from 'react'
import {FormProvider, useForm, useWatch} from 'react-hook-form'
import {lastValueFrom} from 'rxjs'
import scrollIntoView from 'smooth-scroll-into-view-if-needed'
import {FORM_ERRORS} from './constants'
import {UpgradeSummary} from './components/upgradeSummary'
import styled from 'styled-components'
import {useCacheInvalidation} from '@/data/base/useCacheInvalidation'

interface Props {
  onSubmit: (project: Project) => void
  onSubmitError?: (title: string, message: string) => void
}

const StickyHeader = styled(Card)`
  position: sticky;
  z-index: 100;
  top: 0;
`

export function CheckoutForm({onSubmit, onSubmitError}: Props) {
  const {state} = useSwitchPlanStore()
  const scope = useCurrentScopeContext()
  const [hasAlerts, setHasAlerts] = useState(false)
  const [formLoading, setFormLoading] = useState(false)
  const form = useForm<CheckoutFormData>({
    disabled: hasAlerts, // Disable the form if there is an alert
    defaultValues: {
      org:
        scope?.org && scope?.org?.id !== 'personal'
          ? {type: 'existing', id: scope.org.id}
          : undefined,
      customer: {name: scope?.currentUser?.name},
    },
    resolver: zodResolver(CheckoutFormData),
  })
  const formData = useWatch({
    control: form.control,
  })
  const formRef = useRef<HTMLFormElement>(null)
  const {
    data: customerData,
    isLoading,
    error,
    patchData: updateCustomer,
  } = useCustomerData(formData.org?.id || scope!.org!.id)
  const pending = scope?.org?.id !== 'personal' && isLoading && !error
  const {reCaptchaScript, enabled: recaptchaEnabled} = useReCaptchaContext()
  const router = useRouter()
  const stripe = useStripe()
  const cardElements = useStripeElements()
  const {invalidateProject, invalidateCustomerData} = useCacheInvalidation()
  /* When org changes, update the customer data accordingly */
  useEffect(() => {
    form.setValue(
      'customer',
      customerData || {
        name: scope?.currentUser?.name || '',
        address: '',
        zip: '',
        city: '',
        country: '',
      }
    )
  }, [customerData, form, scope?.currentUser?.name])

  const scrollToFirstError = useCallback(() => {
    const firstError = document.querySelector('[aria-invalid="true"]') as HTMLElement | null
    if (firstError && formRef.current) {
      scrollIntoView(firstError, {
        boundary: formRef.current?.parentElement,
        scrollMode: 'if-needed',
        behavior: 'smooth',
        block: 'start',
        duration: 300,
      })
      firstError.querySelector('input')?.focus() // Avoid relying on react-hook-form ref focus, as it's incompatible with Stripe elements
    }
  }, [formRef])

  const onInvalidHandler = (): boolean => {
    /* Scroll to the first error on next tick */
    setTimeout(scrollToFirstError, 0)

    /* Syncs stripe errors with react-hook-form errors */
    const stripeErrors = getStripeErrors(cardElements)
    if (Object.keys(stripeErrors).length) {
      form.setError('stripe', stripeErrors)
      return true
    }

    return false
  }

  const onSubmitHandler = async (formData: CheckoutFormData) => {
    const invalid = onInvalidHandler()
    if (invalid) {
      return
    }
    const projectCache = structuredClone(scope!.project!)
    const card = cardElements?.getElement(CardNumberElement)
    let targetOrgId = scope?.org?.id === 'personal' ? formData.org?.id : scope?.org?.id
    let projectHasMoved = false

    try {
      setFormLoading(true)
      if (formData.org.type === 'new') {
        targetOrgId = await createOrg(
          {name: formData.org.name},
          {...formData.customer, email: scope!.currentUser!.email}
        ).then((org) => org.id)
      }
      if (formData.org.type === 'existing' && isNewPatch(customerData, formData.customer)) {
        updateCustomer(stripEmptyKeys(formData.customer) as CustomerData)
      }

      /* Save payment source if the user has entered card details, or is editing an existing card */
      if (card && stripe) {
        const clientSecret = await doWithRecaptcha(
          recaptchaEnabled,
          reCaptchaScript,
          'setup_intent',
          (token) => createSetupIntent(targetOrgId!, token)
        )
        if (!clientSecret) throw new Error(FORM_ERRORS.paymentSource)

        const {error, setupIntent} = await stripe.confirmCardSetup(clientSecret, {
          payment_method: {card},
        })
        if (error) throw new Error(error.message)
        if (!setupIntent?.payment_method) throw new Error(FORM_ERRORS.confirmCard)

        await doWithRecaptcha(recaptchaEnabled, reCaptchaScript, 'payment_source', (token) =>
          setPaymentSourceForCustomer(targetOrgId!, setupIntent.payment_method!, token)
        )

        invalidateCustomerData(targetOrgId!)
      }

      /* Transfer the project to the new organization before upgrading */
      if (projectCache?.organizationId !== targetOrgId) {
        await transferProject(projectCache!.id, targetOrgId!)
        projectHasMoved = true

        invalidateProject(projectCache.id, {
          invalidateAllProjects: true,
        })
      }

      /* Create a new project object with the new organization id */
      const newProject: Project = {...projectCache, organizationId: targetOrgId!}

      /* Redirect to the new project path under the same slug as before, if a new organization was created */
      const newProjectPath = getProjectPath(newProject)
      const slug = router.asPath.match(new RegExp(`^.*?${projectCache.id}(/.*)`))?.[1]
      await router.push(newProjectPath + slug)

      /* Finally, switch the project to the new plan */
      const planSwitch = await lastValueFrom(switchProjectPlan(projectCache.id, state.plan!.id, []))
      if (planSwitch?.status === 'incomplete') {
        throw new Error(FORM_ERRORS.cardDeclined, {cause: 'Card declined'})
      }
      onSubmit(newProject)
      setFormLoading(false)
    } catch (error) {
      setFormLoading(false)
      const {cause, message: _message} = error as Error
      const title = typeof cause === 'string' ? cause : 'Checkout failed'
      const message = ensureSentenceStop(_message) || FORM_ERRORS.generic
      onSubmitError?.(title, projectHasMoved ? `${message} ${FORM_ERRORS.projectMoved}` : message)
      Sentry.setTags({checkout_flow: title, project_id: projectCache?.id})
      Sentry.captureException(error) // Log the error to Sentry
      /* If we created a new organization, delete it. If we've gotten as far as transferring the project, it won't be deleted */
      if (formData.org.type === 'new' && targetOrgId) {
        deleteOrg(targetOrgId)
      }
    }
  }

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmitHandler, onInvalidHandler)}
        aria-busy={pending}
        ref={formRef}
      >
        <Card padding={4} paddingTop={3} style={{position: 'relative'}}>
          <Stack space={4}>
            <CheckoutAlerts setHasAlerts={setHasAlerts} />

            <StickyHeader borderBottom paddingBottom={3}>
              <UpgradeSummary />
            </StickyHeader>

            <OrganizationFieldset />
            <PaymentMethodFieldset customer={customerData} />
            <BillingAddressFieldset customer={customerData} />
          </Stack>
        </Card>

        <Stack paddingX={4} paddingBottom={4} space={4}>
          <Button
            text={`Upgrade to ${state.plan?.name}`}
            tone="primary"
            type="submit"
            width="fill"
            loading={pending || form.formState.isSubmitting}
            disabled={form.formState.disabled}
          />
          <Footer />
        </Stack>
      </form>

      <LoadingOverlay show={pending || form.formState.isSubmitting || formLoading} />
    </FormProvider>
  )
}
