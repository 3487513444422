import {CheckmarkIcon, ChevronRightIcon} from '@sanity/icons'
import {Flex, Box, Inline, Text, Card} from '@sanity/ui'
import React from 'react'
import styled, {css} from 'styled-components'

type Props = {
  onClick: () => void
  text: string
  selected?: boolean | undefined
  hasChildren?: boolean
}

const Button = styled.button<{selected: boolean}>`
  height: 64px;
  text-align: start;
  width: 100%;
  border: 0;
  background: ${({theme}) => theme.sanity.color.base.bg};
  color: ${({theme}) => theme.sanity.color.base.fg};
  outline: 0;

  ${({selected}) =>
    selected &&
    css`
      background: ${({theme}) => theme.sanity.color.muted.primary.enabled.bg};
      color: ${({theme}) => theme.sanity.color.muted.primary.enabled.fg};
    `}
`

export function SlideItem({text, onClick, selected = false, hasChildren}: Props) {
  return (
    <Card as="li" borderTop>
      <Button onClick={onClick} selected={selected}>
        <Box paddingLeft={2} paddingRight={2}>
          <Flex align="center" justify="space-between">
            <Box
              paddingBottom={2}
              paddingTop={2}
              style={{
                display: 'block',
                textOverflow: 'ellipsis',
                overflowX: 'hidden',
                whiteSpace: 'nowrap',
                maxWidth: '80%',
              }}
            >
              <Text size={2}>
                <span
                  style={{
                    display: 'block',
                    textOverflow: 'ellipsis',
                    overflowX: 'hidden',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {text}
                </span>
              </Text>
            </Box>

            {(selected || hasChildren) && (
              <Box marginLeft={4}>
                <Inline space={3}>
                  {selected && (
                    <Text size={2}>
                      <CheckmarkIcon />
                    </Text>
                  )}
                  {hasChildren && (
                    <Text size={2} muted>
                      <ChevronRightIcon />
                    </Text>
                  )}
                </Inline>
              </Box>
            )}
          </Flex>
        </Box>
      </Button>
    </Card>
  )
}
