import {Tag, Project, ProjectMemberRole} from '../../types'
import {tagRoles} from '.'

import {useQuery} from '@tanstack/react-query'
import {getProjectTagRolesKey} from '../projects/cache'

export function useTagRoles(project: Project, tag: Tag) {
  return useQuery<ProjectMemberRole[]>({
    queryKey: getProjectTagRolesKey(project.id, tag.name),
    queryFn: () => tagRoles(project, tag),
  })
}
