import React from 'react'
import {
  Container,
  Box,
  Stack,
  Heading,
  Text,
  Grid,
  Inline,
  Flex,
  Button,
  ButtonProps,
  Badge,
} from '@sanity/ui'
import {ArrowTopRightIcon, BoltIcon} from '@sanity/icons'
import {AppLink} from '../..'
import {useCurrentScopeContext, useRoutePath} from '@/context/index'
import {useMatchesMedia} from '@/utils/index'
import {ReferenceLink} from '@/types/components'
import {isInfinity} from '@/utils/number'
import styled from 'styled-components'
import {purple} from '@sanity/color'
import {useIsOrgPlan} from '@/context/useIsOrgPlan'
import {useProjectSubscription} from '@/data/projects/useProjectSubscription'
import {useManageScheme} from '@/ui/app/manageSchemeProvider'

interface Props {
  title: string | React.ReactNode
  badge?: string
  description?: string | React.ReactNode
  button?: React.ReactNode
  content?: React.ReactNode
  counter?: {
    current: number
    quota: number
    max: number
    resource: string
  }
  referenceLink?: ReferenceLink
  tooltip?: React.ReactNode
  showUpsell?: boolean
  upsellResourceName?: string
  upsellButtonText?: string
  referenceLinkComponent?: JSX.ElementType
}

const AppLinkButton = ({href, target, ...props}: ButtonProps & {href: string; target?: string}) => {
  return <Button {...props} as={AppLink} href={href} target={target} />
}

const BlueLinkButton = styled(AppLinkButton)`
  [data-ui='Text'] {
    color: ${({theme}) => theme.sanity.color.card.enabled.link.fg};
  }
  svg {
    color: inherit !important;
  }
`

const PurpleLinkButton = styled(AppLinkButton)<{$dark?: boolean}>`
  [data-ui='Text'] {
    color: ${({$dark}) => ($dark ? purple['400'].hex : purple['600'].hex)};
  }

  &:hover {
    background-color: ${({$dark}) => ($dark ? purple['950'].hex : purple['50'].hex)};
  }
  svg {
    color: inherit !important;
  }
`

export function TabHeader({
  title,
  badge,
  description,
  referenceLink,
  referenceLinkComponent,
  button,
  counter,
  content,
  tooltip,
  showUpsell = false,
  upsellResourceName,
  upsellButtonText,
}: Props) {
  const {projectId} = useCurrentScopeContext()
  const {data: subscription} = useProjectSubscription(projectId)
  // Don't show upsell button for certain plans
  const shouldUpsell = !['poc', 'enterprise', 'internal', 'test', 'non-profit'].includes(
    subscription?.plan?.planTypeId || ''
  )
  const isMobile = useMatchesMedia(1)
  const {basePath} = useRoutePath()
  const isOrgPlan = useIsOrgPlan()
  const {resolvedScheme} = useManageScheme()
  const ReferenceLinkComponent = referenceLinkComponent
  return (
    <Container style={{position: 'relative'}}>
      <Grid
        columns={[1, 1, 2]}
        row={3}
        gapX={3}
        style={isMobile ? {} : {gridTemplateColumns: '1.5fr 1fr'}}
      >
        <Flex style={isMobile ? {} : {order: 0}} align="center">
          <Box paddingTop={2}>
            <Heading size={2} as="h2">
              {title}
            </Heading>
          </Box>
          {badge ? (
            <Box paddingLeft={2}>
              <Badge paddingX={2} marginTop={1}>
                {badge}
              </Badge>
            </Box>
          ) : null}
        </Flex>
        <Flex style={isMobile ? {} : {order: 3}}>
          {description && (
            <Box paddingTop={4}>
              <Text size={2} muted style={{maxWidth: 440}} as="p">
                {description}
              </Text>
            </Box>
          )}
        </Flex>
        <Flex style={isMobile ? {} : {order: 5}}>
          {referenceLink && (
            <Box paddingTop={3} style={{marginLeft: '-0.75rem'}}>
              <BlueLinkButton
                icon={ArrowTopRightIcon}
                href={referenceLink.url}
                fontSize={1}
                target={'_blank'}
                text={referenceLink.label}
                mode="bleed"
              />
            </Box>
          )}
          {ReferenceLinkComponent && (
            <Box paddingTop={3} style={{marginLeft: '-0.75rem'}}>
              <ReferenceLinkComponent />
            </Box>
          )}
        </Flex>

        <>
          <Box style={isMobile ? {} : {order: 2}}>
            {button && (
              <Flex marginTop={[3, 3, 0]} justify={isMobile ? 'flex-start' : 'flex-end'}>
                {button}
              </Flex>
            )}
          </Box>

          <Flex
            style={isMobile ? {} : {order: 4}}
            justify={isMobile ? 'flex-start' : 'flex-end'}
            align={'center'}
          >
            {counter && (
              <Stack
                space={3}
                paddingTop={4}
                style={{
                  maxWidth: 170,
                  textAlign: isMobile ? 'left' : 'right',
                  justifySelf: isMobile ? 'flex-start' : 'flex-end',
                  marginRight: 0,
                }}
              >
                <Inline marginBottom={1} space={3}>
                  {tooltip && <Text>{tooltip}</Text>}
                  <Text>
                    <strong>{`${counter.current}`}</strong>{' '}
                    {`of ${isInfinity(counter.max) ? '∞' : counter.max}`}
                  </Text>
                </Inline>
                <Text size={1} muted>
                  {counter.resource}
                </Text>
                {counter.quota > 0 && !isInfinity(counter.quota) ? (
                  <Text size={1} muted>
                    ({counter.quota} included in {isOrgPlan ? 'organization' : 'plan'})
                  </Text>
                ) : null}
              </Stack>
            )}
          </Flex>
          <Flex
            style={isMobile ? {} : {order: 6}}
            justify={isMobile ? 'flex-start' : 'flex-end'}
            align={'center'}
          >
            {showUpsell && shouldUpsell && (
              <Box paddingTop={3} style={{marginRight: '-0.75rem'}}>
                <PurpleLinkButton
                  icon={BoltIcon}
                  href={`${basePath}/plan?ref=manage-upsell-tab-header-${
                    upsellResourceName || counter?.resource
                  }`}
                  fontSize={1}
                  text={
                    upsellButtonText
                      ? upsellButtonText
                      : `Get more ${upsellResourceName || counter?.resource}`
                  }
                  mode="bleed"
                  $dark={resolvedScheme === 'dark'}
                />
              </Box>
            )}
          </Flex>
        </>

        {content && <Box marginY={[4, 4, 0]}>{content}</Box>}
      </Grid>
    </Container>
  )
}
