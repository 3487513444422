import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Observable} from 'rxjs'
import {
  Box,
  Card,
  Flex,
  Dialog,
  Grid,
  Inline,
  Stack,
  Text,
  Button,
  Heading,
  TextInput,
  Label,
  useToast,
  Popover,
  useClickOutside,
} from '@sanity/ui'
import {
  SpinnerIcon,
  AddIcon,
  ArrowRightIcon,
  RemoveCircleIcon,
  SelectIcon,
  ErrorOutlineIcon,
} from '@sanity/icons'
import {hues} from '@sanity/color'
import {Err} from './err'
import {Li, Loader} from '@/ui/index'
import {Project, ProjectMemberRole} from '@/types/models/project'
import {OrganizationSSOProviderSAML, RoleMapping, SetRoleMapping} from '@/types/models/organization'
import {useProjectRoles} from '@/data/projects/useProjectRoles'
import {MenuMultiSelect, MenuMultiSelectOption} from '@/components/general/menuMultiSelect'

export const FALLBACK_GROUP_RULE = '_.*.sanity.fallback'
export const REQUIRED_ROLE_MAPPING_FEATURE = 'authSAMLRoleMapping'
const DEFAULT_GROUP_RULE = ''

const red = hues.red['700'].hex

type RoleErr = Err & {
  badRule: boolean
  badRole: boolean
}

type ErrMap = Record<string, RoleErr>

function isValidRegex(rule: string): boolean {
  try {
    return !!new RegExp(rule)
  } catch {
    return false
  }
}

function translate(rolesMappings: RolesMapping[], err: string): ErrMap {
  try {
    const {message} = JSON.parse(err)
    if (message.includes('request validation failed')) {
      const key = ''
      return rolesMappings.reduce((acc, rm) => {
        if (rm.groupRule === key) {
          acc[rm.id] = {
            title: 'Invalid role mapping',
            description: 'Must specify group name and Sanity role.',
            short: 'The field is required',
            key,
            badRule: true,
            badRole: false,
          }
        }
        return acc
      }, {} as ErrMap)
    }
    if (message.includes('must be a valid regex')) {
      const key = message.replace('groupRule "', '').replace('" must be a valid regex', '')
      return rolesMappings.reduce((acc, rm) => {
        if (rm.groupRule === key) {
          acc[rm.id] = {
            title: 'Invalid group name',
            description: `Unsupported regex "${key}". Verify the regex is valid and does not contain any backreferences or lookahead assertions.`,
            short: 'The field contains unsupported regex',
            key: key,
            badRule: true,
            badRole: false,
          }
        }
        return acc
      }, {} as ErrMap)
    }
  } catch {
    /* do nothing */
  }
  return {
    __generic__: {
      title: 'There was an error submitting the form',
      description: err,
      badRule: false,
      badRole: false,
    },
  }
}

/**
 * Diff the roles mappings list (i.e. what the user wants) with the role mapping (i.e. what the server has)
 * The "exclusive" flag controls if current mappings are returned as part of add.
 **/
function diffMappings(
  rolesMappings: RolesMapping[],
  projectRoleMappings: RoleMapping[],
  exclusive = false
) {
  const add = rolesMappings.reduce((acc, map) => {
    for (const roleName of map.roleNames) {
      const has = projectRoleMappings.find(
        (prm) => prm.groupRule === map.groupRule && prm.roleName && prm.roleName === roleName
      )
      if (has && exclusive) continue
      if (has?.id && acc.some((a) => a?.id && a?.id === has?.id)) continue
      acc.push({
        id: has?.id,
        groupRule: map.groupRule,
        roleName,
      })
    }
    return acc
  }, [] as SetRoleMapping[])
  const remove = projectRoleMappings.reduce((acc, prm) => {
    const has = rolesMappings.find(
      (map) =>
        map.groupRule === prm.groupRule && prm.roleName && map.roleNames.includes(prm.roleName)
    )
    if (has) return acc
    if (prm.id && acc.some((a) => a?.id && a?.id === prm.id)) return acc
    acc.push({
      id: prm.id,
      groupRule: null,
      roleName: null,
    })
    return acc
  }, [] as SetRoleMapping[])
  return [add, remove]
}

type RolesMapping = {
  id: string
  groupRule: string
  roleNames: string[]
}
function tmpId() {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  for (let i = 0; i < 8; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length))
  }
  return result
}

function RoleMappingSelector({
  roles,
  groupRule: defaultGroupRule,
  roleNames: defaultRoleName,
  mappingErr,
  disabled,
  handleRoleMappingChange,
  handleGroupRuleDelete,
  index,
}: {
  roles: ProjectMemberRole[]
  roleNames: string[]
  groupRule: string
  mappingErr?: RoleErr
  disabled: boolean
  handleRoleMappingChange: (data: {roleName?: string; groupRule?: string}) => void
  handleGroupRuleDelete: () => void
  index: number
}) {
  const [roleNames, setRoleName] = useState<string[]>(defaultRoleName)
  const [groupRule, setGroupRule] = useState<string>(defaultGroupRule)
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState<boolean>(false)
  const [popoverElement, setPopoverElement] = useState<HTMLElement | null>(null)

  useClickOutside(() => setShowRemoveConfirmation(false), [popoverElement])
  const toggleRemoveConfirmation = useCallback(
    (b: boolean) => () => setShowRemoveConfirmation(b),
    []
  )

  const roleOptions: MenuMultiSelectOption[] = useMemo(() => {
    return roles
      .filter(({appliesToUsers}) => appliesToUsers)
      .sort((a, b) => a.name.localeCompare(b.name))
  }, [roles])

  const handleRoleChange = useCallback(
    (roleName) => {
      if (roleNames.includes(roleName)) {
        setRoleName(roleNames.filter((name) => name !== roleName))
      } else {
        setRoleName([...roleNames, roleName])
      }
      handleRoleMappingChange({roleName})
    },
    [roleNames, setRoleName, handleRoleMappingChange]
  )
  const handleGroupRuleChange = useCallback(
    (ev) => {
      setGroupRule(ev.target.value)
      handleRoleMappingChange({groupRule: ev.target.value})
    },
    [setGroupRule, handleRoleMappingChange]
  )

  return (
    <>
      <Stack space={2}>
        <Card tone={mappingErr?.badRule ? 'critical' : 'default'}>
          <TextInput
            value={groupRule}
            onChange={handleGroupRuleChange}
            disabled={disabled}
            style={{paddingRight: mappingErr?.badRule ? '2rem' : 0}}
            fontSize={1}
          />
        </Card>
        {mappingErr?.badRule && (
          <Inline paddingX={1} space={2}>
            <Text size={1} style={{color: red}}>
              <ErrorOutlineIcon />
            </Text>
            <Text size={1} style={{color: red}}>
              {mappingErr?.short}
            </Text>
          </Inline>
        )}
      </Stack>
      <Flex justify="center" paddingY={3}>
        <Text size={1} muted>
          <ArrowRightIcon />
        </Text>
      </Flex>

      <Stack space={2}>
        <MenuMultiSelect
          id={`roles-${index}`}
          options={roleOptions}
          values={roleNames}
          onChange={handleRoleChange}
          noValue={'Select roles...'}
          icon={SelectIcon}
          disabled={disabled}
          mode="ghost"
          tone={mappingErr?.badRole ? 'critical' : 'default'}
        />
        {mappingErr?.badRole && (
          <Inline space={2} paddingX={1}>
            <Text size={1} style={{color: red}}>
              <ErrorOutlineIcon />
            </Text>
            <Text size={1} style={{color: red}}>
              {mappingErr?.short}
            </Text>
          </Inline>
        )}
      </Stack>
      <Flex marginTop={0}>
        <Popover
          portal
          content={
            <Stack style={{minWidth: 300}}>
              <Box padding={3} paddingY={4}>
                <Text>Remove role mapping?</Text>
              </Box>
              {/* Need to use marginBottom to circumvent overflow issues on the popover  */}
              <Card borderTop overflow="hidden" padding={2} paddingBottom={0} marginBottom={2}>
                <Grid columns={2} gap={2}>
                  <Button mode="bleed" text="Cancel" onClick={toggleRemoveConfirmation(false)} />
                  <Button tone="critical" text="Confirm" onClick={handleGroupRuleDelete} />
                </Grid>
              </Card>
            </Stack>
          }
          open={showRemoveConfirmation}
          placement="top"
          ref={setPopoverElement}
        >
          <Box>
            <Button
              onClick={toggleRemoveConfirmation(true)}
              icon={RemoveCircleIcon}
              tone="default"
              mode="bleed"
              fontSize={1}
              padding={3}
              aria-label="Remove rule"
              disabled={disabled || (index === 0 && roleNames.length === 0 && groupRule === '')}
            />
          </Box>
        </Popover>
      </Flex>
    </>
  )
}

function ManageRoleMapping({
  disabled,
  roles,
  roleMappings,
  setRoleMappings,
  clearError,
  errMap,
}: {
  disabled: boolean
  roles: ProjectMemberRole[]
  roleMappings: RolesMapping[]
  setRoleMappings: React.Dispatch<React.SetStateAction<RolesMapping[]>>
  clearError: (id: string, flags: {role: boolean; rule: boolean}) => void
  errMap: ErrMap
}) {
  const handleRoleMappingChange = useCallback(
    (id) =>
      ({roleName, groupRule}: {roleName?: string; groupRule?: string}) => {
        clearError(id, {role: roleName !== undefined, rule: groupRule !== undefined})
        setRoleMappings((values) => {
          const mappings = [...values]
          const m = mappings.find((mapping) => mapping.id === id)
          if (m === undefined) {
            return mappings
          }
          if (roleName !== undefined) {
            if (m.roleNames.includes(roleName)) {
              m.roleNames = m.roleNames.filter((n) => n !== roleName)
            } else {
              m.roleNames = [...m.roleNames, roleName]
            }
          }
          if (groupRule !== undefined) {
            m.groupRule = groupRule
          }
          return mappings
        })
      },
    [setRoleMappings, clearError]
  )
  const handleGroupRuleDelete = useCallback(
    (id) => () => {
      setRoleMappings((values) => {
        return values.filter((m) => m.id !== id)
      })
    },
    [setRoleMappings]
  )
  const addRoleMapping = useCallback(() => {
    setRoleMappings((values) => {
      const n = [...values]
      n.push({
        id: tmpId(),
        roleNames: [],
        groupRule: DEFAULT_GROUP_RULE,
      })
      return n
    })
  }, [setRoleMappings])

  useEffect(() => {
    if (roleMappings.length === 0) {
      addRoleMapping()
    }
  }, [addRoleMapping, roleMappings])

  return (
    <Stack space={3}>
      <Grid gapX={2} gapY={3} columns={4} style={{gridTemplateColumns: '1fr 20px 1fr 30px'}}>
        <Flex flex={2} align="center">
          <Label size={0} muted>
            Group name
          </Label>
        </Flex>
        <Box>&nbsp;</Box>
        <Flex flex={2} align="center">
          <Label size={0} muted>
            Sanity roles
          </Label>
        </Flex>
        <Box>&nbsp;</Box>

        {roleMappings.map((rm, idx) => {
          const err = errMap[rm.id]
          return (
            <RoleMappingSelector
              key={rm.id}
              roles={roles}
              roleNames={rm.roleNames}
              groupRule={rm.groupRule}
              mappingErr={err}
              handleRoleMappingChange={handleRoleMappingChange(rm.id)}
              handleGroupRuleDelete={handleGroupRuleDelete(rm.id)}
              disabled={disabled}
              index={idx}
            />
          )
        })}
      </Grid>
      <Stack>
        <Button
          onClick={addRoleMapping}
          icon={AddIcon}
          mode="ghost"
          tone="default"
          fontSize={1}
          padding={3}
          text={'Add mapping'}
          disabled={disabled}
        />
      </Stack>
    </Stack>
  )
}

type SsoSAMLProjectRoleDialogProps = {
  samlProvider: OrganizationSSOProviderSAML
  project: Project
  loading: boolean
  closeDialog: () => void
  saveProjectRoleMappings: (
    providerId: string,
    projectId: string,
    opts: {roleMappings?: SetRoleMapping[]; fallbackRoleName?: string | null}
  ) => Observable<RoleMapping[]>
}

export default function SsoSAMLProjectRoleDialog({
  samlProvider,
  project,
  loading,
  closeDialog,
  saveProjectRoleMappings,
}: SsoSAMLProjectRoleDialogProps) {
  const {push} = useToast()
  const [errMap, setErrMap] = useState<ErrMap>({})
  const fallbackGroupRule = useMemo(() => {
    return (
      (samlProvider.projectRoleMappings[project.id] || []).find(
        ({groupRule}) => groupRule === FALLBACK_GROUP_RULE
      )?.roleName || undefined
    )
  }, [project.id, samlProvider.projectRoleMappings])
  const projectRoleMappings = useMemo(() => {
    return (samlProvider.projectRoleMappings[project.id] || []).filter(
      ({groupRule}) => groupRule !== FALLBACK_GROUP_RULE
    )
  }, [samlProvider.projectRoleMappings, project.id])

  const [rolesMappings, setRolesMappings] = useState<RolesMapping[]>(
    Object.values(
      projectRoleMappings.reduce(
        (acc, m) => {
          // the api uses null values to denote deletions, we can safely ignore them
          if (m.groupRule === null || m.roleName === null) {
            return acc
          }
          if (acc[m.groupRule] === undefined) {
            acc[m.groupRule] = {
              id: tmpId(),
              groupRule: m.groupRule,
              roleNames: [m.roleName],
            }
          } else {
            acc[m.groupRule].roleNames.push(m.roleName)
          }
          return acc
        },
        {} as Record<string, RolesMapping>
      )
    )
  )

  const [fallbackRoleMapping, setFallbackRoleMapping] = useState<string | undefined>(
    fallbackGroupRule
  )

  const save = useCallback(() => {
    // do a quick check to validate regex
    const ruleErrs = rolesMappings.reduce((acc, curr) => {
      const emptyRule = curr.groupRule === ''
      const badRegex = !isValidRegex(curr.groupRule)
      const emptyRole = curr.roleNames.length === 0
      if (emptyRole && emptyRule) {
        return acc
      }
      if (emptyRule || badRegex || emptyRole) {
        let description = 'Must specify valid group name and Sanity role.'
        let short = 'A group name is required'
        if (badRegex && !emptyRole) {
          description = `Invalid regex "${curr.groupRule}".`
          short = 'The field contains invalid regex'
        } else if (!emptyRule && !badRegex && emptyRole) {
          description = `Must specify a Sanity role.`
          short = 'Must specify a Sanity role'
        }
        acc[curr.id] = {
          title: 'Invalid role mapping',
          description,
          short,
          badRule: emptyRule || badRegex,
          badRole: emptyRole,
        }
      }
      return acc
    }, {} as ErrMap)

    if (Object.keys(ruleErrs).length > 0) {
      setErrMap(ruleErrs)
      return
    }

    // determine add/remove set
    setErrMap({})
    const [add, remove] = diffMappings(rolesMappings, projectRoleMappings)

    saveProjectRoleMappings(samlProvider.id, project.id, {
      roleMappings: [...add, ...remove].filter(Boolean),
      fallbackRoleName:
        fallbackRoleMapping && fallbackRoleMapping.length > 0 ? fallbackRoleMapping : null,
    }).subscribe(
      () => {
        closeDialog()
        push({
          description: 'Project SAML SSO configuration updated successfully',
          status: 'success',
        })
      },
      (e) => {
        setErrMap(translate(rolesMappings, e?.response?.body || ''))
      },
      () => {
        /* do nothing */
      }
    )
  }, [
    saveProjectRoleMappings,
    samlProvider.id,
    project.id,
    rolesMappings,
    fallbackRoleMapping,
    closeDialog,
    projectRoleMappings,
    push,
  ])

  const handleSetFallbackRoleMapping = useCallback(
    (roleName) => {
      if (roleName === fallbackRoleMapping) {
        setFallbackRoleMapping(undefined)
      } else {
        setFallbackRoleMapping(roleName)
      }
    },
    [fallbackRoleMapping]
  )

  const {
    data: projectRoles = [],
    error: errorRoles,
    isLoading: loadingProjectRoles,
  } = useProjectRoles(project.id)

  const roleOptions: MenuMultiSelectOption[] = useMemo(() => {
    return (projectRoles || [])
      .filter(({appliesToUsers}) => appliesToUsers)
      .sort((a, b) => a.name.localeCompare(b.name))
  }, [projectRoles])

  const canManage: boolean = useMemo(() => {
    return projectRoles.length > 0 && !loadingProjectRoles && !errorRoles
  }, [projectRoles, loadingProjectRoles, errorRoles])

  const hasRoleMappingFeature = project.features.includes(REQUIRED_ROLE_MAPPING_FEATURE)

  const clearError = useCallback(
    (id, {role, rule}: {role: boolean; rule: boolean}) => {
      const err = errMap[id]
      if (err === undefined) return
      if (err.badRole && role) err.badRole = false
      if (err.badRule && rule) err.badRule = false
      if (!err.badRole && !err.badRule) delete errMap[id]
      setErrMap({...errMap})
    },
    [errMap]
  )

  const dirtyForm = useMemo(() => {
    if (fallbackGroupRule !== fallbackRoleMapping) return true
    const [add, remove] = diffMappings(rolesMappings, projectRoleMappings, true)
    return add.length > 0 || remove.length > 0
  }, [fallbackGroupRule, fallbackRoleMapping, rolesMappings, projectRoleMappings])

  return (
    <Dialog
      header={`Role mapping for ${project.displayName}`}
      id="saml-project-dialog"
      onClose={closeDialog}
      zOffset={1000}
      width={1}
      footer={
        !loadingProjectRoles && (
          <Grid columns={2} gapX={2} padding={4}>
            {canManage && (
              <>
                <Button text="Cancel" mode="bleed" onClick={closeDialog} />
                <Button
                  text="Save"
                  tone="primary"
                  icon={loading ? SpinnerIcon : undefined}
                  onClick={save}
                  disabled={!dirtyForm}
                />
              </>
            )}
            {!canManage && (
              <>
                <Button text="Cancel" mode="default" onClick={closeDialog} />
              </>
            )}
          </Grid>
        )
      }
    >
      {loadingProjectRoles && (
        <Box padding={2} margin={3}>
          <Loader text="Loading project roles..." />
        </Box>
      )}

      {canManage && (
        <Stack padding={4} space={5}>
          <Card>
            <Stack space={2}>
              <Heading size={0}>Default fallback role</Heading>
              <Text muted size={1}>
                This role is given to all users that do not belong to any groups mapped below
              </Text>
              <Inline>
                <Card border radius={2} overflow="hidden">
                  <MenuMultiSelect
                    id={`fallback-role`}
                    options={roleOptions}
                    values={[fallbackRoleMapping || '']}
                    onChange={handleSetFallbackRoleMapping}
                    noValue={'Select role...'}
                    icon={SelectIcon}
                  />
                </Card>
              </Inline>
            </Stack>
          </Card>
          <Card>
            <Stack space={4}>
              <Stack space={2}>
                <Heading size={0}>Role mappings</Heading>
                <Text muted size={1}>
                  Add existing SSO groups and map them to roles in the project. Group names must be
                  a valid regex.{' '}
                  <a
                    href={`https://www.${process.env.host}/docs/sso-saml`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Read more →
                  </a>
                </Text>
              </Stack>

              {hasRoleMappingFeature === false && (
                <Card padding={3} tone="primary" border radius={2}>
                  <Flex justify="space-between" align="center">
                    <Text size={1} muted>
                      Role mapping is available for projects on the{' '}
                      <a
                        href={`https://www.${process.env.host}/pricing`}
                        target="_blank"
                        rel="noreferrer"
                        style={{textDecoration: 'underline'}}
                      >
                        Enterprise plan
                      </a>
                      .
                    </Text>
                    <Button
                      as="a"
                      href={`https://www.${process.env.host}/contact/sales?ref=manage-saml`}
                      target="_blank"
                      rel="noreferrer"
                      text="Upgrade"
                      padding={2}
                      fontSize={1}
                      tone="primary"
                    />
                  </Flex>
                </Card>
              )}

              <ManageRoleMapping
                disabled={hasRoleMappingFeature === false}
                roles={projectRoles || []}
                roleMappings={rolesMappings}
                setRoleMappings={setRolesMappings}
                clearError={clearError}
                errMap={errMap}
              />
            </Stack>
          </Card>
        </Stack>
      )}
      {errorRoles && (
        <Box paddingX={4} paddingBottom={3}>
          <Card padding={3} radius={2} tone="critical">
            <Stack space={3}>
              <Text weight="semibold">Error loading roles</Text>
              <Text>{errorRoles.message}</Text>
            </Stack>
          </Card>
        </Box>
      )}
      {Object.keys(errMap).length > 0 && (
        <Card tone="critical" padding={4} border radius={2} margin={4} marginTop={0}>
          <Inline space={3}>
            <Text size={1} muted>
              <ErrorOutlineIcon />
            </Text>
            <Text weight="semibold" size={1}>
              There was a problem saving the configuration. Please correct the following:
            </Text>
          </Inline>
          <Stack space={3} as="ul" marginTop={4} marginLeft={5}>
            {Object.values(
              Object.values(errMap).reduce(
                (acc, err) => {
                  acc[err.description] = err
                  return acc
                },
                {} as Record<string, RoleErr>
              )
            ).map((err) => {
              return (
                <Li size={1} key={err.title}>
                  {err.description}
                </Li>
              )
            })}
          </Stack>
        </Card>
      )}
    </Dialog>
  )
}
