import {Dialog as UiDialog, DialogProps, Text, Box} from '@sanity/ui'
import {useEffect} from 'react'
import styled from 'styled-components'

type DialogWidth = number | `${string}px`

interface Props extends Omit<DialogProps, 'width'> {
  children?: React.ReactNode
  /**
   * The size of the header text
   * @default 2
   */
  headerSize?: number
  /**
   * @default true
   */
  disableBodyOverflow?: boolean
  width: DialogWidth
}

const StyledDialog = styled(UiDialog)<{width: DialogWidth}>`
  [data-ui='DialogCard'] {
    width: 100%;
    max-width: ${({width, theme}) =>
      typeof width === 'number' ? `${theme.sanity.container[width]}px` : width};
  }
`

export function Dialog({
  header,
  headerSize = 2,
  disableBodyOverflow = true,
  children,
  ...rest
}: Props) {
  /* Disable body overflow when dialog is open, re-enable when unmounted */
  useEffect(() => {
    const initialOverflow = document.body.style.overflow
    if (disableBodyOverflow) {
      document.body.style.overflow = 'hidden'
    }
    return () => {
      document.body.style.overflow = initialOverflow
    }
  }, [disableBodyOverflow])

  return (
    <StyledDialog
      animate
      header={
        <Box paddingY={1}>
          <Text size={headerSize} weight="semibold">
            {header}
          </Text>
        </Box>
      }
      zOffset={2500}
      {...rest}
    >
      {children}
    </StyledDialog>
  )
}
