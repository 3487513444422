'use client'

import {useCurrentScopeContext} from '@/context/index'
import {UseMutationResult, UseQueryResult, useMutation, useQuery} from '@tanstack/react-query'
import {request} from '../util/promiseRequest'
import {ExperienceDialogsResponse, ExperienceModal} from './types'
import {useToast} from '@sanity/ui'
import {_} from 'numeral'

const API_VERSION = 'v2023-12-11'

export function useExperienceDialogs(): UseQueryResult<ExperienceDialogsResponse> {
  const {project} = useCurrentScopeContext()
  // disable fetching if user is merely organization member
  const isProjectMember = project?.members.some((member) => member.isCurrentUser)

  // Setup query params based on the projectId
  const queryParams = new URLSearchParams()
  queryParams.append('manage', 'true')
  queryParams.append('projectId', project?.id || '')

  if (typeof window !== 'undefined') {
    // See if we have any parameters from the current route
    // to pass onto our query
    const {searchParams} = new URL(location.href)
    // Allows us to override the current state of the trial to
    // get back certain modals based on the current experience
    // can be 'growth-trial', 'growth-trial-ending', or 'post-growth-trial'
    const trialState = searchParams.get('trialState')
    // Allows us to set whether we've seen the modals before
    // or whether this is our first time seeing them (i.e. show a popup)
    const seenBefore = searchParams.get('seenBefore')

    if (trialState) queryParams.append('trialState', trialState)
    if (seenBefore) queryParams.append('seenBefore', seenBefore)
  }

  // If we have trialState, query the override endpoint so that we
  // get back trial modals for that state
  const queryURL = queryParams.get('trialState')
    ? `https://api.${process.env.host}/${API_VERSION}/journey/trial/override?`
    : `https://api.${process.env.host}/${API_VERSION}/journey/trial?`

  return useQuery({
    queryKey: ['project', project?.id, 'trial'],
    queryFn: async () => {
      if (project?.id === undefined) {
        return null
      }
      const res = await request<ExperienceDialogsResponse | undefined>({
        url: queryURL + queryParams.toString(),
        headers: {
          'Content-Type': 'application/json',
        },
      })
      if (res.statusCode === 401) {
        return null
      }
      if (!res.ok) {
        throw new Error(res.body.message)
      }
      return res.body
    },
    staleTime: 30 * 1000,
    enabled: isProjectMember,
  })
}

export function useDismissExperienceDialog(): UseMutationResult<
  void,
  unknown,
  {
    dialogId: string
  },
  unknown
> {
  const {project} = useCurrentScopeContext()

  return useMutation({
    mutationKey: ['experienceDialogs', 'dismissExperienceDialog'],
    mutationFn: async ({dialogId}: {dialogId: string}) => {
      const res = await request<ExperienceDialogsResponse>({
        url: `https://api.${process.env.host}/${API_VERSION}/journey/trial/${dialogId}?projectId=${project?.id}&manage=true`,
        method: 'POST',
      })
      if (!res.ok) throw new Error(res.body.message)
    },
  })
}

export function useRequestUpgradeDialog(): UseQueryResult<ExperienceModal> {
  const {project} = useCurrentScopeContext()

  // Setup query params based on the projectId
  const queryParams = new URLSearchParams()
  queryParams.append('manage', 'true')
  queryParams.append('projectId', project?.id || '')
  // If we have trialState, query the override endpoint so that we
  // get back trial modals for that state
  const queryURL = `https://api.${process.env.host}/v2024-06-04/journey/upgrade/request?`

  return useQuery({
    queryKey: ['project', project?.id, 'request-upgrade'],

    queryFn: async () => {
      if (project?.id === undefined) {
        return null
      }
      const res = await request<ExperienceModal | undefined>({
        url: queryURL + queryParams.toString(),
        headers: {
          'Content-Type': 'application/json',
        },
      })
      if (res.statusCode === 401) {
        return null
      }
      if (!res.ok) {
        throw new Error(res.body.message)
      }
      return res.body
    },

    staleTime: 30 * 1000,
  })
}

export function useSendRequestUpgrade(): UseMutationResult<void, unknown, void> {
  const {project} = useCurrentScopeContext()
  const toast = useToast()

  return useMutation({
    mutationKey: ['experienceDialogs', 'sendRequestUpgrade', project?.id],
    mutationFn: async () => {
      const res = await request<ExperienceDialogsResponse>({
        url: `https://api.${process.env.host}/v2024-06-04/journey/upgrade/request/${project?.id}?`,
        method: 'POST',
      })
      if (!res.ok) throw new Error(res.body.message)
    },

    onSuccess: () => {
      toast.push({
        status: 'success',
        title: `Upgrade request sent to your project administrator(s)`,
      })
    },
  })
}
