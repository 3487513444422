globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"cb104358697c4bd8ff556d754cda562543252052"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "/manage-assets";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
const SENTRY_DEBUG = Boolean(process.env.SENTRY_DEBUG ?? process.env.NEXT_PUBLIC_SENTRY_DEBUG)

Sentry.init({
  dsn: SENTRY_DSN,

  // possible envs in sentry: development, staging, production, vercel-preview
  environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT || 'development',

  // Add optional integrations for additional features
  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: true,
      mask: ['[data-recording-mask]'],
    }),
    Sentry.thirdPartyErrorFilterIntegration({
      // Must match the applicationKey from SentryWebpackPlugin config.
      filterKeys: ['sanity-io/manage'],
      behaviour: 'apply-tag-if-contains-third-party-frames',
    }),
  ],

  // Define how likely traces are sampled. Adjust this value in production, or use tracesSampler for greater control.
  tracesSampleRate: 1,

  // Define how likely Replay events are sampled.
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // Define how likely Replay events are sampled when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: SENTRY_DEBUG,

  ignoreErrors: [/undefined is not an object \(evaluating '[a-zA-Z]\.didTimeout'\)/],
})
