import {Button, Card, Dialog, Flex, Text} from '@sanity/ui'
import React, {createElement, useCallback, useMemo, useState} from 'react'
import {useWizardStore} from './store'
import {WizardCompletionCallback, WizardParams, WizardStep} from './types'
import {WizardContext} from './wizardContext'

type SetIndexFn = (value: number) => number
export function WizardDialog(props: {
  onClose: () => void
  onComplete?: WizardCompletionCallback
  params: WizardParams
  steps: WizardStep[]
  title: React.ReactNode
}) {
  const {onComplete, onClose, params, steps, title} = props
  const {dispatch, state} = useWizardStore()
  let index = state.stepIndex || 0
  const setIndex = useCallback(
    (value: number | SetIndexFn) =>
      dispatch({type: 'setStepIndex', value: typeof value === 'function' ? value(index) : value}),
    [dispatch, index]
  )
  if (index >= steps.length) {
    index = steps.length - 1
  }
  const currentStep = steps[index]
  const len = steps.length
  const handler = currentStep?.handle
  const [handling, setHandling] = useState(false)
  const [handlerError, setHandlerError] = useState()

  const handleNext = useCallback(() => {
    if (handler) {
      setHandling(true)
      setHandlerError(undefined)
      handler()
        .then((gotoStep) => {
          setIndex(gotoStep === undefined ? (i) => i + 1 : gotoStep)
          setHandling(false)
        })
        .catch((err) => {
          setHandlerError(err)
          setHandling(false)
        })
    } else {
      setIndex((i) => i + 1)
    }
  }, [handler, setIndex])

  const handleFinal = useCallback(() => {
    if (handler) {
      setHandling(true)
      setHandlerError(undefined)
      handler()
        .then(() => {
          if (onComplete) {
            onComplete().then(() => {
              onClose()
              setHandling(false)
            })
            return
          }
          onClose()
          setHandling(false)
        })
        .catch((err) => {
          setHandlerError(err)
          setHandling(false)
        })
    } else {
      onClose()
    }
  }, [handler, onClose, onComplete])

  const footer = useMemo(() => {
    if (!currentStep) {
      return null
    }
    const Footer = currentStep.footer
    return (
      <>
        <Flex gap={2} paddingX={4} paddingY={3}>
          {len > 1 && !currentStep.hideSteps && (
            <Button
              disabled={currentStep.disabled || index === 0}
              mode="ghost"
              // eslint-disable-next-line react/jsx-no-bind
              onClick={() => setIndex((i) => i - 1)}
              style={{flex: 1}}
              text="Back"
            />
          )}

          {index < len - 1 && (
            <Button
              disabled={currentStep.disabled || !currentStep.completed}
              loading={handling}
              onClick={handleNext}
              style={{flex: 1}}
              text={currentStep.action?.label || 'Continue'}
              tone={currentStep.action?.tone || 'primary'}
            />
          )}

          {index === len - 1 && (
            <Button
              disabled={currentStep.disabled || !currentStep.completed}
              loading={handling}
              onClick={handleFinal}
              style={{flex: 1}}
              text={currentStep.action?.label || 'Done'}
              tone={currentStep.action?.tone || 'primary'}
            />
          )}
        </Flex>
        {Footer && <Footer />}
      </>
    )
  }, [currentStep, handleFinal, handleNext, handling, index, len, setIndex])
  const contextValue = useMemo(
    () => ({handling, index, steps, handlerError}),
    [handling, index, steps, handlerError]
  )
  return (
    <WizardContext.Provider value={contextValue}>
      <Dialog
        footer={footer}
        header={title}
        id="wizard"
        onClose={onClose}
        onClickOutside={onClose}
        width={1}
        zOffset={2500}
      >
        <Flex direction="column">
          {steps.length > 1 && !currentStep?.hideSteps && (
            <Card padding={3} tone="transparent">
              <Flex
                align="center"
                direction="row"
                justify="space-evenly"
                wrap="wrap"
                style={{textAlign: 'center'}}
              >
                {steps.map((step, stepIndex) => (
                  <Card
                    padding={2}
                    radius={5}
                    key={step.id}
                    selected={stepIndex === index}
                    tone={stepIndex === index ? undefined : 'inherit'}
                  >
                    <Text muted={stepIndex !== index} size={1}>
                      {step.title}
                    </Text>
                  </Card>
                ))}
              </Flex>
            </Card>
          )}
          <Card borderTop flex={1} overflow="auto" paddingX={[3, 3, 3, 4]} paddingY={[4, 4, 4, 5]}>
            {currentStep &&
              createElement(currentStep.component, {params, handlerError, steps, currentStep})}
          </Card>
        </Flex>
      </Dialog>
    </WizardContext.Provider>
  )
}
