/* eslint-disable complexity */
import React from 'react'

export const ComingSoon = ({color}: {color?: string}) => (
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M54 26C54 28.7614 49.0751 31 43 31C36.9249 31 32 28.7614 32 26C32 23.2386 36.9249 7 43 7C49.0751 7 54 23.2386 54 26Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M65 71.5V57M65 71.5L60.5789 69.0132M65 71.5L60.5789 73.9868M65 57L57 52.5L54 54.1875M65 57V42.5M65 57L57 47M65 57L58.1586 60.8483M65 57L60.5789 67.5M65 42.5L57 38L54 39.6875M65 42.5L57 47M65 42.5V27.5M57 47L65 27.5M57 47L54 39.6875M57 47L54 45.3125M57 47L54 50.75M57 23L65 27.5M57 23L54 24.6875V39.6875M57 23V60M65 27.5L57 32L54 30.3125"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M54 76C54 78.7614 49.0751 81 43 81C36.9249 81 32 78.7614 32 76M54 76H60.5V63L54 57M54 76V57M32 76H25V63L32 57M32 76V57M32 26V57M54 26V57M54 45C54 47.7614 49.0751 50 43 50V35C49.0751 35 54 32.7614 54 30"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M43 45.5701H44.8976L45.8465 43.1981L46.9679 42.7335L49.3162 43.7399L50.658 42.3981L49.6516 40.0499L50.1161 38.9284L52.4882 37.9795V36.0819L50.1161 35.1331L49.6516 34.0116M47.1334 34.9653C47.5197 35.5597 47.7441 36.269 47.7441 37.0307C47.7441 39.1268 46.0449 40.826 43.9488 40.826C43.6212 40.826 43.3033 40.7845 43 40.7064"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
      strokeLinejoin="round"
    />
    <path
      d="M63.5464 78.6739L64.9606 78.0321L67.4355 78.5135L68.6174 78.2913L69.6174 77.1566H71.6174L72.6174 78.2913L73.7994 78.5135L76.2743 78.0321L77.6885 78.6739M63.5464 78.6739L64.607 79.7972L64.1174 80.3336L63.5464 80.4373M63.5464 78.6739V80.4373M77.6885 78.6739L76.6278 79.7972L77.1174 80.3336L77.6885 80.4373M77.6885 78.6739V80.4373M63.5464 80.4373L61.6174 80.7875V81.6952L64.1174 82.1491L64.607 82.6855L63.5464 83.8088L64.9606 84.4506L67.4355 83.9693L68.6174 84.1915L69.6174 85.3261H71.6174L72.6174 84.1915L73.7994 83.9693L76.2743 84.4506L77.6885 83.8088L76.6278 82.6855L77.1174 82.1491L79.6174 81.6952V80.7875L77.6885 80.4373"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M79.6174 81.4647V84.7162L77.6885 85.0664M76.2743 87.4716L77.6885 86.8298V85.0664M76.2743 87.4716L73.7994 86.9902L72.6174 87.2124L71.6174 88.3471M76.2743 87.4716V84.7162M61.6174 81.4647V84.7162L63.5464 85.0664M71.6174 88.3471H69.6174M71.6174 88.3471V85.3261M69.6174 88.3471L68.6174 87.2124L67.4355 86.9902L64.9606 87.4716M69.6174 88.3471V85.3261M64.9606 87.4716L64.2535 87.1507L63.5464 86.8298V85.0664M64.9606 87.4716V84.6825M63.5464 84.039V85.0664M77.6885 84.039V85.0664"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M73.6174 81.2412C73.6174 81.9932 72.2743 82.6028 70.6174 82.6028C68.9606 82.6028 67.6174 81.9932 67.6174 81.2412C67.6174 80.4892 68.9606 79.8796 70.6174 79.8796C72.2743 79.8796 73.6174 80.4892 73.6174 81.2412Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M90 85.3616C90 86.1136 88.6569 86.7232 87 86.7232C85.3431 86.7232 84 86.1136 84 85.3616M90 85.3616C90 84.6096 88.6569 84 87 84C85.3431 84 84 84.6096 84 85.3616M90 85.3616V87.3616C90 88.1136 88.6569 88.7232 87 88.7232C85.3431 88.7232 84 88.1136 84 87.3616V85.3616"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M82 89.8648C82 90.3425 81.3284 90.7297 80.5 90.7297C79.6716 90.7297 79 90.3425 79 89.8648M82 89.8648C82 89.3872 81.3284 89 80.5 89C79.6716 89 79 89.3872 79 89.8648M82 89.8648V91.1352C82 91.6128 81.3284 92 80.5 92C79.6716 92 79 91.6128 79 91.1352V89.8648"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M52.7556 46.7289L52.5382 45.0136L51.2952 44.6903L50.2696 46.0824L49.4559 46.1956L48.0893 45.1364L46.9818 45.7867L47.2409 47.4962L46.7456 48.1517L45.0303 48.3691L44.707 49.612M51.1085 47.9593C49.8969 47.6442 48.6623 48.2485 48.1435 49.3385"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M39 80.5L38 83.5C38 84.8807 40.2386 86 43 86C45.7614 86 48 84.8807 48 83.5L47 80.5"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M32 77L31 80C31 81.3807 33.2386 82.5 36 82.5C36.9107 82.5 37.7646 82.3783 38.5 82.1655"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M54 77L55 80C55 81.3807 52.7614 82.5 50 82.5C49.0893 82.5 48.2354 82.3783 47.5 82.1655"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
ComingSoon.displayName = 'ComingSoon'
