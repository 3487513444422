import {timeToMilliseconds} from '@/utils/time'
import * as Sentry from '@sentry/nextjs'
import {MutationCache, QueryCache, QueryClient} from '@tanstack/react-query'
import {isAPIError, isIgnorableError, isLegacyAPIError} from './util/promiseRequest'
import isNetworkError from './util/isNetworkError'

function filterOutUniqueSegmentsFromKeys(
  keys: (string | number | undefined | object | null)[]
): string[] {
  // Regular expression to match org or project IDs
  const idPattern = /\b(?=\w*\d)(?=\w*[a-zA-Z])\w+\b/g

  return keys.map((key) => {
    // If the key is an object, assume it is a options bag and return a placeholder
    if (typeof key === 'object' && key !== null) {
      return 'options object'
    }
    // Replace matching IDs with a placeholder
    return key?.toString()?.replace(idPattern, 'ID') ?? 'not set'
  })
}

function normalizeKeysType(keys: unknown): string[] {
  return Array.from((keys ?? []) as Iterable<string>).filter(
    (key): key is string => key !== undefined
  )
}

interface AccessAPIResponseError {
  statusCode: number
  error: string
  message: string
}

const isAccessAPIResponseError = (err: unknown): err is AccessAPIResponseError => {
  return (
    typeof err === 'object' &&
    err !== null &&
    'statusCode' in err &&
    'error' in err &&
    'message' in err
  )
}

export const queryClient = new QueryClient({
  mutationCache: new MutationCache({
    onError: (err, _variables, _context, mutation) => {
      console.error(`[Mutation] Error: ${mutation.mutationId}`, err)

      /**
       * We don't care about 400-499 errors, as they are either auth errors or validation errors
       */
      if (isAccessAPIResponseError(err) && err.statusCode >= 400 && err.statusCode < 500) {
        return
      }

      if (isAPIError(err) && err.statusCode >= 400 && err.statusCode < 500) {
        return
      }

      // TODO: Remove this once we have migrated all requests to promiseRequest
      if (isLegacyAPIError(err) && err.statusCode >= 400 && err.statusCode < 500) {
        return
      }

      // TODO: Remove this once we have migrated all requests to prothismiseRequest
      // This one is a bit heavy handed, but I have only seen it happen in relation to 40* errors
      if (isIgnorableError(err)) {
        return
      }

      Sentry.withScope((scope) => {
        scope.setContext('mutation', {
          mutationId: mutation.mutationId,
          variables: mutation.state.variables,
        })
        if (mutation.options.mutationKey) {
          scope.setFingerprint(
            filterOutUniqueSegmentsFromKeys(normalizeKeysType(mutation.options.mutationKey))
          )
        }

        /**
         * This check is to avoid https://sanity.sentry.io/issues/5998780227
         */
        if (err instanceof Error) {
          Sentry.captureException(err)
        } else {
          // @ts-expect-error This is a bit of a hack to capture the error message regardless of what typescript thinks
          Sentry.captureException(err.message)
        }
      })
    },
  }),
  queryCache: new QueryCache({
    onError: (err, query) => {
      console.error(`[Query] Error: ${query.queryHash}`, err)

      /**
       * We don't care about 400-499 errors, as they are either auth errors or validation errors
       */
      if (isAccessAPIResponseError(err) && err.statusCode >= 400 && err.statusCode < 500) {
        return
      }

      if (isAPIError(err) && err.statusCode >= 400 && err.statusCode < 500) {
        return
      }

      // TODO: Remove this once we have migrated all requests to promiseRequest
      if (isLegacyAPIError(err) && err.statusCode >= 400 && err.statusCode < 500) {
        return
      }

      // TODO: Remove this once we have migrated all requests to prothismiseRequest
      // This one is a bit heavy handed, but I have only seen it happen in relation to 40* errors
      if (isIgnorableError(err)) {
        return
      }

      // Ignore network errors
      if (isNetworkError(err)) {
        console.error(`[Query] Network Error: ${query.queryHash}`, err)
        return
      }

      Sentry.withScope((scope) => {
        scope.setContext('query', {queryHash: query.queryHash, queryKey: query.options.queryKey})
        scope.setFingerprint(
          filterOutUniqueSegmentsFromKeys(normalizeKeysType(query.options.queryKey))
        )

        /**
         * This check is to avoid https://sanity.sentry.io/issues/5998780227
         */
        if (err instanceof Error) {
          Sentry.captureException(err)
        } else {
          // @ts-expect-error This is a bit of a hack to capture the error message regardless of what typescript thinks
          Sentry.captureException(err.message)
        }
      })
    },
  }),
  defaultOptions: {
    queries: {
      staleTime: timeToMilliseconds(5, 'minutes'),
      gcTime: timeToMilliseconds(2, 'days'),
      refetchOnWindowFocus: false,

      retry: (failureCount, error) => {
        // We don't want to retry on 400-499 errors, as they are either auth errors or validation errors
        if (isAPIError(error) && error.statusCode >= 400 && error.statusCode < 500) {
          return false
        }

        // For other errors we retry twice
        return failureCount < 2
      },
    },
  },
})
