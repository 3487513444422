import React from 'react'
import {Box, Button, Text, Tooltip} from '@sanity/ui'
import {ArrowTopRightIcon} from '@sanity/icons'
import {AppLink} from '@/ui/app'
import {AssociatedLink as AssociatedLinkType} from '@/types/models'

export function AssociatedLink({label, url, external}: AssociatedLinkType) {
  return (
    <Text>
      <Tooltip
        disabled={!label}
        content={
          <Box padding={3}>
            <Text size={1} style={{whiteSpace: 'nowrap'}}>
              {label}
            </Text>
          </Box>
        }
        placement="left"
      >
        <Box>
          <>
            {external && url && (
              <Button
                href={url}
                target="blank"
                rel="noreferrer"
                mode="bleed"
                icon={ArrowTopRightIcon}
                title={label || 'Go to'}
                as="a"
              />
            )}
            {!external && url && (
              <AppLink href={url}>
                <Button mode="bleed" icon={ArrowTopRightIcon} title={label || 'Go to'} as="span" />
              </AppLink>
            )}
          </>
        </Box>
      </Tooltip>
    </Text>
  )
}
