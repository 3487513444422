import {useQuery, useMutation, useQueryClient} from '@tanstack/react-query'
import {
  type LessonProgress,
  getProjectLessonProgress,
  updateProjectLessonProgress,
  deleteProjectLessonProgress,
  replaceProjectLessonProgress,
} from './controller'

export function useProjectLessonProgress(projectId: string) {
  const queryClient = useQueryClient()
  const queryKey = ['getStarted', 'lessonProgress', projectId]

  const result = useQuery({
    queryKey: queryKey,
    queryFn: () => getProjectLessonProgress(projectId),
    enabled: !!projectId,
  })

  const update = useMutation({
    mutationKey: ['getStarted', 'lessonProgress', 'update'],
    mutationFn: (lesson: string) => updateProjectLessonProgress(projectId, lesson),
    onMutate: async (newLesson) => {
      await queryClient.cancelQueries({
        queryKey: queryKey,
      })
      const previousProgress = queryClient.getQueryData<LessonProgress>(queryKey)

      queryClient.setQueryData<LessonProgress>(queryKey, (old) => ({
        progress: old ? [...old.progress, newLesson] : [newLesson],
      }))

      return {previousProgress}
    },
    onError: (_err, _newLesson, context) => {
      queryClient.setQueryData(queryKey, context?.previousProgress)
    },
    onSuccess: (updatedProgress) => {
      queryClient.setQueryData(queryKey, updatedProgress)
    },
  })

  const replace = useMutation({
    mutationKey: ['getStarted', 'lessonProgress', 'replace'],
    mutationFn: (lessons: string[]) => replaceProjectLessonProgress(projectId, lessons),
    onMutate: async (newLessons) => {
      await queryClient.cancelQueries({
        queryKey: queryKey,
      })
      queryClient.setQueryData<LessonProgress>(queryKey, () => ({
        progress: newLessons,
      }))

      return {
        progress: newLessons,
      }
    },
  })

  const remove = useMutation({
    mutationKey: ['getStarted', 'lessonProgress', 'remove'],
    mutationFn: (lesson: string) => deleteProjectLessonProgress(projectId, lesson),
    onMutate: async (lessonToRemove) => {
      await queryClient.cancelQueries({
        queryKey: queryKey,
      })
      const previousProgress = queryClient.getQueryData<LessonProgress>(queryKey)

      queryClient.setQueryData<LessonProgress>(queryKey, (old) => ({
        progress: old ? old.progress.filter((lesson) => lesson !== lessonToRemove) : [],
      }))

      return {previousProgress}
    },
    onError: (_err, _lessonToRemove, context) => {
      queryClient.setQueryData(queryKey, context?.previousProgress)
    },
    onSuccess: (updatedProgress) => {
      queryClient.setQueryData(queryKey, updatedProgress)
    },
  })

  return {
    ...result,
    update: update.mutate,
    replace: replace.mutate,
    remove: remove.mutate,
  }
}
