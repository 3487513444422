import {useQuery, useMutation, useQueryClient} from '@tanstack/react-query'
import {getOrgInvitations, postOrgInvitation, revokeOrgInvitation} from './controller'
import {OrganizationInvitation, OrganizationInvitationData} from '@/types/models'
import {useCacheInvalidation} from '../base/useCacheInvalidation'
import {getOrgInvitationsCacheKey} from './cache'
import {APIError} from '../util/promiseRequest'
import {useRequiredOrganizationPermissions} from '../../context'

export function useOrgInvitations(orgId: string) {
  const {invalidateOrgInvitations} = useCacheInvalidation()
  const queryKey = getOrgInvitationsCacheKey(orgId)
  const queryClient = useQueryClient()

  const canReadOrgInvites = useRequiredOrganizationPermissions([
    {permissionName: 'sanity.organization.members', grantName: 'read'},
  ])

  const {
    data: invitations = [],
    error,
    isLoading,
  } = useQuery({
    queryKey,
    queryFn: () => getOrgInvitations(orgId),
    enabled: canReadOrgInvites,
  })

  const sendInvitationMutation = useMutation({
    mutationKey: ['sendOrgInvitation', orgId],
    mutationFn: async (invites: OrganizationInvitationData[]) => {
      const results = await Promise.allSettled(
        invites.map((invite) => postOrgInvitation(orgId, invite))
      )
      return results.map((result, index) => ({
        index,
        invitation: invites[index],
        success: result.status === 'fulfilled',
        data: result.status === 'fulfilled' ? result.value : undefined,
        error: result.status === 'rejected' ? (result.reason as APIError) : undefined,
      }))
    },
    onSuccess: () => {
      invalidateOrgInvitations(orgId)
      queryClient.invalidateQueries({queryKey})
    },
  })

  const revokeInvitationMutation = useMutation({
    mutationKey: ['revokeOrgInvitation', orgId],
    mutationFn: async (invites: OrganizationInvitation[]) => {
      const results = await Promise.allSettled(
        invites.map((invite) => revokeOrgInvitation(orgId, invite))
      )
      return results.map((result, index) => ({
        index,
        invitation: invites[index],
        success: result.status === 'fulfilled',
        data: result.status === 'fulfilled' ? result.value : undefined,
        error: result.status === 'rejected' ? (result.reason as APIError) : undefined,
      }))
    },
    onSuccess: () => {
      invalidateOrgInvitations(orgId)
      queryClient.invalidateQueries({queryKey})
    },
  })

  return {
    invitations: invitations || [],
    error,
    isLoading,
    sendInvitations: sendInvitationMutation.mutate,
    revokeInvitations: revokeInvitationMutation.mutate,
    sendInvitationResults: sendInvitationMutation.data,
    revokeInvitationResults: revokeInvitationMutation.data,
    isSending: sendInvitationMutation.isPending,
    isRevoking: revokeInvitationMutation.isPending,
  }
}
