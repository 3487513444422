import {APIError} from '@/data/util/request'

export const getErrorDetails = (
  error: Error
): {statusCode?: number; message: string; title?: string} => {
  if (error.name === 'HttpError') {
    const response = JSON.parse((error as Error & {response: {body: string}})?.response?.body)
    return {
      title: 'Something went wrong',
      message: response.message,
      statusCode: response.statusCode,
    }
  }

  if (error instanceof APIError) {
    return {
      title: 'Something went wrong',
      message: error.message,
      statusCode: error.statusCode,
    }
  }

  // default response
  return {
    message: error.message,
  }
}
