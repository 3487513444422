import React from 'react'
import {UsageSummaryProject} from './usageSummaryProject'
import {ProjectUsagePrompt, UsageHeader} from '@/ui/index'
import {useCurrentScopeContext} from '@/context/index'

export function UsageProjectHeader() {
  const {project} = useCurrentScopeContext()
  return (
    <>
      <UsageHeader minDate={project?.createdAt} />
      <ProjectUsagePrompt />
      <UsageSummaryProject />
    </>
  )
}
