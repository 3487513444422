import {Tag, Project, ProjectMemberRole} from '../../types/models'
import {promiseRequest} from '../util/promiseRequest'

export function tags(project: Project): Promise<Tag[]> {
  return promiseRequest<Tag[]>(`https://api.${process.env.host}/vX/projects/${project.id}/tags`)
}

export function tagRoles(project: Project, tag: Tag) {
  return promiseRequest<ProjectMemberRole[]>(
    `https://api.${process.env.host}/vX/projects/${project.id}/tags/${tag.name}/roles`
  )
}

export function createTag(
  projectId: string,
  {
    name,
    title,
    description,
    tone,
  }: {
    name: string
    title: string
    description: string
    tone: string
  }
) {
  return promiseRequest<Tag>(
    `https://api.${process.env.host}/v2021-06-07/projects/${projectId}/tags`,
    {
      method: 'POST',
      json: {
        name,
        title,
        description,
        metadata: {
          tone,
        },
      },
    }
  )
}

export function editTag(projectId: string, tagName: string, {name, title, description, tone}) {
  return promiseRequest<Tag>(
    `https://api.${process.env.host}/v2021-06-07/projects/${projectId}/tags/${encodeURIComponent(tagName)}`,
    {
      method: 'PUT',
      json: {
        name,
        title,
        description,
        metadata: {
          tone,
        },
      },
    }
  )
}

export function deleteTag(projectId: string, tagName: string) {
  return promiseRequest(
    `https://api.${process.env.host}/v2021-06-07/projects/${projectId}/tags/${encodeURIComponent(tagName)}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
}
