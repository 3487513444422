import {Box, Card, Flex, Inline, Stack, Text, Tooltip} from '@sanity/ui'
import React from 'react'
import {ExtraWarning, IndeterminateWarning, TableHeader} from './tableUtil'
import {getDocumentResourceGrant} from './util'
import {Grant} from './grant'
import {RoleResource} from '@/types/models'

function ViewResourceTitle({resource}: {resource: RoleResource}) {
  if (resource.description) {
    return (
      <Tooltip
        content={
          <Card radius={2} padding={3} style={{maxWidth: 250}}>
            <Text size={1}>{resource.description}</Text>
          </Card>
        }
      >
        <Text size={1}>{resource.title}</Text>
      </Tooltip>
    )
  }
  return <Text size={1}>{resource.title}</Text>
}

export function ViewResourceGrants({resource}: {resource: RoleResource}) {
  const has = getDocumentResourceGrant(resource)
  return (
    <Flex flex={1} justify="space-between">
      {has.mode && (
        <Box style={{minWidth: 180}}>
          <Flex>
            <Grant grant={has.mode} />
          </Flex>
        </Box>
      )}
      <Inline space={2}>
        {has.indeterminate && <IndeterminateWarning grants={has.grants} />}
        {has.extra.length > 0 && <ExtraWarning grants={has.extra} />}
      </Inline>
    </Flex>
  )
}

export function ViewContentResource({resources}: {resources: RoleResource[]}) {
  if (resources.length === 0) {
    return (
      <Box padding={4}>
        <Text align="center" muted size={1}>
          No permissions granted
        </Text>
      </Box>
    )
  }
  return (
    <Stack>
      <TableHeader />
      {resources.map((resource, i) => (
        <Card
          key={resource.id}
          borderBottom={i > resources.length - 1}
          paddingY={2}
          paddingX={4}
          marginRight={2}
        >
          <Flex align="center">
            <Box flex={2}>
              <ViewResourceTitle resource={resource} />
            </Box>
            <Flex flex={2} align="center" justify="space-between">
              <ViewResourceGrants resource={resource} />
            </Flex>
          </Flex>
        </Card>
      ))}
    </Stack>
  )
}
