import React, {useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react'
import type {PortableTextTypeComponentProps} from '@portabletext/react'
import {CopyIcon, ExpandIcon} from '@sanity/icons'
import {Box, Button, Card, Flex, Text, useToast, usePrefersReducedMotion, Tooltip} from '@sanity/ui'
import styled from 'styled-components'
import {
  type HighlighterGeneric,
  type BundledLanguage,
  type BundledTheme,
  createHighlighter,
} from 'shiki/bundle/web'
import {groqLanguage} from '@/utils/groq-language'
import {useManageScheme} from '@/ui/app/manageSchemeProvider'
import {motion, AnimatePresence, type ResolvedValues} from 'framer-motion'
import {useDynamicString} from '../../utils/useDynamicString'
import {sendAmplitudeTrackingEvent} from '@/utils/tracking'
import {useCurrentScopeContext} from '@/context/useCurrentScopeContext'
import {capitalize} from 'lodash'

interface Props {
  _type: 'code'
  code: string
  filename: string
  language: string
}

const MAX_CODE_BLOCK_HEIGHT = 300

const Root = styled(Card)`
  --fade-opacity: 1;
  display: grid !important;

  &:hover {
    --fade-opacity: 0;
  }
`

const DialogOverlay = styled(motion(Box))`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 200;
  backdrop-filter: blur(2px);
`

const DialogContent = styled(motion(Card))`
  position: fixed;
  z-index: 200;
  max-width: clamp(18rem, min(60rem, calc(100% - 2rem)), 60rem);
  max-height: 84dvh;
  display: grid !important;
  grid-template-rows: auto 1fr;
  overflow: hidden;
`

const SyntaxCard = styled(motion(Box))<{dark?: boolean; maxHeight?: number}>`
  --padding-bottom: 2em;

  overflow: auto;
  max-height: ${(props) => (props.maxHeight ? `${props.maxHeight}px` : 'none')};
  height: 100%;

  pre {
    margin: 0;
    background-color: transparent !important;
    tab-size: 2;
    font-size: 0.8rem;
    padding-bottom: var(--padding-bottom);
  }

  .line {
    padding-right: 1em;
  }

  span {
    ${(props) => (props.dark ? 'color: var(--shiki-dark) !important;' : '')}
  }

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: var(--padding-bottom);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, var(--card-muted-bg-color) 100%);
    opacity: var(--fade-opacity);
    pointer-events: none;
    transition: opacity 0.2s;
  }
`

const Footer = styled(Flex)`
  position: absolute;
  bottom: 0.5rem;
  left: 0.5rem;
  right: 0.5rem;
  z-index: 1;
`

const DIALOG_INITIAL_POSITION = {
  width: 0,
  height: 0,
  top: 0,
  left: 0,
  x: 0,
  y: 0,
}

const DIALOG_END_POSITION = {
  width: 'max-content',
  height: 'max-content',
  top: '50%',
  left: '50%',
  x: '-50%',
  y: '-50%',
}

interface CodeBlockProps {
  filename: string
  code: string
  dark: boolean
  onCopy: (e: React.MouseEvent) => void
  onExpand: (e: React.MouseEvent) => void
  maxHeight?: number
  allowExpand?: boolean
}

const CodeBlock = React.forwardRef<HTMLDivElement, CodeBlockProps>(
  ({filename, code, dark, onCopy, onExpand, maxHeight, allowExpand}, ref) => {
    return (
      <>
        <Flex justify="space-between" align="center" padding={3} paddingLeft={4}>
          <Text size={1} weight="semibold" textOverflow="ellipsis">
            {filename}
          </Text>

          <Tooltip animate placement="left" content={<Text size={1}>Copy code</Text>}>
            <Button
              icon={CopyIcon}
              mode="bleed"
              padding={1}
              style={{background: 'unset'}}
              aria-label="Copy code"
              onClick={onCopy}
            />
          </Tooltip>
        </Flex>

        <SyntaxCard
          ref={ref}
          dark={dark}
          paddingX={4}
          maxHeight={maxHeight}
          dangerouslySetInnerHTML={{__html: code}}
        />

        <Footer justify="flex-end" align="center" gap={1}>
          {allowExpand && (
            <Tooltip animate placement="left" content={<Text size={1}>Full screen</Text>}>
              <Button
                icon={ExpandIcon}
                mode="bleed"
                padding={2}
                radius="full"
                aria-label="Full screen"
                onClick={onExpand}
              />
            </Tooltip>
          )}
        </Footer>
      </>
    )
  }
)

export const TypeCode = (props: PortableTextTypeComponentProps<Props>) => {
  const {code = '', filename = '', language = 'plaintext'} = props.value

  const {projectId, org} = useCurrentScopeContext()
  const {resolvedScheme} = useManageScheme()
  const toast = useToast()
  const prefersReducedMotion = usePrefersReducedMotion()
  const dynamicFilename = useDynamicString(filename)
  const dynamicCode = useDynamicString(code)
  const fallbackFilename = capitalize(language)

  const shiki = useRef<HighlighterGeneric<BundledLanguage, BundledTheme> | null>(null)
  const containerRef = useRef<HTMLDivElement | null>(null)
  const codeRef = useRef<HTMLDivElement | null>(null)
  const dialogCodeRef = useRef<HTMLDivElement>(null)
  const dialogContentRef = useRef<HTMLDivElement>(null)

  const [isMounted, setIsMounted] = useState(false)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [isDialogAnimating, setIsDialogAnimating] = useState(false)
  const [dialogStartPosition, setDialogStartPosition] = useState(DIALOG_INITIAL_POSITION)
  const [isOverflowing, setIsOverflowing] = useState(true)

  const duration = prefersReducedMotion ? 0 : 0.5

  useEffect(() => {
    if (isMounted) return
    createHighlighter({
      themes: ['catppuccin-latte', 'one-dark-pro'],
      langs: language === 'groq' ? [groqLanguage] : [language],
    }).then((h) => {
      shiki.current = h
      setIsMounted(true)
    })
  }, [isMounted, language])

  useLayoutEffect(() => {
    if (!codeRef.current || !isMounted) return
    setIsOverflowing(codeRef.current.scrollHeight > codeRef.current.clientHeight)
  }, [isMounted])

  const shikiHtml = useMemo(() => {
    if (!shiki.current || !isMounted) return ''
    return shiki.current.codeToHtml(dynamicCode || '', {
      themes: {
        light: 'catppuccin-latte',
        dark: 'one-dark-pro',
      },
      lang: language,
    })
  }, [dynamicCode, language, isMounted])

  const handleCopy = useCallback(() => {
    sendAmplitudeTrackingEvent('Getting Started Code Block Copied', projectId, org?.id, {
      code_block_value: dynamicCode,
    })
    navigator.clipboard.writeText(dynamicCode || '')
    toast.push({status: 'success', title: 'Code copied to clipboard', duration: 2000})
  }, [dynamicCode, toast, org?.id, projectId])

  const handleExpand = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()
      if (!containerRef.current) return

      const {top, left, width, height} = containerRef.current.getBoundingClientRect()
      setDialogStartPosition({top, left, width, height, x: 0, y: 0})

      if (isOverflowing) {
        setIsDialogOpen(true)
        setIsDialogAnimating(true)
        document.body.style.overflow = 'hidden'
      }
    },
    [isOverflowing]
  )

  const handleDialogClose = useCallback(() => {
    setIsDialogOpen(false)
    setIsDialogAnimating(true)
    document.body.style.overflow = 'auto'
  }, [])

  const handleDialogAnimating = useCallback((values: ResolvedValues) => {
    if (!dialogCodeRef.current) return
    dialogCodeRef.current.scrollTop = codeRef.current?.scrollTop || 0
    dialogCodeRef.current.scrollLeft = codeRef.current?.scrollLeft || 0
    dialogCodeRef.current.style.overflow = values.width === 'max-content' ? 'auto' : 'hidden'
  }, [])

  const handleDialogAnimationComplete = useCallback(() => {
    setIsDialogAnimating(false)
  }, [])

  if (!isMounted) return null

  return (
    <>
      <Root
        muted
        radius={3}
        ref={containerRef}
        style={{position: 'relative', opacity: isDialogAnimating || isDialogOpen ? 0 : 1}}
      >
        <CodeBlock
          ref={codeRef}
          filename={dynamicFilename || fallbackFilename}
          code={shikiHtml}
          dark={resolvedScheme === 'dark'}
          maxHeight={MAX_CODE_BLOCK_HEIGHT}
          allowExpand={isOverflowing}
          onCopy={handleCopy}
          onExpand={handleExpand}
        />
      </Root>

      <AnimatePresence>
        {isDialogOpen && (
          <DialogOverlay
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            onClick={handleDialogClose}
            onAnimationComplete={handleDialogAnimationComplete}
          />
        )}
      </AnimatePresence>

      <AnimatePresence>
        {isDialogOpen && (
          <DialogContent
            role="dialog"
            ref={dialogContentRef}
            radius={3}
            muted
            initial={dialogStartPosition}
            animate={DIALOG_END_POSITION}
            exit={dialogStartPosition}
            transition={{duration, ease: 'anticipate'}}
            onUpdate={handleDialogAnimating}
            onAnimationComplete={handleDialogAnimationComplete}
          >
            <CodeBlock
              ref={dialogCodeRef}
              filename={dynamicFilename}
              code={shikiHtml}
              dark={resolvedScheme === 'dark'}
              onCopy={handleCopy}
              onExpand={handleExpand}
            />
          </DialogContent>
        )}
      </AnimatePresence>
    </>
  )
}
