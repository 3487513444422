import React, {useCallback} from 'react'
import {ChevronLeftIcon, ChevronRightIcon} from '@sanity/icons'
import {Button, Flex, Text} from '@sanity/ui'
import {getYear, addYears, subYears} from 'date-fns'

type Props = {
  date: Date
  onChange: (date: Date) => void
  minDate: Date
  maxDate: Date
}

export function Header({date, onChange, minDate, maxDate}: Props) {
  const year = getYear(date)
  const minYear = getYear(minDate)
  const maxYear = getYear(maxDate)

  const handleDecreaseYear = useCallback(() => {
    onChange(subYears(date, 1))
  }, [date, onChange])

  const handleIncreaseYear = useCallback(() => {
    onChange(addYears(date, 1))
  }, [date, onChange])

  const disablePrevious = year - 1 < minYear
  const disableNext = year + 1 > maxYear

  return (
    <Flex justify="space-between" align="center">
      <Button
        disabled={disablePrevious}
        icon={ChevronLeftIcon}
        mode="bleed"
        muted
        onClick={handleDecreaseYear}
        aria-label="Previous year"
      />
      <Text>{year}</Text>
      <Button
        disabled={disableNext}
        icon={ChevronRightIcon}
        mode="bleed"
        muted
        onClick={handleIncreaseYear}
        aria-label="Next year"
      />
    </Flex>
  )
}
