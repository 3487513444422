import React from 'react'
import {Stack, Spinner, Text, Flex} from '@sanity/ui'
import {TableRow, TableCell, TableBody} from '@/ui/index'

export const TableLoading = ({text}: {text?: string | React.ReactNode}) => {
  return (
    <TableBody>
      <TableRow padding={6}>
        <TableCell style={{gridColumn: '1/-1'}} $align="center">
          <Stack space={3}>
            <Flex justify="center">
              <Spinner muted size={1} />
            </Flex>
            {text && typeof text === 'string' ? (
              <Text size={1} muted>
                {text}
              </Text>
            ) : (
              text
            )}
          </Stack>
        </TableCell>
      </TableRow>
    </TableBody>
  )
}
