import {useRequiredProjectPermissions, useRequiredOrganizationPermissions} from '@/context/index'
import {Project} from '@/types/index'
import {useProjectSubscription} from './useProjectSubscription'

interface TransferProjectStatus {
  canTransfer: boolean
  isLoading: boolean
  reason?: string
  isCustomPricing?: boolean
}

export function useCanTransferProject(project: Project | undefined): TransferProjectStatus {
  const {data: subscription, isLoading: isSubscriptionLoading} = useProjectSubscription(project?.id)
  const hasDetachPermission = useRequiredOrganizationPermissions([
    {permissionName: 'sanity.organization.projects', grantName: 'detach'},
  ])
  const hasManagePermission = useRequiredProjectPermissions([
    {permissionName: 'sanity.project', grantName: 'update'},
  ])

  const hasRequiredPermissions = Boolean(
    (!project?.organizationId && hasManagePermission) ||
      (project?.organizationId && hasDetachPermission && hasManagePermission)
  )

  const isCustomPricing = subscription?.plan.price === null

  if (isSubscriptionLoading) {
    return {canTransfer: false, isLoading: true, isCustomPricing}
  }

  if (!hasRequiredPermissions) {
    return {
      canTransfer: false,
      isLoading: false,
      reason: 'Insufficient permissions to transfer the project.',
      isCustomPricing,
    }
  }

  if (isCustomPricing) {
    return {
      canTransfer: false,
      isLoading: false,
      reason:
        'Projects with custom pricing cannot be transferred. Contact sales for more information.',
      isCustomPricing,
    }
  }

  return {canTransfer: true, isLoading: false}
}
