import React, {forwardRef, useState} from 'react'
import {useRouter} from 'next/router'
import {Box, Button, Card, Flex, Popover, Text} from '@sanity/ui'
import {purple} from '@sanity/color'
import styled from 'styled-components'
import {BoltIcon} from '@sanity/icons'
import {usePersonalizedDialogsContext, useRoutePath} from '@/context/index'
import {PopoverContent} from '@/components/dialog/experience/popoverContent'
import {useMatchesMedia} from '@/utils/index'
import {useManageScheme} from '../../manageSchemeProvider'

const StyledBox = styled(Box)`
  padding: 2px;
  margin: 2px;
  position: relative;
`

const StyledButton = styled(Button)<{$dark?: boolean}>`
  svg {
    color: inherit !important;
  }

  [data-ui='Text'] {
    color: ${({$dark: dark}) => (dark ? purple['400'].hex : purple['600'].hex)};
  }

  &:hover {
    background-color: ${({$dark: dark}) => (dark ? purple['950'].hex : purple['50'].hex)};
  }
`

const CenteredStroke = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

interface OutlineProps {
  daysLeft: number
  totalDays: number
}

const SvgFilledOutline = ({daysLeft, totalDays}: OutlineProps) => {
  const progress = totalDays - daysLeft

  const percentage = Math.round((progress / totalDays) * 100)
  const radius = 12.5
  const strokeDasharray = 2 * Math.PI * radius
  const strokeDashOffset = strokeDasharray * ((100 - percentage) / 100)
  const strokeWidth = 1.2
  const size = radius * 2 + strokeWidth

  return (
    <Card>
      <CenteredStroke>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={size}
          height={size}
          viewBox={`0 0 ${size} ${size}`}
          style={{transform: 'rotate(-90deg)'}}
        >
          <circle
            r={radius}
            cx={size / 2}
            cy={size / 2}
            fill="transparent"
            strokeWidth={strokeWidth}
            stroke={purple['600'].hex}
          />
          <circle
            r={radius}
            cx={size / 2}
            cy={size / 2}
            fill="transparent"
            strokeWidth={strokeWidth}
            stroke={purple['200'].hex}
            strokeDasharray={strokeDasharray}
            strokeDashoffset={strokeDashOffset}
          />
        </svg>
      </CenteredStroke>
    </Card>
  )
}

interface FreeTrialButtonProps extends OutlineProps {
  toggleShowContent: () => void
}

export const FreeTrialButtonTopbar = forwardRef(function FreeTrialButtonTopbar(
  {toggleShowContent, daysLeft, totalDays}: FreeTrialButtonProps,
  ref: React.Ref<HTMLButtonElement>
) {
  const isMobile = useMatchesMedia(1)
  const {resolvedScheme} = useManageScheme()

  // If we are on mobile, we don't want to show the trial badge
  if (isMobile) return

  if (!daysLeft) {
    return (
      <StyledButton
        ref={ref}
        $dark={resolvedScheme === 'dark'}
        icon={BoltIcon}
        mode="bleed"
        onClick={toggleShowContent}
        text="Upgrade"
        fontSize={1}
      />
    )
  }
  return (
    <StyledButton
      ref={ref}
      $dark={resolvedScheme === 'dark'}
      mode="bleed"
      onClick={toggleShowContent}
      padding={2}
    >
      <Flex align={'center'} gap={3}>
        <StyledBox>
          <Text size={1}>
            <BoltIcon />
          </Text>
          {daysLeft > 0 && <SvgFilledOutline daysLeft={daysLeft} totalDays={totalDays} />}
        </StyledBox>
        {daysLeft > 0 ? (
          <Text size={1} weight="medium">
            {daysLeft > 1 ? `${daysLeft} days left in trial` : 'Last day of trial'}
          </Text>
        ) : null}
      </Flex>
    </StyledButton>
  )
})

export function TrialBadge() {
  const {
    data: dialogData,
    toggleDialogContent,
    showOnClickDialog,
    dismissAndRefetch,
    trackCTAClickCallback,
    isPopover,
    isLoading,
    isFetching,
  } = usePersonalizedDialogsContext()
  const [showPopover, setShowPopover] = useState(true)

  function handleClosePopover(openNextDialog = false) {
    return () => {
      setShowPopover(false)
      toggleDialogContent({closeAndReopen: openNextDialog})
      dismissAndRefetch()
      if (openNextDialog) trackCTAClickCallback()
    }
  }
  const {basePath} = useRoutePath()

  const router = useRouter()

  if (dialogData && dialogData.showOnLoad) {
    return (
      <Popover
        open={isPopover && showPopover}
        size={0}
        radius={3}
        portal
        placement="bottom-end"
        content={
          <PopoverContent
            content={dialogData.showOnLoad!}
            handleClose={handleClosePopover()}
            handleOpenNext={handleClosePopover(true)}
          />
        }
      >
        <FreeTrialButtonTopbar
          daysLeft={dialogData.daysLeft}
          toggleShowContent={showOnClickDialog}
          totalDays={dialogData.totalDays}
        />
      </Popover>
    )
  } else if (dialogData?.id) {
    return (
      <FreeTrialButtonTopbar
        daysLeft={dialogData.daysLeft}
        toggleShowContent={showOnClickDialog}
        totalDays={dialogData.totalDays}
      />
    )
  }
  // For all other free plans, we want to show a basic upgrade button
  return isLoading && isFetching ? null : (
    <FreeTrialButtonTopbar
      daysLeft={0}
      toggleShowContent={() => router.push(`${basePath}/plan`)}
      totalDays={0}
    />
  )
}
