import React, {useCallback, useMemo} from 'react'
import {Flex, Stack, Text} from '@sanity/ui'
import {useRequiredProjectPermissions} from '@/context/index'
import {TabSectionHeader, useConfirmDialog, PermissionButtonProject} from '@/ui/index'
import {Project} from '@/types/models'
import {useProjectData} from '@/data/projects/useProjectData'

export function ActivitySettings({
  title,
  description,
  project,
}: {
  title: string
  description: string
  project: Project
}) {
  const {showDialog, Dialog, hideDialog} = useConfirmDialog()
  const canDisableActivity = useRequiredProjectPermissions([
    {permissionName: 'sanity.project', grantName: 'update'},
  ])

  const {data: projectData, isLoading: loading, error, patchProjectData} = useProjectData(project)
  const {activityFeedEnabled: enabled} = projectData || {}

  const handleDisableActivity = useCallback(() => {
    if (!error) {
      patchProjectData(
        {activityFeedEnabled: !enabled},
        {
          onSuccess: (_data) => {
            hideDialog({
              title: `Activity feed settings updated`,
              status: 'success',
            })
          },
          onError: (err) => {
            console.error(err)
            hideDialog({
              description: `Could not update activity feed`,
              status: 'error',
            })
          },
        }
      )
    }
  }, [enabled, error, hideDialog, patchProjectData])

  const activityFeedTexts = useMemo(
    () => ({
      headerText: enabled ? 'Disable activity feed' : 'Enable activity feed',
      titleText: enabled
        ? 'Do you really want to disable activity feed?'
        : 'Do you really want to enable activity feed?',
      buttonText: enabled ? 'Disable activity feed' : 'Enable activity feed',
    }),
    [enabled]
  )

  return (
    <Stack space={4}>
      <TabSectionHeader title={title} description={description} />
      <Flex>
        <PermissionButtonProject
          title={activityFeedTexts.buttonText}
          hasPermission={canDisableActivity}
          onClick={showDialog}
        />
      </Flex>
      <Dialog
        header={activityFeedTexts.headerText}
        title={activityFeedTexts.titleText}
        id="disable-activity-project-dialog"
        onClose={hideDialog}
        onConfirm={handleDisableActivity}
        confirmButtonProps={{tone: 'primary'}}
        loading={loading}
      >
        <Text size={1} muted>
          {enabled
            ? 'The activity feed will not be visible to any members of the project. Feed items for this project will also not be visible within the organization activity feed.'
            : 'The activity feed will be visible to any members of the project. Feed items for this project will also be visible within the organization activity feed.'}
        </Text>
      </Dialog>
    </Stack>
  )
}
