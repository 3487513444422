import React, {forwardRef} from 'react'
import {Card, type CardTone, type CardProps} from '@sanity/ui'

interface Props extends React.TableHTMLAttributes<HTMLTableRowElement> {
  selected?: boolean
  selectedTone?: CardTone
}

export const TableRow = forwardRef<HTMLTableRowElement, Props & CardProps>(
  ({selected, children, selectedTone = 'primary', style, ...restProps}, ref) => {
    return (
      <Card
        as="tr"
        tone={(selected && selectedTone) || undefined}
        radius={0}
        style={style}
        ref={ref}
        {...restProps}
      >
        {children}
      </Card>
    )
  }
)

TableRow.displayName = 'TableRow'
