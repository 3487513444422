import {useRoutePath} from '@/context/index'
import {Organization, OrganizationInvitation, TableRowAction} from '@/types/index'
import {
  OrgInvitation,
  PermissionButtonOrg,
  RevokeOrgInvitationsDialog,
  TabHeader,
  Table,
  TableBody,
  TableEmpty,
  useConfirmDialog,
} from '@/ui/index'
import {AddIcon, TrashIcon} from '@sanity/icons'
import {Box, Stack, Text} from '@sanity/ui'
import {useState} from 'react'
import {useOrgInvitations} from '../../../../data'
import {inviteConfig} from '../inviteConfig'
import {INVITATIONS_TABLE as headers} from './tableConfig'

export function OrgInvitations({org}: {org: Organization}) {
  const {basePath} = useRoutePath()
  const {invitations, isLoading, error} = useOrgInvitations(org.id)
  const {showDialog, Dialog, hideDialog} = useConfirmDialog()
  const [activeInvitations, setActiveInvitations] = useState<OrganizationInvitation[]>([])

  const handleRevokeInvitation = (invitation: OrganizationInvitation) => {
    setActiveInvitations([invitation])
    showDialog()
  }

  const rowActions: TableRowAction[] = [
    {
      title: 'Revoke invitation',
      icon: TrashIcon,
      tone: 'critical',
      mode: 'bleed',
      onAction: handleRevokeInvitation,
      permissions: [{permissionName: 'sanity.organization.members', grantName: 'invite'}],
      dialog: (
        <RevokeOrgInvitationsDialog
          key="revoke-invitation"
          org={org}
          invitations={activeInvitations}
          Dialog={Dialog}
          hideDialog={hideDialog}
        />
      ),
    },
  ]

  return (
    <Stack space={[4, 4, 6]}>
      {rowActions.map((action) => typeof action !== 'string' && action?.dialog)}
      <TabHeader
        title="Pending organization invitations"
        button={
          <PermissionButtonOrg
            href={`${basePath}/${inviteConfig.path}`}
            title="Invite organization members"
            icon={AddIcon}
            permissions={[{permissionName: 'sanity.organization.members', grantName: 'invite'}]}
          />
        }
        description={
          <Stack space={5}>
            <Text muted>
              Invitations that have been sent, but not accepted yet. Changed your mind? Pending
              invitations may be revoked.
            </Text>
          </Stack>
        }
      />
      <Box>
        <Table
          headers={invitations.length > 0 ? headers : []}
          loadingText="Loading organization invitations..."
          loading={isLoading}
          error={error}
        >
          <TableBody>
            {invitations.length > 0 &&
              invitations.map((invitation: OrganizationInvitation) => (
                <OrgInvitation
                  key={`invitation-${invitation.id}`}
                  org={org}
                  invitation={invitation}
                  rowActions={rowActions}
                />
              ))}
            {!isLoading && invitations.length === 0 && (
              <TableEmpty
                title="There are no pending invitations for this organization"
                description="Maybe try inviting some new members?"
              />
            )}
          </TableBody>
        </Table>
      </Box>
    </Stack>
  )
}
