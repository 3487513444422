import React, {useState} from 'react'
import {Stack, Box} from '@sanity/ui'
import {AddIcon, RemoveCircleIcon} from '@sanity/icons'
import {
  OrgMember,
  TabHeader,
  Table,
  TableBody,
  TableEmpty,
  RemoveOrgMembersDialog,
  useConfirmDialog,
  PermissionButtonOrg,
  EmptyBlock,
} from '@/ui/index'
import {inviteConfig} from '../inviteConfig'
import {MEMBERS_TABLE as headers} from './tableConfig'
import {OrganizationMember, TableRowAction, Organization, ReferenceLink} from '@/types/index'
import {useRequiredOrganizationPermissions, useRoutePath} from '@/context/index'

export function OrgMembersTable({
  org,
  description,
  referenceLink,
}: {
  org: Organization
  description: string
  referenceLink: ReferenceLink
}) {
  const {basePath} = useRoutePath()
  const {showDialog, Dialog, hideDialog} = useConfirmDialog()
  const [activeMembers, setActiveMembers] = useState<OrganizationMember[]>([])
  const members = org?.members || []
  const handleRemoveFromProject = (member: OrganizationMember) => {
    setActiveMembers([member])
    showDialog()
  }

  const canReadMembers = useRequiredOrganizationPermissions([
    {permissionName: 'sanity.organization.members', grantName: 'read'},
  ])

  const canChangeRole = useRequiredOrganizationPermissions([
    {permissionName: 'sanity.organization.members', grantName: 'update'},
  ])

  const rowActions: TableRowAction[] = [
    {
      title: 'Remove from organization',
      icon: RemoveCircleIcon,
      tone: 'critical',
      mode: 'bleed',
      onAction: handleRemoveFromProject,
      permissions: [{permissionName: 'sanity.organization.members', grantName: 'delete'}],
      dialog: (
        <RemoveOrgMembersDialog
          key="remove-member-dialog"
          org={org}
          members={activeMembers}
          hideDialog={hideDialog}
          Dialog={Dialog}
        />
      ),
    },
  ]

  return (
    <Stack space={[4, 4, 6]}>
      {rowActions.map((action) => typeof action !== 'string' && action?.dialog)}
      <TabHeader
        title="Organization members"
        button={
          <PermissionButtonOrg
            href={`${basePath}/${inviteConfig.path}`}
            title="Invite organization members"
            icon={AddIcon}
            permissions={[{permissionName: 'sanity.organization.members', grantName: 'invite'}]}
          />
        }
        description={description}
        referenceLink={referenceLink}
      />
      {canReadMembers && (
        <Box>
          <Table headers={members.length > 0 ? headers : []}>
            <TableBody>
              {members.length > 0 ? (
                members.map((member: OrganizationMember) => (
                  <OrgMember
                    orgId={org.id}
                    member={member}
                    rowActions={canChangeRole ? rowActions : []}
                    key={`member-${member.sanityUserId}`}
                  />
                ))
              ) : (
                <TableEmpty
                  title="There are no members in this organization"
                  description="Maybe try inviting some members?"
                />
              )}
            </TableBody>
          </Table>
        </Box>
      )}
      {!canReadMembers && (
        <EmptyBlock
          illustration="access-denied"
          title="Insufficient permissions"
          description="You don't have the right permissions to see member details."
        />
      )}
    </Stack>
  )
}
