import {CheckmarkCircleIcon, CircleIcon} from '@sanity/icons'
import {Box, Card, Flex, Text} from '@sanity/ui'
import {Fragment} from 'react'

import {type InvitationScope} from './types'

interface InviteScopeTabs {
  onChange: (scope: InvitationScope) => void
  value: InvitationScope
}

const SCOPES: Record<InvitationScope, string> = {
  organization: 'Invite to organization',
  project: 'Invite to projects',
}

const SCOPE_ENTRIES = Object.entries(SCOPES)

export function InviteScopeTabs(props: InviteScopeTabs) {
  const {onChange, value} = props

  return (
    <Card radius={2} overflow="auto" shadow={1}>
      <Flex>
        {SCOPE_ENTRIES.map(([key, title], index) => {
          const showDivider = index > 0
          const isSelected = value === key
          const icon = isSelected ? <CheckmarkCircleIcon /> : <CircleIcon />
          const tone = isSelected ? 'primary' : 'transparent'

          return (
            <Fragment key={key}>
              {showDivider && <Card style={{width: 1}} borderLeft />}

              <Card
                __unstable_focusRing
                as="button"
                onClick={() => onChange(key as InvitationScope)}
                padding={3}
                shadow={1}
                tone={tone}
              >
                <Flex align="center" justify="center" gap={3}>
                  <Text size={1} muted>
                    {icon}
                  </Text>

                  <Box>
                    <Text size={1} muted weight="medium" textOverflow="ellipsis">
                      {title}
                    </Text>
                  </Box>
                </Flex>
              </Card>
            </Fragment>
          )
        })}
      </Flex>
    </Card>
  )
}
