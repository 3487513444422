import {type Invite, type ResourceType, type Role} from '@sanity/access-api'
import {EllipsisVerticalIcon, RemoveCircleIcon} from '@sanity/icons'
import {Flex, Menu, MenuButton, MenuItem, Text} from '@sanity/ui'
import Link from 'next/link'
import {memo, useMemo} from 'react'

import {type MembersV2} from '@/types/members_v2'

import {BadgeStack} from '../../../generic/badge-stack'
import {PermissionTooltip} from '../../../generic/permission-tooltip'
import {
  defineTableVirtualizedCell,
  defineTableVirtualizedColumns,
  TableVirtualized,
} from '../../../generic/table-virtualized'
import {Button} from '../../../primitives/button/Button'
import {type DisabledMemberSettingsFeatures} from '../../../screens/members-settings-screen/types'
import {CellContentWrap, MemberCell} from '../common'
import {CELL_MIN_WIDTH, CELL_PADDING} from '../constants'
import {InvitedByCell} from './InvitedByCell'

const EMPTY_ARRAY: [] = []

function estimateSize() {
  return 73
}

export interface InvitationsTableProps {
  basePath?: string
  disabledFeatures?: DisabledMemberSettingsFeatures
  invitations: Invite[]
  members: MembersV2['member'][]
  onInvitationRevoke: (inviteId: string, resourceId: string, resourceType: ResourceType) => void
  /**
   * The projects data is used to display the project name in the table
   * for the invitations that are related to a project.
   */
  projectsData: MembersV2['projectData'][]
  resourceType: ResourceType
  /**
   * The roles are used to display the role name in the table for the
   * invitations.
   */
  roles: Role[]
}

export const InvitationsTable = memo(function InvitationsTable(props: InvitationsTableProps) {
  const {
    basePath,
    disabledFeatures,
    invitations,
    members,
    onInvitationRevoke,
    roles,
    projectsData,
    resourceType,
  } = props

  const memberCell = useMemo(() => {
    return defineTableVirtualizedCell<Invite>({
      id: 'member',
      header: 'Email',
      cellPadding: CELL_PADDING,
      cellMinWidth: CELL_MIN_WIDTH,
      headerPadding: CELL_PADDING,

      cell: (ctx) => {
        const invitee = ctx.cell.row.original
        const inviteeId = invitee.inviteeId
        const email = invitee.email

        // Check if the invitee is a member of the organization
        const inviteeProfile = members.find((m) => m?.profile?.id === inviteeId)?.profile

        // If the invitee is not a member of the organization we can
        // only display the email of the invitee
        if (!inviteeProfile) {
          return (
            <CellContentWrap>
              <Text size={1}>{email}</Text>
            </CellContentWrap>
          )
        }

        return <MemberCell displayName={inviteeProfile?.displayName || ''} email={email || ''} />
      },
    })
  }, [members])

  const invitedToCell = useMemo(() => {
    return defineTableVirtualizedCell<Invite>({
      id: 'invited-to',
      header: 'Invited to',
      cellPadding: CELL_PADDING,
      cellMinWidth: CELL_MIN_WIDTH,
      headerPadding: CELL_PADDING,

      cell: (ctx) => {
        const resourceId = ctx.row.original.resourceId

        const invitedProject = projectsData.find((p) => p.projectProfile.id === resourceId)
        const projectProfile = invitedProject?.projectProfile

        return (
          <CellContentWrap>
            {ctx.row.original.resourceType === 'organization' && (
              <Text size={1} muted>
                Organization
              </Text>
            )}

            {projectProfile && ctx.row.original.resourceType === 'project' && (
              <BadgeStack
                items={[
                  {
                    title: projectProfile.displayName,
                    info: EMPTY_ARRAY,
                    href: `${basePath}/project/${projectProfile.id}`,
                    as: Link,
                  },
                ]}
              />
            )}
          </CellContentWrap>
        )
      },
    })
  }, [basePath, projectsData])

  const roleCell = useMemo(() => {
    return defineTableVirtualizedCell<Invite>({
      id: 'role',
      header: 'Role',
      cellPadding: CELL_PADDING,
      cellMinWidth: 150,
      headerPadding: CELL_PADDING,

      cell: (ctx) => {
        const role = ctx.row.original.role
        const resourceId = ctx.row.original.resourceId

        const orgRoleProfile = roles
          .filter((v) => v.resourceType === 'organization')
          .find((r) => r.name === role)
        const orgRoleTitle = orgRoleProfile?.title || orgRoleProfile?.name || role

        const project = projectsData.find((p) => p.projectProfile.id === resourceId)
        const projectRoles = project?.roleSchemas
        const projectRoleProfile = projectRoles?.find((r) => r.name === role)

        const tile =
          ctx.row.original.resourceType === 'organization'
            ? orgRoleTitle
            : projectRoleProfile?.title

        return (
          <CellContentWrap>
            <Text size={1} muted>
              {tile}
            </Text>
          </CellContentWrap>
        )
      },
    })
  }, [projectsData, roles])

  const actionsCell = useMemo(() => {
    return defineTableVirtualizedCell<Invite>({
      id: 'actions',
      cellMaxWidth: 50,
      cellMinWidth: 50,
      headerPadding: CELL_PADDING,
      header: '',

      cell: (ctx) => {
        const id = ctx.row.original.id || ''

        return (
          <Flex justify="center" align="center" height="fill" flex={1}>
            <MenuButton
              id="invitations-table-actions"
              button={
                <Button
                  aria-label="Show actions"
                  mode="bleed"
                  icon={EllipsisVerticalIcon}
                  padding={2}
                  fontSize={1}
                  tooltipProps={{content: 'Show actions'}}
                />
              }
              popover={{animate: true, placement: 'left-start'}}
              menu={
                <Menu>
                  <PermissionTooltip disabled={!disabledFeatures?.addMembers.isDisabled}>
                    <MenuItem
                      icon={RemoveCircleIcon}
                      disabled={disabledFeatures?.addMembers.isDisabled}
                      onClick={() =>
                        onInvitationRevoke(
                          id,
                          ctx.row.original.resourceId,
                          ctx.row.original.resourceType
                        )
                      }
                      text="Revoke invitation"
                      tone="critical"
                    />
                  </PermissionTooltip>
                </Menu>
              }
            />
          </Flex>
        )
      },
    })
  }, [])

  const invitedByCell = useMemo(() => {
    return defineTableVirtualizedCell<Invite>({
      id: 'invited-by',
      header: 'Invited by',
      cellMinWidth: 140,
      cellPadding: CELL_PADDING,
      headerPadding: CELL_PADDING,

      cell: (ctx) => {
        const createdAt = ctx.row.original.createdAt
        const inviter = members.find((m) => m.profile?.id === ctx.row.original.inviterId)?.profile

        const isServiceInvite = ctx.row.original.inviterType === 'service'

        return (
          <CellContentWrap>
            <InvitedByCell
              createdAt={createdAt}
              displayName={inviter?.displayName}
              imageUrl={inviter?.imageUrl}
              isServiceInvite={isServiceInvite}
            />
          </CellContentWrap>
        )
      },
    })
  }, [members])

  const columns = useMemo(() => {
    if (resourceType === 'organization') {
      return defineTableVirtualizedColumns<Invite>([
        memberCell,
        invitedToCell,
        roleCell,
        invitedByCell,
        actionsCell,
      ])
    }

    if (resourceType === 'project') {
      return defineTableVirtualizedColumns<Invite>([
        memberCell,
        roleCell,
        invitedByCell,
        actionsCell,
      ])
    }

    return []
  }, [resourceType, memberCell, invitedToCell, roleCell, invitedByCell, actionsCell])

  return (
    <TableVirtualized data={invitations} columnsDefinitions={columns} estimateSize={estimateSize} />
  )
})
