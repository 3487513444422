import {create, keyResolver, windowedFiniteBatchScheduler} from '@yornaath/batshit'
import {CurrentUser, User, UserNotificationPreference} from '../../types/models'
import {isAPIError, promiseRequest, request} from '@/data/util/promiseRequest'

const API_VERSION = 'v2021-06-07'

function ensureUsersArray(users: User | User[]): User[] {
  return Array.isArray(users) ? users : [users]
}

export const usersLoader = create({
  fetcher: async (userIds: string[]) => {
    const dedupedUserIds = Array.from(new Set(userIds))

    try {
      return promiseRequest<User | User[]>(
        `https://api.${process.env.host}/${API_VERSION}/users/${dedupedUserIds.join(',')}`
      ).then((result) => ensureUsersArray(result))
    } catch (err) {
      if (isAPIError(err) && (err.statusCode === 403 || err.statusCode === 401)) {
        return Promise.resolve([])
      }
      throw err
    }
  },
  resolver: keyResolver('id'),
  scheduler: windowedFiniteBatchScheduler({
    windowMs: 200,
    maxBatchSize: 500,
  }),
})

export function getCurrentUser() {
  return request<CurrentUser>({
    url: `https://api.${process.env.host}/${API_VERSION}/users/me`,
  }).then((response) => {
    if (!response.ok || !response.body.id) {
      return null
    }

    return response.body
  })
}

export function getUser(id: string | undefined) {
  if (!id) {
    return Promise.resolve(null)
  }
  return usersLoader.fetch(id)
}

export function getUsers(ids: string[]) {
  return Promise.all(ids.map((id) => usersLoader.fetch(id)))
}

export function deleteCurrentUser() {
  return promiseRequest<{isDeleted: boolean}>(
    `https://api.${process.env.host}/${API_VERSION}/users/me`,
    {
      method: 'DELETE',
    }
  ).then(async (response) => {
    await promiseRequest<User>(`https://api.${process.env.host}/${API_VERSION}/auth/logout`, {
      method: 'POST',
    })

    return response
  })
}

export function updateCurrentUser(user: {givenName: string; familyName: string}) {
  return promiseRequest<CurrentUser>(`https://api.${process.env.host}/${API_VERSION}/users/me`, {
    method: 'PATCH',
    json: {givenName: user.givenName, familyName: user.familyName},
  })
}

export function getProjectUser(userId: string) {
  if (!userId) {
    return Promise.resolve(null)
  }

  return usersLoader.fetch(userId)
}

export function deleteUserFromProject(projectId: string, userId: string) {
  return promiseRequest<any>(
    `https://api.${process.env.host}/${API_VERSION}/projects/${projectId}/acl/${userId}`,
    {
      method: 'DELETE',
    }
  )
}

export function getTelemetryConsentStatus() {
  return request<{status: string}>({
    url: `https://api.${process.env.host}/vX/users/me/consents/telemetry`,
    method: 'GET',
  })
}

export function setTelemetryConsentStatus(status: string) {
  return promiseRequest<{status: string}>(
    `https://api.${process.env.host}/vX/users/me/consents/telemetry/status/${status}`,
    {
      method: 'PUT',
    }
  )
}

export function getNotificationPreferences() {
  return request<{userPreferences: UserNotificationPreference[]}>({
    url: `https://api.${process.env.host}/vX/comments/preferences`,
    method: 'GET',
  })
}

export function setNotificationPreferences(enabled: boolean) {
  return promiseRequest<{response: string}>(
    `https://api.${process.env.host}/vX/comments/preferences`,
    {
      method: 'POST',
      json: {
        notifications: {
          mentions: enabled,
        },
      },
    }
  )
}

export function deleteUserFromOrg(organizationId: string, userId: string) {
  return promiseRequest<any>(
    `https://api.${process.env.host}/${API_VERSION}/organizations/${organizationId}/acl/${userId}`,
    {
      method: 'DELETE',
    }
  )
}
