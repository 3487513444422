export const REQUEST_SUCCESS = 'request-edit-access-success'
export const REQUEST_PENDING = 'request-edit-access-pending'
export const REQUEST_ERROR = 'request-edit-access-error'
export const REQUEST_DECLINED = 'request-edit-access-declined'

export const TOAST_DATA = {
  [REQUEST_SUCCESS]: {
    status: 'success',
    title: 'Request successfully sent',
  },
  [REQUEST_PENDING]: {
    status: 'warning',
    title: 'Previous request pending',
  },
  [REQUEST_ERROR]: {
    status: 'error',
    title: 'Failed to request access, please reach out to a project administrator',
  },
  [REQUEST_DECLINED]: {
    status: 'error',
    title: 'Previous request declined',
  },
}
