import {type ResourceType} from '@sanity/access-api'

import {type PricingModel} from '@/types/index'
import {type MembersV2} from '@/types/members_v2'
import {type ProjectMemberState} from '@/utils/usage'

import {
  type InvitationCreateValue,
  type InvitationScope,
  type LocalInvitationItemValue,
} from './types'

export const createLocalId = () => '_' + Math.random().toString(36).substring(2, 9)

export function isValidEmail(email: string): boolean {
  const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return pattern.test(email)
}

interface HasRoleInResource {
  member: MembersV2['member']
  resourceId: string
  roleName: string
}

export function hasSelectedRoleInResource(opts: HasRoleInResource) {
  const {member, resourceId, roleName} = opts

  return member?.memberships?.some(
    (mem) => mem.resourceId === resourceId && mem.roleNames?.includes(roleName)
  )
}

interface TransformLocalInvitesOpts {
  localInvitations: LocalInvitationItemValue[]
  scope: InvitationScope
  members: MembersV2['member'][]
}

/**
 * This function does the following:
 * - Filters out any invitees that already have the role for the resource
 * - Transforms the local invitation items into valid invitation create values.
 */
export function transformLocalInvites(opts: TransformLocalInvitesOpts): InvitationCreateValue[] {
  const {localInvitations, scope, members} = opts

  const invites: InvitationCreateValue[] = []

  for (const item of localInvitations) {
    for (const resource of item.resources) {
      for (const invitee of item.invitees) {
        let type: InvitationCreateValue['type'] = 'invite'

        const member = members.find((m) => m.profile?.id === invitee.memberId)

        if (member) {
          const hasMembership = member.memberships?.some(
            (mem) => mem.resourceId === resource.resourceId && mem.resourceType === scope
          )

          type = hasMembership ? 'role-add' : 'resource-add'
        }

        invites.push({
          invitee,
          resourceId: resource.resourceId,
          resourceType: scope,
          roleName: resource.roleName,
          type,
        })
      }
    }
  }

  const nextInvites = invites.filter((invite) => {
    const resource = invite.resourceId
    const roleName = invite.roleName
    const inviteeId = invite.invitee.memberId

    if (!resource || !roleName) return true

    // If invitee is new (no memberId), include the invite
    if (!inviteeId) return true

    // Find the member by the invitee's memberId
    const member = members.find((m) => m.profile?.id === inviteeId)

    // If no member is found, it is a new invitee and should be included
    if (!member) return true

    // Check if the member already has the role for the resource
    const hasRole = hasSelectedRoleInResource({member, resourceId: resource, roleName})

    // If the member doesn't have the role, include the invite
    return !hasRole
  })

  return nextInvites
}

export function isValidInvites(invites: InvitationCreateValue[]): boolean {
  return (
    invites.every((invite) => {
      return invite.invitee.email && invite.resourceId && invite.roleName && invite.resourceType
    }) && invites.length > 0
  )
}

export function createEmptyInvitationItem(scope: ResourceType): LocalInvitationItemValue {
  return {
    _id: createLocalId(),
    invitees: [],
    resources: [
      {
        _id: createLocalId(),
        resourceId: '',
        resourceType: scope,
        roleName: 'administrator',
      },
    ],
  }
}
export function noSeatsAvailable(memberState: Partial<ProjectMemberState>) {
  return (memberState?.billable ?? 0) >= (memberState?.max ?? 0)
}

export function noQuotaAvailable(
  memberState: Partial<ProjectMemberState>,
  pricingModel?: PricingModel
) {
  return (
    (memberState?.billable ?? 0) >= (memberState?.quota ?? 0) &&
    pricingModel !== 'per-seat' &&
    !noSeatsAvailable(memberState)
  )
}

export const NO_QUOTA_AVAILABLE_MEMBER_COPY = {
  users: 'member',
  // eslint-disable-next-line camelcase
  non_admin_users: 'non-admin member',
  // eslint-disable-next-line camelcase
  non_viewer_users: 'non-viewer member',
}

export const NO_SEATS_AVAILABLE_MEMBER_COPY = {
  users: {
    unavailableMemberType: 'members',
    availableMemberType: null,
  },
  // eslint-disable-next-line camelcase
  non_admin_users: {
    unavailableMemberType: 'members without administrative access',
    availableMemberType: 'administrators',
  },
  // eslint-disable-next-line camelcase
  non_viewer_users: {
    unavailableMemberType: 'members with editing access',
    availableMemberType: 'viewers',
  },
}
