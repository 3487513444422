import {useCurrentScopeContext, useRoutePath} from '@/context/index'
import {AppLink, PermissionTooltip} from '@/ui/index'
import {useRequiredProjectPermissions, useRequiredOrganizationPermissions} from '@/context/index'
import {Heading, Section, Tab} from '@/types/index'
import {useMatchesMedia} from '@/utils/index'
import {Badge, Box, Card, Flex, Stack, Text, Button as UIButton} from '@sanity/ui'
import {useRouter} from 'next/router'
import styled from 'styled-components'

interface Props {
  tab: Tab
  activeHeadingPath: string | null
  activeSectionPath: string | null
}

const SectionButton = styled.span`
  background: 0;
  border: 0;

  &:hover {
    background: 0;
    box-shadow: none !important;
    & span {
      box-shadow: none !important; /* todo */
    }
  }

  &[data-selected] {
    background: 0;
    box-shadow: none !important;
    & span {
      box-shadow: none !important; /* todo */
    }
  }
`

function ChildItem({
  section,
  href,
  disabled,
  isActive,
}: {
  section: Section
  href: string
  disabled: boolean
  isActive: boolean
}) {
  return (
    <AppLink href={href}>
      <UIButton
        mode="bleed"
        as={SectionButton}
        padding={2}
        style={{
          textAlign: 'left',
          pointerEvents: disabled ? 'none' : 'all',
        }}
        selected={isActive}
        tone={isActive ? 'primary' : 'default'}
        disabled={disabled}
      >
        <Flex align="center" gap={2}>
          <Box padding={1} paddingLeft={0} paddingRight={0}>
            <Text size={1}>{section.title}</Text>
          </Box>
          <Badge hidden={!section.badge} mode="outline" tone="positive">
            {section.badge}
          </Badge>
        </Flex>
      </UIButton>
    </AppLink>
  )
}

const SubTabButton = ({
  heading,
  roleScope,
  sections,
  isActive,
  activeSectionPath,
}: {
  heading: Heading
  roleScope: 'org' | 'project'
  sections: Section[]
  isActive: boolean
  activeSectionPath: string | null
}) => {
  const {basePath, tabId} = useRoutePath()
  const sidebarBasePath = `${basePath}${tabId ? `/${tabId}` : ''}`
  const permissions = {
    project: useRequiredProjectPermissions(heading.permissions),
    org: useRequiredOrganizationPermissions(heading.permissions),
  }
  // Determine if the content can be accessed
  const hasPermission = permissions[roleScope]

  // Get all the sections within the tab
  const childSections = sections
    .filter((s: Section) => s.heading === heading.path)
    .filter((section) => !section.hideFromSidebar)
  // Determine whether the path goes to a component or anchor
  const pathType = heading.component ? '/' : '#'
  // Determine the path based on weather the heading is a root path or not
  const path = heading.isRoot ? `` : `${pathType}${heading.path}`
  // Determine if the current path is from this heading to determine wether is should be highlighted or not
  const basePathIncludesChildPath =
    sidebarBasePath.split('/').find((segment: string) => segment === heading.path) !== undefined

  // This is the href we're generating dynamically based on `path`.
  const headingGeneratedHref = basePathIncludesChildPath
    ? sidebarBasePath
    : `${sidebarBasePath}${path}`

  // However, we always prefer the new `fullPath` property since this is more explicit.
  const headingHref = heading.fullPath ? `${basePath}${heading.fullPath}` : headingGeneratedHref

  const headingDisabled = heading.component && !hasPermission

  return (
    <PermissionTooltip
      key={`${heading.path}-permission`}
      disabled={!headingDisabled}
      action="does not have access to this content"
    >
      <Stack space={1}>
        <AppLink href={headingHref} disabled={headingDisabled}>
          <UIButton
            style={{textAlign: 'left', width: '100%'}}
            selected={isActive}
            mode="bleed"
            as="div"
            tone={isActive ? 'primary' : 'default'}
            disabled={headingDisabled}
            padding={2}
          >
            <Box paddingX={1} paddingY={1}>
              <Text>{heading.title}</Text>
              {heading.badge && (
                <Badge style={{position: 'absolute', right: '5%', top: '25%'}}>
                  {heading.badge}
                </Badge>
              )}
            </Box>
          </UIButton>
        </AppLink>
        {childSections.length > 0 && (
          <Box paddingLeft={3}>
            <Stack space={1}>
              {childSections.map((section: Section) => {
                const href =
                  !heading.isRoot && heading.component
                    ? `/${heading.path}#${section.path}`
                    : `#${section.path}`
                return (
                  <ChildItem
                    key={section.path}
                    section={section}
                    href={`${sidebarBasePath}${section.fullPath ?? href}`}
                    disabled={headingDisabled}
                    isActive={activeSectionPath === section.path}
                  />
                )
              })}
            </Stack>
          </Box>
        )}
      </Stack>
    </PermissionTooltip>
  )
}

export function TabSidebar({tab, activeHeadingPath, activeSectionPath}: Props) {
  const isMobile = useMatchesMedia(1)
  const router = useRouter()

  const roleScope = router.query?.projectId ? 'project' : 'org'

  const {org, project, scope} = useCurrentScopeContext() ?? {}
  const {sections = [], headings = []} = tab
  const visibleHeadings = headings.filter((heading) => {
    if (typeof heading.disableTabButtonRender === 'function') {
      return heading.disableTabButtonRender({org, proj: project, scope}) === false
    }

    return heading.disableTabButtonRender === false || heading.disableTabButtonRender === undefined
  })

  // Check if the first heading has sections to determine the stack spacing
  const stackSpace = sections.find((s) => s.heading === headings[0].path) ? [3, 3, 4] : [2, 3]

  return (
    <Card
      borderLeft={isMobile}
      paddingLeft={[3, 3, 0]}
      marginTop={[3, 3, 0]}
      style={{position: 'sticky', top: 135}}
    >
      <Stack space={stackSpace}>
        {visibleHeadings.map((heading: Heading, index: number) => (
          <SubTabButton
            key={`${heading.path}-${index}`}
            heading={heading}
            isActive={heading.path === activeHeadingPath}
            activeSectionPath={activeSectionPath}
            sections={sections}
            roleScope={roleScope}
          />
        ))}
      </Stack>
    </Card>
  )
}
