import React, {useCallback, useEffect, useState} from 'react'
import {Stack, Text, Select, Card} from '@sanity/ui'
import styled from 'styled-components'
import {SelectedCard} from './components'
import {
  useFilter,
  useSetFilter,
  useRequiredProjectPermissions,
  useCurrentScopeContext,
} from '@/context/index'
import {useDatasets} from '@/data/index'

const SelectWrapper = styled(Card)`
  & * {
    color: ${({theme}) => theme.sanity.color.card.enabled.muted.fg};
  }
`

export function DatasetFilter() {
  const [selectedDataset, setSelectedDataset] = useState<string | undefined>(undefined)
  const canReadDatasets = useRequiredProjectPermissions([
    {permissionName: 'sanity.project.datasets', grantName: 'read'},
  ])
  const {project} = useCurrentScopeContext()
  const {data: datasets = [], error} = useDatasets(project?.id)

  const setFilter = useSetFilter()
  const activeFilter = useFilter()

  const handleSelectDataset = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const dataset = e.target.value
      if (dataset === 'All datasets') {
        setSelectedDataset(undefined)
        setFilter({type: 'datasetName', payload: undefined})
      } else {
        setSelectedDataset(dataset)
        setFilter({type: 'datasetName', payload: dataset})
      }
    },
    [setFilter]
  )

  const handleResetSelect = useCallback(() => {
    setSelectedDataset(undefined)
    setFilter({type: 'datasetName', payload: undefined})
  }, [setFilter])

  useEffect(() => {
    const initialDataset = activeFilter?.datasetName
    if (initialDataset) {
      setSelectedDataset(initialDataset)
    } else {
      setSelectedDataset(undefined)
    }
  }, [activeFilter])

  if (!canReadDatasets || error) {
    return null
  }

  return (
    <Stack space={3}>
      <Text weight="semibold" size={1}>
        Dataset
      </Text>
      {selectedDataset ? (
        <SelectedCard
          value={selectedDataset}
          onClear={handleResetSelect}
          as="div"
          paddingLeft={2}
        />
      ) : (
        <SelectWrapper>
          <Select onChange={handleSelectDataset} radius={2}>
            <option value={undefined}>All datasets</option>
            {datasets.map((dataset) => (
              <option key={dataset.name}>{dataset.name}</option>
            ))}
          </Select>
        </SelectWrapper>
      )}
    </Stack>
  )
}
