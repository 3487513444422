import styled from 'styled-components'
import {SpinnerIcon} from '@sanity/icons'
import {ComponentProps} from 'react'

type Props = ComponentProps<typeof SpinnerIcon>

const SpinningSpinnerIcon = styled(SpinnerIcon)<Props>`
  animation: spin 500ms linear infinite;
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`

export function Spinner(props: Props) {
  return <SpinningSpinnerIcon {...props} />
}
