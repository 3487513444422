import {ExperienceModal, useSendRequestUpgrade} from '@/data/experienceDialogs'
import {ExperienceDialog} from './experience'
import {sendAmplitudeTrackingEvent} from '@/utils/tracking'
import {useCurrentScopeContext} from '@/context/index'
import {useProjectSubscription} from '@/data/projects/useProjectSubscription'

export function RequestUpgradeDialog({
  dialogContent,
  onClose,
  requestedPlanName,
}: {
  dialogContent: ExperienceModal
  onClose: () => void
  requestedPlanName: string
}) {
  const sendRequestUpgrade = useSendRequestUpgrade()
  const {project, org} = useCurrentScopeContext()
  const {data: subscription} = useProjectSubscription(project?.id)
  const currentPlanName = subscription?.plan?.name

  return (
    <ExperienceDialog
      dialogContent={dialogContent}
      onClose={onClose}
      ctaClickCallback={() => {
        sendAmplitudeTrackingEvent('Upgrade Request Sent', project?.id, org?.id, {
          requested_plan: requestedPlanName,
          current_plan: currentPlanName,
        })
        sendRequestUpgrade.mutate()
      }}
    />
  )
}
