import React from 'react'
import {Card, Spinner} from '@sanity/ui'
import styled from 'styled-components'
import {JavascriptDisabled} from './javascriptDisabled'
import {PageHead} from './layout'

const Main = styled(Card)`
  display: grid !important;
  place-items: center;
  position: fixed;
  inset: 0;
  z-index: 10000;
`

const LoadingSpinner = styled(Spinner)`
  svg {
    width: 45px;
    height: 45px;
  }
  color: ${({theme}) => theme.sanity.color.base.fg};
`

export function AppLoadingScreen() {
  return (
    <Main className="sanity-app-loading-screen" aria-busy="true">
      <PageHead />
      <LoadingSpinner />
      <JavascriptDisabled />
    </Main>
  )
}
