import React, {useCallback} from 'react'
import {useProjectData} from '@/data/projects/useProjectData'
import {DialogToast, Project} from '@/types/index'

type Props = {
  project: Project
  Dialog: React.ElementType
  hideDialog: (dialogToast?: DialogToast) => void
}

export const ArchiveProjectDialog = ({project, hideDialog, Dialog}: Props) => {
  const {isLoading, patchProjectData} = useProjectData(project)
  const displayName = project?.displayName

  const confirmArchive = useCallback(() => {
    patchProjectData(
      {isDisabledByUser: true},
      {
        onSuccess: () => {
          hideDialog({
            title: 'Project archived',
            status: 'success',
          })
        },
        onError: () => {
          hideDialog({description: 'Could not archive project', status: 'error'})
        },
      }
    )
  }, [patchProjectData, hideDialog])

  return (
    <Dialog
      header="Archive project"
      title={`Do you really want to archive ${displayName}?`}
      description="Archiving a project removes any access to it but does not delete it permanently."
      id="archive-project-dialog"
      onConfirm={confirmArchive}
      confirmButtonProps={{text: 'Archive'}}
      loading={isLoading}
    />
  )
}
