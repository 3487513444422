import React, {useCallback, useEffect} from 'react'
import {TextInput, Select, Container} from '@sanity/ui'
import {useForm} from 'react-hook-form'
import {FormField} from '../'
import {EditContainer} from './.'
import {Dataset, Webhook} from '@/types/index'

interface Props {
  onSave: (a: Webhook) => void
  onCancel: () => void
  datasets: Dataset[]
  webhooks: Webhook[]
  loading?: boolean
}

const urlValidator = /^https?:\/\//i

export function WebhooksInput({onSave, onCancel, datasets, webhooks, loading}: Props) {
  const {
    register,
    handleSubmit,
    formState: {errors},
    reset,
    setError,
    clearErrors,
  } = useForm<Webhook>()
  const webhookNames: string[] = webhooks?.map((w) => w.name) || []

  useEffect(() => {
    return () => reset()
  }, [])

  const onSubmit = (data: Webhook) => {
    if (webhookNames.includes(data.name)) {
      setError('name', {message: 'A webhook with this name already exists'})
    } else {
      clearErrors('name')
      onSave(data)
    }
  }

  const handleCancel = useCallback(() => {
    onCancel()
  }, [onCancel])

  return (
    <Container style={{margin: 0, maxWidth: 475}}>
      <EditContainer onSave={handleSubmit(onSubmit)} onCancel={handleCancel} loading={loading}>
        <FormField
          label="Name"
          description="Identifying label for this webhook"
          error={errors?.name?.message}
        >
          <TextInput
            radius={2}
            padding={4}
            {...register('name', {required: 'A name is required'})}
          />
        </FormField>

        <FormField
          label="Url"
          description="A URL in the format of protocol://hostname[:port]"
          error={errors?.url && 'A valid URL is required'}
        >
          <TextInput
            radius={2}
            padding={4}
            placeholder="http://"
            {...register('url', {required: true, pattern: urlValidator})}
          />
        </FormField>

        <FormField label="Dataset" description="The dataset(s) it should respond to">
          <Select radius={2} padding={4} {...register('dataset')} defaultValue="*">
            <optgroup label="Datasets">
              <>
                <option key="*" value="*">
                  * (all datasets)
                </option>
                {datasets?.map((d) => (
                  <option key={d.name} value={d.name}>
                    {d.name}
                  </option>
                ))}
              </>
            </optgroup>
          </Select>
        </FormField>
      </EditContainer>
    </Container>
  )
}
