import {promiseRequest} from '../util/promiseRequest'

const STATUS_PAGE_ID = 'qzkkn6cg4njg'

export interface StatusResponse {
  status: {
    indicator: string | undefined
    description: string
  }
  page: {
    url: undefined | string
  }
}

export async function getApiStatus(): Promise<StatusResponse> {
  return promiseRequest<StatusResponse>(
    `https://${STATUS_PAGE_ID}.statuspage.io/api/v2/status.json`,
    {
      credentials: 'omit',
    }
  )
}
