import {Observable} from 'rxjs'
import {map, share} from 'rxjs/operators'
import {PaymentMethod} from '@stripe/stripe-js'
import {deletePaymentSourceAction, headers} from './utils'
import {apiRequest} from '@/data/util/request'
import {Customer, CustomerInvoice, PaymentSecret} from '@/types/index'
import {promiseRequest} from '@/data/util/promiseRequest'

export function deletePaymentSourceForCustomer(customerId: string): Observable<Customer> {
  return apiRequest<Customer>({
    body: {},
    headers,
    method: 'DELETE',
    url: `https://api.${process.env.host}/v1/customers/${customerId}/paymentsource`,
  }).pipe(
    map((res) => {
      deletePaymentSourceAction.next(res)
      return res
    }),
    share()
  )
}

export function setPaymentSourceForCustomer(
  customerId: string,
  source: string | PaymentMethod,
  recaptchaToken: string | undefined
) {
  const sourceId = typeof source === 'string' ? source : source.id
  return promiseRequest<Customer>(
    `https://api.${process.env.host}/v1/customers/${customerId}/paymentsource`,
    {
      json: {stripeSource: sourceId, recaptchaToken},
      headers,
      method: 'POST',
    }
  )
}

export function getCustomerInvoices(id: string) {
  return promiseRequest<CustomerInvoice[]>(
    `https://api.${process.env.host}/v1/customers/${id}/invoices`
  )
}

export function createSetupIntent(
  customerId: string,
  recaptchaToken: string | undefined
): Promise<string> {
  return promiseRequest<string>(
    `https://api.${process.env.host}/v1/customers/${customerId}/setupintent`,
    {
      json: {recaptchaToken},
      headers,
      method: 'POST',
    }
  )
}

export function createPaymentIntent(customerId: string): Observable<Customer> {
  return apiRequest<Customer>({
    body: {},
    headers,
    method: 'POST',
    url: `https://api.${process.env.host}/v1/customers/${customerId}/paymentintent`,
  })
}

export function resolvePaymentState(
  organizationID: string,
  resolution: 'downgrade' | 'reinstate'
): Observable<string> {
  return apiRequest<string>({
    body: {organizationID, resolution},
    headers,
    method: 'PUT',
    url: `https://api.${process.env.host}/v1/subscriptions/resolvePaymentState`,
  })
}

export function getPaymentSecret(projectID: string): Observable<PaymentSecret> {
  return apiRequest<PaymentSecret>({
    url: `https://api.${process.env.host}/v1/subscriptions/project/${projectID}/stripe/clientsecret`,
  })
}
