import React, {useEffect, useCallback, useState} from 'react'
import {Stack, useToast} from '@sanity/ui'
import {useRouter} from 'next/router'
import {AddIcon} from '@sanity/icons'
import {RolesOverview} from './rolesOverview'
import {ProjectRole} from './role'
import {UpdateRoleDialog} from './updateRoleDialog'
import {Project, Tab} from '@/types/index'
import {PermissionButtonProject, TabHeader} from '@/ui/index'
import {useProjectRoles} from '@/data/projects/useProjectRoles'
import {useProjectSubscription} from '@/data/projects/useProjectSubscription'

type Props = {
  project: Project
  title?: string | React.ReactNode
  description?: string | React.ReactNode
}

export function ProjectRoles({title, description, referenceLink, project}: Props & Tab) {
  const {query} = useRouter()
  const toast = useToast()
  const [roleWizard, setRoleWizard] = useState(false)
  const {data: subscription} = useProjectSubscription(project?.id)
  const targetRole = Array.isArray(query.role) ? query.role[0] : query.role
  const hasCreateRoleFeature = project.features.includes('advancedRolesManagement')
  const {
    data: roles = [],
    error: getRoleError,
    isLoading: isLoadingRoles,
  } = useProjectRoles(project.id)

  const handleRoleWizardOpen = useCallback(() => {
    setRoleWizard(true)
  }, [])

  const handleRoleWizardClose = useCallback(() => {
    setRoleWizard(false)
  }, [])

  useEffect(() => {
    if (getRoleError) {
      toast.push({
        title: getRoleError.name,
        description: getRoleError.message,
        status: 'error',
      })
    }
  }, [toast, getRoleError])

  if (targetRole) {
    const role = roles.find((r) => r.name == targetRole)
    if (role) {
      return (
        <ProjectRole
          project={project}
          subscription={subscription}
          role={role}
          roleName={targetRole}
          users={project.members.filter((m) => m.roles.some((r) => r.name === targetRole))}
          loading={isLoadingRoles}
          loadingText={`Loading role...`}
        />
      )
    }
  }

  return (
    <>
      <Stack space={[4, 4, 6]} data-title={title}>
        <TabHeader
          title="Roles"
          description={description}
          referenceLink={referenceLink}
          button={
            <PermissionButtonProject
              onClick={handleRoleWizardOpen}
              title="Create new role"
              icon={AddIcon}
              permissions={[{permissionName: 'sanity.project.roles', grantName: 'create'}]}
              disabled={isLoadingRoles || !hasCreateRoleFeature}
            />
          }
          showUpsell={!hasCreateRoleFeature}
          upsellResourceName="roles"
        />
      </Stack>
      <RolesOverview roles={roles} users={project.members} loading={isLoadingRoles} />
      {roleWizard && (
        <UpdateRoleDialog onClose={handleRoleWizardClose} projectId={project.id} mode="create" />
      )}
    </>
  )
}
