import {Card, CardProps, Flex, Spinner, Stack, Text} from '@sanity/ui'
import React from 'react'

type Props = {
  text?: string
  cardProps?: CardProps
  height?: number
}

export function Loader({text = 'Loading...', cardProps, height}: Props) {
  return (
    <Card {...cardProps}>
      <Flex justify="center" align="center" style={{height: height}}>
        <Stack space={3}>
          <Text style={{textAlign: 'center'}}>
            <Spinner muted size={1} />
          </Text>
          <Text size={1} muted style={{textAlign: 'center'}}>
            {text}
          </Text>
        </Stack>
      </Flex>
    </Card>
  )
}
