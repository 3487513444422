import React, {useCallback, useEffect} from 'react'
import {
  Badge,
  Card,
  Flex,
  Box,
  Inline,
  Text,
  Button,
  Stack,
  useToast,
  MenuButton,
  Menu,
  MenuItem,
  MenuDivider,
} from '@sanity/ui'
import {
  EllipsisVerticalIcon,
  CheckmarkCircleIcon,
  CogIcon,
  TrashIcon,
  CopyIcon,
} from '@sanity/icons'
import {Project} from '@/types/models/project'
import {OrganizationSSOProviderSAML} from '@/types/models/organization'
import {useProjectHasFeature, useRequiredProjectPermissions} from '@/context/index'

import {useProjectSubscription} from '@/data/projects/useProjectSubscription'
import {useCopyToClipboard} from '@/utils/general'

type SsoSAMLProjectRoleProps = {
  samlProvider: OrganizationSSOProviderSAML
  project: Project
  setSamlProjectRoleVisible: (project: Project | null) => void
  setSamlProjectRoleDeleteVisible: (project: Project | null) => void
}

export const REQUIRED_FEATURE = 'authSAML'

export default function SsoSAMLProjectRole({
  samlProvider,
  project,
  setSamlProjectRoleVisible,
  setSamlProjectRoleDeleteVisible,
}: SsoSAMLProjectRoleProps) {
  const {push} = useToast()
  const {copyToClipboard, success: copySuccess, error: copyError} = useCopyToClipboard()

  const projectsRoleMappings = samlProvider.projectRoleMappings || {}
  const projectRoleMappings = projectsRoleMappings[project.id] || []

  const {data: subscription} = useProjectSubscription(project.id)
  const hasFeature = useProjectHasFeature(project, REQUIRED_FEATURE)
  const hasRolesGrants = useRequiredProjectPermissions(
    [
      {
        permissionName: 'sanity.project.roles',
        grantName: 'read',
      },
    ],
    project.id
  )
  const isEnterprise = subscription?.plan.planTypeId === 'enterprise'

  const openSamlProjectRoleDeleteVisible = useCallback(() => {
    if (hasFeature) {
      setSamlProjectRoleDeleteVisible(project)
    } else {
      push({
        title: 'Plan not supported',
        description: 'The project plan does not support SAML integration. ',
        status: 'error',
      })
    }
  }, [setSamlProjectRoleDeleteVisible, project, hasFeature, push])
  const openDialog = useCallback(() => {
    if (hasFeature) {
      setSamlProjectRoleVisible(project)
    } else {
      push({
        title: 'Plan not supported',
        description: 'The project plan does not support SAML integration. ',
        status: 'error',
      })
    }
  }, [setSamlProjectRoleVisible, project, hasFeature, push])

  const enabled = hasFeature && projectRoleMappings.length > 0

  const copyUrl = useCallback(
    (value: string) => () => {
      copyToClipboard(value)
    },
    [copyToClipboard]
  )

  useEffect(() => {
    if (copySuccess) {
      push({
        title: 'Copied to clipboard',
        status: 'success',
      })
      return
    }

    if (copyError) {
      push({
        title: 'Failed to copy to clipboard',
        status: 'error',
      })
    }
  }, [copySuccess, copyError, push])

  return (
    <Card padding={4} border radius={3}>
      <Flex align="center">
        <Box flex={2}>
          <Stack space={1}>
            <Text size={2} weight="semibold">
              {project.displayName}
            </Text>
            {subscription && (
              <Box>
                <Badge fontSize={0} tone={isEnterprise ? 'primary' : undefined}>
                  {subscription.plan.name}
                </Badge>
              </Box>
            )}
          </Stack>
        </Box>
        <Box flex={2}>
          {enabled && (
            <Inline>
              <Card tone="positive" padding={2} radius={2}>
                <Inline space={3}>
                  <Text size={1} muted>
                    <CheckmarkCircleIcon />
                  </Text>
                  <Text size={1} muted>
                    SAML SSO is activated
                  </Text>
                </Inline>
              </Card>
            </Inline>
          )}
          {!enabled && (
            <Inline>
              <Card tone="transparent" padding={2} radius={2}>
                <Text size={1} muted>
                  SAML SSO is not configured
                </Text>
              </Card>
            </Inline>
          )}
        </Box>
        <Flex flex={1} justify="flex-end">
          {hasFeature && (
            <MenuButton
              id={`${project.id}-configure.menu`}
              button={
                <Button
                  mode="bleed"
                  icon={EllipsisVerticalIcon}
                  aria-label="Edit"
                  title={hasRolesGrants ? '' : 'Missing required permission to read project roles'}
                  disabled={hasRolesGrants === false}
                  style={hasRolesGrants === false ? {cursor: 'not-allowed'} : {}}
                />
              }
              menu={
                <Menu>
                  <MenuItem text="Configure" onClick={openDialog} icon={CogIcon} />
                  {enabled && (
                    <>
                      <MenuItem
                        text="Copy login url"
                        onClick={copyUrl(
                          `${samlProvider.loginUrl}?origin=${encodeURIComponent(
                            `https://www.${process.env.host}/manage`
                          )}&projectId=${project.id}`
                        )}
                        icon={CopyIcon}
                      />
                      <MenuDivider />
                      <MenuItem
                        text="Remove configuration"
                        tone="critical"
                        icon={TrashIcon}
                        onClick={openSamlProjectRoleDeleteVisible}
                      />
                    </>
                  )}
                </Menu>
              }
            />
          )}
        </Flex>
      </Flex>
    </Card>
  )
}
