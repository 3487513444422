import React from 'react'
import {Banner} from '@/components/banner'
import {useAnnouncement} from '@/data/announcement/useAnnouncement'

export function AnnouncementBannerContainer() {
  const announcement = useAnnouncement()

  if (!announcement) {
    return null
  }

  return (
    <Banner
      tone="caution"
      icon="warning-outline"
      title={announcement.title}
      description={announcement.description}
      ctaText={announcement.ctaText}
      ctaHref={announcement.ctaHref}
    />
  )
}
