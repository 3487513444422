import React from 'react'
import {useRouter} from 'next/router'
import {Stack} from '@sanity/ui'
import {OverageChargeWarning} from './overageChargeWarning'
import {OverageDisallowedWarning} from './overageDisallowedWarning'
import {Dataset, Plan, Project, ResourcesData, Subscription} from '@/types/models'

export function OverageAlert(props: {
  currentPlan: Plan | undefined
  currentUsage: ResourcesData | undefined
  targetPlan: Plan | undefined
  datasets: Dataset[] | undefined
  projectMembers: Project['members'] | undefined
  projectRobotMembers: Project['members'] | undefined
  subscription: Subscription | undefined
  projectId: string | undefined
  organizationId: string | undefined | null
}) {
  const {
    currentPlan,
    currentUsage,
    targetPlan,
    datasets,
    projectMembers,
    projectRobotMembers,
    subscription,
    projectId,
    organizationId,
  } = props

  const router = useRouter()
  return (
    <Stack>
      <OverageDisallowedWarning
        planName={targetPlan?.name}
        datasets={datasets}
        projectMembers={projectMembers}
        projectRobotMembers={projectRobotMembers}
        targetPlan={targetPlan}
        subscription={subscription}
        projectId={projectId}
        organizationId={organizationId}
        router={router}
      />

      <OverageChargeWarning
        targetPlanName={targetPlan?.name}
        currentPlan={currentPlan}
        currentUsage={currentUsage}
        targetPlan={targetPlan}
      />
    </Stack>
  )
}
