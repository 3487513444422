import React from 'react'
import {BaseBadge} from './helpers/baseBadge'
import {usePlanBadgeVariant} from './helpers/usePlanBadgeVariant'
import {useProjectType} from '@/context/index'
import {PlanTypeNames, Project, Subscription} from '@/types/models'
import styled from 'styled-components'
import Link from 'next/link'
import {Skeleton} from '@sanity/ui'

const TITLES: Record<string, string> = {
  enterprise: 'Enterprise',
  advanced: 'Advanced',
  developer: 'Developer',
  playground: 'Playground project',
  'org-plan': 'Organization project',
}

const BadgeLink = styled(Link)`
  cursor: pointer !important;
  color: inherit !important;
  text-decoration: none !important;
`

const SkeletonBadge = styled(Skeleton)`
  min-width: 70px;
  height: 1em;
`

export function ProjectPlanBadge({
  project,
  subscription,
  isLoading,
}: {
  project?: Project
  subscription?: Subscription
  isLoading?: boolean
}) {
  const projectType = useProjectType(project)
  const planName = subscription?.plan.name || 'Unknown'

  const title = projectType === 'regular' ? planName : TITLES[projectType]
  const type = projectType === 'regular' ? planName : projectType
  const variant = usePlanBadgeVariant(type as PlanTypeNames)

  const basePath = project?.organizationId
    ? `/organizations/${project?.organizationId}`
    : '/manage/personal'
  const planHref = `${basePath}/project/${project?.id}/plan`

  return (
    <BaseBadge $variant={variant}>
      {!isLoading && <BadgeLink href={planHref}>{title}</BadgeLink>}
      {isLoading && <SkeletonBadge />}
    </BaseBadge>
  )
}
ProjectPlanBadge.displayName = 'ProjectPlanBadge'
