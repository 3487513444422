import {Box, Card, Flex, Stack, Text} from '@sanity/ui'
import React, {useCallback, useMemo} from 'react'
import {InfoOutlineIcon} from '@sanity/icons'
import {MenuMultiSelect, MenuMultiSelectOption} from '../general/menuMultiSelect'
import {PermissionTooltip, useConfirmDialog} from '@/ui/index'
import {useProjectUserRolesList} from '@/context/index'
import {ProjectMemberRole} from '@/types/models'
import {useProjectMemberRole, useProjectSubscription} from '@/data/projects'
import {useOrganizationCanPay} from '@/data/organizations'
import {getErrorDetails} from '@/utils/errors'
import {useCurrentScopeContext, useProjectType} from '@/context/index'
import {canPayOverage, getProjectMemberState} from '@/utils/usage'

export function ManagedExternalBanner() {
  return (
    <Card tone="caution" border margin={2} padding={3} radius={3}>
      <Flex>
        <Flex marginRight={2}>
          <Text size={1} muted>
            <InfoOutlineIcon />
          </Text>
        </Flex>
        <Box marginLeft={2}>
          <Stack space={3}>
            <Text size={1} as="p" muted weight="bold">
              Cannot edit roles here
            </Text>
            <Text size={1} as="p" muted>
              This member's roles are defined by SAML SSO. They can only be edited at your external
              identity provider.
            </Text>
          </Stack>
        </Box>
      </Flex>
    </Card>
  )
}

type Props = {
  projectId: string
  hasPermission: boolean
  user: {
    id: string
    isCurrentUser: boolean
  }
  currentRoles: ProjectMemberRole[]
  projectRoles?: ProjectMemberRole[]
  managedExternal?: boolean
}

export const ProjectRoleSelect = ({
  projectId,
  user,
  hasPermission,
  currentRoles,
  projectRoles,
  managedExternal = false,
}: Props) => {
  const {org, project} = useCurrentScopeContext() ?? {}
  // State to track the role update and update UI
  const {data: subscription} = useProjectSubscription(project?.id)
  const {data: canPay} = useOrganizationCanPay(org?.id)

  const {roles: rolesList} = useProjectUserRolesList(projectId)
  const userRoles = projectRoles !== undefined ? projectRoles : rolesList

  const {
    addProjectRole,
    removeProjectRole,
    isLoading: loading,
  } = useProjectMemberRole(projectId, user.id)

  const projectType = useProjectType(project)
  const memberState = getProjectMemberState(project!, projectType, subscription?.resources, org)
  const hasQuota = memberState.billable < memberState.quota
  const hasOverage = memberState.billable < memberState.max
  const canPayOverageSeat = canPayOverage(subscription, org, canPay)

  const {showDialog, hideDialog, Dialog} = useConfirmDialog()

  const handleRoleOnAdd = useCallback(
    (roleName) => {
      if (!hasPermission || loading) {
        return undefined
      }
      addProjectRole(roleName, {
        onSuccess: () => {
          hideDialog({title: 'Successfully updated member role', status: 'success'})
        },
        onError: (error) => {
          const errorDetails = getErrorDetails(error)
          hideDialog({
            title: 'An error occurred',
            description: errorDetails.message,
            status: 'error',
          })
        },
      })
    },
    [hasPermission, addProjectRole, loading, hideDialog]
  )

  const handleRoleOnRemove = useCallback(
    (roleName) => {
      if (!hasPermission || loading) {
        return undefined
      }
      if (currentRoles.length === 1) {
        hideDialog({
          title: 'Unable to remove last member role',
          description: 'All members must have a role',
          status: 'error',
        })
        return undefined
      }
      removeProjectRole(roleName, {
        onSuccess: () => {
          hideDialog({title: 'Successfully updated member role', status: 'success'})
        },
        onError: (error) => {
          const errorDetails = getErrorDetails(error)
          hideDialog({
            title: 'An error occurred',
            description: errorDetails.message,
            status: 'error',
          })
        },
      })
    },
    [currentRoles, hasPermission, removeProjectRole, hideDialog, loading]
  )

  const handleRoleConfirmOnRemove = useCallback(
    (roleName: string | undefined | number) => {
      if (typeof roleName !== 'string' || !hasPermission) {
        return undefined
      }

      if (user.isCurrentUser) {
        return showDialog(roleName)
      }
      if (hasPermission) {
        return handleRoleOnRemove(roleName)
      }
      return undefined
    },
    [hasPermission, showDialog, user.isCurrentUser, handleRoleOnRemove]
  )

  const handleRoleOnChange = useCallback(
    (roleName) => {
      const has = currentRoles.some((role) => role.name === roleName)
      if (has) {
        return handleRoleConfirmOnRemove(roleName)
      }
      return handleRoleOnAdd(roleName)
    },
    [currentRoles, handleRoleConfirmOnRemove, handleRoleOnAdd]
  )

  const rolesValue = useMemo(() => {
    return currentRoles.map((role) => role.name)
  }, [currentRoles])

  const roleOptions: MenuMultiSelectOption[] = useMemo(() => {
    return userRoles.sort((a, b) => a.name.localeCompare(b.name))
  }, [userRoles])

  const isRoleDisabled = useCallback(
    (option: MenuMultiSelectOption) => {
      if (managedExternal) {
        return true
      }
      // If a role exists, allow selecting it to remove the role
      if (rolesValue.includes(option.name)) {
        return false
      }

      // All roles are enabled if there are no "free" roles available.
      if (memberState.resource === 'users') {
        return false
      }

      // All roles are enabled if there is quota.
      if (hasQuota) {
        return false
      }

      // All roles are enabled if there is overage and the seat can be paid for.
      if (hasOverage && canPayOverageSeat) {
        return false
      }

      // Only the "free" role is enabled if there's no more quota/overage
      // available, or the seat can't be paid for.
      switch (memberState.resource) {
        case 'non_admin_users':
          return option.name !== 'administrator'
        case 'non_viewer_users':
          return option.name !== 'viewer'
        default:
          return true
      }
    },
    [managedExternal, rolesValue, hasQuota, hasOverage, canPayOverageSeat, memberState.resource]
  )

  return (
    <Box style={{maxWidth: 300}}>
      <Dialog
        header="Change role"
        title="Do you really want to change your own role?"
        description="You may not be able to administrate this project anymore!"
        id={`role-${user.id}`}
        onConfirm={handleRoleOnRemove}
        loading={loading}
      />
      <PermissionTooltip disabled={hasPermission}>
        <MenuMultiSelect
          constrainSize={false}
          id={`roles-${user.id}`}
          options={roleOptions}
          values={rolesValue}
          onChange={handleRoleOnChange}
          disabled={!hasPermission || loading}
          loading={loading}
          noValue={'No role'}
          isOptionDisabled={isRoleDisabled}
          banner={managedExternal ? <ManagedExternalBanner /> : undefined}
        />
      </PermissionTooltip>
    </Box>
  )
}
