import {useCopyToClipboard} from '@/utils/general'
import {Code, useToast} from '@sanity/ui'
import {useCallback, useRef} from 'react'
import {Card, Stack, Text, Flex, Box, Button} from '@sanity/ui'
import {CopyIcon} from '@sanity/icons'

export function TokenContainer({token}: {token: string}) {
  const ref = useRef<HTMLSpanElement>(null)
  const toast = useToast()
  const {copyToClipboard} = useCopyToClipboard()

  const selectToken = () => {
    const el = ref.current!
    const range = document.createRange()
    range.selectNodeContents(el)
    const sel = window.getSelection()
    sel?.removeAllRanges()
    sel?.addRange(range)
  }

  const handleCopyToClipboard = useCallback(() => {
    copyToClipboard(token, {
      onSuccess: () => {
        toast.push({
          title: 'Copied token to clipboard',
          status: 'success',
        })
      },
      onError: () => {
        toast.push({
          title: 'Failed to copy token to clipboard',
          status: 'error',
        })
      },
    })
  }, [copyToClipboard, token, toast])

  return (
    <Card padding={4} paddingLeft={4} paddingRight={4} sizing="border" tone="caution">
      <Stack space={4}>
        <Text weight="semibold" size={1}>
          Copy the token below – this is your only chance to do so!
        </Text>
        <Card padding={4}>
          <Flex align="center" style={{width: '100%'}}>
            <Box onClick={() => selectToken()}>
              <Code
                muted
                style={{wordBreak: 'break-all', whiteSpace: 'initial'}}
                ref={ref}
                data-sentry-mask
              >
                {token}
              </Code>
            </Box>
            <Box marginLeft={4} marginRight={4}>
              <Button
                icon={CopyIcon}
                mode="bleed"
                onClick={handleCopyToClipboard}
                aria-label="Copy Token"
              />
            </Box>
          </Flex>
        </Card>
      </Stack>
    </Card>
  )
}
