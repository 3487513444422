import {Footer, LoadingOverlay} from '@/components/checkout/components'
import {useSwitchPlanStore} from '@/components/checkout/store'
import {useCurrentScopeContext} from '@/context/index'
import {switchProjectPlan, useProjectSubscription} from '@/data/index'
import type {Project} from '@/types/index'
import {ensureSentenceStop} from '@/utils/index'
import {Button, Card, Stack} from '@sanity/ui'
import * as Sentry from '@sentry/nextjs'
import {FormProvider, useForm} from 'react-hook-form'
import {lastValueFrom} from 'rxjs'
import {FORM_ERRORS} from './constants'
import {PlanInfoCards} from './components/planInfoCards'

interface Props {
  onSubmit: (project: Project) => void
  onSubmitError?: (title: string, message: string) => void
}

export function CheckoutWithoutForm({onSubmit, onSubmitError}: Props) {
  const {state} = useSwitchPlanStore()
  const scope = useCurrentScopeContext()
  const form = useForm()
  const {data: currentPlan} = useProjectSubscription(scope?.projectId || '')

  const onSubmitHandler = async () => {
    try {
      /* switch the project to the new plan */
      const planSwitch = await lastValueFrom(
        switchProjectPlan(scope!.project!.id, state.plan!.id, [])
      )
      if (planSwitch?.status === 'incomplete') {
        throw new Error(FORM_ERRORS.cardDeclined, {cause: 'Card declined'})
      }
      onSubmit(scope!.project!)
    } catch (error) {
      const {cause, message: _message} = error as Error
      const title = typeof cause === 'string' ? cause : 'Checkout failed'
      const message = ensureSentenceStop(_message) || FORM_ERRORS.generic
      onSubmitError?.(title, message)
      Sentry.setTags({checkout_flow: title, project_id: scope!.project!.id})
      Sentry.captureException(error) // Log the error to Sentry
    }
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmitHandler)}>
        <Card paddingX={4} paddingTop={2} style={{position: 'relative'}}>
          <PlanInfoCards currentPlan={currentPlan!.plan!} targetPlan={state.plan!} />
        </Card>

        <Stack padding={4} space={4}>
          <Button
            text={`Upgrade to ${state.plan?.name}`}
            tone="primary"
            type="submit"
            width="fill"
            loading={form.formState.isSubmitting}
            disabled={form.formState.disabled}
          />
          <Footer />
        </Stack>
      </form>

      <LoadingOverlay show={form.formState.isSubmitting} />
    </FormProvider>
  )
}
