import {ReCaptchaScript} from '@/ui/recaptcha/types'

export function doWithRecaptcha<T>(
  enabled: boolean,
  recaptchaScript: ReCaptchaScript | undefined,
  actionName: string,
  action: (recaptchaToken: string | undefined) => Promise<T>
): Promise<T> {
  if (enabled && recaptchaScript) {
    return recaptchaScript.execute({action: actionName}).then(action)
  }
  return action(undefined)
}
