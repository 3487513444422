import {TimeAgoText} from '@/components/general'
import {MemberAvatar} from '@/components/members'
import {TableCell, TableRow, TableRowMenuButton} from '@/components/table'
import {Studio, TableRowAction} from '@/types/index'
import {studioUrlSuffix} from '@/utils/general'
import {RemoveCircleIcon} from '@sanity/icons'
import {Badge, Box, Text, Tooltip} from '@sanity/ui'
import Link from 'next/link'
import {RemoveStudioDialog} from './RemoveStudioDialog'
import {useConfirmDialog} from '@/components/dialog'
import {useCallback} from 'react'

interface Props {
  projectId: string
  studio: Studio
  isLast: boolean
}

const truncateString = (str: string, length = 7) => {
  if (str.length <= length) {
    return str
  }
  return str.slice(0, length) + '…'
}

const getUrlHostname = (url: string) => {
  try {
    const cleanUrl = new URL(url)
    return cleanUrl.hostname
  } catch (error) {
    // If url is invalid, return the url as is
    return url
  }
}

const studioUrl = (studio: Studio) => {
  let url = ''
  if (studio.urlType == 'internal') {
    url = `https://${studio.appHost}.${studioUrlSuffix}`
  } else {
    url = studio.appHost
  }

  const cleanUrl = getUrlHostname(url)
  const urlToDisplay = truncateString(cleanUrl, 30)
  const linkElement = (
    <Link href={`${url}`} target="_blank" rel="noreferrer noopener">
      <Text weight="semibold" size={[1, 1, 1, 2]} textOverflow="ellipsis">
        {urlToDisplay}
      </Text>
    </Link>
  )
  // if truncating, show tooltip with full url
  if (urlToDisplay !== cleanUrl) {
    return (
      <Tooltip
        content={
          <Text size={1} muted>
            {cleanUrl}
          </Text>
        }
        portal
        placement="top"
      >
        {linkElement}
      </Tooltip>
    )
  }

  return linkElement
}

const hostingBadge = (urlType: Studio['urlType']) => {
  if (urlType === 'internal') {
    return (
      <Badge padding={2} tone={'positive'}>
        Sanity
      </Badge>
    )
  }
  return (
    <Badge padding={2} tone={'caution'}>
      Other
    </Badge>
  )
}

const versionBadge = (studio: Studio) => {
  const {activeDeployment, urlType} = studio
  if (activeDeployment?.isAutoUpdating) {
    return (
      <Badge padding={2} style={{whiteSpace: 'nowrap'}}>
        Auto-updating
      </Badge>
    )
  } else if (activeDeployment?.version) {
    const truncated = truncateString(activeDeployment.version)
    if (truncated !== activeDeployment.version) {
      return (
        <Tooltip
          content={
            <Text size={1} muted>
              {activeDeployment.version}
            </Text>
          }
          portal
          placement="top"
        >
          <Badge padding={2}>{truncated}</Badge>
        </Tooltip>
      )
    }
    return <Badge padding={2}>{activeDeployment.version}</Badge>
  }

  const tooltipContent = (
    <Box padding={3} style={{maxWidth: 380}}>
      <Text size={1} muted>
        {!activeDeployment && urlType === 'internal'
          ? 'Additional information will populate upon next deployment with an updated CLI'
          : "We don't have information about versions and deployers for externally hosted studios"}
      </Text>
    </Box>
  )
  return (
    <Tooltip content={tooltipContent} portal placement="top">
      <Badge padding={2}>N/A</Badge>
    </Tooltip>
  )
}

const deployedBy = (userId?: string | null) => {
  if (userId) {
    return <MemberAvatar key={`member-${userId}`} userId={userId} size={1} tooltip />
  }
  return null
}

export function StudioItem({projectId, studio, isLast}: Props) {
  const {
    showDialog: showRemoveDialog,
    hideDialog: hideRemoveDialog,
    Dialog: RemoveDialog,
  } = useConfirmDialog()

  const handleRemoveStudio = useCallback(() => {
    showRemoveDialog()
  }, [showRemoveDialog])

  const rowActions: TableRowAction[] = [
    {
      title: 'Remove studio',
      icon: RemoveCircleIcon,
      tone: 'critical',
      permissions: [{permissionName: 'sanity.project', grantName: 'update'}],
      mode: 'bleed',
      onAction: handleRemoveStudio,
      dialog: (
        <RemoveStudioDialog
          key="remove-studio-dialog"
          Dialog={RemoveDialog}
          projectId={projectId}
          hideDialog={hideRemoveDialog}
          studio={studio}
        />
      ),
    },
  ]

  return (
    <>
      {rowActions.map((action) => typeof action !== 'string' && action.dialog)}
      <TableRow paddingY={4} paddingX={2} borderBottom={!isLast}>
        <TableCell>{studioUrl(studio)}</TableCell>
        <TableCell>
          <Text>
            <TimeAgoText
              size={1}
              date={studio.activeDeployment?.deployedAt || studio.updatedAt}
              muted
              options={{agoSuffix: true}}
            />
          </Text>
        </TableCell>
        <TableCell $align={'center'}>{hostingBadge(studio.urlType)}</TableCell>
        <TableCell $align={'center'}>{versionBadge(studio)}</TableCell>
        <TableCell $align={'center'}>{deployedBy(studio.activeDeployment?.deployedBy)}</TableCell>
        <TableCell $align={'right'}>
          {studio.urlType === 'external' ? (
            <TableRowMenuButton
              menuId={studio.id}
              label={`Remove ${studio.id}`}
              rowItem={studio}
              actions={rowActions}
              roleScope="project"
            />
          ) : null}
        </TableCell>
      </TableRow>
    </>
  )
}
