import React from 'react'
import {Card} from '@sanity/ui'
import {flatten} from 'lodash'
import {activityConfig} from '../activityConfig'
import {ActivityTileDefault} from './activityTileDefault'
import {ActivityTileCompact} from './activityTileCompact'
import {ActivityEvent} from '@/types/index'
import {useCurrentScopeContext} from '@/context/index'
import {getActivityMetadata, useAssociatedLink} from '@/utils/index'
import {useUser} from '@/data/users'

interface Props {
  event: ActivityEvent
  compact?: boolean
  disableFilter?: boolean
  borderBottom?: boolean
}

// Actions that should show a project or org avatar in the badge, instead of a member avatar
const profileImageActions = [
  'project.create',
  'project.organization.add',
  'project.organization.delete',
  'project.archive',
  'project.activate',
  'organization.create',
  'metadata.transferTo',
  'metadata.transferFrom',
  'organization.project.create',
]

export function ActivityTile({event, compact = false, disableFilter, borderBottom = true}: Props) {
  const {actorId, userId, action, description, timestamp, organizationId, projectId, datasetName} =
    event
  const config = activityConfig.get(action)
  const {mainIconName = 'ice-cream', secondaryIconName = 'add', showAvatar} = config || {}
  const scope = useCurrentScopeContext()
  const {orgs = []} = scope || {}

  const descriptionTemplate =
    typeof config?.descriptionTemplate === 'function'
      ? config.descriptionTemplate(event)
      : config?.descriptionTemplate || description

  const associatedLink = useAssociatedLink(event, scope)
  // Get the correct member object depending on if there was a user affected by the action or just an actor
  const actorMember = actorId === userId ? actorId : undefined
  const affectedMember = userId
  const invitedByMember = getActivityMetadata(event, 'invitedBy')

  const {data: user} = useUser(affectedMember || actorMember)

  const details = [
    {
      type: 'timestamp',
      value: timestamp,
    },
    {
      type: 'actorId',
      value: invitedByMember ? invitedByMember : actorId,
    },
    {
      type: 'organizationId',
      value: organizationId,
    },
    {
      type: 'projectId',
      value: projectId,
    },
    {
      type: 'dataset',
      value: datasetName,
    },
  ]

  // Determine whether to show a project or org avatar rather than a member avatar
  const isEntityAction = profileImageActions.includes(action)
  // Find the project that the event is about
  const eventProject = flatten(orgs.map((o) => o.projects)).find((p) => p?.id === projectId)

  const TileComponent = compact ? ActivityTileCompact : ActivityTileDefault

  return (
    <Card borderBottom={borderBottom}>
      <TileComponent
        details={details}
        secondaryIconName={secondaryIconName}
        mainIconName={mainIconName}
        showAvatar={showAvatar}
        projectId={projectId}
        isEntityAction={isEntityAction}
        user={user || undefined}
        descriptionTemplate={descriptionTemplate}
        event={event}
        associatedLink={associatedLink}
        eventProject={eventProject}
        disableFilter={disableFilter}
      />
    </Card>
  )
}
