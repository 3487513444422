import {useStudios} from '@/data/studios/useStudios'
import {DialogToast, Studio} from '@/types/index'
import {Card, useToast} from '@sanity/ui'
import {useCallback} from 'react'

interface Props {
  Dialog: React.ElementType
  hideDialog: (dialogToast: DialogToast) => void
  projectId: string
  studio: Studio
}

export const RemoveStudioDialog = (props: Props) => {
  const {projectId, Dialog, studio, hideDialog} = props
  const toast = useToast()
  const {deleteUserApplicationMutation} = useStudios(projectId)

  const handleRemoveStudio = useCallback(() => {
    deleteUserApplicationMutation.mutate(studio.id, {
      onSuccess: () => {
        hideDialog({
          description: 'Removed studio from project',
          status: 'success',
        })
      },
      onError: (error) => {
        toast.push({
          title: 'Failed to remove studio from project',
          description: error.message,
          status: 'error',
        })
      },
    })
  }, [deleteUserApplicationMutation, studio.id, hideDialog, toast])

  return (
    <Dialog
      id="remove-studio"
      header="Remove Studio"
      title="Do you really want to remove this Studio?"
      description="This action cannot be undone."
      onConfirm={handleRemoveStudio}
      loading={deleteUserApplicationMutation.isPending}
    >
      <Card border padding={2}>
        {studio.appHost}
      </Card>
    </Dialog>
  )
}
