import type {Customer} from '@/types/index'
import {CaseIcon, EditIcon} from '@sanity/icons'
import {Button, Card, Flex, Text} from '@sanity/ui'
import countryList from 'iso-3166-country-list'
import {upperFirst} from 'lodash'
import {useMemo} from 'react'

interface Props {
  customer: Customer
  onEdit: () => void
}

const countryCodeToName = (code: string) => {
  return countryList.find((country) => country.code === code)?.name
}

export function SavedAddress({customer, onEdit}: Props) {
  const countryName = useMemo(() => countryCodeToName(customer.country), [customer.country])
  return (
    <Card padding={3} radius={2} shadow={1} tone="primary" width="fill">
      <Flex justify="space-between" align="center" gap={4}>
        <Flex align="center" gap={4}>
          <Flex style={{width: '44px'}} justify="center">
            <CaseIcon fontSize={25} />
          </Flex>

          <Flex direction="column" gap={2}>
            <Text data-recording-mask size={1} weight="medium">
              {customer.address}
            </Text>
            <Text data-recording-mask size={1}>
              {customer.zip}, {upperFirst(customer.city)}
            </Text>
            <Text size={1}>{countryName}</Text>
          </Flex>
        </Flex>
        <Button icon={EditIcon} mode="bleed" padding={2} onClick={onEdit} tone="primary" />
      </Flex>
    </Card>
  )
}
