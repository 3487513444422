import {useToast} from '@sanity/ui'
import {MembersSettingsScreen, type MembersSettingsScreenProps} from '@ui-components'
import {useRouter} from 'next/router'
import {useQueryState} from 'nuqs'
import {useCallback, useMemo} from 'react'

import {useWizardStore} from '@/components/payment-wizard'
import {useCurrentScopeContext} from '@/context/useCurrentScopeContext'
import {useRequiredOrganizationPermissions} from '@/context/useOrganizationPermissions'
import {useRoutePath} from '@/context/useRoutePathContext'
import {useMembersSettings} from '@/data/members_v2'
import {useCurrentUser} from '@/data/users'
import {type TableViews} from '@/ui/ui-components/features/member-tables-view'

interface OrganizationMembersViewProps {
  description: string
  title: string
  referenceLinkComponent?: JSX.ElementType
}

const VIEWS: TableViews[] = ['members', 'invitations', 'requests']

export function OrganizationMembersView({
  description,
  title,
  referenceLinkComponent,
}: OrganizationMembersViewProps) {
  const router = useRouter()
  const [inspectedMemberId, setMemberIdToInspect] = useQueryState('memberToInspect')
  const canInviteMembers = useRequiredOrganizationPermissions([
    {permissionName: 'sanity.organization.members', grantName: 'invite'},
  ])
  const canDeleteMembers = useRequiredOrganizationPermissions([
    {permissionName: 'sanity.organization.members', grantName: 'delete'},
  ])
  const canUpdateMembers = useRequiredOrganizationPermissions([
    {permissionName: 'sanity.organization.members', grantName: 'update'},
  ])

  const currentUser = useCurrentUser()
  const ctx = useCurrentScopeContext()
  const {basePath} = useRoutePath()

  const toast = useToast()

  const wizardStore = useWizardStore()

  const inviteDialogOpen = wizardStore.state.inviteMembersWizard.open

  const handleInviteDialogClose = useCallback(() => {
    wizardStore.dispatch({type: 'inviteMembersWizard/close'})
  }, [wizardStore])

  const currentUserId = String(currentUser.data?.id)
  const resourceId = String(ctx.org?.id)

  const selectedView = useMemo((): TableViews => {
    const headingId = router.query.headingId

    if (VIEWS.find((view) => view === headingId)) {
      return headingId as TableViews
    }

    return 'members'
  }, [router.query.headingId])

  const value = useMembersSettings({
    currentUserId,
    resourceId,
    resourceType: 'organization',
    onMutationError: (error) => {
      const message = error.message || 'Something went wrong'

      toast.push({
        id: message,
        status: 'error',
        title: message,
      })
    },
    onMutationSuccess: (message) => {
      toast.push({
        status: 'success',
        title: message,
      })
    },
  })

  const error = value.error
  const loading = value.loading

  const projects = ctx.org?.projects || []

  const disabledFeatures: MembersSettingsScreenProps['disabledFeatures'] = {
    addMembers: {
      isDisabled: !canInviteMembers,
      reason: 'You are not allowed to invite members to this organization',
    },
    editMembers: {
      isDisabled: !canUpdateMembers,
      reason: 'You are not allowed to update members in this organization',
    },
    removeMembers: {
      isDisabled: !canDeleteMembers,
      reason: 'You are not allowed to remove members from this organization',
    },
  }

  const resourcesQuota: MembersSettingsScreenProps['resourcesQuota'] = {
    members: {
      total: value.data.members.length,
    },
  }

  return (
    <MembersSettingsScreen
      basePath={basePath}
      description={description}
      disabledFeatures={disabledFeatures}
      error={error}
      inspectedMemberId={inspectedMemberId}
      invitations={value.data.invitations}
      inviteDialogOpen={inviteDialogOpen}
      loading={loading}
      members={value.data.members}
      onInspectMember={setMemberIdToInspect}
      onInvitationRevoke={value.operations.onInvitationRevoke}
      onInviteDialogClose={handleInviteDialogClose}
      onInviteMembers={value.operations.onInviteMembers}
      onMemberRemove={value.operations.onResourceMembershipRemove}
      onRequestApprove={value.operations.onRequestApprove}
      onRequestRevoke={value.operations.onRequestRevoke}
      onResourceMembershipAdd={value.operations.onResourceMembershipAdd}
      onResourceMembershipRemove={value.operations.onResourceMembershipRemove}
      onResourceRoleAdd={value.operations.onResourceRoleAdd}
      onResourceRoleRemove={value.operations.onResourceRoleRemove}
      onRetryFetch={value.refetch}
      projects={projects}
      requests={value.data.requests}
      resourceId={resourceId}
      resourcesQuota={resourcesQuota}
      resourceType="organization"
      roles={value.data.roles}
      selectedView={selectedView}
      title={title}
      referenceLinkComponent={referenceLinkComponent}
    />
  )
}
