import React, {useState, useCallback, forwardRef, ReactNode, useRef, useMemo} from 'react'
import {
  Button,
  Card,
  Menu,
  MenuItem,
  Popover,
  Flex,
  Stack,
  Inline,
  Text,
  Box,
  useGlobalKeyDown,
  ButtonMode,
} from '@sanity/ui'
import {CheckmarkIcon, ChevronDownIcon, IconComponent} from '@sanity/icons'
import {useVirtualizer} from '@tanstack/react-virtual'
import styled from 'styled-components'
import {find} from 'lodash'

function getOptionTitle(option: MenuMultiSelectOption) {
  return find([option.title, option.name], Boolean)
}

export type MenuMultiSelectOption = {
  name: string
  title: string
  description: string
}

function defaultOption(option: MenuMultiSelectOption) {
  return (
    <Stack space={2} flex={1}>
      <Inline space={2}>
        <Text weight="semibold">{option.title}</Text>
        <Text muted size={1}>
          {option.name}
        </Text>
      </Inline>
      {option.description && (
        <Text muted size={1}>
          {option.description}
        </Text>
      )}
    </Stack>
  )
}

type Props = {
  constrainSize?: boolean
  id: string
  disabled?: boolean
  isOptionDisabled?: (item: MenuMultiSelectOption) => boolean
  values: string[]
  options: MenuMultiSelectOption[]
  onChange?: (value: string) => void
  loading?: boolean
  noValue?: ReactNode
  text?: ReactNode
  icon?: IconComponent
  banner?: JSX.Element
  optionRender?: (_option: MenuMultiSelectOption) => ReactNode
  optionPaddingX?: number | number[]
  optionPaddingY?: number | number[]
  tone?: 'critical' | 'default' | undefined
  mode?: ButtonMode | undefined
  displayMax?: number
}

type GetButtonTextOptions = {
  values: string[]
  options: MenuMultiSelectOption[]
  noValue?: ReactNode
  text?: ReactNode
  displayMax?: number
}

function getButtonText({
  values,
  options,
  noValue,
  text,
  displayMax,
}: GetButtonTextOptions): ReactNode {
  if (text && typeof text === 'string' && text.length > 0) {
    return text
  }

  const titles = options
    .filter((option) => values.includes(option.name))
    .map((option) => getOptionTitle(option))
    .filter(Boolean)

  if (titles.length === 0) {
    return noValue || ''
  }

  if (displayMax && titles.length > displayMax) {
    const extra = titles.length - displayMax
    return `${titles.slice(0, displayMax).join(', ')} + ${extra} more`
  }

  return titles.join(', ')
}

const VirtualListWrapper = styled.div.attrs<{$height: number}>((props) => ({
  style: {
    height: `${props.$height}px`,
  },
}))`
  width: 100%;
  position: relative;
`

const VirtualListInner = styled.div.attrs<{$translateY: number}>((props) => ({
  style: {
    transform: `translateY(${props.$translateY}px)`,
  },
}))`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`

export const MenuMultiSelect = forwardRef(function MenuMultiSelect(
  {
    constrainSize = true,
    id,
    values,
    options,
    noValue,
    onChange,
    disabled = false,
    isOptionDisabled,
    loading = false,
    icon = ChevronDownIcon,
    text,
    optionRender = defaultOption,
    optionPaddingX,
    optionPaddingY,
    banner,
    tone = 'default',
    mode = 'bleed',
    displayMax = 2,
  }: Props,
  ref: React.ForwardedRef<HTMLButtonElement | null>
) {
  const [open, setOpen] = useState(false)
  const [shouldFocus, setShouldFocus] = useState<'first' | 'last' | null>(null)
  const buttonText = useMemo(
    () => getButtonText({values, options, noValue, text, displayMax}),
    [values, options, noValue, text, displayMax]
  )
  const buttonRef = useRef<HTMLButtonElement | null>(null)

  const handleButtonClick = useCallback(() => {
    setOpen(!open)
    setShouldFocus(null)
  }, [setOpen, open])

  const handleClose = useCallback(
    (e) => {
      // Check if we are clicking on the button and prevent trigger the callback
      // to ensure the window doesn't reopen
      // as unknown as HTMLButtonElement
      if (buttonRef.current && buttonRef.current.contains(e.target)) {
        return
      }
      setOpen(false)
    },
    [setOpen]
  )

  const handleSelectInternal = useCallback(
    (option: MenuMultiSelectOption) => {
      if (onChange) {
        onChange(option.name)
      }
    },
    [onChange]
  )

  const setButtonRef = useCallback(
    (el: HTMLButtonElement | null) => {
      if (buttonRef.current === el) return

      if (typeof ref === 'function') {
        ref(el)
      } else if (ref) {
        ref.current = el
      }
      buttonRef.current = el
    },
    [ref]
  )

  const handleButtonKeyDown = useCallback((event: React.KeyboardEvent<HTMLButtonElement>) => {
    // On `ArrowDown`, `Enter` and `Space`
    // - Opens menu and moves focus to first menuitem
    if (event.key === 'ArrowDown' || event.key === 'Enter' || event.key === ' ') {
      event.preventDefault()
      setOpen(true)
      setShouldFocus('first')
      return
    }

    // On `ArrowUp`
    // - 	Opens menu and moves focus to last menuitem
    if (event.key === 'ArrowUp') {
      event.preventDefault()
      setOpen(true)
      setShouldFocus('last')
    }
  }, [])

  const isMenuItemDisabled = useCallback(
    (option: MenuMultiSelectOption) => {
      if (isOptionDisabled) {
        return isOptionDisabled(option)
      }
      return false
    },
    [isOptionDisabled]
  )

  useGlobalKeyDown((event) => {
    if (event.key === 'Escape' && open) {
      setOpen(false)
    }
  })

  const menuRef = useRef<HTMLDivElement | null>(null)

  const virtualizer = useVirtualizer({
    count: options.length,
    getScrollElement: useCallback(() => menuRef.current, [menuRef]),
    estimateSize: useCallback(() => 55, []),
    isScrollingResetDelay: 500,
    enabled: open,
  })

  const items = virtualizer.getVirtualItems()

  const popoverContent = useMemo(
    () => (
      <Menu
        style={{minWidth: 350, minHeight: 250, maxWidth: 400, maxHeight: 400, overflow: 'auto'}}
        disabled={loading || disabled}
        space={1}
        onClickOutside={handleClose}
        aria-multi-selectable
        aria-labelledby={id}
        shouldFocus={shouldFocus}
        ref={menuRef}
      >
        {banner}
        <VirtualListWrapper $height={virtualizer.getTotalSize()}>
          <VirtualListInner $translateY={items[0]?.start ?? 0}>
            {items.map((virtualItem) => {
              const option = options[virtualItem.index]
              const selected = values.includes(option.name)
              const Option = optionRender(option)
              return (
                <MenuItem
                  key={`${option.name}-${virtualItem.key}`}
                  onClick={() => handleSelectInternal(option)}
                  tone={selected ? 'primary' : 'default'}
                  id={option.name}
                  paddingX={optionPaddingX}
                  paddingY={optionPaddingY}
                  role="option"
                  aria-selected={selected}
                  disabled={isMenuItemDisabled(option)}
                  ref={virtualizer.measureElement}
                  data-index={virtualItem.index}
                >
                  <Flex align="center" justify="space-between">
                    {Option}
                    {selected && (
                      <Box marginLeft={3} paddingRight={1}>
                        <Text>
                          <CheckmarkIcon />
                        </Text>
                      </Box>
                    )}
                  </Flex>
                </MenuItem>
              )
            })}
          </VirtualListInner>
        </VirtualListWrapper>
      </Menu>
    ),
    [
      loading,
      disabled,
      handleClose,
      id,
      shouldFocus,
      banner,
      virtualizer,
      items,
      options,
      values,
      optionRender,
      optionPaddingX,
      optionPaddingY,
      isMenuItemDisabled,
      handleSelectInternal,
    ]
  )

  return (
    <Card tone={tone}>
      <Popover
        constrainSize={constrainSize}
        open={open}
        portal
        placement="bottom-start"
        /**
         * This is a workaround to make the popover menu be bound to the dialog backdrop,
         * instead of the nearest parent
         */
        floatingBoundary={
          (document.querySelector("[data-portal]>[aria-modal='true']") as HTMLElement) || undefined
        }
        content={popoverContent}
      >
        <Button
          ref={setButtonRef}
          text={buttonText}
          mode={mode}
          tone={tone}
          iconRight={icon}
          disabled={disabled}
          style={{cursor: disabled ? 'not-allowed' : 'default', width: '100%'}}
          loading={loading}
          fontSize={1}
          paddingX={2}
          onClick={handleButtonClick}
          aria-haspopup
          aria-expanded={open}
          selected={open}
          onKeyDown={handleButtonKeyDown}
        />
      </Popover>
    </Card>
  )
})
