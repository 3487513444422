import {Label, type LabelProps} from '@sanity/ui'
import {forwardRef} from 'react'

import {CommonCell, type CommonCellProps} from './CommonCell'

export interface TableHeadProps extends Omit<CommonCellProps, 'as'> {
  size?: LabelProps['size']
  title?: string
}

export const TableHead = forwardRef(function TableHead(
  props: TableHeadProps &
    Omit<React.HTMLProps<HTMLTableCellElement>, 'as' | 'children' | 'height' | 'ref'>,
  ref: React.Ref<HTMLTableCellElement>
) {
  const {children, size, cellFlex, title, cellMinWidth, cellMaxWidth, ...rest} = props

  return (
    <CommonCell
      as="th"
      cellFlex={cellFlex}
      cellMaxWidth={cellMaxWidth}
      cellMinWidth={cellMinWidth}
      ref={ref}
      {...rest}
    >
      {children}

      {title && (
        <Label textOverflow="ellipsis" align="left" size={size}>
          {title}
        </Label>
      )}
    </CommonCell>
  )
})
