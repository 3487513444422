import React, {createElement, forwardRef} from 'react'
import {default as drawings, IllustrationDrawing} from './drawings'

type Props = {
  drawing: IllustrationDrawing
}

export const Illustration = forwardRef(
  (props: Props & Omit<React.SVGProps<SVGSVGElement>, 'ref'>, ref: React.Ref<SVGSVGElement>) => {
    const {drawing, ...restProps} = props

    if (!drawings[drawing]) {
      return null
    }

    return createElement(drawings[drawing], {...restProps, ref} as Props &
      React.SVGProps<SVGSVGElement>)
  }
)

Illustration.displayName = 'Illustration'
