import React, {useEffect, useCallback} from 'react'
import {Text, Grid, Stack, Card, Label, useToast} from '@sanity/ui'
import {useConfirmDialog} from '@/ui/index'
import {Project, Tag} from '@/types/index'
import {useDeleteTag} from '@/data/index'

interface Props {
  project: Project
  tag: Tag
  closeDialog: () => void
  onDelete: () => void
}

export function DeleteTagDialog(props: Props) {
  const {tag, project, closeDialog, onDelete} = props

  const toast = useToast()
  const {Dialog} = useConfirmDialog({open: true})
  const {isPending, error, mutate: deleteTag} = useDeleteTag(project.id)

  useEffect(() => {
    if (error) {
      toast.push({title: error.name, description: error.message, status: 'error'})
    }
  }, [error, toast])

  const onSubmit = useCallback(() => {
    deleteTag(tag.name, {
      onSuccess: () => {
        onDelete()
      },
    })
  }, [deleteTag, tag.name, onDelete])

  return (
    <Dialog
      id="delete-tag"
      header="Delete tag"
      title="Do you really want to delete this tag?"
      description="This action cannot be undone."
      onClose={closeDialog}
      onConfirm={onSubmit}
      confirmButtonProps={{text: 'Delete'}}
      loading={isPending}
      disabled={(tag.datasets?.length || 0) > 0}
    >
      <Stack space={4}>
        <Card border radius={2} padding={1} paddingTop={2} paddingBottom={2}>
          <Grid columns={1} gap={1}>
            <Card padding={3}>
              <Stack space={3}>
                <Label muted size={0}>
                  Title
                </Label>
                <Text>{tag.title}</Text>
              </Stack>
            </Card>
            <Card padding={3}>
              <Stack space={3}>
                <Label muted size={0}>
                  Identifier
                </Label>
                <Text>{tag.name}</Text>
              </Stack>
            </Card>
            <Card padding={3}>
              <Stack space={3}>
                <Label muted size={0}>
                  Description
                </Label>
                <Text>{tag.description || <em>No description</em>}</Text>
              </Stack>
            </Card>
          </Grid>
        </Card>
      </Stack>
    </Dialog>
  )
}
