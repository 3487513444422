import {UseFormRegister, useWatch} from 'react-hook-form'
import {DOWNGRADE_FOLLOWUP_PLACEHOLDERS, DowngradeSurveyFormData} from '../types'
import {Box, Grid, Radio, Text, TextArea} from '@sanity/ui'
import {motion, AnimatePresence} from 'framer-motion'

type Props = {
  value: string
  label: string
  register: UseFormRegister<DowngradeSurveyFormData>
  textSize?: number
}

export const DowngradeRadioInput = ({value, label, register, textSize}: Props) => {
  const reasonValue = useWatch({name: 'reason'})
  const reasonFollowup = DOWNGRADE_FOLLOWUP_PLACEHOLDERS[value]
  const showFollowup = reasonValue === value && reasonFollowup

  return (
    <Grid
      gapX={3}
      as="label"
      htmlFor={value}
      paddingRight={4}
      style={{gridTemplateColumns: 'auto 1fr', alignItems: 'center'}}
    >
      <Radio value={value} id={value} {...register('reason')} />
      <Text as="p" size={textSize}>
        {label}
      </Text>

      <AnimatePresence initial={false}>
        {showFollowup && (
          <motion.div
            initial={{opacity: 0, height: 0}}
            animate={{opacity: 1, height: 'auto'}}
            exit={{opacity: 0, height: 0}}
            transition={{duration: 0.25}}
            style={{gridColumn: '2 / span 1', overflow: 'hidden'}}
          >
            <Box paddingTop={3}>
              <TextArea
                autoFocus
                placeholder={reasonFollowup}
                fontSize={textSize}
                {...register('comment')}
              />
            </Box>
          </motion.div>
        )}
      </AnimatePresence>
    </Grid>
  )
}
