import React from 'react'

export const Mastercard = () => {
  return (
    <svg width="35" height="22" viewBox="0 0 35 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.231 2.687h-9.463v16.905h9.463V2.687z" fill="#FF5F00" />
      <path
        d="M13.37 11.14c0-3.435 1.621-6.482 4.115-8.453a10.789 10.789 0 00-6.67-2.3C4.838.387 0 5.196 0 11.14c0 5.944 4.837 10.753 10.816 10.753 2.523 0 4.836-.866 6.669-2.3a10.713 10.713 0 01-4.116-8.453z"
        fill="#EB001B"
      />
      <path
        d="M35 11.14c0 5.943-4.836 10.752-10.815 10.752-2.523 0-4.837-.866-6.67-2.3a10.669 10.669 0 004.116-8.453c0-3.434-1.622-6.48-4.115-8.452a10.789 10.789 0 016.67-2.3C30.163.387 35 5.226 35 11.14z"
        fill="#F79E1B"
      />
    </svg>
  )
}
