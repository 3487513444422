export function getUserOnboardingBaseCacheKey() {
  return ['user', 'onboarding']
}
export function getUserOnboardingDataCacheKey(userId?: string) {
  return ['user', 'onboarding', userId]
}

export function getProjectOnboardingDataCacheKey(projectId?: string) {
  return ['user', 'onboarding', 'project', projectId]
}
