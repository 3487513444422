/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-unused-prop-types */
import React, {useCallback, useMemo, useState} from 'react'
import {Card, Text, useTheme} from '@sanity/ui'
import {BillIcon} from '@sanity/icons'
import {format, isSameYear} from 'date-fns'
import {hues} from '@sanity/color'
import {ChartSummaryItem, ChartSummaryList} from './chartSummaryList'
import {ProjectUsage, OrganizationCostSummary} from '@/types/index'
import {useCurrentScopeContext, useRequiredOrganizationPermissions} from '@/context/index'
import {useProjectsUsage, useOrganizationCostSummary} from '@/data/index'
import {ColumnChart, ChartSectionHeader, ChartContextualAction} from '@/ui/index'
import {useUsageContext, useRoutePath} from '@/context/index'
import {
  getStringColor,
  getCompareSeriesForChartSection,
  getSeriesForChartSection,
  formatUnitType,
  useScrollMarginTop,
} from '@/utils/index'

const currentComparePeriodColor = hues.blue[500].hex
const previousComparePeriodColor = hues.gray[200].hex

interface ChartSectionProps {
  id: string
  title?: string
  description?: string
  isPlayground?: boolean
}

function getSummaryHeaderData(id: string, summary: OrganizationCostSummary | null) {
  const resource = summary?.resources?.[id.toLowerCase()]
  if (!resource) {
    return {quota: 0, quotaFormatted: '', usage: 0, usageFormatted: '', hasOverage: false}
  }

  const {quota, usage, unit} = resource
  return {
    quota: quota,
    quotaFormatted: formatUnitType(quota || 0, unit, quota !== null),
    usage: usage,
    usageFormatted: formatUnitType(usage || 0, unit, false),
    hasOverage: usage > quota,
  }
}

const yaxisFormatMap: Record<
  string,
  'number' | 'size' | 'gigaBytes' | 'bytesPrecise' | 'simpleNumber'
> = {
  bandwidth: 'gigaBytes',
  assets: 'gigaBytes',
  datasets: 'simpleNumber',
}

export function ChartSection({title, id, isPlayground = false}: ChartSectionProps) {
  const {sanity} = useTheme()
  const scrollMargin = useScrollMarginTop()
  const resourcechartIds = ['assets', 'documents', 'datasets']
  const isResourceChart = resourcechartIds.includes(id)
  const curveStroke = 'smooth'
  const {currentPeriod, currentRange, compareRange} = useUsageContext()

  const {org} = useCurrentScopeContext()
  const projects = isPlayground
    ? (org?.projects || []).filter(
        (project) => org?.organizationPlan?.projectIds.includes(project.id) === false
      )
    : (org?.projects || []).filter(
        (project) =>
          !org?.organizationPlan || org?.organizationPlan?.projectIds.includes(project.id)
      )
  const projectUsages = useProjectsUsage(projects, {
    ...currentRange,
    period: currentPeriod,
  })
  const compareUsages = useProjectsUsage(projects, {
    ...compareRange,
    period: currentPeriod,
  })
  const summary = useOrganizationCostSummary(org, projects, {
    ...currentRange,
    period: currentPeriod,
  })

  const isOrgPlan = org?.organizationPlan !== undefined
  const isLoading =
    projectUsages.some((usage) => usage && usage.isLoading) ||
    compareUsages.some((usage) => usage && usage.isLoading) ||
    summary.isLoading

  const [activeDataSeriesName, setActiveDataSeriesName] = useState<string | undefined>()
  const [mode, setMode] = useState('daily')
  const {orgPath} = useRoutePath()

  const canManageBilling = useRequiredOrganizationPermissions([
    {permissionName: 'sanity.organization', grantName: 'billing'},
  ])

  const actions: ChartContextualAction[] = [
    {
      title: 'View associated costs',
      icon: BillIcon,
      tone: 'default',
      mode: 'bleed',
      href: `${orgPath}/billing`,
      hasPermission: canManageBilling,
    },
  ]

  const onChangeMode = useCallback(
    (newMode: string) => {
      setMode(newMode)
    },
    [setMode]
  )

  const handleHoverSummaryItem = useCallback(
    (hoveredId: string | undefined, summaryItem: ChartSummaryItem) => {
      setActiveDataSeriesName(hoveredId ? summaryItem.label : undefined)
    },
    [setActiveDataSeriesName]
  )

  const isYearPeriod = currentPeriod?.history === 'month'
  const cumulative = !isYearPeriod && mode === 'runningTotal'
  const isCompareMode = typeof compareRange?.toDate !== 'undefined'
  const isDifferentCompareYear =
    currentRange?.fromDate &&
    compareRange?.toDate &&
    !isSameYear(currentRange?.fromDate, compareRange?.toDate)
  const seriesPerDay = useMemo(
    () =>
      compareRange?.toDate
        ? getCompareSeriesForChartSection({
            id,
            projects: projectUsages
              .map(({data}) => data)
              .filter((projectUsage) => projectUsage !== undefined) as ProjectUsage[],
            compareProjects: compareUsages
              .map(({data}) => data)
              .filter((projectUsage) => projectUsage !== undefined) as ProjectUsage[],
            cumulative: false,
            currentRange,
            compareRange,
            mode: currentPeriod?.history,
          })
        : getSeriesForChartSection({
            id,
            projects: projectUsages
              .map(({data}) => data)
              .filter((projectUsage) => projectUsage !== undefined) as ProjectUsage[],
            cumulative: false,
            currentRange,
            mode: currentPeriod?.history,
          }),
    [compareRange, id, projectUsages, compareUsages, currentRange, currentPeriod?.history]
  )
  const seriesAccumulated = useMemo(
    () =>
      compareRange?.toDate
        ? getCompareSeriesForChartSection({
            id,
            projects: projectUsages
              .map(({data}) => data)
              .filter((projectUsage) => projectUsage !== undefined) as ProjectUsage[],
            compareProjects: compareUsages
              .map(({data}) => data)
              .filter((projectUsage) => projectUsage !== undefined) as ProjectUsage[],
            cumulative: true,
            currentRange,
            compareRange,
            mode: currentPeriod?.history,
          })
        : getSeriesForChartSection({
            id,
            projects: projectUsages

              .map(({data}) => data)
              .filter((projectUsage) => projectUsage !== undefined) as ProjectUsage[],
            cumulative: true,
            currentRange,
            mode: currentPeriod?.history,
          }),
    [compareRange, id, projectUsages, compareUsages, currentRange, currentPeriod?.history]
  )
  const series = cumulative ? seriesAccumulated : seriesPerDay
  const {
    usageFormatted: summaryUsageFormatted,
    quota: summaryQuota,
    quotaFormatted: summaryQuotaFormatted,
    hasOverage: summaryHasOverage,
  } = getSummaryHeaderData(id, summary.data)

  const {compareTotal, compareUnit, usageTotal} = useMemo(() => {
    let cTotal = 0
    let uTotal = 0
    let unit = 'number'
    compareUsages.forEach(({data: project}) => {
      if (!project) {
        return
      }
      const resource = project.resources?.[id] || project.resources?.[id.toLowerCase()]
      cTotal += resource?.usage || 0
      unit = resource?.unit
    })
    projectUsages.forEach(({data: project}) => {
      if (!project) {
        return
      }
      const resource = project.resources?.[id] || project.resources?.[id.toLowerCase()]
      uTotal += resource?.usage || 0
    })
    return {compareTotal: cTotal, usageTotal: uTotal, compareUnit: unit}
  }, [projectUsages, compareUsages, id])

  const summaryList: ChartSummaryItem[] = useMemo(() => {
    const list: ChartSummaryItem[] = []

    if (isLoading) {
      return [
        {
          id: 'Placeholder',
          value: 0,
          unit: 'number',
          label: 'Placeholder',
        },
      ]
    }

    if (compareRange?.toDate && currentRange?.toDate) {
      let periodSummaryValue = 0
      let comparePeriodSummaryValue = 0
      let periodItem: ChartSummaryItem | null = null
      let comparePeriodItem: ChartSummaryItem | null = null
      const periodDateKey = format(currentRange?.toDate, 'MMMM yyyy')
      const comparePeriodDateKey = format(compareRange?.toDate, 'MMMM yyyy')

      projectUsages.forEach(({data: project}) => {
        if (!project) {
          return
        }
        const resource = project.resources?.[id] || project.resources?.[id.toLowerCase()]
        periodSummaryValue = periodSummaryValue + resource?.usage || 0

        if (!periodItem) {
          periodItem = {
            color: currentComparePeriodColor,
            id: `${id}-${periodDateKey}`,
            value: 0,
            unit: resource?.unit || 'number',
            label: periodDateKey,
            sortOrder: 1,
          }
        }

        periodItem.value = periodSummaryValue
      })

      compareUsages.forEach(({data: project}) => {
        if (!project) {
          return
        }
        const resource = project.resources?.[id] || project.resources?.[id.toLowerCase()]
        comparePeriodSummaryValue = comparePeriodSummaryValue + resource?.usage || 0

        if (!comparePeriodItem) {
          comparePeriodItem = {
            color: previousComparePeriodColor,
            id: `${id}-${comparePeriodDateKey}`,
            value: 0,
            unit: resource?.unit || 'number',
            label: comparePeriodDateKey,
            sortOrder: 2,
          }
        }

        comparePeriodItem.value = comparePeriodSummaryValue
      })

      if (comparePeriodItem) {
        list.push(comparePeriodItem)
      }
      if (periodItem) {
        list.push(periodItem)
      }

      return list
    }

    projectUsages.forEach(({data: project}) => {
      if (!project) {
        return
      }
      const resource = project.resources?.[id] || project.resources?.[id.toLowerCase()]

      list.push({
        color: getStringColor(project.projectId) || 'gray',
        id: project.projectId,
        value: resource?.usage || 0,
        unit: resource?.unit || 'number',
        label: project.label,
      })
    })

    return list
  }, [id, isLoading, projectUsages, compareUsages, compareRange?.toDate, currentRange?.toDate])

  const description = (
    <>
      <Text as="span" size={2} color={summaryHasOverage ? sanity.color.spot.green : undefined}>
        {summaryUsageFormatted || 'Placeholder'}
      </Text>
      {isOrgPlan && currentPeriod?.history === 'day' && (
        <Text as="span" size={1} muted>
          &nbsp;/ {summaryQuotaFormatted}
        </Text>
      )}
    </>
  )

  return (
    <Card
      padding={4}
      radius={2}
      id={id.toLowerCase()}
      border
      style={{scrollMarginTop: scrollMargin}}
    >
      <ChartSectionHeader
        actions={actions}
        title={title}
        description={description}
        showCumulativeToggle={!isResourceChart}
        onChangeMode={isYearPeriod ? undefined : onChangeMode}
        id={id}
        loading={isLoading}
        compareValue={{usage: usageTotal, compare: compareTotal, unit: compareUnit}}
      />
      <ColumnChart
        dataSeries={series}
        curveStroke={curveStroke}
        stacked={!compareRange?.toDate}
        chartType="bar"
        isLoading={isLoading}
        showQuotaAnnotation={isOrgPlan && cumulative && !isYearPeriod}
        currentPlanQuota={summaryQuota || 0}
        isCumulative={cumulative}
        isCompareMode={isCompareMode}
        activeDataSeriesName={activeDataSeriesName}
        formatDataPointLabelAsISODate={isCompareMode}
        isDifferentCompareYear={isDifferentCompareYear}
        period={currentPeriod?.history}
        yAxisFormat={yaxisFormatMap?.[id] || 'number'}
      />
      {/* currentPlanQuota={summaryQuota || 0} */}
      <ChartSummaryList
        items={summaryList}
        loading={isLoading}
        onHover={handleHoverSummaryItem}
        showZeroValues={isCompareMode}
      />
      {/* <pre>{JSON.stringify(series, null, 2)}</pre> */}
    </Card>
  )
}
