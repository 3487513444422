import React, {memo, useCallback, useState} from 'react'
import {
  Card,
  Flex,
  Box,
  Button,
  Stack,
  Text,
  Inline,
  AvatarStack,
  Label,
  Grid,
  Badge,
  Tooltip,
  useTheme,
} from '@sanity/ui'
import {ChevronDownIcon, ChevronUpIcon} from '@sanity/icons'
import styled from 'styled-components'
import {
  TableCell,
  MemberAvatar,
  TableRow,
  TableRowMenuButton,
  MobileRowMenu,
  ProjectPlanBadge,
  ProjectUsageStatusTooltip,
} from '@/ui/index'
import {Project, TableRowAction} from '@/types/index'
import {useProjectSubscription} from '@/data/projects/useProjectSubscription'
import {useMatchesMedia, useTimeAgo} from '@/utils/index'
import {AppLink} from '@/ui/app'
import {useRoutePath} from '@/context/index'
import {ProjectIcon} from '../../project/projectIcon'

type Props = {
  project: Project
  bulkSelect?: React.ReactNode
  selected?: boolean
  rowActions?: TableRowAction[] | undefined
}

const TitleButton = styled(Button)`
  overflow: hidden;
  & [data-ui='Text'] {
    color: ${({theme}) => theme.sanity.color.base.fg};
    font-weight: ${({theme}) => theme.sanity.fonts.heading.weights.regular};
    text-overflow: ellipsis;
  }

  &:not([data-disabled='true']):hover {
    --card-bg-color: 'none';
    --card-border-color: 'none';
  }
`

const getPluralForm = (word: string, count: number) => {
  return count === 1 ? word : `${word}s`
}

export const ProjectItemLarge = memo(function ProjectItemLarge({
  selected,
  project,
  bulkSelect,
  rowActions,
}: Props) {
  const [showDetails, setShowDetails] = useState<boolean>(false)
  const members = project.members.filter((m) => !m.isRobot) || []
  const {data: subscription} = useProjectSubscription(project.id)
  const plan = subscription?.plan
  const isMobile = useMatchesMedia(1)
  const {basePath} = useRoutePath()

  const handleToggleDetails = useCallback(() => {
    if (!isMobile) {
      return
    }

    setShowDetails((prevShowDetails) => !prevShowDetails)
  }, [isMobile])

  const {sanity} = useTheme()
  const textColor = sanity.color.base.fg

  const currentUser = members.find((member) => member.isCurrentUser)

  const roles = currentUser?.roles ? currentUser?.roles.length : 0
  const isMember = roles > 0

  const details = [
    {
      title: `Your ${getPluralForm('role', roles)}`,
      value: (
        <Inline>
          {isMember === true && <Text size={2}>{currentUser?.roles[0].title}</Text>}
          {isMember === false && (
            <Box marginTop={1}>
              <Text size={2}>Not a member</Text>
            </Box>
          )}
          {roles > 1 && (
            <Tooltip
              content={
                <Stack padding={2}>
                  <Text size={1}>
                    {currentUser?.roles
                      .slice(1)
                      .map((role) => role.title)
                      .join(', ')}
                  </Text>
                </Stack>
              }
            >
              <Badge marginLeft={2} padding={2} size={1} mode="outline">
                +{roles - 1}
              </Badge>
            </Tooltip>
          )}
        </Inline>
      ),
    },
    {
      title: 'Created',
      value: <Box>{useTimeAgo(project.createdAt || '', {agoSuffix: true})}</Box>,
    },
    // {title: 'Created by', value: 'someone'},
  ]

  const projectHref = `${basePath}/project/${project.id}`

  const memberAvatars = (
    <Box marginTop={1}>
      <Flex align="center">
        <AppLink href={`${projectHref}/members`}>
          <Button
            tabIndex={-1}
            mode="bleed"
            paddingY={2}
            paddingLeft={1}
            paddingRight={2}
            radius={6}
            text={
              <Flex align="center">
                <AvatarStack maxLength={4}>
                  {members.map((member) => (
                    <MemberAvatar key={member.id} userId={member.id} />
                  ))}
                </AvatarStack>
                <Box marginLeft={2}>
                  <Text size={1} style={{color: textColor}}>{`${members.length} ${
                    members.length === 1 ? 'member' : 'members'
                  }`}</Text>
                </Box>
              </Flex>
            }
          />
        </AppLink>
      </Flex>
    </Box>
  )

  return (
    <TableRow
      selected={selected}
      radius={[2]}
      shadow={1}
      marginBottom={[3, 3, 4]}
      borderBottom={false}
      padding={1}
      onClick={handleToggleDetails}
    >
      {bulkSelect && <TableCell>{bulkSelect}</TableCell>}
      <TableCell>
        <Flex direction="column">
          <Flex align="center">
            <Box marginRight={[1, 1, 3]}>
              <AppLink href={projectHref}>
                <Button
                  as="span"
                  mode="bleed"
                  radius={3}
                  paddingX={2}
                  text={<ProjectIcon project={project} size={3} />}
                />
              </AppLink>
            </Box>
            <Stack space={1}>
              <AppLink href={projectHref}>
                <TitleButton tabIndex={-1} text={project.displayName} mode="bleed" paddingX={0} />
              </AppLink>

              {plan && (
                <Inline>
                  <ProjectPlanBadge project={project} subscription={subscription} />
                </Inline>
              )}
              {!isMobile && memberAvatars}
            </Stack>
          </Flex>
          {isMobile && memberAvatars}
        </Flex>
      </TableCell>
      <TableCell
        $align="right"
        flexProps={{direction: 'column', justify: 'space-between', align: 'flex-end'}}
      >
        <Box>
          {!isMobile && (
            <Inline space={2}>
              <Text>
                <ProjectUsageStatusTooltip project={project} />
              </Text>
              <TableRowMenuButton
                rowItem={project}
                menuId={project.id}
                label={`Edit ${project.displayName}`}
                actions={rowActions}
                roleScope="org"
              />
            </Inline>
          )}
        </Box>
        <Flex align="center">
          {/* tags, badges or collections here */}
          {isMobile && (
            <Box marginRight={2}>
              <Text>
                <ProjectUsageStatusTooltip project={project} />
              </Text>
            </Box>
          )}
          <Button
            label="Show more"
            icon={showDetails ? ChevronUpIcon : ChevronDownIcon}
            mode={selected ? 'default' : 'bleed'}
            onClick={handleToggleDetails}
            tone={selected ? 'primary' : 'default'}
          />
        </Flex>
      </TableCell>
      {showDetails && (
        <TableCell style={{gridColumn: '1/-1'}} flexProps={{direction: 'column', align: 'stretch'}}>
          <Card borderTop flex={1} paddingTop={2}>
            <Grid columns={[2, 2, 3]} gap={1} style={{alignItems: 'start'}}>
              {details.map((detail) => (
                <Box paddingX={2} paddingY={3} key={detail.title}>
                  <Stack space={3}>
                    <Label muted size={0}>
                      {detail.title}
                    </Label>
                    <Text size={2}>{detail.value}</Text>
                  </Stack>
                </Box>
              ))}
            </Grid>

            {isMobile && (
              <Flex style={{width: '100%'}}>
                <Box marginTop={2} flex={1}>
                  <MobileRowMenu actions={rowActions} roleScope="org" rowItem={project} />
                </Box>
              </Flex>
            )}
          </Card>
        </TableCell>
      )}
    </TableRow>
  )
})
ProjectItemLarge.displayName = 'memo(ProjectItemLarge)'
