import {Fragment, type ReactNode} from 'react'

interface BaseOptions {
  emptyLabel?: string
  items: string[]
  maxLength?: number
  label?: string
  conjunction?: 'or' | 'and'
}

interface ElementOptions extends BaseOptions {
  type: 'element'
  renderItem?: (item: string) => ReactNode
  renderConjunction?: (conjunction: string) => ReactNode
}

interface StringOptions extends BaseOptions {
  type: 'string'
}

type RenderListOptions = ElementOptions | StringOptions
type RenderListReturn<T> = T extends {type: 'element'} ? ReactNode : string

/**
 * Renders a list of items with customizable options.
 * - If the list is empty, it returns "No" followed by the label.
 * - If the list length exceeds maxLength, it returns the number of items and the label.
 * - Joins items with commas or a specified conjunction ('or', 'and').
 * - Supports custom rendering of items and conjunctions.
 *
 * Example with "element" type:
 * ```tsx
 * const list = renderList({
 *   conjunction: 'or',
 *   items: ['apple', 'banana', 'cherry'],
 *   label: 'fruits',
 *   maxLength: 2,
 *   renderConjunction: (conjunction) => <b>{conjunction}</b>,
 *   renderItem: (item) => <i>{item}</i>,
 *   type: 'element',
 * })
 * ```
 *
 * Example with "string" type:
 * ```tsx
 * const list = renderList({
 *  conjunction: 'or',
 *  items: ['apple', 'banana', 'cherry'],
 *  label: 'fruits',
 *  maxLength: 2,
 *  type: 'string',
 * })
 */
export function renderList<T extends RenderListOptions>(opts: T): RenderListReturn<T> {
  const {conjunction, emptyLabel, items, label, maxLength = Infinity, type} = opts

  if (items.length === 0) {
    return (emptyLabel ? emptyLabel : `No ${label}`) as RenderListReturn<T>
  }

  const isElement = type === 'element'
  const renderedItems = isElement && opts.renderItem ? items.map(opts.renderItem) : items
  const renderedConjunction =
    isElement && opts.renderConjunction && conjunction
      ? opts.renderConjunction(conjunction)
      : conjunction

  if (items.length > maxLength) {
    return `${items.length} ${label}` as RenderListReturn<T>
  }

  if (items.length === 1) {
    return renderedItems[0] as RenderListReturn<T>
  }

  const allButLast = renderedItems.slice(0, -1)
  const lastItem = renderedItems[renderedItems.length - 1]

  if (isElement) {
    return (
      <Fragment>
        {allButLast.map((item, index) => (
          <Fragment key={index}>
            {item}
            {index < allButLast.length - 1 ? ', ' : ''}
          </Fragment>
        ))}
        {renderedConjunction ? <Fragment> {renderedConjunction} </Fragment> : ', '}
        {lastItem}
      </Fragment>
    ) as RenderListReturn<T>
  }

  return `${allButLast.join(', ')}${renderedConjunction ? ` ${renderedConjunction} ` : ', '}${lastItem}` as RenderListReturn<T>
}
