import {Card, Text} from '@sanity/ui'
import {PortableTextComponentProps} from '@portabletext/react'

export const ListItemNote = (props: PortableTextComponentProps<any>) => {
  return (
    <Card padding={4} radius={2} tone="primary">
      <Text muted size={2}>
        {props.children}
      </Text>
    </Card>
  )
}
