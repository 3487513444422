import {useCurrentScopeContext} from '@/context/index'
import {CheckoutForm} from '@/components/checkout/checkoutForm'
import {useSwitchPlanStore} from '@/components/checkout/store'
import {useOrganizationCanPay, useProjectSubscription} from '@/data/index'
import {sendAmplitudeTrackingEvent} from '@/utils/tracking'
import {purple} from '@sanity/color'
import {useToast} from '@sanity/ui'
import confetti from 'canvas-confetti'
import {useRouter} from 'next/router'
import React, {useCallback, useEffect, useMemo} from 'react'
import {useSwitchPlanOverages} from './hooks/useSwitchPlanOverages'
import posthog from 'posthog-js'
import {CheckoutWithoutForm} from './checkoutWithoutForm'
import {Dialog} from '../general'
import {useSearchParams} from 'next/navigation'

export function CheckoutDialog() {
  const {dispatch, state} = useSwitchPlanStore()
  const scope = useCurrentScopeContext()
  const router = useRouter()
  const searchParams = useSearchParams()
  const toast = useToast()
  const {projectId, org} = useCurrentScopeContext()
  const {data: plan, refetch: refetchPlan} = useProjectSubscription(projectId)
  const {overages} = useSwitchPlanOverages(scope!.project!, state!.plan!)
  const {data: canPay} = useOrganizationCanPay(scope?.org?.id || '')
  const shouldSkipForm = useMemo(
    () => scope?.org?.id !== 'personal' && canPay?.status && overages.length === 0,
    [overages.length] // We only want to re-run this effect when overages change
  )
  const Checkout = shouldSkipForm ? CheckoutWithoutForm : CheckoutForm

  const closeDialog = useCallback(async () => {
    dispatch({type: 'switchPlan/close'})

    /* Remove the checkout query param */
    const newParams = new URLSearchParams(searchParams.toString())
    newParams.delete('checkout')
    const paramString = newParams.toString().length ? `?${newParams.toString()}` : ''
    await router.push(window.location.pathname + paramString, undefined, {
      shallow: true,
    })
  }, [dispatch, router, searchParams])

  const handleSuccess = async () => {
    sendAmplitudeTrackingEvent('Change Plan Flow Completed', projectId, org?.id, {
      targetPlan: state.plan!.id,
      newFlow: true,
    })
    refetchPlan()
    await closeDialog()
    confetti({
      resize: true,
      height: '100vh',
      width: '100vw',
      colors: [400, 500, 600].map((shade) => purple[shade].hex),
    })
  }

  const handleSubmitError = useCallback(
    (title: string, message: string) => {
      toast.push({
        status: 'error',
        title,
        description: message,
        closable: true,
        duration: 10000, // 10 seconds
      })
    },
    [toast]
  )

  const handleClose = useCallback(() => {
    if (confirm('Are you sure you want to cancel upgrading your project?')) {
      closeDialog()
    }
  }, [dispatch])

  /* Send event when checkout form loaded */
  useEffect(() => {
    sendAmplitudeTrackingEvent('Change Plan Flow Initiated', projectId, org?.id, {
      currentPlan: plan?.planId,
      targetPlan: state?.plan?.id,
      newFlow: true,
      has_valid_billing: canPay?.status || false,
      current_path: window.location.href,
    })
    posthog.capture('Change Plan Flow Initiated', {
      currentPlan: plan?.planId,
      targetPlan: state?.plan?.id,
      has_org: Boolean(org?.id),
      has_valid_billing: canPay?.status || false,
    })
  }, [projectId, org?.id, state?.plan?.id])

  useEffect(() => {
    if (overages.length > 0) {
      sendAmplitudeTrackingEvent('Plan Conflict Detected', projectId, org?.id, {
        currentPlan: plan?.planId,
        targetPlan: state?.plan?.id,
        conflicts: overages?.map((o) => o.id),
      })
    }
  }, [plan?.planId, plan?.id, overages.length])

  /* If the plan is the same as the current plan, don't show the dialog */
  if (plan?.planId === state?.plan?.id) {
    return null
  }

  return (
    <Dialog
      header={`Upgrade to ${state.plan?.name} plan`}
      id="checkout"
      onClose={handleClose}
      onClickOutside={handleClose}
      width={shouldSkipForm ? 1 : '500px'}
    >
      <Checkout onSubmit={handleSuccess} onSubmitError={handleSubmitError} />
    </Dialog>
  )
}
