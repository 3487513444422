import {Button, Stack, useToast} from '@sanity/ui'
import {StudioItem, TabHeader, Table, TableBody, TableEmpty} from '@/ui/index'
import {Project, Tab, TableHeader} from '@/types/index'
import {useStudios} from '@/data/studios/useStudios'
import {useCallback, useEffect, useState} from 'react'
import {AddIcon} from '@sanity/icons'
import {AddStudioDialog} from './AddStudioDialog'
import {useRequiredProjectPermissions} from '@/context/index'

type Props = {
  project: Project
}

const tableConfig: TableHeader[] = [
  {
    id: 'name',
    label: 'Name',
    columnWidth: 3,
  },
  {
    id: 'updated',
    label: 'Updated',
    columnWidth: 2,
  },
  {
    id: 'hosting',
    label: 'Hosting',
    alignment: 'center',
    columnWidth: 1,
  },
  {
    id: 'version',
    label: 'Version',
    columnWidth: 2,
    alignment: 'center',
    hideOnMobile: true,
  },
  {
    id: 'deployedBy',
    label: 'Deployed by',
    columnWidth: 1,
    alignment: 'center',
    hideOnMobile: true,
  },
  {
    id: 'actions',
    label: 'Actions',
    disableTabButtonRender: true,
    columnWidth: 1,
    alignment: 'right',
  },
]

export function ProjectStudios({title, description, referenceLink, project}: Props & Tab) {
  const toast = useToast()
  const {data: studios = [], isLoading, error} = useStudios(project.id)
  const [isDialogOpen, setDialogOpen] = useState(false)
  const canManageProject = useRequiredProjectPermissions([
    {permissionName: 'sanity.project', grantName: 'update'},
  ])

  useEffect(() => {
    if (error) {
      toast.push({title: error.name, description: error.message, status: 'error'})
    }
  }, [error, toast])

  const handleAddStudio = useCallback(() => {
    setDialogOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setDialogOpen(false)
  }, [])

  return (
    <>
      <Stack space={[4, 4, 6]} data-title={title}>
        <TabHeader
          button={
            <Button
              onClick={handleAddStudio}
              text="Add studio"
              icon={AddIcon}
              disabled={!canManageProject}
              mode="default"
              tone="primary"
            />
          }
          title={title}
          description={description}
          referenceLink={referenceLink}
        />
        <Table headers={tableConfig} loadingText={'Loading studios...'} loading={isLoading}>
          <TableBody>
            {(studios || []).map((studio, i) => {
              const isLast = i === (studios && studios.length - 1)
              return (
                <StudioItem
                  projectId={project.id}
                  key={studio.id}
                  studio={studio}
                  isLast={isLast}
                />
              )
            })}
            {!isLoading && !error && studios?.length === 0 && (
              <TableEmpty
                title="There are no studios deployed for this project yet."
                description="When you deploy studios with Sanity, they will show up here."
              />
            )}
          </TableBody>
        </Table>
      </Stack>
      {isDialogOpen && <AddStudioDialog projectId={project.id} onClose={handleClose} />}
    </>
  )
}
