import {type Tab} from '@/types/components'
import {createItemsObject} from '@/utils/views'
import {useMemo} from 'react'

export function useActiveTab(tabId: string | undefined, items: Tab[]): Tab | undefined {
  const tabs: Record<string, Tab> = useMemo(() => createItemsObject(items), [items])

  if (tabId) {
    return tabs[tabId]
  }

  return tabs.fallback
}
