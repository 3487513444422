import {Heading, Stack, Inline, Badge, Button, Card, Flex, Box, Label, Text, Grid} from '@sanity/ui'
import React, {useCallback, useState} from 'react'
import {ChevronDownIcon, ChevronUpIcon} from '@sanity/icons'
import {TableRow, TableCell, TableRowMenuButton, MobileRowMenu} from '..'
import {AvatarWithFallback} from '../../members'
import {nameToInitials} from '../../members/utils'
import {InfoTooltip} from '../../general'
import {formatUnitType, useMatchesMedia, useTimeAgo} from '@/utils/index'
import {Dataset, TableRowAction} from '@/types/index'
import {useUser} from '@/data/index'

// Format dataset specific usage values and limits
function formatUsage(
  usage: {value: number; unit: string; limit: number} | undefined
): string | undefined {
  if (usage) {
    const {value, unit, limit} = usage
    return limit
      ? `${formatUnitType(value, unit)} / ${formatUnitType(limit, unit, true)}`
      : formatUnitType(value, unit)
  }
  return undefined
}

type DatasetMetaData = {
  label: string
  value: any
}

type Props = {
  dataset: Dataset
  bulkSelect?: React.ReactNode
  selected?: boolean
  children?: React.ReactNode
  rowActions: TableRowAction[] | undefined
}

const DATASET_TONE: any = {
  public: 'positive',
  private: 'caution',
  frozen: 'default',
}

const MetadataItem = ({data}: {data: DatasetMetaData}) => (
  <Box paddingY={2}>
    <Stack space={3}>
      <Label muted size={1}>
        {data.label}
      </Label>
      {/* // A bit hacky here to get things to visually align on the baseline because of the avatar in "created by" cell */}
      <Box paddingTop={['string', 'number'].includes(typeof data.value) ? 1 : 0}>
        <Text size={1}>{data.value}</Text>
      </Box>
    </Stack>
  </Box>
)

const CreatedBy = ({userId}: {userId?: string}) => {
  const {data: user} = useUser(userId)
  return (
    <InfoTooltip disabled={!!userId} description="Couldn't find the creator of this dataset">
      <Flex align="center">
        <Box marginRight={2}>
          <AvatarWithFallback
            title={user?.displayName}
            src={user?.imageUrl || undefined}
            initials={nameToInitials(user?.displayName)}
          />
        </Box>
        <Text size={1}>{user?.displayName || 'Unknown'}</Text>
      </Flex>
    </InfoTooltip>
  )
}

export function DatasetItemLarge({
  bulkSelect,
  selected,
  dataset,
  children,
  rowActions = [],
}: Props) {
  const [showDetails, setShowDetails] = useState<boolean>(false)
  const isMobile = useMatchesMedia(1)
  const handleToggleDetails = useCallback(() => {
    setShowDetails((val) => !val)
  }, [])

  const metadata: DatasetMetaData[] = [
    {
      value: <CreatedBy userId={dataset.createdByUserId || undefined} />,
      label: 'Created by',
    },
    {value: useTimeAgo(dataset?.createdAt || '', {agoSuffix: true}), label: 'Created'},
    {
      value: formatUsage(dataset.stats?.documents.jsonSizeSum),
      label: 'Size',
    },
    {value: dataset.stats?.documents.count.value, label: 'Documents'},
    {
      value: formatUsage(dataset.stats?.fields.count),
      label: 'Attributes',
    },
  ]

  return (
    <TableRow
      selected={selected}
      shadow={1}
      borderBottom={false}
      marginY={3}
      padding={[3, 3, 4]}
      radius={2}
      onClick={isMobile ? handleToggleDetails : undefined}
    >
      {bulkSelect && bulkSelect}
      <TableCell>
        <Stack space={3}>
          <Flex
            direction={['row-reverse', 'row-reverse', 'column']}
            align={['center', 'center', 'flex-start']}
          >
            <Inline marginLeft={[4, 4, 0]} marginBottom={[0, 0, 2]}>
              <Badge
                padding={1}
                radius={2}
                size={1}
                mode="outline"
                tone={dataset.aclMode && DATASET_TONE[dataset.aclMode]}
              >
                {dataset.aclMode}
              </Badge>
            </Inline>

            <Heading size={1}>{dataset.name}</Heading>
          </Flex>
        </Stack>
      </TableCell>
      <TableCell>
        <Grid columns={[2, 2, 2, 3]} gap={[2, 2, 3]} flex={1}>
          {metadata.map((data) => (
            <MetadataItem data={data} key={data.label} />
          ))}
        </Grid>
      </TableCell>
      <TableCell $align="right">
        {isMobile ? (
          <Button
            label="Show more"
            icon={showDetails ? ChevronUpIcon : ChevronDownIcon}
            onClick={handleToggleDetails}
            mode="bleed"
          />
        ) : (
          <TableRowMenuButton
            menuId={`${dataset.name}-menu`}
            label={`Edit ${dataset.name}`}
            rowItem={dataset}
            actions={rowActions}
            roleScope="project"
          />
        )}
      </TableCell>
      {isMobile && showDetails && (
        <TableCell
          style={{gridColumn: '1/-1', width: '100%'}}
          flexProps={{direction: 'column', align: 'flex-start'}}
        >
          <Card flex={1} paddingTop={1} style={{width: '100%'}}>
            <Stack space={4}>
              <Grid columns={2} gap={3}>
                {metadata.map((data) => (
                  <MetadataItem data={data} key={data.label} />
                ))}
              </Grid>
              <Flex style={{width: '100%'}}>
                <Box flex={1}>
                  <MobileRowMenu actions={rowActions} roleScope="project" rowItem={dataset} />
                </Box>
              </Flex>
            </Stack>
          </Card>
        </TableCell>
      )}
      {children}
    </TableRow>
  )
}
