import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {ExperienceDialog} from '@/components/dialog/experience'
import {
  ExperienceDialogsResponse,
  useDismissExperienceDialog,
  useExperienceDialogs,
} from '@/data/experienceDialogs'
import {
  TrialDialogDismissedAttributes,
  getTrialSate,
  sendDialogEventTracking,
} from '@/utils/tracking/dialogTracking'
import {useCurrentScopeContext} from './useCurrentScopeContext'
import {usePathname, useSearchParams} from 'next/navigation'

export const PersonalizedDialogsContext = createContext<
  | {
      data: ExperienceDialogsResponse | undefined
      toggleDialogContent: ({closeAndReopen}: {closeAndReopen: boolean}) => void
      dismissAndRefetch: () => void
      showOnClickDialog: () => void
      trackCTAClickCallback: () => void
      isPopover: boolean
      isLoading: boolean
      isFetching: boolean
    }
  | undefined
>(undefined)

export const PersonalizedDialogProvider = ({children}: {children: ReactNode}) => {
  const {data, refetch, isLoading, isFetching} = useExperienceDialogs()
  const {project, org} = useCurrentScopeContext()
  const dismissDialog = useDismissExperienceDialog()
  const pathname = usePathname()
  const searchParams = useSearchParams()

  const isOnboarding = pathname.includes('/getting-started')
  const isCors = searchParams.get('search') === 'add'
  const skipUpsell = searchParams.get('skip-upsell') === 'true'

  const explicitlyDisableDialog = Boolean(isOnboarding || isCors || skipUpsell)

  // Only show dialog by default if showOnLoad is present
  // also do not show if CORS dialog is present so we don't have double modals
  const [showDialog, setShowDialog] = useState(!isCors && !!data?.showOnLoad)

  // Keep dialog state in sync with data from API
  const [dialog, setDialog] = useState(data?.showOnLoad || data?.showOnClick)
  useEffect(() => {
    setDialog(data?.showOnLoad || data?.showOnClick)
    if (data?.showOnLoad) {
      setShowDialog(true)
      sendDialogEventTracking({
        action: 'Trial Dialog Viewed',
        projectId: project?.id,
        organizationId: org?.id,
        trialDaysLeft: data.daysLeft,
        dialogType: data.showOnLoad.dialogType,
        dialogId: data.showOnLoad.id,
        dialogRevision: data.showOnLoad._rev,
        dialogTrigger: 'auto',
        dialogTrialStage: getTrialSate({showOnLoad: true, dialogId: data.showOnLoad.id}),
      })
    }
  }, [data])

  function showOnClickDialog() {
    setDialog(data?.showOnClick)
    setShowDialog(true)

    if (data?.showOnClick)
      sendDialogEventTracking({
        action: 'Trial Dialog Viewed',
        projectId: project?.id,
        organizationId: org?.id,
        trialDaysLeft: data.daysLeft,
        dialogType: data.showOnClick.dialogType,
        dialogId: data.showOnClick.id,
        dialogRevision: data.showOnClick._rev,
        dialogTrigger: 'fromClick',
        dialogTrialStage: getTrialSate({showOnLoad: false, dialogId: data.showOnClick.id}),
      })
  }

  const isPopover = useMemo(
    () => (dialog && dialog.dialogType === 'popover' && !explicitlyDisableDialog) || false,
    [dialog, explicitlyDisableDialog]
  )

  const toggleDialogContent = useCallback(
    ({closeAndReopen = false}) => {
      // Hide dialog if showing currently
      if (closeAndReopen) setShowDialog(false)
      // Set dialog to be showOnClick content
      setDialog(data?.showOnClick)
      // reshow if requested to show again
      setShowDialog(closeAndReopen)
    },
    [data]
  )

  const dismissAndRefetch = useCallback(
    async (
      dialogDismissAction: TrialDialogDismissedAttributes['dialogDismissAction'] = 'xClick'
    ) => {
      if (dialog && dialog == data?.showOnLoad)
        await dismissDialog.mutateAsync({dialogId: dialog.id})
      refetch()
      if (data && dialog)
        sendDialogEventTracking({
          action: 'Trial Dialog Dismissed',
          projectId: project?.id,
          organizationId: org?.id,
          trialDaysLeft: data.daysLeft,
          dialogType: dialog.dialogType,
          dialogId: dialog.id,
          dialogRevision: dialog._rev,
          dialogDismissAction,
          dialogTrialStage: getTrialSate({showOnLoad: !!data?.showOnLoad, dialogId: dialog.id}),
        })
    },
    [dismissDialog, refetch, dialog, data]
  )

  const onClose = (dialogDismissAction: TrialDialogDismissedAttributes['dialogDismissAction']) => {
    setShowDialog(false)
    dismissAndRefetch(dialogDismissAction)
  }

  const trackCTAClickCallback = useCallback(() => {
    if (data && dialog)
      sendDialogEventTracking({
        action: 'Trial Dialog CTA Clicked',
        projectId: project?.id,
        organizationId: org?.id,
        trialDaysLeft: data.daysLeft,
        dialogType: dialog.dialogType,
        dialogId: dialog.id,
        dialogRevision: dialog._rev,
        dialogCtaType: dialog.ctaButton?.action === 'openNext' ? 'learnMore' : 'upgrade',
        dialogTrialStage: getTrialSate({showOnLoad: !!data?.showOnLoad, dialogId: dialog.id}),
      })
  }, [project, org, data, dialog])

  return (
    <PersonalizedDialogsContext.Provider
      value={{
        data,
        toggleDialogContent,
        dismissAndRefetch,
        isPopover,
        showOnClickDialog,
        trackCTAClickCallback,
        isLoading,
        isFetching,
      }}
    >
      {dialog && dialog.dialogType === 'modal' && showDialog ? (
        <ExperienceDialog
          dialogContent={dialog}
          onClose={onClose}
          ctaClickCallback={trackCTAClickCallback}
        />
      ) : null}
      {children}
    </PersonalizedDialogsContext.Provider>
  )
}

export const usePersonalizedDialogsContext = () => {
  const context = useContext(PersonalizedDialogsContext)
  if (!context) {
    throw new Error(
      'usePersonalizedDialogsContext must be used within a PersonalizedDialogProvider'
    )
  }
  return context
}
