import React, {useState, useCallback} from 'react'
import {Box, Card, Container, Heading, Stack, Text, Button, Code, Flex, useToast} from '@sanity/ui'
import {CopyIcon} from '@sanity/icons'
import {parse} from '@/utils/stackTraceParser'
import SanityLogo from './SanityLogo'

export function ErrorComponent({
  error,
  heading,
  message,
}: {
  error?: Error
  heading?: string
  message?: string
}) {
  const [isStackTraceVisible, setIsStackTraceVisible] = useState(false)
  const [showCollapsed, setShowCollapsed] = useState(false)
  const toast = useToast()

  const toggleStackTrace = () => setIsStackTraceVisible(!isStackTraceVisible)
  const toggleCollapsed = () => setShowCollapsed(!showCollapsed)

  const copyErrorAndStackTrace = useCallback(() => {
    const errorText = `${heading || 'An error occurred'}\n${message || 'Please try again'}\n\nStack Trace:\n${error?.stack || 'No stack trace available'}`
    navigator.clipboard
      .writeText(errorText)
      .then(() => {
        toast.push({
          status: 'success',
          title: 'Copied to clipboard',
        })
      })
      .catch(() => {
        toast.push({
          status: 'error',
          title: 'Failed to copy',
        })
      })
  }, [heading, message, error, toast])

  const stackFrames = error && error.stack ? parse(error.stack) : []
  const visibleFrames = showCollapsed
    ? stackFrames
    : stackFrames.filter((frame) => !frame.isCollapsed)
  const hasCollapsedFrames = stackFrames.some((frame) => frame.isCollapsed)

  return (
    <Card
      display="grid"
      style={{minHeight: '100vh', justifyContent: 'center', alignContent: 'center'}}
    >
      <Container>
        <Flex justify="center">
          <Box paddingBottom={6}>
            <SanityLogo />
          </Box>
        </Flex>
        <Flex paddingBottom={5} justify="center">
          <Heading size={2}>{heading || 'An error occurred'}</Heading>
        </Flex>
        <Card padding={4} border>
          <Stack space={4}>
            <Text size={2} as="p">
              Please try to reload the page and try again. If the error persists, please contact
              support.
            </Text>
            {message && (
              <Box marginY={2}>
                <Code size={1} as="p">
                  {message}
                </Code>
              </Box>
            )}
            {error && error.stack && (
              <Stack space={4}>
                <Flex justify="space-between">
                  <Flex gap={2}>
                    <Button
                      onClick={toggleStackTrace}
                      text={isStackTraceVisible ? 'Hide error details' : 'Show error details'}
                      mode="ghost"
                    />
                    {hasCollapsedFrames && isStackTraceVisible && (
                      <Flex justify="space-between">
                        <Button
                          onClick={toggleCollapsed}
                          text={showCollapsed ? 'Hide collapsed details' : 'Show collapsed details'}
                          tone="primary"
                          mode="ghost"
                          size={1}
                        />
                      </Flex>
                    )}
                  </Flex>
                  <Button
                    icon={CopyIcon}
                    onClick={copyErrorAndStackTrace}
                    text="Copy error"
                    mode="bleed"
                  />
                </Flex>
                {isStackTraceVisible && (
                  <Stack space={4}>
                    <Box
                      style={{
                        minHeight: '3em',
                        maxWidth: '40em',
                        maxHeight: '40em',
                        overflowY: 'initial',
                        overflowX: 'auto',
                      }}
                    >
                      <Stack space={4}>
                        {visibleFrames.map((item, index) => (
                          <Flex key={index} direction="column" gap={3}>
                            <Text size={2} as="p">
                              {item.methodName}
                            </Text>
                            <Text size={1} as="p" muted>
                              {item.fileRelative}:{item.lineNumber}
                            </Text>
                          </Flex>
                        ))}
                      </Stack>
                    </Box>
                  </Stack>
                )}
              </Stack>
            )}
          </Stack>
        </Card>
      </Container>
    </Card>
  )
}
