import {hues, white} from '@sanity/color'
import {SanityMonogram, type SanityMonogramColor, type SanityMonogramProps} from '@sanity/logos'
import {Card, Flex, Text, useTheme} from '@sanity/ui'
import {type CSSProperties} from 'react'

import {UserAvatar} from '../../../generic/user-avatar'
import {useRelativeTime} from '../../../hooks'

const COLORS: Record<'dark', SanityMonogramColor> = {
  dark: {
    bg1: hues.red[600].hex,
    bg2: hues.red[200].hex,
    fg: white.hex,
  },
}

function SanityMonogramWithScheme(props: SanityMonogramProps & {style: CSSProperties}) {
  const isDark = useTheme().sanity.v2?.color._dark

  return <SanityMonogram color={isDark ? COLORS.dark : undefined} {...props} />
}

interface InvitedByCellProps {
  createdAt: string
  displayName?: string
  imageUrl?: string
  isServiceInvite: boolean
}

export function InvitedByCell(props: InvitedByCellProps) {
  const {createdAt, displayName, isServiceInvite, imageUrl} = props

  const timeAgo = useRelativeTime(createdAt, {
    minimal: true,
  })

  return (
    <Flex align="center" gap={2}>
      {!isServiceInvite && <UserAvatar displayName={displayName || ''} src={imageUrl || ''} />}

      {isServiceInvite && (
        <Flex align="center" justify="center" style={{width: 25, height: 25}} sizing="border">
          <Card radius={2} overflow="hidden" style={{width: 20, height: 20}} title="Sanity">
            <SanityMonogramWithScheme
              style={{
                width: '100%',
                height: '100%',
              }}
            />
          </Card>
        </Flex>
      )}

      <Flex flex={1} direction={'column'} gap={2}>
        {isServiceInvite || displayName ? (
          <Text size={1} title={createdAt} textOverflow="ellipsis">
            {isServiceInvite ? 'System' : displayName}
          </Text>
        ) : (
          <Text size={1} textOverflow="ellipsis" muted>
            Unknown user
          </Text>
        )}
        <Text size={0} muted textOverflow="ellipsis">
          {timeAgo}
        </Text>
      </Flex>
    </Flex>
  )
}
