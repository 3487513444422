import React, {useCallback, useEffect, useRef, useState} from 'react'
import {Subscription, of} from 'rxjs'
import {catchError} from 'rxjs/operators'
import {
  OrganizationContact,
  OrganizationContactData,
  OrganizationContactRole,
} from '../../types/models'
import {getOrganizationContacts, setOrganizationContact} from '.'
import {APIError} from '@/data/util/request'

type InvalidDataError = Error & {name: 'InvalidDataError'}
type SetContact = ({
  contact,
  role,
}: {
  contact: OrganizationContactData
  role: OrganizationContactRole
}) => void
type ContactsDataState = {
  error?: InvalidDataError | Error
  loading: boolean
  data: OrganizationContact[]
}
export function useContacts(organizationId: string): [ContactsDataState, SetContact] {
  const [contacts, setContacts] = useState<OrganizationContact[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<InvalidDataError | undefined>(undefined)

  const addSub: React.MutableRefObject<Subscription | undefined> = useRef()
  const getSub: React.MutableRefObject<Subscription | undefined> = useRef()

  useEffect(
    () => () => {
      if (getSub.current) {
        getSub.current.unsubscribe()
      }
      if (addSub.current) {
        addSub.current.unsubscribe()
      }
    },
    []
  )

  useEffect(() => {
    setError(undefined)
    setLoading(true)
    getSub.current = getOrganizationContacts(organizationId)
      .pipe(
        catchError((err) => {
          // If the user isn't allowed to see the contacts, just return an empty list
          if (err.response && err.response.statusCode === 401) {
            return of([] as OrganizationContact[])
          }
          // Throw everything else
          setError(err)
          throw err
        })
      )
      .subscribe((next) => {
        setContacts(next)
        setLoading(false)
        getSub.current?.unsubscribe()
      })
  }, [organizationId])

  const updateContact = useCallback(
    ({contact, role}: {contact: OrganizationContactData; role: OrganizationContactRole}) => {
      setError(undefined)
      setLoading(true)
      addSub.current = setOrganizationContact(organizationId, role, contact)
        .pipe(
          catchError((err) => {
            const isInvalidDataError = err instanceof APIError && err.statusCode === 400

            if (isInvalidDataError) {
              const nError = new Error('Invalid origin') as InvalidDataError
              nError.name = 'InvalidDataError'
              setError(nError)
              return of(undefined)
            }
            // Throw everything else
            setError(err)
            throw err
          })
        )
        .subscribe((next) => {
          if (next) {
            setContacts([...contacts.filter((oContact) => oContact.role !== role), next])
          }
          setLoading(false)
          addSub.current?.unsubscribe()
        })
    },
    [organizationId, contacts]
  )
  return [
    {data: contacts, loading, error},
    ({contact, role}: {contact: OrganizationContactData; role: OrganizationContactRole}) => {
      updateContact({contact, role})
    },
  ]
}
