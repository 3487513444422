import {useQuery, UseQueryResult} from '@tanstack/react-query'
import {GeoIp} from '../../types/models'
import {getGeoIp} from './controller'
import {timeToMilliseconds} from '@/utils/time'

const CACHE_TTL = timeToMilliseconds(1, 'days')

export function useGeoIp(): UseQueryResult<GeoIp, Error> {
  return useQuery({
    queryKey: ['geoIp'],
    queryFn: getGeoIp,
    staleTime: CACHE_TTL,
    gcTime: Infinity,
  })
}
