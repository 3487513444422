import {useCurrentScopeContext} from '.'
import {useProjectPermissions} from '@/data/access/useAccessPermissions'
import {useMemo} from 'react'
import type {RequiredPermissionsConfigUnion} from '@/types/permissions'
import {checkAccessPermissions, getPermissionsConfig} from '@/utils/permissions'

export const useRequiredProjectPermissions = (
  permissionsConfig?: RequiredPermissionsConfigUnion,
  projectId?: string,
  currentOrgId?: string
) => {
  const scope = useCurrentScopeContext()
  const targetProjectId = projectId || scope?.projectId
  const targetOrgId = currentOrgId ?? scope?.currentOrgId
  const {grants: accessGrants} = useProjectPermissions(targetProjectId || '', targetOrgId)

  return useMemo(() => {
    if (!permissionsConfig || !targetProjectId) {
      return true
    }

    const config = getPermissionsConfig(permissionsConfig)
    return checkAccessPermissions(accessGrants, config)
  }, [permissionsConfig, targetProjectId, accessGrants])
}

export const useCanManageProject = () =>
  useRequiredProjectPermissions([{permissionName: 'sanity.project', grantName: 'update'}])

export const useCanReadProject = (projectId?: string) =>
  useRequiredProjectPermissions([{permissionName: 'sanity.project', grantName: 'read'}], projectId)
