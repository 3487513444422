import {Text as UIText, BadgeTone, Card, Flex, Box} from '@sanity/ui'
import React from 'react'
import styled from 'styled-components'
import {useApiStatus} from '@/data/status/useApiStatus'

const Text = styled(UIText)<{$tone?: BadgeTone}>`
  text-decoration: none;
  text-transform: uppercase;
  color: ${({theme, $tone: tone}) => {
    return theme.sanity.color.muted?.[tone || 'default']?.enabled.fg
  }};
`

const StatusDot = styled.div<{$tone?: BadgeTone}>`
  height: 10px;
  width: 10px;
  border-radius: 10px;
  background-color: ${({theme, $tone: tone}) => {
    return theme.sanity.color.muted?.[tone || 'default']?.enabled.fg
  }};
`

export default React.memo(function StatusBadge() {
  const {data: status} = useApiStatus()
  const getToneFromIndicator = (indicator?: string): BadgeTone | undefined => {
    const colorMap = new Map<string, BadgeTone>([
      ['none', 'positive'],
      ['critical', 'critical'],
      ['major', 'critical'],
      ['minor', 'caution'],
      ['default', 'default'],
    ])

    if (!indicator || !colorMap.has(indicator)) {
      return 'default'
    }

    return colorMap.get(indicator)
  }

  const badgeTone: BadgeTone | undefined = getToneFromIndicator(status?.status.indicator)

  return (
    <a href={status.page.url} target="_blank" rel="noreferrer" style={{textDecoration: 'none'}}>
      <Card tone={badgeTone} padding={2} radius={2}>
        <Flex align="center">
          <Box marginRight={2}>
            <StatusDot $tone={badgeTone} />
          </Box>
          <Text size={1} weight="semibold" $tone={badgeTone}>
            {status.status.description}
          </Text>
        </Flex>
      </Card>
    </a>
  )
})
