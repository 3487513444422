import {Flex, Heading, Text, Inline, AvatarStack, Button} from '@sanity/ui'
import React, {memo} from 'react'
import {AppLink} from '../../../app'
import {TableCell, MemberAvatar, PlanBadge, TableRow} from '@/ui/index'
import {useTimeAgo} from '@/utils/index'
import {Project, Subscription} from '@/types/index'
import {useRoutePath} from '@/context/index'
import {ProjectIcon} from '../../project/projectIcon'

type Props = {
  project: Project
  subscription?: Subscription
  children?: React.ReactNode
  bulkSelect?: React.ReactNode
  selected?: boolean
}

export const ProjectItemSmall = memo(function ProjectItemSmall({
  selected,
  project,
  bulkSelect,
  subscription,
  children,
}: Props) {
  const {basePath} = useRoutePath()
  const members = project.members.filter((m) => !m.isRobot) || []
  const plan = subscription?.plan?.name

  const createdAt = useTimeAgo(project.createdAt || '', {agoSuffix: true, minimal: true})

  return (
    <TableRow selected={selected} key={project.id} paddingY={2} paddingX={2} borderBottom>
      {bulkSelect && <TableCell>{bulkSelect}</TableCell>}
      <TableCell>
        <AppLink href={`${basePath}/project/${project.id}`}>
          <Button
            as="span"
            mode="bleed"
            radius={3}
            paddingX={2}
            text={<ProjectIcon project={project} size={1} />}
          />
        </AppLink>
      </TableCell>
      <TableCell>
        <AppLink href={`${basePath}/project/${project.id}`}>
          <Button
            as="span"
            text={<Heading size={1}>{project.displayName}</Heading>}
            mode="bleed"
            paddingX={1}
            style={{color: 'inherit'}}
          />
        </AppLink>
      </TableCell>
      <TableCell>
        <Inline>
          <PlanBadge title={plan || ''} />
        </Inline>
      </TableCell>
      <TableCell>
        <AppLink href={`${basePath}/project/${project.id}/members`}>
          <Button
            as="span"
            mode="bleed"
            paddingY={2}
            paddingLeft={1}
            paddingRight={1}
            radius={6}
            style={{color: 'inherit'}}
            text={
              <Flex align="center">
                <AvatarStack maxLength={3}>
                  {members.map((member) => (
                    <MemberAvatar key={member.id} userId={member.id} size={1} />
                  ))}
                </AvatarStack>
              </Flex>
            }
          />
        </AppLink>
      </TableCell>
      <TableCell>
        <Text>{createdAt}</Text>
      </TableCell>
      <TableCell $align="right">{children}</TableCell>
    </TableRow>
  )
})
ProjectItemSmall.displayName = 'memo(ProjectItemSmall)'
