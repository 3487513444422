export * from './controller'
export * from './useDatasets'
export * from './useCreateDataset'
export * from './useEditDataset'
export * from './useDeleteDataset'
export * from './useDatasetRoles'

// - Must be all lowercase
// - Must start with one alphanumeric character (a-z or 0-9)
// - Must be followed by alphanumeric characters, _ or -
// - Must be no longer than 64 characters long
export const datasetNamePattern = /^[a-z0-9][-_a-z0-9]{1,63}$/

export const MAX_DATASET_NAME_LENGTH = 64

export const PRIVATE_DATASET_FEATURE = 'privateDataset'
