const cache = new Map<string, Intl.Collator>()

/**
 * @internal
 * @description create a cached Intl.Collator instance based off the options provided
 */
const getCollator = (options?: Intl.CollatorOptions) => {
  const cacheKey = options
    ? Object.entries(options)
        .sort((a, b) => (a[0] < b[0] ? -1 : 1))
        .join()
    : ''

  if (cache.has(cacheKey)) {
    return cache.get(cacheKey)!
  }

  const formatter = new Intl.Collator(undefined, options)
  cache.set(cacheKey, formatter)

  return formatter
}

type Paths<T> = T extends object
  ? {
      [K in keyof T]: [K, ...Paths<T[K]>]
    }[keyof T]
  : []

/**
 *
 * @param data The data to filter
 * @param query The query to filter by
 * @param filterPaths The paths to the values to filter by, e.g. [['memberProfile', 'displayName'], ['memberProfile', 'email']]
 */
export function filterByQuery<T>(data: T[], query: string | null, filterPaths: Paths<T>[]) {
  if (!query) {
    return data
  }

  const {compare} = getCollator({usage: 'sort', sensitivity: 'base'})
  const collator = getCollator({usage: 'search', sensitivity: 'base'})

  const contains = (string: string, substring: string): boolean => {
    if (substring.length === 0) {
      return true
    }

    string = string.normalize('NFC')
    substring = substring.normalize('NFC')

    let scan = 0
    const sliceLen = substring.length
    for (; scan + sliceLen <= string.length; scan++) {
      const slice = string.slice(scan, scan + sliceLen)

      if (collator.compare(substring, slice) === 0) {
        return true
      }
    }

    return false
  }

  const filteredData = data.filter((item) => {
    return filterPaths.some((path) => {
      // Safely navigate the object based on the provided path
      const value = path.reduce((acc: any, key) => (acc ? acc[key] : undefined), item)

      return value && typeof value === 'string' && contains(value, query)
    })
  })

  // Sort filtered results by relevance
  filteredData.sort((a, b) => {
    const [path] = filterPaths

    const valueA = path.reduce((acc: any, key) => (acc ? acc[key] : undefined), a)
    const valueB = path.reduce((acc: any, key) => (acc ? acc[key] : undefined), b)

    return compare(valueA, valueB)
  })

  return filteredData
}
