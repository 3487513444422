import {useQuery} from '@tanstack/react-query'
import {Project} from '../../types/models'
import {getProjects} from './controller'
import {isAPIError} from '../util/promiseRequest'
import {getProjectsKey} from './cache'

export function useProjects(options?: {initialData?: Project[]; enabled?: boolean}) {
  const {initialData, enabled = true} = options || {}
  return useQuery<Project[]>({
    queryKey: getProjectsKey(),
    queryFn: () => getProjects(),
    initialData,

    retry: (failureCount, error) => {
      if ((isAPIError(error) && error.statusCode === 401) || failureCount > 2) {
        return false
      }
      return true
    },
    enabled,
  })
}
