/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-unassigned-import */
import React, {useCallback, useEffect, useRef, useState, forwardRef} from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import DatePicker, {CalendarContainer} from 'react-datepicker'
import {
  Box,
  Button,
  Card,
  Flex,
  Inline,
  Popover as UIPopover,
  Select,
  Text,
  useClickOutside,
  useGlobalKeyDown,
  useTheme,
} from '@sanity/ui'
import {ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, CloseIcon} from '@sanity/icons'
import {addDays, compareAsc, format, getMonth, getYear, isToday} from 'date-fns'
import {range} from 'lodash'
import styled, {CSSObject} from 'styled-components'
import {styles} from './styles'
import {useMatchesMedia} from '@/utils/index'

type Props = {
  onChange: (date: Date) => void
  maxDate?: Date
  minDate?: Date
  initialDate?: Date
  dateSuffix?: string
  clearable?: boolean
}

const Popover = styled(UIPopover)`
  & [data-ui='PopoverCard'] {
    @media (min-width: ${({theme}) => theme.sanity.media[2]}px) {
      min-width: 400px;
    }
  }
`

const Wrapper = styled(Box)`
  ${styles}
  .react-datepicker__day:hover,
  .react-datepicker__day:focus,
  .react-datepicker__day--outside-month:hover,
  .react-datepicker__day--outside-month:focus {
    opacity: 1;
    span {
      background: ${({theme}) => theme.sanity.color.button.bleed.default.hovered.bg};
      color: ${({theme}) => theme.sanity.color.button.bleed.default.hovered.fg};
    }
  }
`

export function DateInput({
  onChange,
  minDate,
  maxDate,
  initialDate,
  clearable = false,
  dateSuffix,
}: Props) {
  const {sanity} = useTheme()
  const [startDate, setStartDate] = useState<Date>(initialDate ? initialDate : new Date())
  const inputRef = useRef<HTMLButtonElement | null>(null)
  const [popoverEl, setPopoverEl] = useState<HTMLDivElement | null>(null)
  const isMobile = useMatchesMedia(2)
  const [open, setOpen] = useState(false)

  const handleCloseDatePicker = useCallback(() => {
    setOpen(false)
  }, [])

  const handleGlobalKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setOpen(false)
        inputRef?.current?.focus()
      }
    },
    [inputRef]
  )

  useClickOutside(handleCloseDatePicker, [popoverEl])
  useGlobalKeyDown(handleGlobalKeyDown)

  useEffect(() => {
    if (!initialDate) return
    setStartDate(initialDate)
  }, [initialDate])

  const minYear = minDate ? new Date(minDate).getFullYear() : 2019
  const years = range(minYear, getYear(new Date()) + 1, 1)

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const handleChange = useCallback(
    (date) => {
      setStartDate(date)

      if (onChange) {
        onChange(date)
      }
    },
    [onChange]
  )

  // Component rendered as each day in the calendar
  const Day = (dayOfMonth: number, date: Date): React.ReactNode => {
    const selected = format(date, 'yyyy-MM-dd') === format(startDate, 'yyyy-MM-dd')
    const currentDay = format(date, 'yyyy-MM-dd') === format(new Date(), 'yyyy-MM-dd')

    const style = (): CSSObject => {
      if (currentDay) {
        return {
          background: sanity.color.button.default.primary.enabled.bg,
          color: sanity.color.button.default.primary.enabled.fg,
        }
      }
      if (selected) {
        return {
          background: sanity.color.button.bleed.primary.selected.bg,
          color: sanity.color.button.bleed.primary.selected.fg,
        }
      }
      return {}
    }

    const title = (): string => {
      if (currentDay) return 'Current day'
      if (selected) return 'Selected day'
      return ''
    }

    return (
      <Card
        padding={[1, 2, 2]}
        paddingX={[1, 3, 3]}
        marginY={1}
        radius={2}
        style={{...style(), width: 48, height: 32}}
        as="span"
        tone="inherit"
        title={title()}
        onClick={handleCloseDatePicker}
      >
        {dayOfMonth}
      </Card>
    )
  }

  // Component rendered as header in the calendar
  const Header = ({
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }: any) => {
    return (
      <Flex justify="space-between" marginBottom={2}>
        {/* back button */}
        <Button
          icon={ChevronLeftIcon}
          onClick={decreaseMonth}
          disabled={prevMonthButtonDisabled}
          mode="bleed"
          aria-label="Previous month"
        />

        <Inline space={2}>
          {/* year select */}
          <Select
            value={getYear(date)}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => changeYear(e.target.value)}
            radius={2}
            fontSize={1}
          >
            {years.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </Select>

          {/* month select */}
          <Select
            value={months[getMonth(date)]}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              changeMonth(months.indexOf(e.target.value))
            }
            radius={2}
            fontSize={1}
          >
            {months.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </Select>
        </Inline>

        {/* next button */}
        <Button
          icon={ChevronRightIcon}
          onClick={increaseMonth}
          disabled={nextMonthButtonDisabled}
          mode="bleed"
          aria-label="Next month"
        />
      </Flex>
    )
  }

  //Container that wraps the calendar
  const Container = ({className, children}: {className: string; children: React.ReactNode[]}) => {
    const test = popoverEl?.querySelectorAll('[tabindex]:not([tabindex="-1"])')[0]

    const [firstItem, setFirstItem] = useState<any>()

    useEffect(() => {
      if (open && test) {
        setFirstItem(test)
      }
    }, [test])

    useEffect(() => {
      if (!firstItem) return
      requestAnimationFrame(() => firstItem?.focus())
    }, [firstItem])

    return (
      <Card padding={2} paddingBottom={0} shadow={2} radius={2}>
        <CalendarContainer className={className}>
          <>{children}</>
        </CalendarContainer>
      </Card>
    )
  }

  //Component that show/hide the calendar
  const Input = forwardRef(({value}: {value: Date}, ref: React.Ref<HTMLDivElement>) => {
    const disablePrev = minDate ? compareAsc(startDate, addDays(minDate, 1)) === -1 : false
    const disableNext = maxDate ? compareAsc(startDate, addDays(maxDate, -1)) === 1 : false
    const val = value ? format(value, 'dd LLL yyyy') : format(new Date(), 'dd LLL yyyy')
    const inputText = isToday(new Date(val)) ? 'Today' : val

    return (
      <Box ref={ref}>
        <Card radius={2} border>
          <Flex>
            <Button
              icon={ChevronLeftIcon}
              mode="bleed"
              onClick={() => handleChange(addDays(startDate, -1))}
              disabled={disablePrev}
              aria-label="Previous day"
            />
            <Card flex={1} borderLeft borderRight>
              {(!clearable || isToday(startDate)) && (
                <Button
                  ref={inputRef}
                  radius={0}
                  text={
                    <Flex align="center">
                      {inputText}
                      {dateSuffix && (
                        <Box marginLeft={1}>
                          <Text muted style={{opacity: 0.5, color: 'inherit'}}>
                            {dateSuffix}
                          </Text>
                        </Box>
                      )}
                    </Flex>
                  }
                  onClick={() => setOpen(!open)}
                  style={{width: '100%'}}
                  mode="bleed"
                  iconRight={ChevronDownIcon}
                />
              )}

              {clearable && !isToday(startDate) && (
                <Flex align="center" style={{height: '100%'}}>
                  <Box padding={1} flex={1}>
                    <Card
                      tone={isToday(startDate) ? 'inherit' : 'primary'}
                      padding={1}
                      flex={1}
                      radius={2}
                    >
                      <Flex align="center" style={{width: '100%'}}>
                        <Card
                          flex={1}
                          padding={2}
                          radius={2}
                          paddingRight={1}
                          as="button"
                          tone="primary"
                          style={{
                            width: '100%',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                          aria-label="Open calendar"
                          onClick={() => setOpen(!open)}
                        >
                          {inputText}
                        </Card>

                        <Box>
                          <Button
                            ref={inputRef}
                            radius={2}
                            icon={CloseIcon}
                            mode="bleed"
                            aria-label="Clear date"
                            onClick={() => handleChange(new Date())}
                          />
                        </Box>
                      </Flex>
                    </Card>
                  </Box>
                </Flex>
              )}
            </Card>
            <Button
              icon={ChevronRightIcon}
              mode="bleed"
              onClick={() => handleChange(addDays(startDate, 1))}
              disabled={disableNext}
              aria-label="Next day"
            />
          </Flex>
        </Card>
      </Box>
    )
  })

  Input.displayName = 'DatePickerInput'

  return (
    <Popover
      ref={setPopoverEl}
      open={open}
      portal
      placement={isMobile ? 'bottom' : 'bottom-start'}
      content={
        <Wrapper>
          <DatePicker
            dateFormat="dd LLL yyyy"
            calendarContainer={Container as any}
            renderDayContents={Day as any}
            selected={startDate}
            onChange={(date) => handleChange(date)}
            renderCustomHeader={Header}
            maxDate={maxDate}
            minDate={minDate}
            inline
          />
        </Wrapper>
      }
    >
      <Input value={startDate} />
    </Popover>
  )
}
