import React from 'react'
import {ProjectMemberState} from '@/utils/usage'
import {LimitReachedUpgradeBanner} from '../banner/limitReachedUpgradeBanner'

const data = {
  users: 'member',
  // eslint-disable-next-line camelcase
  non_admin_users: 'non-admin member',
  // eslint-disable-next-line camelcase
  non_viewer_users: 'non-viewer member',
}

export function NoQuotaAvailableWarning({resource}: ProjectMemberState) {
  const memberType = data[resource]

  return (
    <LimitReachedUpgradeBanner
      description={`  Your project has reached its included member quota but allows for overages. You will be
    charged monthly per additional ${memberType}`}
      ctaText="More details"
    />
  )
}
