import React, {useEffect} from 'react'
import {Text, Box, Stack, Flex, useToast} from '@sanity/ui'
import {useEditOrg} from '../../../../data'
import {Organization} from '@/types/index'
import {EditableField, TabSectionHeader, Ul, Li} from '@/ui/index'
import {useRequiredOrganizationPermissions} from '@/context/index'

export function OrgDetails({org, title}: {org: Organization; title: string}) {
  const [editOrgState, editOrg] = useEditOrg(org)
  const {push} = useToast()
  const {data: orgData, error} = editOrgState

  useEffect(() => {
    if (error) {
      const description = error?.response?.message || error.message
      push({
        status: 'error',
        title: 'Failed update organization settings',
        description,
      })
    }
  }, [error, push])

  const canManage = useRequiredOrganizationPermissions([
    {permissionName: 'sanity.organization', grantName: 'update'},
  ])

  function handleSaveName(newName: string) {
    editOrg({name: newName})
  }

  function handleSaveSlug(newSlug: string) {
    if (newSlug === '') {
      editOrg({slug: null})
    } else {
      editOrg({slug: newSlug})
    }
  }

  const hasRequiredFeature = org.features.includes('authSAML')

  return (
    <Box>
      <Stack space={5}>
        <TabSectionHeader title={title} />
        <Stack space={5}>
          <Flex>
            <Box flex={1}>
              <EditableField
                title="Name"
                description="The name is used to identify the organization within Sanity."
                value={orgData.name || ''}
                onSave={handleSaveName}
                canManage={canManage}
                noPermissionText="Your member role does not have access to project settings"
              />
            </Box>
          </Flex>
          {hasRequiredFeature && (
            <Flex>
              <Box flex={1}>
                <EditableField
                  title="Slug"
                  description={
                    <Stack marginTop={1}>
                      <Text size={1} muted>
                        The slug used to identify the organization. It needs to be:
                      </Text>
                      <Ul marginY={2}>
                        <Li>Lowercase</Li>
                        <Li>Between 1-19 characters</Li>
                        <Li>Start with an alphanumeric character (a-z, 0-9)</Li>
                        <Li>Contain only alphanumeric characters, _ or -</Li>
                      </Ul>
                    </Stack>
                  }
                  value={orgData.slug || ''}
                  onSave={handleSaveSlug}
                  canManage={canManage}
                  noPermissionText="Your member role does not have access to project settings"
                  required={false}
                />
              </Box>
            </Flex>
          )}
        </Stack>
      </Stack>
    </Box>
  )
}
