import {useInfiniteQuery} from '@tanstack/react-query'
import {useEffect} from 'react'

/**
 * @internal
 * @description We paginated the response from certain endpoints in Populus
 * but the UI is not designed to handle paginated responses, therefore we need to
 * completely resolve the infinite query.
 */
export const useInfiniteResolver = (response: ReturnType<typeof useInfiniteQuery>) => {
  useEffect(() => {
    if (response.hasNextPage && !response.isFetchingNextPage) {
      response.fetchNextPage({
        cancelRefetch: false,
      })
    }
  }, [response])
}
