import {Button, Box, Card, Flex, Stack, Text, usePrefersReducedMotion} from '@sanity/ui'
import type {Course, Lesson} from '@/data/index'
import {AnimatePresence, motion} from 'framer-motion'
import MuxPlayer from '@mux/mux-player-react'
import {PortableText} from '@portabletext/react'
import {portableTextComponents} from './portableText'
import {red} from '@sanity/color'
import smoothScrollIntoView from 'smooth-scroll-into-view-if-needed'
import styled from 'styled-components'
import {Suspense, useCallback, useMemo, useRef, useState} from 'react'
import {organizePortableTextIntoSections} from './portableText/organizePortableTextIntoSections'
import {sendAmplitudeTrackingEvent} from '@/utils/tracking'
import {useCurrentScopeContext} from '@/context/useCurrentScopeContext'

interface Props {
  course: Course
  lesson: Lesson
  isOpen: boolean
  isCompleted?: boolean
  isAllClosed?: boolean
  onToggle: (isOpen: boolean) => void
  onComplete: (lesson: Lesson) => void
  tabsSwitcher?: React.ReactNode
}

const AccordionWrapper = styled(Card)`
  scroll-margin-top: 140px;

  // Focus ring
  &:has(> button:focus-visible) {
    box-shadow: 0 0 0 2px var(--card-focus-ring-color);
  }
`

const CheckmarkWrapper = styled(motion(Box))<{checked?: boolean}>`
  background-color: ${(p) => (p.checked ? 'var(--card-badge-positive-icon-color)' : 'transparent')};
  color: ${(p) => (p.checked ? 'var(--card-avatar-gray-fg-color)' : 'transparent')};
  border: ${(p) => (p.checked ? 'none' : '1px solid var(--card-kbd-border-color)')};
  width: 20px;
  height: 20px;
  display: grid !important;
  place-items: center;
  border-radius: 50%;
  flex-shrink: 0; 
`

const CheckmarkIcon = () => (
  <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.5 4L4.24138 6.5L9 1.5" stroke="currentColor" strokeWidth="2" />
  </svg>
)

const VideoPlayer = styled(motion(MuxPlayer))`
  --media-object-fit: cover;
  --media-object-position: bottom;
`

const MotionBox = motion(Box)
const MotionCard = motion(Card)

export const LessonAccordion = ({
  course,
  lesson,
  isCompleted,
  isAllClosed,
  isOpen,
  onToggle,
  onComplete,
  tabsSwitcher,
}: Props) => {
  const {projectId, org} = useCurrentScopeContext()
  const ref = useRef<HTMLDivElement>(null)
  const prefersReducedMotion = usePrefersReducedMotion()
  const accordionTransitionDuration = prefersReducedMotion ? 0 : 0.3
  const [hasPlayedVideo, setHasPlayedVideo] = useState(false)

  const handleScroll = useCallback(() => {
    smoothScrollIntoView(ref.current!, {
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
      scrollMode: 'always',
      duration: prefersReducedMotion ? 0 : undefined, // Let the lib handle the duration if not reduced motion
    })
  }, [prefersReducedMotion])

  const handleToggle = useCallback(() => {
    onToggle(!isOpen)
    if (!isOpen) {
      setTimeout(() => handleScroll(), 0) // Next tick
    }
  }, [isOpen, onToggle, handleScroll])

  const handleComplete = useCallback(() => {
    onComplete(lesson)
    if (!isAllClosed) {
      handleScroll()
    }
  }, [isAllClosed, lesson, onComplete, handleScroll])

  const contentInSections = useMemo(() => {
    return organizePortableTextIntoSections(lesson.content)
  }, [lesson.content])

  const isFinalLesson = useMemo(() => {
    return course.lessons.at(-1)?._id === lesson._id
  }, [course.lessons, lesson._id])

  return (
    <AccordionWrapper
      ref={ref}
      radius={4}
      shadow={isCompleted && !isOpen ? 0 : 1}
      overflow="hidden"
      aria-expanded={isOpen}
    >
      {/* Accordion header */}
      <Button
        width="fill"
        mode={isCompleted && !isOpen ? 'ghost' : 'bleed'}
        paddingX={5}
        paddingTop={isOpen ? 4 : 3}
        paddingBottom={3}
        style={{boxShadow: 'unset', background: isOpen ? 'unset' : 'var(--card-bg-color)'}}
        onClick={handleToggle}
      >
        <Flex align="center">
          <CheckmarkWrapper
            checked={isCompleted}
            animate={isOpen ? 'open' : 'closed'}
            initial="closed"
            variants={{
              open: {opacity: 0, marginRight: -21},
              closed: {opacity: 1, marginRight: 12},
            }}
          >
            <CheckmarkIcon />
          </CheckmarkWrapper>

          <Text size={2} weight="bold" textOverflow="ellipsis" style={{flex: 1}}>
            {lesson.shortTitle || lesson.title}
          </Text>

          <MotionBox
            display={['none', 'none', 'block']}
            animate={isOpen ? 'open' : 'closed'}
            initial={false}
            variants={{
              open: {opacity: 1, x: 0},
              closed: {opacity: 0, x: 21},
            }}
          >
            <Text size={1} muted>
              {lesson.readTimeInMinutes} {lesson.readTimeInMinutes > 1 ? 'minutes' : 'minute'}
            </Text>
          </MotionBox>
        </Flex>
      </Button>

      {/* Accordion content */}
      <MotionBox
        overflow="hidden"
        variants={{
          open: {height: 'auto', opacity: 1},
          closed: {
            height: 0,
            opacity: 0,
            transition: {duration: isAllClosed ? accordionTransitionDuration : 0},
          },
        }}
        transition={{ease: 'circOut', duration: accordionTransitionDuration}}
        animate={isOpen ? 'open' : 'closed'}
        initial="closed"
        // @ts-expect-error inert prop is not correctly typed in React
        inert={isOpen ? undefined : ''}
      >
        <Card padding={5} paddingTop={3}>
          <Stack space={5}>
            {lesson.description && (
              <Text size={2} muted>
                {lesson.description}
              </Text>
            )}

            {tabsSwitcher}

            <AnimatePresence mode="wait" initial={false}>
              <MotionBox
                key={lesson._id}
                initial={{opacity: 0, scale: 0.95}}
                animate={{opacity: 1, scale: 1}}
                exit={{opacity: 0, scale: 0.95}}
                transition={{duration: 0.2}}
              >
                {lesson.muxVideo && (
                  <VideoPlayer
                    disableCookies
                    disableTracking
                    streamType="on-demand"
                    playbackId={lesson.muxVideo.playbackId}
                    initial={{aspectRatio: hasPlayedVideo ? '16/9' : '21/9'}}
                    animate={{aspectRatio: hasPlayedVideo ? '16/9' : '21/9'}}
                    accentColor={red[500].hex}
                    poster={
                      lesson.muxVideo.poster?.url ? `${lesson.muxVideo.poster.url}?q=90` : undefined
                    }
                    onPlay={() => {
                      setHasPlayedVideo(true)
                      sendAmplitudeTrackingEvent(
                        'Getting Started Video Played',
                        projectId,
                        org?.id,
                        {
                          course_name: course.shortTitle || course.title,
                          lesson_name: lesson.shortTitle || lesson.title,
                        }
                      )
                    }}
                  />
                )}
              </MotionBox>
            </AnimatePresence>

            <Suspense>
              <PortableText value={contentInSections} components={portableTextComponents} />
            </Suspense>

            <Card paddingY={4} paddingLeft={5}>
              <Button
                text={isFinalLesson ? 'Complete setup' : 'Next step'}
                onClick={handleComplete}
              />
            </Card>
          </Stack>
        </Card>
      </MotionBox>
    </AccordionWrapper>
  )
}

export const LessonAccordionSkeleton = () => (
  <MotionCard
    muted
    radius={4}
    paddingX={5}
    paddingY={3}
    animate={{
      opacity: [0.6, 1, 0.6],
      transition: {
        repeat: Number.POSITIVE_INFINITY,
        duration: 1.5,
        ease: 'easeInOut',
      },
    }}
  >
    <Card radius="full" style={{width: 21, height: 21}} />
  </MotionCard>
)
