import {Avatar as UIAvatar, AvatarProps} from '@sanity/ui'
import React, {CSSProperties, useCallback, useState} from 'react'
import {useManageScheme} from '@/ui/app/manageSchemeProvider'
import styled from 'styled-components'

interface Props extends AvatarProps {
  fallback?: boolean
  squared?: boolean
  bgColor?: string
  style?: CSSProperties
  robot?: boolean
}

const Avatar = styled(UIAvatar)<{$squared: boolean; $bgColor?: string}>`
  ${({theme, $squared: squared, $bgColor: bgColor}) => `

      svg {
        margin: 0 !important;
      }

      &[data-fallback="true"] {
        padding: 4px;
      }


      background-color: ${bgColor ? bgColor : theme.sanity.v2?.color.badge.default.bg};
      
      & [data-ui="Label"] {
        color: ${theme.sanity.color.base.fg};
      }

      ${squared && `border-radius: ${theme.sanity.radius[2]}px;`}


      &[data-fallback='false'] svg {
        color: var(--card-bg-color);

      }

      &[data-fallback='true'] svg {
        height: unset;
        width: unset;
      }

      & svg ellipse {
        stroke: transparent;
      }
    `}
`

/* eslint-disable no-process-env */
const basePath = process.env.assetPrefix || ''
/* eslint-enable no-process-env */

export function AvatarWithFallback({
  fallback = false,
  bgColor,
  squared,
  style,
  robot,
  ...restProps
}: Props) {
  const {resolvedScheme} = useManageScheme()
  const [imageFailed, setImageFailed] = useState<boolean>(false)
  const onImageLoadError = useCallback(() => setImageFailed(true), [])

  if (robot) {
    return (
      <Avatar
        {...restProps}
        src={`${basePath}/${resolvedScheme === 'light' ? 'robot.svg' : 'robot-white.svg'}`}
        style={style}
      />
    )
  }
  return (
    <Avatar
      {...restProps}
      onImageLoadError={onImageLoadError}
      src={
        imageFailed || fallback ? !restProps.initials && `${basePath}/SanityS.svg` : restProps.src
      }
      data-fallback={fallback || imageFailed}
      $squared={squared}
      $bgColor={bgColor}
      style={style}
    />
  )
}
AvatarWithFallback.displayName = 'AvatarWithFallback'
