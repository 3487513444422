import {Box, Layer} from '@sanity/ui'
import React, {useCallback, useEffect, useState} from 'react'
import {PermissionTooltip, useConfirmDialog, MenuSelect, MenuSelectOption} from '@/ui/index'
import {OrganizationRole} from '@/types/models'
import {getErrorDetails} from '@/utils/errors'
import {useOrganizationRolesList} from '@/data/organizations/useOrganizationRoleList'
import {useOrgMemberRole} from '@/data/organizations'

type Props = {
  orgId: string
  hasPermission: boolean
  user: {
    id: string
    isCurrentUser: boolean
  }
  currentRoles: OrganizationRole[]
}

export const OrgRoleSelect = ({orgId, user, hasPermission, currentRoles}: Props) => {
  // State to track the role update and update UI
  const [role, setRole] = useState<OrganizationRole[]>(currentRoles)

  // All available roles in the org
  const {data: roles = []} = useOrganizationRolesList(orgId)

  // All the role names
  const roleNames = roles?.map((r) => r.name)
  // The first role name when sorted alphabetically
  // Naive workaround until new roles UI, because users could have multiple roles
  // and we want to show the most important role they have first (admin)
  const firstRoleName = roleNames.sort()[0]
  const [{error, loading, isUpdated}, updateOrgRole] = useOrgMemberRole(
    orgId,
    user.id,
    firstRoleName
  )

  const {showDialog, hideDialog, Dialog} = useConfirmDialog()

  const handleUpdateRole = useCallback(
    (newRoleName: string) => {
      setRole(roles.filter((r) => r.name === newRoleName))
      updateOrgRole({
        newRoleName,
      })
    },
    [updateOrgRole, roles]
  )

  const handleConfirmSetRole = useCallback(
    (roleName: string | undefined | number) => {
      if (typeof roleName !== 'string') {
        return undefined
      }

      if (user.isCurrentUser && hasPermission) {
        return showDialog(roleName)
      }
      if (hasPermission) {
        return handleUpdateRole(roleName)
      }
      return undefined
    },
    [hasPermission, showDialog, user.isCurrentUser, handleUpdateRole]
  )

  useEffect(() => {
    if (isUpdated && !error && !loading) {
      hideDialog({description: 'Successfully changed member role', status: 'success'})
    }
  }, [error, loading, isUpdated, hideDialog])

  useEffect(() => {
    if (error && !loading && !isUpdated) {
      const errorDetails = getErrorDetails(error)
      hideDialog({
        title: 'An error occurred',
        description: errorDetails.message,
        status: 'error',
      })
      setRole(currentRoles)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, loading, isUpdated, hideDialog, currentRoles])

  const roleOptions: MenuSelectOption[] = roles.map((rol) => ({
    value: rol.name,
    label: rol.title,
    description: rol.description,
  }))

  return (
    <Layer zOffset={100}>
      <Box>
        <Dialog
          header="Change role"
          title="Do you really want to change your own role?"
          description="You may not be able to administrate this organization anymore!"
          id={`role-${user.id}`}
          onConfirm={handleUpdateRole}
          loading={loading}
        />
        <PermissionTooltip disabled={hasPermission}>
          <MenuSelect
            options={roleOptions}
            value={role}
            onChange={handleConfirmSetRole}
            disabled={!hasPermission || loading}
            loading={loading}
            defaultValue={
              currentRoles.length === 0 ? [{label: 'No role', value: undefined}] : undefined
            }
          />
        </PermissionTooltip>
      </Box>
    </Layer>
  )
}
