import React from 'react'
import styled from 'styled-components'
import {Layer} from '@sanity/ui'
import {TableError, TableHead, TableLoading} from '.'
import {useMatchesMedia} from '@/utils/index'

interface StyledTableProps {
  $columns?: string
  alignment?: 'right' | 'center' | 'left'
  bulkSelect?: boolean
  $isMobile?: boolean
  $hiddenMobileColumns?: number[]
}

interface TableProps {
  headers: any[]
  children: React.ReactNode
  bulkSelect?: boolean
  loading?: boolean
  loadingText?: string | React.ReactNode
  error?: Error | boolean | null
  ref?: React.Ref<HTMLTableElement>
}

const StyledTable = styled.table<StyledTableProps>`
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  text-align: left;

  & tr:not([hidden]) {
    display: grid;
    gap: ${({theme}) => `${theme.sanity.space[2]}px`};
    row-gap: ${({theme}) => `${theme.sanity.space[1]}px`};
    ${({$columns: columns, bulkSelect}) => {
      if (bulkSelect) {
        return `grid-template-columns: ${columns ? `40px ${columns}` : '40px repeat(4, 1fr)'}`
      }
      return `grid-template-columns: ${columns ? `${columns}` : 'repeat(4, 1fr)'}`
    }}
  }

  ${({$isMobile: isMobile, $hiddenMobileColumns}) => {
    if (isMobile) {
      return $hiddenMobileColumns?.map(
        (column) => `tr td:not([hidden]):nth-child(${column}) {display: none}`
      )
    }
    return ''
  }}
`

export const Table = ({
  children,
  headers,
  bulkSelect,
  loading,
  loadingText,
  error,
  ref,
}: TableProps) => {
  const isMobile = useMatchesMedia(1)

  if (error) {
    return (
      <Layer zOffset={1}>
        <StyledTable>
          <TableError />
        </StyledTable>
      </Layer>
    )
  }
  const filteredHeaders = isMobile ? headers.filter((h) => !h.hideOnMobile) : headers
  const hiddenMobileColumns = headers.map((h, i) => h.hideOnMobile && i + 1).filter(Number)
  const tableColumns = filteredHeaders
    ? filteredHeaders
        .map((h) => {
          if (!h.columnWidth) {
            return `1fr`
          }
          if (typeof h.columnWidth === 'number') {
            return `${h.columnWidth}fr`
          }
          return h.columnWidth
        })
        .join(' ')
    : undefined

  return (
    <Layer zOffset={1}>
      <StyledTable
        $columns={tableColumns}
        bulkSelect={bulkSelect}
        $hiddenMobileColumns={hiddenMobileColumns}
        $isMobile={isMobile}
        ref={ref}
      >
        {!loading && <TableHead headers={headers} bulkSelect={bulkSelect} />}
        {loading ? <TableLoading text={loadingText} /> : children}
      </StyledTable>
    </Layer>
  )
}
