import {Studio} from '../../types/models'

const API_VERSION = 'v2024-08-01'

import {promiseRequest} from '../util/promiseRequest'

export function getStudios(projectId?: string): Promise<Studio[]> {
  if (!projectId) return Promise.reject(new Error(`Invalid projectId: ${projectId}`))
  return promiseRequest<Studio[]>(
    `https://api.${process.env.host}/${API_VERSION}/projects/${projectId}/user-applications/`
  )
}

export function updateExternalStudioHost(
  data: {urlType: 'external'; appHost: string},
  projectId?: string
): Promise<Studio> {
  const {urlType, appHost} = data
  if (!projectId) return Promise.reject(new Error(`Invalid projectId: ${projectId}`))
  if (urlType !== 'external') {
    return Promise.reject(new Error(`Invalid urlType: ${data.urlType}`))
  }
  return promiseRequest<Studio>(
    `https://api.${process.env.host}/${API_VERSION}/projects/${projectId}/user-applications`,
    {
      method: 'POST',
      json: {
        urlType,
        appHost,
      },
    }
  )
}

export function deleteUserApplication(id: string, projectId?: string) {
  if (!projectId) return Promise.reject(new Error(`Invalid projectId: ${projectId}`))
  return promiseRequest<Studio>(
    `https://api.${process.env.host}/${API_VERSION}/projects/${projectId}/user-applications/${id}`,
    {
      method: 'DELETE',
    }
  )
}
