import {useQuery, type UseQueryResult} from '@tanstack/react-query'
import {getUserOnboardingData, type UserOnboardingData} from './controller'
import {useCurrentScopeContext} from '@/context/useCurrentScopeContext'
import {getUserOnboardingDataCacheKey} from './cache'
export function useUserOnboardingData(): UseQueryResult<UserOnboardingData | null> {
  const {currentUser} = useCurrentScopeContext()
  return useQuery({
    queryKey: getUserOnboardingDataCacheKey(currentUser?.id),
    queryFn: () => getUserOnboardingData(),
    enabled: !!currentUser,
    retry: false,
  })
}
