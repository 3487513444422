import {
  DefaultError,
  InfiniteData,
  QueryKey,
  useInfiniteQuery,
  useQueries,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import {getOrganizationRoles} from '@/data/organizations'
import {OrganizationRole} from '@/types/index'
import {
  getOrganizationAccessRolesKey,
  getOrganizationRolesKey,
  STALE_TTL,
} from '@/data/organizations/cache'
import {useMemo} from 'react'
import {useMutation} from '@tanstack/react-query'
import {promiseRequest} from '@/data/util/promiseRequest'
import {useInfiniteResolver} from '../../hooks/useInfiniteResolver'
import {getRoles, GetRolesResponse} from '@sanity/access-api'
import {useRequiredOrganizationPermissions} from '../../context'

export function useOrganizationRolesList(orgId?: string) {
  const canReadOrganizationRoles = useRequiredOrganizationPermissions(
    [{permissionName: 'sanity.organization.roles', grantName: 'read'}],
    orgId
  )

  return useQuery<OrganizationRole[]>({
    queryKey: getOrganizationRolesKey(orgId),
    queryFn: () => {
      if (!orgId) {
        return Promise.resolve([])
      }
      return getOrganizationRoles(orgId)
    },
    staleTime: STALE_TTL,
    enabled: !!orgId && canReadOrganizationRoles,
  })
}

export function useOrganizationRoles(orgId: string) {
  const response = useInfiniteQuery<
    GetRolesResponse,
    DefaultError,
    InfiniteData<GetRolesResponse, string | null>,
    QueryKey,
    string | undefined
  >({
    queryKey: getOrganizationAccessRolesKey(orgId),
    queryFn: async ({pageParam}) => {
      const response = await getRoles({
        path: {
          resourceType: 'organization',
          resourceId: orgId,
        },
        query: {
          limit: 500,
          nextCursor: pageParam,
        },
      })

      return response['data']
    },
    staleTime: STALE_TTL,
    enabled: !!orgId,
    // select: (data) => data.filter(Boolean),
    initialPageParam: undefined,
    getNextPageParam: (lastPage) =>
      'nextCursor' in lastPage && typeof lastPage.nextCursor === 'string'
        ? lastPage.nextCursor
        : undefined,
  })

  useInfiniteResolver(response)

  const roles = useMemo(() => {
    return response.data?.pages.flatMap((page) => ('data' in page ? page.data : page))
  }, [response.data])

  return {
    ...response,
    data: roles,
  }
}

export function useOrganizationsRolesList(orgIds?: string[]) {
  const uniqueOrgIds = useMemo(() => Array.from(new Set(orgIds)), [orgIds])
  const result = useQueries({
    queries:
      uniqueOrgIds?.map((orgId) => ({
        queryKey: getOrganizationRolesKey(orgId),
        queryFn: () => getOrganizationRoles(orgId),
        staleTime: STALE_TTL,
      })) ?? [],
  })

  const isLoading = result.some((query) => query.isLoading)
  const error = result.find((query) => query.error)
  const data = useMemo<Record<string, OrganizationRole[] | undefined>>(() => {
    return result.reduce((acc, query, index) => {
      if (orgIds && orgIds[index]) {
        acc[orgIds[index]] = query.data ?? []
      }
      return acc
    }, {})
  }, [result, orgIds])

  return {
    data,
    isLoading,
    error,
  }
}

export function useApplyDefaultRoleToAllUsers(orgId: string) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['applyDefaultRoleToAllUsers', orgId],
    mutationFn: () => {
      return promiseRequest<{message: string}>(
        `https://api.${process.env.host}/vX/access/organization/${orgId}/users/roles/default`,
        {
          method: 'PUT',
        }
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['organization', 'members-v2', orgId]})
    },
  })
}
