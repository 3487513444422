import {useState, useEffect} from 'react'
import {useTheme} from '@sanity/ui'
import {Heading} from '../types/components'
import {FallbackComponent} from '@/ui/index'

export function createItemsObject(items: any[]): Record<string, any> {
  const itemsAsObject = items.reduce((item: Record<string, any>, obj) => {
    const toAdd = {
      ...obj,
      component: obj.component || (obj.headings || [])[0]?.component || FallbackComponent,
    }
    if (obj.isRoot) {
      item.root = toAdd
    }
    item[obj.path] = toAdd
    return item
  }, {})

  const itemsObject = {
    ...itemsAsObject,
    fallback: {
      title: 'Fallback component',
      description: 'No component was configured for this path.',
      component: FallbackComponent,
      path: 'fallback',
    },
  }
  return itemsObject
}

export function useActiveHeading(id: string, items: Heading[]): Heading {
  const headings: Record<string, Heading> = createItemsObject(items)
  if (id) {
    return headings[id]
  }
  return headings.fallback
}

export function useScrollMarginTop(): number {
  return 150
}

export function useMatchesMedia(
  size: 0 | 1 | 2 | 3 | 4 | 5,
  condition: 'min' | 'max' = 'max'
): boolean {
  const {sanity} = useTheme()
  const {media} = sanity

  const mediaWidth = media[size]
  const query = window.matchMedia(`(${condition}-width: ${mediaWidth}px)`)
  const getMatches = () => {
    return query.matches
  }
  const [matches, setMatches] = useState<boolean>(getMatches)

  useEffect(() => {
    const handleChange = () => setMatches(getMatches)
    query.addEventListener('change', handleChange)
    return () => query.removeEventListener('change', handleChange)
  }, [])

  return matches
}
