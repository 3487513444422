import {useProjectInvitations} from '@/data/invitations'
import {useProjectSubscription} from '@/data/projects/useProjectSubscription'
import {Project} from '@/types/models'
import {Box, Card, Flex, Grid, Heading, Stack, Text} from '@sanity/ui'
import styled from 'styled-components'

const Dot = styled.div<{isFilled: boolean}>`
  background-color: ${(props) => (props.isFilled ? '#2276FC' : '#CAD1DC')};
  height: 0.375rem;

  &:not(:last-child) {
    margin-right: 2px;
  }
`

export function ProjectQuota({project, quotaToAdd = 0}: {project: Project; quotaToAdd: number}) {
  const {data: subscription} = useProjectSubscription(project?.id)
  const usersQuota = subscription?.resources.users.quota
  const {data: pendingInvitations = []} = useProjectInvitations(project.id)
  const usersCount =
    project.members.filter((member) => !member.isRobot).length + pendingInvitations.length

  if (!usersQuota) {
    return null
  }

  // https://github.com/sanity-io/manage-sanity-io/blob/09ef048179491e243bbc2a7c4500ddf36ba557a3/src/components/invitations/InvitationForm.js#L150

  const numberOfDots = usersQuota
  const dots = new Array(numberOfDots).fill(null)
  const totalQuotaAfterAdd = usersCount + quotaToAdd
  const hasHitCurrentLimit = usersCount === usersQuota

  return (
    <Stack space={2}>
      <Flex justify="space-between" align="center">
        <Heading as="h3" size={1}>
          Project quota
        </Heading>
      </Flex>
      <Card radius={2} style={{height: '0.375rem', overflow: 'hidden'}}>
        <Grid columns={numberOfDots}>
          {dots.map((_, index: number) => (
            <Dot key={`dot-${index}`} isFilled={index + 1 <= totalQuotaAfterAdd} />
          ))}
        </Grid>
      </Card>
      <Flex justify="space-between" align="center">
        <Flex align="center">
          <Text size={2}>{usersCount}</Text>
          {!hasHitCurrentLimit && quotaToAdd > 0 && (
            <Box marginLeft={1}>
              <Text size={2} style={{color: '#2276FC'}}>
                +{quotaToAdd}
              </Text>
            </Box>
          )}
        </Flex>
        <Text size={2} muted>
          of {usersQuota}
        </Text>
      </Flex>
    </Stack>
  )
}
