import React from 'react'
import styled from 'styled-components'
import {Card, Text} from '@sanity/ui'
import {TableCell} from '.'
import {TableHeader} from '@/types/index'
import {useMatchesMedia} from '@/utils/index'

type Props = {
  headers: TableHeader[]
  bulkSelect?: boolean
}

const Head = styled.thead`
  display: contents;
`

export function TableHead({headers = [], bulkSelect = false}: Props) {
  const isMobile = useMatchesMedia(1)
  const isVisible = !headers.every((h) => h.disableTabButtonRender)
  const filteredHeaders = isMobile ? headers.filter((header) => !header.hideOnMobile) : headers
  return (
    <Head>
      <Card
        as="tr"
        borderBottom={isVisible}
        style={{borderWidth: 2}}
        paddingY={headers.length > 0 && isVisible ? 2 : 0}
        paddingX={2}
        tone="default"
      >
        {bulkSelect && (
          <TableCell as="th" key={`header-bulkselect`} style={{position: 'sticky', top: 116}}>
            &nbsp;
          </TableCell>
        )}
        {isVisible &&
          filteredHeaders.length > 0 &&
          filteredHeaders.map((header) => (
            <TableCell
              as="th"
              key={header.id}
              $align={header.alignment}
              style={{position: 'sticky', top: 116}}
            >
              <Text
                muted
                size={0}
                style={{
                  textTransform: 'uppercase',
                  fontWeight: 600,
                  opacity: header.disableTabButtonRender ? 0 : 1,
                }}
              >
                {header.label}
              </Text>
            </TableCell>
          ))}
      </Card>
    </Head>
  )
}
