import {getRequests as getRequestsQuery, type Request, type ResourceType} from '@sanity/access-api'

export async function getRequests(
  resourceId: string,
  resourceType: ResourceType
): Promise<Request[]> {
  const requests = await getRequestsQuery({
    path: {
      resourceType,
      resourceId,
    },
    query: {
      includeChildren: resourceType === 'organization',
    },
  })

  return requests?.data || []
}
