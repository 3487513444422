export function isInfinity(value: number): boolean {
  return !isNaN(value) && !isFinite(value)
}

export const formatUSD = (amount?: number | null): string => {
  const formattedAmount = amount || 0
  return Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: formattedAmount % 1 === 0 ? 0 : 2,
  }).format(formattedAmount)
}
