import {useMutation, useQueryClient} from '@tanstack/react-query'
import {addDatasetTag, removeDatasetTag} from '..'
import {getProjectTagsKey} from '../projects/cache'
import {Tag} from '@/types/index'
import {useToast} from '@sanity/ui'

type UndoError = {
  error: Error
  undo: {
    name: string
    op: 'add' | 'remove'
  }
}

export function useTagDataset(projectId: string, tagName: string) {
  const queryClient = useQueryClient()
  const toast = useToast()

  const addMutation = useMutation({
    mutationKey: ['addDatasetTag', projectId, tagName],
    mutationFn: ({datasetName}: {datasetName: string}) =>
      addDatasetTag(projectId, datasetName, tagName),
    onMutate: async ({datasetName}) => {
      await queryClient.cancelQueries({
        queryKey: getProjectTagsKey(projectId),
      })
      const previousTags = queryClient.getQueryData<Tag[]>(getProjectTagsKey(projectId))
      queryClient.setQueryData<Tag[]>(getProjectTagsKey(projectId), (old = []) =>
        old.map((tag) =>
          tag.name === tagName
            ? {...tag, datasets: [...(tag.datasets || []), {name: datasetName}]}
            : tag
        )
      )
      return {previousTags}
    },
    onError: (error, {datasetName}, context) => {
      queryClient.setQueryData(getProjectTagsKey(projectId), context?.previousTags)
      toast.push({
        title: `Failed to add tag ${tagName} to dataset ${datasetName}`,
        description: error.message,
        status: 'error',
      })
    },
    onSuccess: (_data, {datasetName}) => {
      toast.push({
        title: 'Added tag to dataset',
        description: `Tag ${tagName} added to dataset ${datasetName}`,
        status: 'success',
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: getProjectTagsKey(projectId),
      })
    },
  })

  const removeMutation = useMutation({
    mutationKey: ['removeDatasetTag', projectId, tagName],
    mutationFn: ({datasetName}: {datasetName: string}) =>
      removeDatasetTag(projectId, datasetName, tagName),
    onMutate: async ({datasetName}) => {
      await queryClient.cancelQueries({
        queryKey: getProjectTagsKey(projectId),
      })
      const previousTags = queryClient.getQueryData<Tag[]>(getProjectTagsKey(projectId))
      queryClient.setQueryData<Tag[]>(getProjectTagsKey(projectId), (old = []) =>
        old.map((tag) =>
          tag.name === tagName
            ? {...tag, datasets: (tag.datasets || []).filter((d) => d.name !== datasetName)}
            : tag
        )
      )
      return {previousTags}
    },
    onSuccess: (_data, {datasetName}) => {
      toast.push({
        title: 'Removed tag from dataset',
        description: `Tag ${tagName} removed from dataset ${datasetName}`,
        status: 'success',
      })
    },
    onError: (error, {datasetName}, context) => {
      queryClient.setQueryData(getProjectTagsKey(projectId), context?.previousTags)
      toast.push({
        title: `Failed to remove tag ${tagName} from dataset ${datasetName}`,
        description: error.message,
        status: 'error',
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: getProjectTagsKey(projectId),
      })
    },
  })

  return {
    addTagDataset: addMutation.mutate,
    removeTagDataset: removeMutation.mutate,
    isLoading: addMutation.isPending || removeMutation.isPending,
    error: (addMutation.error || removeMutation.error) as UndoError | null,
  }
}
