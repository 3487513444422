import React, {useState, useCallback} from 'react'
import Link from 'next/link'
import {useRouter} from 'next/router'
import {Box, Text, useToast} from '@sanity/ui'

import {TagDetails, UpdateTagDialog, DeleteTagDialog} from '@/ui/index'
import {useRoutePath} from '@/context/index'
import {Tag, Project} from '@/types/index'

interface Props {
  project: Project
  tag: Tag
}

export function TagView({tag, project}: Props) {
  const {basePath} = useRoutePath()
  const router = useRouter()
  const toast = useToast()

  const onTagDelete = useCallback(() => {
    toast.push({title: 'Tag deleted', status: 'success'})
    router.push(`${basePath}/datasets/tags`, undefined, {shallow: true})
  }, [toast, router, basePath])

  const [editTagDialogOpen, setEditTagDialogOpen] = useState(false)
  const openEditTagDialog = useCallback(() => setEditTagDialogOpen(true), [setEditTagDialogOpen])
  const closeEditTagDialog = useCallback(() => setEditTagDialogOpen(false), [setEditTagDialogOpen])

  const [deleteTagDialogOpen, setDeleteTagDialogOpen] = useState(false)
  const openDeleteTagDialog = useCallback(
    () => setDeleteTagDialogOpen(true),
    [setDeleteTagDialogOpen]
  )
  const closeDeleteTagDialog = useCallback(
    () => setDeleteTagDialogOpen(false),
    [setDeleteTagDialogOpen]
  )

  return (
    <>
      {editTagDialogOpen && (
        <UpdateTagDialog project={project} tag={tag} closeDialog={closeEditTagDialog} mode="edit" />
      )}
      {deleteTagDialogOpen && (
        <DeleteTagDialog
          project={project}
          tag={tag}
          closeDialog={closeDeleteTagDialog}
          onDelete={onTagDelete}
        />
      )}

      <Box marginBottom={5}>
        <Link href={`${basePath}/datasets/tags`} passHref legacyBehavior>
          <Text as="a">← All tags</Text>
        </Link>
      </Box>

      <TagDetails
        project={project}
        tag={tag}
        openEditTagDialog={openEditTagDialog}
        openDeleteTagDialog={openDeleteTagDialog}
      />
    </>
  )
}
