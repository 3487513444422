import {timeToMilliseconds} from '@/utils/time'

export const STALE_TTL = timeToMilliseconds(5, 'minutes')

export function getDatasetsListKey(projectId?: string) {
  return ['project', projectId, 'datasets']
}

export function getDatasetRolesKey(projectId?: string, datasetName?: string) {
  return ['project', projectId, 'dataset', datasetName, 'roles']
}
