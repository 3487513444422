import React from 'react'
import {ProjectsOverageList, ProjectsOverageListData} from '@/ui/index'
import {useUsageContext} from '@/context/index'
import {useOrganizationCanPay} from '@/data/organizations'
import {useCurrentScopeContext} from '@/context/index'
import {useProjectsUsage} from '@/data/projects'

export function PlaygroundSummary() {
  const {currentRange, currentPeriod} = useUsageContext()
  const {org} = useCurrentScopeContext()
  const projectUsages = useProjectsUsage(
    org?.projects.filter(
      (project) => org.organizationPlan?.projectIds.includes(project.id) === false
    ),
    {
      ...currentRange,
      period: currentPeriod,
    }
  )
  const {data: canPay} = useOrganizationCanPay(org?.id)
  const usageLoading = projectUsages.some((usage) => usage.isLoading)

  const data = React.useMemo(() => {
    if (!org) {
      return []
    }
    return projectUsages
      .map(({data: usage}) => {
        if (!usage) {
          return null
        }

        const {overageResources, approachingResources} = usage
        const project = org.projects.find((proj) => proj.id === usage.projectId)
        if (!project) {
          return null
        }
        const {displayName: name, id, metadata} = project
        return {
          name,
          id,
          overageResources: overageResources || [],
          approachingResources: approachingResources || [],
          href: `/organizations/${project.organizationId}/project/${project.id}/usage`,
          projectColor: metadata?.color || '',
          isPlayground: true,
          canPay: canPay?.status === true,
        }
      })
      .filter(Boolean) as ProjectsOverageListData[]
  }, [canPay?.status, org, projectUsages])

  if (!org) {
    return null
  }
  return <ProjectsOverageList data={data} loading={usageLoading} />
}
