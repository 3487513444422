import {useQuery} from '@tanstack/react-query'
import {z} from 'zod'

const githubUrlSchema = z
  .string()
  .url()
  .regex(/github\.com/)

async function fetchReadmeContent(repoUrl: string | undefined): Promise<string> {
  if (!repoUrl) {
    throw new Error('Repository URL is required')
  }

  // Validate URL
  githubUrlSchema.parse(repoUrl)

  // Extract owner/repo from URL
  const [owner, repo] = repoUrl.split('github.com/')[1].split('/')

  // Get default branch name
  const repoResponse = await fetch(`https://api.github.com/repos/${owner}/${repo}`)
  const repoData = await repoResponse.json()
  const defaultBranch = repoData.default_branch

  // Try to fetch README.md first, then fallback to readme.md if not found
  const readmeVariants = ['README.md', 'readme.md']
  let content: string | null = null

  for (const variant of readmeVariants) {
    try {
      const response = await fetch(
        `https://raw.githubusercontent.com/${owner}/${repo}/${defaultBranch}/${variant}`
      )

      if (response.ok) {
        content = await response.text()
        break
      }
    } catch (error) {
      console.error(`Error fetching ${variant}:`, error)
    }
  }

  if (!content) {
    throw new Error('README not found in repository')
  }

  return content
}

export function useGitHubReadme(repoUrl: string | undefined) {
  return useQuery({
    queryKey: ['repo-readme', repoUrl],
    queryFn: () => fetchReadmeContent(repoUrl),
    enabled: Boolean(repoUrl),
    staleTime: 1000 * 60 * 60, // Consider data stale after 1 hour
    retry: 2,
  })
}
