import {Card, Button, Stack, Flex, Text, TextInput} from '@sanity/ui'
import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {EllipsisHorizontalIcon, SearchIcon} from '@sanity/icons'
import {take} from 'lodash'
import {ProjectTile, EmptyBlock, CreateProjectDialog} from '@/ui/index'
import {Organization, Project} from '@/types/models'
import {useRoutePath} from '@/context/index'
import {AppLink} from '@/ui/app'

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1em;
`

interface Props {
  items: Project[] | undefined
  layout?: 'list' | 'grid'
  max?: number
  org?: Organization
}

export function ProjectList({items = [], layout = 'list', max = 10, org}: Props) {
  const {basePath} = useRoutePath()
  const [showCreateProjectDialog, setShowCreateProjectDialog] = useState<boolean>(false)
  const [filter, setFilter] = useState<string>('')

  const onCloseProjectDialog = useCallback(() => {
    setShowCreateProjectDialog(false)
  }, [])

  const onCreateProject = useCallback(() => {
    setShowCreateProjectDialog(true)
  }, [])

  const handleSetFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value)
  }

  const visible = useMemo(() => {
    // Allow searching by project name, i, and description, and finally use take to limit the number of projects shown
    return take(
      items.filter(
        (item) =>
          item.displayName.toLowerCase().includes(filter.toLowerCase()) ||
          item.id.toLowerCase().includes(filter.toLowerCase())
      ),
      max
    )
  }, [items, filter, max])

  const hasProjects = items.length > 0
  const List = layout === 'grid' ? Grid : Stack
  const projectList = (
    <List space={4}>
      {visible.map((item: Project) => (
        <Card key={item.id}>
          <ProjectTile project={item} org={org} />
        </Card>
      ))}
    </List>
  )

  return (
    <>
      {items.length > max && (
        <Card marginBottom={3}>
          <TextInput
            placeholder="Search projects"
            icon={SearchIcon}
            value={filter}
            onChange={handleSetFilter}
          />
        </Card>
      )}

      <Stack space={2}>
        {hasProjects ? (
          projectList
        ) : (
          <EmptyBlock
            illustration={false}
            description={
              <Stack space={4}>
                <Flex justify="center">
                  <Text size={1} muted>
                    No projects found
                  </Text>
                </Flex>
                <Flex justify="center">
                  <Button
                    tone="primary"
                    as="a"
                    text="Create first project"
                    onClick={onCreateProject}
                    target="_blank"
                  />
                </Flex>
              </Stack>
            }
          />
        )}

        {items.length > max && (
          <Card radius={2} shadow={1} marginTop={3}>
            <AppLink
              href={
                basePath
                  ? `${basePath}/projects`
                  : `${
                      items[0].organizationId
                        ? `organizations/${items[0].organizationId}`
                        : 'manage/personal'
                    }/projects`
              }
            >
              <Button
                as="span"
                mode="bleed"
                tone="default"
                icon={EllipsisHorizontalIcon}
                label="Show all projects"
                style={{width: '100%'}}
              />
            </AppLink>
          </Card>
        )}
      </Stack>

      {showCreateProjectDialog && <CreateProjectDialog onClose={onCloseProjectDialog} />}
    </>
  )
}
