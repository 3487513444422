import React from 'react'
import {Container} from '@sanity/ui'
import {EmptyBlock} from '@/ui/index'

export function NotFound({heading, message}: {heading?: string; message?: string}) {
  return (
    <>
      <Container>
        <EmptyBlock
          illustration="error"
          title={heading || 'Oops! Page not found.'}
          description={message || 'The content you tried to access does not exist.'}
        />
      </Container>
    </>
  )
}
