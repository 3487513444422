import React, {useCallback} from 'react'
import {Button, Box, Stack, Text} from '@sanity/ui'
import {EllipsisHorizontalIcon} from '@sanity/icons'
import {format} from 'date-fns'
import {AnimatePresence, motion} from 'framer-motion'

import {ActivityTile} from './activityTile'
import {ActivityEvent} from '@/types/index'
import {sortEvents} from '@/utils/index'
import {useCurrentScopeContext} from '@/context/index'

type Props = {
  loading?: boolean
  lastUpdated?: Date
  hasNextPage?: boolean
  currentPage?: number
  events: ActivityEvent[]
  onNextPage?: () => void
  compact?: boolean
}

export function ActivityList({
  events = [],
  loading,
  onNextPage,
  lastUpdated,
  hasNextPage,
  currentPage = 0,
  compact = false,
}: Props) {
  const context = useCurrentScopeContext()
  const hasEvents = events.length > 0

  const handleLoadOlder = useCallback(() => {
    if (onNextPage) onNextPage()
  }, [onNextPage])

  return (
    <Stack>
      {hasEvents && (
        <Box paddingTop={3} marginBottom={3}>
          {lastUpdated && (
            <Text size={1} align="center" muted>
              Last Updated: {format(lastUpdated, "MMMM d 'at' p")}
            </Text>
          )}
        </Box>
      )}
      {!hasEvents && !loading && (
        <Box padding={6} key="activityNotFoundMessage">
          <Text muted size={1} align="center">
            No activity found...
          </Text>
        </Box>
      )}
      <AnimatePresence>
        {hasEvents &&
          sortEvents(events).map((event, index) => (
            <motion.div
              key={event.id}
              initial={currentPage > 0 ? {opacity: 0} : {}}
              animate={currentPage > 0 ? {opacity: 1} : {}}
            >
              <ActivityTile
                borderBottom={index < events.length - 1}
                event={event}
                compact={compact}
                disableFilter={context?.scope === 'global'}
              />
            </motion.div>
          ))}
      </AnimatePresence>

      {hasEvents && (
        <Box paddingTop={4}>
          <Button
            loading={loading}
            disabled={!hasNextPage}
            icon={EllipsisHorizontalIcon}
            mode="ghost"
            padding={3}
            text={'Load older events'}
            style={{width: '100%'}}
            onClick={handleLoadOlder}
          />
        </Box>
      )}
    </Stack>
  )
}
