import {useCurrentScopeContext, useRoutePath} from '@/context/index'
import {
  GrowthBook,
  GrowthBookProvider,
  useFeatureIsOn,
  useGrowthBook as useGrowthBookContext,
} from '@growthbook/growthbook-react'
import {ReactElement, useEffect, useMemo, useState} from 'react'
import {sendExperimentTrackingEvent} from '.'
import {isServer} from '../general'

const GROWTHBOOK_CLIENT_KEY = process.env.NEXT_PUBLIC_GROWTHBOOK_CLIENT_KEY || ''

export const REQUEST_ACCESS_TO_PROJECT_FLAG = 'request-access-to-project'
export const ACCESS_MANAGEMENT_FEATURE_FLAG = 'manage-centralized-access-management'
export const GET_STARTED_FEATURE_FLAG = 'manage-getting-started-xp'
export const DASHBOARD_ROLLOUT_FEATURE_FLAG = 'manage-dashboard-rollout'

function useGrowthBook() {
  const {project, org} = useCurrentScopeContext()

  const growthBook = useMemo(() => {
    if (!GROWTHBOOK_CLIENT_KEY) {
      return undefined
    }
    return new GrowthBook({
      apiHost: 'https://cdn.growthbook.io',
      clientKey: GROWTHBOOK_CLIENT_KEY,
      subscribeToChanges: false,

      trackingCallback: (experiment, result) => {
        // Rudderstack tracking
        sendExperimentTrackingEvent({
          projectId: project?.id,
          organizationId: org?.id,
          experimentId: experiment.key,
          variationId: result.variationId,
        })
      },
      backgroundSync: false,
      // eslint-disable-next-line no-process-env
      enableDevMode: process.env.NODE_ENV === 'development',
    })
  }, [project, org])

  return growthBook
}

export default function GrowthBookContext({children}: {children: ReactElement}) {
  const {tabId, headingId, asPath} = useRoutePath()
  const growthbook = useGrowthBook()
  const scope = useCurrentScopeContext()
  const {currentOrgId, currentProjectId, currentUser, loading} = scope || {}
  // Save project and org id to local state and use useeffect to update growthbook
  const [projectId, setProjectId] = useState(currentProjectId)
  const [orgId, setOrgId] = useState(currentOrgId)

  useEffect(() => {
    // Only change if the value has changed
    if (currentProjectId !== projectId) {
      setProjectId(currentProjectId)
    }
    if (currentOrgId !== orgId) {
      setOrgId(currentOrgId)
    }
  }, [currentProjectId, currentOrgId, projectId, orgId])

  useEffect(() => {
    growthbook?.loadFeatures()
  }, [growthbook])

  useEffect(() => {
    if (loading === false) {
      // Attributes used in experiments/feature flag rules.
      growthbook?.setAttributes({
        userId: currentUser?.id,
        organizationId: orgId,
        projectId: projectId,
      })
    }
    if (isServer === false) {
      const searchParams = new URLSearchParams(window.location.search)
      const forcedFeatures = new Map<string, boolean | string>(searchParams)
      for (const [key, value] of forcedFeatures) {
        forcedFeatures.set(key, value === 'true' ? true : value === 'false' ? false : value)
      }
      growthbook?.setForcedFeatures(forcedFeatures)
    }
  }, [headingId, tabId, loading, asPath, currentUser, growthbook, orgId, projectId])
  return <GrowthBookProvider growthbook={growthbook}>{children}</GrowthBookProvider>
}

export function useFeatureFlag(flag: string) {
  const isFeatureOn: boolean = useFeatureIsOn(flag)
  const growthbook = useGrowthBookContext()
  const loaded: boolean = growthbook?.ready || false

  return [isFeatureOn, loaded]
}
