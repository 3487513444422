import {MenuButton, Button, Menu, MenuItem, MenuDivider, Box, Label} from '@sanity/ui'
import React from 'react'
import {HelpCircleIcon} from '@sanity/icons'
import {useConfig} from '@/context/index'
import {AppLink} from '../appLink'
import {AnnouncementsMenuItem} from '@/ui/ui-components/features/announcements/AnnouncementsMenuItem'

const MenuItemLink = ({href, text}: {href: string; text: string}) => {
  return (
    <AppLink href={href} passHref legacyBehavior>
      <MenuItem muted as={'a'} text={text} style={{width: '100%'}} />
    </AppLink>
  )
}

export function HelpMenu() {
  const {baseUrl} = useConfig()
  return (
    <MenuButton
      button={<Button mode={'bleed'} icon={HelpCircleIcon} />}
      id={'help-button'}
      menu={
        <Menu>
          <Box paddingX={3} paddingY={2}>
            <Label muted size={1}>
              Developer Resources
            </Label>
          </Box>
          <MenuItemLink href={`${baseUrl}/docs`} text={'Documentation'} />
          <MenuItemLink href={`${baseUrl}/changelog`} text={'Changelog'} />
          <AnnouncementsMenuItem text="What's new" />
          <MenuDivider />
          <Box padding={3} paddingY={2}>
            <Label muted size={1}>
              Get in touch
            </Label>
          </Box>
          <MenuItemLink href="https://slack.sanity.io/" text={'Join our community'} />
          <MenuItemLink href={`${baseUrl}/contact/support`} text={'Help and support'} />
          <MenuItemLink href={`${baseUrl}/contact/sales?ref=manage`} text={'Contact sales'} />
        </Menu>
      }
    />
  )
}
