import React from 'react'
import {ProjectMemberState} from '@/utils/usage'
import {LimitReachedUpgradeBanner} from '../banner/limitReachedUpgradeBanner'

const data = {
  users: {
    unavailableMemberType: 'members',
    availableMemberType: null,
  },
  // eslint-disable-next-line camelcase
  non_admin_users: {
    unavailableMemberType: 'members without administrative access',
    availableMemberType: 'administrators',
  },
  // eslint-disable-next-line camelcase
  non_viewer_users: {
    unavailableMemberType: 'members with editing access',
    availableMemberType: 'viewers',
  },
}

export function NoSeatsAvailableWarning({resource}: ProjectMemberState) {
  const {unavailableMemberType, availableMemberType} = data[resource]

  return (
    <LimitReachedUpgradeBanner
      description={`Your project has reached its limits for ${unavailableMemberType}.
    ${availableMemberType && ` You can still invite ${availableMemberType}.`}`}
    />
  )
}
