import {useCurrentScopeContext} from '@/context/index'
import {
  createDataset,
  createOrg,
  createProject,
  deleteProject,
  fetchCouponPlans,
} from '@/data/index'
import {getProjectsKey} from '@/data/projects/cache'
import {APIError} from '@/data/util/request'
import type {Plan, Project} from '@/types/index'
import {sendAmplitudeTrackingEvent} from '@/utils/tracking'
import {AddIcon, CaseIcon, SelectIcon, UserIcon} from '@sanity/icons'
import {
  Badge,
  Box,
  Button,
  Container,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  Stack,
  Text,
  TextInput,
  useToast,
} from '@sanity/ui'
import {useQueryClient} from '@tanstack/react-query'
import {useRouter} from 'next/router'
import {forwardRef, useEffect, useImperativeHandle, useMemo, useState} from 'react'
import {useForm} from 'react-hook-form'
import {firstValueFrom} from 'rxjs'
import {FormField} from '../formField'
import {EditContainer} from './editContainer'
import {useOrganizationsList} from '@/data/organizations/useOrganizations'
import {useCurrentUserPermissions} from '../../../../data/access/useAccessPermissions'
export const MANAGE_INTEGRATION_VALUE = 'manage'
export const DEFAULT_DATASET_NAME = 'production'

type SetupType = 'cli' | 'quickstart'

type CreateNewProjectData = {
  organizationId: string
  createNewOrg: boolean
  organizationName: string
  displayName: string
  setupType: SetupType
}

type Props = {
  onCreate?: (project: Project, setupType: SetupType) => void
}

const CreateNewProject = forwardRef((props: Props, ref) => {
  const toast = useToast()
  const scope = useCurrentScopeContext()
  const queryClient = useQueryClient()
  const router = useRouter()
  const {refetch: refetchOrganziations} = useOrganizationsList()

  const [loading, setLoading] = useState<boolean>(false)
  const {
    register,
    watch,
    formState: {errors},
    trigger,
    setError,
    setValue,
    handleSubmit,
  } = useForm<CreateNewProjectData>({
    defaultValues: {
      organizationId: scope?.org?.id,
      createNewOrg: false,
      organizationName: '',
      displayName: '',
      setupType: 'cli',
    },
  })
  const orgs = useMemo(() => scope?.orgs || [], [scope])
  const {organizationId, createNewOrg} = watch()

  const {data: perms = []} = useCurrentUserPermissions()

  const accessibleOrgs = useMemo(() => {
    return orgs.filter((org) => {
      if (org.id === 'personal') return true

      const canAttachProjectToOrg = perms.find(
        (p) => p.name === 'sanity.organization.projects.attach' && p.resourceId === org.id
      )

      return canAttachProjectToOrg !== undefined
    })
  }, [orgs, perms])

  useEffect(() => {
    if (accessibleOrgs.length === 0) {
      setValue('createNewOrg', true)
    }
  }, [accessibleOrgs])

  const selectedOrg = useMemo(
    () => orgs.find((org) => org.id === organizationId),
    [organizationId, orgs]
  )
  const [couponPlan, setCouponPlan] = useState<Plan | null>(null)
  const numberOfDisabledOrgs = orgs.length - accessibleOrgs.length

  useEffect(() => {
    const fetchCoupon = async () => {
      const couponCode = router.query.coupon as string
      if (!couponCode) return

      try {
        const plans = await firstValueFrom(fetchCouponPlans(couponCode))
        const plan = plans?.[0]
        setCouponPlan(plan)

        if (plan) {
          toast.push({
            id: 'coupon-applied',
            title: 'Coupon applied',
            description: `Coupon ${couponCode} applied successfully`,
            status: 'success',
          })
          sendAmplitudeTrackingEvent('Coupon Validated', undefined, organizationId, {
            userId: scope?.currentUser?.id,
            targetPlan: plan.id,
            coupon: couponCode,
          })
        } else {
          toast.push({
            id: 'coupon-error',
            title: 'Coupon could not be applied',
            description: `Coupon ${couponCode} is invalid or expired`,
            status: 'error',
          })
        }
      } catch (err) {
        toast.push({
          id: 'coupon-error',
          title: 'Coupon could not be applied',
          description: `Coupon ${couponCode} is invalid or expired`,
          status: 'error',
        })
      }
    }
    if (!(organizationId && scope?.currentUser?.id)) {
      return
    }
    fetchCoupon()
  }, [router.query.coupon, scope?.currentUser?.id, toast, organizationId])

  const onSubmit = async ({
    organizationId,
    organizationName,
    createNewOrg,
    displayName,
    setupType,
  }: CreateNewProjectData): Promise<void> => {
    let createdProjectId: string | undefined
    if (!organizationId && !createNewOrg) {
      setError('organizationId', {message: 'This field is required'})
      return
    }

    try {
      setLoading(true)

      if (createNewOrg) {
        const createdOrg = await createOrg({name: organizationName})
        if (!createdOrg?.id) {
          throw new Error('Organization creation failed')
        }
        organizationId = createdOrg.id
      }

      const createdProject = await createProject({
        displayName,
        organizationId: organizationId === 'personal' ? undefined : organizationId,
        metadata: {
          integration: MANAGE_INTEGRATION_VALUE,
          coupon: couponPlan?.id,
        },
        subscription: couponPlan
          ? {
              planId: couponPlan.id,
            }
          : undefined,
      })
      // To avoid waiting for the dataset creation to finish, we just patch the createdProject object
      createdProject.datasets = [{name: DEFAULT_DATASET_NAME}]

      await createDataset(createdProject.id, DEFAULT_DATASET_NAME, {
        aclMode: 'public',
      })

      if (props.onCreate) {
        await refetchOrganziations()
        props.onCreate(createdProject, setupType)
      }
    } catch (err) {
      setLoading(false)

      if (createdProjectId) {
        await deleteProject(createdProjectId)
      }

      if (err instanceof APIError && err.statusCode === 400) {
        const nError = new Error(err.message)
        nError.name = 'InvalidDataError'

        toast.push({
          title: 'Error',
          description: nError.message,
          status: 'error',
        })

        throw nError
      }

      toast.push({
        title: 'Error',
        description: err instanceof Error ? err.message : 'Unexpected error',
        status: 'error',
      })
    } finally {
      queryClient.invalidateQueries({queryKey: getProjectsKey()})
    }
  }

  const submitHandler = handleSubmit(onSubmit)
  const handleForm = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    return submitHandler()
  }

  let createdProject: Project | undefined

  useImperativeHandle(ref, () => ({
    validate() {
      return trigger()
    },
    submit: async () => {
      await submitHandler()
      return createdProject
    },
  }))

  return (
    <Container>
      <Box>
        <Stack space={5}>
          <EditContainer
            onSave={handleForm}
            primaryButtonText="Create project"
            loading={loading}
            alignButtonsRight
            noCancel
          >
            <FormField
              required
              label="Project name"
              error={errors.displayName && 'This field is required'}
            >
              <TextInput
                placeholder={'Project name'}
                disabled={loading}
                fontSize={2}
                {...register('displayName', {required: true})}
              />
            </FormField>

            <FormField
              label="Organization"
              description="Select the organization the project should belong to"
              error={errors.organizationId && 'This field is required'}
              required
            >
              <MenuButton
                id="organizationId"
                button={
                  <Button
                    text={
                      selectedOrg?.name ||
                      (createNewOrg && 'New organization') ||
                      'Select organization'
                    }
                    padding={3}
                    mode="ghost"
                    iconRight={SelectIcon}
                    justify={'space-between'}
                    disabled={loading}
                  />
                }
                menu={
                  <Menu>
                    {accessibleOrgs.map((org) => {
                      const isPersonal = org.id === 'personal'
                      return (
                        <MenuItem
                          key={org.id}
                          value={org.id}
                          onClick={() => {
                            setValue('organizationId', org.id)
                            setValue('createNewOrg', false)
                          }}
                          selected={organizationId === org.id}
                          icon={isPersonal ? <UserIcon /> : <CaseIcon />}
                          text={
                            isPersonal ? (
                              <Flex gap={2} justify={'space-between'} align={'center'}>
                                {org.name}
                                <Badge tone="primary">Personal</Badge>
                              </Flex>
                            ) : (
                              org.name
                            )
                          }
                        />
                      )
                    })}
                    <MenuItem
                      value={'createNewOrg'}
                      onClick={() => {
                        setValue('createNewOrg', true)
                        setValue('organizationId', '')
                      }}
                      icon={<AddIcon />}
                      text={'Create new organization'}
                    />

                    {numberOfDisabledOrgs > 0 && (
                      <Box padding={3}>
                        <Text muted size={1}>
                          {`${numberOfDisabledOrgs} organizations are hidden due to insufficient permissions`}
                        </Text>
                      </Box>
                    )}
                  </Menu>
                }
                popover={{portal: true, placement: 'bottom-start', matchReferenceWidth: true}}
              />
            </FormField>

            {createNewOrg && (
              <FormField
                required
                label="Organization name"
                error={errors.organizationName && 'This field is required'}
              >
                <TextInput
                  placeholder={'Organization name'}
                  disabled={loading}
                  fontSize={2}
                  {...register('organizationName', {required: createNewOrg})}
                />
              </FormField>
            )}
          </EditContainer>
        </Stack>
      </Box>
    </Container>
  )
})

CreateNewProject.displayName = 'CreateNewProject'
export {CreateNewProject}
