import React from 'react'
import {Card, Label, useTheme} from '@sanity/ui'
import styled from 'styled-components'
import {Mastercard, Visa, Amex} from '@/components/general/svg'

export type BrandType =
  | 'visa'
  | 'mastercard'
  | 'amex'
  | 'discover'
  | 'diners'
  | 'jcb'
  | 'unionpay'
  | 'unknown'
  | undefined
interface Props {
  faded?: boolean
  brandType: BrandType
}

type BrandIcon = {
  id: string
  icon: JSX.Element
}

interface WrapperProps {
  $faded?: boolean
  $s?: boolean
}

const CardWrapper = styled.div<WrapperProps>`
  display: flex;
  filter: ${(props: WrapperProps) => props.$faded && `grayscale(1)`};
  opacity: ${(props: WrapperProps) => props.$faded && `0.3`};
  align-self: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center;

  svg {
    width: 100%;
    height: 100%;
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
  }

  > span {
    margin: auto;
  }
`

export const CardSingleBrand = ({brandType, faded}: Props) => {
  const {sanity} = useTheme()
  const {color} = sanity
  const BRANDS: BrandIcon[] = [
    {
      id: 'visa',
      icon: <Visa />,
    },
    {
      id: 'mastercard',
      icon: <Mastercard />,
    },
    {
      id: 'amex',
      icon: <Amex />,
    },
  ]
  const brand = BRANDS.find((match) => match.id === brandType)
  const brandIcon = brand ? (
    brand.icon
  ) : (
    <Label color={color.card.selected.fg} as="span" size={2}>
      Other
    </Label>
  )

  return (
    <Card
      paddingX={2}
      paddingY={0}
      radius={2}
      border
      style={{
        display: 'flex',
        width: '65px',
        height: '45px',
        padding: '10px 5px',
      }}
    >
      <CardWrapper $faded={faded}>{brandIcon}</CardWrapper>
    </Card>
  )
}
