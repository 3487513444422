import {IconSymbol} from '@sanity/icons'
import {ThemeColorToneKey} from '@sanity/ui'

export type StatusConfigContext = 'members'

export type StatusType = {
  title: string
  message: string
  cta?: 'transfer-project' | 'add-billing-details' | 'contact-sales' | 'visit-slack-community'
  tone: ThemeColorToneKey
  icon: IconSymbol
}

export const statusConfig = ({
  resourcesText,
  planName,
  isPlayground,
  isPersonal,
  isRegular,
  isCustomPlan,
  isOrgPlan,
  isLocked,
  canPay,
  isApproaching,
  hasOverage,
  hasOverageHardLimit,
  isNonAdminUsersAtQuota,
  context,
}: {
  resourcesText: string
  planName: string
  isPlayground: boolean | undefined
  isPersonal: boolean
  isRegular: boolean
  isCustomPlan?: boolean
  isOrgPlan: boolean
  isLocked: boolean | undefined
  canPay: boolean | undefined
  isApproaching: boolean
  hasOverage: boolean
  hasOverageHardLimit: boolean
  isNonAdminUsersAtQuota: boolean
  context?: StatusConfigContext
}): StatusType | undefined => {
  const variants: Record<string, StatusType> = {
    playgroundApproaching: {
      title: 'Project approaching quota',
      message: `Your project is approaching the limit for ${resourcesText} on the ${planName} plan. Your project will be blocked from use if it exceeds quota. Contact sales for more information.`,
      cta: 'contact-sales',
      tone: 'caution',
      icon: 'warning-outline',
    },
    playgroundExceeding: {
      title: 'Project exceeding quota',
      message: `Your project is exceeding the limit for ${resourcesText} on the ${planName} plan and might be blocked from further use. Contact sales for more information.`,
      cta: 'contact-sales',
      tone: 'caution',
      icon: 'warning-outline',
    },
    playgroundLocked: {
      title: 'Project locked',
      message: `Your project has been locked from further use due to exceeding quota limits. Contact sales for more information.`,
      cta: 'contact-sales',
      tone: 'critical',
      icon: 'info-outline',
    },
    personalApproaching: {
      title: 'Project approaching quota',
      message: `Your project is approaching the limit for ${resourcesText} on the ${planName} plan. Please add billing information to enable overage usage for this project.`,
      cta: 'transfer-project',
      tone: 'caution',
      icon: 'warning-outline',
    },
    personalOverage: {
      title: 'Project exceeding quota',
      message: `Your project is exceeding the limit for ${resourcesText} on the ${planName} plan. Please add billing information to enable overage usage for this project.`,
      cta: 'transfer-project',
      tone: 'caution',
      icon: 'warning-outline',
    },
    orgApproachingNoPayment: {
      title: 'Project approaching quota',
      message: `Your project is approaching the limit for ${resourcesText} on the ${planName} plan. Please add billing information to enable overage usage for this project.`,
      cta: 'add-billing-details',
      tone: 'caution',
      icon: 'warning-outline',
    },
    orgOverageNoPayment: {
      title: 'Project exceeding quota',
      message: `Your project is exceeding the limit for ${resourcesText} on the ${planName} plan. Please add billing information to enable overage usage for this project.`,
      cta: 'add-billing-details',
      tone: 'caution',
      icon: 'warning-outline',
    },
    orgOverageWithPayment: {
      title: 'Project exceeding quota',
      message: `Your project is exceeding the limit for ${resourcesText} on the ${planName} plan. Any usage above quota will be charged overages according to your plan.`,
      tone: 'positive',
      icon: 'overage',
    },
    orgOverageHardLimit: {
      title: 'Project limit reached',
      message: `Your project has reached the limit (including overage) for ${resourcesText} on the ${planName} plan. Your project may be blocked from further use. Please visit our Slack community for support.`,
      cta: 'visit-slack-community',
      tone: 'caution',
      icon: 'warning-outline',
    },
    orgPlanHardLimitOverage: {
      title: 'Project limit reached',
      message: `Your project has reached the limit for ${resourcesText} (including overage) on the ${planName} plan. Your project may be blocked from further use. Please contact sales for support.`,
      cta: 'contact-sales',
      tone: 'caution',
      icon: 'warning-outline',
    },
    nonAdminUsersLocked: {
      title: 'Missing billing information',
      message: `You will be able to add additional non-admin members when billing information is added.`,
      cta: 'add-billing-details',
      tone: 'caution',
      icon: 'warning-outline',
    },
    default: {
      title: '',
      message: `Your organization is exceeding the limit for ${planName}. Any usage above quota might be charged overages according to your plan.`,
      tone: 'positive',
      icon: 'overage',
    },
  }

  //playground
  if (isPlayground) {
    if (isLocked) {
      return variants.playgroundLocked
    }
    if (hasOverage) {
      return variants.playgroundExceeding
    }
    if (isApproaching) {
      return variants.playgroundApproaching
    }
  }

  // personal
  if (isPersonal) {
    if (hasOverage) {
      return variants.personalOverage
    }
    if (isApproaching) {
      return variants.personalApproaching
    }
  }

  //org/org with org-plan (with payment details)
  if ((isRegular || isOrgPlan) && canPay) {
    if (hasOverage) {
      return variants.orgOverageWithPayment
    }
  }

  //org/org with org-plan (no payment details)
  if ((isRegular || isOrgPlan) && !canPay && !isCustomPlan) {
    if (hasOverage) {
      return variants.orgOverageNoPayment
    }
    if (isApproaching) {
      return variants.orgApproachingNoPayment
    }
  }

  //org hard quota reached
  if (isRegular && !isOrgPlan && hasOverageHardLimit) {
    return variants.orgOverageHardLimit
  }

  //org with org-plan hard quota reached
  if (isOrgPlan && !isRegular && hasOverageHardLimit) {
    return variants.orgPlanHardLimitOverage
  }

  if (hasOverage && canPay) {
    return variants.default
  }

  if (!canPay && isNonAdminUsersAtQuota && context === 'members') {
    return variants.nonAdminUsersLocked
  }

  return undefined
}
