import {purple} from '@sanity/color'
import {Button, ButtonProps, Card, Flex, Text} from '@sanity/ui'
import styled from 'styled-components'
import {BoltIcon} from '@sanity/icons'
import {useRoutePath} from '@/context/index'
import Link from 'next/link'

const PurpleCard = styled(Card)`
  --card-bg-color: ${purple['50'].hex};
  --card-border-color: ${purple['100'].hex} !important;
  --card-fg-color: ${purple['700'].hex};
`

const LinkButton = ({href, target, ...props}: ButtonProps & {href: string; target?: string}) => {
  return (
    <Link href={href} target={target} passHref legacyBehavior>
      <Button as="a" {...props} />
    </Link>
  )
}

const PurpleCTA = styled(LinkButton)`
  --card-bg-color: ${purple['50'].hex};
  --card-border-color: ${purple['200'].hex} !important;
  --card-fg-color: ${purple['700'].hex};
  &:hover {
    --card-bg-color: ${purple['100'].hex} !important;
    --card-fg-color: ${purple['700'].hex} !important;
  }
`

export function LimitReachedUpgradeBanner({
  description,
  ctaText = 'View plans',
  showCTA = true,
}: {
  description: string
  ctaText?: string
  showCTA?: boolean
}) {
  const {basePath} = useRoutePath()
  return (
    <PurpleCard padding={4} border radius={2}>
      <Flex gap={[4, 2]} align={['flex-start', 'center']} direction={['column', 'row']}>
        <Flex flex={1} wrap={'wrap'}>
          <Text size={1}>{description}</Text>
        </Flex>
        {showCTA && (
          <PurpleCTA
            fontSize={1}
            mode="ghost"
            href={`${basePath}/plan`}
            text={ctaText}
            icon={BoltIcon}
          />
        )}
      </Flex>
    </PurpleCard>
  )
}
