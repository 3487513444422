export function nameToInitials(fullName: string | undefined, forceDoubleInitials = false) {
  if (!fullName) {
    return undefined
  }
  const namesArray = fullName.split(' ')

  if (namesArray.length === 1) {
    let initials = `${namesArray[0].charAt(0)}`
    if (forceDoubleInitials) {
      initials += `${namesArray[0].charAt(1)}`
    }
    return initials
  }

  return `${namesArray[0].charAt(0)}${namesArray[namesArray.length - 1].charAt(0)}`
}
