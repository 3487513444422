/* eslint-disable react/display-name */
import React, {forwardRef, useCallback, useEffect, useRef} from 'react'
import styled from 'styled-components'
import {Stack, Box, Card as UICard, ButtonProps} from '@sanity/ui'
import {LIST_MAX_HEIGHT} from './config'
import {Separator} from '@/components/general'

type Props = {
  children: React.ReactNode
  level?: number
  offset?: number
  // eslint-disable-next-line react/no-unused-prop-types
  buttonProps?: ButtonProps | undefined
  button: React.ReactNode
}

const OuterCard = styled(UICard)<{$level: number; $offset: number}>`
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  left: 0;
  top: 0;
  overflow: hidden;
  min-width: 350px;
  transform: ${({$level: level, $offset: offset}) => `translate(${level * 100}%, ${offset}px)`};
`

const ScrollWrapper = styled(Box)`
  overflow-y: scroll;
  max-height: ${LIST_MAX_HEIGHT}px;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 0;
  }
`

export const DrawerList = forwardRef<HTMLDivElement | null, Props>((props, ref) => {
  const {children, level = 0, offset = 0, button} = props
  const listRef = useRef<HTMLDivElement>()

  const handleHasOverflow = useCallback(() => {
    const {current} = listRef
    if (!current) return
  }, [listRef])

  const handleScroll = useCallback((e: Event) => {
    const {current} = listRef
    if (!current) return
    e.preventDefault()
  }, [])

  useEffect(() => {
    handleHasOverflow()
  }, [])

  useEffect(() => {
    const {current} = listRef
    current?.addEventListener('scroll', handleScroll)
    return () => current?.removeEventListener('scroll', handleScroll)
  }, [listRef, handleScroll])

  return (
    <OuterCard $level={level} $offset={offset} shadow={3} radius={2} ref={ref}>
      <ScrollWrapper ref={listRef as any}>
        <Stack as="ul" paddingY={2}>
          {children}
        </Stack>
      </ScrollWrapper>
      <Box paddingX={2}>
        <Separator />
      </Box>
      <Box padding={2}>{button}</Box>
    </OuterCard>
  )
})
