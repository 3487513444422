import React, {useCallback, useEffect, useRef, useState} from 'react'
import {of, Subscription} from 'rxjs'
import {catchError} from 'rxjs/operators'
import {switchProjectPlan} from '.'

type Features = string[]

type SwitchState = {
  isSwitched: boolean
  error?: any
  loading: boolean
}

type SwitchPlan = (projectId: string, wantedPlanId: string, features: Features) => void

export function useSwitchProjectPlan(): [SwitchState, SwitchPlan] {
  const [isSwitched, setIsSwitched] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<Error | undefined>(undefined)

  const sub: React.MutableRefObject<Subscription | undefined> = useRef()

  useEffect(
    () => () => {
      if (sub.current) {
        sub.current.unsubscribe()
      }
    },
    []
  )

  const _switchProject = useCallback((projectId, wantedPlanId, features: Features = []) => {
    setIsSwitched(false)
    setError(undefined)
    setLoading(true)

    sub.current = switchProjectPlan(projectId, wantedPlanId, features)
      .pipe(
        catchError((err) => {
          setLoading(false)
          setError(err)
          return of(undefined)
        })
      )
      .subscribe((next) => {
        if (next) {
          setIsSwitched(true)
        }
        setLoading(false)
        setError(undefined)
        sub.current?.unsubscribe()
      })
  }, [])
  return [{isSwitched, loading, error}, _switchProject]
}
