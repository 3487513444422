import {useCurrentScopeContext} from '@/context/useCurrentScopeContext'
import {Box, Container, Stack} from '@sanity/ui'
import {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {
  LessonAccordion,
  LessonAccordionSkeleton,
} from '@/ui/components/get-started/components/LessonAccordion'
import {useProjectLessonProgress} from '@/data/journey/useProjectLessonProgress'
import {useUserOnboardingData} from '@/data/journey/useUserOnboardingData'
import {motion, AnimatePresence} from 'framer-motion'
import {ProgressFooter} from '@/ui/components/get-started/components/ProgressFooter'
import {useLayoutPortal} from '@/ui/app/layout/defaultLayout'
import confetti from 'canvas-confetti'
import {ResourceCards} from '@/ui/components/get-started/components/ResourceCards'
import {sendAmplitudeTrackingEvent} from '@/utils/tracking'
import {CourseFeedback} from '@/ui/components/get-started/components/CourseFeedback'
import {type Tab, type Lesson, useGettingStarted} from '@/data/projects/useGettingStarted'
import {parseMarkdownToLessons} from '@/ui/components/get-started/components/portableText/markdownToPortableText'
import {TemplateCard} from '@/ui/components/get-started/components/TemplateCard'
import type {TemplateData} from '@/data/projects/useTemplateData'
import {useGitHubReadme} from '@/data/projects/useGitHubReadme'
import {AllSetHeading} from '@/ui/components/get-started/components/AllSetHeading'
import {GetStartedHeading} from '@/ui/components/get-started/components/GetStartedHeading'
import {NoTemplateLessonsFallback} from '@/ui/components/get-started/components/NoTemplateLessonsFallback'
import smoothScrollIntoView from 'smooth-scroll-into-view-if-needed'

export type AccordionState = number | 'all-closed' | 'uninitialized'

const MotionBox = motion(Box)
const MotionStack = motion(Stack)

export function TemplateView({template}: {template: TemplateData}) {
  const [accordionState, setAccordionState] = useState<AccordionState>('uninitialized')

  const headerRef = useRef<HTMLDivElement>(null)
  const {projectId, org} = useCurrentScopeContext()
  const {data: readme, isLoading: readmeLoading} = useGitHubReadme(template?.repository)
  const {data: gettingStartedData} = useGettingStarted()

  const {course}: Tab = useMemo(
    () => ({
      _key: 'getting-started',
      title: 'Getting Started',
      slug: {_type: 'slug', current: 'getting-started'},
      course: {
        _id: 'template-course',
        title: `Getting Started with template "${template?.title}"`,
        slug: {_type: 'slug', current: 'template-course'},
        visibility: 'manage',
        lessons: readme ? parseMarkdownToLessons(readme) : [],
        related: gettingStartedData?.related || [], // Fallback to related resources from the getting started document
      },
    }),
    [gettingStartedData?.related, readme, template?.title]
  )

  const {isLoading: onboardingLoading} = useUserOnboardingData()
  const {
    data: lessonProgress,
    isLoading: progressLoading,
    update: updateLessonProgress,
    replace: replaceLessonProgress,
  } = useProjectLessonProgress(projectId!)
  const {addPortal, removePortal} = useLayoutPortal()
  const lessonProgressSet = useMemo(() => new Set(lessonProgress?.progress), [lessonProgress])

  const firstUncompletedIndex = course.lessons.findIndex(({_id}) => !lessonProgressSet.has(_id))

  const hasLessons = Boolean(course?.lessons?.length)
  const isEveryLessonCompleted = hasLessons
    ? course.lessons.every((lesson) => lessonProgressSet.has(lesson._id))
    : false // If there are no lessons, we consider the course as not completed

  const setCompletedUptoStep = (lesson: Lesson) => {
    const currentIndex = course?.lessons.findIndex((l) => l._id === lesson._id)
    const previousSteps = course?.lessons.slice(0, currentIndex).map((l) => l._id)
    if (!previousSteps) return
    for (const step of previousSteps) {
      if (!lessonProgressSet.has(step)) {
        const stepLesson = course?.lessons.find((l) => l._id === step)
        if (!stepLesson) return
        sendAmplitudeTrackingEvent('Getting Started Section Completed', projectId, org?.id, {
          course_name: `(TEMPLATE) ${template.slug.current}`,
          lesson_name: stepLesson.title,
        })
      }
    }
    replaceLessonProgress(previousSteps)
  }

  const handleSetCompleteStep = (lesson: Lesson) => {
    updateLessonProgress(lesson._id)
    const updatedProgressSet = new Set(lessonProgressSet)
    updatedProgressSet.add(lesson._id)
    const isEveryLessonCompleted = course?.lessons?.every((l) => updatedProgressSet.has(l._id))

    if (isEveryLessonCompleted) {
      setAccordionState('all-closed')
      setTimeout(() => smoothScrollIntoView(headerRef!.current!, {block: 'start'}), 0) // Next tick
    } else {
      setAccordionState((p) => (p === 'all-closed' || p === 'uninitialized' ? 'all-closed' : p + 1))
    }
    sendAmplitudeTrackingEvent('Getting Started Section Completed', projectId, org?.id, {
      course_name: `(TEMPLATE) ${template.slug.current}`,
      lesson_name: lesson.title,
    })

    if (isEveryLessonCompleted) {
      sendAmplitudeTrackingEvent('Getting Started Course Completed', projectId, org?.id, {
        course_name: `(TEMPLATE) ${template.slug.current}`,
        lesson_name: lesson.title,
      })
      setTimeout(() => confetti(), 1000)
    }
  }

  const handleToggleAccordion = (index: number) => {
    setAccordionState((prev) => (prev === index ? 'all-closed' : index))
    const lesson = course?.lessons[index]
    if (lesson) setCompletedUptoStep(lesson)
  }

  useEffect(() => {
    if (accordionState !== 'uninitialized' || progressLoading) {
      return
    }

    const hasLessonProgress = lessonProgressSet.size > 0
    if (hasLessonProgress && firstUncompletedIndex === -1) {
      return setAccordionState('all-closed')
    }
    setAccordionState(firstUncompletedIndex === -1 ? 0 : firstUncompletedIndex)
  }, [firstUncompletedIndex, progressLoading, lessonProgressSet, accordionState])

  const progressFooter = useCallback(
    () =>
      course?.lessons && (
        <ProgressFooter
          lessons={course.lessons}
          currentIndex={typeof accordionState === 'number' ? accordionState : firstUncompletedIndex}
          numberOfCompletedLessons={
            course?.lessons?.filter((lesson) => lessonProgressSet.has(lesson._id)).length ?? 0
          }
        />
      ),
    [accordionState, firstUncompletedIndex, lessonProgressSet, course.lessons]
  )

  useEffect(() => {
    addPortal(progressFooter())
    return () => removePortal()
  }, [progressFooter, addPortal, removePortal])

  return (
    <Container>
      <AnimatePresence mode="wait">
        <MotionStack
          space={4}
          marginBottom={4}
          key={isEveryLessonCompleted ? 'completed' : 'in-progress'}
          ref={headerRef}
          style={{scrollMarginTop: '140px'}}
          initial={{opacity: 0}}
          animate={{opacity: 1}}
          exit={{opacity: 0}}
        >
          {isEveryLessonCompleted ? <AllSetHeading /> : <GetStartedHeading />}
          {!isEveryLessonCompleted && template && <TemplateCard template={template} />}
          {!readmeLoading && !hasLessons && <NoTemplateLessonsFallback template={template} />}
          {(isEveryLessonCompleted || (!readmeLoading && !hasLessons)) && (
            <ResourceCards resources={course.related} largeCardCount={0} />
          )}
        </MotionStack>
      </AnimatePresence>

      <Stack space={2}>
        <AnimatePresence mode="popLayout">
          {onboardingLoading || progressLoading || readmeLoading
            ? Array.from({length: 5}).map((_, i) => (
                <MotionBox key={`skeleton-${i}`} animate={{opacity: 1}} exit={{opacity: 0}}>
                  <LessonAccordionSkeleton />
                </MotionBox>
              ))
            : course?.lessons?.map((lesson, i) => (
                <MotionBox key={lesson._id} initial={{opacity: 0}} animate={{opacity: 1}}>
                  <LessonAccordion
                    course={course}
                    lesson={lesson}
                    isOpen={accordionState === i}
                    isCompleted={lessonProgressSet.has(lesson._id)}
                    isAllClosed={accordionState === 'all-closed'}
                    onToggle={() => handleToggleAccordion(i)}
                    onComplete={handleSetCompleteStep}
                  />
                </MotionBox>
              ))}
        </AnimatePresence>
      </Stack>

      <CourseFeedback
        framework={`Template: ${template?.slug.current}`}
        currentLesson={
          typeof accordionState === 'number' ? course.lessons[accordionState] : undefined
        }
      />
    </Container>
  )
}
