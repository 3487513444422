import React from 'react'

export function TagIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-sanity-icon="tag"
      width="1em"
      height="1em"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.5 20L5 20L5 12.5L12.5 5L20 12.5L12.5 20Z"
        stroke="currentColor"
        strokeWidth="1.2"
      />
      <path
        d="M11 15.5C11 16.3284 10.3284 17 9.5 17C8.67157 17 8 16.3284 8 15.5C8 14.6716 8.67157 14 9.5 14C10.3284 14 11 14.6716 11 15.5Z"
        stroke="currentColor"
        strokeWidth="1.2"
      />
    </svg>
  )
}
