import {DowngradeSurveyFieldset} from '@/components/checkout/fieldsets/downgradeSurveyFieldset'
import {DowngradeSurveyFormData} from '@/components/checkout/types'
import {useCurrentScopeContext, useRoutePath} from '@/context/index'
import {DialogToast, Project} from '@/types/index'
import {FormField} from '@/ui/index'
import {sendAmplitudeTrackingEvent} from '@/utils/tracking'
import {zodResolver} from '@hookform/resolvers/zod'
import {Box, Text, TextInput} from '@sanity/ui'
import {useRouter} from 'next/router'
import React, {useEffect, useMemo} from 'react'
import {FormProvider, useForm} from 'react-hook-form'
import {z} from 'zod'
import {useDeleteProject, useProjectSubscription} from '../../../../data/projects'

type Props = {
  project: Project
  Dialog: React.ElementType
  hideDialog: (dialogToast: DialogToast) => void
}

const DeleteProjectFormSchema = DowngradeSurveyFormData.extend({
  projectName: z.string(),
})

type DeleteProjectFormData = z.infer<typeof DeleteProjectFormSchema>

export const DeleteProjectDialog = ({project, hideDialog, Dialog}: Props) => {
  const router = useRouter()
  const scope = useCurrentScopeContext()
  const {data: plan} = useProjectSubscription(project?.id || '')
  const skipSurvey = useMemo(
    () => plan?.plan.planTypeId === 'free' || plan?.plan.planTypeId === 'growth-trial',
    [plan]
  )
  const {orgPath} = useRoutePath()

  const normalizedName = project.displayName.replace(/\s/g, ' ')
  const {deleteProject, isPending} = useDeleteProject(project.id)

  useEffect(() => {
    // Prefetch the org projects page for faster redirect on project deletion
    router.prefetch(`${orgPath}/projects`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const form = useForm<DeleteProjectFormData>({
    resolver: zodResolver(DeleteProjectFormSchema),
  })

  useEffect(() => {
    if (skipSurvey) {
      form.setValue('reason', 'skip-survey')
    }
  }, [form, skipSurvey])

  const onSubmitHandler = async (formData: DeleteProjectFormData) => {
    if (formData.projectName !== normalizedName) {
      form.setError('projectName', {message: 'Typed name does not match the project name'})
      return
    }
    deleteProject(undefined, {
      onSuccess: () => {
        if (formData.reason !== 'skip-survey') {
          sendAmplitudeTrackingEvent('Plan Downgraded', project.id, scope?.org?.id, {
            userId: scope?.currentUser?.id,
            projectName: project?.displayName,
            orgName: scope?.org?.name,
            currentPlan: plan?.planId,
            targetPlan: 'deleted',
            downgradeReason: formData.reason,
            downgrade_reason_followup: formData.comment,
          })
        }
        hideDialog({description: 'Project deleted', status: 'success'})
        router.push(`${orgPath}/projects`)
      },
      onError: (error) => {
        console.error(error)
        hideDialog({description: 'Could not delete project', status: 'error'})
      },
    })
  }
  return (
    <FormProvider {...form}>
      <Dialog
        header="Delete project"
        title={`Do you really want to delete "${normalizedName}"?`}
        description={`The project will be permanently deleted, including all data, users and API settings. This action is irrevocable and cannot be undone.`}
        confirmButtonProps={{text: 'Delete forever', form: 'delete-project-form', type: 'submit'}}
        loading={form.formState.isSubmitting || isPending}
        disabled={!form.formState.isValid}
      >
        <form id="delete-project-form" onSubmit={form.handleSubmit(onSubmitHandler)}>
          <FormField
            label="Type in the name of the project to confirm"
            description={normalizedName}
            error={form.getFieldState('projectName').error?.message}
          >
            <TextInput {...form.register('projectName')} placeholder="Project name" />
          </FormField>
          {!skipSurvey && (
            <Box>
              <Box paddingY={4}>
                <Text size={1} weight="medium">
                  Can you let us know why you're deleting this project?
                </Text>
              </Box>
              <DowngradeSurveyFieldset textSize={1} />
            </Box>
          )}
        </form>
      </Dialog>
    </FormProvider>
  )
}
