import {Box, Card, Heading, Text, TextInput, useToast} from '@sanity/ui'
import React, {useCallback} from 'react'
import {useWizardStore, WizardStep} from '@/components/payment-wizard'
import {MAX_DATASET_NAME_LENGTH} from '@/data/datasets'
import {Illustration, Prompt} from '@/ui/index'
import {fetchCouponPlans} from '@/data/plan'
import {catchError} from 'rxjs/operators'
import {useCurrentScopeContext} from '@/context/index'
import {sendAmplitudeTrackingEvent} from '@/utils/tracking'

function ApplyCouponStep() {
  const {state, dispatch} = useWizardStore()

  const handleEditCoupon = useCallback(
    (e) => {
      const couponCode = e.target.value
      dispatch({type: 'setCouponCode', value: couponCode})
      dispatch({
        type: 'setNextButtonDisabled',
        value: couponCode === undefined || couponCode === '',
      })
      dispatch({type: 'setCouponError', value: false})
    },
    [dispatch]
  )

  return (
    <Card>
      <Heading as="h1" align={'center'}>
        <Illustration drawing="coupon" />
      </Heading>
      <br />
      <Box>
        <Heading as="h2">Enter Coupon code</Heading>
        <br />
        <TextInput
          name="name"
          placeholder="e.g. sanity-abc-123"
          padding={3}
          defaultValue={state.couponCode || ''}
          maxLength={MAX_DATASET_NAME_LENGTH}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleEditCoupon(e)}
        />
      </Box>
      {state.couponError && (
        <Prompt
          cardProps={{marginTop: 5}}
          icon="error-outline"
          layout="row"
          description={
            <span>
              <Text size={1}>This does not match any of our codes. Please try again</Text>
            </span>
          }
          tone="critical"
        />
      )}
    </Card>
  )
}

export function useApplyCouponStep(): WizardStep | null {
  const {dispatch, state} = useWizardStore()
  const {project, org} = useCurrentScopeContext()
  const scope = useCurrentScopeContext()
  const {useCoupon} = state
  const {push} = useToast()

  return useCoupon
    ? {
        id: 'apply-coupon-step',
        title: 'Coupon',
        completed: true,
        component: ApplyCouponStep,
        disabled: !!state.nextButtonDisabled,
        action: {
          label: <>Apply</>,
        },
        hideSteps: true,
        async handle() {
          const {couponCode} = state
          if (!couponCode) {
            throw new Error('Cannot be empty')
          }
          const plans = await fetchCouponPlans(couponCode)
            .pipe(
              catchError((err) => {
                state.couponError = false
                if (err.response) {
                  if (err?.response?.statusCode === 402) {
                    dispatch({type: 'changePlanWizard/carderror'})
                  }
                  state.couponError = true
                }
                throw err
              })
            )
            .toPromise()
          state.useCoupon = false

          const plan = plans?.[0]
          if (!plan) {
            throw new Error('Coupon not found')
          }
          sendAmplitudeTrackingEvent('Coupon Validated', project?.id, org?.id, {
            userId: scope?.currentUser?.id,
            targetPlan: plan.id,
            coupon: couponCode,
          })

          dispatch({type: 'setTargetPlan', value: plan})

          push({
            title: 'Coupon accepted',
            description: `You can now switch to the ${plan.name} plan`,
            status: 'success',
          })
          sendAmplitudeTrackingEvent('Switch Plan Step Completed', project?.id, org?.id, {
            stepName: 'Coupon',
          })
        },
      }
    : null
}
