import {useMutation, useQueryClient} from '@tanstack/react-query'
import {transferProject} from '.'
import {getProjectsKey} from './cache'

export function useTransferProject() {
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationKey: ['transferProject'],
    mutationFn: ({projectId, organizationId}: {projectId: string; organizationId: string | null}) =>
      transferProject(projectId, organizationId),
    onSettled: () => {
      queryClient.invalidateQueries({queryKey: getProjectsKey()})
    },
  })

  return {
    isTransferred: mutation.isSuccess,
    isLoading: mutation.isPending,
    error: mutation.error,
    transferProject: mutation.mutate,
  }
}
