/* eslint-disable max-statements */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {type ComponentClass, useEffect, useMemo} from 'react'
import getConfig from 'next/config'
import {LayerProvider, ToastProvider} from '@sanity/ui'
import {QueryClientProvider} from '@tanstack/react-query'
import {ReactQueryDevtools} from '@tanstack/react-query-devtools'
import {useRouter, type NextRouter} from 'next/router'
import {AnalyticsInit} from '@sanity/frontend-analytics'
import {trackingConfig} from '../utils/tracking'
import {Elements} from '@stripe/react-stripe-js'
import {
  queryClient,
  useCurrentUser,
  useOrganizationBillingAdminStatus,
  usePaymentState,
  useProjectShallow,
} from '../data'
import type {CurrentScope} from '@/types/models'
import type {PageProps} from '@/types/app'
import {environment} from '@/data/util/environment'
import {
  ActivityContextProvider,
  CurrentScopeContext,
  RoutePathContext,
  UsageContextProvider,
  PersonalizedDialogProvider,
} from '@/context/index'

import {AppLoadingScreen, ErrorComponent, ErrorBoundary, GlobalStyle} from '@/ui/app'
import {WizardStoreProvider} from '@/components/payment-wizard'
import {sendPageLoadEvent} from '@/utils/tracking'
import {getActiveScope} from '@/utils/general'
import {ReCaptchaProvider} from '@/ui/recaptcha/ReCaptchaContext'
import GrowthBookContext from '@/utils/tracking/growthBook'
import {ManageSchemeProvider} from '@/ui/app/manageSchemeProvider'
import {SwitchPlanStoreProvider} from '@/components/checkout'
import {PosthogContextProvider} from '@/utils/tracking/posthog'
import useSentryGlobalTags from '@/context/useSentryUserAndTags'
import {GetStartedStoreProvider} from '@/components/get-started/store/storeProvider'
import {SentryUserProvider} from '@/context/SentryUserProvider'
import {useOrganizationsList} from '@/data/organizations/useOrganizations'
import {LoadingStateProvider} from '@/context/LoadingProvider'
import getStripe from '@/utils/get-stripe'
import {useStudios} from '@/data/studios/useStudios'
import {getStudioHost} from '@/utils/studioHost'
import {useCurrentUserPermissions} from '@/data/access/useAccessPermissions'
import {AnnouncementsProvider} from '@/context/announcements'
import {isAPIError} from '@/data/util/promiseRequest'
import {client} from '@sanity/access-api'

const {publicRuntimeConfig} = getConfig()

const loadingScreen = <AppLoadingScreen />

client.setConfig({
  credentials: 'include',
  baseUrl: `https://api.${process.env.host}`,
})

function RedirectToLogin(props: {router: NextRouter}) {
  const {router} = props

  useEffect(() => {
    router.push(
      `https://www.${environment.host}/login?origin=${encodeURIComponent(window.location.href)}`
    )
  }, [router])

  return null
}

function CurrentScopeContextProvider(props: {
  children: React.ReactNode
  currentOrg: CurrentScope['org']
  currentProject: CurrentScope['project'] | null
  currentUser: CurrentScope['currentUser'] | null
  isLoading: CurrentScope['loading']
  isLoadingPermissions: CurrentScope['isLoadingPermissions']
  orgBillingAdminStatus: CurrentScope['currentBillingAdminStatus']
  orgs: CurrentScope['orgs'] | undefined
  paymentState: CurrentScope['currentPaymentState'] | undefined
  currentUserPermissions: CurrentScope['currentUserPermissions'] | undefined
  orgsLoading: CurrentScope['isLoadingOrgs']
  currentProjectLoading: CurrentScope['isLoadingProjects']
  projectId: CurrentScope['projectId'] | string[] | undefined
}) {
  const {
    children,
    currentOrg,
    currentProject,
    currentUser,
    isLoading,
    isLoadingPermissions,
    orgBillingAdminStatus,
    orgs,
    paymentState,
    projectId,
    currentUserPermissions,
    orgsLoading,
    currentProjectLoading,
  } = props

  // Create the scope context consisting of user, project, and org, and all roles, as well as projects with usage data
  const currentScope = useMemo(
    (): CurrentScope => ({
      scope: getActiveScope(currentOrg, currentProject),
      currentProjectId: currentProject?.id,
      currentProjectFeatures: currentProject?.features || [],
      currentOrgId: currentOrg?.id,
      currentUser: currentUser || undefined,
      currentUserPermissions: currentUserPermissions || [],
      project: currentProject || undefined,
      projectId: Array.isArray(projectId) ? projectId[0] : projectId,
      org: currentOrg,
      loading: isLoading,
      isLoadingOrgs: orgsLoading,
      isLoadingProjects: currentProjectLoading,
      isLoadingPermissions,
      orgs: orgs ?? [],
      currentPaymentState: paymentState || [],
      currentBillingAdminStatus: orgBillingAdminStatus,
    }),
    [
      currentOrg,
      currentProject,
      currentUser,
      currentUserPermissions,
      projectId,
      isLoading,
      orgsLoading,
      currentProjectLoading,
      isLoadingPermissions,
      orgs,
      paymentState,
      orgBillingAdminStatus,
    ]
  )

  return (
    <CurrentScopeContext.Provider value={currentScope}>{children}</CurrentScopeContext.Provider>
  )
}
CurrentScopeContextProvider.displayName = 'CurrentScopeContextProvider'

function App({
  Component,
  pageProps,
}: {
  Component: ComponentClass<PageProps>
  pageProps: PageProps
}): JSX.Element {
  const router = useRouter()
  const {data: currentUser, isLoading: userLoading, error: userError} = useCurrentUser()
  const {
    data: currentUserPermissions,
    isLoading: userPermissionsLoading,
    error: userPermissionsError,
  } = useCurrentUserPermissions({
    enabled: !userLoading && Boolean(currentUser),
  })
  const {projectId, orgId, headingId, tabId, previous} = router.query
  const currentProjectId = useMemo(
    () => (Array.isArray(projectId) ? projectId[0] : projectId),
    [projectId]
  )
  const isPersonalRoute = useMemo(() => router.route.includes('personal'), [router.route])
  const {data: orgs, isLoading: orgsLoading, error: orgsError} = useOrganizationsList()
  const projectFromOrg = useMemo(() => {
    const org = orgs?.find((o) => o.id === (isPersonalRoute ? 'personal' : orgId))
    return org?.projects.find((p) => p.id === currentProjectId)
  }, [orgs, isPersonalRoute, orgId, currentProjectId])
  const notLoggedIn = useMemo(
    () => !userLoading && userError === null && currentUser === null,
    [currentUser, userError, userLoading]
  )
  const loggedIn = useMemo(
    () => !userLoading && userError === null && Boolean(currentUser?.id),
    [currentUser, userError, userLoading]
  )

  // Fetch the current project
  // If the user does not have access to the project, we use the partial project from the org
  // This fallback is important - else you will be able to click the route, but still be stuck
  // on the org overview/project list page
  const {
    data: currentProject,
    isLoading: currentProjectLoading,
    error: currentProjectError,
  } = useProjectShallow(currentProjectId, {
    enabled: loggedIn && Boolean(currentProjectId),
    placeholderData: projectFromOrg,
  })

  const {data: studios = [], isLoading: isStudiosLoading} = useStudios(currentProjectId, {
    enabled: loggedIn,
  })

  // Redirect to studio if coming from invitation acceptance page
  if (previous === 'invitation-acceptance' && !isStudiosLoading) {
    if (studios?.length > 0) {
      // if coming from the invitation acceptance page, check if we can redirect to a valid studio
      const url = getStudioHost(studios)
      if (url) router.replace(url)
    }
    const {
      pathname,
      query: {previous: _previous, ...query},
    } = router
    router.replace({pathname, query}, undefined, {shallow: true})
  }

  // Set the current org
  const currentOrg = useMemo(() => {
    return orgs?.find((tm) =>
      isPersonalRoute ? tm.id === 'personal' : tm.id === orgId?.toString()
    )
  }, [orgs, orgId, isPersonalRoute])

  // Set user data, projectId and organizationId globaly for Sentry errors
  useSentryGlobalTags(
    currentUser,
    // Rely on raw router values as project/org objects might not be populated.
    currentProjectId,
    Array.isArray(orgId) ? orgId[0] : orgId
  )

  const {data: paymentState} = usePaymentState(currentUser || null)
  const orgBillingAdminStatus = useOrganizationBillingAdminStatus(paymentState || [])

  // Set page error when error loading required data
  const error = useMemo<Error | null>(() => {
    if (userError) {
      return userError
    }
    if (userPermissionsError) {
      return userPermissionsError
    }
    if (orgsError) {
      return orgsError
    }

    // Here we only want to show this fullscreen error if there is an 50* error
    // For permissions errors (401/403) we want to show the access denied error
    // handled by the page tab component
    const isProjectServerError =
      currentProjectError &&
      isAPIError(currentProjectError) &&
      currentProjectError.statusCode >= 500

    return currentProjectError && isProjectServerError ? currentProjectError : null
  }, [userError, userPermissionsError, orgsError, currentProjectError])

  // Trigger page seen by specific user event
  useEffect(() => {
    if (currentUser) {
      sendPageLoadEvent(currentUser)
    }
  }, [currentUser])

  const isLoading = useMemo(
    () => userLoading || orgsLoading || currentProjectLoading || userPermissionsLoading,
    [userLoading, orgsLoading, currentProjectLoading, userPermissionsLoading]
  )
  const loadingState = useMemo(() => {
    if (isLoading) {
      return 'loading'
    }
    return 'loaded'
  }, [isLoading])

  // Paths
  const orgTypePath = useMemo(
    () => (currentOrg?.id === 'personal' ? '/manage/personal' : `/organizations/${currentOrg?.id}`),
    [currentOrg?.id]
  )
  const orgPath = useMemo(() => (currentOrg ? orgTypePath : ''), [currentOrg, orgTypePath])
  const projectPath = useMemo(
    () => (currentProject?.id ? `/project/${currentProject?.id}` : ''),
    [currentProject?.id]
  )
  const routePath = useMemo(
    () => ({
      basePath: currentOrg ? `${orgPath}${projectPath}` : undefined,
      asPath: router.asPath,
      orgPath,
      projectPath,
      tabId: tabId?.toString(),
      headingId: headingId?.toString(),
    }),
    [currentOrg, orgPath, projectPath, router.asPath, headingId, tabId]
  )

  const content = useMemo(() => {
    if (error) {
      return <ErrorComponent message={error.message} error={error} />
    }

    if (isLoading) {
      return loadingScreen
    }

    return <Component {...pageProps} />
  }, [Component, error, isLoading, pageProps])

  const recaptcha = useMemo<{
    siteKey: string
    scriptSrc: string
    scriptName: string
    enabled: boolean
  }>(() => {
    return publicRuntimeConfig.recaptcha || {enabled: false}
  }, [])

  // Redirect to login if no logged in user
  if (notLoggedIn && typeof window !== 'undefined') {
    return <RedirectToLogin router={router} />
  }

  return (
    <ManageSchemeProvider>
      <ToastProvider zOffset={3000}>
        <GlobalStyle scheme="light" />
        <RoutePathContext.Provider value={routePath}>
          <CurrentScopeContextProvider
            currentOrg={currentOrg}
            currentProject={currentProject ?? projectFromOrg}
            currentUser={currentUser}
            isLoading={isLoading}
            orgBillingAdminStatus={orgBillingAdminStatus}
            orgs={orgs}
            paymentState={paymentState}
            projectId={projectId}
            currentUserPermissions={currentUserPermissions}
            orgsLoading={orgsLoading}
            currentProjectLoading={currentProjectLoading}
            isLoadingPermissions={userPermissionsLoading}
          >
            <PersonalizedDialogProvider>
              <AnnouncementsProvider>
                <ActivityContextProvider>
                  <UsageContextProvider>
                    <GrowthBookContext>
                      <PosthogContextProvider>
                        <ErrorBoundary>
                          <AnalyticsInit config={trackingConfig} />
                          <ReCaptchaProvider
                            scriptSrc={recaptcha.scriptSrc}
                            scriptName={recaptcha.scriptName}
                            sitekey={recaptcha.siteKey}
                            enabled={recaptcha.enabled}
                          >
                            <WizardStoreProvider>
                              <SwitchPlanStoreProvider>
                                <GetStartedStoreProvider>
                                  <LayerProvider>
                                    <LoadingStateProvider loadingState={loadingState}>
                                      {content}
                                    </LoadingStateProvider>
                                  </LayerProvider>
                                </GetStartedStoreProvider>
                              </SwitchPlanStoreProvider>
                            </WizardStoreProvider>
                          </ReCaptchaProvider>
                        </ErrorBoundary>
                      </PosthogContextProvider>
                    </GrowthBookContext>
                  </UsageContextProvider>
                </ActivityContextProvider>
              </AnnouncementsProvider>
            </PersonalizedDialogProvider>
          </CurrentScopeContextProvider>
        </RoutePathContext.Provider>
      </ToastProvider>
    </ManageSchemeProvider>
  )
}
App.displayName = 'App'

const stripePromise = getStripe()

function AppWrapper(props: {Component: ComponentClass<PageProps>; pageProps: PageProps}) {
  const {isReady} = useRouter()

  if (!isReady) {
    return null
  }

  return (
    <Elements stripe={stripePromise}>
      <SentryUserProvider>
        <QueryClientProvider client={queryClient}>
          <App {...props} />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </SentryUserProvider>
    </Elements>
  )
}
AppWrapper.displayName = 'AppWrapper'

export default AppWrapper
