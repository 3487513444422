import React from 'react'
import styled from 'styled-components'
import {Card, Grid, Stack, Text} from '@sanity/ui'
import {TimeAgoText} from '@/ui/index'

const Title = styled(Text)`
  text-transform: uppercase;
`

type Props = {
  name: string
  url: string
  dataset: string
  createdAt: string | Date
}

export function WebhookPreview({name, url, dataset, createdAt}: Props) {
  return (
    <Card border radius={2} padding={1} paddingTop={2} paddingBottom={2}>
      <Grid columns={1} gap={1}>
        <Card padding={3}>
          <Stack space={3}>
            <Title muted weight="semibold" size={0}>
              name
            </Title>
            <Stack space={2}>
              <Text>{name}</Text>
              <Text muted size={1} style={{wordBreak: 'break-all'}}>
                {url}
              </Text>
            </Stack>
          </Stack>
        </Card>
        <Card padding={3}>
          <Stack space={3}>
            <Title muted weight="semibold" size={0}>
              credentials
            </Title>
            <Text>{dataset}</Text>
          </Stack>
        </Card>
        <Card padding={3}>
          <Stack space={3}>
            <Title muted weight="semibold" size={0}>
              created
            </Title>
            <TimeAgoText date={createdAt} muted />
          </Stack>
        </Card>
      </Grid>
    </Card>
  )
}
