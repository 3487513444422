import {useCurrentScopeContext} from '@/context/index'
import {FormField} from '@/components/form/formField'
import {requestOrganizationGrants} from '@/data/index'
import type {Organization} from '@/types/index'
import {AddIcon, CaseIcon, InfoOutlineIcon} from '@sanity/icons'
import {
  Autocomplete,
  Box,
  Card,
  Flex,
  Text,
  TextInput,
  type BaseAutocompleteOption,
} from '@sanity/ui'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {useFormContext} from 'react-hook-form'
import {Fieldset} from '@/components/checkout/components'
import {AnimatePresence, motion} from 'framer-motion'
import {CheckoutFormData} from '@/components/checkout/types'
import {FORM_ERRORS} from '../constants'

interface NewOrganization {
  name: string
  id: '#new'
}

interface SelectOption extends BaseAutocompleteOption {
  value: string
  payload?: Organization | NewOrganization
}

async function getOrgsWithUpgradePermission(orgs?: Organization[]) {
  const checkPermission = async (org) => {
    const grants = await requestOrganizationGrants(org.id)
    const hasAttachPermission = !!grants['sanity.organization.projects']?.[0].grants.find(
      (grant) => grant.name === 'attach'
    )
    const hasBillingPermission = !!grants['sanity.organization']?.[0].grants.find(
      (grant) => grant.name === 'billing'
    )
    return hasAttachPermission && hasBillingPermission ? org : null
  }

  const promiseArray = orgs
    ?.filter((org) => org.id !== 'personal') // Skip personal org
    ?.map((org) => checkPermission(org))
  const results = await Promise.all(promiseArray || [])
  return results.filter((org) => org !== null)
}

export function OrganizationFieldset() {
  const {
    register,
    setValue,
    unregister,
    watch,
    formState: {errors},
  } = useFormContext<CheckoutFormData>()
  const scope = useCurrentScopeContext()
  const [orgsWithUpgradePermission, setOrgsWithUpgradePermission] = useState<Organization[]>(
    scope?.orgs || []
  )
  const [selectQuery, setSelectQuery] = useState<string | null>(null)
  const [isInOrganizationScope] = useState(!!scope?.org && scope?.org?.id !== 'personal')
  const noOrgs = orgsWithUpgradePermission.length === 0

  useEffect(() => {
    if (isInOrganizationScope) return // Skip if we're already in an org
    // TODO: we should show the user all of their orgs, but disable the ones
    // where they do not have billing permissions and give them a tooltip telling
    // them why they can't select those
    getOrgsWithUpgradePermission(scope?.orgs).then((orgs) => {
      setOrgsWithUpgradePermission(orgs)
    })
  }, []) // Only run once to avoid re-fetching when form is submitted

  const selectOptions: SelectOption[] = useMemo(() => {
    const options = orgsWithUpgradePermission.map((org) => ({
      value: org.id,
      payload: org,
    }))
    if (selectQuery && selectQuery.trim().length) {
      options.push({
        value: '#new',
        payload: {name: `Create "${selectQuery}"`, id: '#new'} as Organization,
      })
    }
    return options
  }, [orgsWithUpgradePermission, selectQuery])

  const filterOption = useCallback((query, option: SelectOption): boolean => {
    if (option?.payload?.id === '#new') return true
    return Boolean(option?.payload?.name.toLowerCase().includes(query.toLowerCase()))
  }, [])

  const renderValue = useCallback(
    (value: string, option?: SelectOption): string => {
      if (value === '#new') return selectQuery!
      return option?.payload?.name || ''
    },
    [selectOptions]
  )

  const renderOption = useCallback(({payload}: SelectOption) => {
    const Icon = payload?.id === '#new' ? AddIcon : CaseIcon
    return (
      <Card as="button" padding={3}>
        <Text size={2}>
          <Icon style={{marginRight: '0.25rem'}} />
          {payload?.name}
        </Text>
      </Card>
    )
  }, [])

  const handleSelectChange = useCallback(
    (value: string) => {
      if (value === '#new') {
        setValue('org.id', null)
        setValue('org.name', selectQuery?.trim() || null)
        setValue('org.type', 'new')
      } else if (value) {
        setValue('org.id', value)
        setValue('org.name', null)
        setValue('org.type', 'existing')
      } else {
        unregister('org')
      }
    },
    [selectQuery]
  )

  /**
   * If we're in an organization, we skip showing the organization fieldset
   */
  if (isInOrganizationScope) return null

  /**
   * If we're in an organization, show an input field for creating a new organization
   */
  if (noOrgs) {
    return (
      <Fieldset legend="Organization">
        <FormField
          label="Organization name"
          description="To upgrade, your project must be part of an organization. Create a new organization by entering a name."
          error={errors?.org?.name?.message}
        >
          <TextInput
            {...register('org.name', {
              onChange: () => {
                setValue('org.id', null)
                setValue('org.type', 'new')
              },
            })}
            placeholder="Acme Inc."
            autoComplete="organization"
            autoFocus
          />
        </FormField>
      </Fieldset>
    )
  }

  /**
   * If we have multiple organizations with attach permission, show an autocomplete field
   */
  return (
    <Fieldset legend="Organization">
      <FormField
        label="Organization"
        description="To upgrade, transfer your project to a new or existing organization."
        error={errors?.org && FORM_ERRORS.required}
      >
        <Box>
          <Autocomplete
            filterOption={filterOption}
            id="org.id"
            name="org.id"
            onChange={handleSelectChange}
            onQueryChange={(query) => setSelectQuery(query)}
            openButton
            openOnFocus
            options={selectOptions}
            placeholder="Select or create organization"
            renderOption={renderOption}
            renderValue={renderValue}
            // @ts-expect-error - autocomplete types are not up to date
            autoComplete="ignore"
          />

          <AnimatePresence>
            {watch('org.type') === 'new' && (
              <motion.div
                initial={{height: 0, opacity: 0}}
                animate={{height: 'auto', opacity: 1}}
                exit={{height: 0, opacity: 0}}
                transition={{duration: 0.2}}
              >
                <Flex gap={1} align="center" marginTop={2}>
                  <InfoOutlineIcon fontSize={21} />
                  <Text muted size={1}>
                    We'll create the organization "{watch('org.name')}"
                  </Text>
                </Flex>
              </motion.div>
            )}
          </AnimatePresence>
        </Box>
      </FormField>
    </Fieldset>
  )
}
