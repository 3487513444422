import {Card, Grid, Heading, Stack} from '@sanity/ui'
import React from 'react'
import {Loader} from '../../general'
import {Item, CostSummaryItemProps} from './item'

type Props = {
  data: CostSummaryItemProps[]
  loading?: boolean
  title?: string
}

export function Summary({data, title, loading}: Props) {
  return (
    <Stack space={title ? 5 : 0}>
      {title && (
        <Heading size={1} as="h3">
          {title}
        </Heading>
      )}
      <Card radius={2} border>
        {loading ? (
          <Loader text="Loading costs..." height={93} cardProps={{paddingTop: 2}} />
        ) : (
          <Grid columns={[1, 1, data.length, data.length]}>
            {data?.map(({title: itemTitle, key, subtitle, period}, index) => (
              <Card
                key={key}
                borderRight={index < data.length - 1 && [false, false, true, true]}
                borderBottom={index < data.length - 1 && [true, true, false, false]}
                flex={1}
              >
                <Item title={itemTitle} subtitle={subtitle} period={period} />
              </Card>
            ))}
          </Grid>
        )}
      </Card>
    </Stack>
  )
}
