import {Observable} from 'rxjs'
import {map, tap, share, switchMap} from 'rxjs/operators'
import {editOrgAction, headers, updateOrganizationMemberRoleAction} from './utils'
import {apiRequest} from '@/data/util/request'
import {
  OrganizationRole,
  Organization,
  OrganizationContact,
  OrganizationData,
  OrganizationContactData,
  OrgData,
  CustomerData,
  Customer,
  PatchOrganizationData,
} from '@/types/index'
import {isAPIError, promiseRequest} from '@/data/util/promiseRequest'

const API_VERSION = 'v2021-06-07'

export function getOrganizationRoles(orgId: string) {
  return promiseRequest<OrganizationRole[]>(
    `https://api.${process.env.host}/${API_VERSION}/organizations/${orgId}/roles`
  ).catch((error) => {
    if (isAPIError(error) && error.statusCode === 401) {
      return []
    }
    throw error
  })
}

export function getOrganizationContacts(organizationId: string): Observable<OrganizationContact[]> {
  return apiRequest<OrganizationContact[]>({
    url: `https://api.${process.env.host}/${API_VERSION}/organizations/${organizationId}/contacts`,
  })
}

export function getOrganizationData(id: string) {
  return promiseRequest<Organization>(
    `https://api.${process.env.host}/${API_VERSION}/organizations/${id}`
  )
}

export function setOrganizationContact(
  organizationId: string,
  role: string,
  contact: OrganizationContactData
): Observable<OrganizationContact> {
  return apiRequest<OrganizationContact>({
    body: contact,
    headers,
    method: 'PUT',
    url: `https://api.${process.env.host}/${API_VERSION}/organizations/${organizationId}/contacts/${role}`,
  })
}

export function patchOrganization(organizationId: string, data: PatchOrganizationData) {
  return promiseRequest<OrganizationData>(
    `https://api.${process.env.host}/${API_VERSION}/organizations/${organizationId}`,
    {
      method: 'PATCH',
      json: data,
    }
  )
}

type NewRoleName = string

export function setOrganizationMemberRole(
  organizationId: string,
  userId: string,
  roleName: string
): Observable<NewRoleName> {
  return apiRequest<{role: string}>({
    url: `https://api.${process.env.host}/${API_VERSION}/organizations/${organizationId}/acl/${userId}`,
    method: 'PUT',
    headers,
    body: {roleName, deleteExistingRoles: true},
  }).pipe(
    map((res) => {
      updateOrganizationMemberRoleAction.next(res.role)
      return res.role
    }),
    switchMap(() => roleName),
    share()
  )
}

export function editOrg(orgId: string, orgData: OrgData): Observable<Organization> {
  return apiRequest<Organization>({
    method: 'PATCH',
    headers,
    url: `https://api.${process.env.host}/${API_VERSION}/organizations/${orgId}`,
    body: orgData,
  }).pipe(
    tap((result) => {
      editOrgAction.next(result)
    }),
    share()
  )
}

export function deleteOrg(orgId: string) {
  return promiseRequest(`https://api.${process.env.host}/${API_VERSION}/organizations/${orgId}`, {
    method: 'DELETE',
  })
}

export async function createOrg(
  orgData: OrganizationData,
  customerData?: CustomerData
): Promise<Organization> {
  let org: Organization | undefined

  try {
    org = await promiseRequest<Organization>(
      `https://api.${process.env.host}/${API_VERSION}/organizations`,
      {
        method: 'POST',
        headers,
        json: orgData,
      }
    )

    if (customerData) {
      await promiseRequest<Customer>(
        `https://api.${process.env.host}/${API_VERSION}/customers/${org.id}`,
        {
          method: 'PATCH',
          headers,
          json: customerData,
        }
      )
    }

    return org
  } catch (error) {
    if (org) {
      await deleteOrg(org.id)
    }
    throw error
  }
}
