import React, {useCallback, useEffect, useRef, useState} from 'react'
import {Subscription, of} from 'rxjs'
import {catchError} from 'rxjs/operators'
import {Organization, OrgData} from '../../types'
import {editOrg} from '.'

type EditOrgFn = (data: OrgData) => void

type OrgError = {
  name: string
  message: string
  response?: {statusCode: number; message: string; error: string}
}

type OrgEditState = {
  data: OrgData
  loading: boolean
  error: OrgError | undefined
  success: boolean | undefined
}

export function useEditOrg(org: Organization): [OrgEditState, EditOrgFn] {
  const [editedOrgData, setEditedOrgData] = useState<OrgData>({name: org.name, slug: org.slug})
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<OrgError | undefined>(undefined)
  const [success, setSuccess] = useState<boolean | undefined>(undefined)
  const editSub: React.MutableRefObject<Subscription | undefined> = useRef()

  useEffect(
    () => () => {
      if (editSub.current) {
        editSub.current.unsubscribe()
      }
    },
    []
  )

  const _editOrg = useCallback(
    (data: OrgData) => {
      setError(undefined)
      setSuccess(undefined)
      setLoading(true)
      editSub.current = editOrg(org.id, data)
        .pipe(
          catchError((err) => {
            const newError = new Error(err.message) as OrgError
            try {
              newError.response = JSON.parse(err.response.body)
            } catch (e) {
              // do nothing
            }

            if (err.response && err.response.statusCode < 500) {
              newError.name =
                err.response.statusCode === 400
                  ? 'InvalidDataError'
                  : newError?.response?.error || err.name
              setError(newError)
              setSuccess(false)
              return of(undefined)
            }
            // Throw everything else
            throw err
          })
        )
        .subscribe((next) => {
          if (next) {
            setEditedOrgData({name: next.name, slug: next.slug})
            setSuccess(true)
          }
          setLoading(false)
          editSub.current?.unsubscribe()
        })
    },
    [org]
  )

  return [{data: editedOrgData, loading, error, success}, _editOrg]
}
