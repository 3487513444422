import {
  Box,
  type BoxHeight,
  Button,
  Dialog as UIDialog,
  type DialogProps as UIDialogProps,
  Flex,
} from '@sanity/ui'
import {
  type ComponentProps,
  forwardRef,
  type HTMLProps,
  type ReactNode,
  type Ref,
  useMemo,
} from 'react'

export type DialogProps = Pick<
  UIDialogProps,
  | '__unstable_autoFocus'
  | '__unstable_hideCloseButton'
  | 'contentRef'
  | 'footer'
  | 'header'
  | 'id'
  | 'onActivate'
  | 'onClickOutside'
  | 'onClose'
  | 'portal'
  | 'position'
  | 'scheme'
  | 'width'
> & {
  /**
   * Dialog body height.
   * Set this to 'fill' (i.e. 100%) if you want overflow body content to be contained
   * and not trigger dynamic border visibility.
   */
  bodyHeight?: BoxHeight
  children?: ReactNode
  cancelButton?: Omit<ComponentProps<typeof Button>, 'fontSize' | 'padding'>
  confirmButton?: Omit<ComponentProps<typeof Button>, 'fontSize' | 'padding'>
  /**
   * If enabled, removes all default padding from dialog content.
   */
  padding?: boolean
}

export const Dialog = forwardRef(function Dialog(
  props: DialogProps & Pick<HTMLProps<HTMLDivElement>, 'onDragEnter' | 'onDrop'>,
  ref: Ref<HTMLDivElement>
) {
  const {
    bodyHeight,
    children,
    cancelButton,
    confirmButton,
    footer,
    padding = true,
    onClose,
    ...rest
  } = props

  const DialogFooter = useMemo(() => {
    if (!confirmButton && !cancelButton && !footer) return undefined
    return (
      <>
        {(confirmButton || cancelButton) && (
          <Flex width="full" justify="flex-end" gap={2} padding={4}>
            {cancelButton && (
              <Button mode="ghost" text="Cancel" onClick={onClose} fontSize={1} {...cancelButton} />
            )}

            {confirmButton && (
              <Button mode="default" text="Confirm" fontSize={1} {...confirmButton} />
            )}
          </Flex>
        )}

        {footer}
      </>
    )
  }, [cancelButton, confirmButton, footer, onClose])

  return (
    <UIDialog {...rest} animate ref={ref} onClose={onClose} footer={DialogFooter}>
      <Box height={bodyHeight} padding={padding ? 4 : 0}>
        {children}
      </Box>
    </UIDialog>
  )
})
