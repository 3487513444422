import React from 'react'
import {Box, Button, ButtonProps} from '@sanity/ui'
import {CogIcon, RocketIcon, SortIcon} from '@sanity/icons'
import {AppLink, Prompt, PromptProps, TransferProjectButton} from '@/ui/index'
import {useProjectSubscription} from '@/data/projects/useProjectSubscription'
import {useOrganizationCanPay} from '@/data/organizations'
import {Project, Organization} from '@/types/index'
import {formatPrice} from '@/utils/index'

type Props = {
  project?: Project
  org?: Organization
  showWarning: boolean
}

export function QuotaWarning({org, project, showWarning = false}: Props) {
  const {data: subscription} = useProjectSubscription(project?.id)
  const {data: canPay} = useOrganizationCanPay(org?.id)
  const orgType = org?.type
  const isPersonalOrg = orgType === 'personal'

  const overageUsersCost = formatPrice(subscription?.resources.users.overageChunkPrice || 0, 0)

  /**
   * Scenarios to account for
   * - Hit quota without payment method added to org
   * - Hit quota with payment method added to org and overage allowed for
   * - Transfer it to a org
   */
  let promptProps: PromptProps = {
    tone: 'caution',
    description: (
      <span>
        You have reached the limit of members available in your plan. <br />
        Add payment details to add more members.
      </span>
    ),
    icon: 'warning-outline',
  }
  let ctaProps: ButtonProps = {
    tone: 'caution',
    text: 'Add payment details',
    icon: CogIcon,
  }

  let promptUrl = `/organizations/${org?.id}/settings#payment`

  if (canPay?.status === true) {
    promptProps = {
      tone: 'positive',
      description: (
        <span>
          You have used all the included members in your plan.
          <br />
          Each additional member adds {overageUsersCost} monthly.
        </span>
      ),
      icon: 'trend-upward',
    }
    ctaProps = {
      tone: 'positive',
      text: 'Compare and switch plans',
      icon: RocketIcon,
    }

    promptUrl = `/organizations/${org?.id}/settings#plan`
  }

  return (
    <>
      {showWarning && (
        <Box>
          {isPersonalOrg && project && (
            <Prompt
              tone="primary"
              icon="info-outline"
              layout="column"
              title="Transfer project to a organization to add more members"
              description={
                <span>
                  Your reached the limit of members available for personal projects. In order to add
                  more members, you need to transfer it to <br />
                  an existing organization or create a new organization.
                </span>
              }
              button={
                project && (
                  <TransferProjectButton
                    project={project}
                    buttonProps={{
                      tone: 'primary',
                      mode: 'default',
                      icon: (
                        <Box style={{transform: 'rotate(90deg)'}}>
                          <SortIcon />
                        </Box>
                      ),
                    }}
                  />
                )
              }
            />
          )}
          {!isPersonalOrg && (
            <Prompt
              {...promptProps}
              button={
                org && (
                  <AppLink href={promptUrl} style={{flexShrink: 0}}>
                    <Button {...ctaProps} as="span" />
                  </AppLink>
                )
              }
            />
          )}
        </Box>
      )}
    </>
  )
}
