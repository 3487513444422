import {Box, Stack} from '@sanity/ui'
import React, {useCallback, useEffect, useRef, useState} from 'react'
import {InviteForm} from './inviteForm'
import {
  WizardStep,
  useWizardStore,
  WizardStepProps,
  InviteMembersForm,
} from '@/components/payment-wizard'
import {SamlBanner} from '@/components/form'
import {useRouter} from 'next/router'
import {useCurrentScopeContext, useRoutePath} from '@/context/index'
import {useProjectSubscription} from '@/data/projects/useProjectSubscription'
import {getSubscriptionFeatures} from '@/utils/index'

function InviteMemberStep(props: WizardStepProps) {
  const {params} = props
  const {dispatch, state} = useWizardStore()
  const {inviteMembersWizard} = state
  const {form} = inviteMembersWizard
  const [isValidFormData, setIsValidFormData] = useState(false)
  const {project, org, onInvitationsSent} = params
  const formRef = useRef<InviteMembersForm>()

  const handleFormChange = useCallback(setIsValidFormData, [setIsValidFormData])

  const handleChange = useCallback(() => {
    dispatch({
      type: 'inviteMembersWizard/setForm',
      value: {isValidFormData, formRef},
    })
  }, [dispatch, isValidFormData])

  // Update the value isValidFormData whenever isValidFormData changes
  useEffect(() => {
    if (
      (isValidFormData && !form?.isValidFormData) ||
      (!isValidFormData && form?.isValidFormData)
    ) {
      handleChange()
    }
  }, [handleChange, isValidFormData, form?.isValidFormData])

  const handleComplete = useCallback(() => {
    if (onInvitationsSent) {
      onInvitationsSent()
    }
  }, [onInvitationsSent])

  return (
    <Box>
      <Stack space={4}>
        <InviteForm
          project={project}
          org={org}
          onFormValidityChanged={handleFormChange}
          onComplete={handleComplete}
          ref={formRef}
        />
      </Stack>
    </Box>
  )
}

export function useInviteMemberStep(): WizardStep | null {
  const {basePath} = useRoutePath()
  const router = useRouter()
  const {projectId} = useCurrentScopeContext()
  const {data: subscription} = useProjectSubscription(projectId)

  const hasAnySamlFeature =
    subscription &&
    getSubscriptionFeatures(subscription).some((feature) => feature.includes('saml'))
  const isGrowthPlan = subscription?.plan?.planTypeId === 'growth'
  const samlBannerCta = isGrowthPlan ? 'View add-on' : 'Upgrade'

  const {state} = useWizardStore()
  const isValid = !!state.inviteMembersWizard.form?.isValidFormData

  return {
    id: 'invite-members',
    title: 'Invite members',
    completed: !!isValid,
    component: InviteMemberStep,
    disabled: !isValid,
    action: {
      label: <>Send invites</>,
    },
    footer: hasAnySamlFeature
      ? null
      : () => (
          <SamlBanner
            cta={samlBannerCta}
            onClick={() =>
              router.push(
                `${basePath}/plan?ref=saml-invite-cta&addon=accesscontrol-auth-saml-role-mapping`
              )
            }
          />
        ),
    handle() {
      const form = state.inviteMembersWizard.form?.formRef?.current
      if (form) {
        return form.submit().then(() => Promise.resolve())
      }
      return Promise.reject(new Error('No form hooked up'))
    },
  }
}
