import {useEffect, useState} from 'react'
import {ReCaptchaScriptOptions} from './types'
import {loadAsyncGlobalScript} from '@/utils/domUtils'

// This interface represents the methods the Google Recaptcha script exposes
export type RawReCaptchaScript = {
  ready: (fn: () => void) => void
  execute: (sitekey: string, action: {action: string}) => Promise<string>
  reset: (reCaptchaId?: number) => void
  render: (elem: HTMLElement, options: ReCaptchaScriptOptions) => number
}

// Assumes we're using reCaptcha enterprise
export const useGlobalReCaptchaLoader = ({
  scriptSrc,
  scriptName,
  scriptId = 'grecaptcha_script_elem',
  enabled = false,
}: {
  scriptSrc: string
  scriptName: string
  scriptId?: string
  enabled: boolean
}) => {
  const [reCaptcha, setReCaptcha] = useState<RawReCaptchaScript | undefined>(undefined)

  useEffect(() => {
    if (!enabled) return

    loadAsyncGlobalScript<{enterprise: RawReCaptchaScript}>(scriptSrc, scriptName, scriptId)
      .then((grecaptcha) => setReCaptcha(grecaptcha.enterprise))
      .catch(() => setReCaptcha(undefined))
  }, [setReCaptcha, enabled])

  return reCaptcha
}
