// Type to represent paths for deep key navigation
type Paths<T> = T extends object
  ? {
      [K in keyof T]: [K, ...Paths<T[K]>]
    }[keyof T]
  : []

function getValueByPath<T>(item: T, path: Paths<T>): string | null {
  const value = path.reduce((acc: any, key) => (acc ? acc[key] : undefined), item)
  return value != null ? String(value).toLowerCase() : null
}

/**
 * @param list The list to sort
 * @param primaryPath The primary path to sort by
 * @param options Options for sorting the list (e.g., order, fallbackPath)
 * @returns
 */
export function sortList<T>(
  list: T[],
  primaryPath: Paths<T>,
  options: {fallbackPath?: Paths<T>; order?: 'asc' | 'desc'} = {}
): T[] {
  const {fallbackPath, order = 'asc'} = options

  return list.sort((a, b) => {
    // Get the primary and fallback values for comparison
    const nameA = getValueByPath(a, primaryPath)
    const nameB = getValueByPath(b, primaryPath)

    const fallbackA = fallbackPath ? getValueByPath(a, fallbackPath) : null
    const fallbackB = fallbackPath ? getValueByPath(b, fallbackPath) : null

    // Use primary value if available, otherwise use fallback
    const valueA = nameA !== null ? nameA : fallbackA !== null ? fallbackA : ''
    const valueB = nameB !== null ? nameB : fallbackB !== null ? fallbackB : ''

    // Custom sorting logic: Place null or empty values last
    if (valueA === '' && valueB !== '') return order === 'asc' ? 1 : -1
    if (valueB === '' && valueA !== '') return order === 'asc' ? -1 : 1

    // Standard comparison
    if (valueA < valueB) return order === 'asc' ? -1 : 1
    if (valueA > valueB) return order === 'asc' ? 1 : -1

    return 0
  })
}
