import {TrialDialogTrackingActions, sendTrackingEvent} from '.'

type DialogTrialStage =
  | 'trialStarted'
  | 'trialActive'
  | 'trialEndingSoon'
  | 'trialEnded'
  | 'postTrial'

type BaseDialogEventAttributes = {
  action: TrialDialogTrackingActions
  projectId: string | undefined
  organizationId: string | undefined
  trialDaysLeft: number
  dialogType: 'modal' | 'popover'
  dialogId: string
  dialogRevision: string
  dialogTrialStage: DialogTrialStage
}

export interface TrialDialogViewedAttributes extends BaseDialogEventAttributes {
  dialogTrigger: 'fromClick' | 'auto'
}

export interface TrialDialogDismissedAttributes extends BaseDialogEventAttributes {
  dialogDismissAction: 'ctaClicked' | 'xClick' | 'outsideClick'
}

export interface TrialDialogCTAClickedAttributes extends BaseDialogEventAttributes {
  dialogCtaType: 'upgrade' | 'learnMore'
}

export function sendDialogEventTracking({
  action,
  projectId,
  organizationId,
  ...extraAttrs
}:
  | TrialDialogViewedAttributes
  | TrialDialogDismissedAttributes
  | TrialDialogCTAClickedAttributes): void {
  return sendTrackingEvent(action, projectId, organizationId, {source: 'manage', ...extraAttrs})
}

export function getTrialSate({
  showOnLoad,
  dialogId,
}: {
  showOnLoad: boolean
  dialogId:
    | 'days-left'
    | 'after-trial-upgrade'
    | 'project-downgraded-to-free'
    | 'Free-upgrade-popover'
    | 'trial-ending-popover'
}): DialogTrialStage {
  if (showOnLoad && dialogId === 'Free-upgrade-popover') return 'trialStarted'
  if (showOnLoad && dialogId === 'trial-ending-popover') return 'trialEndingSoon'
  if (showOnLoad && dialogId === 'project-downgraded-to-free') return 'trialEnded'
  if (!showOnLoad && dialogId === 'after-trial-upgrade') return 'postTrial'
  return 'trialActive'
}
