import React, {useState} from 'react'
import {Flex, Card, Box, Text} from '@sanity/ui'
import {CardSingleBrand} from '@/ui/index'
import {deletePaymentSourceForCustomer} from '@/data/index'
import {DialogToast, Customer} from '@/types/index'
import {useCacheInvalidation} from '@/data/base/useCacheInvalidation'

type Props = {
  customer?: Customer | null
  Dialog: React.ElementType
  hideDialog: (dialogToast: DialogToast) => void
}

export const DeletePaymentSourceDialog = ({customer, hideDialog, Dialog}: Props) => {
  const {invalidateCustomerData} = useCacheInvalidation()
  const [loading, setLoading] = useState(false)

  const handleDeletePaymentSource = async () => {
    if (!customer) {
      return
    }

    try {
      setLoading(true)
      await deletePaymentSourceForCustomer(customer.organizationId).toPromise()
      invalidateCustomerData(customer.organizationId)
      setLoading(false)

      hideDialog({description: 'Payment details successfully deleted!', status: 'success'})
    } catch (e) {
      hideDialog({description: 'Could not delete payment details', status: 'error'})
    }
  }

  return (
    <Dialog
      header="Delete payment details"
      title={`Do you really want to delete your current payment details?`}
      onConfirm={handleDeletePaymentSource}
      confirmButtonProps={{text: 'Delete'}}
      loading={loading}
    >
      <Card border radius={2} padding={3}>
        <Flex align="center">
          <Box>
            <CardSingleBrand brandType={customer?.paymentSource?.card?.brand || 'unknown'} />
          </Box>
          <Box marginLeft={4} flex={1}>
            <Text size={2}>
              {[`**** **** ****`, customer?.paymentSource?.card?.last4 || '****']
                .join(' ')
                .replaceAll('*', '•')}{' '}
              {}
            </Text>
          </Box>
        </Flex>
      </Card>
    </Dialog>
  )
}
