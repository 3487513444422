import {type UserProfile} from '@sanity/access-api'
import {EnvelopeIcon} from '@sanity/icons'
import {
  Box,
  Card,
  Flex,
  KBD,
  Popover,
  type PopoverProps,
  Stack,
  Text,
  TextInput,
  useClickOutside,
} from '@sanity/ui'
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import styled, {css} from 'styled-components'

import {type MembersV2} from '@/types/members_v2'

import {CommandList, type CommandListRenderItemCallback} from '../../generic/command-list'
import {UserAvatar} from '../../generic/user-avatar'
import {filterByQuery} from '../../utils'
import {isValidEmail} from './invite-utils'
import {type InvitationScope, type LocalInvitee} from './types'

const POPOVER_FALLBACK_PLACEMENTS: PopoverProps['fallbackPlacements'] = ['bottom-start']

const StyledPopover = styled(Popover)(() => {
  return css`
    &:has([data-ui='Flex']:empty) {
      display: none;
    }

    max-width: 350px !important;
    width: 100%;

    [data-ui='Popover__wrapper'] {
      border-radius: ${({theme}) => theme.sanity.radius[4]}px;
      display: flex;
      flex-direction: column;
      max-height: 354px;
      max-width: 350px;
      min-width: 350px;

      [data-ui='Flex']:empty {
        display: none;
      }
    }
  `
})

const StyledTextInput = styled(TextInput)`
  padding-left: 0;
`

export interface AutocompleteProps {
  members: MembersV2['member'][]
  onSubmit: (payload: LocalInvitee) => void
  scope: InvitationScope
  setTextInputElement?: (element: HTMLInputElement | null) => void
}

export function Autocomplete(props: AutocompleteProps) {
  const {members, onSubmit, setTextInputElement} = props

  const [popoverElement, setPopoverElement] = useState<HTMLDivElement | null>(null)
  const [open, setOpen] = useState<boolean>(false)
  const [hasFocus, setHasFocus] = useState<boolean>(false)

  const didInitialFocus = useRef<boolean>(false)

  const [inputElement, setInputElement] = useState<HTMLInputElement | null>(null)
  const [searchQuery, setSearchQuery] = useState<string>('')

  const handleSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value)
  }, [])

  const hasValidEmail = useMemo(() => isValidEmail(searchQuery), [searchQuery])

  const membersWithoutCurrentUser = useMemo(() => {
    return members.filter((member) => !member.profile?.isCurrentUser)
  }, [members])

  const result = useMemo(() => {
    return filterByQuery(membersWithoutCurrentUser, searchQuery, [
      ['profile', 'displayName'],
      ['profile', 'email'],
    ])
  }, [membersWithoutCurrentUser, searchQuery])

  const handleFocus = useCallback(() => {
    setHasFocus(true)
  }, [])

  const handleBlur = useCallback(() => {
    setHasFocus(false)
  }, [])

  const handleSetInputElement = useCallback(
    (element: HTMLInputElement | null) => {
      setInputElement(element)
      setTextInputElement?.(element)
    },
    [setTextInputElement]
  )

  const handleSubmit = useCallback(
    (payload: LocalInvitee) => {
      onSubmit(payload)
      setSearchQuery('')
      setOpen(false)
      inputElement?.focus()
    },
    [inputElement, onSubmit]
  )

  const handleSubmitExistingMember = useCallback(
    (profile: UserProfile) => {
      if (!profile.email) return

      handleSubmit({
        email: profile.email,
        memberId: profile.id,
      })
    },
    [handleSubmit]
  )

  const handleSubmitNewMember = useCallback(() => {
    if (!hasValidEmail) return

    // Since we technically have a valid email, its highly likely that if there is a partial match
    // the intention is to invite the existing member. Example: typing name@sanity.i should match name@sanity.io
    // as its not likely that you want to invite someone with the email name@sanity.i
    const existingMember = result.find(
      (member) =>
        member.profile?.email === searchQuery || member.profile?.email?.includes(searchQuery)
    )

    if (existingMember && existingMember.profile) {
      handleSubmitExistingMember(existingMember.profile)
    } else {
      handleSubmit({
        email: searchQuery,
      })
    }
  }, [hasValidEmail, result, searchQuery, handleSubmitExistingMember, handleSubmit])

  const handleTextInputKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        handleSubmitNewMember()
      }

      if (event.key === 'Escape') {
        setSearchQuery('')
        setOpen(false)
        inputElement?.focus()

        // If the auto complete component is used inside e.g. a dialog
        // that closes on escape, we need to stop the event propagation
        // when the search query is not empty to prevent the dialog from closing.
        if (searchQuery) {
          event.stopPropagation()
        }
      }

      if (event.key === 'ArrowDown' && !searchQuery) {
        setOpen(true)
      }
    },
    [handleSubmitNewMember, inputElement, searchQuery]
  )

  const renderItem = useCallback<CommandListRenderItemCallback<MembersV2['member']>>(
    (item) => {
      return (
        <Card
          as="button"
          radius={2}
          marginBottom={1}
          padding={2}
          onClick={() => {
            if (!item?.profile) return

            handleSubmitExistingMember(item?.profile)
          }}
        >
          <Flex align="center" gap={2}>
            <UserAvatar
              displayName={item.profile?.displayName || ''}
              provider={item.profile?.provider}
              src={item.profile?.imageUrl || ''}
            />

            <Stack flex={1} space={2}>
              <Text size={1} textOverflow="ellipsis" weight="medium">
                {item.profile?.displayName}
              </Text>

              <Text muted size={1} textOverflow="ellipsis">
                {item.profile?.email}
              </Text>
            </Stack>
          </Flex>
        </Card>
      )
    },
    [handleSubmitExistingMember]
  )
  const content = useMemo(() => {
    // Check if we don't have a member with the same email. We check for both exact match
    // and if the email is included in the search query to prevent flashing the Invite <email> action
    const existingMember = result.find(
      (member) =>
        member.profile?.email === searchQuery || member.profile?.email?.includes(searchQuery)
    )

    if (hasValidEmail && !existingMember) {
      return (
        <Stack padding={2}>
          <Card as="button" radius={2} padding={3} onClick={handleSubmitNewMember}>
            <Flex align="center" gap={3}>
              <Text size={1}>
                <EnvelopeIcon />
              </Text>

              <Box flex={1}>
                <Text size={1} textOverflow="ellipsis">
                  Invite <b>{searchQuery}</b>
                </Text>
              </Box>

              <KBD>Enter</KBD>
            </Flex>
          </Card>
        </Stack>
      )
    }

    if (result.length === 0) return null

    return (
      <CommandList
        activeItemDataAttr="data-selected"
        ariaLabel="Select item"
        autoFocus="input"
        initialIndex={-1}
        inputElement={inputElement}
        itemHeight={40}
        items={result}
        padding={1}
        paddingBottom={0}
        renderItem={renderItem}
      />
    )
  }, [members, hasValidEmail, result, inputElement, renderItem, searchQuery, handleSubmitNewMember])

  const isOpen = useMemo(() => {
    if (!hasFocus) return false

    if (open) return true

    return hasValidEmail || (result.length > 0 && searchQuery.length > 0 && !hasValidEmail)
  }, [hasFocus, hasValidEmail, open, result.length, searchQuery.length])

  useClickOutside(() => {
    setOpen(false)
  }, [popoverElement])

  useEffect(() => {
    if (didInitialFocus.current) return

    const to = setTimeout(() => {
      inputElement?.focus()
      didInitialFocus.current = true
    })

    return () => {
      clearTimeout(to)
    }
  }, [content, inputElement])

  return (
    <StyledPopover
      animate
      constrainSize
      content={content}
      fallbackPlacements={POPOVER_FALLBACK_PLACEMENTS}
      floatingBoundary={document.body}
      matchReferenceWidth
      open={isOpen}
      placement="bottom-start"
      portal
      ref={setPopoverElement}
    >
      <StyledTextInput
        __unstable_disableFocusRing
        border={false}
        fontSize={1}
        onBlur={handleBlur}
        onChange={handleSearchChange}
        onFocus={handleFocus}
        onKeyDown={handleTextInputKeyDown}
        placeholder="Enter email address or search for a member"
        ref={handleSetInputElement}
        value={searchQuery}
      />
    </StyledPopover>
  )
}
