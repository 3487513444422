import React from 'react'
import {AccessDeniedIcon, CogIcon, DocumentIcon, EditIcon} from '@sanity/icons'
import {CardTone} from '@sanity/ui'
import {GrantName} from '@/types/models'

export type AbilityGrant = {
  name: GrantName
  type: string
}

type Ability = {
  name: string
  grants: AbilityGrant[]
}

export type Resource = {
  name: string
  title: string
  abilities: Ability[]
}

type ResourceGroup = {
  title: string
  resources: Resource[]
}

export const RESOURCE_GROUPS: ResourceGroup[] = [
  {
    title: 'Project',
    resources: [
      {
        name: 'project.details',
        title: 'Project details',
        abilities: [
          {
            name: 'Read',
            grants: [{name: 'read', type: 'sanity.project'}],
          },
          {
            name: 'Manage',
            grants: [
              {name: 'read', type: 'sanity.project'},
              {name: 'update', type: 'sanity.project'},
              {name: 'delete', type: 'sanity.project'},
            ],
          },
        ],
      },
      {
        name: 'project.usage',
        title: 'Can access project usage details',
        abilities: [
          {
            name: 'Read',
            grants: [{name: 'read', type: 'sanity.project.usage'}],
          },
        ],
      },
    ],
  },
  {
    title: 'Members and roles',
    resources: [
      {
        name: 'members.members',
        title: 'Project members',
        abilities: [
          {
            name: 'Read',
            grants: [{name: 'read', type: 'sanity.project.members'}],
          },
          {
            name: 'Update',
            grants: [
              {name: 'read', type: 'sanity.project.members'},
              {name: 'update', type: 'sanity.project.members'},
            ],
          },
          {
            name: 'Manage',
            grants: [
              {name: 'read', type: 'sanity.project.members'},
              {name: 'update', type: 'sanity.project.members'},
              {name: 'delete', type: 'sanity.project.members'},
            ],
          },
        ],
      },
      {
        name: 'members.invite',
        title: 'Can invite project members',
        abilities: [
          {
            name: 'Invite',
            grants: [{name: 'invite', type: 'sanity.project.members'}],
          },
        ],
      },
      {
        name: 'members.roles',
        title: 'Project roles',
        abilities: [
          {
            name: 'Read',
            grants: [{name: 'read', type: 'sanity.project.roles'}],
          },
          {
            name: 'Manage',
            grants: [
              {name: 'create', type: 'sanity.project.roles'},
              {name: 'read', type: 'sanity.project.roles'},
              {name: 'update', type: 'sanity.project.roles'},
              {name: 'delete', type: 'sanity.project.roles'},
            ],
          },
        ],
      },
      {
        name: 'members.session',
        title: 'Can create session for third party users',
        abilities: [
          {
            name: 'Create Session',
            grants: [{name: 'createSession', type: 'sanity.project'}],
          },
        ],
      },
    ],
  },
  {
    title: 'API settings',
    resources: [
      {
        name: 'api.tokens',
        title: 'Project tokens',
        abilities: [
          {
            name: 'Read',
            grants: [{name: 'read', type: 'sanity.project.tokens'}],
          },
          {
            name: 'Manage',
            grants: [
              {name: 'read', type: 'sanity.project.tokens'},
              {name: 'create', type: 'sanity.project.tokens'},
              {name: 'delete', type: 'sanity.project.tokens'},
            ],
          },
        ],
      },
      {
        name: 'api.cors',
        title: 'Project CORS settings',
        abilities: [
          {
            name: 'Read',
            grants: [{name: 'read', type: 'sanity.project.cors'}],
          },
          {
            name: 'Manage',
            grants: [
              {name: 'read', type: 'sanity.project.cors'},
              {name: 'create', type: 'sanity.project.cors'},
              {name: 'delete', type: 'sanity.project.cors'},
            ],
          },
        ],
      },
      {
        name: 'api.webhooks',
        title: 'Project webhooks',
        abilities: [
          {
            name: 'Read',
            grants: [{name: 'read', type: 'sanity.project.webhooks'}],
          },
          {
            name: 'Manage',
            grants: [
              {name: 'read', type: 'sanity.project.webhooks'},
              {name: 'create', type: 'sanity.project.webhooks'},
              {name: 'update', type: 'sanity.project.webhooks'},
              {name: 'delete', type: 'sanity.project.webhooks'},
            ],
          },
        ],
      },
      {
        name: 'api.graphql',
        title: 'Can manage GraphQL',
        abilities: [
          {
            name: 'Manage',
            grants: [{name: 'manage', type: 'sanity.project.graphql'}],
          },
        ],
      },
      {
        name: 'api.studio',
        title: 'Can deploy Studio',
        abilities: [
          {
            name: 'Deploy Studio',
            grants: [{name: 'deployStudio', type: 'sanity.project'}],
          },
        ],
      },
    ],
  },
  {
    title: 'Datasets and tags',
    resources: [
      {
        name: 'datasets.datasets',
        title: 'Project datasets',
        abilities: [
          {
            name: 'Read',
            grants: [{name: 'read', type: 'sanity.project.datasets'}],
          },
          {
            name: 'Manage',
            grants: [
              {name: 'read', type: 'sanity.project.datasets'},
              {name: 'create', type: 'sanity.project.datasets'},
              {name: 'update', type: 'sanity.project.datasets'},
              {name: 'delete', type: 'sanity.project.datasets'},
            ],
          },
        ],
      },
      {
        name: 'datasets.tags',
        title: 'Dataset tags',
        abilities: [
          {
            name: 'Read',
            grants: [{name: 'read', type: 'sanity.project.tags'}],
          },
          {
            name: 'Manage',
            grants: [
              {name: 'read', type: 'sanity.project.tags'},
              {name: 'create', type: 'sanity.project.tags'},
              {name: 'update', type: 'sanity.project.tags'},
              {name: 'delete', type: 'sanity.project.tags'},
            ],
          },
        ],
      },
    ],
  },
]

export const RESOURCE_MAP = RESOURCE_GROUPS.reduce(
  (acc, group) => {
    for (const resource of group.resources) {
      acc[resource.name] = resource
    }
    return acc
  },
  {} as Record<string, Resource>
)

export function grantKey(grant: AbilityGrant) {
  return `${grant.type}/${grant.name}`
}

type PermissionMode = {
  description: string
  icon: React.ElementType
  tone: CardTone
}

export const PERMISSION_MODES: Record<string, PermissionMode> = {
  'No access': {
    description: 'No access to the resource',
    icon: AccessDeniedIcon,
    tone: 'default',
  },
  Read: {
    description: 'Can only read the resource',
    icon: DocumentIcon,
    tone: 'primary',
  },
  Update: {
    description: 'Can read and edit but not remove the resource',
    icon: EditIcon,
    tone: 'primary',
  },
  Manage: {
    description: 'Full access to the resource',
    icon: CogIcon,
    tone: 'positive',
  },
}
