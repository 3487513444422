import React, {useCallback, useState} from 'react'
import {Stack, Flex, Button, Box, Card, Text, Badge, useToast} from '@sanity/ui'
import {AddIcon, EllipsisHorizontalIcon, TrashIcon} from '@sanity/icons'
import {useTokens} from '@/data/tokens/useTokens'
import {TableHeader} from '@/types/index'
import {
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableEmpty,
  TokenPreview,
  useConfirmDialog,
  TimeAgoText,
  PermissionButtonProject,
  TabHeader,
} from '@/ui/index'
import {Project, Token} from '@/types/models'
import {ApiTokenInput} from '@/components/form/edit-states'
import {useProjectRobotRolesList} from '@/context/index'
import {TokenContainer} from './TokenContainer'

const headers: TableHeader[] = [
  {
    id: 'name',
    label: 'Name',
    columnWidth: 4,
  },
  {
    id: 'permissions',
    label: 'Permissions',
    columnWidth: 2,
  },
  {
    id: 'created',
    label: 'Created',
    columnWidth: 1,
    hideOnMobile: true,
  },
  {
    id: 'edit',
    label: 'Edit',
    columnWidth: 0,
    disableTabButtonRender: true,
  },
]

export function TokensDetails({
  project,
  title,
  description,
}: {
  project: Project
  title: string
  description: string
}) {
  const [showAddToken, setShowAddToken] = useState(false)
  const [showAll, setShowAll] = useState(false)
  const {roles} = useProjectRobotRolesList(project.id)
  const {tokens, isLoading, addToken, deleteToken, isDeleting, isAdding} = useTokens(project)
  const [createdToken, setCreatedToken] = useState<Token | null>(null)
  const [itemToDelete, setItemToDelete] = useState<Token | null>(null)
  const isEmpty = !tokens || tokens.length <= 0
  const {showDialog, Dialog, hideDialog} = useConfirmDialog()

  const toast = useToast()

  const handleToggleAddToken = useCallback(() => {
    // Reset the created token state when adding starts
    setCreatedToken(null)
    setShowAddToken((prev) => !prev)
  }, [])

  const handleDeleteStart = (token: Token) => {
    setItemToDelete(token)
    showDialog()
  }

  const handleDeleteConfirm = (id: string) => {
    deleteToken(id, {
      onSuccess: () => {
        setItemToDelete(null)
        hideDialog({title: 'API token deleted', status: 'success'})
      },
      onError: (error) => {
        toast.push({title: error.message, status: 'error'})
      },
    })
  }

  const handleDeleteCancel = () => {
    setItemToDelete(null)
    hideDialog()
  }

  const handleSave = (payload: {role: string; label: string}) => {
    const tokenAlreadyExists = tokens.some((token) => token.label === payload.label)
    if (tokenAlreadyExists) {
      toast.push({description: 'A token with this name already exists', status: 'error'})
    } else {
      addToken(payload, {
        onSuccess: (payload) => {
          toast.push({title: 'Token created', status: 'success'})
          setShowAddToken(false)
          setCreatedToken(payload)
        },
        onError: (error) => {
          toast.push({title: error.message, status: 'error'})
        },
      })
    }
  }

  const sortByDate = (array: Token[]) => {
    return array.sort((a: Token, b: Token) => +new Date(b.createdAt) - +new Date(a.createdAt))
  }

  const getBadgeTone = (roleName: string) => {
    switch (roleName) {
      case 'read':
        return 'default'
      case 'write':
        return 'positive'
      case 'deploy-studio':
        return 'primary'
      default:
        return 'default'
    }
  }

  return (
    <Box>
      <Stack space={4}>
        <TabHeader
          title={title}
          description={description}
          button={
            <PermissionButtonProject
              title="Add API token"
              icon={AddIcon}
              mode="ghost"
              onClick={handleToggleAddToken}
              permissions={[{permissionName: 'sanity.project.tokens', grantName: 'create'}]}
              action="cannot add API tokens"
            />
          }
        />
        <Table
          headers={isEmpty ? [] : headers}
          loading={isLoading}
          loadingText="Loading API tokens..."
        >
          <TableBody>
            {showAddToken && (
              <TableRow paddingY={5} paddingX={3}>
                <TableCell style={{gridColumn: '1/-1'}}>
                  <Card style={{width: '100%'}}>
                    <ApiTokenInput
                      roles={roles || []}
                      onSave={(payload: {role: string; label: string}) => handleSave(payload)}
                      onCancel={() => setShowAddToken(false)}
                      isLoading={isAdding}
                    />
                  </Card>
                </TableCell>
              </TableRow>
            )}

            {!isEmpty &&
              sortByDate(tokens)
                ?.slice(0, showAll ? tokens.length : 3)
                .map((token, index) => (
                  <TableRow
                    key={token.id}
                    selected={false}
                    paddingY={3}
                    paddingX={2}
                    borderBottom={index + 1 !== (showAll ? tokens.length : 3)}
                  >
                    <TableCell>
                      <Text style={{wordBreak: 'break-word'}}>{token.label}</Text>
                    </TableCell>
                    <TableCell>
                      <Badge
                        tone={getBadgeTone(token.roles[0]?.name)}
                        mode="outline"
                        size={1}
                        radius={2}
                      >
                        {roles?.find((rol) => rol.name === token.roles[0]?.name)?.title ||
                          'Unknown'}
                      </Badge>
                    </TableCell>
                    <TableCell>
                      <TimeAgoText date={token.createdAt} muted size={1} />
                    </TableCell>
                    <TableCell>
                      <Flex justify="flex-end" style={{width: '100%'}}>
                        <Box>
                          <PermissionButtonProject
                            icon={TrashIcon}
                            mode="bleed"
                            aria-label="Delete"
                            onClick={() => handleDeleteStart(token)}
                            permissions={[
                              {permissionName: 'sanity.project.tokens', grantName: 'delete'},
                            ]}
                            action="Cannot delete API tokens"
                          />
                        </Box>
                      </Flex>
                    </TableCell>

                    {token.id === createdToken?.id && createdToken?.key && (
                      <TableCell style={{gridColumn: '1/-1'}}>
                        <Box marginTop={3}>
                          <TokenContainer token={createdToken?.key} />
                        </Box>
                      </TableCell>
                    )}
                  </TableRow>
                ))}

            {isEmpty && (
              <TableEmpty
                title="There are no API tokens yet"
                description="Maybe try creating one?"
              />
            )}
          </TableBody>
        </Table>
      </Stack>
      {tokens.length > 3 && (
        <Button
          icon={EllipsisHorizontalIcon}
          mode="ghost"
          onClick={() => setShowAll(!showAll)}
          style={{width: '100%'}}
        />
      )}

      {itemToDelete && (
        <Dialog
          id="delete-token-dialog"
          header="Delete API token"
          title="Do you really want to delete this API token?"
          description="This action cannot be undone."
          onClose={handleDeleteCancel}
          onConfirm={() => handleDeleteConfirm(itemToDelete.id)}
          confirmButtonProps={{text: 'Delete'}}
          loading={isDeleting}
        >
          <TokenPreview
            name={itemToDelete.label}
            createdAt={itemToDelete.createdAt}
            badgeTone={getBadgeTone(itemToDelete.roles[0]?.name)}
            roleTitle={
              roles?.find((rol) => rol.name === itemToDelete.roles[0].name)?.title || 'Unknown'
            }
          />
        </Dialog>
      )}
    </Box>
  )
}
