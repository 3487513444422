import React from 'react'
import {Box, Stack, Card, Flex, Text, Label, Heading, Spinner, studioTheme} from '@sanity/ui'
import {hues} from '@sanity/color'
import styled from 'styled-components'
import dynamic from 'next/dynamic'
import {renderToString} from 'react-dom/server'
import {format, parse} from 'date-fns'
import {Tooltip} from './tooltip'
import {
  formatDocumentsValue,
  formatRequestsValue,
  formatGigaBytesValue,
  formatQuotaPercent,
  getMaxYValueFromSeries,
  roundupNumber,
} from '@/utils/index'
import {EmptyBlock} from '@/ui/index'
import {ChartData} from '@/types/index'

// eslint-disable-next-line react/display-name
const Chart = dynamic(() => import('react-apexcharts'), {ssr: false, loading: () => <p>...</p>})

type LineChartProps = ChartData

const LoadingWrapper = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 2rem;
`

const CenteredSpinner = styled(Spinner)`
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`

// https://apexcharts.com/docs/options/stroke/
// Annotation: Show previous month top as line
// Show overage as green + green arrow
export function LineChart({
  heading,
  dataSeries,
  options,
  overageCostLabel,
  currentUsage,
  currentUsagePercent,
  showUsagePercent = false,
  curveStroke = 'smooth',
  isLoading = false,
  isFullHistory = false,
  yAxisFormat,
  currentPlanUsage,
  currentPlanQuota,
  usageType,
  description,
}: LineChartProps) {
  const formatters = new Map([
    ['number', formatRequestsValue],
    ['documents', formatDocumentsValue],
    ['size', formatRequestsValue],
    ['bytesPrecise', formatRequestsValue],
    ['gigaBytes', formatGigaBytesValue],
  ])
  const yAxisFormatter = formatters.get(yAxisFormat || '') || formatters.get('number')
  let xAxisFormatter = (value: number | string): string => {
    return parseInt(`${value}`, 10).toString()
  }
  const yAxisAnnotations: YAxisAnnotations[] = []
  const maxYValueFromSeries = getMaxYValueFromSeries(dataSeries, currentPlanQuota)
  // Check if the data series actually has data
  const hasData = dataSeries.some((s) => s.data.length > 0)
  // Get the max value for the y axis based on the max value from the data series
  // round up to the nearest 100/1000/10000 etc based on the max value
  const maxYaxis = roundupNumber(Math.ceil(maxYValueFromSeries))
  const tickAmount = maxYaxis < 4 ? maxYaxis : 4
  if (isFullHistory) {
    xAxisFormatter = (value: number | string): string => {
      if (typeof value === 'number') {
        return format(value, 'd. MMM')
      }
      return format(parse(`${value}`, 'T', new Date()), 'd. MMM')
    }
  }

  if (currentPlanQuota) {
    yAxisAnnotations.push({
      y: currentPlanQuota,
      offsetX: 5,
      borderColor: '#3AB667',
      strokeDashArray: 1,
      label: {
        position: 'left',
        textAnchor: 'start',
        borderColor: 'transparent',
        offsetY: 6,
        offsetX: 8,
        style: {
          color: '#3AB667',
          fontSize: '10px',
          fontFamily: studioTheme.fonts.text.family,
          padding: {
            left: 5,
            right: 5,
            bottom: 5,
          },
        },
        text: 'Current plan quota',
      },
    })
  }

  options = {
    ...options,
    chart: {
      animations: {
        enabled: false,
      },
      fontFamily: studioTheme.fonts.text.family,
      id: 'linechart',
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      offsetX: -15,
      events: {
        mounted: (ctx) => {
          const serie = dataSeries[dataSeries.length - 1]
          const lastX = serie?.data.length - 1
          const {x, y} = serie?.data[lastX]
          ctx?.addPointAnnotation({
            x,
            y,
            marker: {
              size: 3,
              strokeWidth: 12,
              fillColor: '#2276FC',
              strokeColor: '#2276FC33',
            },
          })
        },
      },
    },
    colors: dataSeries.length > 1 ? ['#F1F3F6', '#2276FC'] : ['#2276FC'],
    stroke: {
      width: 2,
      curve: curveStroke,
    },
    grid: {
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    legend: {
      onItemClick: {
        toggleDataSeries: false,
      },
    },
    xaxis: {
      tickAmount: 5,
      labels: {
        style: {
          colors: ['#1C2430'],
        },
        formatter: xAxisFormatter,
      },
      axisBorder: {
        show: true,
        color: '#E4E8ED',
        offsetX: 0,
        offsetY: 0,
      },
      axisTicks: {
        show: false,
      },
      type: isFullHistory ? 'datetime' : 'category',
    },
    yaxis: {
      max: maxYaxis,
      min: 0,
      tickAmount,
      axisBorder: {
        show: true,
        color: '#E4E8ED',
        offsetX: 0,
        offsetY: 0,
      },
      labels: {
        // align: 'left',
        // offsetX: 10,
        style: {
          colors: '#1C2430',
          fontFamily: studioTheme.fonts.text.family,
        },
        formatter: yAxisFormatter,
      },
    },
    tooltip: {
      custom: ({series, seriesIndex, dataPointIndex /* w */}) =>
        renderToString(
          <Tooltip
            series={series}
            seriesIndex={seriesIndex}
            dataPointIndex={dataPointIndex}
            formatter={yAxisFormatter}
          />
        ),
      x: {
        show: false,
        formatter: (val: number, opts?: any) => {
          const {seriesIndex} = opts

          /**
           * Because Apex wants to put timestamped series after eachother in the graph instead of overlayed,
           * we 1) fallback to the category option 2) Set the day # (so 1-31) on the x axis 3) fetch the timestamp from the
           * series object with the provided index when we want to display the tooltip
           */
          if (typeof seriesIndex !== 'undefined') {
            const {dataPointIndex, w} = opts
            const timestamp = w.config.series[seriesIndex].data[dataPointIndex].timestamp

            if (timestamp) {
              return format(parse(`${timestamp}`, 'T', new Date()), 'MMMM do yyyy')
            }
          }

          return format(parse(`${val}`, 'T', new Date()), 'MMMM do yyyy')
        },
      },
    },
    annotations: {
      yaxis: yAxisAnnotations,
    },
  }

  if (showUsagePercent && currentPlanQuota && currentPlanUsage) {
    currentUsagePercent = `${formatQuotaPercent(currentPlanUsage, currentPlanQuota)}%`
  }

  const getNoDataMessage = (type: string) => {
    switch (type) {
      case 'assets':
        return 'An asset is any file (JPG, PDF, DOC etc) that is uploaded to the project'
      case 'documents':
        return 'Documents are the individual records that can be edited in Sanity Studio or over APIs'
      case 'api-cdn-requests':
        return 'Start querying the APICDN and track the numbers here'
      case 'bandwidth':
        return 'Track the outgoing traffic numbers here'
      case 'api-requests':
        return 'Start querying the API and track the numbers here'
      default:
        return ''
    }
  }

  return (
    <Stack space={[3, 3, 4]}>
      <Box flex={1} frameBorder={1}>
        <Stack space={3}>
          <Heading size={1} as="h4">
            {heading}
          </Heading>
          <Text size={1} muted>
            {description}
          </Text>
        </Stack>
      </Box>
      <Card
        radius={2}
        border
        padding={[4, 4, 5]}
        style={{position: 'relative', minHeight: '200px'}}
      >
        {isLoading && (
          <LoadingWrapper>
            <CenteredSpinner size={3} muted />
          </LoadingWrapper>
        )}
        {!hasData && !isLoading && (
          <EmptyBlock
            border={false}
            illustration="screen"
            title="Nothing to show here yet"
            description={getNoDataMessage(usageType || '')}
          />
        )}

        {hasData && !isLoading && (
          <Stack space={4}>
            <Flex align="flex-start" justify="space-between">
              <Text size={2} color={hues.gray['700'].hex}>
                {currentUsage}
              </Text>
              {showUsagePercent && currentPlanQuota && (
                <Text size={2} color={hues.gray['700'].hex}>
                  {currentUsagePercent}
                </Text>
              )}
            </Flex>
            <Box style={{position: 'relative', minHeight: 200}}>
              <Chart options={options} series={dataSeries} type="line" width="100%" height="200" />
            </Box>
            {overageCostLabel && (
              <Flex align="flex-start" justify="space-between">
                <Label size={1} color={'#515E72'}>
                  Overage cost
                </Label>
                <Text size={3} color={'#515E72'}>
                  {overageCostLabel}
                </Text>
                <Text size={3} color={'#515E72'}>
                  $0.00
                </Text>
              </Flex>
            )}
          </Stack>
        )}
      </Card>
    </Stack>
  )
}
