import {Text, Stack, Button, Card, TextInput, Flex, Grid} from '@sanity/ui'
import {CopyIcon, CheckmarkIcon, ArrowRightIcon} from '@sanity/icons'
import {useState} from 'react'
import {motion, AnimatePresence} from 'framer-motion'
import type {TemplateData} from '@/data/projects/useTemplateData'
import {useCurrentScopeContext} from '@/context/useCurrentScopeContext'
import {useDatasets} from '../../../../data'

export function NoTemplateLessonsFallback({template}: {template?: TemplateData}) {
  const [hasCopied, setHasCopied] = useState(false)
  const {projectId} = useCurrentScopeContext()
  const {data: datasets} = useDatasets(projectId)

  const shorthandGitUrl = template?.repository.split('github.com/')[1] // e.g. 'sanity-io/sanity-template-nextjs-blog'
  const dataset = datasets?.[0].name || 'production'

  const cliCommand = `npm create sanity@latest -- --template ${shorthandGitUrl} --project ${projectId} --dataset ${dataset}`

  const handleCopy = () => {
    navigator.clipboard.writeText(cliCommand)
    setHasCopied(true)
    setTimeout(() => setHasCopied(false), 2000)
  }

  return (
    <Grid columns={[1, 2]} gap={4}>
      <Card padding={5} shadow={1} radius={4}>
        <Stack space={4}>
          <Text size={3} weight="semibold">
            Quick Setup Command
          </Text>
          <Text size={2} muted>
            Copy and paste this command into your terminal to quickly set up this template
          </Text>

          <Grid gap={3} style={{gridTemplateColumns: '1fr auto'}}>
            <TextInput value={cliCommand} readOnly onFocus={(e) => e.target.select()} padding={3} />
            <Button
              icon={
                <AnimatePresence mode="wait">
                  <motion.div
                    key={hasCopied ? 'check' : 'copy'}
                    initial={{opacity: 0, y: 10}}
                    animate={{opacity: 1, y: 0}}
                    exit={{opacity: 0, y: -10}}
                  >
                    {hasCopied ? <CheckmarkIcon /> : <CopyIcon />}
                  </motion.div>
                </AnimatePresence>
              }
              onClick={handleCopy}
              text="Copy"
              padding={3}
            />
          </Grid>
        </Stack>
      </Card>

      <Card padding={5} shadow={1} radius={4}>
        <Stack space={4}>
          <Text size={3} weight="semibold">
            Documentation
          </Text>
          <Text size={2} muted>
            Learn more about this template's features and configuration options
          </Text>

          <Flex gap={3} justify="flex-end">
            <Button
              as="a"
              href={template?.repository}
              target="_blank"
              rel="noreferrer"
              text="View repo"
              iconRight={<ArrowRightIcon />}
              mode="ghost"
              padding={3}
            />
            <Button
              as="a"
              href={`${template?.repository}/blob/main/README.md`}
              target="_blank"
              rel="noreferrer"
              text="View README"
              iconRight={<ArrowRightIcon />}
              padding={3}
            />
          </Flex>
        </Stack>
      </Card>
    </Grid>
  )
}
