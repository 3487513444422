import {useQuery} from '@tanstack/react-query'
import {fetchPlanPreview} from './controller'
import {timeToMilliseconds} from '@/utils/time'
import {getPreviewPlanKey} from './cache'

export function usePreviewProjectPlan(
  planId: string,
  options?: {
    projectId?: string
    features?: string[]
    overagePriceFor?: string[]
  }
) {
  return useQuery({
    queryKey: getPreviewPlanKey(planId, options),
    queryFn: () => fetchPlanPreview(planId, options),
    staleTime: timeToMilliseconds(1, 'days'),
  })
}
