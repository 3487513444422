import React, {useCallback, useEffect} from 'react'
import {Box, Stack, Label, Text, Flex, Button, useToast, Badge} from '@sanity/ui'
import {ArrowRightIcon, CopyIcon} from '@sanity/icons'
import {TabPageHeader} from '..'
import {ProjectPlanBadge} from '../general'
import {ProjectIcon} from './projectIcon'
import {useCopyToClipboard} from '@/utils/index'
import {useProjectType, useRoutePath} from '@/context/index'
import {useStudios} from '@/data/studios/useStudios'
import {Organization, Project} from '@/types/index'
import {getStudioHost} from '@/utils/studioHost'
import {useProjectSubscription} from '@/data/projects/useProjectSubscription'
import Link from 'next/link'

export function ProjectHeader({project, org}: {project: Project; org: Organization | undefined}) {
  const {data: subscription, isLoading: isSubscriptionLoading} = useProjectSubscription(project?.id)
  const {orgPath, basePath} = useRoutePath()

  const {data: studios = [], error: studioHostError} = useStudios(project.id)

  const hasStudios = studios.length > 0
  const url = getStudioHost(studios)

  const isActive = project?.isDisabledByUser === false

  const projectType = useProjectType(project)
  const isRegularProject = projectType == 'regular'

  const {push} = useToast()
  const {copyToClipboard, success, error} = useCopyToClipboard()

  const handleCopyToClipboard = useCallback(() => {
    copyToClipboard(project?.id || '')
  }, [copyToClipboard, project?.id])

  useEffect(() => {
    if (success) {
      push({
        title: 'Copied project id to clipboard',
        status: 'success',
      })
      return
    }

    if (error) {
      push({
        title: 'Failed to copy project id to clipboard',
        status: 'error',
      })
    }
  }, [success, error, push])

  const marginRight = [4, 4, 6, 6]
  const marginBottom = [4, 4, 0, 0]

  return (
    <TabPageHeader
      title={project?.displayName || ''}
      icon={<ProjectIcon project={project} size={3} />}
      link={{
        title: org?.name || '',
        href: org?.id === 'personal' ? `${orgPath}/projects` : orgPath,
      }}
    >
      <Flex direction={['column', 'column', 'row', 'row']} marginTop={[2, 2, 0, 0]}>
        {/* 1 */}
        <Box marginRight={marginRight} marginBottom={marginBottom}>
          <Stack space={1}>
            <Label muted weight="medium">
              {isRegularProject ? 'Plan' : 'Type'}
            </Label>
            <Box paddingTop={1}>
              <ProjectPlanBadge
                project={project}
                subscription={subscription}
                isLoading={isSubscriptionLoading}
              />
            </Box>
          </Stack>
        </Box>
        <Box marginRight={marginRight} marginBottom={marginBottom}>
          <Stack space={1}>
            <Label muted weight="medium">
              STATUS
            </Label>
            <Box paddingTop={1}>
              <Badge tone={isActive ? 'positive' : 'caution'} mode="outline">
                {isActive ? 'Active' : 'Archived'}
              </Badge>
            </Box>
          </Stack>
        </Box>

        {/* 2 */}
        <Flex>
          <Box marginRight={marginRight} marginBottom={marginBottom}>
            <Stack space={1}>
              <Label muted weight="medium">
                Project ID
              </Label>
              <Flex align="center">
                <Text size={1}>{project?.id}</Text>
                <Box style={{marginLeft: 2}}>
                  <Button
                    fontSize={0}
                    padding={2}
                    icon={CopyIcon}
                    tone="default"
                    mode="bleed"
                    onClick={handleCopyToClipboard}
                  />
                </Box>
              </Flex>
            </Stack>
          </Box>
          <Box marginRight={marginRight} flex={1}>
            <Stack space={3}>
              {!studioHostError && hasStudios && (
                <Link href={url ?? `${basePath}/studios`} passHref legacyBehavior>
                  <Button
                    tone="primary"
                    as="a"
                    text={url ? 'Open Sanity Studio' : 'View Studios'}
                    iconRight={ArrowRightIcon}
                    target={url ? '_blank' : '_self'}
                  />
                </Link>
              )}
            </Stack>
          </Box>
        </Flex>
      </Flex>
    </TabPageHeader>
  )
}
