import {Flex} from '@sanity/ui'
import {type PropsWithChildren} from 'react'

export function CellContentWrap(props: PropsWithChildren) {
  const {children} = props

  return (
    <Flex align="center" height="fill">
      {children}
    </Flex>
  )
}
