import React from 'react'
import styled from 'styled-components'
import {Card, Grid, Stack, Text, Badge, Flex} from '@sanity/ui'
import {TimeAgoText} from '@/ui/index'

const Title = styled(Text)`
  text-transform: uppercase;
`

type Props = {
  origin: string
  allowCredentials: boolean
  createdAt: string | Date
}

export function CorsPreview({origin, allowCredentials, createdAt}: Props) {
  return (
    <Card border radius={2} padding={1} paddingTop={2} paddingBottom={2}>
      <Grid columns={1} gap={1}>
        <Card padding={3}>
          <Stack space={3}>
            <Title muted weight="semibold" size={0}>
              origin
            </Title>
            <Text>{origin}</Text>
          </Stack>
        </Card>
        <Card padding={3}>
          <Stack space={3}>
            <Title muted weight="semibold" size={0}>
              credentials
            </Title>
            <Flex>
              <Badge tone={allowCredentials ? 'positive' : 'default'} mode="outline" size={1}>
                {allowCredentials ? 'allowed' : 'not allowed'}
              </Badge>
            </Flex>
          </Stack>
        </Card>
        <Card padding={3}>
          <Stack space={3}>
            <Title muted weight="semibold" size={0}>
              created
            </Title>
            <TimeAgoText date={createdAt} muted />
          </Stack>
        </Card>
      </Grid>
    </Card>
  )
}
