import React, {useState} from 'react'
import {Stack, Box} from '@sanity/ui'
import {ArchiveIcon, MasterDetailIcon, TrashIcon} from '@sanity/icons'
import {ACTIVE_LARGE_TABLE} from './tableConfig'
import {
  UnarchiveProjectDialog,
  DeleteProjectDialog,
  ProjectItemLarge,
  TabHeader,
  Table,
  TableBody,
  TableEmpty,
  useConfirmDialog,
} from '@/ui/index'
import {Project, TableRowAction} from '@/types/index'
import {useCurrentScopeContext, useRoutePath} from '@/context/index'

type Props = {
  description: string
}

export function ArchivedProjects({description}: Props) {
  const {basePath} = useRoutePath()
  const {org} = useCurrentScopeContext()
  const {
    showDialog: showDeleteDialog,
    Dialog: DeleteDialog,
    hideDialog: hideDeleteDialog,
  } = useConfirmDialog()
  const {
    showDialog: showUnarchiveDialog,
    Dialog: UnarchiveDialog,
    hideDialog: hideUnarchiveDialog,
  } = useConfirmDialog()
  const [activeProjects, setActiveProjects] = useState<Project[]>([])
  const projects = org?.projects || []
  const filteredProjects =
    projects.filter((project) => project.isDisabled || project.isDisabledByUser) || []

  const handleDeleteProject = (project: Project) => {
    setActiveProjects([project])
    showDeleteDialog()
  }

  const handleUnarchiveProject = (project: Project) => {
    setActiveProjects([project])
    showUnarchiveDialog()
  }

  const ProjectItem = ProjectItemLarge
  const headers = ACTIVE_LARGE_TABLE

  const rowActions: TableRowAction[] = [
    {
      title: 'View project',
      icon: MasterDetailIcon,
      href: {basePath: `${basePath}/project`, rowId: true},
    },
    'divider',
    {
      title: 'Unarchive project',
      icon: ArchiveIcon,
      tone: 'critical',
      permissions:
        org?.id === 'personal'
          ? undefined
          : [{permissionName: 'sanity.project', grantName: 'update'}],
      onAction: handleUnarchiveProject,
      dialog: activeProjects[0] && (
        <UnarchiveProjectDialog
          key="unarchive-project-dialog"
          project={activeProjects[0]}
          hideDialog={hideUnarchiveDialog}
          Dialog={UnarchiveDialog}
        />
      ),
    },
    {
      title: 'Delete project',
      icon: TrashIcon,
      tone: 'critical',
      permissions:
        org?.id === 'personal'
          ? undefined
          : [{permissionName: 'sanity.project', grantName: 'delete'}],
      onAction: handleDeleteProject,
      dialog: activeProjects[0] && (
        <DeleteProjectDialog
          key="delete-project-dialog"
          project={activeProjects[0]}
          hideDialog={hideDeleteDialog}
          Dialog={DeleteDialog}
        />
      ),
    },
  ]

  return (
    <Stack space={4}>
      {rowActions.map((action) => typeof action !== 'string' && action?.dialog)}
      <TabHeader title="Archived projects" description={description} />
      <Box paddingTop={4}>
        <Table headers={projects.length > 0 ? headers : []}>
          <TableBody>
            {filteredProjects.length > 0 ? (
              filteredProjects.map((project) => (
                <ProjectItem key={project.id} project={project} rowActions={rowActions} />
              ))
            ) : (
              <TableEmpty
                title="There are no archived projects"
                description="When you disable a project it will show up here"
              />
            )}
          </TableBody>
        </Table>
      </Box>
    </Stack>
  )
}
