import React from 'react'
import {Box} from '@sanity/ui'
import {Tab, Organization} from '@/types/index'
import {SinglePageTab} from '@/ui/app'

export function Settings({tab, org}: {tab: Tab; org: Organization}) {
  const headings = tab?.headings || []

  return <Box>{tab && <SinglePageTab tab={tab} headings={headings} org={org} />}</Box>
}
