import {useMemo} from 'react'

import {Project, Organization} from '@/types/models'

export function useProjectHasFeature(project?: Project, feature?: string, initalValue = false) {
  const hasFeature = useMemo(() => {
    if (!feature || project === undefined) {
      return initalValue
    }

    const projectFeatures = project?.features || []
    return projectFeatures.includes(feature)
  }, [feature, project, initalValue])

  return hasFeature
}

export function useOrganizationHasFeature(
  organization?: Organization,
  feature?: string,
  initalValue = false
) {
  const hasFeature = useMemo(() => {
    if (!feature || organization === undefined) {
      return initalValue
    }

    const orgFeatures = organization?.features || []
    return orgFeatures.includes(feature)
  }, [feature, organization, initalValue])

  return hasFeature
}
