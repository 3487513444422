import React, {useCallback, useMemo, useRef, useState} from 'react'
import {
  Box,
  Text,
  Card,
  Flex,
  Menu as UIMenu,
  MenuButton,
  MenuItem,
  Button as UIButton,
  MenuDivider,
  Stack,
  useToast,
} from '@sanity/ui'
import {AddIcon, ChevronDownIcon, InfoOutlineIcon} from '@sanity/icons'
import {useRouter} from 'next/router'
import styled, {css} from 'styled-components'
import {Li, Ul} from '@/ui/index'
import {useTransferProject} from '@/data/projects'
import {useTransferToOrgList} from '@/data/organizations'
import {DialogToast, Project, Organization} from '@/types/index'
import {AppLink, SanityLogoSmall} from '@/ui/app'
import {isAPIError} from '@/data/util/promiseRequest'
import {useCacheInvalidation} from '@/data/base/useCacheInvalidation'

type Props = {
  project: Project
  Dialog: React.ElementType
  hideDialog: (dialogToast: DialogToast) => void
}

const Button = styled(UIButton)`
  & [data-ui='Text'] {
    color: ${({theme}) => theme.sanity.color.base.fg};
    font-weight: 600;
  }
  [data-ui='Box'] {
    [data-ui='Flex'] {
      align-items: center;
    }
  }
`

const Menu = styled(UIMenu)<{$width: any; $hasOrgs?: boolean}>`
  max-height: 400px;

  ${({$hasOrgs: hasOrgs}) =>
    hasOrgs &&
    css`
      max-height: 250px;
    `}

  overflow-y: auto;
  box-sizing: border-box;
  /* min-width: 400px; */
  width: ${({$width: width}) => (width ? `${width}px` : `400px`)};

  @media (max-width: ${({theme}) => theme.sanity.media[1]}px) {
    min-width: calc(100vw - 80px);
  }
`

const LogoCard = styled(Card)`
  background: ${({theme}) => theme.sanity.color.muted.transparent.enabled.bg};
  width: 30px;
  height: 30px;
  border-radius: 100%;
`

const PlaceholderAvatar = () => (
  <LogoCard padding={1}>
    <SanityLogoSmall />
  </LogoCard>
)

export function TransferOwnershipDialog({Dialog, hideDialog, project}: Props) {
  const {invalidateProject} = useCacheInvalidation()
  const [orgToTransfer, setOrgToTransfer] = useState<Organization | null>(null)
  const {data: orgs} = useTransferToOrgList(project.organizationId || undefined)
  const router = useRouter()
  const boxRef = useRef<HTMLDivElement>()
  const toast = useToast()
  const {isLoading, error, transferProject} = useTransferProject()

  const handleTransferOrg = useCallback(() => {
    transferProject(
      {projectId: project.id, organizationId: orgToTransfer?.id ?? null},
      {
        onSuccess: () => {
          hideDialog({
            title: 'The project was successfully transferred',
            status: 'success',
          })

          setTimeout(() => {
            router.push(`/organizations/${orgToTransfer?.id}/project/${project.id}`)
          }, 1000)
        },
        onError: (error) => {
          if (
            isAPIError(error) &&
            error.statusCode === 400 &&
            error.response.includes('Project is already a part of this organization')
          ) {
            toast.push({
              title: 'Project already in organization',
              status: 'info',
            })

            return
          }

          toast.push({
            title: 'The project was not transferred',
            status: 'error',
            description: error?.message,
          })
        },
        onSettled: () => {
          invalidateProject(project.id, {
            invalidateAllProjects: true,
          })
        },
      }
    )
  }, [transferProject, project.id, orgToTransfer?.id, hideDialog, router, toast, invalidateProject])

  const handleSelectOrg = useCallback((org: Organization) => {
    setOrgToTransfer(org)
  }, [])

  const handleClose = useCallback(() => {
    setOrgToTransfer(null)
  }, [])

  const buttonText = useMemo(
    () =>
      orgToTransfer ? (
        <Box>
          <Flex align="center">
            <PlaceholderAvatar />

            <Box marginLeft={3}>
              <Text weight="semibold">{orgToTransfer.name}</Text>
            </Box>
          </Flex>
        </Box>
      ) : (
        'Select a organization'
      ),
    [orgToTransfer]
  )

  return (
    <Dialog
      id="transfer-project-ownership-dialog"
      header="Transfer ownership"
      title={
        <>
          {error && (
            <Card tone="critical" padding={4} radius={2}>
              <Flex align="center">
                <Box>
                  <Text size={1}>
                    <InfoOutlineIcon />
                  </Text>
                </Box>
                <Box marginLeft={4}>
                  <Text size={1}>
                    Transfer could not be completed. Try again or contact support if the error does
                    not resolve.
                  </Text>
                </Box>
              </Flex>
            </Card>
          )}

          <Text size={2} weight="bold">
            {`You are about to transfer the project ${project.displayName}`}
          </Text>
        </>
      }
      onConfirm={handleTransferOrg}
      loading={isLoading}
      confirmButtonProps={{text: 'Transfer ownership', tone: 'primary'}}
      disabled={!orgToTransfer}
      onClose={handleClose}
    >
      <Stack space={4}>
        <Ul>
          <Li>
            The project will, with immediate effect, be transferred to the receiving organization
          </Li>
          <Li>
            All project data, plan, settings, members and groups will remain the same. There will be
            no interruption to the service of the project
          </Li>
          <Li>
            Sending organization and the receiving organization will be prorated any monthly plan
            costs
          </Li>
          <Li>
            Receiving organization will be responsible for all overage charges accrued for the
            current month
          </Li>
        </Ul>

        <Flex>
          <Box flex={1} ref={boxRef as any}>
            <MenuButton
              portal
              id="transfer-ownership-menu"
              button={
                <Card shadow={1} radius={2}>
                  <Button
                    text={buttonText}
                    iconRight={ChevronDownIcon}
                    mode="bleed"
                    tone="primary"
                    paddingX={4}
                    style={{width: '100%', height: 51}}
                  />
                </Card>
              }
              menu={
                <Menu $width={boxRef.current?.clientWidth} $hasOrgs={orgs && orgs?.length > 0}>
                  <Card paddingBottom={6}>
                    {orgs?.map((org: Organization, index: number) => (
                      <Box key={org.id}>
                        <MenuItem onClick={() => handleSelectOrg(org)} padding={2}>
                          <Flex align="center">
                            <PlaceholderAvatar />

                            <Box marginLeft={3}>
                              <Text weight="semibold">{org.name}</Text>
                            </Box>
                          </Flex>
                        </MenuItem>
                        {index !== orgs.length - 1 && <MenuDivider key={org.id} />}
                      </Box>
                    ))}

                    {(!orgs || orgs.length === 0) && (
                      <Stack>
                        <Flex justify="center" align="center" paddingY={5} style={{maxWidth: 400}}>
                          <Box paddingY={2} flex={1}>
                            <Stack space={4}>
                              <Text muted weight="semibold" size={1} style={{textAlign: 'center'}}>
                                No organizations available for transfer
                              </Text>
                              <Text muted size={1} style={{textAlign: 'center'}}>
                                To transfer a project, you need to have administrator or billing
                                manager permissions in both organizations.
                              </Text>
                            </Stack>
                          </Box>
                        </Flex>
                        <Card padding={2} radius={3}>
                          <AppLink href="/manage/create-new-team">
                            <UIButton
                              text="Create new organization"
                              style={{width: '100%'}}
                              mode="ghost"
                              as="span"
                              icon={AddIcon}
                              muted
                            />
                          </AppLink>
                        </Card>
                      </Stack>
                    )}

                    {orgs && orgs?.length > 0 && (
                      <MenuItem
                        padding={0}
                        style={{
                          position: 'absolute',
                          bottom: 0,
                          left: 0,
                          width: '100%',
                          background: 'transparent',
                          outline: 0,
                        }}
                        as="span"
                      >
                        <MenuDivider />
                        <Card padding={2} radius={3}>
                          <AppLink href="/manage/create-new-team">
                            <UIButton
                              text="Create new organization"
                              style={{width: '100%'}}
                              mode="ghost"
                              as="span"
                              icon={AddIcon}
                              muted
                            />
                          </AppLink>
                        </Card>
                      </MenuItem>
                    )}
                  </Card>
                </Menu>
              }
            />
          </Box>
        </Flex>
      </Stack>
    </Dialog>
  )
}
