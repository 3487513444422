import Image from 'next/image'
import {Box, Button, Flex, Grid, Text} from '@sanity/ui'
import {GetStartedHeading} from '@/ui/components/get-started/components/GetStartedHeading'
import {NextStepsCard} from '@/ui/components/get-started/components/NextStepsCard'
import styled from 'styled-components'
import {useManageScheme} from '@/ui/app/manageSchemeProvider'

// import customerStories from '@/ui/components/get-started/images/customer-stories.png'
import createPromoLight from '@/ui/components/get-started/images/create-promo-light.svg'
import createPromoDark from '@/ui/components/get-started/images/create-promo-dark.svg'
import exploreDemoLight from '@/ui/components/get-started/images/explore-demo-light.png'
import exploreDemoDark from '@/ui/components/get-started/images/explore-demo-dark.png'
import personalizedDemoLight from '@/ui/components/get-started/images/personalized-demo-light.svg'
import personalizedDemoDark from '@/ui/components/get-started/images/personalized-demo-dark.svg'
import {InviteForm} from '@/ui/components/get-started/components/inviteForm'
import {useGettingStartedStore} from '@/ui/components/get-started/store'
import {useRouter} from 'next/router'
import {useRoutePath} from '@/context/useRoutePathContext'
import {useConfirmDialog} from '@/components/dialog/useConfirmDialog'
import {sendAmplitudeTrackingEvent} from '@/utils/tracking'
import {useCurrentScopeContext} from '@/context/useCurrentScopeContext'
import {updateProjectOnboardingData} from '@/data/index'

const ImageContainer = styled(Box)`
  margin-top: auto;
`

const StyledImage = styled(Image)`
  width: 100%;
  height: auto;
  align-self: end;
`

export function NonDeveloperView() {
  const router = useRouter()
  const {resolvedScheme} = useManageScheme()
  const {projectId, org} = useCurrentScopeContext()
  const {dispatch} = useGettingStartedStore()
  const {basePath} = useRoutePath()
  const {showDialog, Dialog, hideDialog} = useConfirmDialog()

  const handleDismiss = () => {
    sendAmplitudeTrackingEvent('Getting Started Page Dismissed', projectId, org?.id, {
      persona: 'non-developer',
    })
    dispatch({type: 'getStarted/setDismissed', value: true})
    updateProjectOnboardingData(projectId!, {disabled: true})
    router.push(basePath)
  }

  return (
    <>
      <GetStartedHeading />
      <Grid columns={[1, 2, 2, 4]} marginY={6} gap={3}>
        <NextStepsCard
          borderTone="critical"
          badgeTone="critical"
          badgeText="We recommend"
          heading="Invite a developer"
        >
          <Text muted size={1}>
            Setting up Sanity requires familiarity with JavaScript. Invite a developer to let them
            complete the project setup.
          </Text>
          <InviteForm />
        </NextStepsCard>
        <NextStepsCard
          badgeTone="default"
          badgeText="Demos"
          heading="Explore a demo"
          link="https://www.sanity.io/product-demo?ref=manage-get-started"
        >
          <Text muted size={1}>
            Watch a demo video or try Sanity for yourself with our interactive demo.
          </Text>
          <ImageContainer paddingTop={1}>
            <StyledImage
              src={resolvedScheme === 'light' ? exploreDemoLight : exploreDemoDark}
              alt=""
              quality={90}
            />
          </ImageContainer>
        </NextStepsCard>
        <NextStepsCard
          badgeTone="positive"
          badgeText="Sanity Create"
          heading="Write content faster"
          link="https://www.sanity.io/app/create?ref=manage-get-started"
        >
          <Text muted size={1}>
            Meet Sanity Create, an intuitive AI writing assistant that connects with your Studio.
          </Text>
          <ImageContainer paddingTop={1}>
            <StyledImage
              src={resolvedScheme === 'light' ? createPromoLight : createPromoDark}
              alt=""
              quality={90}
            />
          </ImageContainer>
        </NextStepsCard>
        {/* <NextStepsCard
          badgeTone="default"
          badgeText="Customers"
          heading="Browse customer stories"
          link="https://www.sanity.io/case-studies?ref=manage-get-started"
        >
          <Text muted size={1}>
            See how companies like Puma, AT&T, and Amplitude use Sanity to create remarkable digital
            experiences.
          </Text>
          <ImageContainer paddingTop={1}>
            <StyledImage src={customerStories} alt="" quality={90} />
          </ImageContainer>
        </NextStepsCard> */}
        <NextStepsCard
          badgeTone="default"
          badgeText="Chat with us"
          heading="Get a personalized demo"
          link="https://www.sanity.io/contact/sales?ref=manage-get-started"
        >
          <Text muted size={1}>
            Request a personalized demo with our sales team to see Sanity in action.
          </Text>
          <ImageContainer paddingTop={1}>
            <StyledImage
              src={resolvedScheme === 'light' ? personalizedDemoLight : personalizedDemoDark}
              alt=""
              quality={90}
            />
          </ImageContainer>
        </NextStepsCard>
      </Grid>
      <Flex justify="center">
        <Button
          padding={2}
          text="Dismiss “Getting started”"
          textAlign="center"
          onClick={showDialog}
        />
      </Flex>
      <Dialog
        id="dismiss-getting-started"
        header='Dismiss "Getting started"'
        description='Are you sure you want to hide the "Getting started" section for all members of this project?'
        onClose={hideDialog}
        onConfirm={handleDismiss}
        confirmButtonProps={{tone: 'primary', text: 'Dismiss'}}
      />
    </>
  )
}
