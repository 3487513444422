/* eslint-disable react/jsx-no-bind */
import {Box, Button, Stack} from '@sanity/ui'
import React from 'react'
import styled from 'styled-components'
import {AddIcon} from '@sanity/icons'
import {Project, TableHeader, Organization} from '@/types/index'
import {Table, TableBody} from '@/ui/index'

type Props = {
  fields: any[]
  project?: Project
  org?: Organization
  rowComponent?: any
  addButtonText: string
  maxRows?: number | null | undefined
  onInsert?: () => void
  onDelete?: (index: number) => void
  // onChange?: (value: string, id: string) => void
}

const headers: TableHeader[] = [
  {
    id: 'email',
    label: 'Email',
    columnWidth: 5,
  },
  {
    id: 'Role',
    label: 'Role',
    columnWidth: 3,
  },
  {
    id: 'warning',
    label: 'Warning',
    columnWidth: 2,
    disableTabButtonRender: true,
  },
  {
    id: 'actions',
    label: 'Actions',
    columnWidth: 1,
    disableTabButtonRender: true,
  },
]

export function RepeatableTableGroup({
  fields = [],
  addButtonText,
  rowComponent,
  maxRows,
  project,
  org,
  onInsert,
  onDelete,
}: Props) {
  const RowComponent = rowComponent
  const validFieldsCount = fields.length
  const hasHitMaxRows = maxRows ? maxRows === validFieldsCount : false
  return (
    <Stack space={0}>
      <Box>
        <Table headers={headers}>
          <TableBody>
            {fields.map((field, index) => (
              <RowComponent
                borderBottom={index < fields.length - 1}
                key={field.id}
                label={field.label}
                {...field}
                index={index}
                project={project}
                org={org}
                onDelete={onDelete ? () => onDelete(index) : undefined}
              />
            ))}
          </TableBody>
        </Table>
      </Box>
      <Box marginTop={fields.length > 0 ? 3 : 0}>
        <AddButton
          icon={AddIcon}
          mode="ghost"
          padding={3}
          text={addButtonText}
          disabled={hasHitMaxRows || maxRows === 0}
          style={{width: '100%'}}
          onClick={onInsert}
        />
      </Box>
    </Stack>
  )
}

const AddButton = styled(Button)`
  &:disabled {
    cursor: not-allowed;
  }
`
