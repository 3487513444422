import React, {useEffect, useState} from 'react'
import {useOrganizationData} from '../../../../data'
import {Organization} from '@/types/index'
import {CheckboxInput, ErrorTooltip, TabHeader} from '@/ui/index'
import {Button, Card, Flex, Heading, Inline, Stack, Text, TextInput, useToast} from '@sanity/ui'
import {AddIcon, TrashIcon} from '@sanity/icons'
import {useRequiredOrganizationPermissions} from '@/context/useOrganizationPermissions'
import {useForm} from 'react-hook-form'
import freeEmailDomains from 'free-email-domains'
import isValidDomain from 'is-valid-domain'

export function OrgAccessRequestSettings({org, title}: {org: Organization; title: string}) {
  const {
    data: orgData,
    patchOrganizationData: editOrg,
    patchSuccess,
    patchError: error,
  } = useOrganizationData(org)

  const canManage = useRequiredOrganizationPermissions([
    {permissionName: 'sanity.organization', grantName: 'update'},
  ])

  const {push} = useToast()

  // success toast with content
  const [successMessage, setSuccessMessage] = useState('')

  const requestAccessDisabled = orgData?.requestAccessStatus === 'disabled'
  const {
    register,
    handleSubmit,
    reset,
    formState: {errors: formErrors},
  } = useForm<{domain: string}>({disabled: !canManage || requestAccessDisabled})

  useEffect(() => {
    if (patchSuccess) {
      if (successMessage) {
        push({
          status: 'info',
          description: successMessage,
          closable: true,
        })
      }
      // clear input only after succesful update
      reset()
    } else if (error) {
      // API error toast
      push({
        status: 'error',
        title: 'Failed update organization access request settings',
        description: 'Invalid domain',
      })
    }
  }, [patchSuccess, successMessage, error, push, reset])

  const domainValidator = (domainCandidate: string) => {
    if (!isValidDomain(domainCandidate)) {
      return 'Please enter a valid domain'
    }
    if (freeEmailDomains.includes(domainCandidate)) {
      return 'Freemium domains not supported'
    }
    if (orgData?.domains?.includes(domainCandidate)) {
      return 'Domain already added'
    }
    return true
  }

  const toggleAccessRequest = () => {
    editOrg({
      requestAccessStatus: orgData?.requestAccessStatus === 'allowed' ? 'disabled' : 'allowed',
    })
  }

  const addDomain = ({domain}: {domain: string}) => {
    editOrg({domains: [...(orgData?.domains || []), domain.toLocaleLowerCase()]})
    setSuccessMessage(domain + ' added to allowed domains list')
  }

  const removeDomain = (domain: string) => {
    let domains = orgData?.domains?.filter((dom) => dom !== domain) || null
    if (!domains?.length) {
      domains = null
    }
    editOrg({domains})
    setSuccessMessage(domain + ' removed from allowed domains list')
  }

  return (
    <>
      <TabHeader
        title={title}
        // referenceLink={{
        //   url: `https://www.${process.env.host}/docs/`, // TODO: Fix URL when doc link is known
        //   label: 'View documentation for API configuration',
        // }}
        description="Control which domains can request access to organization."
      />
      <Card
        radius={3}
        border
        paddingLeft={4}
        paddingTop={4}
        paddingRight={4}
        style={requestAccessDisabled ? {opacity: 0.5} : undefined}
      >
        <Stack
          space={4}
          as="form"
          disabled={canManage && !requestAccessDisabled}
          onSubmit={handleSubmit(addDomain)}
        >
          <Inline>
            <Heading size={1} as="h3">
              Allow list
            </Heading>
            {!!formErrors?.domain && <ErrorTooltip message={formErrors.domain.message!} />}
          </Inline>
          <Text size={1} as="p">
            Requests to join the organization will be restricted to domains on this list
          </Text>
          <Flex gap={2}>
            <Card flex={1}>
              <TextInput
                placeholder="domain.com"
                {...register('domain', {required: true, validate: domainValidator, minLength: 2})}
              />
            </Card>
            <Card>
              <Button
                disabled={!canManage || requestAccessDisabled || !!formErrors?.domain?.message}
                onClick={handleSubmit(addDomain)}
                text="Add domain"
                icon={AddIcon}
                mode="ghost"
              />
            </Card>
          </Flex>
          <Card marginBottom={3}>
            {(!orgData?.domains || orgData?.domains.length < 1) && (
              <Card paddingTop={3} paddingBottom={3}>
                <Text size={2} align="center">
                  No access restrictions in place.
                </Text>
              </Card>
            )}
            {orgData?.domains?.map((domain, idx) => (
              <Card borderTop={idx > 0} sizing="border" key={idx} paddingBottom={3} paddingTop={3}>
                <Flex>
                  <Card flex={1} marginTop={3}>
                    <Text size={2}>{domain}</Text>
                  </Card>
                  <Button
                    disabled={requestAccessDisabled}
                    icon={TrashIcon}
                    mode="bleed"
                    onClick={() => removeDomain(domain)}
                  />
                </Flex>
              </Card>
            ))}
          </Card>
        </Stack>
      </Card>
      <Card paddingTop={4}>
        <CheckboxInput
          label="Disable all access requests"
          checked={requestAccessDisabled}
          onChange={toggleAccessRequest}
        />
      </Card>
    </>
  )
}
