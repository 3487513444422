import React from 'react'
import {Grid, Stack} from '@sanity/ui'
import {Loader} from '@/ui/index'
import {Subscription} from '@/types/index'
import {AddOnCard} from '@/components/project/plan/addonCard'
import {getAddOnsCopy} from '@/components/project/plan/addonCopyConfig'
import {useRequiredOrganizationPermissions} from '@/context/index'

type Props = {
  loading?: boolean
  subscription: Subscription
  upsell?: boolean
  onUpdate?: () => void
  onUpsell?: () => void
}

export function PlanAddOns({loading, subscription, upsell = false, onUpdate, onUpsell}: Props) {
  const addonOptions = getAddOnsCopy()

  const canManageBilling = useRequiredOrganizationPermissions([
    {permissionName: 'sanity.organization', grantName: 'billing'},
  ])

  if (loading) {
    return <Loader text="Loading add-ons..." cardProps={{border: true, radius: 2}} height={346} />
  }

  return (
    <Stack space={5}>
      <Grid columns={addonOptions.length > 3 ? [1, 1, 2, 2, 2] : [1, 1, 2, 2, 3]} gap={4}>
        {addonOptions?.map((addon) => (
          <AddOnCard
            key={addon.featureId}
            addon={addon}
            subscription={subscription}
            upsell={upsell}
            hasPermission={canManageBilling}
            onUpdate={onUpdate}
            onUpsell={onUpsell}
          />
        ))}
      </Grid>
    </Stack>
  )
}
