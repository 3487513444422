import React from 'react'
import {TableRow, TableCell, EmptyBlock} from '@/ui/index'
import {Flex, Text} from '@sanity/ui'

type TableEmptyProps = {
  title?: string | React.ReactNode
  description?: string | React.ReactNode
  children?: React.ReactNode
  illustration?: any
  border?: boolean
}

export const TableEmpty = ({
  title,
  description,
  illustration,
  children,
  border,
}: TableEmptyProps) => {
  return (
    <TableRow>
      <TableCell style={{gridColumn: '1/-1'}} $align="stretch">
        <EmptyBlock
          title={title}
          description={description}
          illustration={illustration}
          border={border}
        >
          {children}
        </EmptyBlock>
      </TableCell>
    </TableRow>
  )
}

type EmptyRowProps = {
  text: string
}

export const EmptyRow = ({text}: EmptyRowProps) => {
  return (
    <TableRow borderBottom>
      <TableCell style={{gridColumn: '1/-1'}} $align="stretch">
        <Flex flex={1} justify={'center'} paddingBottom={4}>
          <Text muted align={'center'} size={1}>
            {text}
          </Text>
        </Flex>
      </TableCell>
    </TableRow>
  )
}
TableEmpty.displayName = 'TableEmpty'
