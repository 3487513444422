import {OverageAlert} from './overageAlert'
import {BillingPermissionsAlert} from './billingPermissionsAlert'
import {useCanManageBilling} from '@/context/useOrganizationPermissions'
import {useFormContext} from 'react-hook-form'
import {CheckoutFormData} from '../types'
import {useSwitchPlanOverages} from '../hooks/useSwitchPlanOverages'
import {useCurrentScopeContext} from '@/context/useCurrentScopeContext'
import {useSwitchPlanStore} from '../store'
import {useEffect} from 'react'

interface Props {
  setHasAlerts: (x: boolean) => void
}

export function CheckoutAlerts({setHasAlerts}: Props) {
  const scope = useCurrentScopeContext()
  const {state} = useSwitchPlanStore()
  const {watch} = useFormContext<CheckoutFormData>()
  const targetOrgId = watch('org.id') || scope?.org?.id
  const canManageBilling = useCanManageBilling(targetOrgId || undefined)
  const {overages} = useSwitchPlanOverages(scope!.project!, state!.plan!)
  const billingPermissionAlert = targetOrgId !== 'personal' && !canManageBilling

  useEffect(() => {
    if (billingPermissionAlert || overages.length) {
      setHasAlerts(true)
    } else {
      setHasAlerts(false)
    }
  }, [billingPermissionAlert, overages.length])

  if (billingPermissionAlert) {
    return <BillingPermissionsAlert />
  } else if (overages.length) {
    return <OverageAlert blockingOverages={overages} />
  } else {
    return null
  }
}
