import type {PortableTextBlock} from '@portabletext/react'
import {randomKey} from '@/utils/general'

/**
 * Organizes Portable Text content into sections based on headings.
 *
 * This function splits the content at h2 headings, creating list items of a type
 * called 'section'. These sections are rendered using a custom component found at
 * src/ui/components/get-started/portableText/listItemSection.tsx.
 */
export function organizePortableTextIntoSections(content: PortableTextBlock[]) {
  const newList: PortableTextBlock[] = []
  let buffer: PortableTextBlock[] = []
  let currentUl: PortableTextBlock | null = null
  const flushBuffer = () => {
    if (buffer.length > 0) {
      newList.push({_type: 'block', level: 0, listItem: 'section', children: [...buffer]})
      buffer = []
    }
  }

  for (const block of content) {
    if (block._type === 'block' && block.style === 'h2') {
      flushBuffer()
      currentUl = null
    }

    // If we encounter a list item, we want to group them together
    if (block._type === 'block' && block.listItem === 'bullet' && block.level) {
      if (currentUl === null) {
        currentUl = {
          _type: 'block',
          _key: randomKey(8),
          children: [],
          style: 'list',
        }
        buffer.push(currentUl)
      }
      currentUl.children.push(block)
    } else {
      currentUl = null
      buffer.push(block)
    }
  }

  flushBuffer()
  return newList
}
