import {Box, Text} from '@sanity/ui'

interface Props {
  as: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  children: React.ReactNode
}

export function BlockHeading(props: Props) {
  const {children, as} = props

  return (
    <Box marginTop={1} marginBottom={4}>
      <Text as={as} size={2} weight="semibold">
        {children}
      </Text>
    </Box>
  )
}
