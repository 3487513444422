import React, {useState, useCallback} from 'react'
import {Box, Stack, Text, Flex, Card} from '@sanity/ui'
import {PaymentDetailsInput} from '@/components/form/edit-states'
import {useConfirmDialog, EditablePreview, DeletePaymentSourceDialog} from '@/ui/index'
import {CardSingleBrand} from '@/components/form/edit-states/stripe/cardSingleBrand'
import {useRequiredOrganizationPermissions} from '@/context/index'

import {Organization} from '@/types/index'
import {useCustomerData} from '@/data/organizations/useCustomerData'
import {useOrganizationCanPay} from '@/data/index'

export function PaymentMethod({
  title,
  description,
  org,
  mode = 'default',
  onCardAdded,
  onCardRemoved,
}: {
  title: string
  description: string
  org: Organization
  mode?: 'edit' | 'default'
  onCardAdded?: () => void
  onCardRemoved?: () => void
}) {
  const [showEdit, setShowEdit] = useState(() => mode === 'edit')
  const {data: customerData} = useCustomerData(org?.id || '')
  const {invalidate: invalidateCanPay} = useOrganizationCanPay(org?.id)
  const canManage = useRequiredOrganizationPermissions([
    {permissionName: 'sanity.organization', grantName: 'billing'},
  ])
  const {
    showDialog: showDeleteDialog,
    Dialog: DeleteDialog,
    hideDialog: hideDeleteDialog,
  } = useConfirmDialog()

  function handleSave() {
    setShowEdit(false)
    if (onCardAdded) onCardAdded()
    invalidateCanPay()
  }

  function handleDelete() {
    showDeleteDialog()
    if (onCardRemoved) onCardRemoved()
    invalidateCanPay()
  }

  const {paymentSource} = customerData ?? {}

  const handleEdit = useCallback(() => setShowEdit(true), [])
  const handleCancel = useCallback(() => setShowEdit(false), [])

  return (
    <Box data-recording-mask>
      <Stack space={5}>
        <EditablePreview
          hasData={paymentSource !== null && typeof paymentSource?.card !== 'undefined'}
          onEdit={handleEdit}
          onDelete={handleDelete}
          title={title}
          description={description}
          isEditing={showEdit}
          canManage={canManage}
          addButtonText="Add payment method"
          noPermissionText="Your member role does not have access to payment settings"
        >
          <>
            {!showEdit && (
              <Stack space={4}>
                <Flex align="center">
                  <Box>
                    <CardSingleBrand
                      brandType={customerData?.paymentSource?.card?.brand || 'unknown'}
                    />
                  </Box>
                  <Box marginLeft={4} flex={1}>
                    <Text size={2}>
                      {[`**** **** ****`, customerData?.paymentSource?.card?.last4 || '****']
                        .join(' ')
                        .replaceAll('*', '•')}{' '}
                      {}
                    </Text>
                  </Box>
                </Flex>
              </Stack>
            )}
            {showEdit && (
              <Card>
                {org && (
                  <PaymentDetailsInput
                    organization={org}
                    onSave={handleSave}
                    onCancel={handleCancel}
                  />
                )}
              </Card>
            )}
          </>
        </EditablePreview>
      </Stack>
      <DeletePaymentSourceDialog
        key="delete-paymentsource"
        customer={customerData}
        Dialog={DeleteDialog}
        hideDialog={hideDeleteDialog}
      />
    </Box>
  )
}
