import React from 'react'
import {Grid, Box, Inline, Text, AvatarStack, Badge, Flex} from '@sanity/ui'
import {MemberAvatar, TabPageHeader} from '@/ui/index'
import {Organization} from '@/types/models'
import {AppLink} from '@/ui/app'
import {useQuery} from '@tanstack/react-query'
import {useRequiredOrganizationPermissions} from '@/context/useOrganizationPermissions'
import {getUsers} from '@sanity/access-api'

const MEMBER_AVATAR_LIMIT = 4

export function OrgHeader({org}: {org?: Organization}) {
  const {projects, id} = org || {}
  const hasMembersPermission = useRequiredOrganizationPermissions([
    {permissionName: 'sanity.organization.members', grantName: 'read'},
  ])
  const {data: membersData, isLoading} = useQuery({
    queryKey: ['orgMembers', {orgId: id, limit: MEMBER_AVATAR_LIMIT}],
    queryFn: () =>
      getUsers({
        path: {
          resourceType: 'organization',
          resourceId: id!,
        },
        query: {
          limit: MEMBER_AVATAR_LIMIT,
        },
      }),
    // only enabled if the org is available
    enabled: !!id && id !== 'personal' && hasMembersPermission,
    select: (data) => ('data' in data ? data.data : {data, totalCount: 50, nextCursor: null}),
  })

  const hasOrgPlan = !!org?.organizationPlan

  if (!org) {
    return null
  }

  const projectCount = `${projects?.length} ${projects?.length === 1 ? 'project' : 'projects'}`
  const members = membersData?.data ?? new Array(MEMBER_AVATAR_LIMIT).fill({sanityUserId: ''})
  const memberCount = membersData
    ? `${membersData.totalCount} ${membersData.totalCount === 1 ? 'member' : 'members'}`
    : ''

  return (
    <Grid columns={1} marginBottom={[2, 2, 2, 0]}>
      <TabPageHeader title={org.name}>
        <Flex align="center" wrap="wrap">
          {id === 'personal' && (
            <Box marginRight={[3, 3, 5]}>
              <Badge tone="primary" mode="outline">
                Personal account
              </Badge>
            </Box>
          )}
          <Box marginRight={[3, 3, 5]}>
            <AppLink
              href={
                id === 'personal' ? `/manage/personal/projects` : `/organizations/${id}/projects`
              }
            >
              <Text size={1}>{projectCount}</Text>
            </AppLink>
          </Box>

          {org.id !== 'personal' && members.length > 0 && (
            <Box marginRight={[3, 3, 5]}>
              <Inline>
                <AvatarStack>
                  {members.map((m) => {
                    return (
                      <MemberAvatar
                        isLoading={isLoading}
                        key={m.sanityUserId}
                        userId={m.sanityUserId}
                      />
                    )
                  })}
                </AvatarStack>
                <Box marginLeft={2}>
                  <Text size={1}>{memberCount}</Text>
                </Box>
              </Inline>
            </Box>
          )}
          {hasOrgPlan && (
            <Box>
              <Badge tone="primary">Enterprise</Badge>
            </Box>
          )}
        </Flex>
      </TabPageHeader>
    </Grid>
  )
}
