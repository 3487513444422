import {type ResourceType, type Role} from '@sanity/access-api'
import {hues} from '@sanity/color'
import {FilterIcon} from '@sanity/icons'
import {Card, Grid, Stack} from '@sanity/ui'
import {motion} from 'framer-motion'
import {type Dispatch, type SetStateAction, useMemo, useRef, useState} from 'react'
import styled, {css} from 'styled-components'

import {type MembersV2} from '../../../../../../types/members_v2'
import {PopoverButton, type PopoverButtonHandle} from '../../../../generic/popover-button'
import {type BaseButtonProps, Button} from '../../../../primitives/button/Button'
import {OrganizationFilters} from './OrganizationFilters'
import {ProjectFilters} from './ProjectFilters'

const StyledButton = styled(Button)(({theme}) => {
  const isDark = theme.sanity.v2?.color._dark
  const border = theme.sanity.v2?.color.bg

  const bg = hues.blue[isDark ? 600 : 300].hex

  return css`
    position: relative;

    // A dot in the top right corner
    &::after {
      content: '';
      position: absolute;
      top: -2px;
      right: -2px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      transform: scale(0);
      background-color: ${bg};
      box-shadow: 0 0 0 2px ${border};
      opacity: 0;
      transition:
        opacity 0.15s ease,
        transform 0.15s ease;
    }

    &[data-has-filters='true']::after {
      opacity: 1;
      transform: scale(1);
    }
  `
})

const MotionStack = motion(Stack)

const ContentRoot = styled(MotionStack)`
  min-width: 350px;
`

export interface FiltersProps extends Pick<BaseButtonProps, 'disabled'> {
  projectsData: MembersV2['projectData'][]
  resourceRoles: Role[]
  resourceType: ResourceType
  value: AppliedFilters | null
  setValue: (value: AppliedFilters | null) => void
}

/**
 * The interface a filter interface should extend from for it's props
 * to ensure it's compatible with the `Filters` component.
 */
export interface BaseFilterProps
  extends Pick<FiltersProps, 'value' | 'resourceRoles' | 'projectsData'> {
  onValueChange: Dispatch<SetStateAction<AppliedFilters | null>>
  onSubmit: () => void
}

export type Filter = 'all' | 'organization-roles' | 'project-access'

export interface AppliedFilters {
  filter: Filter
  project?: MembersV2['project']
  projectRoles?: Role[]
  roles?: Role[]
}

export const Filters = ({
  disabled = false,
  resourceType,
  resourceRoles,
  projectsData,
  value,
  setValue,
}: FiltersProps) => {
  const popoverButtonRef = useRef<PopoverButtonHandle | null>(null)
  const [filtersToApply, setFiltersToApply] = useState<AppliedFilters | null>(null)

  const handleSubmit = () => {
    setValue(filtersToApply)

    popoverButtonRef.current?.onClose()
    popoverButtonRef.current?.focusButton()
  }

  const clearFilters = () => {
    setValue(null)
    setFiltersToApply(null)

    popoverButtonRef.current?.onClose()
    popoverButtonRef.current?.focusButton()
  }

  const resetFilters = () => {
    setFiltersToApply(null)
  }

  const canApplyFilters = useMemo((): boolean => {
    if (!filtersToApply) return false

    if (resourceType === 'organization') {
      // Ensure the user has selected a project when filtering by project
      if (filtersToApply.filter === 'project-access') {
        return !!filtersToApply.project
      }

      // Ensure the user has selected roles when filtering by roles
      if (filtersToApply.filter === 'organization-roles') {
        return Boolean(filtersToApply.roles && filtersToApply.roles.length > 0)
      }
    }

    if (resourceType === 'project') {
      // Ensure the user has selected roles when filtering by roles
      if (filtersToApply.filter === 'project-access') {
        return Boolean(filtersToApply.projectRoles && filtersToApply.projectRoles.length > 0)
      }
    }

    return filtersToApply.filter === 'all'
  }, [filtersToApply, resourceType])

  return (
    <PopoverButton
      autoFocus
      button={
        <StyledButton
          aria-label="Open filters menu"
          data-has-filters={value && value.filter !== 'all'}
          disabled={disabled}
          fontSize={1}
          icon={FilterIcon}
          mode="ghost"
          tone={!value || value?.filter === 'all' ? undefined : 'primary'}
          tooltipProps={{content: 'Open filters menu'}}
        />
      }
      content={
        <ContentRoot space={0} sizing="border">
          <MotionStack space={4} padding={3} paddingY={4}>
            {resourceType === 'organization' ? (
              <OrganizationFilters
                projectsData={projectsData}
                resourceRoles={resourceRoles}
                onValueChange={setFiltersToApply}
                onSubmit={handleSubmit}
                value={filtersToApply || value}
              />
            ) : (
              <ProjectFilters
                projectsData={projectsData}
                resourceRoles={resourceRoles}
                onValueChange={setFiltersToApply}
                onSubmit={handleSubmit}
                value={filtersToApply || value}
              />
            )}
          </MotionStack>

          <Card padding={2} paddingTop={0}>
            <Grid columns={2} gap={2}>
              <Button text="Clear filters" fontSize={1} mode="bleed" onClick={clearFilters} />

              <Button
                disabled={!canApplyFilters}
                fontSize={1}
                onClick={handleSubmit}
                text="Apply filters"
              />
            </Grid>
          </Card>
        </ContentRoot>
      }
      id="organization-members-table-filter-button"
      onClose={resetFilters}
      portal
      focusLock
      ref={popoverButtonRef}
      maxWidth={350}
      minWidth={350}
    />
  )
}
