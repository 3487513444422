import {ButtonTone, Flex} from '@sanity/ui'
import React, {useCallback, useMemo, useState} from 'react'
import {Project} from '@/types/index'
import {PermissionButtonProject, TabSectionHeader, useConfirmDialog} from '@/ui/index'
import {useProjectData} from '@/data/projects/useProjectData'

export function ArchiveProject({
  title,
  description,
  project,
}: {
  title: string
  description: string
  project: Project
}) {
  const {showDialog, Dialog, hideDialog} = useConfirmDialog()
  const {isLoading, patchProjectData} = useProjectData(project)
  const [isDisabled, setIsDisabled] = useState(project.isDisabledByUser)
  const dialogTexts = useMemo(
    () => ({
      disabled: {
        header: 'Activate Project',
        title: `Do you really want to activate ${project.displayName}?`,
        description: 'Activating a project makes it accessible again.',
        button: 'Activate',
      },
      enabled: {
        header: 'Archive project',
        title: `Do you really want to archive ${project.displayName}?`,
        description:
          'Archiving a project removes any access to it but does not delete it permanently.',
        button: 'Archive',
      },
    }),
    [project.displayName]
  )

  // CTA text
  const ctaTexts = useMemo(
    () => ({
      disabled: {
        ctaText: 'Activate project',
        tone: 'caution',
        description: 'Activating a project makes it accessible again.',
        title: 'Activate project',
      },
      enabled: {
        ctaText: 'Archive project',
        tone: 'critical',
        description,
        title,
      },
    }),
    [description, title]
  )

  const currentTexts = useMemo(
    () => (isDisabled ? dialogTexts.disabled : dialogTexts.enabled),
    [isDisabled, dialogTexts]
  )

  const currentCtaTexts = useMemo(
    () => (isDisabled ? ctaTexts.disabled : ctaTexts.enabled),
    [isDisabled, ctaTexts]
  )

  const handleToggleArchive = useCallback(() => {
    patchProjectData(
      {isDisabledByUser: !isDisabled},
      {
        onSuccess: () => {
          setIsDisabled(!isDisabled)
          hideDialog({
            title: isDisabled ? 'Project activated' : 'Project archived',
            status: 'success',
          })
        },
        onError: (_error) => {
          hideDialog({
            description: isDisabled ? 'Could not activate project' : 'Could not archive project',
            status: 'error',
          })
        },
      }
    )
  }, [patchProjectData, isDisabled, hideDialog])

  return (
    <>
      <TabSectionHeader title={currentCtaTexts.title} description={currentCtaTexts.description} />
      <Flex>
        <PermissionButtonProject
          title={currentCtaTexts.ctaText}
          tone={currentCtaTexts.tone as ButtonTone}
          mode="ghost"
          onClick={showDialog}
          permissions={[{permissionName: 'sanity.project', grantName: 'update'}]}
        />
      </Flex>
      <Dialog
        header={currentTexts.header}
        title={currentTexts.title}
        description={currentTexts.description}
        id="archive-project-dialog"
        onClose={hideDialog}
        onConfirm={handleToggleArchive}
        confirmButtonProps={{text: currentTexts.button}}
        loading={isLoading}
      />
    </>
  )
}
