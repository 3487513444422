import {Stack, Heading, Text, Inline, Button, Flex, Box, Grid, Label, Card, Badge} from '@sanity/ui'
import React, {useCallback, useMemo, useState} from 'react'
import {ChevronDownIcon, ChevronUpIcon} from '@sanity/icons'
import {OrgRoleSelect} from '../../members'
import {MobileRowMenu, TableCell, TableRow, TableRowMenuButton, MemberAvatar} from '@/ui/index'
import {TableRowAction, OrganizationMember} from '@/types/index'
import {useRequiredOrganizationPermissions} from '@/context/index'
import {useMatchesMedia} from '@/utils/index'

type Props = {
  orgId: string
  member: OrganizationMember
  rowActions: TableRowAction[]
}

export const OrgMember = ({orgId, member, rowActions}: Props) => {
  const [showDetails, setShowDetails] = useState<boolean>(false)
  const hasRoleChangePermission = useRequiredOrganizationPermissions([
    {permissionName: 'sanity.organization.members', grantName: 'update'},
  ])
  const hasMemberDeletePermission = useRequiredOrganizationPermissions([
    {permissionName: 'sanity.organization.members', grantName: 'delete'},
  ])
  const isMobile = useMatchesMedia(1)

  const handleToggleDetails = useCallback(() => {
    if (!isMobile) {
      return
    }
    setShowDetails((prevShowDetails) => !prevShowDetails)
  }, [isMobile])

  const roleSelect = useMemo(
    () => (
      <OrgRoleSelect
        orgId={orgId}
        hasPermission={hasRoleChangePermission}
        user={{id: member.sanityUserId, isCurrentUser: member.isCurrentUser}}
        currentRoles={member.roles}
      />
    ),
    [hasRoleChangePermission, member, orgId]
  )

  if (member) {
    return (
      <TableRow paddingY={3} paddingX={2} borderBottom onClick={handleToggleDetails}>
        <TableCell flexProps={isMobile ? {align: 'flex-start'} : {}}>
          <MemberAvatar showProvider userId={member.sanityUserId} size={1} />
        </TableCell>
        <TableCell>
          <Stack space={2}>
            <Inline space={2}>
              <Heading size={1}>{member.user?.displayName}</Heading>
              {member.isCurrentUser && <Badge mode="outline">You</Badge>}
            </Inline>
            {member && <Text size={1}>{member.user?.email}</Text>}
          </Stack>
        </TableCell>
        <TableCell>{roleSelect}</TableCell>
        <TableCell $align="right">
          {isMobile
            ? hasMemberDeletePermission && (
                <Button
                  label="Show more"
                  icon={showDetails ? ChevronUpIcon : ChevronDownIcon}
                  onClick={handleToggleDetails}
                  mode="bleed"
                />
              )
            : hasMemberDeletePermission && (
                <TableRowMenuButton
                  menuId={member.sanityUserId}
                  label={`Edit ${member.sanityUserId}`}
                  rowItem={member}
                  actions={rowActions}
                  roleScope="org"
                />
              )}
        </TableCell>
        {isMobile && showDetails && (
          <TableCell
            style={{gridColumn: '1/-1', width: '100%'}}
            flexProps={{direction: 'column', align: 'flex-start'}}
          >
            <Card flex={1} paddingTop={3} style={{width: '100%'}}>
              <Stack space={4}>
                <Grid columns={2} gap={4} paddingX={3}>
                  <Stack space={3}>
                    <Label muted size={0}>
                      ROLE
                    </Label>
                    {roleSelect}
                  </Stack>
                </Grid>
                <Flex style={{width: '100%'}}>
                  <Box flex={1}>
                    <MobileRowMenu actions={rowActions} roleScope="org" rowItem={member} />
                  </Box>
                </Flex>
              </Stack>
            </Card>
          </TableCell>
        )}
      </TableRow>
    )
  }
  return null
}
