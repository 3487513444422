/* eslint-disable max-nested-callbacks */
import React, {useCallback, useEffect, useMemo, useRef} from 'react'
import {
  Box,
  Button,
  Card,
  Flex,
  Code,
  Heading,
  Label,
  Stack,
  Text,
  Inline,
  Menu,
  MenuButton,
  MenuItem,
  Tooltip,
  useToast,
  HeadingSkeleton,
} from '@sanity/ui'
import {EditIcon, TrashIcon, EllipsisVerticalIcon, CopyIcon} from '@sanity/icons'
import {TagDatasetSelect} from './tagDatasetSelect'
import {TagIcon} from '@/ui/index'
import {Tag, Project, RoleResource} from '@/types/index'
import {useCopyToClipboard} from '@/utils/index'
import {useTagRoles} from '@/data/index'
import {ContentResource} from '@/ui/views/project/roles/contentResource'
import {useProjectPermissionResources} from '@/data/projects/useProjectRoles'
import {useDatasets} from '@/data/datasets'
import {useWindowVirtualizer} from '@tanstack/react-virtual'
import styled from 'styled-components'

const ListWrapper = styled.div.attrs<{$height: string}>(({$height}) => ({
  style: {
    height: $height,
  },
}))`
  box-sizing: border-box;
  position: relative;
  width: 100%;
`

const VirtualListContainer = styled(Stack).attrs<{$translateY: number}>(({$translateY}) => ({
  space: 4,
  style: {
    transform: `translateY(${$translateY ?? 0}px)`,
  },
}))`
  box-sizing: border-box;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`

const ResourceItemWrapper = styled.div`
  width: 100%;
`

const LoadingSkeleton = styled(HeadingSkeleton).attrs({animated: true})`
  height: 1em;
  background-color: var(--card-skeleton-color);
  border-radius: 2px;
`

function ContentPermissionsSkeleton() {
  return (
    <Stack space={3}>
      {[...Array(10)].map((_item, i) => (
        <Card
          // eslint-disable-next-line react/no-array-index-key
          key={`skeleton-${i}`}
          border
          radius={2}
          paddingX={4}
          paddingY={4}
          style={{minHeight: '125px'}}
        >
          <Flex justify="space-between" align="center">
            <Stack space={1}>
              <Flex gap={2}>
                <LoadingSkeleton style={{width: '150px'}} />
                <LoadingSkeleton style={{width: '100px'}} />
              </Flex>
            </Stack>
            <Box>
              <LoadingSkeleton style={{width: '75px'}} />
            </Box>
          </Flex>
        </Card>
      ))}
    </Stack>
  )
}

interface Props {
  project: Project
  tag: Tag
  openEditTagDialog: () => void
  openDeleteTagDialog: () => void
}
export function TagDetails({project, tag, openEditTagDialog, openDeleteTagDialog}: Props) {
  const toast = useToast()
  const {copyToClipboard, success, error: errorCopy} = useCopyToClipboard()
  const copyName = useCallback(() => {
    copyToClipboard(tag.name)
  }, [copyToClipboard, tag.name])

  const {
    data: roles = [],
    isLoading: loadingTagRoles,
    error: errorTagRoles,
  } = useTagRoles(project, tag)

  const {
    data: datasets = [],
    isLoading: loadingDatasets,
    error: errorDatasets,
  } = useDatasets(project.id)

  const {
    data: permissionResources,
    isLoading: loadingPR,
    error: errorPR,
  } = useProjectPermissionResources(project.id)

  const loading = useMemo(
    () => loadingPR || loadingTagRoles || loadingDatasets,
    [loadingPR, loadingTagRoles, loadingDatasets]
  )
  const error = useMemo(
    () => errorPR || errorTagRoles || errorDatasets,
    [errorPR, errorTagRoles, errorDatasets]
  )

  const resourcesMap = useMemo(() => {
    return roles.reduce<Record<string, RoleResource[]>>((acc, role) => {
      acc[role.name] = Object.keys(role.grants).reduce<RoleResource[]>((resAcc, key) => {
        resAcc.push(...role.grants[key].map((pr) => ({...pr, type: key})))
        return resAcc
      }, [])
      return acc
    }, {})
  }, [roles])

  const parentRef = useRef<HTMLDivElement | null>(null)
  const filteredRoles = useMemo(() => {
    return roles.filter((role) => role.isCustom)
  }, [roles])
  const virtualizer = useWindowVirtualizer({
    count: filteredRoles.length,
    scrollMargin: parentRef.current?.scrollTop ?? 0,
    estimateSize: useCallback(() => 125, []),
    overscan: 10,
    gap: 22,
  })

  useEffect(() => {
    if (error) {
      toast.push({title: error.name, description: error.message, status: 'error'})
    }
  }, [toast, error])

  useEffect(() => {
    if (success) {
      toast.push({
        title: 'Copied to clipboard',
        status: 'success',
      })
      return
    }

    if (errorCopy) {
      toast.push({
        title: 'Failed to copy to clipboard',
        status: 'error',
      })
    }
  }, [success, errorCopy, toast])

  return (
    <Box>
      <Flex marginTop={2}>
        <Stack flex={1} space={4}>
          <Inline space={2}>
            <Heading size={1}>
              <Card tone={tag.metadata.tone || 'default'} paddingY={1} paddingX={2} radius={2}>
                <TagIcon />
              </Card>
            </Heading>
            <Heading size={3} as="h2" style={{fontWeight: 600}}>
              {tag.title}
            </Heading>
          </Inline>

          <Text muted>{tag.description || <em>No description</em>}</Text>
        </Stack>

        <Inline space={2}>
          <Box marginLeft={6}>
            <Button icon={EditIcon} mode="ghost" onClick={openEditTagDialog} text="Edit tag" />
          </Box>
          <Box>
            <MenuButton
              button={<Button fontSize={1} icon={EllipsisVerticalIcon} mode="ghost" />}
              id="menu-button-role"
              menu={
                <Menu>
                  <Tooltip
                    disabled={(tag.datasets?.length || 0) === 0}
                    portal
                    content={
                      <Box padding={3}>
                        <Text size={1}>Unable to delete tag when datasets are tagged</Text>
                      </Box>
                    }
                  >
                    <Box>
                      <MenuItem
                        icon={TrashIcon}
                        text="Delete tag"
                        tone="critical"
                        disabled={(tag.datasets?.length || 0) > 0}
                        onClick={openDeleteTagDialog}
                      />
                    </Box>
                  </Tooltip>
                </Menu>
              }
            />
          </Box>
        </Inline>
      </Flex>

      <Card borderTop borderBottom paddingY={3} marginTop={5}>
        <Flex>
          <Stack space={3}>
            <Label muted size={1}>
              Identifier
            </Label>
            <Inline space={1}>
              <Code size={1}>{tag.name}</Code>
              <Button icon={CopyIcon} mode="bleed" fontSize={0} padding={2} onClick={copyName} />
            </Inline>
          </Stack>
          <Stack space={3} flex={1} marginLeft={6}>
            <Label muted size={1}>
              Datasets
            </Label>
            <TagDatasetSelect project={project} tag={tag} datasets={datasets} />
          </Stack>
        </Flex>
      </Card>

      <Stack marginTop={6} space={4}>
        <Heading>Content permissions</Heading>
        <Text as="p" muted size={1}>
          Define permissions to content resources for this tag for the various custom roles.
        </Text>

        {loading && <ContentPermissionsSkeleton />}

        {!loading && errorTagRoles && (
          <Card border tone="critical" padding={4}>
            <Heading size={2}>Failed to load content permissions</Heading>
            <Text as="p" size={1}>
              {errorTagRoles.message}
            </Text>
          </Card>
        )}

        {!loading && (
          <Stack space={0} ref={parentRef}>
            <ListWrapper $height={`${virtualizer.getTotalSize()}px`}>
              <VirtualListContainer $translateY={virtualizer.getVirtualItems()[0]?.start ?? 0}>
                {virtualizer.getVirtualItems().map((virtualRow) => {
                  const role = filteredRoles[virtualRow.index]
                  return (
                    <ResourceItemWrapper key={role.name} data-index={virtualRow.index}>
                      <ContentResource
                        key={`resource-${tag.name}-${role.name}`}
                        tag={tag}
                        dataset={undefined}
                        resources={resourcesMap[role.name]}
                        permissionResources={permissionResources}
                        projectId={project.id}
                        role={role}
                        readOnly={!role.isCustom}
                        context="role"
                      />
                    </ResourceItemWrapper>
                  )
                })}
              </VirtualListContainer>
            </ListWrapper>
          </Stack>
        )}
      </Stack>
    </Box>
  )
}
