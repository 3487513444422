import {GetStarted} from '@/components/get-started/GettingStarted'
import {useIsProjectInOnboardingXp} from '@/components/get-started/hooks/useIsProjectInOnboardingXp'
import {AppLoadingScreen} from '@/ui/app'
import {usePosthog} from '@/utils/tracking/posthog'
import {useRouter} from 'next/router'
import {useEffect} from 'react'
import posthog from 'posthog-js'

export function GettingStarted() {
  const [getStartedFlag, getStartedFlagLoading] = useIsProjectInOnboardingXp()
  const router = useRouter()
  const {init: initPosthog} = usePosthog()

  useEffect(() => {
    initPosthog()
    return () => {
      posthog.stopSessionRecording()
    }
  }, [])

  if (getStartedFlagLoading) {
    return <AppLoadingScreen />
  }

  if (!getStartedFlag) {
    router.replace('/learn/course/day-one-with-sanity-studio/creating-a-schema')
    return <AppLoadingScreen />
  }

  return <GetStarted />
}
