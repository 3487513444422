import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Box, Stack, Text, ToastParams} from '@sanity/ui'
import {endOfDay, startOfDay} from 'date-fns'
import {DateInput} from '@/ui/index'
import {useCurrentScopeContext, useFilter, useScopeFilter} from '@/context/index'
import {API_VERSION} from '@/data/index'
import {generateFilter} from '@/utils/index'

type Props = {
  Dialog: React.ElementType
  hideDialog: (toast: ToastParams) => void
}

export function ExportActivityDialog({Dialog, hideDialog}: Props) {
  const scopeContext = useCurrentScopeContext()
  const filter = useFilter()
  const scopeFilter = useScopeFilter()
  const [startTime, setStartTime] = useState(startOfDay(new Date()))
  const [endTime, setEndTime] = useState(endOfDay(new Date()))

  //Set local state to the same as in filter state
  useEffect(() => {
    if (filter?.endTime) {
      setStartTime(startOfDay(new Date(filter.endTime)))
      setEndTime(endOfDay(new Date(filter.endTime)))
    }
  }, [filter])

  const exportQueryString = useMemo(() => {
    const query = generateFilter({
      ...scopeFilter,
      startTime: startTime.toISOString(),
      endTime: endTime.toISOString(),
    })

    return `https://api.${process.env.host}/${API_VERSION}/activity/export/csv${query}`
  }, [endTime, scopeFilter, startTime])

  // Don't allow to select any date that is before the project was created
  const minFromDate = useMemo((): Date | undefined => {
    if (scopeContext?.scope === 'project' && scopeContext?.project?.createdAt) {
      return startOfDay(new Date(scopeContext?.project?.createdAt))
    }
    return undefined
  }, [scopeContext])

  // Don't allow to select any date that is in the future
  const maxDate = endOfDay(new Date())

  const handleSetStartTime = useCallback((date: Date) => {
    setStartTime(startOfDay(date))
  }, [])

  const handleSetEndTime = useCallback((date: Date) => {
    setEndTime(endOfDay(date))
  }, [])

  const onConfirm = useCallback(() => {
    hideDialog({
      title: 'Your export will start downloading shortly',
      status: 'success',
    })
  }, [hideDialog])

  return (
    <Dialog
      header="Export"
      description="Export all events in the specified time frame in CSV format."
      onConfirm={onConfirm}
      confirmButtonProps={{
        text: 'Export',
        tone: 'positive',
        as: 'a',
        download: true,
        href: exportQueryString,
      }}
    >
      <Box marginTop={2}>
        <Stack space={5}>
          <Stack space={2}>
            <Text size={1} weight="semibold">
              From
            </Text>
            <DateInput
              onChange={handleSetStartTime}
              minDate={minFromDate}
              maxDate={endTime}
              initialDate={startTime}
              dateSuffix="00:00"
            />
          </Stack>
          <Stack space={2}>
            <Text size={1} weight="semibold">
              To
            </Text>
            <DateInput
              onChange={handleSetEndTime}
              initialDate={endTime}
              minDate={startTime}
              maxDate={maxDate}
              dateSuffix="23:59"
            />
          </Stack>
        </Stack>
      </Box>
    </Dialog>
  )
}
