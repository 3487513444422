import React from 'react'
import {Box, Stack} from '@sanity/ui'
import {AddIcon} from '@sanity/icons'
import {uniqBy} from 'lodash'
import {LARGE_LAYOUT as headers} from './tableConfig'
import {OrgInvoice, PermissionButtonOrg, TabHeader, Table, TableBody, TableEmpty} from '@/ui/index'
import {useOrganizationInvoices} from '@/data/index'
import {CustomerInvoice, Heading, Organization} from '@/types/index'
import {useRoutePath} from '@/context/index'

export function OrgInvoices({
  org,
  title,
  description,
  referenceLink,
}: {org: Organization} & Heading) {
  const {basePath} = useRoutePath()
  const {data: invoices = [], isLoading} = useOrganizationInvoices(org.id)
  const uniqueInvoices = uniqBy(invoices || [], (invoice) => invoice.id)
  const noInvoiceMessage = org.organizationPlan
    ? 'Contact sales about your invoices'
    : 'There are no invoices'
  return (
    <Stack space={[4, 4, 6]}>
      <TabHeader
        title={title}
        description={description}
        referenceLink={referenceLink}
        button={
          <PermissionButtonOrg
            href={`${basePath}/settings#billing`}
            title="Change billing information"
            icon={AddIcon}
            permissions={[{permissionName: 'sanity.organization', grantName: 'billing'}]}
          />
        }
      />
      <Box>
        <Table
          headers={uniqueInvoices.length > 0 ? headers : []}
          loading={isLoading}
          loadingText="Loading invoices..."
        >
          <TableBody>
            {uniqueInvoices.length > 0 ? (
              uniqueInvoices
                .filter((invoice) => invoice.status == 'paid' || invoice.status == 'open')
                .map((invoice: CustomerInvoice, index: number) => (
                  <OrgInvoice key={`invoice-${invoice.id}-${index}`} invoice={invoice} />
                ))
            ) : (
              <TableEmpty title={noInvoiceMessage} />
            )}
          </TableBody>
        </Table>
      </Box>
    </Stack>
  )
}
