import React from 'react'
import styled from 'styled-components'
import {Card, Grid, Stack, Text, Badge, Flex, BadgeTone} from '@sanity/ui'
import {TimeAgoText} from '@/ui/index'

const Title = styled(Text)`
  text-transform: uppercase;
`
type Props = {
  name: string
  createdAt: string | Date
  badgeTone: BadgeTone
  roleTitle: string
}

export function TokenPreview({name, createdAt, roleTitle, badgeTone}: Props) {
  return (
    <Card border radius={2} padding={1} paddingTop={2} paddingBottom={2}>
      <Grid columns={1} gap={1}>
        <Card padding={3}>
          <Stack space={3}>
            <Title muted weight="semibold" size={0}>
              name
            </Title>
            <Text>{name}</Text>
          </Stack>
        </Card>
        <Card padding={3}>
          <Stack space={3}>
            <Title muted weight="semibold" size={0}>
              permissions
            </Title>
            <Flex>
              <Badge tone={badgeTone} mode="outline" size={1}>
                {roleTitle}
              </Badge>
            </Flex>
          </Stack>
        </Card>
        <Card padding={3}>
          <Stack space={3}>
            <Title muted weight="semibold" size={0}>
              created
            </Title>
            <TimeAgoText date={createdAt} muted />
          </Stack>
        </Card>
      </Grid>
    </Card>
  )
}
