import React from 'react'

export const Screen = ({color}: {color?: string}) => (
  <svg width="70" height="72" viewBox="0 0 70 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20 71.5L26.7308 54H35V56.5M50 71.5L43.2692 54M17 52H27.5H35H42.5H66V10H4V52H9"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M69 3V10H1V3M69 3H1M69 3L67 1H3L1 3"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path d="M9 52H3L2 54H68L67 52H17" stroke={color || 'currentColor'} strokeWidth="1.2" />
    <circle cx="35" cy="59" r="2" stroke={color || 'currentColor'} strokeWidth="1.2" />
    <path
      d="M9 50C9 48.8954 9.89543 48 11 48H15C16.1046 48 17 48.8954 17 50V54H9V50Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M9 52C9 50.8954 9.89543 50 11 50H15C16.1046 50 17 50.8954 17 52V54H9V52Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M13 31.9975C16.0617 31.62 18.5601 29.4793 20.2478 26.995C21.199 25.5947 21.9116 24.0761 21.9653 22.3597C21.9973 21.3355 21.8332 20.1459 20.8944 19.5454C20.3035 19.1675 19.4935 19.0285 18.8044 19.0022C17.7788 18.9631 17.1643 19.4357 16.8489 20.4197C15.3123 25.2145 18.0184 29.7025 21.3911 32.8977C23.1152 34.531 25.0746 35.9275 27.1179 37.1294C28.9867 38.2287 31.003 39.2595 33.1189 39.7885C33.9466 39.9954 35.4951 40.3913 36.3211 39.954C37.3579 39.4051 36.8285 36.7323 36.7091 35.9809C36.3862 33.9486 35.7293 32.0311 34.8726 30.1662C34.3791 29.0918 33.7948 27.8004 32.9999 26.8967C32.4959 26.3237 32.1331 26.4889 32.1101 27.2278C32.0699 28.5204 32.5768 29.7535 33.3103 30.7973C35.633 34.1024 39.4924 36.4656 43.2792 37.6985C44.9643 38.2471 46.8953 38.7306 48.6853 38.6193C50.3845 38.5137 50.6225 37.1321 50.7287 35.655C50.9958 31.9405 49.9629 27.7695 47.6403 24.7963C46.615 23.4839 45.3547 22.6334 43.6879 22.4476C42.7554 22.3437 41.665 22.3303 40.7391 22.5097C39.8073 22.6903 39.0162 23.297 39.3371 24.3462C39.6464 25.3572 40.8044 26.0666 41.6444 26.5759C42.983 27.3876 44.4813 27.9898 46.0262 28.2624C48.2658 28.6576 50.1745 28.1715 52.141 27.1346C52.9966 26.6835 53.8825 26.1795 54.4379 25.3861"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
  </svg>
)
Screen.displayName = 'Screen'
