import {ChartGroupSection} from '@/components/usage'
import {Tab} from '@/types/components'
import {ChartUpwardIcon} from '@sanity/icons'
import {OrgUsage, UsageOrgHeader, UsageSummaryOrg} from '../../org'
import {inviteConfig} from '../../org/inviteConfig'
import {getCommonTabs} from './getCommonTabs'
import {GetOrgTabsOptions} from '../types'

export function getOrgRegularTabs(opts: GetOrgTabsOptions): Tab[] {
  const {overview, projects, members, activity, billing, settings} = getCommonTabs(opts)

  return [
    overview,
    projects,
    members,
    activity,
    {
      title: 'Usage',
      path: 'usage',
      icon: ChartUpwardIcon,
      referenceLink: {
        label: 'Learn more about plans and payments in Sanity Docs',
        url: `https://www.${process.env.host}/docs/plans-and-payments`,
      },
      headings: [
        {
          title: 'Organization usage',
          description:
            'An overview of usage across all projects within your organization. Any usage exceeding quota is indicated in the relevant sections. All usage metrics are updated daily.',
          path: 'usage',
          isRoot: true,
          component: OrgUsage,
        },
      ],
      sections: [
        {
          title: 'Header',
          heading: 'usage',
          component: UsageOrgHeader,
          hideFromSidebar: true,
        },
        {
          title: 'Summary',
          heading: 'usage',
          path: 'summary',
          hideFromSidebar: true,
          component: UsageSummaryOrg,
        },
        {
          title: 'Bandwidth & APIs',
          heading: 'usage',
          path: 'bandwidth-api',
          component: ChartGroupSection,
        },
        {
          title: 'Resources & datasets',
          heading: 'usage',
          path: 'resources',
          component: ChartGroupSection,
        },
      ],
    },
    billing,
    settings,
    inviteConfig,
  ]
}
