import {Inline, Text, Card, Flex, Stack, Switch, useToast, HeadingSkeleton} from '@sanity/ui'
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query'
import {TabSectionHeader, ExternalLink, FormField} from '@/ui/index'
import {useConfig} from '@/context/index'
import {getTelemetryConsentStatus, setTelemetryConsentStatus} from '@/data/users'
import styled from 'styled-components'
import {useEffect} from 'react'
import {FIXME} from '@/types/app'

const LoadingSkeleton = styled(HeadingSkeleton)`
  max-width: 430px;
`

const CACHE_KEY = 'telemetryConsent'

async function fetchConsent() {
  const response = await getTelemetryConsentStatus()

  if (!response.ok) {
    throw new Error(`Failed to load telemetry consent: ${response.statusMessage}`)
  }

  return response.body?.status === 'granted'
}

export function Telemetry({title}: {title: string}) {
  const {baseUrl} = useConfig()
  const toast = useToast()
  const queryClient = useQueryClient()

  const {
    data: consentGranted,
    isLoading,
    isError,
    error,
  } = useQuery<FIXME>({
    queryKey: [CACHE_KEY],
    queryFn: () => fetchConsent(),
  })

  useEffect(() => {
    if (isError && error) {
      toast.push({
        title: 'Failed to load telemetry consent',
        description: error.message,
        status: 'error',
      })
    }
  }, [isError, error, toast])

  const mutation = useMutation({
    mutationKey: ['telemetryConsent'],
    mutationFn: (consent: boolean) => setTelemetryConsentStatus(consent ? 'granted' : 'denied'),
    onMutate: async (newConsentStatus: boolean) => {
      // Cancel any outgoing refetches
      await queryClient.cancelQueries({queryKey: [CACHE_KEY]})

      // Snapshot the previous value
      const previousState = queryClient.getQueryData([CACHE_KEY])

      // Optimistically update to the new value
      queryClient.setQueryData([CACHE_KEY], () => newConsentStatus)

      return previousState
    },
    onError: (error: any, previousConsentStatus) => {
      queryClient.setQueryData([CACHE_KEY], () => previousConsentStatus)

      toast.push({
        title: 'Could not update telemetry consent',
        description: error.message,
        status: 'error',
      })
    },
    onSuccess: () => {
      toast.push({
        title: 'Telemetry consent successfully updated',
        status: 'success',
      })
    },
  })

  const handleConsentStatus = (enabled: boolean) => {
    mutation.mutate(enabled)
  }

  const desc = (
    <>
      Allow us to collect telemetry data on general usage and errors.{' '}
      <ExternalLink url={`${baseUrl}/telemetry`} text={'Your privacy remains our priority.'} />
    </>
  )

  return (
    <Stack space={5}>
      <TabSectionHeader title={title} />
      <Flex direction={'column'}>
        <FormField
          label="Help us build a better product"
          description={desc}
          htmlFor="telemetry-consentGranted"
        />
        <Card>
          {(isLoading || isError) && <LoadingSkeleton animated />}
          {!isLoading && !isError && (
            <Inline space={3} as="label">
              <Switch
                id="telemetry-consentGranted"
                checked={consentGranted}
                readOnly={isLoading}
                onChange={(e) => handleConsentStatus(e.currentTarget.checked)}
              />
              <Text>Share telemetry data</Text>
            </Inline>
          )}
        </Card>
      </Flex>
    </Stack>
  )
}
