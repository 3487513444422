// TODO: types for this?
// TS
import getIt from 'get-it'
import jsonRequest from 'get-it/lib/middleware/jsonRequest'
import jsonResponse from 'get-it/lib/middleware/jsonResponse'
import httpErrors from 'get-it/lib/middleware/httpErrors'
import {defer, Observable} from 'rxjs'

const requester = getIt([httpErrors(), observable(), jsonRequest(), jsonResponse()])

export function request<T = unknown>(options: RequestOptions): Observable<T> {
  return defer(() => requester(options) as Observable<T>)
}

export function apiRequest<T = unknown>(options: RequestOptions): Observable<T> {
  return defer(() => requester({withCredentials: true, ...options}) as Observable<T>)
}

export type RequestMethod = 'GET' | 'POST' | 'PUT' | 'HEAD' | 'DELETE' | 'OPTIONS' | 'PATCH'

export interface RequestOptions {
  url: string
  method?: RequestMethod
  headers?: Record<string, unknown>
  body?: string | ArrayBufferView | Blob | FormData | File | unknown
  bodySize?: number
  timeout?: {connect: number; socket: number}
  maxRedirects?: number
  rawBody?: boolean
  query?: Record<string, unknown>
  withCredentials?: boolean
}

export interface Response {
  body: string
  url: string
  method: RequestMethod
  statusCode: number
  statusMessage?: string
  headers: Record<string, string>
}

export class APIError extends Error {
  response: string

  constructor(
    public statusCode: number,
    message: string,
    response: string,
    cause?: Error
  ) {
    super(message, {cause})
    this.name = 'APIError'
    this.statusCode = statusCode
    this.response = response
  }
}

// Observable middleware without progress events
function observable() {
  return {
    onReturn: (
      channels: {
        error: {subscribe: (arg0: (err: any) => void) => void}
        response: {subscribe: (arg0: (response: any) => void) => void}
        request: {publish: (arg0: any) => void}
        abort: {publish: () => any}
      },
      context: any
    ) =>
      new Observable((observer) => {
        channels.error.subscribe((err) => {
          if (err.name !== 'HttpError' || err.response === undefined) {
            return observer.error(err)
          }
          try {
            const errorBody = JSON.parse(
              (err as Error & {response: {body: string}})?.response?.body
            )

            const apiError = new APIError(
              errorBody.statusCode,
              errorBody.message,
              err.response,
              err
            )
            return observer.error(apiError)
          } catch (parseError) {
            let message = 'Unknown error'
            if (parseError instanceof Error) message = parseError.message
            return observer.error(new Error(`Failed to parse error response: ${message}`))
          }
        })
        channels.response.subscribe((response) => {
          observer.next(response.body)
          observer.complete()
        })

        channels.request.publish(context)
        return () => channels.abort.publish()
      }),
  }
}
