import {BlueLinkButton} from '@/ui/app'
import {ArrowTopRightIcon} from '@sanity/icons'
import {useParams} from 'next/navigation'

export const OrganizationAccessRequestSettingsLink = () => {
  const {orgId} = useParams()

  if (orgId) {
    return (
      <BlueLinkButton
        icon={ArrowTopRightIcon}
        fontSize={1}
        text={'Manage who can request access'}
        mode="bleed"
        href={`/organizations/${orgId}/settings#access-requests`}
      />
    )
  }

  return null
}
