import {useEffect} from 'react'
import {CurrentUser} from '../types/models'
import {useSentryUserContext} from './SentryUserProvider'

const useSentryGlobalTags = (
  currentUser?: CurrentUser | null,
  projectId?: string,
  orgId?: string
) => {
  const {setContext} = useSentryUserContext()
  useEffect(() => {
    setContext({
      currentUser,
      projectId,
      orgId,
    })
  }, [setContext, currentUser, projectId, orgId])
}

export default useSentryGlobalTags
