import {useMemo} from 'react'
import {Organization, OrganizationCostSummary, Project, ResourceOptions} from '@/types/models'
import {useProjectsUsage} from '@/data/projects'
import {
  convertSnapshotKeysToResourceIds,
  getResourcesObject,
  RESOURCE_NAMES,
  RESOURCE_UNITS,
} from '@/utils/usage'

export function useOrganizationCostSummary(
  org?: Organization,
  projects?: Project[],
  options?: ResourceOptions
): {data: OrganizationCostSummary | null; isLoading: boolean} {
  const projectUsages = useProjectsUsage(projects, {...options, cumulative: false})
  const data = useMemo(() => {
    let planCosts = 0
    let overageCosts = 0

    projectUsages.forEach(({data: projectUsage}) => {
      if (!projectUsage) {
        return
      }

      if (projectUsage.planCost) {
        planCosts += projectUsage.planCost
      }
      if (projectUsage.overageCost) {
        overageCosts += projectUsage.overageCost
      }
    })

    const prjResources = projectUsages.map((projectUsage) => {
      if (
        projectUsage.data &&
        org?.organizationPlan &&
        org.organizationPlan.projectIds.includes(projectUsage.data.projectId) === false
      ) {
        return convertSnapshotKeysToResourceIds(
          // todo: fix types
          Object.keys(projectUsage.data?.resources).reduce<any>((res, curr) => {
            res[curr] = {...projectUsage.data?.resources[curr]}
            if (org.organizationPlan?.playgroundProjects.maxResources[curr]) {
              res[curr].quota = org.organizationPlan?.playgroundProjects.maxResources[curr].quota
            }
            return res
          }, {})
        )
      }
      return convertSnapshotKeysToResourceIds(projectUsage.data?.resources)
    })

    const totals = RESOURCE_NAMES.map((name) => {
      return {
        id: name,
        usage: prjResources?.reduce((sum, res) => sum + (res[name]?.usage || 0), 0),
        overageCost: prjResources?.reduce((sum, res) => sum + res[name]?.overageCost || 0, 0),
        quota: prjResources?.reduce((sum, res) => sum + res[name]?.quota || 0, 0),
        unit: RESOURCE_UNITS[name],
      }
    })

    return {
      planCost: planCosts,
      overageCost: overageCosts,
      totalCost: planCosts + overageCosts,
      resources: getResourcesObject(totals),
    }
  }, [org, projectUsages])

  const isLoading = projectUsages.some((projectUsage) => projectUsage.isLoading)

  return {data, isLoading}
}
