import {Text} from '@sanity/ui'
import styled from 'styled-components'
import {PortableTextComponentProps} from '@portabletext/react'

const ListItem = styled(Text)`
  display: list-item !important;
  list-style-type: disc;
  list-style-position: outside;
  transform: none;

  &:before {
    display: none;
  }

  span {
    display: inline;
  }
`

export const ListItemBullet = (props: PortableTextComponentProps<any>) => {
  return (
    <ListItem muted forwardedAs="li">
      {props.children}
    </ListItem>
  )
}
