import {useContext} from 'react'
import {GetStartedStoreContext} from './storeContext'

export function useGettingStartedStore() {
  const store = useContext(GetStartedStoreContext)

  if (!store) throw new Error('Store: missing context value')

  return store
}
