import {Grant, PermissionResource, PermissionUpdate, RoleResource} from '@/types/models'

const documentFilterRead = ['read']
const documentFilterUpdate = ['read', 'update', 'create']

export const CONTENT_RESOURCES = ['sanity.document.filter.mode', 'sanity.document.filter']

export function isContentResource(type: string): boolean {
  return CONTENT_RESOURCES.includes(type)
}

export function isUserRoleResource(resource: {type: string; name: string | null}): boolean {
  return (
    resource.type === 'sanity.document.filter.mode' ||
    resource.name === 'sanity-document-filter-images' ||
    resource.name === 'sanity-document-filter-files'
  )
}

export function isUserResource(resource: {
  permissionResourceType: string
  name: string | null
}): boolean {
  return (
    resource.permissionResourceType === 'sanity.document.filter.mode' ||
    resource.name === 'sanity-document-filter-images' ||
    resource.name === 'sanity-document-filter-files'
  )
}

export function contentResourceCompare(
  a: {name: string | null; title: string},
  b: {name: string | null; title: string}
): number {
  if (a.name === 'sanity-all-documents' && b.name === 'sanity-all-documents') return 0
  if (a.name === 'sanity-all-documents') return -1
  if (b.name === 'sanity-all-documents') return 1

  if (a.name === 'sanity-document-filter-images' && b.name === 'sanity-document-filter-images')
    return 0
  if (a.name === 'sanity-document-filter-images') return -1
  if (b.name === 'sanity-document-filter-images') return 1

  if (a.name === 'sanity-document-filter-files' && b.name === 'sanity-document-filter-files')
    return 0
  if (a.name === 'sanity-document-filter-files') return -1
  if (b.name === 'sanity-document-filter-files') return 1

  return a.title.localeCompare(b.title)
}

export function getDocumentFilterAsModePermissions(mode: string, invert = false): string[] {
  switch (mode) {
    case 'read':
      if (invert) return documentFilterUpdate.filter((name) => !documentFilterRead.includes(name))
      return documentFilterRead
    case 'create':
      if (invert) return []
      return documentFilterUpdate
    default:
      if (invert) return documentFilterUpdate
      return []
  }
}

export function getModePermissions(): string[] {
  return documentFilterUpdate
}

export function getDocumentFilterExtraPermissions(): string[] {
  return ['manage', 'editHistory', 'history']
}

export type DocumentFilterOverview = {
  read: boolean
  update: boolean
  create: boolean
  extra: string[]
  grants: string[]
}

export function getDocumentFilterAsModeOverview(grants: Grant[]): DocumentFilterOverview {
  return grants.reduce(
    (acc, grant) => {
      acc.grants.push(grant.name)
      switch (grant.name) {
        case 'read':
          acc.read = true
          break
        case 'update':
          acc.update = true
          break
        case 'create':
          acc.create = true
          break
        default:
          acc.extra.push(grant.name)
      }
      return acc
    },
    {read: false, update: false, create: false, extra: [], grants: []} as DocumentFilterOverview
  )
}

export type ContentResourceMode = {
  mode: string | undefined
  indeterminate: boolean
  extra: string[]
  grants: string[]
}
export function getDocumentFilterGrantsAsMode(resource: RoleResource): ContentResourceMode {
  const has = getDocumentFilterAsModeOverview(resource.grants)

  if (has.read && has.update && has.create) {
    return {
      mode: 'create',
      indeterminate: false,
      extra: has.extra,
      grants: has.grants,
    }
  }

  if (has.read) {
    return {
      mode: 'read',
      indeterminate: has.update || has.create,
      extra: has.extra,
      grants: has.grants,
    }
  }

  return {
    mode: 'unknown',
    indeterminate: has.read || has.update || has.create,
    extra: has.extra,
    grants: has.grants,
  }
}

export function getDocumentResourceGrant(resource: RoleResource | undefined): ContentResourceMode {
  if (!resource) {
    return {
      mode: 'no access',
      indeterminate: false,
      extra: [],
      grants: [],
    }
  }
  if (resource.type === 'sanity.document.filter') {
    return getDocumentFilterGrantsAsMode(resource)
  }

  const grant = resource.grants.find((modeGrant) => modeGrant.params.mode !== undefined)
  return {
    mode: grant?.params.mode,
    indeterminate: false,
    extra: [],
    grants: [],
  }
}

export function addGrantsToResources(
  toAdd: PermissionUpdate[],
  resources: RoleResource[],
  permissionResources: PermissionResource[]
): RoleResource[] {
  const updated = resources.map((resource) => {
    const permissions = toAdd.filter((update) => update.permissionResourceId === resource.id)
    if (permissions.length === 0) return resource

    const existingGrants = resource.grants.filter(
      (grant) =>
        !permissions.some(
          (permission) =>
            grant.name === permission.permissionName &&
            grant.params.tagName === permission.params.tagName &&
            grant.params.dataset === permission.params.dataset
        )
    )

    return {
      ...resource,
      grants: [
        ...existingGrants,
        ...permissions.map((permission) => ({
          name: permission.permissionName,
          params: {...permission.params},
        })),
      ],
    }
  })
  return toAdd.reduce((acc, update) => {
    const resource = resources.find((res) => res.id === update.permissionResourceId)
    if (resource !== undefined) return acc
    const addedResource = acc.find((res) => res.id === update.permissionResourceId)
    if (addedResource) {
      addedResource.grants.push({name: update.permissionName, params: {...update.params}})
    } else {
      const permissionResource = permissionResources.find(
        (res) => res.id === update.permissionResourceId
      )
      acc.push({
        id: update.permissionResourceId,
        name: permissionResource?.name || null,
        title: permissionResource?.title || '',
        type: permissionResource?.permissionResourceType || '',
        description: permissionResource?.description || null,
        grants: [
          {
            name: update.permissionName,
            params: {...update.params},
          },
        ],
      })
    }
    return acc
  }, updated)
}

export function removeGrantsToResources(
  toRemove: PermissionUpdate[],
  resources: RoleResource[]
): RoleResource[] {
  return resources
    .map((resource) => {
      const permissions = toRemove.filter((update) => update.permissionResourceId === resource.id)
      if (permissions.length === 0) return resource
      const grants = resource.grants.filter(
        (grant) =>
          !permissions.some(
            (permission) =>
              grant.name === permission.permissionName &&
              grant.params.tagName === permission.params.tagName &&
              grant.params.dataset === permission.params.dataset
          )
      )
      if (grants.length === 0) {
        return undefined
      }
      return {
        ...resource,
        grants,
      }
    })
    .filter(Boolean) as RoleResource[]
}
