import {useOrgInvitations} from '@/data/invitations'
import {useOrganizationRolesList} from '@/data/organizations/useOrganizationRoleList'
import {DialogToast, Organization, OrganizationInvitation} from '@/types/index'
import {List, MemberPreview} from '@/ui/index'
import React from 'react'

type Props = {
  org: Organization
  invitations: OrganizationInvitation[]
  Dialog: React.ElementType
  hideDialog: (dialogToast: DialogToast) => void
}

export const RevokeOrgInvitationsDialog = ({org, invitations, hideDialog, Dialog}: Props) => {
  const {data: roles = []} = useOrganizationRolesList(org.id)
  const roleTitle = (name: string | undefined) =>
    roles?.find((role) => role.name === name)?.title || ''
  const {revokeInvitations, isRevoking} = useOrgInvitations(org.id)
  const invitationPluralPrefixed = invitations.length === 1 ? 'invitation' : 'invitations'

  const handleRevokeInvitations = () => {
    revokeInvitations(invitations, {
      onSuccess: () => {
        hideDialog({
          description: `Revoked the ${invitationPluralPrefixed} from the organization`,
          status: 'success',
        })
      },
      onError: () => {
        hideDialog({
          description: `Failed to revoke the ${invitationPluralPrefixed} from the organization`,
          status: 'error',
        })
      },
    })
  }

  return (
    <Dialog
      header={`Revoke ${invitationPluralPrefixed} to organization`}
      title={
        invitations.length === 1
          ? `Do you really want to revoke the invitation sent to ${invitations[0]?.email}?`
          : `Do you really want to revoke ${invitations.length} invitation${
              invitations.length === 1 ? '' : 's'
            }?`
      }
      description={
        invitations.length === 1
          ? `${invitations[0].email} will no longer be able to join the organization.`
          : `They will no longer be able to join the organization.`
      }
      onConfirm={handleRevokeInvitations}
      loading={isRevoking}
    >
      <List>
        {invitations.map((invitation) => (
          <li key={invitation.id}>
            <MemberPreview
              title={invitation.email}
              userId={invitation.invitedByUserId}
              subtitle={roleTitle(invitation.roles ? invitation.roles[0].name : undefined)}
            />
          </li>
        ))}
      </List>
    </Dialog>
  )
}
