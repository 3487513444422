import {
  Box,
  Flex,
  type HotkeysProps,
  Text,
  Tooltip as UITooltip,
  type TooltipProps as UITooltipProps,
} from '@sanity/ui'
import {type ForwardedRef, forwardRef} from 'react'

import {TOOLTIP_DELAY_PROPS} from './constants'

export type TooltipProps = Omit<UITooltipProps, 'arrow' | 'padding' | 'shadow'> & {
  hotkeys?: HotkeysProps['keys']
}

const TOOLTIP_SHARED_PROPS: UITooltipProps = {
  animate: true,
  arrow: false,
  boundaryElement: null,
  delay: TOOLTIP_DELAY_PROPS,
  fallbackPlacements: ['bottom-start', 'bottom-end', 'top-start', 'top-end'],
  placement: 'bottom',
  portal: true,
}

export const Tooltip = forwardRef(function Tooltip(
  props: TooltipProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const {content, ...rest} = props

  if (typeof content === 'string') {
    return (
      <UITooltip
        {...TOOLTIP_SHARED_PROPS}
        content={
          <Flex align="center">
            {typeof content === 'string' && (
              <Box flex={1} padding={1}>
                <Text size={1}>{content}</Text>
              </Box>
            )}

            {typeof content !== 'string' && content}
          </Flex>
        }
        padding={1}
        ref={ref}
        {...rest}
      />
    )
  }

  return <UITooltip {...TOOLTIP_SHARED_PROPS} content={content} ref={ref} {...rest} />
})
