import {type Request, type ResourceType, type Role} from '@sanity/access-api'
import {SelectIcon} from '@sanity/icons'
import {Box, Flex, Text} from '@sanity/ui'
import {useCallback, useMemo, useState} from 'react'
import styled, {css} from 'styled-components'

import {type MembersV2} from '@/types/members_v2'

import {BadgeStack} from '../../../generic/badge-stack'
import {
  defineTableVirtualizedCell,
  defineTableVirtualizedColumns,
  TableVirtualized,
} from '../../../generic/table-virtualized'
import {Button} from '../../../primitives/button/Button'
import {Tooltip} from '../../../primitives/tooltip'
import {RoleSelectMenuButton} from '../../role-select-menu-button'
import {CellContentWrap, MemberAvatarCell, MemberCell} from '../common'
import {CELL_PADDING} from '../constants'

const estimateSize = () => 73

const StyledButton = styled(Button)(({theme}) => {
  const space = theme.sanity.space[2]

  return css`
    overflow: auto;

    // If the button mode is "bleed", we need to visually align the button
    // by moving it to the left.
    transform: translateX(-${space}px);
  `
})

interface RoleRequestRow {
  requestId: string
  requestedRole: string
  currentRole: string
}

export interface RequestAccessTableProps {
  onRequestApprove: (
    requestId: string,
    resourceId: string,
    resourceType: ResourceType,
    roleName: string
  ) => void
  onRequestRevoke: (requestId: string, resourceId: string, resourceType: ResourceType) => void
  projectsData: MembersV2['projectData'][]
  resourceType: ResourceType
  requests: Request[]
  /**
   * The roles are used to determine which roles that should be
   * selectable for each request.
   */
  roles: Role[]
}

export function RequestRoleChangeTable(props: RequestAccessTableProps) {
  const {onRequestApprove, onRequestRevoke, projectsData, resourceType, requests, roles} = props

  // This is used to store the selected role for each request
  const [requestRowsData, setRequestRowsData] = useState<RoleRequestRow[]>(() => {
    if (!requests) return []

    // Set "administrator" as the default role for each request
    return requests.map((request) => ({
      requestId: request.id || '',
      requestedRole: request.requestedRole || 'administrator',
      currentRole: 'viewer',
    }))
  })

  const getRequest = useCallback(
    (requestId: string) => {
      return requests.find((request) => request.id === requestId)
    },
    [requests]
  )

  const handleRequestApprove = useCallback(
    (requestId: string) => {
      const request = getRequest(requestId)

      if (!request?.resourceType || !request?.resourceId) return

      const selectedRoleName = requestRowsData.find(
        (row) => row.requestId === requestId
      )?.requestedRole

      if (selectedRoleName) {
        onRequestApprove(
          requestId,
          request.resourceId,
          request.resourceType as ResourceType,
          selectedRoleName
        )
      }
    },
    [getRequest, onRequestApprove, requestRowsData]
  )

  const handleRequestRevoke = useCallback(
    (requestId: string) => {
      const request = getRequest(requestId)

      if (!request?.resourceType || !request?.resourceId) return

      onRequestRevoke(requestId, request.resourceId, request.resourceType as ResourceType)
    },
    [getRequest, onRequestRevoke]
  )

  const avatarCell = useMemo(() => {
    return defineTableVirtualizedCell<Request>({
      id: 'avatar',
      cellMaxWidth: 80,
      cellMinWidth: 40,
      cellPadding: 0,
      headerPadding: CELL_PADDING,
      header: '',

      cell: (ctx) => {
        const member = ctx.row.original.requestedByUserProfile

        return (
          <MemberAvatarCell
            displayName={member?.displayName || ''}
            imageUrl={member?.imageUrl || ''}
            provider={member?.provider || ''}
          />
        )
      },
    })
  }, [])

  const memberCell = useMemo(() => {
    return defineTableVirtualizedCell<Request>({
      header: 'Name',
      id: 'member',
      cellPadding: CELL_PADDING,
      cellMinWidth: 140,
      headerPadding: CELL_PADDING,

      cell: (ctx) => {
        const member = ctx.row.original.requestedByUserProfile

        if (!member) {
          return <MemberCell displayName="Unknown" email="" />
        }

        return (
          <MemberCell
            displayName={member?.displayName}
            email={member?.email}
            createdAt={ctx.row.original.createdAt}
          />
        )
      },
    })
  }, [])

  const requestResourceCell = useMemo(() => {
    return defineTableVirtualizedCell<Request>({
      header: 'Project',
      id: 'resource',
      cellPadding: CELL_PADDING,
      cellMinWidth: 150,
      cellMaxWidth: 200,
      headerPadding: CELL_PADDING,

      cell: (ctx) => {
        const resourceId = ctx.row.original.resourceId

        if (ctx.row.original.resourceType === 'organization') {
          return (
            <CellContentWrap>
              <Text size={1} muted>
                Organization
              </Text>
            </CellContentWrap>
          )
        }

        const project = projectsData.find((p) => p.projectProfile.id === resourceId)
        const projectProfile = project?.projectProfile

        return (
          <CellContentWrap>
            {!projectProfile && (
              <Box>
                <Text size={1} muted textOverflow="ellipsis">
                  Unknown
                </Text>
              </Box>
            )}

            {projectProfile?.displayName && (
              <BadgeStack items={[{title: projectProfile?.displayName}]} />
            )}
          </CellContentWrap>
        )
      },
    })
  }, [projectsData])

  const roleCell = useMemo(() => {
    return defineTableVirtualizedCell<Request>({
      header: 'Requested Role',
      id: 'role',
      cellPadding: CELL_PADDING,
      headerPadding: CELL_PADDING,
      cellMinWidth: 150,
      cellMaxWidth: 200,

      cell: (ctx) => {
        const requestId = ctx.row.original?.id || ''
        const resourceId = ctx.row.original?.resourceId || ''

        // Find the roles that are available for the resource
        const currentRoles = roles.filter((r) => r.resourceId === resourceId)

        // Find the selected role for the request
        const selectedRole = requestRowsData.find(
          (row) => row.requestId === requestId
        )?.requestedRole

        // Find the role profile for the selected role
        const roleProfile = roles.find((r) => r.name === selectedRole)

        return (
          <CellContentWrap>
            <RoleSelectMenuButton
              options={currentRoles}
              onSelect={(role) => {
                // Update the selected role for the request
                setRequestRowsData((prev) =>
                  prev.map((row) => {
                    if (row.requestId === requestId && role.name) {
                      return {
                        ...row,
                        requestedRole: role.name,
                      }
                    }

                    return row
                  })
                )
              }}
              selectedRoles={roleProfile ? [roleProfile] : []}
              popoverButtonProps={{
                button: (
                  <StyledButton
                    fontSize={1}
                    mode="bleed"
                    padding={2}
                    text={roleProfile?.title || roleProfile?.name || 'Select role'}
                    iconRight={SelectIcon}
                  />
                ),
              }}
            />
          </CellContentWrap>
        )
      },
    })
  }, [requestRowsData, roles])

  const noteCell = useMemo(() => {
    return defineTableVirtualizedCell<Request>({
      header: 'Note',
      id: 'note',
      cellPadding: CELL_PADDING,
      headerPadding: CELL_PADDING,
      cellMinWidth: 175,

      cell: (ctx) => {
        const value = ctx.row.original.note

        return (
          <CellContentWrap>
            <Box>
              <Text size={1} muted style={{wordBreak: 'break-word'}}>
                {value ? `"${value}"` : 'N/A'}
              </Text>
            </Box>
          </CellContentWrap>
        )
      },
    })
  }, [])

  const actionsCell = useMemo(() => {
    return defineTableVirtualizedCell<Request>({
      id: 'actions',
      headerPadding: CELL_PADDING,
      header: '',
      cellMaxWidth: 150,
      cellMinWidth: 150,

      cell: (ctx) => {
        const requestId = ctx.row.original.id || ''

        const hasSelectedRole = requestRowsData.some((row) => row.requestId === requestId)

        return (
          <CellContentWrap>
            <Flex gap={1} flex={1}>
              <Button
                text="Deny"
                onClick={() => handleRequestRevoke(requestId)}
                fontSize={1}
                mode="bleed"
              />

              <Tooltip
                disabled={hasSelectedRole}
                placement="bottom-start"
                content="You need to select a role before you can approve the request"
              >
                <div>
                  <Button
                    text="Approve"
                    disabled={!hasSelectedRole}
                    onClick={() => handleRequestApprove(requestId)}
                    fontSize={1}
                    mode="ghost"
                    tone="primary"
                  />
                </div>
              </Tooltip>
            </Flex>
          </CellContentWrap>
        )
      },
    })
  }, [handleRequestApprove, handleRequestRevoke, requestRowsData])

  const columns = useMemo(() => {
    if (resourceType === 'project') {
      return defineTableVirtualizedColumns<Request>([
        avatarCell,
        memberCell,
        noteCell,
        roleCell,
        actionsCell,
      ])
    }

    if (resourceType === 'organization') {
      return defineTableVirtualizedColumns<Request>([
        avatarCell,
        memberCell,
        noteCell,
        requestResourceCell,
        roleCell,
        actionsCell,
      ])
    }

    return []
  }, [actionsCell, avatarCell, memberCell, noteCell, requestResourceCell, resourceType, roleCell])

  return (
    <TableVirtualized columnsDefinitions={columns} data={requests} estimateSize={estimateSize} />
  )
}
