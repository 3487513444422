import {Box, Card, Heading, MenuDivider, Stack, ThemeColorToneKey} from '@sanity/ui'
import React, {useCallback} from 'react'
import {IconSymbol} from '@sanity/icons'
import {Item} from './item'
import {Loader} from '@/ui/index'

export type ProjectsOverageListData = {
  id: string
  name: string
  overageResources: string[]
  approachingResources: string[]
  href?: string
  projectColor?: string
  canPay: boolean
  isPlayground: boolean
}

type Props = {
  loading?: boolean
  data: ProjectsOverageListData[]
}

export function ProjectsOverageList({data, loading}: Props) {
  const filteredData = data.filter(
    (d) => d.overageResources.length > 0 || d.approachingResources.length > 0
  )

  const hasOverage = filteredData.filter((d) => d.overageResources.length > 0).length > 0
  const hasApproaching = filteredData.filter((d) => d.approachingResources.length > 0).length > 0
  const showPrompt = hasOverage || hasApproaching

  const getStyling = useCallback(
    (
      _data: ProjectsOverageListData
    ): {
      icon: IconSymbol
      tone: ThemeColorToneKey
    } => {
      const {isPlayground, canPay} = _data

      if (isPlayground) {
        return {
          icon: 'warning-outline',
          tone: 'caution',
        }
      }
      if (canPay === false) {
        return {
          icon: 'warning-outline',
          tone: 'caution',
        }
      }
      return {
        icon: 'overage',
        tone: 'positive',
      }
    },
    []
  )

  const promptText = useCallback(() => {
    const prefix = `${filteredData.length} ${
      filteredData.length === 1 ? 'project is' : 'projects are'
    }`

    if (hasOverage && hasApproaching) {
      return `${prefix} approaching or exceeding quota`
    }
    if (hasOverage && !hasApproaching) {
      return `${prefix} exceeding quota`
    }
    if (!hasOverage && hasApproaching) {
      return `${prefix} approaching quota`
    }
    return ''
  }, [filteredData.length, hasApproaching, hasOverage])

  if (loading) {
    return <Loader text="Loading usage..." />
  }

  if (filteredData.length === 0 || !filteredData) {
    return null
  }

  return (
    <Card padding={5} border radius={2}>
      <Stack space={4}>
        {showPrompt && (
          <Box marginBottom={2}>
            <Heading size={0}>{promptText()}</Heading>
          </Box>
        )}

        {filteredData.map((item, index: number) => (
          <Stack space={4} key={item.name}>
            <Item
              id={item.id}
              name={item.name}
              overageResources={item.overageResources}
              approachingResources={item.approachingResources}
              tone={getStyling(item).tone}
              href={item.href}
              icon={getStyling(item).icon}
              projectColor={item?.projectColor}
            />
            {index < filteredData.length - 1 && <MenuDivider />}
          </Stack>
        ))}
      </Stack>
    </Card>
  )
}
