import {Project, ProjectMemberRole, User} from '@/types/index'
import {useQuery} from '@tanstack/react-query'
import freeEmailDomains from 'free-email-domains'
import {getProjectAccessRequestsKey} from './cache'
import {getUsers} from '../users'
import {getRequests, Request, UserProfile} from '@sanity/access-api'

export type RequestWithWarnings = Request & {
  warnings?: ('nomatch-email-domain' | 'auth-provider' | 'personal-email')[]
  requestedRole?: string
}

function getAccessRequestWarnings(projectMemberUsers: (User | null)[], requester: UserProfile) {
  const warnings: RequestWithWarnings['warnings'] = []
  const requesterEmailDomain = requester?.email?.split('@')?.[1]
  const requesterProvider = requester?.provider
  const projectMemberEmailDomains = projectMemberUsers.map(
    (member) => member?.email?.split('@')?.[1]
  )
  const projectMemberAuthProviders = projectMemberUsers.map((member) => member?.provider)
  if (!requesterEmailDomain) throw new Error('Requester email domain not found')
  if (!projectMemberEmailDomains.includes(requesterEmailDomain))
    warnings.push('nomatch-email-domain')
  if (freeEmailDomains.includes(requesterEmailDomain)) warnings.push('personal-email')
  if (!projectMemberAuthProviders.includes(requesterProvider)) warnings.push('auth-provider')

  return warnings
}

export function useProjectRequests({project}: {project: Project}) {
  return useQuery({
    queryKey: getProjectAccessRequestsKey(project.id),
    queryFn: async () => {
      const requests = await getRequests({path: {resourceId: project.id, resourceType: 'project'}})
      if (!requests) {
        throw new Error(`Something went wrong fetching access requests for projectId ${project.id}`)
      }

      // Get the sanityUserIds for the projectMemberUsers
      const projectMemberUsers = await getUsers(project.members.map((member) => member.id))
      const projectMembersBySanityId: Record<string, User & {roles: ProjectMemberRole[]}> =
        Object.fromEntries(
          projectMemberUsers.map((projectUser, index) => [
            projectUser?.sanityUserId,
            {...projectUser, roles: project.members[index].roles},
          ])
        )

      const returnedRequests: RequestWithWarnings[] = []

      requests.data?.forEach((request) => {
        const isProjectMember = projectMembersBySanityId[request.requestedByUserId || '']
        // Hide access requests where user is already a member of the project
        if (request.type === 'access' && !isProjectMember) {
          const requestWithWarnings = {
            ...request,
            warnings: getAccessRequestWarnings(projectMemberUsers, request.requestedByUserProfile!),
          }

          returnedRequests.push(requestWithWarnings)
        }
        // Hide role requests where user's role is anything but a Viewer only
        if (
          request.type === 'role' &&
          isProjectMember.roles.length === 1 &&
          isProjectMember.roles[0].name === 'viewer'
        ) {
          returnedRequests.push(request)
        }
      })
      return returnedRequests
    },
  })
}
