import {Card, Inline, Heading, Text, Stack} from '@sanity/ui'
import React from 'react'
import {nameToInitials} from './utils'
import {AvatarWithFallback} from '@/ui/index'
import {useUser} from '@/data/users'

interface Props {
  userId: string
  title?: string
  subtitle?: string
}

export function MemberPreview({title, userId, subtitle}: Props) {
  const {data: user} = useUser(userId)
  if (!user) {
    return null
  }
  return (
    <Card shadow={1} padding={3} radius={2} marginBottom={3}>
      <Inline space={3}>
        <AvatarWithFallback
          size={1}
          fallback={!user?.imageUrl}
          src={user.imageUrl || undefined}
          initials={nameToInitials(user.displayName)}
        />
        <Stack space={2}>
          <Heading size={1}>{title || user.displayName}</Heading>
          {subtitle && (
            <Text size={1} muted>
              {subtitle}
            </Text>
          )}
        </Stack>
      </Inline>
    </Card>
  )
}
