import {useUsageOverages} from '@/components/checkout/hooks/useUsageOverages'
import {useMeteredOverages} from '@/components/checkout/hooks/useMeteredOverages'
import type {Plan, Project} from '@/types/index'
import {type OverageData, type Overage, type SuggestedAddon} from './overages'
import {useMemo} from 'react'

export function useSwitchPlanOverages(project: Project, targetPlan: Plan): OverageData {
  const usage = useUsageOverages(project, targetPlan)
  const metered = useMeteredOverages(project, targetPlan)

  /* Combine overages from both sources and remove duplicates */
  const overages = useMemo(() => {
    const allOverages = [...usage.overages, ...metered.overages]
    return allOverages.filter(
      (overage, index, self) => self.findIndex((o) => o.id === overage.id) === index
    )
  }, [usage.overages, metered.overages])

  /* Combine blocking overages from both sources and remove duplicates */
  const blockingOverages = useMemo(() => {
    const allBlockingOverages = [...usage.blockingOverages, ...metered.blockingOverages]
    return allBlockingOverages.filter(
      (overage, index, self) => self.findIndex((o) => o.id === overage.id) === index
    )
  }, [usage.blockingOverages, metered.blockingOverages])

  /* Combine suggested addons from both sources */
  const suggestedAddons = useMemo(() => {
    return [...usage.suggestedAddons, ...metered.suggestedAddons]
  }, [usage.suggestedAddons, metered.suggestedAddons])

  return {
    overages,
    blockingOverages,
    suggestedAddons,
  }
}

export type {Overage, SuggestedAddon}
