import {promiseRequest} from '../util/promiseRequest'
import {CorsOrigin} from '../../types/models'

const headers = {
  'Content-Type': 'application/json',
}

export function getCorsOrigins(projectId: string): Promise<CorsOrigin[]> {
  return promiseRequest<CorsOrigin[]>(
    `https://api.${process.env.host}/v1/projects/${projectId}/cors`,
    {
      method: 'GET',
    }
  )
}

export function addCorsOrigin(
  projectId: string,
  corsOrigin: {origin: string; allowCredentials: boolean}
): Promise<CorsOrigin> {
  return promiseRequest<CorsOrigin>(
    `https://api.${process.env.host}/v1/projects/${projectId}/cors`,
    {
      method: 'POST',
      headers,
      json: corsOrigin,
    }
  )
}

export function deleteCorsOrigin(projectId: string, corsOriginId: string): Promise<CorsOrigin> {
  return promiseRequest<CorsOrigin>(
    `https://api.${process.env.host}/v1/projects/${projectId}/cors/${corsOriginId}`,
    {
      method: 'DELETE',
    }
  )
}
