import {useQuery} from '@tanstack/react-query'
import {CACHE_TTL, getUserKey, STALE_TTL} from './cache'
import {getUser} from './controller'

export function useUser(userId: string | undefined) {
  return useQuery({
    queryKey: getUserKey(userId),
    queryFn: () => getUser(userId),
    staleTime: STALE_TTL,
    gcTime: CACHE_TTL,

    // Default here is to always fetch on remount, but in virtualized lists
    // we don't want this behaviour
    refetchOnMount: false,
  })
}
