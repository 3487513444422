import {UseQueryResult, useQuery} from '@tanstack/react-query'
import {marketingStudioClient} from '../marketingStudioClient'
import {getPlanFeaturesKey} from './cache'

export type PlanFeatures = {
  freePlanFeatures: string[]
  growthPlanFeatures: string[]
  enterprisePlanFeatures: string[]
}

export const usePlanFeatures = (): UseQueryResult<PlanFeatures> => {
  return useQuery({
    queryKey: getPlanFeaturesKey(),
    queryFn: () => {
      return marketingStudioClient.fetch(`*[_type == 'pricingPage'] {
        "freePlanFeatures": content[_type == 'pricingPlanCards'].cards[0].features[listItem == 'bullet'].children[0].text,
          "growthPlanFeatures": content[_type == 'pricingPlanCards'].cards[1].features[listItem == 'bullet'].children[0].text,
          "enterprisePlanFeatures": content[_type == 'pricingPlanCards'].cards[2].features[listItem == 'bullet'].children[0].text,
      }[0]`)
    },
  })
}
