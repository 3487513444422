import {useCurrentScopeContext} from '@/context/useCurrentScopeContext'
import {type Lesson, updateProjectOnboardingData, useGettingStarted} from '@/data/index'
import {Box, Container, Stack} from '@sanity/ui'
import {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {
  LessonAccordion,
  LessonAccordionSkeleton,
} from '@/ui/components/get-started/components/LessonAccordion'
import {GetStartedHeading} from '@/ui/components/get-started/components/GetStartedHeading'
import {TabsSwitcher} from '@/ui/components/get-started/components/TabsSwitcher'
import {useProjectLessonProgress} from '@/data/journey/useProjectLessonProgress'
import {useUserOnboardingData} from '@/data/journey/useUserOnboardingData'
import {motion, AnimatePresence} from 'framer-motion'
import {useGettingStartedStore} from '@/ui/components/get-started/store'
import {ProgressFooter} from '@/ui/components/get-started/components/ProgressFooter'
import {useLayoutPortal} from '@/ui/app/layout/defaultLayout'
import confetti from 'canvas-confetti'
import {AllSetHeading} from '@/ui/components/get-started/components/AllSetHeading'
import {ResourceCards} from '@/ui/components/get-started/components/ResourceCards'
import {sendAmplitudeTrackingEvent} from '@/utils/tracking'
import smoothScrollIntoView from 'smooth-scroll-into-view-if-needed'
import {CourseFeedback} from '@/ui/components/get-started/components/CourseFeedback'

export type AccordionState = number | 'all-closed' | 'uninitialized'

const MotionBox = motion(Box)
const MotionContainer = motion(Container)

export function DeveloperView() {
  const {state, dispatch} = useGettingStartedStore()
  const headerRef = useRef<HTMLDivElement>(null)
  const [activeTabSlug, setActiveTabSlug] = useState<string | null>(state.framework)
  const [accordionState, setAccordionState] = useState<AccordionState>('uninitialized')

  const {projectId, org} = useCurrentScopeContext()
  const {data: getStartedData, isLoading: getStartedLoading} = useGettingStarted()
  const {data: onboardingData, isLoading: onboardingLoading} = useUserOnboardingData()
  const {
    data: lessonProgress,
    isLoading: progressLoading,
    update: updateLessonProgress,
    replace: replaceLessonProgress,
  } = useProjectLessonProgress(projectId!)
  const {addPortal, removePortal} = useLayoutPortal()
  const lessonProgressSet = useMemo(() => new Set(lessonProgress?.progress), [lessonProgress])

  const activeTab = useMemo(() => {
    return getStartedData?.tabs?.find((tab) => tab.slug.current === activeTabSlug)
  }, [getStartedData, activeTabSlug])

  const firstUncompletedIndex = useMemo(() => {
    return activeTab?.course.lessons.findIndex(({_id}) => !lessonProgressSet.has(_id))
  }, [activeTab, lessonProgressSet])

  const isEveryLessonCompleted = useMemo(() => {
    return activeTab?.course?.lessons?.every((lesson) => lessonProgressSet.has(lesson._id))
  }, [activeTab, lessonProgressSet])

  useEffect(() => {
    setActiveTabSlug(state.framework)
  }, [state.framework])

  const handleTabChange = useCallback(
    (slug: string) => {
      dispatch({type: 'getStarted/setFramework', value: slug})
      updateProjectOnboardingData(projectId!, {framework: slug})
      sendAmplitudeTrackingEvent('Getting Started Framework Switched', projectId, org?.id, {
        framework: slug,
      })
    },
    [dispatch, projectId, org]
  )

  const setCompletedUptoStep = useCallback(
    (lesson: Lesson) => {
      const currentIndex = activeTab?.course?.lessons.findIndex((l) => l._id === lesson._id)
      const previousSteps = activeTab?.course?.lessons.slice(0, currentIndex).map((l) => l._id)
      if (!previousSteps) return
      for (const step of previousSteps) {
        if (!lessonProgressSet.has(step)) {
          const stepLesson = activeTab?.course?.lessons.find((l) => l._id === step)
          if (!stepLesson) return
          sendAmplitudeTrackingEvent('Getting Started Section Completed', projectId, org?.id, {
            course_name: activeTab?.course.shortTitle || activeTab?.course.title,
            lesson_name: stepLesson.shortTitle || stepLesson.title,
          })
        }
      }
      replaceLessonProgress(previousSteps)
    },
    [replaceLessonProgress, lessonProgressSet, activeTab, projectId, org]
  )

  const handleSetCompleteStep = useCallback(
    (lesson: Lesson) => {
      updateLessonProgress(lesson._id)
      const updatedProgressSet = new Set(lessonProgressSet)
      updatedProgressSet.add(lesson._id)
      const isEveryLessonCompleted = activeTab?.course?.lessons?.every((l) =>
        updatedProgressSet.has(l._id)
      )

      if (isEveryLessonCompleted) {
        setAccordionState('all-closed')
        setTimeout(() => smoothScrollIntoView(headerRef!.current!, {block: 'start'}), 0) // Next tick
      } else {
        setAccordionState((p) =>
          p === 'all-closed' || p === 'uninitialized' ? 'all-closed' : p + 1
        )
      }
      sendAmplitudeTrackingEvent('Getting Started Section Completed', projectId, org?.id, {
        course_name: activeTab?.course.shortTitle || activeTab?.course.title,
        lesson_name: lesson.shortTitle || lesson.title,
      })

      if (isEveryLessonCompleted) {
        sendAmplitudeTrackingEvent('Getting Started Course Completed', projectId, org?.id, {
          course_name: activeTab?.course.shortTitle || activeTab?.course.title,
          lesson_name: lesson.shortTitle || lesson.title,
        })
        setTimeout(() => confetti(), 1000)
      }
    },
    [updateLessonProgress, lessonProgressSet, activeTab, projectId, org, headerRef]
  )

  const handleToggleAccordion = useCallback(
    (index: number) => {
      setAccordionState((prev) => (prev === index ? 'all-closed' : index))
      const lesson = activeTab?.course?.lessons[index]
      if (lesson) setCompletedUptoStep(lesson)
    },
    [activeTab?.course?.lessons, setCompletedUptoStep]
  )

  const tabsSwitcher = useCallback(
    (id: string) => {
      const showTabSwitcher = getStartedData?.displayTabSwitcherInLessons?.some(
        (lesson) => lesson._id === id
      )
      if (!getStartedData?.tabs || !showTabSwitcher) return null
      return (
        <TabsSwitcher
          tabs={getStartedData.tabs}
          activeTabSlug={activeTabSlug}
          onChange={handleTabChange}
        />
      )
    },
    [getStartedData, activeTabSlug, handleTabChange]
  )

  /**
   * Set the active tab based on the user onboarding data
   */
  useEffect(() => {
    /**
     * Avoid changing the active tab if:
     * - user is missing onboarding data
     * - get started data is missing
     * - the user has already selected a framework
     */
    if (onboardingLoading || getStartedLoading || state.framework) {
      return
    }
    const chosenTabBasedOnOnboardingData = getStartedData?.tabs?.find((tab) =>
      onboardingData?.technologies?.includes(tab.slug.current)
    )
    const firstTab = getStartedData?.tabs[0].slug.current

    setActiveTabSlug(chosenTabBasedOnOnboardingData?.slug.current || firstTab || null)
  }, [onboardingData, getStartedData, onboardingLoading, getStartedLoading, state.framework])

  /**
   * Set the first uncompleted lesson as the active accordion index
   */
  useEffect(() => {
    if (!activeTab || accordionState !== 'uninitialized' || progressLoading) {
      return
    }

    const hasLessonProgress = lessonProgressSet.size > 0
    // If all lessons are completed, set the accordion state to 'all-closed'
    if (hasLessonProgress && firstUncompletedIndex === -1) {
      return setAccordionState('all-closed')
    }
    // Set the first uncompleted lesson as the active accordion index, or the first lesson if none are completed
    setAccordionState(firstUncompletedIndex === -1 ? 0 : firstUncompletedIndex!)
  }, [
    activeTab,
    firstUncompletedIndex,
    progressLoading,
    lessonProgressSet,
    accordionState,
    setAccordionState,
  ])

  const progressFooter = useCallback(
    () =>
      activeTab?.course?.lessons && (
        <ProgressFooter
          lessons={activeTab.course.lessons}
          currentIndex={typeof accordionState === 'number' ? accordionState : firstUncompletedIndex}
          numberOfCompletedLessons={
            activeTab?.course?.lessons?.filter((lesson) => lessonProgressSet.has(lesson._id))
              .length ?? 0
          }
        />
      ),
    [activeTab, accordionState, firstUncompletedIndex, lessonProgressSet]
  )

  useEffect(() => {
    addPortal(progressFooter())
    return () => removePortal()
  }, [progressFooter, addPortal, removePortal])

  return (
    <Stack space={4}>
      <MotionBox
        ref={headerRef}
        key={isEveryLessonCompleted ? 'all-set' : 'get-started'}
        initial={{opacity: 0, height: 'auto'}}
        animate={{opacity: 1, height: 'auto'}}
        exit={{opacity: 0, height: 'auto'}}
        style={{scrollMarginTop: '140px'}}
      >
        {isEveryLessonCompleted ? <AllSetHeading /> : <GetStartedHeading />}
      </MotionBox>

      <Container>
        {isEveryLessonCompleted && (
          <MotionContainer initial={{opacity: 0}} animate={{opacity: 1, paddingBottom: 32}}>
            <ResourceCards resources={activeTab?.course?.related} />
          </MotionContainer>
        )}

        <Stack space={2}>
          <AnimatePresence mode="popLayout">
            {getStartedLoading || onboardingLoading || progressLoading
              ? Array.from({length: 5}).map((_, i) => (
                  <MotionBox key={`skeleton-${i}`} animate={{opacity: 1}} exit={{opacity: 0}}>
                    <LessonAccordionSkeleton />
                  </MotionBox>
                ))
              : activeTab?.course?.lessons?.map((lesson, i) => (
                  <MotionBox key={`lesson-${i}`} initial={{opacity: 0}} animate={{opacity: 1}}>
                    <LessonAccordion
                      course={activeTab.course}
                      lesson={lesson}
                      isOpen={accordionState === i}
                      isCompleted={lessonProgressSet.has(lesson._id)}
                      isAllClosed={accordionState === 'all-closed'}
                      onToggle={() => handleToggleAccordion(i)}
                      onComplete={handleSetCompleteStep}
                      tabsSwitcher={tabsSwitcher(lesson._id)}
                    />
                  </MotionBox>
                ))}
          </AnimatePresence>
        </Stack>
        <CourseFeedback
          framework={activeTab?.title}
          currentLesson={
            typeof accordionState === 'number'
              ? activeTab?.course.lessons[accordionState]
              : undefined
          }
        />
      </Container>
    </Stack>
  )
}
