import {Box, Card, Flex, Inline, Menu, MenuButton, MenuItem, Stack, Text, Tooltip} from '@sanity/ui'
import React, {useCallback, useMemo, useState} from 'react'
import {SelectIcon} from '@sanity/icons'
import {ExtraWarning, IndeterminateWarning, TableHeader} from './tableUtil'
import {getDocumentResourceGrant} from './util'
import {Grant} from './grant'
import {PermissionResource, RoleResource} from '@/types/models'
import {modeToDescription} from '@/components/roles/grants/format'

function EditResourceTitle({permissionResource}: {permissionResource: PermissionResource}) {
  if (permissionResource.description) {
    return (
      <Tooltip
        content={
          <Card radius={2} padding={3} style={{maxWidth: 250}}>
            <Text size={1}>{permissionResource.description}</Text>
          </Card>
        }
      >
        <Text size={1}>{permissionResource.title}</Text>
      </Tooltip>
    )
  }
  return <Text size={1}>{permissionResource.title}</Text>
}

const modeOptions = ['no access', 'read', 'create', 'publish']
const assetOptions = ['no access', 'read', 'create']
function EditResourceMode({
  resource,
  permissionResource,
  setPending,
}: {
  resource: RoleResource | undefined
  permissionResource: PermissionResource
  setPending: (_permissionResource: PermissionResource, option: string) => void
}) {
  const [has, setHas] = useState(() => getDocumentResourceGrant(resource))

  const options = useMemo(() => {
    if (permissionResource.permissionResourceType === 'sanity.document.filter.mode')
      return modeOptions
    return assetOptions
  }, [permissionResource])

  const handleUpdate = useCallback(
    (option) => {
      setHas({
        mode: option,
        indeterminate: false,
        extra: has.extra,
        grants: [],
      })
      setPending(permissionResource, option)
    },
    [has, permissionResource, setPending]
  )

  return (
    <Flex flex={1} justify="space-between">
      <MenuButton
        id="menubutto-permission-mode"
        portal
        button={
          <Card padding={1} border radius={2} style={{minWidth: 180}}>
            <Flex align="center" justify="space-between">
              <Flex>
                <Grant grant={has.mode} />
              </Flex>
              <Box paddingLeft={2} paddingRight={1}>
                <Text>
                  <SelectIcon />
                </Text>
              </Box>
            </Flex>
          </Card>
        }
        menu={
          <Menu>
            {options.map((option) => {
              const selected = option === has.mode
              return (
                <MenuItem
                  key={option}
                  onClick={() => handleUpdate(option)} // eslint-disable-line react/jsx-no-bind
                  selected={selected}
                  tone={selected ? 'primary' : 'default'}
                >
                  <Box>
                    <Stack space={2}>
                      <Flex>
                        <Grant grant={option} />
                      </Flex>
                      <Text muted size={1}>
                        {modeToDescription(option)}
                      </Text>
                    </Stack>
                  </Box>
                </MenuItem>
              )
            })}
          </Menu>
        }
      />
      <Flex align="center">
        <Inline space={2}>
          {has.indeterminate && <IndeterminateWarning grants={has.grants} />}
          {has.extra.length > 0 && <ExtraWarning grants={has.extra} />}
        </Inline>
      </Flex>
    </Flex>
  )
}

function EditResource({
  resource,
  permissionResource,
  setPending,
}: {
  resource: RoleResource | undefined
  permissionResource: PermissionResource
  setPending: (_permissionResource: PermissionResource, option: string) => void
}) {
  return (
    <Flex align="center">
      <Box flex={2}>
        <EditResourceTitle permissionResource={permissionResource} />
      </Box>
      <Flex flex={2}>
        <EditResourceMode
          resource={resource}
          permissionResource={permissionResource}
          setPending={setPending}
        />
      </Flex>
    </Flex>
  )
}

export function EditContentResource({
  resources,
  permissionResources,
  setPending,
}: {
  resources: RoleResource[]
  permissionResources: PermissionResource[]
  setPending: (_permissionResource: PermissionResource, option: string) => void
}) {
  return (
    <Stack>
      <TableHeader />
      {permissionResources.map((permissionResource) => {
        const resource = resources.find((r) => r.id === permissionResource.id)
        return (
          <Card key={permissionResource.id} paddingY={1} paddingX={4}>
            <EditResource
              resource={resource}
              permissionResource={permissionResource}
              setPending={setPending}
            />
          </Card>
        )
      })}
    </Stack>
  )
}
