import {useContext, createContext, useEffect, useState} from 'react'
import {ThemeProvider, ThemeColorProvider} from '@sanity/ui'
import {buildTheme} from '@sanity/ui/theme'

export type Scheme = 'light' | 'dark' | 'system'
export type ResolvedScheme = 'light' | 'dark'

interface Props {
  initialScheme?: Scheme
  children: React.ReactNode
}

interface ManageThemeContextType {
  scheme: Scheme
  resolvedScheme: ResolvedScheme
  setScheme: (scheme: Scheme) => void
}

const LOCAL_STORAGE_KEY = 'sanityManage:ui:colorScheme'

export const manageTheme = buildTheme({
  media: [420, 640, 960, 1280],
})

const ManageThemeContext = createContext<ManageThemeContextType>({
  scheme: 'system' as Scheme,
  resolvedScheme: 'light' as ResolvedScheme,
  setScheme: () => null,
})

const getSystemScheme = (callback?: (scheme: ResolvedScheme) => void): ResolvedScheme => {
  if (typeof window === 'undefined') return 'light'

  const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
  const handleChange = (e: MediaQueryListEvent) => {
    callback?.(e.matches ? 'dark' : 'light')
  }
  mediaQuery.addEventListener('change', handleChange)
  return mediaQuery.matches ? 'dark' : 'light'
}

export const useManageScheme = () => useContext(ManageThemeContext)

export const ManageSchemeProvider = ({initialScheme = 'system', children}: Props) => {
  const [scheme, setScheme] = useState<Scheme>(initialScheme)
  const [isMounted, setIsMounted] = useState(false)
  const [systemScheme, setSystemScheme] = useState<ResolvedScheme>(getSystemScheme())

  useEffect(() => {
    getSystemScheme(setSystemScheme)
  }, [])

  const resolvedScheme: ResolvedScheme = scheme === 'system' ? systemScheme : scheme

  useEffect(() => {
    const stored = localStorage.getItem(LOCAL_STORAGE_KEY)
    if (stored) {
      setScheme(stored as Scheme)
    } else {
      setScheme(scheme)
    }
    setIsMounted(true)
  }, [scheme])

  const handleSetScheme = (value: Scheme) => {
    localStorage.setItem(LOCAL_STORAGE_KEY, value)
    setScheme(value)
  }

  const contextValue = {
    scheme,
    resolvedScheme,
    setScheme: handleSetScheme,
  }

  return (
    <ManageThemeContext.Provider value={contextValue}>
      <ThemeProvider theme={manageTheme}>
        <ThemeColorProvider scheme={resolvedScheme} tone="primary">
          {isMounted && children}
        </ThemeColorProvider>
      </ThemeProvider>
    </ManageThemeContext.Provider>
  )
}
