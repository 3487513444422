import React, {useState, useCallback} from 'react'
import {Stack, Text} from '@sanity/ui'
import {useRouter} from 'next/router'
import {AddIcon} from '@sanity/icons'
import {TagView} from './'
import {TabHeader, TagsList, UpdateTagDialog, PermissionButtonProject, EmptyBlock} from '@/ui/index'
import {Project, Tab} from '@/types/index'
import {useTags} from '@/data/index'
import {useRequiredProjectPermissions} from '@/context/index'

type Props = {
  project: Project
  title?: string | React.ReactNode
  description?: string | React.ReactNode
}

export function Tags({title, description, referenceLink, project}: Props & Tab) {
  const canReadTags = useRequiredProjectPermissions([
    {permissionName: 'sanity.project.tags', grantName: 'read'},
  ])

  const [showCreateTagDialog, setCreateTagDialog] = useState(false)
  const handleNewTagDialogOpen = useCallback(() => setCreateTagDialog(true), [setCreateTagDialog])
  const handleNewTagDialogCLose = useCallback(() => setCreateTagDialog(false), [setCreateTagDialog])

  const {data: tags = [], isLoading, error} = useTags(project)

  const {
    query: {name},
  } = useRouter()
  if (name) {
    if (isLoading) {
      return null
    }

    const tag = tags.find((_tag) => _tag.name === name || _tag.previousName === name)
    if (!tag) {
      return <Text as="p">Tag not found</Text>
    }

    return <TagView project={project} tag={tag} />
  }

  if (!tags) {
    return null
  }

  return (
    <>
      <Stack space={4} data-title={title}>
        <TabHeader
          title="Tags"
          button={
            <PermissionButtonProject
              onClick={handleNewTagDialogOpen}
              title="New tag"
              icon={AddIcon}
              permissions={[{permissionName: 'sanity.project.tags', grantName: 'create'}]}
              disabled={isLoading}
            />
          }
          description={description}
          referenceLink={referenceLink}
        />

        {canReadTags && <TagsList loading={isLoading} error={error} tags={tags} />}
        {!canReadTags && (
          <EmptyBlock
            illustration="access-denied"
            title="Insufficient permissions"
            description="You don't have the right permissions to see tags details."
          />
        )}
      </Stack>
      {showCreateTagDialog && (
        <UpdateTagDialog project={project} closeDialog={handleNewTagDialogCLose} mode="create" />
      )}
    </>
  )
}
