import {type UserProfile} from '@sanity/access-api'
import {Flex} from '@sanity/ui'

import {UserAvatar, type UserAvatarProps} from '../../../generic/user-avatar'

interface MemberAvatarCellProps {
  displayName: UserProfile['displayName']
  imageUrl: UserProfile['imageUrl']
  provider: UserProfile['provider']
  size?: UserAvatarProps['size']
}

export function MemberAvatarCell(props: MemberAvatarCellProps) {
  const {displayName, provider, imageUrl, size = 2} = props

  return (
    <Flex align="center" flex={1} height="fill" justify="center">
      <UserAvatar
        displayName={displayName || ''}
        provider={provider}
        size={size}
        src={imageUrl || ''}
      />
    </Flex>
  )
}
