import React, {forwardRef, useImperativeHandle, useState, useCallback} from 'react'
import {Box, Container, Heading, Stack, Text, TextArea, TextInput, useToast} from '@sanity/ui'
import {useStripe, useElements} from '@stripe/react-stripe-js'
import {useForm} from 'react-hook-form'
import {FormField} from '../formField'
import {TabSectionHeader} from '../../tabs'
import {CountrySelect} from '../inputs/countrySelect'
import {EditContainer} from './editContainer'
import {emailPattern} from '@/utils/form'
import {CardForm, CardFormEvent} from '@/ui/index'
import {CustomerData, Organization} from '@/types/index'
import {useReCaptchaContext} from '@/ui/recaptcha/ReCaptchaContext'
import {ReCaptchaInfo} from '@/ui/recaptcha/ReCaptchaInfo'
import {useCreateOrg} from '@/data/organizations/useCreateOrg'

type Props = {
  hideCardForm?: boolean
  onChange?: (newOrg: Organization) => void
  onCreate?: (newOrg: Organization) => void
  noPrimaryButton?: boolean
}

type SubmitData = {
  orgName: string
} & CustomerData

const _CreateNewOrg = forwardRef((props: Props, ref) => {
  const {onCreate, hideCardForm} = props
  const toast = useToast()
  const stripe = useStripe()
  const elements = useElements()
  const {mutate: createOrg, isPending: isCreatingOrg} = useCreateOrg()

  const [hasEmptyCard, setHasEmptyCard] = useState<boolean>(true)
  const {
    register,
    handleSubmit,
    formState: {errors},
    trigger,
  } = useForm<SubmitData>()

  const {reCaptchaScript, enabled: recaptchaEnabled} = useReCaptchaContext()

  const onSubmit = async (data: SubmitData) => {
    if (!stripe || !elements) {
      toast.push({
        title: 'Error',
        description: 'Stripe is not initialized',
        status: 'error',
      })
      return
    }

    const {orgName, ...orgData} = data
    createOrg(
      {
        name: orgName,
        orgData,
        recaptchaEnabled,
        reCaptchaScript,
        hasEmptyCard,
        hideCardForm: hideCardForm || false,
      },
      {
        onSuccess: (createdOrg) => {
          toast.push({
            title: 'Success',
            description: 'Organization created',
            status: 'success',
          })
          onCreate?.(createdOrg)
        },
        onError: (error) => {
          toast.push({
            title: 'Error',
            description: error instanceof Error ? error.message : 'Unexpected error',
            status: 'error',
          })
        },
      }
    )
  }

  const submitHandler = handleSubmit(onSubmit)
  const handleForm = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    return submitHandler()
  }

  function handleChangeCardNumber(event: CardFormEvent) {
    setHasEmptyCard(event.empty)
  }

  const onChange = useCallback(
    (change: any) => {
      if (props.onChange) {
        props.onChange(change)
      }
    },
    [props]
  )

  useImperativeHandle(ref, () => ({
    validate() {
      return trigger()
    },
    submit: async () => {
      return submitHandler()
    },
  }))

  return (
    <Container width={1}>
      <Box>
        <Stack space={6}>
          <Stack space={[4, 4, 5]}>
            <Heading size={2} as="h2">
              Create new organization
            </Heading>
            <Text size={2} muted style={{maxWidth: 450}} as="p">
              Organizations organize projects and their members. An organization holds contact and
              billing information, and can have administrators, billing managers, and developers
              added to them.
            </Text>
          </Stack>
          <Stack space={5}>
            <TabSectionHeader title="Organization details" />

            <EditContainer
              onSave={handleForm}
              onChange={onChange}
              primaryButtonText="Create organization"
              noPrimaryButton={props.noPrimaryButton}
              loading={isCreatingOrg}
              noCancel
            >
              <Box paddingBottom={5}>
                <FormField
                  required
                  label="Organization name"
                  description="Name of the new organization to be created"
                  error={errors.orgName ? 'This field is required' : ''}
                >
                  <TextInput
                    autoFocus
                    disabled={isCreatingOrg}
                    padding={3}
                    autoComplete="organization"
                    {...register('orgName', {required: true})}
                  />
                </FormField>
              </Box>

              <TabSectionHeader title="Billing details" />
              <FormField
                required
                label="Legal name"
                error={errors.name ? 'This field is required' : ''}
              >
                <TextInput
                  disabled={isCreatingOrg}
                  padding={3}
                  autoComplete="name"
                  {...register('name', {required: true})}
                />
              </FormField>
              <FormField label="VAT number" description="Provide only if relevant">
                <TextInput disabled={isCreatingOrg} padding={3} {...register('vatId')} />
              </FormField>
              <FormField
                required
                label="Email"
                error={errors.email ? 'This field is required' : ''}
              >
                <TextInput
                  disabled={isCreatingOrg}
                  padding={3}
                  type="email"
                  inputMode="email"
                  {...register('email', {required: true, pattern: emailPattern})}
                />
              </FormField>
              <FormField label="Phone">
                <TextInput
                  disabled={isCreatingOrg}
                  padding={3}
                  type="tel"
                  inputMode="tel"
                  autoComplete="tel"
                  {...register('phone')}
                />
              </FormField>
              <FormField
                required
                label="Address"
                error={errors.address ? 'This field is required' : ''}
              >
                <TextArea
                  disabled={isCreatingOrg}
                  padding={3}
                  autoComplete="street-address"
                  {...register('address', {required: true})}
                />
              </FormField>
              <FormField
                required
                label="ZIP Code"
                error={errors.zip ? 'This field is required' : ''}
              >
                <TextInput
                  disabled={isCreatingOrg}
                  padding={3}
                  autoComplete="postal-code"
                  {...register('zip', {required: true})}
                />
              </FormField>
              <FormField required label="City" error={errors.city ? 'This field is required' : ''}>
                <TextInput
                  disabled={isCreatingOrg}
                  padding={3}
                  autoComplete="address-level2"
                  {...register('city', {required: true})}
                />
              </FormField>
              <Box paddingBottom={5}>
                <FormField
                  required
                  label="Country"
                  error={errors.country ? 'This field is required' : ''}
                >
                  <CountrySelect
                    disabled={isCreatingOrg}
                    resolveFromIp
                    value=""
                    {...register('country', {required: true})}
                  />
                </FormField>
              </Box>

              {!props.hideCardForm && (
                <>
                  <TabSectionHeader
                    title="Payment details"
                    description={
                      <>
                        All costs on your organization projects will be covered by this payment
                        source.
                        <br />
                        You can add this information later.
                      </>
                    }
                  />
                  <CardForm onChange={handleChangeCardNumber} />
                  <ReCaptchaInfo />
                </>
              )}
            </EditContainer>
          </Stack>
        </Stack>
      </Box>
    </Container>
  )
})

_CreateNewOrg.displayName = 'CreateNewOrg'
export const CreateNewOrg = _CreateNewOrg
