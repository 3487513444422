import React, {useMemo} from 'react'
import {
  useCurrentScopeContext,
  useRequiredOrganizationPermissions,
  useRequiredProjectPermissions,
  useRoutePath,
  useTabAccess,
} from '@/context/index'
import {EmptyBlock} from '@/ui/index'
import {useActiveHeading} from '@/utils/views'
import {Container} from '@sanity/ui'
import {DefaultTabLayout} from '.'
import {NotFound} from '..'
import {useHashSection} from './useHashSection'
import {TabsPermissionError, useActiveTab, useTabs} from '@/ui/views/tabs'
import {useLoadingState} from '@/context/LoadingProvider'
import {PageTabLoading} from './PageTabLoading'

export function PageTab() {
  const {tabId, headingId} = useRoutePath()
  const scope = useCurrentScopeContext()
  const {org, project, projectId} = scope

  const tabStatus = useTabs()
  const tabs = useMemo(() => tabStatus.tabs, [tabStatus.tabs])

  const activeTab = useMemo(
    () => (org?.id === 'personal' && tabId === 'projects' ? 'root' : tabId),
    [org?.id, tabId]
  )
  const tab = useActiveTab(activeTab || 'root', tabs)

  const heading = useActiveHeading(headingId || '', tab?.headings || [])
  const TabComponent = useMemo(
    () => (headingId ? heading?.component : tab?.component),
    [headingId, heading?.component, tab?.component]
  )

  const tabExists = useMemo(() => tabs?.some((t) => t.path === tabId), [tabs, tabId])

  const hasOrgPermission = useRequiredOrganizationPermissions(tab?.permissions)
  const hasProjectPermission = useRequiredProjectPermissions(tab?.permissions)
  const hasPermission = useMemo(
    () => (projectId ? hasOrgPermission || hasProjectPermission : hasOrgPermission),
    [projectId, hasProjectPermission, hasOrgPermission]
  )

  const hasAccess = useTabAccess(tab)
  const hashData = useHashSection({tab})
  const tabData = useMemo(() => (headingId ? heading : tab), [headingId, heading, tab])

  const loadingState = useLoadingState()
  const isLoading = useMemo(
    () => loadingState === 'loading' || tabStatus.isLoading,
    [loadingState, tabStatus.isLoading]
  )
  const isLoaded = useMemo(
    () => !isLoading && !tabStatus.isLoading,
    [isLoading, tabStatus.isLoading]
  )
  const tabNotFound = useMemo(
    () => (!tabExists && !tab && !isLoading) || tab?.disableTabComponentRender,
    [tabExists, tab, isLoading]
  )

  const loadingLayoutType = useMemo(() => {
    if (!activeTab || activeTab === 'root') {
      return 'overview'
    }
    return 'sidebar'
  }, [activeTab])

  if (isLoading) {
    return <PageTabLoading layoutType={loadingLayoutType} />
  }

  const hasProjectReadError = tabStatus.error instanceof TabsPermissionError

  // If finished loading both content and permissions, potentially show permission error
  if (isLoaded && (!hasPermission || !hasAccess || hasProjectReadError)) {
    return (
      <DefaultTabLayout
        tabs={tabs}
        hideSidebar={!hasPermission || !tabExists || !hasAccess || hasProjectReadError}
        activeTab={tab}
        activeHeadingPath={headingId || hashData.activeHeadingPath}
        activeSectionPath={hashData.activeSectionPath}
      >
        <Container>
          <EmptyBlock
            illustration="access-denied"
            title="Insufficient permissions"
            description="You don't have the right permissions to see this content."
          />
        </Container>
      </DefaultTabLayout>
    )
  }

  if (isLoaded && tabNotFound) {
    return (
      <DefaultTabLayout
        tabs={tabs}
        hideSidebar={!hasPermission || !tabExists || !hasAccess}
        activeTab={tab}
        activeHeadingPath={headingId || hashData.activeHeadingPath}
        activeSectionPath={hashData.activeSectionPath}
      >
        <NotFound />
      </DefaultTabLayout>
    )
  }

  return (
    <DefaultTabLayout
      tabs={tabs}
      hideSidebar={!hasPermission || !tabExists || !hasAccess}
      activeTab={tab}
      activeHeadingPath={headingId || hashData.activeHeadingPath}
      activeSectionPath={hashData.activeSectionPath}
    >
      {TabComponent && (
        <TabComponent
          title={tabData?.title}
          description={tabData?.description}
          referenceLink={tabData?.referenceLink}
          referenceLinkComponent={tabData?.referenceLinkComponent}
          org={org}
          tab={tab}
          project={project}
        />
      )}
    </DefaultTabLayout>
  )
}
