import {Grid, Card, Text, Badge, Stack, Inline} from '@sanity/ui'
import Link from 'next/link'
import styled from 'styled-components'
import type {RelatedResource} from '@/data/index'
import {sendAmplitudeTrackingEvent} from '@/utils/tracking'
import {useCurrentScopeContext} from '@/context/useCurrentScopeContext'

interface ResourceCardsProps {
  resources?: RelatedResource[]
  largeCardCount?: number
}

const StyledCard = styled(Card)<{largeCardCount: number}>`
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: ${({theme}) => theme.sanity.v2?.color.button.bleed.default.hovered.bg};
  }

  @media (min-width: ${({theme}) => theme.sanity.v2?.media[2]}px) {
    &:nth-child(-n + ${(props) => props.largeCardCount}) {
      grid-column: span 2;
    }
  }
`

export const ResourceCards = ({resources, largeCardCount = 2}: ResourceCardsProps) => {
  const {projectId, org} = useCurrentScopeContext()
  const trackClick = (link: string) => {
    sendAmplitudeTrackingEvent('Getting Started Resource Clicked', projectId, org?.id, {
      resource_url: link,
      resource_type: 'developer',
    })
  }
  return (
    <Grid gap={3} columns={[1, 2, 2, 4]}>
      {resources?.map((resource, index) => (
        <StyledCard
          key={index}
          largeCardCount={largeCardCount}
          href={resource?.url || '#'}
          target="_blank"
          forwardedAs={Link}
          padding={5}
          radius={4}
          border={Boolean(index < largeCardCount)}
          onClick={() => trackClick(resource?.url || '#')}
        >
          <Stack space={4}>
            {resource.label && (
              <Inline>
                <Badge tone={index < largeCardCount ? 'primary' : 'default'}>
                  {resource.label}
                </Badge>
              </Inline>
            )}
            {resource.title && <Text weight="bold">{resource.title}</Text>}

            {resource.title && (
              <Text size={1} muted>
                {resource.description}
              </Text>
            )}
          </Stack>
        </StyledCard>
      ))}
    </Grid>
  )
}
