import {useMutation, useQueryClient} from '@tanstack/react-query'
import {createDataset} from '.'
import {Dataset, type DatasetAclMode} from '@/types/models'
import {getDatasetsListKey} from './cache'
import {isAPIError} from '../util/promiseRequest'

type InvalidDataError = Error & {name: 'InvalidDataError'}
type PaymentRequiredError = Error & {name: 'PaymentRequiredError'}

export function useCreateDataset(projectId: string) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['createDataset', projectId],
    mutationFn: ({name, aclMode}: {name: string; aclMode: DatasetAclMode}) =>
      createDataset(projectId, name, {aclMode}),
    onMutate: async ({name, aclMode}) => {
      await queryClient.cancelQueries({queryKey: getDatasetsListKey(projectId)})

      const previousDatasets = queryClient.getQueryData<Dataset[]>(getDatasetsListKey(projectId))
      queryClient.setQueryData<Dataset[]>(getDatasetsListKey(projectId), (old) => [
        ...(old || []),
        {name, aclMode},
      ])

      return {previousDatasets}
    },
    onError: (err, _variables, context) => {
      queryClient.setQueryData(getDatasetsListKey(projectId), context?.previousDatasets)
      if (isAPIError(err)) {
        if (err.statusCode === 400) {
          const nError = new Error('Invalid aclMode') as InvalidDataError
          nError.name = 'InvalidDataError'
          throw nError
        }
        if (err.statusCode === 402) {
          const nError = new Error(err.message) as PaymentRequiredError
          nError.name = 'PaymentRequiredError'
          throw nError
        }

        throw new Error('UnknownError')
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({queryKey: getDatasetsListKey(projectId)})
    },
  })
}
