import type {SwitchPlanState} from './store/types'

export const SINGLE_PAGE_CHECKOUT_FEATURE = 'manage-single-page-checkout-flow'

export const INITIAL_STATE: SwitchPlanState = {
  open: false,
  plan: undefined,
  couponCode: undefined,
}

export const FORM_ERRORS = {
  required: 'Required',
  invalid: 'Invalid value',
  paymentSource: 'Could not create a payment source. Please try again.',
  confirmCard: 'Could not confirm your card. Please contact support.',
  cardDeclined:
    'There seems to be an issue with your payment card. Please re-enter the card details or replace with another payment card. If the problem persists, check there are sufficient funds or contact the card issuer.',
  projectMoved: 'Your project has still been moved to your organization.',
  generic: 'An error occurred.',
}
