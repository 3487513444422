import React, {useCallback} from 'react'
import {useProjectData} from '@/data/projects/useProjectData'
import {Project, DialogToast} from '@/types/index'

type Props = {
  project: Project
  Dialog: React.ElementType
  hideDialog: (dialogToast: DialogToast) => void
}

export const UnarchiveProjectDialog = ({project, hideDialog, Dialog}: Props) => {
  const {isLoading, patchProjectData} = useProjectData(project)
  const displayName = project?.displayName

  const confirmActivate = useCallback(() => {
    patchProjectData(
      {isDisabledByUser: false},
      {
        onSuccess: () => {
          hideDialog({title: 'Project activated', status: 'success'})
        },
        onError: () => {
          hideDialog({description: 'Could not activate project', status: 'error'})
        },
      }
    )
  }, [patchProjectData, hideDialog])

  return (
    <Dialog
      header="Activate Project"
      title={`Do you really want to activate ${displayName}?`}
      description="Activating a project makes it accessible again."
      id="unarchive-project-dialog"
      onConfirm={confirmActivate}
      confirmButtonProps={{text: 'Activate', tone: 'caution'}}
      loading={isLoading}
    />
  )
}
