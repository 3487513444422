import React, {useMemo, useState} from 'react'
import {Card} from '@sanity/ui'
import {OrgBillingChartHeader} from './header'
import {ColumnChart, ChartSummaryItem, ChartSummaryList} from '@/ui/index'
import {useCurrentScopeContext, useUsageContext} from '@/context/index'
import {ApexAxisChartSerie, ProjectUsage} from '@/types/index'
import {getCompareSeriesForBilling} from '@/utils/index'
import {useProjectsUsage} from '@/data/projects'

export function OrgBillingChart() {
  const {org} = useCurrentScopeContext()
  const {billingRange} = useUsageContext()
  const billingUsage = useProjectsUsage(org?.projects, {
    ...billingRange,
    period: {history: 'month', snapshot: 'year'},
  })
  const billingLoading = billingUsage.some((usage) => usage.isLoading)
  const [activeDataSeriesName, setActiveDataSeriesName] = useState<string | undefined>()

  const series: ApexAxisChartSerie[] = useMemo(() => {
    const projects: ProjectUsage[] = billingUsage
      .map(({data}) => data)
      .filter(Boolean) as ProjectUsage[]

    return getCompareSeriesForBilling({
      projects: projects || [],
      currentRange: billingRange,
      mode: 'month',
    })
  }, [billingRange, billingUsage])
  const summaryList: ChartSummaryItem[] = series.map((serie, index) => {
    const {name, color, total} = serie
    return {
      id: name!,
      value: total || 0,
      label: name!,
      unit: 'number',
      color,
      sortOrder: index + 1,
    }
  })

  const handleHoverSummaryItem = (hoveredId: string | undefined, summaryItem: ChartSummaryItem) => {
    setActiveDataSeriesName(hoveredId ? summaryItem.label : undefined)
  }

  return (
    <Card padding={4} radius={2} border>
      <OrgBillingChartHeader />
      <ColumnChart
        dataSeries={series}
        stacked
        chartType="bar"
        usageType="overage"
        isLoading={billingLoading}
        isCompareMode={false}
        period="month"
        activeDataSeriesName={activeDataSeriesName}
        emptyPlaceholderMessage="No overage yet"
        yAxisFormat="currency"
      />
      <ChartSummaryList
        items={summaryList}
        loading={billingLoading}
        valueFormat="currency"
        onHover={handleHoverSummaryItem}
        showValue={false}
      />
    </Card>
  )
}
