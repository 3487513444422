import {type AddOnCopy} from '@/components/project/plan/addonCopyConfig'
import {PRIVATE_DATASET_FEATURE} from '@/data/datasets'
import {Plan, Project, ProjectMemberRole} from '@/types/models'

export interface Overage {
  id: string
  description: React.ReactNode
  link?: [string, string]
  exceededByAmount?: number
  unblockedByAddonId?: string
}

export interface SuggestedAddon extends AddOnCopy {
  count: number
  totalPrice: number
  suggestReason: `overage-${string}` | 'upsell'
}

export interface OverageData {
  overages: Overage[]
  blockingOverages: Overage[]
  suggestedAddons: SuggestedAddon[]
}

export function getDatasetLimits(plan: Plan | undefined) {
  const datasetQuota = plan?.resources?.datasets?.quota ?? Number.POSITIVE_INFINITY
  const privateDatasetAllowed =
    plan?.featureTypes?.accesscontrol?.features?.some(({attributes}) => {
      return attributes && attributes[PRIVATE_DATASET_FEATURE] === true
    }) ?? false

  return {
    datasetQuota,
    privateDatasetAllowed,
  }
}

export function getNotAllowedProjectMembers(
  plan: Plan | undefined,
  projectMembers: Project['members'] | undefined
) {
  const rolesToFilterOut = ['administrator']
  const robotRolesToFilterOut = ['deploy-studio', 'editor']
  const roleMap = projectMembers?.flatMap((member) =>
    member.roles.filter(
      (role) =>
        !(
          rolesToFilterOut.includes(role.name) ||
          (member.isRobot && robotRolesToFilterOut.includes(role.name))
        )
    )
  )

  const accessControlFeatures = plan?.featureTypes?.accesscontrol?.features?.map((f) => f.variantId)

  let notAllowedRoles: ProjectMemberRole[] = []
  if (roleMap && accessControlFeatures) {
    notAllowedRoles = roleMap.filter((role) => !accessControlFeatures.includes(`role-${role.name}`))
  }

  return [...new Set(notAllowedRoles.map((role) => role.title))]
}
