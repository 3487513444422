import React from 'react'
import {
  PublishIcon,
  AddIcon,
  DocumentIcon,
  BookIcon,
  CogIcon,
  AccessDeniedIcon,
  EditIcon,
  WarningOutlineIcon,
} from '@sanity/icons'
import {BadgeTone} from '@sanity/ui'

export function modeToIcon(mode: string) {
  switch (mode) {
    case 'publish':
      return <PublishIcon />
    case 'create':
      return <AddIcon />
    case 'update':
      return <AddIcon />
    case 'read':
      return <DocumentIcon />
    case 'history':
      return <BookIcon />
    case 'editHistory':
      return <EditIcon />
    case 'manage':
      return <CogIcon />
    case 'no access':
      return <AccessDeniedIcon />
    case 'unknown':
      return <WarningOutlineIcon />
    default:
      return null
  }
}

export function modeToDescription(mode: string) {
  switch (mode) {
    case 'publish':
      return 'Can create, read, edit, delete, and publish/unpublish the resource'
    case 'create':
      return 'Can create, read, and edit the resource'
    case 'update':
      return 'Can read and edit the resource'
    case 'read':
      return 'Can read the resource'
    case 'history':
      return 'Can read previous versions of the resource'
    case 'editHistory':
      return 'Can edit the history of the resource'
    case 'manage':
      return 'Can update system documents'
    case 'no access':
      return 'No access to the resource'
    case 'unknown':
      return 'Unable to fully determine permission because they have been modified externally.'
    default:
      return null
  }
}

export function modeToTone(mode: string): BadgeTone | undefined {
  switch (mode) {
    case 'publish':
    case 'update':
      return 'positive'
    case 'unknown':
    case 'manage':
    case 'editHistory':
      return 'caution'
    case 'create':
    case 'read':
    case 'history':
      return 'primary'
    case 'no access':
      return 'default'
    default:
      return undefined
  }
}

export function modeToTitle(mode: string): string {
  switch (mode) {
    case 'create':
      return 'Update and create'
    default:
      return mode.substr(0, 1).toUpperCase() + mode.substr(1)
  }
}
