export function getPlanFeaturesKey() {
  return ['planFeatures']
}

export function getPlanByIdKey(id: string) {
  return ['plans', id]
}

export function getOrganizationPlansKey() {
  return ['organizationPlans']
}

export function getDefaultPlansKey() {
  return ['plans', 'default']
}

export function getPreviewPlanKey(planId: string, options?: object) {
  return ['plans', 'preview', planId, options]
}
