import {Stack, Text} from '@sanity/ui'
import {motion} from 'framer-motion'
import {type PropsWithChildren} from 'react'

const MotionStack = motion(Stack)

export interface FilterSectionProps extends PropsWithChildren<{title: string}> {}

export const FilterSection = (props: FilterSectionProps) => {
  const {title, children} = props

  return (
    <MotionStack
      animate="animate"
      exit="exit"
      flex={1}
      initial="initial"
      space={3}
      style={{minWidth: 200}}
      variants={{
        initial: {opacity: 0.5},
        animate: {opacity: 1},
        exit: {opacity: 0},
      }}
      key={title}
    >
      <Text size={1} weight="medium">
        {title}
      </Text>
      {children}
    </MotionStack>
  )
}
