import type {PortableTextComponentProps} from '@portabletext/react'
import {Stack} from '@sanity/ui'
import styled from 'styled-components'

const StyledStack = styled(Stack)`
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
`

export function BlockList(props: PortableTextComponentProps<any>) {
  const {children} = props

  return (
    <StyledStack forwardedAs="ul" space={1} marginLeft={4} marginTop={3} marginBottom={6}>
      {children}
    </StyledStack>
  )
}
