import {useMutation} from '@tanstack/react-query'
import {deleteOrg} from '.'
import {APIError} from '@/data/util/request'

type DeletionError = Error & {name: 'DeletionError'}

export function useDeleteOrg(orgId: string) {
  const {mutate: deleteOrganization, ...rest} = useMutation({
    mutationKey: ['deleteOrg', orgId],
    mutationFn: () => deleteOrg(orgId),
    onError: (error, _variables) => {
      if (error instanceof APIError && error.statusCode >= 400 && error.statusCode < 500) {
        const deletionError = new Error(error.message) as DeletionError
        deletionError.name = 'DeletionError'
        throw deletionError
      }
      console.error('Failed to delete organization:', error)
    },
  })

  return {
    deleteOrganization,
    ...rest,
  }
}
