import {useQueryClient} from '@tanstack/react-query'
import {getProjectBaseKey, getProjectsKey} from '../projects/cache'
import {getOrganizationCanPayKey, getOrganizationsKey} from '../organizations/cache'
import {getDatasetsListKey} from '../datasets/cache'
import {useCallback} from 'react'
import {getOrgInvitationsCacheKey, getProjectInvitationsCacheKey} from '../invitations/cache'
import {getUserOnboardingBaseCacheKey} from '../journey/cache'
import {getCustomerKey} from '../customers/cache'

export function useCacheInvalidation() {
  const queryClient = useQueryClient()

  const invalidateDatasets = useCallback(
    (projectId: string) => {
      queryClient.invalidateQueries({queryKey: getDatasetsListKey(projectId)})
    },
    [queryClient]
  )

  const invalidateOrgInvitations = useCallback(
    (orgId: string) => {
      queryClient.invalidateQueries({queryKey: getOrgInvitationsCacheKey(orgId)})
    },
    [queryClient]
  )

  const invalidateProjectInvitations = useCallback(
    (projectId: string) => {
      queryClient.invalidateQueries({queryKey: getProjectInvitationsCacheKey(projectId)})
    },
    [queryClient]
  )

  const invalidateAllProjects = useCallback(() => {
    queryClient.invalidateQueries({queryKey: getProjectsKey()})
  }, [queryClient])

  const invalidateProject = useCallback(
    (projectId: string, options?: {invalidateAllProjects?: boolean}) => {
      if (options?.invalidateAllProjects ?? true) {
        invalidateAllProjects()
      }
      queryClient.invalidateQueries({queryKey: getProjectBaseKey(projectId)})
    },
    [invalidateAllProjects, queryClient]
  )

  const invalidateAllOrganizations = useCallback(() => {
    queryClient.invalidateQueries({queryKey: getOrganizationsKey()})
  }, [queryClient])

  const invalidateOrganization = useCallback(
    (_orgId: string) => {
      queryClient.invalidateQueries({queryKey: getOrganizationsKey()})
    },
    [queryClient]
  )

  const invalidateUserOnboardingData = useCallback(() => {
    queryClient.invalidateQueries({queryKey: getUserOnboardingBaseCacheKey()})
  }, [queryClient])

  const invalidateCustomerData = useCallback(
    (orgId: string) => {
      queryClient.invalidateQueries({queryKey: getOrganizationCanPayKey(orgId)})
      queryClient.invalidateQueries({queryKey: getCustomerKey(orgId)})
    },
    [queryClient]
  )

  return {
    invalidateAllProjects,
    invalidateProject,
    invalidateCustomerData,
    invalidateAllOrganizations,
    invalidateOrganization,
    invalidateDatasets,
    invalidateOrgInvitations,
    invalidateProjectInvitations,
    invalidateUserOnboardingData,
  }
}
