import {ProjectInvitation, OrganizationInvitation} from '../../types'
import {useQuery} from '@tanstack/react-query'
import {getInvitation} from './controller'

export function useInvitation(invitationToken: string) {
  return useQuery<ProjectInvitation | OrganizationInvitation, Error>({
    queryKey: ['invitation', invitationToken],
    queryFn: () => getInvitation(invitationToken),
    enabled: !!invitationToken,
  })
}
