import {useMutation, useQueryClient} from '@tanstack/react-query'
import {deleteUserFromProject} from '.'
import {getProjectBaseKey} from '../projects/cache'
import {isAPIError} from '../util/promiseRequest'

type DeletionError = Error & {name: 'DeletionError'}

export function useDeleteUserFromProject() {
  const queryClient = useQueryClient()
  const deleteUserMutation = useMutation({
    mutationFn: ({projectId, userId}: {projectId: string; userId: string}) =>
      deleteUserFromProject(projectId, userId),
    onError: (error: any) => {
      if (isAPIError(error) && error.statusCode >= 400 && error.statusCode < 500) {
        const deletionError = new Error(error.message) as DeletionError
        deletionError.name = 'DeletionError'
        return deletionError
      }
      return error
    },
    onSettled: (_, __, {projectId}) => {
      queryClient.invalidateQueries({queryKey: getProjectBaseKey(projectId)})
    },
  })

  return {
    deleteUser: deleteUserMutation.mutate,
    isDeleting: deleteUserMutation.isPending,
    isDeleted: deleteUserMutation.isSuccess,
    error: deleteUserMutation.error as DeletionError | null,
  }
}
