import React from 'react'
import {Box, Card, Flex, Container, Text} from '@sanity/ui'
import styled from 'styled-components'
import {ArrowRightIcon, RocketIcon} from '@sanity/icons'
import {useMatchesMedia} from '@/utils/index'

const BannerCard = styled(Card)`
  background: #132038;
  width: 100%;
  & * {
    color: #d2e3fe;
  }
`

const TextLink = styled(Text)`
  text-decoration: none;
  a {
    font-size: inherit;
    color: #d2e3fe;
  }
`

export function PageBanner() {
  const isMobile = useMatchesMedia(1)

  return (
    <BannerCard padding={3} sizing="border">
      <Container style={{maxWidth: 1235}}>
        <Flex
          align={['flex-start', 'flex-start', 'center']}
          justify="space-between"
          direction={['column', 'column', 'row']}
        >
          <Flex
            align="center"
            justify={['space-between', 'space-between', 'flex-start']}
            style={isMobile ? {width: '100%'} : {}}
          >
            <Text size={1} weight="semibold">
              <Flex align="center">
                <Box marginRight={2}>
                  <RocketIcon />
                </Box>
                Early Access
              </Flex>
            </Text>
            <Box marginLeft={[0, 0, 5]}>
              <Text size={1} style={{color: '#4E91FC'}}>
                <Flex align="center">
                  <a
                    href="https://www.sanity.io/blog/early-access-manage"
                    style={{color: 'inherit'}}
                  >
                    Read blog post
                  </a>
                  <Box marginLeft={2}>
                    <ArrowRightIcon />
                  </Box>
                </Flex>
              </Text>
            </Box>
          </Flex>
          {!isMobile && (
            <Box>
              <TextLink size={1} weight="semibold">
                <a href={`https://manage.${process.env.host}`}>Switch to old version</a>
              </TextLink>
            </Box>
          )}
        </Flex>
      </Container>
    </BannerCard>
  )
}
