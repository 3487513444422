import React from 'react'
import Link from 'next/link'
import {Box, Card, Flex, Text, Inline, Stack, Badge, BadgeTone, useTheme} from '@sanity/ui'
import {DatabaseIcon, AddCircleIcon} from '@sanity/icons'

import {useRoutePath} from '@/context/index'
import {TagIcon, TreeStack} from '@/ui/index'
import {Dataset, DatasetAclMode, Project, Tag} from '@/types/index'
import {useMatchesMedia} from '@/utils/index'

const DATASET_TONE: {[key in DatasetAclMode]: BadgeTone} = {
  public: 'positive',
  private: 'caution',
  custom: 'default',
}

type Props = {
  project: Project
  dataset: Dataset
  tags: Tag[]
  addons: Record<string, Dataset[]>
  indent: number
}

function Icon({isAddon}: {isAddon: boolean}) {
  const theme = useTheme()
  const green = theme.sanity.color.spot.green
  const fgColor = theme.sanity.color.base.bg

  if (!isAddon) {
    return <DatabaseIcon />
  }

  return (
    <div style={{position: 'relative'}}>
      <DatabaseIcon />
      <span style={{position: 'absolute', bottom: -4, right: -4}}>
        <AddCircleIcon width={18} height={18} fill={green} style={{color: fgColor}} />
      </span>
    </div>
  )
}

export function DatasetItem(props: Props) {
  const {basePath} = useRoutePath()

  const {dataset, project, tags} = props
  const datasetTags = dataset.tags || []
  const isMobile = useMatchesMedia(1)
  const addons = props.addons[dataset.name] || []

  const tagsHasRequiredFeature = project.features.some(
    (feature) => feature === 'advancedRolesManagement'
  )

  return (
    <TreeStack $showTree={props.indent > 0} $isMobile={isMobile} space={2}>
      <Box style={{marginLeft: props.indent * (isMobile ? 16 : 32)}}>
        <Link href={`${basePath}/datasets?name=${dataset.name}`}>
          <Card padding={[3, 3, 4, 4]} border radius={3}>
            <Flex align="center" wrap="wrap">
              <Flex align="center" flex={isMobile ? 1 : 2} marginRight={3}>
                <Box marginRight={3}>
                  <Text>
                    <Icon isAddon={Boolean(dataset.addonFor)} />
                  </Text>
                </Box>
                <Stack space={2}>
                  <Text
                    weight="semibold"
                    size={[1, 1, 1, 2]}
                    style={{whiteSpace: 'nowrap'}}
                    textOverflow="ellipsis"
                  >
                    {dataset.name}
                  </Text>
                  {addons.length > 0 ? (
                    <Text muted size={1}>
                      {addons.length} add-on datasets
                    </Text>
                  ) : null}
                  {addons.length === 0 && Boolean(dataset.addonFor) ? (
                    <Text muted size={1}>
                      Add-on dataset
                    </Text>
                  ) : null}
                </Stack>
              </Flex>
              <Flex wrap="wrap" flex={1} gap={2}>
                <Box flex={1}>
                  <Badge
                    mode="outline"
                    size={1}
                    tone={dataset.aclMode && DATASET_TONE[dataset.aclMode]}
                    fontSize={1}
                  >
                    {dataset.aclMode}
                  </Badge>
                </Box>
                <Box flex={1}>
                  {dataset.datasetProfile && dataset.datasetProfile !== 'content' ? (
                    <Badge mode="outline" size={1} tone="default" fontSize={1}>
                      {dataset.datasetProfile}
                    </Badge>
                  ) : null}
                </Box>
              </Flex>
              {tagsHasRequiredFeature && (
                <Inline space={1} flex={1}>
                  {datasetTags.map((tag) => {
                    const tone =
                      tags.find((t) => t.name === tag.name)?.metadata?.tone || 'transparent'
                    return (
                      <Card key={tag.name} tone={tone} padding={2} radius={2}>
                        <Flex align="center">
                          <Box marginRight={2}>
                            <Text size={1}>
                              <TagIcon />
                            </Text>
                          </Box>
                          <Text size={1}>{tag.title}</Text>
                        </Flex>
                      </Card>
                    )
                  })}
                </Inline>
              )}
            </Flex>
          </Card>
        </Link>
      </Box>
      {addons.map((addon) => (
        <DatasetItem
          key={addon.name}
          dataset={addon}
          project={project}
          tags={tags}
          addons={props.addons}
          indent={props.indent + 1}
        />
      ))}
    </TreeStack>
  )
}
