/* eslint-disable no-shadow */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-unassigned-import */
import React, {useMemo, useRef, useEffect, useState} from 'react'
import {TextInput, Select, TextArea, Inline, Button, Box, Grid, Heading} from '@sanity/ui'
import {Controller, useForm} from 'react-hook-form'
import {EyeOpenIcon, TrashIcon} from '@sanity/icons'
import {isEqual} from 'lodash'
import styled from 'styled-components'
import {CheckboxInput, FormField} from '../'
import {EditContainer} from './.'
import {Dataset, DocumentWebhook, DocumentWebhookFields, TableHeader} from '@/types/index'
import {Table, TableBody, TableCell, TableRow} from '@/ui/index'
import {JSONInput} from '@/ui/index'

interface Props {
  initialValue?: DocumentWebhookFields
  onSave: (a: DocumentWebhookFields) => void
  datasets: Dataset[]
  /** List of existing webhooks. Used for validating that the provided `name` is unique. */
  existingWebhookNames: string[]
  loading?: boolean
  /** This is invoked every time a field changes. */
  onChange?: (fields: DocumentWebhookFields) => void
  /** True when the form is used in share-mode. */
  shareMode?: boolean

  /** An element that is placed _inside_ the form before all fields. */
  before?: JSX.Element

  /** An element that is placed _inside_ the form after all fields. */
  after?: JSX.Element

  /** Disables the Save-button. */
  noPrimaryButton?: boolean
}

export type Webhooks2InputProps = Props

const urlValidator = /^https?:\/\//i
const secretValidator = /^\S+$/

const DEFAULT_HTTP_METHODS = ['POST', 'PUT', 'PATCH', 'DELETE', 'GET']
const DEFAULT_API_VERSIONS = ['v2021-03-25']

const tableConfig: TableHeader[] = [
  {
    id: 'name',
    label: 'Name',
    columnWidth: '200px',
  },
  {
    id: 'value',
    label: 'Value',
    columnWidth: '1fr',
  },
  {
    id: 'action',
    label: '',
    alignment: 'right',
    columnWidth: '50px',
  },
]

/** HeaderList is a list of header lines which is easier to deal with inside React. */
type HeaderList = HeaderLine[]
type HeaderLine = {name: string; value: string}

function toHeaderList(headers: DocumentWebhook['headers']): HeaderList {
  if (!headers) return []
  return Object.entries(headers).map(([name, value]) => ({name, value}))
}

function fromHeaderList(headers: HeaderList): DocumentWebhook['headers'] {
  return Object.fromEntries(headers.map(({name, value}) => [name, value]))
}

type FormData = Pick<
  DocumentWebhook,
  | 'name'
  | 'description'
  | 'dataset'
  | 'url'
  | 'httpMethod'
  | 'apiVersion'
  | 'includeDrafts'
  | 'secret'
> & {
  headerList: HeaderList
  operations: Array<'create' | 'update' | 'delete'>
  filter: string
  projection: string
  enabled: boolean
}

export function Webhooks2Input({
  initialValue,
  onSave,
  datasets,
  existingWebhookNames,
  loading,
  onChange,
  shareMode = false,
  noPrimaryButton,
  before,
  after,
}: Props) {
  const defaultValues: FormData | undefined = useMemo(() => {
    if (initialValue) {
      const {headers, rule, isDisabledByUser, ...values} = initialValue

      return {
        ...values,
        headerList: toHeaderList(headers),
        operations: rule ? rule.on : ['create'],
        filter: rule?.filter || '',
        projection: rule?.projection || '',
        enabled: !isDisabledByUser,
      }
    }

    return undefined
  }, [initialValue])

  const {
    register,
    control,
    handleSubmit,
    formState: {errors},
    watch,
  } = useForm<FormData>({
    defaultValues,
    // Omit valus that arent registered
    shouldUnregister: true,
  })

  // If we _don't_ have a secret yet then we default to showing the field.
  const [showSecret, setShowSecret] = useState(!defaultValues?.secret)

  const convert = (data: FormData): DocumentWebhookFields => {
    const {
      headerList,
      operations,
      filter,
      projection,
      description,
      enabled,
      secret = defaultValues?.secret,
      ...rest
    } = data

    const rule: DocumentWebhook['rule'] = {on: operations}

    if (filter.trim()) rule.filter = filter
    if (projection.trim()) rule.projection = projection

    return {
      ...rest,
      rule,
      description: description?.trim() ? description : null,
      headers: fromHeaderList(headerList),
      secret: secret?.trim() ? secret : null,
      isDisabledByUser: !enabled,
    }
  }

  const onSubmit = (data: FormData) => {
    onSave(convert(data))
  }

  // watch() supports a callback in the latest version of react-hook-form,
  // but we haven't updated yet. We're working around this with a custom useEffect.

  // We also use a reference to detect when the fields have _actually_ changed.
  const changedData = useRef<FormData>()
  const data = watch()

  useEffect(() => {
    // The first time the component is rendered we haven't set up any fields and `data` returns an empty object.
    const hasData = Object.keys(data).length > 0
    if (onChange && hasData) {
      const hasChanged = !changedData.current || !isEqual(changedData.current, data)
      if (hasChanged) {
        changedData.current = data
        onChange(convert(data))
      }
    }
  }, [data, onChange])

  return (
    <EditContainer
      onSave={handleSubmit(onSubmit)}
      loading={loading}
      noCancel
      noPrimaryButton={noPrimaryButton}
    >
      {before}

      <FormField
        label="Name"
        description="Identifying label for this webhook"
        error={errors?.name?.message}
        required
      >
        <TextInput
          radius={2}
          padding={4}
          {...register('name', {
            required: 'A name is required',
            validate: (v) =>
              existingWebhookNames.includes(v) ? 'A webhook with this name already exists' : true,
          })}
        />
      </FormField>

      <FormField
        label="Description"
        error={errors?.description?.message}
        description="Describe what this webhook does and what it is used for."
      >
        <TextArea radius={2} padding={4} {...register('description')} rows={5} />
      </FormField>

      <FormField
        label="URL"
        description="Add the URL that should receive messages from the webhook."
        error={errors?.url && 'A valid URL is required'}
        required
      >
        <TextInput
          radius={2}
          padding={4}
          placeholder="http[s]://hostname:[port]/path"
          {...register('url', {
            required: 'A URL is required',
            pattern: {value: urlValidator, message: 'A valid URL is required'},
          })}
        />
      </FormField>

      {!shareMode && (
        <FormField
          label="Dataset"
          description="Select which dataset(s) the webhook should be applied to."
          error={errors?.dataset?.message}
        >
          {datasets.length > 0 && (
            <Select radius={2} padding={4} {...register('dataset')} defaultValue="*">
              <optgroup label="Datasets">
                <>
                  <option key="*" value="*">
                    * (all datasets)
                  </option>
                  {datasets.map((d) => (
                    <option key={d.name} value={d.name}>
                      {d.name}
                    </option>
                  ))}
                </>
              </optgroup>
            </Select>
          )}
        </FormField>
      )}

      <FormField
        label="Trigger on"
        description="Select the document actions that trigger this webhook."
      >
        <Controller
          control={control}
          name="operations"
          defaultValue={['create']}
          // eslint-disable-next-line react/jsx-handler-names
          render={({field}) => <OperationInput value={field.value} onChange={field.onChange} />}
        />
      </FormField>

      <Grid columns={[1, 1, 1, 2]} gap={4}>
        <FormField
          label="Filter"
          description="Filter documents the webhook will trigger on with GROQ"
          error={errors?.filter?.message}
        >
          <Controller
            control={control}
            name="filter"
            defaultValue={''}
            render={({field}) => (
              <JSONInput
                value={field.value}
                // eslint-disable-next-line react/jsx-handler-names
                onChange={field.onChange}
                placeholder="Example: _type == 'post'"
                padding={4}
                autoHeight
              />
            )}
          />
        </FormField>

        <FormField
          label="Projection"
          description="Customise payloads with GROQ projections"
          error={errors?.projection?.message}
        >
          <Controller
            control={control}
            name="projection"
            defaultValue={''}
            render={({field}) => (
              <JSONInput
                value={field.value}
                // eslint-disable-next-line react/jsx-handler-names
                onChange={field.onChange}
                placeholder="Example: {_id, name}"
                padding={4}
                autoHeight
              />
            )}
          />
        </FormField>
      </Grid>

      <FormField
        label="Status"
        description="Disabling a webhook will prevent it from sending any requests to your endpoint."
        error={errors?.enabled?.message}
      >
        <CheckboxInput label="Enable webhook" {...register('enabled')} defaultChecked />
      </FormField>

      <Box marginTop={4}>
        <Heading as="h3" size={2}>
          Advanced settings
        </Heading>
      </Box>

      <FormField
        label="HTTP method"
        description="The HTTP method used to deliver the webhook"
        error={errors?.httpMethod?.message}
      >
        <Select radius={2} padding={4} {...register('httpMethod')} defaultValue="POST">
          <optgroup label="HTTP method">
            {initialValue && !DEFAULT_HTTP_METHODS.includes(initialValue.httpMethod) && (
              <option key={initialValue.httpMethod} value={initialValue.httpMethod}>
                {initialValue.httpMethod}
              </option>
            )}
            {DEFAULT_HTTP_METHODS.map((httpMethod) => (
              <option key={httpMethod} value={httpMethod}>
                {httpMethod}
              </option>
            ))}
          </optgroup>
        </Select>
      </FormField>

      <FormField
        label="HTTP headers"
        description="Additional HTTP headers that are sent with the webhook."
      >
        <Controller
          control={control}
          name="headerList"
          defaultValue={[]}
          render={({field}) => (
            <Table headers={tableConfig}>
              <TableBody>
                {field.value
                  .map((line, idx) => (
                    <HeaderLineInput
                      key={idx}
                      value={line}
                      onChange={(newLine) => {
                        const before = field.value.slice(0, idx)
                        const after = field.value.slice(idx + 1)
                        field.onChange([...before, newLine, ...after])
                      }}
                      onRemove={() => {
                        const before = field.value.slice(0, idx)
                        const after = field.value.slice(idx + 1)
                        field.onChange([...before, ...after])
                      }}
                    />
                  ))
                  .concat(
                    <HeaderLineInput
                      key={field.value.length}
                      value={{name: '', value: ''}}
                      onChange={(newLine) => {
                        field.onChange(field.value.concat(newLine))
                      }}
                    />
                  )}
              </TableBody>
            </Table>
          )}
        />
      </FormField>

      <FormField
        label="API version"
        description="API version used for GROQ"
        error={errors?.apiVersion?.message}
      >
        <Select
          radius={2}
          padding={4}
          {...register('apiVersion')}
          defaultValue={DEFAULT_API_VERSIONS[0]}
        >
          <optgroup label="API version">
            {initialValue && !DEFAULT_API_VERSIONS.includes(initialValue.apiVersion) && (
              <option
                key={initialValue.apiVersion}
                value={initialValue.apiVersion}
                {...register('apiVersion')}
              >
                {initialValue.apiVersion}
              </option>
            )}
            {DEFAULT_API_VERSIONS.map((apiVersion) => (
              <option key={apiVersion} value={apiVersion} {...register('apiVersion')}>
                {apiVersion}
              </option>
            ))}
          </optgroup>
        </Select>
      </FormField>

      <FormField
        label="Drafts"
        description="Should changes to draft documents trigger this webhook? Enabling this can cause a huge amount of traffic to your webhook."
        error={errors?.includeDrafts?.message}
      >
        <CheckboxInput
          label="Trigger webhook when drafts are modifed"
          {...register('includeDrafts')}
        />
      </FormField>

      {shareMode ? (
        <FormField label="Secret" description="Secrets cannot be shared. You can add it later." />
      ) : (
        <FormField
          label="Secret"
          description="Secret can be used to verify the events that Sanity sends to your endpoint"
          error={errors?.secret?.message}
        >
          {showSecret ? (
            <TextInput
              radius={2}
              padding={4}
              {...register('secret', {
                pattern: {value: secretValidator, message: 'Secret should not contain spaces'},
              })}
            />
          ) : (
            <div>
              <Button
                icon={EyeOpenIcon}
                text="Show secret"
                mode="ghost"
                onClick={() => {
                  setShowSecret(true)
                }}
              />
            </div>
          )}
        </FormField>
      )}
      {after}
    </EditContainer>
  )
}

const Flex1 = styled.div`
  flex: 1;
`

function HeaderLineInput({
  value: {name, value},
  onChange,
  onRemove,
}: {
  value: HeaderLine
  onChange: (line: HeaderLine) => void
  onRemove?: () => void
}) {
  return (
    <TableRow padding={2} borderBottom>
      <TableCell>
        <TextInput
          radius={2}
          padding={4}
          value={name}
          onChange={(evt) => onChange({name: evt.currentTarget.value, value})}
        />
      </TableCell>

      <TableCell>
        <Flex1>
          <TextInput
            radius={2}
            padding={4}
            value={value}
            onChange={(evt) => onChange({name, value: evt.currentTarget.value})}
          />
        </Flex1>
      </TableCell>

      {onRemove && (
        <TableCell $align="right">
          <Button
            icon={TrashIcon}
            padding={4}
            size={2}
            mode="bleed"
            onClick={(evt) => {
              evt.preventDefault()
              onRemove()
            }}
          />
        </TableCell>
      )}
    </TableRow>
  )
}

const operationChoices = [
  {name: 'create', label: 'Create'},
  {name: 'update', label: 'Update'},
  {name: 'delete', label: 'Delete'},
]

function OperationInput({
  value,
  onChange,
}: {
  value: string[]
  onChange: (newValue: string[]) => void
}) {
  return (
    <Inline space={4}>
      {operationChoices.map((op) => (
        <CheckboxInput
          key={op.name}
          label={op.label}
          checked={value.includes(op.name)}
          onChange={(evt) => {
            if (evt.currentTarget.checked) {
              onChange(value.concat(op.name))
            } else {
              onChange(value.filter((v) => v !== op.name))
            }
          }}
        />
      ))}
    </Inline>
  )
}
