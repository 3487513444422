import {Project} from '@/types/models/project'
import {useQuery} from '@tanstack/react-query'
import {getProjectBaseKey} from './cache'
import {getProject} from './controller'
import {useCanReadProject} from '@/context/useProjectPermissions'

export function useProject(id?: string) {
  const canReadProject = useCanReadProject()

  return useQuery<Project | null>({
    queryKey: getProjectBaseKey(id),
    queryFn: () => getProject(id),
    enabled: !!id && !!canReadProject,
    retry: false,
  })
}

/**
 * This hook is only used in _app.tsx to fetch the current project, but we skip read access check
 * as we both already have the partial project from the org + can't access the scope permission here
 */
export function useProjectShallow(
  id?: string,
  options?: {
    placeholderData?: Project
    enabled?: boolean
    select?: (data: Project | null) => Project | null
  }
) {
  const {placeholderData, enabled = true, select} = options || {}

  return useQuery<Project | null>({
    queryKey: getProjectBaseKey(id),
    queryFn: () => getProject(id),
    enabled: !!id && !!enabled,
    placeholderData,
    retry: false,
    select,
  })
}
