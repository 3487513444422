import {promiseRequest} from '@/data/util/promiseRequest'
import {
  PermissionResource,
  PermissionResourceSchema,
  Project,
  ProjectCreateRole,
  ProjectData,
  ProjectMemberRole,
  ProjectUpdateRole,
  Tag,
} from '../../types/models'

import {Bucket} from '@/ui/views/project/settings/logDelivery'

const headers = {
  'Content-Type': 'application/json',
}

const API_VERSION = 'v2021-06-07'

export function getProject(id?: string) {
  if (!id) {
    return Promise.resolve(null)
  }

  return promiseRequest<Project>(`https://api.${process.env.host}/${API_VERSION}/projects/${id}`)
}

export function getRequestLogSinks(projectId: string) {
  return promiseRequest<Bucket[]>(
    `https://api.${process.env.host}/v2024-01-31/projects/${projectId}/request-logs/buckets`,
    {
      method: 'GET',
    }
  )
}

export function deleteRequestLogSink(projectId: string, bucket: Bucket) {
  return promiseRequest<void>(
    `https://api.${process.env.host}/v2024-01-31/projects/${projectId}/request-logs/buckets/${bucket.id}`,
    {
      method: 'DELETE',
    }
  )
}

export function updateRequestLogSink(projectId: string, bucket: Bucket) {
  return promiseRequest<Bucket>(
    `https://api.${process.env.host}/v2024-01-31/projects/${projectId}/request-logs/buckets/${bucket.id}`,
    {
      method: 'PUT',
      json: {...bucket, type: 'gcs', enabled: true, projectId},
    }
  )
}

export function createRequestLogSink(projectId: string, bucket: Bucket) {
  return promiseRequest<Bucket>(
    `https://api.${process.env.host}/v2024-01-31/projects/${projectId}/request-logs/buckets`,
    {
      method: 'POST',
      json: {...bucket, type: 'gcs', enabled: true, projectId},
    }
  )
}

export type DownloadableFile = {
  metadata: {
    id: string
    dateStart: string
    dateEnd: string
    state: string
    errorCode: string
  }
}

export function getRequestLogFile(projectId: string) {
  return promiseRequest<DownloadableFile>(
    `https://api.${process.env.host}/v2024-03-06/projects/${projectId}/request-logs/files/latest`,
    {
      method: 'GET',
    }
  )
}

export function createRequestLogFile(projectId: string) {
  return promiseRequest<DownloadableFile>(
    `https://api.${process.env.host}/v2024-03-06/projects/${projectId}/request-logs/files`,
    {
      method: 'POST',
    }
  )
}

export function getDownloadUrl(projectId: string) {
  return `https://api.${process.env.host}/v2024-03-06/projects/${projectId}/request-logs/files/latest/download`
}

export function setOrganization(projectId: string, organizationId: string | null) {
  return promiseRequest<void>(
    `https://api.${process.env.host}/${API_VERSION}/projects/${projectId}/organization`,
    {
      method: 'PUT',
      json: {organizationId},
      headers,
    }
  )
}

export function patchProject(projectId: string, data: ProjectData) {
  return promiseRequest<Project>(
    `https://api.${process.env.host}/${API_VERSION}/projects/${projectId}`,
    {
      method: 'PATCH',
      json: data,
    }
  ).then((result) => {
    return result
  })
}

export function getProjectRoles(projectId: string) {
  return promiseRequest<ProjectMemberRole[]>(
    `https://api.${process.env.host}/${API_VERSION}/projects/${projectId}/roles`
  )
}

export function getProjects() {
  return promiseRequest<Project[]>(
    `https://api.${process.env.host}/${API_VERSION}/projects?includeOrganizationProjects=true`
  )
}

export function setProjectMemberRole(projectId: string, userId: string, roleName: string) {
  return promiseRequest<void>(
    `https://api.${process.env.host}/${API_VERSION}/projects/${projectId}/acl/${userId}`,
    {
      method: 'PUT',
      headers,
      json: {roleName, deleteExistingRoles: true},
    }
  ).then(() => {
    return roleName
  })
}

export function addProjectMembersRole(projectId: string, userIds: string[], roleName: string) {
  return Promise.all(
    userIds.map((userId) =>
      promiseRequest<void>(
        `https://api.${process.env.host}/${API_VERSION}/projects/${projectId}/acl/${userId}`,
        {
          method: 'PUT',
          headers,
          json: {roleName},
        }
      )
    )
  ).then(() => {
    return roleName
  })
}

export function addProjectMemberRole(projectId: string, userId: string, roleName: string) {
  return promiseRequest<void>(
    `https://api.${process.env.host}/${API_VERSION}/projects/${projectId}/acl/${userId}`,
    {
      method: 'PUT',
      headers,
      json: {roleName},
    }
  ).then(() => {
    return roleName
  })
}

export function removeProjectMemberRole(projectId: string, userId: string, roleName: string) {
  return promiseRequest<void>(
    `https://api.${process.env.host}/${API_VERSION}/projects/${projectId}/acl/${userId}`,
    {
      method: 'DELETE',
      headers,
      json: {roleName},
    }
  ).then(() => {
    return roleName
  })
}

export function createProject(
  data: {
    displayName: string
    subscription?: {
      planId: string
    }
  } & ProjectData
) {
  return promiseRequest<Project>(`https://api.${process.env.host}/${API_VERSION}/projects`, {
    json: data,
    method: 'POST',
    headers,
  })
}

export function deleteProject(projectId: string) {
  return promiseRequest<{organizationId?: string}>(
    `https://api.${process.env.host}/${API_VERSION}/projects/${projectId}`,
    {
      method: 'DELETE',
    }
  )
}

export function transferProject(projectId: string, organizationId: string | null) {
  return promiseRequest<Project>(
    `https://api.${process.env.host}/${API_VERSION}/projects/${projectId}/organization`,
    {
      method: 'PUT',
      json: {organizationId},
    }
  )
}

export function getProjectPermissionResources(projectId: string) {
  return promiseRequest<PermissionResource[]>(
    `https://api.${process.env.host}/${API_VERSION}/projects/${projectId}/permissionResources`
  )
}

export function getProjectPermissionResourceSchemas(projectId: string) {
  return promiseRequest<PermissionResourceSchema[]>(
    `https://api.${process.env.host}/${API_VERSION}/projects/${projectId}/permissionResourceSchemas`
  )
}

export function grantProjectRolePermission(
  projectId: string,
  roleName: string,
  permissionResourceId: string,
  permissionName: string,
  params: Record<string, unknown> = {}
) {
  return promiseRequest<ProjectMemberRole[]>(
    `https://api.${process.env.host}/${API_VERSION}/projects/${projectId}/grants`,
    {
      method: 'POST',
      headers,
      json: {roleName, permissionName, permissionResourceId, params},
    }
  ).then((res) => {
    return res
  })
}

export function grantProjectRolePermissions(
  projectId: string,
  roleName: string,
  grants: {permissionResourceId: string; permissionName: string; params: Record<string, unknown>}[]
) {
  return promiseRequest<ProjectMemberRole[]>(
    `https://api.${process.env.host}/${API_VERSION}/projects/${projectId}/grants`,
    {
      method: 'POST',
      headers,
      json: {roleName, grants},
    }
  ).then((res) => {
    return res
  })
}

export function revokeProjectRolePermission(
  projectId: string,
  roleName: string,
  permissionResourceId: string,
  permissionName: string,
  params: Record<string, unknown> = {}
) {
  return promiseRequest<ProjectMemberRole[]>(
    `https://api.${process.env.host}/${API_VERSION}/projects/${projectId}/grants`,
    {
      method: 'DELETE',
      headers,
      json: {roleName, permissionName, permissionResourceId, params},
    }
  ).then((res) => {
    return res
  })
}

export function revokeProjectRolePermissions(
  projectId: string,
  roleName: string,
  grants: {
    permissionResourceId: string
    permissionName: string
    params: Record<string, unknown>
  }[]
) {
  return promiseRequest<ProjectMemberRole[]>(
    `https://api.${process.env.host}/${API_VERSION}/projects/${projectId}/grants`,
    {
      method: 'DELETE',
      headers,
      json: {roleName, grants},
    }
  ).then((res) => {
    return res
  })
}

export function createProjectRole(projectId: string, data: ProjectCreateRole) {
  return promiseRequest<ProjectMemberRole>(
    `https://api.${process.env.host}/${API_VERSION}/projects/${projectId}/roles`,
    {
      method: 'POST',
      headers,
      json: data,
    }
  ).then((res) => {
    return res
  })
}

export function updateProjectRole(projectId: string, roleName: string, data: ProjectUpdateRole) {
  return promiseRequest<ProjectMemberRole>(
    `https://api.${process.env.host}/${API_VERSION}/projects/${projectId}/roles/${roleName}`,
    {
      method: 'PUT',
      headers,
      json: data,
    }
  ).then((res) => {
    return res
  })
}

export function deleteProjectRole(projectId: string, roleName: string) {
  return promiseRequest<void>(
    `https://api.${process.env.host}/${API_VERSION}/projects/${projectId}/roles/${roleName}`,
    {
      method: 'DELETE',
      headers,
    }
  ).then(() => {
    return roleName
  })
}

export function getProjectTags(projectId: string) {
  return promiseRequest<Tag[]>(
    `https://api.${process.env.host}/${API_VERSION}/projects/${projectId}/tags`
  )
}

export function createPermissionResource(
  projectId: string,
  data: {
    permissionResourceType: string
    name: string
    title: string
    description: string
    config: {
      filter?: string
    }
  }
) {
  return promiseRequest<PermissionResource>(
    `https://api.${process.env.host}/${API_VERSION}/projects/${projectId}/permissionResources`,
    {
      method: 'POST',
      headers,
      json: data,
    }
  )
}

export function updatePermissionResource(
  projectId: string,
  resourceId: string,
  data: {
    title: string
    description: string
    config: {
      filter?: string
    }
  }
) {
  return promiseRequest<PermissionResource>(
    `https://api.${process.env.host}/${API_VERSION}/projects/${projectId}/permissionResources/${resourceId}`,
    {
      method: 'PUT',
      headers,
      json: data,
    }
  )
}

export function deletePermissionResource(projectId: string, resourceId: string) {
  return promiseRequest<void>(
    `https://api.${process.env.host}/${API_VERSION}/projects/${projectId}/permissionResources/${resourceId}`,
    {
      method: 'DELETE',
      headers,
    }
  )
}
