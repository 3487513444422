import React from 'react'
import {Box, Heading, Text} from '@sanity/ui'

export function SetPermissions() {
  return (
    <Box>
      <Heading size={1}>Set permissions</Heading>
      <Text>Description</Text>
    </Box>
  )
}
