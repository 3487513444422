import React from 'react'
import {ReCaptchaContextTypes, ReCaptchaScript} from './types'
import {RawReCaptchaScript, useGlobalReCaptchaLoader} from './useGlobalReCaptchaLoader'

export const ReCaptchaContext = React.createContext<ReCaptchaContextTypes | null>(null)

export const useReCaptchaContext = () => {
  const context = React.useContext(ReCaptchaContext)

  if (!context) throw new Error('useReCaptchaContext must be used within a ReCaptchaProvider')

  return context
}

function wrapRaw(
  raw: RawReCaptchaScript | undefined,
  siteKey: string
): ReCaptchaScript | undefined {
  if (!raw) {
    return undefined
  }
  return {
    execute(action: {action: string}) {
      return raw.execute(siteKey, action)
    },
    render: raw.render,
    reset: raw.reset,
    ready: raw.ready,
  }
}

export const ReCaptchaProvider: React.FC<
  React.PropsWithChildren<{
    scriptSrc: string
    scriptName: string
    sitekey: string
    enabled: boolean
  }>
> = ({scriptSrc, scriptName, sitekey, enabled, children}) => {
  const reCaptchaScript = useGlobalReCaptchaLoader({scriptSrc, scriptName, enabled})
  const s = wrapRaw(reCaptchaScript, sitekey)
  return (
    <ReCaptchaContext.Provider value={{sitekey, reCaptchaScript: s, enabled}}>
      {children}
    </ReCaptchaContext.Provider>
  )
}
