/* eslint-disable no-warning-comments, max-nested-callbacks */
import {grantProjectRolePermission, revokeProjectRolePermission} from '@/data/projects'
import {getProjectRolesKey} from '@/data/projects/cache'
import {
  Dataset,
  PermissionResource,
  PermissionResourceSchema,
  ProjectMemberRole,
  RoleResource,
  Tag,
} from '@/types/models'
import {TagIcon} from '@/ui/index'
import {DatabaseIcon} from '@sanity/icons'
import {Box, Card, Checkbox, Flex, Grid, Heading, Stack, Text, useToast} from '@sanity/ui'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {useCallback, useMemo} from 'react'

const permissionOrder = [
  'read',
  'update',
  'create',
  'publish',
  'manage',
  'invite',
  'editHistory',
  'deployStudio',
  'createSession',
  'history',
  'delete',
]

const orderMap = permissionOrder.reduce(
  (acc, perm, i) => {
    acc[perm] = i
    return acc
  },
  {} as Record<string, number>
)

function permissionSort(a: string, b: string) {
  if (orderMap[a] < orderMap[b]) {
    return -1
  } else if (orderMap[a] > orderMap[b]) {
    return 1
  }
  return 0
}

function ToggleGrant({
  checked,
  projectId,
  roleName,
  permissionResourceId,
  permission,
  params,
  forceGrant,
  tone,
}: {
  checked: boolean
  projectId: string
  roleName: string
  permissionResourceId: string
  permission: string
  params: Record<string, unknown>
  forceGrant?: boolean
  tone?: 'critical' | 'caution' | 'primary'
}) {
  const toast = useToast()
  const queryClient = useQueryClient()

  const grantMutation = useMutation({
    mutationFn: () =>
      grantProjectRolePermission(projectId, roleName, permissionResourceId, permission, params),
    onSuccess: () => {
      toast.push({title: 'Permission granted', status: 'success'})
      queryClient.invalidateQueries({queryKey: getProjectRolesKey(projectId)})
    },
    onError: (err: Error) => {
      toast.push({title: 'Error granting permission', description: err.message, status: 'error'})
    },
  })

  const revokeMutation = useMutation({
    mutationFn: () =>
      revokeProjectRolePermission(projectId, roleName, permissionResourceId, permission, params),
    onSuccess: () => {
      toast.push({title: 'Permission revoked', status: 'success'})
      queryClient.invalidateQueries({queryKey: getProjectRolesKey(projectId)})
    },
    onError: (err: Error) => {
      toast.push({title: 'Error revoking permission', description: err.message, status: 'error'})
    },
  })

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const next = e.currentTarget.checked
      if (next || forceGrant) {
        grantMutation.mutate()
      } else {
        revokeMutation.mutate()
      }
    },
    [grantMutation, revokeMutation, forceGrant]
  )

  return (
    <Card padding={1} tone={checked ? 'positive' : tone || 'default'}>
      <Flex align="center" justify="center">
        <Checkbox
          checked={checked}
          onChange={handleChange}
          disabled={grantMutation.isPending || revokeMutation.isPending}
        />
      </Flex>
    </Card>
  )
}

function PermissionHeader({name}: {name: string}) {
  return (
    <Card paddingBottom={2}>
      <Flex align="center" justify="center">
        <Text weight="semibold" size={1}>
          {name}
        </Text>
      </Flex>
    </Card>
  )
}

function typeIcon(type?: string) {
  switch (type) {
    case 'dataset':
      return <DatabaseIcon />
    case 'tag':
      return <TagIcon />
    default:
      return undefined
  }
}

type HintType = 'all' | 'tag' | 'dataset' | 'none'
function typeTone(type?: HintType) {
  switch (type) {
    case 'all':
      return 'primary'
    case 'tag':
      return 'caution'
    case 'dataset':
    case 'none':
    default:
      return undefined
  }
}

function GridRowLabel({name, type}: {type?: HintType; name: string}) {
  const tone = typeTone(type)
  const icon = typeIcon(type)
  return (
    <Flex justify="flex-start" align="center" direction="row">
      <Card radius={2} padding={1} tone={tone} style={{overflow: 'scroll'}}>
        <Flex>
          {icon && (
            <Box paddingRight={2}>
              <Text size={1}>{icon}</Text>
            </Box>
          )}
          <Box>
            <Text size={1} weight={icon ? 'regular' : 'semibold'} style={{whiteSpace: 'nowrap'}}>
              {name}
            </Text>
          </Box>
        </Flex>
      </Card>
    </Flex>
  )
}

function PermissionGrid({
  projectId,
  roleName,
  resource,
  permissionResource,
  permissionResourceSchema,
  datasets,
  tags,
  defaultLabel = 'All datasets',
}: {
  projectId: string
  roleName: string
  resource: RoleResource | undefined
  permissionResource: PermissionResource
  permissionResourceSchema: PermissionResourceSchema
  datasets: Dataset[]
  tags: Tag[]
  defaultLabel?: string
}) {
  const labelColumn = datasets.length > 0 || tags.length > 0
  const columnCount = permissionResourceSchema.permissions.length + (labelColumn ? 1 : 0)
  const permissions = permissionResourceSchema.permissions
    .map((perm) => perm.name)
    .sort(permissionSort)

  return (
    <Grid columns={columnCount}>
      {/* Header */}
      {labelColumn && <PermissionHeader name="" />}
      {permissions.map((perm) => {
        return <PermissionHeader key={perm} name={perm} />
      })}
      {/* Grants */}
      {labelColumn && <GridRowLabel type="all" name={defaultLabel} />}
      {permissions.map((perm) => {
        const checked =
          resource?.grants.some(
            (grant) => grant.name === perm && !grant.params.tagName && !grant.params.dataset
          ) || false
        return (
          <ToggleGrant
            key={perm}
            checked={checked}
            projectId={projectId}
            roleName={roleName}
            permissionResourceId={permissionResource.id}
            permission={perm}
            params={{}}
            tone={typeTone(checked ? 'all' : 'none')}
          />
        )
      })}
      {/* Tag grants */}
      {tags.map((tag) => {
        return (
          <>
            <GridRowLabel type="tag" name={tag.name} />
            {permissions.map((perm) => {
              const checked =
                resource?.grants.some(
                  (grant) => grant.name === perm && grant.params.tagName === tag.name
                ) || false
              const allChecked =
                resource?.grants.some(
                  (grant) => grant.name === perm && !grant.params.tagName && !grant.params.dataset
                ) || false

              return (
                <ToggleGrant
                  key={perm}
                  checked={checked}
                  projectId={projectId}
                  roleName={roleName}
                  permissionResourceId={permissionResource.id}
                  permission={perm}
                  params={{
                    tagName: tag.name,
                  }}
                  tone={typeTone(allChecked ? 'all' : 'none')}
                />
              )
            })}
          </>
        )
      })}
      {/* Dataset grants */}
      {datasets.map((dataset) => {
        return (
          <>
            <GridRowLabel type="dataset" name={dataset.name} />
            {permissions.map((perm) => {
              const checked =
                resource?.grants.some(
                  (grant) => grant.name === perm && grant.params.dataset === dataset.name
                ) || false
              const allChecked =
                resource?.grants.some(
                  (grant) => grant.name === perm && !grant.params.tagName && !grant.params.dataset
                ) || false
              const tagChecked =
                dataset.tags?.some(
                  (tag) =>
                    resource?.grants.some(
                      (grant) => grant.name === perm && grant.params.tagName === tag.name
                    ) || false
                ) || false

              let inheritType: HintType = 'none'
              if (allChecked) inheritType = 'all'
              else if (tagChecked) inheritType = 'tag'

              return (
                <ToggleGrant
                  key={perm}
                  checked={checked}
                  projectId={projectId}
                  roleName={roleName}
                  permissionResourceId={permissionResource.id}
                  permission={perm}
                  params={{
                    dataset: dataset.name,
                  }}
                  tone={typeTone(inheritType)}
                />
              )
            })}
          </>
        )
      })}
    </Grid>
  )
}

const modes = ['read', 'create', 'publish', 'history']
function DocumentModePermissionGrid({
  projectId,
  roleName,
  resource,
  permissionResource,
  datasets,
  tags,
}: {
  projectId: string
  roleName: string
  resource: RoleResource | undefined
  permissionResource: PermissionResource
  datasets: Dataset[]
  tags: Tag[]
}) {
  const permissions = modes.map((m) => m).sort(permissionSort)

  return (
    <Grid columns={modes.length + 1}>
      {/* Header */}
      <PermissionHeader name="" />
      {permissions.map((perm) => {
        return <PermissionHeader key={perm} name={perm} />
      })}
      {/* All datasets grants */}
      <GridRowLabel type="all" name="All datasets" />
      {permissions.map((mode) => {
        const currentGrant = resource?.grants.find(
          (grant) => grant.name === 'mode' && !grant.params.tagName && !grant.params.dataset
        )
        let checked = false
        let forceGrant = false
        const params: Record<string, unknown> = {}

        if (mode === 'history') {
          checked = currentGrant?.params.history || false
          forceGrant = true
          params.history = !checked
          params.mode = currentGrant?.params.mode || 'read'
        } else {
          checked = currentGrant?.params.mode === mode
          params.mode = mode
          params.history = currentGrant?.params.history || false
        }

        return (
          <ToggleGrant
            key={mode}
            checked={checked}
            projectId={projectId}
            roleName={roleName}
            permissionResourceId={permissionResource.id}
            permission="mode"
            params={params}
            forceGrant={forceGrant}
            tone={typeTone(checked ? 'all' : 'none')}
          />
        )
      })}
      {/* Tag grants */}
      {tags.map((tag) => {
        return (
          <>
            <GridRowLabel type="tag" name={tag.name} />
            {permissions.map((mode) => {
              const allGrant = resource?.grants.find(
                (grant) => grant.name === 'mode' && !grant.params.tagName && !grant.params.dataset
              )
              const allChecked =
                mode === 'history'
                  ? allGrant?.params.history || false
                  : allGrant?.params.mode === mode

              const currentGrant = resource?.grants.find(
                (grant) => grant.name === 'mode' && grant.params.tagName === tag.name
              )
              let checked = false
              let forceGrant = false
              const params: Record<string, unknown> = {
                tagName: tag.name,
              }

              if (mode === 'history') {
                checked = currentGrant?.params.history || false
                forceGrant = true
                params.history = !checked
                params.mode = currentGrant?.params.mode || 'read'
              } else {
                checked = currentGrant?.params.mode === mode
                params.mode = mode
                params.history = currentGrant?.params.history || false
              }

              return (
                <ToggleGrant
                  key={mode}
                  checked={checked}
                  projectId={projectId}
                  roleName={roleName}
                  permissionResourceId={permissionResource.id}
                  permission="mode"
                  params={params}
                  forceGrant={forceGrant}
                  tone={typeTone(allChecked ? 'all' : 'none')}
                />
              )
            })}
          </>
        )
      })}
      {/* Dataset grants */}
      {datasets.map((dataset) => {
        return (
          <>
            <GridRowLabel type="dataset" name={dataset.name} />
            {permissions.map((mode) => {
              const allGrant = resource?.grants.find(
                (grant) => grant.name === 'mode' && !grant.params.tagName && !grant.params.dataset
              )
              const allChecked =
                mode === 'history'
                  ? allGrant?.params.history || false
                  : allGrant?.params.mode === mode

              const tagChecked =
                dataset.tags?.some((tag) => {
                  const tagGrant = resource?.grants.find(
                    (grant) => grant.name === 'mode' && grant.params.tagName === tag.name
                  )
                  return mode === 'history'
                    ? tagGrant?.params.history || false
                    : tagGrant?.params.mode === mode
                }) || false

              const currentGrant = resource?.grants.find(
                (grant) => grant.name === 'mode' && grant.params.dataset === dataset.name
              )
              let checked = false
              let forceGrant = false
              const params: Record<string, unknown> = {
                dataset: dataset.name,
              }

              if (mode === 'history') {
                checked = currentGrant?.params.history || false
                forceGrant = true
                params.history = !checked
                params.mode = currentGrant?.params.mode || 'read'
              } else {
                checked = currentGrant?.params.mode === mode
                params.mode = mode
                params.history = currentGrant?.params.history || false
              }

              let inheritType: HintType = 'none'
              if (allChecked) inheritType = 'all'
              else if (tagChecked) inheritType = 'tag'

              return (
                <ToggleGrant
                  key={mode}
                  checked={checked}
                  projectId={projectId}
                  roleName={roleName}
                  permissionResourceId={permissionResource.id}
                  permission="mode"
                  params={params}
                  forceGrant={forceGrant}
                  tone={typeTone(inheritType)}
                />
              )
            })}
          </>
        )
      })}
    </Grid>
  )
}

type Props = {
  projectId: string
  role: ProjectMemberRole
  resources: RoleResource[]
  permissionResources: PermissionResource[]
  permissionResourceSchemas: PermissionResourceSchema[]
  datasets: Dataset[]
  tags: Tag[]
}
export function RawPermissionsOverview({
  projectId,
  role,
  resources,
  permissionResources: rawPermissionResources,
  permissionResourceSchemas,
  datasets,
  tags,
}: Props) {
  const permissionResources = useMemo(() => {
    return [...rawPermissionResources].sort((a, b) => {
      if (
        a.permissionResourceType === 'sanity.document.filter.mode' &&
        b.permissionResourceType === 'sanity.document.filter'
      ) {
        return -1
      }
      if (
        a.permissionResourceType === 'sanity.document.filter' &&
        b.permissionResourceType === 'sanity.document.filter.mode'
      ) {
        return 1
      }
      if (
        a.permissionResourceType === 'sanity.document.filter' &&
        b.permissionResourceType === 'sanity.document.filter'
      ) {
        return a.title.localeCompare(b.title)
      }
      return a.permissionResourceType.localeCompare(b.permissionResourceType)
    })
  }, [rawPermissionResources])

  return (
    <Box>
      <Stack space={4} paddingBottom={4}>
        <Heading size={2}>Project Permissions</Heading>
        <Text muted>
          Grant permissions for this role.{' '}
          <a href={`https://www.${process.env.host}/docs/roles`}>Learn more →</a>
        </Text>
      </Stack>

      {permissionResources.map((permissionResource) => {
        const permissionResourceSchema = permissionResourceSchemas.find(
          (schema) => schema.name === permissionResource.permissionResourceType
        )
        const resource = resources.find((r) => r.id === permissionResource.id)

        if (
          permissionResourceSchema &&
          permissionResource.permissionResourceType.startsWith('sanity.project')
        ) {
          return (
            <Card key={permissionResource.id} borderTop paddingTop={2} paddingBottom={2}>
              <Flex>
                <Card flex={1}>
                  <Stack space={2}>
                    <Text weight="semibold" size={2}>
                      {permissionResource.title}
                    </Text>
                    <Text muted size={1}>
                      {permissionResource.permissionResourceType}
                    </Text>
                    <Text muted size={1}>
                      {permissionResource.id}
                    </Text>
                  </Stack>
                </Card>
                <Box flex={4}>
                  <PermissionGrid
                    projectId={projectId}
                    roleName={role.name}
                    resource={resource}
                    permissionResource={permissionResource}
                    permissionResourceSchema={permissionResourceSchema}
                    datasets={[]}
                    tags={[]}
                  />
                </Box>
              </Flex>
            </Card>
          )
        }

        return (
          <Card key={permissionResource.id} borderTop paddingTop={2} paddingBottom={2}>
            <Card flex={1} paddingTop={2} paddingBottom={2}>
              <Stack space={2}>
                <Flex>
                  <Flex flex={1}>
                    <Text weight="semibold" size={2}>
                      {permissionResource.title}
                    </Text>
                  </Flex>
                  <Flex flex={1} justify="center">
                    <Text muted size={1}>
                      {permissionResource.permissionResourceType}
                    </Text>
                  </Flex>
                  <Flex flex={1} justify="flex-end">
                    <Text muted size={1}>
                      {permissionResource.id}
                    </Text>
                  </Flex>
                </Flex>
                <Text muted size={1}>
                  {permissionResource.description}
                </Text>
              </Stack>
            </Card>
            <Flex>
              <Box flex={4}>
                {permissionResourceSchema === undefined && (
                  <Text>Unknown permission resource type.</Text>
                )}
                {permissionResourceSchema &&
                  permissionResource.permissionResourceType.startsWith('sanity.project') && (
                    <PermissionGrid
                      projectId={projectId}
                      roleName={role.name}
                      resource={resource}
                      permissionResource={permissionResource}
                      permissionResourceSchema={permissionResourceSchema}
                      datasets={[]}
                      tags={[]}
                    />
                  )}
                {permissionResourceSchema &&
                  permissionResource.permissionResourceType === 'sanity.document.filter' && (
                    <PermissionGrid
                      projectId={projectId}
                      roleName={role.name}
                      resource={resource}
                      permissionResource={permissionResource}
                      permissionResourceSchema={permissionResourceSchema}
                      datasets={datasets}
                      tags={tags}
                    />
                  )}
                {permissionResourceSchema &&
                  permissionResource.permissionResourceType === 'sanity.document.filter.mode' && (
                    <DocumentModePermissionGrid
                      projectId={projectId}
                      roleName={role.name}
                      resource={resource}
                      permissionResource={permissionResource}
                      datasets={datasets}
                      tags={tags}
                    />
                  )}
              </Box>
            </Flex>
          </Card>
        )
      })}
      <Card borderTop />
    </Box>
  )
}
