import {AvatarStack, Box, Button, Card, Flex, Inline, Stack, Text, useTheme} from '@sanity/ui'
import React from 'react'
import styled from 'styled-components'
import {ProjectIcon} from './projectIcon'
import {Organization, Project} from '@/types/models'
import {useProjectSubscription} from '@/data/projects/useProjectSubscription'
import {
  ProjectUsageStatusTooltip,
  AppLink,
  PlanLoadingBadge,
  ProjectPlanBadge,
  MemberAvatar,
} from '@/ui/index'
import {getProjectPath} from '@/utils/getProjectPath'

const MAX_MEMBERS = 3

type Props = {
  project: Project
  org?: Organization
}

const TitleButton = styled(Button)`
  overflow: hidden;
  & [data-ui='Text'] {
    color: ${({theme}) => theme.sanity.color.base.fg};
    font-weight: ${({theme}) => theme.sanity.fonts.heading.weights.regular};
    text-overflow: ellipsis;
  }

  &:not([data-disabled='true']):hover {
    --card-bg-color: 'none';
    --card-border-color: 'none';
  }
`

export function ProjectTile({project, org}: Props) {
  const members = project.members.filter((m) => !m.isRobot)
  const {data: subscription, isLoading} = useProjectSubscription(project.id)
  const plan = subscription?.plan?.name
  const {sanity} = useTheme()
  const textColor = sanity.color.base.fg
  const projectHref = getProjectPath(project)

  return (
    <Card padding={1} radius={2} border>
      <Flex align={['flex-start', 'flex-start', 'center']} flex={1}>
        <Box paddingRight={1} marginTop={[0, 0, 1]} width={40}>
          <AppLink href={projectHref}>
            <Button
              as="span"
              mode="bleed"
              radius={3}
              paddingX={2}
              text={<ProjectIcon project={project} size={3} />}
            />
          </AppLink>
        </Box>
        <Box flex={1}>
          <Stack space={2}>
            <Flex align="flex-start">
              <Box flex={1}>
                <Stack space={1}>
                  <Flex justify="space-between">
                    <Box flex={1}>
                      <AppLink href={projectHref}>
                        <TitleButton
                          tabIndex={-1}
                          text={project.displayName}
                          mode="bleed"
                          paddingX={0}
                          paddingY={2}
                        />
                      </AppLink>
                    </Box>

                    <Flex marginLeft={4} justify="flex-end" align="center">
                      {project && (
                        <Box paddingX={2}>
                          <Text>
                            <ProjectUsageStatusTooltip project={project} org={org} />
                          </Text>
                        </Box>
                      )}
                    </Flex>
                  </Flex>

                  {plan !== undefined && (
                    <Box>
                      <Inline>
                        <ProjectPlanBadge project={project} subscription={subscription} />
                      </Inline>
                    </Box>
                  )}

                  {isLoading && (
                    <Box paddingX={1}>
                      <Inline>
                        <PlanLoadingBadge />
                      </Inline>
                    </Box>
                  )}
                </Stack>
              </Box>
            </Flex>

            <Flex align="center">
              <Box>
                <AppLink href={`${projectHref}/members`}>
                  <Button
                    tabIndex={-1}
                    as="span"
                    mode="bleed"
                    paddingY={2}
                    paddingLeft={1}
                    paddingRight={2}
                    radius={6}
                    style={{color: 'inherit'}}
                    text={
                      <Flex align="center">
                        <AvatarStack maxLength={MAX_MEMBERS}>
                          {members.map((member) => (
                            <MemberAvatar key={`${project.id}-${member.id}`} userId={member.id} />
                          ))}
                        </AvatarStack>
                        <Box marginLeft={2}>
                          <Text size={1} style={{color: textColor}}>{`${members.length} ${
                            members.length === 1 ? 'member' : 'members'
                          }`}</Text>
                        </Box>
                      </Flex>
                    }
                  />
                </AppLink>
              </Box>
            </Flex>
          </Stack>
        </Box>
      </Flex>
    </Card>
  )
}
