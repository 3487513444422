import {Box, Heading, Stack, Text} from '@sanity/ui'
import React, {useCallback} from 'react'
import {LaunchIcon} from '@sanity/icons'
import {useWizardStore, WizardStep, WizardStepProps} from '@/components/payment-wizard'
import {useProjectSubscription} from '@/data/projects/useProjectSubscription'
import {CardDeclinedWarning} from '@/ui/views/wizards/common/cardDeclinedWarning'

function InviteMembersIntroStep(props: WizardStepProps) {
  const {params, steps, currentStep} = props
  const {dispatch, state} = useWizardStore()
  const myIndex = steps.indexOf(currentStep)
  const nextStep = steps[myIndex + 1]
  const {project} = params
  const {data: subscription} = useProjectSubscription(project.id)
  const currentPlan = subscription?.plan

  const nextStepIsAddBilling = nextStep && nextStep.id === 'add-billing-details'
  const nextStepIsChangePlan = nextStep && nextStep.id === 'change-plan'
  const nextStepIsSelectOrg = nextStep && nextStep.id === 'transfer-to-org'

  const handleMoveOrgLinkClicked = useCallback(() => {
    if (project.organizationId) {
      dispatch({type: 'setForceShowTransferStep', value: true})
      dispatch({type: 'setStepIndex', value: myIndex + 1})
    }
  }, [dispatch, myIndex, project.organizationId])

  if (!currentPlan) {
    return null
  }

  return (
    <Box>
      <Stack space={4}>
        {nextStepIsSelectOrg && (
          <Stack space={4}>
            <Heading as="h1">Get Started with Organizations</Heading>
            <Text as="p" muted>
              In order to add more members, this project needs to be a part of an organization with
              up to date billing information. It only takes a couple of minutes to set this up, and
              we will walk you through the process here.
            </Text>
          </Stack>
        )}
        {nextStepIsAddBilling && (
          <Stack space={4}>
            {state.existingCardDeclined ? (
              <>
                <CardDeclinedWarning />
                <Heading as="h1">Update Billing Information</Heading>
              </>
            ) : (
              <Heading as="h1">Add Billing Information</Heading>
            )}
            <Text as="p" muted>
              In order to add more members, your project needs to have billing information
              associated to it. It will only take a couple of minutes to set this up. During this
              process, you can also transfer your project to a different organization, or upgrade
              your plan to increase the maximum number of seats you can use with this project.
            </Text>
          </Stack>
        )}
        {nextStepIsChangePlan && currentPlan.planTypeId !== 'enterprise' && (
          <Stack space={4}>
            <Heading as="h1">Upgrade your Plan</Heading>
            <Text as="p" muted>
              In order to add more members, you will need to upgrade your plan.
            </Text>
          </Stack>
        )}
        {nextStepIsChangePlan && currentPlan.planTypeId === 'enterprise' && (
          <Stack space={4}>
            <Heading as="h1">Upgrade your Plan</Heading>
            <Text as="p" muted>
              You've reached the maximum number of users on this project.
            </Text>
            <Text as="p" muted>
              Please contact our sales team to learn more about our enterprise plans, which include
              many additional features as{' '}
              <a href={`https://www.${process.env.host}/pricing`} target="_blank" rel="noreferrer">
                described here
              </a>{' '}
              <LaunchIcon />
            </Text>
          </Stack>
        )}
        <Stack space={4}>
          {!nextStepIsSelectOrg && project.organizationId && (
            <Text as="p" muted>
              You may also move the project to{' '}
              <a onClick={handleMoveOrgLinkClicked}>
                <strong>another organization</strong>
              </a>{' '}
              if you want.
            </Text>
          )}
        </Stack>
      </Stack>
    </Box>
  )
}

export function useInviteMembersIntroStep(): WizardStep | null {
  return {
    id: 'invite-members-intro',
    title: 'Intro',
    completed: true,
    component: InviteMembersIntroStep,
    disabled: false,
    action: {
      label: <>Continue</>,
    },
    handle() {
      return Promise.resolve()
    },
  }
}
