import {CardTone} from '@sanity/ui'

export const tones: {tone: CardTone; title: string}[] = [
  {tone: 'default', title: 'None'},
  {tone: 'primary', title: 'Blue'},
  {tone: 'positive', title: 'Green'},
  {tone: 'caution', title: 'Yellow'},
  {tone: 'critical', title: 'Red'},
  {tone: 'transparent', title: 'Gray'},
]
