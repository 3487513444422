import {Avatar, type AvatarProps, type AvatarSize, Box, Card} from '@sanity/ui'
import {useMemo} from 'react'
import styled, {css} from 'styled-components'

import {ProviderLogo, type ProviderLogoProps} from './ProviderLogo'

function getInitials(name: string): string {
  const nameParts = name.trim().split(/\s+/)

  return nameParts.map((part) => part.charAt(0).toUpperCase()).join('')
}

const SIZE_MAP = [
  {
    size: 12,
    x: 4,
    y: 1,
    fontSize: 5,
  },
  {
    size: 16,
    x: 6,
    y: 2,
    fontSize: 6,
  },
  {
    size: 18,
    x: 6,
    y: 2,
    fontSize: 7,
  },
  {
    size: 22,
    x: 7,
    y: 2,
    fontSize: 8,
  },
  {
    size: 32,
    x: 7,
    y: 3,
    fontSize: 8,
  },
]

const LogoCard = styled(Card)<{$size?: AvatarSize}>(({$size}) => {
  const sizeIndex = $size ?? 0
  const {size, x, y, fontSize} = SIZE_MAP[sizeIndex]

  return css`
    &:empty {
      display: none;
    }

    position: absolute;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    border-radius: 50%;
    width: ${size}px;
    height: ${size}px;

    bottom: 0px;
    right: 0px;
    transform: translate(${x}px, ${y}px);

    [data-ui='Label'] {
      font-size: ${fontSize}px;
    }

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;

      &:not([data-provider='sanity']) {
        width: 70%;
        height: 70%;
      }
    }
  `
})

const Root = styled(Box)`
  position: relative;
  max-width: max-content;
`

export interface UserAvatarProps extends Omit<AvatarProps, 'size' | 'initials'> {
  displayName: string
  provider?: ProviderLogoProps['provider']
  size?: AvatarSize
  providerSize?: AvatarSize
}

export function UserAvatar(props: UserAvatarProps) {
  const {
    displayName,
    provider,
    providerSize: providerSizeProp,
    size: sizeProp,
    ...avatarProps
  } = props

  const initials = useMemo(() => getInitials(displayName), [displayName])

  const size = sizeProp && sizeProp > 3 ? 2 : sizeProp
  const providerSize =
    providerSizeProp || ((sizeProp ? Math.max(0, sizeProp - 1) : 0) as AvatarSize)

  return (
    <Root>
      <Avatar
        initials={initials}
        size={size}
        title={displayName}
        __unstable_hideInnerStroke
        {...avatarProps}
      />

      {provider && (
        <LogoCard display="flex" sizing="border" shadow={1} tone="transparent" $size={providerSize}>
          <ProviderLogo provider={provider} />
        </LogoCard>
      )}
    </Root>
  )
}
