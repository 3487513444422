import React from 'react'
import {Stack, Heading, Text, Box, Tooltip, TooltipProps, Flex} from '@sanity/ui'
import {Icon, IconSymbol} from '@sanity/icons'

type Props = {
  title?: string | React.ReactNode
  description?: string | React.ReactNode
  icon?: IconSymbol | boolean
} & TooltipProps

export const InfoTooltip = ({
  icon = 'help-circle',
  title,
  description,
  children,
  disabled,
  ...rest
}: Props) => {
  return (
    <Tooltip
      portal
      disabled={!!disabled}
      content={
        <Box padding={4} style={{maxWidth: 280}}>
          <Stack space={4}>
            {title && (
              <Flex align="center">
                {typeof icon !== 'boolean' && (
                  <Heading size={1} muted>
                    <Icon symbol={icon} />
                  </Heading>
                )}
                <Box marginLeft={3}>
                  {typeof title === 'string' ? <Heading size={1}>{title}</Heading> : title}
                </Box>
              </Flex>
            )}
            <Text size={1} muted>
              {description}
            </Text>
          </Stack>
        </Box>
      }
      {...rest}
    >
      <Box>{children}</Box>
    </Tooltip>
  )
}
