import {type Role} from '@sanity/access-api'
import {AddCircleIcon, RemoveCircleIcon, SelectIcon, TrashIcon} from '@sanity/icons'
import {Card, Flex, Grid, MenuDivider, Stack} from '@sanity/ui'
import {useMemo} from 'react'

import {type MembersV2} from '@/types/members_v2'

import {Button} from '../../primitives/button/Button'
import {ProjectSelectMenuButton} from '../project-select-menu-button'
import {RoleSelectMenuButton} from '../role-select-menu-button'
import {AutoCompleteStack} from './AutoCompleteStack'
import {Prompt} from './Prompt'
import {type InvitationScope, type LocalInvitationItemValue, type LocalInvitee} from './types'

const EMPTY_ARRAY: [] = []

export interface InvitationItemProps {
  invitation: LocalInvitationItemValue
  members: MembersV2['member'][]
  multiProjectInvite?: boolean
  onInvitationResourceAdd: () => void
  onInvitationResourceRemove: (resourceItemId: string) => void
  onInviteeAdd: (payload: LocalInvitee) => void
  onInviteeRemove: (index: number) => void
  onRemove: () => void
  onResourceSelect: (resourceId: string, resourceItemId: string) => void
  onRoleSelect: (roleName: string, resourceItemId: string) => void
  organizationRoles?: Role[]
  organizationId?: string
  projectsData: MembersV2['projectData'][]
  scope: InvitationScope
  showRemoveButton?: boolean
}

export function InvitationItem(props: InvitationItemProps) {
  const {
    invitation,
    members,
    multiProjectInvite,
    onInvitationResourceAdd,
    onInvitationResourceRemove,
    onInviteeAdd,
    onInviteeRemove,
    onRemove,
    onResourceSelect,
    onRoleSelect,
    organizationRoles,
    organizationId,
    projectsData,
    scope,
    showRemoveButton,
  } = props

  const allInvitees = useMemo(() => invitation.invitees, [invitation.invitees])
  const hasInvitees = allInvitees.length > 0

  const canAddMore = useMemo(() => {
    return invitation.resources.length < projectsData.length
  }, [invitation, projectsData.length])

  return (
    <Card border radius={3} overflow="auto">
      <Stack>
        <Stack>
          <Flex
            align={hasInvitees ? 'flex-start' : 'center'}
            gap={2}
            padding={2}
            paddingLeft={4}
            paddingRight={3}
          >
            <Stack flex={1}>
              <AutoCompleteStack
                invitees={allInvitees}
                members={members}
                onInviteeRemove={onInviteeRemove}
                onSubmit={onInviteeAdd}
                scope={scope}
              />
            </Stack>

            {showRemoveButton && (
              <Button
                aria-label="Remove invitation"
                fontSize={1}
                icon={TrashIcon}
                mode="bleed"
                onClick={onRemove}
                padding={3}
                tooltipProps={{content: 'Remove invitation'}}
              />
            )}
          </Flex>

          {hasInvitees && (
            <Card borderTop padding={3}>
              <Stack space={3}>
                {invitation.resources.map((resource, resourceIndex) => {
                  const isLast = resourceIndex === invitation.resources.length - 1
                  const showDivider = invitation.resources.length > 1 && !isLast

                  // Get project options, excluding already selected projects
                  const projectOptions = projectsData
                    .map((p) => p.projectProfile)
                    .filter(
                      (p) =>
                        !invitation.resources.some((i) => i.resourceId === p.id) ||
                        p.id === resource.resourceId
                    )

                  // Determine role options based on scope
                  const projectRoleOptions = projectsData.find(
                    (p) => p.projectProfile.id === resource.resourceId
                  )?.roleSchemas

                  const roleOptions =
                    scope === 'organization' ? organizationRoles : projectRoleOptions

                  // Find selected project
                  const selectedProject = projectsData.find(
                    (p) => p.projectProfile.id === resource.resourceId
                  )

                  const selectedProjectProfile = selectedProject?.projectProfile

                  const promptResourceId =
                    scope === 'organization' ? organizationId : resource.resourceId
                  const promptResourceRoles =
                    (scope === 'organization' ? organizationRoles : projectRoleOptions) || []

                  const selectedRole =
                    scope === 'organization'
                      ? organizationRoles?.find((r) => r.name === resource.roleName)
                      : projectRoleOptions?.find((r) => r.name === resource.roleName)

                  return (
                    <Stack key={resource._id} space={3}>
                      {selectedRole && promptResourceId && (
                        <Prompt
                          invitees={allInvitees}
                          members={members}
                          selectedRole={selectedRole}
                          scope={scope}
                          resourceId={promptResourceId}
                          resourceRoles={promptResourceRoles}
                        />
                      )}

                      <Flex align="center" gap={2}>
                        <Grid
                          columns={multiProjectInvite && scope === 'project' ? 2 : 1}
                          gap={2}
                          flex={1}
                        >
                          {scope === 'project' && multiProjectInvite && (
                            <ProjectSelectMenuButton
                              options={projectOptions}
                              closeOnSelect
                              onSelect={(p) => onResourceSelect(p.id, resource._id)}
                              selectedProjects={
                                selectedProjectProfile ? [selectedProjectProfile] : EMPTY_ARRAY
                              }
                              popoverButtonProps={{
                                button: (
                                  <Button
                                    mode="ghost"
                                    tooltipProps={{content: 'Select project'}}
                                    text={selectedProjectProfile?.displayName || 'Select project'}
                                    iconRight={SelectIcon}
                                    width="fill"
                                    justify="space-between"
                                  />
                                ),
                              }}
                            />
                          )}

                          <RoleSelectMenuButton
                            options={roleOptions || EMPTY_ARRAY}
                            closeOnSelect
                            selectedRoles={selectedRole ? [selectedRole] : EMPTY_ARRAY}
                            onSelect={(r) => {
                              if (!r.name) return
                              onRoleSelect(r.name, resource._id)
                            }}
                            popoverButtonProps={{
                              matchReferenceWidth: true,
                              button: (
                                <Button
                                  disabled={
                                    multiProjectInvite && scope === 'project' && !selectedProject
                                  }
                                  mode="ghost"
                                  tooltipProps={{content: 'Select role'}}
                                  text={selectedRole?.title || selectedRole?.name || 'Select role'}
                                  iconRight={SelectIcon}
                                  width="fill"
                                  justify="space-between"
                                />
                              ),
                            }}
                          />
                        </Grid>

                        {invitation.resources.length > 1 && (
                          <Button
                            aria-label={`Remove project ${selectedProjectProfile?.displayName} from invitation`}
                            fontSize={1}
                            icon={RemoveCircleIcon}
                            mode="bleed"
                            onClick={() => onInvitationResourceRemove(resource._id)}
                            padding={2}
                            tone="critical"
                            tooltipProps={{content: 'Remove project from invitation'}}
                          />
                        )}
                      </Flex>

                      {showDivider && <MenuDivider />}
                    </Stack>
                  )
                })}
              </Stack>
            </Card>
          )}
        </Stack>

        {scope === 'project' && hasInvitees && multiProjectInvite && (
          <Flex padding={3} paddingTop={0} sizing="border">
            <Button
              disabled={!canAddMore}
              icon={AddCircleIcon}
              mode="bleed"
              onClick={onInvitationResourceAdd}
              text="Add more"
            />
          </Flex>
        )}
      </Stack>
    </Card>
  )
}
