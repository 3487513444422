import {Box, Heading, Stack, Text} from '@sanity/ui'
import React, {useCallback} from 'react'
import {useWizardStore, WizardStep, WizardStepProps} from '@/components/payment-wizard'
import {CardDeclinedWarning} from '@/ui/views/wizards/common/cardDeclinedWarning'
import {sendAmplitudeTrackingEvent} from '@/utils/tracking'
import {useCurrentScopeContext} from '@/context/index'

function ChangePlanIntroStep(props: WizardStepProps) {
  const {steps, currentStep, params} = props
  const {project} = params
  const {dispatch, state} = useWizardStore()
  const myIndex = steps.indexOf(currentStep)
  const nextStep = steps[myIndex + 1]
  const nextStepIsAddBilling = nextStep && nextStep.id === 'add-billing-details'
  const nextStepIsSelectOrg = nextStep && nextStep.id === 'transfer-to-org'

  const handleMoveOrgLinkClicked = useCallback(() => {
    if (project.organizationId) {
      dispatch({type: 'setForceShowTransferStep', value: true})
      dispatch({type: 'setStepIndex', value: myIndex + 1})
    }
  }, [dispatch, myIndex, project.organizationId])

  return (
    <Box>
      <Stack space={4}>
        {nextStepIsSelectOrg && (
          <Stack space={4}>
            <Heading as="h1">Get Started with Organizations</Heading>
            <Text as="p" muted>
              In order to upgrade your plan, this project needs to be a part of an organization with
              up to date billing information. It only takes a couple of minutes to set this up, and
              we will walk you through the process here.
            </Text>
          </Stack>
        )}
        {nextStepIsAddBilling && (
          <Stack space={4}>
            {state.existingCardDeclined ? (
              <>
                <Box marginBottom={3}>
                  <CardDeclinedWarning />
                </Box>
                <Heading as="h1">Update Billing Information</Heading>
              </>
            ) : (
              <Heading as="h1">Add Billing Information</Heading>
            )}
            <Text as="p" muted>
              In order to upgrade your plan, your project needs to have billing information
              associated to it. It will only take a couple of minutes to set this up.
            </Text>
          </Stack>
        )}
        {!nextStepIsSelectOrg && project.organizationId && (
          <Stack space={4}>
            <Text as="p" muted>
              You may also move the project to{' '}
              <a onClick={handleMoveOrgLinkClicked}>
                <strong>another organization</strong>
              </a>{' '}
              if you want.
            </Text>
          </Stack>
        )}
      </Stack>
    </Box>
  )
}

export function useChangePlanIntroStep(): WizardStep | null {
  const {project, org} = useCurrentScopeContext()
  const {state} = useWizardStore()
  if (state.subscriptionIncomplete) {
    return null
  }
  return {
    id: 'create-dataset-intro',
    title: 'Intro',
    completed: true,
    component: ChangePlanIntroStep,
    disabled: false,
    action: {
      label: <>Continue</>,
    },
    handle() {
      sendAmplitudeTrackingEvent('Switch Plan Step Completed', project?.id, org?.id, {
        stepName: 'Intro',
      })
      return Promise.resolve()
    },
  }
}
