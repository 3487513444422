import {useMutation} from '@tanstack/react-query'
import {deleteCurrentUser} from '.'
import {isAPIError} from '@/data/util/promiseRequest'

type DeletionError = Error & {name: 'DeletionError'}

export function useDeleteCurrentUser() {
  return useMutation({
    mutationKey: ['deleteCurrentUser'],
    mutationFn: () => deleteCurrentUser(),
    onError: (error) => {
      if (isAPIError(error) && error.statusCode >= 400 && error.statusCode < 500) {
        const deletionError = new Error(error.message) as DeletionError
        deletionError.name = 'DeletionError'
        throw deletionError
      }
      throw error
    },
  })
}
