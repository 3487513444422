import {Observable} from 'rxjs'
import {map} from 'rxjs/operators'
import {apiRequest} from '../util/request'
import {DocumentWebhook, DocumentWebhookFields} from '../../types/models'

const headers = {
  'Content-Type': 'application/json',
}

export function getWebhooks(projectId: string): Observable<DocumentWebhook[]> {
  return apiRequest<any[]>({
    url: `https://api.${process.env.host}/v2021-10-04/hooks/projects/${projectId}`,
  }).pipe(
    map<any[], DocumentWebhook[]>((hooks) => hooks.filter((hook) => hook.type === 'document'))
  )
}

export function addWebhook(
  projectId: string,
  fields: DocumentWebhookFields
): Observable<DocumentWebhook> {
  return apiRequest<DocumentWebhook>({
    url: `https://api.${process.env.host}/v2021-10-04/hooks/projects/${projectId}`,
    method: 'POST',
    headers,
    body: {type: 'document', ...fields},
  })
}

export function updateWebhook(
  projectId: string,
  webhookId: string,
  fields: Partial<DocumentWebhookFields>
): Observable<DocumentWebhook> {
  return apiRequest<DocumentWebhook>({
    url: `https://api.${process.env.host}/v2021-10-04/hooks/projects/${projectId}/${webhookId}`,
    method: 'PATCH',
    headers,
    body: fields,
  })
}

export function deleteWebhook(projectId: string, hookId: string): Observable<DocumentWebhook> {
  return apiRequest<DocumentWebhook>({
    url: `https://api.${process.env.host}/v2021-10-04/hooks/projects/${projectId}/${hookId}`,
    method: 'DELETE',
  })
}

/**
 * Returns a duplicate of a webhook (which will be disabled by default.)
 */
export function duplicateWebhook(
  source: DocumentWebhook,
  {existingNames}: {existingNames: string[]}
): DocumentWebhookFields {
  // We try to be a bit smart when coming up with a new name
  let num: number
  let prefix: string
  let name: string

  const m = source.name.match(/^(.*)(\d+)$/)
  if (m) {
    // There's already a number at the end. Increment this.
    prefix = m[1]
    num = Number(m[2]) + 1
  } else {
    // Otherwise we just append 2.
    prefix = source.name
    num = 2
  }

  // Keep incrementing the number until we find a free one.
  for (;;) {
    name = prefix + num
    if (!existingNames.includes(name)) break
    num++
  }

  return {
    name,
    description: source.description,
    url: source.url,
    dataset: source.dataset,
    rule: source.rule,
    httpMethod: source.httpMethod,
    apiVersion: source.apiVersion,
    includeDrafts: source.includeDrafts,
    secret: source.secret,
    headers: source.headers,
    isDisabledByUser: true,
  }
}

export function attemptsUrlFor(projectId: string, hookId: string): string {
  return `https://api.${process.env.host}/v2021-10-04/hooks/projects/${projectId}/${hookId}/attempts`
}
