import React from 'react'
import {Box, Card, Flex, Stack, Text} from '@sanity/ui'
import styled from 'styled-components'

const StyledBox = styled(Box)`
  text-decoration: none;
`

const StyledCard = styled(Card)`
  &:hover {
    background: ${({theme}) => theme.sanity.color.card.hovered.bg};
  }
`

type Props = {
  items: {
    title: string
    description?: string
    url: string
    icon: React.ReactNode
  }[]
}

export function LinkList({items}: Props) {
  return (
    <Card padding={2} border radius={2}>
      <Stack space={1}>
        {items.map((item) => (
          <StyledCard key={item.title} radius={2}>
            <StyledBox as="a" href={item.url} target="_blank">
              <Flex align="center" padding={3}>
                <Box marginX={2}>
                  <Text size={4}>{item.icon}</Text>
                </Box>
                <Stack space={3} marginLeft={3}>
                  <Text size={1} weight="semibold">
                    {item.title}
                  </Text>
                  {item.description && (
                    <Text size={1} muted>
                      {item.description}
                    </Text>
                  )}
                </Stack>
              </Flex>
            </StyledBox>
          </StyledCard>
        ))}
      </Stack>
    </Card>
  )
}
