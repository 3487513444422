import {useQuery} from '@tanstack/react-query'
import {getOrganizationPlans} from './controller'
import {getOrganizationPlansKey} from './cache'

export function useOrganizationPlans(options?: {enabled?: boolean}) {
  const {enabled = true} = options || {}
  return useQuery({
    queryKey: getOrganizationPlansKey(),
    queryFn: () => getOrganizationPlans(),
    enabled,
  })
}
