import React, {useCallback, useMemo, useState} from 'react'
import {useRouter} from 'next/router'
import {useInviteMemberStep} from './steps/inviteMembersStep'
import {useInviteMembersIntroStep} from './steps/inviteMembersIntroStep'
import {useProjectSubscription} from '@/data/projects/useProjectSubscription'
import {useOrganizationCanPay} from '@/data/organizations'
import {
  useAddBillingDetailsStep,
  useChangePlanStep,
  useCreateNewOrgStep,
  useTransferToOrgStep,
  useWizardStore,
  WizardCompletionCallback,
  WizardDialog,
  WizardParams,
  WizardStep,
} from '@/components/payment-wizard'
import {useProjectType} from '@/context/index'
import {
  canPayOverage,
  countNonAdminUsers,
  countNonViewerUsers,
  getProjectMemberState,
} from '@/utils/usage'
import {Plan, Project} from '@/types/index'

function useSelectablePlans(
  project: Project,
  currentPlanId: string,
  plans: Plan[],
  enterprisePlan?: Plan
) {
  const {membersCount, nonAdminMembersCount, nonViewerMembersCount} = useMemo(
    () => ({
      membersCount: project.members.filter((m) => !m.isRobot).length,
      nonAdminMembersCount: countNonAdminUsers(project),
      nonViewerMembersCount: countNonViewerUsers(project),
    }),
    [project]
  )

  const selectablePlans = useMemo(
    () =>
      plans
        .filter((plan) => {
          if (!plan.orderable || !plan.public || plan.id === currentPlanId) {
            return false
          }

          const isNonAdminUsersBased = plan.resources.non_admin_users.quota !== null
          const isNonViewersUsersBased = plan.resources.non_viewer_users.quota !== null

          let resource = plan.resources.users
          let billableMembersCount = membersCount
          if (isNonAdminUsersBased) {
            resource = plan.resources.non_admin_users
            billableMembersCount = nonAdminMembersCount
          } else if (isNonViewersUsersBased) {
            resource = plan.resources.non_viewer_users
            billableMembersCount = nonViewerMembersCount
          }

          const maxMembersCount = (resource.quota ?? Infinity) + (resource.maxOverageQuota ?? 0)

          return billableMembersCount <= maxMembersCount
        })
        .sort((a, b) => (a.price ?? 0) - (b.price ?? 0))
        .concat(enterprisePlan || []),
    [
      currentPlanId,
      enterprisePlan,
      membersCount,
      nonAdminMembersCount,
      nonViewerMembersCount,
      plans,
    ]
  )

  return selectablePlans
}

export function InviteMembersWizard(props: {
  params: WizardParams
  onComplete?: WizardCompletionCallback
}) {
  const router = useRouter()
  const {dispatch, state} = useWizardStore()

  const {onComplete: handleWizardComplete, params} = props
  const {plans, org} = params
  const project = params.project
  const {data: subscription} = useProjectSubscription(project.id)
  const {data: canPay} = useOrganizationCanPay(org?.id)

  const projectType = useProjectType(project)
  const memberState = getProjectMemberState(project, projectType, subscription?.resources, org)

  const [showAllWizardSteps] = useState(() => {
    if (state.stepIndex > 0) {
      return true
    }

    if (memberState.resource !== 'users') {
      return false
    }

    if (memberState.billable < memberState.quota) {
      return false
    }

    const canPayOverageSeats = canPayOverage(subscription, org, canPay)

    if (memberState.billable < memberState.max && canPayOverageSeats) {
      return false
    }

    return true
  })
  const onOrganizationAssigned = useCallback(
    (orgId: string) => {
      const basePath = `/organizations/${orgId}`
      const {query} = router
      return router.push(`${basePath}/project/${project.id}/${query.tabId}`, undefined, {
        shallow: true,
      })
    },
    [project, router]
  )
  const onInvitationsSent = useCallback(() => {
    const basePath = `/organizations/${org?.id}`
    const {query} = router
    return router.push(`${basePath}/project/${project.id}/${query.tabId}/invitations`, undefined, {
      shallow: true,
    })
  }, [project, router, org])

  const enterprisePlan = useMemo(() => plans?.find((p) => p.planTypeId === 'enterprise'), [plans])

  const selectablePlans = useSelectablePlans(
    project,
    subscription?.plan?.id ?? '',
    plans,
    enterprisePlan
  )

  const _params: WizardParams = useMemo(
    () => ({
      onOrganizationAssigned,
      onInvitationsSent,
      ...params,
      plans: selectablePlans,
    }),
    [onInvitationsSent, onOrganizationAssigned, params, selectablePlans]
  )

  const handleWizardClose = useCallback(() => {
    dispatch({type: 'inviteMembersWizard/close'})
    dispatch({type: 'reset'})
  }, [dispatch])

  const introStep = useInviteMembersIntroStep()
  const transferStep = useTransferToOrgStep(_params)
  const createNewOrgStep = useCreateNewOrgStep()
  const addBillingDetails = useAddBillingDetailsStep(_params)
  const changePlanStep = useChangePlanStep(_params)
  const inviteMemberStep = useInviteMemberStep()
  const steps = useMemo(() => {
    let _steps = (
      showAllWizardSteps
        ? [
            introStep,
            transferStep,
            createNewOrgStep,
            addBillingDetails,
            changePlanStep,
            inviteMemberStep,
          ]
        : [inviteMemberStep]
    ).filter(Boolean) as WizardStep[]
    // Remove intro step if needed
    if (_steps.length === 2 && _steps[0] === introStep && _steps[1] === inviteMemberStep) {
      _steps = [inviteMemberStep]
    }
    return _steps
  }, [
    addBillingDetails,
    changePlanStep,
    createNewOrgStep,
    introStep,
    inviteMemberStep,
    showAllWizardSteps,
    transferStep,
  ])

  if (steps.length === 0) {
    return null
  }

  return (
    <WizardDialog
      onClose={handleWizardClose}
      onComplete={handleWizardComplete}
      steps={steps}
      params={_params}
      title="Invite members"
    />
  )
}
