import React from 'react'
import {Box, TooltipProps} from '@sanity/ui'
import {IconSymbol} from '@sanity/icons'
import {InfoTooltip} from '.'

type Props = {
  data?: unknown[]
  title?: string | React.ReactNode
  description?: string | React.ReactNode
  icon?: IconSymbol | boolean
} & TooltipProps

export const NoElementsTooltip = ({
  data = [],
  icon = 'info-outline',
  title = 'No elements',
  description = 'There are no elements',
  children,
  disabled,
}: Props) => {
  return (
    <InfoTooltip
      portal
      disabled={disabled || data.length > 0}
      title={title}
      icon={icon}
      description={description}
    >
      <Box>{children}</Box>
    </InfoTooltip>
  )
}
