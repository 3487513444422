import React, {useEffect} from 'react'
import {Text} from '@sanity/ui'
import {Icon, IconSymbol} from '@sanity/icons'
import {sendTrackingEvent} from '@/utils/tracking'
import {BannerStatus} from '@/components/arrears/utils'
import {Banner} from '@/components/banner'

export function TextIcon({iconSymbol, textColor}: {iconSymbol: IconSymbol; textColor: string}) {
  return (
    <Text style={{paddingRight: '4px'}}>
      <Icon style={{marginLeft: 0}} color={textColor} symbol={iconSymbol} />
    </Text>
  )
}

function statusToIcon(status: BannerStatus): IconSymbol {
  switch (status) {
    case 'block':
    case 'can_resolve':
      return 'error-outline'
    case 'warn':
      return 'warning-outline'
    default:
      throw Error(`Unknown status: ${status}`)
  }
}

type BannerData = {
  iconSymbol: IconSymbol
  prompt: string
  explanation: string
  callToActionCopy: string
  callToActionHref?: string
}

function buildBannerData(
  organizationId: string,
  status: BannerStatus,
  isBillingAdmin: boolean,
  reviewAt?: string
): BannerData {
  switch (status) {
    case 'block':
    case 'can_resolve':
      return {
        iconSymbol: statusToIcon(status),
        prompt: 'All projects are blocked',
        explanation: 'from all further use due to payments overdue.',
        callToActionCopy: isBillingAdmin
          ? 'Resolve payment'
          : 'Contact administrator to resolve payment',
        callToActionHref: isBillingAdmin
          ? `/organizations/${organizationId}/billing`
          : `/organizations/${organizationId}`,
      }
    case 'warn': {
      const explanation = reviewAt
        ? `An unresolved payment will block all projects on ${reviewAt}.`
        : 'An unresolved payment will block all projects soon.'

      return {
        iconSymbol: statusToIcon(status),
        prompt: 'Payment overdue.',
        explanation: explanation,
        callToActionCopy: isBillingAdmin
          ? 'Resolve payment'
          : 'Contact administrator to resolve this',
        callToActionHref: isBillingAdmin
          ? `/organizations/${organizationId}/billing`
          : `/organizations/${organizationId}`,
      }
    }
    default:
      throw Error(`Unknown status: ${status}`)
  }
}

export function ArrearsBanner({
  organizationId,
  status,
  reviewAt,
  isBillingAdmin,
}: {
  organizationId: string
  status: BannerStatus
  reviewAt?: string
  isBillingAdmin: boolean
}) {
  const tone = status === 'block' || status === 'can_resolve' ? 'critical' : 'caution'
  const bannerData = buildBannerData(organizationId, status, isBillingAdmin, reviewAt)

  useEffect(() => {
    sendTrackingEvent('ArrearsBanner-View', undefined, organizationId)
  }, [organizationId])

  return (
    <Banner
      tone={tone}
      icon={bannerData.iconSymbol}
      title={bannerData.prompt}
      description={bannerData.explanation}
      ctaText={bannerData.callToActionCopy}
      ctaHref={bannerData.callToActionHref}
      ctaOnClick={() => sendTrackingEvent('ArrearsBanner-Click-CTA', undefined, organizationId)}
    />
  )
}
