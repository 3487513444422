import {CloseIcon, EnvelopeIcon, UserIcon} from '@sanity/icons'
import {Box, Card, Flex, Stack, Text} from '@sanity/ui'
import {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'

import {type MembersV2} from '@/types/members_v2'

import {UserAvatar} from '../../generic/user-avatar'
import {Button} from '../../primitives/button/Button'
import {Autocomplete} from './AutoComplete'
import {type InvitationScope, type LocalInvitee} from './types'

const PrefixFlex = styled(Flex)`
  width: 20px;
  height: 20px;
`

const RemoveButton = styled(Button)`
  opacity: 0.5;
  transition: opacity 0.2s;

  &:hover,
  &:focus {
    opacity: 1;
  }
`

export interface AutoCompleteStackProps {
  invitees: LocalInvitee[]
  members: MembersV2['member'][]
  onInviteeRemove: (index: number) => void
  onSubmit: (payload: LocalInvitee) => void
  scope: InvitationScope
}

export function AutoCompleteStack(props: AutoCompleteStackProps) {
  const {invitees, onInviteeRemove, onSubmit, members, scope} = props
  const [textInputElement, setInputElement] = useState<HTMLInputElement | null>(null)

  const handleSubmit = useCallback(
    (payload: LocalInvitee) => {
      onSubmit(payload)
      textInputElement?.focus()
    },
    [onSubmit, textInputElement]
  )

  const handleRemoveInvitee = useCallback(
    (index: number) => {
      onInviteeRemove(index)
      textInputElement?.focus()
    },
    [onInviteeRemove, textInputElement]
  )

  const filteredMembers = useMemo(() => {
    return members.filter((v) => !invitees.find((invitee) => invitee.memberId === v.profile?.id))
  }, [invitees, members])

  return (
    <Stack>
      <Flex>
        <Flex align="center" justify="center" marginRight={3} style={{height: 36}}>
          <Text size={1}>
            <UserIcon />
          </Text>
        </Flex>

        <Flex align="center" wrap="wrap" gap={2} flex={1}>
          {invitees?.map((invitee, index) => {
            const member = members.find((member) => member.profile?.id === invitee.memberId)

            const text = member ? member.profile?.displayName : invitee.email

            return (
              <Card key={invitee.email} tone="transparent" radius={3} padding={2}>
                <Flex align="center" gap={1}>
                  <PrefixFlex align="center" justify="center">
                    {member?.profile && (
                      <UserAvatar
                        displayName={member.profile?.displayName || ''}
                        src={member.profile?.imageUrl || ''}
                        size={0}
                      />
                    )}

                    {!member && (
                      <Text size={1} muted>
                        <EnvelopeIcon />
                      </Text>
                    )}
                  </PrefixFlex>

                  <Flex flex={1}>
                    <Box>
                      <Text muted size={1} textOverflow="ellipsis">
                        {text}
                      </Text>
                    </Box>
                  </Flex>

                  <RemoveButton
                    fontSize={0}
                    icon={CloseIcon}
                    mode="bleed"
                    onClick={() => handleRemoveInvitee(index)}
                    padding={1}
                    aria-label={`Remove ${text}`}
                    tooltipProps={{
                      content: `Remove`,
                    }}
                  />
                </Flex>
              </Card>
            )
          })}

          <Stack style={{minWidth: 250, flexGrow: 1}}>
            <Autocomplete
              members={filteredMembers}
              onSubmit={handleSubmit}
              scope={scope}
              setTextInputElement={setInputElement}
            />
          </Stack>
        </Flex>
      </Flex>
    </Stack>
  )
}
