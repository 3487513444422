import React, {useMemo} from 'react'
import {format, subMonths} from 'date-fns'
import {Summary} from './summary'
import {useCurrentScopeContext} from '@/context/index'
import {formatPrice} from '@/utils/general'
import {useProjectsUsage, RANGE_CURRENT_MONTH, RANGE_PREVIOUS_MONTH} from '@/data/projects'

export function OrgCostsSummary() {
  const {org} = useCurrentScopeContext()

  const currentMonthUsage = useProjectsUsage(org?.projects, {
    ...RANGE_CURRENT_MONTH,
  })
  const previousMonthUsage = useProjectsUsage(org?.projects, {
    ...RANGE_PREVIOUS_MONTH,
  })

  const currentAndPreviousLoading =
    currentMonthUsage.some((usage) => usage.isLoading) ||
    previousMonthUsage.some((usage) => usage.isLoading)

  const currency = org?.organizationPlan?.currency || 'USD'
  const hasOrgPlan = org?.type === 'org-plan'
  const currentDate = new Date()

  const title = hasOrgPlan ? 'Estimated overage costs' : 'Total organization costs'

  const currentCost = useMemo(
    () =>
      currentMonthUsage.reduce((value, {data}) => {
        if (
          data === undefined ||
          org?.organizationPlan?.projectIds.includes(data.projectId) === false
        ) {
          return value
        }
        const cost = (hasOrgPlan ? data.overageCost : data.totalCost) || 0

        return value + cost
      }, 0),
    [hasOrgPlan, currentMonthUsage, org]
  )

  const previousCost = useMemo(
    () =>
      previousMonthUsage.reduce((value, {data}) => {
        if (
          data === undefined ||
          org?.organizationPlan?.projectIds.includes(data.projectId) === false
        ) {
          return value
        }
        const cost = (hasOrgPlan ? data.overageCost : data.totalCost) || 0

        return value + cost
      }, 0),
    [hasOrgPlan, previousMonthUsage, org]
  )

  const data = [
    {
      title: formatPrice(currentCost, 0, currency),
      subtitle: 'Current billing period',
      period: `1 - ${format(currentDate, 'MMM d')} (ongoing)`,
      key: 'current-period',
    },
    {
      title: formatPrice(previousCost, 0, currency),
      subtitle: 'Previous month',
      period: format(subMonths(currentDate, 1), 'MMM Y'),
      key: 'previous-period',
    },
  ]

  return <Summary title={title} data={data} loading={currentAndPreviousLoading} />
}
