import React, {useCallback, useRef, useMemo} from 'react'
import Link from 'next/link'
import {UsersIcon, LockIcon} from '@sanity/icons'
import {Stack, Text, Box, Card, Flex, Inline, AvatarStack, HeadingSkeleton} from '@sanity/ui'
import {ProjectMember, ProjectMemberRole} from '@/types/models'
import {MemberAvatar} from '@/ui/index'
import {useRoutePath} from '@/context/index'
import {useWindowVirtualizer} from '@tanstack/react-virtual'
import styled from 'styled-components'

const ListWrapper = styled.div.attrs<{$height: string}>(({$height}) => ({
  style: {
    height: $height,
  },
}))`
  width: '100%';
  position: relative;
`

const RoleItemWrapper = styled.div.attrs<{$height: string; $transform: string}>(
  ({$height, $transform}) => ({
    style: {
      height: $height,
      transform: $transform,
    },
  })
)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`

type TableProps = {
  roles: ProjectMemberRole[]
  users: ProjectMember[]
  loading?: boolean
}

function RoleItem({
  role,
  users,
  basePath,
}: {
  role: ProjectMemberRole
  users: ProjectMember[]
  basePath: string
}) {
  return (
    <Link href={`${basePath}/access/roles?role=${role.name}`} passHref legacyBehavior>
      <Card border paddingX={2} paddingY={3} paddingLeft={3} radius={2} as="a">
        <Flex align="center" paddingY={2} gap={5}>
          <Flex align="flex-start" flex={1}>
            <Box marginRight={3} paddingLeft={1}>
              <Text>
                <UsersIcon />
              </Text>
            </Box>
            <Stack space={2}>
              <Inline space={2}>
                <Text weight="semibold">{role.title}</Text>
                {!role.isCustom && (
                  <Text muted>
                    <LockIcon />
                  </Text>
                )}
              </Inline>
              <Text muted size={1}>
                {role.description || <em>No description</em>}
              </Text>
            </Stack>
          </Flex>

          <AvatarStack>
            {users
              .filter((user) => {
                return user.roles && !user.isRobot && user.roles.some((r) => r.name === role.name)
              })
              .map((user) => (
                <MemberAvatar key={`member-${user.id}`} userId={user.id} />
              ))}
          </AvatarStack>
        </Flex>
      </Card>
    </Link>
  )
}

function RolesTable({roles, users, loading}: TableProps) {
  const {basePath} = useRoutePath()
  const parentRef = useRef<HTMLDivElement | null>(null)
  const filteredRoles = useMemo(() => roles.filter((role) => role.appliesToUsers), [roles])
  const virtualizer = useWindowVirtualizer({
    count: filteredRoles.length,
    scrollMargin: parentRef.current?.scrollTop ?? 0,
    estimateSize: useCallback(() => 70, []),
    overscan: 10,
    gap: 12,
  })

  if (loading) {
    return (
      <Stack space={3}>
        {[...Array(10)].map((_item, i) => (
          <Card
            // eslint-disable-next-line react/no-array-index-key
            key={`skeleton-${i}`}
            border
            radius={2}
            paddingX={2}
            paddingY={4}
            paddingLeft={5}
            style={{minHeight: '70px'}}
          >
            <Flex justify="space-between" align="center">
              <Stack space={1} style={{paddingLeft: '0.5rem'}}>
                <HeadingSkeleton style={{width: '100px'}} animated />
                <HeadingSkeleton style={{width: '250px'}} animated />
              </Stack>
              <Box>
                <HeadingSkeleton animated style={{width: '50px'}} />
              </Box>
            </Flex>
          </Card>
        ))}
      </Stack>
    )
  }

  return (
    <Stack space={3} ref={parentRef}>
      <ListWrapper $height={`${virtualizer.getTotalSize()}px`}>
        {virtualizer.getVirtualItems().map((virtualRow) => {
          const role = filteredRoles[virtualRow.index]
          return (
            <RoleItemWrapper
              key={role.name}
              $height={`${virtualRow.size}px`}
              $transform={`translateY(${virtualRow.start - virtualizer.options.scrollMargin}px)`}
              ref={virtualizer.measureElement}
            >
              <RoleItem role={role} users={users} basePath={basePath} />
            </RoleItemWrapper>
          )
        })}
      </ListWrapper>
    </Stack>
  )
}

type Props = {
  roles: ProjectMemberRole[]
  users: ProjectMember[]
  loading?: boolean
}

export function RolesOverview({roles, users, loading}: Props) {
  return (
    <Box marginTop={6}>
      <RolesTable roles={roles} users={users} loading={loading} />
    </Box>
  )
}
