/* eslint-disable react/jsx-no-bind, import/no-unassigned-import */

import React from 'react'
import dynamic from 'next/dynamic'
import styled from 'styled-components'

export const CodeMirror = dynamic(
  async () => {
    const {Controlled} = await import('react-codemirror2')
    import('codemirror/mode/javascript/javascript')
    import('codemirror/addon/edit/closebrackets')
    require('codemirror/addon/display/placeholder')
    import('codemirror/lib/codemirror.css')
    return Controlled
  },
  {ssr: false}
)

const size = 1
const CSSWrapper = styled.div<{autoHeight?: boolean; padding?: number}>`
  * {
    font-size: ${({theme}) => theme.sanity.fonts.code.sizes[size].fontSize};
    font-family: ${({theme}) => theme.sanity.fonts.code.family};
  }

  .CodeMirror {
    ${({autoHeight = false}) => autoHeight && 'height: auto;'}
    padding: ${({theme, padding}) => padding !== undefined && `${theme.sanity.space[padding]}px`};
    background-color: ${({theme}) => theme.sanity.color.muted.default.enabled.bg};
    color: ${({theme}) => theme.sanity.color.card.enabled.fg};
  }

  .CodeMirror-cursor {
    border-color: ${({theme}) => theme.sanity.color.card.enabled.fg} !important;
  }

  .CodeMirror-placeholder {
    color: ${({theme}) => theme.sanity.color.card.enabled.muted.fg} !important;
  }
`

export function JSONInput({
  value,
  onChange,
  placeholder,
  autoHeight,
  padding,
}: {
  value: string
  onChange: (newValue: string) => void
  placeholder?: string
  autoHeight?: boolean
  padding?: number
}) {
  return (
    <CSSWrapper autoHeight={autoHeight} padding={padding}>
      <CodeMirror
        value={value}
        options={{
          mode: {name: 'javascript', json: true},
          tabSize: 2,
          smartIndent: true,
          autoCloseBrackets: true,
          placeholder: placeholder,
          extraKeys: {
            Tab: false,
            'Shift-Tab': false,
          },
        }}
        onBeforeChange={(_editor, _data, newValue) => {
          onChange(newValue)
        }}
      />
    </CSSWrapper>
  )
}
