import {Grid, Heading, Stack, Text} from '@sanity/ui'
import React from 'react'
import {Organization, Project, Section, Tab} from '@/types/index'
import {ChartSection, ChartSectionProject} from '@/ui/views/project/usage'
import {useCurrentScopeContext} from '@/context/index'

type ChartGroupSectionProps = {
  section?: Section
  title: string
  description?: string
  org?: Organization
  tab?: Tab
  project?: Project
}

type ChartSectionProps = {
  id: string
  title: string
}

type ChartGroupProps = {
  charts: ChartSectionProps[]
  isPlayground?: boolean
  actions?: any
} & Section

// @todo Context depending on org, project, org-plan
// @todo Context depending on billing
// @todo Define groups per section
// @todo Year: The project list should be grouped by quarter?
// @todo Grouped data summary at bottom of section should be configurable
// @todo Toggle types
// @todo Handle toggle type
// @todo Contextual items: View associated costs

const CHARTGROUPS: ChartGroupProps[] = [
  {
    title: 'Bandwidth & APIs',
    heading: 'org-usage',
    path: 'bandwidth-api',
    charts: [
      {
        id: 'bandwidth',
        title: 'Bandwidth',
      },
      {
        id: 'apiRequests',
        title: 'API requests',
      },
      {
        id: 'apiCdnRequests',
        title: 'API CDN requests',
      },
    ],
    actions: [],
  },
  {
    title: 'Resources & datasets',
    heading: 'org-usage',
    path: 'resources',
    charts: [
      {
        id: 'assets',
        title: 'Assets',
      },
      {
        id: 'documents',
        title: 'Documents',
      },
      {
        id: 'datasets',
        title: 'Datasets',
      },
    ],
  },
  {
    title: 'Projects & members',
    heading: 'org-usage',
    path: 'project-members',
    charts: [
      {
        id: 'organizationProjects',
        title: 'Organization projects',
      },
      {
        id: 'playgroundProjects',
        title: 'Playground projects',
      },
      {
        id: 'users',
        title: 'Organization members',
      },
      {
        id: 'projectMembers',
        title: 'Project members',
      },
    ],
  },
]

function ChartGroup(chartGroup: ChartGroupProps) {
  const currentScope = useCurrentScopeContext()
  const isProject = currentScope?.scope === 'project' && currentScope?.project !== undefined

  return (
    <Grid gap={5}>
      {chartGroup.charts.map((chart) =>
        isProject ? (
          <ChartSectionProject isPlayground={chartGroup.isPlayground} key={chart.id} {...chart} />
        ) : (
          <ChartSection isPlayground={chartGroup.isPlayground} key={chart.id} {...chart} />
        )
      )}
    </Grid>
  )
}

export function ChartGroupSection({section, title, description}: ChartGroupSectionProps) {
  return (
    <Stack space={5} id={section?.path}>
      <Heading size={1} as="h3">
        {title}
      </Heading>
      {description && (
        <Text size={1} muted>
          {description}
        </Text>
      )}
      {/* <pre>{JSON.stringify(section, null, 2)}</pre> */}
      <Stack space={5}>
        {CHARTGROUPS.filter((group) => group.path === (section?.path || '').replace('pg_', '')).map(
          (group) => (
            <ChartGroup
              key={`${section?.path}-${group.path}`}
              {...group}
              isPlayground={section?.heading.includes('playground')}
            />
          )
        )}
      </Stack>
      {/* <Grid gap={5} columns={[1, 1, 1, columns || 1]}>
        <pre>{JSON.stringify(, section)}</pre>
        {data.map((sectionData) => (
          <div key={`chart-${sectionData.heading}`} id={sectionData.id}>
            <LineChart
              isLoading={sectionIsLoading}
              usageType={slugify(sectionData.heading)}
              {...sectionData}
            />
          </div>
        ))}
      </Grid> */}
    </Stack>
  )
}
