import {useEffect, useMemo, useState} from 'react'
import {useCurrentScopeContext} from '@/context/useCurrentScopeContext'
import {Tab} from '@/types/components'
import {
  ACCESS_MANAGEMENT_FEATURE_FLAG,
  DASHBOARD_ROLLOUT_FEATURE_FLAG,
  REQUEST_ACCESS_TO_PROJECT_FLAG,
  useFeatureFlag,
} from '@/utils/tracking/growthBook'
import {getProjectTabs, getOrgPersonalTabs, getOrgPlanTabs, getOrgRegularTabs} from '../utils'
import {useProjectSubscription} from '@/data/projects/useProjectSubscription'
import {useGettingStartedStore} from '@/components/get-started/store'
import {useIsProjectInOnboardingXp} from '@/components/get-started/hooks/useIsProjectInOnboardingXp'
import {useRequiredProjectPermissions} from '@/context/useProjectPermissions'
import {useRequiredOrganizationPermissions} from '@/context/useOrganizationPermissions'

export class TabsPermissionError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'TabsPermissionError'
  }
}

interface TabStatus {
  tabs: Tab[]
  isLoading: boolean
  error: TabsPermissionError | Error | null
}

const EMPTY_TABS: Tab[] = []

const flagCache = new Map<string, boolean>()

const getCachedOrLoadingFlag = (flagName: string): boolean | null => {
  if (flagCache.has(flagName)) {
    return flagCache.get(flagName) || false
  }
  return null // Indicates the flag is still loading
}

/**
 *  A hook that returns the tabs for the current scope (org or project)
 */
export function useTabs(): TabStatus {
  const {org, project, isLoadingOrgs, isLoadingProjects, isLoadingPermissions} =
    useCurrentScopeContext() || {}

  const {data: subscription} = useProjectSubscription(project?.id, {
    enabled: !isLoadingPermissions,
  })
  const isLegacyPlan = useMemo(
    () => subscription?.plan?.name.toLowerCase().includes('legacy'),
    [subscription]
  )
  const [currentOrgId, setCurrentOrgId] = useState(org?.id)
  const [currentProjectId, setCurrentProjectId] = useState(project?.id)
  const [projectFeatures, setProjectFeatures] = useState(project?.features)
  const [hasOrgPlan, setHasOrgPlan] = useState(!!org?.organizationPlan)

  // If you don't have at least read access, return an error
  const hasProjectReadAccess = useRequiredProjectPermissions(
    {
      permissions: [
        {permissionName: 'sanity.project', grantName: 'read'},
        {permissionName: 'sanity.organization.projects', grantName: 'read'},
      ],
      allowPartialPermissions: true,
    },
    currentProjectId
  )
  const hasOrgProjectsReadAccess = useRequiredOrganizationPermissions({
    permissions: [{permissionName: 'sanity.organization.projects', grantName: 'read'}],
  })

  const permissionError = useMemo(() => {
    if (currentProjectId && !hasProjectReadAccess && !hasOrgProjectsReadAccess) {
      return new TabsPermissionError('You do not have read access to this project')
    }
    return null
  }, [currentProjectId, hasProjectReadAccess, hasOrgProjectsReadAccess])

  // This is a workaround to prevent re-mounting tab sections whenever the org changes
  useEffect(() => {
    if (org?.id !== currentOrgId) {
      setCurrentOrgId(org?.id)
    }

    const hasNewOrgPlan = !!org?.organizationPlan
    if (hasNewOrgPlan !== hasOrgPlan) {
      setHasOrgPlan(hasNewOrgPlan)
    }

    if (project?.id !== currentProjectId) {
      setCurrentProjectId(project?.id)
    }

    if (project?.features !== projectFeatures) {
      setProjectFeatures(project?.features)
    }
  }, [
    org?.id,
    org?.organizationPlan,
    currentOrgId,
    hasOrgPlan,
    project?.id,
    currentProjectId,
    project?.features,
    projectFeatures,
  ])

  const [requestAccessFlag, requestAccessFlagLoaded] = useFeatureFlag(
    REQUEST_ACCESS_TO_PROJECT_FLAG
  )
  const [accessManagementFlag, accessManagementFlagLoaded] = useFeatureFlag(
    ACCESS_MANAGEMENT_FEATURE_FLAG
  )
  const [dashboardRolloutFlag, dashboardRolloutFlagLoaded] = useFeatureFlag(
    DASHBOARD_ROLLOUT_FEATURE_FLAG
  )

  // We cache the flags because the *Loaded properties are not stable, meaning we can't use them
  // directly when determining if the flags are loaded.
  useEffect(() => {
    if (requestAccessFlagLoaded && accessManagementFlagLoaded) {
      const orgProjectKey = `${currentOrgId}:${currentProjectId}:`
      if (!flagCache.has(orgProjectKey + REQUEST_ACCESS_TO_PROJECT_FLAG)) {
        flagCache.set(orgProjectKey + REQUEST_ACCESS_TO_PROJECT_FLAG, requestAccessFlag)
      }
      if (!flagCache.has(orgProjectKey + ACCESS_MANAGEMENT_FEATURE_FLAG)) {
        flagCache.set(orgProjectKey + ACCESS_MANAGEMENT_FEATURE_FLAG, accessManagementFlag)
      }
      if (!flagCache.has(orgProjectKey + DASHBOARD_ROLLOUT_FEATURE_FLAG)) {
        flagCache.set(orgProjectKey + DASHBOARD_ROLLOUT_FEATURE_FLAG, dashboardRolloutFlag)
      }
    }
  }, [
    requestAccessFlag,
    requestAccessFlagLoaded,
    accessManagementFlag,
    accessManagementFlagLoaded,
    dashboardRolloutFlag,
    dashboardRolloutFlagLoaded,
    currentOrgId,
    currentProjectId,
  ])

  const [cachedRequestAccessFlag, setCachedRequestAccessFlag] = useState(
    () => getCachedOrLoadingFlag(REQUEST_ACCESS_TO_PROJECT_FLAG) ?? requestAccessFlag
  )
  const [cachedAccessManagementFlag, setCachedAccessManagementFlag] = useState(
    () => getCachedOrLoadingFlag(ACCESS_MANAGEMENT_FEATURE_FLAG) ?? accessManagementFlag
  )
  const [cachedDashboardRolloutFlag, setCachedDashboardRolloutFlag] = useState(
    () => getCachedOrLoadingFlag(DASHBOARD_ROLLOUT_FEATURE_FLAG) ?? dashboardRolloutFlag
  )

  // Update the cached flags only if the flags is enabled
  useEffect(() => {
    if (requestAccessFlag === true) {
      setCachedRequestAccessFlag(true)
    }
  }, [accessManagementFlag, requestAccessFlag])

  useEffect(() => {
    if (accessManagementFlag === true) {
      setCachedAccessManagementFlag(true)
    }
  }, [accessManagementFlag])

  useEffect(() => {
    if (dashboardRolloutFlag === true) {
      setCachedDashboardRolloutFlag(true)
    }
  }, [dashboardRolloutFlag])

  const {state: getStartedState} = useGettingStartedStore()
  const [getStartedEnabled, getStartedLoading] = useIsProjectInOnboardingXp()
  const isLoading = useMemo(() => {
    return getStartedLoading || isLoadingOrgs || isLoadingProjects || isLoadingPermissions
  }, [getStartedLoading, isLoadingOrgs, isLoadingProjects, isLoadingPermissions])

  return useMemo(() => {
    // Return empty tabs if the flags are still loading
    // since we don't know what tabs to show
    if (isLoading)
      return {
        tabs: EMPTY_TABS,
        isLoading: true,
        error: null,
      }

    if (!currentOrgId) {
      return {
        tabs: EMPTY_TABS,
        isLoading: false,
        error: null,
      }
    }

    if (permissionError) {
      return {
        tabs: EMPTY_TABS,
        isLoading: false,
        error: permissionError,
      }
    }

    // Return the tabs for the project
    if (currentProjectId) {
      return {
        tabs: getProjectTabs({
          projectFeatures,
          featuresFlags: {
            projectRequestAccessEnabled: cachedRequestAccessFlag,
            members_v2: cachedAccessManagementFlag,
            get_started: getStartedEnabled,
          },
          newPlanBadge: isLegacyPlan,
          getStartedDismissed: getStartedState.dismissed,
        }),
        isLoading: false,
        error: permissionError,
      }
    }

    // Return the tabs for a personal organization
    if (currentOrgId === 'personal') {
      return {
        tabs: getOrgPersonalTabs(),
        isLoading: false,
        error: null,
      }
    }

    // Return the tabs for an organization with an "organizationPlan"
    if (hasOrgPlan) {
      return {
        tabs: getOrgPlanTabs({
          featureFlags: {
            members_v2: cachedAccessManagementFlag,
            dashboard_rollout: cachedDashboardRolloutFlag,
          },
        }),
        isLoading: false,
        error: null,
      }
    }

    // Return the tabs for a regular organization
    if (currentOrgId) {
      return {
        tabs: getOrgRegularTabs({
          featureFlags: {
            members_v2: cachedAccessManagementFlag,
            dashboard_rollout: cachedDashboardRolloutFlag,
          },
        }),
        isLoading: false,
        error: null,
      }
    }

    return {
      tabs: EMPTY_TABS,
      isLoading: false,
      error: null,
    }
  }, [
    isLoading,
    currentOrgId,
    currentProjectId,
    hasOrgPlan,
    projectFeatures,
    cachedRequestAccessFlag,
    cachedAccessManagementFlag,
    cachedDashboardRolloutFlag,
    getStartedEnabled,
    isLegacyPlan,
    getStartedState.dismissed,
  ])
}
