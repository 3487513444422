import {TableHeader} from '@/types/index'

export const ACTIVE_LARGE_TABLE: TableHeader[] = [
  {
    id: 'details',
    label: 'Details',
    columnWidth: 5,
    disableTabButtonRender: true,
  },
  {
    id: 'actions',
    label: 'Actions',
    disableTabButtonRender: true,
    alignment: 'right',
  },
]

export const ACTIVE_SMALL_TABLE: TableHeader[] = [
  {
    id: 'picture',
    label: 'Picture',
    disableTabButtonRender: true,
    columnWidth: '45px',
  },
  {
    id: 'name',
    label: 'Name',
    columnWidth: 3,
  },
  {
    id: 'plan',
    label: 'Plan',
    columnWidth: 1.5,
  },
  {
    id: 'members',
    label: 'Members',
    columnWidth: 2,
  },
  {
    id: 'created',
    label: 'Created',
    columnWidth: 1.5,
  },

  {
    id: 'actions',
    label: 'Actions',
    disableTabButtonRender: true,
    alignment: 'right',
  },
]
