import {
  Card,
  focusFirstDescendant,
  focusLastDescendant,
  useClickOutside,
  useLayer,
} from '@sanity/ui'
import {cloneElement, forwardRef, useCallback, useEffect, useRef, useState} from 'react'
import styled from 'styled-components'

import {type PopoverButtonProps} from './PopoverButton'

const ContentCard = styled(Card)`
  display: flex;
  flex-direction: column;
`

export interface PopoverButtonContentProps extends PopoverButtonProps {
  id: string
  onClickOutside: (event: MouseEvent) => void
  onIsTopLayerChange: (isTopLayer: boolean) => void
  onKeyDown: (event: React.KeyboardEvent) => void
  focusLock?: boolean
}

export const PopoverButtonContent = forwardRef(function PopoverButtonContent(
  props: PopoverButtonContentProps,
  ref: React.Ref<HTMLDivElement>
) {
  const {
    autoFocus,
    content: contentProp,
    focusLock,
    id,
    onClickOutside,
    onIsTopLayerChange,
    onKeyDown,
  } = props

  const [popoverContentRoot, setPopoverContentRoot] = useState<HTMLDivElement | null>(null)

  const {isTopLayer} = useLayer()

  const preDivRef = useRef<HTMLDivElement | null>(null)
  const postDivRef = useRef<HTMLDivElement | null>(null)
  const innerRef = useRef<HTMLDivElement | null>(null)

  const handleFocus = useCallback((event: React.FocusEvent<HTMLDivElement>) => {
    const target = event.target
    const innerEl = innerRef.current

    if (innerEl && target === preDivRef.current) {
      focusLastDescendant(innerEl)
      return
    }

    if (innerEl && target === postDivRef.current) {
      focusFirstDescendant(innerEl)
    }
  }, [])

  const handleSetContentCardRef = useCallback(
    (el: HTMLDivElement | null) => {
      setPopoverContentRoot(el)

      if (typeof ref === 'function') {
        ref(el)
      }
    },
    [ref]
  )

  const clonedContent = cloneElement(contentProp, {
    ref: (el: HTMLDivElement) => {
      innerRef.current = el

      contentProp.props.ref?.(el)
    },
  })

  useClickOutside(
    (event: MouseEvent) => {
      if (isTopLayer) {
        onClickOutside(event)
      }
    },

    [popoverContentRoot]
  )

  useEffect(() => {
    if (innerRef.current && autoFocus) {
      focusFirstDescendant(innerRef.current)
    }
  }, [autoFocus])

  useEffect(() => {
    onIsTopLayerChange(isTopLayer)
  }, [isTopLayer, onIsTopLayerChange])

  return (
    <ContentCard
      aria-labelledby={id}
      data-testid="popover-button-content"
      display="flex"
      flex={1}
      height="fill"
      onFocus={handleFocus}
      onKeyDown={onKeyDown}
      overflow="hidden"
      radius={3}
      ref={handleSetContentCardRef}
    >
      {focusLock && <div ref={preDivRef} tabIndex={0} />}

      {clonedContent}

      {focusLock && <div ref={postDivRef} tabIndex={0} />}
    </ContentCard>
  )
})
