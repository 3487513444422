import {Organization, OrganizationRole} from '@/types/models/organization'
import {useMemo} from 'react'
import {useOrganizationsList} from './useOrganizations'
import {useOrganizationsRolesList} from './useOrganizationRoleList'

function mapOrgs(orgs?: Organization[], fromOrgId?: string) {
  return (
    orgs
      ?.filter((org) => org.id !== fromOrgId && org.id !== 'personal')
      .map((org) => ({
        id: org.id,
        name: org.name,
        projects: [], // Not needed in this context
        organization: org,
        members: org.members,
        features: org.features,
      })) ?? []
  )
}

function mapTransferableOrgs(
  fromOrgId?: string,
  orgs?: Organization[],
  orgRoles?: Record<string, OrganizationRole[] | undefined>
): Organization[] {
  return (
    orgs
      ?.filter((org) => org.id !== fromOrgId && org.id !== 'personal')
      .map((org) => ({
        id: org.id,
        name: org.name,
        projects: [], // Not needed in this context
        organization: org,
        members: org.members,
        features: org.features,
        createdAt: org.createdAt,
        updatedAt: org.updatedAt,
        dashboardStatus: org.dashboardStatus,
      }))
      .filter((org) => {
        const currentUserRole = org.members.find((member) => member.isCurrentUser)?.roles[0]
        if (!currentUserRole) return false

        const hasAttachPermission = orgRoles?.[org.id]?.some((role) =>
          role.grants?.['sanity.organization.projects']?.some((permission) =>
            permission.grants.some((grant) => grant.name === 'attach')
          )
        )

        return hasAttachPermission
      }) ?? []
  )
}

export function useTransferToOrgList(fromOrgId?: string) {
  const {data: orgs, isLoading, error} = useOrganizationsList()
  const mappedOrgs = useMemo(() => mapOrgs(orgs, fromOrgId), [orgs, fromOrgId])
  const orgIds = mappedOrgs.map((org) => org.id)
  const {
    data: orgRoles,
    isLoading: isRolesLoading,
    error: _rolesError,
  } = useOrganizationsRolesList(orgIds)
  const transferableOrgs = useMemo(() => {
    return mapTransferableOrgs(fromOrgId, orgs, orgRoles)
  }, [orgs, orgRoles, fromOrgId])

  return {
    data: transferableOrgs,
    isLoading: isLoading || isRolesLoading,
    error,
  }
}
