import {useCallback, useMemo} from 'react'
import {statusConfig, StatusConfigContext, StatusType} from './statusConfig'
import {Project, Organization, ResourceIds} from '@/types/models'
import {useCurrentScopeContext} from '@/context/index'
import {arrayToText, getResourceNameById} from '@/utils/general'
import {isProjectNonAdminUsersAtQuota} from '@/utils/usage'

import {useProjectSubscription, useProjectUsage} from '@/data/projects'
import {useOrganizationCanPay} from '@/data/organizations/useOrganizationCanPay'

type Props = {
  project: Project | undefined
  /**  Uses the current organization as default organization */
  org?: Organization | undefined
  /**  Checks all resources by default */
  resourcesToCheck?: ResourceIds[] | undefined
  context?: StatusConfigContext
}

const filterArray = (array: string[], compareArray: string[]) =>
  array.filter((a) => compareArray.includes(a))

export function useProjectUsageStatus({
  project,
  org,
  resourcesToCheck,
  context,
}: Props): StatusType | undefined {
  const {org: scopeContextOrg} = useCurrentScopeContext() ?? {}
  const currentOrg = org ?? scopeContextOrg

  const {data: subscription} = useProjectSubscription(project?.id)
  const {data: usage} = useProjectUsage(project)
  const {data: canPay} = useOrganizationCanPay(currentOrg?.id)

  // If no org is passed, use the current org in the scope context

  /**
   * Check if organization can pay
   * if can pay is undefined, we haven't loaded the org yet so
   * optimistically assume true to prevent flash in case it is actually true
   */
  //Check the organization type
  const isPersonal = currentOrg?.type === 'personal'
  const isRegular = currentOrg?.type === 'regular'
  const isOrgPlan = currentOrg?.type === 'org-plan'

  //Check if the project bills non-admin users
  const isNonAdminUsersAtQuota = isProjectNonAdminUsersAtQuota(project)

  // If a plan is custom or enterprise it will never be charged via Stripe, and always invoiced manually
  const isCustomPlan =
    subscription?.plan.variantId === 'custom' || subscription?.plan.planTypeId == 'enterprise'

  const {overageResources, approachingResources, overageResourcesHardLimit} = usage ?? {}
  const isPlayground =
    project !== undefined && currentOrg?.organizationPlan?.projectIds.includes(project.id) === false

  // If resourcesToCheck is passed, only check those resources
  const filteredApproaching = resourcesToCheck
    ? filterArray(approachingResources || [], resourcesToCheck)
    : approachingResources || []

  const filteredOverage = resourcesToCheck
    ? filterArray(overageResources || [], resourcesToCheck)
    : overageResources || []

  const filteredHardLimit = resourcesToCheck
    ? filterArray(overageResourcesHardLimit || [], resourcesToCheck)
    : overageResourcesHardLimit || []

  // Check if the project has overage or approaching overage
  const isApproaching =
    ((filteredOverage || [])?.length === 0 &&
      (filteredHardLimit || [])?.length === 0 &&
      (filteredApproaching || [])?.length > 0) ||
    false
  const hasOverage = (filteredOverage || [])?.length > 0 || false
  const hasOverageHardLimit = (filteredHardLimit || []).length > 0 || false

  // Check if project is locked
  const isLocked = project?.isBlocked || (!project?.isDisabledByUser && project?.isDisabled)

  // Generate string with resource names from overage/approaching resources arrays
  const hardLimitText = arrayToText(
    ([...new Set([...filteredHardLimit, ...filteredApproaching])] as ResourceIds[])?.map((r) =>
      getResourceNameById(r)
    ) || []
  )
  const overageText = arrayToText(
    ([...new Set([...filteredOverage, ...filteredApproaching])] as ResourceIds[])?.map((r) =>
      getResourceNameById(r)
    ) || []
  )
  const approachingText = arrayToText(
    (filteredApproaching as ResourceIds[])?.map((r) => getResourceNameById(r)) || []
  )

  // Return the resourcesText depending on usage status
  const resourcesText = useCallback(() => {
    if (hasOverageHardLimit) {
      return hardLimitText
    }
    if (hasOverage) {
      return overageText
    }
    return approachingText
  }, [approachingText, hardLimitText, hasOverage, hasOverageHardLimit, overageText])

  const getPlanName = useCallback(() => {
    if (isPlayground) {
      return 'Playground'
    }

    if (!isPlayground && isOrgPlan) {
      return 'Organization'
    }

    return subscription?.plan.name || ''
  }, [isOrgPlan, isPlayground, subscription?.plan.name])

  //Get project usage status
  const getStatus = useMemo(() => {
    const status = statusConfig({
      resourcesText: resourcesText(),
      planName: getPlanName(),
      isPlayground,
      isPersonal,
      isRegular,
      isOrgPlan,
      isLocked,
      isCustomPlan,
      canPay: canPay?.status === true,
      isApproaching,
      hasOverage,
      hasOverageHardLimit,
      isNonAdminUsersAtQuota,
      context,
    })
    return status
  }, [
    canPay?.status,
    getPlanName,
    hasOverage,
    hasOverageHardLimit,
    isApproaching,
    isLocked,
    isOrgPlan,
    isPersonal,
    isCustomPlan,
    isPlayground,
    isRegular,
    resourcesText,
    isNonAdminUsersAtQuota,
    context,
  ])
  return getStatus
}
