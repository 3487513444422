import type {Project} from '../types'

/**
 * Get the path for a project depending on if it is an organization project or a personal project
 * @param project
 * @returns The href path for the project
 */
export function getProjectPath(project: Project): string {
  if (project.organizationId) {
    return `/organizations/${project.organizationId}/project/${project.id}`
  }
  return `/manage/personal/project/${project.id}`
}
