import {Card} from '@sanity/ui'
import {forwardRef, type ReactNode} from 'react'
import {css, styled} from 'styled-components'

const Root = styled(Card)(({theme}) => {
  const gap = theme.sanity.space[1]

  return css`
    border-bottom: 0px solid var(--card-border-color);
    box-sizing: border-box;
    border-color: var(--card-border-color);
    border-bottom-width: 1px;
    vertical-align: middle;
    gap: ${gap}px;

    &:not([hidden]) {
      display: flex;
    }
  `
})

export interface TableRowProps {
  children: ReactNode
}

export const TableRow = forwardRef(function TableRow(
  props: TableRowProps & Omit<React.HTMLProps<HTMLTableRowElement>, 'as' | 'height' | 'ref'>,
  ref: React.Ref<HTMLTableRowElement>
) {
  const {children, ...rest} = props

  return (
    <Root forwardedAs="tr" ref={ref} sizing="border" {...rest}>
      {children}
    </Root>
  )
})
