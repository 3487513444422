import {Project} from '@/types/models/project'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {getProjectsKey} from './cache'
import {deleteProject as deleteProjectFn} from './controller'

export function useDeleteProject(projectId: string) {
  const queryClient = useQueryClient()
  const {mutate: deleteProject, ...rest} = useMutation({
    mutationKey: ['deleteProject', projectId],
    mutationFn: () => deleteProjectFn(projectId),
    onMutate: () => {
      queryClient.setQueryData(getProjectsKey(), (projects: Project[]) => {
        return projects.map((p) => (p.id === projectId ? {...p, isDeleted: true} : p))
      })
    },
    onError: (error) => {
      console.error('Failed to delete project:', error)
    },
    onSuccess: () => {
      // Allow the redirect to go through before invalidating the cache
      setTimeout(() => {
        queryClient.invalidateQueries({queryKey: getProjectsKey()})
      }, 1000)
    },
  })
  return {
    deleteProject,
    ...rest,
  }
}
