import {RefreshIcon} from '@sanity/icons'
import {Flex, Spinner, Stack, Text} from '@sanity/ui'
import {motion, type Variants} from 'framer-motion'
import styled from 'styled-components'

import {Button} from '../../primitives/button/Button'
import {Delay} from '../../utils'

const ANIMATION_VARIANTS: Variants = {
  initial: {opacity: 0},
  animate: {opacity: 1},
  exit: {opacity: 0},
}

const MotionFlex = motion(Flex)

const MessageRoot = styled(MotionFlex).attrs({
  padding: 4,
  align: 'center',
  justify: 'center',
  sizing: 'border',
  initial: 'initial',
  animate: 'animate',
  exit: 'exit',
  variants: ANIMATION_VARIANTS,
})`
  &[data-border='true'] {
    border-bottom: 1px solid var(--card-border-color);
  }
`

interface EmptyStateProps {
  title: string
  borderBottom?: boolean
}

export function EmptyState(props: EmptyStateProps) {
  const {borderBottom, title} = props

  return (
    <MessageRoot
      animate="animate"
      data-border={borderBottom}
      exit="exit"
      initial="initial"
      variants={ANIMATION_VARIANTS}
    >
      <Text size={1} muted>
        {title}
      </Text>
    </MessageRoot>
  )
}

export function LoadingState() {
  return (
    <MessageRoot variants={ANIMATION_VARIANTS} initial="initial" animate="animate" exit="exit">
      <Delay ms={400}>
        <Flex align="center" gap={2}>
          <Spinner muted size={1} />

          <Text size={1} muted>
            Loading...
          </Text>
        </Flex>
      </Delay>
    </MessageRoot>
  )
}

interface ErrorStateProps {
  onRetry?: () => void
}

export function ErrorState(props: ErrorStateProps) {
  const {onRetry} = props

  return (
    <MessageRoot variants={ANIMATION_VARIANTS} initial="initial" animate="animate" exit="exit">
      <Stack space={4}>
        <Flex align="center" justify="center" gap={2}>
          <Text size={1} muted>
            Something went wrong
          </Text>
        </Flex>

        {onRetry && (
          <Flex align="center" justify="center">
            <Button
              fontSize={1}
              icon={RefreshIcon}
              onClick={onRetry}
              padding={2}
              text="Try again"
            />
          </Flex>
        )}
      </Stack>
    </MessageRoot>
  )
}
