import React from 'react'
import {Heading, Button as UIButton, Flex, Box, FlexAlign} from '@sanity/ui'
import {ArrowRightIcon, Icon} from '@sanity/icons'
import styled from 'styled-components'
import {AppLink} from '@/ui/app'

interface Props {
  title: string | React.ReactNode
  path?: string
  buttonText?: string | number | boolean
  buttonIcon?: typeof Icon
  align?: FlexAlign
}

const Button = styled(UIButton)`
  &:not([data-disabled='true']):not(:focus-visible):hover {
    background: 0;
    box-shadow: none;
  }
`

export function ModuleHeading({
  title,
  buttonText,
  buttonIcon = ArrowRightIcon,
  path = '',
  align = 'center',
}: Props) {
  return (
    <Box marginBottom={buttonText ? 0 : 1} marginTop={buttonText ? 0 : 2}>
      <Flex align={align}>
        <Box flex={1}>
          {typeof title === 'string' ? (
            <Heading size={2} as="h2">
              {title}
            </Heading>
          ) : (
            title
          )}
        </Box>
        {buttonText && (
          <AppLink href={path}>
            <Button
              tabIndex={-1}
              mode="bleed"
              iconRight={buttonIcon}
              text={buttonText}
              paddingX={0}
              paddingY={2}
            />
          </AppLink>
        )}
      </Flex>
    </Box>
  )
}
