import groq from 'groq'
import {type UseQueryResult, useQuery} from '@tanstack/react-query'
import {marketingStudioClient} from '../marketingStudioClient'
import type {SanityImageAssetDocument} from '@sanity/client'
import type {Slug} from '@sanity/types'
import type {PortableTextBlock} from '@portabletext/react'

export interface MuxVideo {
  playbackId: string
  autoplay?: boolean
  muted?: boolean
  loop?: boolean
  hideControls?: boolean
  poster?: SanityImageAssetDocument
}

export interface Lesson {
  _id: string
  title: string
  shortTitle?: string
  slug: Slug
  description?: string
  content: PortableTextBlock[]
  readTimeInMinutes: number
  muxVideo?: MuxVideo
}

export interface RelatedResource {
  label?: string
  title?: string
  description?: string
  url?: string
}

export interface Course {
  _id: string
  title: string
  shortTitle?: string
  slug: Slug
  visibility: 'public' | 'internal' | 'early-access' | 'manage'
  description?: string
  lessons: Lesson[]
  related: RelatedResource[]
}

export interface AdaptiveImage extends SanityImageAssetDocument {
  darkModeImage?: SanityImageAssetDocument
}

export interface Tab {
  _key: string
  title: string
  icon?: AdaptiveImage
  slug: Slug
  course: Course
}

export interface GettingStarted {
  tabs: Tab[]
  displayTabSwitcherInLessons: {_id: string}[]
  related: RelatedResource[]
}

const getStartedQuery = groq`*[_id == 'manage-get-started'][0] {
  tabs[] {
    _key,
    title,
    icon {
      asset-> {
        url
      },
      darkModeImage {
        ...asset-> {
          url
        }
      },
    },
    slug,
    course-> {
      _id,
      title,
      shortTitle,
      slug,
      visibility,
      description,
      "lessons": lessons[]-> {
        _id,
        title,
        shortTitle,
        slug,
        description,
        content,
        "readTimeInMinutes": round(length(pt::text(content)) / 350), // 350 characters per minute
        muxVideo {
          autoplay,
          muted,
          loop,
          hideControls,
          "playbackId": video.asset->playbackId,
          poster {
            ...asset-> {
              url
            }
          },
        },
      },
      related[],
    }
  },
  displayTabSwitcherInLessons[]-> {
    _id
  },
  related[],
}`

export const useGettingStarted = (): UseQueryResult<GettingStarted> => {
  return useQuery({
    queryKey: ['getStarted'],

    queryFn: async () => {
      return marketingStudioClient.fetch(getStartedQuery)
    },
  })
}
