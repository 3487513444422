import {ChartGroupSection} from '@/ui/components'
import {Tab, Heading} from '@/types/components'
import {ChartUpwardIcon, DatabaseIcon, LockIcon, BoltIcon, RocketIcon} from '@sanity/icons'
import {settingsTab, overviewTab, membersTab, activityTab, studiosTab} from '../../config'
import {inviteConfig} from '../../org/inviteConfig'
import {
  Settings,
  ProjectDetails,
  ActivitySettings,
  TransferProject,
  ArchiveProject,
  DeleteProject,
  ProjectUsage,
  UsageProjectHeader,
  ProjectMembersTable,
  ProjectInvitations,
  ProjectOverview,
  PlanProject,
  apiTab,
  ProjectStudios,
} from '@/ui/views'
import {ProjectActivity} from '../../project/activity'
import {APIMovedPrompt} from '../../project/api'
import {Datasets} from '../../project/datasets'
import {ProjectRequestsTab} from '../../project/members/projectRequestsTab'
import {ProjectResources} from '../../project/resources'
import {ProjectRoles} from '../../project/roles'
import LogDeliverySettings from '../../project/settings/logDelivery'
import {Tags} from '../../project/tags'
import LogDownloadSection from '../../project/usage/logDownload'
import {ProjectMembersView} from '../../project/members_v2'
import {GettingStarted} from '../../project/getting_started'
import {OrganizationAccessRequestSettingsLink} from '../../org/settings/OrganizationAccessRequestSettingsLink'

interface GetProjectTabsOptions {
  projectFeatures?: string[]
  featuresFlags: {
    projectRequestAccessEnabled: boolean
    members_v2: boolean
    get_started: boolean
  }
  newPlanBadge?: boolean
  getStartedDismissed?: boolean
}

const PROJECT_READ_PERMISSIONS = [
  {permissionName: 'sanity.project', grantName: 'read'},
  {permissionName: 'sanity.organization.projects', grantName: 'read'},
]

export function getProjectTabs(opts: GetProjectTabsOptions): Tab[] {
  const {featuresFlags, newPlanBadge} = opts

  const baseSettingsTab = {
    ...settingsTab,
    order: 8,
    component: Settings,
    permissions: {
      permissions: [
        ...PROJECT_READ_PERMISSIONS,
        {permissionName: 'sanity.project', grantName: 'update'},
        {permissionName: 'sanity.project', grantName: 'delete'},
      ],
      allowPartialPermissions: true,
    },

    headings: [
      {
        title: 'General settings',
        path: 'general',
        isRoot: true,
      },
      {
        title: 'API settings',
        fullPath: '/api', // Note that this links to the new tab
        component: APIMovedPrompt,
      },
      {
        title: 'Danger zone',
        path: 'danger-zone',
        description: 'Potentially destructive actions.',
      },
    ],
    sections: [
      {
        title: 'Project details',
        isRoot: true,
        path: 'details',
        heading: 'general',
        description: 'Key details about your project.',
        component: ProjectDetails,
      },
      {
        title: 'Activity feed',
        path: 'activity',
        heading: 'general',
        description: 'Find out what’s happening in your project.',
        component: ActivitySettings,
        grantingFeature: 'activityFeed',
      },
      {
        title: 'Configure Log Delivery',
        path: 'request-logs',
        heading: 'general',
        description: 'View logs of requests made to the Sanity API.',
        component: LogDeliverySettings,
      },
      {
        title: 'Transfer ownership',
        description:
          'In order to transfer ownership of a project to another organization, you need to have administrator or billing manager permissions in both organizations. If you do not, our support team will have to do the transfer for you.',
        isRoot: true,
        path: 'transfer-ownership',
        heading: 'danger-zone',
        component: TransferProject,
      },
      {
        title: 'Archive project',
        description:
          'Archiving a project removes any access to it but does not delete it permanently. The project can be reactivated at any time.',
        path: 'archive',
        heading: 'danger-zone',
        component: ArchiveProject,
      },
      {
        title: 'Delete project',
        description: 'Deletes the project permanently. This cannot be undone.',
        path: 'delete',
        heading: 'danger-zone',
        component: DeleteProject,
      },
    ],
  }

  const usageTab = {
    title: 'Usage',
    icon: ChartUpwardIcon,
    path: 'usage',
    description: 'Hello',
    component: ProjectUsage,
    permissions: {
      permissions: [
        ...PROJECT_READ_PERMISSIONS,
        {permissionName: 'sanity.project.usage', grantName: 'read'},
      ],
      allowPartialPermissions: true,
    },
    referenceLink: {
      label: 'Learn more about plans and payments in Sanity Docs',
      url: `https://www.${process.env.host}/docs/plans-and-payments`,
    },
    headings: [
      {
        title: 'Project usage',
        description:
          'An overview of key metrics on the project. Plan quotas are indicated in the graphs. All stats are updated daily.',
        path: 'usage',
        isRoot: true,
      },
    ],
    sections: [
      {
        title: 'Header',
        heading: 'usage',
        hideFromSidebar: true,
        component: UsageProjectHeader,
      },
      {
        title: 'Bandwidth & APIs',
        path: 'bandwidth-api',
        heading: 'usage',
        component: ChartGroupSection,
      },
      {
        title: 'Resources & datasets',
        path: 'resources',
        heading: 'usage',
        component: ChartGroupSection,
      },
      {
        title: 'Request Logs',
        path: 'request-logs',
        heading: 'usage',
        component: LogDownloadSection,
      },
    ],
  }

  const projectMembersHeadings: Heading[] = [
    {
      title: 'Members',
      path: 'members',
      isRoot: true,
      component: ProjectMembersTable,
      permissions: [{permissionName: 'sanity.project.members', grantName: 'read'}],
    },
    {
      title: 'Invitations',
      description:
        'Invitations that have been sent, but not accepted yet. Changed your mind? Pending invitation may be revoked.',
      path: 'invitations',
      component: ProjectInvitations,
      referenceLink: {
        label: 'Learn more about roles',
        url: `https://www.${process.env.host}/docs/roles`,
      },
      permissions: [{permissionName: 'sanity.project.members', grantName: 'invite'}],
    },
  ]

  if (featuresFlags?.projectRequestAccessEnabled) {
    projectMembersHeadings.push({
      title: 'Requests',
      description:
        "Overview of users requesting access to the project. You can set the user's role before approving a request.",
      path: 'requests',
      component: ProjectRequestsTab,
      referenceLinkComponent: OrganizationAccessRequestSettingsLink,
      permissions: [{permissionName: 'sanity.project.members', grantName: 'read'}],
    })
  }

  const defaultMembers: Tab = {
    ...membersTab,
    description:
      'Project members can be given access to data in the project. Users with the "Viewer" role do not consume seats.',
    referenceLink: {
      label: 'Learn more about roles',
      url: `https://www.${process.env.host}/docs/roles`,
    },
    permissions: {
      permissions: [
        {permissionName: 'sanity.project.members', grantName: 'read'},
        {permissionName: 'sanity.project.roles', grantName: 'read'},
      ],
      allowPartialPermissions: false,
    },
    headings: projectMembersHeadings,
  }

  const projectMembersHeadings_v2: Heading[] = [
    {
      title: 'Members',
      path: 'members',
      description:
        'Project members can be given access to data in the project. Users with the "Viewer" role do not consume seats.',
      isRoot: true,
      component: ProjectMembersView,
      permissions: [{permissionName: 'sanity.project.members', grantName: 'read'}],
    },
    {
      title: 'Invitations',
      description:
        'Invitations that have been sent, but not accepted yet. Changed your mind? Pending invitations may be revoked.',
      path: 'invitations',
      component: ProjectMembersView,
      permissions: [{permissionName: 'sanity.project.members', grantName: 'invite'}],
    },
  ]

  if (featuresFlags?.projectRequestAccessEnabled) {
    projectMembersHeadings_v2.push({
      title: 'Requests',
      description:
        "Overview of access requests to the project. You can set the user's role before approving a request.",
      path: 'requests',
      component: ProjectMembersView,
      referenceLinkComponent: OrganizationAccessRequestSettingsLink,
      permissions: [{permissionName: 'sanity.project.members', grantName: 'read'}],
    })
  }

  const members_v2: Tab = {
    ...defaultMembers,
    headings: projectMembersHeadings_v2,
  }

  const members = featuresFlags?.members_v2 ? members_v2 : defaultMembers

  return [
    {
      title: 'Getting started',
      icon: RocketIcon,
      path: 'getting-started',
      component: GettingStarted,
      disableTabButtonRender: !featuresFlags?.get_started || opts.getStartedDismissed,
      tabButtonTone: 'positive',
    },
    {
      ...overviewTab,
      component: ProjectOverview,
    },
    members,
    {
      ...studiosTab,
      description:
        'Studios are customizable applications for managing and editing content. A project can have multiple Studios, configured for different purposes and environments.',
      referenceLink: {
        label: 'Learn more about creating and deploying Studios',
        url: `https://www.${process.env.host}/docs/sanity-studio`,
      },
      permissions: [{permissionName: 'sanity.project', grantName: 'read'}],
      headings: [
        {
          title: 'Studios',
          isRoot: true,
          path: 'datasets',
          component: ProjectStudios,
        },
      ],
    },
    {
      title: 'Datasets',
      icon: DatabaseIcon,
      path: 'datasets',
      description:
        'Datasets are collections of documents and assets of different types that reference each other. A project can contain any number of datasets, each with separate data and visibility.',
      referenceLink: {
        label: 'Learn more about datasets',
        url: `https://www.${process.env.host}/docs/datasets`,
      },
      permissions: [
        {permissionName: 'sanity.project.datasets', grantName: 'read'},
        {permissionName: 'sanity.project.tags', grantName: 'read'},
      ],
      headings: [
        {
          title: 'Datasets',
          isRoot: true,
          path: 'datasets',
          component: Datasets,
          permissions: [{permissionName: 'sanity.project.datasets', grantName: 'read'}],
        },
        {
          title: 'Tags',
          isRoot: false,
          path: 'tags',
          component: Tags,
          permissions: [{permissionName: 'sanity.project.tags', grantName: 'read'}],
          description:
            'Using datasets tags lets you create multiple groups of associated datasets. Define content permissions against a tag in order to keep them in sync across tagged datasets.',
          referenceLink: {
            label: 'Learn more about dataset tags',
            url: `https://www.${process.env.host}/docs/roles#0db30012bd04`,
          },
          disableTabButtonRender: ({proj}): boolean => {
            const tagsHasRequiredFeature = proj?.features.some(
              (feature) => feature === 'advancedRolesManagement'
            )
            return tagsHasRequiredFeature === false
          },
        },
      ],
    },
    {
      title: 'Access',
      icon: LockIcon,
      path: 'access',
      description:
        'Roles are sets of permissions that can be assigned to project members. Members can be assigned multiple roles.',
      referenceLink: {
        label: 'Learn more about roles',
        url: `https://www.${process.env.host}/docs/roles`,
      },
      permissions: [{permissionName: 'sanity.project.roles', grantName: 'read'}],
      headings: [
        {
          title: 'Roles',
          path: 'roles',
          isRoot: true,
          component: ProjectRoles,
          description:
            'Roles are sets of permissions that can be assigned to project members. Members can be assigned multiple roles.',
          referenceLink: {
            label: 'Learn more about roles',
            url: `https://www.${process.env.host}/docs/roles`,
          },
          permissions: [{permissionName: 'sanity.project.roles', grantName: 'read'}],
        },
        {
          title: 'Resources',
          path: 'resources',
          isRoot: false,
          component: ProjectResources,
          description:
            'Content resources define types of content that can have permission settings. Within each role you can customize permissions for any given content resource.',
          referenceLink: {
            label: 'Learn more about content resources',
            url: `https://www.${process.env.host}/docs/roles#e156da551901`,
          },
          permissions: [],
        },
      ],
    },
    {
      ...activityTab,
      component: ProjectActivity,
      description: 'Find out what’s happening in your project.',
      permissions: [{permissionName: 'sanity.project', grantName: 'read'}],
    },
    usageTab,
    {
      title: 'Plan',
      icon: BoltIcon,
      path: 'plan',
      badge: newPlanBadge ? 'New' : undefined,
      component: PlanProject,
      permissions: {
        permissions: [...PROJECT_READ_PERMISSIONS],
        allowPartialPermissions: true,
      },
    },
    apiTab,
    baseSettingsTab,
    inviteConfig,
  ]
}
