import {useQuery} from '@tanstack/react-query'
import {useCurrentUser} from '../users/useCurrentUser'
import {getOrganizations} from './controller'
import {Organization, Project} from '../../types/models'
import {useProjects} from '../projects/useProjects'
import {getOrganizationsKey} from './cache'
import {useCallback, useMemo} from 'react'
import {useOrganizationPlans} from '../plan/useOrganizationPlans'

const projectSortFn = (a: Project, b: Project) => {
  return +new Date(b.createdAt) - +new Date(a.createdAt)
}

export const filterOrgs = (orgs: Organization[]) => {
  return orgs.filter((org) => {
    if (org.isDeleted) return false

    if (org.type === 'personal' && org.projects.length === 0) {
      return false
    }
    return true
  })
}

export const filterProjects = (projects: Project[]) => {
  return projects.filter((project) => !project.isDeleted)
}

function mapOrganizations(
  currentUser: any,
  organizations: Organization[],
  projects: Project[],
  organizationPlans: any[]
): Organization[] {
  const timestamp = '1970-05-27T17:09:52.702Z'

  return [
    {
      id: 'personal',
      type: 'personal',
      createdAt: timestamp,
      updatedAt: timestamp,
      name: currentUser ? currentUser.name : 'Personal',
      projects: filterProjects(projects.filter((proj) => !proj.organizationId).sort(projectSortFn)),
      members: [],
      features: [],
      isDeleted: false,
      dashboardStatus: 'disabled',
    } as Organization,
    ...organizations.map((org) => {
      const organizationPlan = organizationPlans.find((plan) => plan.organizationId === org.id)
      return {
        ...org,
        projects: filterProjects(
          projects.filter((proj) => proj.organizationId === org.id).sort(projectSortFn)
        ),
        organizationPlan,
        type: organizationPlan ? 'org-plan' : 'regular',
      } as Organization
    }),
  ]
}

const orgSortFn = (a: Organization, b: Organization) =>
  +new Date(a.createdAt) - +new Date(b.createdAt)
export const sortOrgs = (orgs: Organization[]): Organization[] => orgs.sort(orgSortFn)

export function useOrganizationsList(currentOrgId?: string) {
  const {data: currentUser, isLoading: isCurrentUserLoading} = useCurrentUser()
  const {data: projects = [], isLoading: isProjectsLoading} = useProjects({
    enabled: !!currentUser,
  })
  const {
    data: organizationPlans = [],
    isLoading: isOrganizationPlansLoading,
    error: organizationsError,
  } = useOrganizationPlans({
    enabled: !!currentUser,
  })

  const mapCurrentOrg = useCallback(
    (orgs: Organization[]) => {
      const mappedOrganizations = mapOrganizations(currentUser, orgs, projects, organizationPlans)

      const filteredOrgs = filterOrgs(mappedOrganizations)

      if (currentOrgId) {
        const current = filteredOrgs.find((org) => org.id === currentOrgId)
        if (current) {
          return sortOrgs([current, ...filteredOrgs.filter((org) => org.id !== currentOrgId)])
        }
      }

      return sortOrgs(filteredOrgs)
    },
    [currentUser, projects, organizationPlans, currentOrgId]
  )

  const {
    isLoading: isOrganizationsLoading,
    refetch: refetchOrganizations,
    data: organizations,
    isRefetching: isOrganizationsRefetching,
  } = useQuery({
    queryKey: getOrganizationsKey(),
    queryFn: () => getOrganizations(),
    select: mapCurrentOrg,
    enabled: !!currentUser,
  })

  const isLoading = useMemo(
    () =>
      isOrganizationsLoading ||
      isCurrentUserLoading ||
      isProjectsLoading ||
      isOrganizationPlansLoading,
    [isOrganizationsLoading, isCurrentUserLoading, isProjectsLoading, isOrganizationPlansLoading]
  )

  return {
    data: organizations,
    isLoading,
    isRefetching: isOrganizationsRefetching,
    refetch: refetchOrganizations,
    error: organizationsError,
  }
}
