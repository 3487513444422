import React, {useCallback, useEffect} from 'react'
import {TextInput, Stack, Container} from '@sanity/ui'
import {useForm, Validate} from 'react-hook-form'
import {FormField} from '../'
import {CheckboxInput} from '../inputs'
import {Prompt} from '../../general'
import {EditContainer} from './.'

interface Props {
  onSave: ({origin, allowCredentials}: {origin: string; allowCredentials: boolean}) => void
  onCancel: () => void
  isLoading?: boolean
}

interface FormValues {
  origin: string
  allowCredentials: boolean
}

function tryParseUrl(url: string) {
  try {
    // The input URL may contain wildcards (*), which isn't a valid character
    // in the hostname nor port parts. That's why we need to replace them when
    // validating the URL.
    // Due to a bug, Chrome supports wildcards. A fix was requested here [1]
    // [1] https://bugs.chromium.org/p/chromium/issues/detail?id=652808
    return new URL(url.replace(/:\*/, ':0').replace('*', 'WILDCARD'))
  } catch (err) {
    return false
  }
}

function getPath(url: string) {
  const parsed = tryParseUrl(url)
  return parsed && parsed.pathname
}

const anyProtocolPrefix = /^[a-z0-9-_]+:\/\//i

interface CorsValidationMessages {
  basePattern: string
  parseable: string
  nonWildcardFileOrigin: string
  noPath: string
}

const corsValidationMessages: CorsValidationMessages = {
  basePattern: 'Must either be a protocol-prefixed origin, wildcard ("*") or "null"',
  parseable: 'Unable to parse URL',
  nonWildcardFileOrigin: 'File origins must use wildcard ("file:///*")',
  noPath: 'Origin must not contain a path, only the hostname',
}

const trimOrigin = (origin: string) => origin.replace(/\/$/, '').trim()

const corsValidators: {
  [key in keyof CorsValidationMessages]: Validate<string, FormValues>
} = {
  basePattern: (origin: string) => {
    const trimmedOrigin = trimOrigin(origin)
    return (
      trimmedOrigin === '*' ||
      trimmedOrigin === 'null' ||
      anyProtocolPrefix.test(trimmedOrigin) ||
      corsValidationMessages.basePattern
    )
  },

  parseable: (origin: string) => {
    const trimmedOrigin = trimOrigin(origin)
    return (
      !anyProtocolPrefix.test(trimmedOrigin) ||
      Boolean(tryParseUrl(trimmedOrigin)) ||
      corsValidationMessages.parseable
    )
  },

  nonWildcardFileOrigin: (origin: string) => {
    const trimmedOrigin = trimOrigin(origin)
    return (
      !trimmedOrigin.startsWith('file://') ||
      trimmedOrigin === 'file:///*' ||
      corsValidationMessages.nonWildcardFileOrigin
    )
  },

  noPath: (origin: string) => {
    const trimmedOrigin = trimOrigin(origin)
    return (
      !anyProtocolPrefix.test(trimmedOrigin) ||
      trimmedOrigin.startsWith('file://') ||
      getPath(trimmedOrigin) === '/' ||
      corsValidationMessages.noPath
    )
  },
}

export function CorsInput({onSave, onCancel, isLoading}: Props) {
  const {
    register,
    handleSubmit,
    watch,
    formState: {errors},
    reset,
  } = useForm<FormValues>({
    defaultValues: {
      origin: '',
      allowCredentials: false,
    },
  })

  const showPrompt = watch('allowCredentials')

  useEffect(() => {
    return () => reset()
  }, [reset])

  const onSubmit = (data: FormValues) => {
    onSave({
      origin: trimOrigin(data.origin),
      allowCredentials: data.allowCredentials,
    })
  }

  const handleCancel = useCallback(() => {
    onCancel()
  }, [onCancel])

  return (
    <Stack space={4}>
      <EditContainer onSave={handleSubmit(onSubmit)} onCancel={handleCancel} loading={isLoading}>
        <Container style={{margin: 0, maxWidth: 475}}>
          <FormField
            label="Origin"
            description="A URL in the format of protocol://hostname[:port] (use * for wildcard)"
            error={errors?.origin?.message}
          >
            <TextInput
              padding={4}
              radius={2}
              placeholder="https://"
              {...register('origin', {
                required: true,
                validate: corsValidators,
              })}
            />
          </FormField>
        </Container>

        <Container style={{margin: 0, maxWidth: 475}}>
          <FormField
            label="Credentials"
            description="Should this origin be allowed to send authenticated requests with a user’s token or session? If you're hosting a Studio on the above origin you need to enable this option."
          >
            <CheckboxInput label="Allow credentials" {...register('allowCredentials')} />
          </FormField>
        </Container>

        {showPrompt && (
          <Prompt
            tone="caution"
            icon="warning-outline"
            description="Allowing credentials on cross-origin requests can be dangerous. Scripts running on
          pages on the given origin will be able to send requests on the user’s behalf if they
          are logged in to your Studio. Tread carefully"
          />
        )}
      </EditContainer>
    </Stack>
  )
}
