import {Text} from '@sanity/ui'
import styled from 'styled-components'

interface Props {
  children: React.ReactNode
}

const StyledText = styled(Text)`
  margin-top: 4px;
  margin-bottom: 12px;

  &:not(:last-child) + & {
    margin-top: 24px !important;
  }

  &:last-child {
    margin-bottom: 0;
  }
`

export function BlockText(props: Props) {
  const {children} = props

  return (
    <StyledText size={2} muted>
      {children}
    </StyledText>
  )
}
