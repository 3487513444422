import React, {useCallback} from 'react'
import {TextArea, TextInput} from '@sanity/ui'
import {useForm} from 'react-hook-form'
import {FormField} from '../formField'
import {EditContainer} from './editContainer'
import {OrganizationContactData} from '@/types/index'
import {emailPattern} from '@/utils/form'

type Props = {
  data?: OrganizationContactData
  onCancel: () => void
  onSave: (data: OrganizationContactData) => void
}

export function ContactInput({data, onCancel, onSave}: Props) {
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<OrganizationContactData>({
    defaultValues: data,
  })

  const onSubmit = (newData: OrganizationContactData) => {
    onSave(newData)
  }

  const handleCancel = useCallback(() => {
    onCancel()
  }, [onCancel])

  return (
    <EditContainer onSave={handleSubmit(onSubmit)} onCancel={handleCancel}>
      <FormField label="Name" error={errors.name ? 'This field is required' : ''}>
        <TextInput
          padding={3}
          defaultValue={data?.name}
          autoFocus
          {...register('name', {required: true})}
        />
      </FormField>
      <FormField label="Postal Address" error={errors.address ? 'This field is required' : ''}>
        <TextArea
          padding={3}
          defaultValue={data?.address}
          {...register('address', {required: true})}
        />
      </FormField>
      <FormField label="Phone Number" error={errors.phoneNumber ? 'This field is required' : ''}>
        <TextInput
          padding={3}
          defaultValue={data?.phoneNumber}
          {...register('phoneNumber', {required: true})}
        />
      </FormField>
      <FormField label="Email" error={errors.email ? 'This field is required' : ''}>
        <TextInput
          padding={3}
          defaultValue={data?.email}
          {...register('email', {required: true, pattern: emailPattern})}
        />
      </FormField>
    </EditContainer>
  )
}
