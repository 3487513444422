import React, {useState, useEffect, useCallback} from 'react'
import {Heading, Stack, TextInput, Text} from '@sanity/ui'
import {useRouter} from 'next/router'
import {useForm} from 'react-hook-form'
import {FormField} from '../../form'
import {Prompt} from '@/ui/index'
import {Dataset, DialogToast, Project} from '@/types/index'
import {useDeleteDataset} from '@/data/index'
import {useRoutePath} from '@/context/index'

type Props = {
  project: Project
  dataset: Dataset
  addons: Dataset[]
  hideDialog: (dialogToast: DialogToast) => void
  Dialog: React.ElementType
}

export const DeleteDatasetDialog = ({project, dataset, addons, hideDialog, Dialog}: Props) => {
  const router = useRouter()
  const {basePath} = useRoutePath()
  const {isPending, mutate: deleteDataset} = useDeleteDataset(project.id)
  const [validationError, setValidationError] = useState<boolean>(false)
  const {register, handleSubmit, reset} = useForm<{name: string}>()

  useEffect(() => {
    return () => reset()
  }, [reset])

  const onSubmit = useCallback(
    (data: {name: string}) => {
      if (data.name !== dataset.name) {
        setValidationError(true)
        return
      }

      deleteDataset(
        {datasetName: dataset.name},
        {
          onSuccess: () => {
            setTimeout(() => {
              hideDialog({
                title: 'Deleted dataset',
                status: 'success',
              })

              router.push(`${basePath}/datasets`)
            }, 0)
          },
          onError: (error) => {
            hideDialog({
              title: 'Failed to delete dataset',
              description: error.message,
              status: 'error',
            })
          },
        }
      )
    },
    [basePath, dataset.name, deleteDataset, hideDialog, router]
  )

  if (dataset) {
    return (
      <Dialog
        id="delete-dataset"
        header="Delete dataset"
        onClose={() => setValidationError(false)}
        title={
          <Heading size={1}>
            Do you really want to delete <code>{dataset.name}</code>?
          </Heading>
        }
        description="The dataset will be permanently deleted, including all data, addon datasets, and settings. This action is irrevocable and cannot be undone."
        confirmButtonProps={{text: 'Delete forever', form: 'delete-dataset-form', type: 'submit'}}
        loading={isPending}
      >
        <Stack space={4}>
          {validationError && (
            <Prompt tone="critical" icon="info-outline" description="Dataset name doesn't match" />
          )}
          <Text muted size={1}>
            The following addon datasets will be deleted as well:
            <ul>
              {addons.map((addon) => (
                <li key={addon.name}>{addon.name}</li>
              ))}
            </ul>
          </Text>
          <form onSubmit={handleSubmit(onSubmit)} id="delete-dataset-form">
            <FormField
              label="Type in the name of the dataset to confirm deletion"
              description={dataset.name}
            >
              <TextInput padding={3} {...register('name')} />
            </FormField>
          </form>
        </Stack>
      </Dialog>
    )
  }
  return null
}
