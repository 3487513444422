import {useCurrentScopeContext} from '@/context/index'
import {
  DataOverrideWarning,
  Fieldset,
  SavedCard,
  StripeTextField,
} from '@/components/checkout/components'
import {CheckoutFormData} from '@/components/checkout/types'
import {FormField} from '@/components/form/formField'
import {Customer} from '@/types/index'
import {stripEmptyKeys} from '@/utils/general'
import {Grid, TextInput} from '@sanity/ui'
import {CardCvcElement, CardExpiryElement, CardNumberElement} from '@stripe/react-stripe-js'
import type {StripeElements} from '@stripe/stripe-js/types/stripe-js'
import {useEffect, useMemo, useState} from 'react'
import {FieldError, useFormContext} from 'react-hook-form'
import {FORM_ERRORS} from '../constants'

interface Props {
  customer?: Customer | null
}

export function PaymentMethodFieldset({customer}: Props) {
  const {
    register,
    watch,
    formState: {errors},
  } = useFormContext<CheckoutFormData>()
  const [edit, setEdit] = useState(false)
  const scope = useCurrentScopeContext()
  const hasCard = Boolean(customer?.paymentSource?.card && customer?.name)

  const currentOrgId = watch('org.id')
  const currentOrg = useMemo(
    () => scope?.orgs?.find((org) => org.id === currentOrgId),
    [currentOrgId]
  )

  /* Reset the edit state when the customer data changes */
  useEffect(() => setEdit(false), [customer])

  if (hasCard && !edit) {
    return (
      <Fieldset legend="Saved payment details">
        <FormField label="Payment method">
          <SavedCard card={customer!.paymentSource!.card!} onEdit={() => setEdit(true)} />
        </FormField>
      </Fieldset>
    )
  }

  return (
    <Fieldset legend="Payment details">
      <Grid gap={3} columns={[2, 2, 4]}>
        <FormField
          label="Card number"
          style={{gridColumn: 'span 2'}}
          error={errors?.stripe?.cardNumber?.message}
        >
          <StripeTextField as={CardNumberElement} />
        </FormField>
        <FormField label="Expiry date" error={errors?.stripe?.cardExpiry?.message}>
          <StripeTextField as={CardExpiryElement} />
        </FormField>
        <FormField label="CVC" error={errors?.stripe?.cardCvc?.message}>
          <StripeTextField as={CardCvcElement} />
        </FormField>
      </Grid>

      <FormField label="Cardholder name" error={errors?.customer?.name?.message}>
        <TextInput {...register('customer.name')} autoComplete="cc-name" />
      </FormField>

      {edit && (
        <DataOverrideWarning
          text={`This will replace your previous payment card for ${currentOrg?.name}`}
          onCancel={() => setEdit(false)}
        />
      )}
    </Fieldset>
  )
}

export const getStripeErrors = (elements: StripeElements | null): Record<string, FieldError> => {
  if (!elements) return {}

  const getError = (element: any): FieldError | null => {
    if (element?._invalid) {
      return {type: 'invalid', message: FORM_ERRORS.invalid}
    }
    if (element?._empty) {
      return {type: 'required', message: FORM_ERRORS.required}
    }
    return null
  }
  return stripEmptyKeys({
    cardNumber: getError(elements.getElement(CardNumberElement)),
    cardExpiry: getError(elements.getElement(CardExpiryElement)),
    cardCvc: getError(elements.getElement(CardCvcElement)),
  })
}
