import {DownloadIcon} from '@sanity/icons'
import {Box, Button, Card, Flex} from '@sanity/ui'
import React from 'react'
import {ExportActivityDialog, useConfirmDialog} from '../dialog'

export function ActivityHead() {
  const {
    Dialog: ExportDialog,
    hideDialog: hideTransferDialog,
    showDialog: showTransferDialog,
  } = useConfirmDialog()

  return (
    <>
      <ExportActivityDialog Dialog={ExportDialog} hideDialog={hideTransferDialog} />
      <Card padding={2} radius={2} tone="transparent" marginBottom={2}>
        <Flex justify="space-between">
          <Box />
          <Box>
            <Button
              tone="positive"
              text="Export"
              icon={DownloadIcon}
              onClick={showTransferDialog}
            />
          </Box>
        </Flex>
      </Card>
    </>
  )
}
