import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInSeconds,
  differenceInWeeks,
  differenceInYears,
} from 'date-fns'
import {useCallback, useEffect, useReducer} from 'react'

interface TimeSpec {
  timestamp: string
  refreshInterval: number | null
}

const FIVE_SECONDS = 1000 * 5
const TWENTY_SECONDS = 1000 * 20
const ONE_MINUTE = 1000 * 60
const ONE_HOUR = ONE_MINUTE * 60

const NO_YEAR_DATE_ONLY_FORMAT: Intl.DateTimeFormatOptions = {
  month: 'short',
  day: 'numeric',
}

const DATE_ONLY_FORMAT: Intl.DateTimeFormatOptions = {
  ...NO_YEAR_DATE_ONLY_FORMAT,
  year: 'numeric',
}

const FULL_DATE_FORMAT: Intl.DateTimeFormatOptions = {
  ...DATE_ONLY_FORMAT,
  hour: 'numeric',
  minute: 'numeric',
}

interface RelativeTimeOptions {
  minimal?: boolean
  style?: 'short' | 'long'
  relativeTo?: Date
}

function useFormatRelativeTime(date: Date | string, opts: RelativeTimeOptions = {}): TimeSpec {
  const {minimal, style} = opts
  const parsedDate = date instanceof Date ? date : new Date(date)

  const format = useCallback(
    (count: number, unit: Intl.RelativeTimeFormatUnit): string => {
      const relativeFormatter = new Intl.RelativeTimeFormat(undefined, {
        style: style ?? (minimal ? 'short' : 'long'),
        numeric: 'auto',
      })
      return relativeFormatter.format(count, unit)
    },
    [minimal, style]
  )

  if (isNaN(parsedDate.getTime())) {
    return {
      timestamp: '',
      refreshInterval: null,
    }
  }

  const now = opts.relativeTo || new Date()
  const diffMonths = differenceInMonths(now, parsedDate)
  const diffYears = differenceInYears(now, parsedDate)

  if (diffMonths || diffYears) {
    const dateFormatter = new Intl.DateTimeFormat(undefined, {
      ...(opts.minimal && diffYears === 0 ? NO_YEAR_DATE_ONLY_FORMAT : DATE_ONLY_FORMAT),
      ...(opts.minimal ? {} : FULL_DATE_FORMAT),
    })

    return {
      timestamp: dateFormatter.format(parsedDate),
      refreshInterval: null,
    }
  }

  const diffWeeks = differenceInWeeks(parsedDate, now)
  if (diffWeeks) {
    return {
      timestamp: format(diffWeeks, 'week'),
      refreshInterval: ONE_HOUR,
    }
  }

  const diffDays = differenceInDays(parsedDate, now)
  if (diffDays) {
    return {
      timestamp: format(diffDays, 'day'),
      refreshInterval: ONE_HOUR,
    }
  }

  const diffHours = differenceInHours(parsedDate, now)
  if (diffHours) {
    return {
      timestamp: format(diffHours, 'hour'),
      refreshInterval: ONE_MINUTE,
    }
  }

  const diffMins = differenceInMinutes(parsedDate, now)
  if (diffMins) {
    return {
      timestamp: format(diffMins, 'minute'),
      refreshInterval: TWENTY_SECONDS,
    }
  }

  const diffSeconds = differenceInSeconds(parsedDate, now)
  if (Math.abs(diffSeconds) > 10) {
    return {
      timestamp: format(diffSeconds, 'second'),
      refreshInterval: FIVE_SECONDS,
    }
  }

  return {timestamp: 'just now', refreshInterval: FIVE_SECONDS}
}

export function useRelativeTime(time: Date | string, options: RelativeTimeOptions = {}): string {
  const resolved = useFormatRelativeTime(time, options)

  const [, forceUpdate] = useReducer((x) => x + 1, 0)

  useEffect(() => {
    let timerId: number | null = null

    function tick(interval: number) {
      timerId = window.setTimeout(() => {
        forceUpdate()
        timerId = window.setTimeout(() => tick(interval), interval)
      }, interval)
    }

    if (resolved.refreshInterval !== null) {
      tick(resolved.refreshInterval)
    }

    return () => {
      if (timerId !== null) {
        clearTimeout(timerId)
      }
    }
  }, [forceUpdate, resolved.refreshInterval])

  return resolved.timestamp
}
