import {CurrentUser} from '@/types/models/user'
import * as Sentry from '@sentry/nextjs'
import {createContext, useCallback, useContext} from 'react'

interface SentryUserContextData {
  currentUser: CurrentUser | null
  projectId: string | null
  orgId: string | null
}

const SentryUserContext = createContext<{
  setContext: (data: Partial<SentryUserContextData>) => void
}>({
  setContext: () => {},
})

/**
 * This is set up to tag Sentry with the current user, project, and organization.
 * It has to be the outermost provider for Sentry to pick up the tags.
 */
export function SentryUserProvider({children}: {children: React.ReactNode}) {
  const setContext = useCallback((data: Partial<SentryUserContextData>) => {
    const {currentUser, projectId, orgId} = data

    Sentry.getGlobalScope().setUser({id: currentUser?.id})
    Sentry.getGlobalScope().setTag('projectId', projectId)
    Sentry.getGlobalScope().setTag('organizationId', orgId)
    Sentry.getGlobalScope().setTag('userProvider', currentUser?.provider)
  }, [])

  return <SentryUserContext.Provider value={{setContext}}>{children}</SentryUserContext.Provider>
}

export function useSentryUserContext() {
  return useContext(SentryUserContext)
}
