import {Card, Text, Stack, Flex} from '@sanity/ui'
import styled from 'styled-components'
import {urlFor} from '@/data/communityClient'
import {TemplateData} from '@/data/projects/useTemplateData'

export const CreateProjectTemplateCard = ({template}: {template: TemplateData}) => {
  const image = template?.image
  const imageUrl = urlFor(image).url()

  const Image = styled.img`
    max-width: 100%;
    border-radius: 6px;
    margin: 0 auto;
    object-fit: contain;
    @media (min-width: ${({theme}) => theme.sanity.v2?.media[1]}px) {
      max-width: 50%;
    }
  `

  const truncatedDescription =
    template?.description.length > 120
      ? `${template?.description.slice(0, 120)}...`
      : template?.description

  return (
    <Card border={true} radius={3} padding={4}>
      <Flex gap={4} direction={['column', 'column', 'row']}>
        <Stack space={3} paddingTop={2}>
          <Text weight="semibold" size={1}>
            {template?.title}
          </Text>
          <Text muted size={1}>
            {truncatedDescription}
          </Text>
        </Stack>
        <Image src={imageUrl} alt="" />
      </Flex>
    </Card>
  )
}
