import {Grid, Stack} from '@sanity/ui'
import React from 'react'
import {ActivityOverview, MemberOverview, OverviewModule, ProjectList} from '@/ui/index'
import {
  useCurrentScopeContext,
  useOrganizationHasFeature,
  useRequiredOrganizationPermissions,
  useRoutePath,
} from '@/context/index'
import {useQuery} from '@tanstack/react-query'
import {getUsers, User} from '@sanity/access-api'

export function OrgOverview({membersV2}: {membersV2: boolean}) {
  const {basePath} = useRoutePath()
  const {org} = useCurrentScopeContext()

  const hasMembersPermission = useRequiredOrganizationPermissions([
    {permissionName: 'sanity.organization.members', grantName: 'read'},
  ])
  const {data: membersData} = useQuery({
    queryKey: ['orgMembers', {orgId: org?.id, limit: 50}],
    queryFn: () =>
      getUsers({
        path: {
          resourceType: 'organization',
          resourceId: org!.id,
        },
        query: {
          limit: 50,
        },
      }),
    // only enabled if the org is available
    enabled: org && !!org.id && hasMembersPermission,
    select: (data) => ('data' in data ? data.data : {data, totalCount: 50, nextCursor: null}),
  })
  const projects = org?.projects || []
  const members = membersData?.data || []
  const projectsCount = projects.length || org?.projects.length || 0

  // Hide activity module if the org has:
  // - Disabled activity on all projects
  // - Do not have any project(s) with the required plans for activity

  const hasEnabledActivityProjects = projects?.filter((prj) => prj?.activityFeedEnabled).length > 0
  const hasRequiredOrgFeature = useOrganizationHasFeature(org, 'activityFeed', true)
  const showActivityModule = hasEnabledActivityProjects && hasRequiredOrgFeature

  return (
    <Grid columns={[1, 1, 1, 2]} gap={6}>
      <Stack space={6}>
        {/* Left column */}
        <OverviewModule
          title={`Projects ${projectsCount > 0 ? `(${projectsCount})` : ''}`}
          buttonText="View all"
          path={`${basePath}/projects`}
        >
          <ProjectList items={projects} layout="list" max={6} />
        </OverviewModule>
      </Stack>
      <Stack space={6}>
        {/* right column */}
        {org?.id !== 'personal' && members.length > 0 && (
          <OverviewModule
            title={`Organization members ${membersData && 'totalCount' in membersData && membersData.totalCount > 0 ? `(${membersData.totalCount})` : ''}`}
            buttonText="View all"
            path={`${basePath}/members`}
          >
            <MemberOverview
              kind="org"
              membersV2={membersV2}
              userIds={
                membersData && membersData.data
                  ? (membersData.data as Array<User>).map((datum) => datum.profile.id)
                  : []
              }
            />
          </OverviewModule>
        )}
        {org?.id !== 'personal' && showActivityModule && (
          <OverviewModule title="Activity" buttonText="View more" path={`${basePath}/activity`}>
            <ActivityOverview path={`${basePath}/activity`} />
          </OverviewModule>
        )}
      </Stack>
    </Grid>
  )
}
