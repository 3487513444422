import React, {useCallback, useMemo} from 'react'
import {Stack, Text} from '@sanity/ui'
import {endOfDay} from 'date-fns'
import {DateInput} from '../../form'
import {useCurrentScopeContext, useFilter, useSetFilter} from '@/context/index'

export function DateFilter() {
  const setFilter = useSetFilter()
  const scopeContext = useCurrentScopeContext()
  const filter = useFilter()

  const handleChange = useCallback(
    (date: Date) => {
      setFilter({
        type: 'endTime',
        payload: endOfDay(date).toISOString(),
      })
    },
    [setFilter]
  )

  // Don't allow to select any date that is before the project was created
  const minDate = useMemo((): Date | undefined => {
    if (scopeContext?.scope === 'project' && scopeContext?.project?.createdAt) {
      return new Date(scopeContext?.project?.createdAt)
    }
    return undefined
  }, [scopeContext?.project?.createdAt, scopeContext?.scope])

  // Don't allow to select any date that is in the future
  const maxDate = endOfDay(new Date())

  const initialDate = useMemo(() => {
    return filter?.endTime ? new Date(filter.endTime) : new Date()
  }, [filter?.endTime])

  return (
    <Stack space={3}>
      <Text weight="semibold" size={1}>
        Date
      </Text>
      <DateInput
        onChange={handleChange}
        minDate={minDate}
        maxDate={maxDate}
        initialDate={initialDate}
        clearable
      />
    </Stack>
  )
}
