import {Box, Card, Grid} from '@sanity/ui'
import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {RadioItem, RadioItemType} from './radioItem'
import {useMatchesMedia} from '@/utils/views'

type ToggleProps = {
  options: RadioItemType[]
  onChange: (value: string) => void
  variant?: 'default' | 'border'
}

const StyledCard = styled(Card)`
  position: relative;
  background: ${({theme}) => theme.sanity.color.card.pressed.bg};
  display: flex;
  align-items: center;
`

const ActiveCard = styled(Card)<{$width: number; $variant: 'default' | 'border'}>`
  position: absolute;
  top: 0;
  left: 0;
  height: ${({$variant}) => ($variant === 'border' ? 'calc(100% - 4px)' : '100%')};
  width: ${({$width: _width, $variant}) =>
    $variant === 'border' ? `calc(${_width}% - 1px)` : `${_width}%`};
  box-shadow:
    0px 1px 2px ${({theme}) => theme.sanity.color.base.shadow.ambient},
    0px 1px 1px ${({theme}) => theme.sanity.color.base.shadow.ambient},
    0px 1px 5px ${({theme}) => theme.sanity.color.base.shadow.ambient};
  transition-property: transform;
  transition-duration: ${({theme}) => theme.sanity.input.switch.transitionDurationMs}ms;
  transition-timing-function: ${({theme}) => theme.sanity.input.switch.transitionTimingFunction};
`

export function Toggle({options, onChange, variant = 'default'}: ToggleProps) {
  const isMobile = useMatchesMedia(1)
  const defaultValue = (options || [])?.find((o) => o?.defaultChecked)?.value || ''
  const [checkedItemValue, setCheckedItemValue] = useState<string | number>(defaultValue)
  const checkedIndex = options.findIndex((a) => a.value === checkedItemValue)

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
      setCheckedItemValue(value)
      if (onChange) {
        onChange(value)
      }
    },
    [onChange]
  )

  const getMargin = useMemo(() => {
    if (variant === 'border') {
      if (checkedIndex === options.length - 1) {
        return '2px 2px 2px 0px'
      }
      return 2
    }
    return 0
  }, [checkedIndex, options.length, variant])

  return (
    <Box style={isMobile ? {width: '100%'} : {maxWidth: 'max-content'}}>
      <Card as={StyledCard} radius={variant === 'border' ? 3 : 2}>
        <ActiveCard
          padding={2}
          radius={2}
          $width={100 / options.length}
          $variant={variant}
          style={{
            transform: `translateX(${100 * checkedIndex}%)`,
            margin: getMargin,
          }}
        />
        <Grid columns={options.length} as="form" onChange={handleChange}>
          {options.map((m) => (
            <RadioItem
              key={m.id}
              label={m.label}
              value={m.value}
              id={m.id}
              name={m.name}
              _checked={checkedItemValue === m.value}
            />
          ))}
        </Grid>
      </Card>
    </Box>
  )
}
