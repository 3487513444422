import React from 'react'
import {Box, Card, CardProps, Flex, Heading, Stack, Text} from '@sanity/ui'
import {Illustration, IllustrationDrawing} from '@/ui/index'

type Props = {
  title?: string | React.ReactNode
  description?: string | React.ReactNode
  children?: React.ReactNode
  illustration?: IllustrationDrawing | false
} & CardProps

export const EmptyBlock = ({
  title,
  description,
  illustration = 'package',
  children,
  border = true,
  ...rest
}: Props) => {
  return (
    <Card border={border} padding={5} radius={2} {...(rest as any)} flex={1}>
      <Stack space={4}>
        {illustration && (
          <Heading size={5} muted>
            <Flex align="center" justify="center" style={{minHeight: 120}}>
              <Box padding={1}>
                <Illustration drawing={illustration} />
              </Box>
            </Flex>
          </Heading>
        )}
        <Flex align="center" justify="center" style={illustration ? {} : {minHeight: 180}}>
          <Stack space={3}>
            {title && typeof title === 'string' ? <Text align="center">{title}</Text> : title}
            <Box style={{maxWidth: 400}}>
              {description && typeof description === 'string' ? (
                <Text align="center" size={1} muted as="p">
                  {description}
                </Text>
              ) : (
                description
              )}
            </Box>
          </Stack>
        </Flex>
        {children}
      </Stack>
    </Card>
  )
}
EmptyBlock.displayName = 'EmptyBlock'
