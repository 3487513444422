import {useContext} from 'react'
import {SwitchPlanStoreContext} from './storeContext'

export function useSwitchPlanStore() {
  const store = useContext(SwitchPlanStoreContext)

  if (!store) throw new Error('Store: missing context value')

  return store
}
