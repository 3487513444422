const envName =
  process.env.SANITY_INTERNAL_ENV || process.env.NEXT_PUBLIC_SANITY_INTERNAL_ENV || 'staging'

let host
switch (envName) {
  case 'staging':
    host = 'sanity.work'
    break
  case 'development':
    host = process.env.SANITY_HOST || 'sanity.work'
    break
  default:
    host = 'sanity.io'
}

export const environment = {
  name: envName,
  host: host,
}
