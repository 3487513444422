import {useMutation, useQueryClient} from '@tanstack/react-query'
import {createTag, editTag} from '.'
import {Tag} from '@/types/index'
import {CardTone} from '@sanity/ui'
import {getProjectTagsKey} from '../projects/cache'

type Data = {tone: CardTone} & Omit<Tag, 'id' | 'metadata' | 'datasets'>

export function useCreateTag(projectId: string) {
  const queryClient = useQueryClient()

  const createMutation = useMutation({
    mutationKey: ['tag', 'create'],
    mutationFn: ({name, title, description, tone}: Data) =>
      createTag(projectId, {name, title, description, tone}),
    onMutate: async ({tone, ...newTag}) => {
      await queryClient.cancelQueries({queryKey: getProjectTagsKey(projectId)})
      const previousTags = queryClient.getQueryData<Tag[]>(getProjectTagsKey(projectId))
      queryClient.setQueryData(getProjectTagsKey(projectId), (old: Tag[] = []) => [
        ...old,
        {...newTag, metadata: {tone}},
      ])
      return {previousTags}
    },
    onError: (_err, _newTag, context) => {
      queryClient.setQueryData(getProjectTagsKey(projectId), context?.previousTags)
    },
    onSettled: () => {
      queryClient.invalidateQueries({queryKey: getProjectTagsKey(projectId)})
    },
  })

  const editMutation = useMutation({
    mutationKey: ['tag', 'edit'],
    mutationFn: ({tagName, ...rest}: {tagName: string} & Data) => editTag(projectId, tagName, rest),
    onMutate: async ({tagName, ...updatedTag}) => {
      await queryClient.cancelQueries({queryKey: getProjectTagsKey(projectId)})
      const previousTags = queryClient.getQueryData<Tag[]>(getProjectTagsKey(projectId))
      queryClient.setQueryData(getProjectTagsKey(projectId), (old: Tag[] = []) =>
        old.map((tag) =>
          tag.name === tagName ? {...tag, ...updatedTag, previousName: tagName} : tag
        )
      )
      return {previousTags}
    },
    onError: (_err, _variables, context) => {
      queryClient.setQueryData(getProjectTagsKey(projectId), context?.previousTags)
    },
    onSettled: () => {
      debugger
      queryClient.invalidateQueries({queryKey: getProjectTagsKey(projectId)})
    },
  })

  return {
    createTag: createMutation.mutate,
    editTag: editMutation.mutate,
    isLoading: createMutation.isPending || editMutation.isPending,
    error: createMutation.error || editMutation.error,
    data: createMutation.data || editMutation.data,
  }
}
