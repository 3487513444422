import {PlugIcon} from '@sanity/icons'
import {CorsDetails, TokensDetails, WebhooksDetails} from '../api'
import {Tab} from '@/types/index'

export const apiTab: Tab = {
  order: 7,
  title: 'API',
  icon: PlugIcon,
  path: 'api',
  permissions: {
    permissions: [
      {permissionName: 'sanity.project.cors', grantName: 'read'},
      {permissionName: 'sanity.project.tokens', grantName: 'read'},
      {permissionName: 'sanity.project.webhooks', grantName: 'read'},
    ],
    allowPartialPermissions: true,
  },
  headings: [
    {
      title: 'Webhooks',
      description: 'HTTP callbacks to a given URL when content is changed.',
      path: 'webhooks',
      fullPath: '/api',
      component: WebhooksDetails,
    },
    {
      title: 'CORS origins',
      description: 'Hosts that can connect to the project API.',
      path: 'cors-origins',
      fullPath: '/api#cors-origins',
      component: CorsDetails,
    },
    {
      title: 'Tokens',
      description: 'Tokens are used to authenticate apps and scripts to access project data.',
      path: 'tokens',
      fullPath: '/api#tokens',
      component: TokensDetails,
    },
  ],
}
