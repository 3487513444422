import React, {useCallback, useState} from 'react'
import {
  format,
  subYears,
  getYear,
  getMonth,
  isThisMonth,
  isBefore,
  startOfYear,
  endOfYear,
  subMonths,
} from 'date-fns'
import {BillingHeaderLayout} from './billingHeaderLayout'
import {MonthInput, MonthInputMenuItem} from '@/ui/index'
import {useUsageContext} from '@/context/index'
import {getDateRange} from '@/utils/usage'

const today = new Date()

export function BillingHeader({minDate = '1970-01-01'}: {minDate?: string}) {
  const [activeDate, setActiveDate] = useState<Date>(today)
  const {setBillingRange} = useUsageContext()
  const [buttonLabel, setButtonLabel] = useState<string>('Last 12 months')
  const min = new Date(minDate)

  const handleSetCurrentRange = useCallback(
    ({fromDate, toDate, label}: {fromDate: Date; toDate: Date; label: string}, newDate?: Date) => {
      if (setBillingRange && setBillingRange) {
        if (newDate) {
          setActiveDate(isThisMonth(newDate) ? today : newDate)
        }
        setButtonLabel(label)
        setBillingRange({fromDate, toDate})
      }
    },
    [setBillingRange, setButtonLabel]
  )

  // Labels used for buttons when the period is YEAR
  const last12MonthsLabel = `${format(subMonths(today, 11), 'MMM y')} - ${format(today, 'MMM y')}`
  const thisYearLabel = `${format(startOfYear(today), 'MMM y')} - ${format(
    endOfYear(today),
    'MMM y'
  )}`
  const lastYearLabel = `${format(subYears(startOfYear(today), 1), 'MMM y')} - ${format(
    subYears(endOfYear(today), 1),
    'MMM y'
  )}`

  // Menu items when the period is YEAR
  const yearMenuItems: MonthInputMenuItem[] = [
    {
      title: 'Last 12 months',
      selected: buttonLabel === 'Last 12 months',
      subTitle: last12MonthsLabel,
      // disabled: isBefore(subYears(today, 1), min),
      onClick: () => handleSetCurrentRange(getDateRange({range: 'last12Months', date: today})),
    },
    {
      title: 'This year',
      selected: buttonLabel === 'This year',
      subTitle: thisYearLabel,
      // disabled: isBefore(startOfYear(today), min),
      onClick: () => handleSetCurrentRange(getDateRange({range: 'thisYear', date: today})),
    },
    {
      title: 'Last year',
      selected: buttonLabel === 'Last year',
      filterDate: subYears(today, 1),
      subTitle: lastYearLabel,
      // disabled: isBefore(subYears(startOfYear(today), 1), min),
      onClick: () => handleSetCurrentRange(getDateRange({range: 'lastYear', date: today})),
    },
  ].filter((item) => !item.filterDate || isBefore(min, item?.filterDate))

  return (
    <BillingHeaderLayout>
      <MonthInput
        buttonType="month"
        label="Select starting month"
        min={{year: getYear(min), month: getMonth(min)}}
        max={{year: getYear(today), month: getMonth(today)}}
        initialDate={activeDate}
        onChange={(selectedDate) => {
          const newDate = isThisMonth(selectedDate) ? new Date() : selectedDate
          handleSetCurrentRange(getDateRange({date: newDate, range: 'year'}), newDate)
        }}
        buttonProps={{
          text: buttonLabel,
        }}
        menuItems={yearMenuItems}
      />
    </BillingHeaderLayout>
  )
}
