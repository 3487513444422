import React from 'react'

export const Coupon = () => (
  <svg
    width="167"
    height="142"
    viewBox="0 0 167 142"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_866_26979)">
      <rect
        x="0.228301"
        y="84.632"
        width="137.068"
        height="94.4058"
        rx="5.5"
        transform="rotate(-37 0.228301 84.632)"
        stroke="#3F434A"
        strokeLinecap="round"
      />
      <path
        d="M61.6363 76.4152C65.4032 81.414 70.7865 82.0206 78.2588 78.8488L86.0686 75.3442C93.0343 72.2014 99.1741 72.8062 103.271 78.2423C105.101 80.5757 106.06 83.4782 105.985 86.4634C101.898 81.0398 96.8342 80.2612 88.618 83.6899L80.9714 87.1597C74.8452 89.9343 68.7329 90.2395 64.2834 84.3347C62.5375 82.0752 61.6063 79.2892 61.6363 76.4152Z"
        fill="#9EA6B3"
      />
      <path
        d="M95.9518 81.6708C100.142 81.3154 103.272 82.8754 105.974 86.462C105.853 91.1971 102.971 95.9746 98.1117 99.6364C89.9322 105.8 81.2274 106.158 74.7759 100.244L82.631 94.3245C86.0201 96.7179 89.7996 96.1622 93.3742 93.4686C97.7374 90.1806 98.9144 85.7081 95.9488 81.6535"
        fill="#CED2D9"
      />
      <path
        d="M71.304 80.7489C69.4346 80.9692 67.5487 80.6826 65.8465 79.9196C64.1444 79.1566 62.6895 77.9457 61.6363 76.4152C61.6939 71.7674 64.2924 66.9977 69.1072 63.3695C77.4393 57.0908 85.5188 57.7817 91.2944 62.9714L83.7382 68.6654C81.0969 66.8938 77.6028 66.5976 73.7993 69.4638C69.735 72.5264 68.7135 76.9406 71.3231 80.7345"
        fill="#CED2D9"
      />
      <mask id="path-5-inside-1_866_26979" fill="white">
        <path d="M8 141H159V160H8V141Z" />
      </mask>
      <path d="M8 141H159V160H8V141Z" fill="white" />
      <path d="M8 142H159V140H8V142Z" fill="#3F434A" mask="url(#path-5-inside-1_866_26979)" />
    </g>
    <defs>
      <clipPath id="clip0_866_26979">
        <rect width="167" height="142" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
Coupon.displayName = 'Coupon'
