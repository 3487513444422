import {Box, type BoxProps} from '@sanity/ui'
import {forwardRef, type ReactNode} from 'react'
import {styled} from 'styled-components'

const Root = styled(Box)`
  position: relative;
  border-collapse: collapse;

  &:not([hidden]) {
    display: table-row-group;
    width: 100%;
  }
`

export interface TableBodyProps {
  children: ReactNode
  overflow?: BoxProps['overflow']
}

export const TableBody = forwardRef(function TableBody(
  props: TableBodyProps & Omit<React.HTMLProps<HTMLTableSectionElement>, 'as' | 'height' | 'ref'>,
  ref: React.Ref<HTMLTableSectionElement>
) {
  const {children, overflow, ...rest} = props

  return (
    <Root forwardedAs="tbody" overflow={overflow} ref={ref} {...rest}>
      {children}
    </Root>
  )
})
