import {Box, Button} from '@sanity/ui'
import React from 'react'
import {CogIcon} from '@sanity/icons'
import {TabSectionHeader, AppLink} from '@/ui/index'
import {Project} from '@/types/models'

type Props = {
  title: string
  description: string
  project: Project
}

export function Billing({title, description, project}: Props) {
  return (
    <>
      <TabSectionHeader title={title} description={description} />
      <Box>
        <AppLink href={`/organizations/${project.organizationId}/settings`}>
          <Button text="Organization billing settings" mode="ghost" icon={CogIcon} as="span" />
        </AppLink>
      </Box>
    </>
  )
}
