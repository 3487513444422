import styled, {css} from 'styled-components'
import {Badge} from '@sanity/ui'

interface BaseBadgeProps {
  $variant: {background: string; color: string; boxShadow: string}
}

export const BaseBadge = styled(Badge)<BaseBadgeProps>`
  ${({$variant}) =>
    $variant &&
    css`
      background: ${$variant.background};
      box-shadow: inset 0 0 0 1px ${$variant.background};

      [data-ui='Text'],
      [data-sanity-icon] {
        color: ${$variant.color} !important;
      }
    `}
`
