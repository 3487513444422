import {Box, Button, ButtonProps, Dialog} from '@sanity/ui'
import React, {useState, useCallback} from 'react'

type Props = {
  dialogHeader?: React.ReactNode
  dialogFooter?: React.ReactNode
  dialogId: string
  buttonProps?: any | ButtonProps
  children: React.ReactNode
}

export function DialogButton({dialogHeader, dialogFooter, dialogId, buttonProps, children}: Props) {
  const [isOpen, setOpen] = useState(false)
  const onClose = useCallback(() => setOpen(false), [])
  const onOpen = useCallback(() => setOpen(true), [])
  return (
    <>
      <Button onClick={onOpen} {...buttonProps} />
      {isOpen && (
        <Dialog
          id={dialogId}
          header={dialogHeader}
          footer={dialogFooter}
          onClose={onClose}
          width={1}
          style={{position: 'fixed'}}
        >
          <Box padding={4}>{children}</Box>
        </Dialog>
      )}
    </>
  )
}
