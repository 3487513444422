import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query'
import {CorsOrigin} from '../../types/models'
import {addCorsOrigin, deleteCorsOrigin, getCorsOrigins} from './'
import {getCorsOriginsCacheKey} from './cache'
import {useRequiredProjectPermissions} from '../../context'

export function useCors(projectId: string) {
  const queryClient = useQueryClient()
  const canReadProjectCors = useRequiredProjectPermissions([
    {permissionName: 'sanity.project.cors', grantName: 'read'},
  ])

  const {
    data: corsOrigins = [],
    error,
    isLoading,
  } = useQuery({
    queryKey: getCorsOriginsCacheKey(projectId),
    queryFn: () => getCorsOrigins(projectId),
    enabled: canReadProjectCors,
  })

  const addOriginMutation = useMutation({
    mutationKey: ['addCorsOrigin', projectId],
    mutationFn: ({origin, allowCredentials}: {origin: string; allowCredentials: boolean}) =>
      addCorsOrigin(projectId, {origin, allowCredentials}),
    onMutate: (newOrigin) => {
      queryClient.setQueryData(
        getCorsOriginsCacheKey(projectId),
        (old: CorsOrigin[] | undefined) => [
          ...(old || []),
          {...newOrigin, createdAt: new Date().toISOString()},
        ]
      )
    },
    onSettled: () => {
      queryClient.invalidateQueries({queryKey: getCorsOriginsCacheKey(projectId)})
    },
  })

  const deleteOriginMutation = useMutation({
    mutationKey: ['deleteCorsOrigin', projectId],
    mutationFn: (originId: string) => deleteCorsOrigin(projectId, originId),
    onMutate: (deletedOriginId: string) => {
      queryClient.setQueryData(
        getCorsOriginsCacheKey(projectId),
        (old: CorsOrigin[] | undefined) =>
          old?.filter((origin) => origin.id !== deletedOriginId) || []
      )
    },
    onSettled: () => {
      queryClient.invalidateQueries({queryKey: getCorsOriginsCacheKey(projectId)})
    },
  })

  return {
    corsOrigins,
    error,
    isLoading,
    addOrigin: addOriginMutation.mutate,
    deleteOrigin: deleteOriginMutation.mutate,
    isAdding: addOriginMutation.isPending,
    isDeleting: deleteOriginMutation.isPending,
    addError: addOriginMutation.error,
    deleteError: deleteOriginMutation.error,
  }
}
