import {hues} from '@sanity/color'
import {Box} from '@sanity/ui'
import {forwardRef, type ReactNode} from 'react'
import {css, styled} from 'styled-components'

const Root = styled(Box)<{$constrainSize?: boolean}>(({theme, $constrainSize}) => {
  return css`
    min-width: ${$constrainSize ? undefined : 'max-content'};

    width: 100%;
    border-collapse: collapse;

    &:not([hidden]) {
      display: table;
    }

    &[data-debug] {
      th:nth-child(odd) {
        border: 1px solid ${hues.blue[theme.sanity.color.dark ? 800 : 300].hex};
        background-color: ${hues.blue[theme.sanity.color.dark ? 950 : 50].hex};
      }

      th:nth-child(even) {
        border: 1px solid ${hues.magenta[theme.sanity.color.dark ? 800 : 300].hex};
        background-color: ${hues.magenta[theme.sanity.color.dark ? 950 : 50].hex};
      }

      td:nth-child(odd) {
        border: 1px solid ${hues.blue[theme.sanity.color.dark ? 700 : 200].hex};
        background-color: ${hues.blue[theme.sanity.color.dark ? 950 : 50].hex};
      }

      td:nth-child(even) {
        border: 1px solid ${hues.magenta[theme.sanity.color.dark ? 700 : 200].hex};
        background-color: ${hues.magenta[theme.sanity.color.dark ? 950 : 50].hex};
      }
    }
  `
})

export interface TableProps {
  children: ReactNode
  constrainSize?: boolean
}

export const Table = forwardRef(function Table(
  props: TableProps & Omit<React.HTMLProps<HTMLTableElement>, 'as' | 'height' | 'ref' | 'wrap'>,
  ref: React.Ref<HTMLTableElement>
) {
  const {children, constrainSize = false, ...rest} = props

  return (
    <Root ref={ref} forwardedAs="table" sizing="border" $constrainSize={constrainSize} {...rest}>
      {children}
    </Root>
  )
})
