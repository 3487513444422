import {useEffect, useState} from 'react'
import {useCurrentScopeContext} from './useCurrentScopeContext'
import {useProjectSubscription} from '@/data/projects/useProjectSubscription'
import {PlanTypeIds} from '@/types/index'

export function useRequiredPlanAccess(plans?: PlanTypeIds[]) {
  const {project, scope} = useCurrentScopeContext()
  const [hasAccess, setHasAccess] = useState<boolean>(true)
  const {data: subscription} = useProjectSubscription(project?.id)

  useEffect(() => {
    if (project && scope == 'project') {
      const access =
        plans && subscription?.plan?.planTypeId
          ? plans.includes(subscription?.plan?.planTypeId as PlanTypeIds)
          : true
      setHasAccess(access)
    } else {
      setHasAccess(true)
    }
  }, [plans, project, subscription, scope])

  return hasAccess
}
