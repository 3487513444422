import {useQuery} from '@tanstack/react-query'
import {getUsers} from './controller'
import {timeToMilliseconds} from '@/utils/time'
import {useMemo} from 'react'
import {type User} from '@/types/index'

function filterNullUsers(data: (User | null)[]) {
  return data.filter((user) => user !== null)
}

export function useUsers(userIds: string[], enabled = true) {
  const queryKey = useMemo(() => ['users', userIds.sort().join('-')], [userIds])

  return useQuery({
    queryKey,
    queryFn: () => getUsers(userIds),
    staleTime: timeToMilliseconds(1, 'hours'),
    select: (data) => filterNullUsers(data),
    enabled,
    gcTime: timeToMilliseconds(1, 'days'),
  })
}
