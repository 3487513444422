import React, {useCallback} from 'react'
import {Card, Stack, useToast} from '@sanity/ui'
import {useRouter} from 'next/router'
import {getProjectPath} from '@/utils/getProjectPath'
import type {Project} from '@/types/index'
import {CreateNewProject} from '@/components/form/index'
import {Dialog} from '../general'
import {useSearchParams} from 'next/navigation'
import {useTemplateData} from '@/data/projects/useTemplateData'
import {CreateProjectTemplateCard} from './createProjectTemplateCard'
import {useProjectOnboardingData} from '@/data/journey/useProjectOnboardingData'
import {useQueryClient} from '@tanstack/react-query'
import {getCurrentUserPermissionsKey} from '../../../data/access/cache'

export function CreateProjectDialog({onClose}: {onClose: () => void}) {
  const router = useRouter()
  const queryClient = useQueryClient()
  const {push: pushToast} = useToast()
  const searchParams = useSearchParams()
  const templateSlug = searchParams.get('template')
  const {data: templateData} = useTemplateData(templateSlug || '')
  const {updateAsync: updateProjectOnboardingData} = useProjectOnboardingData()

  const usingTemplate = Boolean(templateSlug && templateData)

  const handleSuccess = async (project: Project) => {
    if (usingTemplate) {
      await updateProjectOnboardingData({
        projectId: project.id,
        templateSlug: templateSlug!,
      })
    }
    queryClient.invalidateQueries({queryKey: getCurrentUserPermissionsKey()})
    pushToast({
      closable: true,
      status: 'success',
      title: 'Project created!',
      description: 'Your project has successfully been created',
    })
    /* Redirect to project */
    const projectBase = getProjectPath(project)
    router.push(`${projectBase}/getting-started?ref=create-project`)
    onClose()
  }

  const handleClose = useCallback(() => {
    /* Remove the query parameter that opens the dialog automatically if it is present */
    const {'new-project': _newProject, template: _template, ...otherParams} = router.query
    router.replace({query: otherParams})
    onClose()
  }, [router, onClose])

  return (
    <Dialog
      header={usingTemplate ? 'Create new project with template' : 'Create new project'}
      id="create-new-project-dialog"
      onClose={handleClose}
      onClickOutside={handleClose}
      width="500px"
    >
      <Card padding={4}>
        <Stack space={5}>
          {usingTemplate && <CreateProjectTemplateCard template={templateData!} />}
          <CreateNewProject onCreate={handleSuccess} />
        </Stack>
      </Card>
    </Dialog>
  )
}
