import {marked} from 'marked'
import type {PortableTextBlock} from '@portabletext/react'
import type {Lesson} from '@/data/projects/useGettingStarted'
import {randomKey} from '@/utils/general'

const GETTING_STARTED_GUIDE_NEEDLE = 'Getting Started'
const COURSE_HEADING_DEPTH = 2
const LESSON_HEADING_DEPTH = 3
const INIT_COMMAND_PATTERN = /^npm create sanity@latest -- --template [^ ]+$/ // Matches "npm create sanity@latest -- --template <template>"

type Token = ReturnType<typeof marked.lexer>[number]

interface InlineToken {
  type: 'text' | 'strong' | 'em' | 'codespan' | 'link'
  text: string
  href?: string
  openInNewWindow?: boolean
}

interface ListItem {
  type: 'list_item'
  text: string
  tokens: [
    {
      type: 'text' | 'paragraph'
      text: string
      tokens?: InlineToken[]
    },
  ]
}

function createHeadingBlock(text: string): PortableTextBlock {
  return {
    _type: 'block',
    _key: randomKey(12),
    style: 'h2', // Force all headings to be h2
    children: [
      {
        _key: randomKey(12),
        _type: 'span',
        text: text.replace(/^\d+\.\s+/, ''), // Strip '1. ' pattern from start of headings
      },
    ],
    markDefs: [],
  }
}

function processInlineTokens(tokens: InlineToken[]) {
  return tokens.map((token) => {
    const span = {
      _key: randomKey(12),
      _type: 'span',
      text: '',
      marks: [] as string[],
    }

    switch (token.type) {
      case 'text':
        span.text = token.text
        break

      case 'strong':
        span.text = token.text
        span.marks.push('strong')
        break

      case 'em':
        span.text = token.text
        span.marks.push('em')
        break

      case 'codespan':
        span.text = token.text
        span.marks.push('code')
        break

      case 'link':
        span.text = token.text
        span.marks.push(randomKey(12))
        return {
          span,
          markDef: {
            _key: span.marks[0],
            _type: 'link',
            href: token.href,
            openInNewWindow: true,
          },
        }
    }
    return {span}
  })
}

// Todo: Fix the type of the token parameter
function createParagraphBlock(token: any): PortableTextBlock {
  const inlineTokens = token.tokens || [{type: 'text', text: token.text}]
  const processed = processInlineTokens(inlineTokens)

  return {
    _type: 'block',
    _key: randomKey(12),
    style: 'normal',
    children: processed.map(({span}) => span),
    markDefs: processed
      .map(({markDef}) => markDef)
      .filter((def): def is NonNullable<typeof def> => def != null),
  }
}

function createListItemBlock(item: ListItem, ordered: boolean): PortableTextBlock {
  const firstToken = item.tokens[0]
  // Use its inline tokens or create a simple text token if none exist
  const inlineTokens = firstToken.tokens || [{type: 'text', text: firstToken.text}]
  const processed = processInlineTokens(inlineTokens)

  return {
    _type: 'block',
    _key: randomKey(12),
    style: 'normal',
    listItem: ordered ? 'number' : 'bullet',
    level: 1,
    children: processed.map(({span}) => span),
    markDefs: processed
      .map(({markDef}) => markDef)
      .filter((def): def is NonNullable<typeof def> => def != null),
  }
}

function createCodeBlock(text: string, lang: string | undefined): PortableTextBlock {
  // Check for a filename in the first line - matches "// filename.js" pattern
  const [, filename] = text.match(/^\/\/ (.+)$/m) || []
  // Remove filename line if present and trim whitespace
  let code = text.replace(/^\/\/ .+\n/, '').trim()
  // Append project/dataset placeholders to init command
  if (INIT_COMMAND_PATTERN.test(code)) {
    code += ' --project {{PROJECT_ID}} --dataset {{PROJECT_DATASET_0}}'
  }
  return {
    _type: 'code',
    _key: randomKey(12),
    // @ts-expect-error — This is an object block
    language: lang || 'text',
    filename,
    code,
  }
}

function tokenToPortableText(token: Token): PortableTextBlock[] {
  const blocks: PortableTextBlock[] = []

  switch (token.type) {
    case 'heading':
      if (token.depth === 2) return blocks
      blocks.push(createHeadingBlock(token.text))
      break

    case 'paragraph':
      blocks.push(createParagraphBlock(token))
      break

    case 'list':
      if (!('items' in token)) return blocks
      for (const item of token.items as ListItem[]) {
        blocks.push(createListItemBlock(item, token.ordered))
      }
      break

    case 'code':
      blocks.push(createCodeBlock(token.text, token.lang))
      break
  }

  return blocks
}

function createLesson(tokens: Token[], index: number, contentLength: number): Lesson {
  const titleToken = tokens[0] as Token & {text: string}
  return {
    _id: `lesson-${index}`,
    title: titleToken.text,
    slug: {
      _type: 'slug',
      current: `lesson-${index}`,
    },
    content: tokens.slice(1).flatMap(tokenToPortableText),
    readTimeInMinutes: Math.ceil(contentLength / 1000),
  }
}

export function parseMarkdownToLessons(markdown: string): Lesson[] {
  const prefix = '#'.repeat(COURSE_HEADING_DEPTH)
  const startHeading = `${prefix} ${GETTING_STARTED_GUIDE_NEEDLE}`
  const contentStart = markdown.indexOf(startHeading)

  if (contentStart === -1) return []

  const nextHeading = markdown.indexOf(`\n${prefix} `, contentStart + startHeading.length)
  const contentEnd = nextHeading === -1 ? undefined : nextHeading

  const gettingStartedContent = markdown
    .slice(contentStart + startHeading.length, contentEnd)
    .trim()

  const tokens = marked.lexer(gettingStartedContent)

  const lessons: Lesson[] = []
  let currentLessonTokens: Token[] = []

  for (const token of tokens) {
    const isNewLesson = token.type === 'heading' && token.depth === LESSON_HEADING_DEPTH
    const shouldAddLesson = isNewLesson && currentLessonTokens.length > 0

    if (shouldAddLesson) {
      lessons.push(createLesson(currentLessonTokens, lessons.length, gettingStartedContent.length))
    }
    if (isNewLesson) {
      currentLessonTokens = [token]
      continue
    }
    if (currentLessonTokens.length > 0) {
      currentLessonTokens.push(token)
    }
  }

  if (currentLessonTokens.length > 0) {
    lessons.push(createLesson(currentLessonTokens, lessons.length, gettingStartedContent.length))
  }

  return lessons
}
