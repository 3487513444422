import React, {useCallback, useMemo} from 'react'
import {Box, Card, Flex, Inline, Text} from '@sanity/ui'
import {DatabaseIcon, EditIcon} from '@sanity/icons'
import Link from 'next/link'
import {Dataset, Project, Tag as TagT} from '@/types/models'
import {useRequiredProjectPermissions, useRoutePath} from '@/context/index'
import {NoElementsTooltip, PermissionTooltip} from '@/ui/index'
import {MenuMultiSelect, MenuMultiSelectOption} from '@/components/general/menuMultiSelect'
import {useTagDataset} from '@/data/tags/useTagDataset'

type Props = {
  project: Project
  tag: TagT
  datasets: Dataset[]
}
export function TagDatasetSelect({project, tag, datasets}: Props) {
  const {basePath} = useRoutePath()
  const tagDatasets = useMemo(() => {
    return tag.datasets || []
  }, [tag])

  const hasPermission = useRequiredProjectPermissions([
    {permissionName: 'sanity.project.tags', grantName: 'read'},
    {permissionName: 'sanity.project.datasets', grantName: 'update'},
  ])

  const {addTagDataset, removeTagDataset, isLoading} = useTagDataset(project.id, tag.name)

  const values = useMemo(() => {
    return tagDatasets.sort((a, b) => a.name.localeCompare(b.name)).map((dataset) => dataset.name)
  }, [tagDatasets])

  const options: MenuMultiSelectOption[] = useMemo(() => {
    return datasets
      .sort((a, b) => {
        // If a dataset is selected, it should be at the top
        const aSelected = values.includes(a.name)
        const bSelected = values.includes(b.name)
        if (aSelected && !bSelected) return -1
        if (!aSelected && bSelected) return 1
        return a.name.localeCompare(b.name)
      })
      .map((dataset) => ({name: dataset.name, title: '', description: ''}))
  }, [datasets, values])

  const formatOption = useCallback((dataset: Dataset) => {
    return (
      <Card tone="transparent" padding={2} radius={2}>
        <Flex align="center">
          <Box marginRight={2}>
            <Text size={1}>
              <DatabaseIcon />
            </Text>
          </Box>
          <Text size={1}>{dataset.name}</Text>
        </Flex>
      </Card>
    )
  }, [])

  const formatLinkOption = useCallback(
    (dataset: Dataset) => {
      return (
        <Link
          href={`${basePath}/datasets?name=${dataset.name}`}
          key={`dataset-link-${dataset.name}`}
        >
          <Card tone="transparent" padding={2} radius={2}>
            <Flex align="center">
              <Box marginRight={2}>
                <Text size={1}>
                  <DatabaseIcon />
                </Text>
              </Box>
              <Text size={1}>{dataset.name}</Text>
            </Flex>
          </Card>
        </Link>
      )
    },
    [basePath]
  )

  const handleTagDatasetRemove = useCallback(
    (datasetName: string) => {
      if (!hasPermission) return
      removeTagDataset({datasetName})
    },
    [hasPermission, removeTagDataset]
  )

  const handleTagDatasetAdd = useCallback(
    (datasetName: string) => {
      if (!hasPermission) return
      addTagDataset({datasetName})
    },
    [hasPermission, addTagDataset]
  )

  const handleDatasetTagChange = useCallback(
    (tagName) => {
      const has = tagDatasets.some((dataset) => dataset.name === tagName)
      if (has) {
        return handleTagDatasetRemove(tagName)
      }
      return handleTagDatasetAdd(tagName)
    },
    [handleTagDatasetAdd, handleTagDatasetRemove, tagDatasets]
  )

  return (
    <Inline space={1} flex={1}>
      {tagDatasets.length === 0 ? (
        <Card border padding={2} radius={2} style={{borderStyle: 'dashed'}}>
          <Text align="center" muted size={1}>
            No datasets
          </Text>
        </Card>
      ) : (
        <Inline space={2}>{tagDatasets.map(formatLinkOption)}</Inline>
      )}

      <NoElementsTooltip
        data={datasets}
        title="No datasets"
        description="There are no datasets in this project"
      >
        <PermissionTooltip disabled={hasPermission}>
          <MenuMultiSelect
            id={`tag-${tag.name}-datasets`}
            disabled={!hasPermission || datasets.length === 0}
            text=""
            icon={EditIcon}
            values={values}
            options={options}
            optionRender={formatOption}
            optionPaddingX={1}
            optionPaddingY={1}
            onChange={handleDatasetTagChange}
            loading={isLoading}
          />
        </PermissionTooltip>
      </NoElementsTooltip>
    </Inline>
  )
}
