import {ChartGroupSection} from '@/components/usage'
import {Tab} from '@/types/components'
import {ChartUpwardIcon} from '@sanity/icons'
import {
  OrgPlanUsage,
  PlaygroundUsage,
  UsageOrgPlanHeader,
  UsagePlaygroundHeader,
  PlaygroundSummary,
} from '../../org'
import {inviteConfig} from '../../org/inviteConfig'
import {getCommonTabs} from './getCommonTabs'
import {GetOrgTabsOptions} from '../types'

export function getOrgPlanTabs(opts: GetOrgTabsOptions): Tab[] {
  const {overview, projects, members, activity, billing, settings} = getCommonTabs(opts)

  return [
    overview,
    projects,
    members,
    activity,
    {
      title: 'Usage',
      icon: ChartUpwardIcon,
      path: 'usage',
      referenceLink: {
        label: 'Learn more about plans and payments in Sanity Docs',
        url: `https://www.${process.env.host}/docs/plans-and-payments`,
      },
      headings: [
        {
          title: 'Organization usage',
          path: 'org-usage',
          description:
            'An overview of usage across all projects within your organization. Any usage exceeding quota is indicated in the relevant sections. All usage metrics are updated daily.',
          component: OrgPlanUsage,
          isRoot: true,
        },
        {
          title: 'Playground projects',
          description:
            'An overview of usage across all playground projects within your organization. Any usage exceeding quota is indicated in the relevant sections. All usage metrics are updated daily.',
          path: 'playground-projects',
          component: PlaygroundUsage,
        },
      ],
      sections: [
        // Org usage
        {
          title: 'Header',
          heading: 'org-usage',
          component: UsageOrgPlanHeader,
          hideFromSidebar: true,
        },
        {
          title: 'Bandwidth & APIs',
          heading: 'org-usage',
          path: 'bandwidth-api',
          component: ChartGroupSection,
        },
        {
          title: 'Resources & datasets',
          heading: 'org-usage',
          path: 'resources',
          component: ChartGroupSection,
        },
        {
          title: 'Header',
          heading: 'playground-projects',
          component: UsagePlaygroundHeader,
          hideFromSidebar: true,
        },
        {
          title: 'Summary',
          heading: 'playground-projects',
          path: 'playground-summary',
          component: PlaygroundSummary,
          hideFromSidebar: true,
        },
        {
          title: 'Bandwidth & APIs',
          heading: 'playground-projects',
          path: 'pg_bandwidth-api',
          component: ChartGroupSection,
        },
        {
          title: 'Resources & datasets',
          heading: 'playground-projects',
          path: 'pg_resources',
          component: ChartGroupSection,
        },
      ],
    },
    billing,
    settings,
    inviteConfig,
  ]
}
