import React, {useCallback} from 'react'
import {Card, Text, Inline, Flex} from '@sanity/ui'
import {AddIcon} from '@sanity/icons'
import {useWizardStore} from '../payment-wizard'
import {Illustration, MemberAvatar, PermissionButtonOrg, PermissionButtonProject} from '@/ui/index'
import {useRoutePath} from '@/context/index'

interface Props {
  kind: 'project' | 'org'
  displayName?: string
  userIds: string[]
  membersV2?: boolean
}

export function MemberOverview({kind, userIds = [], membersV2}: Props) {
  const {basePath} = useRoutePath() || {}
  const {dispatch} = useWizardStore()
  const handleAddMemberClicked = useCallback(() => {
    dispatch({type: 'inviteMembersWizard/open'})
  }, [dispatch])
  const isSingleMember = userIds.length === 1

  return (
    <Card shadow={1} padding={3} paddingBottom={isSingleMember ? 4 : 3} radius={2}>
      <Flex align={isSingleMember ? 'center' : 'flex-start'} direction="column" gap={4}>
        {isSingleMember ? (
          <Flex direction="column" align="center" gap={1}>
            <Illustration drawing="sso" />
            <Text size={2} muted align="center">
              Invite your first team member
            </Text>
          </Flex>
        ) : (
          <Inline space={2}>
            {userIds.slice(0, 50).map((userId) => {
              return <MemberAvatar key={`member-${userId}`} userId={userId} size={1} tooltip />
            })}
          </Inline>
        )}

        {basePath && kind === 'org' && (
          <PermissionButtonOrg
            href={`${basePath}/${membersV2 ? 'members/invitations' : 'invite'}`}
            title={'Invite organization members'}
            onClick={membersV2 ? handleAddMemberClicked : undefined}
            icon={AddIcon}
            permissions={[{permissionName: 'sanity.organization.members', grantName: 'invite'}]}
          />
        )}
        {basePath && kind === 'project' && (
          <PermissionButtonProject
            href={`${basePath}/members`}
            title={'Invite project members'}
            icon={AddIcon}
            onClick={handleAddMemberClicked}
            permissions={[{permissionName: 'sanity.project.members', grantName: 'invite'}]}
            mode="ghost"
          />
        )}
      </Flex>
    </Card>
  )
}
