import React, {Fragment} from 'react'
import {Card, Flex, Inline, Text, ThemeColorToneKey, useTheme} from '@sanity/ui'
import {Icon, IconSymbol} from '@sanity/icons'
import Link from 'next/link'
import {useMatchesMedia} from '@/utils/views'

function TextIcon({iconSymbol, textColor}: {iconSymbol: IconSymbol; textColor: string}) {
  return (
    <Text style={{paddingRight: '4px'}}>
      <Icon style={{marginLeft: 0}} color={textColor} symbol={iconSymbol} />
    </Text>
  )
}

interface CallToActionProps {
  text: string
  textColor: string
  href?: string
  onClick?: () => void
}

function CallToAction({text, textColor, href, onClick}: CallToActionProps) {
  if (!href) {
    return <Text>{text}</Text>
  }

  return (
    <Flex>
      <Link href={href} onClick={onClick} style={{color: textColor}}>
        <Text>{text}</Text>
      </Link>
      <TextIcon textColor={textColor} iconSymbol={'arrow-right'} />
    </Flex>
  )
}

interface BannerProps {
  tone: ThemeColorToneKey
  icon: IconSymbol
  title: string
  description: string
  ctaText?: string
  ctaHref?: string
  ctaOnClick?: () => void
}

export function Banner({
  tone,
  icon,
  title,
  description,
  ctaText,
  ctaHref,
  ctaOnClick,
}: BannerProps) {
  const isMobile = useMatchesMedia(1)
  const {sanity} = useTheme()
  const textColor = sanity.color.solid[tone].pressed.bg

  const Title = isMobile
    ? (props: React.PropsWithChildren<unknown>) => {
        return (
          <Flex justify={'flex-start'} style={{marginBottom: '5px', gap: '5px'}}>
            {props.children}
          </Flex>
        )
      }
    : Fragment

  const Description = isMobile
    ? (props: React.PropsWithChildren<unknown>) => {
        return (
          <Inline space={[2, 3]} style={{gap: '5px', textAlign: 'left'}}>
            {props.children}
          </Inline>
        )
      }
    : Fragment

  return (
    <Card
      tone={tone}
      padding={3}
      shadow={1}
      style={{textAlign: 'center', paddingTop: '1rem', paddingBottom: '1rem'}}
    >
      <Flex
        gap={2}
        justify={'center'}
        align={isMobile ? 'stretch' : 'center'}
        direction={isMobile ? 'column' : 'row'}
      >
        <Title>
          <TextIcon textColor={textColor} iconSymbol={icon} />
          <Text style={{color: textColor}} weight="semibold">
            {title}
          </Text>
        </Title>
        <Description>
          <Text align={'left'} style={{color: textColor}}>
            {' '}
            {description}
          </Text>
          {ctaText && (
            <CallToAction
              text={ctaText}
              textColor={textColor}
              href={ctaHref}
              onClick={ctaOnClick}
            />
          )}
        </Description>
      </Flex>
    </Card>
  )
}
