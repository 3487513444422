import {purple} from '@sanity/color'
import {Button, ButtonProps} from '@sanity/ui'
import styled from 'styled-components'
import {useManageScheme, type ResolvedScheme} from '@/ui/app/manageSchemeProvider'
import {ComponentProps} from 'react'

type CustomTone = 'purple'

export interface ButtonWithExtendedTonesProps extends Omit<ComponentProps<typeof Button>, 'tone'> {
  tone?: ButtonProps['tone'] | CustomTone
}

const customTones: Record<CustomTone, Record<string, {hex: string}>> = {
  purple,
  // Add more hex objects here to support more custom tones
}

const getCustomTone = (
  theme: CustomTone,
  mode: ButtonProps['mode'],
  token: 'bg' | 'border' | 'fg',
  scheme: ResolvedScheme
) => {
  const hexMap = customTones[theme]

  const colorMap = {
    light: {
      bleed: {
        bg: hexMap['50'].hex,
        border: hexMap['200'].hex,
        fg: hexMap['700'].hex,
      },
      ghost: {
        bg: hexMap['50'].hex,
        border: hexMap['200'].hex,
        fg: hexMap['700'].hex,
      },
      default: {
        bg: hexMap['600'].hex,
        border: hexMap['600'].hex,
        fg: undefined,
      },
    },

    dark: {
      bleed: {
        bg: hexMap['950'].hex,
        border: hexMap['800'].hex,
        fg: hexMap['300'].hex,
      },
      ghost: {
        bg: hexMap['950'].hex,
        border: hexMap['800'].hex,
        fg: hexMap['200'].hex,
      },
      default: {
        bg: hexMap['400'].hex,
        border: hexMap['400'].hex,
        fg: undefined,
      },
    },
  }

  const modeWithFallback = mode || 'default'
  return colorMap[scheme][modeWithFallback][token]
}

const ThemedButton = styled(Button)<{tone: CustomTone; scheme: ResolvedScheme}>`
  --card-bg-color: ${(p) => getCustomTone(p.tone, p.mode, 'bg', p.scheme)};
  --card-border-color: ${(p) => getCustomTone(p.tone, p.mode, 'border', p.scheme)};
  --card-fg-color: ${(p) => getCustomTone(p.tone, p.mode, 'fg', p.scheme)};
  --card-icon-color: ${(p) => getCustomTone(p.tone, p.mode, 'fg', p.scheme)};

  &:hover:not(:disabled) {
    --card-bg-color: color-mix(
      in srgb,
      ${(p) => getCustomTone(p.tone, p.mode, 'bg', p.scheme)},
      ${(p) => (p.scheme === 'light' ? 'black' : 'white')} 5%
    ) !important;

    --card-border-color: color-mix(
      in srgb,
      ${(p) => getCustomTone(p.tone, p.mode, 'border', p.scheme)},
      ${(p) => (p.scheme === 'light' ? 'black' : 'white')} 5%
    ) !important;

    --card-fg-color: color-mix(
      in srgb,
      ${(p) => getCustomTone(p.tone, p.mode, 'fg', p.scheme)},
      ${(p) => (p.scheme === 'light' ? 'black' : 'white')} 5%
    ) !important;

    --card-icon-color: color-mix(
      in srgb,
      ${(p) => getCustomTone(p.tone, p.mode, 'fg', p.scheme)},
      ${(p) => (p.scheme === 'light' ? 'black' : 'white')} 5%
    ) !important;
  }
`

export const ButtonWithExtendedTones = ({tone, as, ...props}: ButtonWithExtendedTonesProps) => {
  const {resolvedScheme} = useManageScheme()
  const isCustomTone = Object.keys(customTones).includes(tone as string)
  if (isCustomTone) {
    return <ThemedButton {...props} as={as} tone={tone} scheme={resolvedScheme} />
  }
  return <Button {...props} as={as} />
}
