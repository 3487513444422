import {Heading, Inline, Badge, Button, Flex, Box, Stack, Card, Grid, Label, Text} from '@sanity/ui'
import React, {useCallback, useState} from 'react'
import {ChevronDownIcon, ChevronUpIcon} from '@sanity/icons'
import {TableRow, TableCell, TableRowMenuButton, MobileRowMenu} from '..'
import {Dataset, TableRowAction} from '@/types/index'
import {useMatchesMedia} from '@/utils/index'

type DatasetMetaData = {
  label: string
  value: string | number | null | undefined
}

type Props = {
  dataset: Dataset
  children?: React.ReactNode
  selected?: boolean
  bulkSelect?: React.ReactNode
  rowActions: TableRowAction[] | undefined
}

const DATASET_TONE: any = {
  public: 'positive',
  private: 'caution',
  frozen: 'default',
}

const MetadataItem = ({data}: {data: DatasetMetaData}) => (
  <Box padding={2}>
    <Stack space={3}>
      <Label muted size={0}>
        {data.label}
      </Label>
      <Text size={2}>{data.value}</Text>
    </Stack>
  </Box>
)

export function DatasetItemSmall({
  selected,
  bulkSelect,
  children,
  dataset,
  rowActions = [],
}: Props) {
  const [showDetails, setShowDetails] = useState<boolean>(false)
  const isMobile = useMatchesMedia(1)
  const handleToggleDetails = useCallback(() => {
    setShowDetails((val) => !val)
  }, [])

  const metadata: DatasetMetaData[] = [
    {value: dataset.stats?.documents.jsonSizeSum.value, label: 'Size'}, // TODO: format size?
    {value: '10m', label: 'Last active'}, // TODO: get last active timestamp and format date
    {value: /* dataset.createdAt */ 123, label: 'Created at'}, // TODO: format date
  ]

  return (
    <TableRow selected={selected} paddingY={3} paddingX={2} borderBottom>
      {bulkSelect && bulkSelect}
      <TableCell>
        <Heading size={1}>{dataset.name}</Heading>
      </TableCell>
      <TableCell>
        <Inline>
          <Badge
            padding={1}
            radius={2}
            size={1}
            mode="outline"
            tone={dataset.aclMode && DATASET_TONE[dataset.aclMode]}
          >
            {dataset.aclMode}
          </Badge>
        </Inline>
      </TableCell>
      {metadata?.map((data, i) => (
        <TableCell key={data.label} $align={i === metadata.length - 1 ? 'right' : 'left'}>
          <Text size={1}>{data.value}</Text>
        </TableCell>
      ))}
      <TableCell $align="right">
        {isMobile ? (
          <Button
            label="Show more"
            icon={showDetails ? ChevronUpIcon : ChevronDownIcon}
            onClick={handleToggleDetails}
            mode="bleed"
          />
        ) : (
          <TableRowMenuButton
            menuId={`${dataset.name}-menu`}
            label={`Edit ${dataset.name}`}
            rowItem={dataset}
            actions={rowActions}
            roleScope="project"
          />
        )}
      </TableCell>
      {isMobile && showDetails && (
        <TableCell
          style={{gridColumn: '1/-1', width: '100%'}}
          flexProps={{direction: 'column', align: 'flex-start'}}
        >
          <Card flex={1} style={{width: '100%'}}>
            <Stack space={4}>
              <Grid columns={2} gap={3}>
                {metadata.map((data) => (
                  <MetadataItem data={data} key={data.label} />
                ))}
              </Grid>
              <Flex style={{width: '100%'}}>
                <Box flex={1}>
                  <MobileRowMenu actions={rowActions} roleScope="project" rowItem={dataset} />
                </Box>
              </Flex>
            </Stack>
          </Card>
        </TableCell>
      )}
      {children}
    </TableRow>
  )
}
