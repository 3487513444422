import React, {useCallback, useEffect} from 'react'
import {Box, Flex, Stack, Text, useToast} from '@sanity/ui'
import {ArrowRightIcon} from '@sanity/icons'
import {DialogToast, Project} from '@/types/index'
import {useSwitchProjectPlan} from '@/data/index'
import {PlanBadge} from '@/ui/index'
import {ProfileImage} from '../../project'
import {sendAmplitudeTrackingEvent} from '@/utils/tracking'
import {useCurrentScopeContext} from '@/context/index'

type Props = {
  Dialog: React.ElementType
  project: Project
  switchPlanInfo: {
    from: any
    to: any
  }
  hideDialog: (a: DialogToast) => void
}

export function SwitchPlanDialog({project, Dialog, hideDialog, switchPlanInfo}: Props) {
  const {from, to} = switchPlanInfo
  const {id: wantedPlanId} = to
  const {id: projectId, displayName} = project
  const [switchState, switchProjectPlan] = useSwitchProjectPlan()
  const {error, loading, isSwitched} = switchState
  const {push} = useToast()
  const {org} = useCurrentScopeContext()

  const handleSwitchPlan = useCallback(() => {
    sendAmplitudeTrackingEvent('Switch Plan Step Completed', project.id, org?.id, {
      plan: to.planTypeId,
      stepName: 'Confirm upgrade',
    })
    sendAmplitudeTrackingEvent('Change Plan Flow Completed', project.id, org?.id, {
      targetPlan: to.id,
      newFlow: false,
    })
    switchProjectPlan(projectId, wantedPlanId, [])
  }, [projectId, switchProjectPlan, wantedPlanId, org])

  useEffect(() => {
    if (error) {
      push({
        title: 'Something went wrong',
        description: JSON.parse(error.response.body).message,
        status: 'error',
      })
    }

    if (isSwitched && !loading && !error) {
      hideDialog({
        title: 'Success',
        description: 'Successfully switched plan',
        status: 'success',
      })

      //Reload the site in order to ensure updated plan data when changing plan
      window.location.reload()
    }
  }, [error, push, isSwitched, loading, hideDialog])

  const fromName = from?.name
  const toName = to?.name

  return (
    <Dialog
      header="Switch plan"
      onConfirm={handleSwitchPlan}
      confirmButtonProps={{text: 'Switch plan', tone: 'primary'}}
      loading={loading}
    >
      <Stack space={4}>
        <Flex align="center">
          <Box marginRight={3}>
            <ProfileImage size={2} />
          </Box>

          <Stack space={3}>
            <Text weight="bold">{displayName}</Text>
            <Flex align="center">
              <PlanBadge title={fromName} />
              <Box paddingX={2}>
                <Text size={1} muted>
                  <ArrowRightIcon />
                </Text>
              </Box>
              <PlanBadge title={toName} />
            </Flex>
          </Stack>
        </Flex>
        <Text muted size={1}>
          You are about to switch your current plan from {fromName} to {toName}. Would you like to
          proceed?
        </Text>
      </Stack>
    </Dialog>
  )
}
