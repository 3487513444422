import {Box, Card, Flex, type FlexProps, Heading, Stack, Text} from '@sanity/ui'
import {type ForwardedRef, forwardRef, type ReactNode} from 'react'
import {styled} from 'styled-components'

const FLEX_DIRECTION: FlexProps['direction'] = ['column', 'column', 'row']

const FLEX_GAP: FlexProps['gap'] = [4]

const CONTENT_FLEX: FlexProps['flex'] = [1, 1, 0.75]

const ContentCard = styled(Card)`
  min-height: max-content;
`

export interface ContentHeaderProps {
  title: ReactNode
  description?: ReactNode
  content?: ReactNode
  referenceLinkComponent?: JSX.ElementType
}

export const ContentHeader = forwardRef(function ContentHeader(
  props: ContentHeaderProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const {content, description, title, referenceLinkComponent: ReferenceLinkComponent} = props

  return (
    <Flex direction={FLEX_DIRECTION} gap={FLEX_GAP} sizing="border" ref={ref}>
      <ContentCard flex={1}>
        <Stack space={4}>
          <Box marginTop={2}>
            <Heading size={2} as="h1">
              {title}
            </Heading>
          </Box>

          <Text muted>{description}</Text>
          {ReferenceLinkComponent && (
            <Flex style={{marginLeft: '-0.75rem'}}>
              <ReferenceLinkComponent />
            </Flex>
          )}
        </Stack>
      </ContentCard>

      <ContentCard flex={CONTENT_FLEX}>{content && content}</ContentCard>
    </Flex>
  )
})
