import {ToastParams} from '@sanity/ui'
import {IllustrationDrawing} from '@/components/illustrations'

export type AddOnCopy = {
  featureId: string
  name: string
  short: string
  description: (string | string[])[]
  price: number
  priceTerms: string
  maxAllowed: number
  docsUrl: string
  downgradeWarning: string
  drawing: IllustrationDrawing
  enabledToast?: Pick<ToastParams, 'title' | 'description' | 'status'>
}

export function getAddOnsCopy(): AddOnCopy[] {
  return [
    {
      featureId: 'accesscontrol-auth-saml-role-mapping-2022-12-08',
      name: 'SAML / single sign-on',
      short:
        'Simplify project access using a third-party identity provider with rule-based role mapping.',
      description: [
        'SAML SSO enables you to control access to your Sanity project through a third-party identity provider, such as Okta, Google, or Azure Active Directory.',
        'When a user logs in, they will be assigned a default role. You can configure which role is the default. Additional role mapping rules are available on the Enterprise plan.',
      ],
      price: 1399,
      maxAllowed: 1,
      priceTerms: 'per month',
      docsUrl: '/docs/growth-plan-add-ons#ec44fec5f1cd',
      downgradeWarning:
        'Removing this add-on will cause users managed by your SSO identity provider to lose access to this project.',
      drawing: 'sso',
    },
    {
      featureId: 'dedicated-support-2023-10-19',
      name: 'Dedicated Support',
      short: "Get direct technical support from Sanity's Support Engineers.",
      description: ["Get direct technical support from Sanity's Support Engineers by email."],
      price: 799,
      maxAllowed: 1,
      priceTerms: 'per month',
      docsUrl: '/docs/growth-plan-add-ons#993b8afe77de',
      downgradeWarning:
        'Removing this add-on will limit technical support to our Slack community only.',
      drawing: 'support',
      enabledToast: {
        title: 'Add-on enabled: Dedicated support',
        description: 'One of our representatives will be in contact with you soon',
        status: 'success',
      },
    },
    {
      featureId: 'additional-quota-2023-10-19',
      name: 'Increased quota',
      short:
        'Extend the included quota of the Growth plan for API CDN requests, API requests, bandwidth, documents and assets.',
      description: [
        'Extend the included quota of the Growth plan to:',
        [
          'API CDN requests: 5M (up from 1M)',
          'API requests: 1M (up from 250k)',
          'Bandwidth: 500GB (up from 100GB)',
          'Assets: 500GB (up from 100GB)',
          'Documents: 50k (up from 25k)',
        ],
        'Cost of additional usage remains unchanged, as listed on our Pricing page.',
      ],
      price: 299,
      maxAllowed: 1,
      priceTerms: 'per month',
      docsUrl: '/docs/growth-plan-add-ons#86ba43d4cb49',
      downgradeWarning:
        'Removing this add-on can generate overage costs for additional consumption above the default included quota.',
      drawing: 'quota',
    },
    {
      featureId: 'additional-datasets-2023-10-19',
      name: 'Extra datasets',
      short: 'Unlock up to two additional datasets for your project.',
      description: [
        'Unlock up to 2 additional datasets for your project, increasing the maximum number of datasets from 2 to 4.',
        'We only charge you when you create the additional dataset(s) – not when you enable this add-on.',
      ],
      price: 999,
      maxAllowed: 2,
      priceTerms: 'per dataset / month',
      docsUrl: '/docs/growth-plan-add-ons#f239f0ac9f79',
      downgradeWarning: 'Removing this add-on will limit your project to a maximum of 2 datasets.',
      drawing: 'dataset',
    },
  ]
}
