import React, {useCallback, useState} from 'react'
import {Box, Code, Card, Inline, Stack, Text, Heading, Flex, Button} from '@sanity/ui'
import {ToggleArrowRightIcon} from '@sanity/icons'
import {OrganizationSSOProviderSAML} from '@/types/models/organization'

type SsoSAMLStudioSetupDetailsProps = {
  samlProvider: OrganizationSSOProviderSAML
  expanded?: boolean
}

export default function SsoSAMLStudioSetupDetails({
  samlProvider,
  expanded = false,
}: SsoSAMLStudioSetupDetailsProps) {
  const [studioVersion, setStudioVersion] = useState<'v2' | 'v3'>('v3')
  const selectAll = useCallback((ev) => {
    if (window.getSelection) {
      const selection = window.getSelection()
      const range = document.createRange()
      range.selectNodeContents(ev.target)
      selection?.removeAllRanges()
      selection?.addRange(range)
    }
  }, [])
  const [expand, setExpand] = useState<boolean>(expanded)
  const expandCallback = useCallback(() => {
    setExpand(!expand)
  }, [expand])

  const handleSetStudioVersionV3 = useCallback(() => {
    setStudioVersion('v3')
  }, [setStudioVersion])
  const handleSetStudioVersionV2 = useCallback(() => {
    setStudioVersion('v2')
  }, [setStudioVersion])

  return (
    <Card borderTop>
      <Card
        as="button"
        padding={4}
        onClick={expandCallback}
        tone={expand ? 'transparent' : 'default'}
      >
        <Flex align="center" justify="space-between">
          <Stack space={3}>
            <Inline space={2}>
              <Text>
                <ToggleArrowRightIcon style={expand ? {transform: 'rotate(90deg)'} : {}} />
              </Text>
              <Heading size={1} as="h3">
                SAML SSO login for Sanity Studio
              </Heading>
            </Inline>
            <Text size={1} muted>
              Details on how to set up SAML SSO in your studio.{' '}
              <a
                href={`https://www.${process.env.host}/docs/third-party-login#78c9b12d4a21`}
                target="_blank"
                rel="noreferrer"
              >
                Learn more →
              </a>
            </Text>
          </Stack>
        </Flex>
      </Card>
      {expand && (
        <Stack space={3} padding={4}>
          <Inline>
            <Box>
              <Button
                text="Studio v3"
                mode={studioVersion === 'v3' ? 'default' : 'bleed'}
                tone={studioVersion === 'v3' ? 'primary' : 'default'}
                onClick={handleSetStudioVersionV3}
              />
            </Box>
            <Box marginLeft={2}>
              <Button
                text="Studio v2"
                mode={studioVersion === 'v2' ? 'default' : 'bleed'}
                tone={studioVersion === 'v2' ? 'primary' : 'default'}
                onClick={handleSetStudioVersionV2}
              />
            </Box>
          </Inline>
          {studioVersion === 'v2' && (
            <Stack space={3}>
              <Card paddingY={2}>
                <Text>
                  Copy & paste the below configuration into the file{' '}
                  <strong onClick={selectAll}>config/@sanity/default-login.json</strong> to setup
                  SAML sign on in your studio.
                </Text>
              </Card>

              <Card padding={3} tone="transparent" radius={3} border>
                <Code
                  size={1}
                  language="js"
                  onClick={selectAll}
                  style={{whiteSpace: 'break-spaces'}}
                >
                  {JSON.stringify(
                    {
                      providers: {
                        mode: 'replace',
                        redirectOnSingle: false,
                        entries: [
                          {
                            name: 'saml',
                            title: samlProvider.name,
                            url: samlProvider.loginUrl,
                          },
                        ],
                      },
                    },
                    null,
                    2
                  )}
                </Code>
              </Card>
            </Stack>
          )}

          {studioVersion === 'v3' && (
            <Stack space={3}>
              <Card paddingY={2}>
                <Text>
                  To enable SAML SSO login in Sanity Studio you need to update the configuration in{' '}
                  <strong>sanity.config.{`{ts, js}`}</strong>. Add the <code>auth</code> value below
                  to the configuration object.
                </Text>
              </Card>

              <Card padding={3} tone="transparent" radius={3} border>
                <Code
                  size={1}
                  language="javascript"
                  onClick={selectAll}
                  style={{whiteSpace: 'break-spaces'}}
                >
                  {`import {defineConfig, createAuthStore} from 'sanity'

export default defineConfig({
  // ... The rest of your configuration
  auth: createAuthStore({
    projectId: PROJECT_ID, // replace with your project id
    dataset: DATASET_NAME, // replace with your dataset name
    redirectOnSingle: false,
    mode: 'append', // Use ‘replace’ if you only want this login provider
    providers: [
      {
        name: 'saml',
        title: '${samlProvider.name}',
        url: '${samlProvider.loginUrl}',
      },
    ],
    loginMethod: 'dual',
  }),
})`}
                </Code>
              </Card>
            </Stack>
          )}
        </Stack>
      )}
    </Card>
  )
}
