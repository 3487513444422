import {useQuery} from '@tanstack/react-query'
import {getPlanById} from './controller'
import {getPlanByIdKey} from './cache'

export function usePlanById(id: string) {
  return useQuery({
    queryKey: getPlanByIdKey(id),
    queryFn: () => getPlanById(id),
  })
}
