import {useQuery, useMutation, useQueryClient} from '@tanstack/react-query'
import {Project, Token} from '../../types/models'
import {addToken, deleteToken, getTokens} from '.'
import {getTokensCacheKey} from './cache'

type TokenWithRoleName = Token & {roleName: string}

export function useTokens(project: Project) {
  const queryClient = useQueryClient()

  const {
    data: tokens = [],
    error,
    isLoading,
  } = useQuery({
    queryKey: getTokensCacheKey(project.id),
    queryFn: () => getTokens(project.id),
    select: (data: Token[]) =>
      data.map((token) => ({
        ...token,
        roleName: project.members.find((mem) => mem.id === token.projectUserId)?.role,
      })) as TokenWithRoleName[],
  })

  const addTokenMutation = useMutation({
    mutationKey: ['addToken', project.id],
    mutationFn: ({label, role}: {label: string; role: string}) =>
      addToken(project.id, {label, roleName: role}),
    onMutate: (newToken) => {
      queryClient.setQueryData(
        getTokensCacheKey(project.id),
        (old: TokenWithRoleName[] | undefined) => [
          ...(old || []),
          {
            ...newToken,
            createdAt: new Date().toISOString(),
            roles: [{name: newToken.role}],
            roleName: newToken.role,
          },
        ]
      )
    },
    onSettled: () => {
      queryClient.invalidateQueries({queryKey: getTokensCacheKey(project.id)})
    },
  })

  const deleteTokenMutation = useMutation({
    mutationKey: ['deleteToken', project.id],
    mutationFn: (tokenId: string) => deleteToken(project.id, tokenId),
    onMutate: (deletedTokenId: string) => {
      queryClient.setQueryData(
        getTokensCacheKey(project.id),
        (old: TokenWithRoleName[] | undefined) =>
          old?.filter((token) => token.id !== deletedTokenId) || []
      )
    },
    onSettled: () => {
      queryClient.invalidateQueries({queryKey: getTokensCacheKey(project.id)})
    },
  })

  return {
    tokens,
    error,
    isLoading,
    addToken: addTokenMutation.mutate,
    deleteToken: deleteTokenMutation.mutate,
    isAdding: addTokenMutation.isPending,
    isDeleting: deleteTokenMutation.isPending,
    addError: addTokenMutation.error,
    deleteError: deleteTokenMutation.error,
    isDeleted: deleteTokenMutation.isSuccess,
  }
}
