import {hues, white, black} from '@sanity/color'
import {PlanTypeNames} from '@/types/index'
import {useManageScheme} from '@/ui/app/manageSchemeProvider'

type VariantType = {color: string; background: string; boxShadow: string}

const LIGHT_COLORS: Record<string, VariantType> = {
  enterprise: {
    color: white.hex,
    background: hues.blue[500].hex,
    boxShadow: hues.blue[500].hex,
  },
  advanced: {
    color: white.hex,
    background: hues.gray[700].hex,
    boxShadow: hues.gray[700].hex,
  },
  developer: {
    color: hues.gray[950].hex,
    background: hues.gray[50].hex,
    boxShadow: hues.gray[50].hex,
  },
  playground: {
    color: hues.yellow[700].hex,
    background: hues.yellow[100].hex,
    boxShadow: hues.gray[100].hex,
  },
  'org-plan': {
    color: hues.blue[700].hex,
    background: hues.blue[100].hex,
    boxShadow: hues.gray[100].hex,
  },
  trial: {
    color: hues.purple[700].hex,
    background: hues.purple[100].hex,
    boxShadow: hues.gray[100].hex,
  },
  fallback: {
    color: hues.gray[950].hex,
    background: hues.gray[100].hex,
    boxShadow: hues.gray[100].hex,
  },
}

const DARK_COLORS: Record<string, VariantType> = {
  enterprise: {
    color: black.hex,
    background: hues.blue[500].hex,
    boxShadow: hues.blue[500].hex,
  },
  advanced: {
    color: black.hex,
    background: hues.gray[300].hex,
    boxShadow: hues.gray[300].hex,
  },
  developer: {
    color: hues.gray[50].hex,
    background: hues.gray[950].hex,
    boxShadow: hues.gray[950].hex,
  },
  playground: {
    color: hues.yellow[300].hex,
    background: hues.yellow[900].hex,
    boxShadow: hues.gray[900].hex,
  },
  'org-plan': {
    color: hues.blue[300].hex,
    background: hues.blue[900].hex,
    boxShadow: hues.gray[900].hex,
  },
  trial: {
    color: hues.purple[300].hex,
    background: hues.purple[900].hex,
    boxShadow: hues.gray[900].hex,
  },
  fallback: {
    color: hues.gray[50].hex,
    background: hues.gray[900].hex,
    boxShadow: hues.gray[900].hex,
  },
}

export function usePlanBadgeVariant(planName: PlanTypeNames | string): VariantType {
  const {resolvedScheme} = useManageScheme()
  const colors = resolvedScheme === 'light' ? LIGHT_COLORS : DARK_COLORS
  const validLabels = Object.keys(colors)
  const name = planName.toLowerCase()
  const variant = validLabels.includes(name) ? colors[name] : colors.fallback
  const maybeTrial = name.includes('trial')

  return maybeTrial ? colors.trial : variant
}
