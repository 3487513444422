import {useMemo} from 'react'
import {Organization, OrganizationData, PatchOrganizationData} from '../../types/models'
import {getOrganizationData, patchOrganization} from './'
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query'
import {getOrganizationBaseKey, STALE_TTL, getOrganizationsKey} from './cache'
import {useRequiredOrganizationPermissions} from '../../context'

export function useOrganizationData(organization: Organization) {
  const queryClient = useQueryClient()
  const organizationKey = useMemo(() => getOrganizationBaseKey(organization.id), [organization.id])
  const canReadOrganization = useRequiredOrganizationPermissions(
    [{permissionName: 'sanity.organization', grantName: 'read'}],
    organization.id
  )

  const query = useQuery({
    queryKey: organizationKey,
    queryFn: () => getOrganizationData(organization.id),
    enabled: !!organization.id && canReadOrganization,
    staleTime: STALE_TTL,
  })

  const patchMutation = useMutation({
    mutationKey: ['patchOrganizationData', organization.id],
    mutationFn: (data: PatchOrganizationData) => patchOrganization(organization.id, data),
    onMutate: async (newData: PatchOrganizationData) => {
      await queryClient.cancelQueries({queryKey: organizationKey})
      const previousData = queryClient.getQueryData<OrganizationData>(organizationKey)

      // Update organization data
      queryClient.setQueryData(organizationKey, (old: OrganizationData) => ({
        ...old,
        ...newData,
      }))

      // Update organization in organizations list
      queryClient.setQueryData(getOrganizationsKey(), (old: Organization[] | undefined) => {
        if (!old) return old
        return old.map((org) => (org.id === organization.id ? {...org, ...newData} : org))
      })

      return {previousData}
    },
    onError: (_err, _newData, context) => {
      // Revert organization data
      queryClient.setQueryData(organizationKey, context?.previousData)

      // Revert organization in organizations list
      queryClient.setQueryData(getOrganizationsKey(), (old: Organization[] | undefined) => {
        if (!old) return old
        return old.map((org) =>
          org.id === organization.id ? {...org, ...context?.previousData} : org
        )
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries({queryKey: organizationKey})
    },
  })

  return {
    ...query,
    patchOrganizationData: patchMutation.mutate,
    isPending: patchMutation.isPending,
    patchSuccess: patchMutation.isSuccess,
    patchError: patchMutation.error,
  }
}
