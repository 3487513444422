import {Box, Button, Flex, Grid, Label, Stack, Text} from '@sanity/ui'
import React, {useState, useCallback} from 'react'
import {ChevronDownIcon, ChevronUpIcon} from '@sanity/icons'
import {AvatarWithFallback, MemberAvatar} from '../../members'
import {MobileRowMenu, TableCell, TableRow, TableRowMenuButton} from '@/ui/index'
import {useMatchesMedia, useTimeAgo} from '@/utils/index'
import {OrganizationInvitation, TableRowAction, Organization} from '@/types/index'
import {useOrganizationRolesList} from '@/data/organizations/useOrganizationRoleList'

type Props = {
  org: Organization
  invitation: OrganizationInvitation
  rowActions: TableRowAction[]
}

export const OrgInvitation = ({org, invitation, rowActions}: Props) => {
  const [showDetails, setShowDetails] = useState<boolean>(false)
  const isMobile = useMatchesMedia(1)

  const handleToggleDetails = useCallback(() => {
    if (!isMobile) {
      return
    }
    setShowDetails((val) => !val)
  }, [isMobile])
  const sent = useTimeAgo(invitation.createdAt, {minimal: true})
  const orgMember = org.members.find(
    (member) =>
      invitation.invitedByUserId === member.sanityUserId ||
      invitation.invitedByUserId === member?.sanityUserId
  )
  const {data: roles = []} = useOrganizationRolesList(org.id)
  const roleTitle =
    roles?.find((rol) => rol.name === invitation.roles?.[0]?.name)?.title || 'Unknown'
  return (
    <TableRow paddingY={3} paddingX={2} borderBottom onClick={handleToggleDetails}>
      <TableCell>
        <Text size={1}>{invitation.email}</Text>
      </TableCell>
      <TableCell>
        <Text size={1}>{roleTitle}</Text>
      </TableCell>
      <TableCell>
        <Box paddingRight={2}>
          {orgMember ? (
            <MemberAvatar userId={orgMember.sanityUserId} />
          ) : (
            <AvatarWithFallback fallback />
          )}
        </Box>
        <Text size={1}>{sent}</Text>
      </TableCell>
      <TableCell $align="right">
        {isMobile ? (
          <Button
            label="Show more"
            icon={showDetails ? ChevronUpIcon : ChevronDownIcon}
            onClick={handleToggleDetails}
            mode="bleed"
          />
        ) : (
          <TableRowMenuButton
            menuId={invitation.id}
            label={`Edit ${invitation.id}`}
            rowItem={invitation}
            actions={rowActions}
            roleScope="org"
          />
        )}
      </TableCell>
      {isMobile && showDetails && (
        <>
          <TableCell
            style={{gridColumn: '2/-1', width: '100%'}}
            flexProps={{direction: 'column', align: 'flex-start'}}
          >
            <Grid columns={2} gap={5}>
              <Stack space={3}>
                <Label muted size={0}>
                  Role
                </Label>
                <Text size={1}>{roleTitle}</Text>
              </Stack>
              <Stack space={3}>
                <Label muted size={0}>
                  Sent
                </Label>
                <Text size={1}>{sent}</Text>
              </Stack>
            </Grid>
          </TableCell>
          <TableCell
            style={{gridColumn: '1/-1', width: '100%'}}
            flexProps={{direction: 'column', align: 'flex-start'}}
          >
            <Flex style={{width: '100%'}}>
              <Box flex={1}>
                <MobileRowMenu actions={rowActions} roleScope="org" rowItem={invitation} />
              </Box>
            </Flex>
          </TableCell>
        </>
      )}
    </TableRow>
  )
}
