import {useDeleteUserFromProject, useUser} from '@/data/index'
import {DialogToast, Project, ProjectMemberDecorated} from '@/types/index'
import {List, MemberPreview} from '@/ui/index'
import {useToast} from '@sanity/ui'
import React, {useCallback} from 'react'

type Props = {
  project: Project
  members: ProjectMemberDecorated[]
  Dialog: React.ElementType
  hideDialog: (dialogToast: DialogToast) => void
}

export const RemoveProjectMembersDialog = ({project, members, hideDialog, Dialog}: Props) => {
  const toast = useToast()
  const {deleteUser, isDeleting} = useDeleteUserFromProject()

  const handleRemoveMembers = useCallback(() => {
    const [firstMember] = members
    deleteUser(
      {projectId: project.id, userId: firstMember.id},
      {
        onSuccess: () => {
          hideDialog({
            description: 'Removed member from project',
            status: 'success',
          })
        },
        onError: (error) => {
          toast.push({
            title: 'Failed to remove member from project',
            description: error.message,
            status: 'error',
          })
        },
      }
    )
  }, [deleteUser, members, project.id, hideDialog, toast])

  const {data: user} = useUser(members[0]?.id)

  if (!user) {
    return null
  }

  return (
    <Dialog
      id="remove-project-member"
      header={members.length === 1 ? `Remove member from project` : `Remove members from project`}
      title={
        members.length === 1
          ? `Do you really want to remove ${user.displayName} from ${project.displayName}?`
          : `Do you really want to remove ${members.length} members from ${project.displayName}?`
      }
      description={
        members.length === 1
          ? `${user.displayName} will lose access to the project and all the related rights and permissions.`
          : `The following members will lose access to the project and all the related rights and permissions.`
      }
      onConfirm={handleRemoveMembers}
      loading={isDeleting}
    >
      <List>
        {members.map((member) => (
          <li key={member.id}>
            <MemberPreview title={user.displayName} userId={user.id} subtitle={member.role} />
          </li>
        ))}
      </List>
    </Dialog>
  )
}
