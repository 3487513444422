import {Observable, Subject, merge, of} from 'rxjs'
import {map, share, shareReplay, switchMap} from 'rxjs/operators'
import {OrganizationPlan, Plan, PlanPreview, Subscription} from '../../types'
import {apiRequest} from '../util/request'
import {promiseRequest} from '../util/promiseRequest'

export const changePlanAction = new Subject()

export function getDefaultPlans() {
  return promiseRequest<Plan[]>(`https://api.${process.env.host}/v1/plans`)
}

export function fetchPlanPreview(
  planId: string,
  options?: {
    projectId?: string
    features?: string[]
    overagePriceFor?: string[]
  }
) {
  const url = new URL(`https://api.${process.env.host}/v1/plans/${planId}/preview`)
  if (options?.projectId) url.searchParams.set('projectId', options.projectId)
  if (options?.features) url.searchParams.set('features', options.features.join(','))
  if (options?.overagePriceFor)
    url.searchParams.set('overagePriceFor', options.overagePriceFor.join(','))
  return promiseRequest<PlanPreview>(url.toString())
}

export function fetchCouponPlans(couponCode: string): Observable<Plan[]> {
  return apiRequest<Plan[]>({
    url: `https://api.${process.env.host}/v2024-01-30/plans/coupon/${couponCode}`,
  })
}

export function fetchProjectPlan(projectId: string) {
  return promiseRequest<Subscription>(
    `https://api.${process.env.host}/v1/subscriptions/project/${projectId}`
  )
}

export function fetchOrganizationPlans(): Observable<OrganizationPlan[]> {
  return apiRequest<OrganizationPlan[]>({
    url: `https://api.${process.env.host}/v1/_unstable_organizationPlans`,
  })
}

export function getOrganizationPlans() {
  return promiseRequest<OrganizationPlan[]>(
    `https://api.${process.env.host}/v1/_unstable_organizationPlans`
  )
}

export function getProjectPlan(projectId?: string): Observable<Subscription | undefined> {
  if (!projectId) {
    return of()
  }
  return merge(
    fetchProjectPlan(projectId),
    changePlanAction.pipe(switchMap(() => fetchProjectPlan(projectId)))
  ).pipe(shareReplay({bufferSize: 1, refCount: true}))
}

export function getPlanById(id: string) {
  return promiseRequest<Plan>(`https://api.${process.env.host}/v1/plans/${id}`)
}

export function switchProjectPlan(
  projectId: string,
  wantedPlanId: string,
  features: string[]
): Observable<Subscription> {
  return apiRequest<Subscription>({
    method: 'PUT',
    url: `https://api.${process.env.host}/v1/subscriptions/project/${projectId}/plan`,
    body: {
      planId: wantedPlanId,
      features: features,
    },
  }).pipe(
    map((res) => {
      changePlanAction.next(res)
      return res
    }),
    share()
  )
}
