/* eslint-disable complexity */
import React from 'react'

export const Dataset = ({color}: {color?: string}) => (
  <svg
    width="242"
    height="120"
    viewBox="0 0 242 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M111 17.5V70C111 77.797 98.752 85 81 85C63.248 85 51 77.797 51 70V17.5M111 17.5C111 24.7772 97.5685 30 81 30C64.4315 30 51 24.7772 51 17.5C51 10.2228 64.4315 5 81 5C97.5685 5 111 10.2228 111 17.5Z"
      stroke={color || '#2276FC'}
      strokeOpacity="0.5"
      strokeLinejoin="round"
    />
    <path
      d="M191 17.5V70C191 77.797 178.752 85 161 85C143.248 85 131 77.797 131 70V17.5M191 17.5C191 24.7772 177.569 30 161 30C144.431 30 131 24.7772 131 17.5C131 10.2228 144.431 5 161 5C177.569 5 191 10.2228 191 17.5Z"
      stroke={color || '#2276FC'}
      strokeOpacity="0.5"
      strokeLinejoin="round"
    />
    <path
      d="M121 35C104.431 35 91 40.2228 91 47.5V100C91 107.797 103.248 115 121 115C138.752 115 151 107.797 151 100V47.5C151 40.2228 137.569 35 121 35Z"
      fill={color || '#2276FC'}
      fillOpacity="0.25"
    />
    <path
      d="M151 47.5V100C151 107.797 138.752 115 121 115C103.248 115 91 107.797 91 100V47.5M151 47.5C151 54.7772 137.569 60 121 60C104.431 60 91 54.7772 91 47.5C91 40.2228 104.431 35 121 35C137.569 35 151 40.2228 151 47.5Z"
      stroke={color || '#2276FC'}
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
  </svg>
)
Dataset.displayName = 'Dataset'
