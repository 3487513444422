import {useCallback, useEffect, useMemo} from 'react'
import {Inline, Stack, Text} from '@sanity/ui'
import {NextRouter} from 'next/router'
import {Prompt} from '@/ui/index'
import {useWizardStore} from '@/components/payment-wizard'
import {PRIVATE_DATASET_FEATURE} from '@/data/datasets'
import {Dataset, Plan, Project, ProjectMemberRole, Subscription} from '@/types/models'
import {getSubscriptionCustomFeatures} from '@/utils/subscription'
import {filterBillableDatasets} from '@/utils/usage'

function getRestrictedQuotasAndFeatures(plan: Plan | undefined) {
  const datasetQuota = plan?.resources?.datasets?.quota ?? Number.POSITIVE_INFINITY
  const projectMemberQuota = plan?.resources?.users?.quota ?? Number.POSITIVE_INFINITY
  const privateDatasetAllowed =
    plan?.featureTypes?.accesscontrol?.features?.some(({attributes}) => {
      return attributes && attributes[PRIVATE_DATASET_FEATURE] === true
    }) ?? false
  const perSeat = plan?.pricingModel === 'per-seat'

  return {
    datasetQuota,
    projectMemberQuota,
    privateDatasetAllowed,
    perSeat,
  }
}

function getNotAllowedProjectMembers(
  plan: Plan | undefined,
  projectMembers: Project['members'] | undefined
) {
  const rolesToFilterOut = ['administrator', 'deploy-studio']
  const roleMap = projectMembers?.flatMap((member) =>
    member.roles.filter((role) => !rolesToFilterOut.includes(role.name))
  )
  const accessControlFeatures = plan?.featureTypes?.accesscontrol?.features?.map((f) => f.variantId)

  let notAllowedRoles: ProjectMemberRole[] = []
  if (roleMap && accessControlFeatures) {
    notAllowedRoles = roleMap.filter((role) => !accessControlFeatures.includes(`role-${role.name}`))
  }

  return [...new Set(notAllowedRoles.map((role) => role.title))]
}

function calculatePerSeatToFlatFeeBlockingOverages(
  datasets: Dataset[] | undefined,
  projectMembers: Project['members'] | undefined,
  projectRobotMembers: Project['members'] | undefined,
  targetPlan: Plan | undefined,
  subscription: Subscription | undefined
) {
  const addonsActive = subscription ? getSubscriptionCustomFeatures(subscription) : []
  const {datasetQuota, projectMemberQuota, privateDatasetAllowed, perSeat} =
    getRestrictedQuotasAndFeatures(targetPlan)

  const billableDatasets = datasets?.filter((dataset) => {
    return filterBillableDatasets([dataset]).length > 0
  })

  const notAllowedRoles = getNotAllowedProjectMembers(targetPlan, projectMembers)
  const notAllowedRobotRoles = getNotAllowedProjectMembers(targetPlan, projectRobotMembers)

  const offendingOverages = {
    datasets: (() => {
      const usage = billableDatasets?.length ?? 0
      return usage > datasetQuota
    })(),
    datasetPrivacy: (() => {
      if (privateDatasetAllowed) {
        return false
      }
      const usage = billableDatasets?.filter((d) => d.aclMode !== 'public')?.length ?? 0
      return usage > 0
    })(),
    projectMembers: (() => {
      if (perSeat) {
        return false
      }
      const usage = projectMembers?.length ?? 0
      return usage > projectMemberQuota
    })(),
    addons: (() => {
      return addonsActive.length > 0
    })(),
    notAllowedRoles: (() => {
      return notAllowedRoles.length > 0
    })(),
    notAllowedRobotRoles: (() => {
      return notAllowedRobotRoles.length > 0
    })(),
  }

  return Object.entries(offendingOverages)
    .filter(([, isOverage]) => isOverage)
    .map(([key]) => key as keyof typeof offendingOverages)
}

function resourceCopyBuilder(intro: string, boldened: string, cta: string, handler: () => void) {
  return (
    <Inline key={boldened}>
      <Text size={1}>{intro}</Text>&nbsp;
      <Text size={1} weight="semibold">
        {boldened}
      </Text>
      <Text size={1}>.</Text>&nbsp;
      <Text
        style={{textDecoration: 'underline', color: 'inherit'}}
        size={1}
        as="a"
        onClick={handler}
      >
        {cta}
      </Text>
    </Inline>
  )
}

export function OverageDisallowedWarning({
  planName,
  datasets,
  projectMembers,
  projectRobotMembers,
  targetPlan,
  subscription,
  projectId,
  organizationId,
  router,
}: {
  planName?: string
  datasets: Dataset[] | undefined
  projectMembers: Project['members'] | undefined
  projectRobotMembers: Project['members'] | undefined
  targetPlan: Plan | undefined
  subscription: Subscription | undefined
  projectId: string | undefined
  organizationId: string | undefined | null
  router: NextRouter | undefined
}) {
  // only resources we warn on for per-seat plans, ignoring other resources

  const {dispatch} = useWizardStore()

  const onClick = useCallback(
    (tab: 'datasets' | 'members' | 'plan' | 'api') => () => {
      const url =
        organizationId === 'personal'
          ? `/manage/personal/project/${projectId}/${tab}`
          : `/organizations/${organizationId}/project/${projectId}/${tab}`

      router?.push(url)
      dispatch({type: 'changePlanWizard/close'})
    },
    [dispatch, organizationId, projectId, router]
  )

  const {datasetQuota, projectMemberQuota} = getRestrictedQuotasAndFeatures(targetPlan)

  const offendingOverages = useMemo(() => {
    return calculatePerSeatToFlatFeeBlockingOverages(
      datasets,
      projectMembers,
      projectRobotMembers,
      targetPlan,
      subscription
    )
  }, [datasets, projectMembers, subscription, targetPlan])

  const notAllowedRoles = getNotAllowedProjectMembers(targetPlan, projectMembers)
  const notAllowedRobotRoles = getNotAllowedProjectMembers(targetPlan, projectRobotMembers)

  const overageCopy = {
    datasets: () =>
      resourceCopyBuilder(
        `The ${planName ?? ''} plan has a limit of`,
        `${datasetQuota} ${datasetQuota === 1 ? 'dataset' : 'datasets'}`,
        'Go to Datasets',
        onClick('datasets')
      ),
    datasetPrivacy: () =>
      resourceCopyBuilder(
        `The ${planName ?? ''} plan does not include`,
        `private datasets`,
        'Go to Datasets',
        onClick('datasets')
      ),
    projectMembers: () =>
      resourceCopyBuilder(
        `The ${planName ?? ''} plan has a limit of`,
        `${projectMemberQuota} ${projectMemberQuota === 1 ? 'member' : 'members'}`,
        'Go to Members',
        onClick('members')
      ),
    addons: () =>
      resourceCopyBuilder(
        `The ${planName ?? ''} plan does not allow`,
        `add-ons`,
        'Go to Plans',
        onClick('plan')
      ),
    notAllowedRoles: () =>
      resourceCopyBuilder(
        `The ${planName ?? ''} plan does not allow existing member roles:`,
        `${notAllowedRoles.join(', ')}`,
        'Go to Members',
        onClick('members')
      ),
    notAllowedRobotRoles: () =>
      resourceCopyBuilder(
        `The ${planName ?? ''} plan does not allow existing token roles:`,
        `${notAllowedRobotRoles.join(', ')}`,
        'Go to API → Tokens',
        onClick('api')
      ),
  }

  const hasOffendingOverages = offendingOverages.length > 0
  useEffect(() => {
    if (hasOffendingOverages) {
      dispatch({type: 'setNextButtonDisabled', value: true})
    } else {
      dispatch({type: 'setNextButtonDisabled', value: false})
    }
  }, [dispatch, hasOffendingOverages])

  if (!hasOffendingOverages) {
    return null
  }
  return (
    <Prompt
      cardProps={{marginTop: 4}}
      title="You need to reduce your project’s usage before switching to this plan"
      icon="error-outline"
      layout="column"
      description={
        <Stack space={3} marginTop={3}>
          {offendingOverages.map((key) => overageCopy[key]?.())}
        </Stack>
      }
      tone="critical"
      as="div"
    />
  )
}
