import {Code, Label, Card, Text, Flex, Box} from '@sanity/ui'
import {pickBy, forIn, find, isNil, capitalize, isEmpty} from 'lodash'
import styled from 'styled-components'
import React from 'react'
import {Plan, ResourcesData} from '@/types/index'
import {Prompt} from '@/ui/index'

const GIGABYTE = 1000000000 // We store data units as multiples of 1000, not 1024.

interface Overage {
  [key: string]: any
  currentUsage?: number
  resourceUnit?: string
  newQuota?: number
}

type OverageSummary = Record<string, Overage>

const roundToTwoDecimals = (value: number) => Math.round(value * 100) / 100

function FormatResourceValue(resourceValue: number, resourceUnit: string) {
  const roundedValue = roundToTwoDecimals(resourceValue)
  const roundedGigabyteValue = roundToTwoDecimals(resourceValue / GIGABYTE)
  return resourceUnit === 'byte' ? `${roundedGigabyteValue} GB` : roundedValue
}

const MutedCode = styled(Code)`
  code {
    color: caution;
    background-color: transparent;
  }
`

const StyledLabel = styled(Label)`
  color: inherit;
`

function OverageDetails({overages}: OverageSummary) {
  return (
    <>
      <Card borderBottom tone="inherit">
        <Flex marginBottom={2} paddingTop={4} gap={1}>
          <Box flex={1}>
            <StyledLabel size={0}>Resource</StyledLabel>
          </Box>
          <Box flex={0.5}>
            <StyledLabel size={0}>Current plan</StyledLabel>
          </Box>
          <Box flex={0.5}>
            <StyledLabel size={0}>New plan</StyledLabel>
          </Box>
          <Box flex={0.5}>
            <StyledLabel size={0}>Overage</StyledLabel>
          </Box>
        </Flex>
      </Card>
      {Object.keys(overages).map((resourceName) => {
        const {currentUsage, resourceUnit, newQuota} = overages[resourceName]

        const formattedUsage = FormatResourceValue(currentUsage, resourceUnit)
        const formattedQuota = FormatResourceValue(newQuota, resourceUnit)
        const formattedOverage = FormatResourceValue(currentUsage - newQuota, resourceUnit)

        return (
          <Flex key={resourceName} paddingTop={4} gap={1}>
            <Box flex={1}>
              <Text size={1} style={{color: 'inherit'}} weight="semibold" muted>
                {resourceName}
              </Text>
            </Box>
            <Box flex={0.5}>
              <MutedCode size={1}>{formattedUsage}</MutedCode>
            </Box>
            <Box flex={0.5}>
              <MutedCode size={1}>{formattedQuota}</MutedCode>
            </Box>
            <Box flex={0.5}>
              <MutedCode size={1}>{formattedOverage}</MutedCode>
            </Box>
          </Flex>
        )
      })}
    </>
  )
}

const userResources = ['users', 'non_admin_users', 'non_viewer_users']

function calculateOverages(
  currentPlan: Plan | undefined,
  currentUsage: ResourcesData | undefined,
  targetPlan: Plan | undefined
) {
  if (currentPlan === undefined || targetPlan === undefined) return {}

  const currentResources = currentPlan.resources
  const targetResources = targetPlan.resources
  const perSeat = targetPlan?.pricingModel === 'per-seat'

  const quantifiableTargetResources = pickBy(targetResources, (resource) => {
    return resource.unit === 'number' || resource.unit === 'byte'
  })

  const overages = {} as OverageSummary
  forIn(quantifiableTargetResources, (newResource, resourceName) => {
    if (perSeat && userResources.includes(resourceName)) {
      return // If the target plan is "per-seat", then we don't consider users to be overages
    }
    if (newResource.overageAllowed === false) {
      return // If the target plan doesn't allow overages, then we don't warn on them
    }
    const matchingCurrentResource = find(currentResources, (resource) => {
      return resource.id === resourceName
    })

    if (!isNil(matchingCurrentResource) && !isNil(currentUsage)) {
      const usedResource = currentUsage[newResource.id]?.usage || 0

      // A `null` valued quota is equivalent to an unlimited quota, which never has an overage.
      if (!isNil(newResource.quota) && usedResource > newResource.quota) {
        // For display purposes we want the first letter of our overage resource names to be
        // capitalized, unless they refer to GROQ.
        const formattedResourceName = capitalize(newResource.name).replace(/Groq/g, 'GROQ')

        overages[formattedResourceName] = {
          currentUsage: usedResource,
          resourceUnit: newResource.unit,
          newQuota: newResource.quota,
        }
      }
    }
  })

  return overages
}

export function OverageChargeWarning({
  currentPlan,
  currentUsage,
  targetPlan,
  targetPlanName,
}: {
  currentPlan: Plan | undefined
  currentUsage: ResourcesData | undefined
  targetPlan: Plan | undefined
  targetPlanName: string | undefined
}) {
  const overages = calculateOverages(currentPlan, currentUsage, targetPlan)

  if (isEmpty(overages)) {
    return <></>
  }

  const promptTitle =
    `Your current usage exceeds what's included in the ${targetPlanName ?? ''} plan. ` +
    `You will be charged for overages when you switch.`

  return (
    <Prompt
      cardProps={{marginTop: 5}}
      title={promptTitle}
      icon="warning-outline"
      layout="column"
      description={
        <span>
          <OverageDetails overages={overages} />
          <br />
          <br />
          <Text size={1}>
            <a href={`https://www.${process.env.host}/pricing`} target="_blank" rel="noreferrer">
              See the pricing page for details.
            </a>
          </Text>
        </span>
      }
      tone="caution"
    />
  )
}
