import {
  EmptyRow,
  InfoTooltip,
  PermissionButtonProject,
  TabHeader,
  Table,
  TableBody,
  useWizardStore,
} from '@/components/index'
import {useCurrentScopeContext} from '@/context/useCurrentScopeContext'
import {useProjectType} from '@/context/useProjectType'
import {useProjectSubscription} from '@/data/projects/useProjectSubscription'
import {OrganizationSSOProvider, Project, ReferenceLink} from '@/types/index'
import {getProjectMemberState} from '@/utils/usage'
import {
  Badge,
  Box,
  Button,
  Card,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  Stack,
  Text,
  TextInput,
} from '@sanity/ui'
import {getUserResourceName} from './common'

import {useDefaultPlans} from '@/data/plan'
import {RequestWithWarnings, useProjectRequests} from '@/data/projects/useProjectAccessRequests'
import {useProjectProviders} from '@/data/projects/useProjectProviders'
import {sendAmplitudeTrackingEvent} from '@/utils/tracking'
import {AddIcon, CheckmarkIcon, ChevronDownIcon, SearchIcon} from '@sanity/icons'
import {AnimatePresence, motion} from 'framer-motion'
import {debounce} from 'lodash'
import {useCallback, useEffect, useMemo, useState} from 'react'
import styled from 'styled-components'
import {InviteMembersWizard} from '../../wizards/inviteMembers'
import {ProjectAccessRequestRow} from './projectAccessRequestRow'
import {ProjectRoleRequestRow} from './projectRoleRequestRow'
import {ACCESS_REQUEST_TABLE_HEADERS, ROLE_REQUEST_TABLE_HEADERS} from './tableConfig'

export const AvatarWithLoginProvider = styled.div`
  position: relative;
`

export function ProjectRequestsTab({
  project,
  description,
  referenceLink,
}: {
  project: Project
  description: string
  referenceLink: ReferenceLink
}) {
  const {
    data: requests,
    isLoading,
    isError,
    refetch: refetchRequests,
  } = useProjectRequests({project})
  const pendingRequests = requests?.filter((request) => request.status === 'pending')
  const numPendingAccessRequests = pendingRequests?.filter(
    (request) => request.type === 'access'
  ).length
  const numPendingRoleRequests = pendingRequests?.filter(
    (request) => request.type === 'role'
  ).length

  const {org, orgs = [], currentUser} = useCurrentScopeContext() || {}
  const {data: plans = []} = useDefaultPlans()
  const projectType = useProjectType(project)
  const {data: subscription, refetch: refetchSubscription} = useProjectSubscription(project.id)
  const memberState = getProjectMemberState(project, projectType, subscription?.resources, org)
  const {dispatch, state: wizardState} = useWizardStore()

  const isDisabled: boolean = project.isDisabled || project.isDisabledByUser || false

  const handleInviteButtonClicked = useCallback(() => {
    dispatch({type: 'inviteMembersWizard/open'})
  }, [dispatch])

  useEffect(() => {
    if (org?.id && project?.id && currentUser?.id) {
      sendAmplitudeTrackingEvent('Request Page Viewed', project.id, org.id, {
        num_requests: pendingRequests?.length,
        entity: 'project',
      })
    }
  }, [org?.id, project.id, currentUser?.id, pendingRequests?.length])

  const [sortFilter, setSortFilter] = useState<'Newest' | 'Oldest'>('Newest')

  const [searchFilter, setSearchFilter] = useState('')
  const handleTextFilterChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchFilter(event.target.value)
  }, [])
  const debouncedSearchFilterChange = useMemo(
    () => debounce(handleTextFilterChange, 300),
    [handleTextFilterChange]
  )

  const filteredRequests = useMemo(() => {
    const matchTextFilter = (request: RequestWithWarnings) => {
      if (!searchFilter) {
        return true
      }
      if (!request.requestedByUserProfile) return false
      const name = request.requestedByUserProfile.displayName?.toLowerCase() || ''
      const email = request.requestedByUserProfile.email?.toLowerCase() || ''
      return (
        name.includes(searchFilter.toLowerCase()) || email?.includes(searchFilter.toLowerCase())
      )
    }

    const sortDateFilter = (a: RequestWithWarnings, b: RequestWithWarnings) => {
      const newestFirst = sortFilter === 'Newest'
      const dateA = new Date(a.createdAt!).getTime()
      const dateB = new Date(b.createdAt!).getTime()
      if (newestFirst) {
        return dateB - dateA // For newest first
      } else {
        return dateA - dateB // For oldest first
      }
    }
    return requests
      ?.filter((request) => request.status === 'pending' && matchTextFilter(request))
      ?.sort(sortDateFilter)
  }, [searchFilter, sortFilter, requests])

  const filteredAccessRequests = useMemo(
    () => filteredRequests?.filter((request) => request.type === 'access'),
    [filteredRequests]
  )

  const filteredRoleRequests = useMemo(
    () => filteredRequests?.filter((request) => request.type === 'role'),
    [filteredRequests]
  )

  const {data: providers = []} = useProjectProviders(project.id)

  const providersMap: Record<string, OrganizationSSOProvider> = useMemo(() => {
    return providers.reduce(
      (acc, val) => {
        acc[`${val.type}-${val.id}`] = val
        return acc
      },
      {} as Record<string, OrganizationSSOProvider>
    )
  }, [providers])
  return (
    <>
      <Stack space={6}>
        <Stack space={2}>
          <TabHeader
            title="Access requests"
            description={description}
            counter={{
              current: memberState.billable,
              quota: memberState.quota,
              max: memberState.max,
              resource: getUserResourceName(memberState.resource),
            }}
            referenceLink={referenceLink}
            upsellButtonText="Get more seats"
            showUpsell
            button={
              isDisabled ? (
                <InfoTooltip
                  title="Archived project"
                  description="Reactivate the project in settings to invite more members"
                >
                  <Button icon={AddIcon} disabled text="Invite project members" mode="ghost" />
                </InfoTooltip>
              ) : (
                <PermissionButtonProject
                  onClick={handleInviteButtonClicked}
                  title="Invite project members"
                  icon={AddIcon}
                  permissions={[{permissionName: 'sanity.project.members', grantName: 'invite'}]}
                />
              )
            }
          />

          {/* Don't show text search and filters until there are pending requests */}
          {!isLoading && pendingRequests?.length ? (
            <Box>
              <Flex>
                <Box flex={1} marginRight={2}>
                  <TextInput
                    icon={SearchIcon}
                    placeholder="Filter by name or email"
                    radius={2}
                    onChange={debouncedSearchFilterChange}
                  />
                </Box>
                <Card>
                  <MenuButton
                    id="sort-requests-menu-button"
                    button={
                      <Button
                        text={sortFilter}
                        mode={'ghost'}
                        tone={'default'}
                        iconRight={ChevronDownIcon}
                      />
                    }
                    placement="bottom-end"
                    portal
                    menu={
                      <Menu style={{maxHeight: '50vh'}}>
                        <MenuItem
                          onClick={() => setSortFilter('Newest')}
                          tone={sortFilter === 'Newest' ? 'primary' : 'default'}
                        >
                          <Flex gap={2} justify="space-between">
                            <Text>Newest</Text>
                            <Box marginLeft={2}>
                              <Text>
                                {sortFilter === 'Newest' ? <CheckmarkIcon /> : <Box padding={2} />}
                              </Text>
                            </Box>
                          </Flex>
                        </MenuItem>
                        <MenuItem
                          onClick={() => setSortFilter('Oldest')}
                          tone={sortFilter === 'Oldest' ? 'primary' : 'default'}
                        >
                          <Flex gap={2} justify="space-between">
                            <Text>Oldest</Text>
                            <Box marginLeft={2}>
                              <Text>
                                {sortFilter === 'Oldest' ? <CheckmarkIcon /> : <Box padding={2} />}
                              </Text>
                            </Box>
                          </Flex>
                        </MenuItem>
                      </Menu>
                    }
                  />
                </Card>
              </Flex>
            </Box>
          ) : null}
        </Stack>
        <Stack space={4} id="access-requests">
          <Flex align={'center'} gap={2}>
            <Text size={3} weight="bold">
              Project access
            </Text>
            <Badge>{numPendingAccessRequests}</Badge>
          </Flex>
          <Text size={2} muted>
            The following members have requested access to this project.
          </Text>

          <Table
            headers={filteredAccessRequests ? ACCESS_REQUEST_TABLE_HEADERS : []}
            loading={isLoading}
          >
            <TableBody>
              <AnimatePresence>
                <>
                  {filteredAccessRequests?.map((request, index) => {
                    return (
                      <motion.div
                        key={`request-${request.id}`}
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        exit={{opacity: 0}}
                      >
                        <ProjectAccessRequestRow
                          request={request}
                          project={project}
                          providersMap={providersMap}
                          isLast={index === filteredAccessRequests.length - 1}
                          onApprove={() => {
                            refetchRequests()
                            refetchSubscription()
                          }}
                          onDecline={() => {
                            refetchRequests()
                            refetchSubscription()
                          }}
                        />
                      </motion.div>
                    )
                  })}
                  <motion.div initial={{opacity: 0}} animate={{opacity: 1}}>
                    <Box marginTop={4}>
                      {!isLoading && !isError && !numPendingAccessRequests ? (
                        <EmptyRow text="There are no pending access requests" />
                      ) : null}
                      {!isLoading && numPendingAccessRequests && !filteredAccessRequests?.length ? (
                        <EmptyRow text="No requests matching your search" />
                      ) : null}
                      {isError ? (
                        <EmptyRow text="Something went wrong getting pending requests for this project" />
                      ) : null}
                    </Box>
                  </motion.div>
                </>
              </AnimatePresence>
            </TableBody>
          </Table>
        </Stack>
        <Stack space={4} id="role-requests">
          <Flex align={'center'} gap={2}>
            <Text size={3} weight="bold">
              Role change
            </Text>
            <Badge>{numPendingRoleRequests}</Badge>
          </Flex>

          <Text size={2} muted>
            The following members have requested a role change.
          </Text>
          <Table
            headers={filteredRoleRequests ? ROLE_REQUEST_TABLE_HEADERS : []}
            loading={isLoading}
          >
            <TableBody>
              <AnimatePresence>
                <>
                  {filteredRoleRequests?.map((request, index) => {
                    return (
                      <motion.div
                        key={`request-${request.id}`}
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        exit={{opacity: 0}}
                      >
                        <ProjectRoleRequestRow
                          request={request}
                          project={project}
                          providersMap={providersMap}
                          isLast={index === filteredRoleRequests.length - 1}
                          onApprove={() => {
                            refetchRequests()
                            refetchSubscription()
                          }}
                          onDecline={() => {
                            refetchRequests()
                            refetchSubscription()
                          }}
                        />
                      </motion.div>
                    )
                  })}
                  <motion.div initial={{opacity: 0}} animate={{opacity: 1}}>
                    <Box marginTop={4}>
                      {!isLoading && !isError && !numPendingRoleRequests ? (
                        <EmptyRow text="There are no pending role change requests" />
                      ) : null}
                      {!isLoading && numPendingRoleRequests && !filteredRoleRequests?.length ? (
                        <EmptyRow text="No requests matching your search" />
                      ) : null}
                      {isError ? (
                        <EmptyRow text="Something went wrong getting pending requests for this project" />
                      ) : null}
                    </Box>
                  </motion.div>
                </>
              </AnimatePresence>
            </TableBody>
          </Table>
        </Stack>
      </Stack>
      {wizardState.inviteMembersWizard.open && (
        <InviteMembersWizard
          params={{
            org,
            orgs,
            plans,
            project,
          }}
        />
      )}
    </>
  )
}
