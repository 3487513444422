import React from 'react'
import {Box} from '@sanity/ui'
import {Tab} from '@/types/index'
import {Organization} from '@/types/models'
import {SinglePageTab} from '@/ui/index'

export function OrgPlanUsage({tab, org}: {tab: Tab; org: Organization}) {
  const headings = tab.headings || []

  return (
    <Box>
      {tab && (
        <SinglePageTab
          org={org}
          tab={tab}
          headings={headings.filter((h) => h.path === 'org-usage')}
        />
      )}
    </Box>
  )
}
