import React, {useCallback, useMemo} from 'react'
import {Box, Card, CardTone, Flex, Inline, Text, useToast} from '@sanity/ui'
import {EditIcon} from '@sanity/icons'
import Link from 'next/link'
import {Dataset, DatasetTag, Project, Tag as TagT} from '@/types/models'
import {useRequiredProjectPermissions, useRoutePath} from '@/context/index'
import {NoElementsTooltip, PermissionTooltip, TagIcon} from '@/ui/index'
import {MenuMultiSelect, MenuMultiSelectOption} from '@/components/general/menuMultiSelect'
import {useDatasetTag} from '@/data/datasets/useDatasetTag'

function Tag({title, tone}: {title: string; tone?: CardTone}) {
  return (
    <Card tone={tone} padding={2} radius={2}>
      <Flex align="center">
        <Box marginRight={2}>
          <Text size={1}>
            <TagIcon />
          </Text>
        </Box>
        <Text size={1}>{title}</Text>
      </Flex>
    </Card>
  )
}

function LinkTag({name, title, tone}: {name: string; title: string; tone?: CardTone}) {
  const {basePath} = useRoutePath()
  return (
    <Link href={`${basePath}/datasets/tags?name=${name}`} passHref legacyBehavior>
      <Card tone={tone} padding={2} radius={2} as="a">
        <Flex align="center">
          <Box marginRight={2}>
            <Text size={1}>
              <TagIcon />
            </Text>
          </Box>
          <Text size={1}>{title}</Text>
        </Flex>
      </Card>
    </Link>
  )
}

type Props = {
  project: Project
  dataset: Dataset
  tags: TagT[]
}
export function DatasetTagSelect({project, dataset, tags}: Props) {
  const toast = useToast()
  const datasetTags = useMemo(() => dataset?.tags || [], [dataset])

  const hasPermission = useRequiredProjectPermissions([
    {permissionName: 'sanity.project.tags', grantName: 'read'},
    {permissionName: 'sanity.project.datasets', grantName: 'update'},
  ])

  const {isLoading, addDatasetTag, removeDatasetTag} = useDatasetTag(project.id, dataset.name)

  const tagValues = useMemo(() => {
    return datasetTags.sort((a, b) => a.title.localeCompare(b.title)).map((role) => role.name)
  }, [datasetTags])

  const tagOptions: MenuMultiSelectOption[] = useMemo(() => {
    return tags.sort((a, b) => a.title.localeCompare(b.title))
  }, [tags])

  const formatTag = useCallback(
    (tag: DatasetTag) => {
      const tone = tags.find((t) => t.name === tag.name)?.metadata?.tone || 'transparent'
      return <Tag key={tag.name} title={tag.title} tone={tone} />
    },
    [tags]
  )

  const formatLinkTag = useCallback(
    (tag: DatasetTag) => {
      const tone = tags.find((t) => t.name === tag.name)?.metadata?.tone || 'transparent'
      return <LinkTag key={tag.name} name={tag.name} title={tag.title} tone={tone} />
    },
    [tags]
  )

  const handleDatasetTagRemove = useCallback(
    (tagName: string) => {
      if (!hasPermission) return
      removeDatasetTag(
        {tagName},
        {
          onSuccess() {
            toast.push({
              title: 'Removed tag from dataset',
              status: 'success',
            })
          },
          onError(error) {
            toast.push({
              title: 'Failed to remove tag from dataset',
              description: error.message,
              status: 'error',
            })
          },
        }
      )
    },
    [hasPermission, removeDatasetTag, toast]
  )

  const handleDatasetTagAdd = useCallback(
    (tagName: string) => {
      if (!hasPermission) return
      addDatasetTag(
        {tagName},
        {
          onSuccess() {
            toast.push({
              title: 'Added tag to dataset',
              status: 'success',
            })
          },
          onError(error) {
            toast.push({
              title: 'Failed to add tag to dataset',
              description: error.message,
              status: 'error',
            })
          },
        }
      )
    },
    [hasPermission, addDatasetTag, toast]
  )

  const handleDatasetTagChange = useCallback(
    (tagName) => {
      const has = datasetTags.some((tag) => tag.name === tagName)
      if (has) {
        return handleDatasetTagRemove(tagName)
      }
      return handleDatasetTagAdd(tagName)
    },
    [handleDatasetTagAdd, handleDatasetTagRemove, datasetTags]
  )

  return (
    <Inline space={1} flex={1}>
      {datasetTags.length === 0 ? (
        <Card border padding={2} radius={2} style={{borderStyle: 'dashed'}}>
          <Text align="center" muted size={1}>
            No tags
          </Text>
        </Card>
      ) : (
        <Inline space={2}>{datasetTags.map(formatLinkTag)}</Inline>
      )}

      <NoElementsTooltip
        data={tags}
        title="No tags"
        description="There are no tags in this project"
      >
        <PermissionTooltip disabled={hasPermission}>
          <MenuMultiSelect
            id={`dataset-${dataset.name}-tags`}
            disabled={!hasPermission || tags.length === 0}
            text=""
            icon={EditIcon}
            values={tagValues}
            options={tagOptions}
            optionRender={formatTag}
            optionPaddingX={1}
            optionPaddingY={1}
            onChange={handleDatasetTagChange}
            loading={isLoading}
          />
        </PermissionTooltip>
      </NoElementsTooltip>
    </Inline>
  )
}
