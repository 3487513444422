import {Card, type CardProps, Flex, Stack, Text, TooltipDelayGroupProvider} from '@sanity/ui'
import {forwardRef, useMemo} from 'react'

import {Tooltip, TOOLTIP_DELAY_PROPS} from '../../primitives/tooltip'

export interface BadgeStackProps {
  items: {
    title: string
    info?: string[]
    href?: string
    as?: CardProps['as']
  }[]
  maxLength?: number
}

export const BadgeStack = forwardRef(function BadgeStack(
  props: BadgeStackProps,
  ref: React.Ref<HTMLDivElement>
) {
  const {items, maxLength = 4} = props

  const visibleItems = useMemo(() => {
    if (items.length <= maxLength) return items

    return items.slice(0, maxLength)
  }, [items, maxLength])

  const hiddenItems = useMemo(() => {
    if (items.length <= maxLength) return []

    return items.slice(maxLength).map((item) => item.title)
  }, [items, maxLength])

  return (
    <TooltipDelayGroupProvider delay={TOOLTIP_DELAY_PROPS}>
      <Flex align="center" gap={1} wrap="wrap" ref={ref}>
        {visibleItems.map((item, index) => {
          return (
            <Tooltip
              animate
              key={index}
              disabled={!item.info?.join(', ')}
              content={
                <Stack style={{maxWidth: 250}}>
                  <Text size={1}>{item.info?.join(', ')}</Text>
                </Stack>
              }
              portal
            >
              <Card shadow={1} radius={2}>
                <Card
                  __unstable_focusRing
                  data-as="a"
                  as={item.as}
                  padding={1}
                  radius={2}
                  shadow={1}
                  tabIndex={0}
                  tone="transparent"
                  href={item.href}
                  style={{textDecoration: 'none'}}
                >
                  <Text size={1} muted textOverflow="ellipsis">
                    {item.title}
                  </Text>
                </Card>
              </Card>
            </Tooltip>
          )
        })}

        {hiddenItems.filter(Boolean).length > 0 && (
          <Tooltip
            animate
            disabled={hiddenItems.length === 0}
            content={
              <Stack style={{maxWidth: 250}}>
                <Text size={1}>{hiddenItems.join(', ')}</Text>
              </Stack>
            }
            portal
          >
            <Card shadow={1} radius={2}>
              <Card
                data-as="a"
                radius={2}
                tabIndex={0}
                shadow={1}
                padding={1}
                __unstable_focusRing
                tone="transparent"
              >
                <Text size={1} muted>
                  +{hiddenItems.length}
                </Text>
              </Card>
            </Card>
          </Tooltip>
        )}
      </Flex>
    </TooltipDelayGroupProvider>
  )
})
