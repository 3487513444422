/* eslint-disable react/jsx-no-bind, react/jsx-handler-names */
import {createPermissionResource, updatePermissionResource} from '@/data/projects'
import {getProjectPermissionResourcesKey} from '@/data/projects/cache'
import {PermissionResource, Project} from '@/types/models'
import {FormField, JSONInput} from '@/ui/index'
import {slugify} from '@/utils/index'
import {Button, Card, Dialog, Grid, Stack, TextArea, TextInput, useToast} from '@sanity/ui'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import React, {useCallback, useMemo, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'

const resourceNamePattern = /^[a-z0-9-_]{1,64}$/

type FormData = {
  name: string
  title: string
  description: string
  filter: string
}

type Props = {
  onClose: (isDirty: boolean) => void
  create?: boolean
  project: Project
  resource?: PermissionResource
}

function parseFormErrorMessage(msg: string): [keyof FormData | '__unknown__', string] {
  if (msg.startsWith('Error parsing config filter'))
    return ['filter', msg.replace('Error parsing config filter: ', '')]
  return ['__unknown__', msg]
}

export function ContentResourceWizard({onClose, create, project, resource}: Props) {
  const toast = useToast()
  const queryClient = useQueryClient()
  const [nameIsModified, setNameIsModified] = useState(false)

  const defaultValues: FormData = useMemo(() => {
    return {
      name: resource?.name || '',
      title: resource?.title || '',
      description: resource?.description || '',
      filter: (resource?.config?.filter as string) || '',
    }
  }, [resource])

  const {
    register,
    control,
    handleSubmit,
    formState: {errors},
    setError,
    setValue,
  } = useForm<FormData>({
    defaultValues,
  })

  const createMutation = useMutation({
    mutationFn: (data: FormData) =>
      createPermissionResource(project.id, {
        permissionResourceType: 'sanity.document.filter.mode',
        name: data.name,
        title: data.title,
        description: data.description,
        config: {
          filter: data.filter,
        },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: getProjectPermissionResourcesKey(project.id)})
      onClose(true)
      toast.push({title: 'Content resource created', status: 'success'})
    },
    onError: (error: Error) => {
      const [field, message] = parseFormErrorMessage(error.message)
      if (field === '__unknown__') {
        toast.push({title: error.name, description: error.message, status: 'error'})
      } else {
        setError(field, {message})
      }
    },
  })

  const updateMutation = useMutation({
    mutationFn: (data: FormData) =>
      updatePermissionResource(project.id, resource!.id, {
        title: data.title,
        description: data.description,
        config: {
          filter: data.filter,
        },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: getProjectPermissionResourcesKey(project.id)})
      onClose(true)
      toast.push({title: 'Content resource updated', status: 'success'})
    },
    onError: (error: Error) => {
      const [field, message] = parseFormErrorMessage(error.message)
      if (field === '__unknown__') {
        toast.push({title: error.name, description: error.message, status: 'error'})
      } else {
        setError(field, {message})
      }
    },
  })

  const handNameIsModified = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value && event.target.value.length > 0) {
        setNameIsModified(true)
      } else {
        setNameIsModified(false)
      }
    },
    [setNameIsModified]
  )

  const onTitleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (nameIsModified) {
        return
      }
      setValue('name', slugify(event.target.value), {shouldValidate: true})
    },
    [nameIsModified, setValue]
  )

  const save = (data: FormData) => {
    if (create) {
      createMutation.mutate(data)
    } else if (resource) {
      updateMutation.mutate(data)
    }
  }

  return (
    <Dialog
      header={create ? 'Create new content resource' : 'Configure content resource'}
      id="content-resource-wizard"
      onClose={() => onClose(false)}
      zOffset={1000}
      width={1}
      footer={
        <Card padding={4}>
          <Grid columns={2} gapX={2}>
            <Button text="Cancel" mode="bleed" onClick={() => onClose(false)} />
            <Button
              text={create ? 'Create content resource' : 'Update content resource'}
              tone="primary"
              loading={createMutation.isPending || updateMutation.isPending}
              onClick={handleSubmit(save)}
            />
          </Grid>
        </Card>
      }
    >
      <Stack padding={4} space={5}>
        <FormField
          label="Title"
          description={
            'A descriptive name of the document resource. E.g. "Blog posts" or "Articles in review"'
          }
          error={errors?.title?.message}
        >
          <TextInput
            {...register('title', {required: 'A title is required'})}
            radius={2}
            onChange={onTitleChange}
          />
        </FormField>

        <FormField
          label="Identifier"
          description={'A unique identifier used to access this resource programmatically'}
          error={errors?.name?.message}
        >
          <TextInput
            readOnly={!create}
            {...register('name', {
              required: create ? 'An identifier is required' : undefined,
              pattern: resourceNamePattern,
            })}
            radius={2}
            onChange={handNameIsModified}
          />
        </FormField>

        <FormField
          label="Description"
          description="Describe the resource for others in your team"
          error={errors?.description?.message}
        >
          <TextArea {...register('description')} radius={2} />
        </FormField>

        <FormField
          label="GROQ filter"
          description="Provide a GROQ filter to select relevant documents for this resource"
          error={errors?.filter?.message}
        >
          <Controller
            control={control}
            name="filter"
            defaultValue=""
            rules={{required: 'A valid GROQ filter must be specified'}}
            render={({field}) => (
              <JSONInput
                value={field.value}
                onChange={field.onChange}
                placeholder={'_type == "blog"'}
                padding={2}
              />
            )}
          />
        </FormField>
      </Stack>
    </Dialog>
  )
}
