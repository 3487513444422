import {CloseIcon} from '@sanity/icons'
import {Box, Button, Card, CardProps, Flex, Text} from '@sanity/ui'
import React from 'react'

interface Props {
  value: React.ReactNode
  icon?: React.ReactNode
  onClick?: () => void
  onClear?: () => void
}

export const SelectedCard = ({value, icon, onClick, onClear, ...rest}: Props & CardProps) => (
  <Card border radius={2} padding={1} style={{height: 53, display: 'flex'}}>
    <Flex align="center" style={{width: '100%'}}>
      <Box flex={1}>
        <Card tone={'primary'} as={'div' as any} padding={1} radius={2} {...rest}>
          <Flex align="center" style={{width: '100%'}}>
            <Box flex={1}>
              <Card
                padding={1}
                radius={2}
                paddingRight={1}
                as={onClick ? 'button' : 'div'}
                tone="primary"
                onClick={onClick}
              >
                {typeof value === 'string' ? <Text textOverflow="ellipsis">{value}</Text> : value}
              </Card>
            </Box>

            <Box>
              <Button
                radius={2}
                icon={icon ? icon : CloseIcon}
                mode="bleed"
                aria-label="Clear"
                onClick={onClear}
              />
            </Box>
          </Flex>
        </Card>
      </Box>
    </Flex>
  </Card>
)
