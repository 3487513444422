import {DotIcon} from '@sanity/icons'
import {Box, Flex, Stack, type StackProps, Text, type TextProps} from '@sanity/ui'
import {isString} from 'lodash'
import {forwardRef, isValidElement, type ReactNode, useCallback} from 'react'
import styled from 'styled-components'

const Root = styled(Stack)`
  &[data-debug='true'] {
    & > li  {
      outline: 1px solid red;
    }
  }
`

const Item = styled(Flex)`
  display: flex;
`

const Dot = styled(DotIcon)``

interface BulletListProps {
  _debug?: boolean
  fontSize?: TextProps['size']
  items: unknown[]
  renderBullet?: (item: any) => ReactNode
  renderItem?: (item: any) => ReactNode
  space?: StackProps['space']
}

export const BulletList = forwardRef(function BulletList(
  props: BulletListProps,
  ref: React.ForwardedRef<HTMLUListElement>
) {
  const {_debug, items, renderItem, renderBullet, space = 4, fontSize = 1} = props

  const handleRenderItem = useCallback(
    (item: unknown): ReactNode => {
      if (renderItem) {
        const rendered = renderItem(item)

        if (isString(rendered)) {
          return <Text size={fontSize}>{rendered}</Text>
        }

        if (!isValidElement(rendered)) return null

        return rendered
      }

      if (isString(item)) {
        return <Text size={fontSize}>{item}</Text>
      }

      if (!isValidElement(item)) return null

      return item
    },
    [fontSize, renderItem]
  )

  const handleRenderBullet = useCallback(
    (item: unknown) => {
      if (renderBullet) return renderBullet(item)

      return (
        <Text size={fontSize}>
          <Dot />
        </Text>
      )
    },
    [fontSize, renderBullet]
  )

  return (
    <Root forwardedAs="ul" space={space} data-debug={_debug} ref={ref}>
      {items.map((item, index) => {
        const renderedItem = handleRenderItem(item)
        const renderedBullet = handleRenderBullet(item)

        const key = `bullet-list-item-${index}`

        return (
          <Item forwardedAs="li" gap={2} key={key}>
            <Flex align="flex-start">{renderedBullet}</Flex>

            <Box flex={1}>{renderedItem}</Box>
          </Item>
        )
      })}
    </Root>
  )
})
