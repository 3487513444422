import {useMutation, useQueryClient} from '@tanstack/react-query'
import {deleteDataset} from '.'
import {getDatasetsListKey} from './cache'
import {Dataset} from '@/types/models'

export function useDeleteDataset(projectId: string) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['deleteDataset', projectId],
    mutationFn: ({datasetName}: {datasetName: string}) => deleteDataset(projectId, datasetName),
    onMutate: async ({datasetName}) => {
      await queryClient.cancelQueries({queryKey: getDatasetsListKey(projectId)})
      const previousDatasets = queryClient.getQueryData<Dataset[]>(getDatasetsListKey(projectId))
      queryClient.setQueryData<Dataset[]>(getDatasetsListKey(projectId), (old) =>
        old
          ? old.map((dataset) =>
              dataset.name === datasetName ? {...dataset, isDeleted: true} : dataset
            )
          : []
      )

      return {previousDatasets}
    },
    onError: (_err, _variables, context) => {
      queryClient.setQueryData(getDatasetsListKey(projectId), context?.previousDatasets)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: getDatasetsListKey(projectId)})
    },
  })
}
