import React from 'react'
import {ExternalLink, TabSectionHeader, TransferProjectButton} from '@/ui/index'
import {Project} from '@/types/index'

export function TransferProject({
  title,
  description,
  project,
}: {
  title: string
  description: string
  project: Project
}) {
  return (
    <>
      <TabSectionHeader title={title} description={description} />
      <ExternalLink
        text="Read more about project transfers in the docs"
        url={`https://www.${process.env.host}/docs/plans-and-payments#project-transfers`}
      />

      <TransferProjectButton project={project} />
    </>
  )
}
