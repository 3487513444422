import React, {useCallback, useEffect, useState} from 'react'
import {Button, Stack, TextInput, Flex} from '@sanity/ui'
import {Prompt, TabSectionHeader} from '@/ui/index'
import {FormField} from '@/components/form'
import {ConfirmDialog} from '@/components/dialog'
import {useDeleteCurrentUser} from '@/data/users'
import {useCurrentUser} from '@/context/index'

export function DeleteAccount({title, description}: {title: string; description: string}) {
  const currentUser = useCurrentUser()
  const [confirmString, setConfirmString] = useState<string>('')
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const [displayError, setDisplayError] = useState<boolean>(false)
  const {isPending, mutate: deleteAccount, error} = useDeleteCurrentUser()
  const name = currentUser?.name

  useEffect(() => {
    if (error) {
      setDisplayError(true)
      return
    }
    setDisplayError(false)
    setConfirmString('')
  }, [error])

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmString(e.target.value)
  }, [])

  function confirm() {
    if (confirmString === name) {
      deleteAccount(undefined, {
        onSuccess: () => {
          setDialogOpen(false)
          setTimeout(() => {
            window.location.href = `https://www.${process.env.host}/login`
          }, 500)
        },
      })
    }
  }

  function closeDialog() {
    setDialogOpen(false)
  }

  function openDialog() {
    setDisplayError(false)
    setDialogOpen(true)
  }
  return (
    <>
      <Stack space={4}>
        <TabSectionHeader title={title} description={description} />
        <Flex>
          <Button text="Delete account" tone="critical" mode="ghost" onClick={openDialog} />
        </Flex>
      </Stack>
      {dialogOpen && (
        <ConfirmDialog
          header="Delete account"
          title={`Do you really want to delete this account?`}
          description="The account will be permanently deleted, including all data. This action is irrevocable and cannot be undone."
          id="delete-account-dialog"
          onClose={closeDialog}
          confirmButton={
            <Button
              disabled={confirmString !== name}
              text="Delete"
              tone="critical"
              onClick={confirm}
              loading={isPending}
            />
          }
        >
          {!displayError && (
            <FormField label="Type in the name of your user to confirm" description={name}>
              <TextInput padding={3} onChange={handleChange} />
            </FormField>
          )}
          {error && displayError && (
            <Prompt tone="critical" icon="error-outline" description={error.message} />
          )}
        </ConfirmDialog>
      )}
    </>
  )
}
