import React from 'react'
import {Box, Card, Flex, Stack} from '@sanity/ui'
import {Icon} from '@sanity/icons'
import styled from 'styled-components'
import {nameToInitials} from '../../members/utils'
import {EventDescription, ActivityBadge, ActivityDetail} from './atoms'
import {PropsType} from './PropTypes'
import {AssociatedLink} from './atoms/associatedLink'
import {AvatarWithFallback} from '@/ui/index'

const DetailsFlex = styled(Flex)`
  .event-details-wrap {
    margin-right: 20px;
  }

  .event-details-wrap:empty {
    display: none;
  }
`

export function ActivityTileDefault(props: PropsType) {
  const {
    details,
    secondaryIconName,
    mainIconName,
    showAvatar,
    projectId,
    isEntityAction,
    user,
    descriptionTemplate,
    event,
    associatedLink,
    eventProject,
    disableFilter,
  } = props

  return (
    <Card paddingY={3}>
      <Flex align="center">
        <Box flex={1} paddingRight={4}>
          <Flex>
            <Box paddingRight={4} style={{alignSelf: 'center', minWidth: '60px'}}>
              <ActivityBadge icon={secondaryIconName} squared={isEntityAction && !!projectId}>
                {mainIconName && !showAvatar && <Icon symbol={mainIconName} />}
                {showAvatar && (
                  <AvatarWithFallback
                    size={1}
                    src={isEntityAction ? undefined : user?.imageUrl || undefined}
                    squared={isEntityAction && !!projectId}
                    fallback={isEntityAction || !event.actorName}
                    bgColor={
                      isEntityAction && !!projectId ? eventProject?.metadata?.color : undefined
                    }
                    initials={
                      isEntityAction
                        ? undefined
                        : nameToInitials(event?.userName || event?.actorName)
                    }
                  />
                )}
              </ActivityBadge>
            </Box>
            <Stack space={3} paddingBottom={2}>
              <Box>
                <DetailsFlex align="center" wrap="wrap">
                  {details?.map(
                    (detail) =>
                      detail.value && (
                        <Box key={detail.type} className="event-details-wrap">
                          <ActivityDetail {...detail} disableFilter={disableFilter} event={event} />
                        </Box>
                      )
                  )}
                </DetailsFlex>
              </Box>
              <EventDescription template={descriptionTemplate} event={event} />
            </Stack>
          </Flex>
        </Box>

        <Box>
          {associatedLink.url && (
            <AssociatedLink
              label={associatedLink.label}
              url={associatedLink.url}
              external={associatedLink.external}
            />
          )}
        </Box>
      </Flex>
    </Card>
  )
}
