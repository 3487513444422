import {type Request} from '@sanity/access-api'
import {capitalize} from 'lodash'

import {getEmailDomain, isPersonalEmail} from './emailUtils'

interface Warning {
  value: string
  type: 'personal-email' | 'unknown-email-domain' | 'unknown-login-provider'
}

export interface RequestToApprove {
  request: Request
  warnings: Warning[]
}

export async function getRequestApprovalConcerns(
  request: Request,
  members: {profile?: {email?: string; provider?: string}}[]
): Promise<RequestToApprove | null> {
  const requesterEmail = request.requestedByUserProfile?.email || ''
  const requesterLoginProvider = request.requestedByUserProfile?.provider || ''
  const requesterEmailDomain = getEmailDomain(requesterEmail)
  const currentProviders = new Set(members.map((member) => member.profile?.provider))
  const currentEmailDomains = new Set(
    members.map((member) => getEmailDomain(member.profile?.email || ''))
  )

  const warnings: Warning[] = []

  if (await isPersonalEmail(requesterEmail)) {
    warnings.push({
      value: requesterEmailDomain,
      type: 'personal-email',
    })
  }

  if (!currentEmailDomains.has(requesterEmailDomain)) {
    warnings.push({
      value: requesterEmailDomain,
      type: 'unknown-email-domain',
    })
  }

  if (!currentProviders.has(requesterLoginProvider)) {
    warnings.push({
      value: capitalize(requesterLoginProvider),
      type: 'unknown-login-provider',
    })
  }

  if (warnings.length > 0) {
    return {
      request,
      warnings,
    }
  }

  return null
}
