import {useMemo} from 'react'
import {useDatasets} from '@/data/datasets'
import {Plan, Project} from '@/types/models'
import {getSubscriptionCustomFeatures} from '@/utils/subscription'
import {useProjectSubscription} from '@/data/projects/useProjectSubscription'
import {getAddOnsCopy} from '@/components/project/plan/addonCopyConfig'
import {filterBillableDatasets, getProjectMemberState} from '@/utils/usage'
import {useProjectType} from '@/context/useProjectType'
import {
  getDatasetLimits,
  getNotAllowedProjectMembers,
  type Overage,
  type SuggestedAddon,
  type OverageData,
} from './overages'

export function useUsageOverages(project: Project, targetPlan: Plan): OverageData {
  const {data: datasets} = useDatasets(project.id)
  const {data: currentPlan} = useProjectSubscription(project.id)
  const addons = getAddOnsCopy()
  const projectType = useProjectType(project)

  /* Get the current usage of the project */
  const billableDatasets = useMemo(() => {
    return filterBillableDatasets(datasets || [])
  }, [datasets])

  const hasPrivateDatasets = useMemo(() => {
    return billableDatasets?.some((d) => d.aclMode !== 'public')
  }, [billableDatasets])

  const projectMembers = useMemo(() => {
    return project.members.filter((m) => !m.isRobot)
  }, [project.members])

  const memberState = useMemo(() => {
    return getProjectMemberState(project, projectType, targetPlan?.resources)
  }, [project, projectType, targetPlan?.resources])

  const robotMembers = useMemo(() => {
    return project.members.filter((m) => m.isRobot)
  }, [project.members])

  const notAllowedRoles = useMemo(() => {
    return getNotAllowedProjectMembers(targetPlan, projectMembers)
  }, [projectMembers, targetPlan])

  const notAllowedRobotRoles = useMemo(() => {
    return getNotAllowedProjectMembers(targetPlan, robotMembers)
  }, [robotMembers, targetPlan])

  const isAddonsForiddenForPlan = useMemo(() => {
    if (!currentPlan) return false
    return getSubscriptionCustomFeatures(currentPlan)?.length > 0
  }, [currentPlan])

  /* Get the quotas and limits for the target plan */
  const datasetLimits = useMemo(() => getDatasetLimits(targetPlan), [targetPlan])

  const currentOverages = (() => {
    const overages: Overage[] = []

    if ((billableDatasets?.length ?? 0) > datasetLimits.datasetQuota) {
      overages.push({
        id: 'datasets',
        description: `Plan includes ${datasetLimits.datasetQuota} datasets, you are currently use ${billableDatasets?.length ?? 0}.`,
        link: ['datasets', 'Edit datasets'],
        exceededByAmount: (billableDatasets?.length ?? 0) - datasetLimits.datasetQuota,
        unblockedByAddonId: 'additional-datasets-2023-10-19',
      })
    }

    if (hasPrivateDatasets && datasetLimits.privateDatasetAllowed === false) {
      overages.push({
        id: 'private-datasets',
        description: `Plan does not support private datasets.`,
        link: ['datasets', 'Edit datasets'],
      })
    }

    if (memberState.billable > memberState.max) {
      overages.push({
        id: 'users',
        description: `Plan includes ${memberState.max} seats, you currently use ${memberState.billable}.`,
        link: ['members', 'View members'],
        exceededByAmount: memberState.billable - memberState.max,
      })
    }

    if (isAddonsForiddenForPlan) {
      overages.push({
        id: 'add-ons',
        description: `Plan does not allow add-ons.`,
        link: ['plan#add-ons', 'Configure add-ons'],
      })
    }

    if (notAllowedRoles.length > 0) {
      overages.push({
        id: 'roles',
        description: `Plan does not allow the following member roles currently in use: ${notAllowedRoles.join(', ')}.`,
        link: ['members', 'View members'],
      })
    }

    if (notAllowedRobotRoles.length > 0) {
      overages.push({
        id: 'robot-roles',
        description: `Plan does not allow the following robot member roles currently in use: ${notAllowedRobotRoles.join(', ')}.`,
        link: ['api#tokens', 'Edit API tokens'],
      })
    }

    return overages
  })()

  const suggestedAddons = useMemo(() => {
    if (isAddonsForiddenForPlan) return []

    const suggestedAddons: SuggestedAddon[] = []

    /* Suggest addons based on current overages */
    currentOverages.forEach((overage) => {
      const addon = addons.find((a) => a.featureId === overage.unblockedByAddonId)
      const countofAddonsNeededToUnblock = overage.exceededByAmount ?? 1

      if (!addon || countofAddonsNeededToUnblock > addon.maxAllowed) {
        return
      }
      suggestedAddons.push({
        ...addon,
        count: countofAddonsNeededToUnblock,
        totalPrice: countofAddonsNeededToUnblock * addon.price,
        suggestReason: `overage-${overage.id}`,
      })
    })

    return suggestedAddons
  }, [isAddonsForiddenForPlan, addons, currentOverages])

  /**
   * Filter out overages that have been confirmed to be unblocked by an addon
   */
  const blockingOverages = useMemo(() => {
    return currentOverages.filter(
      (overage) => !suggestedAddons.some((a) => a.suggestReason === `overage-${overage.id}`)
    )
  }, [currentOverages, suggestedAddons])

  return {
    overages: currentOverages,
    blockingOverages,
    suggestedAddons,
  }
}
