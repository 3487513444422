import {type Role} from '@sanity/access-api'
import {AddIcon} from '@sanity/icons'
import {Stack} from '@sanity/ui'
import {isEqual} from 'lodash'
import {useCallback, useEffect, useMemo, useRef, useState} from 'react'

import {type MembersV2} from '@/types/members_v2'

import {Button} from '../../primitives/button/Button'
import {InvitationItem} from './InvitationItem'
import {
  addInvitationItem,
  addInvitationResource,
  addInvitee,
  removeInvitationItem,
  removeInvitationResource,
  type RemoveInvitationResourcePayload,
  removeInvitee,
  type RemoveInviteePayload,
  resourceSelect,
  type ResourceSelectPayload,
  roleSelect,
  type RoleSelectPayload,
} from './invite-mutations'
import {createEmptyInvitationItem, isValidInvites, transformLocalInvites} from './invite-utils'
import {
  type InvitationCreateValue,
  type InvitationScope,
  type LocalInvitationItemValue,
  type LocalInvitee,
} from './types'

export interface MembersInvitationFormProps {
  members: MembersV2['member'][]
  multiProjectInvite?: boolean
  onCanInviteChange: (canInvite: boolean) => void
  onChange: (invitations: InvitationCreateValue[]) => void
  /** The organization ID to invite to when the `scope` is "organization" */
  organizationId?: string
  /** The selectable roles when the `scope` is "organization". */
  organizationRoles?: Role[]
  /** The project ID to invite to when the `scope` is "project" and `multiProjectInvite` is false.  */
  projectId?: string
  projectsData: MembersV2['projectData'][]
  scope: InvitationScope
}

export function MembersInvitationForm(props: MembersInvitationFormProps) {
  const {
    members,
    multiProjectInvite,
    onCanInviteChange,
    onChange,
    organizationId,
    organizationRoles,
    projectId,
    projectsData,
    scope,
  } = props

  const prevInvites = useRef<InvitationCreateValue[]>([])

  const [_localInvitations, setLocalInvitations] = useState<LocalInvitationItemValue[]>([
    createEmptyInvitationItem(scope),
  ])

  const handleRoleSelect = useCallback((_id: string, payload: RoleSelectPayload) => {
    setLocalInvitations((prev) => {
      return roleSelect(_id, prev, payload)
    })
  }, [])

  const handleSelectResource = useCallback((_id: string, payload: ResourceSelectPayload) => {
    setLocalInvitations((prev) => {
      return resourceSelect(_id, prev, payload)
    })
  }, [])

  const handleAddInvitee = useCallback((_id: string, payload: LocalInvitee) => {
    setLocalInvitations((prev) => {
      return addInvitee(_id, prev, payload)
    })
  }, [])

  const handleRemoveInvitee = useCallback((_id: string, payload: RemoveInviteePayload) => {
    setLocalInvitations((prev) => {
      return removeInvitee(_id, prev, payload)
    })
  }, [])

  const handleRemoveInviteItem = useCallback((_id: string) => {
    setLocalInvitations((prev) => {
      return removeInvitationItem(_id, prev)
    })
  }, [])

  const handleAddInviteItem = useCallback(() => {
    setLocalInvitations((prev) => {
      return addInvitationItem(prev, scope)
    })
  }, [scope])

  const handleResourceItemRemove = useCallback(
    (_id: string, payload: RemoveInvitationResourcePayload) => {
      setLocalInvitations((prev) => {
        return removeInvitationResource(_id, prev, payload)
      })
    },
    []
  )

  const handleInvitationResourceAdd = useCallback((_id: string) => {
    setLocalInvitations((prev) => {
      return addInvitationResource(_id, prev)
    })
  }, [])

  // If the scope is "organization" or "project" without multi-project invite,
  // we already know the resource ID for the invite. In this case, we can
  // transform the local invitations to always include the resource ID.
  // This is necessary, as the logic in the `InvitationItem` component
  // will pick the appropriate roles to select from based on the resource ID.
  const transformedInvitations: LocalInvitationItemValue[] = useMemo(() => {
    const isOrganizationScope = scope === 'organization' && organizationId
    const isSingleProjectInvite = !multiProjectInvite && scope === 'project' && projectId
    const isMultiProjectInvite = multiProjectInvite && scope === 'project'

    if (isOrganizationScope) {
      return _localInvitations.map((item) => {
        return {
          ...item,
          resources: item.resources.map((resource) => {
            return {
              ...resource,
              resourceId: organizationId,
            }
          }),
        }
      })
    }

    if (isSingleProjectInvite) {
      return _localInvitations.map((item) => {
        return {
          ...item,
          resources: item.resources.map((resource) => {
            return {
              ...resource,
              resourceId: projectId,
            }
          }),
        }
      })
    }

    if (isMultiProjectInvite) {
      return _localInvitations
    }

    return _localInvitations
  }, [_localInvitations, multiProjectInvite, organizationId, projectId, scope])

  const invites = useMemo(() => {
    // This function maps the local invitations to the final invites
    // that will be passed to the parent component.
    // The `localInvitations` only makes sense in the context of the
    // current component, and the final invites are what the parent
    // component will use to send the invitations.
    // Note that, the invites returned from this component don't have
    // a 1:1 mapping with an API endpoint. For example, some invites
    // require sending an email to the invitee, while others involve
    // adding a role to a resource.
    // What API endpoint to use is determined by the `type` field in
    // in each invite item returned from this function.
    return transformLocalInvites({
      localInvitations: transformedInvitations,
      members,
      scope,
    })
  }, [transformedInvitations, scope, members])

  const hasValidInvites = useMemo(() => isValidInvites(invites), [invites])

  useEffect(() => {
    // Notify parent component of the current invites
    if (!isEqual(prevInvites.current, invites)) {
      onChange(invites)
      onCanInviteChange(hasValidInvites)
    }

    prevInvites.current = invites
  }, [hasValidInvites, invites, onCanInviteChange, onChange])

  return (
    <Stack space={4}>
      <Stack space={3}>
        {transformedInvitations.map((item) => {
          return (
            <InvitationItem
              key={item._id}
              invitation={item}
              members={members}
              multiProjectInvite={multiProjectInvite}
              onInvitationResourceAdd={() => handleInvitationResourceAdd(item._id)}
              onInvitationResourceRemove={(resourceItemId) =>
                handleResourceItemRemove(item._id, {resourceItemId})
              }
              onInviteeAdd={(payload) => handleAddInvitee(item._id, payload)}
              onInviteeRemove={(index) => handleRemoveInvitee(item._id, {index, scope})}
              onRemove={() => handleRemoveInviteItem(item._id)}
              onResourceSelect={(resourceId, resourceItemId) =>
                handleSelectResource(item._id, {resourceId, resourceItemId})
              }
              onRoleSelect={(roleName, resourceItemId) =>
                handleRoleSelect(item._id, {roleName, resourceItemId})
              }
              projectsData={projectsData}
              scope={scope}
              organizationId={organizationId}
              organizationRoles={organizationRoles}
              showRemoveButton={transformedInvitations.length > 1}
            />
          )
        })}

        <Button
          fontSize={1}
          icon={AddIcon}
          mode="bleed"
          onClick={handleAddInviteItem}
          text="Add another invitation"
        />
      </Stack>
    </Stack>
  )
}
