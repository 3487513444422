import {
  acceptRequest,
  type AcceptRequestData,
  addRoleToUser,
  createInvite,
  declineRequest,
  type DeclineRequestData,
  getInvites,
  type GetInvitesResponse,
  getRoles,
  type GetRolesResponse,
  getUsers,
  type GetUsersResponse,
  type Invite,
  removeRoleFromUser,
  removeUser,
  type Request,
  type ResourceType,
  revokeInvite,
  type RevokeInviteData,
  type Role,
} from '@sanity/access-api'
import {
  type DefaultError,
  type InfiniteData,
  type QueryKey,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import {
  type InvitationCreateValue,
  type ResourceMembershipAddPayload,
  type ResourceMembershipRemovePayload,
  type ResourceRoleAddPayload,
  type ResourceRoleRemovePayload,
} from '@ui-components'
import {useCallback, useMemo} from 'react'

import {useRequiredOrganizationPermissions} from '@/context/useOrganizationPermissions'
import {type MembersV2} from '@/types/members_v2'

import {useInfiniteResolver} from '../../hooks/useInfiniteResolver'
import {
  invitationRevoke,
  invitationsCreate,
  requestApprove,
  requestRevoke,
  resourceMembershipAdd,
  resourceMembershipRemove,
  resourceRoleAdd,
  resourceRoleRemove,
} from './mutations'
import {getRequests} from './request'
import {useOptimisticUpdate} from './useOptimisticUpdate'

const EMPTY_ARRAY: [] = []

export interface MemberSettingsData {
  /**
   * A list of all invitations in the resource
   */
  invitations: Invite[]
  /**
   * A list of all the members in the organization.
   * Note that this is _not_ a list of just the members in the resource.
   * The list includes all members in the organization.
   */
  members: MembersV2['member'][]
  /**
   * A list of all the requests in the resource
   */
  requests: Request[]
  /**
   * A list of all the roles in the resource
   */
  roles: Role[]
}

interface MembersSettingsHookValue {
  data: MemberSettingsData

  operations: {
    onResourceMembershipAdd: (payload: ResourceMembershipAddPayload) => void
    onResourceMembershipRemove: (payload: ResourceMembershipRemovePayload) => void
    onResourceRoleAdd: (payload: ResourceRoleAddPayload) => void
    onResourceRoleRemove: (payload: ResourceRoleRemovePayload) => void

    onInviteMembers: (invitees: InvitationCreateValue[]) => void
    onInvitationRevoke: (inviteId: string, resourceId: string, resourceType: ResourceType) => void

    onRequestApprove: (
      requestId: string,
      resourceId: string,
      resourceType: ResourceType,
      requestedRole: string
    ) => void
    onRequestRevoke: (requestId: string, resourceId: string, resourceType: ResourceType) => void
  }
  loading: boolean
  error: Error | null
  refetch: () => void
}

interface BaseOptions {
  currentUserId: string
  onMutationError?: (error: Error) => void
  onMutationSuccess?: (message: string) => void
  resourceId: string
}

interface OrgOptions extends BaseOptions {
  resourceType: 'organization'
}

interface ProjectOptions extends BaseOptions {
  organizationId: string | null | undefined
  resourceType: 'project'
}

type MemberSettingsHookOptions = OrgOptions | ProjectOptions

export function useMembersSettings(opts: MemberSettingsHookOptions): MembersSettingsHookValue {
  const {currentUserId, onMutationError, onMutationSuccess, resourceId, resourceType} = opts
  const queryClient = useQueryClient()

  // Check if the user has permission to read organization members.
  // For project resources, we still want to fetch organization members to support
  // features like invite auto-completion with the full member list.
  // If the user has access only to project members, we’ll limit the list to those members.
  // See below where `membersResourceId` defines which resource ID to use for fetching members.
  const canReadOrgMembers = useRequiredOrganizationPermissions([
    {permissionName: 'sanity.organization.members', grantName: 'read'},
  ])

  // Determine the ID to use for fetching members, based on whether the resource is an organization or a project.
  const membersResourceId = useMemo(() => {
    // For projects within an organization, use the organization ID to retrieve the full member list.
    // This requires the `sanity.organization.members.read` permission.
    // If this permission is not available, or if there’s no organization ID associated with the project,
    // the next condition will use the project ID to restrict the list to only the project’s members.
    if (resourceType === 'project' && opts.organizationId && canReadOrgMembers) {
      return opts.organizationId
    }

    // For standalone projects without an organization, use the project ID to limit
    // the members fetch to those directly associated with the project.
    if (resourceType === 'project' && !opts.organizationId) {
      return opts.resourceId
    }

    // For organizations or other cases, use the provided resource ID directly.
    return resourceId
  }, [resourceType, opts, canReadOrgMembers, resourceId])

  // Define the resource type for fetching members.
  // For projects with an organization ID, treat the resource as an organization to fetch the complete member list.
  const membersResourceType = useMemo(() => {
    if (resourceType === 'project' && opts.organizationId && canReadOrgMembers) {
      return 'organization'
    }

    return resourceType
  }, [canReadOrgMembers, opts, resourceType])

  const invitesQueryKey = [resourceType, 'members-v2', 'invites', resourceId]
  const requestsQueryKey = [resourceType, 'members-v2', 'requests', resourceId]
  const organizationMembersQueryKey = [resourceType, 'members-v2', 'members', membersResourceId]

  const organizationMembersResponse = useInfiniteQuery<
    GetUsersResponse,
    DefaultError,
    InfiniteData<GetUsersResponse, string | undefined>,
    QueryKey,
    string | undefined
  >({
    queryKey: organizationMembersQueryKey,
    queryFn: async ({pageParam}) => {
      const response = await getUsers({
        path: {
          resourceType: membersResourceType,
          resourceId: membersResourceId,
        },
        query: {
          limit: 500,
          nextCursor: pageParam,
        },
      })

      return response['data']
    },
    initialPageParam: undefined,
    getNextPageParam: (lastPage) =>
      'nextCursor' in lastPage && typeof lastPage.nextCursor === 'string'
        ? lastPage.nextCursor
        : undefined,
  })

  useInfiniteResolver(organizationMembersResponse)

  const rolesResponse = useInfiniteQuery<
    GetRolesResponse,
    DefaultError,
    InfiniteData<GetRolesResponse, string | undefined>,
    QueryKey,
    string | undefined
  >({
    queryKey: [resourceType, 'members-v2', 'roles', resourceId],
    queryFn: async ({pageParam}) => {
      const response = await getRoles({
        path: {
          resourceType,
          resourceId,
        },
        query: {
          includeChildren: resourceType === 'organization',
          limit: 500,
          nextCursor: pageParam,
        },
      })

      return response['data']
    },
    initialPageParam: undefined,
    getNextPageParam: (lastPage) =>
      'nextCursor' in lastPage && typeof lastPage.nextCursor === 'string'
        ? lastPage.nextCursor
        : undefined,
  })

  useInfiniteResolver(rolesResponse)

  // Check if we have members and roles available before fetching invites and requests.
  // This is because the UI of invites and requests depends on the members and roles data.
  const hasRolesAndMembersData =
    !organizationMembersResponse.isLoading &&
    !rolesResponse.isLoading &&
    !organizationMembersResponse.error &&
    !rolesResponse.error

  const orgInvitesResponse = useInfiniteQuery<
    GetInvitesResponse,
    DefaultError,
    InfiniteData<GetInvitesResponse, string | undefined>,
    QueryKey,
    string | undefined
  >({
    queryKey: invitesQueryKey,
    queryFn: async ({pageParam}) => {
      const response = await getInvites({
        path: {
          resourceType,
          resourceId,
        },
        query: {
          includeChildren: resourceType === 'organization',
          limit: 1,
          nextCursor: pageParam,
        },
      })

      return response['data']
    },
    enabled: hasRolesAndMembersData,
    initialPageParam: undefined,
    getNextPageParam: (lastPage) =>
      'nextCursor' in lastPage && typeof lastPage.nextCursor === 'string'
        ? lastPage.nextCursor
        : undefined,
  })

  useInfiniteResolver(orgInvitesResponse)

  const orgRequestsResponse = useQuery({
    queryKey: requestsQueryKey,
    queryFn: () => getRequests(resourceId, resourceType),
    enabled: hasRolesAndMembersData,
  })

  const handleMutateInvitations =
    useOptimisticUpdate<InfiniteData<GetInvitesResponse>>(invitesQueryKey)
  const handleMutateRequests = useOptimisticUpdate<Request[]>(requestsQueryKey)
  const handleMutateMembers = useOptimisticUpdate<InfiniteData<GetUsersResponse>>(
    organizationMembersQueryKey
  )

  const handleRefetch = useCallback(() => {
    rolesResponse.refetch()
    organizationMembersResponse.refetch()
    orgInvitesResponse.refetch()
    orgRequestsResponse.refetch()
  }, [rolesResponse, organizationMembersResponse, orgInvitesResponse, orgRequestsResponse])

  const handleOnSettled = useCallback(
    (key: string[]) => {
      queryClient.invalidateQueries({queryKey: key})
    },
    [queryClient]
  )

  const resourceMembershipAddMutation = useMutation({
    mutationKey: ['resourceMembershipAddMutation', resourceId],
    mutationFn: async (payload: ResourceMembershipAddPayload) => {
      const {memberId, resourceId, resourceType, roleName} = payload

      return addRoleToUser({
        path: {
          resourceId,
          resourceType,
          sanityUserId: memberId,
          roleName,
        },
      })
    },
    onMutate: async (payload) => {
      const result = await handleMutateMembers((prev) => resourceMembershipAdd(prev, payload))
      return result
    },
    onSuccess: (_, variables) => {
      onMutationSuccess?.(`Member added to ${variables.resourceType}`)
    },
    onError: (_error, _variables, prev) => {
      queryClient.setQueryData(organizationMembersQueryKey, prev)
      const error = new Error('Could not add member')
      onMutationError?.(error)
    },
    onSettled: () => handleOnSettled(organizationMembersQueryKey),
  })

  const resourceMembershipRemoveMutation = useMutation({
    mutationKey: ['resourceMembershipRemoveMutation', resourceId],
    mutationFn: async (payload: ResourceMembershipRemovePayload) => {
      const {memberId, resourceId, resourceType} = payload
      return removeUser({
        path: {
          resourceId,
          resourceType,
          sanityUserId: memberId,
        },
      })
    },
    onMutate: async (payload) => {
      const result = await handleMutateMembers((prev) => resourceMembershipRemove(prev, payload))
      return result
    },
    onSuccess: (_, variables) => {
      onMutationSuccess?.(`Member removed from ${variables.resourceType}`)
    },
    onError: (_error, _variables, prev) => {
      queryClient.setQueryData(organizationMembersQueryKey, prev)
      const error = new Error('Could not remove member')
      onMutationError?.(error)
    },
    onSettled: () => handleOnSettled(organizationMembersQueryKey),
  })

  const resourceRoleAddMutation = useMutation({
    mutationKey: ['resourceRoleAddMutation', resourceId],
    mutationFn: async (payload: ResourceRoleAddPayload) => {
      const {memberId, resourceId, resourceType, roleName} = payload

      return addRoleToUser({
        path: {
          resourceId,
          resourceType,
          sanityUserId: memberId,
          roleName,
        },
      })
    },
    onMutate: async (payload) => {
      const result = await handleMutateMembers((prev) => resourceRoleAdd(prev, payload))
      return result
    },
    onSuccess: () => {
      onMutationSuccess?.('Roles updated')
    },
    onError: (_error, _variables, prev) => {
      queryClient.setQueryData(organizationMembersQueryKey, prev)
      const error = new Error('Could not add role to member')
      onMutationError?.(error)
    },
    onSettled: () => handleOnSettled(organizationMembersQueryKey),
  })

  const resourceRoleRemoveMutation = useMutation({
    mutationKey: ['resourceRoleRemoveMutation', resourceId],
    mutationFn: async (payload: ResourceRoleRemovePayload) => {
      const {memberId, resourceId, resourceType, roleName} = payload

      return removeRoleFromUser({
        path: {
          resourceId,
          resourceType,
          roleName: roleName,
          sanityUserId: memberId,
        },
      })
    },
    onMutate: async (payload) => {
      const result = await handleMutateMembers((prev) => resourceRoleRemove(prev, payload))
      return result
    },
    onSuccess: () => {
      onMutationSuccess?.('Role removed')
    },
    onError: (_error, _variables, prev) => {
      queryClient.setQueryData(organizationMembersQueryKey, prev)
      const error = new Error('Could not remove role from member')
      onMutationError?.(error)
    },
    onSettled: () => handleOnSettled(organizationMembersQueryKey),
  })

  /**
   * Invite mutations
   */
  const inviteMembersMutation = useMutation({
    mutationKey: ['inviteMembers', resourceId],
    mutationFn: async (payload: InvitationCreateValue[]) => {
      // The list of invitees can consist of three types:
      // 1. New invitees who are not a member of the organization or the resource.
      // 2. Existing members with a membership in the resource. For these members, we don't want
      //    to create an invite, but we want to add the role specified in the invite to their existing roles
      //    in the resource.
      // 3. Existing members without a membership in the resource. For these members, we want to
      //    add a membership to the resource with the role specified in the invite.

      // Each invite has a type that determines how it should be handled:
      // - `invite`: Create an invite for a new member.
      // - `role-add`: Add the role specified in the invite to an existing member with a membership in the resource.
      // - `resource-add`: Add a membership to the resource with the role specified in the invite for an existing
      //    member without a membership in the resource.
      const resourceAddInvites = payload.filter((invite) => invite.type === 'resource-add')
      const roleAddInvites = payload.filter((invite) => invite.type === 'role-add')
      const newMembers = payload.filter((invite) => invite.type === 'invite')

      // Add the existing members to the resource with the appropriate role with `projectMemberAddMutation`
      for (const invite of resourceAddInvites) {
        if (invite.invitee.memberId) {
          resourceMembershipAddMutation.mutate({
            memberId: invite.invitee.memberId,
            resourceId: invite.resourceId,
            resourceType: invite.resourceType,
            roleName: invite.roleName,
          })
        }
      }

      // Add the role to the existing members with a membership in the resource with `projectRoleAddMutation`
      for (const invite of roleAddInvites) {
        if (invite.invitee.memberId) {
          if (invite.invitee.memberId) {
            resourceRoleAddMutation.mutate({
              memberId: invite.invitee.memberId,
              resourceId: invite.resourceId,
              resourceType: invite.resourceType,
              roleName: invite.roleName,
            })
          }
        }
      }

      // Create invites for the non-existing members.
      if (newMembers.length > 0) {
        const promises = newMembers.map((invite) => {
          return createInvite({
            path: {
              resourceType: invite.resourceType,
              resourceId: invite.resourceId,
            },
            body: {
              email: invite.invitee.email,
              role: invite.roleName,
            },
          })
        })

        await Promise.all(promises)
      }

      return payload
    },
    onMutate: async (payload) => {
      const result = await handleMutateInvitations((prev) =>
        invitationsCreate(prev, {
          invites: payload,
          currentUserId,
        })
      )

      return result
    },
    onSuccess: (payload) => {
      const newInvites = payload.filter((invite) => invite.type === 'invite')

      // Don't trigger the success message if no new invites were created.
      if (newInvites.length === 0) return

      onMutationSuccess?.(
        `${newInvites.length === 1 ? '1 member' : `${newInvites.length} members`} invited`
      )
    },
    onError: (_error, _variables, prev) => {
      queryClient.setQueryData(invitesQueryKey, prev)
      const error = new Error('Could not invite members')
      onMutationError?.(error)
    },
    onSettled: () => handleOnSettled(invitesQueryKey),
  })

  const invitationRevokeMutation = useMutation({
    mutationKey: ['invitationRevoke', resourceId],
    mutationFn: async (payload: Pick<RevokeInviteData, 'path'>) => {
      const {
        path: {inviteId, resourceId, resourceType},
      } = payload

      return revokeInvite({
        path: {inviteId, resourceId, resourceType},
      })
    },
    onMutate: async (payload) => {
      const result = await handleMutateInvitations((prev) => invitationRevoke(prev, payload))

      return result
    },
    onSuccess: () => {
      onMutationSuccess?.('Invitation revoked')
    },
    onError: (_error, _variables, prev) => {
      queryClient.setQueryData(invitesQueryKey, prev)
      const error = new Error('Could not revoke invitation')
      onMutationError?.(error)
    },
    onSettled: () => handleOnSettled(invitesQueryKey),
  })

  /**
   * Request mutations
   */
  const requestApproveMutation = useMutation({
    mutationKey: ['requestApprove', resourceId],
    mutationFn: async (payload: Pick<AcceptRequestData, 'path' | 'body'>) => {
      return acceptRequest(payload)
    },
    onMutate: async (payload) => {
      const result = await handleMutateRequests((prev) => requestApprove(prev, payload))

      return result
    },
    onSuccess: () => {
      onMutationSuccess?.('Request approved')
    },
    onError: (_error, _variables, prev) => {
      queryClient.setQueryData(requestsQueryKey, prev)
      const error = new Error('Could not approve request')
      onMutationError?.(error)
    },
    onSettled: () => handleOnSettled(requestsQueryKey),
  })

  const requestRevokeMutation = useMutation({
    mutationKey: ['requestRevoke', resourceId],
    mutationFn: async (payload: Pick<DeclineRequestData, 'path'>) => {
      return declineRequest(payload)
    },
    onMutate: async (payload) => {
      const result = await handleMutateRequests((prev) => requestRevoke(prev, payload))

      return result
    },
    onSuccess: () => {
      onMutationSuccess?.('Request denied')
    },
    onError: (_error, _variables, prev) => {
      queryClient.setQueryData(requestsQueryKey, prev)
      const error = new Error('Could not revoke request')
      onMutationError?.(error)
    },
    onSettled: () => handleOnSettled(requestsQueryKey),
  })

  const isLoading =
    organizationMembersResponse.isLoading ||
    rolesResponse.isLoading ||
    orgInvitesResponse.isLoading ||
    orgRequestsResponse.isLoading

  const error =
    organizationMembersResponse.error ||
    rolesResponse.error ||
    orgInvitesResponse.error ||
    orgRequestsResponse.error

  return useMemo(
    (): MembersSettingsHookValue => ({
      data: {
        members:
          organizationMembersResponse.data?.pages.flatMap((datum) =>
            'data' in datum ? datum.data : datum
          ) || EMPTY_ARRAY,
        roles:
          rolesResponse.data?.pages.flatMap((datum) => ('data' in datum ? datum.data : datum)) ||
          EMPTY_ARRAY,
        invitations:
          orgInvitesResponse.data?.pages.flatMap((datum) =>
            'data' in datum ? datum.data : datum
          ) || EMPTY_ARRAY,
        requests: orgRequestsResponse.data || EMPTY_ARRAY,
      },

      // TODO: consider if we want to split this up into separate loading and error states
      loading: isLoading,
      error: error,

      // TODO: consider if we want to split this up into separate refetch functions
      refetch: handleRefetch,

      operations: {
        onResourceMembershipAdd: (payload) => {
          resourceMembershipAddMutation.mutate(payload)
        },

        onResourceMembershipRemove: (payload) => {
          resourceMembershipRemoveMutation.mutate(payload)
        },

        onResourceRoleAdd: (payload) => {
          resourceRoleAddMutation.mutate(payload)
        },

        onResourceRoleRemove: (payload) => {
          resourceRoleRemoveMutation.mutate(payload)
        },

        onInviteMembers: (invitees) => {
          inviteMembersMutation.mutate(invitees)
        },
        onInvitationRevoke: (inviteId, resourceId, resourceType) => {
          invitationRevokeMutation.mutate({
            path: {
              inviteId,
              resourceId,
              resourceType,
            },
          })
        },
        onRequestApprove: (requestId, resourceId, resourceType, roleName) => {
          requestApproveMutation.mutate({
            path: {
              requestId,
              resourceId,
              resourceType,
            },
            body: {
              roleNames: [roleName],
            },
          })
        },
        onRequestRevoke: (requestId, resourceId, resourceType) => {
          requestRevokeMutation.mutate({
            path: {
              requestId,
              resourceId,
              resourceType,
            },
          })
        },
      },
    }),
    [
      error,
      handleRefetch,
      invitationRevokeMutation,
      inviteMembersMutation,
      isLoading,
      organizationMembersResponse.data,
      orgInvitesResponse.data,
      orgRequestsResponse.data,
      requestApproveMutation,
      requestRevokeMutation,
      resourceMembershipAddMutation,
      resourceMembershipRemoveMutation,
      resourceRoleAddMutation,
      resourceRoleRemoveMutation,
      rolesResponse.data,
    ]
  )
}
