import {useMutation, useQueryClient} from '@tanstack/react-query'
import {deleteUserFromOrg} from '.'
import {getOrganizationsKey} from '../organizations/cache'
import {isAPIError} from '../util/promiseRequest'

type DeletionError = Error & {name: 'DeletionError'}

export function useDeleteUserFromOrg() {
  const queryClient = useQueryClient()
  const deleteUserMutation = useMutation({
    mutationFn: ({orgId, userId}: {orgId: string; userId: string}) =>
      deleteUserFromOrg(orgId, userId),
    onError: (error: any) => {
      if (isAPIError(error) && error.statusCode >= 400 && error.statusCode < 500) {
        const deletionError = new Error(error.message) as DeletionError
        deletionError.name = 'DeletionError'
        return deletionError
      }
      return error
    },
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: getOrganizationsKey()}) // Adjust the queryKey as needed
    },
  })

  return {
    deleteUser: deleteUserMutation.mutate,
    isDeleting: deleteUserMutation.isPending,
    isDeleted: deleteUserMutation.isSuccess,
    error: deleteUserMutation.error as DeletionError | null,
  }
}
