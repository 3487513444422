import {CurrentUser} from '@/types/models'
import posthog from 'posthog-js'
import {analyticsClient} from '@sanity/frontend-analytics'

/* eslint-disable no-process-env */
const isProd = process.env.NEXT_PUBLIC_SANITY_INTERNAL_ENV === 'production'

export type ProductAnnouncementsEvents =
  | 'Product Announcement Card Seen'
  | 'Product Announcement Link Clicked'
  | 'Product Announcement Viewed'
  | 'Whats New Help Menu Item Clicked'
  | 'Product Announcement Dismissed'
  | 'Product Announcement Card Clicked'
  | 'Product Announcement Card Dismissed'

export type TrialDialogTrackingActions =
  | 'Trial Dialog Viewed'
  | 'Trial Dialog Dismissed'
  | 'Trial Dialog CTA Clicked'
  | 'Upgrade Request Sent'
  | 'Request Upgrade Dialog Viewed'

export type TabNavigationEvents =
  | 'Project Overview Page Viewed'
  | 'Project Members Page Viewed'
  | 'Project Datasets Page Viewed'
  | 'Project Access Page Viewed'
  | 'Project Usage Page Viewed'
  | 'Project Plan Page Viewed'
  | 'Project API Page Viewed'
  | 'Project Settings Page Viewed'
  | 'Request Page Viewed'

export type PlanTabEvents =
  | 'Switch Plan CTA Clicked'
  | 'Switch Plan Step Completed'
  | 'Add Billing Details CTA Clicked'
  | 'Add-On CTA Clicked'
  | 'Add-On Enabled'
  | 'Add-On Disabled'
  | 'Change Plan Flow Initiated'
  | 'Change Plan Flow Completed'
  | 'Plan Downgraded'
  | 'Plan Conflict Detected'
  | 'Plan Conflict Resolved'
  | 'Upgrade Blocked by Billing Permissions'

export type OnboardingXpEvents =
  | 'Getting Started Page Viewed'
  | 'Getting Started Page Dismissed'
  | 'Getting Started Path Switched'
  | 'Getting Started Video Played'
  | 'Getting Started Code Block Copied'
  | 'Getting Started Framework Switched'
  | 'Getting Started Section Completed'
  | 'Getting Started Course Completed'
  | 'Getting Started Resource Clicked'
  | 'Getting Started Developer Invited'
  | 'Getting Started Course Feedback Clicked'
  | 'Getting Started Course Feedback Submitted'

export type allowedActions =
  | ProductAnnouncementsEvents
  | TabNavigationEvents
  | PlanTabEvents
  | 'ClickUpgradeBanner'
  | 'AppliedSharedWebhook'
  | 'ConfigureSaml'
  | 'ArrearsBanner-View'
  | 'ArrearsBanner-Click-CTA'
  | 'ArrearsStatusCard-View'
  | 'ArrearsStatusCard-Click-ContactBilling'
  | 'ArrearsStatusCard-Click-UpdatePaymentCard'
  | 'ArrearsStatusCard-Click-PayInvoices'
  | 'ArrearsStatusCard-Click-Downgrade'
  | 'ArrearsStatusCard-Click-RestartSubscription'
  | 'IncompletePaymentBanner-View'
  | 'IncompletePaymentBanner-Click-CTA'
  | TrialDialogTrackingActions
  | OnboardingXpEvents
  | 'CLI Snippet Copied'
  | 'Coupon Validated'
  | 'Sanity Create Project Switcher CTA Clicked'
  | 'Sanity Create Navbar CTA Clicked'

// defined in vercel settings page
export const trackingConfig = {
  writeKey: process.env.NEXT_PUBLIC_RUDDERSTACK_WRITE_KEY as string,
  dataPlaneUrl: isProd
    ? `https://www.sanity.io/intake/dp`
    : 'https://sanity-dataplane.rudderstack.com',
  configUrl: isProd
    ? 'https://www.sanity.io/intake/api/sourceConfig'
    : 'https://api.rudderstack.com',
  cookieDomain: `www.${process.env.host}`,
  applicationName: 'manage',
}

export async function sendPageLoadEvent(user: CurrentUser): Promise<void> {
  analyticsClient.identify(user.id)

  analyticsClient.page('manage')
  posthog.capture('$pageview')
  if (window?.ko) {
    window?.ko?.identify?.(user.email, {
      name: user.name,
      user_id: user.id,
    })
  }
}

export function sendTrackingEvent(
  action: allowedActions,
  projectId: string | undefined = undefined,
  organizationId: string | undefined = undefined,
  extraAttrs = {},
  amplitude = false
): void {
  let source = ''
  if (window) {
    // If we have a `ref` search param, use that as the source attribute
    source = new URL(window.location.href).searchParams.get('ref') || source
  }
  analyticsClient.track(
    action,
    {
      projectId,
      organizationId,
      source,
      ...extraAttrs,
    },
    {},
    amplitude
  )
}

export function sendAmplitudeTrackingEvent(
  action: allowedActions,
  projectId: string | undefined = undefined,
  organizationId: string | undefined = undefined,
  extraAttrs = {}
): void {
  sendTrackingEvent(
    action,
    projectId,
    organizationId,
    {
      ...extraAttrs,
    },
    true
  )
}

export function sendExperimentTrackingEvent({
  projectId,
  organizationId,
  experimentId,
  variationId,
  attrs,
}: {
  projectId?: string
  organizationId?: string
  experimentId: string
  variationId: string | number
  attrs?: Record<string, unknown>
}): void {
  analyticsClient.track('experiment', {
    projectId,
    organizationId,
    experimentId,
    variationId,
    ...attrs,
  })
}

declare global {
  interface Window {
    ko?: {
      identify?: (data: unknown, accountData?: unknown) => void
      track?: (data: unknown, accountData?: unknown) => void
    }
  }
}
