import React from 'react'

import {Prompt} from '@/ui/index'
import {AppLink} from '@/ui/app'
import {useRoutePath} from '@/context/index'

export function APIMovedPrompt() {
  const {basePath} = useRoutePath()
  return (
    <Prompt
      icon="info-outline"
      title="API settings are now in their own tab"
      description={
        <>
          Webhooks, CORS Origins and Token Management have moved to the{' '}
          <AppLink href={`${basePath}/api`} style={{display: 'inline'}}>
            API tab
          </AppLink>
          .
        </>
      }
    />
  )
}
