import styled from 'styled-components'
import {motion, useAnimation} from 'framer-motion'
import {useEffect} from 'react'

const StyledSvg = styled.svg`
  transform: rotate(-90deg);
`

interface Props {
  percentage: number
}

const CircularProgressBar = ({percentage}: Props) => {
  const controls = useAnimation()

  useEffect(() => {
    controls.start({
      strokeDashoffset: 100 - percentage,
      transition: {duration: 1, ease: 'easeInOut'},
    })
  }, [percentage, controls])

  return (
    <StyledSvg width="24" height="24" viewBox="0 0 24 24">
      <circle
        cx="12"
        cy="12"
        r="10"
        fill="none"
        stroke="var(--card-border-color)"
        strokeWidth="3"
      />
      <motion.circle
        cx="12"
        cy="12"
        r="10"
        fill="none"
        stroke="var(--card-badge-positive-icon-color)"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
        pathLength="100"
        initial={{strokeDashoffset: 100}}
        animate={controls}
        style={{strokeDasharray: 100}}
      />
    </StyledSvg>
  )
}

export default CircularProgressBar
