import {useCurrentScopeContext} from '@/context/useCurrentScopeContext'
import {sendAmplitudeTrackingEvent} from '@/utils/tracking'
import {Badge, BadgeTone, Card, CardTone, Flex, Inline, Text} from '@sanity/ui'
import styled from 'styled-components'

type Props = {
  borderTone?: CardTone
  badgeTone: BadgeTone
  badgeText: string
  heading: string
  children: React.ReactNode
  link?: string
}

const StyledLink = styled.a`
  height: 100%;
  :focus-visible {
    outline: -webkit-focus-ring-color auto 1px;
  }
`

const OuterCard = styled(Card)<{$link?: boolean}>`
  height: 100%;
  ${(props) =>
    props.$link &&
    `
    :hover {
      background: var(--card-muted-bg-color);
    }
  `}
`

const InnerCard = styled(Card)`
  height: 100%;
`

const OptionalLink = ({
  link,
  children,
  ...rest
}: {link?: string; children: React.ReactNode} & React.AnchorHTMLAttributes<HTMLAnchorElement>) => {
  const {projectId, org} = useCurrentScopeContext()
  const trackClick = () => {
    sendAmplitudeTrackingEvent('Getting Started Resource Clicked', projectId, org?.id, {
      resource_url: link,
      resource_type: 'non-developer',
    })
  }
  if (!link) return <>{children}</>
  return (
    <StyledLink href={link} {...rest} onClick={trackClick}>
      {children}
    </StyledLink>
  )
}

export const NextStepsCard = ({
  borderTone,
  badgeTone,
  badgeText,
  heading,
  children,
  link,
}: Props) => {
  return (
    <OptionalLink link={link} target="_blank">
      <OuterCard border={true} radius={4} tone={borderTone || 'default'} $link={!!link}>
        <InnerCard padding={4} radius={4}>
          <Flex gap={4} direction="column" height="fill">
            <Inline space={1}>
              <Badge tone={badgeTone}>{badgeText}</Badge>
            </Inline>
            <Text size={2} weight="bold">
              {heading}
            </Text>
            {children}
          </Flex>
        </InnerCard>
      </OuterCard>
    </OptionalLink>
  )
}
