import {Studio} from '../../types/models'
import {deleteUserApplication, getStudios, updateExternalStudioHost} from './controller'
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query'
import {getProjectStudiosKey} from '../projects/cache'

export function useStudios(projectId: string | undefined, options?: {enabled?: boolean}) {
  const queryClient = useQueryClient()
  const queryKey = getProjectStudiosKey(projectId)

  const query = useQuery<Studio[], Error>({
    queryKey,
    queryFn: () => getStudios(projectId),
    enabled: Boolean(projectId) && (options?.enabled ?? true),
  })

  const postUserApplicationMutation = useMutation({
    mutationKey: ['updateExternalStudioHost', projectId],
    mutationFn: (data: {urlType: 'external'; appHost: string}) =>
      updateExternalStudioHost(data, projectId),
    onSettled: () => {
      queryClient.invalidateQueries({queryKey})
    },
  })

  const deleteUserApplicationMutation = useMutation({
    mutationKey: ['deleteExternalStudioHost', projectId],
    mutationFn: (id: string) => deleteUserApplication(id, projectId),
    onSettled: () => {
      queryClient.invalidateQueries({queryKey})
    },
  })

  return {
    ...query,
    postUserApplicationMutation,
    deleteUserApplicationMutation,
  }
}
