import {useQuery} from '@tanstack/react-query'
import {getDefaultPlans} from './controller'
import {timeToMilliseconds} from '@/utils/time'
import {getDefaultPlansKey} from './cache'

const CACHE_TTL = timeToMilliseconds(1, 'days')

export function useDefaultPlans() {
  return useQuery({
    queryKey: getDefaultPlansKey(),
    queryFn: () => getDefaultPlans(),
    staleTime: CACHE_TTL,
    gcTime: Infinity,
  })
}
