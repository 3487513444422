import React, {forwardRef} from 'react'
import {Box, TooltipProps} from '@sanity/ui'
import {IconSymbol} from '@sanity/icons'
import {InfoTooltip} from '.'

type Props = {
  title?: string | React.ReactNode
  action?: string | React.ReactNode
  icon?: IconSymbol | boolean
  customDescription?: string
} & TooltipProps

export const PermissionTooltip = forwardRef<HTMLDivElement, Props>(
  (
    {
      icon = 'error-outline',
      title = 'Insufficient permissions',
      action,
      children,
      disabled,
      customDescription,
    },
    ref
  ) => {
    const getDescription = () => {
      if (customDescription) return customDescription
      return `Your member role ${action || 'cannot perform this action'}`
    }

    return (
      <InfoTooltip
        portal
        disabled={disabled}
        title={title}
        icon={icon}
        description={getDescription()}
      >
        <Box ref={ref} style={disabled ? {} : {cursor: 'not-allowed'}}>
          {children}
        </Box>
      </InfoTooltip>
    )
  }
)
