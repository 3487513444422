/* eslint-disable react/jsx-no-bind */
import React from 'react'

import {GROQWebhooksDetails} from './groqWebhooks'
import {LegacyWebhooksDetails} from './legacyWebhooks'
import {Project} from '@/types/models'

export function WebhooksDetails({project, description}: {project: Project; description: string}) {
  return (
    <>
      <GROQWebhooksDetails
        project={project}
        title="GROQ-powered webhooks"
        description="HTTP callbacks to a given URL triggered by changes in your content lake"
      />
      <LegacyWebhooksDetails
        project={project}
        title="Legacy webhooks"
        description={description}
        hideOnEmpty
      />
    </>
  )
}
