import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Box, Text, Stack, Card, useToast} from '@sanity/ui'
import {useOrganizationData} from '../../../../data'
import {Organization} from '@/types/index'
import {EditablePreview, EditContainer, EditContainerProps, FormField} from '@/ui/index'
import {useRequiredOrganizationPermissions} from '@/context/index'
import {
  useApplyDefaultRoleToAllUsers,
  useOrganizationRoles,
} from '@/data/organizations/useOrganizationRoleList'
import {RoleSelectMenuButton} from '@/ui/ui-components/features/role-select-menu-button'
import {type Role} from '@sanity/access-api'
import {SelectIcon} from '@sanity/icons'
import {getRoleSelectButtonText} from '@/ui/ui-components/utils'
import {get, find} from 'lodash'
import {Button} from '@/ui/ui-components/primitives/button/Button'

export function OrgDefaultRoleSetting({
  org,
  title,
  description,
}: {
  org: Organization
  title: string
  description: string
}) {
  const toast = useToast()
  const [showEdit, setShowEdit] = useState(false)
  const [newRole, setNewRole] = useState<Role | null>(null)
  const {
    data: orgData,
    isPending,
    isLoading: isLoadingOrg,
    patchOrganizationData,
  } = useOrganizationData(org)
  const {data: roles, isLoading} = useOrganizationRoles(org.id)
  const {mutate: applyDefaultRoleToAllUsers, isPending: isApplyingDefaultRoleToAllUsers} =
    useApplyDefaultRoleToAllUsers(org.id)
  const defaultRoleTitle = useMemo(() => {
    return orgData?.defaultRoleName
      ? get(find(roles, ['name', orgData.defaultRoleName]), 'title')
      : null
  }, [orgData?.defaultRoleName, roles])
  const organizationRoles = useMemo(() => roles?.filter(Boolean) ?? [], [roles])
  const [additionalButtons, setAdditionalButtons] = useState<
    EditContainerProps['additionalButtons']
  >([])

  // Update the new role when the default role changes
  useEffect(() => {
    if (
      orgData &&
      !isLoadingOrg &&
      !isLoading &&
      // Only update the selected role when the default role changes on the org
      // and we are not in edit mode
      (newRole === null || (orgData.defaultRoleName === null && newRole !== null && !showEdit))
    ) {
      setNewRole(roles?.find((role) => role.name === orgData.defaultRoleName) ?? null)
    }
  }, [orgData, isLoadingOrg, isLoading, roles, newRole, showEdit])

  const canManage = useRequiredOrganizationPermissions([
    {permissionName: 'sanity.organization', grantName: 'update'},
  ])
  const handleRoleChange = useCallback((newRole: Role) => {
    setNewRole(newRole)
  }, [])

  const handleApplyDefaultRoleToAllUsers = useCallback(() => {
    applyDefaultRoleToAllUsers(undefined, {
      onSuccess: () => {
        toast.push({
          status: 'success',
          title: 'Default role assigned to all users',
        })
      },
      onError: (err) => {
        toast.push({
          status: 'error',
          title: 'Failed to assign default role to all users',
          description: err.message,
        })
      },
    })
  }, [applyDefaultRoleToAllUsers, toast])

  const existingRole = useMemo(() => {
    return newRole ? [newRole] : []
  }, [newRole])

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      if (newRole) {
        setShowEdit(false)
        patchOrganizationData(
          {defaultRoleName: newRole.name},
          {
            onSuccess: () => {
              toast.push({
                status: 'success',
                title: 'Default organization role updated',
              })
            },
            onError: (err) => {
              toast.push({
                status: 'error',
                title: 'Failed to update default organization role',
                description: err.message,
              })
              setShowEdit(true)
            },
          }
        )
      } else {
        setShowEdit(false)
        patchOrganizationData(
          {defaultRoleName: undefined},
          {
            onSuccess: () => {
              toast.push({
                status: 'success',
                title: 'Default organization role removed',
              })
            },
            onError: (err) => {
              toast.push({
                status: 'error',
                title: 'Failed to remove default organization role',
                description: err.message,
              })
              setShowEdit(true)
            },
          }
        )
      }
    },
    [newRole, patchOrganizationData, toast, setShowEdit]
  )

  const handleCancel = useCallback(() => {
    setShowEdit(false)
  }, [])

  const handleRemoveDefaultRole = useCallback(() => {
    setShowEdit(false)
    patchOrganizationData(
      {defaultRoleName: null},
      {
        onSuccess: () => {
          toast.push({
            status: 'success',
            title: 'Default organization role removed',
          })
        },
        onError: (err) => {
          toast.push({
            status: 'error',
            title: 'Failed to remove default organization role',
            description: err.message,
          })
          setShowEdit(true)
        },
      }
    )
  }, [patchOrganizationData, setShowEdit, toast])

  const organizationRoleSelectButton = useMemo(() => {
    const text = getRoleSelectButtonText(organizationRoles, existingRole, 'Select default role')

    return (
      <Button
        fontSize={1}
        justify={'space-between'}
        mode={'ghost'}
        padding={undefined}
        text={text}
        iconRight={SelectIcon}
      />
    )
  }, [existingRole, organizationRoles])

  // Only update the additional buttons after the edit container is closed
  useEffect(() => {
    if (showEdit) {
      return
    }

    // Remove the additional buttons if the default role is removed
    if (!orgData?.defaultRoleName && (additionalButtons?.length ?? 0) > 0) {
      setAdditionalButtons([])

      return
    }

    // Add the additional buttons if the default role is set
    if (orgData?.defaultRoleName && additionalButtons?.length === 0) {
      setAdditionalButtons([{text: 'Remove default role', onClick: handleRemoveDefaultRole}])
    }
  }, [isPending, showEdit, orgData?.defaultRoleName, handleRemoveDefaultRole, additionalButtons])

  return (
    <>
      <EditablePreview
        onEdit={() => setShowEdit(true)}
        title={title}
        description={description}
        isEditing={showEdit}
        hasData={!!defaultRoleTitle}
        addButtonText="Set default organization role"
        canManage={canManage}
        noPermissionText="Your member role does not have access to organization settings"
      >
        <>
          {!showEdit && (
            <Stack space={4} data-recording-mask>
              {defaultRoleTitle && <Text weight="semibold">{defaultRoleTitle}</Text>}
            </Stack>
          )}
          {showEdit && (
            <Card style={{maxWidth: '320px'}}>
              <EditContainer
                onSave={handleSubmit}
                onCancel={handleCancel}
                loading={isPending}
                additionalButtons={additionalButtons}
              >
                <FormField>
                  <RoleSelectMenuButton
                    onSelect={handleRoleChange}
                    options={organizationRoles}
                    selectedRoles={existingRole}
                    closeOnSelect
                    popoverButtonProps={{
                      button: organizationRoleSelectButton,
                    }}
                  />
                </FormField>
              </EditContainer>
            </Card>
          )}
        </>
      </EditablePreview>
      {defaultRoleTitle && (
        <Card tone="inherit">
          <Stack space={4}>
            <Box>
              <Button
                onClick={handleApplyDefaultRoleToAllUsers}
                loading={isApplyingDefaultRoleToAllUsers}
                mode="ghost"
                fontSize={1}
                padding={3}
                text={`Assign ${defaultRoleTitle} role to all users`}
              />
            </Box>
          </Stack>
        </Card>
      )}
    </>
  )
}
