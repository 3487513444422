import React, {useCallback, useEffect} from 'react'
import {Button, Stack, Container, Flex, Text, Box} from '@sanity/ui'
import {ArrowTopRightIcon, CogIcon} from '@sanity/icons'
import Link from 'next/link'
import {useRouter} from 'next/router'
import {ChangePlanWizard} from '../../wizards/changePlan'
import {AddBillingWizard} from '../../wizards/addBilling'
import {
  Prompt,
  PlanOptions,
  PlanBlock,
  useWizardStore,
  PermissionButtonProject,
} from '@/components/index'
import {useCanManageBilling, useCurrentScopeContext} from '@/context/index'
import {Plan} from '@/types/index'
import {useProjectSubscription} from '@/data/projects/useProjectSubscription'
import {useDefaultPlans} from '@/data/plan'
import {sendAmplitudeTrackingEvent} from '@/utils/tracking'
import {PlanAddOns} from '@/components/project/plan/planAddOns'
import {useOrganizationCanPay} from '@/data/organizations'
import {SINGLE_PAGE_CHECKOUT_FEATURE, useSwitchPlanStore} from '@/components/checkout'
import {AnimatePresence, motion} from 'framer-motion'
import {useFeatureFlag} from '@/utils/tracking/growthBook'
import {usePosthog} from '@/utils/tracking/posthog'
import posthog from 'posthog-js'

const SectionHeader = ({title, description}: {title: string; description?: string}) => (
  <Stack paddingLeft={3} space={2}>
    <Text size={1} muted weight={'semibold'}>
      {title.toUpperCase()}
    </Text>
    {description && (
      <Text muted size={1}>
        {description}
      </Text>
    )}
  </Stack>
)

export function PlanProject() {
  const {project, org, orgs = [], loading} = useCurrentScopeContext()
  const {data: defaultPlans, isLoading: plansLoading} = useDefaultPlans()
  const {dispatch: dispatchWizard, state: wizardState} = useWizardStore()
  const {dispatch: dispatchCheckout} = useSwitchPlanStore()
  const {data: subscription, refetch: refetchSubscription} = useProjectSubscription(project?.id)
  const {data: canPay} = useOrganizationCanPay(project?.organizationId)
  const canManageBilling = useCanManageBilling()
  const {query} = useRouter()
  const {init: initPosthog} = usePosthog()

  /**
   * Growth team is experimenting with a new single page checkout flow
   * @see /components/ui/checkout/README.md
   */
  const [experimentSinglePageCheckout, featuresLoaded] = useFeatureFlag(
    SINGLE_PAGE_CHECKOUT_FEATURE
  )

  const onPlanChangeComplete = useCallback(() => {
    return refetchSubscription()
  }, [refetchSubscription])

  useEffect(() => {
    if (project?.id) {
      sendAmplitudeTrackingEvent('Project Plan Page Viewed', project.id)
    }
  }, [project?.id])

  const customPlans = ['enterprise', 'internal', 'test']
  const orgType = org?.type

  const currentPlan = subscription?.plan
  const growthPlan = defaultPlans?.find((plan) => plan.planTypeId === 'growth')

  const isFreePlan: boolean = currentPlan?.planTypeId === 'free'
  const isOrgPlan: boolean = orgType === 'org-plan'
  const isEnterprise: boolean = currentPlan?.planTypeId === 'enterprise'
  const isTrial: boolean = currentPlan?.planTypeId === 'growth-trial'
  const isStartup: boolean = currentPlan?.id.includes('growth-startup') ?? false
  const isPOC: boolean = currentPlan?.planTypeId === 'test'
  const isNonProfit: boolean = currentPlan?.planTypeId === 'non-profit'
  const isGrowth: boolean = currentPlan?.planTypeId === 'growth'
  const isLegacyPlan =
    currentPlan &&
    (currentPlan.name.toLowerCase().includes('legacy') ||
      ['business', 'team'].includes(currentPlan.planTypeId))

  const hasOveragesAllowed =
    currentPlan &&
    (Object.values(currentPlan.resources).filter((x) => x.overageAllowed).length || isNonProfit)

  const canUpgrade: boolean = !isEnterprise && !isOrgPlan
  // Hide change plan / available plans section for custom plans
  const hideChangePlan = !!(currentPlan?.planTypeId && customPlans.includes(currentPlan.planTypeId))
  // Show billing details callout if no payment method setup and the plan allows overages
  const showBillingDetailsBanner =
    isOrgPlan && canPay?.status === false && hasOveragesAllowed && !isEnterprise
  // Only show current plan banner for legacy plans, enterprise, and active trials
  const showCurrentPlan =
    isLegacyPlan || isEnterprise || isTrial || isNonProfit || isPOC || isStartup
  // Only show add-ons section for non-enterprise and equivalent plans
  const showAddOnsSection = !(isEnterprise || isPOC)

  const handleOpenChangePlanWizardOrNewCheckoutFlow = useCallback(
    (targetPlan?: Plan) => {
      if (
        (experimentSinglePageCheckout && // Experiment is enabled
          (isFreePlan || isTrial) && // Current plan is free or trial
          targetPlan?.planTypeId === 'growth') || // Target plan is growth
        targetPlan?.planTypeId === 'free' // Target plan is free
      ) {
        dispatchCheckout({type: 'switchPlan/open'})
      } else {
        // Open the normal change plan wizard
        dispatchWizard({type: 'changePlanWizard/open'})
      }
    },
    [dispatchWizard, dispatchCheckout, experimentSinglePageCheckout, currentPlan]
  )

  const handleSelectPlan = useCallback(
    (plan: Plan) => {
      dispatchWizard({type: 'setTargetPlan', value: plan})
      dispatchCheckout({type: 'setTargetPlan', value: plan})
      handleOpenChangePlanWizardOrNewCheckoutFlow(plan)
    },
    [dispatchWizard, handleOpenChangePlanWizardOrNewCheckoutFlow]
  )

  const handleOpenAddBillingDetailsWizard = useCallback(() => {
    sendAmplitudeTrackingEvent('Add Billing Details CTA Clicked', project?.id, org?.id)
    dispatchWizard({type: 'addBillingWizard/open'})
  }, [dispatchWizard, project?.id, org?.id])

  const handleUseCoupon = useCallback(() => {
    dispatchWizard({type: 'setUseCoupon', value: true})
    handleOpenChangePlanWizardOrNewCheckoutFlow()
  }, [dispatchWizard, handleOpenChangePlanWizardOrNewCheckoutFlow])

  const handleUpsell = useCallback(() => {
    if (growthPlan) handleSelectPlan(growthPlan)
  }, [growthPlan, handleSelectPlan])

  useEffect(() => {
    // Automatically open self-serve
    // modal with a query parameter
    if (!query.checkout || !currentPlan || !featuresLoaded) return
    if (!isEnterprise && currentPlan.planTypeId !== 'growth') handleUpsell()
  }, [query, handleUpsell, isEnterprise, currentPlan, growthPlan, featuresLoaded])

  useEffect(() => {
    const incomplete = subscription?.status === 'incomplete'
    dispatchWizard({type: 'setSubscriptionIncomplete', value: incomplete})
    if (incomplete) {
      dispatchWizard({type: 'setTargetPlan', value: currentPlan})
      handleOpenChangePlanWizardOrNewCheckoutFlow()
    }
  }, [subscription, dispatchWizard, handleOpenChangePlanWizardOrNewCheckoutFlow, currentPlan])

  useEffect(() => {
    initPosthog()
    return () => {
      posthog.stopSessionRecording()
    }
  }, [])

  if (!subscription || !defaultPlans || !project) {
    return null
  }

  const AddOns = ({upsell = false}: {upsell?: boolean}) => (
    <>
      {currentPlan && (
        <Stack space={4} id="add-ons">
          <SectionHeader
            title="add-ons"
            description="Extend your Growth plan with enterprise-grade add-ons."
          />
          <PlanAddOns
            subscription={subscription}
            loading={plansLoading}
            onUpdate={onPlanChangeComplete}
            upsell={upsell}
            onUpsell={handleUpsell}
          />
        </Stack>
      )}
    </>
  )

  return (
    <Container style={{maxWidth: 850}}>
      <Stack space={4}>
        {isOrgPlan ? (
          <Prompt
            cardProps={{padding: 4}}
            tone="primary"
            layout="column"
            title="This project is a part of an organization plan"
            description="Quotas are aggregated on an organization level. To view plan details, visit the organization's settings page."
            button={
              <Link href={`/organizations/${org?.id}/settings#plan`} passHref legacyBehavior>
                <Button text="View organization settings" tone="primary" icon={CogIcon} as="a" />
              </Link>
            }
          />
        ) : (
          <Stack space={5}>
            <AnimatePresence initial={false}>
              {currentPlan && showCurrentPlan && (
                <motion.div
                  initial={{opacity: 0, height: 0}}
                  animate={{opacity: 1, height: 'auto'}}
                  exit={{opacity: 0, height: 0}}
                  transition={{duration: 0.2}}
                >
                  <Stack space={4}>
                    <SectionHeader title="current plan" />
                    <PlanBlock
                      plan={currentPlan}
                      defaultPlans={defaultPlans}
                      subscription={subscription}
                      onSelect={handleSelectPlan}
                      showUpgradeButton={isTrial}
                      upgradeButtonOnClick={handleUpsell}
                    />
                  </Stack>
                </motion.div>
              )}
            </AnimatePresence>
            {(isEnterprise || isPOC) && (
              <Stack space={4}>
                <SectionHeader title="change plan" />
                <Prompt
                  cardProps={{padding: 3}}
                  layout="column"
                  title="Contact us to make changes to the contract and get more information about plans."
                  button={
                    <Button
                      as="a"
                      href={`https://www.${process.env.host}/contact/sales?ref=manage-enterprise-change-plan`}
                      rel="noopener"
                      target="_blank"
                      text="Contact sales"
                      tone="primary"
                    />
                  }
                />
              </Stack>
            )}
            {showBillingDetailsBanner && (
              <Prompt
                icon="credit-card"
                description="Add billing details to pay-as-you go and avoid being blocked for exceeding your included quota"
                button={
                  <PermissionButtonProject
                    title="Add billing details"
                    hasPermission={canManageBilling}
                    onClick={handleOpenAddBillingDetailsWizard}
                  />
                }
              />
            )}
            {!hideChangePlan && (
              <Stack space={4}>
                <SectionHeader title="available plans" />
                {currentPlan && (
                  <PlanOptions
                    project={project}
                    onSelect={handleSelectPlan}
                    planOptions={defaultPlans || []}
                    currentPlan={currentPlan}
                    loading={plansLoading}
                  />
                )}
                <Flex marginTop={2} justify={'center'}>
                  <Text>
                    <a
                      href="https://www.sanity.io/pricing#compare-plans"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Flex justify={'center'}>
                        See full plan comparison
                        <Box marginLeft={2}>
                          <ArrowTopRightIcon />
                        </Box>
                      </Flex>
                    </a>
                  </Text>
                </Flex>
              </Stack>
            )}
            {showAddOnsSection && <AddOns upsell={!isEnterprise && !isGrowth} />}
          </Stack>
        )}
        <Flex justify="center" marginTop={2}>
          {showAddOnsSection && (
            <>
              <Text>
                <a onClick={handleUseCoupon}>Apply coupon</a>
              </Text>
            </>
          )}
        </Flex>
      </Stack>

      {canUpgrade && wizardState.changePlanWizard.open && (
        <ChangePlanWizard
          params={{
            org,
            orgs,
            plans: defaultPlans,
            project,
            loading,
          }}
          onComplete={onPlanChangeComplete}
        />
      )}
      {canUpgrade && wizardState.addBillingWizard.open && (
        <AddBillingWizard
          params={{
            org,
            orgs,
            plans: defaultPlans,
            project,
            loading,
          }}
        />
      )}
    </Container>
  )
}
