import React, {useCallback, useState} from 'react'
import {Button, TextInput, Flex, useToast} from '@sanity/ui'
import {useRouter} from 'next/router'
import {useDeleteOrg} from '../../../../data'
import {Prompt, TabSectionHeader, PermissionButtonOrg, ConfirmDialog, FormField} from '@/ui/index'
import {Organization} from '@/types/index'
import {useQueryClient} from '@tanstack/react-query'
import {getOrganizationsKey} from '@/data/organizations/cache'

export function DeleteOrg({
  title,
  description,
  org,
}: {
  title: string
  description: string
  org: Organization
}) {
  const queryClient = useQueryClient()
  const [confirmString, setConfirmString] = useState<string>('')
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const [displayError, setDisplayError] = useState<boolean>(false)
  const {deleteOrganization, isPending, error} = useDeleteOrg(org.id)
  const router = useRouter()
  const {name} = org
  const toast = useToast()

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmString(e.target.value)
  }, [])

  const confirm = useCallback(() => {
    if (confirmString === name) {
      deleteOrganization(undefined, {
        onSuccess: () => {
          setDisplayError(false)
          setConfirmString('')
          toast.push({title: 'Organization deleted', status: 'success'})
          router.push('/manage')

          // Allow the redirect to go through before invalidating the cache
          setTimeout(() => {
            queryClient.invalidateQueries({queryKey: getOrganizationsKey()})
          }, 4000)
        },
        onError: () => {
          setDisplayError(true)
        },
      })
    }
  }, [confirmString, name, deleteOrganization, toast, router, queryClient])

  const closeDialog = useCallback(() => {
    setDialogOpen(false)
  }, [])

  const openDialog = useCallback(() => {
    setDisplayError(false)
    setDialogOpen(true)
  }, [])

  return (
    <>
      <TabSectionHeader title={title} description={description} />
      <Flex>
        <PermissionButtonOrg
          permissions={[{permissionName: 'sanity.organization', grantName: 'delete'}]}
          title="Delete organization"
          tone="critical"
          mode="ghost"
          onClick={openDialog}
        />
      </Flex>
      {dialogOpen && (
        <ConfirmDialog
          header="Delete organization"
          title={`Do you really want to delete “${name}”?`}
          description="The organization will be permanently deleted. This action is irrevocable and cannot be undone."
          id="delete-org-dialog"
          onClose={closeDialog}
          confirmButton={
            <Button
              disabled={confirmString !== name}
              text="Delete"
              tone="critical"
              onClick={confirm}
              loading={isPending}
            />
          }
        >
          {!displayError && (
            <FormField label="Type in the name of the organization to confirm" description={name}>
              <TextInput padding={3} onChange={handleChange} />
            </FormField>
          )}
          {error && displayError && (
            <Prompt tone="critical" icon="error-outline" description={error.message} />
          )}
        </ConfirmDialog>
      )}
    </>
  )
}
