import {Badge, Box, Button, Card, Container, Layer, Inline as UIInline} from '@sanity/ui'
import {useRouter} from 'next/router'
import {useEffect, useMemo, useRef} from 'react'
import styled from 'styled-components'
import {PermissionTooltip} from '..'
import {AppLink, useNavbarHeight} from '../../app'
import {useMatchesMedia} from '@/utils/views'
import {useArrearsBannerHeight} from '@/components/arrears/useArrearsBannerHeight'
import {Tab} from '@/types/index'
import {
  useCurrentScopeContext,
  useRequiredOrganizationPermissions,
  useRequiredProjectPermissions,
  useRoutePath,
  useTabAccess,
} from '@/context/index'

const TabsContainer = styled.div`
  white-space: nowrap;
  overflow: auto;
  overflow: -moz-scrollbars-none;

  &::-webkit-scrollbar {
    width: 0;
    display: none;
  }
`

interface TabsProps {
  currentTab?: Tab
  tabs: Tab[]
}

const TabButton = ({tab, href, isActive}: {tab: Tab; href: string; isActive: boolean}) => {
  const activeTabRef = useRef<HTMLButtonElement>(null)
  const isMobile = useMatchesMedia(1)
  const {query} = useRouter()

  const hasOrgPermission = useRequiredOrganizationPermissions(tab?.permissions)
  const hasProjectPermission = useRequiredProjectPermissions(tab?.permissions)
  const hasPermission = useMemo(
    () => (query.projectId ? hasOrgPermission || hasProjectPermission : hasOrgPermission),
    [query.projectId, hasProjectPermission, hasOrgPermission]
  )

  const tabAccess = useTabAccess(tab)

  useEffect(() => {
    let scrollToTabTimer: any
    // Use a timer in order to scroll correctly
    if (isMobile && activeTabRef.current) {
      scrollToTabTimer = setTimeout(() => {
        activeTabRef?.current?.scrollIntoView({block: 'end', inline: 'center', behavior: 'smooth'})
      }, 250)
    }
    return () => {
      clearTimeout(scrollToTabTimer)
    }
  }, [query.projectId, query.tabId, isMobile])

  if (!tabAccess) return null

  return (
    <PermissionTooltip
      disabled={hasPermission}
      customDescription={`Your member role does not have access to ${tab.title}`}
    >
      <AppLink href={href} key={tab.title} disabled={!hasPermission}>
        <Button
          as="span"
          tabIndex={-1}
          icon={tab.icon}
          mode="bleed"
          text={
            <>
              {tab.title}
              <Badge
                hidden={!tab.badge}
                mode="outline"
                tone="positive"
                style={{position: 'absolute', top: '-25px', right: '-25px'}}
              >
                {tab.badge}
              </Badge>
            </>
          }
          selected={isActive}
          tone={isActive ? tab.tabButtonTone || 'primary' : tab.tabButtonTone || 'default'}
          disabled={!hasPermission}
          ref={isActive ? activeTabRef : undefined}
          style={{
            cursor: hasPermission ? 'default' : 'not-allowed',
            position: 'relative',
            textDecoration: 'none',
          }}
        />
      </AppLink>
    </PermissionTooltip>
  )
}

const Inline = styled(UIInline)`
  div:empty {
    display: none;
  }
`

export function TabNav({tabs, currentTab}: TabsProps) {
  const {basePath} = useRoutePath()
  const visibleTabs = tabs
    .filter((tab) => !tab.disableTabButtonRender)
    .sort((a, b) => (a.order || 0) - (b.order || 0))
  const NAV_BAR_HEIGHT = useNavbarHeight()

  const {currentPaymentState = []} = useCurrentScopeContext()
  const BANNER_HEIGHT = useArrearsBannerHeight(currentPaymentState)

  return (
    <Layer
      zOffset={4}
      style={{
        position: 'sticky',
        top: NAV_BAR_HEIGHT + BANNER_HEIGHT,
      }}
    >
      <Card borderBottom paddingX={[2, 2, 0]}>
        <TabsContainer>
          <Container width={3}>
            <Box paddingY={2} paddingX={[0, 0, 4]}>
              <Inline space={3}>
                {visibleTabs.map((tab, index) => {
                  const href = tab.isRoot && !tab.path ? basePath : `${basePath}/${tab.path}`
                  const isActive = currentTab ? currentTab.path === tab.path : false

                  return (
                    <TabButton
                      key={`${tab.path}-${index}`}
                      tab={tab}
                      href={href}
                      isActive={isActive}
                    />
                  )
                })}
              </Inline>
            </Box>
          </Container>
        </TabsContainer>
      </Card>
    </Layer>
  )
}
