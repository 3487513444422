import {Subject} from 'rxjs'

export const headers = {
  'Content-Type': 'application/json',
}
export const updateOrganizationMemberRoleAction = new Subject()
export const setPaymentSourceAction = new Subject()
export const deletePaymentSourceAction = new Subject()

export const editOrgAction = new Subject()
export const createOrgAction = new Subject()
export const deleteOrgAction = new Subject()
