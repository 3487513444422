import {Dataset, DatasetAclMode, Tag, Project, ProjectMemberRole} from '../../types/models'
import {promiseRequest} from '../util/promiseRequest'

const API_VERSION = 'v2024-08-25'

export function getDatasets(project: Project): Promise<Dataset[]> {
  return promiseRequest<Dataset[]>(
    `https://api.${process.env.host}/${API_VERSION}/projects/${project.id}/datasets?includeStats=true`
  )
}

export function getDatasetsPromise(projectId: string) {
  return promiseRequest<Dataset[]>(
    `https://api.${process.env.host}/${API_VERSION}/projects/${projectId}/datasets?includeStats=true`
  )
}

export function datasetsList(project?: Project | null) {
  if (!project) {
    return Promise.resolve([])
  }

  return getDatasets(project)
}

export function datasetsRoles(project: Project, dataset: Dataset) {
  return promiseRequest<ProjectMemberRole[]>(
    `https://api.${process.env.host}/${API_VERSION}/projects/${project.id}/datasets/${dataset.name}/roles`
  )
}

export function datasetsTags(project: Project, dataset: Dataset) {
  return promiseRequest<Tag[]>(
    `https://api.${process.env.host}/${API_VERSION}/projects/${project.id}/datasets/${dataset.name}/tags`
  )
}

export function addDatasetTag(
  projectId: string,
  datasetName: string,
  tagName: string
): Promise<null> {
  return promiseRequest(
    `https://api.${process.env.host}/${API_VERSION}/projects/${projectId}/datasets/${datasetName}/tags/${tagName}`,
    {
      method: 'PUT',
    }
  )
}

export function removeDatasetTag(
  projectId: string,
  datasetName: string,
  tagName: string
): Promise<null> {
  return promiseRequest(
    `https://api.${process.env.host}/${API_VERSION}/projects/${projectId}/datasets/${datasetName}/tags/${tagName}`,
    {
      method: 'DELETE',
    }
  )
}

export function editDataset(
  projectId: string,
  datasetName: string,
  {aclMode}: {aclMode: DatasetAclMode}
): Promise<Dataset> {
  return promiseRequest<Dataset>(
    `https://api.${process.env.host}/${API_VERSION}/projects/${projectId}/datasets/${datasetName}`,
    {
      method: 'PATCH',
      json: {aclMode},
    }
  )
}

export function createDataset(
  projectId: string,
  datasetName: string,
  {aclMode}: {aclMode: DatasetAclMode}
): Promise<Dataset> {
  return promiseRequest<Dataset>(
    `https://api.${process.env.host}/${API_VERSION}/projects/${projectId}/datasets/${datasetName}`,
    {
      method: 'PUT',
      json: {aclMode},
    }
  )
}

export function deleteDataset(projectId: string, datasetName: string): Promise<Dataset> {
  return promiseRequest<Dataset>(
    `https://api.${process.env.host}/${API_VERSION}/projects/${projectId}/datasets/${datasetName}`,
    {
      method: 'DELETE',
    }
  )
}
