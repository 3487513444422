import {forwardRef} from 'react'

import {CommonCell, type CommonCellProps} from './CommonCell'

export interface TableCellProps extends Omit<CommonCellProps, 'as'> {
  children: React.ReactNode
}

export const TableCell = forwardRef(function TableCell(
  props: TableCellProps & Omit<React.HTMLProps<HTMLTableCellElement>, 'as' | 'height' | 'ref'>,
  ref: React.Ref<HTMLTableCellElement>
) {
  const {children, cellMaxWidth, cellMinWidth, cellFlex, ...rest} = props

  return (
    <CommonCell
      as="td"
      cellFlex={cellFlex}
      cellMaxWidth={cellMaxWidth}
      cellMinWidth={cellMinWidth}
      ref={ref}
      {...rest}
    >
      {children}
    </CommonCell>
  )
})
