import React from 'react'
import {UsageSummaryOrgPlan} from './usageSummaryOrgPlan'
import {UsageHeader} from '@/ui/index'

export function UsageOrgPlanHeader() {
  return (
    <>
      <UsageHeader />
      <UsageSummaryOrgPlan />
    </>
  )
}
