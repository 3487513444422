import {createClient} from '@sanity/client'
import imageUrlBuilder from '@sanity/image-url'

const communityClient = createClient({
  projectId: '81pocpw8',
  dataset: 'production',
  apiVersion: '2021-10-21',
})

const builder = imageUrlBuilder(communityClient)

const urlFor = (source: any) => {
  return builder.image(source)
}

export {communityClient, urlFor}
