import {SelectIcon} from '@sanity/icons'

import {type CommandListMenuButtonProps} from '../../../../generic/command-list-menu-button'
import {type ButtonProps} from '../../../../primitives/button/Button'

export const SHARED_BUTTON_PROPS = {
  mode: 'ghost',
  fontSize: 1,
  justify: 'space-between',
  iconRight: SelectIcon,
  tooltipProps: null,
} satisfies ButtonProps

export const SHARED_POPOVER_BUTTON_PROPS = {
  matchReferenceWidth: true,
  portal: true,
  minWidth: 200,
} satisfies Omit<CommandListMenuButtonProps['popoverButtonProps'], 'button'>
