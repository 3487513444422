import React, {useState} from 'react'
import {Box, Button, Flex, Grid, Label, Stack, Text, useTheme} from '@sanity/ui'
import {ChevronDownIcon, ChevronUpIcon, DownloadIcon, Icon, IconSymbol} from '@sanity/icons'
import {format, parseISO} from 'date-fns'
import {TableCell, TableRow} from '@/ui/index'
import {useMatchesMedia} from '@/utils/index'
import {CustomerInvoice, InvoiceStatus} from '@/types/index'

type Props = {
  invoice: CustomerInvoice
}

type StatusConfig = {
  label: string
  icon: IconSymbol
  color: string
}

export const OrgInvoice = ({invoice}: Props) => {
  const [showDetails, setShowDetails] = useState<boolean>(false)
  const {sanity} = useTheme()
  const isMobile = useMatchesMedia(1)

  const handleToggleDetails = () => {
    setShowDetails(!showDetails)
  }

  const statusMap = new Map<InvoiceStatus, StatusConfig>([
    [
      'paid',
      {
        label: 'Paid',
        icon: 'checkmark-circle',
        color: sanity.color.button.default.positive.enabled.bg,
      },
    ],
    [
      'pending',
      {
        label: 'Payment Due',
        icon: 'spinner',
        color: sanity.color.button.default.caution.enabled.bg,
      },
    ],
    [
      'overdue',
      {
        label: 'Overdue',
        icon: 'error-outline',
        color: sanity.color.button.default.critical.enabled.bg,
      },
    ],
  ])
  const amount = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(
    invoice.total / 100
  )
  const period = format(parseISO(invoice.periodEnd), 'MMMM yyyy')
  const status: StatusConfig | undefined = statusMap.get(invoice.status) || statusMap.get('pending')

  if (invoice) {
    return (
      <TableRow
        paddingY={[2, 2, 3]}
        paddingX={2}
        borderBottom
        onClick={isMobile ? handleToggleDetails : undefined}
      >
        <TableCell>
          <Text size={2}>
            <Icon symbol="document" />
          </Text>
        </TableCell>
        <TableCell>
          <Text size={2} weight="bold">
            {period}
          </Text>
        </TableCell>
        <TableCell>
          <Text size={1}>USD {amount}</Text>
        </TableCell>
        <TableCell>
          {status && (
            <>
              <Box paddingRight={2} paddingLeft={1}>
                <Text size={2} style={{color: status.color}}>
                  <Icon symbol={status.icon} />
                </Text>
              </Box>
              <Text size={1} style={{color: status.color}}>
                {status.label}
              </Text>
            </>
          )}
        </TableCell>
        <TableCell $align="right">
          {isMobile ? (
            <Button
              label="Show more"
              icon={showDetails ? ChevronUpIcon : ChevronDownIcon}
              onClick={handleToggleDetails}
              mode="bleed"
            />
          ) : (
            <Button
              text="Download"
              mode="bleed"
              icon={DownloadIcon}
              as={'a' as any}
              href={invoice.invoicePdf}
            />
          )}
        </TableCell>
        {isMobile && showDetails && (
          <TableCell
            style={{gridColumn: '1/-1', width: '100%'}}
            flexProps={{direction: 'column', align: 'flex-start'}}
          >
            <Stack space={4} style={{width: '100%'}} paddingTop={3}>
              <Grid columns={3}>
                <Stack space={3}>
                  <Label muted size={1}>
                    Price
                  </Label>
                  <Text size={1}>USD {amount}</Text>
                </Stack>
                <Stack space={3}>
                  <Label muted size={1}>
                    Status
                  </Label>
                  {status && (
                    <Flex align="center">
                      <Box paddingRight={2} paddingLeft={1}>
                        <Text size={2} style={{color: status.color}}>
                          <Icon symbol={status.icon} />
                        </Text>
                      </Box>
                      <Text size={1} style={{color: status.color}}>
                        {status.label}
                      </Text>
                    </Flex>
                  )}
                </Stack>
              </Grid>
              <Button
                text="Download"
                mode="ghost"
                icon={DownloadIcon}
                as={'a' as any}
                href={invoice.invoicePdf}
                style={{width: '100%'}}
              />
            </Stack>
          </TableCell>
        )}
      </TableRow>
    )
  }
  return null
}
