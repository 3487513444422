import {Stack, Card, Inline, Box, Flex, Tooltip, Text} from '@sanity/ui'
import React from 'react'
import {nameToInitials} from './utils'
import {AvatarWithFallback} from '@/ui/index'
import {User} from '@/types/index'

type Props = {
  user?: User | null | undefined
  children: React.ReactNode
  disabled?: boolean
}

export function MemberTooltip({user, children, disabled}: Props) {
  if (!user) {
    return null
  }
  const tooltipContent = (
    <Card padding={1} paddingRight={2}>
      <Flex wrap="wrap" align="center">
        <Box>
          <AvatarWithFallback
            size={1}
            src={user.imageUrl || undefined}
            initials={nameToInitials(user.displayName) || ''}
          />
        </Box>
        <Box flex={1} paddingLeft={3}>
          <Stack space={2}>
            <Text size={1} weight="medium">
              {user.displayName}
            </Text>
            <Text muted size={1}>
              {user.email || user.displayName}
            </Text>
          </Stack>
        </Box>
      </Flex>
    </Card>
  )
  return (
    <Tooltip disabled={disabled} content={tooltipContent} portal placement="top">
      <Inline>{children}</Inline>
    </Tooltip>
  )
}
MemberTooltip.displayName = 'MemberTooltip'
