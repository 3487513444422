import {useQuery, useMutation} from '@tanstack/react-query'
import {getProjectInvitations, postProjectInvitation, revokeProjectInvitation} from './controller'
import {ProjectInvitation, ProjectInvitationData} from '@/types/models'
import {useCacheInvalidation} from '../base/useCacheInvalidation'
import {getProjectInvitationsCacheKey} from './cache'
import {APIError} from '../util/promiseRequest'
import {useRequiredProjectPermissions} from '../../context'

export function useProjectInvitations(projectId: string) {
  const {invalidateProjectInvitations} = useCacheInvalidation()
  const canReadProjectInvites = useRequiredProjectPermissions([
    {permissionName: 'sanity.project.members', grantName: 'read'},
  ])

  const {data, error, isLoading} = useQuery({
    queryKey: getProjectInvitationsCacheKey(projectId),
    queryFn: () => getProjectInvitations(projectId),
    enabled: canReadProjectInvites,
  })

  const sendInvitationMutation = useMutation({
    mutationKey: ['sendProjectInvitation', projectId],
    mutationFn: async (invites: ProjectInvitationData[]) => {
      const results = await Promise.allSettled(
        invites.map((invite) => postProjectInvitation(projectId, invite))
      )
      return results.map((result, index) => ({
        index,
        invitation: invites[index],
        success: result.status === 'fulfilled',
        data: result.status === 'fulfilled' ? result.value : undefined,
        error: result.status === 'rejected' ? (result.reason as APIError) : undefined,
      }))
    },
    onSuccess: () => {
      invalidateProjectInvitations(projectId)
    },
  })

  const revokeInvitationMutation = useMutation({
    mutationKey: ['revokeProjectInvitation', projectId],
    mutationFn: async (invites: ProjectInvitation[]) => {
      const results = await Promise.allSettled(
        invites.map((invite) => revokeProjectInvitation(projectId, invite))
      )
      return results.map((result, index) => ({
        index,
        invitation: invites[index],
        success: result.status === 'fulfilled',
        data: result.status === 'fulfilled' ? result.value : undefined,
        error: result.status === 'rejected' ? (result.reason as APIError) : undefined,
      }))
    },
    onSuccess: () => {
      invalidateProjectInvitations(projectId)
    },
  })

  return {
    data,
    error,
    isLoading,
    sendInvitations: sendInvitationMutation.mutate,
    revokeInvitations: revokeInvitationMutation.mutate,
    sendInvitationResults: sendInvitationMutation.data,
    revokeInvitationResults: revokeInvitationMutation.data,
    isSending: sendInvitationMutation.isPending,
    isRevoking: revokeInvitationMutation.isPending,
  }
}
