import React, {useCallback, useEffect, useRef, useState} from 'react'
import {Card, Stack, Text, Popover, useToast, useGlobalKeyDown, Button} from '@sanity/ui'
import reactStringReplace from 'react-string-replace'
import dotProp from 'dot-prop'
import styled, {css} from 'styled-components'
import {ArrowTopRightIcon, CopyIcon} from '@sanity/icons'
import {useUID} from 'react-uid'
import {debounce} from 'lodash'
import {activityKeyRegex, truncateString, useAssociatedLink} from '@/utils/activity'
import {ActivityEvent} from '@/types/models'
import {useCurrentScopeContext} from '@/context/index'
import {useCopyToClipboard} from '@/utils/index'
import {getAddOnsCopy} from '@/components/project/plan/addonCopyConfig'

const DocumentBadge = styled.button`
  border: 0;
  font: inherit;
  font-size: 0.85rem;
  &:not([hidden]) {
    display: inline-block;
  }

  ${({theme}) => css`
    background: ${theme.sanity.color.muted.default.enabled.bg};
    color: ${theme.sanity.color.muted.default.enabled.fg};
    font-weight: 600;
  `}
`

const DocumentId = ({value, event}: {value: string; event: ActivityEvent}) => {
  const minimizedId = truncateString(value)
  const menuId = useUID()
  const scope = useCurrentScopeContext()
  const associatedLink = useAssociatedLink(event, scope)
  const {copyToClipboard, success, error} = useCopyToClipboard()
  const {push} = useToast()
  const [buttonHovered, setButtonHovered] = useState(false)
  const [open, setOpen] = useState<boolean>(false)
  const firstItemRef = useRef<HTMLButtonElement>()
  const buttonRef = useRef<HTMLButtonElement>()

  useGlobalKeyDown((e) => {
    if (e.key === 'Escape' && open) {
      setOpen(false)
      buttonRef?.current?.focus()
    }
  })

  const handleCopyToClipboard = useCallback(() => {
    copyToClipboard(value)
  }, [copyToClipboard, value])

  useEffect(() => {
    if (success) {
      push({
        title: 'Copied document ID to clipboard',
        status: 'success',
      })
      return
    }

    if (error) {
      push({
        title: 'Failed to copy document ID to clipboard',
        status: 'error',
      })
    }
  }, [success, error, push])

  const handleButtonEnter = useCallback(() => {
    setButtonHovered(true)
    setOpen(true)
  }, [])

  const handleButtonLeave = useCallback(() => {
    setButtonHovered(false)
  }, [])

  const handleButtonLeaveDelayed = debounce(handleButtonLeave, 100)

  const handlePopverEnter = useCallback(() => {
    handleButtonLeaveDelayed.cancel()
  }, [handleButtonLeaveDelayed])

  const handlePopverLeave = useCallback(() => {
    setOpen(false)
  }, [])

  useEffect(() => {
    if (buttonHovered) {
      setOpen(true)
    }
    if (!buttonHovered) {
      setOpen(false)
    }
  }, [buttonHovered])

  useEffect(() => {
    if (open) {
      requestAnimationFrame(() => firstItemRef?.current?.focus())
    }
  }, [open])

  const onSetOpen = useCallback(() => {
    setOpen(true)
  }, [])

  return (
    <Popover
      onMouseEnter={handlePopverEnter}
      onMouseLeave={handlePopverLeave}
      id={menuId}
      open={open}
      portal
      content={
        <Stack space={3} padding={3}>
          <Text size={1} weight="semibold" muted>
            {value}
          </Text>
          <Stack space={1}>
            <Button
              mode="ghost"
              ref={firstItemRef as any}
              text="Copy document ID"
              icon={CopyIcon}
              style={{width: '100%'}}
              onClick={handleCopyToClipboard}
            />
            {associatedLink.url && (
              <Button
                mode="ghost"
                text="Show document in Studio"
                icon={ArrowTopRightIcon}
                style={{width: '100%'}}
                href={associatedLink?.url}
                target="blank"
                rel="noreferrer"
                as="a"
              />
            )}
          </Stack>
        </Stack>
      }
    >
      <Card
        ref={buttonRef as any}
        tabIndex={0}
        paddingX={1}
        as={DocumentBadge}
        radius={2}
        onMouseEnter={handleButtonEnter}
        onMouseLeave={handleButtonLeaveDelayed}
        onClick={onSetOpen}
      >
        {minimizedId}
      </Card>
    </Popover>
  )
}

const OrgName = ({id}: {id: string}) => {
  const scope = useCurrentScopeContext()
  return (
    <strong style={{fontWeight: 600}}>
      {scope?.orgs.find((org) => org.id === id)?.name || id}
    </strong>
  )
}

type KeyComponent = {
  id: string
  value: string
  component?: React.ReactNode
}

type KeyComponents = Record<string, KeyComponent>

const getKeyComponents = (template: string, event: ActivityEvent) => {
  const keys = template
    .match(activityKeyRegex)
    ?.map((matchKey) => {
      // Extract the key ID
      const keyId = matchKey.replace('{{', '').replace('}}', '')
      // Get the value of that key from the event object
      const value = dotProp.get(event, keyId, matchKey)
      const obj: KeyComponent = {
        id: keyId,
        value,
      }
      if (keyId === 'documentId') {
        obj.component = <DocumentId value={value} key={keyId} event={event} />
      }
      if (['metadata.oldRole', 'metadata.role'].includes(keyId)) {
        obj.component = (
          <strong key={keyId} style={{fontWeight: 600}}>
            {value}
          </strong>
        )
      }
      if (
        ['metadata.transferredFromOrganizationId', 'metadata.transferredToOrganizationId'].includes(
          keyId
        )
      ) {
        obj.component = <OrgName id={value} key={keyId} />
      }
      return obj
    })
    .filter((k) => k.component)
    .reduce((obj, key) => ({...obj, [key.id]: {...key}}), {})

  return keys
}

export const EventDescription = ({template, event}: {template: string; event: ActivityEvent}) => {
  // Create an object with the keys in the template and get the appropriate components for each key, if any
  const keysWithComponents: KeyComponents | undefined = getKeyComponents(template, event)
  // Remove quotation marks from the template
  const normalizedTemplate = template.replace(/['"]+/g, '')

  // Create array with appropriate values and components
  const description = reactStringReplace(normalizedTemplate, activityKeyRegex, (match, index) => {
    // Remove {{}} from the matched string
    const key: string = match.replace('{{', '').replace('}}', '')
    // Check if the matched key has a component attached to it
    if (keysWithComponents && Object.keys(keysWithComponents || {}).includes(key || '')) {
      const matchedKey = keysWithComponents[key]
      return matchedKey?.component
    }

    // If a feature name, render the key as the text to be marked bold
    // otherwise get the value from the event object using the matched string
    const addons = getAddOnsCopy().map(({name}) => name)
    const value: string = addons.includes(key) ? key : dotProp.get(event, key)!

    // If there's no component for the key, render it in bold text
    return (
      <strong style={{fontWeight: 600}} key={`event-${key}-${event.id}-${index}-${event.actorId}`}>
        {value}
      </strong>
    )
  })
  return <Text size={2}>{description}</Text>
}
