import {useCurrentScopeContext} from '@/context/index'
import {DataOverrideWarning, Fieldset, SavedAddress} from '@/components/checkout/components'
import {CheckoutFormData} from '@/components/checkout/types'
import {FormField} from '@/components/form/formField'
import {useGeoIp} from '@/data/geoIp'
import {Customer} from '@/types/index'
import {Grid, Select, TextInput} from '@sanity/ui'
import countryList from 'iso-3166-country-list'
import {useEffect, useMemo, useState} from 'react'
import {useFormContext} from 'react-hook-form'

interface Props {
  customer?: Customer | null
}

interface Country {
  code: string
  name: string
}

export function BillingAddressFieldset({customer}: Props) {
  const {
    register,
    setValue,
    watch,
    formState: {errors},
  } = useFormContext<CheckoutFormData>()

  const currentOrgId = watch('org.id')
  const countryValue = watch('customer.country')

  const scope = useCurrentScopeContext()
  const {data: geoIp} = useGeoIp()
  const hasAddress = Boolean(customer?.address && customer?.city && customer?.zip)

  const [edit, setEdit] = useState(false)

  const currentOrg = useMemo(
    () => scope?.orgs?.find((org) => org?.id === currentOrgId),
    [currentOrgId, scope?.orgs]
  )

  /* Reset local state swhen the customer data changes */
  useEffect(() => setEdit(false), [currentOrgId]) // Set edit to false when org changes

  useEffect(() => {
    if (countryValue || !geoIp?.isoCode) return
    setValue('customer.country', geoIp.isoCode)
  }, [countryValue, geoIp, setValue])

  if (hasAddress && !edit) {
    return (
      <Fieldset legend="Saved billing address">
        <FormField label="Billing address">
          <SavedAddress customer={customer!} onEdit={() => setEdit(true)} />
        </FormField>
      </Fieldset>
    )
  }

  return (
    <Fieldset legend="Billing address">
      <FormField label="Billing address" error={errors?.customer?.address?.message}>
        <Select
          {...register('customer.country')}
          autoComplete="country"
          placeholder="Select country..."
          defaultValue={geoIp?.isoCode}
        >
          <option value="" disabled selected>
            Select country
          </option>
          {countryList.map((country: Country) => (
            <option key={country.code} value={country.code}>
              {country.name}
            </option>
          ))}
        </Select>

        <TextInput
          {...register('customer.address')}
          autoComplete="address-line1"
          placeholder="Address"
        />

        <Grid gap={3} columns={[1, 1, 2]}>
          <TextInput
            {...register('customer.city')}
            autoComplete="address-level2"
            placeholder="City"
          />
          <TextInput
            {...register('customer.zip')}
            autoComplete="postal-code"
            placeholder="Zip code"
          />
        </Grid>
      </FormField>

      {edit && (
        <DataOverrideWarning
          text={`This will replace your previous billing address for ${currentOrg?.name}`}
          onCancel={() => setEdit(false)}
        />
      )}
    </Fieldset>
  )
}
