import React from 'react'
import {Box} from '@sanity/ui'
import {NotFound} from '@/ui/app'

type Props = {
  title?: string | React.ReactNode
  description?: string | React.ReactNode
}
export function FallbackComponent({title, description}: Props) {
  // eslint-disable-next-line no-console
  console.error(`${title}. ${description}`)
  return (
    <Box>
      <NotFound />
    </Box>
  )
}
