import React, {useEffect, useState} from 'react'
import {Grid, Stack, useToast} from '@sanity/ui'
import {UsageSummaryProject} from './usage/usageSummaryProject'
import {
  ActivityOverview,
  ProjectUsagePrompt,
  OverviewModule,
  MemberOverview,
  HelpModule,
  UpgradeToGrowthBanner,
} from '@/ui/index'
import {sendAmplitudeTrackingEvent} from '@/utils/tracking'
import {
  useCurrentScopeContext,
  useProjectHasFeature,
  useRequiredProjectPermissions,
  useRoutePath,
} from '@/context/index'
import {ProjectOverviewEjectCard} from '@/components/project/projectOverviewEjectCard'
import {useRouter} from 'next/router'
import {TOAST_DATA} from '@/utils/toasts'

export function ProjectOverview() {
  const {project} = useCurrentScopeContext()
  const {asPath} = useRoutePath()
  const {isReady: routerReady, query, replace} = useRouter()

  const [sawToast, setSawToast] = useState(false)
  const toast = useToast()

  const projectId = project?.id || ''

  const members = project?.members.filter((m) => !m.isRobot) || []

  const hasPermission = useRequiredProjectPermissions([
    {permissionName: 'sanity.project.members', grantName: 'read'},
  ])

  useEffect(() => {
    if (routerReady && project?.displayName && !sawToast) {
      const {toastKey, ...rest} = query
      if (toastKey && TOAST_DATA[toastKey as string]) {
        toast.push({
          closable: true,
          ...TOAST_DATA[toastKey as string],
        })
        setSawToast(true)
        replace({query: rest}) // Remove the toast query param for tidy URLs
      }
    }
  }, [routerReady, replace, query, project, toast, sawToast])

  useEffect(() => {
    if (projectId) {
      sendAmplitudeTrackingEvent('Project Overview Page Viewed', projectId)
    }
  }, [projectId])

  const hasFeatureAccess = useProjectHasFeature(project, 'activityFeed', true)

  const showActivityOverview = hasFeatureAccess && project?.activityFeedEnabled

  return (
    <Grid columns={[1, 1, 1, 2]} gap={6}>
      <Stack space={6}>
        {/* Left column */}
        <ProjectOverviewEjectCard />
        <UpgradeToGrowthBanner projectId={projectId} />
        <OverviewModule title="Usage" buttonText="View more" path={`${asPath}/usage`}>
          <Stack space={3}>
            <ProjectUsagePrompt />
            <UsageSummaryProject isOverview />
          </Stack>
        </OverviewModule>
      </Stack>
      <Stack space={6}>
        {/* right column */}
        {hasPermission && (
          <OverviewModule
            title={`Project members ${members.length > 1 ? `(${members.length})` : ''}`}
            buttonText="View all"
            path={`${asPath}/members`}
          >
            <MemberOverview
              kind="project"
              userIds={members.filter((member) => !member.isRobot).map((member) => member.id)}
            />
          </OverviewModule>
        )}
        {showActivityOverview && (
          <OverviewModule title="Activity" buttonText="View more" path={`${asPath}/activity`}>
            <ActivityOverview path={`${asPath}/activity`} showProjectEvents />
          </OverviewModule>
        )}
        <HelpModule />
      </Stack>
    </Grid>
  )
}
