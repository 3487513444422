import {buildStudioUrl} from './general'
import {Studio} from '../types'

export const getStudioHost = (studios?: Studio[]) => {
  if (!studios || studios.length === 0) {
    return null
  }

  // prioritize external host, if multiple do not suggest a url
  const externalHosts = studios.filter((studio) => studio.urlType === 'external')
  const singleExternalHost = externalHosts.length === 1 ? externalHosts[0].appHost : null
  if (singleExternalHost) {
    return singleExternalHost
  } else if (externalHosts.length > 1) {
    return null
  }

  // if no external entries, only provide url if there is a single internal one
  const internalHosts = studios.filter((studio) => studio.urlType === 'internal')
  const singleInternalHost =
    internalHosts.length === 1 ? buildStudioUrl(internalHosts[0].appHost) : null
  if (singleInternalHost) {
    return singleInternalHost
  }

  return null
}
