import {InfoOutlineIcon, ToggleArrowRightIcon} from '@sanity/icons'
import {Box, Button, Card, Code, Flex, Inline, Stack, Text, Tooltip} from '@sanity/ui'
import React, {useCallback, useMemo, useState} from 'react'
import {contentResourceCompare} from './util'
import {Dataset, RoleResource, Tag} from '@/types/models'

export default function ExternalPermissionResources({
  resources,
  tag,
  dataset,
}: {
  resources: RoleResource[]
  tag: Tag | undefined
  dataset: Dataset | undefined
}) {
  const [showExternal, setShowExternal] = useState(false)
  const handleToggleExternal = useCallback(() => setShowExternal(!showExternal), [showExternal])

  const externalResources = useMemo(() => {
    return resources
      .filter(
        (resource) =>
          resource.type !== 'sanity.document.filter.mode' &&
          resource.name !== 'sanity-document-filter-files' &&
          resource.name !== 'sanity-document-filter-images' &&
          !resource.type.startsWith('sanity.project') &&
          resource.grants.some(
            (g) => g.params.tagName === tag?.name && g.params.dataset === dataset?.name
          )
      )
      .map((resource) => {
        return {
          ...resource,
          grants: resource.grants.filter(
            (g) => g.params.tagName === tag?.name && g.params.dataset === dataset?.name
          ),
        }
      })
      .sort(contentResourceCompare)
  }, [resources, dataset?.name, tag?.name])

  if (externalResources.length === 0) {
    return <></>
  }

  return (
    <Card tone="default" paddingX={4} paddingY={2} borderTop>
      <Flex>
        <Button mode="bleed" tone="default" onClick={handleToggleExternal} padding={2}>
          <Flex>
            <Box marginRight={2}>
              <Text size={1}>
                <ToggleArrowRightIcon
                  style={{transform: showExternal ? 'rotate(90deg)' : 'rotate(0)'}}
                />
              </Text>
            </Box>
            <Inline space={2}>
              <Text size={1} weight="medium">
                Show externally managed resources
              </Text>
              <Tooltip
                content={
                  <Box padding={3}>
                    <Text size={1} muted>
                      These resources are managed through an API and cannot be edited here.
                    </Text>
                  </Box>
                }
              >
                <Text size={1}>
                  <InfoOutlineIcon />
                </Text>
              </Tooltip>
            </Inline>
          </Flex>
        </Button>
      </Flex>
      {showExternal && (
        <Stack marginTop={2}>
          {externalResources.map((resource, i) => (
            <Card
              paddingY={2}
              tone="inherit"
              key={resource.id}
              borderBottom={i < externalResources.length - 1}
            >
              <Flex align="center">
                <Box flex={2}>
                  <Text size={1}>{resource.title}</Text>
                </Box>
                <Box flex={2}>
                  <Inline space={1}>
                    {resource.grants
                      .map((grant) => grant.name)
                      .sort((a, b) => a.localeCompare(b))
                      .map((grant) => (
                        <Card key={grant} padding={1} tone="inherit" radius={2}>
                          <Code size={1}>{grant}</Code>
                        </Card>
                      ))}
                  </Inline>
                </Box>
              </Flex>
            </Card>
          ))}
        </Stack>
      )}
    </Card>
  )
}
