import React, {FormEvent, useCallback} from 'react'
import {Button, Stack, Flex, Card} from '@sanity/ui'

interface AdditionalButtonProps {
  text: string
  onClick: () => void
}

export interface EditContainerProps {
  onSave: (e: FormEvent<HTMLFormElement>) => void
  onChange?: (e: FormEvent) => void
  onCancel?: () => void
  children: React.ReactNode
  loading?: boolean
  noCancel?: boolean
  cancelText?: string
  primaryButtonText?: string
  noPrimaryButton?: boolean
  alignButtonsRight?: boolean
  additionalButtons?: AdditionalButtonProps[]
}

export function EditContainer({
  children,
  onSave,
  onChange,
  onCancel,
  loading,
  noPrimaryButton,
  primaryButtonText = 'Save',
  noCancel = false,
  cancelText = 'Cancel',
  alignButtonsRight = false,
  additionalButtons,
}: EditContainerProps) {
  const handleSubmit = useCallback(
    (e: FormEvent) => onSave(e as unknown as FormEvent<HTMLFormElement>),
    [onSave]
  )
  const handleChange = useCallback((e: FormEvent) => onChange && onChange(e), [onChange])

  const handleKeydown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Escape') {
        onCancel?.()
      }
    },
    [onCancel]
  )

  return (
    <Card>
      <Stack
        space={4}
        as="form"
        onSubmit={handleSubmit}
        onChange={handleChange}
        onKeyDown={handleKeydown}
        noValidate
      >
        <>{children}</>
        <Flex gap={3} direction={alignButtonsRight ? 'row-reverse' : 'row'}>
          {!noPrimaryButton && (
            <Button text={primaryButtonText} tone="primary" type="submit" loading={loading} />
          )}
          {!noCancel && <Button text={cancelText} mode="ghost" onClick={onCancel} />}
          {additionalButtons?.map((button) => (
            <Button key={button.text} text={button.text} mode="bleed" onClick={button.onClick} />
          ))}
        </Flex>
      </Stack>
    </Card>
  )
}
