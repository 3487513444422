import {Text, Flex, Button, Card} from '@sanity/ui'
import {EditIcon} from '@sanity/icons'
import type {PaymentMethodCard} from '@/types/index'
import {upperFirst} from 'lodash'
import {CardLogo} from './cardLogo'

interface Props {
  card: PaymentMethodCard
  onEdit: () => void
}

export function SavedCard({card, onEdit}: Props) {
  return (
    <Card padding={3} radius={2} shadow={1} tone="primary" width="fill">
      <Flex justify="space-between" align="center" gap={4}>
        <Flex align="center" gap={4}>
          <CardLogo variant={card.brand} mode="outline" />
          <Text size={1} weight="medium">
            Saved {upperFirst(card.brand)}
          </Text>
        </Flex>

        <Flex align="center" gap={3}>
          <Text data-recording-mask size={1} muted>
            •••• {card.last4}
          </Text>
          <Button icon={EditIcon} mode="bleed" padding={2} onClick={onEdit} tone="primary" />
        </Flex>
      </Flex>
    </Card>
  )
}
