import {promiseRequest} from '../util/promiseRequest'
import {Token} from '../../types/models'

const API_VERSION = 'v2021-06-07'

export function getTokens(projectId: string) {
  return promiseRequest<Token[]>(
    `https://api.${process.env.host}/${API_VERSION}/projects/${projectId}/tokens`
  )
}

export function addToken(
  projectId: string,
  token: {
    label: string
    roleName: string
  }
) {
  return promiseRequest<Token>(
    `https://api.${process.env.host}/${API_VERSION}/projects/${projectId}/tokens`,
    {
      method: 'POST',
      json: token,
    }
  )
}

export function deleteToken(projectId: string, tokenId: string) {
  return promiseRequest<void>(
    `https://api.${process.env.host}/${API_VERSION}/projects/${projectId}/tokens/${tokenId}`,
    {
      method: 'DELETE',
    }
  )
}
