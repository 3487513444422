import React, {useCallback, useEffect, useRef, useState} from 'react'
import {of, Subscription} from 'rxjs'
import {catchError} from 'rxjs/operators'
import {setOrganizationMemberRole} from '.'

type SetOrganizationRole = ({newRoleName}: {newRoleName: string}) => void
type RoleDataState = {
  error?: any
  loading: boolean
  data: string | undefined
  isUpdated: boolean
}

export function useOrgMemberRole(
  orgId: string,
  userId: string,
  role: string
): [RoleDataState, SetOrganizationRole] {
  const [currentRole, setCurrentRole] = useState<string | undefined>()
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<Error | undefined>(undefined)
  const [isUpdated, setIsUpdated] = useState<boolean>(false)

  const sub: React.MutableRefObject<Subscription | undefined> = useRef()

  useEffect(
    () => () => {
      if (sub.current) {
        sub.current.unsubscribe()
      }
    },
    []
  )

  useEffect(() => {
    setCurrentRole(role)
  }, [role])

  const updateOrganizationRole = useCallback(
    ({newRoleName}: {newRoleName: string}) => {
      setIsUpdated(false)
      setError(undefined)
      setLoading(true)
      sub.current = setOrganizationMemberRole(orgId, userId, newRoleName)
        .pipe(
          catchError((err) => {
            setLoading(false)
            setError(err)
            return of(undefined)
          })
        )
        .subscribe((next) => {
          if (next) {
            setCurrentRole(next)
            setOrganizationMemberRole(orgId, userId, next)
            setIsUpdated(true)
          }
          setLoading(false)
          setError(undefined)
          sub.current?.unsubscribe()
        })
    },
    [orgId, userId]
  )
  return [{data: currentRole, loading, error, isUpdated}, updateOrganizationRole]
}
