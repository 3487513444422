import {type Role} from '@sanity/access-api'
import {ArrowLeftIcon, ArrowRightIcon, SearchIcon} from '@sanity/icons'
import {Box, Card, Flex, Stack, Text, TextInput} from '@sanity/ui'
import {AnimatePresence, motion, type Transition, type Variants} from 'framer-motion'
import {
  type ChangeEvent,
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import {type MembersV2} from '@/types/members_v2'

import {
  CommandListMenuButton,
  type CommandListMenuButtonProps,
} from '../../generic/command-list-menu-button'
import {type PopoverButtonHandle} from '../../generic/popover-button'
import {Button} from '../../primitives/button/Button'
import {filterByQuery} from '../../utils'
import {ProjectSelectMenuItem} from '../project-select-menu-button'
import {RoleSelectMenuItem} from '../role-select-menu-button'

const ANIMATION_VARIANTS: Variants = {
  initial: {opacity: 0},
  animate: {opacity: 1},
  exit: {opacity: 0},
}

const TRANSITION: Transition = {
  ease: 'easeInOut',
  duration: 0.1,
}

const HEADER_HEIGHT: number = 90
const FOOTER_HEIGHT: number = 49
const ITEM_HEIGHT: number = 62
const MAX_ITEMS_DISPLAYED: number = 5
const LIST_PADDING: number = 10

const MAX_HEIGHT: number =
  ITEM_HEIGHT * MAX_ITEMS_DISPLAYED + LIST_PADDING + HEADER_HEIGHT + FOOTER_HEIGHT

const MotionFlex = motion(Flex)

const isRole = (item: any): item is Role => {
  return (item as Role).title !== undefined
}

// Type guard for Project
const isProject = (item: any): item is MembersV2['project'] => {
  return (item as MembersV2['project']) !== undefined
}

type Step = 'select-project' | 'select-role'

interface ProjectMemberAddMenuButtonProps {
  options: MembersV2['projectData'][]
  popoverButtonProps: CommandListMenuButtonProps['popoverButtonProps']
  onConfirm: (projectId: string, role: Role) => void
}

export function ProjectMemberAddMenuButton(props: ProjectMemberAddMenuButtonProps) {
  const {popoverButtonProps: popoverButtonPropsProp, options, onConfirm} = props

  const [step, setStep] = useState<Step>('select-project')
  const [selectedProjectId, setSelectedProjectId] = useState<string | null>(null)
  const [selectedRole, setSelectedRole] = useState<Role | null>(null)

  const [searchQuery, setSearchQuery] = useState<string>('')
  const [inputElement, setInputElement] = useState<HTMLInputElement | null>(null)

  const popoverButtonRef = useRef<PopoverButtonHandle | null>(null)

  const currentOptions = useMemo(() => {
    if (step === 'select-project') {
      const projects = options.map((project) => project.projectProfile)

      return filterByQuery(projects, searchQuery, [['displayName']])
    }

    if (step === 'select-role' && selectedProjectId) {
      const selectedProject = options.find(
        (project) => project.projectProfile.id === selectedProjectId
      )
      const roles = selectedProject?.roleSchemas || []

      return filterByQuery(roles, searchQuery, [['title']])
    }

    return []
  }, [options, searchQuery, selectedProjectId, step])

  const canSubmit = useMemo(() => {
    return selectedProjectId !== null && selectedRole
  }, [selectedProjectId, selectedRole])

  const handleSearchChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
  }, [])

  const clearSelection = useCallback(() => {
    setSearchQuery('')
    setStep('select-project')
    setSelectedProjectId(null)
    setSelectedRole(null)
  }, [])

  const handleCloseButtonClick = useCallback(() => {
    if (!selectedProjectId || !selectedRole) return

    onConfirm(selectedProjectId, selectedRole)

    clearSelection()

    popoverButtonRef.current?.onClose()
    popoverButtonRef.current?.focusButton()
  }, [clearSelection, onConfirm, selectedProjectId, selectedRole])

  const getItemSelected = useCallback(
    (index: number) => {
      const item = currentOptions[index]

      if (isRole(item)) {
        return selectedRole?.name === item.name
      }

      if (isProject(item)) {
        return selectedProjectId === item.id
      }

      return false
    },
    [currentOptions, selectedProjectId, selectedRole]
  )

  const renderItem = useCallback(
    (item: MembersV2['project'] | Role) => {
      if (isRole(item)) {
        const isSelected = selectedRole?.name === item.name

        return (
          <RoleSelectMenuItem item={item} onRoleSelect={setSelectedRole} selected={isSelected} />
        )
      }

      if (isProject(item)) {
        const isSelected = selectedProjectId === item.id

        return (
          <ProjectSelectMenuItem
            onProjectSelect={() => {
              setSelectedProjectId(item.id)
              setStep('select-role')
              setSelectedRole(null)
            }}
            item={item}
            selected={isSelected}
          />
        )
      }

      return null
    },
    [selectedProjectId, selectedRole]
  )

  const footer = (
    <Stack space={2} padding={2} sizing="border">
      <Button
        disabled={!canSubmit}
        fontSize={1}
        onClick={handleCloseButtonClick}
        text="Add member"
        width="fill"
      />
    </Stack>
  )

  const header = (
    <Stack space={2} padding={2} sizing="border">
      <Flex align="center" style={{position: 'relative'}} paddingY={3} sizing="border">
        {step !== 'select-project' && (
          <Button
            aria-label="Back to select project"
            fontSize={1}
            icon={ArrowLeftIcon}
            mode="bleed"
            onClick={() => setStep('select-project')}
            style={{position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)'}}
            tooltipProps={{content: 'Back to select project'}}
          />
        )}

        <Flex flex={1} justify="center" style={{margin: '0 40px'}}>
          <Box>
            <Text size={1} weight="semibold">
              {step === 'select-project' && 'Select project'}
              {step === 'select-role' && 'Select role in project'}
            </Text>
          </Box>
        </Flex>

        {step !== 'select-role' && selectedRole && (
          <Button
            aria-label="Continue to select role"
            fontSize={1}
            icon={ArrowRightIcon}
            mode="bleed"
            onClick={() => setStep('select-role')}
            style={{position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)'}}
            tooltipProps={{content: 'Continue to select role'}}
          />
        )}
      </Flex>

      <Stack space={2} sizing="border">
        <TextInput
          fontSize={1}
          icon={SearchIcon}
          onChange={handleSearchChange}
          placeholder={
            step === 'select-project' ? 'Search projects by name' : 'Search roles by name'
          }
          ref={setInputElement}
          value={searchQuery}
        />
      </Stack>
    </Stack>
  )

  const popoverButtonProps = useMemo(
    (): CommandListMenuButtonProps['popoverButtonProps'] => ({
      minWidth: 320,
      maxHeight: MAX_HEIGHT,
      onClose: clearSelection,
      placement: 'top',
      ...popoverButtonPropsProp,
    }),
    [clearSelection, popoverButtonPropsProp]
  )

  const emptyNode = useMemo(() => {
    if (step === 'select-project') {
      return (
        <Card padding={5} radius={2} sizing="border">
          <Text align="center" muted size={1}>
            No projects found
          </Text>
        </Card>
      )
    }

    if (step === 'select-role') {
      return (
        <Card padding={5} radius={2} sizing="border">
          <Text align="center" muted size={1}>
            No roles found
          </Text>
        </Card>
      )
    }

    return null
  }, [step])

  const renderCommandList = useCallback(
    (children: ReactNode) => {
      return (
        <AnimatePresence mode="wait">
          <MotionFlex
            animate="animate"
            exit="exit"
            flex={1}
            initial="initial"
            key={step}
            transition={TRANSITION}
            variants={ANIMATION_VARIANTS}
          >
            {children}
          </MotionFlex>
        </AnimatePresence>
      )
    },
    [step]
  )

  // Clear search query when step changes
  useEffect(() => {
    setSearchQuery('')
  }, [step])

  return (
    <CommandListMenuButton
      ariaLabel={step === 'select-project' ? 'Select project' : 'Select role'}
      autoFocus="input"
      emptyNode={emptyNode}
      footer={footer}
      getItemSelected={getItemSelected}
      header={header}
      id="project-member-add-select-menu"
      inputElement={inputElement}
      itemHeight={ITEM_HEIGHT}
      items={currentOptions}
      onlyShowSelectionWhenActive
      padding={2}
      paddingBottom={0}
      popoverButtonProps={popoverButtonProps}
      ref={popoverButtonRef}
      renderCommandList={renderCommandList}
      renderItem={renderItem}
    />
  )
}
