/* eslint-disable react/jsx-no-bind */
import React, {useEffect, useState} from 'react'
import {
  Box,
  Dialog as UiDialog,
  Button,
  Flex,
  Text,
  Badge,
  useToast,
  Stack,
  TextInput,
  Card,
} from '@sanity/ui'
import {EllipsisHorizontalIcon, TrashIcon, AddIcon, WarningOutlineIcon} from '@sanity/icons'
import styled from 'styled-components'
import {useCors} from '@/data/cors/useCors'
import {
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableEmpty,
  CorsPreview,
  useConfirmDialog,
  PermissionButtonProject,
} from '../../../'
import {CorsInput} from '@/components/form/edit-states'
import {Project, CorsOrigin, TableHeader} from '@/types/index'
import {TabHeader, TimeAgoText} from '@/ui/index'
import {isAPIError} from '@/data/util/promiseRequest'

export const ScreenReaderLabel = styled.label`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const headers: TableHeader[] = [
  {
    id: 'origin',
    label: 'Origin',
    columnWidth: 4,
  },
  {
    id: 'credentials',
    label: 'Credentials',
    columnWidth: 2,
  },
  {
    id: 'created',
    label: 'Created',
    columnWidth: 1,
    hideOnMobile: true,
  },
  {
    id: 'edit',
    label: 'Edit',
    columnWidth: 0,
    disableTabButtonRender: true,
    // hideOnMobile: true,
  },
]

export function CorsDetails({project}: {project: Project}) {
  const {corsOrigins, isLoading, addOrigin, deleteOrigin, isAdding, isDeleting} = useCors(
    project.id
  )
  const [showAddCors, setShowAddCors] = useState(false)
  const {showDialog, Dialog, hideDialog} = useConfirmDialog()
  const [itemToDelete, setItemToDelete] = useState<CorsOrigin | null>(null)
  const [showAll, setShowAll] = useState(false)
  const isEmpty = !corsOrigins || corsOrigins.length <= 0
  const toast = useToast()

  const handleInsert = (payload: {origin: string; allowCredentials: boolean}) => {
    const originAlreadyExists = corsOrigins.some((o) => o.origin === payload.origin)
    if (originAlreadyExists) {
      toast.push({
        title: 'An origin with this URL already exists',
        status: 'error',
      })
    } else {
      addOrigin(payload, {
        onSuccess: () => {
          toast.push({title: 'CORS origin added successfully', status: 'success'})
          setShowAddCors(false)
        },
        onError: (error) => {
          if (isAPIError(error) && error.statusCode === 409) {
            toast.push({title: 'Origin already exists', status: 'error'})

            return
          }
          if (isAPIError(error) && error.statusCode === 400) {
            toast.push({title: 'Invalid origin', status: 'error'})

            return
          }

          toast.push({title: error.message, status: 'error'})
        },
      })
    }
  }

  const handleDeleteStart = (origin: CorsOrigin) => {
    setItemToDelete(origin)
    showDialog()
  }

  const handleDeleteConfirm = (id: string) => {
    deleteOrigin(id, {
      onSuccess: () => {
        setItemToDelete(null)
        hideDialog({title: 'CORS origin deleted', status: 'success'})
      },
      onError: (error) => {
        toast.push({title: error.message, status: 'error'})
      },
    })
  }

  const handleDeleteCancel = () => {
    setItemToDelete(null)
    hideDialog()
  }

  const [addCorsDialogOrigin, setAddCorsDialog] = useState<false | string>(false)
  useEffect(() => {
    const {searchParams} = new URL(location.href)
    if (searchParams.get('cors') === 'add') {
      if (!searchParams.has('origin')) {
        toast.push({title: 'The `origin` parameter is missing', status: 'error'})
      }
      const {origin} = new URL(searchParams.get('origin')!)
      if (origin && origin !== 'null') {
        setAddCorsDialog(origin)
      } else {
        toast.push({title: 'The `origin` parameter is not a valid URL', status: 'error'})
      }
    }
  }, [toast])

  const sortByDate = (array: CorsOrigin[]) => {
    return array.sort(
      (a: CorsOrigin, b: CorsOrigin) => +new Date(b.createdAt) - +new Date(a.createdAt)
    )
  }

  return (
    <>
      {addCorsDialogOrigin && (
        <UiDialog header="Add CORS origin" id="direct-add-cors-origin" width={1}>
          <Flex paddingX={4} paddingY={5} direction="column">
            <Card tone="caution" padding={3} border radius={2}>
              <Flex>
                <Flex align="center">
                  <WarningOutlineIcon />
                </Flex>
                <Box marginLeft={2}>
                  <Text size={1} as="p">
                    The website running on the URL below will be able to modify this project on
                    behalf of users.
                    <Box marginTop={1}>
                      <a
                        href={`https://www.${process.env.host}/docs/front-ends/cors`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Read more about CORS Origins.
                      </a>
                    </Box>
                  </Text>
                </Box>
              </Flex>
            </Card>

            <Box marginTop={5} marginBottom={4}>
              <Text as="p">Allow the following origin to connect to the project API:</Text>
            </Box>

            <Box marginBottom={3}>
              {/* added for accessibility */}
              <ScreenReaderLabel aria-hidden="true">CORS URL to be added</ScreenReaderLabel>
              <TextInput type="url" value={addCorsDialogOrigin} readOnly />
            </Box>
            <Button
              text="Add CORS origin"
              tone="primary"
              loading={isAdding}
              onClick={() => {
                const {searchParams} = new URL(location.href)
                handleInsert({
                  origin: addCorsDialogOrigin,
                  allowCredentials: searchParams.has('credentials'),
                })
                setAddCorsDialog(false)
              }}
            />
          </Flex>
        </UiDialog>
      )}
      <Box>
        <Stack space={4}>
          <TabHeader
            title="CORS origins"
            description="Hosts that can connect to the project API."
            button={
              <PermissionButtonProject
                title="Add CORS origin"
                icon={AddIcon}
                mode="ghost"
                onClick={() => setShowAddCors(true)}
                permissions={[{permissionName: 'sanity.project.cors', grantName: 'create'}]}
                action="cannot add CORS origins"
              />
            }
          />

          <Table
            headers={isEmpty ? [] : headers}
            loading={isLoading}
            loadingText="Loading CORS origins"
          >
            <TableBody>
              {showAddCors && (
                <TableRow paddingY={5} paddingX={3}>
                  <TableCell style={{gridColumn: '1/-1'}}>
                    <Flex>
                      <Box flex={1}>
                        <CorsInput
                          onSave={(data) => handleInsert(data)}
                          onCancel={() => setShowAddCors(false)}
                          isLoading={isAdding}
                        />
                      </Box>
                    </Flex>
                  </TableCell>
                </TableRow>
              )}
              {!isEmpty &&
                sortByDate(corsOrigins)
                  ?.slice(0, showAll ? corsOrigins.length : 3)
                  .map((origin: CorsOrigin, index) => (
                    <TableRow
                      key={origin.id}
                      selected={false}
                      paddingY={3}
                      paddingX={2}
                      borderBottom={index + 1 !== (showAll ? corsOrigins.length : 3)}
                    >
                      <TableCell>
                        <Text style={{wordBreak: 'break-word'}}>{origin.origin}</Text>
                      </TableCell>
                      <TableCell>
                        <Badge
                          tone={origin.allowCredentials ? 'positive' : 'default'}
                          mode="outline"
                          size={1}
                          radius={2}
                        >
                          {origin.allowCredentials ? 'Allowed' : 'Not Allowed'}
                        </Badge>
                      </TableCell>
                      <TableCell>
                        <TimeAgoText date={origin.createdAt} muted size={1} />
                      </TableCell>
                      <TableCell>
                        <Flex justify="flex-end" style={{width: '100%'}}>
                          <Box>
                            <PermissionButtonProject
                              icon={TrashIcon}
                              mode="bleed"
                              aria-label="Delete"
                              onClick={() => handleDeleteStart(origin)}
                              permissions={[
                                {permissionName: 'sanity.project.cors', grantName: 'delete'},
                              ]}
                              action="Cannot delete CORS origins"
                            />
                          </Box>
                        </Flex>
                      </TableCell>
                    </TableRow>
                  ))}

              {isEmpty && (
                <TableEmpty
                  title="There are no CORS origins yet"
                  description="Maybe try creating one?"
                />
              )}
            </TableBody>
          </Table>
        </Stack>

        {corsOrigins && corsOrigins.length > 3 && (
          <Button
            icon={EllipsisHorizontalIcon}
            mode="ghost"
            onClick={() => setShowAll(!showAll)}
            style={{width: '100%'}}
          />
        )}

        {itemToDelete && (
          <Dialog
            id="delete-cors-dialog"
            header="Delete CORS origin"
            title="Do you really want to delete this CORS origin?"
            description="This action cannot be undone."
            onClose={handleDeleteCancel}
            onConfirm={() => handleDeleteConfirm(itemToDelete.id)}
            confirmButtonProps={{text: 'Delete'}}
            loading={isDeleting}
          >
            <CorsPreview
              origin={itemToDelete.origin}
              createdAt={itemToDelete.createdAt}
              allowCredentials={itemToDelete.allowCredentials}
            />
          </Dialog>
        )}
      </Box>
    </>
  )
}
