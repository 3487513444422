import React from 'react'
import {Text, Card, Flex, Tooltip} from '@sanity/ui'
import {
  modeToDescription,
  modeToIcon,
  modeToTone,
  modeToTitle,
} from '@/components/roles/grants/format'

export function Grant({grant}: {grant: string | undefined}) {
  if (!grant) return <></>
  return (
    <Tooltip
      content={
        <Card radius={2} padding={3} style={{maxWidth: 250}}>
          <Text size={1}>{modeToDescription(grant)}</Text>
        </Card>
      }
    >
      <Card tone={modeToTone(grant)} padding={2} radius={5}>
        <Flex align="center" gap={2}>
          <Text size={1}>{modeToIcon(grant)}</Text>
          <Text size={1} weight="medium">
            {modeToTitle(grant)}
          </Text>
        </Flex>
      </Card>
    </Tooltip>
  )
}
