import {useOrganizationRolesList} from '@/data/organizations/useOrganizationRoleList'
import {useDeleteUserFromOrg} from '@/data/users/useDeleteUserFromOrg'
import {DialogToast, Organization, OrganizationMember} from '@/types/index'
import {List, MemberPreview} from '@/ui/index'
import {useToast} from '@sanity/ui'
import React, {useCallback} from 'react'

type Props = {
  org: Organization
  members: OrganizationMember[]
  Dialog: React.ElementType
  hideDialog: (dialogToast: DialogToast) => void
}

export const RemoveOrgMembersDialog = ({org, members, hideDialog, Dialog}: Props) => {
  const {data: roles = []} = useOrganizationRolesList(org.id)
  const {deleteUser, isDeleting} = useDeleteUserFromOrg()
  const roleName = (name: string) => roles?.find((role) => role.name === name)?.name

  const toast = useToast()

  const handleRemoveMembers = useCallback(() => {
    const [firstMember] = members
    deleteUser(
      {orgId: org.id, userId: firstMember.sanityUserId},
      {
        onSuccess: () => {
          hideDialog({
            description: 'Removed member from organization',
            status: 'success',
          })
        },
        onError: (error) => {
          toast.push({
            title: 'Failed to remove member from organization',
            description: error.message,
            status: 'error',
          })
        },
      }
    )
  }, [deleteUser, members, org.id, hideDialog, toast])

  return (
    <Dialog
      id="remove-org-member"
      header={`Remove member${members.length === 1 ? '' : 's'} from organization`}
      title={
        members.length === 1
          ? `Do you really want to remove this user from ${org.name}?`
          : `Do you really want to remove ${members.length} member${
              members.length === 1 ? '' : 's'
            } from ${org.name}?`
      }
      description={
        members.length === 1
          ? `The user will lose access to the organization and all the related rights and permissions.`
          : `The following members will lose access to the organization and all the related rights and permissions.`
      }
      onConfirm={handleRemoveMembers}
      loading={isDeleting}
    >
      <List>
        {members.map((member) => (
          <li key={member.sanityUserId}>
            <MemberPreview userId={member.sanityUserId} subtitle={roleName(member.roles[0].name)} />
          </li>
        ))}
      </List>
    </Dialog>
  )
}
