import {Project} from '../../types'
import {getDatasetsPromise} from './controller'
import {useQuery} from '@tanstack/react-query'
import {getDatasetsListKey, STALE_TTL} from './cache'
import {useRequiredProjectPermissions} from '../../context'

export function useDatasets(projectId?: Project['id']) {
  const canReadProjectDatasets = useRequiredProjectPermissions(
    [{permissionName: 'sanity.project.datasets', grantName: 'read'}],
    projectId
  )

  return useQuery({
    queryKey: getDatasetsListKey(projectId),
    queryFn: () => getDatasetsPromise(projectId!),
    staleTime: STALE_TTL,
    enabled: !!projectId && canReadProjectDatasets,
  })
}
