import {useQuery, UseQueryResult} from '@tanstack/react-query'
import {CustomerInvoice} from '../../types/models'
import {getCustomerInvoices} from './controller'
import {getOrganizationInvoicesKey} from './cache'

export function useOrganizationInvoices(
  customerId: string
): UseQueryResult<CustomerInvoice[], Error> {
  return useQuery({
    queryKey: getOrganizationInvoicesKey(customerId),
    queryFn: () => getCustomerInvoices(customerId),
  })
}
