import {
  type ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  type Row,
  useReactTable,
} from '@tanstack/react-table'
import {useWindowVirtualizer} from '@tanstack/react-virtual'
import {isValidElement} from 'react'
import styled from 'styled-components'

import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from '../table'
import {type CommonCellProps} from '../table/CommonCell'

const StyledTableBody = styled(TableBody)`
  position: relative;

  &:not([hidden]) {
    display: grid;
  }
`

const StyledTableRow = styled(TableRow)`
  display: flex;
  width: 100%;
`

const StyledTableRowVirtualized = styled(StyledTableRow)`
  position: absolute;
`

const StyledTableHeader = styled(TableHeader)`
  display: grid;
`

const StyledTable = styled(Table)`
  display: grid;
`

const StyledTableHead = styled(TableHead)`
  display: flex;
`

const StyledTableCell = styled(TableCell)`
  display: flex;
`

type InternalColumnDef<T> = ColumnDef<T> & {
  cellFlex?: number
  cellHidden?: boolean
  cellMaxWidth?: CommonCellProps['cellMaxWidth']
  cellMinWidth?: CommonCellProps['cellMinWidth']
  cellPadding?: CommonCellProps['padding']
  headerPadding?: CommonCellProps['padding']
}

export type TableVirtualizedColumnDef<T> = InternalColumnDef<T>

export interface TableVirtualizedProps {
  _debug?: boolean
  columnsDefinitions: TableVirtualizedColumnDef<any>[]
  data: unknown[]
  estimateSize: () => number
  overscan?: number
  stickyHeader?: boolean
}

export function defineTableVirtualizedColumns<T>(
  columns: TableVirtualizedColumnDef<T>[]
): TableVirtualizedColumnDef<T>[] {
  return columns
}

export function defineTableVirtualizedCell<T>(
  column: TableVirtualizedColumnDef<T>
): TableVirtualizedColumnDef<T> {
  return column
}

/**
 * A virtualized table component that efficiently renders a large number of rows.
 *
 * Example:
 *
 * ```tsx
 *   <TableVirtualized
 *     columnsDefinitions={[
 *       {
 *         accessorKey: 'member',
 *         header: 'Member',
 *         cell: (ctx) => {
 *           const value = ctx.getValue() as string;
 *           return <Text size={1}>{value}</Text>;
 *         },
 *       },
 *       {
 *         accessorKey: 'role',
 *         header: 'Role',
 *         cell: (ctx) => {
 *           const value = ctx.getValue() as string;
 *           return <Text size={1}>{value}</Text>;
 *         },
 *       },
 *     ]}
 *     data={[
 *       {
 *         member: 'User-1',
 *         role: 'Manager',
 *       },
 *       {
 *         member: 'User-2',
 *         role: 'Developer',
 *       },
 *     ]}
 *     estimateSize={() => 45}
 *     scrollElement={scrollEl}
 *     stickyHeader
 *   />
 * ```
 */
export function TableVirtualized(props: TableVirtualizedProps) {
  const {
    _debug = false,
    columnsDefinitions,
    data,
    estimateSize,
    overscan = 10,
    stickyHeader,
  } = props

  const table = useReactTable({
    data,
    columns: columnsDefinitions,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  const {rows} = table.getRowModel()

  const rowVirtualizer = useWindowVirtualizer({
    count: rows.length,
    estimateSize,
    overscan,
  })

  return (
    <StyledTable data-debug={_debug ? '' : undefined}>
      <StyledTableHeader sticky={stickyHeader}>
        {table.getHeaderGroups().map((headerGroup) => {
          return (
            <StyledTableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                const columnDef = header.column.columnDef as InternalColumnDef<unknown>

                if (columnDef.cellHidden) return null

                const cellFlex = columnDef?.cellFlex || 1
                const colWidth = columnDef?.cellMaxWidth
                const padding = columnDef?.headerPadding
                const colMinWidth = columnDef?.cellMinWidth

                const renderedHeader = flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )

                const isElementHeader = isValidElement(renderedHeader)
                const isStringHeader = typeof renderedHeader === 'string'

                return (
                  <StyledTableHead
                    cellFlex={cellFlex}
                    cellMaxWidth={colWidth}
                    cellMinWidth={colMinWidth}
                    key={header.id}
                    padding={padding}
                    title={isStringHeader ? renderedHeader : undefined}
                  >
                    {isElementHeader ? renderedHeader : null}
                  </StyledTableHead>
                )
              })}
            </StyledTableRow>
          )
        })}
      </StyledTableHeader>

      <StyledTableBody style={{height: rowVirtualizer.getTotalSize()}}>
        {rowVirtualizer.getVirtualItems().map((virtualRow) => {
          const row = rows[virtualRow.index] as Row<unknown>

          return (
            <StyledTableRowVirtualized
              style={{transform: `translateY(${virtualRow.start}px)`}}
              data-index={virtualRow.index}
              key={row.id}
              ref={rowVirtualizer.measureElement}
            >
              {row.getVisibleCells().map((cell) => {
                const columnDef = cell.column.columnDef as InternalColumnDef<unknown>

                if (columnDef?.cellHidden) return null

                const cellFlex = columnDef?.cellFlex || 1
                const cellMaxWidth = columnDef?.cellMaxWidth
                const cellPadding = columnDef?.cellPadding
                const colMinWidth = columnDef?.cellMinWidth

                const content = flexRender(cell.column.columnDef.cell, cell.getContext())

                return (
                  <StyledTableCell
                    cellFlex={cellFlex}
                    cellMaxWidth={cellMaxWidth}
                    cellMinWidth={colMinWidth}
                    key={cell.id}
                    padding={cellPadding}
                  >
                    {content}
                  </StyledTableCell>
                )
              })}
            </StyledTableRowVirtualized>
          )
        })}
      </StyledTableBody>
    </StyledTable>
  )
}
