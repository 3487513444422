import React, {memo, useCallback, useEffect, useMemo, useState, useTransition} from 'react'
import {Stack, Box, Button, Flex, Text, Card, TextInput} from '@sanity/ui'
import {ArchiveIcon, MasterDetailIcon, TrashIcon, SearchIcon} from '@sanity/icons'
import {ACTIVE_LARGE_TABLE, ACTIVE_SMALL_TABLE} from './tableConfig'
import {
  TableEmpty,
  ProjectItemLarge,
  ProjectItemSmall,
  Table,
  TableBody,
  TabHeader,
  DeleteProjectDialog,
  useConfirmDialog,
  ArchiveProjectDialog,
  CreateProjectDialog,
  Spinner,
} from '@/ui/index'
import {Project, TableRowAction} from '@/types/index'
import {useCurrentScopeContext, useRoutePath} from '@/context/index'

const FilteredProjects = memo(function FilteredProjects(props: {
  filter: string
  mounted: boolean
  onCreateProject: () => void
  projects?: Project[]
  rowActions: TableRowAction[]
  smallLayout: boolean
}) {
  const {filter, mounted, onCreateProject, projects, rowActions, smallLayout} = props

  const ProjectItem = smallLayout ? ProjectItemSmall : ProjectItemLarge

  const filteredProjects = useMemo(() => {
    const filtered =
      projects?.filter(
        (project) =>
          !project.isDisabledByUser &&
          !project.isDisabled &&
          (project.displayName.toLowerCase().includes(filter.toLowerCase()) ||
            project.id.toLowerCase().includes(filter.toLowerCase()))
      ) || []
    /**
     * Kap the initial render before mount to projects rendered above the fold,
     * and then render the rest of the list async, non-blocking, after mount.
     * The user can start searching without waiting for the full list to render.
     */
    return mounted ? filtered : filtered.slice(0, 20)
  }, [projects, mounted, filter])

  return (
    <TableBody>
      {filteredProjects.length > 0 ? (
        filteredProjects.map((project) => (
          <ProjectItem key={project.id} project={project} rowActions={rowActions} />
        ))
      ) : (
        <TableEmpty
          illustration={false}
          description={
            <Stack space={4}>
              <Flex justify="center">
                <Text size={1} muted>
                  No projects found
                </Text>
              </Flex>
              <Flex justify="center">
                <Button
                  tone="primary"
                  as="a"
                  text={filter ? 'No results' : 'Create first project'}
                  onClick={onCreateProject}
                  target="_blank"
                />
              </Flex>
            </Stack>
          }
        />
      )}
    </TableBody>
  )
})
FilteredProjects.displayName = 'memo(FilteredProjects)'

export function ActiveProjects({description}: {description: string}) {
  const [pending, startTransition] = useTransition()
  const {basePath} = useRoutePath()
  const {org} = useCurrentScopeContext()
  const {
    showDialog: showDeleteDialog,
    Dialog: DeleteDialog,
    hideDialog: hideDeleteDialog,
  } = useConfirmDialog()
  const {
    showDialog: showArchiveDialog,
    Dialog: ArchiveDialog,
    hideDialog: hideArchiveDialog,
  } = useConfirmDialog()
  const [activeProjects, setActiveProjects] = useState<Project[]>([])
  const [smallLayout /* , setSmallLayout */] = useState<boolean>(false)
  const [showCreateProjectDialog, setShowCreateProjectDialog] = useState<boolean>(false)
  const [filter, setFilter] = useState<string>('')

  const [mounted, setMounted] = useState(false)
  useEffect(() => {
    /**
     * Wrapping this in a startTransition allows React to render the first 20 projects with high sync priority,
     * and then after mount we schedule an async, interruptible, non-blocking render where React remains responsive while it renders the
     * rest of the list, however long it may be.
     */
    startTransition(() => setMounted(true))
  }, [])

  const onCloseProjectDialog = useCallback(() => {
    setShowCreateProjectDialog(false)
  }, [])

  const onCreateProject = useCallback(() => {
    setShowCreateProjectDialog(true)
  }, [])

  const handleDeleteProject = (project: Project) => {
    setActiveProjects([project])
    showDeleteDialog()
  }

  const handleArchiveProject = (project: Project) => {
    setActiveProjects([project])
    showArchiveDialog()
  }

  const handleSetFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    startTransition(() => setFilter(e.target.value))
  }

  const rowActions: TableRowAction[] = [
    {
      title: 'View project',
      icon: MasterDetailIcon,
      href: {basePath: `${basePath}/project`, rowId: true},
    },
    'divider',
    {
      title: 'Archive project',
      icon: ArchiveIcon,
      tone: 'critical',
      onAction: handleArchiveProject,
      permissions:
        org?.id === 'personal'
          ? undefined
          : [{permissionName: 'sanity.project', grantName: 'delete'}],
      dialog: activeProjects[0] && (
        <ArchiveProjectDialog
          key="archive-projects-dialog"
          project={activeProjects[0]}
          hideDialog={hideArchiveDialog}
          Dialog={ArchiveDialog}
        />
      ),
    },
    {
      title: 'Delete project',
      icon: TrashIcon,
      tone: 'critical',
      onAction: handleDeleteProject,
      permissions:
        org?.id === 'personal'
          ? undefined
          : [{permissionName: 'sanity.project', grantName: 'delete'}],
      dialog: activeProjects[0] && (
        <DeleteProjectDialog
          key="delete-projects-dialog"
          project={activeProjects[0]}
          hideDialog={hideDeleteDialog}
          Dialog={DeleteDialog}
        />
      ),
    },
  ]

  const headers = smallLayout ? ACTIVE_SMALL_TABLE : ACTIVE_LARGE_TABLE

  return (
    <>
      <Stack space={4}>
        {rowActions.map((action) => typeof action !== 'string' && action.dialog)}
        <TabHeader
          title={org?.id === 'personal' ? 'Active projects' : 'Organization projects'}
          description={description}
        />
        <Card>
          <TextInput
            placeholder="Search projects"
            icon={pending ? Spinner : SearchIcon}
            onChange={handleSetFilter}
          />
        </Card>
        <Box>
          <Table headers={headers}>
            <FilteredProjects
              filter={filter}
              mounted={mounted}
              onCreateProject={onCreateProject}
              projects={org?.projects}
              rowActions={rowActions}
              smallLayout={smallLayout}
            />
          </Table>
        </Box>
      </Stack>

      {showCreateProjectDialog && <CreateProjectDialog onClose={onCloseProjectDialog} />}
    </>
  )
}
ActiveProjects.displayName = 'ActiveProjects'
