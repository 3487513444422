import {TableHeader} from '@/types/index'

export const MEMBERS_TABLE: TableHeader[] = [
  {
    id: 'picture',
    label: 'Picture',
    disableTabButtonRender: true,
    columnWidth: '45px',
  },
  {
    id: 'name',
    label: 'Name',
    columnWidth: 3,
  },
  // {
  //   id: 'projects',
  //   label: 'Projects',
  //   columnWidth: 2,
  // },
  {
    id: 'Role',
    label: 'Role',
    columnWidth: 2,
    hideOnMobile: true,
  },
  {
    id: 'actions',
    label: 'Actions',
    disableTabButtonRender: true,
    alignment: 'right',
    // hideOnMobile: true,
  },
]
export const INVITATIONS_TABLE: TableHeader[] = [
  {
    id: 'email',
    label: 'Email',
    columnWidth: 4,
  },
  {
    id: 'Role',
    label: 'Role',
    columnWidth: 2,
    hideOnMobile: true,
  },
  {
    id: 'sent',
    label: 'Invited',
    columnWidth: 2,
    hideOnMobile: true,
  },
  {
    id: 'actions',
    label: 'Actions',
    disableTabButtonRender: true,
  },
]
