import {useMutation, useQueryClient} from '@tanstack/react-query'
import {
  createSetupIntent,
  setPaymentSourceForCustomer,
} from '@/data/organizations/controller/payment'
import {createOrg, deleteOrg} from '@/data/organizations/controller/details'
import {type Organization, type CustomerData} from '@/types/models/organization'
import {getOrganizationsKey} from '@/data/organizations/cache'
import {doWithRecaptcha} from '@/ui/recaptcha/recaptchaUtils'
import {useElements, useStripe} from '@stripe/react-stripe-js'
import {useCallback} from 'react'
import {CardNumberElement} from '@stripe/react-stripe-js'
import {getCurrentUserPermissionsKey} from '../access/cache'

type CreateOrgData = {
  name: string
  orgData: CustomerData
  cardElement?: any
  hasEmptyCard: boolean
  recaptchaEnabled: boolean
  reCaptchaScript: any
  hideCardForm: boolean
}

class CreateOrgError extends Error {
  constructor(
    message: string,
    public createdOrgId?: string
  ) {
    super(message)
    this.name = 'CreateOrgError'
  }
}

export function useCreateOrg() {
  const queryClient = useQueryClient()
  const stripe = useStripe()
  const elements = useElements()

  const getCardElement = useCallback(
    ({hideCardForm}) => {
      const cardElement = elements?.getElement(CardNumberElement)

      if (hideCardForm) {
        return null
      }

      // Throw unless the card element is set to hidden
      if (!cardElement) {
        console.error('Stripe.js: No card element found')
        throw new CreateOrgError(
          'There was a technical problem with Stripe. Please try again by reloading the page.'
        )
      }
      return cardElement
    },
    [elements]
  )

  return useMutation({
    mutationKey: ['createOrg'],
    mutationFn: async ({
      name,
      hasEmptyCard,
      orgData,
      recaptchaEnabled,
      reCaptchaScript,
      hideCardForm,
    }: CreateOrgData) => {
      if (!stripe || !elements) {
        throw new CreateOrgError('Stripe not initialized')
      }

      // 1. Get a reference to a mounted CardElement unless it is set to hidden
      const cardElement = getCardElement({hideCardForm})

      // 2. Create the organization
      const createdOrg = await createOrg({name}, orgData)

      // 3. If the organization was not created, throw an error
      if (!createdOrg) {
        throw new CreateOrgError('Failed to create organization')
      }

      // 4. If the card form is not hidden and the card element is not null, create a setup intent
      if (!hasEmptyCard && cardElement) {
        const clientSecret = await doWithRecaptcha(
          recaptchaEnabled,
          reCaptchaScript,
          'setup_intent',
          (recaptchaToken) => {
            return createSetupIntent(createdOrg.id, recaptchaToken).catch((error) => {
              console.error('Error creating setup intent:', error)

              return Promise.resolve(null)
            })
          }
        )

        // 5. If the client secret was not created, throw an error
        if (!clientSecret) {
          throw new CreateOrgError(
            'Could not create a payment source. Please try again.',
            createdOrg.id
          )
        }

        const {error, setupIntent} = await stripe.confirmCardSetup(clientSecret, {
          payment_method: {
            card: cardElement,
          },
        })

        // 6. If there is an error with the setup intent, throw an error
        if (error) {
          throw new CreateOrgError(error.message ?? 'Unknown error', createdOrg.id)
        }

        // 7. If the setup intent does not have a payment method, throw an error
        if (!setupIntent?.payment_method) {
          throw new CreateOrgError(
            'Could not confirm your card. Please contact support.',
            createdOrg.id
          )
        }

        // 8. Set the payment source for the customer
        await doWithRecaptcha(
          recaptchaEnabled,
          reCaptchaScript,
          'payment_source',
          (recaptchaToken) => {
            if (!setupIntent.payment_method) {
              throw new CreateOrgError(
                'Could not confirm your card. Please contact support.',
                createdOrg.id
              )
            }

            return setPaymentSourceForCustomer(
              createdOrg.id,
              setupIntent.payment_method,
              recaptchaToken
            )
          }
        )
      }

      // Optimistically update the organizations list
      queryClient.setQueryData(getOrganizationsKey(), (orgs: Organization[] | undefined) => {
        if (!orgs) return [createdOrg]
        const existingOrgIndex = orgs.findIndex((org) => org.id === createdOrg.id)
        if (existingOrgIndex !== -1) {
          return orgs.map((org) => (org.id === createdOrg.id ? createdOrg : org))
        } else {
          return [...orgs, createdOrg]
        }
      })

      return createdOrg
    },
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: getOrganizationsKey()})
      /**
       * You have to refetch the user's permissions after creating an org
       * Otherwise you won't have any permissions for anything you just created.
       */
      queryClient.invalidateQueries({queryKey: getCurrentUserPermissionsKey()})
    },
    onError: (error) => {
      if (error instanceof CreateOrgError && error.createdOrgId) {
        deleteOrg(error.createdOrgId)
      }
    },
  })
}
