import {FormField} from '@/components/form'
import {Text, Button, Dialog, Flex, Stack, TextInput, useToast} from '@sanity/ui'
import {urlRegex} from '@/utils/index'
import {useCallback} from 'react'
import {useForm} from 'react-hook-form'
import {useStudios} from '@/data/studios/useStudios'
import {Prompt} from '@/components/index'
import {environment} from '@/data/util/environment'

export function AddStudioDialog(props: {onClose: () => void; projectId: string}) {
  const {onClose, projectId} = props
  const {postUserApplicationMutation} = useStudios(projectId)
  const toast = useToast()

  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: {errors},
  } = useForm<{studioHost: string}>({
    defaultValues: {studioHost: ''},
    values: {studioHost: ''},
  })

  const onSaveHost = useCallback(() => {
    postUserApplicationMutation.mutate(
      {
        urlType: 'external',
        appHost: getValues('studioHost'),
      },
      {
        onSuccess() {
          toast.push({
            title: 'Studio host added',
            status: 'success',
          })
          onClose()
        },
      }
    )
  }, [getValues, onClose, postUserApplicationMutation, toast])

  const handleStudioHostChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue('studioHost', event.target.value)
    },
    [setValue]
  )

  const footer = (
    <Stack paddingX={4} paddingY={3}>
      <Button
        onClick={handleSubmit(onSaveHost)}
        text="Add studio host"
        tone="primary"
        disabled={postUserApplicationMutation.isPending}
      />
    </Stack>
  )

  return (
    <Dialog
      onClose={onClose}
      onClickOutside={onClose}
      header="Add studio"
      footer={footer}
      id="addStudio"
      width={1}
    >
      <Flex direction="column">
        <Stack as="form" paddingX={4} paddingY={2} space={5} onSubmit={handleSubmit(onSaveHost)}>
          <Text muted size={1}>
            Studios you are hosting manually outside of Sanity can be added here. If you intended to
            create a new studio, please follow the process in the{' '}
            <a
              href={`https://www.${environment.host}/docs/installation`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {`Sanity documentation `}
            </a>
            instead.
          </Text>

          {postUserApplicationMutation.error?.message && (
            <Prompt
              cardProps={{marginTop: 3}}
              icon="error-outline"
              layout="column"
              description={
                <Stack space={3}>
                  <Text size={2}>{postUserApplicationMutation.error?.message}</Text>
                </Stack>
              }
              tone="critical"
            />
          )}

          <FormField
            label="Custom studio URL"
            description="If you are using custom studio deployment, you can add your studio URL here."
            error={errors?.studioHost?.message}
          >
            <TextInput
              radius={2}
              {...register('studioHost', {
                required: 'A studio host is required',
                pattern: {
                  value: urlRegex,
                  message: 'The URL format is not valid',
                },
              })}
              onChange={handleStudioHostChange}
            />
          </FormField>
        </Stack>
      </Flex>
    </Dialog>
  )
}
