import {Card, Stack, Text, TextInput, Button, Grid, useToast, Inline} from '@sanity/ui'
import {ArrowRightIcon, CopyIcon} from '@sanity/icons'
import {DEFAULT_DATASET_NAME, MANAGE_INTEGRATION_VALUE} from '../form'
import {useCurrentScopeContext} from '@/context/index'
import {buildStudioUrl, isProduction} from '@/utils/general'
import {useDatasets} from '@/data/datasets'
import {useJourneyProject} from '@/data/journey'
import {Separator} from '../general/separator'
import {OverviewModule} from '../module'
import {motion} from 'framer-motion'
import {sendAmplitudeTrackingEvent} from '@/utils/tracking'
import {useProjectOnboardingData} from '@/data/journey/useProjectOnboardingData'
import {useTemplateData} from '@/data/projects/useTemplateData'

function Container(props: {children: React.ReactNode}) {
  return (
    <motion.div initial={{opacity: 0, height: 0}} animate={{opacity: 1, height: 'auto'}}>
      <OverviewModule title="Next steps">
        <Card
          padding={4}
          paddingBottom={3}
          radius={3}
          tone="default"
          border
          style={{
            boxShadow: '0px 0px 6px var(--card-badge-primary-bg-color)',
            backgroundColor: 'var(--card-avatar-gray-fg-color)',
          }}
        >
          {props.children}
        </Card>
      </OverviewModule>
    </motion.div>
  )
}

function CLICommand(props: {value: string}) {
  const {value} = props
  const {projectId} = useCurrentScopeContext()
  const toast = useToast()
  const environment = isProduction ? '' : 'SANITY_INTERNAL_ENV=staging'
  const command = `${environment} ${value}`.trim()

  const trackCliCommandInteraction = () => {
    sendAmplitudeTrackingEvent('CLI Snippet Copied', projectId, undefined, {
      snippet: command,
      snippet_source: 'manage project overview',
    })
  }

  return (
    <Grid gap={2} style={{gridTemplateColumns: '1fr auto'}}>
      <TextInput
        value={command}
        readOnly
        fontSize={1}
        padding={3}
        style={{fontFamily: 'monospace'}}
        onFocus={(e) => {
          trackCliCommandInteraction()
          return e.target.select()
        }}
      />
      <Button
        icon={CopyIcon}
        text="Copy"
        mode="ghost"
        padding={3}
        onClick={() => {
          trackCliCommandInteraction()
          navigator.clipboard.writeText(command)
          toast.push({
            title: 'Copied to clipboard',
            description: 'Paste the command in your Terminal to continue',
            status: 'info',
          })
        }}
      />

      <Button
        as="a"
        href="https://www.sanity.io/help/cli-errors"
        target="_blank"
        style={{gridColumn: '1 / span 2', justifySelf: 'end'}}
        mode="bleed"
        muted
        tone="primary"
        padding={2}
        text="Having issues with the CLI?"
      />
    </Grid>
  )
}

export function ProjectOverviewEjectCard() {
  const {project, projectId} = useCurrentScopeContext()
  const {data: datasets, isLoading: datasetsLoading} = useDatasets(project?.id)
  const {data: journeyData, isLoading: journeyLoading} = useJourneyProject(projectId!)
  const {data: projectOnboardingData, isLoading: onboardingLoading} = useProjectOnboardingData()
  const {data: templateData, isLoading: templateLoading} = useTemplateData(
    projectOnboardingData?.templateSlug
  )

  const createdThroughManage = project?.metadata?.integration === MANAGE_INTEGRATION_VALUE
  const createdThoughOnboarding = project?.metadata?.integration === 'onboarding'

  const deployedThroughJourney = Boolean(journeyData) && Boolean(project?.studioHost)
  const hasEjected = Boolean(project?.metadata?.cliInitializedAt)

  /* If the project has been initialized with CLI, don't show the card */
  if (hasEjected || datasetsLoading || journeyLoading || onboardingLoading || templateLoading) {
    return null
  }

  const datasetName = datasets?.[0]?.name || DEFAULT_DATASET_NAME

  /* If a Studio has been deployed through the journey service */
  if (deployedThroughJourney) {
    return (
      <Container>
        <Stack space={4}>
          <Text size={2} weight="semibold">
            Step 1: Visit your new Sanity Studio
          </Text>
          <Text size={2} muted>
            We've deployed a Studio with your initial schema and a step-by-step guide to help you
            get started.
          </Text>
          <Inline>
            <Button
              mode="ghost"
              as="a"
              padding={3}
              text="Open Sanity Studio"
              iconRight={ArrowRightIcon}
              href={buildStudioUrl(project!.studioHost!)}
              target="_blank"
            />
          </Inline>

          <Separator />

          <Text size={2} weight="semibold">
            Step 2: Continue developing locally with the CLI
          </Text>
          <Text size={2} muted>
            Run this command in your Terminal to further customize your schema and Studio with code.
            This will install the Sanity CLI and initialize your project.
          </Text>
          <CLICommand value={`npm create sanity@latest -- --quickstart ${projectId}`} />
        </Stack>
      </Container>
    )
  }

  /* If the project has been created through Manage or onboarding, and is using template */
  if ((createdThroughManage || createdThoughOnboarding) && templateData) {
    const shorthandGitUrl = templateData?.repository.split('github.com/')[1] // e.g. 'sanity-io/sanity-template-nextjs-blog'
    return (
      <Container>
        <Stack space={4}>
          <Text size={2} weight="semibold">
            Initialize your project with the CLI
          </Text>
          <Text size={2} muted>
            Run this command in your Terminal to continue setting up your project.
          </Text>
          <CLICommand
            value={`npm create sanity@latest -- --template ${shorthandGitUrl} --project ${projectId} --dataset ${datasetName}`}
          />
        </Stack>
      </Container>
    )
  }

  /* If the project has been created through Manage or onboarding, but not deployed */
  if (createdThroughManage || createdThoughOnboarding) {
    return (
      <Container>
        <Stack space={4}>
          <Text size={2} weight="semibold">
            Initialize your project with the CLI
          </Text>
          <Text size={2} muted>
            Run this command in your Terminal to continue setting up your project.
          </Text>
          <CLICommand
            value={`npm create sanity@latest -- --project ${projectId} --dataset ${datasetName} --template clean`}
          />
        </Stack>
      </Container>
    )
  }

  return null
}
