import React from 'react'
import * as Sentry from '@sentry/nextjs'

import {ErrorComponent} from './error'

function getErrorMessage(error: unknown): string {
  if (error instanceof Error) {
    return error.message
  }

  return 'unknown error'
}

function getError(error: unknown): Error | undefined {
  if (error instanceof Error) {
    return error
  }

  return undefined
}

export class ErrorBoundary extends React.Component<unknown, {hasError: boolean; error?: unknown}> {
  constructor(props: any) {
    super(props)

    // Define a state variable to track whether is an error or not
    this.state = {hasError: false}
  }
  static getDerivedStateFromError(error: unknown) {
    // Update state so the next render will show the fallback UI

    return {hasError: true, error}
  }

  // eslint-disable-next-line class-methods-use-this
  componentDidCatch(error: unknown) {
    Sentry.captureException(error)
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorComponent
          message={getErrorMessage(this.state.error)}
          error={getError(this.state.error)}
        />
      )
    }

    return (this.props as {children: React.ReactNode}).children
  }
}
