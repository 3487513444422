import {
  Card,
  Flex,
  Text,
  Tooltip,
  ThemeColorToneKey,
  useTheme,
  Box,
  Inline,
  Stack,
  Placement,
  Layer,
} from '@sanity/ui'
import {Icon} from '@sanity/icons'
import React from 'react'
import styled from 'styled-components'
import {useProjectUsageStatus} from '@/context/index'
import {Organization, Project, ResourceIds} from '@/types/models'
import {StatusConfigContext} from '@/context/use-project-usage-status/statusConfig'

type Props = {
  project: Project
  org?: Organization
  placement?: Placement | undefined
  resourcesToCheck?: ResourceIds[] | undefined
  disabled?: boolean
  context?: StatusConfigContext
}

const StyledBox = styled(Box)`
  * {
    text-align: left !important; //@todo: remove important
  }
`

export function ProjectUsageStatusTooltip({
  project,
  org,
  placement,
  resourcesToCheck,
  disabled,
  context,
}: Props) {
  const {sanity} = useTheme()
  const status = useProjectUsageStatus({
    project,
    org: org,
    resourcesToCheck: resourcesToCheck,
    context,
  })
  const {
    tone: toneKey = 'caution',
    icon = 'warning-outline',
    title = '',
    message = '',
  } = status ?? {}
  const tone = sanity.color.solid[toneKey || ('caution' as ThemeColorToneKey)].enabled.bg

  if (!status) return null

  return (
    <Layer zOffset={2500}>
      <Box as={StyledBox}>
        <Flex justify="flex-start">
          <Tooltip
            disabled={disabled}
            placement={placement || 'bottom'}
            portal
            open
            content={
              <Box padding={4} style={{width: 256}}>
                <Stack space={4}>
                  <Inline space={3}>
                    <Text size={1} muted align="left">
                      <Icon symbol={icon ?? 'warning-outline'} />
                    </Text>
                    <Text size={1} weight="bold" align="left">
                      {title}
                    </Text>
                  </Inline>
                  <Text size={1}>{message}</Text>
                </Stack>
              </Box>
            }
          >
            <Card tone="caution" style={{background: 'transparent'}}>
              <Icon symbol={icon ?? 'warning-outline'} style={{color: tone}} />
            </Card>
          </Tooltip>
        </Flex>
      </Box>
    </Layer>
  )
}
