import React, {useCallback, useState} from 'react'
import {Box, Button, Card, Flex, Heading, Inline, Layer, Stack, Text} from '@sanity/ui'
import {SortIcon, DownloadIcon, CloseIcon} from '@sanity/icons'
import styled from 'styled-components'
import {isToday} from 'date-fns'
import {ExportActivityDialog, useConfirmDialog} from '../dialog'
import {DatasetFilter, DateFilter, EventFilter, MemberFilter} from './filters'
import {useCurrentScopeContext, useFilter, useFilterOptions, useSetFilter} from '@/context/index'
import {useNavbarHeight} from '@/ui/app'

const CardMobile = styled(Card)`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  background: ${({theme}) => theme.sanity.color.base.bg};
  overflow: auto;
`

const StyledCard = styled(Card)<{$top: number}>`
  position: sticky;
  top: ${({$top: top}) => (top ? top : 0)}px;
  @media (max-height: 700px) {
    position: static;
  }
`

export function ActivityFilter({isMobile = false}: {isMobile: boolean}) {
  const options = useFilterOptions()
  const setFilter = useSetFilter()
  const [open, setOpen] = useState<boolean>(false)
  const activeFilters = useFilter()
  const navbarHeight = useNavbarHeight()
  const {scope} = useCurrentScopeContext() ?? {}

  const {
    Dialog: ExportDialog,
    hideDialog: hideTransferDialog,
    showDialog: showTransferDialog,
  } = useConfirmDialog()

  const hasActiveFilters =
    Object.entries(activeFilters)
      .map(([key, val]) => {
        if (key === 'endTime' && isToday(new Date(val as Date))) return null
        return key
      })
      .filter((a) => a).length > 0

  const handleResetFilter = useCallback(() => {
    setFilter({type: 'reset'})
  }, [setFilter])

  const topValue = scope === 'global' ? navbarHeight * 1.4 : navbarHeight * 2.2

  const handleSetOpen = useCallback(() => setOpen(true), [])
  const handleSetClosed = useCallback(() => setOpen(false), [])

  return (
    <>
      {/* Desktop */}
      {!isMobile && (
        <StyledCard $top={topValue}>
          <Stack space={5}>
            <Flex justify="space-between">
              <Heading size={1}>Filters</Heading>
              {hasActiveFilters && (
                <Button
                  mode="bleed"
                  tone="primary"
                  padding={0}
                  onClick={handleResetFilter}
                  style={{background: 'transparent', boxShadow: 'none'}}
                >
                  <Text>Reset all filters</Text>
                </Button>
              )}
            </Flex>

            {options.members && <MemberFilter members={options?.members} />}
            {options.date && <DateFilter />}
            {options.showDatasets && <DatasetFilter />}
            {options.events && <EventFilter events={options.events} />}
          </Stack>
        </StyledCard>
      )}

      {/* Mobile */}
      <ExportActivityDialog Dialog={ExportDialog} hideDialog={hideTransferDialog} />

      {isMobile && (
        <>
          <Card
            padding={2}
            tone="transparent"
            style={{marginLeft: '-1.20rem', marginRight: '-1.20rem'}}
            marginY={4}
          >
            <Flex>
              <Box flex={1} padding={1}>
                <Button
                  text="Filters"
                  icon={SortIcon}
                  style={{width: '100%'}}
                  onClick={handleSetOpen}
                />
              </Box>
              <Box flex={1} padding={1}>
                <Button
                  tone="positive"
                  text="Export"
                  icon={DownloadIcon}
                  style={{width: '100%'}}
                  onClick={showTransferDialog}
                />
              </Box>
            </Flex>
          </Card>

          {open && (
            <Layer zOffset={1000}>
              <CardMobile>
                <Stack space={5}>
                  <Layer style={{position: 'sticky', top: 0}}>
                    <Card padding={3} borderBottom>
                      <Flex align="center" justify="space-between">
                        <Box>
                          <Text weight="semibold">Filters</Text>
                        </Box>
                        <Inline space={2}>
                          {hasActiveFilters && (
                            <Button
                              mode="bleed"
                              tone="primary"
                              padding={0}
                              onClick={handleResetFilter}
                              style={{background: 'transparent', boxShadow: 'none'}}
                            >
                              <Text>Reset all filters</Text>
                            </Button>
                          )}
                          <Button icon={CloseIcon} onClick={handleSetClosed} mode="bleed" />
                        </Inline>
                      </Flex>
                    </Card>
                  </Layer>

                  <Box padding={3}>
                    <Stack space={5}>
                      {options.members && <MemberFilter members={options?.members} />}
                      {options.date && <DateFilter />}
                      {options.showDatasets && <DatasetFilter />}
                      {options.events && <EventFilter events={options.events} />}
                    </Stack>
                  </Box>

                  <Layer style={{position: 'sticky', bottom: 0}}>
                    <Box padding={3}>
                      <Button
                        text="Close"
                        icon={CloseIcon}
                        style={{width: '100%'}}
                        onClick={handleSetClosed}
                      />
                    </Box>
                  </Layer>
                </Stack>
              </CardMobile>
            </Layer>
          )}
        </>
      )}
    </>
  )
}
