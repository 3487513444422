import {useCurrentScopeContext} from '@/context/useCurrentScopeContext'
import {LOCAL_STORAGE_KEY} from './constants'
import {DeveloperView} from './views/DeveloperView'
import {NonDeveloperView} from './views/NonDeveloperView'
import {TemplateView} from './views/TemplateView'
import {useGettingStartedStore} from './store'
import {useUserOnboardingData} from '@/data/journey/useUserOnboardingData'
import {useEffect} from 'react'
import {sendAmplitudeTrackingEvent} from '@/utils/tracking'
import {useProjectOnboardingData} from '@/data/journey/useProjectOnboardingData'
import {useTemplateData} from '@/data/projects/useTemplateData'
import type {Persona} from './store/types'

export function GetStarted() {
  const {projectId, org} = useCurrentScopeContext()
  const {state, dispatch} = useGettingStartedStore()

  const {data: userOnboardingData, isLoading: userLoading} = useUserOnboardingData()
  const {data: projectOnboardingData, isLoading: projectLoading} = useProjectOnboardingData()
  const {data: templateData, isLoading: templateLoading} = useTemplateData(
    projectOnboardingData?.templateSlug
  )

  const isLoading = Boolean(userLoading || projectLoading || templateLoading)
  const localStorageState = localStorage.getItem(LOCAL_STORAGE_KEY(projectId!))

  useEffect(() => {
    sendAmplitudeTrackingEvent('Getting Started Page Viewed', projectId, org?.id, {
      persona: state.persona,
    })
  }, [projectId, org?.id, state.persona])

  useEffect(() => {
    if (localStorageState || isLoading) return

    let persona: Persona = 'developer'
    if (userOnboardingData?.javascriptLevel === 'not_developer') {
      persona = 'non-developer'
    }
    if (templateData) {
      persona = 'template'
    }
    dispatch({
      type: 'getStarted/setPersona',
      value: persona,
    })
  }, [isLoading, userOnboardingData, dispatch, localStorageState, templateData])

  useEffect(() => {
    if (projectOnboardingData?.disabled) {
      dispatch({
        type: 'getStarted/setDismissed',
        value: true,
      })
    }
    if (projectOnboardingData?.framework) {
      dispatch({
        type: 'getStarted/setFramework',
        value: projectOnboardingData.framework,
      })
    }
  }, [projectOnboardingData, dispatch])

  if (isLoading) return null
  if (state.persona === 'template' && templateData) {
    return <TemplateView template={templateData} />
  }
  if (state.persona === 'non-developer') {
    return <NonDeveloperView />
  }
  return <DeveloperView />
}
