import {useMatchesMedia} from '@/utils/views'
import {OrganizationPaymentState} from '@/types/models/paymentstate'
import {isDisplayablePaymentState} from '@/components/arrears/utils'

export function useArrearsBannerHeight(currentPaymentState: OrganizationPaymentState[]): number {
  const isMobile = useMatchesMedia(1)
  const isArrearsBannerVisible = currentPaymentState.some((s) =>
    isDisplayablePaymentState(s.paymentState)
  )
  if (!isArrearsBannerVisible) {
    return 0
  }
  return isMobile ? 20 : 43
}
