import {Stack, Heading as UIHeading, Text, Button} from '@sanity/ui'
import React from 'react'
import styled from 'styled-components'
import {AppLink} from '../appLink'
import {TabHeader} from '@/ui/index'
import {useScrollMarginTop} from '@/utils/index'
import {Heading, Project, Section, Tab, Organization} from '@/types/index'
import {useConfig, useRoutePath} from '@/context/index'

const StyledStack = styled.div`
  &:empty {
    display: none;
  }
`

const TabSection = ({
  section,
  org,
  tab,
  project,
}: {
  section: Section
  org?: Organization
  tab: Tab
  project?: Project
}) => {
  const {component, path, description, title} = section
  const Component = component
  const scrollMargin = useScrollMarginTop()

  return component ? (
    <Stack space={4} as={StyledStack} id={section.path} style={{scrollMarginTop: scrollMargin}}>
      <Component
        section={section}
        title={title}
        description={description}
        project={project}
        org={org}
        tab={tab}
      />
    </Stack>
  ) : (
    <Stack space={3} id={section.path} style={{scrollMarginTop: scrollMargin}}>
      <UIHeading size={1} id={path} as="h3">
        {title}
      </UIHeading>
      {description && (
        <Text size={1} muted as="p">
          {description}
        </Text>
      )}
    </Stack>
  )
}

/**
 * Renders all headings and sections on a single page. This actually has two different modes:
 *
 * Originally "headings" were only headings and didn't have their own content. A heading
 * could be "General settings" and then under there you would have a section ("Project details")
 * which contains the actual content. In this mode you would set `heading.component` to a component
 * which renders the SinglePageTab. Then SinglePageTab should of course _not_ render the heading's component
 * as this would be cause an infinite loop.
 *
 * This worked until we wanted a page (the API tab) where we only have top-level headings and there's
 * content directly under there. To fascilitate this we've added a new prop `renderHeadingComponents`
 * which will cause this component to render all of the headings' `component`.
 */
export const SinglePageTab = ({
  project,
  org,
  tab,
  headings,
  renderHeadingTitles = true,
  renderHeadingComponents = false,
  showUpsell,
  upsellResourceName,
  upsellButtonText,
}: {
  project?: Project
  org?: Organization
  tab: Tab
  headings: Heading[]
  /** Whether or not the heading titles should be rendered. */
  renderHeadingTitles?: boolean
  renderHeadingComponents?: boolean
  // Pass on upsell button props to TabHeader
  showUpsell?: boolean
  upsellResourceName?: string
  upsellButtonText?: string
}) => {
  const scrollMargin = useScrollMarginTop()
  const {baseUrl} = useConfig()
  const {asPath} = useRoutePath()
  /**
   * use the pathname from the asPath to get the current
   * path that doesn't include any hash parameters to ensure
   * that the link works correctly
   * @see https://linear.app/sanity/issue/ENTX-613
   */
  const {pathname} = new URL(asPath, baseUrl)
  return (
    <Stack space={[5, 6, 7]}>
      {headings.length > 0 &&
        headings.map((heading: Heading, index: number) => {
          const sections = (tab.sections || []).filter((h) => h.heading === heading.path)
          if (sections.length === 0 && !heading.component) {
            return null
          }

          return (
            <Stack
              space={[5, 6]}
              key={`${heading.path}-${index}`}
              id={heading.path}
              style={{scrollMarginTop: scrollMargin}}
            >
              {renderHeadingTitles && (
                <Stack space={6}>
                  <TabHeader
                    title={heading.title}
                    description={heading.description}
                    referenceLink={heading.referenceLink || tab.referenceLink}
                    showUpsell={showUpsell}
                    upsellResourceName={upsellResourceName}
                    upsellButtonText={upsellButtonText}
                  />
                  {sections.length == 0 && heading.path && (
                    <AppLink href={`${pathname}/${heading.path}`}>
                      <Button text={heading.pathLabel || 'Open'} mode="ghost" />
                    </AppLink>
                  )}
                </Stack>
              )}

              {renderHeadingComponents && heading.component && (
                <heading.component
                  org={org}
                  project={project}
                  title={heading.title}
                  description={heading.description}
                />
              )}

              {sections.length > 0 && (
                <Stack space={[5, 6, 6]}>
                  {sections.map((section: Section, i: number) => {
                    return (
                      <TabSection
                        key={`${section.path}-${i}`}
                        section={section}
                        org={org}
                        tab={tab}
                        project={project}
                      />
                    )
                  })}
                </Stack>
              )}
            </Stack>
          )
        })}
    </Stack>
  )
}
