/* eslint-disable max-nested-callbacks */
import {grantProjectRolePermissions, revokeProjectRolePermissions} from '@/data/projects'
import {GrantName, PermissionResource, ProjectMemberRole, RoleResource} from '@/types/models'
import {
  AccessDeniedIcon,
  CheckmarkIcon,
  EditIcon,
  SelectIcon,
  WarningOutlineIcon,
} from '@sanity/icons'
import {
  Box,
  Button,
  Card,
  Checkbox,
  Code,
  Flex,
  Grid,
  Heading,
  Inline,
  Label,
  Menu,
  MenuButton,
  MenuItem,
  Stack,
  Text,
  Tooltip,
  useToast,
} from '@sanity/ui'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {createElement, useCallback, useEffect, useMemo, useState} from 'react'
import {
  AbilityGrant,
  grantKey,
  PERMISSION_MODES,
  Resource,
  RESOURCE_GROUPS,
  RESOURCE_MAP,
} from './projectPermissionConfig'
import {getProjectRolesKey} from '@/data/projects/cache'

type PermissionUpdate = {
  permissionResourceId: string
  permissionName: string
  params: Record<string, unknown>
}

type GroupMap = Record<
  string,
  {
    name: string // name of the group
    indeterminate: boolean // are all permissions for the group accounted for
    multi: boolean // does the group have multiple options
    permissionMap: Record<string, Set<string>> // the set of all permissions for the group
    current: string | undefined // the currently determined ability
  }
>

type GrantMap = Record<string, AbilityGrant>
type State = {
  grantMap: GrantMap
  groupMap: GroupMap
}

function getResourceAbilityName(resource: Resource, grantMap: GrantMap) {
  let current: string | undefined
  for (const ability of resource.abilities) {
    const grantCount = ability.grants.filter(
      (grant) => grantMap[grantKey(grant)] !== undefined
    ).length
    if (grantCount === ability.grants.length) {
      current = ability.name
    }
  }
  return current
}

function getResourcePermissionMap(
  resource: Resource,
  current: string | undefined,
  indeterminate: boolean
): Record<string, Set<string>> {
  const permissionMap: Record<string, Set<string>> = {}
  if (!indeterminate && current === undefined) return permissionMap

  if (indeterminate) {
    for (const ability of resource.abilities) {
      for (const grant of ability.grants) {
        if (!permissionMap[grant.type]) {
          permissionMap[grant.type] = new Set()
        }
        permissionMap[grant.type].add(grant.name)
      }
    }
  } else {
    const ability = resource.abilities.find((a) => a.name === current)
    if (!ability) return permissionMap
    for (const grant of ability.grants) {
      if (!permissionMap[grant.type]) {
        permissionMap[grant.type] = new Set()
      }
      permissionMap[grant.type].add(grant.name)
    }
  }

  return permissionMap
}

function getResourceIndeterminate(
  resource: Resource,
  grantMap: GrantMap,
  current: string | undefined
) {
  /**
   * - get all permissions in the group but not in the current level
   * - if any permission exist in the group map, resource is indeterminate
   */

  // Get all grants wanted by the current resource ability
  const currentGrantMap =
    resource.abilities
      .find((ability) => ability.name === current)
      ?.grants.reduce((acc, grant) => {
        acc[grantKey(grant)] = grant
        return acc
      }, {} as GrantMap) || ({} as GrantMap)

  // Get all grants which are referenced by the resource but not in the current ability
  const indeterminateGrantMap = resource.abilities.reduce((acc, ability) => {
    for (const grant of ability.grants) {
      const key = grantKey(grant)
      if (!currentGrantMap[key]) {
        acc[key] = grant
      }
    }
    return acc
  }, {} as GrantMap)

  return Object.values(indeterminateGrantMap)
    .map((grant) => grantMap[grantKey(grant)] !== undefined)
    .some((has) => has)
}

function updateStateGroupMap(state: State) {
  for (const group of RESOURCE_GROUPS) {
    for (const groupResource of group.resources) {
      const stateGroup = state.groupMap[groupResource.name]
      stateGroup.indeterminate = getResourceIndeterminate(
        groupResource,
        state.grantMap,
        stateGroup.current
      )
      stateGroup.permissionMap = getResourcePermissionMap(
        groupResource,
        stateGroup.current,
        stateGroup.indeterminate
      )
    }
  }
}

function updateStateGrantMap(state: State) {
  const grantMap: GrantMap = {}
  for (const group of Object.values(state.groupMap)) {
    const abilities = RESOURCE_MAP[group.name].abilities
    const grants = abilities.find((a) => a.name === group.current)?.grants || []
    for (const grant of grants) {
      grantMap[grantKey(grant)] = grant
    }
  }
  state.grantMap = grantMap
}

function initState(serverResources: RoleResource[]) {
  const grantMap = serverResources.reduce((acc, resource) => {
    for (const grant of resource.grants) {
      const key = `${resource.type}/${grant.name}`
      acc[key] = {
        name: grant.name as GrantName,
        type: resource.type,
      }
    }
    return acc
  }, {} as GrantMap)

  const groupMap = RESOURCE_GROUPS.reduce((acc, group) => {
    for (const groupResource of group.resources) {
      const name = groupResource.name
      const multi = groupResource.abilities.length > 1
      const current = getResourceAbilityName(groupResource, grantMap)
      const indeterminate = getResourceIndeterminate(groupResource, grantMap, current)
      const permissionMap = getResourcePermissionMap(groupResource, current, indeterminate)

      acc[groupResource.name] = {
        name,
        multi,
        current,
        indeterminate,
        permissionMap,
      }
    }
    return acc
  }, {} as GroupMap)

  return {
    grantMap,
    groupMap,
  } as State
}

type Props = {
  projectId: string
  resources: RoleResource[]
  role: ProjectMemberRole
  permissionResources?: PermissionResource[]
}

export function ManagePermissions({projectId, resources, role, permissionResources}: Props) {
  const toast = useToast()
  const queryClient = useQueryClient()
  const [state, setState] = useState<State>({grantMap: {}, groupMap: {}})
  const [edit, setEdit] = useState(false)

  const serverResources = useMemo(() => {
    return resources.filter((resource) => resource.type.startsWith('sanity.project'))
  }, [resources])

  const grantMutation = useMutation({
    mutationFn: (addedGrants: PermissionUpdate[]) =>
      grantProjectRolePermissions(projectId, role.name, addedGrants),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: getProjectRolesKey(projectId)})
      toast.push({title: 'Permissions granted successfully', status: 'success'})
    },
    onError: (err: Error) => {
      toast.push({title: 'Error granting permissions', description: err.message, status: 'error'})
    },
  })

  const revokeMutation = useMutation({
    mutationFn: (removedGrants: PermissionUpdate[]) =>
      revokeProjectRolePermissions(projectId, role.name, removedGrants),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: getProjectRolesKey(projectId)})
      toast.push({title: 'Permissions revoked successfully', status: 'success'})
    },
    onError: (err: Error) => {
      toast.push({title: 'Error revoking permissions', description: err.message, status: 'error'})
    },
  })

  const handleSave = useCallback(() => {
    if (grantMutation.isPending || revokeMutation.isPending) return

    const serverGrants = serverResources.reduce((acc, resource) => {
      for (const grant of resource.grants) {
        const key = `${resource.type}/${grant.name}`
        acc[key] = {
          type: resource.type,
          name: grant.name as GrantName,
        }
      }
      return acc
    }, {} as GrantMap)

    const addedGrants = Object.values(state.grantMap)
      .filter((grant) => serverGrants[grantKey(grant)] === undefined)
      .map((grant) => {
        const permissionResource = permissionResources?.find(
          (pr) => pr.permissionResourceType === grant.type
        )
        if (!permissionResource) return undefined
        return {
          permissionResourceId: permissionResource.id,
          permissionName: grant.name,
          params: {},
        }
      })
      .filter(Boolean) as PermissionUpdate[]

    const removedGrants = Object.values(serverGrants)
      .filter((grant) => state.grantMap[grantKey(grant)] === undefined)
      .map((grant) => {
        const permissionResource = permissionResources?.find(
          (pr) => pr.permissionResourceType === grant.type
        )
        if (!permissionResource) return undefined
        return {
          permissionResourceId: permissionResource.id,
          permissionName: grant.name,
          params: {},
        }
      })
      .filter(Boolean) as PermissionUpdate[]

    if (addedGrants.length > 0) {
      grantMutation.mutate(addedGrants, {
        onSuccess: () => {
          if (removedGrants.length > 0) {
            revokeMutation.mutate(removedGrants)
          }
          setEdit(false)
        },
      })
    } else if (removedGrants.length > 0) {
      revokeMutation.mutate(removedGrants, {
        onSuccess: () => setEdit(false),
      })
    } else {
      setEdit(false)
      toast.push({title: 'No changes to update', status: 'info'})
    }
  }, [grantMutation, revokeMutation, serverResources, state.grantMap, permissionResources, toast])

  const handleEdit = useCallback(() => setEdit(true), [setEdit])
  const handleEditCancel = useCallback(() => {
    setState(initState(serverResources))
    setEdit(false)
  }, [setEdit, serverResources])

  const handlePermissionSelect = useCallback(
    (e, stateGroup) => {
      const {checked} = e.target
      const group = state.groupMap[stateGroup.name]
      if (checked) {
        group.current = RESOURCE_MAP[stateGroup.name].abilities[0].name
      } else {
        group.current = undefined
      }
      updateStateGrantMap(state)
      updateStateGroupMap(state)
      setState({...state})
    },
    [state]
  )

  const handleMultiPermissionSelect = useCallback(
    (value, stateGroup) => {
      const group = state.groupMap[stateGroup.name]
      if (value === 'No access') {
        group.current = undefined
      } else {
        const ability = RESOURCE_MAP[stateGroup.name].abilities.find((a) => a.name === value)
        if (!ability) return
        group.current = ability.name
      }
      updateStateGrantMap(state)
      updateStateGroupMap(state)
      setState({...state})
    },
    [state]
  )

  useEffect(() => {
    const newState = initState(serverResources)
    setState(newState)
  }, [serverResources])

  return (
    <Box>
      <Flex align="flex-start" justify="space-between">
        <Stack>
          <Heading size={2} as="h3">
            Management permissions
          </Heading>
          <Box marginTop={4} paddingBottom={5}>
            <Text muted>
              Define access to project level settings.{' '}
              <a
                href={`https://www.${process.env.host}/docs/roles#0a39a9508bd2`}
                target="_blank"
                rel="noreferrer"
              >
                Learn more →
              </a>
            </Text>
          </Box>
        </Stack>
        {role.isCustom && (
          <Box>
            {edit ? (
              <Button text="Cancel" mode="ghost" onClick={handleEditCancel} />
            ) : (
              <Button
                icon={EditIcon}
                text="Edit management permissions"
                mode="ghost"
                onClick={handleEdit}
              />
            )}
          </Box>
        )}
      </Flex>
      <Stack space={2} marginTop={4}>
        <Card borderBottom paddingBottom={2} paddingX={2} style={{borderBottomWidth: 2}}>
          <Flex>
            <Box flex={0.75}>
              <Label size={1} muted>
                Resource
              </Label>
            </Box>
            <Box flex={0.5}>
              <Label size={1} muted>
                Permisson
              </Label>
            </Box>
            <Box flex={0.1} />
          </Flex>
        </Card>
        {RESOURCE_GROUPS.map((group, index) => (
          <Box key={group.title}>
            <Card paddingY={4} paddingX={2} borderBottom marginTop={index > 0 ? 5 : 0}>
              <Label muted={!edit}>{group.title}</Label>
            </Card>
            {group.resources.map((groupResource) => {
              const stateGroup = state.groupMap[groupResource.name]
              if (!stateGroup) return undefined
              const currentMode = PERMISSION_MODES[stateGroup.current || 'No access']

              return (
                <Card paddingX={4} paddingY={3} borderBottom key={groupResource.name}>
                  <Flex align="center">
                    <Box flex={0.75}>
                      <Text muted={!edit}>{groupResource.title}</Text>
                    </Box>

                    <Flex flex={0.5} justify="flex-start">
                      {stateGroup.multi && edit && (
                        <Box>
                          <MenuButton
                            id="permission-button"
                            button={
                              <Button disabled={!edit} mode="ghost" fontSize={1} padding={1}>
                                <Flex align="center" justify="space-between">
                                  <Card
                                    tone={currentMode?.tone || 'default'}
                                    paddingY={2}
                                    paddingX={3}
                                    radius={5}
                                    marginRight={2}
                                  >
                                    <Inline space={2}>
                                      <Text size={1}>
                                        {currentMode && createElement(currentMode.icon)}
                                      </Text>
                                      <Text size={1}>{stateGroup.current || 'No access'}</Text>
                                    </Inline>
                                  </Card>
                                  <Box padding={1}>
                                    <Text>
                                      <SelectIcon />
                                    </Text>
                                  </Box>
                                </Flex>
                              </Button>
                            }
                            menu={
                              <Menu>
                                <MenuItem
                                  selected={stateGroup.current === 'No access'}
                                  // eslint-disable-next-line react/jsx-no-bind
                                  onClick={() =>
                                    handleMultiPermissionSelect('No access', stateGroup)
                                  }
                                >
                                  <Stack space={2}>
                                    <Flex>
                                      <Card tone="default" padding={2} radius={5}>
                                        <Inline space={2}>
                                          <Text size={1}>
                                            <AccessDeniedIcon />
                                          </Text>
                                          <Text size={1}>No access</Text>
                                        </Inline>
                                      </Card>
                                    </Flex>
                                    <Text size={1} muted>
                                      No access to the resource
                                    </Text>
                                  </Stack>
                                </MenuItem>
                                {groupResource.abilities.map((option) => {
                                  const mode = PERMISSION_MODES[option.name]
                                  return (
                                    <MenuItem
                                      key={option.name}
                                      selected={stateGroup.current === option.name}
                                      // eslint-disable-next-line react/jsx-no-bind
                                      onClick={() =>
                                        handleMultiPermissionSelect(option.name, stateGroup)
                                      }
                                    >
                                      <Stack space={2}>
                                        <Flex>
                                          <Card
                                            tone={mode?.tone || 'default'}
                                            paddingY={2}
                                            paddingX={3}
                                            radius={5}
                                          >
                                            <Inline space={3}>
                                              <Text size={1}>
                                                {mode && createElement(mode?.icon)}
                                              </Text>
                                              <Text size={1}>{option.name}</Text>
                                            </Inline>
                                          </Card>
                                        </Flex>
                                        <Text muted size={1}>
                                          {mode?.description}
                                        </Text>
                                      </Stack>
                                    </MenuItem>
                                  )
                                })}
                              </Menu>
                            }
                          />
                        </Box>
                      )}

                      {stateGroup.multi && !edit && (
                        <Box>
                          <Card
                            tone={currentMode?.tone || 'default'}
                            radius={5}
                            paddingY={2}
                            paddingX={3}
                          >
                            <Inline space={3}>
                              <Text size={1}>
                                {currentMode && createElement(currentMode?.icon)}
                              </Text>
                              <Text size={1}>{stateGroup.current || 'No access'}</Text>
                            </Inline>
                          </Card>
                        </Box>
                      )}

                      <Box>
                        {!stateGroup.multi && edit && (
                          <Checkbox
                            // eslint-disable-next-line react/jsx-no-bind
                            onChange={(e) => handlePermissionSelect(e, stateGroup)}
                            disabled={!edit}
                            checked={stateGroup.current !== undefined}
                          />
                        )}

                        {!stateGroup.multi && !edit && stateGroup.current === undefined && (
                          <Card paddingY={2} paddingX={3} radius={5} tone="transparent">
                            <Inline space={3}>
                              <Text size={1}>
                                <AccessDeniedIcon />
                              </Text>
                              <Text size={1}>Disabled</Text>
                            </Inline>
                          </Card>
                        )}

                        {!stateGroup.multi && !edit && stateGroup.current && (
                          <Card paddingY={2} paddingX={3} radius={5} tone="positive">
                            <Inline space={3}>
                              <Text size={1}>
                                <CheckmarkIcon />
                              </Text>
                              <Text size={1}>Enabled</Text>
                            </Inline>
                          </Card>
                        )}
                      </Box>
                    </Flex>

                    <Flex flex={0.1} justify="flex-end">
                      {stateGroup.indeterminate && (
                        <Tooltip
                          content={
                            <Box padding={3} style={{maxWidth: 300}}>
                              <Stack space={2}>
                                <Text size={1} muted>
                                  Unable to fully determine permission because they have been
                                  modified externally.
                                  <br />
                                  The permission is:
                                  <br />
                                </Text>
                                {Object.keys(stateGroup.permissionMap).map((type) => (
                                  <Stack key={type} space={2}>
                                    <Box marginTop={2}>
                                      <Code size={1}>{type}</Code>
                                    </Box>
                                    <Inline space={2}>
                                      {Array.from(stateGroup.permissionMap[type]).map((grant) => {
                                        const isEnabled =
                                          state.grantMap[`${type}/${grant}`] !== undefined
                                        return (
                                          <Card
                                            shadow={1}
                                            padding={isEnabled ? 1 : 2}
                                            tone={isEnabled ? 'positive' : 'critical'}
                                            radius={2}
                                            key={grant}
                                          >
                                            <Inline space={1}>
                                              {isEnabled && <CheckmarkIcon />}
                                              <Code size={1} muted>
                                                {grant}
                                              </Code>
                                            </Inline>
                                          </Card>
                                        )
                                      })}
                                    </Inline>
                                  </Stack>
                                ))}
                              </Stack>
                            </Box>
                          }
                        >
                          <Card tone="caution" radius={2} padding={2}>
                            <Text>
                              <WarningOutlineIcon />
                            </Text>
                          </Card>
                        </Tooltip>
                      )}
                    </Flex>
                  </Flex>
                </Card>
              )
            })}
          </Box>
        ))}
        {edit && (
          <Card paddingTop={4}>
            <Grid columns={2} gap={2}>
              <Button
                text="Cancel"
                tone="default"
                mode="bleed"
                onClick={handleEditCancel}
                disabled={grantMutation.isPending || revokeMutation.isPending}
              />
              <Button
                text="Save"
                tone="primary"
                onClick={handleSave}
                loading={grantMutation.isPending || revokeMutation.isPending}
              />
            </Grid>
          </Card>
        )}
      </Stack>
    </Box>
  )
}
