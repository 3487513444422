import {Flex, Label} from '@sanity/ui'
import {useMemo} from 'react'
import styled from 'styled-components'

import {Github} from './logos/Github'
import {Google} from './logos/Google'

const Root = styled(Flex)``

export interface ProviderLogoProps {
  provider: 'google' | 'github' | 'sanity' | string
}

export function ProviderLogo(props: ProviderLogoProps) {
  const {provider} = props

  const logo = useMemo(() => {
    if (provider === 'google') return <Google data-provider={provider} />
    if (provider === 'github') return <Github data-provider={provider} />
    if (provider?.startsWith('saml-')) {
      return (
        <Label muted size={0} data-provider={provider}>
          SSO
        </Label>
      )
    }

    return null
  }, [provider])

  const title = useMemo(() => {
    if (provider === 'google') return 'Google'
    if (provider === 'github') return 'GitHub'
    if (provider?.startsWith('saml-')) return 'SAML/SSO'

    return undefined
  }, [provider])

  if (!logo) return null

  return <Root title={title}>{logo}</Root>
}
