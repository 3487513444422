import React, {useCallback, useEffect, useRef, useState} from 'react'
import {Subscription, of} from 'rxjs'
import {catchError} from 'rxjs/operators'
import {Webhook} from '../../types/models'
import {addWebhook, deleteWebhook, getWebhooks} from '.'
import {APIError} from '@/data/util/request'

type InvalidDataError = Error
type AddWebhook = ({name, url, dataset}: Webhook) => void
type DeleteWebhook = (webhookId: string) => void
type WebhooksDataState = {
  error?: Error
  loading: boolean
  data: Webhook[]
  isDeleted: boolean
  isCreated: boolean
}

export function useWebhooks(projectId: string): [WebhooksDataState, AddWebhook, DeleteWebhook] {
  const [webhooks, setWebhooks] = useState<Webhook[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [isDeleted, setIsDeleted] = useState<boolean>(false)
  const [isCreated, setIsCreated] = useState<boolean>(false)
  const [error, setError] = useState<InvalidDataError | undefined>(undefined)

  const addSub: React.MutableRefObject<Subscription | undefined> = useRef()
  const getSub: React.MutableRefObject<Subscription | undefined> = useRef()
  const delSub: React.MutableRefObject<Subscription | undefined> = useRef()

  useEffect(() => {
    return () => {
      if (getSub.current) {
        getSub.current.unsubscribe()
      }
      if (addSub.current) {
        addSub.current.unsubscribe()
      }
      if (delSub.current) {
        delSub.current.unsubscribe()
      }
    }
  }, [])

  useEffect(() => {
    setError(undefined)
    setLoading(true)
    getSub.current = getWebhooks(projectId)
      .pipe(
        catchError((err) => {
          // If the user isn't allowed to see the API tokens, just return an empty list
          if (err.response && [401, 502].includes(err.response.statusCode)) {
            return of([] as Webhook[])
          }
          // Throw everything else
          setError(err)
          throw err
        })
      )
      .subscribe((next) => {
        setWebhooks(next)
        setLoading(false)
        getSub.current?.unsubscribe()
      })
  }, [projectId])

  const _addWebhook = useCallback(
    ({url, name, dataset}: {url: string; name: string; dataset: string}) => {
      setIsCreated(false)
      setError(undefined)
      setLoading(true)
      addSub.current = addWebhook(projectId, {url, name, dataset})
        .pipe(
          catchError((err) => {
            if (err instanceof APIError) {
              setError(err)
              return of(undefined)
            }
            setError(err)
            throw err
          })
        )
        .subscribe((next) => {
          if (next) {
            setWebhooks([...webhooks, next])
            setIsCreated(true)
          }
          setLoading(false)
          addSub.current?.unsubscribe()
        })
    },
    [projectId, webhooks]
  )
  const _delWebhook = useCallback(
    (webhookId: string) => {
      setIsDeleted(false)
      setError(undefined)
      setLoading(true)
      delSub.current = deleteWebhook(projectId, webhookId)
        .pipe(
          catchError((err) => {
            if (err.name === 'HttpError') {
              setError(err)
              return of(undefined)
            }
            setError(err)
            throw err
          })
        )
        .subscribe((next) => {
          if (next) {
            setWebhooks(webhooks.filter((webhook) => webhook.id !== next.id))
            setIsDeleted(true)
          }
          setLoading(false)
          delSub.current?.unsubscribe()
        })
    },
    [projectId, webhooks]
  )
  return [
    {data: webhooks, loading, error, isDeleted, isCreated},
    ({name, url, dataset}: Webhook) => {
      _addWebhook({url, name, dataset})
    },
    (webhookId: string) => {
      _delWebhook(webhookId)
    },
  ]
}
