import React, {useCallback, useEffect, useMemo, useRef} from 'react'
import Link from 'next/link'
import {
  Box,
  Button,
  Card,
  Flex,
  Grid,
  Heading,
  Label,
  Stack,
  Text,
  Inline,
  Badge,
  BadgeTone,
  Menu,
  MenuButton,
  MenuItem,
  Tooltip,
  useToast,
  HeadingSkeleton,
} from '@sanity/ui'
import {
  DatabaseIcon,
  EditIcon,
  TrashIcon,
  EllipsisVerticalIcon,
  InfoOutlineIcon,
} from '@sanity/icons'
import {DatasetPermissionSummary} from '../roles/datasetPermissionSummary'
import {DatasetTagSelect} from './datasetTagSelect'
import {useRoutePath} from '@/context/index'
import {Dataset, DatasetAclMode, Project, Tag, RoleResource} from '@/types/index'
import {formatUnitType, useTimeAgo, useMatchesMedia} from '@/utils/index'
import {useUser, useDatasetRoles} from '@/data/index'
import {InfoTooltip} from '@/components/general'
import {useConfirmDialog, DeleteDatasetDialog, EditDatasetDialog, DatasetItem} from '@/ui/index'
import {AvatarWithFallback} from '@/components/members'
import {nameToInitials} from '@/components/members/utils'
import {useProjectPermissionResources} from '@/data/projects/useProjectRoles'
import {useWindowVirtualizer} from '@tanstack/react-virtual'
import styled from 'styled-components'
import {startCase} from 'lodash'

const DATASET_TONE: {[key in DatasetAclMode]: BadgeTone} = {
  public: 'positive',
  private: 'caution',
  custom: 'default',
}

function formatUsage(
  usage: {value: number; unit: string; limit: number} | undefined
): string | undefined {
  if (usage) {
    const {value, unit, limit} = usage
    return limit
      ? `${formatUnitType(value, unit)} / ${formatUnitType(limit, unit, true)}`
      : formatUnitType(value, unit)
  }
  return undefined
}

const CreatedBy = ({userId}: {userId?: string}) => {
  const {data: user} = useUser(userId)
  return (
    <InfoTooltip disabled={!!userId} description="Couldn't find the creator of this dataset">
      <Flex align="center">
        <Box marginRight={2}>
          <AvatarWithFallback
            title={user?.displayName}
            src={user?.imageUrl || undefined}
            initials={nameToInitials(user?.displayName)}
          />
        </Box>
        <Text size={1}>{user?.displayName || 'Unknown'}</Text>
      </Flex>
    </InfoTooltip>
  )
}

interface Props {
  project: Project
  dataset: Dataset
  addons: Dataset[]
  tags: Tag[]
}

const ListWrapper = styled.div.attrs<{$height: string}>(({$height}) => ({
  style: {
    height: $height,
  },
}))`
  box-sizing: border-box;
  position: relative;
  width: 100%;
`

const ResourceItemWrapper = styled.div.attrs<{$translateY: number}>(({$translateY}) => ({
  style: {
    transform: `translateY(${$translateY}px)`,
  },
}))`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

const LoadingSkeleton = styled(HeadingSkeleton).attrs({animated: true})`
  height: 1em;
  background-color: var(--card-skeleton-color);
  border-radius: 2px;
`

function ContentPermissionsSkeleton() {
  return (
    <Stack space={3}>
      {[...Array(10)].map((_item, i) => (
        <Card
          // eslint-disable-next-line react/no-array-index-key
          key={`skeleton-${i}`}
          border
          radius={2}
          paddingX={2}
          paddingY={4}
          paddingLeft={5}
          style={{minHeight: '70px'}}
        >
          <Flex justify="space-between" align="center">
            <Stack space={1}>
              <Flex gap={2}>
                <LoadingSkeleton style={{width: '150px'}} />
                <LoadingSkeleton style={{width: '100px'}} />
              </Flex>
            </Stack>
            <Box>
              <LoadingSkeleton style={{width: '75px'}} />
            </Box>
          </Flex>
        </Card>
      ))}
    </Stack>
  )
}

export function DatasetView({dataset, addons, project, tags}: Props) {
  const toast = useToast()
  const {basePath} = useRoutePath()
  const createdAt = useTimeAgo(dataset?.createdAt || '', {agoSuffix: true})

  const {
    showDialog: showDeleteDialog,
    Dialog: DeleteDialog,
    hideDialog: hideDeleteDialog,
  } = useConfirmDialog()
  const handleDeleteDataset = useCallback(() => showDeleteDialog(true), [showDeleteDialog])

  const {
    showDialog: showEditDialog,
    Dialog: EditDialog,
    hideDialog: hideEditDialog,
  } = useConfirmDialog()
  const handlEditDataset = useCallback(() => showEditDialog(true), [showEditDialog])

  const {
    data: datasetRoles = [],
    isLoading: loadingDatasetRoles,
    error: errorDatasetRoles,
  } = useDatasetRoles(project, dataset)

  const {
    data: permissionResources,
    isLoading: loadingPR,
    error: errorPR,
  } = useProjectPermissionResources(project.id)

  const isLoading = useMemo(
    () => loadingPR || loadingDatasetRoles,
    [loadingPR, loadingDatasetRoles]
  )
  const error = errorPR || errorDatasetRoles
  useEffect(() => {
    if (error) {
      toast.push({title: error.name, description: error.message, status: 'error'})
    }
  }, [error, toast])

  const isMobile = useMatchesMedia(1)

  const hasTagsFeature = project.features.some((feat) => feat === 'advancedRolesManagement')

  const parentRef = useRef<HTMLDivElement | null>(null)
  const filteredRoles = useMemo(() => {
    return datasetRoles?.filter((role) => role.isCustom) || []
  }, [datasetRoles])

  const roleResourcesMap = useMemo(() => {
    if (!datasetRoles) return {}
    return datasetRoles.reduce(
      (acc, role) => {
        acc[role.name] = Object.keys(role.grants).flatMap((key) =>
          role.grants[key].map((pr) => ({...pr, type: key}))
        )
        return acc
      },
      {} as Record<string, RoleResource[]>
    )
  }, [datasetRoles])

  const virtualizer = useWindowVirtualizer({
    count: filteredRoles.length,
    scrollMargin: parentRef.current?.scrollTop ?? 0,
    estimateSize: useCallback(() => 205, []),
    overscan: 5,
    gap: 22,
    isScrollingResetDelay: 1000,
  })

  return (
    <>
      <DeleteDatasetDialog
        key="delete-dataset"
        dataset={dataset}
        addons={addons}
        project={project}
        Dialog={DeleteDialog}
        hideDialog={hideDeleteDialog}
      />
      <EditDatasetDialog
        key="edit-dataset"
        mode="edit"
        datasets={[dataset]}
        project={project}
        Dialog={EditDialog}
        hideDialog={hideEditDialog}
      />
      <Box marginBottom={5}>
        <Text>
          {dataset.addonFor ? (
            <Link href={`${basePath}/datasets?name=${dataset.addonFor}`}>← {dataset.addonFor}</Link>
          ) : (
            <Link href={`${basePath}/datasets`}>← All datasets</Link>
          )}
        </Text>
      </Box>

      <Flex marginTop={3} align="flex-start">
        <Stack flex={1} marginRight={3} space={3}>
          <Box>
            <Badge tone={dataset.aclMode && DATASET_TONE[dataset.aclMode]} mode="outline">
              {startCase(dataset.aclMode)}
            </Badge>
          </Box>
          <Flex align="center">
            <Box marginRight={3} marginLeft={0}>
              <Heading size={2}>
                <DatabaseIcon />
              </Heading>
            </Box>
            <Text size={4} as="h2" weight="semibold">
              {dataset.name}
            </Text>
          </Flex>
        </Stack>

        <Inline marginLeft={6} space={2}>
          {!isMobile && (
            <Box>
              <Button icon={EditIcon} mode="ghost" text="Edit dataset" onClick={handlEditDataset} />
            </Box>
          )}
          <Box>
            <MenuButton
              button={<Button icon={EllipsisVerticalIcon} mode="ghost" />}
              id="menu-button-role"
              menu={
                <Menu>
                  {isMobile && (
                    <MenuItem icon={EditIcon} text="Edit dataset" onClick={handlEditDataset} />
                  )}
                  <MenuItem
                    text="Delete dataset"
                    icon={TrashIcon}
                    tone="critical"
                    onClick={handleDeleteDataset}
                  />
                </Menu>
              }
            />
          </Box>
        </Inline>
      </Flex>

      <Card borderTop borderBottom paddingY={4} marginTop={5}>
        <Grid columns={[2, 2, 3, 5]} gapX={3} gapY={[5, 5, 4, 4]}>
          <Stack space={3}>
            <Label muted size={1}>
              Created by
            </Label>
            <CreatedBy userId={dataset.createdByUserId || undefined} />
          </Stack>
          <Stack space={3}>
            <Label muted size={1}>
              Created
            </Label>
            <Text size={1}>{createdAt}</Text>
          </Stack>
          <Stack space={3}>
            <Label muted size={1}>
              Size
            </Label>
            <Text size={1}>{formatUsage(dataset.stats?.documents.jsonSizeSum) || 'N/A'}</Text>
          </Stack>
          <Stack space={3}>
            <Label muted size={1}>
              Documents
            </Label>
            <Text size={1}>{formatUsage(dataset.stats?.documents.count) || 'N/A'}</Text>
          </Stack>
          <Stack space={3}>
            <Inline space={1}>
              <Label muted size={1}>
                Attributes
              </Label>
              <Tooltip
                content={
                  <Box padding={3}>
                    <Text size={1} muted>
                      An attribute is any unique attribute/datatype combination
                    </Text>
                  </Box>
                }
              >
                <Text size={0} muted>
                  <InfoOutlineIcon />
                </Text>
              </Tooltip>
            </Inline>
            <Text size={1}>{formatUsage(dataset.stats?.fields.count) || 'N/A'}</Text>
          </Stack>
        </Grid>
      </Card>

      {hasTagsFeature && (
        <Card borderBottom paddingY={3}>
          <Stack space={3}>
            <Label muted size={1}>
              Tags
            </Label>
            <DatasetTagSelect project={project} dataset={dataset} tags={tags} />
          </Stack>
        </Card>
      )}

      {dataset.addonFor !== undefined && dataset.addonFor !== null && (
        <Card marginTop={4} padding={[3, 3, 4]} radius={2} shadow={1} tone="caution">
          <Stack space={3}>
            <Text size={[1, 2, 2]} weight="bold">
              This is an add-on dataset
            </Text>
            <Text size={2}>
              Add-on datasets belong to a parent dataset and follow the parent dataset lifecycle.
            </Text>
          </Stack>
        </Card>
      )}

      {addons.length > 0 && (
        <Stack marginTop={6} space={3}>
          <Heading size={2} as="h3">
            Add-on datasets
          </Heading>
          <Text muted size={1}>
            The dataset has the following add-on datasets
          </Text>
          {addons.map((addon) => (
            <DatasetItem
              key={addon.name}
              project={project}
              dataset={addon}
              tags={tags}
              addons={{}}
              indent={0}
            />
          ))}
        </Stack>
      )}

      {dataset.datasetProfile !== 'comments' && datasetRoles && (
        <>
          <Stack marginTop={6} space={3}>
            <Heading size={2} as="h3">
              Permissions
            </Heading>
            <Text muted size={1}>
              Grant permissions to resources for each custom role. Built in roles have a predefined
              set of grants and can't be edited.
            </Text>
          </Stack>
          <Stack marginTop={4} space={4}>
            {isLoading && <ContentPermissionsSkeleton />}
            {!isLoading && filteredRoles.length > 0 && (
              <Stack space={0} ref={parentRef}>
                <ListWrapper $height={`${virtualizer.getTotalSize()}px`}>
                  {virtualizer.getVirtualItems().map((virtualRow) => {
                    const role = filteredRoles[virtualRow.index]
                    const resources = roleResourcesMap[role.name]

                    return (
                      <ResourceItemWrapper
                        key={role.name}
                        data-index={virtualRow.index}
                        ref={virtualizer.measureElement}
                        $translateY={virtualRow.start - virtualizer.options.scrollMargin}
                      >
                        <DatasetPermissionSummary
                          key={`resource-${dataset.name}-${role.name}`}
                          dataset={dataset}
                          tags={tags}
                          resources={resources}
                          permissionResources={permissionResources}
                          project={project}
                          role={role}
                          readOnly={!role.isCustom}
                          context="dataset"
                        />
                      </ResourceItemWrapper>
                    )
                  })}
                </ListWrapper>
              </Stack>
            )}
            {errorDatasetRoles && (
              <Card border padding={4} radius={3} style={{borderStyle: 'dashed'}}>
                <Stack space={3}>
                  <Text weight="bold">Error loading dataset roles</Text>
                  <Text>{String(errorDatasetRoles)}</Text>
                </Stack>
              </Card>
            )}
            {isLoading === false && !errorDatasetRoles && filteredRoles.length === 0 && (
              <Card border padding={4} radius={2} style={{borderStyle: 'dashed'}}>
                <Stack space={3}>
                  <Text align="center" muted weight="medium">
                    No custom roles
                  </Text>
                  <Text align="center" muted size={1}>
                    Go to{' '}
                    <Link href={`${basePath}/access`} passHref legacyBehavior>
                      <a>Roles</a>
                    </Link>{' '}
                    to add a custom role.
                  </Text>
                </Stack>
              </Card>
            )}
          </Stack>
        </>
      )}
    </>
  )
}
