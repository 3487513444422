import React, {useCallback, useMemo, useState} from 'react'
import {useRouter} from 'next/router'
import {useCreateDatasetStep} from './steps/createDatasetStep'
import {useCreateDatasetIntroStep} from './steps/createDatasetIntroStep'
import {
  useAddBillingDetailsStep,
  useChangePlanStep,
  useCreateNewOrgStep,
  useTransferToOrgStep,
  useWizardStore,
  WizardCompletionCallback,
  WizardDialog,
  WizardParams,
  WizardStep,
} from '@/components/payment-wizard'
import {useProjectSubscription} from '@/data/projects/useProjectSubscription'
import {useOrganizationCanPay} from '@/data/organizations'

export function NewDatasetWizard(props: {
  params: WizardParams
  onComplete?: WizardCompletionCallback
}) {
  const {onComplete: handleWizardComplete, params} = props
  const {plans, project, org} = params
  const router = useRouter()
  const {dispatch, state} = useWizardStore()
  const {data: subscription} = useProjectSubscription(project.id)
  const {data: canPay} = useOrganizationCanPay(org?.id)

  const datasetQuota: number | null = subscription ? subscription.resources.datasets.quota : null
  const numberOfDatasets = params.datasets?.length || 0
  const [maxOverageQuota] = useState(() => subscription?.resources.datasets.maxOverageQuota || null)
  const isEnterpriseOrOrgPlanProject =
    subscription?.plan.planTypeId === 'enterprise' || org?.type === 'org-plan'
  const [showAllWizardSteps] = useState(() => {
    if (state.stepIndex > 0) {
      return true
    }
    if (datasetQuota === null) {
      return false
    }
    const underOrCanNotPay =
      isEnterpriseOrOrgPlanProject ||
      (canPay?.status === true && subscription?.resources.datasets.overageAllowed)
        ? false
        : numberOfDatasets >= datasetQuota

    return maxOverageQuota === null
      ? underOrCanNotPay
      : numberOfDatasets >= maxOverageQuota + datasetQuota
  })

  const onOrganizationAssigned = useCallback(
    (orgId: string) => {
      const basePath = `/organizations/${orgId}`
      const {query} = router
      return router.push(`${basePath}/project/${project.id}/${query.tabId}`, undefined, {
        shallow: true,
      })
    },
    [project, router]
  )
  const enterprisePlan = useMemo(() => plans?.find((p) => p.planTypeId === 'enterprise'), [plans])

  const possiblePlans = useMemo(
    () =>
      plans
        ? plans
            .filter(
              (p) =>
                p.orderable &&
                p.public &&
                ((p.resources.datasets.maxOverageQuota !== null &&
                  p.resources.datasets.maxOverageQuota > numberOfDatasets) ||
                  (p.resources.datasets.maxOverageQuota === null &&
                    (p.resources.datasets.quota || 0) > numberOfDatasets) ||
                  (p.pricingModel === 'per-seat' && p.id !== subscription?.plan?.id)) // per-seat plans are targetable for all plans
            )
            .sort((a, b) => (a.price ?? 0) - (b.price ?? 0))
            .concat(enterprisePlan || [])
        : [],
    [plans, enterprisePlan, numberOfDatasets, subscription]
  )

  const _params: WizardParams = useMemo(
    () => ({
      onOrganizationAssigned,
      ...params,
      plans: possiblePlans,
    }),
    [onOrganizationAssigned, params, possiblePlans]
  )

  const handleWizardClose = useCallback(() => {
    dispatch({type: 'newDatasetWizard/close'})
    dispatch({type: 'reset'})
  }, [dispatch])

  const introStep = useCreateDatasetIntroStep()
  const transferStep = useTransferToOrgStep(_params)
  const createNewOrgStep = useCreateNewOrgStep()
  const addBillingDetails = useAddBillingDetailsStep(_params)
  const changePlanStep = useChangePlanStep(_params)
  const createDatasetStep = useCreateDatasetStep(_params)
  const steps = useMemo(() => {
    let _steps = (
      showAllWizardSteps
        ? [
            introStep,
            transferStep,
            createNewOrgStep,
            addBillingDetails,
            changePlanStep,
            createDatasetStep,
          ]
        : [createDatasetStep]
    ).filter(Boolean) as WizardStep[]
    // Remove intro step if needed
    if (_steps.length === 2 && _steps[0] === introStep && _steps[1] === createDatasetStep) {
      _steps = [createDatasetStep]
    }

    if (state.forceMoveToBillingStep) {
      const index = _steps.indexOf(addBillingDetails!)
      if (index > 0) {
        dispatch({type: 'changePlanWizard/carderror/forceToBillingStep', value: index})
      }
    }
    return _steps
  }, [
    addBillingDetails,
    changePlanStep,
    createDatasetStep,
    createNewOrgStep,
    dispatch,
    introStep,
    showAllWizardSteps,
    state.forceMoveToBillingStep,
    transferStep,
  ])

  if (steps.length === 0) {
    return null
  }

  return (
    <WizardDialog
      onClose={handleWizardClose}
      onComplete={handleWizardComplete}
      steps={steps}
      params={_params}
      title="Create new dataset"
    />
  )
}
