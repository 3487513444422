import {useCallback, useState} from 'react'
import {ActivityFilter, HistoryPeriod, Organization, Project, ResourceIds} from '@/types/models'

export const isProduction = process.env.NEXT_PUBLIC_SANITY_INTERNAL_ENV === 'production'

export const isServer = typeof window === 'undefined'

export const isUndefined = (value: unknown): boolean => {
  return typeof value === 'undefined'
}

export const split = (array: any[], max: number) => {
  const idx = Math.max(0, array.length - max)
  return [array.slice(0, idx), array.slice(idx)]
}

export const splitRight = (array: any[], max: number) => {
  const indexFromMax = array.length > max ? max - 1 : max
  const idx = Math.max(0, array.length - indexFromMax)
  return [array.slice(0, idx), array.slice(idx)]
}

export const slugify = (value: string, maxLength?: number): string => {
  let result = value
  result = result.replace(/^\s+|\s+$/g, '')
  result = result.toLowerCase()

  // remove accents, swap ñ for n, etc
  const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;'
  const to = 'aaaaeeeeiiiioooouuuunc------'

  for (let i = 0, l = from.length; i < l; i++) {
    result = result.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
  }

  result = result
    .replace(/[^a-z0-9 -]/g, '')
    .replace(/\s+/g, '-')
    .replace(/-+/g, '-')

  if (maxLength) {
    return (
      result
        .substring(0, maxLength)
        // Make sure the slug doesn't start or end with a dash
        .replace(/^-|-$/g, '')
    )
  }

  return result
}

export const filterEmptyArray = (values: any[]) =>
  values.filter((value) => typeof value !== 'undefined' && value !== null && value !== '')

export const formatNumber = (num: number): string | number => {
  if (num > 999 && num < 1000000) {
    return `${(num / 1000).toFixed(0)}K`
  } else if (num >= 1000000) {
    return `${(num / 1000000).toFixed(0)}M`
  }
  return num
}

export const formatPrice = (price: number, maximumFractionDigits = 2, currency = 'USD'): string => {
  const isWholeNumber = Number.isInteger(price)
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
    maximumFractionDigits: isWholeNumber ? 0 : maximumFractionDigits,
  }).format(price)
}

export const lowercaseKeys = (obj: any): any => {
  return Object.getOwnPropertyNames(obj || {}).reduce((acc: any, key: string) => {
    acc[key.toLowerCase()] = obj[key]
    return acc
  }, {})
}

export function useCopyToClipboard(): {
  copyToClipboard: (text: string, options?: {onSuccess?: () => void; onError?: () => void}) => void
  success: boolean
  error: boolean
} {
  const [success, setSucces] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)

  const copyToClipboard = useCallback(
    (text: string, options?: {onSuccess?: () => void; onError?: () => void}) => {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          setSucces(true)
          setError(false)
          options?.onSuccess?.()
        })
        .catch(() => {
          setSucces(false)
          setError(true)
          options?.onError?.()
        })
        .finally(() => {
          setSucces(false)
          setError(false)
        })
    },
    []
  )

  return {copyToClipboard, success, error}
}

export function getResourceNameById(id: ResourceIds): string {
  const resourceNames: any = {
    apirequests: 'API Requests',
    apicdnrequests: 'API CDN Requests',
    assets: 'Assets',
    bandwidth: 'Bandwidth',
    users: 'Members',
    non_admin_users: 'Non-admin members', // eslint-disable-line camelcase
    non_viewer_users: 'User seats', // eslint-disable-line camelcase
    documents: 'Documents',
    datasets: 'Datasets',
    listeners: 'Listeners',
    'org-projects': 'Organization projects',
    'playground-projects': 'Playground projects',
  }

  return resourceNames[id]
}

export const studioUrlSuffix = isProduction ? 'sanity.studio' : 'studio.sanity.work'

export const buildStudioUrl = (name: string): string => `https://${name}.${studioUrlSuffix}`

export const getActiveScope = (org?: Organization, project?: Project | null) => {
  if (project) {
    return 'project'
  }
  if (org) {
    return org?.id === 'personal' ? 'user' : 'org'
  }
  return 'global'
}

export const arrayToText = (array: string[], conjunction = 'and'): string => {
  return [array.slice(0, -1).join(', '), array.slice(-1)[0]].join(
    array.length < 2 ? '' : ` ${conjunction} `
  )
}

export function generateQueryString(
  params:
    | ActivityFilter
    | {
        from?: string
        to?: string
        period?: HistoryPeriod
        cumulative?: boolean
        format?: 'csv' | 'json'
      }
): string {
  if (!params) return ''

  // Delete empty fields from filter object if any
  const paramsObj = (params || {}) as any
  Object.keys(paramsObj).forEach((key) =>
    paramsObj[key] === undefined ? delete paramsObj[key] : {}
  )
  if ('metadata' in params) {
    const metadata = params?.metadata || {}
    Object.keys(metadata).forEach((key) =>
      metadata[key] === undefined ? delete metadata[key] : {}
    )
  }

  let queryString = '?'

  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(params)) {
    if (typeof value === 'object' && !Array.isArray(value)) {
      // eslint-disable-next-line no-restricted-syntax
      for (const [k, elem] of Object.entries(value)) {
        if (Array.isArray(elem)) {
          // eslint-disable-next-line no-loop-func
          elem.forEach((v) => {
            queryString += `${key}.${k}=${encodeURIComponent(v)}&`
          })
        } else {
          queryString += `${key}.${k}=${encodeURIComponent(elem as string)}&`
        }
      }
    } else if (typeof value !== 'object' && typeof value !== 'function') {
      queryString += `${key}=${encodeURIComponent(value)}`
    } else if (Array.isArray(value)) {
      // eslint-disable-next-line no-loop-func
      value.forEach((v) => {
        queryString += `${key}=${encodeURIComponent(v)}&`
      })
    }

    if (!queryString.endsWith('&')) {
      queryString += '&'
    }
  }

  return queryString.slice(0, queryString.length - 1)
}

export const sortArrayOfObjects = ({
  array,
  property,
  order = 'asc',
}: {
  array: any[]
  /** Property in the objects to be sorted by */
  property: string
  order?: 'asc' | 'desc'
}): any[] => {
  return array.sort((a: Record<string, number>, b: Record<string, number>) => {
    if (order === 'desc') {
      return a[property] < b[property] ? 1 : -1
    }
    return a[property] > b[property] ? 1 : -1
  })
}

export const randomKey = (length: number) =>
  Math.random()
    .toString(36)
    .substring(2, 2 + length)

export const stripEmptyKeys = (obj: any) =>
  Object.keys(obj).reduce((acc, key) => {
    if (obj[key] !== null && obj[key] !== undefined && obj[key] !== '') {
      acc[key] = obj[key]
    }
    return acc
  }, {})

export const isNewPatch = (source: object | null | undefined, patch: object): boolean => {
  if (!source) return true
  for (const key in patch) {
    if (patch[key] !== source[key]) {
      return true
    }
  }
  return false
}

export const ensureSentenceStop = (text: string): string => {
  if (!/[.!?]$/.test(text)) {
    return `${text}.`
  }
  return text
}
