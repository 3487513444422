import React, {useCallback, useEffect, useRef, useState} from 'react'
import {Subscription, of, Observable} from 'rxjs'
import {catchError, shareReplay, take} from 'rxjs/operators'
import {
  Organization,
  OrganizationSSOProvider,
  OrganizationSSOProviderSAML,
  CreateOrganizationSSOProvider,
  SaveOrganizationSSOProvider,
  SetRoleMapping,
  RoleMapping,
} from '../../types/models'
import {
  createOrganizationSSOProvider,
  deleteOrganizationSSOProvider,
  getOrganizationSSOProviders,
  setOrganizationSSOProvider,
  getSSOProviderSAMLMetadata,
  setSSOProviderProjectRoleMappings,
  deleteSSOProviderProjectRoleMappings,
} from './controller/ssoProviders'

type useSSOProviderState = {
  providers: OrganizationSSOProvider[]
  error: Error | null
  loading: boolean
  createProvider: (data: CreateOrganizationSSOProvider) => Subscription
  saveProvider: (
    providerId: string,
    data: SaveOrganizationSSOProvider
  ) => Observable<OrganizationSSOProvider>
  deleteProvider: (providerId: string) => Subscription
  saveProjectRoleMappings: (
    providerId: string,
    projectId: string,
    opts: {
      roleMappings?: SetRoleMapping[]
      fallbackRoleName?: string | null
    }
  ) => Observable<RoleMapping[]>
  deleteProjectRoleMappings: (providerId: string, projectId: string) => Subscription
}

export function useSSOProvider(organizationId: Organization['id']): useSSOProviderState {
  const [providers, setProviders] = useState<OrganizationSSOProvider[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<Error | null>(null)

  const sub: React.MutableRefObject<Subscription | undefined> = useRef()

  useEffect(() => {
    setLoading(true)

    sub.current = getOrganizationSSOProviders(organizationId)
      .pipe(
        catchError((err) => {
          setError(err)

          return of([] as OrganizationSSOProvider[])
        })
      )
      .subscribe((next) => {
        setProviders(next)
        setLoading(false)
      })
  }, [organizationId])

  useEffect(
    () => () => {
      sub.current?.unsubscribe()
    },
    [organizationId]
  )

  const saveProvider = useCallback(
    (providerId: string, data: SaveOrganizationSSOProvider) => {
      setError(null)
      setLoading(true)

      const obs = setOrganizationSSOProvider(organizationId, providerId, data).pipe(
        shareReplay({bufferSize: 1, refCount: true})
      )
      obs.subscribe(
        (next) => {
          setProviders([next])
        },
        () => setLoading(false),
        () => setLoading(false)
      )
      return obs
    },
    [organizationId]
  )

  const createProvider = useCallback(
    (data: CreateOrganizationSSOProvider) => {
      setError(null)
      setLoading(true)
      return createOrganizationSSOProvider(organizationId, data)
        .pipe(
          catchError((err) => {
            setError(err)

            return of(null)
          })
        )
        .subscribe((next) => {
          setLoading(false)
          if (next === null) {
            return
          }

          setProviders([next])
        })
    },
    [organizationId]
  )

  const deleteProvider = useCallback(
    (providerId: string) => {
      setError(null)
      setLoading(true)
      return deleteOrganizationSSOProvider(organizationId, providerId)
        .pipe(
          catchError((err) => {
            setError(err)

            return of(null)
          })
        )
        .subscribe((next) => {
          setLoading(false)
          if (next === null) {
            return
          }

          setProviders([])
        })
    },
    [organizationId]
  )

  const saveProjectRoleMappings = useCallback(
    (
      providerId: string,
      projectId: string,
      opts: {roleMappings?: SetRoleMapping[]; fallbackRoleName?: string | null}
    ) => {
      setError(null)
      setLoading(true)
      const obs = setSSOProviderProjectRoleMappings(organizationId, providerId, projectId, opts)
        .pipe(shareReplay({bufferSize: 1, refCount: true}))
        .pipe(take(1))
      obs.subscribe(
        (newRoleMappings) => {
          setLoading(false)

          if (!newRoleMappings) {
            return
          }

          const index = providers.findIndex(({id}) => id === providerId)
          if (providers[index] === undefined) {
            return
          }
          const provider = {...providers[index]}

          provider.projectRoleMappings[projectId] = newRoleMappings

          providers[index] = provider

          setProviders([...providers])
        },
        () => {
          setLoading(false)
        }
      )
      return obs
    },
    [providers, organizationId]
  )

  const deleteProjectRoleMappings = useCallback(
    (providerId: string, projectId: string) => {
      setError(null)
      setLoading(true)
      return deleteSSOProviderProjectRoleMappings(organizationId, providerId, projectId)
        .pipe(
          catchError((err) => {
            setError(err)

            return of(null)
          })
        )
        .subscribe(() => {
          setLoading(false)

          const index = providers.findIndex(({id}) => id === providerId)
          if (providers[index] === undefined) {
            return
          }
          const provider = {...providers[index]}

          delete provider.projectRoleMappings[projectId]

          providers[index] = provider

          setProviders([...providers])
        })
    },
    [providers, organizationId]
  )

  return {
    saveProvider,
    saveProjectRoleMappings,
    deleteProjectRoleMappings,
    createProvider,
    deleteProvider,
    providers,
    error,
    loading,
  }
}

type useSSOProviderSAMLMetadataState = {
  metadata: string
  error: Error | null
  loading: boolean
}
export function useSSOProviderSAMLMetadata(
  provider: OrganizationSSOProviderSAML
): useSSOProviderSAMLMetadataState {
  const [metadata, setMetadata] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<Error | null>(null)

  const sub: React.MutableRefObject<Subscription | undefined> = useRef()

  useEffect(() => {
    setLoading(true)

    sub.current = getSSOProviderSAMLMetadata(provider.id)
      .pipe(
        catchError((err) => {
          setError(err)

          return of('')
        })
      )
      .subscribe((next) => {
        setMetadata(next)
        setLoading(false)
      })
  }, [provider.id])

  useEffect(
    () => () => {
      if (sub.current) {
        sub.current.unsubscribe()
      }
    },
    [provider.id]
  )

  return {metadata, loading, error}
}
