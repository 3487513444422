import React from 'react'
import {Text, Dialog, Button, Grid, Card} from '@sanity/ui'

type ConfirmDeleteProviderDialogProps = {
  closeDialog: () => void
  deleteProvider: () => void
}

export default function ConfirmDeleteProviderDialog({
  closeDialog,
  deleteProvider,
}: ConfirmDeleteProviderDialogProps) {
  function handleConfirm() {
    deleteProvider()
    closeDialog()
  }

  return (
    <Dialog
      id="confirm-delete-provider"
      width={1}
      footer={
        <Grid columns={2} gapX={2} padding={4}>
          <Button text="Cancel" mode="bleed" onClick={closeDialog} />
          <Button text="Delete service provider" tone="critical" onClick={handleConfirm} />
        </Grid>
      }
      onClose={closeDialog}
      header="Delete Sanity SAML SSO sevice provider"
    >
      <Card padding={4} paddingY={5}>
        <Text>All SAML SSO configuration for this organization will be lost.</Text>
      </Card>
    </Dialog>
  )
}
