import React, {useEffect, useState} from 'react'
import {Stack, Text, Card, useToast, Box} from '@sanity/ui'
import countryList from 'iso-3166-country-list'
import {EditablePreview} from '@/ui/index'
import {CustomerData, Organization} from '@/types/index'
import {BillingDetailsInput} from '@/components/form/edit-states'
import {useCustomerData} from '@/data/organizations/useCustomerData'
import {useRequiredOrganizationPermissions} from '@/context/index'

export function BillingDetails({
  org,
  title,
  description,
}: {
  org: Organization
  title: string
  description: string
}) {
  const toast = useToast()
  const [showEdit, setShowEdit] = useState(false)
  const {data: customerData, patchData: updateContact, isPending} = useCustomerData(org?.id || '')

  useEffect(() => {
    // If we don't have any customer details, automatically show the form
    if (!customerData || !customerData.name) {
      setShowEdit(true)
    }
  }, [customerData])

  const canManage = useRequiredOrganizationPermissions([
    {permissionName: 'sanity.organization', grantName: 'billing'},
  ])

  function handleSave(data: CustomerData) {
    updateContact(data, {
      onSuccess: () => {
        setShowEdit(false)
        toast.push({
          title: 'Billing details updated',
          status: 'success',
        })
      },
      onError: (error) => {
        toast.push({
          title: 'Failed to update billing details',
          description: error.message,
          status: 'error',
        })
      },
    })
  }

  const {name, vatId, email, phone, zip, city, country} = customerData ?? {}

  return (
    <>
      <EditablePreview
        onEdit={() => setShowEdit(true)}
        title={title}
        description={description}
        isEditing={showEdit}
        hasData={!!customerData}
        addButtonText="Add Billing address"
        canManage={canManage}
        noPermissionText="Your member role does not have access to billing settings"
      >
        <>
          {!showEdit && (
            <Box padding={3} data-recording-mask>
              <Stack space={2}>
                {name && <Text weight="semibold">{name}</Text>}
                {vatId && <Text>{vatId}</Text>}
                {email && <Text>{email}</Text>}
                {phone && <Text>{phone}</Text>}
                {zip && <Text>{zip}</Text>}
                {city && <Text>{city}</Text>}
                {country && <Text>{countryList.name(country)}</Text>}
              </Stack>
            </Box>
          )}
          {showEdit && (
            <Card paddingY={3}>
              <BillingDetailsInput
                onSave={handleSave}
                onCancel={() => setShowEdit(false)}
                data={customerData}
                isSaving={isPending}
              />
            </Card>
          )}
        </>
      </EditablePreview>
    </>
  )
}
