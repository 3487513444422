import {Flex, Text, AvatarStack, Stack, Grid, Avatar} from '@sanity/ui'
import {useSwitchPlanStore} from '@/components/checkout/store'
import {MemberAvatar} from '@/ui/index'
import {useCurrentScopeContext} from '@/context/index'
import {useMemo, useState} from 'react'
import {calculateTotalPrice, getNonViewerUsers} from '@/utils/usage'
import {formatUSD} from '@/utils/number'

const CartItem = (props: {
  title: string
  description: string
  price?: number
  flair?: React.ReactNode
}) => (
  <Flex justify="space-between" align="center" gap={4}>
    <Flex gap={3} align="center">
      <Stack space={2}>
        <Text size={1} weight="semibold">
          {props.title}
        </Text>
        <Text size={1} muted>
          {props.description}
        </Text>
      </Stack>
      {props.flair}
    </Flex>

    {props.price && (
      <Text size={1} weight="semibold">
        {formatUSD(props.price)}
      </Text>
    )}
  </Flex>
)

const AVATAR_LIMIT = 3

export function UpgradeSummary() {
  const {state} = useSwitchPlanStore()
  const plan = state.plan!

  const scope = useCurrentScopeContext()

  /* This case only targets the Growth plan, which only bills per non-viewer users */
  const [seatConsumers] = useState(getNonViewerUsers(scope?.project))
  const seatCount = seatConsumers?.length

  const seatCountText = `${seatCount} ${seatCount === 1 ? 'seat' : 'seats'} in project`
  const seatAvatars = useMemo(() => {
    return seatConsumers
      ?.reverse() // Reverse to show the most recent members
      ?.slice(0, AVATAR_LIMIT) // Only show the last 3 members
      ?.map(({id}) => <MemberAvatar key={id} userId={id} tooltip />)
  }, [seatConsumers])

  /* Depending on the plan, we need to calculate the total price per seat or flat fee */
  const basePlanPrice = calculateTotalPrice(plan, seatCount)
  const totalPrice = calculateTotalPrice(plan, seatCount) // Todo: Add addons to the total price

  const cartIncludesAddons = basePlanPrice !== totalPrice

  return (
    <Grid columns={cartIncludesAddons ? 1 : 2} gap={4} style={{alignItems: 'center'}}>
      <CartItem
        title={seatCountText}
        description={`${formatUSD(plan.price)}${plan.pricingModel === 'per-seat' && ' per seat'}`}
        price={cartIncludesAddons ? basePlanPrice : undefined}
        flair={
          <AvatarStack maxLength={5}>
            {seatAvatars}
            {seatCount! > AVATAR_LIMIT && <Avatar initials={`+${seatCount - AVATAR_LIMIT}`} />}
          </AvatarStack>
        }
      />

      <Text size={3} weight="semibold" align="right" aria-live="polite">
        {formatUSD(totalPrice)}
        <span style={{color: 'var(--card-muted-fg-color)'}}> / month</span>
      </Text>
    </Grid>
  )
}
