import React from 'react'
import {Button} from '@sanity/ui'
import {ChartUpwardIcon} from '@sanity/icons'
import {isBefore} from 'date-fns'
import {UsageSummary, UsageSummaryItem, AppLink} from '@/ui/index'
import {useRoutePath, useUsageContext} from '@/context/index'
import {
  OVERAGE_APPROACHING_LIMIT,
  getResourceNameById,
  sortArrayOfObjects,
  getResourceSummary,
} from '@/utils/index'
import {Organization, ProjectUsage, ResourceIds} from '@/types/models'
import {useCurrentScopeContext} from '@/context/index'
import {useProjectsUsage} from '@/data/index'

const formatUsageSummary = (
  org: Organization | undefined,
  orgProjects: ProjectUsage[],
  playgroundProjects: ProjectUsage[]
): UsageSummaryItem[] => {
  if (org?.organizationPlan) {
    const organizationProjects: UsageSummaryItem = {
      id: 'org-projects',
      usage: orgProjects?.length,
      quota: org.organizationPlan.maxProjects,
      hasOverage: orgProjects?.length > org.organizationPlan.maxProjects,
      approachingQuota:
        orgProjects?.length > org.organizationPlan.maxProjects * OVERAGE_APPROACHING_LIMIT,
      unit: 'number',
    }
    const playground: UsageSummaryItem = {
      id: 'playground-projects',
      usage: playgroundProjects.length,
      quota: org.organizationPlan.playgroundProjects.maxProjects,
      hasOverage: playgroundProjects.length > org.organizationPlan.playgroundProjects.maxProjects,
      approachingQuota:
        playgroundProjects.length >
        org.organizationPlan.playgroundProjects.maxProjects * OVERAGE_APPROACHING_LIMIT,
      unit: 'number',
    }
    const resources = getResourceSummary(orgProjects, org.organizationPlan)
    return [...Object.values(resources), organizationProjects, playground]
  }
  return []
}

const filterProjects = (usage: ProjectUsage, date: Date) =>
  usage.projectCreatedAt && isBefore(new Date(usage.projectCreatedAt), date)

export function UsageSummaryOrgPlan({isOverview}: {isOverview?: boolean}) {
  const {basePath} = useRoutePath()
  const {org} = useCurrentScopeContext()
  const {currentRange, currentPeriod, compareRange} = useUsageContext()
  const toDateCurrent = currentRange?.toDate || new Date()
  const toDateCompare = compareRange?.toDate
  const projectUsages = useProjectsUsage(org?.projects, {
    ...currentRange,
    period: currentPeriod,
  })
  const compareUsage = useProjectsUsage(org?.projects, {
    ...compareRange,
    period: currentPeriod,
  })
  const normalProjects = projectUsages.filter(
    ({data: project}) =>
      project && org?.organizationPlan?.projectIds.includes(project.projectId) === true
  )
  const playgroundProjects = projectUsages.filter(
    ({data: project}) =>
      project && org?.organizationPlan?.projectIds.includes(project.projectId) === false
  )

  const compareProjects = compareUsage.filter(
    ({data: project}) =>
      project && org?.organizationPlan?.projectIds.includes(project.projectId) === true
  )
  const playgroundCompareProjects = compareUsage.filter(
    ({data: project}) =>
      project && org?.organizationPlan?.projectIds.includes(project.projectId) === false
  )

  const usageLoading =
    projectUsages.some((usage) => usage.isLoading) || compareUsage.some((usage) => usage.isLoading)

  // Filter projects to find how many were created before the end of the current period selected
  const currentOrgProjects = normalProjects.filter(
    ({data: project}) => project && filterProjects(project, toDateCurrent)
  )
  const currentPlaygroundProjects = playgroundProjects.filter(
    ({data: project}) => project && filterProjects(project, toDateCurrent)
  )

  // Filter projects to find how many were created before the end of the compare period selected
  const compareOrgProjects =
    (toDateCompare &&
      compareProjects.filter(
        ({data: project}) => project && filterProjects(project, toDateCompare)
      )) ||
    []

  const comparePlaygroundProjects =
    (toDateCompare &&
      playgroundCompareProjects.filter(
        ({data: project}) => project && filterProjects(project, toDateCompare)
      )) ||
    []

  const resources = formatUsageSummary(
    org,
    currentOrgProjects.map(({data}) => data).filter((data) => data !== undefined) as ProjectUsage[],
    currentPlaygroundProjects
      .map(({data}) => data)
      .filter((data) => data !== undefined) as ProjectUsage[]
  )
  const compareResources = formatUsageSummary(
    org,
    compareOrgProjects.map(({data}) => data).filter((data) => data !== undefined) as ProjectUsage[],
    comparePlaygroundProjects
      .map(({data}) => data)
      .filter((data) => data !== undefined) as ProjectUsage[]
  )

  const sortedResources = sortArrayOfObjects({array: resources, property: 'id'})

  return (
    <UsageSummary.Container
      loading={usageLoading}
      dense
      columns={isOverview ? [1, 1, 1, 2] : [1, 1, 1, 2]}
    >
      {sortedResources.map((data: UsageSummaryItem) => {
        const {usage: compare} = compareResources.find((r) => r.id === data.id) || {}
        const Link = ({children}: {children: React.ReactNode}) =>
          isOverview ? (
            <AppLink href={`${basePath}/usage#${data.id}`}>{children}</AppLink>
          ) : (
            <a href={`#${data.id}`}>{children}</a>
          )

        return (
          <UsageSummary.Item
            key={data.id}
            id={data.id}
            name={getResourceNameById(data.id as ResourceIds)}
            usage={data.usage}
            quota={data.quota}
            hasOverage={data.hasOverage}
            approachingQuota={data.approachingQuota}
            compare={compareRange && compare}
            unit={data.unit}
            overageTone={data.overageTone}
            overageIcon={data.overageIcon}
            dense
            link={
              <Link>
                <Button icon={ChartUpwardIcon} mode="bleed" as="span" padding={2} />
              </Link>
            }
          />
        )
      })}
    </UsageSummary.Container>
  )
}
