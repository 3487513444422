import React, {useState, useCallback, useEffect, useRef, useMemo} from 'react'
import {
  Card,
  Flex,
  Stack,
  Text,
  Button,
  TextInput,
  Box,
  Inline,
  useClickOutside,
  useGlobalKeyDown,
} from '@sanity/ui'
import {CloseIcon, EditIcon} from '@sanity/icons'
import {useForm, type RegisterOptions} from 'react-hook-form'
import {useUID} from 'react-uid'
import {EmptyBlock, PermissionButtonProject} from '../../general'
import {ErrorTooltip} from '@/ui/index'

type Props = {
  value: string
  label?: string
  onSave?: (value: string) => void
  onChange?: (value: string) => void
  borderTop?: boolean
  borderBottom?: boolean
  title?: string
  description?: string | JSX.Element
  disabled?: boolean
  canManage?: boolean
  noPermissionText?: string
  required?: boolean
  rules?: Partial<RegisterOptions<{valueInput: string}>>
}

export function EditableField({
  value,
  borderTop = true,
  borderBottom = true,
  title,
  label,
  disabled,
  description,
  rules = {},
  required = true,
  onSave,
  onChange,
  canManage = true,
  noPermissionText,
}: Props) {
  const inputUid = useUID()
  const [edit, setEdit] = useState(false)
  const valueElement = useRef<HTMLInputElement | null>(null)
  const [formEl, setFormEl] = useState<HTMLFormElement | null>(null)
  const {
    clearErrors,
    setFocus,
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<{valueInput: string}>({
    defaultValues: {valueInput: value},
    values: {valueInput: value},
  })

  useEffect(() => {
    return () => clearErrors()
  }, [clearErrors])

  const handleClickOutside = useCallback(() => {
    setEdit(false)
  }, [])

  const onKeydown = (e: KeyboardEvent) => {
    if (e.key === 'Escape' && edit) {
      setEdit(false)
    }
  }

  useGlobalKeyDown((e) => onKeydown(e))
  useClickOutside(handleClickOutside, [formEl])

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(e.target.value)
    },
    [onChange]
  )

  function handleSave(data: any) {
    const {valueInput: name} = data
    if (onSave) {
      onSave(name)
    }
    setEdit(false)
  }

  useEffect(() => {
    if (edit) {
      setFocus('valueInput')
    }
    if (!edit) {
      clearErrors()
    }
  }, [clearErrors, edit, setFocus, value, valueElement])

  const requiredRule = useMemo(
    () => (required ? {required: 'This field is required'} : {}),
    [required]
  )

  const cancelEditing = useCallback(() => setEdit(false), [])
  const setEditing = useCallback(() => setEdit(true), [])

  return (
    <Stack
      space={title ? 4 : 0}
      as="form"
      onSubmit={handleSubmit(handleSave)}
      ref={setFormEl as any}
    >
      <Stack space={3}>
        {title && (
          <Flex as="label" align="center" htmlFor={inputUid}>
            <Text weight="semibold" size={1}>
              {title}
            </Text>
            {edit && errors.valueInput && (
              <ErrorTooltip message={errors.valueInput.message || ''} />
            )}
          </Flex>
        )}
        {description && (
          <Text size={1} muted>
            {description}
          </Text>
        )}
      </Stack>
      {canManage && (
        <Card borderTop={borderTop} borderBottom={borderBottom} sizing="border">
          <Box padding={3} paddingX={0}>
            <Flex justify="space-between" align="center" gap={3}>
              <Box flex={1}>
                {edit ? (
                  <Box>
                    <TextInput
                      aria-label={label}
                      id={inputUid}
                      padding={3}
                      {...register('valueInput', {...requiredRule, ...rules})}
                      onChange={handleChange}
                      border={false}
                    />
                  </Box>
                ) : (
                  <Box padding={3}>
                    <Text muted={typeof label === 'string'} size={2}>
                      {value}
                    </Text>
                  </Box>
                )}
              </Box>

              {!edit && (
                <Inline space={2}>
                  <PermissionButtonProject
                    icon={edit ? CloseIcon : EditIcon}
                    mode="bleed"
                    hasPermission={!disabled}
                    onClick={setEditing}
                  />
                </Inline>
              )}

              {edit && (
                <Inline space={2}>
                  <Button text="Save" tone="primary" type="submit" />
                  <Button text="Cancel" mode="ghost" onClick={cancelEditing} />
                </Inline>
              )}
            </Flex>
          </Box>
        </Card>
      )}

      {!canManage && (
        <EmptyBlock
          title="Insufficient permissions"
          description={noPermissionText}
          illustration="access-denied"
        />
      )}
    </Stack>
  )
}
