import {ActivityIcon, CogIcon, DashboardIcon, MasterDetailIcon, UsersIcon} from '@sanity/icons'
import {Tab} from '@/types/index'

export const overviewTab: Tab = {
  title: 'Overview',
  icon: DashboardIcon,
  isRoot: true,
}

export const projectsTab: Tab = {
  title: 'Projects',
  icon: MasterDetailIcon,
  path: 'projects',
}

export const membersTab = {
  title: 'Members',
  icon: UsersIcon,
  path: 'members',
}

export const settingsTab: Tab = {
  title: 'Settings',
  icon: CogIcon,
  path: 'settings',
}

export const activityTab: Tab = {
  title: 'Activity',
  icon: ActivityIcon,
  path: 'activity',
  disableNav: true,
  grantingFeature: 'activityFeed',
  // @TODO Add back later when docs are ready
  // referenceLink: {
  //   label: 'Learn more about activity in the Sanity docs',
  //   url: 'https://www.sanity.io/docs/activity-feed'
  // }
}

export const studiosTab: Tab = {
  title: 'Studios',
  icon: MasterDetailIcon,
  path: 'studios',
}
