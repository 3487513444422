import {useContext} from 'react'
import {WizardStoreContext} from './storeContext'

export function useWizardStore() {
  const store = useContext(WizardStoreContext)

  if (!store) throw new Error('Store: missing context value')

  return store
}
