import {Box, Heading, Stack, Text} from '@sanity/ui'
import React from 'react'
import {WizardStep} from '@/components/payment-wizard'

function AddBillingIntroStep() {
  return (
    <Box>
      <Stack space={4}>
        <Stack space={4}>
          <Heading as="h1">Add Billing Information</Heading>
          <Text as="p" muted>
            Your project needs to have billing information associated to it if you are going over
            quotas on your current plan. This is important to avoid being blocked.
          </Text>
          <Text as="p" muted>
            It only takes a couple of minutes to set this up, and we will walk you through the
            process here
          </Text>
        </Stack>
      </Stack>
    </Box>
  )
}

export function useAddBillingIntroStep(): WizardStep | null {
  return {
    id: 'add-billing-intro',
    title: 'Intro',
    completed: true,
    component: AddBillingIntroStep,
    disabled: false,
    action: {
      label: <>Continue</>,
    },
    handle() {
      return Promise.resolve()
    },
  }
}
