import React, {useState} from 'react'
import {Box, Button, Card, Flex, Stack, Inline} from '@sanity/ui'
import {ArrowTopRightIcon, EllipsisHorizontalIcon, Icon} from '@sanity/icons'
import styled from 'styled-components'
import {nameToInitials} from '../../members/utils'
import {EventDescription, ActivityBadge, ActivityDetail} from './atoms'
import {PropsType} from './PropTypes'
import {AssociatedLink} from './atoms/associatedLink'
import {AvatarWithFallback} from '@/ui/index'
import {AppLink} from '@/ui/app'

const DetailsFlex = styled(Flex)`
  .event-details-compact-wrap {
    margin-right: 12px;
  }
  .event-details-compact-wrap:empty {
    display: none;
  }
`

export function ActivityTileCompact(props: PropsType) {
  const {
    details,
    secondaryIconName,
    mainIconName,
    showAvatar,
    projectId,
    isEntityAction,
    user,
    descriptionTemplate,
    event,
    associatedLink,
    eventProject,
    disableFilter,
  } = props

  const [expanded, setExpanded] = useState<boolean>(false)

  const notExpandedDetails = ['timestamp', 'actorId', 'projectId', 'organizationId', 'dataset']
  const expandedDetails = ['timestamp']
  const currentDetails = expanded ? expandedDetails : notExpandedDetails

  return (
    <Card paddingY={2}>
      <Flex align="center">
        <Stack space={4} flex={1}>
          <Stack space={1}>
            <Flex justify="space-between" align="center">
              <Inline space={4}>
                <ActivityBadge icon={secondaryIconName} squared={isEntityAction && !!projectId}>
                  {mainIconName && !showAvatar && <Icon symbol={mainIconName} />}
                  {showAvatar && (
                    <AvatarWithFallback
                      size={1}
                      src={isEntityAction ? undefined : user?.imageUrl || undefined}
                      squared={isEntityAction && !!projectId}
                      fallback={isEntityAction || !event.actorName}
                      bgColor={
                        isEntityAction && !!projectId ? eventProject?.metadata?.color : undefined
                      }
                      initials={
                        isEntityAction
                          ? undefined
                          : nameToInitials(event?.userName || event?.actorName)
                      }
                    />
                  )}
                </ActivityBadge>
                <DetailsFlex align="center">
                  {details
                    .filter((d) => currentDetails.includes(d.type))
                    .map(
                      (detail) =>
                        detail.value && (
                          <Box key={detail.type} className="event-details-compact-wrap">
                            <ActivityDetail
                              {...detail}
                              compact
                              disableFilter={disableFilter}
                              event={event}
                            />
                          </Box>
                        )
                    )}
                  {!expanded && (
                    <Box marginLeft={1}>
                      <Button
                        aria-label="Show more"
                        icon={EllipsisHorizontalIcon}
                        mode="bleed"
                        onClick={() => setExpanded(!expanded)}
                        style={{background: 'transparent', boxShadow: 'none'}}
                        padding={0}
                      />
                    </Box>
                  )}
                </DetailsFlex>
              </Inline>
              <Box>
                {associatedLink.url && !expanded && (
                  <AssociatedLink
                    label={associatedLink.label}
                    url={associatedLink.url}
                    external={associatedLink.external}
                  />
                )}
              </Box>
            </Flex>
            {expanded && (
              <Stack space={2} marginLeft={6} paddingLeft={2}>
                {details
                  .filter((d) => d.type !== 'timestamp')
                  .filter((d) => d.value)
                  .map((detail) => {
                    return (
                      <ActivityDetail
                        key={detail.type}
                        {...detail}
                        event={event}
                        disableFilter={disableFilter}
                      />
                    )
                  })}
                {associatedLink.url && (
                  <Flex>
                    {associatedLink.external && (
                      <Button
                        as="a"
                        target="blank"
                        rel="noreferrer"
                        href={associatedLink.url}
                        text={associatedLink.label}
                        fontSize={1}
                        icon={ArrowTopRightIcon}
                        padding={0}
                        mode="bleed"
                        style={{background: 'transparent', boxShadow: 'none', height: 30}}
                      />
                    )}

                    {!associatedLink.external && (
                      <AppLink href={associatedLink.url}>
                        <Button
                          as="span"
                          text={associatedLink.label}
                          fontSize={1}
                          padding={1}
                          icon={ArrowTopRightIcon}
                          mode="bleed"
                          style={{background: 'transparent', boxShadow: 'none', height: 30}}
                        />
                      </AppLink>
                    )}
                  </Flex>
                )}
                <Flex>
                  <Button
                    icon={EllipsisHorizontalIcon}
                    padding={1}
                    mode="bleed"
                    text="Show less"
                    fontSize={1}
                    style={{background: 'transparent', boxShadow: 'none', height: 30}}
                    onClick={() => setExpanded(!expanded)}
                  />
                </Flex>
              </Stack>
            )}
          </Stack>
          <Box paddingBottom={2}>
            <EventDescription template={descriptionTemplate} event={event} />
          </Box>
        </Stack>
      </Flex>
    </Card>
  )
}
