import {Observable} from 'rxjs'
import {apiRequest} from '../util/request'
import {Webhook} from '../../types/models'

const headers = {
  'Content-Type': 'application/json',
}

export function getWebhooks(projectId: string): Observable<Webhook[]> {
  return apiRequest<Webhook[]>({
    url: `https://api.${process.env.host}/v1/hooks/projects/${projectId}`,
  })
}

export function addWebhook(
  projectId: string,
  webhooks: {
    url: string
    name: string
    dataset: string
  }
): Observable<Webhook> {
  return apiRequest<Webhook>({
    url: `https://api.${process.env.host}/v1/hooks/projects/${projectId}`,
    method: 'POST',
    headers,
    body: webhooks,
  })
}

export function deleteWebhook(projectId: string, tokenId: string): Observable<Webhook> {
  return apiRequest<Webhook>({
    url: `https://api.${process.env.host}/v1/hooks/projects/${projectId}/${tokenId}`,
    method: 'DELETE',
  })
}
