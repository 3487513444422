import React, {useCallback, useMemo} from 'react'
import {useRouter} from 'next/router'
import {useAddBillingIntroStep} from './steps/addBillingIntroStep'
import {
  useAddBillingDetailsStep,
  useCreateNewOrgStep,
  useTransferToOrgStep,
  useWizardStore,
  WizardCompletionCallback,
  WizardDialog,
  WizardParams,
  WizardStep,
} from '@/components/payment-wizard'

export function AddBillingWizard(props: {
  params: WizardParams
  onComplete?: WizardCompletionCallback
}) {
  const router = useRouter()
  const {dispatch} = useWizardStore()

  const {onComplete: handleWizardComplete, params} = props
  const project = params.project

  const onOrganizationAssigned = useCallback(
    (orgId: string) => {
      const basePath = `/organizations/${orgId}`
      const {query} = router
      return router.push(`${basePath}/project/${project.id}/${query.tabId}`, undefined, {
        shallow: true,
      })
    },
    [project, router]
  )

  const _params: WizardParams = useMemo(
    () => ({
      onOrganizationAssigned,
      ...params,
    }),
    [onOrganizationAssigned, params]
  )

  const handleWizardClose = useCallback(() => {
    dispatch({type: 'addBillingWizard/close'})
    dispatch({type: 'reset'})
  }, [dispatch])

  const introStep = useAddBillingIntroStep()
  const transferStep = useTransferToOrgStep(_params)
  const createNewOrgStep = useCreateNewOrgStep()
  const addBillingDetails = useAddBillingDetailsStep(_params)
  const steps = useMemo(() => {
    let _steps = [introStep, transferStep, createNewOrgStep, addBillingDetails].filter(
      Boolean
    ) as WizardStep[]
    // Remove intro step if needed
    if (_steps.length === 2 && _steps[0] === introStep && _steps[1] === addBillingDetails) {
      _steps = [addBillingDetails]
    }
    return _steps
  }, [addBillingDetails, createNewOrgStep, introStep, transferStep])

  if (steps.length === 0) {
    return null
  }

  return (
    <WizardDialog
      onClose={handleWizardClose}
      onComplete={handleWizardComplete}
      steps={steps}
      params={_params}
      title="Add billing details"
    />
  )
}
