import {type ResourceOptions} from '@/types/index'

export const STALE_TTL = 1000 * 60 * 5

export function getProjectsKey() {
  return ['projects']
}

export function getProjectBaseKey(projectId?: string) {
  return ['project', projectId, 'data']
}

export function getProjectMembersKey(projectId: string) {
  return ['project', projectId, 'members']
}

export function getProjectSubscriptionKey(projectId?: string) {
  return ['project', projectId, 'subscription']
}

export function getProjectStudiosKey(projectId?: string) {
  return ['project', projectId, 'studios']
}

export function getProjectRolesKey(projectId: string) {
  return ['project', projectId, 'roles']
}

export function getProjectTagsKey(projectId: string) {
  return ['project', projectId, 'tags']
}

export function getProjectTagRolesKey(projectId: string, tagName: string) {
  return ['project', projectId, 'tags', tagName, 'roles']
}

export function getProjectPermissionResourcesKey(projectId: string) {
  return ['project', projectId, 'projectPermissionResources']
}

export function getProjectPermissionResourceSchemasKey(projectId: string) {
  return ['project', projectId, 'projectPermissionResourceSchemas']
}

export function getProjectUsageKey(
  projectId?: string,
  subscriptionId?: string | undefined,
  options?: ResourceOptions
) {
  return ['project', projectId, 'usage', subscriptionId, options]
}

export function getProjectsUsageKey(projectId: string, options?: ResourceOptions) {
  return ['project', projectId, 'usage', options]
}

export function getProjectAccessRequestsKey(projectId: string) {
  return ['project', projectId, 'access-requests']
}
