import React from 'react'
import {ArrowTopRightIcon, Icon, IconSymbol} from '@sanity/icons'
import {Box, Button, Flex, Grid, Text, ThemeColorToneKey, Tooltip, useTheme} from '@sanity/ui'
import {AppLink, ProfileImage} from '@/ui/index'
import {arrayToText, getResourceNameById} from '@/utils/general'
import {ResourceIds} from '@/types/models'

type Props = {
  id: string
  name: string
  overageResources: string[]
  approachingResources: string[]
  tone: ThemeColorToneKey
  href?: string
  icon: IconSymbol
  projectColor?: string
}

export function Item({
  id,
  name,
  overageResources = [],
  approachingResources = [],
  tone,
  href,
  icon,
  projectColor,
}: Props) {
  const {sanity} = useTheme()
  const textColor = sanity.color.solid[tone].hovered.bg
  const resources = [...new Set([...overageResources, ...approachingResources])].map((r) =>
    getResourceNameById(r as ResourceIds)
  )

  const threshold = 2

  const resourcesString =
    resources.length <= threshold
      ? resources.join(', ')
      : `${resources.slice(0, threshold).join(', ')} and ${resources.length - threshold} others`

  const title = arrayToText(resources || [])

  return (
    <Flex align="center" justify="space-between" style={{whiteSpace: 'nowrap'}}>
      <Grid columns={[1, 1, 2, 2]} gap={4} flex={1} style={{whiteSpace: 'nowrap'}}>
        <Box>
          <Flex align="center">
            <Box marginRight={4}>
              <ProfileImage color={projectColor || ''} />
            </Box>

            <Box>
              <Text size={1} textOverflow="ellipsis" title={id}>
                {name}
              </Text>
            </Box>
          </Flex>
        </Box>

        <Flex align="center" justify="flex-start">
          <Box style={{color: textColor}}>
            <Flex align="center">
              <Box marginRight={3}>
                <Text size={1} style={{color: 'inherit'}}>
                  <Icon symbol={icon} />
                </Text>
              </Box>
              <Box>
                <Text size={1} textOverflow="ellipsis" style={{color: 'inherit'}} title={title}>
                  {resourcesString}
                </Text>
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Grid>
      {href && (
        <Tooltip
          placement="left"
          content={
            <Box padding={3}>
              <Text size={1} style={{whiteSpace: 'nowrap'}}>
                Show usage
              </Text>
            </Box>
          }
        >
          <Flex justify="flex-end">
            <AppLink href={href}>
              <Button icon={ArrowTopRightIcon} mode="bleed" as="span" />
            </AppLink>
          </Flex>
        </Tooltip>
      )}
    </Flex>
  )
}
