/* eslint-disable react/jsx-no-bind */
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {Autocomplete, Box, Card, Flex, Layer, Stack, Text} from '@sanity/ui'
import styled from 'styled-components'
import {AvatarWithFallback} from '../../members'
import {nameToInitials} from '../../members/utils'
import {SelectedCard} from './components'
import {useFilter, useSetFilter, useCurrentScopeContext} from '@/context/index'
import {ProjectMemberRole, OrganizationRole, User} from '@/types/index'
import {useUsers} from '@/data/users/useUsers'

type MemberOption = {
  uniqueDisplayName?: string
  displayName?: string
  user?: User
  id?: string
  roles?: ProjectMemberRole[] | OrganizationRole[]
  sanityUserId?: string
  imageUrl?: string
  isRobot?: boolean
}

type Props = {
  members?: MemberOption[]
}

const StyledCard = styled(Card)`
  width: 100%;
  text-align: left;
  border: 0;
  background: 0;
  padding: 0;
  outline: 0;

  &:focus [data-ui='Card'] {
    outline: -webkit-focus-ring-color auto 1px;
    outline-offset: -2px;
    background: ${({theme}) => theme.sanity.color.muted.primary.enabled.bg};
  }

  &:focus:not(:focus-visible) {
    outline: 0;
  }
`

const Wrapper = styled(Box)`
  ul {
    max-height: 400px;
  }

  & [data-ui='AutocompleteOption']:first-child {
    box-shadow: none;
    & [data-ui='Card'] {
      border: 0;
    }
  }
`

const MemberOption = ({member}: {member: any}) => {
  const userInfo = useMemo(() => member?.user || member, [member])
  const displayName = useMemo(
    () => userInfo?.displayName || member?.sanityUserId || member?.id || '',
    [userInfo, member]
  )
  const shortenedName = displayName.split('').slice(0, 15) || ['']
  const roles = useMemo(() => member?.roles?.map((role: any) => role.title) || null, [member])
  return (
    <StyledCard as="button">
      <Card paddingX={3} paddingY={3} tone="inherit" borderTop>
        <Flex align="center">
          <AvatarWithFallback
            size={1}
            src={userInfo.imageUrl}
            initials={nameToInitials(displayName) || ''}
            robot={member?.isRobot}
            style={{flexShrink: 0}}
          />
          <Stack paddingLeft={2} space={1}>
            <Text weight="semibold" as="option" title={displayName}>
              {shortenedName.join('')}
              {shortenedName.length === 15 && '...'}
            </Text>
            <Text size={1} muted>
              {roles?.join(', ')}
            </Text>
          </Stack>
        </Flex>
      </Card>
    </StyledCard>
  )
}

export function MemberFilter({members}: Props) {
  const [selectedMemberId, setSelectedMemberId] = useState<string | undefined>()
  const activeFilter = useFilter()
  const setFilter = useSetFilter()
  const autoCompleteRef = useRef<any>()
  const currentNames: string[] = []
  const {scope} = useCurrentScopeContext()
  const [enrichedMembers, setEnrichedMembers] = useState<MemberOption[] | undefined>(members)
  const {data: users, isLoading: usersLoading} = useUsers(
    (members?.map((mem: MemberOption) => mem.id).filter(Boolean) as string[]) || [],
    scope === 'project'
  )

  useEffect(() => {
    if (users && scope === 'project' && !usersLoading) {
      const updatedMembers: MemberOption[] =
        members?.map((mem: MemberOption) => {
          const memberUser = users.find((resUser) => resUser?.id === mem.id)
          return {...mem, user: memberUser || undefined}
        }) || []

      setEnrichedMembers(updatedMembers)
    }
  }, [members, scope, users, usersLoading])

  useEffect(() => {
    if (scope !== 'project') {
      setEnrichedMembers(members)
    }
  }, [scope, members])

  const uniqueMembers = enrichedMembers?.map((member) => {
    const displayName = member?.user?.displayName || member.displayName || member.sanityUserId || ''
    let uniqueDisplayName = displayName
    let checked = false
    let currentCounter = 0

    do {
      if (currentNames.includes(uniqueDisplayName)) {
        currentCounter++
        uniqueDisplayName = `${displayName}${currentCounter}`
      } else {
        currentNames.push(uniqueDisplayName)
        checked = true
      }
    } while (!checked)

    return {...member, uniqueDisplayName}
  })

  const handleSelectMember = useCallback(
    (displayName: string) => {
      if (displayName) {
        const member = uniqueMembers?.find(
          (m: MemberOption) => m?.uniqueDisplayName === displayName
        )
        setSelectedMemberId(member?.user?.sanityUserId || member?.sanityUserId)
        setFilter({type: 'actorId', payload: [member?.user?.sanityUserId || member?.sanityUserId]})
      }
    },
    [setFilter, uniqueMembers]
  )

  const handleResetMember = useCallback(() => {
    setSelectedMemberId(undefined)
    setFilter({type: 'actorId', payload: undefined})
  }, [setFilter])

  const handleReFocus = useCallback(() => {
    handleResetMember()
    setSelectedMemberId(undefined)

    setTimeout(() => {
      autoCompleteRef.current.focus()
    }, 0)
  }, [handleResetMember])

  useEffect(() => {
    if (activeFilter?.actorId?.length) {
      const member = uniqueMembers?.find((m) => m.sanityUserId === activeFilter?.actorId[0])
      // Set initial member if URL query contains a valid member
      if (members && member) {
        setSelectedMemberId(member.sanityUserId)
      }
    } else if (!activeFilter?.actorId && selectedMemberId) {
      setSelectedMemberId(undefined)
    }
  }, [activeFilter, members, selectedMemberId, uniqueMembers])

  const memberOptions = uniqueMembers?.map((member) => ({
    value: member.uniqueDisplayName || '',
  }))

  // Get the active member by the global sanity user ID
  const member = (uniqueMembers as MemberOption[]).find(
    (m: MemberOption) => (m?.user?.sanityUserId || m.sanityUserId) === selectedMemberId
  )

  const localDisplayName =
    member?.user?.displayName || member?.displayName || member?.sanityUserId || ''

  return (
    <Layer zOffset={2000}>
      <Stack space={3}>
        <Text weight="semibold" size={1} htmlFor="member-filter">
          Members
        </Text>
        {selectedMemberId && (
          <SelectedCard
            onClick={handleReFocus}
            onClear={handleResetMember}
            value={
              <Flex align="center">
                <Box paddingRight={2} style={{minWidth: 'unset'}}>
                  <AvatarWithFallback
                    size={0}
                    src={member?.imageUrl}
                    initials={nameToInitials(localDisplayName) || ''}
                    robot={member?.isRobot}
                  />
                </Box>
                <Box marginRight={2}>
                  <Text weight="semibold" size={2} textOverflow="ellipsis">
                    {localDisplayName}
                  </Text>
                </Box>
              </Flex>
            }
          />
        )}
        {!selectedMemberId && members && (
          <Wrapper>
            <Autocomplete
              ref={autoCompleteRef}
              id="member-filter"
              options={memberOptions}
              onChange={handleSelectMember}
              placeholder="All members"
              openButton
              renderOption={(option) => {
                const memberOption = uniqueMembers?.find(
                  (m) => m?.uniqueDisplayName === option?.value
                )
                return <MemberOption member={memberOption as MemberOption} />
              }}
            />
          </Wrapper>
        )}
      </Stack>
    </Layer>
  )
}
