import {EditIcon, TrashIcon} from '@sanity/icons'
import {Card, Flex, Box, Stack, Button, Spinner} from '@sanity/ui'
import React, {useCallback} from 'react'
import {EmptyBlock} from '../general'
import {TabSectionHeader} from '../tabs'
import {FormFieldLike} from './formField'

type Props = {
  children: React.ReactNode
  onEdit: () => void
  onDelete?: () => void
  onCancel?: () => void
  isEditing: boolean
  tabSection?: boolean
  title: string
  description?: string
  loading?: boolean
  hasData: boolean
  addButtonText?: string
  canManage?: boolean
  noPermissionText?: string
}

export function EditablePreview({
  children,
  onCancel,
  onEdit,
  onDelete,
  isEditing,
  title,
  loading = false,
  tabSection = true,
  description = '',
  hasData,
  addButtonText = 'Add',
  canManage = true,
  noPermissionText,
}: Props) {
  const showChildren = (isEditing || hasData) && canManage

  const handleKeydown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Escape') {
        onCancel?.()
      }
    },
    [onCancel]
  )

  return (
    <Stack space={tabSection ? 4 : 3} onKeyDown={handleKeydown}>
      {tabSection && <TabSectionHeader title={title} description={description} />}
      {!tabSection && <FormFieldLike label={title} description={description} />}
      {!hasData && !isEditing && canManage && (
        <Flex>
          <Button text={addButtonText} mode="ghost" onClick={onEdit} />
        </Flex>
      )}
      {showChildren && (
        <Card borderTop borderBottom style={{position: 'relative'}}>
          <Box padding={3} paddingX={0} style={{opacity: loading ? 0.2 : 1}}>
            <Flex justify="space-between" align="center" gap={3}>
              <Box flex={1}>{children}</Box>
              {!isEditing && (
                <Box>
                  <Button icon={EditIcon} mode="bleed" onClick={onEdit} />
                  {onDelete && <Button icon={TrashIcon} mode="bleed" onClick={onDelete} />}
                </Box>
              )}
            </Flex>
          </Box>
          {loading && (
            <Box
              padding={3}
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%,-50%)',
              }}
            >
              <Spinner />
            </Box>
          )}
        </Card>
      )}
      {!canManage && (
        <EmptyBlock
          title="Insufficient permissions"
          description={noPermissionText}
          illustration="access-denied"
        />
      )}
    </Stack>
  )
}
