import {useProjectSubscription} from '@/data/projects/useProjectSubscription'
import {Banner} from '@/components/banner'
import {sendTrackingEvent} from '@/utils/tracking'
import {useEffect, useState} from 'react'

export function CompletePaymentBanner({
  organizationId,
  projectId,
}: {
  organizationId: string | undefined
  projectId: string | undefined
}) {
  const {data: projectSubscription} = useProjectSubscription(projectId)
  const [renderBanner, setRenderBanner] = useState(false)

  useEffect(() => {
    if (projectSubscription?.status === 'incomplete') {
      setRenderBanner(true)
      sendTrackingEvent('IncompletePaymentBanner-View', projectId, organizationId)
    } else {
      setRenderBanner(false)
    }
  }, [organizationId, projectId, projectSubscription])

  if (!renderBanner) {
    return null
  }

  return (
    <Banner
      tone={'critical'}
      icon={'warning-outline'}
      title={'Payment Incomplete'}
      description={'Plan will be moved to Free plan if not resolved within 24 hours'}
      ctaText={'Complete Payment'}
      ctaHref={`/organizations/${organizationId}/project/${projectId}/plan`}
      ctaOnClick={() =>
        sendTrackingEvent('IncompletePaymentBanner-Click-CTA', undefined, organizationId)
      }
    />
  )
}
