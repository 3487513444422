import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query'
import {
  getProjectOnboardingData,
  updateProjectOnboardingData,
  type ProjectOnboardingData,
} from './controller'
import {useCurrentScopeContext} from '@/context/useCurrentScopeContext'
import {getProjectOnboardingDataCacheKey} from './cache'
import {timeToMilliseconds} from '@/utils/time'
import {useCanReadProject} from '../../context'

type ProjectOnboardingUpdate = Omit<Partial<ProjectOnboardingData>, 'variant'> & {
  projectId?: string
}

export function useProjectOnboardingData() {
  const {projectId} = useCurrentScopeContext()
  const queryClient = useQueryClient()
  const queryKey = getProjectOnboardingDataCacheKey(projectId)

  const canReadProject = useCanReadProject(projectId)

  const result = useQuery({
    queryKey,
    queryFn: () => getProjectOnboardingData(projectId!).catch(() => null),
    enabled: !!projectId && canReadProject,
    retry: false,

    refetchOnMount: false,
    retryDelay: 3000,
    staleTime: timeToMilliseconds(30, 'minutes'),
  })

  const update = useMutation({
    mutationKey: [...getProjectOnboardingDataCacheKey(projectId), 'update'],
    mutationFn: (onboardingData: ProjectOnboardingUpdate) => {
      const {projectId: projectIdOverride, ...rest} = onboardingData
      return updateProjectOnboardingData(projectIdOverride || projectId!, rest)
    },
    onMutate: async (newOnboardingData) => {
      await queryClient.cancelQueries({
        queryKey: queryKey,
      })
      const previousOnboardingData = queryClient.getQueryData<ProjectOnboardingData>(queryKey)

      queryClient.setQueryData<ProjectOnboardingData>(queryKey, (old) => ({
        ...old!,
        ...newOnboardingData,
      }))

      return {previousOnboardingData}
    },
    onError: (_err, _newOnboardingData, context) => {
      queryClient.setQueryData(queryKey, context?.previousOnboardingData)
    },
    onSuccess: (updatedOnboardingData) => {
      queryClient.setQueryData(queryKey, updatedOnboardingData)
    },
  })

  return {
    ...result,
    update: update.mutate,
    updateAsync: update.mutateAsync,
  }
}
