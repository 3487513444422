/* eslint-disable @typescript-eslint/no-use-before-define */
import React, {useState, useCallback} from 'react'

import {Select, Stack} from '@sanity/ui'
import {FormField, Prompt} from '@/ui/index'
import {Organization, Project} from '@/types/models'
import {useDatasets} from '@/data/datasets'
import {useRequiredProjectPermissions} from '@/context/index'

type Data = {
  projectId: string
  orgId: string
  dataset: string
}

type Props = {
  onSubmit: (data: Data) => void
  organizations: Organization[]
  loading?: boolean
  Dialog: React.ElementType
}

export function ApplyWebhookDialog({onSubmit, organizations, Dialog, loading = false}: Props) {
  // These states represent what the user explicitly selected - which may not be possible
  // if you switch organization/project.
  const [organizationId, setOrganizationId] = useState<string | undefined>(organizations[0]?.id)
  const [projectId, setProjectId] = useState<string | undefined>()
  const [dataset, setDataset] = useState<string>('*')

  // You can only select organizations with projects.
  const usableOrganizations = organizations.filter((org) => org.projects.length > 0)

  // Therefore we re-construct an actual valid combination:
  const currentOrg =
    usableOrganizations.find((org) => org.id === organizationId) || organizations[0]
  const projects = currentOrg.projects
  const currentProject: Project | undefined =
    projects.find((p) => p.id === projectId) || projects[0]

  const {data: datasets = [], isLoading: loadingDatasets} = useDatasets(currentProject.id)

  const currentDataset = datasets.some((d) => d.name === dataset) ? dataset : '*'

  const hasPermission = useRequiredProjectPermissions(
    [{permissionName: 'sanity.project.webhooks', grantName: 'create'}],
    currentProject?.id
  )

  const loadingAny = loading || loadingDatasets

  const handleSubmit = useCallback(() => {
    if (!currentProject?.id) {
      return
    }
    onSubmit({orgId: currentOrg.id, projectId: currentProject.id, dataset: currentDataset})
  }, [currentDataset, currentOrg.id, currentProject?.id, onSubmit])

  return (
    <Dialog
      header="Apply webhook"
      onConfirm={handleSubmit}
      confirmButtonProps={{text: 'Create webhook', tone: 'primary'}}
      loading={loadingAny}
      disabled={!loadingAny && !hasPermission}
    >
      <Stack space={5} onSubmit={handleSubmit}>
        {!hasPermission && (
          <Prompt
            tone="critical"
            icon="info-outline"
            description="You are not allowed to create a webhook in this project due to insufficient permissions"
          />
        )}
        <FormField label="Organization">
          <Select
            radius={2}
            padding={4}
            name="organizationId"
            value={currentOrg.id}
            onChange={(evt) => setOrganizationId(evt.currentTarget.value)}
          >
            {usableOrganizations.map((org) => (
              <option key={org.id} value={org.id}>
                {org.name}
              </option>
            ))}
          </Select>
        </FormField>

        <FormField label="Project">
          <Select
            radius={2}
            padding={4}
            name="projectId"
            value={currentProject?.id}
            onChange={(evt) => setProjectId(evt.currentTarget.value)}
          >
            {projects.map((p) => (
              <option key={p.id} value={p.id}>
                {p.displayName}
              </option>
            ))}
          </Select>
        </FormField>

        <FormField label="Dataset" description="The dataset(s) it should respond to">
          <Select
            radius={2}
            padding={4}
            name="dataset"
            value={currentDataset}
            onChange={(evt) => setDataset(evt.currentTarget.value)}
          >
            <option key="*" value="*">
              * (all datasets)
            </option>
            {datasets.map((d) => (
              <option key={d.name} value={d.name}>
                {d.name}
              </option>
            ))}
          </Select>
        </FormField>
      </Stack>
    </Dialog>
  )
}
