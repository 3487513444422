import React, {useMemo, useReducer} from 'react'

import {reducer, INITIAL_STATE} from './reducer'
import {GetStartedStoreContext} from './storeContext'
import {useCurrentScopeContext} from '@/context/useCurrentScopeContext'
import {LOCAL_STORAGE_KEY} from '../constants'
import {GetStartedState} from './types'

interface Props {
  children: React.ReactNode
}

export function GetStartedStoreProvider({children}: Props) {
  const {projectId} = useCurrentScopeContext()
  const localStorageState = localStorage.getItem(LOCAL_STORAGE_KEY(projectId!))
  const initialState = localStorageState
    ? {...INITIAL_STATE(projectId!), ...(JSON.parse(localStorageState) as GetStartedState)}
    : INITIAL_STATE(projectId!)
  const [state, dispatch] = useReducer(reducer, initialState)

  const contextValue = useMemo(() => ({dispatch, state}), [dispatch, state])

  return (
    <GetStartedStoreContext.Provider value={contextValue}>
      {children}
    </GetStartedStoreContext.Provider>
  )
}
