import {Box} from '@sanity/ui'
import React, {useCallback, useEffect, useRef, useState} from 'react'
import {debounce} from 'lodash'
import {WizardStep, CreateNewOrgForm} from '../types'
import {useWizardStore} from '../store/useStore'
import {Organization} from '@/types/models'
import {CreateNewOrg} from '@/components/form/edit-states'
import {useCurrentScopeContext} from '@/context/index'
import {sendAmplitudeTrackingEvent} from '@/utils/tracking'

function CreateNewOrgStep() {
  const {dispatch, state} = useWizardStore()

  const formRef = useRef<CreateNewOrgForm>()
  const [isValidFormData, setIsValidFormData] = useState(false)
  const {createOrg} = state

  const handleChange = useCallback(() => {
    dispatch({
      type: 'setCreateOrg',
      value: {isValidFormData, createdOrg: undefined, formRef},
    })
  }, [dispatch, isValidFormData])

  // Update the value isValidFormData whenever isValidFormData changes
  useEffect(() => {
    if (
      (isValidFormData && !createOrg?.isValidFormData) ||
      (!isValidFormData && createOrg?.isValidFormData)
    ) {
      handleChange()
    }
  }, [handleChange, isValidFormData, createOrg?.isValidFormData])

  const onCreateOrg = useCallback(
    (newOrg: Organization) => {
      dispatch({type: 'setSelectedOrgId', value: newOrg.id})
      dispatch({type: 'setCreatedOrg', value: newOrg})
    },
    [dispatch]
  )

  const validate = useCallback(
    () =>
      debounce(
        () =>
          formRef.current?.validate().then((valid) => {
            setIsValidFormData(valid)
            return valid
          }),
        300
      )(),
    [formRef, setIsValidFormData]
  )

  const onFormChange = useCallback(() => {
    validate()
  }, [validate])

  return (
    <Box>
      <CreateNewOrg
        hideCardForm
        onCreate={onCreateOrg}
        onChange={onFormChange}
        ref={formRef}
        noPrimaryButton
      />
    </Box>
  )
}

export function useCreateNewOrgStep(): WizardStep | null {
  const {state} = useWizardStore()
  const {project, org} = useCurrentScopeContext()
  const {createOrg} = state

  if (state.selectOrgId !== '#new') {
    return null
  }

  return {
    id: 'create-new-org',
    title: 'Create new organization',
    completed: createOrg?.isValidFormData === true,
    component: CreateNewOrgStep,
    disabled: createOrg === undefined,
    action: {
      label: <>Create new organization</>,
    },
    handle() {
      if (createOrg?.formRef?.current) {
        sendAmplitudeTrackingEvent('Switch Plan Step Completed', project?.id, org?.id, {
          stepName: 'Create new organization',
        })

        return createOrg.formRef.current.submit().then(() => Promise.resolve(1))
      }
      return Promise.reject(new Error('No formRef found'))
    },
  }
}
