import React, {useEffect} from 'react'
import {Box} from '@sanity/ui'
import {Tab} from '@/types/index'
import {Project, Organization} from '@/types/models'
import {sendAmplitudeTrackingEvent} from '@/utils/tracking'
import {SinglePageTab} from '@/ui/index'

export function ProjectUsage({tab, project, org}: {tab: Tab; project: Project; org: Organization}) {
  const headings = tab.headings || []

  useEffect(() => {
    if (project?.id) {
      sendAmplitudeTrackingEvent('Project Usage Page Viewed', project.id)
    }
  }, [project?.id])

  return (
    <Box>
      {tab && (
        <SinglePageTab
          project={project}
          org={org}
          tab={tab}
          headings={headings}
          showUpsell
          upsellResourceName="usage"
          upsellButtonText="Increase usage quotas"
        />
      )}
    </Box>
  )
}
