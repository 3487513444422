import React from 'react'
import {Text} from '@sanity/ui'
import {TimeAgoOpts, useTimeAgo} from '@/utils/index'

type Props = {
  date: string | Date
  muted?: boolean
  size?: number
  options?: TimeAgoOpts
}

export function TimeAgoText({date, muted, size, options = {}}: Props) {
  const timeAgo = useTimeAgo(date, options)
  return (
    <Text muted={muted} size={size}>
      {timeAgo}
    </Text>
  )
}
