import {AddIcon} from '@sanity/icons'
import {Card, Heading} from '@sanity/ui'
import React from 'react'
import {DialogButton} from '..'

type Props = {
  id: string
  buttonText?: React.ReactNode
}

export function InviteMembers({id, buttonText}: Props) {
  return (
    <DialogButton
      dialogHeader="Invite new members"
      dialogId={`invite-members-${id}`}
      buttonProps={{text: buttonText || 'Invite new members', icon: AddIcon, mode: 'ghost'}}
    >
      <Card shadow={1} padding={4}>
        <Heading>Hello world</Heading>
      </Card>
    </DialogButton>
  )
}
