import {
  AvatarWithFallback,
  LoginProvider,
  ManagedExternalBanner,
  TableCell,
  TableRow,
  TimeAgoText,
} from '@/components/index'
import {OrganizationSSOProvider, Project} from '@/types/index'
import {
  Box,
  Button,
  Card,
  Dialog,
  Flex,
  Grid,
  Heading,
  Inline,
  Layer,
  Stack,
  Text,
  useToast,
} from '@sanity/ui'
import {MenuMultiSelect} from '@/components/general/menuMultiSelect'
import {nameToInitials} from '@/components/members/utils'
import {useCurrentUser} from '@/context/useCurrentUser'
import {useRequiredProjectPermissions} from '@/context/useProjectPermissions'
import {useProjectUserRolesList} from '@/context/useProjectRolesList'
import {useState} from 'react'
import {RequestWithWarnings} from '@/data/projects/useProjectAccessRequests'
import {AvatarWithLoginProvider} from './projectRequestsTab'
import {acceptRequest, declineRequest} from '@sanity/access-api'

export const ProjectAccessRequestRow = ({
  onApprove,
  onDecline,
  request,
  isLast,
  providersMap,
  project,
}: {
  onApprove?: () => void
  onDecline?: () => void
  request: RequestWithWarnings
  isLast: boolean
  providersMap: Record<string, OrganizationSSOProvider>
  project: Project
}) => {
  const currentUser = useCurrentUser()
  const user = request.requestedByUserProfile

  const hasWarnings = request?.warnings?.length
  const [showWarningDialog, setShowWarningDialog] = useState(false)
  function closeDialog() {
    setShowWarningDialog(false)
  }

  const hasPermission = useRequiredProjectPermissions([
    {permissionName: 'sanity.project.members', grantName: 'invite'},
  ])
  const {roles: projectUserRoles} = useProjectUserRolesList(project?.id)
  const [roleValue, setRoleValue] = useState(['administrator']) // Default role is admin
  const managedExternal: boolean =
    user?.provider && providersMap[user.provider]
      ? providersMap[user?.provider].enforceRolesOnLogin
      : false

  const [isAccepting, setIsAccepting] = useState(false)
  const [isDeclining, setIsDeclining] = useState(false)
  const toast = useToast()

  if (!project || !currentUser || !user) return

  const handleOnAccept = async () => {
    try {
      setIsAccepting(true)
      await acceptRequest({
        path: {
          requestId: request.id!,
          resourceId: project.id,
          resourceType: 'project',
        },
        body: {
          roleNames: roleValue,
        },
      })
      toast.push({status: 'success', title: `Access approved for ${user.displayName}`})
      if (showWarningDialog) closeDialog()
      if (onApprove) onApprove()
    } catch (err) {
      toast.push({
        status: 'error',
        title: 'Something went wrong approving that user. Please try again.',
      })
    } finally {
      setIsAccepting(false)
    }
  }

  const handleOnDenied = async () => {
    try {
      setIsDeclining(true)
      await declineRequest({
        path: {
          requestId: request.id!,
          resourceId: project.id,
          resourceType: 'project',
        },
      })
      toast.push({status: 'success', title: `Access denied for ${user.displayName}`})
      if (onDecline) onDecline()
    } catch (err) {
      toast.push({
        status: 'error',
        title: 'Something went wrong denying that user. Please try again.',
      })
    } finally {
      setIsDeclining(false)
    }
  }
  return (
    <>
      <TableRow paddingY={3} paddingLeft={2} borderBottom={!isLast}>
        <TableCell>
          <AvatarWithLoginProvider>
            {user && (
              <AvatarWithFallback
                src={user.imageUrl || undefined}
                initials={nameToInitials(user.displayName)}
                size={1}
              />
            )}
            {user && user.provider !== 'sanity' && <LoginProvider provider={user.provider} />}
          </AvatarWithLoginProvider>
        </TableCell>
        <TableCell>
          <Stack space={2}>
            <Inline space={2}>
              <Heading size={1}>{user?.displayName}</Heading>
            </Inline>
            <Text size={1}>{user?.email}</Text>
          </Stack>
        </TableCell>
        <TableCell>
          <MenuMultiSelect
            id={`roles-${user.id}`}
            options={projectUserRoles}
            values={roleValue}
            onChange={(role) => setRoleValue([role])}
            disabled={!hasPermission}
            noValue={'No role'}
            banner={managedExternal ? <ManagedExternalBanner /> : undefined}
          />
        </TableCell>
        <TableCell>
          <Text size={1}>{request?.note ? `"${request?.note}"` : ''}</Text>
        </TableCell>
        <TableCell>
          <TimeAgoText size={1} muted date={request.createdAt || ''} options={{agoSuffix: true}} />
        </TableCell>
        <TableCell $align={'right'}>
          <Button
            fontSize={1}
            mode={'bleed'}
            onClick={handleOnDenied}
            text={'Deny'}
            loading={isDeclining}
          />
          <div style={{width: '4px'}} />
          <Button
            fontSize={1}
            mode="ghost"
            tone={'primary'}
            onClick={hasWarnings ? () => setShowWarningDialog(true) : handleOnAccept}
            loading={isAccepting}
            text="Approve"
          />
        </TableCell>
      </TableRow>
      {showWarningDialog && (
        <Layer zOffset={6} style={{position: 'fixed'}}>
          <Dialog
            id="confirm-request-approval-dialog"
            header="Do you trust this request?"
            width={1}
            style={{position: 'fixed'}}
            footer={
              <Card paddingY={2} paddingX={4}>
                <Grid columns={2} gap={2}>
                  <Button text="Back" mode="bleed" onClick={closeDialog} />
                  <Button
                    text="Confirm approval"
                    onClick={handleOnAccept}
                    loading={isAccepting}
                    tone="primary"
                  />
                </Grid>
              </Card>
            }
            onClose={closeDialog}
            onClickOutside={closeDialog}
          >
            <Box paddingX={4} paddingY={2}>
              <Flex>
                <Stack space={4} paddingBottom={2}>
                  <Text size={1}>
                    Make sure{' '}
                    <strong>
                      {user.displayName} ({user.email})
                    </strong>{' '}
                    is someone you trust.
                  </Text>

                  {request.warnings?.map((warning) => (
                    <Card tone="caution" padding={3} radius={1} shadow={1}>
                      <Text size={1}>
                        {warning === 'auth-provider' && (
                          <>
                            This user is using a different auth provider (
                            <strong style={{textTransform: 'capitalize'}}>{user.provider}</strong>)
                            than other members of this project.
                          </>
                        )}
                        {warning === 'nomatch-email-domain' && (
                          <>
                            This user's email domain (<strong>{user.email?.split('@')?.[1]}</strong>
                            ) does not match that of any other members of this project.
                          </>
                        )}
                        {warning === 'personal-email' && (
                          <>
                            This user (<strong>{user.email}</strong>) is using a personal email.
                            Make sure they're not impersonating a team member before accepting their
                            request.
                          </>
                        )}
                      </Text>
                    </Card>
                  ))}
                </Stack>
              </Flex>
            </Box>
          </Dialog>
        </Layer>
      )}
    </>
  )
}
