import {useCurrentScopeContext} from '.'
import {useOrganizationPermissions} from '@/data/access/useAccessPermissions'
import {useMemo} from 'react'
import type {RequiredPermissionsConfigUnion} from '@/types/permissions'
import {checkAccessPermissions, getPermissionsConfig} from '@/utils/permissions'

export const useRequiredOrganizationPermissions = (
  permissionsConfig: RequiredPermissionsConfigUnion,
  organizationId?: string
) => {
  const scope = useCurrentScopeContext()
  const targetOrgId = organizationId || scope?.currentOrgId
  const {grants: accessGrants} = useOrganizationPermissions(targetOrgId || '')

  return useMemo(() => {
    if (!targetOrgId) return true

    const config = getPermissionsConfig(permissionsConfig)
    return checkAccessPermissions(accessGrants, config)
  }, [permissionsConfig, targetOrgId, accessGrants])
}

export const useCanManageBilling = (organizationId?: string) => {
  const scope = useCurrentScopeContext()
  const targetOrgId = organizationId || scope?.currentOrgId
  const {grants: accessGrants} = useOrganizationPermissions(targetOrgId || '')
  const config = getPermissionsConfig({
    permissions: [{permissionName: 'sanity.organization', grantName: 'billing'}],
    allowPartialPermissions: false,
  })

  return checkAccessPermissions(accessGrants, config)
}

export const useCanManageOrganization = (organizationId?: string) =>
  useRequiredOrganizationPermissions(
    [{permissionName: 'sanity.organization', grantName: 'update'}],
    organizationId
  )

export const useCanReadOrganization = (organizationId?: string) =>
  useRequiredOrganizationPermissions(
    [{permissionName: 'sanity.organization', grantName: 'read'}],
    organizationId
  )
