import {useEffect, useState} from 'react'
import {useRouter} from 'next/router'

import {Tab} from '@/types/components'

export type Props = {
  tab?: Tab
}

export type Result = {
  /** The current hash. */
  hash: string

  /** The currently active heading. Only provided when `tab` is given. */
  activeHeadingPath: string | null

  /** The currently active section. Only provided when `tab` is given. */
  activeSectionPath: string | null
}

/**
 * useHashSection is a hook which handles the usage of a single page which has multiple pages
 * that can be navigated with a hash in the URL. This provides:
 *
 * - The currently active hash.
 * - Optionally accepts a Tab, and will then resolve the active heading/section.
 */
export function useHashSection({tab}: Props): Result {
  const router = useRouter()
  const [hash, setHash] = useState(window.location.hash)

  useEffect(() => {
    const hashChanged = (url: string) => {
      const m = url.match(/#.*$/)
      setHash(m ? m[0] : '')
    }

    router.events.on('hashChangeComplete', hashChanged)
    return () => router.events.off('hashChangeComplete', hashChanged)
  }, [router])

  let headingPath: string | undefined
  let sectionPath: string | undefined

  if (tab) {
    if (hash.startsWith('#')) {
      const path = hash.slice(1)
      const section = tab.sections?.find((sec) => sec.path === path)
      if (section) {
        sectionPath = section.path
        headingPath = section.heading
      } else {
        // Make sure there's actually a heading
        headingPath = tab.headings?.find((h) => h.path === path)?.path
      }
    } else {
      headingPath = tab.headings?.find((h) => h.isRoot)?.path
    }
  }

  return {
    hash,
    activeHeadingPath: headingPath || null,
    activeSectionPath: sectionPath || null,
  }
}
