import posthog, {PostHog} from 'posthog-js'
import {analyticsClient} from '@sanity/frontend-analytics'
import {ReactElement, createContext, useContext, useState} from 'react'
const POSTHOG_API_KEY = process.env.NEXT_PUBLIC_POSTHOG_API_KEY || ''
const isProd = process.env.NEXT_PUBLIC_SANITY_INTERNAL_ENV === 'production'

export type PosthogContextType = {
  posthog: PostHog | null
  init: () => void
}

export const PosthogContext = createContext<PosthogContextType>({posthog: null, init: () => {}})

export const usePosthog = () => {
  const {posthog, init} = useContext(PosthogContext)
  if (!posthog && posthog !== null) throw new Error('Store: missing context value')

  return {posthog, init}
}

export function PosthogContextProvider({children}: {children: ReactElement}) {
  const [posthogInstance, setPosthogInstance] = useState<PostHog | null>(null)
  const init = async () => {
    if (typeof window === 'undefined') return
    if (!POSTHOG_API_KEY) return
    const rudderId = analyticsClient.getAnonymousId()

    setPosthogInstance(
      posthog.init(POSTHOG_API_KEY, {
        api_host: isProd ? '/intake/ph' : 'https://eu.posthog.com',
        persistence: 'localStorage',
        session_recording: {
          maskAllInputs: true,
          maskInputFn: (text, element) => {
            if (element?.dataset['capture'] === 'true') {
              return text
            }
            return '*'.repeat(text.length)
          },
          maskTextSelector: '[data-recording-mask]',
        },
        loaded: (posthog) => {
          posthog.identify(rudderId)
        },
      })!
    )
  }

  return (
    <PosthogContext.Provider value={{posthog: posthogInstance, init}}>
      {children}
    </PosthogContext.Provider>
  )
}
