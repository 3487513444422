import {Text} from '@sanity/ui'

import {useRelativeTime} from '../../../hooks/useRelativeTime'
import {CellContentWrap} from './CellContentWrap'

interface TimeCellProps {
  date?: Date
}

export function TimeCell(props: TimeCellProps) {
  const {date} = props
  const timeAgo = useRelativeTime(date || '', {
    minimal: true,
    style: 'long',
  })

  return (
    <CellContentWrap>
      <Text size={1} muted>
        {timeAgo ? timeAgo : '-'}
      </Text>
    </CellContentWrap>
  )
}
