import {EditIcon, ToggleArrowRightIcon} from '@sanity/icons'
import {Box, Flex, Stack, Text} from '@sanity/ui'
import {type ReactNode, useCallback, useState} from 'react'
import styled from 'styled-components'

import {PermissionTooltip} from '../../generic/permission-tooltip'
import {Button} from '../../primitives/button/Button'

const FLEX_GAP: number = 3

const OpenButton = styled(Button)`
  &[data-open='true'] {
    [data-sanity-icon='toggle-arrow-right'] {
      transform: rotate(90deg);
    }
  }
`

interface EditAccordionProps {
  canEdit?: boolean
  title: string
  subtitle?: string
  children: ReactNode
  id: string
  initialOpen?: boolean
  onEditModeChange?: (isEditing: boolean) => void
}

export function EditAccordion(props: EditAccordionProps) {
  const {children, canEdit, title, subtitle, id, initialOpen = false, onEditModeChange} = props

  const [open, setOpen] = useState<boolean>(initialOpen)
  const [isEditing, setIsEditing] = useState<boolean>(false)

  const ariaControls = `section-${id}`
  const ariaLabelledBy = `accordion-${id}`

  const onToggle = useCallback(() => setOpen((v) => !v), [])

  const onEdit = useCallback(() => {
    setIsEditing((v) => !v)
    setOpen(true)

    onEditModeChange?.(!isEditing)
  }, [isEditing, onEditModeChange])

  return (
    <Stack space={4}>
      <Flex align="center" gap={FLEX_GAP}>
        <OpenButton
          aria-label={open ? 'Close section' : 'Open section'}
          data-open={open}
          fontSize={1}
          icon={ToggleArrowRightIcon}
          mode="bleed"
          onClick={onToggle}
          padding={2}
          tooltipProps={{content: open ? 'Close section' : 'Open section'}}
        />

        <Stack flex={1} space={3}>
          <Text as="h3" size={1} weight="bold">
            {title}
          </Text>

          {subtitle && (
            <Text size={1} muted>
              {subtitle}
            </Text>
          )}
        </Stack>

        <PermissionTooltip disabled={canEdit}>
          <Button
            aria-label={`Edit ${title.toLowerCase()}`}
            fontSize={1}
            disabled={!canEdit}
            icon={EditIcon}
            mode="ghost"
            onClick={onEdit}
            padding={2}
            selected={isEditing}
            tone={isEditing ? 'primary' : undefined}
            tooltipProps={{content: `Edit ${title.toLowerCase()}`}}
          />
        </PermissionTooltip>
      </Flex>

      <Flex
        id={ariaControls}
        role="region"
        aria-labelledby={ariaLabelledBy}
        hidden={!open}
        sizing="border"
        gap={FLEX_GAP}
      >
        <Box style={{width: 25}} />

        <Stack flex={1}>{children}</Stack>
      </Flex>
    </Stack>
  )
}
