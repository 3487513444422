import {TableHeader} from '@/types/index'

export const LARGE_LAYOUT: TableHeader[] = [
  {
    id: 'icon',
    label: 'Icon',
    columnWidth: '25px',
    disableTabButtonRender: true,
  },
  {
    id: 'period',
    label: 'Period',
    columnWidth: 4,
  },
  {
    id: 'amount',
    label: 'Amount',
    columnWidth: 3,
    hideOnMobile: true,
  },
  {
    id: 'status',
    label: 'Status',
    columnWidth: 2,
    hideOnMobile: true,
  },
  {
    id: 'action',
    label: 'Download as PDF',
    columnWidth: 2,
    disableTabButtonRender: true,
    alignment: 'right',
  },
]

export const PROJECT_USAGE_TABLE: TableHeader[] = [
  {
    id: 'picture',
    label: 'Picture',
    disableTabButtonRender: true,
    columnWidth: '40px',
  },
  {
    id: 'project-name',
    label: 'Project',
    columnWidth: 4.5,
  },
  {
    id: 'plan',
    label: 'Plan',
    columnWidth: 2,
    hideOnMobile: true,
  },
  {
    id: 'plan-cost',
    label: 'Plan cost',
    columnWidth: 2,
    alignment: 'right',
    hideOnMobile: true,
  },
  {
    id: 'overages',
    label: 'Overages',
    columnWidth: 1.5,
    alignment: 'right',
    hideOnMobile: true,
  },
  {
    id: 'total-cost',
    label: 'Total cost',
    columnWidth: 1.5,
    alignment: 'right',
    hideOnMobile: true,
  },
  {
    id: 'actions',
    label: 'Actions',
    disableTabButtonRender: true,
    alignment: 'right',
  },
]
