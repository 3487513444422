import {Announcement} from '@/types/models/announcement'

function isAnnouncement(data: any): data is Announcement {
  if (typeof data !== 'object' || data === null) {
    return false
  }

  if (typeof data.title !== 'string') {
    return false
  }

  if (typeof data.description !== 'string') {
    return false
  }

  if (typeof data.ctaText !== 'string' && typeof data.ctaText !== 'undefined') {
    return false
  }

  if (typeof data.ctaHref !== 'string' && typeof data.ctaHref !== 'undefined') {
    return false
  }

  return true
}

export function useAnnouncement(): Announcement | undefined {
  // eslint-disable-next-line no-process-env
  const rawData = process.env.NEXT_PUBLIC_ANNOUNCEMENT

  if (!rawData) {
    return undefined
  }

  let data: unknown
  try {
    data = JSON.parse(rawData)
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(`Invalid NEXT_PUBLIC_ANNOUNCEMENT: ${rawData}`)
    return undefined
  }

  if (!isAnnouncement(data)) {
    // eslint-disable-next-line no-console
    console.error(`Invalid NEXT_PUBLIC_ANNOUNCEMENT: ${rawData}`)
    return undefined
  }

  return data
}
