import {Flex, Card, Text} from '@sanity/ui'
import {ButtonWithExtendedTones} from '../general/ButtonWithExtendedTones'

export const SamlBanner = ({onClick, cta}: {onClick: () => void; cta: string}) => (
  <Card padding={4} borderTop>
    <Flex align="center" gap={3}>
      <svg
        width="45"
        height="31"
        viewBox="0 0 45 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.3"
          d="M4.7761 16.1464C3.21521 16.9268 0.891641 18.47 0.786846 23.7573C0.782784 23.9631 0.950039 24.1322 1.15586 24.1322H22.7708C22.9765 24.1322 23.1438 23.9629 23.1405 23.7572C23.0592 18.482 21.2446 17.1934 19.1505 16.1464C17.5534 15.3478 14.359 15.3478 14.359 12.9521C14.359 10.5564 15.9562 9.35849 15.9562 6.16415C15.9562 2.96984 14.359 1.37268 11.9633 1.37268C9.56757 1.37268 7.97041 2.96984 7.97041 6.16415C7.97041 9.35849 9.56757 10.5564 9.56757 12.9521C9.56757 15.3478 6.37326 15.3478 4.7761 16.1464Z"
          stroke="#3E41E7"
          stroke-linejoin="round"
        />
        <path
          d="M21.4637 19.8399C19.5036 20.8199 16.5812 22.7623 16.4756 29.446C16.4723 29.6518 16.6395 29.8221 16.8453 29.8221H44.0503C44.256 29.8221 44.4233 29.6515 44.4207 29.4457C44.3387 22.7743 42.0617 21.1547 39.4318 19.8399C37.4354 18.8416 33.4426 18.8416 33.4426 15.847C33.4426 12.8523 35.439 11.3549 35.439 7.36202C35.439 3.36912 33.4426 1.37268 30.4478 1.37268C27.453 1.37268 25.4566 3.36912 25.4566 7.36202C25.4566 11.3549 27.453 12.8523 27.453 15.847C27.453 18.8416 23.4602 18.8416 21.4637 19.8399Z"
          fill="#556BFC"
          fill-opacity="0.3"
          stroke="#3E41E7"
          stroke-linejoin="round"
        />
      </svg>
      <Flex direction="column" align="flex-start" justify="flex-start" gap={3}>
        <Text size={1} weight="semibold">
          Authenticate with SAML single sign-on
        </Text>
        <Text size={1} muted>
          Available as add-on for the Growth plan or included in{' '}
          <a href="/contact/sales?ref=saml-banner" target="_blank">
            Enterprise plan
          </a>
          .
        </Text>
      </Flex>
      <div style={{flex: 1}} />
      <ButtonWithExtendedTones tone="purple" mode="ghost" text={cta} onClick={onClick} />
    </Flex>
  </Card>
)
