/* eslint-disable max-nested-callbacks */

import {useMemo} from 'react'
import {OrganizationPaymentState} from '@/types/models/paymentstate'
import {useCurrentUserPermissions} from '@/data/access/useAccessPermissions'

/**
 * Returns a set of organization ids that the current user has billing admin access to
 * @param currentPaymentState - The current payment state of the organizations
 * @returns A set of organization ids that the current user has billing admin access to
 */
export function useOrganizationBillingAdminStatus(
  currentPaymentState: OrganizationPaymentState[]
): Set<string> {
  const {data: permissions} = useCurrentUserPermissions()
  const idsToCheck = useMemo(() => {
    return currentPaymentState.map((s) => s.organizationId)
  }, [currentPaymentState])

  return useMemo(() => {
    return new Set(
      permissions
        ?.filter((permission) => {
          return (
            permission.resourceType === 'organization' &&
            idsToCheck.includes(permission.resourceId) &&
            permission.name === `sanity.organization.billing`
          )
        })
        .map(({resourceId}) => {
          return resourceId
        })
    )
  }, [idsToCheck, permissions])
}
