// import {AppState} from './types'
import {WizardState} from './store/types'

export const INITIAL_STATE: WizardState = {
  newDatasetWizard: {
    open: false,
  },
  inviteMembersWizard: {
    open: false,
  },
  changePlanWizard: {
    open: false,
  },
  addBillingWizard: {
    open: false,
  },
  transferToOrg: undefined,
  createOrg: undefined,
  stepIndex: 0,
  existingCardDeclined: false,
  forceMoveToBillingStep: false,
  couponCode: undefined,
}
