/* eslint-disable complexity */
import {useMemo} from 'react'
import {parseISO} from 'date-fns'
import {buildStudioUrl, generateQueryString} from './general'
import {
  ActivityEvent,
  ActivityFilter,
  AssociatedLink,
  CurrentScope,
  EventAction,
} from '@/types/index'

export function generateFilter(filter: ActivityFilter): string {
  return generateQueryString(filter)
}

export const VALID_FILTERS = [
  'actorId',
  'metadata.dataset',
  'startTime',
  'endTime',
  'limit',
  'offset',
  'action',
]

export const activityKeyRegex = /{{(.*?)}}/g

export const truncateString = (
  value: string,
  beforeLength?: number,
  afterLength?: number,
  middleString?: string
) => {
  const l = value.length
  return `${value.substr(0, beforeLength || 4)}${middleString || '...'}${value.substr(
    l - (afterLength || 4),
    l
  )}`
}

export const generateActionsArray = (action: EventAction, prefix?: string) => {
  const actionPrefix = prefix && !action.hideParentPrefix ? `${prefix}.` : ''
  return (action.filters || [])?.map((filter) =>
    action.valuePrefix ? `${actionPrefix}${action.value}.${filter}` : `${actionPrefix}${filter}`
  )
}

const createDomainObject = (
  domain?: string,
  subdomain?: string,
  context?: string,
  crud?: string
) => {
  return {
    domain,
    subdomain,
    context,
    crud,
  }
}

const DOMAINS = ['project', 'organization', 'document']
const CRUD = ['create', 'edit', 'delete', 'publish', 'unpublish', 'archive', 'activate']

export const getActionDomain = (action: string) => {
  const actionValues = action.split('.')
  const domain = DOMAINS.find((d) => d === actionValues[0])
  const subdomain = actionValues[1]
  const context = CRUD.includes(actionValues[2]) ? undefined : actionValues[2]
  const crud = CRUD.find((value) => value === actionValues[actionValues.length - 1])
  if (domain === 'document') {
    return createDomainObject(domain, undefined, undefined, crud)
  }
  return createDomainObject(domain, subdomain, context, crud)
}

export const sortEvents = (events: ActivityEvent[]): ActivityEvent[] => {
  return events.sort((a, b) => {
    if (a.timestamp < b.timestamp) return 1
    if (a.timestamp > b.timestamp) return -1
    return 0
  })
}

export const getOldestActivityDate = (events: ActivityEvent[]): Date => {
  const [oldestEvent] = events.sort((a, b) => {
    return a.timestamp.localeCompare(b.timestamp)
  })

  if (!oldestEvent) {
    return new Date()
  }

  return parseISO(oldestEvent.timestamp)
}

export const deleteUndefinedFilters = (filters: any): any => {
  const newFilters = {...filters}
  for (const key in newFilters) {
    if ({}.hasOwnProperty.call(newFilters, key)) {
      const value = newFilters[key]

      if (Array.isArray(value)) {
        if (value.filter((arrVal) => typeof arrVal !== 'undefined').length === 0) {
          delete newFilters[key]
        }
      } else if (value === undefined) {
        delete newFilters[key]
      }
    }
  }

  return newFilters
}

export function useAssociatedLink(
  event: ActivityEvent,
  currentScope?: CurrentScope
): AssociatedLink {
  const {orgs} = currentScope || {}
  const {action, documentId} = event
  const baseUrl = event.organizationId
    ? `/organizations/${event.organizationId}`
    : `/manage/personal`

  const studioHostUrl = useMemo(() => {
    //get project related to the event
    const getProject = () => {
      return orgs?.flatMap((org: any) => org.projects)?.find(({id}) => id === event.projectId)
    }

    const host = () => {
      // return externalStudioHost if it exists
      if (getProject()?.metadata?.externalStudioHost) {
        return getProject()?.metadata?.externalStudioHost
      }

      // if there is no externalStudioHost, return studioHost if it exists
      if (getProject()?.studioHost) {
        return buildStudioUrl(getProject()?.studioHost || '')
      }

      return undefined
    }

    if (host() && documentId) return `${host()}/desk/__edit__${documentId}`
    if (host()) return host()
    return undefined
  }, [documentId, event.projectId, orgs])

  const associatedLink = () => {
    switch (action) {
      case `organization.members.role.edit`:
        return {
          url: `${baseUrl}/members`,
          label: `Show organization members`,
        }

      case `organization.members.delete`:
        return {
          url: `${baseUrl}/members`,
          label: `Show organization members`,
        }

      case `organization.members.invitations.create`:
        return {
          url: `${baseUrl}/members/invitations`,
          label: `Show organization invitations`,
        }

      case `organization.members.create`:
        return {
          url: `${baseUrl}/members`,
          label: `Show organization members`,
        }

      case `organization.members.invitations.delete`:
        return {
          url: `${baseUrl}/members/invitations`,
          label: `Show organization invitations`,
        }

      case `organization.create`:
        return {
          url: `${baseUrl}`,
          label: `Show organization`,
        }

      case `organization.name.edit`:
        return {
          url: `${baseUrl}/settings#org-details`,
          label: `Show settings`,
        }

      case `organization.billing.address.edit`:
        return {
          url: `${baseUrl}/settings#billing-address`,
          label: `Show settings`,
        }

      case `organization.billing.paymentMethod.create`:
        return {
          url: `${baseUrl}/settings#payment`,
          label: `Show settings`,
        }

      case `organization.billing.paymentMethod.delete`:
        return {
          url: `${baseUrl}/settings#payment`,
          label: `Show settings`,
        }

      case `organization.billing.paymentMethod.edit`:
        return {
          url: `${baseUrl}/settings#payment`,
          label: `Show settings`,
        }

      case `organization.contact.euRepresentative.edit`:
        return {
          url: `${baseUrl}/settings#contacts`,
          label: `Show settings`,
        }

      case `organization.contact.dataProtectionOfficer.edit`:
        return {
          url: `${baseUrl}/settings#contacts`,
          label: `Show settings`,
        }

      // PROJECT
      case `project.create`:
        return {
          url: `${baseUrl}/project/${event.projectId}`,
          label: `Show project`,
        }

      case `organization.project.create`:
        return {
          url: undefined,
          label: undefined,
        }

      case `organization.project.delete`:
        return {
          url: undefined,
          label: undefined,
        }

      case `organization.project.add`:
        return {
          url: undefined,
          label: undefined,
        }

      case `project.plan.edit`:
        return {
          url: `${baseUrl}/project/${event.projectId}/settings#plan`,
          label: `Show settings`,
        }

      case `project.archive`:
        return {
          url: `${baseUrl}/project/${event.projectId}/settings#archive`,
          label: `Show settings`,
        }

      case `project.activate`:
        return {
          url: `${baseUrl}/project/${event.projectId}/settings#archive`,
          label: `Show settings`,
        }

      case `project.name.edit`:
        return {
          url: `${baseUrl}/project/${event.projectId}/settings#details`,
          label: `Show settings`,
        }

      case `project.externalStudioHost.add`:
        return {
          url: `${baseUrl}/project/${event.projectId}/settings#details`,
          label: `Show settings`,
        }

      case `project.externalStudioHost.delete`:
        return {
          url: `${baseUrl}/project/${event.projectId}/settings#details`,
          label: `Show settings`,
        }

      case `project.externalStudioHost.edit`:
        return {
          url: `${baseUrl}/project/${event.projectId}/settings#details`,
          label: `Show settings`,
        }

      case `project.activityFeed.disable`:
        return {
          url: `${baseUrl}/project/${event.projectId}/settings#activity`,
          label: `Show settings`,
        }

      case `project.activityFeed.enable`:
        return {
          url: `${baseUrl}/project/${event.projectId}/settings#activity`,
          label: `Show settings`,
        }

      case `project.corsOrigin.create`:
        return {
          url: `${baseUrl}/project/${event.projectId}/api#cors-origins`,
          label: `Show API settings`,
        }

      case `project.corsOrigin.delete`:
        return {
          url: `${baseUrl}/project/${event.projectId}/api#cors-origins`,
          label: `Show API settings`,
        }

      case `project.webhook.create`:
        return {
          url: `${baseUrl}/project/${event.projectId}/api`,
          label: `Show API settings`,
        }

      case `project.webhook.delete`:
        return {
          url: `${baseUrl}/project/${event.projectId}/api`,
          label: `Show API settings`,
        }

      case `project.token.create`:
        return {
          url: `${baseUrl}/project/${event.projectId}/api#tokens`,
          label: `Show API settings`,
        }

      case `project.token.delete`:
        return {
          url: `${baseUrl}/project/${event.projectId}/api#tokens`,
          label: `Show API settings`,
        }

      case `project.members.invitations.create`:
        return {
          url: `${baseUrl}/project/${event.projectId}/members/invitations`,
          label: `Show project invitations`,
        }

      case `project.members.invitations.delete`:
        return {
          url: `${baseUrl}/members/invitations`,
          label: `Show project invitations`,
        }

      case `project.members.create`:
        return {
          url: `${baseUrl}/project/${event.projectId}/members/invitations`,
          label: `Show project invitations`,
        }

      case `project.members.role.edit`:
        return {
          url: `${baseUrl}/project/${event.projectId}/members`,
          label: `Show project members`,
        }

      case `project.members.delete`:
        return {
          url: `${baseUrl}/project/${event.projectId}/members`,
          label: `Show project invitations`,
        }

      case `project.members.group.create`:
        return {
          url: `${baseUrl}/project/${event.projectId}/members`,
          label: `Show project invitations`,
        }

      case `project.members.group.delete`:
        return {
          url: `${baseUrl}/project/${event.projectId}/members`,
          label: `Show project invitations`,
        }

      case `project.datasets.create`:
        return {
          url: `${baseUrl}/project/${event.projectId}/datasets`,
          label: `Show datasets`,
        }

      case `project.datasets.delete`:
        return {
          url: `${baseUrl}/project/${event.projectId}/datasets`,
          label: `Show datasets`,
        }

      case `project.datasets.edit`:
        return {
          url: `${baseUrl}/project/${event.projectId}/datasets`,
          label: `Show datasets`,
        }

      case `project.api.graphql.create`:
        return {
          url: `${baseUrl}/project/${event.projectId}/datasets`,
          label: undefined,
        }

      case `project.api.graphql.delete`:
        return {
          url: undefined,
          label: undefined,
        }

      // DOCUMENT
      case `document.create`:
        return {
          url: studioHostUrl,
          label: 'Show document',
          external: true,
        }

      case `document.delete`:
        return {
          url: undefined,
          label: undefined,
          external: undefined,
        }

      case `document.publish`:
        return {
          url: studioHostUrl,
          label: 'Show document',
          external: true,
        }

      case `document.unpublish`:
        return {
          url: studioHostUrl,
          label: 'Show document',
          external: true,
        }

      case `document.edit`:
        return {
          url: studioHostUrl,
          label: 'Show document',
          external: true,
        }

      default:
        return {
          url: undefined,
          label: undefined,
          external: undefined,
        }
    }
  }

  return associatedLink()
}

// Deserialize the activity metadata into component values
export function getActivityMetadata(event: ActivityEvent, key: string): string | null | undefined {
  const val = event.metadata[key]
  if (!val) {
    return undefined
  }
  if (val === 'null') {
    return null
  }
  return val
}
