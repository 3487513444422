import {useCurrentScopeContext} from '@/context/index'
import {AvatarWithFallback, LoginProvider} from '@/ui/index'
import {nameToInitials} from '@/components/members/utils'
import {logout} from '@/data/index'
import {useMatchesMedia} from '@/utils/index'
import {CogIcon, DesktopIcon, LeaveIcon, MoonIcon, SunIcon} from '@sanity/icons'
import {
  Box,
  Button,
  Card,
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  Stack,
  Text,
  Tooltip,
  Avatar as UserAvatar,
} from '@sanity/ui'
import {useRouter} from 'next/router'
import React, {useCallback} from 'react'
import {useManageScheme} from '../manageSchemeProvider'
import styled from 'styled-components'
import {getProviderTitle} from '@/ui/index'
import Link from 'next/link'

const StyledMenu = styled(Menu)`
  min-width: 200px;
  max-width: 300px;
`

const AvatarBox = styled(Box)`
  position: relative;
  min-width: ${({theme}) => theme.sanity.avatar.sizes[2].size}px;
  min-height: ${({theme}) => theme.sanity.avatar.sizes[2].size}px;
`

export function UserMenu() {
  const context = useCurrentScopeContext()
  const currentUser = context?.currentUser
  const router = useRouter()
  const {scheme, setScheme} = useManageScheme()
  const isMobile = useMatchesMedia(1)
  const providerTitle = getProviderTitle(currentUser?.provider)

  const handleSignOut = useCallback(async () => {
    const done = await logout()
    if (done) {
      router.reload()
    }
  }, [router])

  if (!currentUser) {
    return null
  }

  return (
    <MenuButton
      button={
        <Button mode="bleed" padding={isMobile ? 0 : 2}>
          <Flex align="center">
            <AvatarWithFallback
              size={1}
              src={currentUser.profileImage}
              initials={nameToInitials(currentUser.name)}
            />
          </Flex>
        </Button>
      }
      id="user-menu"
      popover={{
        placement: 'bottom-end',
      }}
      menu={
        <StyledMenu>
          <Card padding={2}>
            <Flex align="center">
              <Tooltip
                disabled={!providerTitle}
                portal
                content={
                  <Box padding={1}>
                    <Text muted size={1}>
                      Signed in with {providerTitle}
                    </Text>
                  </Box>
                }
              >
                <AvatarBox marginRight={3}>
                  <UserAvatar
                    color="cyan"
                    size={2}
                    src={currentUser.profileImage}
                    initials={nameToInitials(currentUser.name)}
                  />
                  {currentUser?.provider && <LoginProvider provider={currentUser.provider} />}
                </AvatarBox>
              </Tooltip>

              <Stack space={2} flex={1}>
                <Text size={1} weight="medium" textOverflow="ellipsis">
                  {currentUser?.name}
                </Text>

                <Text size={1} muted textOverflow="ellipsis">
                  {currentUser?.email}
                </Text>
              </Stack>
            </Flex>
          </Card>

          <MenuDivider />
          <MenuItem
            text="System"
            size={2}
            icon={DesktopIcon}
            onClick={() => setScheme('system')}
            pressed={scheme === 'system'}
          />
          <MenuItem
            text="Dark"
            size={2}
            icon={MoonIcon}
            onClick={() => setScheme('dark')}
            pressed={scheme === 'dark'}
          />
          <MenuItem
            text="Light"
            size={2}
            icon={SunIcon}
            onClick={() => setScheme('light')}
            pressed={scheme === 'light'}
          />
          <MenuDivider />
          <Link href="/manage/personal/account-settings" passHref legacyBehavior>
            <MenuItem text="Account settings" as="a" icon={CogIcon} size={2} />
          </Link>
          <MenuItem text="Sign out" size={2} icon={LeaveIcon} onClick={handleSignOut} />
        </StyledMenu>
      }
    />
  )
}
