import React from 'react'
import {Card, ThemeColorToneKey, Flex, Text, Stack, Box, useTheme} from '@sanity/ui'
import {Icon, IconSymbol} from '@sanity/icons'

export type PromptPadding = {
  titlePadding: number
  descriptionPadding: number
}

export type PromptProps = {
  tone?: ThemeColorToneKey
  icon?: IconSymbol
  title?: string | React.ReactNode | JSX.Element
  button?: React.ReactNode
  children?: React.ReactNode
  layout?: 'row' | 'column'
  description?: string | React.ReactNode | JSX.Element
  cardProps?: any
  textSize?: number
  verticaSpacing?: number
  as?: keyof JSX.IntrinsicElements
}

export function Prompt({
  tone,
  icon,
  children,
  title,
  description,
  button,
  textSize = 1,
  layout = 'row',
  cardProps,
  verticaSpacing = 3,
  as = 'p',
}: PromptProps) {
  const {sanity} = useTheme()
  const textColor = sanity.color.solid[tone || 'default'].pressed.bg

  const direction: any = layout === 'column' ? 'column' : ['column', 'column', 'row']
  const align: any = layout === 'column' ? 'flex-start' : ['flex-start', 'flex-start', 'center']

  return (
    <Card tone={tone} padding={3} radius={3} border sizing="border" {...cardProps}>
      <Flex align={align} justify="space-between" direction={direction}>
        <Box padding={3} flex={1}>
          <Stack space={4}>
            <Flex align={align}>
              {icon && (
                <Box marginRight={4}>
                  <Text>
                    <Icon symbol={icon} style={{color: textColor}} />
                  </Text>
                </Box>
              )}
              <Box>
                <Stack space={verticaSpacing}>
                  {title && (
                    <Box flex={1} marginRight={[0, 0, 7]}>
                      <Text as={as} size={textSize} weight="semibold" style={{color: textColor}}>
                        {title}
                      </Text>
                    </Box>
                  )}
                  {description && (
                    <Text as={as} size={textSize} style={{color: textColor}}>
                      {description}
                    </Text>
                  )}
                  <>{children}</>
                </Stack>
              </Box>
            </Flex>
            {button && layout === 'column' && (
              <Box marginLeft={icon ? 5 : 0} style={{width: 'max-content'}}>
                {button}
              </Box>
            )}
          </Stack>
        </Box>
        {button && layout === 'row' && <>{button}</>}
      </Flex>
    </Card>
  )
}
