import {Project} from '../../types'
import {tags} from '.'

import {useQuery} from '@tanstack/react-query'
import {getProjectTagsKey, STALE_TTL} from '../projects/cache'

export function useTags(project: Project) {
  return useQuery({
    queryKey: getProjectTagsKey(project.id),
    queryFn: () => tags(project),
    staleTime: STALE_TTL,
  })
}
