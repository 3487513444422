import React, {useCallback, useEffect, useRef} from 'react'
import {Card, Text, Flex, Box} from '@sanity/ui'
import {CheckmarkIcon, ChevronRightIcon} from '@sanity/icons'
import styled, {css} from 'styled-components'
import {ITEM_HEIGHT} from './config'

type Props = {
  text: string
  active?: boolean
  onMouseOver?: (y: number) => void
  onClick?: () => void
  hasChildren?: boolean | undefined
  selected?: boolean
  focus?: boolean
  input: 'mouse' | 'keyboard'
  onFocus?: (y: number) => void
  tabIndex?: number
}

const Button = styled.button<{$active: boolean; $selected: boolean; $input: 'mouse' | 'keyboard'}>`
  height: ${ITEM_HEIGHT}px;
  text-align: start;
  width: 100%;
  border: 0;
  border-radius: 0.35rem;
  background: ${({theme}) => theme.sanity.color.base.bg};
  color: ${({theme}) => theme.sanity.color.base.fg};

  ${({$active: active}) =>
    active &&
    css`
      background: ${({theme}) => theme.sanity.color.card.hovered.bg};
      color: ${({theme}) => theme.sanity.color.base.fg};
    `}

  ${({$selected: selected}) =>
    selected &&
    css`
      background: ${({theme}) => theme.sanity.color.muted.primary.enabled.bg};
      color: ${({theme}) => theme.sanity.color.muted.primary.enabled.fg};
    `}

  ${({$selected: selected, $input: input}) =>
    !selected &&
    input === 'mouse' &&
    css`
      outline: 0;
      @media (hover: hover) {
        &:hover {
          background: ${({theme}) => theme.sanity.color.card.hovered.bg};
          color: ${({theme}) => theme.sanity.color.base.fg};
        }
      }
    `}

    ${({$selected: selected, $input: input}) =>
    !selected &&
    input === 'keyboard' &&
    css`
      &:focus {
        background: ${({theme}) => theme.sanity.color.card.hovered.bg};
        color: ${({theme}) => theme.sanity.color.base.fg};
      }
    `}
`

export function DrawerItem({
  active = false,
  selected = false,
  text,
  onMouseOver,
  onClick,
  hasChildren = false,
  focus,
  onFocus,
  input,
  tabIndex,
}: Props) {
  const ref = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    const {current} = ref

    if (focus) {
      current?.focus()
    } else {
      current?.blur()
    }
  }, [focus, ref])

  useEffect(() => {
    const {current} = ref

    if (input === 'mouse') {
      current?.blur()
    }
  }, [ref, input])

  const handleMouseOver = useCallback(() => {
    if (!onMouseOver) return

    const button = ref.current!
    const {y} = button.getBoundingClientRect()

    onMouseOver(y)
  }, [onMouseOver])

  const handleOnFocus = useCallback(() => {
    if (!onFocus) return
    const button = ref.current!
    const {y} = button.getBoundingClientRect()
    onFocus(y)
  }, [onFocus, ref])

  return (
    <Card as="li" sizing="border" paddingX={2}>
      <Button
        onMouseOver={handleMouseOver}
        onFocus={handleOnFocus}
        onClick={onClick}
        $active={active}
        $selected={selected}
        $input={input}
        ref={ref}
        tabIndex={tabIndex}
      >
        <Box paddingLeft={2} paddingRight={2} sizing="border">
          <Flex align="center" justify="space-between">
            <Box style={{maxWidth: 275}}>
              <Text size={1} weight="medium" textOverflow="ellipsis" style={{color: 'inherit'}}>
                {text}
              </Text>
            </Box>

            {(selected || hasChildren) && (
              <Box marginLeft={4}>
                <Flex>
                  {selected && (
                    <Box>
                      <Text size={2} style={{color: 'inherit'}}>
                        <CheckmarkIcon />
                      </Text>
                    </Box>
                  )}
                  {hasChildren && (
                    <Box marginLeft={3}>
                      <Text size={2} style={{color: 'inherit'}}>
                        <ChevronRightIcon />
                      </Text>
                    </Box>
                  )}
                </Flex>
              </Box>
            )}
          </Flex>
        </Box>
      </Button>
    </Card>
  )
}
