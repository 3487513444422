/* eslint-disable complexity */
import React from 'react'

export const SSO = ({color}: {color?: string}) => (
  <svg
    width="242"
    height="120"
    viewBox="0 0 242 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M52.4743 61.6429C48.2859 63.737 42.051 67.8778 41.7698 82.0654C41.7589 82.6176 42.2077 83.0714 42.76 83.0714H100.76C101.312 83.0714 101.761 82.6172 101.752 82.065C101.534 67.91 96.6647 64.4524 91.0457 61.6429C86.76 59.5 78.1885 59.5 78.1885 53.0714C78.1885 46.6429 82.4743 43.4286 82.4742 34.8571C82.4742 26.2857 78.1885 22 71.76 22C65.3314 22 61.0457 26.2857 61.0457 34.8571C61.0457 43.4286 65.3314 46.6429 65.3314 53.0714C65.3314 59.5 56.76 59.5 52.4743 61.6429Z"
      stroke={color || '#2276FC'}
      strokeOpacity="0.25"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M12.9286 55.0357C9.45453 56.7727 4.29083 60.1994 4.0118 71.8901C3.99863 72.4422 4.44771 72.8929 5 72.8929H53C53.5523 72.8929 54.0011 72.4421 53.9908 71.8899C53.7735 60.2315 49.7321 57.3661 45.0714 55.0357C41.5 53.25 34.3571 53.25 34.3571 47.8929C34.3571 42.5357 37.9286 39.8571 37.9286 32.7143C37.9285 25.5714 34.3571 22 29 22C23.6428 22 20.0714 25.5714 20.0714 32.7143C20.0714 39.8571 23.6429 42.5357 23.6429 47.8929C23.6429 53.25 16.5 53.25 12.9286 55.0357Z"
      stroke={color || '#2276FC'}
      strokeOpacity="0.25"
      strokeLinejoin="round"
    />
    <path
      d="M190.146 61.6429C194.334 63.737 200.569 67.8778 200.85 82.0654C200.861 82.6176 200.412 83.0714 199.86 83.0714H141.86C141.308 83.0714 140.859 82.6172 140.868 82.065C141.086 67.91 145.955 64.4524 151.574 61.6429C155.86 59.5 164.431 59.5 164.431 53.0714C164.431 46.6429 160.146 43.4286 160.146 34.8571C160.146 26.2857 164.431 22 170.86 22C177.289 22 181.574 26.2857 181.574 34.8571C181.574 43.4286 177.289 46.6429 177.289 53.0714C177.289 59.5 185.86 59.5 190.146 61.6429Z"
      stroke={color || '#2276FC'}
      strokeOpacity="0.25"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M229.691 55.0357C233.165 56.7727 238.329 60.1994 238.608 71.8901C238.621 72.4422 238.172 72.8929 237.62 72.8929H189.62C189.068 72.8929 188.619 72.4421 188.629 71.8899C188.846 60.2315 192.888 57.3661 197.549 55.0357C201.12 53.25 208.263 53.25 208.263 47.8929C208.263 42.5357 204.691 39.8571 204.691 32.7143C204.691 25.5714 208.263 22 213.62 22C218.977 22 222.549 25.5714 222.549 32.7143C222.549 39.8571 218.977 42.5357 218.977 47.8929C218.977 53.25 226.12 53.25 229.691 55.0357Z"
      stroke={color || '#2276FC'}
      strokeOpacity="0.25"
      strokeLinejoin="round"
    />
    <path
      d="M97.2528 71.5536C91.9931 74.1835 84.1513 79.3954 83.8679 97.33C83.8592 97.8822 84.3077 98.3393 84.86 98.3393H157.86C158.412 98.3393 158.861 97.8815 158.854 97.3293C158.634 79.4278 152.524 75.0818 145.467 71.5536C140.11 68.875 129.396 68.875 129.396 60.8393C129.396 52.8036 134.753 48.7857 134.753 38.0714C134.753 27.3571 129.396 22 121.36 22C113.324 22 107.967 27.3571 107.967 38.0714C107.967 48.7857 113.324 52.8036 113.324 60.8393C113.324 68.875 102.61 68.875 97.2528 71.5536Z"
      fill="#2276FC"
      fillOpacity="0.25"
      stroke={color || '#2276FC'}
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
  </svg>
)
SSO.displayName = 'SSO'
