import {timeToMilliseconds} from '@/utils/time'

export const STALE_TTL = timeToMilliseconds(5, 'minutes')

export function getOrganizationsKey() {
  return ['organizations']
}

export function getOrganizationBaseKey(orgId?: string) {
  return ['organization', orgId, 'data']
}

export function getOrganizationRolesKey(orgId?: string) {
  return ['organization', orgId, 'roles']
}

export function getOrganizationAccessRolesKey(orgId?: string) {
  return ['organization', orgId, 'accessRoles']
}

export function getOrganizationInvoicesKey(customerId?: string) {
  return ['organization', customerId, 'invoices']
}

export function getOrganizationCanPayKey(orgId?: string | null) {
  return ['organization', orgId, 'canPay']
}
