import React from 'react'
import styled from 'styled-components'
import {useUIDSeed} from 'react-uid'
import {format, parseISO} from 'date-fns'
import {HistoryPeriod, ApexAxisChartSeries} from '@/types/index'

interface TooltipProps {
  originalSeries?: ApexAxisChartSeries
  series: number[][]
  seriesIndex: number
  formatDataPointLabelAsISODate?: boolean
  dataPointIndex: number
  formatter: any
  stacked?: boolean
  isDifferentCompareYear?: boolean
  period?: HistoryPeriod
  w?: any
}

const DotWrapper = styled.div`
  display: flex;
  align-items: center;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1;
  row-gap: 0.2em;
`

const ProjectDot = styled.div`
  background-color: ${(props) => props.color || '#000'};
  border-radius: 100%;
  width: 0.5rem;
  height: 0.5rem;
  margin-right: 0.5rem;
`

export function Tooltip({
  series,
  originalSeries,
  seriesIndex,
  period,
  dataPointIndex,
  formatDataPointLabelAsISODate = false,
  isDifferentCompareYear = false,
  formatter,
  w,
  stacked = false,
}: TooltipProps) {
  let value = series[seriesIndex][dataPointIndex]
  let values: React.ReactNode[] = []
  const seed = useUIDSeed()

  if (stacked) {
    values = series
      .map((serie, index) => {
        return {
          ...serie,
          value: serie[dataPointIndex],
          formattedValue: formatter(serie[dataPointIndex]),
          originalIndex: index,
        }
      })
      .filter((serie) => serie.value > 0)
      .sort((serieA, serieB) => {
        return serieA.value > serieB.value ? -1 : 1
      })
      .map((serie) => {
        const {originalIndex, formattedValue} = serie
        const {color, name} = w.config.series[originalIndex]
        let displayName = name

        if (formatDataPointLabelAsISODate) {
          const dataPoint = originalSeries?.[originalIndex]?.data?.[dataPointIndex]

          if (
            typeof dataPoint === 'undefined' ||
            (typeof dataPoint === 'object' && !Array.isArray(dataPoint) && dataPoint?.y === null)
          ) {
            return null
          }

          if (typeof dataPoint?.date === 'string') {
            displayName =
              period === 'month'
                ? format(parseISO(dataPoint?.date), 'MMM yyyy')
                : format(
                    parseISO(dataPoint?.date),
                    isDifferentCompareYear ? 'MMM do yyyy' : 'MMM do'
                  )
          }
        }

        // if (parseInt(formattedValue, 2) <= 0) {
        //   return null
        // }

        return (
          <>
            <ProjectDot color={color} />
            {displayName}: {formattedValue}
          </>
        )
      })
      .filter((val) => val !== null)
  } else {
    value = formatter(value)
  }

  if (stacked && values.length === 0) {
    return null
  }

  // const color = w.config.colors[seriesIndex]
  return (
    <div
      style={{
        padding: '2px 5px',
      }}
    >
      {/* <span
        style={{
          display: 'inline-block',
          width: '10px',
          height: '10px',
          borderRadius: '3px',
          border: '1px solid #fff',
          marginRight: '5px',
          marginBottom: '-1px',
          backgroundColor: color,
        }}
      ></span> */}
      {!stacked && <span>{value}</span>}
      {stacked && (
        <Grid>
          {values.map((stackedValue) => (
            <DotWrapper key={seed(stackedValue)}>{stackedValue}</DotWrapper>
          ))}
        </Grid>
      )}
    </div>
  )
}
