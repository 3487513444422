import {Button, Card, Flex, Stack, Text} from '@sanity/ui'
import React, {useCallback} from 'react'
import {UserIcon} from '@sanity/icons'
import styled from 'styled-components'
import {getPlanCopy} from '@/ui/index'
import {Plan, Project} from '@/types/models'
import {getProjectMemberState} from '@/utils/usage'
import {useProjectType} from '@/context/useProjectType'

const InnerCard = styled(Card)`
  background: inherit;
`

export function PlanPreview(
  props: {
    buttonText?: React.ReactNode
    currentPlan: Plan
    disabled?: boolean
    footer?: React.ReactNode
    targetPlan: Plan
    onClick?: (plan: Plan) => void
    selected?: boolean
    project: Project
    isCurrentPlan?: boolean
  } & Omit<React.HTMLProps<HTMLDivElement>, 'as' | 'height' | 'onClick' | 'ref'>
) {
  const {
    buttonText,
    currentPlan,
    disabled,
    footer,
    targetPlan,
    onClick,
    selected,
    project,
    ...restProps
  } = props

  const projectType = useProjectType(project)
  const numberSeats = getProjectMemberState(project, projectType, targetPlan.resources).billable

  const isPerSeatPlan = targetPlan.pricingModel === 'per-seat'

  // null for enterprise plans
  const targetPlanPrice =
    targetPlan.price && (isPerSeatPlan ? targetPlan.price * numberSeats : targetPlan.price)

  const currentPlanPrice =
    currentPlan.price &&
    (currentPlan.pricingModel === 'per-seat' ? currentPlan.price * numberSeats : currentPlan.price)

  const handleClick = useCallback(() => {
    if (onClick) onClick(targetPlan)
  }, [targetPlan, onClick])

  const isDowngrade =
    targetPlanPrice !== null &&
    Number.isInteger(targetPlanPrice) &&
    currentPlanPrice !== null &&
    Number.isInteger(currentPlanPrice) &&
    targetPlanPrice < currentPlanPrice

  const copyConfig = getPlanCopy(targetPlan)
  const isLegacy = targetPlan.supersededAt !== null

  return (
    <Card
      {...restProps}
      border
      tone={props.isCurrentPlan ? undefined : 'primary'}
      data-lower={isDowngrade ? '' : undefined}
      padding={2}
      radius={2}
    >
      <Flex direction="column" height="fill">
        <Flex paddingY={2} paddingBottom={3}>
          <Stack flex={1} space={2}>
            <Text as="h3" size={2} weight="bold">
              {`${isLegacy ? 'Legacy ' : ''}${targetPlan.name}`}
            </Text>
          </Stack>
          <Stack space={3} marginTop={2}>
            {targetPlanPrice !== null && ( // null for enterprise plans
              <>
                <Text align="right" size={2}>
                  {isPerSeatPlan && targetPlanPrice > 0 && (
                    <Flex direction="row" align="center">
                      <Text>
                        ${targetPlan.price} x&nbsp;&nbsp;
                        <UserIcon style={{padding: '0 3px 2px 0'}} />
                        &nbsp;
                        {numberSeats} =&nbsp;
                      </Text>
                      <Text style={{fontWeight: 600}}>${`${targetPlanPrice}`}</Text>
                    </Flex>
                  )}
                  {!isPerSeatPlan && targetPlanPrice >= 0 && <Text>${targetPlanPrice}</Text>}
                </Text>
                <Text align="right" muted size={1} style={{marginBottom: 8}}>
                  {targetPlanPrice >= 0 ? <>per month</> : <>&nbsp;</>}
                </Text>
              </>
            )}
          </Stack>
        </Flex>

        <InnerCard borderTop flex={1} paddingX={1} paddingY={4} tone="inherit">
          <Stack flex={1} space={2}>
            <Text muted size={2}>
              {copyConfig?.description}
            </Text>
          </Stack>
        </InnerCard>

        {onClick && (
          <Stack>
            {targetPlan.planTypeId !== 'enterprise' && (
              <Button
                disabled={disabled || selected}
                mode={isDowngrade ? 'ghost' : undefined}
                onClick={handleClick}
                text={
                  <>
                    {buttonText}
                    {!buttonText && (
                      <>
                        {isDowngrade ? 'Downgrade' : 'Upgrade'} to {targetPlan.name}
                      </>
                    )}
                  </>
                }
                tone={targetPlan.planTypeId < currentPlan.planTypeId ? 'critical' : 'positive'}
              />
            )}
            {targetPlan.planTypeId === 'enterprise' && (
              <Button
                as="a"
                disabled={disabled || selected}
                href={`https://www.${process.env.host}/contact/sales?ref=manage-plan-preview`}
                rel="noopener"
                target="_blank"
                text="Contact sales"
                tone="primary"
              />
            )}
          </Stack>
        )}
      </Flex>
      {footer}
    </Card>
  )
}
