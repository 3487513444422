import {Container, Box, Card, Stack, Text, Flex} from '@sanity/ui'
import React from 'react'
import {hues} from '@sanity/color'
import SanityLogo from '../SanityLogo'
import {useManageScheme} from '../manageSchemeProvider'
import StatusBadge from './statusBadge'

export const Footer = React.memo(function Footer() {
  const getCurrentYear = new Date().getFullYear()
  const {resolvedScheme} = useManageScheme()

  return (
    <Card borderTop as="footer">
      <Container width={3}>
        <Box paddingX={4} paddingY={5}>
          <Stack space={4}>
            <Flex
              align={['flex-start', 'flex-start', 'center']}
              justify="space-between"
              direction={['column', 'column', 'row']}
            >
              <Flex
                align={['flex-start', 'flex-start', 'center']}
                justify="space-between"
                direction={['column', 'column', 'row']}
              >
                {/* logo */}
                <SanityLogo
                  color={resolvedScheme === 'dark' ? hues.gray[100].hex : hues.red[500].hex}
                />

                {/* status */}
                <Box marginLeft={[0, 0, 4]} marginTop={[4, 4, 0]}>
                  <StatusBadge />
                </Box>
              </Flex>
            </Flex>
            <Text size={1} muted>
              Copyright © {getCurrentYear} Sanity AS. All rights reserved.
            </Text>
          </Stack>
        </Box>
      </Container>
    </Card>
  )
})
