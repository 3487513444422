import React from 'react'
import {Box, Button, Flex, Heading, Layer, Dialog as UIDialog} from '@sanity/ui'
import {useRouter} from 'next/router'
import {useUID} from 'react-uid'
import styled from 'styled-components'
import {CloseIcon} from '@sanity/icons'
import {DescriptionSerializer} from './descriptionSerializer'
import {ExperienceModal} from '@/data/experienceDialogs'
import {TrialDialogDismissedAttributes} from '@/utils/tracking/dialogTracking'

const Dialog = styled(UIDialog)`
  & [data-ui='DialogCard'] {
    max-width: 440px;
  }
`

const Image = styled.img`
  object-fit: cover;
  width: 100%;
`

const CloseButton = styled(Button)`
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 20;
  background: transparent;
  border-radius: 9999px;
  box-shadow: none;
  color: white;
  --card-fg-color: white;
  :hover {
    --card-fg-color: white !important;
  }
`

export function ExperienceDialog({
  dialogContent,
  onClose,
  ctaClickCallback,
}: {
  dialogContent: ExperienceModal
  onClose: (dialogDismissAction: TrialDialogDismissedAttributes['dialogDismissAction']) => void
  ctaClickCallback: () => void
}) {
  const uId = useUID()
  const router = useRouter()

  function onCTAClick() {
    ctaClickCallback()
    onClose('ctaClicked')
    switch (dialogContent.ctaButton?.action) {
      case 'closeDialog':
        break
      case 'openUrl':
        if (dialogContent.ctaButton.url) router.push(dialogContent.ctaButton.url)
        break
      default:
    }
  }

  return (
    <Layer zOffset={6} style={{position: 'fixed'}}>
      <Dialog
        id={uId}
        onClose={() => onClose('xClick')}
        onClickOutside={() => onClose('outsideClick')}
        __unstable_hideCloseButton
        width={1}
        style={{position: 'fixed'}}
        footer={
          <Flex paddingY={3} paddingX={4} gap={3} align={'center'} justify={'flex-end'}>
            {dialogContent.secondaryButton ? (
              <Button
                onClick={() => onClose('ctaClicked')}
                mode="bleed"
                text={dialogContent.secondaryButton.text}
              />
            ) : null}

            {dialogContent.ctaButton ? (
              <Button
                onClick={onCTAClick}
                text={dialogContent.ctaButton.text}
                mode="default"
                tone={'primary'}
              />
            ) : null}
          </Flex>
        }
      >
        <CloseButton
          icon={CloseIcon}
          mode="bleed"
          tone="default"
          onClick={() => onClose('xClick')}
          tabIndex={-1}
        />
        {dialogContent.image ? (
          <Image
            src={dialogContent.image.asset.url}
            alt={dialogContent.image.asset.altText ?? ''}
            height={'200px'}
          />
        ) : null}
        <Flex padding={3} direction={'column'}>
          <Box paddingX={2} marginTop={3}>
            <Heading size={2}>{dialogContent.headingText}</Heading>
          </Box>
          <Box marginTop={4} paddingBottom={3}>
            <DescriptionSerializer blocks={dialogContent.descriptionText} />
          </Box>
        </Flex>
      </Dialog>
    </Layer>
  )
}
