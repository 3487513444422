import {deletePermissionResource} from '@/data/projects'
import {getProjectPermissionResourcesKey} from '@/data/projects/cache'
import {useProjectPermissionResources} from '@/data/projects/useProjectRoles'
import {Tab} from '@/types/components'
import {PermissionResource, Project} from '@/types/models'
import {PermissionButtonProject, TabHeader, useConfirmDialog} from '@/ui/index'
import {AddIcon, EditIcon, LockIcon, TrashIcon} from '@sanity/icons'
import {
  Button,
  Card,
  Code,
  Flex,
  Grid,
  HeadingSkeleton,
  Inline,
  Label,
  Stack,
  Text,
  TextSkeleton,
  useToast,
} from '@sanity/ui'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {useCallback, useEffect, useState} from 'react'
import {contentResourceCompare, isUserResource} from '../roles/contentResource/util'
import {ContentResourceWizard} from './wizard'

function ContentResourceRow({
  resource,
  loading,
  handleEditOpen,
  handleDelete,
  isFirst,
}: {
  resource: PermissionResource
  loading: boolean
  handleEditOpen: (r: PermissionResource) => void
  handleDelete: (r: PermissionResource) => void
  isFirst: boolean
}) {
  return (
    <Card borderTop={!isFirst} paddingX={2} paddingY={3}>
      <Flex align="center">
        <Stack space={2} flex={1}>
          {resource.isCustom ? (
            <Text weight="semibold">{resource.title}</Text>
          ) : (
            <Inline space={2}>
              <Text weight="semibold" muted>
                {resource.title}
              </Text>
              <Text weight="semibold" muted>
                <LockIcon />
              </Text>
            </Inline>
          )}

          <Text muted size={1}>
            {resource.description || <em>No description</em>}
          </Text>
        </Stack>
        <Inline space={2}>
          <Button
            icon={EditIcon}
            text="Edit"
            mode="ghost"
            disabled={loading || !resource.isCustom}
            onClick={() => handleEditOpen(resource)} // eslint-disable-line react/jsx-no-bind
          />
          <Button
            icon={TrashIcon}
            mode="bleed"
            disabled={loading || !resource.isCustom}
            onClick={() => handleDelete(resource)} // eslint-disable-line react/jsx-no-bind
          />
        </Inline>
      </Flex>
    </Card>
  )
}

type Props = {
  project: Project
  title: string
  description: string
}
export function ProjectResources({project, title, description, referenceLink}: Props & Tab) {
  const toast = useToast()
  const {showDialog, Dialog, hideDialog} = useConfirmDialog()
  const [loading, setLoading] = useState(false)
  const [roleWizard, setRoleWizard] = useState(false)
  const [focus, setFocus] = useState<PermissionResource | undefined>()
  const [toDelete, setToDelete] = useState<PermissionResource | undefined>()
  const hasFeature = project.features.includes('advancedRolesManagement')
  const queryClient = useQueryClient()

  const {
    data: rawResources = [],
    isLoading: loadingResources,
    error: errorResources,
  } = useProjectPermissionResources(project.id)

  const handleRoleWizardOpen = useCallback(() => {
    setRoleWizard(true)
  }, [])

  const handleRoleWizardClose = useCallback(
    (isDirty: boolean) => {
      setRoleWizard(false)
      setFocus(undefined)
      if (isDirty) {
        queryClient.invalidateQueries({queryKey: getProjectPermissionResourcesKey(project.id)})
      }
    },
    [project.id, queryClient]
  )

  const handleEditOpen = useCallback((resource: PermissionResource) => {
    setFocus(resource)
  }, [])

  const clearDelete = useCallback(() => {
    setToDelete(undefined)
    hideDialog()
  }, [hideDialog])

  const setDelete = useCallback(
    (resource: PermissionResource) => {
      setToDelete(resource)
      showDialog()
    },
    [showDialog]
  )

  const deleteMutation = useMutation({
    mutationKey: ['deletePermissionResource', project.id],
    mutationFn: (resourceId: string) => deletePermissionResource(project.id, resourceId),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: getProjectPermissionResourcesKey(project.id)})
      setFocus(undefined)
      setToDelete(undefined)
      hideDialog()
      toast.push({title: 'Content resource deleted', status: 'success'})
    },
    onError: (error: Error) => {
      queryClient.invalidateQueries({queryKey: getProjectPermissionResourcesKey(project.id)})
      setFocus(undefined)
      setToDelete(undefined)
      hideDialog()
      toast.push({title: error.name, description: error.message, status: 'error'})
    },
  })

  const handleDelete = useCallback(() => {
    if (loading || toDelete === undefined) return
    deleteMutation.mutate(toDelete.id)
  }, [loading, toDelete, deleteMutation])

  const resources = rawResources.sort(contentResourceCompare)
  const error = errorResources

  useEffect(() => {
    if (error) {
      toast.push({title: error.name, description: error.message, status: 'error'})
    }
  }, [error, toast])

  useEffect(() => {
    setLoading(loadingResources)
  }, [loadingResources])

  return (
    <>
      <Stack space={[4, 4, 6]} data-title={title}>
        <TabHeader
          title="Content Resources"
          description={description}
          referenceLink={referenceLink}
          button={
            <PermissionButtonProject
              onClick={handleRoleWizardOpen}
              title="Create new content resource"
              icon={AddIcon}
              permissions={[{permissionName: 'sanity.project.roles', grantName: 'update'}]}
              disabled={loading || !hasFeature}
            />
          }
        />
      </Stack>
      {loading && (
        <Stack space={2} marginTop={6}>
          {[...Array(10)].map((_item, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <Card borderTop={i > 0} paddingY={3} key={`skeleton-${i}`}>
              <Stack space={2}>
                <HeadingSkeleton style={{width: 100}} animated />
                <TextSkeleton style={{width: 200}} animated />
              </Stack>
            </Card>
          ))}
        </Stack>
      )}
      <Stack space={0} marginTop={6}>
        {resources
          .filter((resource) => isUserResource(resource) && !resource.isCustom)
          .map((resource, index) => {
            return (
              <ContentResourceRow
                key={resource.id}
                resource={resource}
                loading={loading}
                handleEditOpen={handleEditOpen}
                handleDelete={setDelete}
                isFirst={index === 0}
              />
            )
          })}
        {resources
          .filter(
            (resource) =>
              resource.permissionResourceType === 'sanity.document.filter.mode' && resource.isCustom
          )
          .map((resource) => {
            return (
              <ContentResourceRow
                key={resource.id}
                resource={resource}
                loading={loading}
                handleEditOpen={handleEditOpen}
                handleDelete={setDelete}
                isFirst={false}
              />
            )
          })}
      </Stack>
      {(roleWizard || focus) && (
        <ContentResourceWizard
          onClose={handleRoleWizardClose}
          project={project}
          create={roleWizard}
          resource={focus}
        />
      )}
      <Dialog
        id="delete-content-resource"
        header="Delete content resource"
        title="The resource and all permissions given to the resource will be deleted"
        description="This action cannot be undone."
        onClose={clearDelete}
        onConfirm={handleDelete}
        confirmButtonProps={{text: 'Delete'}}
        loading={deleteMutation.isPending}
      >
        <Card border radius={2} padding={1} paddingTop={2} paddingBottom={2}>
          <Grid columns={1} gap={1}>
            <Card padding={3}>
              <Stack space={3}>
                <Label muted size={0}>
                  Title
                </Label>
                <Text>{toDelete?.title}</Text>
              </Stack>
            </Card>
            <Card padding={3}>
              <Stack space={3}>
                <Label muted size={0}>
                  Description
                </Label>
                <Text>{toDelete?.description || <em>No description</em>}</Text>
              </Stack>
            </Card>
            <Card padding={3}>
              <Stack space={3}>
                <Label muted size={0}>
                  GROQ Filter
                </Label>
                <Code size={2}>{toDelete?.config?.filter as string}</Code>
              </Stack>
            </Card>
          </Grid>
        </Card>
      </Dialog>
    </>
  )
}
