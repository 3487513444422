import * as z from 'zod'
import {FORM_ERRORS} from './constants'

const NewOrg = z.object({
  type: z.literal('new'),
  name: z.string().min(2, FORM_ERRORS.required),
  id: z.null().optional(),
})

const ExistingOrg = z.object({
  type: z.literal('existing'),
  id: z.string().min(6), // Org id should always be 9 characters starting with "o", but don't assume anything
  name: z.null().optional(),
})

const OrgSchema = z.discriminatedUnion('type', [NewOrg, ExistingOrg])

/**
 * Stripe data is validated by Stripe themselves, and we don't have access to the details.
 * This object is used to trigger react-hook-form errors when the Stripe data is invalid.
 */
const StripeSchema = z
  .object({
    cardNumber: z.never(),
    cardExpiry: z.never(),
    cardCvc: z.never(),
  })
  .optional()

const CustomerSchema = z.object({
  name: z.string().min(1, FORM_ERRORS.required),
  address: z.string().min(1, FORM_ERRORS.required),
  zip: z.string().min(1, FORM_ERRORS.required),
  city: z.string().min(1, FORM_ERRORS.required),
  country: z.string().min(2).max(2, FORM_ERRORS.required),
})

export const CheckoutFormData = z.object({
  org: OrgSchema,
  customer: CustomerSchema,
  stripe: StripeSchema,
  couponCode: z.string().optional(),
})

export type CheckoutFormData = z.infer<typeof CheckoutFormData>

// Changes here should also be reflected in the Rudderstack slack_downgrade_feed_filter transformation
export const DOWNGRADE_REASONS = [
  'price',
  'functionality',
  'not-working',
  'competitor',
  'dont-need-sanity',
  'dont-need-paid-plan',
  'other',
  'skip-survey',
] as const

export const DOWNGRADE_FOLLOWUP_PLACEHOLDERS = {
  price: 'How can we improve pricing?',
  functionality: 'What functionality are you missing?',
  'not-working': 'What is not working as expected?',
  competitor: 'Who are you switching to?',
  'dont-need-sanity': 'What will you use for content going forward?',
  'dont-need-paid-plan': 'What feature(s) would you be willing to pay for?',
  other: 'Please let us know why you are downgrading',
}

export const DowngradeSurveyFormData = z.object({
  reason: z.enum(DOWNGRADE_REASONS),
  comment: z.string().optional(),
})

export type DowngradeSurveyFormData = z.infer<typeof DowngradeSurveyFormData>
