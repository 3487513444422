import React from 'react'

import {Button, Container, Stack} from '@sanity/ui'
import {ArrowLeftIcon} from '@sanity/icons'
import styled from 'styled-components'
import {AppLink} from '@/ui/app'
import {TabHeader} from '@/components/index'
import {Webhooks2Input, Webhooks2InputProps} from '@/components/form/edit-states/webhooks2Input'
import {useRoutePath} from '@/context/index'

const ButtonLink = styled.span`
  &:hover {
    background: none;
    box-shadow: none;
  }
`

/** Wrapper around Webhook2Input which adds a header. This makes it usable from multiple routes. */
export function WebhookFormPage({title, ...props}: {title: string} & Webhooks2InputProps) {
  const {basePath} = useRoutePath() || {}

  return (
    <Container size={0}>
      <Stack space={[4, 4, 5]} colSpan={1}>
        <Stack space={4} marginY={3}>
          <AppLink href={`${basePath}/api`}>
            <Button
              as={ButtonLink}
              mode="bleed"
              tone="primary"
              text="API"
              icon={ArrowLeftIcon}
              padding={1}
            />
          </AppLink>
          <TabHeader
            title={title}
            description="Add a new webhook for your project that triggers when content has changed in your content lake"
            referenceLink={{
              label: 'Go to the webhooks documentation to learn more',
              url: `https://www.${process.env.host}/docs/webhooks`,
            }}
          />
        </Stack>

        <Webhooks2Input {...props} />
      </Stack>
    </Container>
  )
}
