import {useQuery, UseQueryResult} from '@tanstack/react-query'

import {OrganizationPaymentState} from '@/types/models/paymentstate'
import {request} from '@/data/util/promiseRequest'
import {CurrentUser} from '@/types/index'
import {getPaymentStateKey, STALE_TTL} from './cache'

export function usePaymentState(
  currentUser: CurrentUser | null
): UseQueryResult<OrganizationPaymentState[]> {
  return useQuery({
    queryKey: getPaymentStateKey(currentUser?.id),
    queryFn: () => {
      if (currentUser === null) {
        return []
      }

      return request<OrganizationPaymentState[]>({
        headers: {'Content-Type': 'application/json'},
        url: `https://api.${process.env.host}/v1/customers/paymentstate`,
        method: 'GET',
      }).then((res) => {
        if (!res.ok) {
          throw new Error(res.body.message)
        }

        return res.body
      })
    },
    enabled: Boolean(currentUser),
    placeholderData: [],
    staleTime: STALE_TTL,
  })
}
