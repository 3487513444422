import React from 'react'

export const Tada = ({color}: {color?: string}) => (
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M35.5029 34.5962C35.6526 34.1765 35.8624 33.7921 36.1347 33.448C37.6808 31.4829 40.9499 31.2146 44.9893 32.3555M35.5029 34.5962L24.1196 78.5808C23.4652 81.1093 26.1304 83.2084 28.4352 81.9797L68.4481 60.6485M35.5029 34.5962C34.231 38.1623 37.2897 44.2844 43.0787 50.0343M68.4481 60.6485C68.817 60.4079 69.1386 60.1181 69.4079 59.7778C70.9731 57.7884 70.4516 54.4678 68.3467 50.7309M68.4481 60.6485C65.1791 62.7808 58.1994 61.0524 51.0408 56.4339M49.3159 55.2668C48.6038 54.7623 47.8928 54.23 47.1862 53.6709C46.3326 52.9954 45.5156 52.3045 44.7382 51.6038M49.3159 55.2668C49.8908 55.6741 50.4664 56.0633 51.0408 56.4339M49.3159 55.2668C52.344 53.1872 58.7278 49.8331 66.496 47.8875M51.0408 56.4339C55.93 53.4321 62.4588 51.6877 68.3467 50.7309M43.0787 50.0343C43.6094 50.5614 44.1629 51.0853 44.7382 51.6038M43.0787 50.0343C44.4001 45.7139 45.8111 38.3871 44.9893 32.3555M44.7382 51.6038C47.6739 44.297 48.713 38.3713 48.6638 33.6917M63.9617 36.4872C63.29 34.2301 62.3495 29.5387 63.9617 28.8294C65.9769 27.9427 68.5564 33.5047 70.8134 32.2149C73.0704 30.9252 69.4431 27.7815 70.8134 25.202C72.1837 22.6226 79.5997 28.4263 81.2118 23.4286C82.5016 19.4305 79.7609 16.6576 78.2293 15.7709M66.496 47.8875C70.7693 46.8172 75.4615 46.1732 80.2444 46.402L80.2444 49.7069C77.5324 49.6937 73.153 49.9499 68.3467 50.7309M66.496 47.8875C64.4495 45.1055 61.6559 42.2265 58.2998 39.586C55.0628 37.0392 51.7424 35.0417 48.6638 33.6917M48.6638 33.6917C48.5817 25.8795 45.4666 21.5397 43.0787 20.0431L39.9405 23.4286C42.9846 25.0775 44.4564 28.4442 44.9893 32.3555"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M32.2084 47.3001C31.0505 50.5466 34.6847 56.6215 40.9587 61.5862C47.2394 66.5563 53.9948 68.696 56.883 66.8122"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M28.8858 60.1903C28.1235 62.3275 30.5159 66.3267 34.6462 69.5951C38.7808 72.8669 43.228 74.2755 45.1293 73.0354"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M26.2713 70.2224C25.8142 71.5038 27.2486 73.9015 29.7249 75.8611C32.2039 77.8227 34.8702 78.6673 36.0101 77.9237"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M28.0524 44.1725C29.5349 43.1982 32.373 40.6905 31.8647 38.4539C31.2293 35.6582 22.8421 38.9623 22.715 36.5478C22.5879 34.1333 27.0356 30.5751 26.9085 28.6689C26.7815 26.7627 18.6484 30.8292 18.6484 26.8897C18.6484 23.7381 21.3595 22.103 22.715 21.6794"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <rect
      x="21.8828"
      y="50.1123"
      width="3.03077"
      height="3.03077"
      transform="rotate(-24.7154 21.8828 50.1123)"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <rect
      x="16.0527"
      y="36.7222"
      width="3.03077"
      height="3.03077"
      transform="rotate(4.36851 16.0527 36.7222)"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <rect
      x="27.9453"
      y="22.8354"
      width="3.03077"
      height="3.03077"
      transform="rotate(-24.7154 27.9453 22.8354)"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <rect
      x="53.7051"
      y="33.4431"
      width="3.03077"
      height="3.03077"
      transform="rotate(-24.7154 53.7051 33.4431)"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <rect
      x="67.3438"
      y="13.7432"
      width="3.03077"
      height="3.03077"
      transform="rotate(-24.7154 67.3438 13.7432)"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <rect
      x="74.9219"
      y="54.6584"
      width="3.03077"
      height="3.03077"
      transform="rotate(-24.7154 74.9219 54.6584)"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <rect
      x="70.375"
      y="66.7815"
      width="3.03077"
      height="3.03077"
      transform="rotate(-24.7154 70.375 66.7815)"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <rect
      x="77.9512"
      y="78.9045"
      width="3.03077"
      height="3.03077"
      transform="rotate(-24.7154 77.9512 78.9045)"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <rect
      x="82.498"
      y="62.2354"
      width="3.03077"
      height="3.03077"
      transform="rotate(-24.7154 82.498 62.2354)"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
  </svg>
)
Tada.displayName = 'Tada'
