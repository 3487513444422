import React, {useCallback} from 'react'
import {Button, ButtonTone} from '@sanity/ui'
import {CogIcon} from '@sanity/icons'
import {Prompt, PromptProps} from '@/ui/index'
import {useWizardStore} from '@/components/payment-wizard'
import {useCurrentScopeContext, useProjectType, useProjectUsageStatus} from '@/context/index'
import {ResourceIds} from '@/types/models'
import {useDefaultPlans} from '@/data/plan'
import {useOrganizationCanPay} from '@/data/organizations'
import {AddBillingWizard} from '@/ui/views/wizards/addBilling'
import {StatusConfigContext} from '@/context/use-project-usage-status/statusConfig'

export function ProjectUsagePrompt({
  resourcesToCheck,
  context,
}: {
  resourcesToCheck?: ResourceIds[]
  context?: StatusConfigContext
}) {
  const {project, org, orgs} = useCurrentScopeContext()
  const status = useProjectUsageStatus({project, resourcesToCheck: resourcesToCheck, context})
  const {data: canPay} = useOrganizationCanPay(org?.id)
  const {type} = org || {}
  const {data: plans} = useDefaultPlans()
  const projectType = useProjectType(project)
  const showPrompt =
    status &&
    (type == 'personal' || canPay?.status === false) &&
    (!org?.organizationPlan || (org?.organizationPlan && projectType === 'playground'))

  const {dispatch, state: wizardState} = useWizardStore()

  const openPaymentWizard = useCallback(() => {
    dispatch({type: 'addBillingWizard/open'})
  }, [dispatch])

  const BUTTONS: Record<string, React.ReactNode> = {
    'transfer-project': (
      <Button
        text="Add billing information"
        tone={status?.tone as ButtonTone}
        icon={CogIcon}
        onClick={openPaymentWizard}
      />
    ),
    'add-billing-details': (
      <Button
        text="Add billing information"
        tone={status?.tone as ButtonTone}
        icon={CogIcon}
        onClick={openPaymentWizard}
      />
    ),
    'contact-sales': (
      <Button
        as="a"
        target="blank"
        rel="noreferrer"
        text="Contact sales"
        href={`https://www.${process.env.host}/contact/sales?ref=manage-plan-usage`}
        tone={status?.tone as ButtonTone}
      />
    ),
    'visit-slack-community': (
      <Button
        as="a"
        target="blank"
        rel="noreferrer"
        text="Visit the community"
        href="https://slack.sanity.io/"
        tone={status?.tone as ButtonTone}
      />
    ),
  }

  const promptProps: PromptProps = {
    title: status?.title,
    description: status?.message,
    tone: status?.tone,
    icon: status?.icon,
    button: status?.cta ? BUTTONS[status?.cta] : null,
  }

  if (!showPrompt || !project || !org || !orgs || !plans) {
    return null
  }

  return (
    <>
      <Prompt {...promptProps} />
      {wizardState.addBillingWizard.open && (
        <AddBillingWizard
          params={{
            org,
            orgs,
            plans,
            project,
          }}
        />
      )}
    </>
  )
}
