import {Box, Flex, Grid, Heading, Stack, useToast, Text} from '@sanity/ui'
import React, {useCallback} from 'react'
import {catchError} from 'rxjs/operators'
import {LaunchIcon} from '@sanity/icons'
import {WizardParams, WizardStep, WizardStepProps} from '../types'
import {useWizardStore} from '../store'
import {switchProjectPlan} from '@/data/plan'
import {useProjectSubscription} from '@/data/projects/useProjectSubscription'
import {PlanPreview} from '@/components/payment-wizard'
import {PlanBlock} from '@/ui/index'
import {Plan} from '@/types/models'

function ChangePlanStep(props: WizardStepProps) {
  const {params} = props
  const {org, plans, project} = params
  const {dispatch, state} = useWizardStore()
  const {targetPlan} = state
  const {data: subscription} = useProjectSubscription(project?.id)
  const currentPlan = subscription?.plan

  const handleSelectPlan = useCallback(
    (selectedPlan: Plan) => {
      dispatch({type: 'setTargetPlan', value: selectedPlan})
    },
    [dispatch]
  )

  if (!org || org.type === 'personal' || !currentPlan || plans.length === 0) {
    return null
  }

  return (
    <Stack space={5}>
      <Heading as="h1">Change plan</Heading>

      <Stack space={3}>
        <Heading as="h2" size={1}>
          Current plan
        </Heading>
        {currentPlan && <PlanBlock plan={currentPlan} columns={[2, 2, 3, 3, 3]} />}
      </Stack>

      <Stack space={3}>
        <Heading as="h2" size={1}>
          Select plan
        </Heading>

        <Grid gap={[2, 2, 2]} columns={[1, 1, 2, 2]}>
          {plans.map((plan: Plan) => (
            <PlanPreview
              buttonText="Select"
              currentPlan={plan}
              targetPlan={plan}
              key={plan.id}
              onClick={handleSelectPlan}
              selected={targetPlan?.id === plan.id}
              project={project}
            />
          ))}
        </Grid>
        <Flex justify="center" marginTop={2}>
          <Flex>
            <Text>
              <a href={`https://www.${process.env.host}/pricing`} target="_blank" rel="noreferrer">
                Compare plans on <strong>sanity.io/pricing</strong>
              </a>
            </Text>
            <Box marginLeft={2}>
              <Text>
                <LaunchIcon />
              </Text>
            </Box>
          </Flex>
        </Flex>
      </Stack>
    </Stack>
  )
}

export function useChangePlanStep(params: WizardParams): WizardStep | null {
  const {dispatch, state} = useWizardStore()
  const {project} = params
  const {targetPlan} = state
  const toast = useToast()
  const {data: subscription} = useProjectSubscription(project?.id)
  return {
    id: 'change-plan',
    title: 'Change plan',
    completed: !!targetPlan,
    component: ChangePlanStep,
    disabled: !targetPlan,
    action: {
      label: <>Continue</>,
    },
    async handle() {
      if (targetPlan && targetPlan.id === subscription?.plan.id) {
        return
      }
      if (targetPlan) {
        await switchProjectPlan(project.id, targetPlan.id, [])
          .pipe(
            catchError((err) => {
              if (err.response) {
                const data = JSON.parse(err.response.body)
                toast.push({
                  title: `Can't change plan`,
                  description: data.message,
                  status: 'error',
                  duration: 10000,
                  closable: true,
                })
              }

              if (err?.response?.statusCode === 402) {
                dispatch({type: 'changePlanWizard/carderror'})
              }
              throw err
            })
          )
          .toPromise()
        return
      }
      throw new Error('No targetPlan set')
    },
  }
}
