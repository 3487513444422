import React, {useCallback, useState} from 'react'
import {Stack, Box, Button} from '@sanity/ui'
import {AddIcon, TrashIcon} from '@sanity/icons'
import {useDefaultPlans, useProjectInvitations} from '../../../../data'
import {InviteMembersWizard} from '../../wizards/inviteMembers'
import {INVITATIONS_TABLE as headers} from './tableConfig'
import {getUserResourceName} from './common'
import {
  ProjectInvitation,
  Table,
  TableBody,
  TabHeader,
  TableEmpty,
  useConfirmDialog,
  RevokeProjectInvitationsDialog,
  PermissionButtonProject,
  InfoTooltip,
  ProjectUsageStatusTooltip,
} from '@/ui/index'
import {useWizardStore} from '@/components/payment-wizard'
import {
  Project,
  ProjectInvitation as ProjectInvitationType,
  ReferenceLink,
  TableRowAction,
} from '@/types/index'
import {useCurrentScopeContext, useProjectType} from '@/context/index'
import {useProjectSubscription} from '@/data/projects/useProjectSubscription'
import {useOrganizationCanPay} from '@/data/organizations'
import {getProjectMemberState} from '@/utils/usage'

export function ProjectInvitations({
  project,
  description,
  referenceLink,
}: {
  project: Project
  description: string
  referenceLink: ReferenceLink
}) {
  const {org, orgs = []} = useCurrentScopeContext()
  const {showDialog, hideDialog, Dialog} = useConfirmDialog()
  const [activeInvitations, setActiveInvitations] = useState<ProjectInvitationType[]>([])
  const {data: plans = []} = useDefaultPlans()
  const {
    data: invitations = [],
    isLoading: memberLoading,
    error,
  } = useProjectInvitations(project.id)
  const {data: subscription} = useProjectSubscription(project.id)
  const {data: canPay} = useOrganizationCanPay(org?.id)
  const projectType = useProjectType(project)
  const {dispatch, state: wizardState} = useWizardStore()

  const isDisabled: boolean = project.isDisabled || project.isDisabledByUser || false
  const disabledTooltip = projectType === 'playground' || org?.type == 'personal' || !canPay?.status

  const memberState = getProjectMemberState(project, projectType, subscription?.resources, org)

  const handleRevokeInvitations = useCallback(
    (invitation: ProjectInvitationType) => {
      setActiveInvitations([invitation])
      showDialog()
    },
    [showDialog]
  )

  const handleInviteButtonClicked = useCallback(() => {
    dispatch({type: 'inviteMembersWizard/open'})
  }, [dispatch])

  const rowActions: TableRowAction[] = [
    {
      title: 'Revoke invitation',
      icon: TrashIcon,
      tone: 'critical',
      mode: 'bleed',
      onAction: handleRevokeInvitations,
      permissions: [{permissionName: 'sanity.project.members', grantName: 'invite'}],
      dialog: (
        <RevokeProjectInvitationsDialog
          key="revoke-invitation"
          project={project}
          Dialog={Dialog}
          invitations={activeInvitations}
          hideDialog={hideDialog}
        />
      ),
    },
  ]

  return (
    <Stack space={6}>
      {rowActions.map((action) => typeof action !== 'string' && action.dialog)}
      <TabHeader
        title="Project invitations"
        button={
          isDisabled ? (
            <InfoTooltip
              title="Archived project"
              description="Reactivate the project in settings to invite more members"
            >
              <Button icon={AddIcon} disabled text="Invite project members" mode="ghost" />
            </InfoTooltip>
          ) : (
            <PermissionButtonProject
              onClick={handleInviteButtonClicked}
              title="Invite project members"
              icon={AddIcon}
              permissions={[{permissionName: 'sanity.project.members', grantName: 'invite'}]}
            />
          )
        }
        description={description}
        referenceLink={referenceLink}
        counter={{
          current: memberState.billable,
          quota: memberState.quota,
          max: memberState.max,
          resource: getUserResourceName(memberState.resource),
        }}
        tooltip={
          project && (
            <ProjectUsageStatusTooltip
              project={project}
              resourcesToCheck={['users']}
              disabled={disabledTooltip}
            />
          )
        }
        upsellButtonText="Get more seats"
        showUpsell
      />

      <Box>
        <Table
          headers={invitations.length > 0 ? headers : []}
          loading={memberLoading}
          loadingText="Loading project invitations..."
          error={error}
        >
          <TableBody>
            {invitations.length > 0 &&
              invitations.map((invitation) => (
                <ProjectInvitation
                  projectId={project.id}
                  key={`invitation-${invitation.id}`}
                  invitation={invitation}
                  rowActions={rowActions}
                />
              ))}
            {!memberLoading && invitations.length === 0 && (
              <TableEmpty
                title="There are no pending invitations"
                description="Maybe try inviting some new members?"
              />
            )}
          </TableBody>
        </Table>
        {wizardState.inviteMembersWizard.open && (
          <InviteMembersWizard
            params={{
              org,
              orgs,
              plans,
              project,
            }}
          />
        )}
      </Box>
    </Stack>
  )
}
