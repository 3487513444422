import {type Role} from '@sanity/access-api'

import {renderList} from './renderList'

export function getRoleSelectButtonText(
  roles: Role[] = [],
  selectedRoles: Role[] = [],
  defaultText?: string
) {
  if (!roles || selectedRoles.length === 0) {
    return defaultText ?? 'Select roles'
  }
  const selectedRoleTitles = selectedRoles.map((role) => role.title || role.name || '')

  return renderList({
    items: selectedRoleTitles,
    maxLength: 4,
    label: 'roles',
    type: 'string',
  })
}
