import {useMutation, useQueryClient} from '@tanstack/react-query'
import {Dataset, DatasetAclMode} from '../../types'
import {editDataset} from '.'
import {APIError} from '@/data/util/request'
import {getDatasetsListKey} from './cache'

export function useEditDataset(projectId: string, datasetName: string) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['editDataset'],
    mutationFn: ({aclMode}: {aclMode: DatasetAclMode}) =>
      editDataset(projectId, datasetName, {aclMode}),
    onMutate: async ({aclMode}) => {
      await queryClient.cancelQueries({
        queryKey: getDatasetsListKey(projectId),
      })

      const previousDatasets = queryClient.getQueryData<Dataset[]>(getDatasetsListKey(projectId))
      queryClient.setQueryData<Dataset[]>(getDatasetsListKey(projectId), (old) =>
        old?.map((d) => (d.name === datasetName ? {...d, aclMode} : d))
      )

      return {previousDatasets}
    },
    onError: (err, _variables, context) => {
      queryClient.setQueryData(getDatasetsListKey(projectId), context?.previousDatasets)
      if (err instanceof APIError && err.statusCode === 400) {
        const invalidDataError = new Error('Invalid aclMode') as Error & {name: 'InvalidDataError'}
        invalidDataError.name = 'InvalidDataError'
        throw invalidDataError
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: getDatasetsListKey(projectId),
      })
    },
  })
}
