import type {DialogMode} from '@/types/announcements'
import {AnnouncementsToast, AnnouncementsDialog} from '@/ui/ui-components/features/announcements'
import {sendAmplitudeTrackingEvent} from '@/utils/tracking'
import {type PropsWithChildren, useState, useCallback, useMemo} from 'react'
import {
  useProductAnnouncements,
  useMarkAnnouncementsSeen,
  StudioAnnouncementsContextValue,
  AnnouncementContext,
} from './useAnnouncements'
import {useUser} from '@/data/users'
import {useCurrentUser} from '../useCurrentUser'

export function AnnouncementsProvider(props: PropsWithChildren) {
  const {data} = useProductAnnouncements()
  const allAnnouncements = data?.allAnnouncements
  const unseenAnnouncements = data?.unseenAnnouncements

  const {mutate: updateSeen} = useMarkAnnouncementsSeen()
  const currentUser = useCurrentUser()
  const {data: userData, isLoading: userDataLoading} = useUser(currentUser?.id)

  const [dialogMode, setDialogMode] = useState<DialogMode | null>(null)
  const [isCardDismissed, setIsCardDismissed] = useState(false)

  const shouldShowToast = useMemo(() => {
    if (userDataLoading) return false
    if (!userData?.createdAt) return false

    const userSignUpDate = new Date(userData.createdAt)
    const today = new Date()

    const userSignedUpToday =
      userSignUpDate.getDate() === today.getDate() &&
      userSignUpDate.getMonth() === today.getMonth() &&
      userSignUpDate.getFullYear() === today.getFullYear()

    return !userSignedUpToday
  }, [currentUser, userData, userDataLoading])

  const saveSeenAnnouncements = useCallback(() => {
    // Mark all the announcements as seen
    if (allAnnouncements) updateSeen(allAnnouncements.map((doc) => doc._id))
  }, [updateSeen, allAnnouncements])

  const handleOpenDialog = useCallback((mode: DialogMode) => {
    setDialogMode(mode)
    setIsCardDismissed(true)
  }, [])

  function handleDialogClose() {
    if (allAnnouncements && unseenAnnouncements) {
      const firstAnnouncement =
        dialogMode === 'help_menu' ? allAnnouncements[0] : unseenAnnouncements[0]

      sendAmplitudeTrackingEvent('Product Announcement Dismissed', undefined, undefined, {
        announcement_id: firstAnnouncement?._id,
        announcement_title: firstAnnouncement?.title,
        announcement_internal_name: firstAnnouncement?.name,
        source: 'manage',
        origin: dialogMode ?? 'card',
      })
    }

    setDialogMode(null)
    saveSeenAnnouncements()
  }

  function handleCardClick() {
    handleOpenDialog('card')

    if (unseenAnnouncements?.length) {
      sendAmplitudeTrackingEvent('Product Announcement Card Clicked', undefined, undefined, {
        announcement_id: unseenAnnouncements[0]?._id,
        announcement_title: unseenAnnouncements[0]?.title,
        announcement_internal_name: unseenAnnouncements[0]?.name,
        source: 'manage',
      })
    }
  }

  function handleCardDismiss() {
    setIsCardDismissed(true)
    saveSeenAnnouncements()

    if (unseenAnnouncements?.length) {
      sendAmplitudeTrackingEvent('Product Announcement Card Dismissed', undefined, undefined, {
        announcement_id: unseenAnnouncements[0]?._id,
        announcement_title: unseenAnnouncements[0]?.title,
        announcement_internal_name: unseenAnnouncements[0]?.name,
        source: 'manage',
      })
    }
  }

  const contextValue: StudioAnnouncementsContextValue = useMemo(
    () => ({
      announcements: allAnnouncements || [],
      unseenAnnouncements: unseenAnnouncements || [],
      onDialogOpen: handleOpenDialog,
    }),
    [handleOpenDialog, unseenAnnouncements, allAnnouncements]
  )

  return (
    <AnnouncementContext.Provider value={contextValue}>
      {props.children}
      {unseenAnnouncements?.length && shouldShowToast ? (
        <AnnouncementsToast
          preHeader={unseenAnnouncements[0].preHeader}
          title={unseenAnnouncements[0].title}
          name={unseenAnnouncements[0].name}
          id={unseenAnnouncements[0]._id}
          onCardClick={handleCardClick}
          isOpen={!isCardDismissed}
          onCardDismiss={handleCardDismiss}
        />
      ) : null}
      {dialogMode && allAnnouncements?.length ? (
        <AnnouncementsDialog
          mode={dialogMode}
          announcements={
            dialogMode === 'help_menu' ? allAnnouncements : unseenAnnouncements || allAnnouncements
          }
          onClose={handleDialogClose}
        />
      ) : null}
    </AnnouncementContext.Provider>
  )
}
