import {
  AvatarWithFallback,
  LoginProvider,
  ManagedExternalBanner,
  TableCell,
  TableRow,
  TimeAgoText,
} from '@/components/index'
import {ArrowRightIcon} from '@sanity/icons'

import {MenuMultiSelect} from '@/components/general/menuMultiSelect'
import {nameToInitials} from '@/components/members/utils'
import {useCurrentUser} from '@/context/useCurrentUser'
import {useRequiredProjectPermissions} from '@/context/useProjectPermissions'
import {useProjectUserRolesList} from '@/context/useProjectRolesList'
import {RequestWithWarnings} from '@/data/projects/useProjectAccessRequests'
import {OrganizationSSOProvider, Project} from '@/types/index'
import {Box, Button, Flex, Heading, Inline, Stack, Text, useToast} from '@sanity/ui'
import {useState} from 'react'
import {AvatarWithLoginProvider} from './projectRequestsTab'
import {acceptRequest, declineRequest, removeRoleFromUser, ResourceType} from '@sanity/access-api'

export const ProjectRoleRequestRow = ({
  onApprove,
  onDecline,
  request,
  isLast,
  providersMap,
  project,
}: {
  onApprove?: () => void
  onDecline?: () => void
  request: RequestWithWarnings
  isLast: boolean
  providersMap: Record<string, OrganizationSSOProvider>
  project: Project
}) => {
  const currentUser = useCurrentUser()
  const user = request.requestedByUserProfile

  const [showWarningDialog, setShowWarningDialog] = useState(false)
  function closeDialog() {
    setShowWarningDialog(false)
  }

  const hasPermission = useRequiredProjectPermissions([
    {permissionName: 'sanity.project.members', grantName: 'invite'},
  ])
  const {roles: projectUserRoles} = useProjectUserRolesList(project?.id)
  const [roleValue, setRoleValue] = useState([
    request.requestedRole?.toLowerCase() || 'administrator',
  ]) // Default role is admin
  const managedExternal: boolean =
    user?.provider && providersMap[user.provider]
      ? providersMap[user?.provider].enforceRolesOnLogin
      : false

  const [isAccepting, setIsAccepting] = useState(false)
  const [isDeclining, setIsDeclining] = useState(false)
  const toast = useToast()

  if (!project || !currentUser || !user) return

  const handleOnAccept = async () => {
    try {
      setIsAccepting(true)
      await acceptRequest({
        path: {
          requestId: request.id!,
          resourceId: project.id,
          resourceType: 'project',
        },
        body: {
          roleNames: roleValue,
        },
      })
      toast.push({status: 'success', title: `Access approved for ${user.displayName}`})
      if (showWarningDialog) closeDialog()
      if (onApprove) onApprove()
    } catch (err) {
      toast.push({
        status: 'error',
        title: 'Something went wrong approving that user. Please try again.',
      })
    } finally {
      setIsAccepting(false)
    }

    try {
      const {resourceId, resourceType, requestedByUserId: sanityUserId} = request
      if (resourceId && resourceType && sanityUserId)
        await removeRoleFromUser({
          path: {
            resourceId,
            resourceType: resourceType as ResourceType,
            roleName: 'viewer',
            sanityUserId,
          },
        })
    } catch (err) {
      console.error(err)
    }
  }

  const handleOnDenied = async () => {
    try {
      setIsDeclining(true)
      await declineRequest({
        path: {
          requestId: request.id!,
          resourceId: project.id,
          resourceType: 'project',
        },
      })
      toast.push({status: 'success', title: `Access denied for ${user.displayName}`})
      if (onDecline) onDecline()
    } catch (err) {
      toast.push({
        status: 'error',
        title: 'Something went wrong denying that user. Please try again.',
      })
    } finally {
      setIsDeclining(false)
    }
  }
  return (
    <>
      <TableRow paddingY={3} paddingLeft={2} borderBottom={!isLast}>
        <TableCell>
          <AvatarWithLoginProvider>
            {user && (
              <AvatarWithFallback
                src={user.imageUrl || undefined}
                initials={nameToInitials(user.displayName)}
                size={1}
              />
            )}
            {user && user.provider !== 'sanity' && <LoginProvider provider={user.provider} />}
          </AvatarWithLoginProvider>
        </TableCell>
        <TableCell>
          <Stack space={2}>
            <Inline space={2}>
              <Heading size={1}>{user?.displayName}</Heading>
            </Inline>
            <Text size={1}>{user?.email}</Text>
          </Stack>
        </TableCell>
        <TableCell>
          <Flex align={'center'}>
            <Text muted size={1}>
              Viewer
            </Text>
            <Box paddingLeft={1} style={{display: 'inline-flex'}}>
              <ArrowRightIcon />
            </Box>
            <MenuMultiSelect
              id={`roles-${user.id}`}
              options={projectUserRoles}
              values={roleValue}
              onChange={(role) => setRoleValue([role])}
              disabled={!hasPermission}
              noValue={'No role'}
              banner={managedExternal ? <ManagedExternalBanner /> : undefined}
            />
          </Flex>
        </TableCell>
        <TableCell>
          <Text size={1}>{request?.note ? `"${request?.note}"` : ''}</Text>
        </TableCell>
        <TableCell>
          <TimeAgoText size={1} muted date={request.createdAt || ''} options={{agoSuffix: true}} />
        </TableCell>
        <TableCell $align={'right'}>
          <Button
            fontSize={1}
            mode={'bleed'}
            onClick={handleOnDenied}
            text={'Deny'}
            loading={isDeclining}
          />
          <div style={{width: '4px'}} />
          <Button
            fontSize={1}
            mode="ghost"
            tone={'primary'}
            onClick={handleOnAccept}
            loading={isAccepting}
            text="Approve"
          />
        </TableCell>
      </TableRow>
    </>
  )
}
