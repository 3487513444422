/* eslint-disable react/jsx-no-bind */
import React from 'react'

import {ProjectsOverageListData, ProjectsOverageList} from '@/ui/index'
import {useUsageContext} from '@/context/index'
import {useCurrentScopeContext} from '@/context/index'
import {useOrganizationCanPay} from '@/data/organizations'
import {useProjectsUsage} from '@/data/projects'

export function UsageSummaryOrg() {
  const {currentRange, currentPeriod} = useUsageContext()
  const {org} = useCurrentScopeContext()
  const projectUsages = useProjectsUsage(org?.projects, {
    ...currentRange,
    period: currentPeriod,
  })

  const usageLoading = projectUsages.some((usage) => usage.isLoading)
  const {data: canPay} = useOrganizationCanPay(org?.id)

  const data = React.useMemo(() => {
    if (!org) {
      return []
    }

    return projectUsages
      .map(({data: usage}): ProjectsOverageListData | null => {
        if (!usage) {
          return null
        }
        const {projectId, overageResources, approachingResources} = usage
        const project = org.projects.find((proj) => proj.id === projectId)
        if (!project) {
          return null
        }
        const {displayName: name, metadata} = project
        return {
          id: projectId,
          name,
          overageResources: overageResources || [],
          approachingResources: approachingResources || [],
          href: `/organizations/${project.organizationId}/project/${projectId}/usage`,
          projectColor: metadata?.color || '',
          isPlayground: false,
          canPay: canPay?.status === true,
        }
      })
      .filter(Boolean) as ProjectsOverageListData[]
  }, [canPay?.status, org, projectUsages])

  if (!org) {
    return null
  }

  return <ProjectsOverageList data={data} loading={usageLoading} />
}
