import React, {useEffect, useState} from 'react'
import {Stack, Button, Box, Card, Text, useToast} from '@sanity/ui'
import {AddIcon, EllipsisHorizontalIcon, TrashIcon} from '@sanity/icons'
import {useWebhooks, useDatasets} from '../../../../data'
import {
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableEmpty,
  WebhookPreview,
  useConfirmDialog,
  TimeAgoText,
  PermissionButtonProject,
  TabHeader,
} from '@/ui/index'
import {WebhooksInput} from '@/components/form/edit-states'
import {Project, Webhook, TableHeader} from '@/types/index'
import {getErrorDetails} from '@/utils/errors'

const headers: TableHeader[] = [
  {
    id: 'name_and_url',
    label: 'name and url',
    columnWidth: 4,
  },
  {
    id: 'dataset',
    label: 'dataset',
    columnWidth: 2,
  },
  {
    id: 'created',
    label: 'Created',
    columnWidth: 1,
    hideOnMobile: true,
  },
  {
    id: 'edit',
    label: 'Edit',
    columnWidth: 0,
    disableTabButtonRender: true,
    // hideOnMobile: true,
  },
]

export function LegacyWebhooksDetails({
  project,
  title,
  description,
  hideOnEmpty = false,
}: {
  project: Project
  title: string
  description: string
  hideOnEmpty?: boolean
}) {
  const [showAddWebhook, setShowAddWebhook] = useState(false)
  const [showAll, setShowAll] = useState(false)
  const [webhooksState, addWebhook, deleteWebhook] = useWebhooks(project.id)
  const [webhookToDelete, setWebhookToDelete] = useState<Webhook | null>(null)
  const {data: datasets = [], isLoading: loading, error} = useDatasets(project.id)
  const {
    data: webhooks,
    isDeleted,
    loading: webhookLoading,
    error: webhookError,
    isCreated,
  } = webhooksState
  const isEmpty = !webhooks || webhooks.length <= 0

  const {showDialog, Dialog, hideDialog} = useConfirmDialog()
  const toast = useToast()

  const handleSave = (webhook: Webhook) => {
    addWebhook(webhook)
  }

  const handleDeleteStart = (webhook: Webhook) => {
    setWebhookToDelete(webhook)
    showDialog()
  }

  const handleDeleteConfirm = (webhook: Webhook) => {
    deleteWebhook(webhook.id)
  }

  const handleDeleteCancel = () => {
    setWebhookToDelete(null)
    hideDialog()
  }

  const handleCancel = () => {
    setShowAddWebhook(false)
  }

  useEffect(() => {
    if (webhookError) {
      setShowAddWebhook(true)
      const errorResponse = getErrorDetails(webhookError)
      toast.push({description: errorResponse.message, status: 'error'})
    }
  }, [error, toast, webhookError])

  useEffect(() => {
    if (!loading && isCreated) {
      setShowAddWebhook(false)
      toast.push({title: 'Webhook successfully created!', status: 'success'})
    }
  }, [isCreated, loading, toast])

  useEffect(() => {
    if (!loading && isDeleted) {
      hideDialog({description: 'Webhook successfully deleted!', status: 'success'})
    }
  }, [hideDialog, loading, isDeleted])

  const sortByDate = (array: Webhook[]) => {
    return array.sort((a: Webhook, b: Webhook) => +new Date(b.createdAt) - +new Date(a.createdAt))
  }

  if (hideOnEmpty && webhooks.length === 0) {
    // We hide here independently of whether we're loading the webhooks or not.
    // This is because we don't want to show a spinner ("Loading legacy webhooks")
    // before suddenly the whole section disappears.
    //
    // On the flip side: This means that if you _do_ have legacy webhooks the section
    // will suddenly appear.
    return null
  }

  return (
    <Box>
      <Stack space={4}>
        <TabHeader
          title={title}
          description={description}
          button={
            <PermissionButtonProject
              title="Create Legacy Webhook"
              icon={AddIcon}
              mode="ghost"
              onClick={() => setShowAddWebhook(true)}
              permissions={[{permissionName: 'sanity.project.webhooks', grantName: 'create'}]}
              action="cannot add webhooks"
            />
          }
        />

        <Table headers={isEmpty ? [] : headers} loading={loading} loadingText="Loading webhooks...">
          <TableBody>
            {showAddWebhook && (
              <TableRow paddingY={5} paddingX={3}>
                <TableCell style={{gridColumn: '1/-1'}}>
                  <Card style={{width: '100%'}}>
                    <WebhooksInput
                      webhooks={webhooks}
                      datasets={datasets}
                      onSave={handleSave}
                      onCancel={handleCancel}
                      loading={webhookLoading}
                    />
                  </Card>
                </TableCell>
              </TableRow>
            )}

            {!isEmpty &&
              sortByDate(webhooks)
                ?.slice(0, showAll ? webhooks.length : 3)
                .map((webhook: Webhook, index) => (
                  <TableRow
                    key={webhook.id}
                    selected={false}
                    paddingY={3}
                    paddingX={2}
                    borderBottom={index + 1 !== (showAll ? webhooks.length : 3)}
                  >
                    <TableCell>
                      <Stack space={2}>
                        <Text weight="semibold" style={{wordBreak: 'break-word'}}>
                          {webhook.name}
                        </Text>
                        <Text muted size={1} style={{wordBreak: 'break-word'}}>
                          {webhook.url}
                        </Text>
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <Text muted>{webhook.dataset}</Text>
                    </TableCell>
                    <TableCell>
                      <TimeAgoText date={webhook.createdAt} muted size={1} />
                    </TableCell>
                    <TableCell $align="right">
                      <Button
                        icon={TrashIcon}
                        mode="bleed"
                        aria-label="Delete"
                        onClick={() => handleDeleteStart(webhook)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            {isEmpty && (
              <TableEmpty title="There are no webhooks yet" description="Maybe try creating one?" />
            )}
          </TableBody>
        </Table>
      </Stack>

      {webhooks && webhooks.length > 3 && (
        <Button
          icon={EllipsisHorizontalIcon}
          mode="ghost"
          onClick={() => setShowAll(!showAll)}
          style={{width: '100%'}}
        />
      )}
      {webhookToDelete && (
        <Dialog
          id="delete-webhook-dialog"
          header="Delete Webhook"
          title="Do you really want to delete this webhook?"
          description="This action cannot be undone."
          onClose={handleDeleteCancel}
          onConfirm={() => handleDeleteConfirm(webhookToDelete)}
          loading={webhookLoading}
        >
          <WebhookPreview
            name={webhookToDelete.name}
            createdAt={webhookToDelete.createdAt}
            dataset={webhookToDelete.dataset}
            url={webhookToDelete.url}
          />
        </Dialog>
      )}
    </Box>
  )
}
