import {useMemo} from 'react'
import {useProjectSubscription, useProjectUsage} from '@/data/index'
import {type OverageData} from './overages'
import {Plan, PlanResource, Project, ProjectUsage, Subscription} from '@/types/index'

const GIGABYTE = 1e9 // We store data units as multiples of 1000, not 1024.
const userResources = ['users', 'non_admin_users', 'non_viewer_users']

interface MeteredOverage extends PlanResource {
  currentUsage: number
}

const roundToTwoDecimals = (value: number) => Math.round(value * 100) / 100
const prettyNumber = (value: number) => value.toLocaleString('en-US')

function formatOverageValue(overage: MeteredOverage) {
  const overageAmount = overage.currentUsage - overage.quota!
  /**
   * Using the last word of the resource name as the suffix:
   * e.g. "API requests" -> "requests"
   * e.g. "GROQ-powered webhooks" -> "webhooks"
   *
   * If the unit is byte, we use "GB" as the suffix
   */
  const suffix = overage.unit === 'byte' ? 'GB' : overage.name.split(' ').pop()?.toLowerCase()

  const formattedValue =
    overage.unit === 'byte'
      ? roundToTwoDecimals(overageAmount / GIGABYTE)
      : roundToTwoDecimals(overageAmount)

  return `${prettyNumber(formattedValue)} ${suffix}`
}

function calculateOverages(
  currentPlan: Subscription | undefined,
  currentUsage: ProjectUsage | undefined,
  targetPlan: Plan | undefined
): Record<string, MeteredOverage> {
  if (!currentPlan || !currentUsage || !targetPlan) return {}

  const {resources: currentResources} = currentPlan
  const {resources: targetResources, pricingModel: perSeat} = targetPlan

  const quantifiableTargetResources = Object.entries(targetResources).reduce(
    (acc, [key, resource]) => {
      if (['number', 'byte'].includes(resource.unit)) {
        acc[key] = resource
      }
      return acc
    },
    {} as Record<string, PlanResource>
  )

  const overages: Record<string, MeteredOverage> = {}

  Object.entries(quantifiableTargetResources).forEach(([key, newResource]) => {
    if (perSeat && userResources.includes(key)) return

    const matchingCurrentResource = currentResources[key]
    if (!matchingCurrentResource) return

    const usedResource = currentUsage.resources[newResource.id]?.usage || 0
    // New resource has a quota AND the used resource is greater than the quota with no overage allowed OR the used resource is greater than the max overage quota if quota allowed and max overage quota is set
    if (
      (newResource.quota !== null &&
        usedResource > newResource.quota &&
        !newResource.overageAllowed) ||
      (newResource.maxOverageQuota !== null && usedResource > newResource.maxOverageQuota)
    ) {
      overages[key] = {...newResource, currentUsage: usedResource}
    }
  })

  return overages
}

export function useMeteredOverages(project: Project, targetPlan: Plan): OverageData {
  const {data: currentPlan} = useProjectSubscription(project.id)
  const {data: usage} = useProjectUsage(project)

  const overageData = calculateOverages(currentPlan, usage, targetPlan)

  /**
   * Format overage data for display
   */
  const overages = useMemo(() => {
    return Object.entries(overageData).map(([key, overage]) => ({
      id: key,
      description: `Project is exceeding ${overage.name} quota by ${formatOverageValue(overage)}`,
    }))
  }, [overageData])

  /**
   * If a resource has overageAllowed set to false, it should block the user from switching to the plan.
   */
  const blockingOverages = useMemo(() => {
    return overages.filter(({id}) => overageData[id]?.overageAllowed === false)
  }, [overages])

  return {
    overages,
    blockingOverages,
    suggestedAddons: [], // WIP
  }
}
