import {forwardRef, useCallback, useEffect, useState} from 'react'
import {Select} from '@sanity/ui'
import countryList from 'iso-3166-country-list'
import {useGeoIp} from '../../../../data/geoIp/useGeoIp'

type Country = {code: string; name: string}

type Props = {
  value?: string
  resolveFromIp?: boolean
}

const CountrySelect = forwardRef(
  (
    {resolveFromIp, value, ...restProps}: Props & Omit<React.HTMLProps<HTMLSelectElement>, 'ref'>,
    ref: React.Ref<HTMLSelectElement>
  ) => {
    const [selectedCountry, setSelectedCountry] = useState<string | undefined>(value)
    const {data: geoIp} = useGeoIp()

    useEffect(() => {
      if (resolveFromIp && !selectedCountry && geoIp) {
        setSelectedCountry(geoIp.isoCode)
      }
    }, [geoIp, resolveFromIp, selectedCountry])

    const handleSetCountry = useCallback((e: React.SyntheticEvent<HTMLSelectElement>) => {
      const val = e.currentTarget.value
      setSelectedCountry(val || undefined)
    }, [])

    return (
      <Select
        {...restProps}
        ref={ref}
        padding={3}
        defaultValue={value ? value : selectedCountry}
        autoComplete="country"
        onChange={handleSetCountry}
      >
        <option value={''}>Select...</option>

        {countryList.map((country: Country) => (
          <option key={country.code} value={country.code}>
            {country.name}
          </option>
        ))}
      </Select>
    )
  }
)

CountrySelect.displayName = 'CountrySelect'

export {CountrySelect}
