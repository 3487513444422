import {Stack, Text} from '@sanity/ui'
import {useSwitchPlanStore} from '@/components/checkout/store'
import styled from 'styled-components'
import Link from 'next/link'
import {Overage} from '../hooks/overages'
import {getProjectPath} from '@/utils/getProjectPath'
import {useCurrentScopeContext} from '@/context/index'
import {ErrorOverlay} from './errorOverlay'
import {Icon} from '@sanity/icons'

interface Props {
  /**
   * An array of overages that prevent the user from upgrading their plan.
   * This component only appears if there are blocking overages in this list.
   * The overages you include depend on whether the user's plan allows for overages:
   * - If overages are allowed: Include only the overages currently preventing an upgrade.
   * - If overages are not allowed: Include all existing overages.
   */
  blockingOverages: Overage[]
}

const List = styled.ul`
  padding-left: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  list-style-type: none;
`

const ListItem = styled.li`
  font-size: ${({theme}) => `${theme.sanity.fonts.text.sizes[1].fontSize}px`};
  color: var(--card-muted-fg-color);
  line-height: 1.5;
  display: flex;
  align-items: center;
  gap: 0.25rem;
`

const ListIcon = styled(Icon)`
  color: var(--card-badge-caution-icon-color);
  height: 1.75em;
  width: 1.75em;
`

export function DowngradeOverageAlert({blockingOverages}: Props) {
  const scope = useCurrentScopeContext()
  const {state, dispatch} = useSwitchPlanStore()

  if (!blockingOverages.length) return null

  return (
    <ErrorOverlay>
      <Stack space={4}>
        <Text size={1} weight="semibold">
          Please address the following issues to downgrade to the {state?.plan?.name} plan
        </Text>

        <List>
          {blockingOverages.map(({id, description, link}) => (
            <ListItem key={id} as="li">
              <ListIcon symbol="warning-outline" />
              {description}{' '}
              {link && (
                <Link
                  href={`${getProjectPath(scope!.project!)}/${link[0]}`}
                  onClick={() => dispatch({type: 'switchPlan/close'})}
                >
                  {link[1]}
                </Link>
              )}
            </ListItem>
          ))}
        </List>
      </Stack>
    </ErrorOverlay>
  )
}
