import {Dataset, Project, ProjectMemberRole} from '../../types'
import {useQuery} from '@tanstack/react-query'
import {datasetsRoles} from '.'
import {getDatasetRolesKey} from './cache'
import {useRequiredProjectPermissions} from '../../context'

export function useDatasetRoles(project: Project, dataset: Dataset) {
  const canReadProjectRoles = useRequiredProjectPermissions(
    [{permissionName: 'sanity.project.roles', grantName: 'read'}],
    project.id
  )

  return useQuery<ProjectMemberRole[]>({
    queryKey: getDatasetRolesKey(project.id, dataset.name),
    queryFn: () => datasetsRoles(project, dataset),
    enabled: canReadProjectRoles,
  })
}
