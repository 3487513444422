import {Inline, Text, Flex, Stack, Switch, HeadingSkeleton, useToast} from '@sanity/ui'
import React, {useEffect} from 'react'
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query'
import {TabSectionHeader, FormField} from '@/ui/index'
import {getNotificationPreferences, setNotificationPreferences} from '@/data/users'
import {UserNotificationPreference} from '@/types/index'
import styled from 'styled-components'

const LoadingSkeleton = styled(HeadingSkeleton)`
  max-width: 430px;
`

const CACHE_KEY = 'notificationPreferences'

interface PreferenceState {
  enabled: boolean
  indeterminate: boolean
}

function getFirstNotificationPreference(preferences: UserNotificationPreference[]): boolean {
  return preferences?.every((preference) => preference.enabled) ?? true
}

function getIndeterminateState(preferences: UserNotificationPreference[]): boolean {
  const someEnabled = preferences.some((preference) => preference.enabled)
  const someDisabled = preferences.some((preference) => !preference.enabled)
  return someEnabled && someDisabled
}

async function fetchPreferences(): Promise<PreferenceState> {
  const response = await getNotificationPreferences()

  if (!response.ok) {
    throw new Error(`Failed to fetch notification preferences: ${response.statusMessage}`)
  }

  const prefs = response.body?.userPreferences || []

  return {
    enabled: getFirstNotificationPreference(prefs),
    indeterminate: getIndeterminateState(prefs),
  }
}

export function Notifications({title}: {title: string}) {
  const toast = useToast()
  const queryClient = useQueryClient()
  const {
    data: notificationsEnabled,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: [CACHE_KEY],
    queryFn: fetchPreferences,
  })

  useEffect(() => {
    if (isError && error) {
      toast.push({
        title: 'Could not refresh notification preferences',
        description: error.message,
        status: 'error',
      })
    }
  }, [isError, error, toast])

  const mutation = useMutation({
    mutationKey: ['notificationPreferences'],
    mutationFn: (enabled: boolean) => setNotificationPreferences(enabled),
    onMutate: async (newEnabledState: boolean) => {
      // Cancel any outgoing refetches
      await queryClient.cancelQueries({queryKey: [CACHE_KEY]})

      // Snapshot the previous value
      const previousState = queryClient.getQueryData([CACHE_KEY])

      // Optimistically update to the new value
      queryClient.setQueryData([CACHE_KEY], () => ({
        enabled: newEnabledState,
        indeterminate: false,
      }))

      return previousState
    },
    onError: (error: any, previousEnabledState) => {
      queryClient.setQueryData([CACHE_KEY], () => previousEnabledState)

      toast.push({
        title: 'Could not update notification preferences',
        description: error.message,
        status: 'error',
      })
    },
    onSuccess: () => {
      toast.push({
        title: 'Notifications preferences was updated',
        status: 'success',
      })
    },
  })

  const handleNotificationStatus = (enabled: boolean) => {
    return mutation.mutate(enabled)
  }

  return (
    <Stack space={5}>
      <TabSectionHeader title={title} />
      <Flex direction={'column'}>
        <FormField label="Comment notifications" htmlFor="notifications-commentsEnabled">
          {(isLoading || isError) && <LoadingSkeleton animated />}
          {!isLoading && !isError && (
            <Inline space={3} as="label">
              <Switch
                id="notifications-commentsEnabled"
                readOnly={isLoading}
                checked={notificationsEnabled?.enabled}
                indeterminate={notificationsEnabled?.indeterminate}
                onChange={(e) => handleNotificationStatus(e.currentTarget.checked)}
              />
              <Text>Receive emails when you are mentioned or replied to</Text>
            </Inline>
          )}
        </FormField>
      </Flex>
    </Stack>
  )
}
