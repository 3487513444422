import React from 'react'
import styled, {keyframes} from 'styled-components'
import {Box} from '@sanity/ui'

const skeletonKeyframes = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`

const SkeletonBadge = styled(Box)<{$box: {width: string; height: string}}>`
  height: ${(props) => props.$box.height || '14px'};
  width: ${(props) => props.$box.width || '80%'};
  animation: ${skeletonKeyframes} 1300ms ease-in-out infinite;
  background-color: ${(props) => props.theme.sanity.color.base.border};
  border-radius: ${(props) => props.theme.sanity.radius[1]};
  background-image: linear-gradient(
    90deg,
    ${(props) => props.theme.sanity.color.base.bg},
    ${(props) => props.theme.sanity.color.base.border},
    ${(props) => props.theme.sanity.color.base.bg}
  );
`

export function PlanLoadingBadge() {
  return <SkeletonBadge $box={{width: '100px', height: '17px'}} />
}
PlanLoadingBadge.displayName = 'PlanLoadingBadge'
