import React from 'react'
import {Box, Card, Flex, Stack, Text, Label, Tooltip} from '@sanity/ui'

import {PermissionGrant} from '@/types/index'

interface Props {
  grants: PermissionGrant
}
export function GrantsPermissionsTable(props: Props) {
  const {grants} = props
  const permissionResourceNames = Object.keys(grants)

  return (
    <Card radius={1}>
      <Stack>
        {permissionResourceNames.length > 0 &&
          permissionResourceNames.map((permissionResourceName) => (
            <Box key={permissionResourceName}>
              <Card tone="inherit" borderBottom>
                <Flex paddingX={4} paddingBottom={3} paddingTop={4} gap={2}>
                  <Box flex={1}>
                    <Label muted size={1}>
                      Content resource
                    </Label>
                  </Box>
                  <Box flex={2}>
                    <Label muted size={1}>
                      Permission
                    </Label>
                  </Box>
                </Flex>
              </Card>

              <Stack space={2}>
                {grants[permissionResourceName].map((permissionResource) => {
                  const {id, title, description} = permissionResource

                  return (
                    <Card key={id} paddingX={4} paddingY={2}>
                      <Flex align="center">
                        <Box flex={1}>
                          <Tooltip
                            content={
                              description && (
                                <Box padding={3} style={{maxWidth: 250}}>
                                  <Card marginBottom={3} paddingBottom={3} borderBottom>
                                    <Text size={1}>{description}</Text>
                                  </Card>
                                </Box>
                              )
                            }
                          >
                            <Text size={1}>{title}</Text>
                          </Tooltip>
                        </Box>
                        <Flex flex={2} align="center">
                          {permissionResource.grants.map((grant) => {
                            let name = grant.name
                            if (
                              permissionResourceName === 'sanity.document.filter.mode' &&
                              name === 'mode'
                            ) {
                              name =
                                typeof grant.params.mode === 'string'
                                  ? grant.params.mode
                                  : 'unknown'
                            }
                            return (
                              <Card
                                key={name}
                                tone={'primary'}
                                padding={2}
                                radius={5}
                                marginRight={2}
                              >
                                <Text size={1} weight="medium">
                                  {name.substr(0, 1).toUpperCase() + name.substr(1)}
                                </Text>
                              </Card>
                            )
                          })}
                        </Flex>
                      </Flex>
                    </Card>
                  )
                })}
              </Stack>
            </Box>
          ))}
      </Stack>
    </Card>
  )
}
