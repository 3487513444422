import {ColorHueKey, ColorTintKey, COLOR_HUES, hues} from '@sanity/color'

export const getIndexColor = (index: number): string => {
  const colors = COLOR_HUES.filter((c) => c !== 'gray')
  const tints: ColorTintKey[] = ['400', '400', '400', '400', '400', '400']

  // Colors
  const maxIndex = colors.length
  const start = Math.floor(index / maxIndex)
  const allowedIndex = index - maxIndex * start

  // Tints
  const maxTintIndex = tints.length
  const tintStart = Math.floor(index / maxTintIndex)
  const allowedTintIndex = index - maxTintIndex * tintStart

  // Get color tint
  const indexColor: ColorHueKey = colors[allowedIndex]
  const indexTint: ColorTintKey = tints[allowedTintIndex]

  return hues?.[indexColor][indexTint].hex || hues.gray[400].hex
}

export const getStringColor = (str: string): string => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash) // eslint-disable-line
  }
  return getIndexColor(hash)
}
