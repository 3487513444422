import React from 'react'
import {Stack, Box, Card, Flex, Label, Text, HeadingSkeleton, Inline} from '@sanity/ui'
import {EmptyBlock, TagItem} from '@/ui/index'
import {Tag} from '@/types/index'

interface Props {
  loading: boolean
  tags: Tag[]
  error?: Error | null
}
export function TagsList(props: Props) {
  const {loading, error, tags} = props

  if (error) {
    return (
      <EmptyBlock
        title="Something went wrong"
        description={
          <Text muted size={1}>
            Try reloading this page. If that doesn't help, you can ask for help in our
            <a
              href="https://slack.sanity.io/"
              target="_blank"
              rel="noreferrer"
              style={{color: 'var(--card-link-color)'}}
            >
              slack community
            </a>
          </Text>
        }
        illustration="error"
      />
    )
  }

  return (
    <Box marginTop={6}>
      <Card borderBottom marginBottom={2}>
        <Flex paddingX={2} paddingBottom={3} paddingTop={4} gap={2}>
          <Box flex={1}>
            <Label muted size={1}>
              Title and description
            </Label>
          </Box>
          <Box flex={1}>
            <Label muted size={1}>
              Tagged datasets
            </Label>
          </Box>
        </Flex>
      </Card>
      <Stack space={3}>
        {loading &&
          [...Array(10)].map((_item, i) => (
            <Card
              // eslint-disable-next-line react/no-array-index-key
              key={`skeleton-${i}`}
              border
              radius={2}
              padding={4}
            >
              <Flex>
                <Box flex={1}>
                  <HeadingSkeleton style={{width: '200px'}} animated />
                </Box>
                <Box flex={1}>
                  <Inline space={2}>
                    <HeadingSkeleton animated style={{width: '40px'}} />
                    <HeadingSkeleton animated style={{width: '60px'}} />
                  </Inline>
                </Box>
              </Flex>
            </Card>
          ))}
        {tags.length > 0 &&
          tags.filter((tag) => !tag.isDeleted).map((tag) => <TagItem key={tag.name} tag={tag} />)}
      </Stack>
      {!loading && tags.length === 0 && (
        <EmptyBlock
          title="There are no tags in this project"
          description="Maybe try creating a new tag?"
        />
      )}
    </Box>
  )
}
