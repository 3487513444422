import {Button, ButtonProps, TextProps} from '@sanity/ui'
import Link, {LinkProps} from 'next/link'
import React from 'react'
import styled from 'styled-components'

type AppLinkProps = {
  children: React.ReactNode
  href?: string
  disabled?: boolean
  style?: React.CSSProperties
} & TextProps &
  Omit<LinkProps, 'href'>

const StyledLink = styled(Link)`
  text-decoration: none;
  display: flex;
  line-height: 1;
  color: inherit;
  padding-right: 2px;
`

export function AppLink({
  children,
  href,
  disabled,
  style = {},
  ...rest
}: AppLinkProps): React.ReactElement {
  if (!href || disabled) {
    return <>{children}</>
  }
  return (
    <StyledLink href={href} style={{pointerEvents: disabled ? 'none' : 'all', ...style}} {...rest}>
      {children}
    </StyledLink>
  )
}
AppLink.displayName = 'AppLink'

export const AppLinkButton = ({
  href,
  target,
  ...props
}: ButtonProps & {href: string; target?: string}) => {
  return <Button {...props} as={AppLink} href={href} target={target} />
}

export const BlueLinkButton = styled(AppLinkButton)`
  [data-ui='Text'] {
    color: ${({theme}) => theme.sanity.color.card.enabled.link.fg};
  }
  svg {
    color: inherit !important;
  }
`
