import {
  WizardParams,
  WizardStep,
  WizardStepProps,
  useWizardStore,
} from '@/components/payment-wizard'
import {Box, Heading, Stack, Text, useToast} from '@sanity/ui'

import {getPaymentSecret} from '@/data/organizations'
import {useProjectSubscription} from '@/data/projects/useProjectSubscription'
import {useStripe} from '@stripe/react-stripe-js'
import {sendAmplitudeTrackingEvent} from '@/utils/tracking'
import {useEffect, useMemo, useState} from 'react'
import {Project} from '@/types/index'
import {getProjectPlan} from '@/data/plan/controller'
import {EMPTY, catchError} from 'rxjs'

// Different API versions have different status strings for the same status. See https://docs.stripe.com/payments/paymentintents/lifecycle?locale=en-GB
type NormalizedStatus = 'action_required' | 'retry_payment' | 'unknown_status'

function normalizePaymentStatus(status: string | undefined): NormalizedStatus {
  const statusMap: {[key: string]: NormalizedStatus} = {
    requires_action: 'action_required',
    requires_source_action: 'action_required',
    requires_payment_method: 'retry_payment',
    requires_source: 'retry_payment',
  }
  return statusMap[status || ''] || 'unknown_status'
}

async function getPaymentIntent(project: Project, stripe) {
  const paymentSecret = await getPaymentSecret(project?.id || '').toPromise()
  if (!paymentSecret?.clientSecret) {
    throw new Error('Client secret not found')
  }
  const pi = await stripe?.retrievePaymentIntent(paymentSecret.clientSecret)
  return pi.paymentIntent
}

function IncompleteSubscriptionStep(props: WizardStepProps) {
  const {params} = props
  const stripe = useStripe()
  const {project} = params
  const [message, setMessage] = useState('Payment Failed...')
  const [action, setAction] = useState('')

  useEffect(() => {
    async function fetchPaymentIntent() {
      try {
        const pi = await getPaymentIntent(project, stripe)
        const errorMessage = pi?.last_payment_error?.message || 'Payment not complete'
        setMessage(errorMessage)

        const status = normalizePaymentStatus(pi?.status)
        switch (status) {
          case 'action_required':
            setAction(
              'In order to upgrade your plan, we need to verify your payment method. Please authorize the payment to continue.'
            )
            break
          case 'retry_payment':
            setAction(
              'Your payment method was declined. Please review or update your payment method.'
            )
            break
          default:
            setAction('Please try again.')
            break
        }
      } catch (error: any) {
        setMessage(error.message)
      }
    }

    fetchPaymentIntent()
  }, [project?.id, stripe])

  return (
    <>
      <Box padding={3}>
        <Stack space={4}>
          <Stack space={4}>
            <Heading as="h1">{message}</Heading>
            <Text as="p" muted>
              {action}
            </Text>
          </Stack>
        </Stack>
      </Box>
    </>
  )
}

export function useIncompleteSubscriptionStep(params: WizardParams): WizardStep | null {
  const {project, org} = params
  const {dispatch, state} = useWizardStore()
  const toast = useToast()
  const {data: subscription} = useProjectSubscription(project?.id)
  const stripe = useStripe()

  const showStep = useMemo(
    () => subscription?.status === 'incomplete' && state.targetPlan?.id === subscription.plan?.id,
    [subscription, state.targetPlan]
  )

  return !showStep
    ? null
    : {
        id: 'incomplete-subscription',
        title: 'Payment authorization required',
        completed: true,
        component: IncompleteSubscriptionStep,
        disabled: !!state.nextButtonDisabled,
        action: {
          label: <>Retry Payment</>,
        },
        async handle() {
          try {
            const result = await confirmCardPayment(project, stripe)

            if (result?.error) {
              toast.push({
                title:
                  result.error.payment_intent?.last_payment_error?.message ??
                  result.error.message ??
                  `Payment authorization failed`,
                description: `Please try again.`,
                status: 'error',
              })
              throw new Error('Payment authorization failed')
            }

            await verifySubscriptionActive(project)
            dispatch({type: 'setSubscriptionIncomplete', value: false})

            toast.push({
              title: `Changed plan`,
              description: `Successfully changed plan`,
              status: 'success',
            })
            sendAmplitudeTrackingEvent('Switch Plan Step Completed', project?.id, org?.id, {
              stepName: 'Confirm upgrade',
            })
            sendAmplitudeTrackingEvent('Change Plan Flow Completed', project.id, org?.id, {
              newFlow: false,
            })
          } catch (error) {
            console.error('Error', error)
            throw new Error('Payment authorization failed')
          }
        },
      }
}

async function confirmCardPayment(project: Project, stripe) {
  const pi = await getPaymentIntent(project, stripe)
  let paymentMethod = typeof pi?.payment_method === 'string' ? pi.payment_method : undefined
  paymentMethod = paymentMethod ?? pi?.last_payment_error?.payment_method?.id

  const result = await stripe?.confirmCardPayment(pi?.client_secret || '', {
    payment_method: paymentMethod,
  })
  return result
}

async function verifySubscriptionActive(project: Project): Promise<boolean> {
  const maxAttempts = 10
  let attempts = 0

  const isSubscriptionActive = () =>
    new Promise<boolean>((resolve, reject) => {
      const checkActive = async () => {
        if (attempts >= maxAttempts) {
          reject(new Error('Maximum attempts reached'))
          return
        }
        getProjectPlan(project?.id)
          .pipe(
            catchError((err) => {
              reject(err)
              return EMPTY
            })
          )
          .subscribe((subscription) => {
            if (subscription?.status === 'active') {
              resolve(true)
            } else if (subscription?.status === 'incomplete') {
              attempts++
              setTimeout(checkActive, 1000)
            }
          })
      }
      checkActive()
    })

  return await isSubscriptionActive()
}
