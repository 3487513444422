import React from 'react'
import {Stack, Text} from '@sanity/ui'
import styled from 'styled-components'

export function Ul({children, ...props}: any) {
  return (
    <Stack as="ul" space={3} paddingLeft={3} {...props}>
      {children}
    </Stack>
  )
}

const Span = styled.span`
  left: -1em;
  width: 1em;
  top: -0.66em;
  display: block;
  position: absolute;
  &:before {
    content: '•';
    font-weight: 700;
  }
`

export function Li({children, ...props}: any) {
  return (
    <Text muted size={1} as="li" {...props}>
      <Span />
      {children}
    </Text>
  )
}
