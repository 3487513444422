import React, {useCallback} from 'react'
import {Text, Dialog, Button, Grid, Card} from '@sanity/ui'

type ConfirmDeleteProviderProjectDialogProps = {
  closeDialog: () => void
  deleteProvider: () => void
}

export default function ConfirmDeleteProviderProjectDialog({
  closeDialog,
  deleteProvider,
}: ConfirmDeleteProviderProjectDialogProps) {
  const handleConfirm = useCallback(() => {
    deleteProvider()
    closeDialog()
  }, [deleteProvider, closeDialog])

  return (
    <Dialog
      id="confirm-remove-project-configuration"
      width={1}
      footer={
        <Grid columns={2} gapX={2} padding={4}>
          <Button text="Cancel" mode="bleed" onClick={closeDialog} />
          <Button text="Remove SAML SSO" tone="critical" onClick={handleConfirm} />
        </Grid>
      }
      onClose={closeDialog}
      header="Remove SAML SSO for project"
    >
      <Card padding={4}>
        <Text>
          All users signing in with SAML SSO will lose access to the project.
          <br />
          The SAML SSO configuration for this project will be lost.
        </Text>
      </Card>
    </Dialog>
  )
}
