import {Card} from '@sanity/ui'
import styled from 'styled-components'

interface Props {
  children: React.ReactNode
}
const Root = styled.div`
  &:before {
    z-index: 90;
    backdrop-filter: blur(1.5px);
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(180deg, transparent 0%, var(--card-bg-color) 100%);
  }
`

const WarningCard = styled(Card)`
  position: relative;
  z-index: 100;
`

export function ErrorOverlay({children}: Props) {
  return (
    <Root>
      <WarningCard marginBottom={2} padding={4} shadow={1} tone="default" radius={2} muted>
        {children}
      </WarningCard>
    </Root>
  )
}
