import {Box, BoxProps, Flex, Stack, Text} from '@sanity/ui'
import React from 'react'

export type CostSummaryItemProps = {
  title: string
  subtitle: string
  period: string
  key?: string
  boxProps?: BoxProps
}

export function Item({title, subtitle, period, boxProps}: CostSummaryItemProps) {
  return (
    <Box padding={4} {...boxProps}>
      <Stack space={4}>
        <Flex align="flex-end">
          <Box marginRight={1}>
            <Text size={4}>{title}</Text>
          </Box>
          <Text size={1} muted>
            est
          </Text>
        </Flex>

        <Stack space={2}>
          <Text size={1} weight="semibold">
            {subtitle}
          </Text>
          <Text size={1} muted>
            {period}
          </Text>
        </Stack>
      </Stack>
    </Box>
  )
}
