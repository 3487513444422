import {useCurrentScopeContext} from '@/context/useCurrentScopeContext'
import type {CurrentScope} from '@/types/index'
import {useMemo, useCallback} from 'react'
import {get} from 'lodash'
import {useDatasets} from '../../../../data'

type ScopeKeyPath =
  | keyof CurrentScope
  | `${keyof CurrentScope}.${string}`
  | `${keyof CurrentScope}[${number}].${string}`
  // special case for datasets to avoid fetching them from the current scope
  | 'projectDatasets[0].name'
type StringTransformationFunction = 'slug'
type DynamicStringMapping = Record<
  string,
  ScopeKeyPath | `${StringTransformationFunction}:${ScopeKeyPath}`
>

/**
 * Define a mapping of dynamic strings to their corresponding scope keys or functions
 */
const dynamicStringMap: DynamicStringMapping = {
  PROJECT_DATASET_0: 'projectDatasets[0].name',
  PROJECT_ID: 'projectId',
  PROJECT_NAME: 'project.displayName',
  PROJECT_NAME_SLUGIFIED: 'slug:project.displayName',
  USER_AUTH_PROVIDER: 'currentUser.provider',
}

const CURSOR_START = '{{'
const CURSOR_END = '}}'

/* Apply a string transformation function to the input value */
const applyStringTransformation = (func: StringTransformationFunction, value: any): string => {
  switch (func) {
    case 'slug':
      return value.toString().toLowerCase().replace(/ /g, '-')
    default:
      throw new Error(`Unknown function: ${func}`)
  }
}

export const useDynamicString = (code: string): string => {
  const scope = useCurrentScopeContext()
  const {data: datasets} = useDatasets(scope.projectId)

  const replaceDynamicValues = useCallback(
    (code: string) => {
      let newCode = code

      for (const [dynamicKey, scopePath] of Object.entries(dynamicStringMap)) {
        /* Create a regex to match the dynamic string key (e.g., {{PROJECT_ID}}) */
        const dynamicStringRegex = new RegExp(`${CURSOR_START}${dynamicKey}${CURSOR_END}`, 'g')
        let resolvedValue: any

        /* Check if the path includes a function (e.g., 'slug:project.displayName') */
        if (scopePath.includes(':')) {
          const [transformationFunc, innerPath] = scopePath.split(':') as [
            StringTransformationFunction,
            ScopeKeyPath,
          ]
          resolvedValue = get(scope, innerPath)
          if (resolvedValue) {
            resolvedValue = applyStringTransformation(transformationFunc, resolvedValue)
          }
        } else if (scopePath === 'projectDatasets[0].name') {
          resolvedValue = datasets?.[0]?.name || 'production'
        } else {
          resolvedValue = get(scope, scopePath)
        }

        /* Replace all occurrences of the dynamic string key with the actual value */
        if (resolvedValue) {
          newCode = newCode.replace(dynamicStringRegex, resolvedValue.toString())
        }
      }
      return newCode
    },
    [datasets, scope]
  )

  return useMemo(() => replaceDynamicValues(code), [replaceDynamicValues, code])
}
