import React from 'react'
import {Flex, Stack, Heading, Text, Box} from '@sanity/ui'

interface Props {
  title: string | React.ReactNode
  description?: string | React.ReactNode
  children?: React.ReactNode
}

export function TabSectionHeader({title, description, children}: Props) {
  return (
    <Flex
      align={['flex-start', 'flex-start', 'center']}
      justify="space-between"
      direction={['column', 'column', 'row']}
    >
      <Stack space={3}>
        <Heading size={1}>{title}</Heading>
        {description && (
          <Text size={1} muted style={{maxWidth: 550}}>
            {description}
          </Text>
        )}
      </Stack>
      {children && <Box marginTop={[3, 3, 4]}>{children}</Box>}
    </Flex>
  )
}
