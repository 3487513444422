import {getActivity} from '@/data/activity'
import {
  ActivityEvent,
  ActivityFilter,
  CurrentScope,
  Dataset,
  EventCategory,
  FilterPayload,
  OrganizationMember,
  Project,
  ProjectMember,
} from '@/types/index'
import {ORG_EVENTS, PROJECT_EVENTS} from '@/ui/index'
import {
  deleteUndefinedFilters,
  generateFilter,
  getOldestActivityDate,
  VALID_FILTERS,
} from '@/utils/index'
import {addMilliseconds, endOfDay} from 'date-fns'
import {debounce} from 'lodash'
import {useRouter} from 'next/router'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react'
import {useCurrentScopeContext} from './useCurrentScopeContext'
import {lastValueFrom} from 'rxjs'

// TODO
// - Add typing
// - refactor into an activity directory( /context/activity)

const defaultActiveFilter: ActivityFilter = {endTime: endOfDay(new Date()).toISOString()}

const defaultSearchValue = undefined

const defaultScope = {}

const filterReducer = (state: any, action: FilterPayload) => {
  switch (action.type) {
    case 'reset':
      return defaultActiveFilter
    case 'action':
      return {...state, action: action.payload}
    case 'limit':
      return {...state, limit: action.payload}
    case 'actorId':
      return {...state, actorId: action.payload}
    case 'startTime':
      return {...state, startTime: action.payload}
    case 'endTime':
      return {...state, endTime: action.payload}
    case 'datasetName':
      return {...state, datasetName: action.payload}
    default:
      return state
  }
}

const scopeReducer = (_state: any, scopeContext: CurrentScope | undefined) => {
  const scope = scopeContext?.scope
  if (scopeContext) {
    const {project, org} = scopeContext
    switch (scope) {
      case 'project':
        return {
          // If we are in a project, the scope filter should be only the ID of the project we are in
          projectId: [project?.id],
        }
      case 'org':
        return {
          // If we are in an org, the scope filter should be the ID of the org we're in, and all the projects in this org
          organizationId: [org?.id],
        }
      case 'user':
        return {
          // If we are in our personal org, the scope filter should be the projects we have
          projectId: (org?.projects || []).map(({id}: Project) => id),
        }
      default:
        return undefined
    }
  }
  return undefined
}

const defaultState = {
  activeFilter: defaultActiveFilter,
  scope: defaultScope,
  searchValue: defaultSearchValue,
}

export const ActivityContext = createContext<any>(defaultState)

export const ActivityContextProvider = ({children}: {children: React.ReactNode}) => {
  // track pagination (offset) here

  const [scopeFilter, setScopeFilter] = useReducer(scopeReducer, defaultScope as any)
  const [activeFilter, setActiveFilter] = useReducer(filterReducer, defaultActiveFilter as any)

  const currentScope = useCurrentScopeContext()

  useEffect(() => {
    // Set the scope filter when the scope changes
    setScopeFilter(currentScope)
  }, [currentScope])

  const value = {
    scopeFilter,
    activeFilter,
    setActiveFilter,
  }
  return <ActivityContext.Provider value={value}>{children}</ActivityContext.Provider>
}

export const useActivityContext = () => {
  return useContext(ActivityContext)
}

export const useActivityEvents = () => {
  const [error, setError] = useState()
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [hasNextPage, setHasNextPage] = useState<boolean>(false)
  const [hasPrevPage, setHasPrevPage] = useState<boolean>(false)
  const [loading, setLoading] = useState(true)
  const [events, setEvents] = useState<ActivityEvent[]>([])
  const [lastUpdated, setLastUpdated] = useState<Date>(new Date())
  const {activeFilter, scopeFilter, setActiveFilter} = useActivityContext()

  const {push, asPath, query, ...router} = useRouter()
  const pages = useRef(new Map<number, ActivityEvent[]>())
  const checkForPages = (newPage: number) => {
    const _nextPage = pages.current.has(newPage + 1)
    const _prevPage = pages.current.has(newPage - 1)

    setHasNextPage(_nextPage)
    setHasPrevPage(_prevPage)
  }
  const resetPages = () => {
    setLoading(true)
    pages.current = new Map([])
    setCurrentPage(0)
    setEvents([])
    checkForPages(0)
    setLastUpdated(new Date())
  }

  const getPages = async (newPage = 0, endTime: Date | null = null) => {
    try {
      const firstPageFilter = endTime ? {endTime: addMilliseconds(endTime, -2)} : {}
      const firstPage = pages.current.has(newPage)
        ? pages.current.get(newPage)
        : await lastValueFrom(
            getActivity(
              deleteUndefinedFilters({
                ...(query?.projectId
                  ? {
                      projectId: query?.projectId ? [query.projectId] : undefined,
                    }
                  : {
                      organizationId: query?.orgId ? [query.orgId] : undefined,
                    }),
                ...activeFilter,
                ...firstPageFilter,
              })
            )
          )

      if (firstPage) {
        let sorted: ActivityEvent[] = []

        if (newPage === 0 && events.length > 0) {
          sorted = firstPage
        } else {
          sorted = [...events, ...firstPage]
        }

        setEvents(sorted)
      } else {
        setEvents([])
      }

      if (firstPage && firstPage.length > 0) {
        pages.current.set(newPage, firstPage)
        const secondPage = pages.current.has(newPage + 1)
          ? pages.current.get(newPage + 1)
          : await lastValueFrom(
              getActivity(
                deleteUndefinedFilters({
                  ...(query?.projectId
                    ? {
                        projectId: query?.projectId ? [query.projectId] : undefined,
                      }
                    : {
                        organizationId: query?.orgId ? [query.orgId] : undefined,
                      }),
                  ...activeFilter,
                  endTime: addMilliseconds(getOldestActivityDate(firstPage), -2).toISOString(),
                })
              )
            )

        if (secondPage && secondPage.length > 0) {
          pages.current.set(newPage + 1, secondPage)
        }
      }

      checkForPages(newPage)
      setLoading(false)
    } catch (response) {
      setError((response as any)?.error)
      setLoading(false)
    }
  }
  const debouncedGetPages = useCallback(debounce(getPages, 500), [activeFilter, events])

  const nextPage = () => {
    const newPage = currentPage + 1
    setCurrentPage(newPage)
    const currentOldestDate = getOldestActivityDate(events)
    getPages(newPage, currentOldestDate)
  }

  const prevPage = () => {
    const newPage = currentPage === 0 ? currentPage : currentPage - 1
    setCurrentPage(newPage)

    if (pages.current.has(newPage)) {
      const pageEvents = pages.current.get(newPage)

      if (pageEvents) {
        setEvents(pageEvents)
        checkForPages(newPage)
      }
    }
  }

  useEffect(() => {
    // Set the filters based on router query and valid query filters, if any
    return Object.keys(query).forEach((key) => {
      if (VALID_FILTERS.includes(key)) {
        if (key === 'actorId') {
          return setActiveFilter({type: key, payload: [query[key]]})
        }
        return setActiveFilter({type: key, payload: query[key]})
      }
      if (key.split('.').includes('metadata')) {
        return setActiveFilter({type: key, payload: query[key]})
      }
      return undefined
    })
  }, [])

  // Use scope filter and active filter on initial load
  useEffect(() => {
    resetPages()
    debouncedGetPages()

    return () => {
      debouncedGetPages.cancel()
    }
  }, [activeFilter, scopeFilter])

  // Function for updating URL query
  const handleSetQueryFilter = () => {
    // Only update URL if the path includes the word 'activity'
    // This is to prevent the activity feed rendered in overview tabs updating the URL with an endtime query param
    if (asPath.includes('activity')) {
      push(
        `${asPath.split('?')[0]}${activeFilter ? generateFilter(activeFilter) : ''}`,
        undefined,
        {
          shallow: true,
          scroll: false,
        }
      )
    }
  }

  // Set query filter on initial load
  useEffect(() => {
    handleSetQueryFilter()
  }, [])

  // Update query when active filter changes
  useEffect(() => {
    handleSetQueryFilter()
  }, [activeFilter])

  // Resets active filter if full route change (like when scope changes)
  const handleResetQueryFilters = useCallback(
    (_url: string, {shallow}: {shallow: boolean}) => {
      if (!shallow) {
        setActiveFilter({type: 'reset'})
      }
    },
    [setActiveFilter]
  )

  // Run
  useEffect(() => {
    router.events.on('routeChangeComplete', handleResetQueryFilters)
    return () => {
      router.events.off('routeChangeComplete', handleResetQueryFilters)
    }
  }, [query])

  return {
    loading,
    data: events,
    error,
    lastUpdated,
    currentPage,
    hasNextPage,
    hasPrevPage,
    nextPage,
    prevPage,
  }
}

export const useSetFilter = () => {
  const {setActiveFilter} = useActivityContext()
  return setActiveFilter
}

export const useFilter = () => {
  const {activeFilter} = useActivityContext()
  return activeFilter
}

export const useScopeFilter = () => {
  const {scopeFilter} = useActivityContext()
  return scopeFilter
}

type FilterOptions = {
  date?: {
    from: string
    to: string
  }
  project?: Project
  members?: ProjectMember[] | OrganizationMember[]
  datasets?: Dataset[]
  showDatasets?: boolean
  events?: EventCategory[]
}

export const useFilterOptions = (): FilterOptions => {
  const [members, setMembers] = useState<ProjectMember[] | OrganizationMember[] | undefined>([])
  const [eventCategories, setEventCategories] = useState<EventCategory[] | undefined>()
  const {scope, project, org} = useCurrentScopeContext()
  useEffect(() => {
    // Fetch the data we need for project activity filters
    if (scope === 'project' && project) {
      setEventCategories(PROJECT_EVENTS)
      setMembers(project.members)
    }
    if (['org', 'global'].includes(scope || '')) {
      setEventCategories(ORG_EVENTS)
    }
    if (scope === 'org' && org) {
      setMembers(org?.members)
    }
  }, [project, scope, org, org?.members])

  if (scope === 'project') {
    return {
      members,
      showDatasets: true,
      project,
      date: {
        from: '',
        to: '',
      },
      events: eventCategories,
    }
  }

  if (scope === 'org') {
    return {
      members,
      date: {
        from: '',
        to: '',
      },
      events: eventCategories,
    }
  }

  return {
    date: {
      from: '',
      to: '',
    },
    events: eventCategories,
  }
}
