import {type Role} from '@sanity/access-api'
import {CopyIcon, RemoveCircleIcon, SelectIcon} from '@sanity/icons'
import {
  Box,
  // eslint-disable-next-line no-restricted-imports
  Button as UIButton,
  Card,
  Flex,
  Label,
  Stack,
  Text,
  useToast,
} from '@sanity/ui'
import Link from 'next/link'
import {useCallback, useMemo, useState} from 'react'

import {type MembersV2} from '@/types/members_v2'
import {useCopyToClipboard} from '@/utils/general'

import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from '../../generic/table'
import {UserAvatar} from '../../generic/user-avatar'
import {Button} from '../../primitives/button/Button'
import {Tooltip} from '../../primitives/tooltip'
import {
  type ResourceMembershipAddPayload,
  type ResourceMembershipRemovePayload,
  type ResourceRoleAddPayload,
  type ResourceRoleRemovePayload,
} from '../../types'
import {renderList} from '../../utils'
import {ProjectMemberAddMenuButton} from '../project-member-add-menu-button'
import {RoleSelectMenuButton} from '../role-select-menu-button'
import {EditAccordion} from './EditAccordion'

const EMPTY_ARRAY: [] = []

const ROLES_CELL_FLEX = 1.5
const PROJECT_CELL_FLEX = 1

export interface MemberInspectProps {
  basePath?: string
  canEdit?: boolean
  member: MembersV2['member']
  onResourceRoleAdd: (payload: ResourceRoleAddPayload) => void
  onResourceRoleRemove: (payload: ResourceRoleRemovePayload) => void
  onResourceMembershipAdd: (payload: ResourceMembershipAddPayload) => void
  onResourceMembershipRemove: (payload: ResourceMembershipRemovePayload) => void
  resourceId: string
  organizationRoles: Role[]
  projectsData: MembersV2['projectData'][]
}

export function MemberInspect(props: MemberInspectProps) {
  const {
    basePath,
    canEdit,
    member,
    onResourceRoleAdd,
    onResourceRoleRemove,
    onResourceMembershipAdd,
    onResourceMembershipRemove,
    organizationRoles,
    projectsData,
    resourceId,
  } = props

  const [isEditingOrganizationRoles, setIsEditingOrganizationRoles] = useState<boolean>(false)
  const [isEditingProjects, setIsEditingProjects] = useState<boolean>(false)

  const handleOrganizationRoleSelectChange = useCallback(
    (role: Role) => {
      const roleName = role.name

      if (!roleName) return
      if (!member?.profile?.id) return

      const orgRoles = member.memberships?.filter((m) => m.resourceType === 'organization')

      const hasRole = orgRoles?.some((m) => m.roleNames?.includes(roleName))

      if (hasRole) {
        onResourceRoleRemove({
          memberId: member.profile.id,
          resourceId,
          resourceType: 'organization',
          roleName,
        })
      } else {
        onResourceRoleAdd({
          memberId: member.profile.id,
          resourceId,
          resourceType: 'organization',
          roleName,
        })
      }
    },
    [member.memberships, member.profile?.id, onResourceRoleAdd, onResourceRoleRemove, resourceId]
  )

  const handleProjectRoleSelectChange = useCallback(
    (projectId: string, role: Role) => {
      const roleName = role.name

      if (!roleName) return
      if (!member?.profile?.id) return

      const projectRoles = member.memberships?.filter(
        (m) => m.resourceType === 'project' && m.resourceId === projectId
      )

      const hasRole = projectRoles?.some((m) => m.roleNames?.includes(roleName))

      if (hasRole) {
        onResourceRoleRemove({
          memberId: member.profile.id,
          resourceId: projectId,
          resourceType: 'project',
          roleName,
        })
      } else {
        onResourceRoleAdd({
          memberId: member.profile.id,
          resourceId: projectId,
          resourceType: 'project',
          roleName,
        })
      }
    },
    [member.memberships, member.profile?.id, onResourceRoleAdd, onResourceRoleRemove]
  )

  const handleProjectMembershipAdd = useCallback(
    (projectId: string, role: Role) => {
      if (!role.name) return
      if (!member.profile?.id) return

      // onProjectMemberAdd(member.profile.id, projectId, role.name)
      onResourceMembershipAdd({
        memberId: member.profile.id,
        resourceId: projectId,
        resourceType: 'project',
        roleName: role.name,
      })
    },
    [member.profile?.id, onResourceMembershipAdd]
  )

  const handleOrganizationRoleRemove = useCallback(
    (roleName: string) => {
      if (!member.profile?.id) return

      onResourceRoleRemove({
        memberId: member.profile.id,
        resourceId,
        resourceType: 'organization',
        roleName,
      })
    },
    [member.profile?.id, onResourceRoleRemove, resourceId]
  )

  const memberOrgMembership = useMemo(
    () => member?.memberships?.find((m) => m.resourceType === 'organization'),
    [member?.memberships]
  )

  const selectedRolesProfiles = useMemo(() => {
    return organizationRoles.filter((r) => {
      return memberOrgMembership?.roleNames?.includes(r?.name || '')
    })
  }, [memberOrgMembership?.roleNames, organizationRoles])

  const memberProjects = useMemo((): MembersV2['projectData'][] => {
    return projectsData.filter((p) => {
      return member.memberships?.some((m) => m?.resourceId === p.projectProfile.id)
    })
  }, [projectsData, member.memberships])

  const projectDataOptions = useMemo(() => {
    return projectsData.filter((data) => {
      return !memberProjects.some((p) => p.projectProfile.id === data.projectProfile.id)
    })
  }, [projectsData, memberProjects])

  const organizationRoleOptions = useMemo(() => {
    return organizationRoles.filter(
      (role) => !selectedRolesProfiles.some((r) => r.name === role.name)
    )
  }, [organizationRoles, selectedRolesProfiles])

  const selectedProjects = useMemo(() => {
    return memberProjects.map((project) => project.projectProfile)
  }, [memberProjects])

  const isPartOfAllProjects = selectedProjects.length === projectsData.length
  const hasAllOrganizationRoles = selectedRolesProfiles.length === organizationRoles.length

  const {copyToClipboard} = useCopyToClipboard()
  const {push} = useToast()

  const handleCopyToClipboard = useCallback(() => {
    copyToClipboard(member.sanityUserId, {
      onSuccess: () => {
        push({
          title: 'Copied user ID to clipboard',
          status: 'success',
        })
      },
      onError: () => {
        push({
          title: 'Failed to copy user ID to clipboard',
          status: 'error',
        })
      },
    })
  }, [copyToClipboard, push, member.sanityUserId])

  return (
    <Stack space={3}>
      <Card sizing="border">
        <Stack space={5} paddingBottom={4} sizing="border">
          <Flex align="center" gap={4}>
            <Box>
              <UserAvatar
                displayName={member.profile?.displayName || ''}
                provider={member.profile?.provider}
                size={3}
                src={member.profile?.imageUrl || ''}
              />
            </Box>

            <Stack space={2} flex={1}>
              <Text size={3} weight="semibold">
                {member.profile?.displayName}
              </Text>

              <Text size={1} muted>
                {member.profile?.email}
              </Text>
            </Stack>

            <Stack space={1} flex={1} marginTop={2}>
              <Label weight="medium">User ID</Label>
              <Flex align="center" gap={1}>
                <Text size={1} muted>
                  {member.sanityUserId}
                </Text>
                <UIButton
                  fontSize={0}
                  padding={2}
                  icon={CopyIcon}
                  tone="default"
                  mode="bleed"
                  onClick={handleCopyToClipboard}
                />
              </Flex>
            </Stack>
          </Flex>

          <Stack space={isEditingOrganizationRoles || isEditingProjects ? 6 : 5}>
            {/* Organization roles */}
            <EditAccordion
              id="organization-roles"
              initialOpen
              canEdit={canEdit}
              onEditModeChange={setIsEditingOrganizationRoles}
              subtitle="Roles that apply to the entire organization"
              title="Organization roles"
            >
              <Stack sizing="border">
                <Table constrainSize>
                  <TableHeader>
                    <TableRow>
                      <TableHead title="Role" paddingLeft={0} paddingY={3} size={1} />

                      {isEditingOrganizationRoles && (
                        <TableHead cellMaxWidth={40} cellMinWidth={40} />
                      )}
                    </TableRow>
                  </TableHeader>

                  <TableBody>
                    {selectedRolesProfiles?.map((roleProfile) => {
                      const title = roleProfile?.title || roleProfile?.name || ''
                      const description = roleProfile?.description || ''

                      if (!roleProfile) return null

                      return (
                        <TableRow key={roleProfile.name}>
                          <TableCell>
                            <Stack space={3} paddingY={3} sizing="border">
                              <Text size={1}>{title}</Text>

                              <Text size={1} muted>
                                {description}
                              </Text>
                            </Stack>
                          </TableCell>

                          {isEditingOrganizationRoles && (
                            <TableCell cellMaxWidth={40} cellMinWidth={40}>
                              <Flex align="center" justify="center" height="fill">
                                <Button
                                  aria-label={`Remove role ${title}`}
                                  icon={RemoveCircleIcon}
                                  mode="bleed"
                                  onClick={() =>
                                    handleOrganizationRoleRemove(roleProfile?.name || '')
                                  }
                                  padding={2}
                                  tone="critical"
                                  tooltipProps={{content: `Remove role`}}
                                />
                              </Flex>
                            </TableCell>
                          )}
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>

                {selectedRolesProfiles.length === 0 && (
                  <Stack marginTop={4} marginBottom={1}>
                    <Text size={1} muted>
                      This member does not have any organization roles
                    </Text>
                  </Stack>
                )}

                {isEditingOrganizationRoles && (
                  <Flex marginTop={4}>
                    <RoleSelectMenuButton
                      closeOnSelect
                      onSelect={handleOrganizationRoleSelectChange}
                      options={organizationRoleOptions}
                      selectedRoles={selectedRolesProfiles}
                      popoverButtonProps={{
                        conditionalWrapper: {
                          condition: hasAllOrganizationRoles,
                          wrapper: (children) => {
                            return (
                              <Tooltip content="This member already has all organization roles">
                                <div>{children}</div>
                              </Tooltip>
                            )
                          },
                        },
                        button: (
                          <Button
                            disabled={hasAllOrganizationRoles}
                            iconRight={SelectIcon}
                            justify="space-between"
                            mode="ghost"
                            padding={2}
                            text="Add role"
                          />
                        ),
                      }}
                    />
                  </Flex>
                )}
              </Stack>
            </EditAccordion>

            {/* Project roles */}
            <EditAccordion
              id="projects"
              initialOpen
              canEdit={canEdit}
              onEditModeChange={setIsEditingProjects}
              subtitle="Roles that apply to specific projects"
              title="Projects"
            >
              <Stack sizing="border">
                <Table constrainSize>
                  <TableHeader>
                    <TableRow>
                      <TableHead
                        cellFlex={PROJECT_CELL_FLEX}
                        paddingLeft={0}
                        paddingY={3}
                        size={1}
                        title="Project"
                      />

                      <TableHead
                        cellFlex={ROLES_CELL_FLEX}
                        paddingLeft={0}
                        paddingY={3}
                        size={1}
                        title="Roles"
                      />

                      {isEditingProjects && <TableHead cellMaxWidth={40} cellMinWidth={40} />}
                    </TableRow>
                  </TableHeader>

                  <TableBody>
                    {memberProjects.map((project) => {
                      const {projectProfile, roleSchemas} = project

                      const projectId = projectProfile.id

                      // Get the member's membership for the project
                      const membership = member?.memberships?.find(
                        (m) => m.resourceId === projectId
                      )

                      // Get the role names for the member's membership
                      const memberRoleNames = membership?.roleNames || EMPTY_ARRAY

                      // Get the role schemas for the project
                      const projectRoles =
                        roleSchemas?.filter((p) => p.resourceId === projectId) || EMPTY_ARRAY

                      // Get the selected roles for the member
                      const selectedRoles =
                        projectRoles.filter((role) => memberRoleNames.includes(role?.name || '')) ||
                        EMPTY_ARRAY

                      const selectedRoleTitles = selectedRoles.map(
                        (role) => role?.title || role?.name || ''
                      )

                      const list = renderList({
                        items: selectedRoleTitles,
                        label: 'roles',
                        maxLength: isEditingProjects ? 3 : Infinity,
                        type: 'string',
                      })

                      return (
                        <TableRow key={projectId}>
                          <TableCell cellFlex={PROJECT_CELL_FLEX}>
                            <Flex align="center" height="fill" paddingY={3} sizing="border">
                              <Text size={1} weight="medium">
                                <Link href={`${basePath}/project/${projectId}`}>
                                  {projectProfile.displayName}
                                </Link>
                              </Text>
                            </Flex>
                          </TableCell>

                          <TableCell cellFlex={ROLES_CELL_FLEX}>
                            {!isEditingProjects && (
                              <Flex paddingY={3} sizing="border">
                                <Text size={1} muted>
                                  {list}
                                </Text>
                              </Flex>
                            )}

                            {isEditingProjects && (
                              <Flex paddingY={3} sizing="border" overflow="hidden">
                                <Box flex={1} overflow="hidden">
                                  <RoleSelectMenuButton
                                    options={projectRoles}
                                    selectedRoles={selectedRoles}
                                    multiSelect
                                    onSelect={(role) =>
                                      handleProjectRoleSelectChange(projectId, role)
                                    }
                                    popoverButtonProps={{
                                      matchReferenceWidth: true,
                                      button: (
                                        <UIButton width="fill" padding={2} mode="ghost">
                                          <Flex align="center" gap={2}>
                                            <Box flex={1}>
                                              <Text
                                                size={1}
                                                textOverflow="ellipsis"
                                                weight="medium"
                                              >
                                                {list}
                                              </Text>
                                            </Box>

                                            <Box>
                                              <Text>
                                                <SelectIcon />
                                              </Text>
                                            </Box>
                                          </Flex>
                                        </UIButton>
                                      ),
                                    }}
                                  />
                                </Box>
                              </Flex>
                            )}
                          </TableCell>

                          {isEditingProjects && (
                            <TableCell cellMaxWidth={40} cellMinWidth={40}>
                              <Flex align="center" justify="center" height="fill">
                                <Button
                                  aria-label={`Remove member from project ${projectProfile.displayName}`}
                                  icon={RemoveCircleIcon}
                                  mode="bleed"
                                  padding={2}
                                  tone="critical"
                                  onClick={() => {
                                    if (!member.profile?.id) return

                                    onResourceMembershipRemove({
                                      memberId: member.profile.id,
                                      resourceId: projectId,
                                      resourceType: 'project',
                                    })
                                  }}
                                  tooltipProps={{content: `Remove member from project`}}
                                />
                              </Flex>
                            </TableCell>
                          )}
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>

                {selectedProjects.length === 0 && (
                  <Stack marginTop={4} marginBottom={1}>
                    <Text size={1} muted>
                      This member is not part of any projects
                    </Text>
                  </Stack>
                )}

                {isEditingProjects && (
                  <Flex marginTop={4}>
                    <ProjectMemberAddMenuButton
                      options={projectDataOptions}
                      onConfirm={handleProjectMembershipAdd}
                      popoverButtonProps={{
                        conditionalWrapper: {
                          condition: isPartOfAllProjects,
                          wrapper: (children) => {
                            return (
                              <Tooltip content="This member is already part of all projects">
                                <div>{children}</div>
                              </Tooltip>
                            )
                          },
                        },
                        button: (
                          <Button
                            disabled={isPartOfAllProjects}
                            iconRight={SelectIcon}
                            justify="space-between"
                            mode="ghost"
                            padding={2}
                            text="Add project"
                          />
                        ),
                      }}
                    />
                  </Flex>
                )}
              </Stack>
            </EditAccordion>
          </Stack>
        </Stack>
      </Card>
    </Stack>
  )
}
