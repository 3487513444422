import {
  WizardCompletionCallback,
  WizardDialog,
  WizardParams,
  WizardStep,
  useAddBillingDetailsStep,
  useCreateNewOrgStep,
  useTransferToOrgStep,
  useWizardStore,
} from '@/components/payment-wizard'
import {useApplyCouponStep} from '@/ui/views/wizards/changePlan/steps/applyCoupon'
import {useProjectSubscription} from '@/data/projects/useProjectSubscription'
import {useRouter} from 'next/router'
import {useCallback, useEffect, useMemo} from 'react'
import {useChangePlanConfirmStep} from './steps/changePlanConfirmStep'
import {useChangePlanIntroStep} from './steps/changePlanIntroStep'
import {useIncompleteSubscriptionStep} from './steps/incompleteSubscriptionStep'
import {sendAmplitudeTrackingEvent} from '@/utils/tracking'

export function ChangePlanWizard(props: {
  params: WizardParams
  onComplete?: WizardCompletionCallback
}) {
  const router = useRouter()
  const {dispatch, state} = useWizardStore()

  const {onComplete: handleWizardComplete, params} = props
  const project = params.project

  const onOrganizationAssigned = useCallback(
    (orgId: string) => {
      const basePath = `/organizations/${orgId}`
      const {query} = router
      return router.push(`${basePath}/project/${project.id}/${query.tabId}`, undefined, {
        shallow: true,
      })
    },
    [project, router]
  )

  const _params: WizardParams = useMemo(
    () => ({
      onOrganizationAssigned,
      ...params,
    }),
    [onOrganizationAssigned, params]
  )

  const handleWizardClose = useCallback(() => {
    const {checkout: _, ...otherParams} = router.query
    router.replace({
      query: otherParams,
    })
    dispatch({type: 'changePlanWizard/close'})
    dispatch({type: 'reset'})
  }, [dispatch])

  const introStep = useChangePlanIntroStep()
  const couponStep = useApplyCouponStep()
  const transferStep = useTransferToOrgStep(_params)
  const createNewOrgStep = useCreateNewOrgStep()
  const addBillingDetails = useAddBillingDetailsStep(_params)
  const confirmChangePlanStep = useChangePlanConfirmStep(_params)
  const incompleteSubscription = useIncompleteSubscriptionStep(_params)
  const {data: subscription} = useProjectSubscription(project.id)
  const steps = useMemo(() => {
    let _steps = [
      couponStep,
      introStep,
      transferStep,
      createNewOrgStep,
      addBillingDetails,
      confirmChangePlanStep,
      incompleteSubscription,
    ].filter(Boolean) as WizardStep[]
    // Remove intro step if needed
    if (_steps.length === 2 && _steps[0] === introStep && _steps[1] === confirmChangePlanStep) {
      _steps = [confirmChangePlanStep]
    }
    if (subscription?.plan.planTypeId === 'free' && state.targetPlan?.planTypeId === 'free') {
      _steps = [confirmChangePlanStep!]
    }

    if (state.forceMoveToBillingStep) {
      const index = _steps.indexOf(addBillingDetails!)
      if (index > 0) {
        dispatch({type: 'changePlanWizard/carderror/forceToBillingStep', value: index})
      }
    }

    return _steps
  }, [
    state,
    addBillingDetails,
    confirmChangePlanStep,
    createNewOrgStep,
    couponStep,
    introStep,
    transferStep,
    subscription?.plan.planTypeId,
  ])

  /* Send event when checkout form loaded */
  useEffect(() => {
    sendAmplitudeTrackingEvent(
      'Change Plan Flow Initiated',
      project.id,
      project.organizationId || undefined,
      {
        targetPlan: state?.targetPlan?.id,
        newFlow: false,
        current_path: window.location.href,
      }
    )
  }, [project?.id, project?.organizationId, state?.targetPlan])

  if (steps.length === 0) {
    return null
  }
  return (
    <WizardDialog
      onClose={handleWizardClose}
      onComplete={handleWizardComplete}
      steps={steps}
      params={_params}
      title="Switch plan"
    />
  )
}
