import styled from 'styled-components'
import {ComponentProps} from 'react'
import {Box, Text} from '@sanity/ui'

type Props = ComponentProps<typeof Box> & {
  text?: string
}

const SeparatorContainer = styled(Box)<Props>`
  position: relative;
`

const HR = styled.hr`
  width: 100%;
  height: 1px;
  border: none;
  background: ${({theme}) => theme.sanity.color.base.border};
  margin-block: 0;
`

const CenteredText = styled(Text)`
  padding-inline: 0.75em;
  position: absolute;
  top: 50%;
  left: 50%;
  line-height: 1;
  background: ${({theme}) => theme.sanity.color.base.bg};
  transform: translate(-50%, -60%);

  &::before,
  &::after {
    display: none;
  }
`

export function Separator(props: Props) {
  return (
    <SeparatorContainer {...props}>
      <HR />
      {props.text && (
        <CenteredText size={1} muted>
          {props.text}
        </CenteredText>
      )}
    </SeparatorContainer>
  )
}
