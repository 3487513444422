import {Card, type CardTone, Flex} from '@sanity/ui'

import {Button, type ButtonProps} from '../../primitives/button/Button'

type InlineBannerProps = {
  children: React.ReactNode
  tone?: CardTone
  buttonProps?: ButtonProps & {
    href?: string
  }
}

export function InlineBanner(props: InlineBannerProps) {
  return (
    <Card tone={props.tone} padding={4} border radius={2}>
      <Flex gap={[4, 2]} align={['flex-start', 'center']} direction={['column', 'row']}>
        <Flex flex={1} wrap={'wrap'}>
          {props.children}
        </Flex>
        {props?.buttonProps && <Button fontSize={1} {...props.buttonProps} />}
      </Flex>
    </Card>
  )
}
