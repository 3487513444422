import React from 'react'
import {Stack, FlexAlign} from '@sanity/ui'
import {Icon} from '@sanity/icons'
import {ModuleHeading} from './moduleHeading'

interface Props {
  title: string | React.ReactNode
  buttonText?: string | number | boolean
  buttonIcon?: typeof Icon
  children: React.ReactNode
  path?: string
  align?: FlexAlign
}

export function OverviewModule({title, buttonText, buttonIcon, path, align, children}: Props) {
  return (
    <Stack space={3}>
      <ModuleHeading
        title={title}
        buttonText={buttonText}
        buttonIcon={buttonIcon}
        path={path}
        align={align}
      />
      {children}
    </Stack>
  )
}
