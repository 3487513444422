import {useMemo} from 'react'
import {PatchProjectData, Project, ProjectData} from '../../types/models'
import {getProject, patchProject} from './controller'
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query'
import {getProjectBaseKey, STALE_TTL, getProjectsKey} from './cache'
import {useRequiredProjectPermissions} from '../../context'

export function useProjectData(project: Project) {
  const queryClient = useQueryClient()
  const projectKey = useMemo(() => getProjectBaseKey(project.id), [project.id])
  const defaultData = useMemo<ProjectData>(
    () => ({
      displayName: project?.displayName,
      metadata: project?.metadata || {},
      isDisabledByUser: project?.isDisabledByUser || false,
    }),
    [project]
  )

  const canReadProject = useRequiredProjectPermissions(
    [{permissionName: 'sanity.project', grantName: 'read'}],
    project.id
  )

  const query = useQuery({
    queryKey: projectKey,
    queryFn: () => getProject(project.id),
    initialData: defaultData,
    enabled: !!project.id && canReadProject,
    staleTime: STALE_TTL,
  })

  const patchMutation = useMutation({
    mutationKey: ['patchProjectData', project.id],
    mutationFn: (data: PatchProjectData) => patchProject(project.id, data),
    onMutate: async (newData: PatchProjectData) => {
      await queryClient.cancelQueries({queryKey: projectKey})
      const previousData = queryClient.getQueryData<ProjectData>(projectKey)

      // Update project data
      queryClient.setQueryData(projectKey, (old: ProjectData) => ({
        ...old,
        ...newData,
      }))

      // Update project in projects list
      queryClient.setQueryData(getProjectsKey(), (old: Project[] | undefined) => {
        if (!old) return old
        return old.map((p) => (p.id === project.id ? {...p, ...newData} : p))
      })

      return {previousData}
    },
    onError: (_err, _newData, context) => {
      // Revert project data
      queryClient.setQueryData(projectKey, context?.previousData)

      // Revert project in projects list
      queryClient.setQueryData(getProjectsKey(), (old: Project[] | undefined) => {
        if (!old) return old
        return old.map((p) => (p.id === project.id ? {...p, ...context?.previousData} : p))
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries({queryKey: projectKey})
    },
  })

  return {...query, patchProjectData: patchMutation.mutate, isPending: patchMutation.isPending}
}
