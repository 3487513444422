import {type ResourceType} from '@sanity/access-api'

import {createEmptyInvitationItem, createLocalId} from './invite-utils'
import {type InvitationScope, type LocalInvitationItemValue, type LocalInvitee} from './types'

export interface RemoveInviteePayload {
  index: number
  scope: InvitationScope
}

export interface RemoveInvitationResourcePayload {
  resourceItemId: string
}

export interface ResourceSelectPayload {
  resourceId: string
  resourceItemId: string
}

export interface RoleSelectPayload {
  roleName: string
  resourceItemId: string
}

// Functions
export function addInvitationItem(prev: LocalInvitationItemValue[], resourceType: ResourceType) {
  const next: LocalInvitationItemValue[] = [...prev, createEmptyInvitationItem(resourceType)]

  return next
}

export function addInvitee(
  _id: string,
  prev: LocalInvitationItemValue[],
  payload: LocalInvitee
): LocalInvitationItemValue[] {
  const next: LocalInvitationItemValue[] = prev.map((prevItem) => {
    if (prevItem._id !== _id) return prevItem

    // Check if the email already exists
    const isEmailAdded = prevItem.invitees.some((invitee) => invitee.email === payload.email)

    // If the email is already present, do not add it again
    if (isEmailAdded) return prevItem

    // Otherwise, add the new invitee
    return {
      ...prevItem,
      invitees: [...prevItem.invitees, payload],
    }
  })

  return next
}

export function addInvitationResource(_id: string, prev: LocalInvitationItemValue[]) {
  const next: LocalInvitationItemValue[] = prev.map((prevItem) => {
    if (prevItem._id !== _id) return prevItem

    const nextResource: LocalInvitationItemValue['resources'][0] = {
      _id: createLocalId(),
      resourceId: '',
      resourceType: 'project',
      roleName: '',
    }

    return {
      ...prevItem,
      resources: [...prevItem.resources, nextResource],
    }
  })

  return next
}

export function removeInvitationItem(_id: string, prev: LocalInvitationItemValue[]) {
  const next: LocalInvitationItemValue[] = prev.filter((item) => item._id !== _id)

  return next
}

export function removeInvitationResource(
  _id: string,
  prev: LocalInvitationItemValue[],
  payload: RemoveInvitationResourcePayload
): LocalInvitationItemValue[] {
  const {resourceItemId} = payload

  const next: LocalInvitationItemValue[] = prev.map((prevItem) => {
    if (prevItem._id !== _id) return prevItem

    return {
      ...prevItem,
      resources: prevItem.resources.filter((v) => v._id !== resourceItemId),
    }
  })

  return next
}

export function removeInvitee(
  _id: string,
  prev: LocalInvitationItemValue[],
  payload: RemoveInviteePayload
): LocalInvitationItemValue[] {
  const {index, scope} = payload

  const next: LocalInvitationItemValue[] = prev.map((prevItem) => {
    if (prevItem._id !== _id) return prevItem

    return {
      ...prevItem,
      invitees: prevItem.invitees.filter((_, i) => i !== index),
    }
  })

  // Filter out invitation items that have no invitees left
  const filteredNext = next.filter((item) => item.invitees.length > 0)

  // If all items are removed, return a new invitation item
  if (filteredNext.length === 0) {
    return [createEmptyInvitationItem(scope)]
  }

  return filteredNext
}

export function resourceSelect(
  _id: string,
  prev: LocalInvitationItemValue[],
  payload: ResourceSelectPayload
) {
  const {resourceId, resourceItemId} = payload

  const next: LocalInvitationItemValue[] = prev.map((prevItem) => {
    if (prevItem._id !== _id) return prevItem

    return {
      ...prevItem,
      resources: prevItem.resources.map((prevValue) => {
        if (prevValue._id !== resourceItemId) return prevValue

        return {
          ...prevValue,
          resourceId,
          roleName: '',
        }
      }),
    }
  })

  return next
}

export function roleSelect(
  _id: string,
  prev: LocalInvitationItemValue[],
  payload: RoleSelectPayload
): LocalInvitationItemValue[] {
  const {roleName, resourceItemId} = payload

  const next: LocalInvitationItemValue[] = prev.map((prevItem) => {
    if (prevItem._id !== _id) return prevItem

    return {
      ...prevItem,
      resources: prevItem.resources.map((prevValue) => {
        if (prevValue._id !== resourceItemId) return prevValue

        return {
          ...prevValue,
          roleName,
        }
      }),
    }
  })

  return next
}
