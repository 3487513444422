import React, {useCallback, useEffect, useMemo} from 'react'
import {Card, Stack, useToast, Skeleton} from '@sanity/ui'
import {useRouter} from 'next/router'
import {AddIcon} from '@sanity/icons'
import {NewDatasetWizard} from '../../wizards/newDataset'
import {DatasetView} from './'
import {
  TabHeader,
  DatasetList,
  PermissionButtonProject,
  ProjectUsagePrompt,
  ProjectUsageStatusTooltip,
  EmptyBlock,
  InfoTooltip,
} from '@/ui/index'
import {Organization, Project, Tab} from '@/types/index'
import {useDatasets, useDefaultPlans, useProjectSubscription} from '@/data/index'
import {sendAmplitudeTrackingEvent} from '@/utils/tracking'
import {
  useCurrentScopeContext,
  useProjectType,
  useRequiredProjectPermissions,
} from '@/context/index'
import {useWizardStore} from '@/components/payment-wizard'
import {useProjectTags} from '@/data/projects/useProjectRoles'
import {filterBillableDatasets, getMaxAllowedResourceUsage} from '@/utils/usage'

type Props = {
  project: Project
  title?: string | React.ReactNode
  org?: Organization
  description?: string | React.ReactNode
}

const CREATE_PERMISSIONS = [{permissionName: 'sanity.project.datasets', grantName: 'create'}]

export function Datasets({title, description, referenceLink, org, project}: Props & Tab) {
  const toast = useToast()

  const canReadDatasets = useRequiredProjectPermissions([
    {permissionName: 'sanity.project.datasets', grantName: 'read'},
  ])

  const {dispatch, state: wizardState} = useWizardStore()

  const {
    data: datasets = [],
    isLoading: isLoadingDataset,
    isError: isLoadingDatasetError,
    error: datasetError,
  } = useDatasets(project.id)
  const {data: tags = [], isLoading: isLoadingTags, error: tagError} = useProjectTags(project.id)
  const {data: subscription} = useProjectSubscription(project.id)

  const billableDatasets = useMemo(() => filterBillableDatasets(datasets), [datasets])
  const nonDeletedDatasets = useMemo(
    () => datasets.filter((dataset) => !dataset.isDeleted),
    [datasets]
  )

  const projectType = useProjectType(project)
  const isEnterprise = useMemo(() => subscription?.plan?.planTypeId == 'enterprise', [subscription])
  const isEnterPriseOrgPlanProject = useMemo(
    () => org?.organizationPlan && projectType !== 'regular',
    [org, projectType]
  )

  const orgPlanResources =
    projectType === 'playground'
      ? org?.organizationPlan?.playgroundProjects?.maxResources
      : org?.organizationPlan?.resources

  const quota =
    (org?.organizationPlan && projectType !== 'regular'
      ? orgPlanResources.datasets.quota
      : subscription?.resources.datasets.quota) ?? Infinity

  const max = useMemo(() => {
    if (isEnterPriseOrgPlanProject) {
      /**
       * If on a legacy org plan, we need to merge the org plan resources with the subscription resources
       * to get the max allowed resource usage. We also force allowing unlimited overage for org plans.
       */
      return (
        getMaxAllowedResourceUsage(
          {
            ...(subscription?.resources.datasets || {}),
            ...orgPlanResources.datasets,
          },
          true,
          true,
          true
        ) ?? Infinity
      )
    }
    return getMaxAllowedResourceUsage(subscription?.resources.datasets) ?? Infinity
  }, [isEnterPriseOrgPlanProject, subscription?.resources.datasets, orgPlanResources])
  const counter = useMemo(
    () => ({
      current: billableDatasets.length,
      quota,
      max,
      resource: 'datasets',
      isOrgPlan: org?.type === 'org-plan' && projectType === 'org-plan',
      isQuotaReached: billableDatasets.length >= max,
    }),
    [billableDatasets.length, quota, max, org?.type, projectType]
  )

  useEffect(() => {
    if (project?.id) {
      sendAmplitudeTrackingEvent('Project Datasets Page Viewed', project.id)
    }
  }, [project?.id])

  const {orgs = []} = useCurrentScopeContext()
  const {data: plans = []} = useDefaultPlans()

  const handleNewDatasetWizardOpen = useCallback(() => {
    dispatch({type: 'newDatasetWizard/open'})
  }, [dispatch])

  const isLoading = useMemo(
    () => isLoadingDataset || isLoadingTags,
    [isLoadingDataset, isLoadingTags]
  )
  const error = datasetError || tagError
  const disableQuotaTooltip = useMemo(
    () => !isEnterprise || !counter.isQuotaReached,
    [isEnterprise, counter.isQuotaReached]
  )

  const disableCreateDatasetButton = useMemo(
    () =>
      isLoadingDataset || ((isEnterprise || isEnterPriseOrgPlanProject) && counter.isQuotaReached),
    [isLoadingDataset, isEnterprise, isEnterPriseOrgPlanProject, counter.isQuotaReached]
  )

  useEffect(() => {
    if (isLoadingDatasetError && error) {
      toast.push({title: error.name, description: error.message, status: 'error'})
    }
  }, [error, isLoadingDatasetError, toast])

  const handleCreatedDataset = useCallback(async () => {
    toast.push({
      title: 'Dataset created',
      status: 'success',
    })
  }, [toast])

  const {
    query: {name},
  } = useRouter()

  if (name) {
    if (isLoadingDataset) {
      return (
        <Card padding={4} paddingTop={0}>
          <Stack space={4}>
            <Skeleton animated style={{height: '24px', width: '200px'}} />
            <Skeleton animated style={{height: '16px', width: '300px'}} />
            <Stack space={3}>
              <Skeleton animated style={{height: '40px'}} />
              <Skeleton animated style={{height: '40px'}} />
              <Skeleton animated style={{height: '40px'}} />
            </Stack>
          </Stack>
        </Card>
      )
    }

    const dataset = datasets.find((_dataset) => _dataset.name === name)
    if (!dataset) {
      return <>Dataset not found</>
    }
    const addons = datasets.filter((_dataset) => _dataset.addonFor === name)

    return <DatasetView project={project} dataset={dataset} addons={addons} tags={tags} />
  }

  return (
    <>
      <Stack space={[4, 4, 6]} data-title={title}>
        <TabHeader
          title="Datasets"
          button={
            <InfoTooltip
              title="Dataset quota reached"
              description="No more datasets available on this plan, contact your admin or customer support to upgrade."
              disabled={disableQuotaTooltip}
            >
              <PermissionButtonProject
                onClick={handleNewDatasetWizardOpen}
                title="New dataset"
                icon={AddIcon}
                permissions={CREATE_PERMISSIONS}
                disabled={disableCreateDatasetButton}
              />
            </InfoTooltip>
          }
          description={description}
          referenceLink={referenceLink}
          counter={canReadDatasets ? counter : undefined}
          tooltip={
            project && (
              <ProjectUsageStatusTooltip project={project} resourcesToCheck={['datasets']} />
            )
          }
          showUpsell
          upsellResourceName="datasets"
        />

        <ProjectUsagePrompt resourcesToCheck={['datasets']} />

        {canReadDatasets && (
          <DatasetList
            isLoading={isLoading}
            error={error}
            datasets={nonDeletedDatasets}
            tags={tags}
            project={project}
          />
        )}
        {!canReadDatasets && (
          <EmptyBlock
            illustration="access-denied"
            title="Insufficient permissions"
            description="You don't have the right permissions to see dataset details."
          />
        )}
      </Stack>
      {wizardState.newDatasetWizard.open && (
        <NewDatasetWizard
          onComplete={handleCreatedDataset}
          params={{
            org,
            orgs,
            plans,
            project,
            datasets: billableDatasets,
          }}
        />
      )}
    </>
  )
}
