import {type ResourceType} from '@sanity/access-api'
import {AddIcon, BoltIcon} from '@sanity/icons'
import {Flex, type FlexProps, Stack, Text, TextSkeleton, useMediaIndex} from '@sanity/ui'
import Link from 'next/link'
import styled, {css} from 'styled-components'

import {getUserResourceName} from '@/utils/getUserResourceName'

import {ColorButton} from '../../generic/color-button'
import {ContentHeader, type ContentHeaderProps} from '../../generic/content-header'
import {PermissionTooltip} from '../../generic/permission-tooltip'
import {Button} from '../../primitives/button/Button'
import {type DisabledMemberSettingsFeatures, type MembersSettingsScreenProps} from './types'

function isInfinity(value: number): boolean {
  return !isNaN(value) && !isFinite(value)
}

function getQuotaText(quota: number | undefined) {
  return quota && isInfinity(quota) ? '∞' : quota
}

const VisuallyAlignedColorButton = styled(ColorButton)(({theme}) => {
  const space = theme.sanity.space[3]

  return css`
    // Since the upsell color button has a transparent background, we need to visually
    // align the button with other elements in the header by moving it.
    // The different directions are based on the current media index to ensure the
    // button is visually aligned with the other elements in the header based on the
    // current layout.
    &[data-direction='right'] {
      transform: translateX(${space}px);
    }

    &[data-direction='left'] {
      transform: translateX(-${space}px);
    }
  `
})

const CONTENT_FLEX_ALIGN: FlexProps['align'] = ['flex-start', 'flex-start', 'flex-end']

export type InvitationScope = 'organization' | 'project'

export interface MembersSettingsScreenHeaderProps extends ContentHeaderProps {
  basePath?: string
  disabledFeatures?: DisabledMemberSettingsFeatures
  isOrganizationPlan?: MembersSettingsScreenProps['isOrganizationPlan']
  loading: boolean
  onInviteMembers: (scope: InvitationScope) => void
  resourcesQuota: MembersSettingsScreenProps['resourcesQuota']
  resourceType: ResourceType
  referenceLinkComponent?: JSX.ElementType
}

export function MembersSettingsScreenHeader(props: MembersSettingsScreenHeaderProps) {
  const {
    basePath,
    description,
    disabledFeatures,
    isOrganizationPlan,
    loading,
    onInviteMembers,
    resourcesQuota,
    resourceType,
    title,
    referenceLinkComponent,
  } = props

  const mediaIndex = useMediaIndex()

  const membersResourceQuota = resourcesQuota?.members

  return (
    <ContentHeader
      title={title}
      description={description}
      referenceLinkComponent={referenceLinkComponent}
      content={
        <Flex
          align={CONTENT_FLEX_ALIGN}
          direction="column"
          gap={3}
          marginBottom={membersResourceQuota?.showUpsell ? 1 : 4}
        >
          <PermissionTooltip disabled={loading || !disabledFeatures?.addMembers?.isDisabled}>
            <Button
              disabled={loading || disabledFeatures?.addMembers?.isDisabled}
              icon={AddIcon}
              text="Add members"
              tone="primary"
              onClick={() => onInviteMembers(resourceType)}
            />
          </PermissionTooltip>

          {!loading && (
            <Flex
              align={['flex-start', 'flex-start', 'flex-end']}
              direction="column"
              gap={4}
              marginTop={2}
            >
              <Stack space={3}>
                {resourceType === 'project' && membersResourceQuota && (
                  <Flex direction="column" gap={3} align={CONTENT_FLEX_ALIGN}>
                    <Text size={2} weight="medium">
                      <b>{membersResourceQuota.billable}</b> of{' '}
                      {getQuotaText(membersResourceQuota.max)}{' '}
                    </Text>
                    <Text size={1} muted>
                      {membersResourceQuota.billableUserType
                        ? getUserResourceName(membersResourceQuota.billableUserType)
                        : 'members'}
                    </Text>

                    {membersResourceQuota?.quota && !isInfinity(membersResourceQuota?.quota) ? (
                      <Text size={1} muted>
                        ({membersResourceQuota.quota} included in{' '}
                        {isOrganizationPlan ? 'organization' : 'plan'})
                      </Text>
                    ) : null}
                  </Flex>
                )}
              </Stack>

              {membersResourceQuota?.showUpsell && !loading && (
                <VisuallyAlignedColorButton
                  color="purple"
                  data-direction={mediaIndex < 2 ? 'left' : 'right'}
                  forwardedAs={Link}
                  href={`${basePath}/plan?ref=manage-upsell-tab-header-users`}
                  icon={BoltIcon}
                  mode="bleed"
                  text="Get more seats"
                />
              )}
            </Flex>
          )}

          {loading && <TextSkeleton animated size={1} style={{width: 100}} />}
        </Flex>
      }
    />
  )
}
