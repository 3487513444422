import {INITIAL_STATE} from '../constants'
import type {Msg, SwitchPlanState} from './types'

export function reducer(state: SwitchPlanState, msg: Msg): SwitchPlanState {
  if (msg.type === 'reset') {
    return msg.value ?? INITIAL_STATE
  }
  if (msg.type === 'switchPlan/open') {
    return {
      ...state,
      open: true,
    }
  }
  if (msg.type === 'switchPlan/close') {
    return {
      ...state,
      open: false,
    }
  }
  if (msg.type === 'setTargetPlan') {
    return {
      ...state,
      plan: msg.value,
    }
  }
  if (msg.type === 'setCouponCode') {
    return {
      ...state,
      couponCode: msg.value,
    }
  }
  return state
}
