import React from 'react'
import {Text, Box, Tooltip} from '@sanity/ui'
import {Icon} from '@sanity/icons'
import {red} from '@sanity/color'

export const ErrorTooltip = ({message}: {message: string | string[]}) => {
  const messageLines = Array.isArray(message) ? message : [message]

  return (
    <Tooltip
      portal
      content={
        <Box padding={1} style={{maxWidth: 300}}>
          {messageLines.map((msgLine) => (
            <Text
              muted
              key={`error-${msgLine}`}
              size={1}
              role="alert"
              style={{textTransform: 'none'}}
            >
              {msgLine}
            </Text>
          ))}
        </Box>
      }
      placement="right"
    >
      <Box marginX={1} paddingX={1}>
        <Text size={1}>
          <Icon symbol="error-outline" style={{color: red[500].hex}} />
        </Text>
      </Box>
    </Tooltip>
  )
}
