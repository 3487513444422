import {type ResourceType, type Role} from '@sanity/access-api'
import {Flex, Stack, Text, TextSkeleton} from '@sanity/ui'
import {AnimatePresence, motion, type Transition, type Variants} from 'framer-motion'
import {Fragment, memo, useCallback, useEffect, useMemo, useState} from 'react'
import styled from 'styled-components'

import {type MembersV2} from '@/types/members_v2'

import {OverflowContainer} from '../../generic/overflow-container'
import {Badge} from '../../primitives/badge'
import {type DisabledMemberSettingsFeatures} from '../../screens/members-settings-screen/types'
import {
  type ResourceMembershipRemovePayload,
  type ResourceRoleAddPayload,
  type ResourceRoleRemovePayload,
} from '../../types'
import {filterByQuery} from '../../utils'
import {
  InvitationsTable,
  type InvitationsTableProps,
  MembersOverviewTable,
  type MembersOverviewTableProps,
  RequestAccessTable,
  type RequestAccessTableProps,
} from '../member-tables'
import {RequestRoleChangeTable} from '../member-tables/request-role-change-table'
import {type AppliedFilters, Filters} from './components/Filters'
import {SearchBar} from './components/Search'
import {getTableHeadingCopy} from './getTableHeadingCopy'
import {EmptyState, ErrorState, LoadingState} from './states'

const TableOverflowContainer = styled(motion(OverflowContainer))`
  overflow-x: auto;
`

const ANIMATION_TRANSITION: Transition = {duration: 0.2, ease: 'easeInOut'}

const MotionStack = motion(Stack)

const ANIMATION_VARIANTS: Variants = {
  initial: {opacity: 0},
  animate: {opacity: 1},
  exit: {opacity: 0},
}

export type TableViews = 'members' | 'invitations' | 'requests'

export interface MembersTablesViewProps {
  basePath?: string
  /**
   * Features that are disabled for the current user.
   */
  disabledFeatures?: DisabledMemberSettingsFeatures
  error: Error | null
  invitations: InvitationsTableProps['invitations']
  loading: boolean
  members: MembersV2['member'][]
  onInspectMember: MembersOverviewTableProps['onInspectMember']
  onInvitationRevoke: InvitationsTableProps['onInvitationRevoke']
  onMemberRemove: (payload: ResourceMembershipRemovePayload) => void
  onRequestApprove: RequestAccessTableProps['onRequestApprove']
  onRequestRevoke: RequestAccessTableProps['onRequestRevoke']
  onResourceRoleAdd: (payload: ResourceRoleAddPayload) => void
  onResourceRoleRemove: (payload: ResourceRoleRemovePayload) => void
  onRetryFetch?: () => void
  selectedView?: TableViews
  projectsData: MembersV2['projectData'][]
  requests: RequestAccessTableProps['requests']
  resourceId: string
  resourceType: ResourceType
  roles: Role[]
}

/**
 * The MembersTablesView component displays the members,
 * invitations, and requests tables in the organization, providing
 * the ability to filter and search the data.
 */
export const MembersTablesView = memo(function MembersTablesView(props: MembersTablesViewProps) {
  const {
    basePath,
    disabledFeatures,
    error,
    invitations,
    loading,
    members,
    onInspectMember,
    onInvitationRevoke,
    onMemberRemove,
    onRequestApprove,
    onRequestRevoke,
    onResourceRoleAdd,
    onResourceRoleRemove,
    onRetryFetch,
    selectedView = 'members',
    projectsData,
    requests,
    resourceId,
    resourceType,
    roles,
  } = props

  const showLoading = loading && !error
  const showError = error && !loading
  const showTable = !showLoading && !showError

  const [searchQuery, setSearchQuery] = useState<string>('')
  const [tableFilters, setTableFilters] = useState<AppliedFilters | null>(null)

  const ConditionalAnimatePresence = showTable ? AnimatePresence : Fragment

  const resourceMembers = useMemo(() => {
    if (resourceType === 'organization') return members

    if (resourceType === 'project') {
      return members.filter((member) => {
        const memberships = member.memberships?.find((m) => m.resourceId === resourceId)
        return memberships
      })
    }

    return []
  }, [members, resourceId, resourceType])

  const handleRemoveMember = useCallback(
    (memberId: string) => {
      onMemberRemove({memberId, resourceId, resourceType})
    },
    [onMemberRemove, resourceId, resourceType]
  )

  const handleTableSearchChange = useCallback((nextQuery: string) => {
    setSearchQuery(nextQuery)
  }, [])

  const pendingRequests = useMemo(() => {
    return requests.filter((r) => r.status === 'pending')
  }, [requests])

  const resourceRoles = useMemo(() => {
    return roles.filter(
      (role) => role.resourceType === resourceType && role.resourceId === resourceId
    )
  }, [resourceId, resourceType, roles])

  const filteredMembers = useMemo(() => {
    const filteredByQuery = filterByQuery(resourceMembers, searchQuery, [
      ['profile', 'displayName'],
      ['profile', 'email'],
    ])

    if (tableFilters) {
      if (tableFilters.filter === 'project-access') {
        const projectId = tableFilters.project?.id || ''

        return filteredByQuery.filter((member) => {
          const memberships = member.memberships?.find((m) => m.resourceId === projectId)

          return (
            memberships &&
            tableFilters.projectRoles &&
            (tableFilters.projectRoles.length === 0 ||
              tableFilters.projectRoles.some((role) =>
                memberships.roleNames?.includes(role.name || '')
              ))
          )
        })
      }

      if (
        tableFilters.filter === 'organization-roles' &&
        tableFilters.roles &&
        tableFilters.roles.length > 0
      ) {
        return filteredByQuery.filter((member) => {
          const memberships = member?.memberships?.find((m) => m.resourceType === 'organization')
          return (
            memberships &&
            tableFilters.roles &&
            tableFilters.roles.some((role) => memberships.roleNames?.includes(role.name || ''))
          )
        })
      }
    }

    return filteredByQuery
  }, [resourceMembers, searchQuery, tableFilters])

  const filteredInvitations = useMemo(
    () => filterByQuery(invitations, searchQuery, [['email']]),
    [invitations, searchQuery]
  )

  const filteredRequests = useMemo(
    () =>
      filterByQuery(pendingRequests, searchQuery, [
        ['requestedByUserProfile', 'displayName'],
        ['requestedByUserProfile', 'email'],
      ]),
    [pendingRequests, searchQuery]
  )

  const filteredResultsLength = useMemo(() => {
    if (selectedView === 'invitations') return filteredInvitations.length
    if (selectedView === 'requests') return filteredRequests.length
    return filteredMembers.length
  }, [selectedView, filteredMembers, filteredInvitations, filteredRequests])

  const numPendingAccessRequests = useMemo(() => {
    return filteredRequests.filter((r) => r.type !== 'role').length
  }, [filteredRequests])

  const numPendingRoleRequests = useMemo(() => {
    return filteredRequests.filter((r) => r.type === 'role').length
  }, [filteredRequests])

  const tableHeadingCopy = useMemo(
    () =>
      getTableHeadingCopy({
        resultLen: filteredResultsLength,
        selectedView,
        tableFilters,
        searchQuery,
      }),
    [selectedView, tableFilters, searchQuery, filteredResultsLength]
  )

  useEffect(() => {
    setSearchQuery('')
    setTableFilters(null)
  }, [selectedView])

  return (
    <Stack space={5}>
      <Stack>
        <Flex
          align={['stretch', 'stretch', 'center']}
          gap={2}
          direction={['column', 'column', 'row']}
        >
          <Flex flex={1} gap={2}>
            <SearchBar disabled={loading} value={searchQuery} onChange={handleTableSearchChange} />

            {selectedView === 'members' ? (
              <Filters
                disabled={loading}
                resourceType={resourceType}
                resourceRoles={resourceRoles}
                projectsData={projectsData}
                value={tableFilters}
                setValue={setTableFilters}
              />
            ) : null}
          </Flex>
        </Flex>
      </Stack>

      <Stack marginBottom={1}>
        {showTable && (
          <Text muted size={1}>
            {tableHeadingCopy}
          </Text>
        )}

        {showLoading && <TextSkeleton animated size={1} style={{width: 200}} />}
      </Stack>

      {showLoading && <LoadingState />}

      {showError && <ErrorState onRetry={onRetryFetch} />}

      <ConditionalAnimatePresence mode="wait">
        {selectedView === 'members' && showTable && (
          <MotionStack
            animate="animate"
            exit="exit"
            initial="initial"
            key="members"
            transition={ANIMATION_TRANSITION}
            variants={ANIMATION_VARIANTS}
          >
            {filteredResultsLength ? (
              <TableOverflowContainer>
                <MembersOverviewTable
                  basePath={basePath}
                  disabledFeatures={disabledFeatures}
                  displayMode={
                    !tableFilters || tableFilters.filter === 'all' ? undefined : tableFilters.filter
                  }
                  filterByProjectId={tableFilters?.project?.id}
                  members={filteredMembers}
                  onInspectMember={onInspectMember}
                  onMemberRemove={handleRemoveMember}
                  onResourceRoleAdd={onResourceRoleAdd}
                  onResourceRoleRemove={onResourceRoleRemove}
                  projectsData={projectsData}
                  resourceId={resourceId}
                  resourceType={resourceType}
                  roles={resourceRoles}
                />
              </TableOverflowContainer>
            ) : (
              <EmptyState title="Try adjusting your search query or filters." />
            )}
          </MotionStack>
        )}

        {selectedView === 'invitations' && showTable && (
          <MotionStack
            animate="animate"
            exit="exit"
            initial="initial"
            key="invitations"
            transition={ANIMATION_TRANSITION}
            variants={ANIMATION_VARIANTS}
          >
            <TableOverflowContainer>
              {filteredResultsLength ? (
                <InvitationsTable
                  basePath={basePath}
                  disabledFeatures={disabledFeatures}
                  invitations={filteredInvitations}
                  members={members}
                  onInvitationRevoke={onInvitationRevoke}
                  projectsData={projectsData}
                  resourceType={resourceType}
                  roles={roles}
                />
              ) : null}
            </TableOverflowContainer>
          </MotionStack>
        )}

        {selectedView === 'requests' && showTable && filteredRequests.length && (
          <>
            <Flex align={'center'} gap={2}>
              <Text size={3} weight="bold">
                {resourceType === 'project' ? 'Project access' : 'Organization access'}
              </Text>

              <Badge>{numPendingAccessRequests}</Badge>
            </Flex>

            <Text size={1} muted>
              {`The following members have requested access to this ${resourceType === 'project' ? 'project' : 'organization or its projects'}.`}
            </Text>

            <MotionStack
              animate="animate"
              exit="exit"
              initial="initial"
              key="requests"
              transition={ANIMATION_TRANSITION}
              variants={ANIMATION_VARIANTS}
            >
              <TableOverflowContainer>
                <RequestAccessTable
                  onRequestApprove={onRequestApprove}
                  onRequestRevoke={onRequestRevoke}
                  projectsData={projectsData}
                  requests={filteredRequests.filter((r) => r.type !== 'role')}
                  resourceType={resourceType}
                  roles={roles}
                />
              </TableOverflowContainer>

              {!numPendingAccessRequests && !searchQuery && (
                <EmptyState borderBottom title="No pending access requests" />
              )}
            </MotionStack>

            <Flex align={'center'} gap={2}>
              <Text size={3} weight="bold">
                Role change
              </Text>

              <Badge>{numPendingRoleRequests}</Badge>
            </Flex>

            <Text size={1} muted>
              The following members have requested a role change.
            </Text>

            <MotionStack
              animate="animate"
              exit="exit"
              initial="initial"
              key="requests"
              transition={ANIMATION_TRANSITION}
              variants={ANIMATION_VARIANTS}
            >
              <TableOverflowContainer>
                <RequestRoleChangeTable
                  onRequestApprove={onRequestApprove}
                  onRequestRevoke={onRequestRevoke}
                  projectsData={projectsData}
                  requests={filteredRequests.filter((r) => r.type === 'role')}
                  resourceType={resourceType}
                  roles={roles}
                />
              </TableOverflowContainer>

              {!numPendingRoleRequests && !searchQuery && (
                <EmptyState borderBottom title="No pending role requests" />
              )}
            </MotionStack>
          </>
        )}
      </ConditionalAnimatePresence>
    </Stack>
  )
})
