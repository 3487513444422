import {Checkbox} from '@sanity/ui'
import React from 'react'
import {TableCell} from '.'

type Props = {
  checked?: boolean
  onChange: (checked: boolean) => void
  label: string
}

export function TableCellCheckbox({checked, label, onChange}: Props) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked)
  }
  return (
    <TableCell>
      <Checkbox label={label} checked={checked} onChange={handleChange} />
    </TableCell>
  )
}
