import type {PortableTextMarkComponentProps} from '@portabletext/react'
import Link from 'next/link'
import styled from 'styled-components'
import {useDynamicString} from '../../utils/useDynamicString'

interface Props {
  _type: 'link'
  href: string
  openInNewWindow?: boolean
}

const StyledLink = styled(Link)`
  color: var(--color-primary) !important;
  text-decoration: underline !important;
  text-underline-offset: 0.2em;
  transition: color 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    text-decoration-color: var(--card-avatar-cyan-bg-color) !important;
  }
`

export function MarkLink(props: PortableTextMarkComponentProps<Props>) {
  const {children, value} = props
  const {href, openInNewWindow} = value || {}
  const dynamicHref = useDynamicString(href || '')

  if (href) {
    return (
      <StyledLink
        href={dynamicHref}
        passHref={true}
        prefetch={false}
        target={openInNewWindow ? '_blank' : undefined}
      >
        {children}
      </StyledLink>
    )
  }

  return <span>{children}</span>
}
