import React from 'react'
import {Text} from '@sanity/ui'
import {TableRow, TableCell, EmptyBlock, TableBody} from '@/ui/index'

export const TableError = () => {
  return (
    <TableBody>
      <TableRow>
        <TableCell style={{gridColumn: '1/-1'}} $align="center">
          <EmptyBlock
            title="Something went wrong"
            description={
              <Text muted size={1}>
                Try reloading this page. If that doesn't help, you can ask for help in our
                <a
                  href="https://slack.sanity.io/"
                  target="_blank"
                  rel="noreferrer"
                  style={{color: 'var(--card-link-color)'}}
                >
                  slack community
                </a>
              </Text>
            }
            illustration="error"
          />
        </TableCell>
      </TableRow>
    </TableBody>
  )
}
