import React from 'react'
import {Flex} from '@sanity/ui'
import {
  DeleteProjectDialog,
  TabSectionHeader,
  PermissionButtonProject,
  useConfirmDialog,
} from '@/ui/index'
import {Project} from '@/types/index'

export function DeleteProject({
  title,
  description,
  project,
}: {
  title: string
  description: string
  project: Project
}) {
  const {showDialog, Dialog: DeleteDialog, hideDialog} = useConfirmDialog()

  return (
    <>
      <TabSectionHeader title={title} description={description} />
      <Flex>
        <PermissionButtonProject
          title="Delete project"
          tone="critical"
          mode="ghost"
          onClick={showDialog}
          permissions={[{permissionName: 'sanity.project', grantName: 'delete'}]}
        />
      </Flex>
      <DeleteProjectDialog
        key="delete-projects-dialog"
        project={project}
        hideDialog={hideDialog}
        Dialog={DeleteDialog}
      />
    </>
  )
}
