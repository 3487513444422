import React, {useCallback, useEffect} from 'react'
import {Box, Card, Stack, Text, Dialog, Button, useToast} from '@sanity/ui'
import {CopyIcon} from '@sanity/icons'
import {CodeTextArea, Loader} from '@/ui/index'
import {OrganizationSSOProviderSAML} from '@/types/models/organization'
import {useSSOProviderSAMLMetadata} from '@/data/organizations/useSSOProviders'
import {useCopyToClipboard} from '@/utils/index'

type SsoSAMLMetadataDialogProps = {
  samlProvider: OrganizationSSOProviderSAML
  closeSamlMetadata: () => void
}

export default function SsoSAMLMetadataDialog({
  samlProvider,
  closeSamlMetadata,
}: SsoSAMLMetadataDialogProps) {
  const {metadata, error, loading} = useSSOProviderSAMLMetadata(samlProvider)
  const selectAll = useCallback((ev) => {
    const target = ev.target
    target?.focus()
    target?.select()
  }, [])
  const {copyToClipboard, success, error: copyError} = useCopyToClipboard()
  const {push} = useToast()
  const copy = useCallback(() => {
    copyToClipboard(metadata)
  }, [copyToClipboard, metadata])

  useEffect(() => {
    if (success) {
      push({
        title: 'Copied metadata to clipboard',
        status: 'success',
      })
      return
    }

    if (copyError) {
      push({
        title: 'Failed to copy to clipboard',
        status: 'error',
      })
    }
  }, [success, copyError, push])

  return (
    <Dialog
      header="SAML Metadata"
      id="saml-metadata-dialog"
      onClose={closeSamlMetadata}
      zOffset={1000}
      width={2}
    >
      {loading && (
        <Box padding={6}>
          <Loader />
        </Box>
      )}
      {error && (
        <Card padding={4} border tone={'critical'}>
          <Stack space={2}>
            <Text weight="bold">Error</Text>
            <Text>{error.message}</Text>
          </Stack>
        </Card>
      )}
      {metadata && metadata.length && (
        <>
          <Card padding={4}>
            <CodeTextArea defaultValue={metadata} rows={10} onClick={selectAll} readOnly />
            <Box marginTop={4}>
              <Button
                text="Copy metadata"
                icon={CopyIcon}
                mode="ghost"
                style={{width: '100%'}}
                onClick={copy}
              />
            </Box>
          </Card>
        </>
        //
      )}
    </Dialog>
  )
}
