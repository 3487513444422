import {Prompt} from '@/ui/index'
import React from 'react'

export function CardDeclinedWarning() {
  return (
    <>
      <Prompt
        tone="caution"
        icon="warning-outline"
        layout="column"
        title="Card Declined"
        description={
          <span>
            There seems to be an issue with your payment card. Please re-enter the card details or
            replace with another payment card. If the problem persists, check there are sufficient
            funds or contact the card issuer.
          </span>
        }
      />
    </>
  )
}
