import {Observable, from} from 'rxjs'
import {switchMap} from 'rxjs/operators'
import {apiRequest} from '@/data/util/request'
import {
  OrganizationSSOProvider,
  CreateOrganizationSSOProvider,
  SaveOrganizationSSOProvider,
  RoleMapping,
  SetRoleMapping,
} from '@/types/index'

const apiVersion = 'v2022-12-21'

export function createOrganizationSSOProvider(
  organizationId: string,
  data: CreateOrganizationSSOProvider
): Observable<OrganizationSSOProvider> {
  return apiRequest<OrganizationSSOProvider>({
    url: `https://api.${process.env.host}/${apiVersion}/auth/organizations/${organizationId}/providers`,
    method: 'PUT',
    body: data,
  })
}

export function deleteOrganizationSSOProvider(
  organizationId: string,
  providerId: string
): Observable<void> {
  return apiRequest<void>({
    url: `https://api.${process.env.host}/${apiVersion}/auth/organizations/${organizationId}/providers/${providerId}`,
    method: 'DELETE',
  })
}

export function getOrganizationSSOProviders(
  organizationId: string
): Observable<OrganizationSSOProvider[]> {
  return apiRequest<OrganizationSSOProvider[]>({
    url: `https://api.${process.env.host}/${apiVersion}/auth/organizations/${organizationId}/providers`,
  })
}

export function getSSOProviderSAMLMetadata(providerId: string): Observable<string> {
  return from(
    fetch(
      `https://api.${process.env.host}/${apiVersion}/auth/saml/metadata/${providerId}?preview=true`
    )
  ).pipe(
    switchMap((response) => {
      if (response.status !== 200) {
        return response.text().then((msg) => {
          const error = new Error(msg)
          try {
            const parsed = JSON.parse(msg)
            if (parsed.message) {
              error.message = parsed.message
            }
          } catch (e) {
            // ignore
          }
          throw error
        })
      }

      return response.text()
    })
  )
}

export function setOrganizationSSOProvider(
  organizationId: string,
  providerId: string,
  data: SaveOrganizationSSOProvider
): Observable<OrganizationSSOProvider> {
  return apiRequest<OrganizationSSOProvider>({
    url: `https://api.${process.env.host}/${apiVersion}/auth/organizations/${organizationId}/providers/${providerId}`,
    method: 'PUT',
    body: data,
  })
}

export function setSSOProviderProjectRoleMappings(
  organizationId: string,
  providerId: string,
  projectId: string,
  opts: {roleMappings?: SetRoleMapping[]; fallbackRoleName?: string | null}
): Observable<RoleMapping[]> {
  return apiRequest<RoleMapping[]>({
    url: `https://api.${process.env.host}/${apiVersion}/auth/organizations/${organizationId}/providers/${providerId}/projects/${projectId}/role-mappings`,
    method: 'PUT',
    body: opts,
  })
}

export function deleteSSOProviderProjectRoleMappings(
  organizationId: string,
  providerId: string,
  projectId: string
): Observable<void> {
  return apiRequest({
    url: `https://api.${process.env.host}/${apiVersion}/auth/organizations/${organizationId}/providers/${providerId}/projects/${projectId}/role-mappings`,
    method: 'DELETE',
  })
}
