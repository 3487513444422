import {useOrgInvitations} from '@/data/invitations'
import {useOrganizationRolesList} from '@/data/organizations/useOrganizationRoleList'
import {Organization, OrganizationInvitation} from '@/types/index'
import {MenuSelect, MenuSelectOption, TableCell, TableRow} from '@/ui/index'
import {emailPattern} from '@/utils/form'
import {filterEmptyArray} from '@/utils/general'
import {TrashIcon} from '@sanity/icons'
import {Button, TextInput} from '@sanity/ui'
import {debounce} from 'lodash'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {Controller, get, useFormContext} from 'react-hook-form'
import styled from 'styled-components'
import {ErrorTooltip} from './errorTooltip'

type Props = {
  index: number
  email: string
  org: Organization
  role?: string
  id: string
  onDelete?: (id: number) => void
}

const InputWrapper = styled.div`
  margin-right: 8px;
  width: calc(100% - 72px);

  @media (max-width: ${({theme}) => theme.sanity.media[1]}px) {
    width: calc(100% - 32px);
  }
`

export const InvitationRowComponentOrg = ({
  email,
  role: currentRole,
  index,
  org,
  onDelete,
}: Props) => {
  const {data: roles = []} = useOrganizationRolesList(org.id)
  const [emailWarning, setEmailWarning] = useState<string>('')
  const {invitations: pendingInvitations} = useOrgInvitations(org.id)
  const [selectedRole, setSelectedRole] = useState<string | number | undefined>(
    currentRole || roles?.[roles.length - 1]?.name || 'billing-manager'
  )
  const {
    control,
    formState: {errors},
    getValues,
    setValue,
    watch,
  } = useFormContext()
  //const emailError = get(errors, `invitations[${index}].email.message`)
  const firstRoleName = roles?.[0]?.name
  const roleError = get(errors, `invitations[${index}].role.message`)
  const invalidEmailMessage = `Incorrect email address`
  const members = useMemo(() => org?.members || [], [org])
  const handleDelete = () => {
    if (!onDelete) return
    onDelete(index)
  }
  const checkIfEmailIsNotAlreadyAdded = useCallback(
    (matchEmail: string) => {
      const isExistingMember = members.some(
        (member: any) =>
          member.user.email && member.user.email.toLowerCase() === matchEmail.toLowerCase()
      )
      const {invitations = []} = getValues()

      if (isExistingMember) {
        return `${matchEmail} is already a member of this organization`
      }

      return (
        invitations.filter(
          (invite: OrganizationInvitation, matchIndex: number) =>
            matchIndex !== index && invite.email.toLowerCase() === matchEmail.toLowerCase()
        ).length === 0 || 'Email already added'
      )
    },
    [getValues, index, members]
  )

  const roleOptions: MenuSelectOption[] =
    (roles &&
      roles.map((role) => ({
        value: role.name,
        label: role.title,
        description: role.description,
      }))) ||
    []

  const checkInvitations = useCallback(
    (matchEmail: string) => {
      const hasPendingInvitations = pendingInvitations.some(
        (pendingInvitation) =>
          matchEmail.length > 4 &&
          pendingInvitation.email.toLowerCase() === matchEmail.toLowerCase()
      )

      if (hasPendingInvitations) {
        setEmailWarning(`Invitation to ${matchEmail} has already been sent`)
      }

      if (!hasPendingInvitations && emailWarning !== '') {
        setEmailWarning('')
      }
    },
    [emailWarning, pendingInvitations]
  )
  const debouncedCheckInvitations = debounce(checkInvitations, 500)

  const emailError = get(errors, `invitations[${index}].email.message`)
  const emailErrorsAndWarnings = filterEmptyArray([emailError, emailWarning])
  const currentEmail = watch(`invitations[${index}].email`)

  useEffect(() => {
    if ((!currentRole || currentRole.length === 0) && roles && roles.length > 0) {
      setValue(`invitations[${index}].role`, firstRoleName)
    }
  }, [currentRole, firstRoleName, index, roles, setValue])

  useEffect(() => {
    if (currentEmail && currentEmail.length > 5) {
      debouncedCheckInvitations(currentEmail)
    }
  }, [currentEmail, debouncedCheckInvitations])

  return (
    <TableRow paddingY={3} paddingX={2} borderBottom>
      <TableCell>
        <InputWrapper>
          <Controller
            control={control}
            name={`invitations[${index}].email`}
            rules={{
              required: invalidEmailMessage,
              pattern: {
                value: emailPattern,
                message: invalidEmailMessage,
              },
              validate: (value) => checkIfEmailIsNotAlreadyAdded(value),
            }}
            defaultValue={email}
            render={({field}) => <TextInput {...field} inputMode="email" />}
          />
        </InputWrapper>
        {emailErrorsAndWarnings.length > 0 && <ErrorTooltip message={emailErrorsAndWarnings} />}
      </TableCell>
      <TableCell>
        {roles && (
          <Controller
            control={control}
            aria-label="Role"
            defaultValue={selectedRole}
            rules={{required: true}}
            name={`invitations[${index}].role`}
            render={({field}) => {
              const handleChange = (roleName: string | number | undefined) => {
                setSelectedRole(roleName)
                field.onChange(roleName)
              }
              return (
                <MenuSelect
                  options={roleOptions}
                  value={roleOptions.filter((opt) => opt.value === selectedRole)}
                  onChange={handleChange}
                  onBlur={field.onBlur}
                  // eslint-disable-next-line no-console
                />
              )
            }}
          />
        )}
        {roleError && <ErrorTooltip message={roleError} />}
      </TableCell>
      <TableCell $align="right">
        {onDelete && (
          <Button icon={TrashIcon} padding={2} size={2} mode="bleed" onClick={handleDelete} />
        )}
      </TableCell>
    </TableRow>
  )
}
