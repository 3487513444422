import {CustomerData} from '../../types/models'
import {getCustomer, patchCustomer} from './'
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query'
import {useCacheInvalidation} from '@/data/base/useCacheInvalidation'
import {getCustomerKey, STALE_TTL} from '../customers/cache'

/*
  This hook is used to get the customer data for an organization.
  It is used in the checkout form and the payment method component.
  In the context of patching, we can say that the customer === the organization.
*/
export function useCustomerData(organizationId: string) {
  const {invalidateCustomerData} = useCacheInvalidation()
  const queryClient = useQueryClient()

  const query = useQuery({
    queryKey: getCustomerKey(organizationId),
    queryFn: () => getCustomer(organizationId),
    staleTime: STALE_TTL,
  })

  const patchMutation = useMutation({
    mutationKey: ['patchCustomer', organizationId],
    mutationFn: (data: CustomerData) => patchCustomer(organizationId, data),
    onSettled: () => {
      queryClient.invalidateQueries({queryKey: getCustomerKey(organizationId)})
      invalidateCustomerData(organizationId)
    },
  })

  return {
    data: query.data,
    isLoading: query.isLoading,
    error: query.error,
    isPending: patchMutation.isPending,
    patchData: patchMutation.mutate,
    patchDataAsync: patchMutation.mutateAsync,
  }
}
