import React, {useEffect, useState} from 'react'
import {Card, Flex, Stack, Text} from '@sanity/ui'
import styled from 'styled-components'
import {Icon} from '@sanity/icons'
import {findCategoryActions} from '../activityConfig'
import {EventAction, EventCategory} from '@/types/index'
import {useFilter, useSetFilter} from '@/context/index'
import {generateActionsArray} from '@/utils/index'

const Input = styled.input`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
`

const StyledFlex = styled(Flex)`
  position: relative;

  // Hover states
  ${({theme}) => {
    const fg = theme.sanity.color.card.hovered.fg
    const bg = theme.sanity.color.card.hovered.bg
    const radius = theme.sanity.radius[2]

    return `
    
      /* Hover state for parent when parent is hovered */
      &[data-parent='true']:not([data-disabled='true'], [data-checked='true']):hover {
        background: ${bg};
        border-top-left-radius: ${radius}px;
        border-top-right-radius: ${radius}px;

        & * {
          background: ${bg};
        }

        & [data-ui='Text'] {
          color: ${fg};
        }
      }

      /* Hover state for children of parent when parent is hovered */
      &[data-parent='true']:not([data-disabled='true'],[data-checked='true']):hover ~ [data-ui='Stack'] {
        background: ${bg};
        color: ${fg};
        border-bottom-left-radius: ${radius}px;
        border-bottom-right-radius: ${radius}px;

        & * {
          background: ${bg};
          color: ${fg};
        }

        & *:last-child {
          border-bottom-left-radius: ${radius}px;
          border-bottom-right-radius: ${radius}px;
        }
      }

      /* Hover state for child when only child is hovered */
      &[data-parent='false']:not([data-disabled='true'], [data-checked='true']):hover {
        & label {
          background: ${bg};
        }

        & [data-ui='Text'] {
          color: ${fg};
        }
      }
    `
  }}

  & label {
    width: 100%;
  }

  & input:focus + label {
    outline: -webkit-focus-ring-color auto 1px;
    outline-offset: -2px;
  }

  & input:focus:not(:focus-visible) + label {
    outline: 0;
  }
`

const StyledItem = styled.li`
  & * {
    cursor: pointer;
  }
  & [data-tone='default'] [data-ui='Text'] {
    color: ${({theme}) => theme.sanity.color.card.enabled.muted.fg};
  }
`

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string
  parentPrefix?: string
  isParent?: EventCategory
  parentChecked?: boolean
  isMixedCheckbox?: boolean
}

const Checkbox = ({
  label,
  onChange,
  isParent,
  parentPrefix,
  parentChecked,
  name,
  checked,
  isMixedCheckbox,
  ...props
}: CheckboxProps) => {
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e)
    }
  }
  const showIcon = parentChecked ? parentChecked && !checked : checked

  const isCheckedParent = !!(isParent && checked)
  const ariaChecked: 'mixed' | boolean = isMixedCheckbox ? 'mixed' : isCheckedParent

  const parentAttributes = isParent
    ? {
        'aria-controls': isParent.actions
          .map((action) => `${name}.${action.value}-input`)
          .join(' '),
        'aria-checked': ariaChecked,
      }
    : {}

  return (
    <Flex as={StyledFlex} data-parent={!!isParent} data-checked={checked}>
      <Input
        type="checkbox"
        checked={checked}
        onChange={handleOnChange}
        name={name}
        id={`${name}-input`}
        {...props}
        {...parentAttributes}
      />
      <Card
        padding={3}
        paddingLeft={parentPrefix ? 5 : 3}
        as="label"
        radius={2}
        htmlFor={`${name}-input`}
        tone={checked || parentChecked ? 'primary' : 'default'}
        marginY={1}
      >
        <Flex align="center" justify="space-between">
          <Text aria-label={parentPrefix ? `${parentPrefix} ${label}` : label}>{label}</Text>
          {showIcon && (
            <Text>
              <Icon symbol="close" />
            </Text>
          )}
        </Flex>
      </Card>
    </Flex>
  )
}

type Props = {
  events: EventCategory[]
}

export function EventFilter({events = []}: Props) {
  const [selectedParent, setSelectedParentCategory] = useState<string | undefined>()
  const [selectedCategory, setSelectedChildCategory] = useState<string | undefined>()

  const activeFilter = useFilter()
  const setFilter = useSetFilter()

  const handleSelectCategory = (
    e: React.ChangeEvent<HTMLInputElement>,
    action: EventAction,
    prefix?: string
  ) => {
    const {value, checked} = e.target
    setSelectedChildCategory(checked ? value : undefined)
    setSelectedParentCategory(checked ? prefix : undefined)

    // Generate the actions we want to filter by
    const actions = generateActionsArray(action, prefix)
    return setFilter({type: 'action', payload: checked ? actions : undefined})
  }

  const handleSelectParent = (e: React.ChangeEvent<HTMLInputElement>, event: EventCategory) => {
    const {value, checked} = e.target
    setSelectedParentCategory(checked ? value : undefined)
    setSelectedChildCategory(undefined)
    // Generate the actions we want to filter by
    const actions = event.actions.flatMap((action) => generateActionsArray(action, event.prefix))
    setFilter({type: 'action', payload: checked ? actions : undefined})
  }

  useEffect(() => {
    // Check if query action matches all actions a parent has
    const isParent = findCategoryActions('parent', activeFilter?.action)
    // Check if query action matches the actions a category has
    const isChild = findCategoryActions('child', activeFilter?.action)
    if (isChild) {
      // If a category matched, set the correct parent of that category, and the category itself
      setSelectedParentCategory(isChild.parent)
      setSelectedChildCategory(isChild.prefix)
    } else {
      setSelectedParentCategory(undefined)
      setSelectedChildCategory(undefined)
    }
    if (isParent) {
      // If a parent category matched, set the correct parent and leave the category empty
      setSelectedParentCategory(isParent.prefix)
      setSelectedChildCategory(undefined)
    }
  }, [activeFilter])

  return (
    <Stack space={3} role="group">
      <Text weight="semibold" size={1} as="label" htmlFor="event-filter">
        Events
      </Text>
      <Card border padding={1} radius={2} role="group" id="event-filter" as="ul">
        {events?.map((event) => (
          <Card
            as={StyledItem}
            key={event.title}
            role="group"
            tone={event.prefix === selectedParent && !selectedCategory ? 'primary' : 'default'}
            radius={2}
          >
            {!event.hideTitle && (
              <Checkbox
                label={event.title}
                value={event.prefix}
                checked={event.prefix === selectedParent && !selectedCategory}
                name={event.prefix}
                onChange={(inputEvent) => handleSelectParent(inputEvent, event)}
                isParent={event}
                isMixedCheckbox={event.actions.some((action) => action.value === selectedCategory)}
              />
            )}
            {event.actions && (
              <Stack as="ul">
                {event.actions.map((action) => (
                  <Checkbox
                    label={action.title}
                    key={action.title}
                    parentPrefix={event.hideTitle ? undefined : event.prefix}
                    value={action.value}
                    onChange={(inputEvent) =>
                      handleSelectCategory(inputEvent, action, event?.prefix)
                    }
                    name={`${event.prefix}.${action.value}`}
                    checked={
                      (selectedParent === event.prefix && !selectedCategory) ||
                      (action.value === selectedCategory && selectedParent === event.prefix)
                    }
                    parentChecked={selectedParent === event.prefix && !selectedCategory}
                  />
                ))}
              </Stack>
            )}
          </Card>
        ))}
      </Card>
    </Stack>
  )
}
