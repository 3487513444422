import type {Organization} from '../types'

/**
 * Get the path for a organization depending on if it is an organization organization or a personal organization
 * @param organization
 * @returns The href path for the organization
 */
export function getOrgPath(organization: Organization): string {
  if (organization.id === 'personal') {
    return `/manage/personal/projects`
  }
  return `/organizations/${organization.id}`
}
