import {ApiIcon, CheckmarkIcon, WarningOutlineIcon} from '@sanity/icons'
import {Box, Card, Code, Flex, Inline, Label, Stack, Text, Tooltip} from '@sanity/ui'
import React from 'react'
import {getModePermissions} from './util'

export function TableHeader() {
  return (
    <Card tone="inherit" borderBottom paddingX={4}>
      <Flex paddingX={-4} paddingBottom={3}>
        <Box flex={2}>
          <Label muted size={1}>
            Content resource
          </Label>
        </Box>
        <Box flex={2}>
          <Label muted size={1}>
            Permission
          </Label>
        </Box>
      </Flex>
    </Card>
  )
}

export function IndeterminateWarning({grants}: {grants: string[]}) {
  return (
    <Tooltip
      content={
        <Box padding={3} style={{maxWidth: 300}}>
          <Stack space={2}>
            <Text size={1} muted>
              Unable to fully determine permission because they have been modified externally.
              <br />
              The permissions are:
              <br />
            </Text>
            <Inline space={2}>
              {getModePermissions().map((grant) => {
                const isEnabled = grants.includes(grant)
                return (
                  <Card
                    shadow={1}
                    padding={isEnabled ? 1 : 2}
                    tone={isEnabled ? 'positive' : 'critical'}
                    radius={2}
                    key={grant}
                  >
                    <Inline space={1}>
                      {isEnabled && <CheckmarkIcon />}
                      <Code size={1} muted>
                        {grant}
                      </Code>
                    </Inline>
                  </Card>
                )
              })}
            </Inline>
          </Stack>
        </Box>
      }
    >
      <Card tone="caution" radius={2} padding={2}>
        <Text>
          <WarningOutlineIcon />
        </Text>
      </Card>
    </Tooltip>
  )
}

export function ExtraWarning({grants}: {grants: string[]}) {
  return (
    <Tooltip
      content={
        <Box padding={3} style={{maxWidth: 300}}>
          <Stack space={2}>
            <Text size={1} muted>
              Additional permissions have been modified externally.
              <br />
              The permission(s) are:
              <br />
            </Text>
            <Inline space={2}>
              {grants.map((grant) => {
                return (
                  <Card shadow={1} padding={2} tone="transparent" radius={2} key={grant}>
                    <Inline space={1}>
                      <Code size={1} muted>
                        {grant}
                      </Code>
                    </Inline>
                  </Card>
                )
              })}
            </Inline>
          </Stack>
        </Box>
      }
    >
      <Card tone="transparent" radius={2} padding={2}>
        <Text>
          <ApiIcon />
        </Text>
      </Card>
    </Tooltip>
  )
}
