import {Flex, Text, Button} from '@sanity/ui'
import {InfoOutlineIcon} from '@sanity/icons'

interface Props {
  text: string
  onCancel: () => void
}

export function DataOverrideWarning(props: Props) {
  return (
    <Flex align="center" gap={1} style={{marginTop: '-0.5rem'}}>
      <InfoOutlineIcon fontSize={21} />
      <Text muted size={1} style={{flex: 1}}>
        {props.text}
      </Text>
      <Button mode="ghost" text="Cancel" size={1} padding={2} onClick={props.onCancel} />
    </Flex>
  )
}
