import {CheckmarkIcon, SearchIcon} from '@sanity/icons'
import {Box, Card, Flex, Stack, Text, TextInput} from '@sanity/ui'
import {type ChangeEvent, useCallback, useMemo, useRef, useState} from 'react'
import styled from 'styled-components'

import {type MembersV2} from '@/types/members_v2'

import {
  CommandListMenuButton,
  type CommandListMenuButtonProps,
} from '../../generic/command-list-menu-button'
import {type PopoverButtonHandle} from '../../generic/popover-button'
import {Button} from '../../primitives/button/Button'
import {filterByQuery} from '../../utils'

const HEADER_HEIGHT: number = 49
const FOOTER_HEIGHT: number = 49
const ITEM_HEIGHT: number = 49
const MAX_ITEMS_DISPLAYED: number = 5
const LIST_PADDING: number = 10

const MAX_HEIGHT: number =
  ITEM_HEIGHT * MAX_ITEMS_DISPLAYED + LIST_PADDING + HEADER_HEIGHT + FOOTER_HEIGHT

const SelectedBox = styled(Box)`
  visibility: hidden;

  &[data-selected='true'] {
    visibility: visible;
  }
`

interface ProjectSelectMenuItemProps {
  item: MembersV2['project']
  selected: boolean
  onProjectSelect: (project: MembersV2['project']) => void
}

export function ProjectSelectMenuItem(props: ProjectSelectMenuItemProps) {
  const {item, selected, onProjectSelect, ...rest} = props

  return (
    <Card
      aria-selected={selected}
      as="button"
      marginBottom={2}
      onClick={() => onProjectSelect(item)}
      padding={3}
      radius={2}
      sizing="border"
      tone={selected ? 'primary' : undefined}
      {...rest}
    >
      <Flex align="center" gap={2} paddingY={1} sizing="border">
        <Stack space={3} flex={1}>
          <Text weight="medium" size={1} textOverflow="ellipsis">
            {item.displayName}
          </Text>
        </Stack>

        <SelectedBox data-selected={selected}>
          <Box>
            <Text size={1}>
              <CheckmarkIcon />
            </Text>
          </Box>
        </SelectedBox>
      </Flex>
    </Card>
  )
}

interface ProjectSelectMenuButtonProps {
  closeButtonText?: string
  closeOnSelect?: boolean
  multiSelect?: boolean
  onCloseButtonClick?: () => void
  onSelect?: (project: MembersV2['project']) => void
  options: MembersV2['project'][]
  popoverButtonProps: CommandListMenuButtonProps['popoverButtonProps']
  selectedProjects: MembersV2['project'][]
}

export function ProjectSelectMenuButton(props: ProjectSelectMenuButtonProps) {
  const {
    closeButtonText = 'Close',
    closeOnSelect = false,
    multiSelect = false,
    onCloseButtonClick,
    onSelect,
    options,
    popoverButtonProps: popoverButtonPropsProp,
    selectedProjects,
  } = props

  const popoverButtonRef = useRef<PopoverButtonHandle | null>(null)
  const [inputElement, setInputElement] = useState<HTMLInputElement | null>(null)
  const [query, setQuery] = useState<string | null>(null)

  const handleSearchChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value)
  }, [])

  const handleSelectProject = useCallback(
    (project: MembersV2['project']) => {
      onSelect?.(project)

      if (closeOnSelect && !multiSelect) {
        popoverButtonRef.current?.onClose()
        popoverButtonRef.current?.focusButton()
      }
    },
    [closeOnSelect, multiSelect, onSelect]
  )

  const handleCloseButtonClick = useCallback(() => {
    onCloseButtonClick?.()
    popoverButtonRef.current?.onClose()
    popoverButtonRef.current?.focusButton()
  }, [onCloseButtonClick])

  const renderItem = useCallback(
    (item: MembersV2['project']) => {
      const showSelected = selectedProjects.some(
        (selectedProject) => selectedProject.id === item.id
      )

      return (
        <ProjectSelectMenuItem
          item={item}
          onProjectSelect={handleSelectProject}
          selected={showSelected}
        />
      )
    },
    [handleSelectProject, selectedProjects]
  )

  const getItemSelected = useCallback(
    (index: number) => {
      return selectedProjects.some((selectedProject) => selectedProject.id === options[index].id)
    },
    [options, selectedProjects]
  )

  const filteredItems = useMemo(() => {
    if (!query) return options

    return filterByQuery(options, query, [['displayName']])
  }, [options, query])

  const footer = multiSelect ? (
    <Stack space={2} padding={2} sizing="border">
      <Button fontSize={1} onClick={handleCloseButtonClick} text={closeButtonText} width="fill" />
    </Stack>
  ) : null

  const header = (
    <Stack space={2} padding={2} sizing="border">
      <TextInput
        fontSize={1}
        icon={SearchIcon}
        onChange={handleSearchChange}
        placeholder="Search projects by name"
        ref={setInputElement}
      />
    </Stack>
  )

  const emptyNode = (
    <Card padding={5} radius={2} sizing="border">
      <Text align="center" muted size={1}>
        No projects found
      </Text>
    </Card>
  )

  const popoverButtonProps = useMemo(
    (): CommandListMenuButtonProps['popoverButtonProps'] => ({
      minWidth: 320,
      maxHeight: MAX_HEIGHT,
      ...popoverButtonPropsProp,
    }),
    [popoverButtonPropsProp]
  )

  return (
    <CommandListMenuButton
      ariaLabel={multiSelect ? 'Select projects' : 'Select project'}
      ariaMultiselectable={multiSelect}
      autoFocus="input"
      emptyNode={emptyNode}
      footer={multiSelect ? footer : undefined}
      getItemSelected={getItemSelected}
      header={header}
      id="project-select-menu"
      inputElement={inputElement}
      itemHeight={ITEM_HEIGHT}
      items={filteredItems}
      onlyShowSelectionWhenActive
      padding={2}
      paddingBottom={0}
      popoverButtonProps={popoverButtonProps}
      ref={popoverButtonRef}
      renderItem={renderItem}
    />
  )
}
