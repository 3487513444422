// List of common personal email domains
const PERSONAL_EMAIL_DOMAINS = [
  'gmail.com',
  'yahoo.com',
  'hotmail.com',
  'icloud.com',
  'aol.com',
  'hotmail.co.uk',
  'hotmail.fr',
  'protonmail.com',
  'msn.com',
  'yahoo.fr',
  'wanadoo.fr',
  'orange.fr',
  'comcast.net',
  'yahoo.co.uk',
  'yahoo.com.br',
  'yahoo.co.in',
  'live.com',
  'rediffmail.com',
  'free.fr',
  'gmx.de',
  'web.de',
  'yandex.ru',
  'ymail.com',
  'libero.it',
  'outlook.com',
  'uol.com.br',
  'bol.com.br',
  'mail.ru',
  'cox.net',
  'hotmail.it',
  'sbcglobal.net',
  'sfr.fr',
  'live.fr',
  'verizon.net',
  'live.co.uk',
  'zoho.com',
  'googlemail.com',
  'yahoo.es',
  'ig.com.br',
  'live.nl',
  'bigpond.com',
  'terra.com.br',
  'yahoo.it',
  'neuf.fr',
  'yahoo.de',
  'alice.it',
  'rocketmail.com',
  'att.net',
  'laposte.net',
  'facebook.com',
  'bellsouth.net',
  'yahoo.in',
  'hotmail.es',
]

/**
 * Extracts the domain from an email address
 * @param email The email address
 * @returns The domain part of the email
 */
export function getEmailDomain(email: string): string {
  try {
    const url = new URL(`http://${email.split('@')[1]}`)
    return url.hostname.toLocaleLowerCase()
  } catch (error) {
    return ''
  }
}

/**
 * Checks if the given email is a personal email address
 * @param email The email address to check
 * @returns True if the email is a personal email, false otherwise
 */
export async function isPersonalEmail(email: string): Promise<boolean> {
  const domain = getEmailDomain(email).toLowerCase()

  // Check against the predefined list
  if (PERSONAL_EMAIL_DOMAINS.includes(domain)) {
    return true
  }

  // Load the comprehensive list dynamically
  const freeEmailDomains = (await import('free-email-domains')).default

  // Check against the comprehensive list from free-email-domains
  return freeEmailDomains.includes(domain)
}
