/* eslint-disable react/no-unused-prop-types */
import React, {useMemo, useState} from 'react'
import {Box, Stack, Grid, Button, Flex, Text} from '@sanity/ui'
import {EllipsisHorizontalIcon} from '@sanity/icons'
import styled from 'styled-components'
import {uid} from 'react-uid'
import {formatUnitType} from '@/utils/usage'

export type ChartSummaryItem = {
  id: string
  value: number
  label: string
  unit?: string
  color?: string
  sortOrder?: number
  isActive?: boolean
  showValue?: boolean
  highlightColor?: string
  valueFormat?: 'number' | 'currency'
  currency?: string
  onHover?: (id: string | undefined) => void
}

const ProjectDot = styled(Box)`
  background-color: ${({color}) => color};
  border-radius: 100%;
  width: 0.5rem;
  height: 0.5rem;
  flex-shrink: 0;
`

const SummaryItem = ({
  id,
  value,
  label,
  color,
  highlightColor,
  unit,
  isActive = true,
  showValue = true,
  onHover,
}: ChartSummaryItem) => {
  const _onHover = (_id: string | undefined) => {
    if (onHover) {
      onHover(_id)
    }
  }

  return (
    <Flex
      align="center"
      onMouseEnter={() => _onHover(id)}
      onMouseLeave={() => _onHover(undefined)}
      style={{opacity: isActive ? 1 : 0.6}}
    >
      <ProjectDot color={color} />
      <Stack space={2} marginLeft={2}>
        <Text size={2} textOverflow="ellipsis">
          {label}
        </Text>
        {showValue && (
          <Text size={1} color={highlightColor} muted>
            {formatUnitType(value, unit)}
          </Text>
        )}
      </Stack>
    </Flex>
  )
}

export function ChartSummaryList({
  items = [],
  loading = false,
  showValue = true,
  valueFormat = 'number',
  currency = 'USD',
  showZeroValues = false,
  onHover,
}: {
  items: ChartSummaryItem[]
  valueFormat?: 'number' | 'currency'
  currency?: string
  loading?: boolean
  showValue?: boolean
  showZeroValues?: boolean
  onHover?: (id: string | undefined, summaryItem: ChartSummaryItem) => void
}) {
  const [hoveredId, setHoveredId] = useState<undefined | string>()
  const [expandedSummaryList, setExpandedSummaryList] = useState<boolean>(false)
  const sortedItems = useMemo(() => {
    return items
      .sort((a: ChartSummaryItem, b: ChartSummaryItem) => {
        if (typeof a.sortOrder !== 'undefined' && typeof b.sortOrder !== 'undefined') {
          return a?.sortOrder > b?.sortOrder ? -1 : 1
        }
        return a?.value > b?.value ? -1 : 1
      })
      .filter((item) => (showZeroValues ? true : item.value > 0))
  }, [items])

  const sortedItemsLength = (sortedItems || []).length
  const fourInTotal = sortedItemsLength === 4
  const limit = fourInTotal ? 4 : 3
  const showExpandButton = sortedItemsLength > limit && !fourInTotal
  const maxToShow = expandedSummaryList ? sortedItemsLength : limit
  const filteredSummaryList: ChartSummaryItem[] = sortedItems?.slice(0, maxToShow)

  const handleExpand = () => setExpandedSummaryList(!expandedSummaryList)

  const handleHover = (id: string | undefined, summaryItem: ChartSummaryItem) => {
    setHoveredId(id)
    if (onHover) onHover(id, summaryItem)
  }

  const isActive = (checkId: string) => {
    if (!hoveredId) {
      return true
    }

    return hoveredId === checkId
  }

  return (
    <Box paddingRight={5} style={{opacity: loading ? 0 : 1}}>
      {filteredSummaryList && (
        <Grid gapX={2} gapY={4} columns={filteredSummaryList.length > 1 ? [2, 2, 4] : 1}>
          {filteredSummaryList.map((summary) => (
            <SummaryItem
              key={uid(summary)}
              {...summary}
              onHover={(id: string | undefined) => handleHover(id, summary)}
              isActive={isActive(summary.id)}
              valueFormat={valueFormat}
              currency={currency}
              showValue={showValue}
            />
          ))}
          {showExpandButton && (
            <Box marginTop={0}>
              <Button
                icon={EllipsisHorizontalIcon}
                mode="bleed"
                padding={2}
                size={1}
                fontSize={1}
                onClick={handleExpand}
              />
            </Box>
          )}
        </Grid>
      )}
    </Box>
  )
}
