import {useState} from 'react'
import {Card, CardProps} from '@sanity/ui'
import styled from 'styled-components'
import {nameToInitials} from '../members/utils'
import {Project} from '@/types/index'
import {useMatchesMedia} from '@/utils/index'

const sizes = [20, 30, 50, 80]
const mobileSizes = [20, 30, 45, 50]
const disallowedFaviconHosts = ['.sanity.studio', '.studio.sanity.work']
const minFaviconSize = 64

interface Props {
  project?: Project
  size?: 0 | 1 | 2 | 3
}

interface WrapperProps {
  $mobile?: boolean
  $color?: string
  $size?: 0 | 1 | 2 | 3
}

const getExternalHost = (project: Project): string | null => {
  const studioHost = project?.metadata?.externalStudioHost
  if (!studioHost) {
    return null
  }
  const hostname = new URL(studioHost).hostname
  // check if hostname ends with any of the disallowed strings
  if (disallowedFaviconHosts.some((v) => hostname.endsWith(v))) {
    return null
  }
  return hostname
}

const getHashedColor = (str: string): string => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  return `hsl(${hash % 360}, 65%, 55%)`
}

const Wrapper = styled(Card)<WrapperProps>`
  display: grid !important;
  overflow: hidden;
  place-items: center;
  height: 100%;
  aspect-ratio: 1 / 1;
  background: ${(props) => props.$color || props.theme.sanity.color.base.bg};
  width: ${({$mobile = false, $size = 2}: {$mobile?: boolean; $size?: number}) =>
    `${$mobile ? mobileSizes[$size] : sizes[$size]}px`};
  height: ${({$mobile = false, $size = 2}: {$mobile?: boolean; $size?: number}) =>
    `${$mobile ? mobileSizes[$size] : sizes[$size]}px`};
  font-size: ${({$mobile = false, $size = 2}: {$mobile?: boolean; $size?: number}) =>
    `${$mobile ? mobileSizes[$size] / 2 : sizes[$size] / 2}px`};
`

const Initials = styled.span`
  display: inline-block;
  line-height: 1;
  font-weight: 600;
  text-transform: uppercase;
  color: black;
  opacity: 0.8;
  user-select: none;
`

const Img = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`

export const ProjectIcon = ({project, size}: Props) => {
  const isMobile = useMatchesMedia(1)
  const [forceFallback, setForceFallback] = useState(false)

  if (!project) {
    return null
  }

  const wrapperProps: WrapperProps & CardProps = {
    $mobile: isMobile,
    radius: 2,
    $size: size,
  }

  const host = getExternalHost(project)
  const fallbackColor = getHashedColor(project.id)

  const handleImageLoad = (event: React.SyntheticEvent<HTMLImageElement>) => {
    const {naturalWidth, naturalHeight} = event.target as HTMLImageElement
    if (naturalWidth < minFaviconSize || naturalHeight < minFaviconSize) {
      setForceFallback(true)
    }
  }

  if (host && forceFallback === false) {
    return (
      <Wrapper {...wrapperProps}>
        <Img src={`/_favicon/${host}`} alt={project.displayName} onLoad={handleImageLoad} />
      </Wrapper>
    )
  }

  return (
    <Wrapper {...wrapperProps} $color={project.metadata?.color || fallbackColor}>
      <Initials>{nameToInitials(project.displayName, true)}</Initials>
    </Wrapper>
  )
}
