import {Card, Flex} from '@sanity/ui'
import React from 'react'

export const TableActions = ({active, children}: {active?: boolean; children: React.ReactNode}) => {
  return (
    <Card tone={active ? 'primary' : 'transparent'} radius={2} paddingX={2} paddingY={2}>
      <Flex align="center" justify="space-between">
        {children}
      </Flex>
    </Card>
  )
}
