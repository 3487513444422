/* eslint-disable react/jsx-no-target-blank */
import {Card, Text} from '@sanity/ui'
import {Icon as UIIcon} from '@sanity/icons'
import React from 'react'
import styled from 'styled-components'

const Anchor = styled.a`
  display: inline;
  color: ${({theme}) => theme.sanity.color.card.enabled.link.fg};
  text-decoration: none;
  align-items: center;
  vertical-align: baseline;

  @media (hover: hover) {
    &:hover {
      text-decoration: underline;
    }
  }
`

const Icon = styled(UIIcon)`
  color: ${({theme}) => theme.sanity.color.card.enabled.link.fg};
`

export function ExternalLink({
  url,
  text,
  size = 1,
  showLinkIcon = true,
}: {
  url: string
  text: string
  size?: number
  showLinkIcon?: boolean
}) {
  return (
    <Anchor href={url} target="_blank" rel="noreferrer">
      {text}
      {showLinkIcon && (
        <Card
          tone="primary"
          padding={1}
          radius={2}
          as="span"
          style={{display: 'inline-block', verticalAlign: 'middle'}}
          marginLeft={1}
        >
          <Text size={size} as="span">
            <Icon symbol="link" />
          </Text>
        </Card>
      )}
    </Anchor>
  )
}
