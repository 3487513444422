import {useState, useEffect, useCallback} from 'react'
import {getStudioHostAvailability, validateStudioHost} from './controller'
import {slugify, randomKey} from '@/utils/general'
import {debounce} from 'lodash'

interface Props {
  value?: string
  potentialSuffix?: string
  forceUpdate?: unknown // any primitive value will trigger a force update
}

interface Result {
  /**
   * The availability status of the value
   */
  available: boolean | null
  /**
   * An array of available suggestions based on the value
   */
  availableSuggestions: string[]
  /**
   * Any error encountered during validation or API calls
   */
  error: string | null
  /**
   * Whether the API call is in progress
   */
  loading: boolean
}

const ATTEMPT_LIMIT = 3

/**
 * React hook to validate and check the availability of hostnames, suggest alternatives, and debounce API calls.
 * It supports initial suggestions based on a provided value, suffix appending for alternatives, and direct value checks.
 * Returns the availability status, an initial suggestion, and any error encountered during validation or API calls.
 */
export function useAvailableStudioHost({value, potentialSuffix, forceUpdate}: Props): Result {
  const [available, setAvailable] = useState<boolean | null>(null)
  const [availableSuggestions, setAvailableSuggestions] = useState<string[]>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const fetchAvailability = async (name: string): Promise<boolean | null> => {
    const abortController = new AbortController()
    const signal = abortController.signal

    try {
      if (!validateStudioHost(name)) {
        throw new Error('Invalid hostname. No space or special characters allowed.')
      }
      return getStudioHostAvailability(slugify(name), {signal})
    } catch (error) {
      if (!signal.aborted) {
        setError((error as Error).message)
        setAvailable(null)
      }
      return null
    } finally {
      abortController.abort()
    }
  }

  const getAvailableSuggestions = useCallback(
    async (name: string, potentialSuffix: string | null = null, attempt = 0) => {
      const slug = slugify(name)
      const available = await fetchAvailability(slug)

      if (available) {
        setAvailableSuggestions([slug])
        return
      }

      if (attempt < ATTEMPT_LIMIT) {
        const newSlug =
          attempt === 0 ? `${slug}-${potentialSuffix || randomKey(4)}` : `${slug}${attempt}`
        getAvailableSuggestions(newSlug, potentialSuffix, attempt + 1)
      }
    },
    []
  )

  // Do both fetch availability and get available suggestions in one call, set promise all and set loading false when both are done
  const debouncedCheck = useCallback(
    debounce((value: string, potentialSuffix?: string) => {
      setLoading(true)
      Promise.all([fetchAvailability(value), getAvailableSuggestions(value, potentialSuffix)]).then(
        ([available]) => {
          setAvailable(available)
          setLoading(false)
        }
      )
    }, 150),
    []
  )

  useEffect(() => {
    /* Reset state when value changes */
    setError(null)
    setAvailable(null)
    setAvailableSuggestions([])

    if (!value) return
    debouncedCheck(value, potentialSuffix)
  }, [debouncedCheck, value, potentialSuffix, forceUpdate])

  return {
    available,
    availableSuggestions,
    error,
    loading,
  }
}
