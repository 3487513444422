import {
  ProjectInvitation,
  OrganizationInvitation,
  ProjectInvitationData,
  OrganizationInvitationData,
} from '@/types/models'
import {promiseRequest} from '../util/promiseRequest'

const API_VERSION = 'v2021-06-07'

export function getProjectInvitations(projectId: string) {
  return promiseRequest<ProjectInvitation[]>(
    `https://api.${process.env.host}/${API_VERSION}/invitations/project/${projectId}`
  )
}

export function getOrgInvitations(organizationId: string) {
  return promiseRequest<OrganizationInvitation[]>(
    `https://api.${process.env.host}/${API_VERSION}/invitations/organization/${organizationId}`
  )
}

export async function getInvitation(
  invitationToken: string
): Promise<ProjectInvitation | OrganizationInvitation> {
  return promiseRequest<ProjectInvitation | OrganizationInvitation>(
    `https://api.${process.env.host}/${API_VERSION}/invitations/${invitationToken}`
  )
}

export function postProjectInvitation(projectId: string, invitation: ProjectInvitationData) {
  return promiseRequest<ProjectInvitation>(
    `https://api.${process.env.host}/${API_VERSION}/invitations/project/${projectId}`,
    {
      method: 'POST',
      json: invitation,
    }
  )
}

export function postOrgInvitation(orgId: string, invitation: OrganizationInvitationData) {
  return promiseRequest<OrganizationInvitation>(
    `https://api.${process.env.host}/${API_VERSION}/invitations/organization/${orgId}`,
    {
      method: 'POST',
      json: invitation,
    }
  )
}

export function revokeProjectInvitation(projectId: string, invitation: ProjectInvitation) {
  return promiseRequest<ProjectInvitation>(
    `https://api.${process.env.host}/${API_VERSION}/invitations/project/${projectId}/${invitation.id}`,
    {
      method: 'DELETE',
      json: invitation,
    }
  )
}

export function revokeOrgInvitation(orgId: string, invitation: OrganizationInvitation) {
  return promiseRequest<OrganizationInvitation>(
    `https://api.${process.env.host}/${API_VERSION}/invitations/organization/${orgId}/${invitation.id}`,
    {
      method: 'DELETE',
      json: invitation,
    }
  )
}

export async function acceptInvitation(invitationToken: string, version?: string) {
  return promiseRequest<string>(
    `https://api.${process.env.host}/${version || API_VERSION}/invitations/claim/${invitationToken}`,
    {
      method: 'POST',
      json: {},
    }
  )
}
