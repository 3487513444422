import React, {createContext, useContext, ReactNode} from 'react'

interface LoadingContextType {
  loadingState: 'loading' | 'loaded' | 'error'
  error?: Error
}

interface LoadingStateProviderProps {
  children: ReactNode
  loadingState: 'loading' | 'loaded' | 'error'
  error?: Error
}

const LoadingContext = createContext<LoadingContextType | undefined>(undefined)

export function LoadingStateProvider({
  children,
  loadingState = 'loading',
  error,
}: LoadingStateProviderProps) {
  return <LoadingContext.Provider value={{loadingState, error}}>{children}</LoadingContext.Provider>
}

export function useLoadingState() {
  const context = useContext(LoadingContext)
  if (context === undefined) {
    throw new Error('useLoadingState must be used within a LoadingProvider')
  }
  return context.loadingState
}
