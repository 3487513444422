import {
  Button,
  MenuButton,
  Menu,
  MenuItem,
  ButtonProps,
  MenuDivider,
  Stack,
  ButtonTone,
} from '@sanity/ui'
import React from 'react'
import {EllipsisVerticalIcon, Icon} from '@sanity/icons'
import {useRouter} from 'next/router'
import {PermissionTooltip} from '../general'
import {AppLink} from '@/ui/index'
import {TableRowAction} from '@/types/index'
import {useMatchesMedia} from '@/utils/index'
import {useRequiredOrganizationPermissions, useRequiredProjectPermissions} from '@/context/index'

type Props = {
  actions: TableRowAction[] | undefined
  label: string
  rowItem: any
  menuId: string
  buttonProps?: ButtonProps
  disabled?: boolean
  roleScope: 'project' | 'org'
}

interface MenuItemProps {
  roleScope: 'project' | 'org'
  permissions?: {permissionName: string; grantName: string}[]
  tone?: ButtonTone
  onClick: () => void
  icon?: typeof Icon
  text: string
  href?: string
}

const Item = ({roleScope, permissions = [], href, ...rest}: MenuItemProps) => {
  const isMobile = useMatchesMedia(1)
  const permission = {
    project: useRequiredProjectPermissions(permissions),
    org: useRequiredOrganizationPermissions(permissions),
  }
  const hasPermission = permission[roleScope]
  const PermissionButton = !hasPermission || isMobile ? Button : MenuItem
  const hasPermissionMode = hasPermission ? 'ghost' : 'bleed'
  return (
    <PermissionTooltip disabled={hasPermission}>
      <AppLink href={href} disabled={!href}>
        <PermissionButton
          {...rest}
          mode={isMobile ? 'ghost' : hasPermissionMode}
          padding={4}
          disabled={!hasPermission}
          justify="flex-start"
          style={{
            width: '100%',
            cursor: hasPermission ? 'default' : 'not-allowed',
            textAlign: 'left',
          }}
        />
      </AppLink>
    </PermissionTooltip>
  )
}

export const MobileRowMenu = ({
  rowItem,
  roleScope,
  actions,
}: {
  rowItem: any
  roleScope: 'org' | 'project'
  actions: TableRowAction[] | undefined
  disabled?: boolean
}) => {
  const {asPath} = useRouter()
  return (
    <Stack space={3} flex={1}>
      {(actions || []).map((action, i) => {
        if (action === 'divider') {
          // eslint-disable-next-line react/no-array-index-key
          return <MenuDivider key={`${action}-${i}`} />
        }
        const {title, onAction, tone, mode, icon, permissions, href} = action
        const handleOnClick = () => {
          if (onAction) {
            onAction(rowItem)
          }
        }
        const hrefPath = href?.rowId ? `/${rowItem.id}` : ''
        const suffixPath = href?.path ? `/${href?.path}` : ''
        const buttonProps = {text: title, onClick: handleOnClick, tone, mode, icon}
        return (
          <Item
            key={action.title}
            roleScope={roleScope}
            permissions={permissions}
            href={href ? `${href?.basePath || asPath}${hrefPath}${suffixPath}` : undefined}
            {...buttonProps}
          />
        )
      })}
    </Stack>
  )
}

export function TableRowMenuButton({
  label,
  menuId,
  actions = [],
  rowItem,
  roleScope,
  disabled,
}: Props) {
  const {asPath} = useRouter()
  return (
    <MenuButton
      button={<Button disabled={disabled} mode="bleed" icon={EllipsisVerticalIcon} label={label} />}
      placement="bottom-end"
      id={menuId}
      menu={
        <Menu>
          {actions.map((action, i) => {
            if (action === 'divider') {
              // eslint-disable-next-line react/no-array-index-key
              return <MenuDivider key={`${action}-${i}`} />
            }
            const {title, onAction, tone, mode, icon, permissions, href} = action
            const handleOnClick = () => {
              if (onAction) {
                onAction(rowItem)
              }
            }
            const hrefPath = href?.rowId ? `/${rowItem.id}` : ''
            const suffixPath = href?.path ? `/${href?.path}` : ''
            const buttonProps = {text: title, onClick: handleOnClick, tone, mode, icon}
            return (
              <Item
                key={action.title}
                roleScope={roleScope}
                permissions={permissions}
                href={href ? `${href?.basePath || asPath}${hrefPath}${suffixPath}` : undefined}
                {...buttonProps}
              />
            )
          })}
        </Menu>
      }
    />
  )
}
