import React from 'react'

export const Visa = () => {
  return (
    <svg width="51" height="17" viewBox="0 0 51 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M22.168 16.656h-4.05L20.65 1.168h4.05l-2.534 15.488zM36.85 1.547a10.08 10.08 0 00-3.634-.66c-4 0-6.816 2.11-6.834 5.124-.033 2.225 2.017 3.46 3.55 4.202 1.567.758 2.1 1.253 2.1 1.929-.016 1.037-1.266 1.515-2.432 1.515-1.617 0-2.484-.246-3.8-.823l-.534-.248-.567 3.477c.95.428 2.7.808 4.517.824 4.25 0 7.017-2.076 7.05-5.29.016-1.762-1.066-3.113-3.4-4.217-1.416-.709-2.284-1.187-2.284-1.912.017-.66.734-1.334 2.333-1.334a6.949 6.949 0 013.017.593l.366.164.551-3.344zM42.233 11.17c.334-.89 1.617-4.334 1.617-4.334-.016.033.333-.906.533-1.483l.283 1.335s.767 3.707.934 4.481h-3.367zm5-10.002H44.1c-.966 0-1.7.28-2.117 1.285l-6.016 14.203h4.25l.85-2.323h5.2c.116.544.483 2.323.483 2.323h3.75L47.234 1.168zM14.734 1.168L10.768 11.73l-.434-2.142c-.733-2.472-3.033-5.157-5.6-6.493L8.368 16.64h4.283l6.366-15.472h-4.283z"
          fill="#00579F"
        />
        <path
          d="M7.083 1.168H.567L.5 1.481c5.083 1.285 8.45 4.383 9.833 8.107L8.917 2.47c-.234-.99-.95-1.27-1.834-1.302z"
          fill="#FAA61A"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" transform="translate(.5 .887)" d="M0 0h50v16H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
