import {type Role} from '@sanity/access-api'
import {CheckmarkIcon, SearchIcon} from '@sanity/icons'
import {Box, Card, Flex, Stack, Text, TextInput} from '@sanity/ui'
import {type ChangeEvent, useCallback, useMemo, useRef, useState} from 'react'
import styled from 'styled-components'

import {
  CommandListMenuButton,
  type CommandListMenuButtonProps,
} from '../../generic/command-list-menu-button'
import {type PopoverButtonHandle} from '../../generic/popover-button'
import {Button} from '../../primitives/button/Button'
import {filterByQuery} from '../../utils'

const HEADER_HEIGHT: number = 49
const FOOTER_HEIGHT: number = 49
const ITEM_HEIGHT: number = 62
const MAX_ITEMS_DISPLAYED: number = 5
const LIST_PADDING: number = 10

const MAX_HEIGHT: number =
  ITEM_HEIGHT * MAX_ITEMS_DISPLAYED + LIST_PADDING + HEADER_HEIGHT + FOOTER_HEIGHT

const SelectedBox = styled(Box)`
  visibility: hidden;

  &[data-selected='true'] {
    visibility: visible;
  }
`

interface RoleSelectMenuItemProps {
  item: Role
  selected: boolean
  onRoleSelect: (role: Role) => void
}

export function RoleSelectMenuItem(props: RoleSelectMenuItemProps) {
  const {item, selected, onRoleSelect, ...rest} = props

  return (
    <Card
      aria-selected={selected}
      as="button"
      marginBottom={2}
      onClick={() => onRoleSelect(item)}
      padding={3}
      radius={2}
      sizing="border"
      tone={selected ? 'primary' : undefined}
      {...rest}
    >
      <Flex align="center" gap={3}>
        <Stack space={3} flex={1}>
          <Text weight="medium" size={1}>
            {item.title || item.name}
          </Text>
          <Text muted size={1}>
            {item.description}
          </Text>
        </Stack>

        <SelectedBox data-selected={selected}>
          <Box>
            <Text size={1}>
              <CheckmarkIcon />
            </Text>
          </Box>
        </SelectedBox>
      </Flex>
    </Card>
  )
}

interface RoleSelectMenuButtonProps {
  appliesToRobots?: boolean
  appliesToUsers?: boolean
  closeButtonText?: string
  closeOnSelect?: boolean
  multiSelect?: boolean
  onCloseButtonClick?: () => void
  onSelect?: (role: Role) => void
  options: Role[]
  popoverButtonProps: CommandListMenuButtonProps['popoverButtonProps']
  selectedRoles?: Role[]
}

export function RoleSelectMenuButton(props: RoleSelectMenuButtonProps) {
  const {
    appliesToRobots,
    appliesToUsers = true,
    closeButtonText = 'Close',
    closeOnSelect,
    multiSelect,
    onCloseButtonClick,
    onSelect,
    options,
    popoverButtonProps: popoverButtonPropsProp,
    selectedRoles,
  } = props

  const popoverButtonRef = useRef<PopoverButtonHandle | null>(null)

  const [inputElement, setInputElement] = useState<HTMLInputElement | null>(null)
  const [query, setQuery] = useState<string | null>(null)

  const handleSearchChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value)
  }, [])

  const handleSelectRole = useCallback(
    (role: Role) => {
      onSelect?.(role)

      if (closeOnSelect && !multiSelect) {
        popoverButtonRef.current?.onClose()
        popoverButtonRef.current?.focusButton()
      }
    },
    [closeOnSelect, multiSelect, onSelect]
  )

  const handleCloseButtonClick = useCallback(() => {
    onCloseButtonClick?.()
    popoverButtonRef.current?.onClose()
    popoverButtonRef.current?.focusButton()
  }, [onCloseButtonClick])

  const renderItem = useCallback(
    (item: Role) => {
      const showSelected = selectedRoles?.some((selectedRole) => selectedRole.name === item.name)

      return (
        <RoleSelectMenuItem
          item={item}
          selected={Boolean(showSelected)}
          onRoleSelect={handleSelectRole}
        />
      )
    },
    [handleSelectRole, selectedRoles]
  )

  const getItemSelected = useCallback(
    (index: number) => {
      return (
        selectedRoles?.some((selectedRole) => selectedRole.name === options[index].name) || false
      )
    },
    [options, selectedRoles]
  )

  const filteredItems = useMemo(() => {
    const appliesTo = options.filter((role) => {
      return (appliesToRobots && role.appliesToRobots) || (appliesToUsers && role.appliesToUsers)
    })

    if (!query) return appliesTo

    const filtered = filterByQuery(appliesTo, query, [['title']])

    return filtered
  }, [appliesToRobots, appliesToUsers, options, query])

  const footer = (
    <Stack space={2} padding={2} sizing="border">
      <Button fontSize={1} onClick={handleCloseButtonClick} text={closeButtonText} width="fill" />
    </Stack>
  )

  const header = (
    <Stack space={2} padding={2} sizing="border">
      <TextInput
        fontSize={1}
        icon={SearchIcon}
        onChange={handleSearchChange}
        placeholder="Search roles by name"
        ref={setInputElement}
      />
    </Stack>
  )

  const emptyNode = (
    <Card padding={5} radius={2} sizing="border">
      <Text align="center" muted size={1}>
        No roles found
      </Text>
    </Card>
  )

  const popoverButtonProps = useMemo(
    (): CommandListMenuButtonProps['popoverButtonProps'] => ({
      minWidth: 320,
      maxWidth: popoverButtonPropsProp.matchReferenceWidth ? undefined : 320,
      maxHeight: MAX_HEIGHT,
      ...popoverButtonPropsProp,
    }),
    [popoverButtonPropsProp]
  )

  return (
    <CommandListMenuButton
      ariaLabel="Select roles"
      ariaMultiselectable={multiSelect}
      autoFocus="input"
      emptyNode={emptyNode}
      footer={multiSelect ? footer : undefined}
      getItemSelected={getItemSelected}
      header={header}
      id="role-select-menu"
      inputElement={inputElement}
      itemHeight={ITEM_HEIGHT}
      items={filteredItems}
      onlyShowSelectionWhenActive
      padding={2}
      paddingBottom={0}
      popoverButtonProps={popoverButtonProps}
      ref={popoverButtonRef}
      renderItem={renderItem}
    />
  )
}
