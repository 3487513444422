import React, {useCallback} from 'react'
import {TextArea, TextInput} from '@sanity/ui'
import {useForm} from 'react-hook-form'
import {FormField} from '../formField'
import {CountrySelect} from '../inputs/countrySelect'
import {EditContainer} from './editContainer'
import {CustomerData} from '@/types/index'
import {emailPattern} from '@/utils/form'

type Props = {
  data?: CustomerData | null
  onCancel: () => void
  onSave: (data: CustomerData) => void
  isSaving?: boolean
}

export function BillingDetailsInput({data, onCancel, onSave, isSaving}: Props) {
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<CustomerData>({
    defaultValues: data || {},
    shouldUnregister: true,
  })

  const onSubmit = (newData: CustomerData) => {
    onSave(newData)
  }

  const handleCancel = useCallback(() => {
    onCancel()
  }, [onCancel])

  return (
    <EditContainer onSave={handleSubmit(onSubmit)} onCancel={handleCancel} loading={isSaving}>
      <FormField label="Legal name" error={errors.name ? 'This field is required' : ''} required>
        <TextInput padding={3} defaultValue={data?.name} {...register('name', {required: true})} />
      </FormField>
      <FormField label="VAT number" description="Provide only if relevant">
        <TextInput padding={3} defaultValue={data?.vatId} {...register('vatId')} />
      </FormField>
      <FormField label="Email" error={errors.email ? 'This field is required' : ''} required>
        <TextInput
          padding={3}
          defaultValue={data?.email}
          {...register('email', {required: true, pattern: emailPattern})}
        />
      </FormField>
      <FormField label="Phone" error={errors.phone ? 'This field is required' : ''} required>
        <TextInput
          padding={3}
          defaultValue={data?.phone}
          {...register('phone', {required: true})}
        />
      </FormField>
      <FormField label="Address" error={errors.address ? 'This field is required' : ''} required>
        <TextArea
          padding={3}
          defaultValue={data?.address}
          {...register('address', {required: true})}
        />
      </FormField>
      <FormField label="ZIP Code" error={errors.zip ? 'This field is required' : ''} required>
        <TextInput padding={3} defaultValue={data?.zip} {...register('zip', {required: true})} />
      </FormField>
      <FormField label="City" error={errors.city ? 'This field is required' : ''} required>
        <TextInput padding={3} defaultValue={data?.city} {...register('city', {required: true})} />
      </FormField>
      <FormField label="Country" error={errors.country ? 'This field is required' : ''} required>
        <CountrySelect
          resolveFromIp
          value={data?.country}
          {...register('country', {required: true})}
        />
      </FormField>
    </EditContainer>
  )
}
