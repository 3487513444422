/* eslint-disable max-len */
import React from 'react'
import styled from 'styled-components'

interface LogoProps {
  className?: string
}

const Icon = styled.svg`
  color: ${(props) => props.theme.sanity.color.base.bg};
  height: 100%;
  width: 100%;
`

export const SanityLogoSmall = ({className}: LogoProps) => {
  return (
    <Icon
      // eslint-disable-next-line react/prop-types
      className={className}
      viewBox="0 0 16 22"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.85569 3.29077C1.85569 6.0404 3.58401 7.67643 7.04413 8.53913L10.7107 9.37433C13.9854 10.1133 15.9796 11.9487 15.9796 14.9389C16.0051 16.2416 15.574 17.5133 14.7586 18.5409C14.7586 15.5576 13.1877 13.9456 9.3987 12.9763L5.79863 12.1721C2.91577 11.5259 0.690653 10.0171 0.690653 6.76905C0.675489 5.51476 1.08534 4.29113 1.85569 3.29077Z"
        fill="currentColor"
      />
      <path
        d="M12.5055 14.2068C14.0694 15.1898 14.7551 16.5646 14.7551 18.5375C13.4606 20.1666 11.1865 21.0809 8.51359 21.0809C4.01437 21.0809 0.865616 18.9053 0.165894 15.1245H4.48668C5.04296 16.8602 6.51588 17.6645 8.4821 17.6645C10.8821 17.6645 12.4775 16.4065 12.509 14.2"
        fill="currentColor"
        fillOpacity="0.6"
      />
      <path
        d="M4.10184 7.36713C3.38772 6.95019 2.80183 6.35107 2.40637 5.63337C2.01091 4.91567 1.82063 4.10618 1.85573 3.2908C3.10474 1.67539 5.27738 0.688965 7.92583 0.688965C12.509 0.688965 15.161 3.06739 15.8152 6.41507H11.6588C11.2005 5.09525 10.053 4.06757 7.96081 4.06757C5.7252 4.06757 4.1998 5.34615 4.11234 7.36713"
        fill="currentColor"
        fillOpacity="0.6"
      />
    </Icon>
  )
}
