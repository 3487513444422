import {useQuery, type UseQueryResult} from '@tanstack/react-query'
import {getJourneyProject, type JourneyProject} from './controller'
import {useCanReadProject} from '../../context'

export function useJourneyProject(projectId: string): UseQueryResult<JourneyProject | null> {
  const canReadProject = useCanReadProject(projectId)

  return useQuery({
    queryKey: ['journey', 'project', projectId],
    queryFn: () => getJourneyProject(projectId),
    enabled: canReadProject,
  })
}
