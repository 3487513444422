import React, {useCallback, useEffect, useRef, useState} from 'react'
import {Subscription, of} from 'rxjs'
import {catchError} from 'rxjs/operators'
import {DocumentWebhook, DocumentWebhookFields} from '../../types/models'
import {addWebhook, deleteWebhook, updateWebhook, getWebhooks} from '.'
import {APIError} from '@/data/util/request'

type AddWebhook = (data: DocumentWebhookFields) => void
type UpdateWeebhook = (webhookId: string, data: Partial<DocumentWebhookFields>) => void
type DeleteWebhook = (webhookId: string) => void
type WebhooksDataState = {
  error?: Error
  loading: boolean
  data: DocumentWebhook[]
  addWebhook: AddWebhook
  updateWebhook: UpdateWeebhook
  deleteWebhook: DeleteWebhook
}

export function useWebhooks(
  projectId: string,
  {
    didAdd,
    didUpdate,
    didDelete,
    didError,
  }: {
    didAdd?: () => void
    didUpdate?: () => void
    didDelete?: () => void
    didError?: (err: Error) => void
  } = {}
): WebhooksDataState {
  const [webhooks, setWebhooks] = useState<DocumentWebhook[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const addSub: React.MutableRefObject<Subscription | undefined> = useRef()
  const getSub: React.MutableRefObject<Subscription | undefined> = useRef()
  const updateSub: React.MutableRefObject<Subscription | undefined> = useRef()
  const delSub: React.MutableRefObject<Subscription | undefined> = useRef()

  useEffect(() => {
    return () => {
      if (getSub.current) {
        getSub.current.unsubscribe()
      }
      if (addSub.current) {
        addSub.current.unsubscribe()
      }
      if (updateSub.current) {
        updateSub.current.unsubscribe()
      }
      if (delSub.current) {
        delSub.current.unsubscribe()
      }
    }
  }, [])

  useEffect(() => {
    setLoading(true)
    getSub.current = getWebhooks(projectId)
      .pipe(
        catchError((err) => {
          // If the user isn't allowed to see the API tokens, just return an empty list
          if (err.response && [401, 502].includes(err.response.statusCode)) {
            return of([])
          }
          // Throw everything else
          if (didError) didError(err)
          throw err
        })
      )
      .subscribe((next) => {
        setWebhooks(next)
        setLoading(false)
        getSub.current?.unsubscribe()
      })
  }, [projectId])

  const _addWebhook = useCallback(
    (fields: DocumentWebhookFields) => {
      setLoading(true)
      addSub.current = addWebhook(projectId, fields)
        .pipe(
          catchError((err) => {
            if (err instanceof APIError) {
              if (didError) didError(err)
              return of(undefined)
            }
            if (didError) didError(err)
            throw err
          })
        )
        .subscribe((next) => {
          if (next) {
            if (didAdd) didAdd()
            setWebhooks([...webhooks, next])
          }
          setLoading(false)
          addSub.current?.unsubscribe()
        })
    },
    [projectId, webhooks, didAdd, didError]
  )
  const _delWebhook = useCallback(
    (webhookId: string) => {
      setLoading(true)
      delSub.current = deleteWebhook(projectId, webhookId)
        .pipe(
          catchError((err) => {
            if (err instanceof APIError) {
              if (didError) didError(err)
              return of(undefined)
            }
            if (didError) didError(err)
            throw err
          })
        )
        .subscribe((next) => {
          if (next) {
            if (didDelete) didDelete()
            setWebhooks(webhooks.filter((webhook) => webhook.id !== next.id))
          }
          setLoading(false)
          delSub.current?.unsubscribe()
        })
    },
    [projectId, webhooks, didDelete, didError]
  )

  const _updateWebhook = useCallback(
    (webhookId: string, fields: Partial<DocumentWebhookFields>) => {
      setLoading(true)
      updateSub.current = updateWebhook(projectId, webhookId, fields)
        .pipe(
          catchError((err) => {
            if (err instanceof APIError) {
              if (didError) didError(err)
              return of(undefined)
            }
            if (didError) didError(err)
            throw err
          })
        )
        .subscribe((next) => {
          if (next) {
            if (didUpdate) didUpdate()
            setWebhooks(webhooks.map((webhook) => (webhook.id === next.id ? next : webhook)))
          }
          setLoading(false)
          updateSub.current?.unsubscribe()
        })
    },
    [projectId, webhooks, didUpdate, didError]
  )

  return {
    data: webhooks,
    loading,
    addWebhook: _addWebhook,
    updateWebhook: _updateWebhook,
    deleteWebhook: _delWebhook,
  }
}
