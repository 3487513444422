import {Stack, rem, _responsive} from '@sanity/ui'
import styled, {css} from 'styled-components'

export function getResponsiveProp(
  val: number | number[] | undefined,
  defaultVal?: number[]
): number[] {
  if (val === undefined) return defaultVal || []

  return Array.isArray(val) ? val : [val]
}

// TreeStack is a component that draws a tree-like structure between items in a stack.
export const TreeStack = styled(Stack)<{$showTree: boolean; $isMobile: boolean}>`
  position: relative;
  ${({$showTree, $isMobile, space}) =>
    $showTree &&
    css`
      &::before,
      &::after {
        content: '';
        ${(_) => ({
          position: 'absolute',
          left: rem(-1),
        })}
      }
      /* This draws the horizontal */
      &::before {
        ${({theme}) => ({
          width: '12px',
          height: '0',
          borderTop: `1px solid ${theme.sanity.color.card.enabled.border}`,
          top: `${34}px`,
          left: rem(($isMobile ? 17 : 32) - 12),
        })}
      }

      /* This draws the vertical line */
      &::after {
        ${({theme}) => {
          return _responsive(theme.media, getResponsiveProp(space), (spaceIndex) => ({
            width: '0',
            borderLeft: `1px solid ${theme.sanity.color.card.enabled.border}`,
            height: `calc(50% + ${rem(theme.sanity.space[spaceIndex])})`,
            top: rem(-1 * theme.sanity.space[spaceIndex]),
            left: rem(($isMobile ? 17 : 32) - 12),
          }))
        }}
      }

      /* If it's an item followed by another item, we need to extend the line */
      & + &::after {
        ${({theme}) => {
          return _responsive(theme.media, getResponsiveProp(space), (spaceIndex) => ({
            height: `calc(100% + ${rem(theme.sanity.space[spaceIndex])})`,
            top: `calc(-50% - ${rem(theme.sanity.space[spaceIndex])})`,
          }))
        }}
      }
    `}
`
