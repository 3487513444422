import React from 'react'
import styled from 'styled-components'

const Root = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`

export const List = ({children}: {children: React.ReactNode}) => {
  return <Root>{children}</Root>
}
