import {Box, Card, Flex} from '@sanity/ui'
import React from 'react'

type Props = {
  children: React.ReactNode
  button?: React.ReactNode
}

export function UsageHeaderLayout({children, button}: Props) {
  const childrenArr = React.Children.toArray(children) as React.ReactElement[]

  return (
    <Card tone="transparent" radius={2} padding={2} paddingBottom={0}>
      <Flex align="center" justify="space-between" wrap="wrap">
        <Flex
          flex={1}
          align={['stretch', 'stretch', 'center']}
          wrap="wrap"
          direction={['column', 'column', 'row']}
        >
          {childrenArr.map((child) => (
            <Box key={child.key} marginRight={3} marginBottom={2}>
              {child}
            </Box>
          ))}
        </Flex>
        {button && (
          <Box paddingBottom={2} marginLeft={3}>
            {button}
          </Box>
        )}
      </Flex>
    </Card>
  )
}
