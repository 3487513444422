import React from 'react'
import {Button, MenuButton, Menu, MenuDivider, ButtonTone} from '@sanity/ui'
import {EllipsisVerticalIcon, Icon} from '@sanity/icons'
import {uid} from 'react-uid'
import {AppLink, PermissionTooltip} from '@/ui/index'

interface MenuItemProps {
  permissions?: string[]
  tone?: ButtonTone
  onClick?: () => void
  icon?: typeof Icon
  text: string
  href?: string
  hasPermission: boolean
}

export type ChartContextualAction = {
  title: string
  href?: string
  icon?: typeof Icon
  tone?: ButtonTone
  mode?: 'bleed'
  hasPermission: boolean
  onAction?: () => void
}

const CustomMenuItem = ({href, hasPermission, ...rest}: MenuItemProps) => {
  return (
    <PermissionTooltip disabled={hasPermission}>
      <AppLink href={href} disabled={!hasPermission}>
        <Button
          {...rest}
          mode="bleed"
          disabled={!hasPermission}
          padding={3}
          justify="flex-start"
          style={{
            width: '100%',
            cursor: hasPermission ? 'default' : 'not-allowed',
            textAlign: 'left',
          }}
        />
      </AppLink>
    </PermissionTooltip>
  )
}

export function ChartSectionActions({
  chartId,
  actions,
}: {
  chartId: string
  actions: ChartContextualAction[]
}) {
  return (
    <MenuButton
      button={<Button mode="bleed" icon={EllipsisVerticalIcon} label="Chart actions" />}
      placement="bottom-end"
      id={uid(chartId)}
      menu={
        <Menu>
          {actions.map((action: ChartContextualAction) => {
            if (action.title === 'divider') {
              return <MenuDivider key={uid(action)} />
            }

            const {title, tone, mode, icon, href, hasPermission} = action
            // const handleOnClick = () => {}
            //const hrefPath = href?.rowId ? `/${rowItem.id}` : ''
            //const suffixPath = href?.path ? `/${href?.path}` : ''
            const buttonProps = {text: title, tone, mode, icon, hasPermission}

            return (
              <CustomMenuItem key={action.title} href={href ? href : undefined} {...buttonProps} />
            )
          })}
        </Menu>
      }
    />
  )
}
