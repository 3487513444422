import {studioTheme, ThemeColorSchemeKey} from '@sanity/ui'
import {createGlobalStyle, css} from 'styled-components'

const foo = (props: {scheme: ThemeColorSchemeKey}) => {
  const {scheme} = props
  const color = studioTheme.color[scheme]

  return css`
    :root {
      --app-backgroung-color: ${color.transparent.base.bg};
    }

    *,
    *:before,
    *:after {
      box-sizing: border-box;
    }

    html,
    body,
    #__next {
      height: 100%;
    }

    html {
      scroll-behavior: smooth;
    }

    a,
    button {
      cursor: pointer;
      text-decoration: none;
    }

    .grecaptcha-badge {
      visibility: hidden;
    }
    [data-ui='Dialog'] {
      background: rgba(134, 144, 160, 0.14);
    }

    body {
      background-color: ${color.transparent.base.bg};
      color: ${color.transparent.base.fg};
      -webkit-font-smoothing: antialiased;
      margin: 0;
      font-family: ${studioTheme.fonts.text.family};
    }

    .apexcharts-tooltip {
      border-radius: 3px !important;
      background: #f1f3f6 !important;
      border: 1px solid #fff !important;
      color: #3c4758 !important;
      font-size: 11px !important;
    }

    .apexcharts-xaxistooltip {
      border-radius: 3px !important;
      border: 1px solid #fff !important;
      background: #f1f3f6 !important;
      color: #3c4758 !important;
      padding: 5px 0px !important;
      font-size: 11px !important;
    }

    .apexcharts-xaxistooltip-bottom:before {
      border-bottom-color: #fff !important;
    }

    .apexcharts-xaxistooltip:after {
      border: 1px solid #fff !important;
    }
  `
}

export const GlobalStyle = createGlobalStyle<{scheme: ThemeColorSchemeKey}>(foo)
