import {Button, ButtonMode, ButtonTone, useToast} from '@sanity/ui'
import {IconSymbol} from '@sanity/icons'
import React, {useCallback, useState} from 'react'
import {ConfirmDialog} from './confirmDialog'
import {DialogToast} from '@/types/index'

type DialogProps = {
  id: string
  title?: string
  description?: string
  header?: string
  confirmButtonProps?: {
    text?: string | React.ReactNode
    icon?: IconSymbol
    tone?: ButtonTone
    mode?: ButtonMode
  }
  children?: React.ReactNode
  onConfirm?: (payload?: any) => void
  onClose?: () => void
  loading?: boolean
  disabled?: boolean
}

export const useConfirmDialog = ({open = false}: {open?: boolean} = {}) => {
  const [dialogData, setDialogData] = useState<any>()
  const [isOpen, setIsOpen] = useState<boolean>(open)

  const showDialog = useCallback((payload?: any) => {
    setDialogData(payload)
    setIsOpen(true)
  }, [])

  const toast = useToast()

  const hideDialog = useCallback(
    (dialogToast?: DialogToast) => {
      setIsOpen(false)
      if (dialogToast) {
        toast.push(dialogToast)
      }
    },
    [toast]
  )

  const RenderDialog = ({
    confirmButtonProps,
    children,
    onConfirm,
    loading,
    disabled,
    onClose,
    ...restProps
  }: DialogProps) => {
    const handleConfirm = (payload: any) => {
      if (onConfirm && !loading) {
        onConfirm(payload)
      }
    }

    const handleOnClose = () => {
      if (onClose) {
        onClose()
      }
      hideDialog()
    }

    return (
      <>
        {isOpen && (
          <ConfirmDialog
            onClose={handleOnClose}
            {...restProps}
            confirmButton={
              <Button
                text="Confirm"
                tone="critical"
                {...confirmButtonProps}
                onClick={() => handleConfirm(dialogData)}
                loading={loading}
                disabled={disabled}
              />
            }
          >
            {children}
          </ConfirmDialog>
        )}
      </>
    )
  }

  return {
    showDialog,
    hideDialog,
    Dialog: RenderDialog,
  }
}
