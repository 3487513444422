import {css} from 'styled-components'

export const styles = css`
  .react-datepicker {
    font-family: inherit;
    width: 100%;
    border: unset;
    background-color: unset;
    color: inherit;
  }

  .react-datepicker__header {
    text-align: unset;
    background-color: unset;
    border-bottom: unset;
    border-top-left-radius: unset;
    padding-top: unset;
  }

  .react-datepicker__day-names {
    display: flex;
    justify-content: space-around;
    font-weight: 700;
    color: inherit;
  }

  .react-datepicker__day-name {
    color: inherit;
    flex: 1;
  }

  .react-datepicker__month {
    margin: 0;
  }

  .react-datepicker-wrapper,
  .react-datepicker__month-container {
    width: 100%;
  }

  .react-datepicker-popper {
    z-index: 9999; //replace
    margin: 0;
  }

  .react-datepicker__week {
    display: flex;
    justify-content: space-between;
  }

  .react-datepicker__day--outside-month {
    opacity: 0.3;
  }

  .react-datepicker__day--today {
    opacity: 1;
  }

  .react-datepicker__day--disabled {
    pointer-events: none;
    opacity: 0.3;
  }

  .react-datepicker-popper[data-placement^='bottom'],
  .react-datepicker-popper[data-placement^='top'] {
    margin: 0;
  }

  .react-datepicker__day {
    border-radius: unset;
    background-color: unset;
    margin: unset;
    line-height: unset;
    line-height: unset;
    width: unset;
    padding: 0;
    margin: 0;
    font-weight: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: 0;

    &:not(:hover):focus {
      span {
        outline: auto;
      }
    }

    @media (hover: hover) {
      &:hover {
        background: unset;
      }
    }
  }
`
