import {useRoutePath} from '@/context/useRoutePathContext'
import {createProjectRole, updateProjectRole} from '@/data/projects'
import {getProjectRolesKey} from '@/data/projects/cache'
import {ProjectMemberRole} from '@/types/models'
import {FormField} from '@/ui/index'
import {Button, Card, Dialog, Grid, Stack, TextArea, TextInput, useToast} from '@sanity/ui'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {useRouter} from 'next/router'
import {useCallback, useMemo, useState} from 'react'
import {FieldError, useForm} from 'react-hook-form'

const roleNamePattern = /^[a-z0-9-_]+$/

type FormData = {
  title: string
  name: string
  description: string
}

function parseFormErrorMessage(msg: string): [keyof FormData | '__unknown__', string] {
  return ['__unknown__', msg]
}

function getError(error: FieldError | undefined) {
  if (!error) return undefined
  if (error?.message) return error.message
  if (error?.type === 'pattern')
    return 'Invalid format, needs to only include lowercase alphanumeric characters (a-z, 0-9), _, or -'
  return ''
}

type Props = {
  onClose: () => void
  mode?: 'create' | 'edit'
  projectId: string
  role?: ProjectMemberRole
}
export function UpdateRoleDialog({role, onClose, mode = 'edit', projectId}: Props) {
  const toast = useToast()
  const router = useRouter()
  const queryClient = useQueryClient()
  const {basePath} = useRoutePath()

  const defaultValues: FormData = useMemo(() => {
    return {
      title: role?.title || '',
      name: role?.name || '',
      description: role?.description || '',
    }
  }, [role])

  const {
    register,
    handleSubmit,
    formState: {errors},
    setError,
    setValue,
  } = useForm<FormData>({
    defaultValues,
  })

  const [nameTouched, setNameTouched] = useState(false)

  const handleName = useCallback(() => {
    setNameTouched(true)
  }, [])

  const handleTitle = useCallback(
    (ev) => {
      const value: string = ev.target.value
      if (!nameTouched && mode === 'create') {
        setValue('name', value.toLowerCase().split(' ').join('-'), {shouldValidate: true})
      }
    },
    [setValue, nameTouched, mode]
  )

  const mutation = useMutation({
    mutationKey: ['saveProjectRole'],
    mutationFn: ({name, title, description}: FormData) => {
      if (mode === 'create') {
        return createProjectRole(projectId, {
          name,
          description,
          title,
          appliesToRobots: true,
          appliesToUsers: true,
        })
      }
      return updateProjectRole(projectId, role!.name, {title, description})
    },
    onMutate: async (newData) => {
      await queryClient.cancelQueries({queryKey: getProjectRolesKey(projectId)})
      const previousRoles = queryClient.getQueryData(getProjectRolesKey(projectId))
      queryClient.setQueryData(getProjectRolesKey(projectId), (old: any) => {
        if (mode === 'create') {
          return [...old, newData]
        }
        return old.map((r: any) => (r.name === role?.name ? {...r, ...newData} : r))
      })
      return {previousRoles}
    },
    onError: (err, _newData, context) => {
      queryClient.setQueryData(getProjectRolesKey(projectId), context?.previousRoles)
      const [field, message] = parseFormErrorMessage(err.message)
      if (field === '__unknown__') {
        toast.push({title: err.name, description: err.message, status: 'error'})
      } else {
        setError(field as keyof FormData, {message})
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({queryKey: getProjectRolesKey(projectId)})
    },
    onSuccess: (newData, {name}) => {
      onClose()
      toast.push({title: mode === 'create' ? 'Role created' : 'Role updated', status: 'success'})
      if (mode === 'create') {
        router.push(`${basePath}/access/roles?role=${newData?.name}`, undefined, {
          shallow: true,
        })
      }
      if (mode === 'edit' && name !== newData?.name) {
        router.push(`${basePath}/access/roles?role=${name}`, undefined, {
          shallow: true,
        })
      }
    },
  })

  const save = useCallback(
    (data: FormData) => {
      mutation.mutate(data)
    },
    [mutation]
  )

  return (
    <Dialog
      header={mode === 'create' ? 'Create new role' : 'Configure role'}
      id="configure-role-dialog"
      onClose={onClose}
      zOffset={1000}
      width={1}
      footer={
        <Card padding={4}>
          <Grid columns={2} gapX={2}>
            <Button text="Cancel" mode="bleed" onClick={onClose} />
            <Button
              text={mode === 'create' ? 'Create role' : 'Update role'}
              tone="primary"
              loading={mutation.isPending}
              onClick={handleSubmit(save)}
            />
          </Grid>
        </Card>
      }
    >
      <Stack padding={4} space={5}>
        <FormField
          label="Title"
          description="Give the role a descriptive title"
          error={errors?.title?.message}
        >
          <TextInput
            {...register('title', {required: 'A title is required'})}
            onChange={handleTitle}
          />
        </FormField>

        <FormField
          label="Identifier"
          description="A unique identifier used to access this role in code"
          error={getError(errors.name)}
        >
          <TextInput
            readOnly={mode !== 'create'}
            {...register('name', {required: 'An identifier is required', pattern: roleNamePattern})}
            onChange={handleName}
          />
        </FormField>

        <FormField
          label="Description"
          description="Describe the role for others"
          error={errors?.description?.message}
        >
          <TextArea {...register('description')} />
        </FormField>
      </Stack>
    </Dialog>
  )
}
