import React from 'react'
import {Inline, Button, Stack, Card, Text} from '@sanity/ui'
import {TrashIcon, UploadIcon} from '@sanity/icons'

type Props = {
  label: string | React.ReactNode
}

export const UploadField = ({label}: Props) => {
  return (
    <Stack space={3}>
      {typeof label === 'string' ? (
        <Text weight="semibold" size={1}>
          {label}
        </Text>
      ) : (
        label
      )}
      <Card borderLeft paddingLeft={3}>
        <Stack space={3}>
          <Card tone="transparent" radius={3} style={{height: 128, width: 128}} />
          <Inline space={3}>
            <Button size={2} text="Upload" icon={UploadIcon} mode="ghost" muted />
            <Button size={2} text="Remove" icon={TrashIcon} mode="ghost" muted tone="critical" />
          </Inline>
        </Stack>
      </Card>
    </Stack>
  )
}
