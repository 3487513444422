export const getOrCreateDomElement = (id: string, forceNew = false) => {
  let elem = document.getElementById(id)

  if (!elem || forceNew) {
    elem = document.createElement('div')
    elem.id = id
  }
  return elem
}

interface WindowToMakeTS7015GoAway extends Window {
  [key: string]: any
}

export const loadAsyncGlobalScript = <T>(src: string, name: string, id: string) => {
  return new Promise<T>((resolve, reject) => {
    const existingScript = document.getElementById(id)
    if (existingScript) {
      const t = (window as WindowToMakeTS7015GoAway)[name] as T
      resolve(t)
    } else {
      try {
        const script = document.createElement('script')
        script.src = src
        script.id = id
        script.async = true
        script.defer = true
        script.addEventListener('load', () => {
          resolve((window as WindowToMakeTS7015GoAway)[name] as T)
        })

        document.body.appendChild(script)
      } catch (e) {
        reject(e)
      }
    }
  })
}
