import {Grid, Stack} from '@sanity/ui'
import React, {useMemo} from 'react'
import {PlanCard, Loader, getPlanCopy} from '@/ui/index'
import {Plan, Project} from '@/types/index'
import {sortArrayOfObjects} from '@/utils/general'
import {usePlanFeatures} from '@/data/plan/usePlanFeatures'

type Props = {
  project: Project
  onSelect: (plan: Plan) => void
  loading?: boolean
  planOptions: Plan[]
  currentPlan: Plan
}

export function PlanOptions({onSelect, loading, planOptions}: Props) {
  const {data: planFeatures, isLoading: featuresLoading} = usePlanFeatures()
  const enterprisePlan = planOptions?.find((plan) => plan.planTypeId === 'enterprise')
  const orderablePlans = planOptions?.filter(
    (plan) => plan.orderable && plan.public && plan.planTypeId !== 'enterprise'
  )
  const sortPlansByPrice = sortArrayOfObjects({array: orderablePlans || [], property: 'price'})
  const plans = useMemo(
    () => [...sortPlansByPrice, enterprisePlan] || [],
    [enterprisePlan, sortPlansByPrice]
  )

  //Format plan options
  const formatPlanOptions = plans
    ?.filter((plan) => {
      const isNotTrial = plan?.planTypeId !== 'growth-trial' // make sure no growth-trials are shown
      return isNotTrial
    })
    ?.map((plan: Plan) => {
      const {listHeader, listItems, description, paymentDescription} =
        getPlanCopy(plan, planFeatures) || {}
      return {
        plan,
        listHeader,
        listItems,
        description,
        paymentDescription,
      }
    })

  if (loading || featuresLoading) {
    return <Loader text="Loading plans..." cardProps={{border: true, radius: 2}} height={346} />
  }

  const columns = formatPlanOptions.length === 3 ? [1, 1, 2, 2, 3] : [1, 1, 2, 2, 2]

  return (
    <Stack space={5}>
      <Grid columns={columns} gap={4}>
        {formatPlanOptions?.map((planProps) => (
          <PlanCard key={planProps.plan.id} {...planProps} onSelectPlan={onSelect} />
        ))}
      </Grid>
    </Stack>
  )
}
