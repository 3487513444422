import {useToast} from '@sanity/ui'
import {MembersSettingsScreen, type MembersSettingsScreenProps} from '@ui-components'
import {useRouter} from 'next/router'
import {useCallback, useMemo} from 'react'

import {useWizardStore} from '@/components/payment-wizard'
import {useCurrentScopeContext} from '@/context/useCurrentScopeContext'
import {useRequiredProjectPermissions} from '@/context/useProjectPermissions'
import {useProjectType} from '@/context/useProjectType'
import {useRoutePath} from '@/context/useRoutePathContext'
import {useProjectSubscription} from '@/data/index'
import {useMembersSettings} from '@/data/members_v2'
import {useCurrentUser} from '@/data/users'
import {type TableViews} from '@/ui/ui-components/features/member-tables-view'
import {getSubscriptionFeatures} from '@/utils/subscription'
import {getProjectMemberState} from '@/utils/usage'

const NON_UPSELL_PLANS = ['poc', 'enterprise', 'internal', 'test', 'non-profit']
const VIEWS: TableViews[] = ['members', 'invitations', 'requests']
const VIEW_TITLES: Record<TableViews, string> = {
  members: 'Project members',
  invitations: 'Project invitations',
  requests: 'Access requests',
}

interface ProjectMembersViewProps {
  description: string
  referenceLinkComponent?: JSX.ElementType
}

export function ProjectMembersView({description, referenceLinkComponent}: ProjectMembersViewProps) {
  const ctx = useCurrentScopeContext()
  const router = useRouter()
  const currentUser = useCurrentUser()
  const {basePath} = useRoutePath()

  const toast = useToast()

  const wizardStore = useWizardStore()

  const inviteDialogOpen = wizardStore.state.inviteMembersWizard.open

  const handleInviteDialogClose = useCallback(() => {
    wizardStore.dispatch({type: 'inviteMembersWizard/close'})
  }, [wizardStore])

  const currentUserId = String(currentUser.data?.id)
  const resourceId = String(ctx.project?.id)
  const organizationId = ctx.project?.organizationId

  const selectedView = useMemo((): TableViews => {
    const headingId = router.query.headingId

    if (VIEWS.find((view) => view === headingId)) {
      return headingId as TableViews
    }

    return 'members'
  }, [router.query.headingId])

  const value = useMembersSettings({
    currentUserId,
    resourceId,
    organizationId,
    resourceType: 'project',
    onMutationError: (error) => {
      const message = error.message || 'Something went wrong'

      toast.push({
        id: message,
        status: 'error',
        title: message,
      })
    },
    onMutationSuccess: (message) => {
      toast.push({
        status: 'success',
        title: message,
      })
    },
  })

  const canInviteUsers = useRequiredProjectPermissions([
    {permissionName: 'sanity.project.members', grantName: 'invite'},
  ])
  const canEditUsers = useRequiredProjectPermissions([
    {permissionName: 'sanity.project.members', grantName: 'update'},
  ])
  const canRemoveUsers = useRequiredProjectPermissions([
    {permissionName: 'sanity.project.members', grantName: 'delete'},
  ])

  const {data} = useProjectSubscription(ctx.project?.id)
  const projectType = useProjectType(ctx.project)

  const isOrganizationPlan = ctx?.org?.type === 'org-plan' && projectType === 'org-plan'

  const resourcesQuota = useMemo((): MembersSettingsScreenProps['resourcesQuota'] => {
    if (!ctx.project) return

    const memberState = getProjectMemberState(ctx.project, projectType, data?.resources, ctx.org)

    return {
      members: {
        billable: memberState.billable,
        billableUserType: memberState.resource,
        max: memberState.max,
        quota: memberState.quota,
        showUpsell: !NON_UPSELL_PLANS.includes(data?.plan.planTypeId || ''),
        total: memberState.total,
      },
    }
  }, [ctx.org, ctx.project, data?.plan.planTypeId, data?.resources, projectType])

  const disabledFeatures: MembersSettingsScreenProps['disabledFeatures'] = {
    addMembers: {
      isDisabled: !canInviteUsers,
      reason: 'You are not allowed to invite members to this project',
    },
    editMembers: {
      isDisabled: !canEditUsers,
      reason: 'You are not allowed to edit members in this project',
    },
    removeMembers: {
      isDisabled: !canRemoveUsers,
      reason: 'You are not allowed to remove members from this project',
    },
  }

  const subscriptionFeatures = data ? getSubscriptionFeatures(data) : []

  const projects = ctx.project ? [ctx.project] : []
  const error = value.error
  const loading = value.loading

  return (
    <MembersSettingsScreen
      basePath={basePath}
      description={description}
      disabledFeatures={disabledFeatures}
      error={error}
      invitations={value.data.invitations}
      inviteDialogOpen={inviteDialogOpen}
      isOrganizationPlan={isOrganizationPlan}
      loading={loading}
      members={value.data.members}
      onInvitationRevoke={value.operations.onInvitationRevoke}
      onInviteDialogClose={handleInviteDialogClose}
      onInviteMembers={value.operations.onInviteMembers}
      onMemberRemove={value.operations.onResourceMembershipRemove}
      onRequestApprove={value.operations.onRequestApprove}
      onRequestRevoke={value.operations.onRequestRevoke}
      onResourceMembershipAdd={value.operations.onResourceMembershipAdd}
      onResourceMembershipRemove={value.operations.onResourceMembershipRemove}
      onResourceRoleAdd={value.operations.onResourceRoleAdd}
      onResourceRoleRemove={value.operations.onResourceRoleRemove}
      onRetryFetch={value.refetch}
      planPricingModel={data?.plan.pricingModel}
      planType={data?.plan.planTypeId}
      projects={projects}
      requests={value.data.requests}
      resourceId={resourceId}
      resourcesQuota={resourcesQuota}
      resourceType="project"
      roles={value.data.roles}
      selectedView={selectedView}
      subscriptionFeatures={subscriptionFeatures}
      title={VIEW_TITLES[selectedView]}
      referenceLinkComponent={referenceLinkComponent}
    />
  )
}
