import {
  Box,
  Button,
  ButtonProps,
  Card,
  Flex,
  Grid,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  PopoverProps,
  Text,
  useToast,
} from '@sanity/ui'
import React, {useCallback, useMemo, useState} from 'react'
import {AddIcon, ArrowRightIcon, SelectIcon} from '@sanity/icons'
import {WizardParams, WizardStep, WizardStepProps} from '../types'
import {useWizardStore} from '../store/useStore'
import {CreditCardIcon, SanityMonogram} from '../index'
import {Organization} from '@/types/models'
import {transferProject} from '@/data/projects'
import {useOrganizationCanPay} from '@/data/organizations'
import {sendAmplitudeTrackingEvent} from '@/utils/tracking'
import {ProjectIcon} from '../../project/projectIcon'
import {useCurrentScopeContext} from '@/context/index'
import {isAPIError} from '@/data/util/promiseRequest'
import {useCacheInvalidation} from '@/data/base/useCacheInvalidation'

const orgSortFn = (a: Organization, b: Organization) =>
  +new Date(b.updatedAt) - +new Date(a.updatedAt)

function SelectOrganizationMenu(props: {
  onChange: (orgId: string | null) => void
  orgs: Organization[]
  value?: string | null
}) {
  const {onChange, orgs, value} = props
  const {projectId} = useCurrentScopeContext()
  const selectableOrgs = useMemo(
    () =>
      [...orgs]
        .filter((o) => o.id !== 'personal' && o.members.find((mb) => mb.isCurrentUser === true))
        .sort(orgSortFn),
    [orgs]
  )
  const selectedOrg = orgs.find((o) => o.id === value)
  const {data: canPay} = useOrganizationCanPay(selectedOrg?.id)
  const changeToNew = useCallback(() => {
    sendAmplitudeTrackingEvent('Switch Plan Step Completed', projectId, selectedOrg?.id, {
      stepName: 'Select new organization',
    })
    return onChange('#new')
  }, [onChange, projectId, selectedOrg])
  const popoverProps = useMemo(
    () =>
      ({
        // eslint-disable-next-line camelcase
        __unstable_margins: [1, 1, 1, 1],
        preventOverflow: true,
        constrainSize: true,
        arrow: false,
        fallbackPlacements: [],
        matchReferenceWidth: true,
        placement: 'bottom',
        portal: true,
        radius: 2,
        /**
         * This is a workaround to make the popover menu be bound to the dialog backdrop,
         * instead of the nearest parent
         */
        floatingBoundary: document.querySelector("[data-portal]>[aria-modal='true']") || undefined,
      }) as PopoverProps,
    []
  )

  const menuButtonProps: ButtonProps = useMemo(() => {
    return {
      iconRight: SelectIcon,
      justify: 'space-between',
      mode: 'ghost',
      padding: 3,
      radius: 2,
      style: {height: '100%'},
      width: 'fill',
    }
  }, [])

  return (
    <MenuButton
      button={
        selectedOrg ? (
          <Button {...menuButtonProps} text={selectedOrg.name} />
        ) : value === '#new' ? (
          <Button {...menuButtonProps} text="New organization" icon={AddIcon} />
        ) : (
          <Button {...menuButtonProps} text="Select organization…" />
        )
      }
      id="org-menu"
      menu={
        <Menu>
          <MenuItem
            onClick={changeToNew}
            selected={value === '#new'}
            text="New organization"
            icon={AddIcon}
          />

          {selectableOrgs.map((org) => (
            <MenuItem
              key={org.id}
              onClick={() => onChange(org.id)}
              selected={value === org.id}
              icon={<SanityMonogram hueColor="gray" style={{fontSize: '21px'}} />}
              text={org.name}
              iconRight={canPay?.status === true && <CreditCardIcon />}
            />
          ))}
        </Menu>
      }
      popover={popoverProps}
    />
  )
}

function TransferToOrgStep(props: WizardStepProps) {
  const {params} = props
  const {project, orgs, org} = params
  const currentOrg = org && org.type !== 'personal' && org
  const {dispatch, state} = useWizardStore()
  const handleChange = useCallback(
    (orgId: string | null) => {
      dispatch({type: 'setSelectedOrgId', value: orgId})
    },
    [dispatch]
  )
  return (
    <Box>
      <Heading as="h1">Transfer project to an organization with billing details</Heading>
      <Grid
        gap={3}
        marginTop={5}
        style={{gridTemplateColumns: '1fr auto 1fr', alignItems: 'center'}}
      >
        <Text size={1} weight="medium">
          Project
        </Text>
        <Box />
        <Text size={1} weight="medium">
          Organization
        </Text>

        <Card border padding={2} radius={2}>
          <Flex align="center" style={{margin: -1}}>
            <Box>
              <ProjectIcon project={project} size={1} />
            </Box>
            <Box flex={1} paddingLeft={3}>
              <Text muted textOverflow="ellipsis">
                {project.displayName}
              </Text>
            </Box>
          </Flex>
        </Card>

        <Text muted>
          <ArrowRightIcon />
        </Text>

        <SelectOrganizationMenu onChange={handleChange} orgs={orgs} value={state.selectOrgId} />
      </Grid>

      {currentOrg && (
        <Flex marginTop={5}>
          <Text>
            Your project currently belongs to the <strong>{currentOrg.name} organization.</strong>
          </Text>
        </Flex>
      )}
    </Box>
  )
}

export function useTransferToOrgStep(params: WizardParams): WizardStep | null {
  const {dispatch, state} = useWizardStore()
  const {selectOrgId, forceShowTransferStep} = state
  const {org: currentOrg, project, orgs, onOrganizationAssigned} = params
  const hasCurrentOrg = !!currentOrg && currentOrg.id !== 'personal'
  const toast = useToast()
  const [hidden] = useState(!!project.organizationId && selectOrgId === undefined)
  const {invalidateProject} = useCacheInvalidation()

  if (hidden && forceShowTransferStep !== true) {
    return null
  }

  const transferProjText = hasCurrentOrg ? 'Continue' : 'Transfer project'

  return {
    id: 'transfer-to-org',
    title: 'Transfer to organization',
    completed: !!selectOrgId,
    component: TransferToOrgStep,
    disabled: selectOrgId === undefined,
    action: {
      label: <>{selectOrgId === '#new' ? 'Create new organization' : transferProjText}</>,
    },
    handle() {
      if (selectOrgId === '#new') {
        dispatch({type: 'setCreateOrg', value: {isValidFormData: false, createdOrg: undefined}})
        return Promise.resolve()
      }
      const org = orgs.find((o) => o.id === selectOrgId)
      if (org && selectOrgId) {
        return transferProject(project.id, org.id)
          .catch((err) => {
            if (
              isAPIError(err) &&
              err.statusCode === 400 &&
              err.response.includes('Project is already a part of this organization')
            ) {
              return Promise.resolve(selectOrgId)
            }
            if (isAPIError(err) && err.statusCode >= 400 && err.statusCode < 500) {
              const data = JSON.parse(err.response)
              toast.push({
                title: `Can't transfer to this organization`,
                description: data.message,
                status: 'error',
                duration: 10000,
                closable: true,
              })
            }
            throw err
          })
          .then(async () => {
            if (selectOrgId && selectOrgId !== '#new') {
              if (onOrganizationAssigned) {
                await onOrganizationAssigned(selectOrgId)
              }
              dispatch({type: 'setTransferToOrg', value: org})
              invalidateProject(project.id, {
                invalidateAllProjects: true,
              })
              sendAmplitudeTrackingEvent('Switch Plan Step Completed', project?.id, org?.id, {
                stepName: 'Transfer to organization',
              })
            }
          })
      }
      return Promise.reject(new Error('Organization not found'))
    },
  }
}
