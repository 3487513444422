import {useMutation, useQuery, useQueryClient, UseQueryResult} from '@tanstack/react-query'
import {Subscription} from '../../types/models'
import {promiseRequest} from '../util/promiseRequest'
import {getProjectSubscriptionKey} from './cache'
import {minutesToMilliseconds} from '@/utils/time'
import {fetchProjectPlan} from '../plan'
import {useRequiredProjectPermissions} from '@/context/useProjectPermissions'
import {useRequiredOrganizationPermissions} from '@/context/useOrganizationPermissions'
import {useCurrentScopeContext} from '@/context/index'

export function useProjectSubscription(
  projectId?: string,
  options: {enabled?: boolean} = {}
): UseQueryResult<Subscription> {
  const {loading: isLoadingScope, isLoadingPermissions} = useCurrentScopeContext()
  const canReadProject = useRequiredProjectPermissions(
    [{permissionName: 'sanity.project', grantName: 'read'}],
    projectId
  )
  const canReadOrgProjects = useRequiredOrganizationPermissions([
    {permissionName: 'sanity.organization.projects', grantName: 'read'},
  ])

  const enabledOpt = options.enabled ?? true
  const canReadProjectsInAnOrg: boolean = !!canReadProject || !!canReadOrgProjects
  const isEnabled: boolean =
    !!projectId && !isLoadingScope && !isLoadingPermissions && canReadProjectsInAnOrg && enabledOpt

  return useQuery({
    queryKey: getProjectSubscriptionKey(projectId),
    queryFn: async () => {
      try {
        if (projectId) {
          const res = await fetchProjectPlan(projectId)

          return res
        }
        return null
      } catch (error) {
        return null
      }
    },
    staleTime: minutesToMilliseconds(5),

    enabled: isEnabled,
    retry: false,
  })
}

export function useSubscriptionFeaturesUpdate(projectId?: string) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['updateSubscriptionFeatures', projectId],
    mutationFn: async (features: string[]) => {
      if (projectId === undefined || projectId === '') {
        throw new Error('Project ID is required')
      }

      return promiseRequest<Subscription>(
        `https://api.${process.env.host}/v1/subscriptions/project/${projectId}/features`,
        {
          method: 'PUT',
          json: {
            projectId,
            features: features,
          },
        }
      )
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: getProjectSubscriptionKey(projectId),
      })
    },
  })
}
