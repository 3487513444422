import type {PortableTextMarkComponentProps} from '@portabletext/react'
import styled from 'styled-components'
import {Box} from '@sanity/ui'
import {useDynamicString} from '../../utils/useDynamicString'
import {useRef, useCallback} from 'react'

interface Props {
  _type: 'link'
}

const StyledCard = styled(Box)`
  display: inline-block !important;
  font-size: 0.8em;
  background-color: var(--card-bg-color) !important;
  color: var(--card-fg-color) !important;
  font-weight: bold;

  &:before,
  &:after {
    content: '\`';
  }
`

export function MarkCode(props: PortableTextMarkComponentProps<Props>) {
  const dynamicCode = useDynamicString(props.text)
  const ref = useRef<HTMLDivElement>(null)

  const handleSelect = useCallback(() => {
    if (ref.current) {
      const selection = window.getSelection()
      const range = document.createRange()
      range.selectNodeContents(ref.current)
      selection?.removeAllRanges()
      selection?.addRange(range)
    }
  }, [ref])

  return (
    <StyledCard forwardedAs="code" ref={ref} onClick={handleSelect} role="button">
      {dynamicCode}
    </StyledCard>
  )
}
