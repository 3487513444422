import {Box, Text} from '@sanity/ui'
import React from 'react'
import {useReCaptchaContext} from './ReCaptchaContext'

export const ReCaptchaInfo: React.FC = () => {
  const {enabled} = useReCaptchaContext()
  if (!enabled) return null
  return (
    <Box>
      <Text align="center" size={1} muted as="p">
        This site is protected by reCAPTCHA and the Google{' '}
        <a href="https://policies.google.com/privacy">Privacy Policy</a> and{' '}
        <a href="https://policies.google.com/terms">Terms of Service</a> apply.
      </Text>
    </Box>
  )
}
