import {type MembersV2} from '../../../../../../types/members_v2'
import {Button} from '../../../../primitives/button/Button'
import {getRoleSelectButtonText} from '../../../../utils'
import {RoleSelectMenuButton} from '../../../role-select-menu-button'
import {SHARED_BUTTON_PROPS, SHARED_POPOVER_BUTTON_PROPS} from './constants'
import {FilterSection} from './FilterSection'
import {type BaseFilterProps} from './index'

export interface ProjectFiltersProps extends BaseFilterProps {}

export const ProjectFilters = ({
  projectsData,
  resourceRoles,
  value,
  onValueChange,
}: ProjectFiltersProps) => {
  const handleProjectRoleSelect = (role: MembersV2['projectData']['roleSchemas'][number]) => {
    const [activeProject] = projectsData
    onValueChange((prev) => {
      /**
       * If prev.role exists, then we either add or remove the role from the list
       * depending on if it already exists.
       */
      const roles = prev?.projectRoles || value?.projectRoles || []
      const roleIndex = roles.findIndex((r) => r.name === role.name)

      return {
        /**
         * Set the filter to 'project-access' if it's not already set,
         * however this is unlikely since we do not show the input until
         * the filter has been set.
         */
        filter: 'project-access',
        project: activeProject.projectProfile,
        ...prev,
        projectRoles: roleIndex > -1 ? roles.toSpliced(roleIndex, 1) : [...roles, role],
      }
    })
  }

  /**
   * In a project, there will only be one item in this array.
   */
  const projectRoles = value?.projectRoles ?? []

  return (
    <FilterSection title="Roles">
      <RoleSelectMenuButton
        options={resourceRoles}
        selectedRoles={projectRoles}
        onSelect={handleProjectRoleSelect}
        multiSelect
        popoverButtonProps={{
          ...SHARED_POPOVER_BUTTON_PROPS,
          button: (
            <Button
              {...SHARED_BUTTON_PROPS}
              text={getRoleSelectButtonText(resourceRoles, projectRoles)}
            />
          ),
        }}
      />
    </FilterSection>
  )
}
