import {request, RequestOptions} from '@/data/util/promiseRequest'

/**
 * Validates a hostname according to the following rules:
 * - Must be more than 1 character long
 * - Must start with a letter
 * - Must end with a letter or number
 * - May only contain letters, numbers, and hyphens
 */
export function validateStudioHost(hostname: string): boolean {
  /* Source: https://github.com/sanity-io/populus/blob/bec497c4e97cfeeaa0b63778ac167cda64803cca/src/validators/index.js#L16C15-L16C52 */
  return /^[a-z]([a-z0-9-]{1,}[^-]|[a-z0-9])$/.test(hostname)
}

/**
 * Check if a studio host is available
 * @param subdomain - Subdomain part only, no protocol or domain name
 */
export function getStudioHostAvailability(
  subdomain: string,
  requestOptions?: Omit<RequestOptions, 'url'>
): Promise<boolean | null> {
  if (!subdomain) {
    throw new Error('Subdomain is required')
  }
  if (!validateStudioHost(subdomain)) {
    throw new Error('Invalid hostname. No space or special characters allowed.')
  }
  return request({
    ...requestOptions,
    method: 'GET',
    url: `https://api.${process.env.host}/v1/studiohosts/${subdomain}/availability`,
  }).then((res) => {
    if (!res.ok) {
      throw new Error('Failed to check hostname availability')
    }
    return (res.body as {available: boolean}).available
  })
}
