const UNKNOWN_FUNCTION = '<unknown>'

const chromeRe =
  /^\s*at (.*?) ?\(((?:file|https?|blob|chrome-extension|native|eval|webpack|<anonymous>|\/|[a-z]:\\|\\\\).*?)(?::(\d+))?(?::(\d+))?\)?\s*$/i
const chromeEvalRe = /\((\S*)(?::(\d+))(?::(\d+))\)/

interface StackFrame {
  file: string | null
  fileRelative: string | null
  methodName: string
  arguments: string[]
  lineNumber: number | null
  column: number | null
  isCollapsed: boolean
}

function isProjectFile(file: string): boolean {
  // Check if it's a webpack-internal file
  if (file.startsWith('webpack-internal:///')) {
    // Extract the actual file path
    const filePath = file.replace('webpack-internal:///', '')
    // Check if it's in the project root (starts with ./) and not in node_modules
    return filePath.startsWith('./') && !filePath.includes('node_modules')
  }
  // For non-webpack files, check if it's in the src directory
  return file.includes('/src/')
}

function shouldCollapseFrame(file: string | null): boolean {
  if (!file) return false

  // Don't collapse project files
  if (isProjectFile(file)) return false

  // Collapse node_modules (including webpack-internal node_modules)
  if (file.includes('node_modules')) return true

  // Collapse common library files
  if (file.includes('react-dom') || file.includes('bundle')) return true

  // Collapse other non-project webpack-internal files
  if (file.startsWith('webpack-internal:///') && !isProjectFile(file)) return true

  return false
}

function getRelativePath(file: string | null): string | null {
  if (!file) return null
  return file.replace(/^(?:webpack-internal:\/\/\/|file:\/\/\/|https?:\/\/[^/]+\/)/, '')
}

function parseChrome(line: string): StackFrame | null {
  const parts = chromeRe.exec(line)

  if (!parts) {
    return null
  }

  const isNative = parts[2] && parts[2].indexOf('native') === 0 // start of line
  const isEval = parts[2] && parts[2].indexOf('eval') === 0 // start of line

  const submatch = chromeEvalRe.exec(parts[2])
  if (isEval && submatch != null) {
    // throw out eval line/column and use top-most line/column number
    parts[2] = submatch[1] // url
    parts[3] = submatch[2] // line
    parts[4] = submatch[3] // column
  }

  const file = !isNative ? parts[2] : null
  const isCollapsed = file ? shouldCollapseFrame(file) : false

  return {
    file,
    fileRelative: getRelativePath(file),
    methodName: parts[1] || UNKNOWN_FUNCTION,
    arguments: isNative ? [parts[2]] : [],
    lineNumber: parts[3] ? +parts[3] : null,
    column: parts[4] ? +parts[4] : null,
    isCollapsed,
  }
}

const winjsRe =
  /^\s*at (?:((?:\[object object\])?.+) )?\(?((?:file|ms-appx|https?|webpack|blob):.*?):(\d+)(?::(\d+))?\)?\s*$/i

function parseWinjs(line: string): StackFrame | null {
  const parts = winjsRe.exec(line)

  if (!parts) {
    return null
  }

  const file = parts[2]
  const isCollapsed = shouldCollapseFrame(file)

  return {
    file,
    fileRelative: getRelativePath(file),
    methodName: parts[1] || UNKNOWN_FUNCTION,
    arguments: [],
    lineNumber: +parts[3],
    column: parts[4] ? +parts[4] : null,
    isCollapsed,
  }
}

const geckoRe =
  /^\s*(.*?)(?:\((.*?)\))?(?:^|@)((?:file|https?|blob|chrome|webpack|resource|\[native).*?|[^@]*bundle)(?::(\d+))?(?::(\d+))?\s*$/i
const geckoEvalRe = /(\S+) line (\d+)(?: > eval line \d+)* > eval/i

function parseGecko(line: string): StackFrame | null {
  const parts = geckoRe.exec(line)

  if (!parts) {
    return null
  }

  const isEval = parts[3] && parts[3].indexOf(' > eval') > -1

  if (parts[3]) {
    const submatch = geckoEvalRe.exec(parts[3])
    if (isEval && submatch != null) {
      // throw out eval line/column and use top-most line number
      parts[3] = submatch[1]
      parts[4] = submatch[2]
      parts[5] = '' // no column when eval
    }
  }

  const file = parts[3]
  const isCollapsed = shouldCollapseFrame(file)

  return {
    file,
    fileRelative: getRelativePath(file),
    methodName: parts[1] || UNKNOWN_FUNCTION,
    arguments: parts[2] ? parts[2].split(',') : [],
    lineNumber: parts[4] ? +parts[4] : null,
    column: parts[5] ? +parts[5] : null,
    isCollapsed,
  }
}

const javaScriptCoreRe = /^\s*(?:([^@]*)(?:\((.*?)\))?@)?(\S.*?):(\d+)(?::(\d+))?\s*$/i

function parseJSC(line: string): StackFrame | null {
  const parts = javaScriptCoreRe.exec(line)

  if (!parts) {
    return null
  }

  const file = parts[3]
  const isCollapsed = shouldCollapseFrame(file)

  return {
    file,
    fileRelative: getRelativePath(file),
    methodName: parts[1] || UNKNOWN_FUNCTION,
    arguments: [],
    lineNumber: +parts[4],
    column: parts[5] ? +parts[5] : null,
    isCollapsed,
  }
}

const nodeRe =
  /^\s*at (?:((?:\[object object\])?[^\\/]+(?: \[as \S+\])?) )?\(?(.*?):(\d+)(?::(\d+))?\)?\s*$/i

function parseNode(line: string): StackFrame | null {
  const parts = nodeRe.exec(line)

  if (!parts) {
    return null
  }

  const file = parts[2]
  const isCollapsed = shouldCollapseFrame(file)

  return {
    file,
    fileRelative: getRelativePath(file),
    methodName: parts[1] || UNKNOWN_FUNCTION,
    arguments: [],
    lineNumber: +parts[3],
    column: parts[4] ? +parts[4] : null,
    isCollapsed,
  }
}

/**
 * This parses the different stack traces and puts them into one format
 * This borrows heavily from TraceKit (https://github.com/csnover/TraceKit)
 */
export function parse(stackString: string): StackFrame[] {
  const lines = stackString.split('\n')

  return lines.reduce<StackFrame[]>((stack, line) => {
    const parseResult =
      parseChrome(line) || parseWinjs(line) || parseGecko(line) || parseNode(line) || parseJSC(line)

    if (parseResult) {
      stack.push(parseResult)
    }

    return stack
  }, [])
}
