import {useConfig} from '@/context/index'
import {
  createRequestLogSink,
  deleteRequestLogSink,
  getRequestLogSinks,
  updateRequestLogSink,
} from '@/data/projects'
import {DialogToast} from '@/types/components'
import {Project} from '@/types/models'
import {
  ExternalLink,
  FormField,
  PermissionButtonProject,
  TabSectionHeader,
  useConfirmDialog,
} from '@/ui/index'
import {
  Box,
  Button,
  Card,
  Flex,
  Inline,
  Spinner,
  Stack,
  Text,
  TextInput,
  useToast,
} from '@sanity/ui'
import {useMutation, useQuery, UseQueryResult} from '@tanstack/react-query'
import React from 'react'

type DialogProps = {
  Dialog: React.ElementType
  hideDialog: (dialogToast?: DialogToast) => void
}

const CenteredSpinner = () => {
  return (
    <Flex justify={'center'} align={'center'} padding={4}>
      <Spinner />
    </Flex>
  )
}

const DisableRequestLogDialog = ({hideDialog, Dialog}: DialogProps) => {
  return (
    <Dialog
      id={'disable-request-log-dialog'}
      header={'Remove Request Logs'}
      confirmButtonProps={{text: 'Delete forever', weight: 'regular'}}
      onConfirm={() => {
        hideDialog()
      }}
    >
      <Text>
        Do you really want to remove this bucket? Request logs will no longer be delivered.
      </Text>
    </Dialog>
  )
}

export type Bucket = {
  uri: string | null
  id: string | null
}

const BucketStepsForm = ({
  currentBucketUri,
  onBucketUriSave,
}: {
  currentBucketUri?: string
  onBucketUriSave: (bucketUri: string) => void
}) => {
  const setupSteps = [
    <Inline key={0} space={1}>
      <Text as="p">Step 1. Assign the </Text>
      <Text as="p" weight={'bold'}>
        Storage Object Creator
      </Text>
      <Text as="p">role to your Cloud Storage bucket.</Text>
    </Inline>,
    <Text key={1}>Step 2. Provide the full URI of the Cloud Storage bucket.</Text>,
  ]

  const [formBucketUri, setFormBucketUri] = React.useState<string | undefined>(currentBucketUri)

  return (
    <>
      <Text size={3} weight={'bold'}>
        Steps
      </Text>
      <FormField description={setupSteps[0]}>
        <TextInput
          radius={1}
          readOnly
          weight={'semibold'}
          value={'delivery@sanity-log-delivery.iam.gserviceaccount.com'}
        />
      </FormField>
      <FormField description={setupSteps[1]}>
        <Flex align={'stretch'}>
          <Box flex={1} marginRight={3}>
            <TextInput
              value={formBucketUri}
              radius={1}
              placeholder={'gs://<bucket-name>/key'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFormBucketUri(e.target.value)
              }}
            />
          </Box>
          <Card>
            <Button
              padding={2}
              radius={2}
              disabled={formBucketUri === currentBucketUri}
              tone={'primary'}
              mode={formBucketUri ? 'default' : 'ghost'}
              onClick={() => {
                if (formBucketUri) {
                  onBucketUriSave(formBucketUri)
                }
              }}
            >
              Save
            </Button>
          </Card>
        </Flex>
      </FormField>
    </>
  )
}

const BucketConfigHeader = () => {
  const {baseUrl} = useConfig()
  return (
    <>
      <Text>
        Set up automatic API request log delivery to your Google Cloud Storage bucket for monitoring
        and analysis.{' '}
        <ExternalLink url={`${baseUrl}/docs/request-logs`} text={'Read the setup guide'} />
      </Text>
    </>
  )
}

const BucketConfig = ({
  initialBucket,
  projectId,
}: {
  initialBucket: Bucket | undefined
  projectId: string
}) => {
  const [bucket, setBucket] = React.useState<Bucket | undefined>(initialBucket)
  const [isComponentVisible, setComponentVisible] = React.useState(true)
  const toast = useToast()
  const {Dialog, hideDialog, showDialog} = useConfirmDialog()

  const createMutation = useMutation({
    mutationKey: ['logDelivery', 'create'],
    mutationFn: async (b: Bucket) => createRequestLogSink(projectId, b),
    onSuccess: (createdBucket) => {
      setBucket(createdBucket)
      toast.push({status: 'success', title: 'Saved'})
    },

    onError: (err: Error) => {
      toast.push({status: 'error', title: `Error saving bucket: ${err.message}.`})
    },
  })

  const updateMutation = useMutation({
    mutationKey: ['logDelivery', 'update'],
    mutationFn: async (b: Bucket) => await updateRequestLogSink(projectId, b),
    onSuccess: (updatedBucket) => {
      setBucket(updatedBucket)
      toast.push({status: 'success', title: 'Saved'})
    },

    onError: (err: Error) => {
      toast.push({status: 'error', title: `Error updating bucket: ${err.message}.`})
    },
  })

  const deleteMutation = useMutation({
    mutationKey: ['logDelivery', 'delete'],
    mutationFn: (b: Bucket) => deleteRequestLogSink(projectId, b),
    onSuccess: () => {
      setBucket(undefined)
      setComponentVisible(true)
      toast.push({status: 'success', title: 'Removed'})
      hideDialog()
    },
  })

  const saveBucket = (formBucketURI: string) => {
    const op = bucket?.id ? 'update' : 'create'
    const mutationBucket = {id: bucket?.id ?? null, uri: formBucketURI}
    switch (op) {
      case 'create':
        createMutation.mutate(mutationBucket)
        break
      case 'update':
        updateMutation.mutate(mutationBucket)
        break
      default:
        throw new Error(`Unknown operation ${op}`)
    }
  }

  let component = (
    <Flex>
      <PermissionButtonProject
        title={'Add bucket'}
        hasPermission
        onClick={() => {
          setBucket({id: null, uri: null})
        }}
      />
    </Flex>
  )

  if (bucket) {
    component = (
      <Stack space={[3, 3, 4, 5]}>
        <BucketStepsForm currentBucketUri={bucket.uri ?? undefined} onBucketUriSave={saveBucket} />
        {bucket && bucket.uri && (
          <Flex direction={'row'} justify="flex-start">
            <Button padding={2} mode="ghost" tone={'critical'} onClick={() => showDialog()}>
              Remove bucket
            </Button>
          </Flex>
        )}
        <DisableRequestLogDialog
          hideDialog={() => {
            deleteMutation.mutate(bucket)
            setComponentVisible(false)
            hideDialog()
          }}
          Dialog={Dialog}
        />
      </Stack>
    )
  }

  return (
    <>
      <BucketConfigHeader />
      {isComponentVisible && component}
      {!isComponentVisible && <CenteredSpinner />}
    </>
  )
}

const BucketConfiguration = ({project}: {project: Project}) => {
  const {
    data,
    isLoading,
    isError,
  }: UseQueryResult<{
    buckets: [Bucket]
  }> = useQuery({
    queryKey: ['buckets', project.id],
    queryFn: async () => getRequestLogSinks(project.id),
  })

  if (isLoading) {
    return <CenteredSpinner />
  }

  if (!isError) {
    const bucketAlreadyConfigured = data && data.buckets
    // We currently only support one bucket per project
    if (data && bucketAlreadyConfigured) {
      return <BucketConfig initialBucket={data.buckets[0]} projectId={project.id} />
    }
  }

  return <Text>Could not load buckets</Text>
}

export default function LogDeliverySettings({project, title}: {project: Project; title: string}) {
  const FEATURE_FLAG = 'logDeliveryBucket'
  if (project.features.includes(FEATURE_FLAG)) {
    return (
      <>
        <TabSectionHeader title={title} />
        <BucketConfiguration project={project} />
      </>
    )
  }

  return (
    <>
      <TabSectionHeader title={title} />
      <Card>This feature is not available on your plan. Upgrade to enable Request Logs.</Card>
    </>
  )
}
