import {Box, Button, Card, Flex, Stack, Text, TextInput, useToast} from '@sanity/ui'
import React, {useCallback, useState, useEffect, useMemo} from 'react'
import {EditIcon} from '@sanity/icons'
import styled from 'styled-components'
import {
  FormField,
  Loader,
  TabSectionHeader,
  LoginProvider,
  EditContainer,
  EditablePreview,
} from '@/ui/index'
import {useCurrentUser} from '@/data/users'
import {useForm} from 'react-hook-form'

const IconWrap = styled(Card)`
  border-radius: 50%;
  svg {
    border: 0;
    min-width: 16px;
    min-height: 16px;
  }
`

const Field = ({text, provider, url}: {text: string; provider?: string; url?: string}) => {
  return (
    <Card borderTop borderBottom paddingY={3}>
      <Flex align="center" justify="space-between">
        <Flex gap={3} flex={1}>
          {provider !== 'sanity' && provider && (
            <IconWrap border padding={1}>
              <LoginProvider provider={provider} style={{position: 'static'}} />
            </IconWrap>
          )}
          <Box flex={1} paddingX={3}>
            <Flex gap={2} justify="space-between" flex={1} direction="column">
              <Text>{text}</Text>
              {provider && (
                <Text muted size={0}>
                  {provider === 'sanity' ? (
                    <>Registered with email address</>
                  ) : (
                    <>
                      Registered with <span style={{textTransform: 'capitalize'}}>{provider}</span>{' '}
                      account
                    </>
                  )}
                </Text>
              )}
            </Flex>
          </Box>
        </Flex>
        {url && (
          <Button icon={EditIcon} mode="bleed" as="a" href={url} target="_blank" rel="noreferrer" />
        )}
      </Flex>
    </Card>
  )
}

export function PersonalDetails({title}: {title: string}) {
  const {data, isLoading, patchCurrentUser} = useCurrentUser()
  const {name: initialName = '', provider, email} = data || {}
  const [name, setName] = useState(initialName)
  const [showEditName, setShowEditName] = useState(false)
  const toast = useToast()

  type FormData = {
    firstName: string
    lastName: string
  }

  const defaultValues: FormData = useMemo(() => {
    return {
      firstName: data?.givenName || '',
      lastName: data?.familyName || '',
    }
  }, [data])

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<FormData>({
    defaultValues,
  })

  const handleSave = (data: FormData) => {
    setShowEditName(false)
    patchCurrentUser(
      {givenName: data?.firstName, familyName: data?.lastName},
      {
        onSuccess: () => {
          toast.push({status: 'success', title: `Name successfully updated`})
        },
        onError: () => {
          toast.push({status: 'error', title: 'Failed to update name'})
          setShowEditName(true)
        },
      }
    )
  }

  const handleCancel = useCallback(() => {
    setShowEditName(false)
  }, [])

  useEffect(() => {
    if (data) {
      setName(initialName)
    }
  }, [data, initialName])

  return (
    <Stack space={5}>
      <TabSectionHeader title={title} />

      {isLoading && (
        <Loader
          text="Loading personal details..."
          cardProps={{border: true, radius: 2, padding: 6}}
        />
      )}

      {!isLoading && (
        <Stack space={5}>
          <EditablePreview
            onEdit={() => setShowEditName(true)}
            title="Name"
            description="Display name used across Sanity products"
            isEditing={showEditName}
            hasData={!!name}
            tabSection={false}
          >
            <>
              {!showEditName && (
                <Box padding={3} data-recording-mask>
                  {name && <Text size={2}>{name}</Text>}
                </Box>
              )}
              {showEditName && (
                <Box paddingY={3}>
                  <EditContainer
                    onSave={handleSubmit(handleSave)}
                    onCancel={handleCancel}
                    loading={isLoading}
                  >
                    <FormField label="First name" error={errors?.firstName?.message}>
                      <TextInput
                        autoFocus
                        {...register('firstName', {
                          required: 'A first name is required',
                          minLength: {value: 2, message: 'Must have at least 2 characters'},
                        })}
                      />
                    </FormField>

                    <FormField label="Last name" error={errors?.lastName?.message}>
                      <TextInput
                        {...register('lastName', {
                          required: 'A last name is required',
                          minLength: {value: 2, message: 'Must have at least 2 characters'},
                        })}
                      />
                    </FormField>
                  </EditContainer>
                </Box>
              )}
            </>
          </EditablePreview>
          {provider === 'sanity' ? (
            <>
              <FormField label="Password" description="Can be changed at any time">
                <Field
                  text={'••••••••'}
                  url={`https://accounts.${process.env.host}/resetPassword`}
                />
              </FormField>

              <FormField
                label="Email"
                description="Email address and service used to create a Sanity account"
              >
                <Field provider={provider} text={email || ''} />
              </FormField>
            </>
          ) : (
            <>
              <FormField
                label="Login provider"
                description="Email address and service used to create a Sanity account"
              >
                <Field provider={provider} text={email || ''} />
              </FormField>
            </>
          )}
        </Stack>
      )}
    </Stack>
  )
}
