import {useCurrentScopeContext, useProjectType} from '@/context/index'
import {getPlanCopy} from '@/ui/index'
import {Plan} from '@/types/index'
import {calculateTotalPrice, getProjectMemberState} from '@/utils/index'
import {formatUSD} from '@/utils/number'
import {Badge, Box, Card, Flex, Text} from '@sanity/ui'
import React from 'react'
import styled from 'styled-components'
import {ArrowRightIcon} from '@sanity/icons'
interface Props {
  currentPlan: Plan
  targetPlan: Plan
}

interface CardProps {
  planType: 'current' | 'target'
  plan: Plan
}

const Muted = styled.span`
  color: var(--card-muted-fg-color);
`

const ArrowText = styled(Text)`
  align-self: center;
`

const PriceContainer = styled(Flex)`
  margin-top: auto;
`

const InfoCard = ({planType, plan}: CardProps) => {
  const {project} = useCurrentScopeContext()!
  const projectType = useProjectType(project)
  const billableSeats = getProjectMemberState(project!, projectType, plan.resources).billable
  const isLegacy = plan.supersededAt !== null
  const isPerSeatPlan = plan.pricingModel === 'per-seat'
  const totalPrice = calculateTotalPrice(plan, billableSeats)
  const planCopy = getPlanCopy(plan)
  return (
    <>
      <Card
        tone={planType === 'target' ? 'primary' : undefined}
        padding={3}
        radius={2}
        shadow={1}
        flex={1}
      >
        <Flex direction="column" height="fill" gap={4}>
          <Flex align="center" justify="space-between">
            <Text as="h3" weight="bold">
              {`${isLegacy ? 'Legacy ' : ''}${plan.name}`}
            </Text>
            <Badge>
              <Box padding={3}>
                <Text weight="semibold" size={1}>
                  {planType === 'current' ? 'Current plan' : 'New plan'}
                </Text>
              </Box>
            </Badge>
          </Flex>

          <Box>
            <Text>{planCopy?.description}</Text>
          </Box>

          <PriceContainer paddingY={2} align="center" justify="space-between">
            {totalPrice !== null && (
              <>
                {isPerSeatPlan && totalPrice > 0 ? (
                  <Text muted={planType === 'current' ? true : false} weight="medium">
                    ${plan.price} x {billableSeats} seats
                  </Text>
                ) : (
                  <div></div>
                )}
                <Text weight="bold">
                  {formatUSD(totalPrice)} <Muted>/ month</Muted>
                </Text>
              </>
            )}
          </PriceContainer>
        </Flex>
      </Card>
    </>
  )
}

export function PlanInfoCards({currentPlan, targetPlan}: Props) {
  return (
    <>
      <Flex gap={3} direction={['column', 'column', 'row']}>
        <InfoCard planType="current" plan={currentPlan} />
        <ArrowText muted>
          <ArrowRightIcon />
        </ArrowText>
        <InfoCard planType="target" plan={targetPlan} />
      </Flex>
    </>
  )
}
