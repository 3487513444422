import type {PermissionsConfig, RequiredPermission} from '@/types/permissions'

export function getPermissionsConfig(
  permissionsConfig: PermissionsConfig | RequiredPermission[] | undefined
): PermissionsConfig {
  if (Array.isArray(permissionsConfig)) {
    return {
      permissions: permissionsConfig,
      allowPartialPermissions: false,
    }
  }

  return (
    permissionsConfig || {
      permissions: [],
      allowPartialPermissions: false,
    }
  )
}

interface UserGrants {
  [key: string]: {grants: {name: string}[]}[]
}

export function checkPermissionGrants(
  userGrants: UserGrants | undefined,
  permissionsConfig: PermissionsConfig
): boolean {
  if (!userGrants) return false

  const {permissions, allowPartialPermissions} = permissionsConfig

  if (allowPartialPermissions) {
    const hasMatch = permissions.some((perm) => {
      const permissionGrants = userGrants[perm.permissionName]
      return permissionGrants?.some((permission) =>
        permission.grants.some((g) => g.name === perm.grantName)
      )
    })
    return hasMatch || permissions.length === 0
  }

  return permissions.every((perm) => {
    const permissionGrants = userGrants[perm.permissionName]
    return permissionGrants?.some((permission) =>
      permission.grants.some((g) => g.name === perm.grantName)
    )
  })
}

export function checkAccessPermissions(
  grants: string[] | undefined,
  permissionsConfig: PermissionsConfig
): boolean {
  if (!grants) return false

  const {permissions, allowPartialPermissions} = permissionsConfig

  if (allowPartialPermissions) {
    const hasMatch = permissions.some((perm) =>
      grants.includes(`${perm.permissionName}.${perm.grantName}`)
    )
    return hasMatch || permissions.length === 0
  }

  return permissions.every((perm) => grants.includes(`${perm.permissionName}.${perm.grantName}`))
}
