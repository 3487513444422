import {useQuery, UseQueryResult} from '@tanstack/react-query'
import {OrganizationSSOProvider} from '../../types/models'
import {request} from '../util/promiseRequest'

const version = '2023-01-10'
export function useProjectProviders(projectId?: string): UseQueryResult<OrganizationSSOProvider[]> {
  return useQuery({
    queryKey: ['auth', 'projects', projectId, 'providers'],
    queryFn: async () => {
      if (projectId === undefined) {
        return null
      }

      const res = await request<OrganizationSSOProvider[] | undefined>({
        url: `https://api.${process.env.host}/v${version}/auth/projects/${projectId}/providers`,
      })
      if (res.statusCode === 401) {
        return null
      }
      if (!res.ok) {
        throw new Error(res.body.message)
      }
      return res.body
    },
    staleTime: 30 * 1000,
  })
}
