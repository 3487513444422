import {useQuery} from '@tanstack/react-query'
import {StatusResponse, getApiStatus} from './controller'
import {timeToMilliseconds} from '@/utils/time'
import {useCallback} from 'react'

const loadingStatus: StatusResponse = {
  status: {
    indicator: undefined,
    description: 'Loading…',
  },
  page: {
    url: undefined,
  },
}

const errorStatus: StatusResponse = {
  status: {
    indicator: 'unknown',
    description: 'Unable to fetch status',
  },
  page: {
    url: undefined,
  },
}

export function useApiStatus() {
  const selectData = useCallback((data: StatusResponse | undefined) => data ?? errorStatus, [])

  const {data, isLoading, error} = useQuery<StatusResponse>({
    queryKey: ['apiStatus'],
    queryFn: () => getApiStatus(),
    placeholderData: loadingStatus,
    initialData: loadingStatus,

    refetchOnMount: 'always',
    select: selectData,
    refetchInterval: timeToMilliseconds(1, 'minutes'),
    staleTime: timeToMilliseconds(1, 'minutes'),
  })

  return {data, isLoading, error}
}
