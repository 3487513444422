import {useMutation, useQueryClient} from '@tanstack/react-query'
import {addProjectMemberRole, removeProjectMemberRole} from '.'
import {useProjectUserRolesList} from '@/context/useProjectRolesList'
import {getProjectBaseKey} from './cache'
import {Project} from '@/types/models/project'

export function useProjectMemberRole(projectId: string, userId: string) {
  const {roles: rolesList} = useProjectUserRolesList(projectId)
  const queryKey = getProjectBaseKey(projectId)
  const queryClient = useQueryClient()

  const addRoleMutation = useMutation({
    mutationKey: ['addProjectMemberRole', projectId, userId],
    mutationFn: (roleName: string) => addProjectMemberRole(projectId, userId, roleName),
    onMutate: async (roleName) => {
      await queryClient.cancelQueries({queryKey})
      const previousProject = queryClient.getQueryData<Project>(queryKey)
      queryClient.setQueryData(queryKey, (old: Project | undefined) => {
        if (!old) return old
        return {
          ...old,
          members: old.members.map((member) =>
            member.id === userId
              ? {
                  ...member,
                  roles: [...member.roles, rolesList.find((r) => r.name === roleName)].filter(
                    Boolean
                  ),
                }
              : member
          ),
        }
      })
      return {previousProject}
    },
    onError: (_err, _variables, context) => {
      if (context?.previousProject) {
        queryClient.setQueryData(['project', projectId], context.previousProject)
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({queryKey})
    },
  })

  const removeRoleMutation = useMutation({
    mutationKey: ['removeProjectMemberRole', projectId, userId],
    mutationFn: (roleName: string) => removeProjectMemberRole(projectId, userId, roleName),
    onMutate: async (roleName) => {
      await queryClient.cancelQueries({queryKey})
      const previousProject = queryClient.getQueryData(queryKey)
      queryClient.setQueryData(queryKey, (old: Project) => ({
        ...old,
        members: old.members.map((member: any) =>
          member.id === userId
            ? {...member, roles: member.roles.filter((role: any) => role.name !== roleName)}
            : member
        ),
      }))
      return {previousProject}
    },
    onError: (_err, _variables, context) => {
      if (context?.previousProject) {
        queryClient.setQueryData(queryKey, context.previousProject)
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({queryKey})
    },
  })

  return {
    addProjectRole: addRoleMutation.mutate,
    removeProjectRole: removeRoleMutation.mutate,
    error: addRoleMutation.error || removeRoleMutation.error || undefined,
    isLoading: addRoleMutation.isPending || removeRoleMutation.isPending,
    isSuccess: addRoleMutation.isSuccess || removeRoleMutation.isSuccess,
  }
}
