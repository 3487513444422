import {Spinner} from '@/ui/index'
import {Grid} from '@sanity/ui'
import {AnimatePresence, motion} from 'framer-motion'
import styled from 'styled-components'

interface Props {
  show: boolean
}

const Container = styled(Grid)`
  place-items: center;
  position: absolute;
  inset: 0;
  z-index: 1000;
  background: ${({theme}) => theme.sanity.color.base.bg};
`

export function LoadingOverlay({show}: Props) {
  const FadingContainer = motion(Container)

  return (
    <AnimatePresence initial={false}>
      {show && (
        <FadingContainer
          animate={{opacity: 1}}
          exit={{opacity: 0}}
          transition={{
            duration: 0.2,
            delay: 0.5,
          }}
        >
          <Spinner fontSize={25} />
        </FadingContainer>
      )}
    </AnimatePresence>
  )
}
