import {useInfiniteQuery} from '@tanstack/react-query'
import {useRouter} from 'next/router'
import {addMilliseconds, endOfDay, parseISO} from 'date-fns'
import {useActivityContext} from './useActivityContext'
import {deleteUndefinedFilters, getOldestActivityDate, timeToMilliseconds} from '@/utils/index'
import {ActivityEvent} from '@/types/index'
import {getActivity} from '@/data/activity'
import {useCallback, useMemo, useState} from 'react'
import {lastValueFrom} from 'rxjs'

export function useActivityEventsQuery() {
  const {activeFilter, scopeFilter} = useActivityContext()
  const {query, isReady} = useRouter()
  const [currentPage, setCurrentPage] = useState(0)

  const initialEndTime = useMemo(() => {
    return activeFilter.endTime ? parseISO(activeFilter.endTime) : endOfDay(new Date())
  }, [activeFilter.endTime])

  const fetchActivities = useCallback(
    async ({pageParam}) => {
      const filter = deleteUndefinedFilters({
        ...(query?.projectId
          ? {projectId: query?.projectId ? [query.projectId] : undefined}
          : {organizationId: query?.orgId ? [query.orgId] : undefined}),
        ...activeFilter,
        ...scopeFilter,
        endTime: addMilliseconds(pageParam, -2).toISOString(),
      })

      return lastValueFrom(getActivity(filter))
    },
    [query, activeFilter, scopeFilter]
  )
  const {tabId: _tabId, ...restQuery} = query

  const {data, error, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage, isLoading} =
    useInfiniteQuery<ActivityEvent[], Error>({
      queryKey: ['activities', activeFilter, scopeFilter, restQuery],
      queryFn: fetchActivities,
      initialPageParam: initialEndTime,
      staleTime: timeToMilliseconds(2, 'minutes'),
      enabled: isReady,
      getNextPageParam: (lastPage) => {
        if (lastPage.length === 0) return undefined
        return getOldestActivityDate(lastPage)
      },
    })

  const events = data?.pages.flat() || []

  const nextPage = useCallback(() => {
    fetchNextPage().then(() => {
      setCurrentPage((prevPage) => prevPage + 1)
    })
  }, [fetchNextPage])

  const prevPage = useCallback(() => {
    if (currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1)
    }
  }, [currentPage])

  return {
    currentPage,
    loading: isLoading,
    data: events,
    error,
    lastUpdated: new Date(),
    hasNextPage,
    hasPrevPage: currentPage > 0,
    nextPage,
    prevPage,
    isFetching,
    isFetchingNextPage,
  }
}
