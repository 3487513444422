import {OrganizationPaymentState, PaymentState} from '@/types/models/paymentstate'

export type BannerStatus = 'block' | 'warn' | 'can_resolve'

export type PaymentStateDisplay = {
  organizationId: string
  isBillingAdmin: boolean
  status: BannerStatus
  reviewAt?: string
}

export function isDisplayablePaymentState(apiState: string): boolean {
  switch (apiState) {
    case 'payment_due':
    case 'pending_blocked':
    case 'payment_overdue':
    case 'can_resolve':
      return true
    default:
      return false
  }
}

export function getDisplayablePaymentState(
  paymentState: OrganizationPaymentState,
  isBillingAdmin: boolean
): PaymentStateDisplay | undefined {
  const remap = function (apiState: PaymentState): BannerStatus | undefined {
    switch (apiState) {
      case 'payment_due':
      case 'pending_blocked':
        return 'warn'
      case 'payment_overdue':
        return 'block'
      case 'can_resolve':
        return 'can_resolve'
      case 'never_in_arrears':
      case 'identified':
      case 'in_review':
      case 'ignored':
      case 'resolved':
      default:
        return undefined
    }
  }
  const status = remap(paymentState.paymentState)
  if (!status) {
    return undefined
  }

  let reviewAtString
  if (paymentState.reviewAt) {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }
    const blockDate = new Date(paymentState.reviewAt)
    reviewAtString = blockDate.toLocaleDateString('en-US', options)
  }

  return {
    organizationId: paymentState.organizationId,
    isBillingAdmin: isBillingAdmin,
    status,
    reviewAt: reviewAtString,
  }
}
