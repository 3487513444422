import React from 'react'
import {Box, Container, Grid} from '@sanity/ui'
import {ActivityFilter, ActivityList} from '.'
import {ActivityHead, Loader} from '@/ui/index'
import {useMatchesMedia} from '@/utils/index'
import {useActivityEventsQuery} from '@/context/useActivityEventsQuery'

type Props = {
  header?: React.ReactNode
}

export function ActivityFeed({header}: Props) {
  const isMobile = useMatchesMedia(2)
  const {
    data: events,
    loading,
    nextPage,
    hasNextPage,
    lastUpdated,
    currentPage,
    isFetchingNextPage,
  } = useActivityEventsQuery()

  return (
    <Grid columns={[1, 1, 1, 4]} gap={[2, 2, 2, 6]}>
      {header && (
        <Box columnStart={[1, 1, 1, 2]} columnEnd={-1}>
          {header}
        </Box>
      )}
      <Box columnStart={1} columnEnd={[1, 1, 1, 2]} marginY={[4, 4, 0]}>
        <ActivityFilter isMobile={isMobile} />
      </Box>
      <Box columnStart={[1, 1, 1, 2]} columnEnd={-1}>
        <Container>
          {!isMobile && <ActivityHead />}
          {loading ? (
            <Box padding={6}>
              <Loader />
            </Box>
          ) : (
            <ActivityList
              compact={isMobile}
              hasNextPage={hasNextPage}
              lastUpdated={lastUpdated}
              currentPage={currentPage}
              events={events}
              onNextPage={nextPage}
              loading={isFetchingNextPage}
            />
          )}
        </Container>
      </Box>
    </Grid>
  )
}
