import React, {useState} from 'react'
import {AvatarStack, type CardTone, Heading, Inline, Stack} from '@sanity/ui'
import {useTimeAgo} from '../../../utils'
import {
  TableCell,
  TableRow,
  MemberAvatar,
  PlanBadge,
  TableCellCheckbox,
  // useBulkSelect,
} from '@/ui/index'
import type {Project} from '@/types/index'

type Props = {
  project: Project
  onSelect: () => void
  tone: CardTone
}

export const ConfirmProjectRow = ({project, tone, onSelect}: Props) => {
  const [selected, setSelected] = useState<boolean>(false)
  const created = useTimeAgo(project?.createdAt || '', {minimal: true})
  const members = project.members.filter((member) => !member.isRobot)

  const handleSelect = () => {
    setSelected(!selected)
    onSelect()
  }
  return (
    <TableRow
      paddingY={3}
      paddingX={2}
      selected={selected}
      selectedTone={tone}
      radius={2}
      marginTop={1}
    >
      <TableCellCheckbox
        onChange={handleSelect}
        checked={selected}
        label={`Select ${project.displayName}`}
      />
      <TableCell>
        <Stack space={2}>
          <Heading size={1}>{project.displayName}</Heading>
          <Inline>
            <PlanBadge title="advanced" />
          </Inline>
        </Stack>
      </TableCell>
      <TableCell>
        <AvatarStack maxLength={3}>
          {members.map((member) => (
            <MemberAvatar key={member.id} userId={member.id} />
          ))}
        </AvatarStack>
      </TableCell>
      <TableCell $align="right">{created}</TableCell>
    </TableRow>
  )
}
