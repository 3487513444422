import {Button, Card, Stack, Text} from '@sanity/ui'
import {useGettingStartedStore} from '../store'
import styled from 'styled-components'
import {sendAmplitudeTrackingEvent} from '@/utils/tracking'
import {useCurrentScopeContext} from '@/context/useCurrentScopeContext'
import type {Persona} from '../store/types'
import {useProjectOnboardingData} from '@/data/journey/useProjectOnboardingData'

const DescriptionText = styled(Text)`
  max-width: 65ch;
  margin: 0 auto;
`

interface Variant {
  title: string
  description: string
  switchLink: string
  switchTo: Persona
}

export function GetStartedHeading() {
  const {projectId, org} = useCurrentScopeContext()
  const {state, dispatch} = useGettingStartedStore()
  const {data: projectOnboardingData} = useProjectOnboardingData()

  const VARIANTS: Record<Persona, Variant> = {
    developer: {
      title: "You're just a few steps away from setting up your project",
      description: "Let's get you ready to use Sanity with your preferred front end framework.",
      switchLink: 'Not a developer?',
      switchTo: 'non-developer',
    },
    'non-developer': {
      title: 'Next steps for your Sanity project',
      description:
        'Invite a developer to complete the project setup, or check out one of our demos.',
      switchLink: 'Switch to developer setup',
      switchTo: projectOnboardingData?.templateSlug ? 'template' : 'developer',
    },
    template: {
      title: "You're just a few steps away from setting up your project",
      description: "Let's get you ready to use Sanity with the template.",
      switchLink: 'Not a developer?',
      switchTo: 'non-developer',
    },
  }

  const currentVariant = VARIANTS[state.persona] || VARIANTS.developer

  const handleSwitchPath = () => {
    const newPersona = currentVariant.switchTo
    dispatch({type: 'getStarted/setPersona', value: newPersona})
    sendAmplitudeTrackingEvent('Getting Started Path Switched', projectId, org?.id, {
      persona: newPersona,
    })
  }

  return (
    <Card marginTop={1} marginBottom={5}>
      <Stack space={4}>
        <Text align="center">
          <Button mode="bleed" tone="primary" paddingY={1} onClick={handleSwitchPath}>
            {currentVariant.switchLink}
          </Button>
        </Text>

        <Text size={4} weight="bold" align="center">
          {currentVariant.title}
        </Text>
        <DescriptionText size={2} align="center" muted>
          {currentVariant.description}
        </DescriptionText>
      </Stack>
    </Card>
  )
}
