import {nameToInitials} from '@/components/members/utils'
import {useRequiredProjectPermissions} from '@/context/index'
import {addProjectMembersRole, removeProjectMemberRole} from '@/data/projects'
import {useUsers} from '@/data/users/useUsers'
import {isAPIError} from '@/data/util/promiseRequest'
import {Project, ProjectMember, ProjectMemberRole, User} from '@/types/models'
import {
  AvatarWithFallback,
  LoginProvider,
  PermissionButtonProject,
  ProjectUsagePrompt,
} from '@/ui/index'
import {AddIcon, CloseIcon, InfoOutlineIcon, SearchIcon, TrashIcon} from '@sanity/icons'
import {
  AvatarProps,
  Badge,
  Box,
  Button,
  Card,
  Dialog,
  Flex,
  Inline,
  Layer,
  Spinner,
  Stack,
  Text,
  TextInput,
  Tooltip,
  useToast,
} from '@sanity/ui'
import {useCallback, useEffect, useMemo, useState} from 'react'

function UserAvatar({user, ...rest}: {user: User | null | undefined} & AvatarProps) {
  return (
    <Box style={{position: 'relative'}}>
      <AvatarWithFallback
        src={user?.imageUrl || undefined}
        initials={nameToInitials(user?.displayName)}
        {...rest}
      />
      {user && user.provider !== 'sanity' && <LoginProvider provider={user.provider} />}
    </Box>
  )
}

function projectMemberSort(usersMap: Record<string, User>) {
  return (a: ProjectMember, b: ProjectMember) => {
    const aDisplayName = usersMap[a.id]?.displayName || a.id
    const bDisplayName = usersMap[b.id]?.displayName || b.id
    const order = aDisplayName.localeCompare(bDisplayName)
    if (order !== 0) {
      return order
    }
    const aEmail = usersMap[a.id]?.email || a.id
    const bEmail = usersMap[b.id]?.email || b.id
    return aEmail.localeCompare(bEmail)
  }
}

function ViewRoleMember({
  member,
  hasMemberUpdatePermission,
  user,
  onMemberRemove,
  loading,
}: {
  member: ProjectMember
  hasMemberUpdatePermission: boolean
  user: User
  onMemberRemove: (_member: ProjectMember) => void
  loading: boolean
}) {
  const handleRemoveUserFromRole = useCallback(() => {
    onMemberRemove(member)
  }, [member, onMemberRemove])

  return (
    <Card paddingX={2}>
      <Flex align="center" gap={5} paddingY={2}>
        <Flex align="center" flex={2.5}>
          <Box marginRight={3}>
            <UserAvatar user={user} size={1} />
          </Box>
          <Stack space={2}>
            <Inline space={2}>
              <Text>{user?.displayName}</Text>
              {member.isCurrentUser && <Badge mode="outline">You</Badge>}
            </Inline>
            <Text muted size={1}>
              {user?.email}
            </Text>
          </Stack>
        </Flex>
        <Box>
          {member.roles.length === 1 && (
            <Tooltip
              content={
                <Stack padding={4} space={4}>
                  <Inline space={2}>
                    <Text muted>
                      <InfoOutlineIcon />
                    </Text>
                    <Text size={2} weight="bold">
                      Unable to remove member
                    </Text>
                  </Inline>
                  <Text size={1} muted>
                    This is the member's only role. <br />
                    All project members must have at least one role.
                  </Text>
                </Stack>
              }
            >
              <Box>
                <Button icon={TrashIcon} mode="bleed" tone="critical" disabled />
              </Box>
            </Tooltip>
          )}
          {member.roles.length > 1 && (
            <PermissionButtonProject
              icon={TrashIcon}
              onClick={handleRemoveUserFromRole}
              mode="bleed"
              tone="critical"
              hasPermission={hasMemberUpdatePermission}
              loading={loading}
            />
          )}
        </Box>
      </Flex>
    </Card>
  )
}

function ViewRoleMembers({
  onAdd,
  members,
  hasMemberUpdatePermission,
  usersMap,
  onMemberRemove,
  loading,
  disableAdd,
  roleName,
}: {
  onAdd: () => void
  members: ProjectMember[]
  hasMemberUpdatePermission: boolean
  usersMap: Record<string, User>
  onMemberRemove: (_member: ProjectMember) => void
  loading: boolean
  disableAdd: boolean
  roleName: string
}) {
  const [filter, setFilter] = useState<string>('')

  const filteredMembers = useMemo(() => {
    return members.filter((member) => {
      const user = usersMap[member.id]

      if (filter) {
        // fuzzy match using lower case everything
        const name = user.displayName.toLowerCase()
        const email = user.email?.toLowerCase() || ''

        if (!name.includes(filter) && !email.includes(filter)) return false
      }
      return true
    })
  }, [members, filter, usersMap])

  const handleFilterChange = useCallback((evt) => {
    setFilter(evt.target.value.toLowerCase())
  }, [])

  return (
    <Flex direction="column" height="fill" paddingX={4} paddingY={3}>
      {roleName !== 'administrator' && (
        <Box paddingBottom={3}>
          <ProjectUsagePrompt resourcesToCheck={['users', 'non_admin_users']} context="members" />
        </Box>
      )}
      <Card>
        {members.length > 0 && (
          <Flex justify="flex-end">
            <PermissionButtonProject
              icon={AddIcon}
              mode="ghost"
              onClick={onAdd}
              title="Add members"
              hasPermission={hasMemberUpdatePermission}
              loading={loading}
              disabled={disableAdd}
            />
          </Flex>
        )}
      </Card>
      <Card border radius={2} marginTop={3} overflow="hidden">
        <Box flex={1}>
          <Card borderBottom>
            <Layer>
              <TextInput
                placeholder="Filter by name or email"
                icon={SearchIcon}
                border={false}
                padding={3}
                onChange={handleFilterChange}
                disabled={members.length === 0}
              />
            </Layer>
          </Card>
          {filteredMembers.length === 0 && filter && (
            <Box paddingX={4} paddingY={5}>
              <Text align="center" muted>
                No members matching search
              </Text>
            </Box>
          )}
          {filteredMembers.length === 0 && !filter && (
            <Flex paddingX={4} paddingY={5} justify="center">
              <Stack space={4}>
                <Text align="center" muted>
                  There are no members with this role
                </Text>
                <Flex justify="center">
                  <PermissionButtonProject
                    icon={AddIcon}
                    onClick={onAdd}
                    title="Add members"
                    mode="default"
                    tone="primary"
                    hasPermission={hasMemberUpdatePermission}
                    disabled={disableAdd}
                  />
                </Flex>
              </Stack>
            </Flex>
          )}
          {filteredMembers.length > 0 && (
            <Stack style={{maxHeight: 350, overflow: 'auto'}}>
              {filteredMembers.map((member) => (
                <ViewRoleMember
                  key={member.id}
                  member={member}
                  hasMemberUpdatePermission={hasMemberUpdatePermission}
                  user={usersMap[member.id]}
                  onMemberRemove={onMemberRemove}
                  loading={loading}
                />
              ))}
            </Stack>
          )}
        </Box>
      </Card>
    </Flex>
  )
}

function AddedMember({onRemove, user}: {onRemove: (id: string) => void; user: User}) {
  return (
    <Box>
      <Card tone="transparent" padding={1} radius={3}>
        <Flex align="center" gap={1}>
          <Box marginRight={1}>
            <UserAvatar user={user} size={0} />
          </Box>
          <Text size={1}>{user?.displayName}</Text>
          <Button
            icon={<CloseIcon />}
            // eslint-disable-next-line react/jsx-no-bind
            onClick={() => onRemove(user?.id)}
            fontSize={1}
            padding={1}
            mode="bleed"
          />
        </Flex>
      </Card>
    </Box>
  )
}

function AvailableToAddMember({
  user,
  isCurrentUser,
  onSelect,
}: {
  user: User
  isCurrentUser: boolean
  onSelect: (id: string) => void
}) {
  return (
    <Card
      as="button"
      paddingX={2}
      paddingY={2}
      // eslint-disable-next-line react/jsx-no-bind
      onClick={() => onSelect(user.id)}
      tone="default"
      radius={2}
    >
      <Flex align="center" flex={1}>
        <Box marginRight={3}>
          <UserAvatar user={user} size={1} />
        </Box>
        <Stack space={2}>
          <Inline space={2}>
            <Text>{user?.displayName}</Text>
            {isCurrentUser && <Badge mode="outline">You</Badge>}
          </Inline>
          <Text muted size={1}>
            {user?.email}
          </Text>
        </Stack>
      </Flex>
    </Card>
  )
}

function AddRoleMembers({
  project,
  role,
  onBack,
  usersMap,
  onMembersAdd,
}: {
  project: Project
  role: ProjectMemberRole
  onBack: () => void
  usersMap: Record<string, User>
  onMembersAdd: (_members: ProjectMember[]) => void
}) {
  const [filter, setFilter] = useState<string>('')
  const [newMembers, setNewMembers] = useState<ProjectMember[]>([])

  const availableMembers = useMemo(() => {
    return project.members
      .filter((member) => {
        const user = usersMap[member.id]
        // don't include tokens in the members list
        if (member.isRobot) return false
        // don't include members who already have the current role
        if (member.roles.some((rol) => rol.name === role.name)) return false
        // don't include members who we have already marked to be added
        if (newMembers.some((m) => m.id === member.id)) return false
        // don't include unknown users
        if (!user) return false
        // don't include items which don't match the filter
        if (filter) {
          // fuzzy match using lower case everything
          const name = user.displayName.toLowerCase()
          const email = user.email?.toLowerCase() || ''

          if (!name.includes(filter) && !email.includes(filter)) return false
        }
        return true
      })
      .sort(projectMemberSort(usersMap))
  }, [project.members, newMembers, role.name, filter, usersMap])

  const handleAddMember = useCallback(
    (id) => {
      const user = usersMap[id]
      if (user === undefined) return
      setNewMembers([
        ...newMembers,
        {
          id: id,
          role: role.name,
          roles: [role],
          isRobot: false,
          isCurrentUser: false,
          createdAt: user.createdAt,
          updatedAt: user.updatedAt,
        },
      ])
    },
    [newMembers, usersMap, role]
  )

  const handleRemoveMember = useCallback(
    (id) => {
      const update = newMembers.filter((member) => member.id !== id)
      setNewMembers(update)
    },
    [newMembers]
  )

  const handleUpdateMemberRoles = useCallback(() => {
    onMembersAdd(newMembers)
  }, [newMembers, onMembersAdd])

  const handleSearchChange = useCallback((evt) => {
    return setFilter(evt.target.value.toLowerCase())
  }, [])

  return (
    <Flex direction="column" height="fill">
      <Box marginTop={2} marginLeft={2}>
        <Button fontSize={1} text="← Members" mode="bleed" tone="primary" onClick={onBack} />
      </Box>
      <Box flex={1} overflow="auto">
        <Box paddingY={2} paddingX={2}>
          <Flex direction="column">
            <Flex marginBottom={3} align="flex-start" gap={2}>
              <Card padding={1} border style={{minHeight: '2.2rem'}} flex={1} radius={2}>
                <Flex gap={1} wrap="wrap">
                  {newMembers.map((member) => (
                    <AddedMember
                      key={member.id}
                      user={usersMap[member.id]}
                      onRemove={handleRemoveMember}
                    />
                  ))}
                </Flex>
              </Card>
              <Box>
                <Button
                  text="Add"
                  tone="primary"
                  disabled={newMembers.length === 0}
                  type="submit"
                  onClick={handleUpdateMemberRoles}
                />
              </Box>
            </Flex>
            <Card border marginBottom={2} radius={2} overflow="hidden">
              <Card borderBottom>
                <Layer>
                  <TextInput
                    border={false}
                    icon={SearchIcon}
                    placeholder="Filter by name or email"
                    onChange={handleSearchChange}
                    padding={3}
                    radius={2}
                  />
                </Layer>
              </Card>
              <Stack style={{maxHeight: 350, overflow: 'auto'}}>
                {availableMembers.map((member) => (
                  <AvailableToAddMember
                    key={member.id}
                    user={usersMap[member.id]}
                    isCurrentUser={member.isCurrentUser}
                    onSelect={handleAddMember}
                  />
                ))}
                {availableMembers.length === 0 && (
                  <Flex padding={5} justify="center">
                    <Text muted>
                      {filter ? 'No members matching filter' : 'No more project members to select'}
                    </Text>
                  </Flex>
                )}
              </Stack>
            </Card>
          </Flex>
        </Box>
      </Box>
    </Flex>
  )
}

type Props = {
  onClose: () => void
  project: Project
  role: ProjectMemberRole
  members: ProjectMember[]
  open: boolean
  disableAdd?: boolean
}

export function ManageRoleMembersDialog({
  onClose,
  project,
  role,
  members,
  open,
  disableAdd = false,
}: Props) {
  const toast = useToast()
  const [state, setState] = useState<'view' | 'add'>('view')
  const [currentMembers, setCurrentMembers] = useState<ProjectMember[]>([])
  const [loadingMember, setLoadingMember] = useState(false)

  const hasMemberUpdatePermission = useRequiredProjectPermissions([
    {permissionName: 'sanity.project.members', grantName: 'update'},
  ])

  const {
    data: users,
    isLoading: loadingUsers,
    error: errorUsers,
  } = useUsers(project.members.map((member) => member.id))

  const loading = loadingUsers || loadingMember

  useEffect(() => {
    const current = members.filter((member) => !member.isRobot)
    setCurrentMembers(current)
  }, [members])

  useEffect(() => {
    if (errorUsers) {
      toast.push({title: errorUsers.name, description: errorUsers.message, status: 'error'})
    }
  }, [toast, errorUsers])

  const usersMap = useMemo(() => {
    if (!users) return {}
    return users.reduce(
      (acc, user) => {
        if (user) {
          acc[user.id] = user
        }
        return acc
      },
      {} as Record<string, User>
    )
  }, [users])

  const contextMembers = useMemo(() => {
    return currentMembers.sort(projectMemberSort(usersMap))
  }, [currentMembers, usersMap])

  const setStateView = useCallback(() => {
    setState('view')
  }, [setState])

  const setStateAdd = useCallback(() => {
    if (hasMemberUpdatePermission) {
      return setState('add')
    }
    return setState('view')
  }, [setState, hasMemberUpdatePermission])

  const onCloseImpl = useCallback(() => {
    setState('view')
    onClose()
  }, [onClose, setState])

  const handleOnMemberRemove = useCallback(
    async (toRemove: ProjectMember) => {
      // check if current user has permission for action
      if (!hasMemberUpdatePermission || loading) {
        return
      }
      setLoadingMember(true)
      const update = currentMembers.filter((currentMember) => currentMember.id !== toRemove.id)
      setCurrentMembers(update)
      try {
        await removeProjectMemberRole(project.id, toRemove.id, role.name)
        setLoadingMember(false)
        toast.push({title: 'Project member removed', status: 'success'})
      } catch (error) {
        setLoadingMember(false)

        if (isAPIError(error)) {
          toast.push({title: error.name, description: error.message, status: 'error'})
        } else {
          toast.push({title: 'Error', description: 'An error occurred', status: 'error'})
        }
      }
    },
    [currentMembers, hasMemberUpdatePermission, loading, project.id, role.name, toast]
  )

  const handleOnMembersAdd = useCallback(
    async (toAdd: ProjectMember[]) => {
      // check if current user has permission for action
      if (!hasMemberUpdatePermission || loading) {
        return
      }
      setLoadingMember(true)
      setCurrentMembers([...currentMembers, ...toAdd])
      try {
        await addProjectMembersRole(
          project.id,
          toAdd.map((member) => member.id),
          role.name
        )
        setStateView()
        setLoadingMember(false)
        toast.push({title: 'Project member(s) added', status: 'success'})
      } catch (error) {
        setLoadingMember(false)
        if (isAPIError(error)) {
          toast.push({title: error.name, description: error.message, status: 'error'})
        } else {
          toast.push({title: 'Error', description: 'An error occurred', status: 'error'})
        }
      }
    },
    [hasMemberUpdatePermission, loading, currentMembers, project.id, role.name, toast, setStateView]
  )

  if (!open) return <></>

  if (loadingUsers)
    return (
      <Dialog
        header={`Manage members of ${role.title}`}
        id="manage-role-members-dialog"
        onClickOutside={onCloseImpl}
        onClose={onCloseImpl}
        width={1}
      >
        <Spinner />
      </Dialog>
    )

  return (
    <Dialog
      header={`Manage members of ${role.title}`}
      id="manage-role-members-dialog"
      onClickOutside={onCloseImpl}
      onClose={onCloseImpl}
      width={1}
    >
      {state === 'view' && (
        <ViewRoleMembers
          roleName={role.name}
          onAdd={setStateAdd}
          members={contextMembers}
          hasMemberUpdatePermission={hasMemberUpdatePermission}
          usersMap={usersMap}
          onMemberRemove={handleOnMemberRemove}
          loading={loading}
          disableAdd={disableAdd}
        />
      )}
      {state === 'add' && (
        <AddRoleMembers
          project={project}
          role={role}
          onBack={setStateView}
          usersMap={usersMap}
          onMembersAdd={handleOnMembersAdd}
        />
      )}
    </Dialog>
  )
}
