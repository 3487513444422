import {useCurrentScopeContext} from '@/context/useCurrentScopeContext'
import {useProject, useProjectSubscription} from '@/data/index'
import {useProjectOnboardingData} from '@/data/journey/useProjectOnboardingData'

type ReturnValue = [isProjectInOnboardingXp: boolean, isLoading: boolean]

const FEATURE_ENABLE_TIMESTAMP = 1729675051629 // 2024-10-23T09:15:51.629Z

const isSelfServeProject = (planName: string) => {
  return planName.toLowerCase().includes('free') || planName.toLowerCase().includes('growth')
}

export function useIsProjectInOnboardingXp(): ReturnValue {
  const {projectId} = useCurrentScopeContext()
  const {data: projectData, isLoading: isProjectLoading} = useProject(projectId)
  const {data: projectSubscriptionData, isLoading: isProjectSubscriptionLoading} =
    useProjectSubscription(projectId)
  const {data: projectOnboardingData, isLoading: isProjectOnboardingLoading} =
    useProjectOnboardingData()
  const isLoading = isProjectLoading || isProjectSubscriptionLoading || isProjectOnboardingLoading

  // Always show getting started for self serve projects created after 2024-10-23 at approx 09:15 UTC when this feature was enabled
  if (
    !projectOnboardingData?.disabled &&
    projectData?.createdAt &&
    new Date(projectData.createdAt).getTime() > FEATURE_ENABLE_TIMESTAMP &&
    isSelfServeProject(projectSubscriptionData?.plan?.name ?? '')
  ) {
    return [true, false]
  }
  // Also show for projects that were part of AB test before feature enabled for all projects
  if (projectOnboardingData?.variant === 'onboarding-xp-q3-24' && !projectOnboardingData.disabled)
    return [true, isLoading]

  return [false, isLoading]
}
