import {hues} from '@sanity/color'
import {type Theme} from '@sanity/ui/theme'
import {type ForwardedRef, forwardRef, type HTMLProps} from 'react'
import styled, {css} from 'styled-components'

import {Button, type ButtonProps} from '../../primitives/button/Button'

/** Only ghost and bleed modes are supported at the moment */
type ColorButtonMode = 'ghost' | 'bleed'

interface StyledButtonProps {
  $color: keyof typeof hues
  $mode: ColorButtonMode
  theme: Theme
}

const getTints = ({isDark, $mode}: {isDark: boolean; $mode: ButtonProps['mode']}) => {
  if ($mode === 'ghost') {
    return {
      fgTint: isDark ? 300 : 700,
      fgHoveredTint: isDark ? 200 : 800,
      fgPressedTint: isDark ? 200 : 800,
      bgTint: isDark ? 950 : 50,
      bgHoveredTint: isDark ? 900 : 100,
      bgPressedTint: isDark ? 800 : 200,
      borderTint: isDark ? 800 : 200,
      borderHoveredTint: isDark ? 700 : 300,
      borderPressedTint: isDark ? 700 : 300,
    }
  }

  return {
    fgTint: isDark ? 400 : 600,
    fgHoveredTint: isDark ? 300 : 700,
    fgPressedTint: isDark ? 200 : 800,
    bgTint: undefined,
    bgHoveredTint: isDark ? 950 : 50,
    bgPressedTint: isDark ? 900 : 100,
    borderTint: undefined,
    borderHoveredTint: undefined,
    borderPressedTint: undefined,
  }
}
export const StyledButton = styled(Button)<StyledButtonProps>(({
  theme,
  $color,
  $mode = 'bleed',
}) => {
  const isDark = theme.sanity.color.dark

  const tints = getTints({isDark, $mode})

  return css`
    &:not([data-disabled='true']) {
      --card-bg-color: ${tints.bgTint ? hues[$color][tints.bgTint].hex : 'transparent'};
      --card-border-color: ${tints.borderTint ? hues[$color][tints.borderTint].hex : 'transparent'};
      --card-fg-color: ${hues[$color][tints.fgTint].hex};
      --card-icon-color: ${hues[$color][tints.fgTint].hex};

      @media (hover: hover) {
        &:hover {
          --card-bg-color: ${hues[$color][tints.bgHoveredTint].hex};
          --card-border-color: ${tints.borderHoveredTint
            ? hues[$color][tints.borderHoveredTint].hex
            : 'transparent'};
          --card-fg-color: ${hues[$color][tints.fgHoveredTint].hex};
          --card-icon-color: ${hues[$color][tints.fgHoveredTint].hex};
        }

        &:active {
          --card-bg-color: ${hues[$color][tints.bgPressedTint].hex};
          --card-border-color: ${tints.borderPressedTint
            ? hues[$color][tints.borderPressedTint].hex
            : 'transparent'};
          --card-fg-color: ${hues[$color][tints.fgPressedTint].hex};
          --card-icon-color: ${hues[$color][tints.fgPressedTint].hex};
        }
      }
    }
  `
})
type ColorButtonProps = {
  color: keyof typeof hues
  mode: ColorButtonMode
}

export const ColorButton = forwardRef(function ColorButton(
  props: ColorButtonProps &
    ButtonProps &
    Omit<HTMLProps<HTMLButtonElement>, 'as' | 'size' | 'title' | 'mode'>,
  ref: ForwardedRef<HTMLButtonElement>
) {
  const {as, color, mode, ...rest} = props

  return <StyledButton {...rest} forwardedAs={as} $color={color} $mode={mode} ref={ref} />
})
