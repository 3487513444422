import React, {forwardRef} from 'react'
import {Checkbox, Text, Box, Flex} from '@sanity/ui'

type Props = {
  label: string
}

// eslint-disable-next-line react/display-name
export const CheckboxInput = forwardRef(
  (
    {label, ...restProps}: Props & Omit<React.HTMLProps<HTMLInputElement>, 'ref'>,
    ref: React.Ref<HTMLInputElement>
  ) => {
    return (
      <Flex align="center" as="label">
        <Checkbox {...restProps} ref={ref} />
        <Box marginLeft={3}>
          <Text size={1}>{label}</Text>
        </Box>
      </Flex>
    )
  }
)
