import React, {useCallback, useEffect, useMemo, useReducer} from 'react'
import {useCurrentScopeContext} from '@/context/index'
import {getDefaultPlans} from '@/data/plan'
import {Portal} from '@sanity/ui'
import {useSearchParams} from 'next/navigation'

import {CheckoutDialog} from '../checkoutDialog'
import {INITIAL_STATE} from '../constants'
import {reducer} from './reducer'
import {SwitchPlanStoreContext} from './storeContext'
import {DowngradeDialog} from '../downgradeDialog'

export function SwitchPlanStoreProvider({children}: {children?: React.ReactNode}) {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE)

  const contextValue = useMemo(() => ({dispatch, state}), [dispatch, state])

  /* Handle search query */
  const scope = useCurrentScopeContext()
  const searchParams = useSearchParams()
  const planId = searchParams.get('checkout')

  const getAndSetPlan = useCallback(async () => {
    try {
      const plans = await getDefaultPlans()
      const value = plans.find((plan) => plan.planTypeId === planId)
      if (!value) {
        throw new Error(`Plan with id ${planId} not found`)
      }
      dispatch({type: 'setTargetPlan', value})
    } catch (error) {
      console.error('Failed to fetch plan', error)
    }
  }, [planId])

  useEffect(() => {
    if (planId && scope?.org && scope?.project) {
      getAndSetPlan()
    }
  }, [planId, scope])

  return (
    <SwitchPlanStoreContext.Provider value={contextValue}>
      {children}
      <Portal>
        {state.open &&
          (state.plan?.planTypeId === 'free' ? <DowngradeDialog /> : <CheckoutDialog />)}
      </Portal>
    </SwitchPlanStoreContext.Provider>
  )
}
