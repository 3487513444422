import {type ReactElement, useEffect, useState} from 'react'

interface DelayProps {
  children: ReactElement | (() => ReactElement)
  ms: number
}

export function Delay(props: DelayProps): ReactElement {
  const {children, ms} = props

  const [ready, setReady] = useState(ms <= 0)

  useEffect(() => {
    if (ms <= 0) return undefined

    const timeoutId = setTimeout(() => setReady(true), ms)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [ms])

  if (!ready || !children) {
    return <></>
  }

  return typeof children === 'function' ? children() : children
}
