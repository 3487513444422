import {Stack} from '@sanity/ui'
import React from 'react'

import {ArrearsBanner} from './arrearsBanner'
import {OrganizationPaymentState} from '@/types/models/paymentstate'
import {getDisplayablePaymentState, PaymentStateDisplay} from '@/components/arrears/utils'

export function ArrearsBannerContainer({
  currentPaymentState,
  organizationsWeAreBillingAdminsFor = new Set(),
}: {
  currentPaymentState: OrganizationPaymentState[]
  organizationsWeAreBillingAdminsFor: Set<string>
}) {
  const selectedPaymentStates = currentPaymentState
    .map((paymentState) =>
      getDisplayablePaymentState(
        paymentState,
        organizationsWeAreBillingAdminsFor.has(paymentState.organizationId)
      )
    )
    .filter(Boolean)
    .slice(0, 1) as PaymentStateDisplay[]

  if (selectedPaymentStates.length > 0) {
    return (
      <Stack>
        {selectedPaymentStates.map((s) => (
          <ArrearsBanner
            key={s.organizationId}
            organizationId={s.organizationId}
            status={s.status}
            reviewAt={s.reviewAt}
            isBillingAdmin={s.isBillingAdmin}
          />
        ))}
      </Stack>
    )
  }
  return <></>
}
