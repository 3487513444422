import {
  endOfMonth,
  endOfYear,
  format,
  isThisMonth,
  parseISO,
  startOfMonth,
  startOfYear,
  subMonths,
  subYears,
} from 'date-fns'
import numeral from 'numeral'
import {
  AccumulatedPeriodUsage,
  AdditionalProjectsRange,
  CanPayStatus,
  Dataset,
  DatasetFeatureSkipBilling,
  Organization,
  OrganizationPlan,
  Plan,
  PlanResource,
  PredefinedRange,
  PriceRange,
  Project,
  ProjectUsage,
  ResourceItem,
  Resources,
  ResourcesData,
  ResourceSummary,
} from '../types'
import {ProjectType} from '@/context/index'
import {Subscription} from '@/types/models'

export const RESOURCE_NAMES: string[] = [
  'users',
  'non_admin_users',
  'non_viewer_users',
  'datasets',
  'apicdnrequests',
  'apirequests',
  'bandwidth',
  'assets',
  'documents',
  'datasets',
]

export const RESOURCE_UNITS: Record<string, string> = {
  users: 'number',
  non_admin_users: 'number', // eslint-disable-line camelcase
  non_viewer_users: 'number', // eslint-disable-line camelcase
  apirequests: 'number',
  apicdnrequests: 'number',
  assets: 'byte',
  bandwidth: 'byte',
  listeners: 'number',
  documents: 'number',
  datasets: 'number',
}

const resourceIdToSnapshotKeyMap: Record<string, string> = {
  apiCdnRequests: 'apicdnrequests',
  apiRequests: 'apirequests',
  assets: 'assets',
  bandwidth: 'bandwidth',
  datasets: 'datasets',
  documents: 'documents',
  nonAdminUsers: 'non_admin_users',
  nonViewerUsers: 'non_viewer_users',
  users: 'users',
}
export const OVERAGE_APPROACHING_LIMIT = 0.85

export const convertSnapshotKeysToResourceIds = (obj: any): any => {
  return Object.getOwnPropertyNames(obj || {}).reduce((acc: any, key: string) => {
    const newkey = resourceIdToSnapshotKeyMap[key] || key
    acc[newkey] = obj[key]
    return acc
  }, {})
}

export const isProjectNonAdminUsersQuoted = (resources?: Resources): boolean => {
  const quota = resources?.non_admin_users?.quota
  return quota !== null && quota !== undefined
}

export const isProjectNonViewerUsersQuoted = (resources?: Resources): boolean => {
  const quota = resources?.non_viewer_users?.quota
  return quota !== null && quota !== undefined
}

export const isProjectUsersQuoted = (resources?: Resources): boolean => {
  const quota = resources?.users?.quota
  return quota !== null && quota !== undefined
}

/**
 * Returns the maximum allowed resource usage for a project, considering quota
 * and max allowed overage.
 */
export function getMaxAllowedResourceUsage(
  resource?: PlanResource,
  hideInfinityOverage = false,
  includeOverageQuota = true,
  allowUnlimitedResources = false
): number | null {
  if (!resource) {
    return null
  }

  const {quota, maxOverageQuota, overageAllowed} = resource

  // A `null` quota indicates that the resource is unlimited.
  if (quota === null || allowUnlimitedResources) {
    return Infinity
  }

  // If overage is not allowed, the max is the quota.
  if (!overageAllowed || !includeOverageQuota) {
    return quota
  }

  // A `null` max overage quota indicates that there's no limit to the overage.
  if (maxOverageQuota === null) {
    return hideInfinityOverage ? quota : Infinity
  }

  return quota + maxOverageQuota
}

export const getTimeFromDateTime = (date: string): number => {
  return parseISO(date).getTime()
}

export const getDayFromDateTime = (date: string): number => {
  return parseISO(date).getDate()
}

export const getDateStringFromDateTime = (date: string): string => {
  return format(parseISO(date), 'yyyy-MM-dd')
}

export const getMonthFromDateTime = (date: string): number => {
  // Normalize to 1-12
  return parseISO(date).getMonth() + 1
}

export const getMonthAndYearFromDateTime = (date: string): string => {
  // Normalize to 1-12
  return format(parseISO(date), 'yyyy-MM')
}

export const getPositiveValue = (value?: number): number | null => {
  if (!value || value === 0) {
    return null
  }

  return value
}

export function getSnapshotData(
  projectUsage: ProjectUsage,
  attribute: string
): {percent: number; usage: number; quota: number} {
  const usage = projectUsage.snapshot?.usage[attribute]
  const quota = projectUsage.snapshot?.quota[attribute] || 0

  const percent = quota == 0 ? 0 : (usage / quota) * 100
  return {percent, usage, quota}
}

export function formatUnitType(
  value: number,
  unitType = 'number',
  isQuota = false,
  formatZeroValue = true
): string {
  if (isQuota && (value === 0 || value === Infinity)) {
    return '∞'
  }

  const unitMap = new Map([
    ['byte', '0[.]0 b'],
    ['number', '0[.]0a'],
    ['default', '0[.]0a'],
  ])
  const formatUnit = unitMap.get(unitType) || '0[.]00a'

  if (!formatZeroValue && value === 0) {
    return '0'
  }

  return numeral(value).format(formatUnit)
}

export const useChartSeries = (type: string, seriesArray: any[], all?: boolean): any => {
  return seriesArray.map((series: any) => {
    return {
      name: series.name,
      type: series.chartType,
      data: series.history?.map((day: AccumulatedPeriodUsage) => {
        return {
          x: all ? getTimeFromDateTime(day.period) : getDayFromDateTime(day.period),
          y: (day.usage as any)[type] || 0,
          timestamp: getTimeFromDateTime(day.period),
        }
      }),
    }
  })
}

export const getResourceValue = (obj: any, key: any) => {
  return obj[key]
}

export const setResourceValue = (obj: any, key: any, value: number | null) => {
  obj[key] = value
}

export const calculateOverageCost = (
  usage: number,
  quota: number,
  chunkSize: number,
  chunkPrice: number
): number => {
  if (chunkSize === 0 || chunkPrice === 0) {
    return 0
  }
  if (quota >= usage) {
    return 0
  }
  return ((usage - quota) / chunkSize) * chunkPrice
}

export const calculateTotalOverageCost = (resources: ResourceItem[]): number => {
  return resources?.reduce((sum, resource) => sum + (resource.overageCost || 0), 0) || 0
}

export const getResourcesObject = (resources: any): ResourcesData => {
  const returnObject: Record<string, any> = {}

  resources.forEach((resource: any) => {
    returnObject[resource.id] = {...resource}
  })

  return returnObject as ResourcesData
}

export const getResourceSummary = (
  _usage: ProjectUsage | ProjectUsage[] | undefined | undefined[],
  orgPlan?: OrganizationPlan
): ResourcesData => {
  let usage: ProjectUsage[]
  if (Array.isArray(_usage) === false) {
    usage = [_usage!]
  } else {
    usage = _usage as ProjectUsage[]
  }

  const resources = usage.filter(Boolean).map((u) => {
    // If project is not in org plans projectId it indicates that this is a playground project
    return Object.keys(u.resources).reduce<Partial<ResourcesData>>((res, curr) => {
      const v = u.resources[curr]

      if (orgPlan) {
        if (orgPlan.resources[curr]) {
          v.quota = orgPlan.resources[curr].quota
        }
        if (!orgPlan?.projectIds.includes(u.projectId)) {
          v.quota = orgPlan?.playgroundProjects.maxResources[curr]?.quota
        }
      }

      res[curr] = v
      return res
    }, {}) as ResourcesData
  })

  if (orgPlan) {
    const orgPlanTotals = RESOURCE_NAMES.map((name) => {
      return {
        id: name,
        usage: resources?.reduce((sum, res) => sum + (res[name]?.usage || 0), 0) || 0,
        quota: resources?.reduce((sum, res) => sum + (res[name]?.quota || 0), 0) || 0,
        unit: orgPlan.resources[name]?.unit,
        overageChunkPrice: orgPlan.resources[name]?.overageChunkPrice || 0,
        overageChunkSize: orgPlan.resources[name]?.overageChunkSize || 0,
        maxOverageQuota: orgPlan.resources[name]?.maxOverageQuota,
        overageAllowed: orgPlan.resources[name]?.overageAllowed,
      }
    })
    return getResourcesObject(orgPlanTotals)
  }

  const totals = RESOURCE_NAMES.map((name) => ({
    // Keep properties from the passed resources, because we want to retain things like hasOverage
    // @todo: This is a temporary solution to retain hasOverage until we have time to refactor usage calculations
    ...(resources[0]?.[name] || {}),
    id: name,
    usage: resources?.reduce((sum, res) => sum + (res[name]?.usage || 0), 0),
    overageCost: resources?.reduce((sum, res) => sum + res[name]?.overageCost || 0, 0),
    quota: resources?.reduce((sum, res) => {
      if (res[name]?.quota === null) {
        return null
      }
      return sum + res[name]?.quota
    }, 0),
    maxOverageQuota: resources?.reduce((sum, res) => {
      if (res[name]?.maxOverageQuota === null) {
        return null
      }
      return sum + res[name]?.maxOverageQuota
    }, 0),
    overageAllowed: resources.map((res) => res[name]?.overageAllowed ?? false),
    unit: RESOURCE_UNITS[name],
  }))

  return getResourcesObject(totals)
}

export const checkHasOverage = (resources: ResourceSummary[]): boolean => {
  return resources.some((resource) => resource.hasOverage)
}

export const calculateAdditionalProjectsCost = (
  projects?: any[],
  maxProjects?: number,
  priceRanges?: PriceRange[]
):
  | {usage: number; overageCost: number; unit: string; ranges: AdditionalProjectsRange[]}
  | undefined => {
  if (!projects || !maxProjects || !priceRanges) {
    return undefined
  }

  const rangesWithProjectCount = priceRanges.map((currentRange, index) => {
    // Calculate how many projects in current range
    const projectsInRange = projects.reduce((acc, _b, i) => {
      // Which number the current project is of the total
      const projectNumber = i + 1

      // If the project is over the max allowed projects
      // and within the current range
      // the project should be counted as part of this range
      if (
        projectNumber > maxProjects &&
        projectNumber >= currentRange.from &&
        projectNumber <= currentRange.to
      ) {
        return acc + 1
      }
      // if it's not part of the current range, don't count it
      return acc
    }, 0)

    return {
      id: index + 1,
      label: `${currentRange.from}-${currentRange.to}`,
      overageChunkPrice: currentRange.price,
      usage: projectsInRange || 0,
      overageCost: projectsInRange * currentRange.price,
      unit: 'number',
    } as AdditionalProjectsRange
  })

  return {
    usage: rangesWithProjectCount.reduce((acc, b) => acc + (b?.usage || 0), 0),
    overageCost: rangesWithProjectCount.reduce((acc, b) => acc + (b?.overageCost || 0), 0),
    ranges: rangesWithProjectCount,
    unit: 'number',
  }
}

export const getResourcesArray = (resources?: ResourcesData): ResourceItem[] => {
  if (!resources) return []
  const keys = Object.getOwnPropertyNames(resources).filter((k) => k !== 'groqwebhooks')
  return keys.map((resourceKey) => resources[resourceKey])
}

export const monthToDateLabel = (date: Date): string =>
  `${format(startOfMonth(date), 'd')}-${format(date, 'd')} ${format(date, 'MMMM y')}`

export const getDateRange = ({
  range,
  date,
}: {
  range?: PredefinedRange
  date?: Date
}): {fromDate: Date; toDate: Date; label: string} => {
  const today = new Date()
  switch (range) {
    case 'month':
      return {
        fromDate: startOfMonth(date || today),
        toDate: endOfMonth(date || today),
        label: format(date || today, 'MMMM y'),
      }
    case 'year':
      return {
        fromDate: startOfMonth(subMonths(date || today, 11)),
        toDate: endOfMonth(date || today),
        label: `${format(subMonths(date || today, 11), 'MMM y')} - ${format(
          date || today,
          'MMM y'
        )}`,
      }
    case 'monthToDate':
      return {
        fromDate: startOfMonth(date || today),
        toDate: date || today,
        label: date && !isThisMonth(date) ? monthToDateLabel(date) : 'Month to date',
      }
    case 'prevMonth':
      return {
        fromDate: startOfMonth(subMonths(today, 1)),
        toDate: endOfMonth(subMonths(today, 1)),
        label: format(subMonths(today, 1), 'MMMM y'),
      }
    case 'last12Months':
      return {
        fromDate: startOfMonth(subMonths(date || today, 11)),
        toDate: endOfMonth(date || today),
        label: 'Last 12 months',
      }
    case 'thisYear':
      return {
        fromDate: startOfYear(today),
        toDate: endOfYear(today),
        label: 'This year',
      }
    case 'lastYear':
      return {
        fromDate: subYears(startOfYear(today), 1),
        toDate: subYears(endOfYear(today), 1),
        label: 'Last year',
      }
    case 'prevMonthToDate':
      return {
        fromDate: startOfMonth(subMonths(date || today, 1)),
        toDate: subMonths(endOfMonth(date || today), 1),
        label: 'Previous month',
      }
    case 'sameMonthLastYear':
      return {
        fromDate: subYears(startOfMonth(date || today), 1),
        toDate: subYears(endOfMonth(date || today), 1),
        label: 'Same month last year',
      }
    default:
      return {
        fromDate: startOfMonth(date || today),
        toDate: endOfMonth(date || today),
        label: monthToDateLabel(today),
      }
  }
}

export const NO_GRAPHS_RESOURCES = [
  'members',
  'users',
  'non_admin_users',
  'non_viewer_users',
  'playground-projects',
  'org-projects',
  'listeners',
]
export const FIXED_RESOURCES = [
  'users',
  'non_admin_users',
  'non_viewer_users',
  'datasets',
  'documents',
  'assets',
]

export const getUsageDifference = (
  current: number,
  unit: string,
  compare?: number
): {value: string; isPositive: boolean} | undefined => {
  if (compare === null || typeof compare === 'undefined') {
    return undefined
  }
  // Get the absolute difference between compare and usage values
  const compareDifference = compare && Math.abs(compare - current)
  if (compareDifference === 0) {
    return undefined
  }
  const formatted = numeral(compareDifference).format(unit === 'byte' ? '0[.]0 b' : '0[.]0a')
  return {
    value: compare < current ? `+${formatted}` : `-${formatted}`,
    isPositive: compare < current,
  }
}

export const roundupNumber = (num: number): number => {
  const floored = 10 ** Math.floor(Math.log10(num))
  return Math.ceil(num / floored) * floored
}

export const getResourceIcon = (id: string): string => {
  switch (id) {
    case 'apicdnrequests':
      return 'earth-globe'

    case 'apirequests':
      return 'plug'

    case 'assets':
      return 'images'

    case 'bandwidth':
      return 'chart-upward'

    case 'datasets':
      return 'database'

    case 'documents':
      return 'documents'

    case 'listeners':
      return 'restore'

    case 'users':
      return 'users'

    case 'non_admin_users':
      return 'users'

    case 'non_viewer_users':
      return 'users'

    case 'groqwebhooks':
      return 'activity' // not final

    default:
      return ''
  }
}

export const getNonAdminUsers = (project?: Project): Project['members'] => {
  return (
    project?.members.filter(
      (member) => !member.isRobot && member.roles.some((role) => role.name !== 'administrator')
    ) || []
  )
}

export const getNonViewerUsers = (project?: Project): Project['members'] => {
  return (
    project?.members.filter(
      (member) => !member.isRobot && member.roles.some((role) => role.name !== 'viewer')
    ) || []
  )
}

export const countNonAdminUsers = (project?: Project): number => {
  return getNonAdminUsers(project).length
}

export const countNonViewerUsers = (project?: Project): number => {
  return getNonViewerUsers(project).length
}

export const doesInviteIncurNonAdminOverage = (
  project: Project,
  resources?: Resources,
  roleName?: string
): number | undefined => {
  if (isProjectNonAdminUsersQuoted(resources)) {
    const quota = resources?.non_admin_users.quota
    const current = countNonAdminUsers(project)
    if (
      Number.isInteger(quota) &&
      Number.isInteger(current) &&
      roleName !== 'administrator' &&
      current + 1 > quota!
    ) {
      return resources?.non_admin_users.overageChunkPrice
    }
  }
  return undefined
}

export const isProjectNonAdminUsersAtQuota = (
  project?: Project,
  resources?: Resources
): boolean => {
  if (isProjectNonAdminUsersQuoted(resources)) {
    const quota = resources?.non_admin_users.quota
    const current = countNonAdminUsers(project)
    return Number.isInteger(quota) && Number.isInteger(current) && current >= quota!
  }
  return false
}

export interface ProjectMemberState {
  total: number
  billable: number
  quota: number
  max: number
  resource: 'users' | 'non_admin_users' | 'non_viewer_users'
}

export function canPayOverage(
  subscription?: Subscription,
  org?: Organization,
  canPay?: CanPayStatus
): boolean {
  if (subscription?.plan.planTypeId === 'enterprise') {
    return true
  }
  if (org?.type === 'org-plan') {
    return true
  }
  return canPay?.status ?? false
}

export function getProjectMemberState(
  project: Project,
  projectType: ProjectType,
  resources?: Resources,
  org?: Organization
): ProjectMemberState {
  const total = project.members.filter((m) => !m.isRobot).length

  let billable: ProjectMemberState['billable']
  let quota: ProjectMemberState['quota']
  let max: ProjectMemberState['max']
  let resource: ProjectMemberState['resource']

  if (isProjectNonAdminUsersQuoted(resources)) {
    billable = countNonAdminUsers(project)
    quota = resources?.non_admin_users.quota ?? Infinity
    max = getMaxAllowedResourceUsage(resources?.non_admin_users) ?? Infinity
    resource = 'non_admin_users'
  } else if (isProjectNonViewerUsersQuoted(resources)) {
    billable = countNonViewerUsers(project)
    quota = resources?.non_viewer_users.quota ?? Infinity
    max = getMaxAllowedResourceUsage(resources?.non_viewer_users) ?? Infinity
    resource = 'non_viewer_users'
  } else {
    billable = project.members.filter((m) => !m.isRobot).length
    resource = 'users'

    switch (projectType) {
      // A playground project can not have overages.
      // (But we will still default to unlimited for type safety.)
      case 'playground':
        quota = org?.organizationPlan?.playgroundProjects?.maxResources.users.quota ?? Infinity
        max = org?.organizationPlan?.playgroundProjects?.maxResources.users.quota ?? Infinity
        break

      // An organization plan can have unlimited overages.
      case 'org-plan':
        quota = org?.organizationPlan?.resources.users.quota ?? Infinity
        max = Infinity
        break

      // A regular project quota/max is determined by the subscription's
      // user resource.
      default:
        quota = resources?.users.quota ?? Infinity
        max = getMaxAllowedResourceUsage(resources?.users) ?? Infinity
    }
  }

  return {
    total,
    billable,
    quota,
    max,
    resource,
  }
}

export const calculateTotalPrice = (plan: Plan, billedSeatCount: number): number => {
  if (plan.pricingModel === 'flat-fee') return plan.price || 0
  return (plan?.price || 0) * billedSeatCount
}

export function filterBillableDatasets(datasets: Dataset[]) {
  return datasets.filter(
    (dataset) => !dataset.features?.includes(DatasetFeatureSkipBilling) && !dataset.isDeleted
  )
}
