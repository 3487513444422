import {
  Box,
  Button,
  ButtonTone,
  Card,
  Container,
  Flex,
  Layer,
  Stack,
  Text,
  useClickOutside,
  useGlobalKeyDown,
} from '@sanity/ui'
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {AddIcon, SelectIcon} from '@sanity/icons'
import styled, {css} from 'styled-components'
import {DrawerList, DrawerItem, ItemEmpty, ShortcutButton} from '..'

type Child = {
  title: string
  selected?: boolean
  id?: string | number
  handleClick: () => void
}

type Parent = {
  title: string
  selected?: boolean
  handleClick: () => void
  children?: Child[]
  id?: string | number
}

type Props = {
  data: Parent[] | Child[]
  openButtonText: string
  emptyText: string
  openButton?: 'shortcut' | 'default'
  isSelected?: boolean
  actions: {
    level: number
    text: string
    description?: string
    icon?: React.ReactNode
    tone?: ButtonTone
    handleClick: () => void
  }[]
}

const SelectButton = styled(Button)<{$isSelected: boolean | undefined}>`
  ${({$isSelected: isSelected}) =>
    isSelected &&
    css`
      [data-ui='Text'] {
        color: ${({theme}) => theme.sanity.color.base.fg};
        font-weight: 600;
      }
    `}
`

const ActionButton = styled(Button)`
  > span > span {
    justify-content: space-between;
  }
`

export function QuickDrawer({
  data,
  actions,
  openButtonText,
  openButton = 'default',
  emptyText,
  isSelected,
}: Props) {
  const focusState = useMemo(
    () => ({
      level: 0,
      parentIndex: 0,
      childIndex: 0,
      amount: data.length,
    }),
    [data.length]
  )

  const [currentParent, setCurrentParent] = useState<Parent | null>()
  const [currentChildren, setCurrentChildren] = useState<Child[] | null>()
  const [open, setOpen] = useState(false)
  const [cardEl, setCardEl] = useState<HTMLDivElement | null>(null)
  const [offset, setOffset] = useState(0)
  const listRef = useRef<HTMLDivElement | null>(null)
  const [focusedItem, setFocusedItem] = useState(focusState)
  const [input, setInput] = useState<'mouse' | 'keyboard'>('mouse')
  const [tabIndex, setTabIndex] = useState(0)

  const reset = useCallback(() => {
    setCurrentParent(null)
    setCurrentChildren(null)
    setOpen(false)
    setFocusedItem(focusState)
  }, [focusState])

  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (!open) return
      const {level, parentIndex, childIndex, amount} = focusedItem

      if (input === 'mouse') return

      if (e.key === 'Escape') {
        reset()
      }

      if (e.key === 'Tab') {
        setTabIndex(-1)
      } else {
        setTabIndex(0)
      }

      if (e.key === 'ArrowRight') {
        if (!currentParent?.children || level === 1) return
        setFocusedItem({
          ...focusedItem,
          childIndex: 0,
          level: 1,
          amount: currentParent?.children ? currentParent?.children.length : 0,
        })
        setCurrentChildren(currentParent?.children)
        setOpen(true)
        return
      }

      if (e.key === 'ArrowLeft') {
        if (level === 0) return
        setFocusedItem({...focusedItem, childIndex: 0, level: 0, amount: data.length})
        setCurrentChildren(null)
        return
      }

      if (e.key === 'ArrowDown') {
        if (level === 0) {
          const next = parentIndex + 1 > amount - 1 ? 0 : parentIndex + 1
          setFocusedItem({...focusedItem, parentIndex: next})
          setCurrentParent(data[next])
        }
        if (level === 1) {
          if (!currentChildren) return
          const next = childIndex + 1 > amount - 1 ? 0 : childIndex + 1
          setFocusedItem({...focusedItem, childIndex: next})
        }
        e.preventDefault()
        return
      }

      if (e.key === 'ArrowUp') {
        if (level === 0 && amount - 1 !== 0) {
          const next = parentIndex - 1 < 0 ? amount - 1 : parentIndex - 1
          setFocusedItem({...focusedItem, parentIndex: next})
          setCurrentParent(data[next])
        }
        if (level === 1 && amount - 1 !== 0) {
          if (!currentChildren) return
          const next = childIndex - 1 < 0 ? amount - 1 : childIndex - 1
          setFocusedItem({...focusedItem, childIndex: next})
        }
        e.preventDefault()
      }
    },
    [currentChildren, currentParent, data, focusedItem, open, reset, input]
  )

  const handleClickOutside = useCallback(() => {
    reset()
  }, [reset])

  useGlobalKeyDown((e) => onKeyDown(e))
  useClickOutside(handleClickOutside, [cardEl])

  const handleMouseInteraction = useCallback(
    (parent: Parent | null, y: number) => {
      if (input === 'keyboard') return

      setCurrentParent(parent)
      setCurrentChildren(parent?.children)

      const list = listRef?.current
      if (!list) return
      const {y: listY} = list.getBoundingClientRect()
      setOffset(y - listY)
    },
    [input, listRef]
  )

  const handleFocus = useCallback(
    (y: number) => {
      const list = listRef?.current
      if (!list) return
      const {y: listY} = list.getBoundingClientRect()
      setOffset(y - listY)
    },
    [listRef]
  )

  const handleCallback = useCallback(
    (callback: (() => void) | undefined) => {
      reset()
      if (!callback) return
      callback()
    },
    [reset]
  )

  useEffect(() => {
    if (!open) return
    const handler = () => setInput('mouse')
    window.addEventListener('mousemove', handler)
    return () => window.removeEventListener('mousemove', handler)
  }, [open])

  useEffect(() => {
    if (!open) return
    const handler = () => setInput('keyboard')
    window.addEventListener('keydown', handler)
    return () => window.removeEventListener('keydown', handler)
  }, [open])

  return (
    <Card ref={setCardEl} onMouseLeave={() => handleMouseInteraction(null, 0)}>
      {openButton === 'default' && (
        <SelectButton
          text={openButtonText}
          $isSelected={isSelected}
          mode="bleed"
          iconRight={SelectIcon}
          onClick={() => setOpen(!open)}
        />
      )}

      {openButton === 'shortcut' && <ShortcutButton onClick={() => setOpen(!open)} />}
      <Layer>
        <Container width={0} style={{position: 'relative', margin: 0}}>
          {open && (
            <DrawerList
              level={0}
              ref={listRef}
              button={
                <>
                  {actions
                    .filter((a) => a.level === 0)
                    .map((action) => (
                      <ActionButton
                        mode="bleed"
                        tone={action?.tone || 'default'}
                        onClick={() => handleCallback(action.handleClick)}
                        onMouseOver={() => setCurrentChildren(null)}
                        style={{width: '100%'}}
                        onBlur={() => reset()}
                      >
                        <Flex align="center">
                          <Stack space={2} flex={1}>
                            <Text weight="semibold" size={1}>
                              {action.text}
                            </Text>
                            {action?.description && <Text size={1}>{action?.description}</Text>}
                          </Stack>
                          <Box marginLeft={2}>{action?.icon || <AddIcon />}</Box>
                        </Flex>
                      </ActionButton>
                    ))}
                </>
              }
            >
              {data &&
                data.length > 0 &&
                (data as Parent[]).map((d: Parent, index: number) => (
                  <DrawerItem
                    tabIndex={tabIndex}
                    input={input}
                    focus={index === focusedItem.parentIndex && focusedItem.level === 0}
                    key={d.id}
                    text={d.title}
                    active={d.id === currentParent?.id && !!currentChildren}
                    selected={d.selected}
                    onMouseOver={(y: number) => handleMouseInteraction(d, y)}
                    onFocus={(y: number) => handleFocus(y)}
                    onClick={() => handleCallback(d.handleClick)}
                    hasChildren={!!d.children}
                  />
                ))}

              {!data || (data.length <= 0 && <ItemEmpty text={emptyText} />)}
            </DrawerList>
          )}

          {open && currentParent && currentChildren && (
            <DrawerList
              level={1}
              offset={offset}
              key={currentParent.id}
              button={
                <>
                  {actions
                    .filter((a) => a.level === 1)
                    .map((action) => (
                      <ActionButton
                        mode="bleed"
                        tone={action?.tone || 'default'}
                        onClick={() => handleCallback(action.handleClick)}
                        style={{width: '100%'}}
                        onBlur={() => reset()}
                      >
                        <Flex align="center">
                          <Stack space={2} flex={1}>
                            <Text weight="semibold" size={1}>
                              {action.text}
                            </Text>
                            {action?.description && <Text size={1}>{action?.description}</Text>}
                          </Stack>
                          <Box marginLeft={2}>{action?.icon || <AddIcon />}</Box>
                        </Flex>
                      </ActionButton>
                    ))}
                </>
              }
            >
              {currentChildren.length > 0 &&
                currentChildren.map((child: Child, index: number) => (
                  <DrawerItem
                    tabIndex={tabIndex}
                    input={input}
                    focus={index === focusedItem.childIndex && focusedItem.level === 1}
                    key={child.id}
                    text={child.title}
                    selected={child.selected}
                    onClick={() => handleCallback(child.handleClick)}
                  />
                ))}

              {!currentChildren || (currentChildren.length <= 0 && <ItemEmpty text={emptyText} />)}
            </DrawerList>
          )}
        </Container>
      </Layer>
    </Card>
  )
}
