import React from 'react'

export const Package = ({color}: {color?: string}) => (
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 32L50 12M18 32L10 50L18 55M18 32L46.8 50M50 12L82 32M50 12V48M82 32L91 40.5L83.4771 35.7982M82 32L53.2 50M82 32L83.4771 35.7982M50 88L82 68L82.0257 54.3589M50 88L18 68V55M50 88V52M50 52L42 70L18 55M50 52L53.2 50M50 52L46.8 50M50 52L57 70L82.0257 54.3589M50 48L53.2 50M50 48L46.8 50M82.0257 54.3589L89 50L83.4771 35.7982"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M80.169 81.1923C80.061 80.9852 80 80.7497 80 80.5C80 79.6716 80.6716 79 81.5 79C82.3284 79 83 79.6716 83 80.5C83 81.3284 82.3284 82 81.5 82C81.3096 82 81.1275 81.9645 80.96 81.8999"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <circle cx="79.5" cy="82.5" r="1.5" stroke={color || 'currentColor'} strokeWidth="1.2" />
    <circle cx="89.5" cy="82.5" r="1.5" stroke={color || 'currentColor'} strokeWidth="1.2" />
    <circle cx="21.5" cy="84.5" r="1.5" stroke={color || 'currentColor'} strokeWidth="1.2" />
  </svg>
)
Package.displayName = 'Package'
