import {Box, Stack} from '@sanity/ui'
import styled from 'styled-components'
import {AnimatePresence, motion} from 'framer-motion'
import {useEffect, useRef, useState} from 'react'
import {useFormContext} from 'react-hook-form'

interface Props {
  children: React.ReactNode
  /**
   * This will be visually hidden but read by screen readers.
   * Also used as a key for the fieldset to trigger animations when the legend changes.
   */
  legend?: string
  space?: number
}

const StyledFieldset = styled.fieldset`
  border: 0;
  margin: 0;
  padding: 0;
`

const Legend = styled.legend`
  /* visually hide the legend */
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`

export function Fieldset({children, legend, space = 4}: Props) {
  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const [height, setHeight] = useState<number | 'auto'>('auto')
  const form = useFormContext()

  useEffect(() => {
    if (!wrapperRef.current) return
    const resizeObserver = new ResizeObserver((entries) => {
      const observedHeight = entries[0].contentRect.height
      setHeight(observedHeight)
    })
    resizeObserver.observe(wrapperRef.current)
    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  return (
    <Box ref={wrapperRef}>
      <AnimatePresence initial={false} mode="popLayout">
        <motion.div
          key={legend}
          initial={{opacity: 0, height: height}}
          animate={{opacity: 1, height: 'auto'}}
          exit={{opacity: 0, height: height}}
          transition={{
            duration: 0.5,
            ease: 'anticipate',
          }}
        >
          <StyledFieldset disabled={form.formState.disabled}>
            {legend && <Legend>{legend}</Legend>}
            <Stack space={space}>{children}</Stack>
          </StyledFieldset>
        </motion.div>
      </AnimatePresence>
    </Box>
  )
}
