import {Observable, combineLatest, of} from 'rxjs'
import {catchError, map, shareReplay} from 'rxjs/operators'
import {endOfMonth, startOfMonth} from 'date-fns'
import {apiRequest} from '../util/request'
import {formatDate} from '@/utils/time'
import {
  UsageHistoryOptions,
  ResourceOptions,
  SnapshotPeriod,
  Snapshot,
  ResourcesHistory,
  Project,
  // DatasetHistory,
  // DatasetSnapshot,
} from '@/types/models'
import {generateQueryString} from '@/utils/general'

const API_VERSION = 'v2021-05-25'

export const exportProjectHistory = (id: string, options: UsageHistoryOptions) => {
  const {fromDate, toDate, cumulative = false, period = 'day'} = options
  const query = generateQueryString({
    period,
    from: fromDate ? formatDate(fromDate) : undefined,
    to: toDate ? formatDate(toDate) : undefined,
    cumulative,
    format: 'csv',
  })
  return `https://api.${process.env.host}/${API_VERSION}/resources/history/${id}?${query}`
}

export function getProjectHistory(
  id: string,
  options: UsageHistoryOptions
): Observable<[ResourcesHistory | null /* , DatasetHistory | null */]> {
  const {fromDate, toDate, cumulative = false, period = 'day'} = options
  const formattedFrom = fromDate ? formatDate(fromDate) : undefined
  const formattedTo = toDate ? formatDate(toDate) : undefined

  // Get the history of all resources
  const resources$ = apiRequest<ResourcesHistory>({
    url: `https://api.${process.env.host}/${API_VERSION}/resources/history/${id}`,
    query: {
      period,
      from: formattedFrom,
      to: formattedTo,
      cumulative,
    },
  }).pipe(
    catchError((err) => {
      // eslint-disable-next-line no-console
      console.warn(err)
      return of(null)
    }),
    shareReplay({bufferSize: 1, refCount: true})
  )

  // Get the history of all project datasets
  // const datasets$ = apiRequest<DatasetHistory | null>({
  //   url: `https://api.${process.env.host}/${API_VERSION}/datasetstats/history/${id}`,
  //   query: {
  //     period,
  //     from: formattedFrom,
  //     to: formattedTo,
  //     cumulative,
  //   },
  // }).pipe(
  //   catchError((err) => {
  //     // eslint-disable-next-line no-console
  //     console.warn(err)
  //     return of(null)
  //   }),
  //   shareReplay({bufferSize: 1, refCount: true})
  // )
  return combineLatest([resources$ /* , datasets$ */])
}

export function getProjectSnapshot(
  id: string,
  options: {
    fromDate?: Date
    period?: SnapshotPeriod
  }
): Observable<[Snapshot | null /* , DatasetSnapshot[] | null */]> {
  const {fromDate, period} = options
  const formattedDate = fromDate ? formatDate(fromDate) : undefined

  const snapshotPeriod = period === 'year' ? 'previousPeriod' : 'startOfPeriod'

  // Get resources snapshot
  const resources$ = apiRequest<Snapshot>({
    url: `https://api.${process.env.host}/${API_VERSION}/resources/snapshot/${id}`,
    query: {period, date: formattedDate, snapshotPeriod: snapshotPeriod},
  }).pipe(
    catchError((err) => {
      // eslint-disable-next-line no-console
      console.warn(err)
      return of(null)
    }),
    shareReplay({bufferSize: 1, refCount: true})
  )
  return combineLatest([resources$])
}

export function getResources(
  project: Project,
  options?: ResourceOptions
): Observable<ResourcesHistory> {
  const {fromDate = new Date(), toDate, cumulative = false, period} = options || {}

  // Add the data to our cache object
  return combineLatest([
    getProjectHistory(project.id, {
      toDate: toDate,
      fromDate: startOfMonth(fromDate),
      cumulative,
      period: period?.history,
    }),
    getProjectSnapshot(project.id, {
      fromDate: toDate ? endOfMonth(toDate) : undefined,
      period: period?.snapshot,
    }),
  ]).pipe(
    map(([[resources], [projectSnapshot]]) => {
      return {
        history: resources?.history || [],
        snapshot: projectSnapshot,
        datasets: [],
      }
    })
  )
}
