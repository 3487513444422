import {Card, Flex, type PopoverProps, Stack} from '@sanity/ui'
import React, {forwardRef, type ReactNode, useMemo} from 'react'
import {styled} from 'styled-components'

import {CommandList, type CommandListProps} from '../command-list'
import {PopoverButton, type PopoverButtonHandle, type PopoverButtonProps} from '../popover-button'

const DEFAULT_FALLBACK_PLACEMENTS: PopoverProps['fallbackPlacements'] = ['top']
const DEFAULT_PLACEMENT: PopoverProps['placement'] = 'bottom'

const PopoverListFlex = styled(Flex)`
  position: relative;
`

const FixedHeightStack = styled(Stack)`
  min-height: max-content;
`

export interface CommandListMenuButtonProps extends CommandListProps {
  emptyNode?: ReactNode
  footer?: ReactNode
  header?: ReactNode
  id: string
  popoverButtonProps: Omit<PopoverButtonProps, 'id' | 'content' | 'ref'>
  renderCommandList?: (children: ReactNode) => ReactNode
}

export const CommandListMenuButton = forwardRef(function CommandListMenuButton(
  props: CommandListMenuButtonProps,
  ref: React.Ref<PopoverButtonHandle>
) {
  const {emptyNode, footer, header, id, popoverButtonProps, renderCommandList, ...rest} = props

  const renderedCommandList = useMemo(() => {
    const node = (
      <PopoverListFlex direction="column" overflow="hidden" flex={1}>
        <CommandList activeItemDataAttr="data-selected" initialIndex={-1} {...rest} />
      </PopoverListFlex>
    )

    if (renderCommandList) {
      return renderCommandList(node)
    }

    return node
  }, [renderCommandList, rest])

  const content = (
    <Flex direction="column" flex={1} height="fill">
      {header && (
        <FixedHeightStack>
          <Card borderBottom>{header}</Card>
        </FixedHeightStack>
      )}

      {rest.items.length === 0 && emptyNode}

      {rest.items.length > 0 && renderedCommandList}

      {footer && (
        <FixedHeightStack>
          <Card borderTop>{footer}</Card>
        </FixedHeightStack>
      )}
    </Flex>
  )

  return (
    <PopoverButton
      constrainSize
      fallbackPlacements={DEFAULT_FALLBACK_PLACEMENTS}
      placement={DEFAULT_PLACEMENT}
      portal
      focusLock
      {...popoverButtonProps}
      matchReferenceWidth
      content={content}
      id={id}
      ref={ref}
    />
  )
})
