export function Google() {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M12 6.13764C12 5.72977 11.9663 5.31971 11.8943 4.91846H6.12036V7.22895H9.42684C9.28963 7.97412 8.84876 8.63332 8.20322 9.05221V10.5514H10.1759C11.3342 9.50637 12 7.9631 12 6.13764Z"
          fill="#4285F4"
        />
        <path
          d="M6.12019 12.0002C7.77118 12.0002 9.16349 11.4688 10.1779 10.5517L8.20529 9.05253C7.65646 9.41851 6.94793 9.62574 6.12244 9.62574C4.52544 9.62574 3.17136 8.56971 2.68552 7.1499H0.649902V8.69537C1.68908 10.7215 3.80567 12.0002 6.12019 12.0002V12.0002Z"
          fill="#34A853"
        />
        <path
          d="M2.68332 7.14986C2.4269 6.40469 2.4269 5.59778 2.68332 4.8526V3.30713H0.649951C-0.218278 5.00252 -0.218278 6.99995 0.649951 8.69533L2.68332 7.14986V7.14986Z"
          fill="#FBBC04"
        />
        <path
          d="M6.12019 2.37483C6.99291 2.3616 7.8364 2.68348 8.46845 3.27433L10.2161 1.56131C9.1095 0.542752 7.64071 -0.017233 6.12019 0.000404333C3.80566 0.000404333 1.68908 1.27911 0.649902 3.3074L2.68326 4.85288C3.16686 3.43087 4.52319 2.37483 6.12019 2.37483V2.37483Z"
          fill="#EA4335"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
