/* eslint-disable complexity */
import React, {useCallback, useState, useEffect} from 'react'
import {
  Box,
  Card,
  Flex,
  Heading,
  Stack,
  Text,
  useToast,
  Button,
  Inline,
  MenuButton,
  Menu,
  MenuItem,
  MenuDivider,
} from '@sanity/ui'
import {
  CheckmarkIcon,
  CheckmarkCircleIcon,
  EditIcon,
  TrashIcon,
  EllipsisVerticalIcon,
  AccessDeniedIcon,
  RocketIcon,
  CogIcon,
  WarningOutlineIcon,
} from '@sanity/icons'

import ConfirmDisableSamlDialog from './confirmDisableSAMLDialog'
import ConfirmDeleteProviderDialog from './confirmDeleteProviderDialog'
import ConfirmDeleteProviderProjectDialog from './confirmDeleteProviderProjectDialog'
import SsoSAMLMetadataDialog from './ssoSAMLMetadataDialog'
import SsoSAMLProjectRole, {REQUIRED_FEATURE} from './ssoSAMLProjectRole'
import SsoSAMLProjectRoleDialog from './ssoSAMLProjectRoleDialog'
import SsoSAMLStudioSetupDetails from './ssoSAMLStudioSetupDetails'

import {Loader, TabHeader} from '@/ui/index'
import {useOrganizationHasFeature, useRoutePath} from '@/context/index'
import {Organization} from '@/types/index'
import {Project} from '@/types/models/project'
import {OrganizationSSOProviderSAML} from '@/types/models/organization'
import {useSSOProvider} from '@/data/organizations/useSSOProviders'
import {AppLink} from '@/ui/app'

function SsoSAMLFeatureRequired() {
  return (
    <Stack space={4}>
      <Card>
        <Flex>
          <Stack space={4} flex={4}>
            <Heading as="h2">SAML SSO </Heading>
            <Text>
              Connect Sanity to your identity provider to securely onboard your organization's users
              to Sanity projects, automate user activations and reduce management overhead.
            </Text>
          </Stack>
          <Stack space={1}>
            <Button
              fontSize={2}
              padding={[3, 3, 4]}
              icon={EditIcon}
              text="Configure"
              tone="positive"
              mode="ghost"
              disabled
            />
          </Stack>
        </Flex>
      </Card>
      <Card padding={4} border tone="caution">
        <Flex padding={2}>
          <Box marginRight={4}>
            <Text size={2}>
              <CheckmarkIcon />
            </Text>
          </Box>
          <Stack space={3}>
            <Text size={1}>
              SAML is only available for organization that has at least one project on a plan that
              supports it.
            </Text>
            <Text size={1}>
              <a href={`https://www.${process.env.host}/docs/third-party-login`}>
                Click here for more information
              </a>
            </Text>
          </Stack>
        </Flex>
      </Card>
    </Stack>
  )
}

type SsoSAMLProps = {
  org: Organization
}
export function SsoSAML({org}: SsoSAMLProps) {
  const {basePath, asPath} = useRoutePath()
  const {push} = useToast()
  const {
    providers,
    saveProvider,
    createProvider,
    deleteProvider,
    saveProjectRoleMappings,
    deleteProjectRoleMappings,
    error,
    loading,
  } = useSSOProvider(org.id)
  const samlProvider = providers.find(
    (provider): provider is OrganizationSSOProviderSAML => provider.type === 'saml'
  )
  const enableSAML = useCallback(() => {
    if (loading) {
      return
    }

    createProvider({
      name: 'saml',
      type: 'saml',
      disabled: false,
      generateKeys: true,
    })
  }, [loading, createProvider])

  const disableSAML = useCallback(() => {
    if (samlProvider) {
      saveProvider(samlProvider.id, {
        name: samlProvider.name,
        type: 'saml',
        disabled: true,
      })
    }
  }, [saveProvider, samlProvider])

  const reEnableSAML = useCallback(() => {
    if (samlProvider) {
      saveProvider(samlProvider.id, {
        name: samlProvider.name,
        type: 'saml',
        disabled: false,
      })
    }
  }, [saveProvider, samlProvider])

  const [samlMetadataVisible, setSamlMetadataVisble] = useState(false)

  const closeSamlMetadata = useCallback(() => {
    setSamlMetadataVisble(false)
  }, [])

  const [samlProjectRoleVisible, setSamlProjectRoleVisible] = useState<Project | null>(null)

  const closeSamlProjectRoleDialog = useCallback(() => {
    setSamlProjectRoleVisible(null)
  }, [])

  const [samlProjectRoleDeleteVisible, setSamlProjectRoleDeleteVisible] = useState<Project | null>(
    null
  )

  const closeSamlProjectRoleDeleteVisible = useCallback(() => {
    setSamlProjectRoleDeleteVisible(null)
  }, [])

  const [confirmDisableSAMLDialogVisible, setConfirmDisableSamlDialogVisible] = useState(false)

  const openConfirmDisableSamlDialog = useCallback(() => {
    setConfirmDisableSamlDialogVisible(true)
  }, [])

  const closeConfirmDisableSamlDialog = useCallback(() => {
    setConfirmDisableSamlDialogVisible(false)
  }, [])

  const [confirmDeleteProviderDialogVisible, setConfirmDeleteProviderDialogVisible] =
    useState(false)

  const openConfirmDeleteProviderDialog = useCallback(() => {
    setConfirmDeleteProviderDialogVisible(true)
  }, [])

  const closeConfirmDeleteProviderDialog = useCallback(() => {
    setConfirmDeleteProviderDialogVisible(false)
  }, [])

  const deleteSAMLProvider = useCallback(() => {
    if (samlProvider) {
      deleteProvider(samlProvider.id)
    }
  }, [deleteProvider, samlProvider])

  const deleteSAMLProjectProvider = useCallback(() => {
    if (samlProvider && samlProjectRoleDeleteVisible) {
      deleteProjectRoleMappings(samlProvider.id, samlProjectRoleDeleteVisible.id)
    }
  }, [deleteProjectRoleMappings, samlProvider, samlProjectRoleDeleteVisible])

  useEffect(() => {
    if (providers.length > 0 && error !== null) {
      push({
        title: 'Error',
        status: 'error',
        description: error.message,
      })
    }
  }, [push, providers.length, error])

  const samlProjects = org.projects.filter((project) => project.features.includes(REQUIRED_FEATURE))

  const orgHasRequiredFeature = useOrganizationHasFeature(org, REQUIRED_FEATURE, false)

  if (!asPath.includes('/settings/saml')) {
    return null
  }

  if (orgHasRequiredFeature === false) {
    return <SsoSAMLFeatureRequired />
  }

  if (providers.length === 0 && error !== null) {
    return (
      <Card padding={4} border tone={'critical'}>
        <Stack space={2}>
          <Text weight="bold">Error</Text>
          <Text>{error.message}!</Text>
        </Stack>
      </Card>
    )
  }

  if (loading && samlProvider === undefined) {
    return (
      <Box padding={6}>
        <Loader />
      </Box>
    )
  }

  let status: 'unknown' | 'disabled' | 'not-configured' | 'enabled' = 'unknown'
  let isConfigured = false
  if (samlProvider !== undefined) {
    status = 'enabled'

    if (samlProvider.configuration !== null && samlProvider.configuration !== undefined) {
      const {configuration} = samlProvider
      isConfigured =
        (configuration.cert || []).some((cert) => cert.length > 0) &&
        (configuration.issuer?.length || 0) > 0 &&
        (configuration.entryPoint?.length || 0) > 0
      // && (configuration.signatureAlgorithm?.length || 0) > 0
    }

    if (samlProvider.disabled) {
      status = 'disabled'
    } else if (samlProvider.configuration === undefined) {
      status = 'unknown'
    } else if (samlProvider.configuration === null || isConfigured === false) {
      status = 'not-configured'
    }
  }

  const statusToTone = (samlStatus: string) => {
    switch (samlStatus) {
      case 'disabled':
        return 'caution'

      case 'not-configured':
        return 'caution'

      case 'unknown':
        return 'default'

      default:
        return 'positive'
    }
  }

  return (
    <>
      <Stack space={6}>
        <TabHeader
          title="SAML SSO"
          description="Connect Sanity to your identity provider to securely onboard your organization's users
              to Sanity projects, automate user activations and reduce management overhead."
          referenceLink={{
            label: 'Learn more about setting up SAML SSO',
            url: `https://www.${process.env.host}/docs/sso-saml`,
          }}
        />

        {status === 'unknown' && (
          <Button
            text="Create SAML SSO provider"
            mode="default"
            tone="primary"
            onClick={enableSAML}
          />
        )}

        {status !== 'unknown' && (
          <Card radius={3} style={{overflow: 'hidden'}} border>
            <Card padding={4} tone={statusToTone(status) || 'positive'}>
              <Flex align="center">
                <Stack space={3} flex={2}>
                  {status === 'not-configured' && (
                    <Text size={2} weight="semibold">
                      SAML SSO is not configured
                    </Text>
                  )}

                  {status === 'enabled' && (
                    <Inline space={3} flex={2}>
                      <Text size={2} muted>
                        <CheckmarkCircleIcon />
                      </Text>
                      <Text size={2} weight="semibold" muted>
                        SAML SSO is enabled
                      </Text>
                    </Inline>
                  )}

                  {status === 'disabled' && (
                    <Inline space={3} flex={2}>
                      <Text size={2} muted>
                        <WarningOutlineIcon />
                      </Text>
                      <Text size={2} weight="semibold" muted>
                        SAML SSO is disabled
                      </Text>
                    </Inline>
                  )}
                </Stack>
                <Flex justify="flex-end">
                  <Inline space={3} flex={1}>
                    {status === 'not-configured' && (
                      <AppLink href={`${basePath}/settings/saml/${samlProvider?.id}/configure`}>
                        <Button as="a" icon={CogIcon} text="Configure" mode="ghost" />
                      </AppLink>
                    )}
                    <MenuButton
                      id="saml-config-menubutton"
                      button={<Button mode="bleed" icon={EllipsisVerticalIcon} />}
                      menu={
                        <Menu>
                          {status === 'disabled' && (
                            <MenuItem
                              icon={RocketIcon}
                              text="Enable SAML SSO"
                              onClick={reEnableSAML}
                            />
                          )}

                          {status !== 'not-configured' && (
                            <AppLink
                              href={`${basePath}/settings/saml/${samlProvider?.id}/configure`}
                            >
                              <MenuItem
                                icon={CogIcon}
                                text="Configure"
                                as="a"
                                style={{width: '100%'}}
                              />
                            </AppLink>
                          )}

                          {(status === 'enabled' || status === 'disabled') && <MenuDivider />}

                          {status === 'enabled' && (
                            <MenuItem
                              icon={AccessDeniedIcon}
                              text="Disable SAML SSO"
                              tone="critical"
                              onClick={openConfirmDisableSamlDialog}
                            />
                          )}

                          {(status === 'disabled' || status === 'not-configured') && (
                            <MenuItem
                              icon={TrashIcon}
                              tone="critical"
                              text="Delete SAML SSO service provider"
                              onClick={openConfirmDeleteProviderDialog}
                            />
                          )}
                        </Menu>
                      }
                    />
                  </Inline>
                </Flex>
              </Flex>
            </Card>

            {samlProvider && <SsoSAMLStudioSetupDetails samlProvider={samlProvider} />}
          </Card>
        )}
        {samlProvider && status === 'enabled' && (
          <Stack space={3}>
            <Heading size={1} as="h3">
              Role mapping
            </Heading>
            <Text size={1} muted>
              Configure SAML SSO role mapping per project. SAML SSO is available on the Enterprise
              plan or as an add-on to the Growth plan.
            </Text>
            {samlProjects.length > 0 ? (
              <>
                {samlProjects.map((project) => (
                  <SsoSAMLProjectRole
                    key={project.id}
                    samlProvider={samlProvider}
                    project={project}
                    setSamlProjectRoleVisible={setSamlProjectRoleVisible}
                    setSamlProjectRoleDeleteVisible={setSamlProjectRoleDeleteVisible}
                  />
                ))}
              </>
            ) : (
              <Flex align="center" direction="column" marginTop={4}>
                <Card radius={3} tone="caution" padding={3} width={1}>
                  <Text size={1}>No SAML compatible projects in organization</Text>
                </Card>
              </Flex>
            )}
          </Stack>
        )}
      </Stack>

      {samlProvider && samlMetadataVisible && (
        <SsoSAMLMetadataDialog samlProvider={samlProvider} closeSamlMetadata={closeSamlMetadata} />
      )}

      {samlProvider && samlProjectRoleVisible && (
        <SsoSAMLProjectRoleDialog
          samlProvider={samlProvider}
          project={samlProjectRoleVisible}
          loading={loading}
          closeDialog={closeSamlProjectRoleDialog}
          saveProjectRoleMappings={saveProjectRoleMappings}
        />
      )}

      {confirmDisableSAMLDialogVisible && (
        <ConfirmDisableSamlDialog
          closeDialog={closeConfirmDisableSamlDialog}
          disableSAML={disableSAML}
        />
      )}

      {confirmDeleteProviderDialogVisible && (
        <ConfirmDeleteProviderDialog
          closeDialog={closeConfirmDeleteProviderDialog}
          deleteProvider={deleteSAMLProvider}
        />
      )}

      {samlProjectRoleDeleteVisible && (
        <ConfirmDeleteProviderProjectDialog
          closeDialog={closeSamlProjectRoleDeleteVisible}
          deleteProvider={deleteSAMLProjectProvider}
        />
      )}
    </>
  )
}
