import {TableHeader} from '@/types/index'

export const MEMBERS_TABLE: TableHeader[] = [
  {
    id: 'picture',
    label: 'Picture',
    disableTabButtonRender: true,
    columnWidth: '45px',
  },
  {
    id: 'name',
    label: 'Name',
    columnWidth: 3,
  },
  {
    id: 'Role',
    label: 'Roles',
    columnWidth: 4,
    hideOnMobile: true,
  },
  {
    id: 'Joined',
    label: 'Joined',
    columnWidth: 1,
    hideOnMobile: true,
  },
  {
    id: 'actions',
    label: 'Actions',
    disableTabButtonRender: true,
  },
]

export const INVITATIONS_TABLE: TableHeader[] = [
  {
    id: 'email',
    label: 'Email',
    columnWidth: 3,
  },
  {
    id: 'Role',
    label: 'Role',
    columnWidth: 2,
    hideOnMobile: true,
  },
  {
    id: 'sent',
    label: 'Invited',
    columnWidth: 2,
    hideOnMobile: true,
  },
  {
    id: 'actions',
    label: 'Actions',
    disableTabButtonRender: true,
  },
]

export const ACCESS_REQUEST_TABLE_HEADERS: TableHeader[] = [
  {
    id: 'user',
    label: 'User',
    columnWidth: '45px',
    disableTabButtonRender: true,
  },
  {
    id: 'name',
    label: 'Name',
    columnWidth: 3,
  },
  {
    id: 'Role',
    label: 'Role',
    columnWidth: 2,
    hideOnMobile: true,
  },
  {
    id: 'Note',
    label: 'Note',
    hideOnMobile: true,
    columnWidth: 3,
    alignment: 'left',
  },
  {
    id: 'Date',
    label: 'Requested',
    hideOnMobile: true,
    columnWidth: 2,
    alignment: 'left',
  },
  {
    id: 'actions',
    label: 'Actions',
    columnWidth: 2,
    disableTabButtonRender: true,
  },
]

export const ROLE_REQUEST_TABLE_HEADERS: TableHeader[] = [
  {
    id: 'user',
    label: 'User',
    columnWidth: '45px',
    disableTabButtonRender: true,
  },
  {
    id: 'name',
    label: 'Name',
    columnWidth: 3,
  },
  {
    id: 'Role',
    label: 'Role',
    columnWidth: 3,
    hideOnMobile: true,
  },
  {
    id: 'Note',
    label: 'Note',
    hideOnMobile: true,
    columnWidth: 2,
    alignment: 'left',
  },
  {
    id: 'Date',
    label: 'Requested',
    hideOnMobile: true,
    columnWidth: 2,
    alignment: 'left',
  },
  {
    id: 'actions',
    label: 'Actions',
    columnWidth: 2,
    disableTabButtonRender: true,
  },
]
