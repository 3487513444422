import React from 'react'
import styled from 'styled-components'
import {Box, BoxProps, Flex} from '@sanity/ui'

const FLEX_ALIGNMENT: Record<string, 'flex-end' | 'flex-start' | 'center'> = {
  right: 'flex-end',
  left: 'flex-start',
  center: 'center',
}

const Root = styled(Box)<{$align?: string}>`
  padding: 0;
  border: 0;

  & [data-ui='Button'] {
    overflow: hidden;
    white-space: break-spaces;
  }

  & [data-ui]:not([data-ui='MenuItem'] *) {
    ${({$align: align}) => align && `text-align: ${align};`}
  }
`

export const TableCell = ({
  $align: align = 'left',
  children,
  className,
  as = 'td',
  flexProps,
  style,
  onClick,
}: {
  $align?: string | number
  children: React.ReactNode
  className?: string
  as?: React.ElementType | keyof JSX.IntrinsicElements
  style?: React.CSSProperties
  onClick?: (ev: React.MouseEvent<HTMLElement>) => void
  flexProps?: any // todo: fix
} & BoxProps) => {
  const Component = as

  return (
    <Root as={Component} className={className} $align={align} style={style} onClick={onClick}>
      <Flex align="center" justify={FLEX_ALIGNMENT[align]} style={{height: '100%'}} {...flexProps}>
        {children}
      </Flex>
    </Root>
  )
}
TableCell.displayName = 'TableCell'
