import {Box, Button, Card, Flex, Grid, Stack, Text} from '@sanity/ui'
import React, {useCallback, useState} from 'react'
import numeral from 'numeral'
import {Icon, IconSymbol, ToggleArrowRightIcon} from '@sanity/icons'
import {getPlanCopy, Prompt} from '@/ui/index'
import {Plan, Subscription, ResourceIds} from '@/types/index'
import {formatPrice, getResourceNameById} from '@/utils/general'
import {
  getResourceIcon,
  isProjectNonAdminUsersQuoted,
  isProjectNonViewerUsersQuoted,
} from '@/utils/usage'
import {ButtonWithExtendedTones} from '../../general/ButtonWithExtendedTones'
import {useCanOpenCheckout} from '@/context/useCanOpenCheckout'

type Props = {
  plan: Plan
  subscription?: Subscription
  prompt?: React.ReactNode
  columns?: number[]
  defaultPlans?: Plan[]
  onSelect?: (plan: Plan) => void
  showUpgradeButton?: boolean
  upgradeButtonOnClick?: () => void
}

export function PlanBlock({
  plan,
  prompt,
  subscription,
  columns = [2, 2, 3, 3, 4],
  defaultPlans,
  onSelect,
  showUpgradeButton = false,
  upgradeButtonOnClick,
}: Props) {
  const [open, setOpen] = useState<boolean>(false)
  const {price, name: planName} = plan || {}
  const {description, paymentDescription} = getPlanCopy(plan) || {}
  const canOpenCheckout = useCanOpenCheckout()

  const newPlan = defaultPlans?.find((p) => p.planTypeId === plan.planTypeId)
  const isSuperseded =
    plan.supersededAt !== null && plan.planTypeId !== 'enterprise' && newPlan !== undefined

  const handleToggle = useCallback(() => {
    setOpen((prev) => !prev)
  }, [])

  const handlePlanRefresh = useCallback(() => {
    if (newPlan && onSelect) {
      onSelect(newPlan)
    }
  }, [onSelect, newPlan])

  const resources = subscription?.resources || plan?.resources
  const isNonAdminUsersQuoted = isProjectNonAdminUsersQuoted(resources)
  const isNonViewerUsersQuoted = isProjectNonViewerUsersQuoted(resources)
  const isAllUsersQuoted = !isNonAdminUsersQuoted && !isNonViewerUsersQuoted

  const formatResources = Object.entries(resources)
    .filter(([key]) => {
      // keys to always exclude
      if (['listeners'].includes(key)) return false

      if (key === 'non_admin_users' && !isNonAdminUsersQuoted) return false
      if (key === 'non_viewer_users' && !isNonViewerUsersQuoted) return false
      if (key === 'users' && !isAllUsersQuoted) return false

      return true
    })
    // eslint-disable-next-line complexity
    .map((resource) => {
      const [key, value] = resource
      const {quota, unit, overageChunkPrice, overageChunkSize} = value
      const subscriptionResource = subscription?.resources?.[key]
      let currentQuota = quota

      if (subscriptionResource && subscriptionResource.quota !== quota) {
        currentQuota = subscriptionResource.quota

        // If this is custom, reset to plan quota (I think this makes sense)
        if (subscriptionResource.custom && currentQuota === 0) {
          currentQuota = null
        }
      }

      const formatQuota =
        currentQuota === null
          ? '∞'
          : numeral(currentQuota).format(unit === 'byte' ? '0[.]0 b' : '0[.]0a')

      const formatOverageQuota =
        overageChunkSize === null
          ? '∞'
          : numeral(overageChunkSize).format(unit === 'byte' ? '0[.]0 b' : '0[.]0a')

      const overagePrice = formatPrice(overageChunkPrice)
      const isPerSeatPricing = plan.pricingModel === 'per-seat'

      const titleConfig: Record<string, string> = {
        apicdnrequests: `${formatQuota} CDN Requests`,
        apirequests: `${formatQuota} ${getResourceNameById(key as ResourceIds)}`,
        assets: `${formatQuota} assets`,
        bandwidth: `${formatQuota} bandwidth`,
        datasets: `${formatQuota} ${formatQuota === '1' ? 'dataset' : 'datasets'}`, // only one worth pluralizing
        documents: `${formatQuota} documents`,
        users: isPerSeatPricing ? `${overagePrice} per member` : `${formatQuota} members`,
        // eslint-disable-next-line camelcase
        non_admin_users: isPerSeatPricing
          ? `${overagePrice} per member`
          : `${formatQuota} non-admin members`,
        // eslint-disable-next-line camelcase
        non_viewer_users: isPerSeatPricing
          ? `${overagePrice} per member`
          : `${formatQuota} user seats`,
        groqwebhooks: `${formatQuota} GROQ webhooks`,
      }

      const subtitleConfig: Record<string, string | null> = {
        apicdnrequests: overageChunkPrice
          ? `+ ${overagePrice} per ${formatOverageQuota}/month`
          : null,
        apirequests: overageChunkPrice ? `+ ${overagePrice} per ${formatOverageQuota}/month` : null,
        assets: overageChunkPrice ? `+ ${overagePrice} per ${formatOverageQuota}` : null,
        bandwidth: overageChunkPrice ? `+ ${overagePrice} per ${formatOverageQuota}/month` : null,
        datasets: overageChunkPrice ? `+ ${overagePrice} per extra dataset` : null,
        documents: overageChunkPrice ? `+ ${overagePrice} per ${formatOverageQuota}` : null,
        // eslint-disable-next-line no-nested-ternary
        users: isPerSeatPricing
          ? null
          : overageChunkPrice
            ? `+ ${overagePrice} per extra member`
            : null,
        // eslint-disable-next-line camelcase, no-nested-ternary
        non_admin_users: isPerSeatPricing
          ? 'Admins free'
          : overageChunkPrice
            ? `+ ${overagePrice} per extra non-admin`
            : null,
        // eslint-disable-next-line camelcase, no-nested-ternary
        non_viewer_users: isPerSeatPricing
          ? 'Viewers free'
          : overageChunkPrice
            ? `+ ${overagePrice} per extra non-viewer`
            : null,
        groqwebhooks: overageChunkPrice ? `+ ${overagePrice} per extra webhook` : null,
      }

      return {
        title: titleConfig[key],
        quota: formatQuota,
        subtitle: subtitleConfig[key],
        icon: getResourceIcon(key) as IconSymbol,
      }
    })

  return (
    <Card paddingX={3} paddingY={4} border radius={3}>
      <Stack space={3}>
        <Box>
          <Flex justify="space-between" align={'flex-start'}>
            <Stack space={3}>
              <Text weight="semibold">{planName}</Text>
              {description && (
                <>
                  <Box>
                    <Text muted size={1}>
                      {description}
                    </Text>
                  </Box>
                </>
              )}
            </Stack>

            <Stack space={3}>
              <Text align="right">
                {typeof price === 'number' ? formatPrice(price) : <>&nbsp;</>}
              </Text>
              <Text align="right" size={1} muted>
                {typeof price === 'number' ? (
                  <>
                    {paymentDescription ??
                      (plan.pricingModel === 'per-seat'
                        ? 'per seat / month'
                        : 'base price / month')}
                  </>
                ) : (
                  <>&nbsp;</>
                )}
              </Text>
            </Stack>
          </Flex>
        </Box>
        {canOpenCheckout && isSuperseded && newPlan && (
          <Prompt
            tone="positive"
            icon="info-outline"
            title={`Check out the new ${newPlan.name} plan`}
            description="If you don't want to upgrade, you may stay on this plan for as long as you'd like."
            button={
              <Button
                tone="positive"
                text={`Switch to the new ${newPlan.name} plan`}
                as="span"
                onClick={handlePlanRefresh}
              />
            }
          />
        )}

        <Card borderBottom tone="inherit" paddingY={1} />
        <Stack space={3}>
          <Flex justify={'space-between'}>
            <Button
              text="Plan details"
              mode="bleed"
              fontSize={1}
              padding={1}
              onClick={handleToggle}
              style={{background: 'none', boxShadow: 'unset'}}
              icon={
                <Box style={{transform: `rotate(${open ? '90deg' : '0deg'} )`}}>
                  <ToggleArrowRightIcon />
                </Box>
              }
            />
            {showUpgradeButton && (
              <ButtonWithExtendedTones
                tone="purple"
                mode="ghost"
                text="Upgrade now"
                onClick={upgradeButtonOnClick}
              />
            )}
          </Flex>
          {open && (
            <>
              <Grid columns={columns} gapY={4} gap={3} padding={2} aria-expanded={open}>
                {formatResources.map((resource) => {
                  return (
                    <Flex key={resource.title}>
                      <Box marginRight={3}>
                        <Text muted size={1}>
                          <Icon symbol={resource.icon} />
                        </Text>
                      </Box>
                      <Stack space={2}>
                        <Box>
                          <Text muted size={1}>
                            {resource.title}
                          </Text>
                        </Box>
                        <Text size={0} muted>
                          {resource.subtitle}
                        </Text>
                      </Stack>
                    </Flex>
                  )
                })}
              </Grid>
              {prompt && <>{prompt}</>}
            </>
          )}
        </Stack>
      </Stack>
    </Card>
  )
}
