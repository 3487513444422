import {Box, Flex, Heading, Stack, Text, Inline, AvatarStack, Button, useTheme} from '@sanity/ui'
import React from 'react'
import {ProjectList, MemberAvatar, OverviewModule, AppLink} from '@/ui/index'
import {Organization, OrganizationMember} from '@/types/models'

interface Props {
  org: Organization
}

export function OrgProjectList({org}: Props) {
  const {members = [], projects = []} = org
  const totalProjects = projects.length
  const {sanity} = useTheme()
  const textColor = sanity.color.base.fg
  return (
    <OverviewModule
      buttonText={org.type === 'personal' ? 'View profile' : 'View organization'}
      path={org.type === 'personal' ? '/manage/personal/projects' : `/organizations/${org.id}`}
      align="center"
      title={
        <Stack space={3}>
          <Heading as="h2">{org.name}</Heading>
          {org.type === 'personal'
            ? totalProjects > 0 && (
                <Text size={1}>{`${totalProjects} ${
                  totalProjects === 1 ? 'project' : 'projects'
                }`}</Text>
              )
            : totalProjects > 0 ||
              (members.length !== 0 && (
                <Inline space={4}>
                  {totalProjects > 0 && (
                    <Text size={1}>{`${totalProjects} ${
                      totalProjects === 1 ? 'project' : 'projects'
                    }`}</Text>
                  )}
                  {members.length !== 0 && (
                    <AppLink href={`/organizations/${org.id}/members`}>
                      <Button
                        tabIndex={-1}
                        mode="bleed"
                        paddingY={2}
                        paddingLeft={1}
                        paddingRight={2}
                        radius={6}
                        text={
                          <Flex align="center">
                            <AvatarStack maxLength={4}>
                              {members.map((member: OrganizationMember) => (
                                <MemberAvatar
                                  key={`${org.id}-${member.sanityUserId}`}
                                  userId={member.sanityUserId}
                                />
                              ))}
                            </AvatarStack>
                            <Box marginLeft={2}>
                              <Text size={1} style={{color: textColor}}>{`${members.length} ${
                                members.length === 1 ? 'member' : 'members'
                              }`}</Text>
                            </Box>
                          </Flex>
                        }
                      />
                    </AppLink>
                  )}
                </Inline>
              ))}
        </Stack>
      }
    >
      <Box>
        <ProjectList items={projects} max={6} org={org} />
      </Box>
    </OverviewModule>
  )
}
