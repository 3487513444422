import {Text} from '@sanity/ui'
import {useCurrentScopeContext} from '@/context/index'
import {ErrorOverlay} from './errorOverlay'
import {useEffect} from 'react'
import {sendAmplitudeTrackingEvent} from '@/utils/tracking'
import {CheckoutFormData} from '../types'
import {useFormContext} from 'react-hook-form'

export function BillingPermissionsAlert() {
  const scope = useCurrentScopeContext()
  const {watch} = useFormContext<CheckoutFormData>()
  const selectedOrgId = watch('org.id')
  useEffect(() => {
    sendAmplitudeTrackingEvent(
      'Upgrade Blocked by Billing Permissions',
      scope!.projectId,
      selectedOrgId || scope!.org?.id,
      {}
    )
  }, [scope!.projectId, scope!.org?.id])

  return (
    <ErrorOverlay>
      <Text size={1} weight="semibold">
        You must be an administrator or billing manager of the organization {scope?.org?.name} to
        add payment details before you upgrade.
      </Text>
    </ErrorOverlay>
  )
}
