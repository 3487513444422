import {CheckmarkIcon} from '@sanity/icons'
import {Box, Flex, Menu, MenuButton, MenuItem, Stack, Text} from '@sanity/ui'
import {useId} from 'react'

import {type MembersV2} from '../../../../../../types/members_v2'
import {Button} from '../../../../primitives/button/Button'
import {getRoleSelectButtonText} from '../../../../utils'
import {ProjectSelectMenuButton} from '../../../project-select-menu-button'
import {RoleSelectMenuButton} from '../../../role-select-menu-button'
import {SHARED_BUTTON_PROPS, SHARED_POPOVER_BUTTON_PROPS} from './constants'
import {FilterSection} from './FilterSection'
import {type BaseFilterProps, type Filter} from './index'

const GLOBAL_TITLES: Record<Filter, string> = {
  all: 'All',
  'organization-roles': 'Organization roles',
  'project-access': 'Project access',
}

export interface OrganizationFiltersProps extends BaseFilterProps {}

export const OrganizationFilters = ({
  projectsData,
  resourceRoles,
  value,
  onValueChange,
}: OrganizationFiltersProps) => {
  const menuButtonId = useId()

  const handleSelectFilter = (key: Filter) => () => {
    onValueChange({filter: key})
  }

  const handleRoleSelect = (role: OrganizationFiltersProps['resourceRoles'][number]) => {
    onValueChange((prev) => {
      /**
       * If prev.role exists, then we either add or remove the role from the list
       * depending on if it already exists.
       */
      const roles = prev?.roles || value?.roles || []
      const roleIndex = roles.findIndex((r) => r.name === role.name)

      return {
        /**
         * Set the filter to 'organization-roles' if it's not already set,
         * however this is unlikely since we do not show the input until
         * the filter has been set.
         */
        filter: 'organization-roles',
        ...prev,
        roles: roleIndex > -1 ? roles.toSpliced(roleIndex, 1) : [...roles, role],
      }
    })
  }

  const handleProjectSelect = (project: MembersV2['project']) =>
    onValueChange(() => ({
      /**
       * Set the filter to 'project-access' if it's not already set,
       * however this is unlikely since we do not show the input until
       * the filter has been set.
       */
      filter: 'project-access',
      /**
       * reset the project roles when a new project is selected because
       * some roles might not exist in the other project
       */
      projectRoles: [],
      project,
    }))

  const handleProjectRoleSelect = (role: MembersV2['projectData']['roleSchemas'][number]) => {
    onValueChange((prev) => {
      /**
       * If prev.role exists, then we either add or remove the role from the list
       * depending on if it already exists.
       */
      const roles = prev?.projectRoles || value?.projectRoles || []
      const roleIndex = roles.findIndex((r) => r.name === role.name)

      return {
        /**
         * Set the filter to 'project-access' if it's not already set,
         * however this is unlikely since we do not show the input until
         * the filter has been set.
         */
        filter: 'project-access',
        ...prev,
        projectRoles: roleIndex > -1 ? roles.toSpliced(roleIndex, 1) : [...roles, role],
      }
    })
  }

  const selectedProject =
    value?.project && value.project.id
      ? projectsData.find((p) => p.projectProfile.id === value.project?.id)
      : null

  const projectRoleOptions = selectedProject?.roleSchemas ?? []

  return (
    <>
      <FilterSection title="Show">
        <MenuButton
          id={menuButtonId}
          popover={{matchReferenceWidth: true, portal: true, animate: true}}
          button={
            <Button
              {...SHARED_BUTTON_PROPS}
              text={value && 'filter' in value ? GLOBAL_TITLES[value.filter] : GLOBAL_TITLES['all']}
            />
          }
          menu={
            <Menu padding={2}>
              {Object.entries(GLOBAL_TITLES).map(([key, val]) => (
                <MenuItem key={key} onClick={handleSelectFilter(key as Filter)} padding={0}>
                  <Box padding={3} sizing="border">
                    <Flex align="center" gap={3} paddingY={1} sizing="border">
                      <Stack space={3} flex={1}>
                        <Text weight="medium" size={1}>
                          {val}
                        </Text>
                      </Stack>

                      {(value?.filter === key || (key === 'all' && !value?.filter)) && (
                        <Box>
                          <Text size={1}>
                            <CheckmarkIcon />
                          </Text>
                        </Box>
                      )}
                    </Flex>
                  </Box>
                </MenuItem>
              ))}
            </Menu>
          }
        />
      </FilterSection>

      {value?.filter === 'organization-roles' ? (
        <FilterSection title="Roles">
          <RoleSelectMenuButton
            onSelect={handleRoleSelect}
            options={resourceRoles}
            selectedRoles={value.roles}
            multiSelect
            popoverButtonProps={{
              ...SHARED_POPOVER_BUTTON_PROPS,
              button: (
                <Button
                  {...SHARED_BUTTON_PROPS}
                  text={getRoleSelectButtonText(resourceRoles, value.roles)}
                />
              ),
            }}
          />
        </FilterSection>
      ) : null}

      {value?.filter === 'project-access' ? (
        <>
          <FilterSection title="Project">
            <ProjectSelectMenuButton
              options={projectsData.map((p) => p.projectProfile)}
              selectedProjects={value.project ? [value.project] : []}
              onSelect={handleProjectSelect}
              closeOnSelect
              popoverButtonProps={{
                ...SHARED_POPOVER_BUTTON_PROPS,
                button: (
                  <Button
                    {...SHARED_BUTTON_PROPS}
                    text={
                      selectedProject
                        ? selectedProject.projectProfile.displayName
                        : 'Select project'
                    }
                  />
                ),
              }}
            />
          </FilterSection>
          <FilterSection title="Roles">
            <RoleSelectMenuButton
              options={projectRoleOptions}
              selectedRoles={value.projectRoles}
              onSelect={handleProjectRoleSelect}
              multiSelect
              popoverButtonProps={{
                ...SHARED_POPOVER_BUTTON_PROPS,
                button: (
                  <Button
                    {...SHARED_BUTTON_PROPS}
                    text={getRoleSelectButtonText(selectedProject?.roleSchemas, value.projectRoles)}
                    disabled={!selectedProject}
                  />
                ),
              }}
            />
          </FilterSection>
        </>
      ) : null}
    </>
  )
}
