import {manageTheme, useManageScheme} from '@/ui/app/manageSchemeProvider'
import {Card} from '@sanity/ui'
import {type CardElementProps} from '@stripe/react-stripe-js'
import {useFormContext} from 'react-hook-form'
import styled from 'styled-components'

interface Props {
  as: React.FunctionComponent
}

const Root = styled(Card)`
  &:has(.StripeElement--focus) {
    border-color: var(--card-focus-ring-color);
  }
`

export const StripeTextField = ({as}: Props) => {
  const {resolvedScheme} = useManageScheme()
  const StripeElement = as as React.FunctionComponent<CardElementProps>
  const form = useFormContext()
  const theme = manageTheme.v2!
  const colors = theme.color[resolvedScheme].default.input.default.enabled
  const text = theme.font.text

  return (
    <Root border radius={2} paddingY={2} paddingX={3}>
      <StripeElement
        options={{
          disabled: form.formState.disabled,
          style: {
            base: {
              iconColor: colors.fg,
              color: colors.fg,
              fontFamily: text.family,
              fontSize: `${text.sizes[2].fontSize}px`,
              '::placeholder': {color: colors.placeholder},
              fontSmoothing: 'antialiased',
              ':-webkit-autofill': {
                backgroundColor: colors.bg,
                color: colors.fg,
              },
            },
            invalid: {
              iconColor: theme.color[resolvedScheme].default.input.invalid.enabled.fg,
              color: theme.color[resolvedScheme].default.input.invalid.enabled.fg,
            },
          },
        }}
      />
    </Root>
  )
}
