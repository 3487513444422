import {createContext, useContext} from 'react'
import {CurrentScope} from '@/types/index'

export const CurrentScopeContext = createContext<CurrentScope | null>(null)

export function useCurrentScopeContext(): CurrentScope {
  const value = useContext(CurrentScopeContext)

  if (!value) {
    throw new Error('useCurrentScopeContext must be used within a CurrentScopeProvider')
  }

  return value
}
