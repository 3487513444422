import {Box, Card, Grid, Stack, Text} from '@sanity/ui'
import styled from 'styled-components'
import {PortableTextComponentProps} from '@portabletext/react'
import {Children, useMemo} from 'react'

const SectionNumber = styled(Card)`
  --size: 21px;

  border-radius: var(--size);
  height: var(--size);
  place-items: center;
  width: var(--size);
  z-index: 0;

  &:before {
    content: '';
    position: absolute;
    height: 100%;
    border-left: 1px solid var(--card-hairline-soft-color);
    top: var(--size);
    left: calc(var(--size) / 2);
  }
`

const Section = styled(Grid)`
  padding-bottom: 4rem;
  position: relative;

  &:last-child {
    padding-bottom: 0;

    ${SectionNumber}:before {
      display: none;
    }
  }

  @media (min-width: ${(props) => props.theme.sanity.media[1]}px) {
    grid-template-columns: auto clamp(200px, 48%, 380px) 1fr;
  }
`

export const ListItemSection = (props: PortableTextComponentProps<any>) => {
  /**
   * Separates children into two arrays: text and code.
   *
   * This useMemo hook processes the children props and categorizes them based on their type.
   * It creates two arrays:
   * 1. text: Contains all non-code children elements
   * 2. code: Contains all children elements of type CODE_TYPE
   */
  const [text, code] = useMemo(() => {
    const children = Children.toArray(props.children)
    return children.reduce<[React.ReactNode[], React.ReactNode[]]>(
      ([textAcc, codeAcc], child) => {
        if (typeof child === 'object' && 'type' in child && typeof child.type === 'function') {
          return child.type.name.toLowerCase().includes('code')
            ? [textAcc, [...codeAcc, child]]
            : [[...textAcc, child], codeAcc]
        }
        return [[...textAcc, child], codeAcc]
      },
      [[], []]
    )
  }, [props.children])

  return (
    <Section gapX={3} gapY={3} role="listitem">
      <SectionNumber border display={['none', 'none', 'grid']}>
        <Text size={0} weight="semibold">
          {props.index + 1}
        </Text>
      </SectionNumber>

      <Box paddingRight={5}>{text}</Box>

      <Stack space={4} marginTop={[4, 4, 0]}>
        {code}
      </Stack>
    </Section>
  )
}
