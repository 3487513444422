import {Card, Text, Stack, Flex} from '@sanity/ui'
import styled from 'styled-components'
import {urlFor} from '@/data/communityClient'
import type {TemplateData} from '@/data/projects/useTemplateData'
import {useGitHubRepoStats} from '@/data/projects/useGitHubRepoStats'
import {formatRelativeTime} from '@/utils/index'
import {ClockIcon, EyeOpenIcon, PlugIcon, StarIcon, type IconComponent} from '@sanity/icons'

const Stat = ({icon: Icon, label}: {icon: IconComponent; label: string}) => (
  <Flex align="center" gap={3}>
    <Text size={1} muted>
      <Icon />
    </Text>
    <Text size={1} muted>
      {label}
    </Text>
  </Flex>
)

export const TemplateCard = ({template}: {template: TemplateData}) => {
  const imageUrl = urlFor(template?.image).url()
  const {data: repoStats} = useGitHubRepoStats(template?.repository)
  const lastUpdated = formatRelativeTime(repoStats?.lastUpdated, {minimal: true}).timestamp

  const Image = styled.img`
    height: fit-content;
    border-radius: 6px;
    max-width: 100%;

    @media (min-width: 768px) {
      max-width: 240px;
    }
  `

  return (
    <Card shadow={1} padding={[4, 5]} radius={4}>
      <Flex gap={[4, 6]} direction={['column-reverse', 'row']} justify="space-between">
        <Flex direction="column" paddingY={2} gap={5}>
          <Stack space={5}>
            <Text weight="semibold" size={3}>
              {template?.title}
            </Text>
            <Text muted size={2}>
              {template?.description}
            </Text>
          </Stack>

          <Flex gap={4} style={{marginTop: 'auto'}} wrap="wrap">
            <Stat icon={StarIcon} label={`${repoStats?.stars} stars`} />
            <Stat icon={EyeOpenIcon} label={`${repoStats?.watchers} watchers`} />
            <Stat icon={PlugIcon} label={`${repoStats?.forks} forks`} />
            <Stat icon={ClockIcon} label={`Updated ${lastUpdated} ago`} />
          </Flex>
        </Flex>

        <Image src={imageUrl} alt="" />
      </Flex>
    </Card>
  )
}
