/* eslint-disable react/display-name */
import React, {forwardRef, useCallback, useMemo} from 'react'
import {ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon} from '@sanity/icons'
import {Button, ButtonProps, Card, Flex} from '@sanity/ui'
import {addYears, subYears, getYear, subMonths, addMonths, getMonth} from 'date-fns'

type Props = {
  label?: string
  date: Date
  onChange: (date: Date) => void
  onClick: () => void
  minDate: Date
  maxDate: Date
  minMonth?: number
  maxMonth?: number
  variant: 'year' | 'month'
  buttonProps?: ButtonProps | unknown
}

export const SelectButton = forwardRef(
  (
    {
      label,
      date,
      onChange,
      onClick,
      minDate,
      maxDate,
      minMonth,
      maxMonth,
      variant,
      buttonProps,
    }: Props,
    ref: React.Ref<HTMLButtonElement>
  ) => {
    const year = getYear(date)
    const month = getMonth(date)
    const minYear = getYear(minDate)
    const maxYear = getYear(maxDate)
    const {loading} = buttonProps as ButtonProps

    const handleDecrease = useCallback(() => {
      const newDate = variant === 'year' ? subYears(date, 1) : subMonths(date, 1)
      onChange(newDate)
    }, [date, onChange, variant])

    const handleIncrease = useCallback(() => {
      const newDate = variant === 'year' ? addYears(date, 1) : addMonths(date, 1)
      onChange(newDate)
    }, [date, onChange, variant])

    const disablePrevious = useMemo(() => {
      if (variant === 'year') {
        return year - 1 < minYear
      }
      if (variant === 'month' && minMonth) {
        return minMonth !== 11 && month - 1 < minMonth
      }
      return false
    }, [minMonth, minYear, month, variant, year])

    const disableNext = useMemo(() => {
      if (variant === 'year') {
        return year + 1 > maxYear
      }

      if (variant === 'month' && maxMonth) {
        return month + 1 > maxMonth && year + 1 > maxYear
      }
      return false
    }, [maxMonth, maxYear, month, variant, year])

    return (
      <Card display="flex" border radius={2}>
        <Flex align="center" justify="space-between" flex={1}>
          <Card borderRight style={{background: 'transparent', padding: '0 2px'}}>
            <Button
              onClick={handleDecrease}
              disabled={disablePrevious || loading}
              aria-label={`Previous ${variant}`}
              icon={ChevronLeftIcon}
              mode="bleed"
              paddingX={2}
              style={{
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
              }}
            />
          </Card>
          <Card flex={1}>
            <Button
              ref={ref}
              iconRight={ChevronDownIcon}
              text={label}
              mode="bleed"
              onClick={onClick}
              style={{width: '100%'}}
              {...(buttonProps as ButtonProps)}
            />
          </Card>
          <Card borderLeft style={{background: 'transparent', padding: '0 2px'}}>
            <Button
              onClick={handleIncrease}
              disabled={disableNext || loading}
              aria-label={`Next ${variant}`}
              icon={ChevronRightIcon}
              mode="bleed"
              paddingX={2}
              style={{borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}
            />
          </Card>
        </Flex>
      </Card>
    )
  }
)
