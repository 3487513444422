/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-no-bind */
import React, {useState, useCallback} from 'react'

import {
  Box,
  Button,
  Card,
  Flex,
  Stack,
  Text,
  useToast,
  Heading,
  MenuButton,
  Menu,
  MenuItem,
  Inline,
  MenuDivider,
  Label,
  Badge,
} from '@sanity/ui'
import {
  AddIcon,
  CodeBlockIcon,
  CopyIcon,
  DatabaseIcon,
  EditIcon,
  EllipsisHorizontalIcon,
  EllipsisVerticalIcon,
  LaunchIcon,
  PauseIcon,
  PlayIcon,
  RocketIcon,
  TrashIcon,
} from '@sanity/icons'
import router from 'next/router'
import {DocumentWebhook, Project} from '@/types/models'
import {
  EmptyBlock,
  Loader,
  PermissionButtonProject,
  Prompt,
  TabHeader,
  TimeAgoText,
  useConfirmDialog,
} from '@/ui/index'
import {attemptsUrlFor, duplicateWebhook, useWebhooks} from '@/data/documentWebhooks'
import {useProjectSubscription} from '@/data/projects/useProjectSubscription'
import {useRoutePath} from '@/context/index'
import {AppLink} from '@/ui/app'
import {fieldsToQuerystring} from '../../../../pages/manage/webhooks/share'

const COLLAPSE_THRESHOLD = 3

export function GROQWebhooksDetails({
  project,
  title,
  description,
}: {
  project: Project
  title: string
  description: string
}) {
  const {basePath} = useRoutePath()
  const [deletingWebhook, setDeletingWebhook] = useState<DocumentWebhook>()
  const [isCollapsed, setIsCollapsed] = useState(true)

  const {showDialog, Dialog, hideDialog} = useConfirmDialog()
  const toast = useToast()

  const didDelete = useCallback(() => {
    toast.push({title: 'Webhook successfully deleted!', status: 'success'})
    setDeletingWebhook(undefined)
    hideDialog()
  }, [hideDialog, toast])

  const didAdd = useCallback(() => {
    toast.push({title: 'Webhook successfully added!', status: 'success'})
  }, [toast])

  const didError = useCallback(
    (err: Error) => {
      toast.push({description: err.message, status: 'error'})
    },
    [toast]
  )

  const webhooks = useWebhooks(project?.id, {
    didAdd,
    didDelete,
    didError,
  })
  const {data: subscription} = useProjectSubscription(project.id)

  const existingNames = webhooks.data.map((w) => w.name)
  const hooks = isCollapsed ? webhooks.data.slice(0, COLLAPSE_THRESHOLD) : webhooks.data
  const hasMore = isCollapsed && webhooks.data.length > COLLAPSE_THRESHOLD
  const quota = subscription?.resources.groqwebhooks?.quota
  const reachedQuota = quota ? webhooks.data.length >= quota : false

  return (
    <Box>
      <Stack space={4}>
        <TabHeader
          title={title}
          description={description}
          referenceLink={{
            label: 'Learn more about webhooks',
            url: `https://www.${process.env.host}/docs/webhooks`,
          }}
          button={
            <PermissionButtonProject
              href={`${basePath}/api/webhooks/new`}
              title="Create webhook"
              icon={AddIcon}
              mode="ghost"
              permissions={[{permissionName: 'sanity.project.webhooks', grantName: 'create'}]}
              action="cannot add webhooks"
              disabled={reachedQuota}
            />
          }
          counter={{
            current: webhooks.data.length,
            quota: Number(quota),
            max: Number(quota),
            resource: 'webhooks',
          }}
          showUpsell
          upsellResourceName="webhooks"
        />

        {reachedQuota && (
          <Prompt
            tone="positive"
            icon="info-outline"
            description="You have used all the included webhooks in your plan. In order to add more webhooks, you need to upgrade your plan."
            button={
              <AppLink href={`${basePath}/plan`} style={{flexShrink: 0}}>
                <Button
                  tone="positive"
                  text="Compare and switch plans"
                  icon={RocketIcon}
                  as="span"
                />
              </AppLink>
            }
          />
        )}

        <Stack space={4}>
          {webhooks.loading && <Loader text="Loading webhooks..." height={100} />}

          {!webhooks.loading && webhooks.data.length === 0 && (
            <EmptyBlock
              title="There are no GROQ-powered webhooks in this project"
              description="Maybe try creating a new webhook?"
            />
          )}

          {hooks.map((webhook) => {
            const details = [
              {
                title: 'Created',
                value: (
                  <Box paddingTop={1}>
                    <TimeAgoText date={webhook.createdAt} size={1} options={{agoSuffix: true}} />
                  </Box>
                ),
              },
              {
                title: 'Datasets',
                value: (
                  <Inline space={2} paddingTop={1}>
                    <Text muted size={1} weight="medium">
                      <DatabaseIcon />
                    </Text>
                    <Text size={1} muted weight="medium">
                      {webhook.dataset === '*' ? 'All datasets' : webhook.dataset}
                    </Text>
                  </Inline>
                ),
              },
              {
                title: 'Status',
                value: <WebhookStatus webhook={webhook} />,
              },
            ]
            return (
              <Card
                key={webhook.id}
                radius={2}
                shadow={1}
                padding={3}
                paddingLeft={[3, 3, 4]}
                paddingBottom={[3, 3, 4]}
              >
                <Stack space={4}>
                  <Flex
                    justify="space-between"
                    align="baseline"
                    direction={['column', 'column', 'row']}
                  >
                    <Box marginBottom={[4, 4, 0]} marginTop={[2, 2, 0]} marginRight={[0, 0, 2]}>
                      <Heading size={1}>{webhook.name}</Heading>
                    </Box>
                    <Inline space={2} style={{flexShrink: 0}}>
                      <Button
                        icon={EditIcon}
                        mode="ghost"
                        text="Edit webhook"
                        href={`${basePath}/api/webhooks/${webhook.id}`}
                        as="a"
                      />
                      <MenuButton
                        id={`webhook-${webhook.id}`}
                        button={<Button icon={EllipsisVerticalIcon} mode="ghost" />}
                        menu={
                          <Menu>
                            <MenuItem
                              text="Duplicate"
                              icon={CopyIcon}
                              disabled={reachedQuota}
                              onClick={() => {
                                webhooks.addWebhook(duplicateWebhook(webhook, {existingNames}))
                              }}
                            />
                            {webhook.isDisabledByUser ? (
                              <MenuItem
                                text="Enable"
                                icon={PlayIcon}
                                onClick={() => {
                                  webhooks.updateWebhook(webhook.id, {isDisabledByUser: false})
                                }}
                              />
                            ) : (
                              <MenuItem
                                text="Disable"
                                icon={PauseIcon}
                                onClick={() => {
                                  webhooks.updateWebhook(webhook.id, {isDisabledByUser: true})
                                }}
                              />
                            )}
                            <MenuItem
                              text="Share"
                              icon={LaunchIcon}
                              onClick={() => {
                                router.push({
                                  pathname: '/manage/webhooks/share',
                                  query: fieldsToQuerystring(webhook),
                                })
                              }}
                            />
                            <MenuItem
                              text="Show attempt log"
                              icon={CodeBlockIcon}
                              as="a"
                              href={attemptsUrlFor(project.id, webhook.id)}
                              target="_blank"
                            />
                            <MenuDivider />
                            <MenuItem
                              text="Delete"
                              tone="critical"
                              icon={TrashIcon}
                              onClick={() => {
                                setDeletingWebhook(webhook)
                                showDialog()
                              }}
                            />
                          </Menu>
                        }
                      />
                    </Inline>
                  </Flex>
                  <Text size={1} muted style={{overflowWrap: 'break-word'}}>
                    {webhook.url}
                  </Text>
                  {webhook.description && <Text size={1}>{webhook.description}</Text>}
                  <Flex align="flex-start" paddingTop={2}>
                    {details.map((d) => (
                      <Stack space={3} marginRight={5} key={d.title}>
                        <Label muted size={1}>
                          {d.title}
                        </Label>
                        {d.value}
                      </Stack>
                    ))}
                  </Flex>
                </Stack>
              </Card>
            )
          })}

          {hasMore && (
            <Button
              icon={EllipsisHorizontalIcon}
              mode="ghost"
              onClick={() => setIsCollapsed(false)}
            />
          )}
        </Stack>

        {deletingWebhook && (
          <Dialog
            id="delete-webhook-dialog"
            header="Delete Webhook"
            title="Do you really want to delete this webhook?"
            onClose={() => {
              setDeletingWebhook(undefined)
              hideDialog()
            }}
            onConfirm={() => webhooks.deleteWebhook(deletingWebhook.id)}
            loading={webhooks.loading}
          />
        )}
      </Stack>
    </Box>
  )
}

function WebhookStatus({webhook}: {webhook: DocumentWebhook}) {
  if (webhook.isDisabled) {
    return (
      <Badge tone="critical" mode="outline" fontSize={1}>
        Blocked
      </Badge>
    )
  }
  if (webhook.isDisabledByUser) {
    return (
      <Badge tone="caution" mode="outline" fontSize={1}>
        Disabled
      </Badge>
    )
  }

  return (
    <Badge tone="positive" mode="outline" fontSize={1}>
      Enabled
    </Badge>
  )
}
