import React from 'react'
import {IfFeatureEnabled} from '@growthbook/growthbook-react'
import {Card, Flex, Text} from '@sanity/ui'
import {BoltIcon} from '@sanity/icons'
import {useRouter} from 'next/router'
import {OverviewModule} from '../../module'
import {sendTrackingEvent, useMatchesMedia} from '@/utils/index'
import {useRoutePath} from '@/context/index'
import {useProjectSubscription} from '@/data/projects/useProjectSubscription'
import {Project} from '@/types/index'
import {ButtonWithExtendedTones} from '../../general/ButtonWithExtendedTones'
import {purple} from '@sanity/color'
import {useManageScheme} from '@/ui/app/manageSchemeProvider'

export function UpgradeToGrowthBanner({projectId}: {projectId: Project['id']}) {
  const {asPath} = useRoutePath()
  const {data: subscription} = useProjectSubscription(projectId)
  const freePlan = subscription?.plan.planTypeId === 'free'
  const {resolvedScheme} = useManageScheme()

  const isMobile = useMatchesMedia(1)
  const router = useRouter()
  function onPlanBannerClick() {
    // Track when the banner is clicked
    sendTrackingEvent('ClickUpgradeBanner', projectId)
    router.push(`${asPath}/plan?ref=banner-cta`)
  }

  return freePlan ? (
    <IfFeatureEnabled feature="manage-plan-cta-banner-q4-2023">
      <OverviewModule
        title="Plan"
        buttonText="Compare plans"
        path={`${asPath}/plan?ref=overview-header`}
      >
        <Card
          padding={4}
          border
          style={{borderColor: resolvedScheme === 'dark' ? purple['800'].hex : purple['200'].hex}}
          radius={2}
        >
          <Flex direction={'column'}>
            <Flex gap={4}>
              <Flex direction={'column'} gap={4}>
                <Flex direction={'column'} gap={3}>
                  <Text weight="semibold" size={2}>
                    Get more from Sanity
                  </Text>
                  <Text size={1}>
                    Create faster with AI Assist. Collaborate easier with Comments. Get peace of
                    mind with Private datasets and User Roles.
                  </Text>
                </Flex>
                <Flex>
                  <ButtonWithExtendedTones
                    mode="ghost"
                    tone="purple"
                    text="Upgrade to Growth"
                    icon={<BoltIcon />}
                    onClick={onPlanBannerClick}
                  />
                </Flex>
              </Flex>
              {!isMobile && (
                <img
                  src={`${process.env.assetPrefix}/upgrade-plan-illustration-${resolvedScheme}.svg`}
                  style={{alignSelf: 'flex-start'}}
                />
              )}
            </Flex>
          </Flex>
        </Card>
      </OverviewModule>
    </IfFeatureEnabled>
  ) : null
}
