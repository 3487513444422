import {useMemo} from 'react'
import {useCurrentScopeContext, useProjectHasFeature, useOrganizationHasFeature} from '.'
import {Tab} from '@/types/components'

function getProjectTabAccess(
  tab: Tab | undefined,
  project: any,
  projectHasRequiredFeature: boolean
): boolean {
  if (!tab) return true
  if (tab.path === 'activity') {
    return Boolean(project?.activityFeedEnabled && projectHasRequiredFeature)
  }
  return true
}

function getOrgTabAccess(tab: Tab | undefined, org: any, orgHasRequiredFeature: boolean): boolean {
  if (!tab) return true
  if (tab.path === 'activity') {
    return Boolean(
      org &&
        org?.projects?.filter((prj: any) => prj?.activityFeedEnabled)?.length > 0 &&
        orgHasRequiredFeature
    )
  }
  return true
}

export function useTabAccess(tab?: Tab) {
  const {org, project, scope} = useCurrentScopeContext()
  const projectHasRequiredFeature = useProjectHasFeature(project, tab?.grantingFeature, true)
  const orgHasRequiredFeature = useOrganizationHasFeature(org, tab?.grantingFeature, true)

  const hasTabAccess = useMemo(() => {
    if (scope === undefined || !tab) return false
    return scope === 'org'
      ? getOrgTabAccess(tab, org, orgHasRequiredFeature)
      : getProjectTabAccess(tab, project, projectHasRequiredFeature)
  }, [scope, tab, org, orgHasRequiredFeature, project, projectHasRequiredFeature])

  return hasTabAccess
}
