import {Subscription} from '@/types/models'

export function isSubscriptionFeature(
  subscription: Subscription | undefined,
  featureId: string
): boolean {
  if (!subscription) {
    return false
  }
  for (const featureType in subscription.featureTypes) {
    if (Object.prototype.hasOwnProperty.call(subscription.featureTypes, featureType)) {
      const features =
        subscription.featureTypes[featureType as keyof typeof subscription.featureTypes].features
      if (features.some((feature: {id: string}) => feature.id === featureId)) {
        return true
      }
    }
  }
  return false
}

export function getSubscriptionCustomFeatures(subscription: Subscription): string[] {
  const featureIds: string[] = []
  if (!subscription) {
    return featureIds
  }

  for (const featureType in subscription.featureTypes) {
    if (Object.prototype.hasOwnProperty.call(subscription.featureTypes, featureType)) {
      const features =
        subscription.featureTypes[featureType as keyof typeof subscription.featureTypes].features
      for (const feature of features) {
        if (feature.custom) {
          featureIds.push(feature.id)
        }
      }
    }
  }
  return featureIds
}

export function getSubscriptionFeatures(subscription: Subscription): string[] {
  const featureIds: string[] = []
  if (!subscription) {
    return featureIds
  }

  for (const featureType in subscription.featureTypes) {
    if (Object.prototype.hasOwnProperty.call(subscription.featureTypes, featureType)) {
      const features =
        subscription.featureTypes[featureType as keyof typeof subscription.featureTypes].features
      for (const feature of features) {
        featureIds.push(feature.id)
      }
    }
  }
  return featureIds
}
