import {Box, Stack, Text, TextInput, useToast} from '@sanity/ui'
import React, {useEffect, useRef} from 'react'
import {useForm, useWatch} from 'react-hook-form'
import {useRouter} from 'next/router'
import {FormField, Ul, Li, RadioInput} from '@/ui/index'
import {
  WizardParams,
  WizardStep,
  useWizardStore,
  WizardStepProps,
} from '@/components/payment-wizard'
import {
  datasetNamePattern,
  MAX_DATASET_NAME_LENGTH,
  PRIVATE_DATASET_FEATURE,
  useCreateDataset,
} from '@/data/datasets'
import {DatasetAclMode} from '@/types/models'
import {DatasetVisibilityWarningPrompt} from '@/ui/views/wizards/newDataset'
import {useProjectHasFeature} from '@/context/index'
import {throttle} from 'lodash'

interface FormValues {
  name: string
  aclMode: DatasetAclMode
  tags: string[]
}

function CreateDatasetStep(props: WizardStepProps) {
  const {params} = props
  const {dispatch} = useWizardStore()
  const router = useRouter()
  const {project} = params
  const hasPrivateDatasetsFeature = useProjectHasFeature(project, PRIVATE_DATASET_FEATURE)
  const lastStateValueRef = useRef<FormValues | undefined>()
  const throttledDispatch = useRef(
    throttle((value) => {
      dispatch({
        type: 'newDatasetWizard/setCreateDataset',
        value,
      })
    }, 250)
  )

  const {
    control,
    register,
    formState: {errors},
    reset,
    formState,
  } = useForm<FormValues>({
    defaultValues: {
      name: '',
      aclMode: 'public',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const formData = useWatch<FormValues>({
    control,
    defaultValue: {
      name: '',
      aclMode: 'public',
    },
  })

  useEffect(() => {
    return () => reset()
  }, [reset])

  useEffect(() => {
    const newValue: FormValues | undefined =
      formState.isValid && formData.name && formData.aclMode
        ? {name: formData.name, aclMode: formData.aclMode, tags: []}
        : undefined

    // Because the form state is updated on every change, we need to throttle the dispatch
    // @todo We would have to refactor the wizard store to handle this better
    if (newValue !== lastStateValueRef.current) {
      lastStateValueRef.current = newValue
      throttledDispatch.current(newValue)
    }
  }, [dispatch, formState.isValid, formData])

  return (
    <Box>
      <Stack space={4}>
        <FormField
          label="Dataset name"
          description={
            <Stack space={1} marginTop={1}>
              <Text size={1} muted>
                The name needs to be:
              </Text>
              <Ul marginY={2}>
                <Li>Lowercase</Li>
                <Li>Between 1-{`${MAX_DATASET_NAME_LENGTH}`} characters</Li>
                <Li>Start with an alphanumeric character (a-z, 0-9)</Li>
                <Li>Contain only alphanumeric characters, _ or -</Li>
              </Ul>
            </Stack>
          }
          error={
            errors.name ? 'The format of the dataset name is invalid or name already exists' : ''
          }
        >
          <TextInput
            padding={3}
            maxLength={MAX_DATASET_NAME_LENGTH}
            {...register('name', {
              required: true,
              pattern: datasetNamePattern,
              validate: (v) => !(project.datasets || []).map((d) => d.name).includes(v),
            })}
          />
        </FormField>
        <FormField label="Visibility" description="Set visibility of a dataset">
          <RadioInput
            label="Public (accessible to everyone)"
            value="public"
            {...register('aclMode')}
          />
          <RadioInput
            label="Private (accessible with a token or by authenticated users)"
            value="private"
            {...register('aclMode')}
          />
        </FormField>
        {!hasPrivateDatasetsFeature && (
          <DatasetVisibilityWarningPrompt router={router} project={project} />
        )}
      </Stack>
    </Box>
  )
}

export function useCreateDatasetStep(params: WizardParams): WizardStep | null {
  const {state} = useWizardStore()
  const newDatasetData = state.newDatasetWizard.dataset
  const {project} = params
  const toast = useToast()
  const {mutateAsync: createDataset} = useCreateDataset(project.id)

  return {
    id: 'create-dataset',
    title: 'Create dataset',
    completed: !!newDatasetData,
    component: CreateDatasetStep,
    disabled: false,
    action: {
      label: <>Create dataset</>,
    },
    handle() {
      if (!newDatasetData || !newDatasetData.name || !newDatasetData.aclMode) {
        return Promise.reject(new Error('Incomplete dataset data'))
      }
      return createDataset(
        {name: newDatasetData.name, aclMode: newDatasetData.aclMode},
        {
          onError: (err) => {
            toast.push({
              title: 'Error',
              description: err.message,
              status: 'error',
              duration: 10000,
              closable: true,
            })
          },
        }
      ).then(() => {
        return Promise.resolve()
      })
    },
  }
}
