import {useConfirmDialog} from '@/components/dialog'
import {useCurrentScopeContext, useRoutePath} from '@/context/index'
import {useDatasets} from '@/data/datasets'
import {useOrganizationCanPay} from '@/data/organizations'
import {deleteProjectRole} from '@/data/projects'
import {
  useProjectPermissionResources,
  useProjectPermissionResourceSchemas,
  useProjectTags,
} from '@/data/projects/useProjectRoles'
import {Project, ProjectMember, ProjectMemberRole, RoleResource, Subscription} from '@/types/index'
import {MemberAvatar, PermissionButtonProject, TagIcon} from '@/ui/index'
import {isProjectNonAdminUsersQuoted} from '@/utils/usage'
import {
  DatabaseIcon,
  EditIcon,
  EllipsisHorizontalIcon,
  EllipsisVerticalIcon,
  InfoOutlineIcon,
  TrashIcon,
  UsersIcon,
  WarningOutlineIcon,
} from '@sanity/icons'
import {
  AvatarStack,
  Box,
  Button,
  Card,
  Code,
  Flex,
  Heading,
  Inline,
  Label,
  Menu,
  MenuButton,
  MenuItem,
  Spinner,
  Stack,
  Tab,
  TabList,
  TabPanel,
  Text,
  useToast,
} from '@sanity/ui'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import Link from 'next/link'
import router, {useRouter} from 'next/router'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {ContentResource} from './contentResource'
import {DatasetPermissionSummary} from './datasetPermissionSummary'
import {ManageRoleMembersDialog} from './manageRoleMembers'
import {ManagePermissions} from './projectPermissions'
import {RawPermissionsOverview} from './rawPermissionsOverview'
import {UpdateRoleDialog} from './updateRoleDialog'
import {getProjectRolesKey} from '@/data/projects/cache'

type Props = {
  project: Project
  subscription?: Subscription
  role: ProjectMemberRole
  roleName: string
  users: ProjectMember[]
  loading?: boolean
  loadingText?: string
}

// eslint-disable-next-line complexity
export function ProjectRole({
  project,
  subscription,
  role,
  roleName,
  users = [],
  loading,
  loadingText = 'Loading...',
}: Props) {
  const {org} = useCurrentScopeContext() ?? {}
  const {query} = useRouter()
  const {showDialog, Dialog, hideDialog} = useConfirmDialog()
  const {basePath} = useRoutePath()
  const toast = useToast()
  const queryClient = useQueryClient()
  const {data: canPay} = useOrganizationCanPay(org?.id)
  const [name, setName] = useState(role?.name || roleName)
  const [title, setTitle] = useState(role?.title || '')
  const [description, setDescription] = useState(role?.description || '')
  const [contentPermissionTabId, setContentPermissionTabId] = useState<'tags' | 'datasets'>('tags')
  const showRawPermissions = Boolean(query.raw)

  const [editRoleMembers, setEditRoleMembers] = useState(false)
  const handleRoleMembersDialogOpen = useCallback(() => {
    setEditRoleMembers(true)
  }, [setEditRoleMembers])

  const handleRoleMembersDialogClose = useCallback(() => {
    setEditRoleMembers(false)
  }, [setEditRoleMembers])

  const [roleWizard, setRoleWizard] = useState(false)
  const handleRoleWizardOpen = useCallback(() => {
    setRoleWizard(true)
  }, [])
  const handleRoleWizardClose = useCallback(() => {
    setRoleWizard(false)
  }, [])
  const [showAllDatasets, setShowAllDatasets] = useState(false)
  const handleShowAllDatasets = useCallback(() => {
    setShowAllDatasets(true)
  }, [])
  const [showAllTags, setShowAllTags] = useState(false)
  const handleShowAllTags = useCallback(() => {
    setShowAllTags(true)
  }, [])

  const {
    data: permissionResources,
    isLoading: loadingPR,
    error: errorPR,
  } = useProjectPermissionResources(project.id)

  const {
    data: permissionResourceSchemas,
    isLoading: loadingPRS,
    error: errorPRS,
  } = useProjectPermissionResourceSchemas(project.id)

  const {
    data: projectTags = [],
    isLoading: loadingTags,
    error: errorTags,
  } = useProjectTags(project.id)

  const {
    data: datasets = [],
    isLoading: loadingDatasets,
    error: errorDatasets,
  } = useDatasets(project.id)

  const isLoading =
    loadingPR || loadingPRS || loadingTags || loadingDatasets || loading || project === undefined
  const error = errorPR || errorPRS || errorTags || errorDatasets

  useEffect(() => {
    if (error) {
      toast.push({title: error.name, description: error.message, status: 'error'})
    }
  }, [toast, error])

  useEffect(() => {
    setName(role?.name || roleName)
    setTitle(role?.title || '')
    setDescription(role?.description || '')
    if (!role?.isCustom || (!loadingTags && projectTags.length === 0)) {
      setContentPermissionTabId('datasets')
    }
  }, [role, roleName, loadingTags, projectTags])

  const resources = useMemo(() => {
    if (!role) return []
    return Object.keys(role.grants ?? {}).reduce((acc, key) => {
      acc.push(...role.grants[key].map((pr) => ({...pr, type: key})))
      return acc
    }, [] as RoleResource[])
  }, [role])

  const deleteRoleMutation = useMutation({
    mutationFn: () => deleteProjectRole(project.id, roleName),
    onSuccess: () => {
      router.push(`${basePath}/access/roles`)
      toast.push({title: 'Role deleted', status: 'success'})
      queryClient.invalidateQueries({queryKey: getProjectRolesKey(project.id)})
    },
    onError: (err: Error) => {
      toast.push({title: 'Error deleting role', description: err.message, status: 'error'})
    },
  })

  const handleRoleDelete = useCallback(() => {
    deleteRoleMutation.mutate()
  }, [deleteRoleMutation])

  const disabledMembersAdd =
    role.name !== 'administrator' &&
    isProjectNonAdminUsersQuoted(subscription?.resources) &&
    !canPay?.status

  if (isLoading) {
    return (
      <Stack space={3}>
        <Flex justify="center">
          <Spinner muted size={1} />
        </Flex>
        <Flex justify="center">
          <Text size={1} muted>
            {loadingText}
          </Text>
        </Flex>
      </Stack>
    )
  }

  return (
    <>
      <Stack space={5} data-title={name} marginTop={3}>
        <Link href={`${basePath}/access/roles`} passHref legacyBehavior>
          <Text as="a">← All roles</Text>
        </Link>

        {!role.isCustom && (
          <Card tone="caution" padding={4} shadow={1} radius={2}>
            <Flex gap={4} align="center">
              <Text>
                <InfoOutlineIcon />
              </Text>
              <Text size={1}>This is a system default role and cannot be edited</Text>
            </Flex>
          </Card>
        )}

        <Box flex={1}>
          <Flex justify="space-between">
            <Flex align="center">
              <Box marginRight={4} marginLeft={1}>
                <Heading size={3}>
                  <UsersIcon />
                </Heading>
              </Box>
              <Heading as="h2" size={3} style={{fontWeight: 500}}>
                {title}
              </Heading>
            </Flex>
            <Flex align="center">
              {role.isCustom && (
                <Inline space={1}>
                  <PermissionButtonProject
                    onClick={handleRoleWizardOpen}
                    title="Edit role details"
                    icon={EditIcon}
                    permissions={[{permissionName: 'sanity.project.roles', grantName: 'update'}]}
                    disabled={loading}
                  />
                  <MenuButton
                    button={<Button icon={EllipsisVerticalIcon} mode="ghost" />}
                    id="menu-button-role"
                    menu={
                      <Menu>
                        <MenuItem
                          text="Delete role"
                          icon={TrashIcon}
                          tone="critical"
                          onClick={showDialog}
                        />
                      </Menu>
                    }
                    popover={{placement: 'bottom-end', preventOverflow: true}}
                  />
                </Inline>
              )}
            </Flex>
          </Flex>

          <Box marginTop={4}>
            <Text muted>{description || <em>No description</em>}</Text>
          </Box>
        </Box>

        <Card borderTop borderBottom paddingY={3}>
          <Flex align="center" gap={0}>
            <Flex align="flex-start" flex={1}>
              <Stack space={2}>
                <Label muted size={1}>
                  Identifier
                </Label>
                <Flex>
                  <Card padding={2} radius={2} tone="transparent">
                    <Inline space={2}>
                      <Code size={1}>{name}</Code>
                    </Inline>
                  </Card>
                </Flex>
              </Stack>
            </Flex>

            <Flex flex={1}>
              <Stack space={3}>
                <Label muted size={1}>
                  Members
                </Label>
                <Inline space={2}>
                  <AvatarStack>
                    {users
                      .filter((user) => !user.isRobot)
                      .map((user) => (
                        <MemberAvatar key={`member-${user.id}`} userId={user.id} />
                      ))}
                  </AvatarStack>
                  <Box>
                    <Button
                      fontSize={1}
                      padding={2}
                      icon={EditIcon}
                      mode="bleed"
                      onClick={handleRoleMembersDialogOpen}
                      text="Edit"
                    />
                  </Box>
                </Inline>
              </Stack>
            </Flex>
          </Flex>
        </Card>

        <Box marginTop={6}>
          <Heading size={2}>Content permissions</Heading>

          <Box marginTop={4}>
            <Text muted>
              Define permissions to content resources for this role.{' '}
              <a
                href={`https://www.${process.env.host}/docs/roles#ce3f205ab5ab`}
                target="_blank"
                rel="noreferrer"
              >
                Learn more →
              </a>
            </Text>
          </Box>
          {role.isCustom && projectTags.length > 0 && (
            <Card marginTop={4}>
              <TabList space={2}>
                <Tab
                  aria-controls="dataset-tag-permissions-panel"
                  icon={TagIcon}
                  id="dataset-tag-permissions-tab"
                  label="Tags"
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={() => setContentPermissionTabId('tags')}
                  selected={contentPermissionTabId === 'tags'}
                />
                <Tab
                  aria-controls="individual-dataset-permissions-panel"
                  icon={DatabaseIcon}
                  id="individual-dataset-permissions-tab"
                  label="Datasets"
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={() => setContentPermissionTabId('datasets')}
                  selected={contentPermissionTabId === 'datasets'}
                />
              </TabList>
            </Card>
          )}
          <TabPanel
            aria-labelledby="dataset-tag-permissions-tab"
            hidden={contentPermissionTabId !== 'tags'}
            id="dataset-tag-permissions-panel"
          >
            <Stack marginTop={4} space={4}>
              {projectTags.slice(0, showAllTags ? undefined : 5).map((tag) => (
                <ContentResource
                  key={tag.name}
                  tag={tag}
                  dataset={undefined}
                  resources={resources}
                  permissionResources={permissionResources}
                  projectId={project.id}
                  role={role}
                  readOnly={!role.isCustom}
                />
              ))}
              {projectTags.length > 5 && !showAllTags && (
                <Button
                  icon={EllipsisHorizontalIcon}
                  text="Show all tags"
                  mode="ghost"
                  onClick={handleShowAllTags}
                />
              )}
            </Stack>
          </TabPanel>

          <TabPanel
            aria-labelledby="individual-dataset-permissions-tab"
            hidden={contentPermissionTabId !== 'datasets'}
            id="individual-dataset-permissions-panel"
          >
            <Stack marginTop={4} space={4}>
              <ContentResource
                tag={undefined}
                dataset={undefined}
                resources={resources}
                permissionResources={permissionResources}
                projectId={project.id}
                role={role}
                readOnly={!role.isCustom}
              />

              {role.isCustom &&
                datasets
                  .slice(0, showAllDatasets ? undefined : 4)
                  .map((dataset) => (
                    <DatasetPermissionSummary
                      key={dataset.name}
                      dataset={dataset}
                      tags={projectTags}
                      resources={resources}
                      permissionResources={permissionResources}
                      project={project}
                      role={role}
                      readOnly={!role.isCustom}
                    />
                  ))}
              {datasets.length > 4 && !showAllDatasets && role.isCustom && (
                <Button
                  icon={EllipsisHorizontalIcon}
                  text="Show all datasets"
                  mode="ghost"
                  onClick={handleShowAllDatasets}
                />
              )}
            </Stack>
          </TabPanel>
        </Box>

        <Box marginTop={6}>
          <ManagePermissions
            projectId={project.id}
            resources={resources}
            role={role}
            permissionResources={permissionResources}
          />
        </Box>
        {showRawPermissions && (
          <Stack marginTop={4} space={4}>
            <Card tone="caution" border padding={4}>
              <Inline space={4}>
                <Text size={3} weight="semibold" muted>
                  <WarningOutlineIcon />
                </Text>
                <Stack space={2}>
                  <Text muted weight="bold">
                    Here be dragons
                  </Text>
                </Stack>
              </Inline>
            </Card>
            <RawPermissionsOverview
              projectId={project.id}
              role={role}
              resources={resources}
              permissionResources={permissionResources || []}
              permissionResourceSchemas={permissionResourceSchemas || []}
              datasets={datasets}
              tags={projectTags}
            />
          </Stack>
        )}
      </Stack>
      {roleWizard && (
        <UpdateRoleDialog
          role={role}
          onClose={handleRoleWizardClose}
          projectId={project.id}
          mode="edit"
        />
      )}
      <Dialog
        id="delete-role-dialog"
        header="Delete role"
        title="Do you really want to delete this role?"
        description="This action cannot be undone."
        onClose={hideDialog}
        onConfirm={handleRoleDelete}
        confirmButtonProps={{text: 'Delete'}}
        loading={loading || deleteRoleMutation.isPending}
      >
        <Card border radius={2} padding={1} paddingTop={2} paddingBottom={2}>
          <Flex align="center">
            <Stack space={2} flex={1}>
              <Inline space={2}>
                <Text weight="semibold" size={2}>
                  {role.title}
                </Text>
                <Text muted size={1}>
                  {role.name}
                </Text>
              </Inline>
              <Text muted size={1}>
                {role.description || <em>No description</em>}
              </Text>
            </Stack>
          </Flex>
        </Card>
      </Dialog>
      <ManageRoleMembersDialog
        onClose={handleRoleMembersDialogClose}
        project={project}
        role={role}
        members={users}
        disableAdd={disabledMembersAdd}
        open={editRoleMembers}
      />
    </>
  )
}
