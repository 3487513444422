import React, {useRef} from 'react'
import {Box, Stack, Card, studioTheme} from '@sanity/ui'
import {hues} from '@sanity/color'
import styled from 'styled-components'
import dynamic from 'next/dynamic'
import {renderToString} from 'react-dom/server'
import {format, parse, parseISO} from 'date-fns'
// import floor from 'lodash/floor'
import isNumber from 'lodash/isNumber'
import {Tooltip} from './tooltip'
import {
  formatDocumentsValue,
  formatRequestsValue,
  formatGigaBytesValue,
  getMaxYValueFromSeries,
  formatPrice,
  formatSimpleNumberValue,
  roundupNumber,
  useMatchesMedia,
} from '@/utils/index'
import {EmptyBlock, Loader} from '@/ui/index'
import {ChartDataStacked} from '@/types/index'

// eslint-disable-next-line react/display-name
const Chart = dynamic(() => import('react-apexcharts'), {ssr: false, loading: () => <p>...</p>})

type LineChartProps = ChartDataStacked

const LoadingWrapper = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 2rem;
`

const CHART_HEIGHT = 250
//const ORANGE = hues.orange['400'].hex
const COLORS = [
  hues.blue['400'].hex,
  hues.purple['400'].hex,
  hues.magenta['400'].hex,
  hues.red['400'].hex,
  hues.orange['400'].hex,
]

// https://apexcharts.com/docs/options/stroke/
// Annotation: Show previous month top as line
// Show overage as green + green arrow
export function ColumnChart(
  this: any,
  {
    dataSeries,
    stacked = false,
    chartType = 'bar',
    options,
    curveStroke = 'smooth',
    period = 'day',
    isCumulative = false,
    isLoading = false,
    isPlayground = false,
    isFullHistory = false,
    isCompareMode = false,
    isDifferentCompareYear = false,
    yAxisFormat,
    activeDataSeriesName,
    currency = 'USD',
    currentPlanQuota,
    showQuotaAnnotation = false,
    formatDataPointLabelAsISODate = false,
    emptyPlaceholderMessage = 'Nothing to show here yet',
    usageType,
  }: LineChartProps
) {
  const isMobile = useMatchesMedia(1)

  const formatters = new Map([
    ['number', formatRequestsValue],
    ['simpleNumber', formatSimpleNumberValue],
    ['documents', formatDocumentsValue],
    ['size', formatRequestsValue],
    ['bytesPrecise', formatRequestsValue],
    ['gigaBytes', formatGigaBytesValue],
    ['currency', (value: number) => formatPrice(value, 0, currency)],
  ])
  let xAxisFormatter = (value: number | string): string => {
    const currentYear = format(new Date(), 'yyyy')

    if (typeof value === 'undefined') {
      return ''
    }

    if (period === 'month') {
      if (!value) {
        return `${currentYear}-01-01`
      }

      if (isNumber(value)) {
        return format(parseISO(`${currentYear}-01-01`), 'MMM')
      }

      // If this doesn't contain a year, we have to add it
      if (!isNumber(value) && !value.includes('-')) {
        value = `${currentYear}-${value}`
      }

      // if (value > 12) {
      //   value = 12
      // }
      // if (value < 10) {
      //   value = `0${value}`
      // }
      return format(parseISO(`${value || `${currentYear}-01-01`}`), 'MMM')
    }

    if (isNumber(value)) {
      return parseInt(`${value}`, 10).toString()
      //return format(parseISO(`${currentYear}-${value}-01`), 'MMM')
    }

    // If this is a day number as string, we add a sanity check
    if (typeof value === 'string' && value.length < 3) {
      return value
    }

    return format(parseISO(value), 'd')
  }
  const yAxisAnnotations: YAxisAnnotations[] = []
  const maxYValueFromSeries = getMaxYValueFromSeries(
    dataSeries,
    isCumulative ? currentPlanQuota : 0,
    stacked,
    isCumulative
  )
  const filteredDataSeries = dataSeries.map((serie) => {
    const copy = {...serie}
    if (activeDataSeriesName) {
      copy.color = serie.name === activeDataSeriesName ? serie.color : hues.gray[100].hex
    }

    return copy
  })
  // Check if the data series actually has data
  const hasData = dataSeries.some((s) => s.data.length > 0)
  // Get the max value for the y axis based on the max value from the data series
  // round up to the nearest 100/1000/10000 etc based on the max value
  const maxYaxis = roundupNumber(Math.ceil(maxYValueFromSeries))
  const tickAmount = maxYaxis < 4 ? maxYaxis : 4
  const chartRef = useRef(null)
  const yAxisFormatter = formatters.get(yAxisFormat || '') || formatters.get('number')
  if (isFullHistory) {
    xAxisFormatter = (value: number | string): string => {
      if (typeof value === 'number') {
        return format(value, 'd. MMM')
      }
      return format(parseISO(value), 'd. MMM')
    }
  }

  if (currentPlanQuota && showQuotaAnnotation) {
    yAxisAnnotations.push({
      y: currentPlanQuota,
      offsetX: 90,
      borderColor: hues.green[400].hex,
      strokeDashArray: 1,
      label: {
        position: 'left',
        textAnchor: 'start',
        borderColor: 'transparent',
        offsetY: 6,
        offsetX: -8,
        style: {
          color: hues.green[400].hex,
          fontSize: '10px',
          fontFamily: studioTheme.fonts.text.family,
          padding: {
            left: 5,
            right: 5,
            bottom: 5,
          },
        },
        text: 'Current plan quota',
      },
    })
  }

  // Add markers when showing a stepline
  const markersConfig =
    curveStroke === 'stepline'
      ? {
          markers: {
            size: 4,
          },
        }
      : {
          markers: {
            size: 4,
            //colors: ['transparent'],
            // strokeColors: 'transparent',
          },
        }

  options = {
    ...options,
    ...markersConfig,
    chart: {
      animations: {
        enabled: false,
      },
      stacked,
      fontFamily: studioTheme.fonts.text.family,
      id: 'linechart',
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      offsetX: -15,
      events: {
        mounted: (ctx) => {
          if (chartRef && !chartRef.current) {
            chartRef.current = ctx
          }

          if (isPlayground) {
            const serie = dataSeries[dataSeries.length - 1]
            const lastX = serie?.data.length - 1
            const {x, y} = serie?.data[lastX]
            ctx?.addPointAnnotation({
              x,
              y,
              marker: {
                size: 3,
                strokeWidth: 12,
                fillColor: hues.blue[500].hex,
                strokeColor: hues.blue[500].hex,
              },
            })
          }
        },
      },
    },
    // colors: dataSeries.length > 1 ? ['#F1F3F6', hues.blue[500].hex] : [hues.blue[500].hex],
    colors: COLORS,
    dataLabels: {
      enabled: false,
    },
    fill: {
      colors: COLORS,
      type: 'solid',
      opacity: 1,
    },
    states: {
      hover: {
        filter: {
          type: 'none',
          value: 0.05,
        },
      },
    },
    stroke: isCompareMode
      ? {
          show: true,
          width: 2,
          colors: ['transparent'],
        }
      : {
          width: 2,
          curve: curveStroke,
        },
    grid: {
      borderColor: hues.gray[100].hex,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: period === 'month' ? '50%' : '50%',
        // borderRadius: 2,
        // endingShape: 'rounded',
        // endingShape: 'rounded',
        // This can't be 8 - it will create a too big of a border and cause a weird shape
        // borderRadius: 0,
      },
    },
    legend: {
      show: false,
      onItemClick: {
        toggleDataSeries: false,
      },
    },
    xaxis: {
      tickAmount: period === 'month' ? 'dataPoints' : 6,
      labels: {
        style: {
          colors: hues.gray[600].hex,
        },
        formatter: xAxisFormatter,
      },
      axisBorder: {
        show: false,
        color: hues.gray['100'].hex,
        offsetX: 0,
        offsetY: 0,
      },
      axisTicks: {
        show: false,
      },
      // type: isFullHistory ? 'datetime' : 'category',
      type: 'category',
    },
    yaxis: {
      max: maxYaxis,
      min: 0,
      tickAmount,
      axisBorder: {
        show: false,
        color: hues.gray[100].hex,
        offsetX: 0,
        offsetY: 0,
      },
      labels: {
        // align: 'left',
        // offsetX: 10,
        style: {
          colors: hues.gray[600].hex,
          fontFamily: studioTheme.fonts.text.family,
        },
        formatter: yAxisFormatter,
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      custom: ({series, seriesIndex, dataPointIndex, w}) =>
        renderToString(
          <Tooltip
            originalSeries={dataSeries}
            series={series}
            seriesIndex={seriesIndex}
            formatDataPointLabelAsISODate={formatDataPointLabelAsISODate}
            isDifferentCompareYear={isDifferentCompareYear}
            dataPointIndex={dataPointIndex}
            formatter={yAxisFormatter}
            period={period}
            w={w}
            stacked
          />
        ),
      x: {
        show: false,
        formatter: (val: number | string, opts?: any): string => {
          const {seriesIndex} = opts
          /**
           * Because Apex wants to put timestamped series after eachother in the graph instead of overlayed,
           * we 1) fallback to the category option 2) Set the day # (so 1-31) on the x axis 3) fetch the timestamp from the
           * series object with the provided index when we want to display the tooltip
           */
          if (typeof seriesIndex !== 'undefined') {
            const {dataPointIndex, w} = opts
            const timestamp = w?.config?.series?.[seriesIndex]?.data?.[dataPointIndex]?.timestamp

            if (timestamp) {
              return format(parse(`${timestamp}`, 'T', new Date()), 'MMMM do yyyy')
            }

            return val.toString()
          }

          if (typeof val === 'string' && val.includes('-') && val.length) {
            return format(parse(`${val}`, 'yyyy-mm', new Date()), 'MMMM do yyyy')
          }

          if ((val as string) === '') {
            return ''
          }

          return format(parse(`${val}`, 'T', new Date()), 'MMMM do yyyy')
        },
      },
    },
    annotations: {
      yaxis: yAxisAnnotations,
    },
  }

  const getNoDataMessage = (type: string) => {
    switch (type) {
      case 'assets':
        return 'An asset is any file (JPG, PDF, DOC etc) that is uploaded to the project'
      case 'documents':
        return 'Documents are the individual records that can be edited in Sanity Studio or over APIs'
      case 'apicdnrequests':
        return 'Start querying the APICDN and track the numbers here'
      case 'bandwidth':
        return 'Track the outgoing traffic numbers here'
      case 'apirequests':
        return 'Start querying the API and track the numbers here'
      case 'datasets':
        return 'Create datasets and track the numbers here'
      default:
        return ''
    }
  }

  return (
    <Stack space={[2, 2, 4]}>
      <Card
        radius={2}
        padding={[0, 0, 2]}
        style={{
          position: 'relative',
          minHeight: isMobile ? `${CHART_HEIGHT + 21}px` : `${CHART_HEIGHT + 31}px`,
        }}
      >
        {isLoading && (
          <LoadingWrapper>
            <Loader text="Loading history..." height={200} />
          </LoadingWrapper>
        )}
        {!hasData && !isLoading && (
          <EmptyBlock
            border={false}
            illustration="screen"
            title={emptyPlaceholderMessage}
            description={getNoDataMessage(usageType || '')}
          />
        )}

        {hasData && !isLoading && (
          <Box
            style={{
              position: 'relative',
              minHeight: isMobile ? `${CHART_HEIGHT + 11}px` : `${CHART_HEIGHT + 31}px`,
            }}
          >
            <Chart
              options={options}
              series={filteredDataSeries}
              type={chartType as any}
              width="100%"
              height={isMobile ? CHART_HEIGHT + 21 : CHART_HEIGHT}
            />
          </Box>
        )}
      </Card>
    </Stack>
  )
}
