import {type CurrentUser} from '../../types/models'
import {logout} from '../auth'
import {getCurrentUser, updateCurrentUser} from './controller'
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query'
import {type APIError} from '../util/request'
import {type ErrorResponse} from '../util/promiseRequest'
import {getCurrentUserKey} from '@/data/users/cache'

function isAPIError(error: unknown): error is ErrorResponse {
  return Boolean((error as ErrorResponse)?.body)
}

export function useCurrentUser() {
  const queryClient = useQueryClient()

  const query = useQuery<CurrentUser | null, APIError>({
    queryKey: getCurrentUserKey(),
    queryFn: async () => {
      try {
        const response = await getCurrentUser()

        return response
      } catch (error: unknown) {
        if (isAPIError(error) && error.statusCode === 401) {
          await logout()

          // Refresh the page to reflect the logged out state
          window.location.reload()

          return null
        }
        return null
      }
    },
    staleTime: 10 * 1000,
  })

  const patchMutation = useMutation({
    mutationKey: ['patchCurrentUser'],
    mutationFn: (data: {givenName: string; familyName: string}) => updateCurrentUser(data),
    onMutate: async (newData: {givenName: string; familyName: string}) => {
      await queryClient.cancelQueries({queryKey: getCurrentUserKey()})
      const previousData = queryClient.getQueryData(getCurrentUserKey())
      // Update project data
      queryClient.setQueryData(getCurrentUserKey(), (old: CurrentUser) => ({
        ...old,
        ...newData,
      }))

      return {previousData}
    },
    onError: (_err, _newData, context) => {
      // Revert user data
      queryClient.setQueryData(getCurrentUserKey(), context?.previousData)
    },
    onSettled: () => {
      queryClient.invalidateQueries({queryKey: getCurrentUserKey(), exact: true})
    },
  })

  return {...query, patchCurrentUser: patchMutation.mutate, isPending: patchMutation.isPending}
}
