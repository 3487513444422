import {CardNumberElement, CardExpiryElement, CardCvcElement} from '@stripe/react-stripe-js'
import * as stripeJs from '@stripe/stripe-js'
import React, {useState} from 'react'
import {Card, Box, Grid} from '@sanity/ui'
import styled, {css} from 'styled-components'
import {CardBrandList} from './cardBrandList'
import {BrandType} from './cardSingleBrand'
import {FormField} from '@/ui/index'
import {ResolvedScheme, manageTheme, useManageScheme} from '@/ui/app/manageSchemeProvider'

export type CardFormEvent = {
  empty: boolean
}

export type FocusedCardFieldKey = 'cardNumber' | 'cvc' | 'expiry' | undefined

type FormProps = {
  onChange?: (event: CardFormEvent) => void
  onFocus?: (focusKey: FocusedCardFieldKey) => void
}

type Errors = {
  cardNumber?: string
  cvc?: string
  expiry?: string
}

const FocusRing = styled.span<{isFocused: boolean}>`
  border-radius: ${({theme}) => theme.sanity.radius[2]}px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  pointer-events: none;
  z-index: 0;

  ${({isFocused}) =>
    isFocused &&
    css`
      box-shadow:
        inset 0 0 0 1px #ced2d9,
        0 0 0 1px var(--card-bg-color),
        0 0 0 3px var(--card-focus-ring-color);
    `}
`

const getInputColors = (theme: ResolvedScheme, type: 'default' | 'invalid' = 'default') =>
  manageTheme.v2!.color[theme].default.input[type].enabled

export const CardForm = ({onChange, onFocus}: FormProps) => {
  const [brandType, setBrandType] = useState<BrandType>(undefined)
  const {resolvedScheme} = useManageScheme()
  const [errors, setErrors] = useState<Errors>({
    cardNumber: undefined,
    cvc: undefined,
    expiry: undefined,
  })

  const [focused, setFocused] = useState<FocusedCardFieldKey>(undefined)

  const CARD_OPTIONS = {
    style: {
      base: {
        iconColor: getInputColors(resolvedScheme).fg,
        color: getInputColors(resolvedScheme).fg,
        fontFamily: manageTheme.v2!.font.text.family,
        fontSize: '16px',
        '::placeholder': {
          color: getInputColors(resolvedScheme).placeholder,
        },
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': {
          backgroundColor: getInputColors(resolvedScheme).bg,
          color: getInputColors(resolvedScheme).fg,
        },
      },
      invalid: {
        iconColor: getInputColors(resolvedScheme, 'invalid').fg,
        color: getInputColors(resolvedScheme, 'invalid').fg,
      },
    },
  }

  const handleFocus = (toggleKey: FocusedCardFieldKey, toggle = true) => {
    const newFocus = (focused && focused === toggleKey) || !toggle ? undefined : toggleKey
    setFocused(newFocus)

    if (onFocus) {
      onFocus(newFocus)
    }
  }

  const handleNumberChange = (event: stripeJs.StripeCardNumberElementChangeEvent) => {
    const {brand, error, empty} = event
    setBrandType(empty ? undefined : brand)
    setErrors({...errors, cardNumber: error?.message})

    if (onChange) {
      onChange({empty})
    }
  }

  const handleExpiryChange = (event: stripeJs.StripeCardExpiryElementChangeEvent) => {
    const {error} = event
    setErrors({...errors, expiry: error?.message})
  }

  const handleCvcChange = (event: stripeJs.StripeCardCvcElementChangeEvent) => {
    const {error} = event
    setErrors({...errors, cvc: error?.message})
  }

  return (
    <>
      <Box marginBottom={1}>
        <CardBrandList brandType={brandType} />
      </Box>
      <Box marginBottom={1}>
        <FormField label="Card number" error={errors.cardNumber}>
          <Card border radius={2} style={{position: 'relative'}} paddingX={[2, 2, 3]} paddingY={2}>
            <CardNumberElement
              options={CARD_OPTIONS}
              onChange={handleNumberChange}
              onFocus={handleFocus.bind(this, 'cardNumber', true)}
              onBlur={handleFocus.bind(this, 'cardNumber', false)}
            />
            <FocusRing isFocused={focused === 'cardNumber'} />
          </Card>
        </FormField>
      </Box>
      <Grid columns={2} gap={5} style={{maxWidth: '21rem'}}>
        <FormField label="Valid through" error={errors.expiry}>
          <Card border radius={2} style={{position: 'relative'}} paddingX={[2, 2, 3]} paddingY={2}>
            <CardExpiryElement
              options={CARD_OPTIONS}
              onChange={handleExpiryChange}
              onFocus={handleFocus.bind(this, 'expiry', true)}
              onBlur={handleFocus.bind(this, 'expiry', false)}
            />
            <FocusRing isFocused={focused === 'expiry'} />
          </Card>
        </FormField>
        <FormField label="CVC" error={errors.cvc}>
          <Card border radius={2} style={{position: 'relative'}} paddingX={[2, 2, 3]} paddingY={2}>
            <CardCvcElement
              options={CARD_OPTIONS}
              onChange={handleCvcChange}
              onFocus={handleFocus.bind(this, 'cvc', true)}
              onBlur={handleFocus.bind(this, 'cvc', false)}
            />
            <FocusRing isFocused={focused === 'cvc'} />
          </Card>
        </FormField>
      </Grid>
    </>
  )
}
