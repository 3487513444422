import {Card} from '@sanity/ui'
import React from 'react'
import styled, {css} from 'styled-components'

const InnerCard = styled(Card)<{$level: number; $visible: boolean}>`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  transition: inherit;
  overflow: auto;
  list-style: none;
  pointer-events: ${({$visible: visible}) => (visible ? 'all' : 'none')};
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  padding-top: 64px;
  transition: transform 0.27s ease-in-out;

  ${({$level: level, $visible: visible}) =>
    level === 0 &&
    visible &&
    css`
      transform: translateX(0%);
    `}

  ${({$level: level, $visible: visible}) =>
    level === 0 &&
    !visible &&
    css`
      transform: translateX(-100%);
    `}

    ${({$level: level, $visible: visible}) =>
    level === 1 &&
    visible &&
    css`
      transform: translateX(0%);
    `}

    ${({$level: level, $visible: visible}) =>
    level === 1 &&
    !visible &&
    css`
      transform: translateX(100%);
    `}
`

type Props = {
  level: number
  visible: boolean
  children: React.ReactNode
}

export function SlideList({level, visible, children}: Props) {
  return (
    <InnerCard $level={level} $visible={visible} as="ul">
      {children}
    </InnerCard>
  )
}
