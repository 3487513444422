import {Observable} from 'rxjs'
import {apiRequest} from '../util/request'
import {generateFilter} from '@/utils/index'
import {ActivityEvent, ActivityFilter} from '@/types/index'

export const API_VERSION = 'v2021-02-01'

// TODO:
// - add typings
// - fix hacky metadata issue

export function getActivity(params: ActivityFilter): Observable<ActivityEvent[]> {
  let metadata = ''
  if (params?.metadata) {
    metadata = generateFilter({metadata: params?.metadata})
    delete params.metadata
  }
  return apiRequest<any>({
    url: `https://api.${process.env.host}/${API_VERSION}/activity${metadata}`,
    query: {
      limit: 20,
      ...params,
    },
  })
}
