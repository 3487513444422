import {useCurrentScopeContext} from './useCurrentScopeContext'
import {Project} from '@/types/models'

export type ProjectType = 'regular' | 'org-plan' | 'playground' | 'growth-trial'

export function useProjectType(project?: Project): ProjectType {
  const {orgs} = useCurrentScopeContext()
  const org = orgs?.find((_org) => _org?.id === project?.organizationId)
  const isPlaygroundProject = !!(
    !org?.organizationPlan?.projectIds.includes(project?.id || '') && org?.organizationPlan
  )
  const isOrgPlanProject = !!(
    org?.organizationPlan?.projectIds.includes(project?.id || '') && org?.organizationPlan
  )

  if (isPlaygroundProject) return 'playground'
  if (isOrgPlanProject) return 'org-plan'
  return 'regular'
}
