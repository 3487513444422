import React from 'react'
import {Button} from '@sanity/ui'
import {ChartUpwardIcon} from '@sanity/icons'
import {ProjectUsageStatusTooltip, UsageSummary, UsageSummaryItem, AppLink} from '@/ui/index'
import {
  useCurrentScopeContext,
  useProjectType,
  useProjectUsageStatus,
  useRoutePath,
  useUsageContext,
} from '@/context/index'
import {
  NO_GRAPHS_RESOURCES,
  getResourcesArray,
  isProjectNonAdminUsersQuoted,
  isProjectUsersQuoted,
  getResourceSummary,
  isProjectNonViewerUsersQuoted,
  getMaxAllowedResourceUsage,
} from '@/utils/usage'
import {sortArrayOfObjects} from '@/utils/general'

import {useOrganizationCanPay} from '@/data/organizations'
import {useProjectUsage, useProjectSubscription} from '@/data/projects'

import {ResourceIds} from '@/types/index'
import {endOfMonth, startOfMonth} from 'date-fns'

export function UsageSummaryProject({isOverview}: {isOverview?: boolean}) {
  const {basePath} = useRoutePath()
  const {compareRange, currentPeriod, currentRange} = useUsageContext()
  const {project, org} = useCurrentScopeContext()

  const {data: projectUsage, isLoading: usageLoading} = useProjectUsage(project, {
    ...currentRange,
    period: currentPeriod,
  })
  const {data: compareUsage, isLoading: compareLoading} = useProjectUsage(project, {
    ...compareRange,
    period: currentPeriod,
  })
  const {data: subscription} = useProjectSubscription(project?.id)
  const {data: currentMonth, isLoading: currentMonthLoading} = useProjectUsage(project, {
    fromDate: startOfMonth(new Date()),
    toDate: endOfMonth(new Date()),
  })

  let resources = getResourcesArray(isOverview ? currentMonth?.resources : projectUsage?.resources)
  const compareResources = getResourcesArray(compareUsage?.resources)
  if (project && !org?.organizationPlan?.projectIds.includes(project.id)) {
    resources = getResourcesArray(
      getResourceSummary(isOverview ? currentMonth : projectUsage, org?.organizationPlan)
    )
  }

  const {tone, icon} = useProjectUsageStatus({project: project}) ?? {}
  const projectType = useProjectType(project)
  const {data: canPay} = useOrganizationCanPay(org?.id)
  const hideTooltip =
    projectType === 'playground' || org?.type == 'personal' || canPay?.status === false

  const noChartSections = NO_GRAPHS_RESOURCES

  let sortedResources = sortArrayOfObjects({array: resources, property: 'id'})

  const hasNonAdminQuota = isProjectNonAdminUsersQuoted(subscription?.resources)
  const hasNonViewerQuota = isProjectNonViewerUsersQuoted(subscription?.resources)
  const hasUserQuota = isProjectUsersQuoted(subscription?.resources)

  sortedResources = sortedResources.filter(({id}) => {
    if (id === 'non_admin_users' && !hasNonAdminQuota) return false
    if (id === 'non_viewer_users' && !hasNonViewerQuota) return false
    if (id === 'users' && !hasUserQuota) return false

    return true
  })

  const isLoading = usageLoading || compareLoading || currentMonthLoading

  return (
    <UsageSummary.Container
      columns={isOverview ? [1, 1, 1, 2] : [1, 1, 1, 2]}
      dense
      loading={isLoading}
      empty={!isLoading && resources.length === 0}
    >
      {sortedResources.map((data: UsageSummaryItem) => {
        const {usage: compare} = compareResources.find((r) => r.id === data.id) || {}

        const Link = ({children}: {children: React.ReactNode}) => {
          if (noChartSections.includes(data.id)) {
            return null
          }

          return isOverview ? (
            <AppLink href={`${basePath}/usage#${data.id}`}>{children}</AppLink>
          ) : (
            <a href={`#${data.id}`}>{children}</a>
          )
        }
        const quota = getMaxAllowedResourceUsage(subscription?.resources[data.id], true, false)

        return (
          <UsageSummary.Item
            key={data.id}
            hasOverage={data.hasOverage}
            approachingQuota={data.approachingQuota}
            id={data.id}
            usage={data.usage}
            quota={quota}
            unit={data.unit}
            overageTone={tone}
            overageIcon={icon}
            compare={compareRange && compare}
            prefix={
              project &&
              !hideTooltip && (
                <ProjectUsageStatusTooltip
                  placement="top"
                  project={project}
                  resourcesToCheck={[data?.id as ResourceIds]}
                />
              )
            }
            link={
              <Link>
                <Button icon={ChartUpwardIcon} mode="bleed" as="span" padding={2} />
              </Link>
            }
            dense
          />
        )
      })}
    </UsageSummary.Container>
  )
}
