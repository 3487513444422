import React from 'react'
import styled from 'styled-components'
import {Icon} from '@sanity/icons'
import {hues} from '@sanity/color'
import {ActivityBadgeStateKey} from '@/types/index'

const states: any = {
  edit: {
    icon: 'edit',
    tone: hues.yellow[400].hex,
  },
  add: {
    icon: 'add',
    tone: hues.green[500].hex,
  },
  sent: {
    icon: 'arrow-right',
    tone: hues.blue[500].hex,
  },
  delete: {
    icon: 'trash',
    tone: hues.red[500].hex,
  },
  copy: {
    icon: 'copy',
    tone: hues.green[500].hex,
  },
  archive: {
    icon: 'archive',
    tone: hues.gray[500].hex,
  },
  unarchive: {
    icon: 'retrieve',
    tone: hues.yellow[500].hex,
  },
  publish: {
    icon: 'publish',
    tone: hues.green[500].hex,
  },
  unpublish: {
    icon: 'unpublish',
    tone: hues.gray[500].hex,
  },
}

const OuterWrapper = styled.div`
  color: ${({theme}) => theme.sanity.color.muted.default.enabled.fg};
  font-size: 25px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Overflow = styled.div<{$squared?: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 41px;
  height: 41px;
  border-radius: ${({$squared}: {$squared?: boolean}) => ($squared ? '2px' : '41px')};
  border: 1px solid ${({theme}) => theme.sanity.color.muted.default.enabled.border};

  & [data-ui='Avatar'] {
    height: 100%;
    width: 100%;
    border-radius: ${({$squared}: {$squared?: boolean}) => ($squared ? '2px' : '41px')};
    &[data-fallback='true'] {
      padding: 5px;
    }
    & svg {
      height: 100%;
      width: 100%;
    }
  }
`

const Wrapper = styled.div`
  position: absolute;
  bottom: -3px;
  right: -5px;
`

const SecondaryIconWrapper = styled.div`
  background: var(--icon-bg-color);
  border: 1px solid ${({theme}) => theme.sanity.color.card.enabled.bg};
  border-radius: 50%;
  color: ${({theme}) => theme.sanity.color.card.enabled.bg};
  font-size: 14px;
  width: 1.125rem;
  height: 1.125rem;
  padding: 0;
  position: relative;
  box-sizing: content-box;

  svg {
    padding: 2px;
    height: 100%;
    width: 100%;
  }
`

type Props = {
  icon: ActivityBadgeStateKey
  children: React.ReactNode
  squared?: boolean
}

export const ActivityBadge = ({icon, squared, children}: Props) => {
  const state = states[icon]
  const secondaryWrapperStyles = {
    '--icon-bg-color': state.tone,
  } as React.CSSProperties

  return (
    <OuterWrapper>
      <Overflow $squared={squared}>{children}</Overflow>
      <Wrapper>
        <SecondaryIconWrapper style={secondaryWrapperStyles}>
          <Icon symbol={state.icon} />
        </SecondaryIconWrapper>
      </Wrapper>
    </OuterWrapper>
  )
}
