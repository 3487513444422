import {Box, Card, type CardProps} from '@sanity/ui'
import {rgba} from '@sanity/ui/theme'
import {forwardRef, useCallback, useEffect, useRef, useState} from 'react'
import styled, {css} from 'styled-components'

const Root = styled(Card)`
  position: relative;
`

const InnerBox = styled(Box)`
  overflow-y: hidden;
  overflow-x: auto;
`

const OverflowDivRight = styled.div(({theme}) => {
  const layer1 = theme.sanity.color.base.bg

  return css`
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 64px;
    opacity: 0;
    background: linear-gradient(to right, ${rgba(layer1, 0)}, ${rgba(layer1, 1)});
    transition: opacity 200ms;
    pointer-events: none;

    &[data-overflow='true'] {
      opacity: 1;
    }
  `
})

const OverflowDivLeft = styled.div(({theme}) => {
  const layer1 = theme.sanity.color.base.bg

  return css`
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 64px;
    opacity: 0;
    background: linear-gradient(to left, ${rgba(layer1, 0)}, ${rgba(layer1, 1)});
    transition: opacity 200ms;
    pointer-events: none;

    &[data-overflow='true'] {
      opacity: 1;
    }
  `
})

interface OverflowContainerProps extends CardProps {
  children: React.ReactNode
}

/**
 * A container that shows gradient overlays when the content overflows horizontally on both sides.
 */
export const OverflowContainer = forwardRef(function OverflowContainer(
  props: OverflowContainerProps,
  ref: React.Ref<HTMLDivElement>
) {
  const {children, ...rest} = props

  const containerRef = useRef<HTMLDivElement>(null)
  const [showRightOverflow, setShowRightOverflow] = useState<boolean>(false)
  const [showLeftOverflow, setShowLeftOverflow] = useState<boolean>(false)

  const checkOverflow = useCallback(() => {
    const container = containerRef.current

    if (container) {
      const scrollRightEdge = container.scrollLeft + container.clientWidth
      const contentWidth = container.scrollWidth

      const isRightOverflow = scrollRightEdge < contentWidth - 5
      const isLeftOverflow = container.scrollLeft > 5

      setShowRightOverflow(isRightOverflow)
      setShowLeftOverflow(isLeftOverflow)
    }
  }, [])

  useEffect(() => {
    checkOverflow()

    window.addEventListener('resize', checkOverflow)

    return () => {
      window.removeEventListener('resize', checkOverflow)
    }
  }, [checkOverflow])

  return (
    <Root overflow="hidden" sizing="border" data-testid="overflow-container" {...rest} ref={ref}>
      <InnerBox
        ref={containerRef}
        data-testid="overflow-container-content"
        height="fill"
        onScroll={checkOverflow}
      >
        {children}
      </InnerBox>

      <OverflowDivRight
        data-overflow={showRightOverflow}
        data-testid="overflow-container-gradient-right"
      />
      <OverflowDivLeft
        data-overflow={showLeftOverflow}
        data-testid="overflow-container-gradient-left"
      />
    </Root>
  )
})
