import {Stack, Text} from '@sanity/ui'
import {NextRouter} from 'next/router'
import React, {useCallback} from 'react'
import {Prompt} from '@/ui/index'
import {useWizardStore} from '@/components/payment-wizard'
import {Project} from '@/types/index'

export function DatasetVisibilityWarningPrompt({
  project,
  router,
}: {
  project: Project
  router: NextRouter
}) {
  const {dispatch} = useWizardStore()
  const handleUpgradePlan = useCallback(() => {
    const org = project.organizationId
    const url = org
      ? `/organizations/${org}/project/${project.id}/plan`
      : `/manage/personal/project/${project.id}/plan`

    router?.push(url)
    dispatch?.({type: 'newDatasetWizard/close'})
  }, [project.organizationId, project.id, router, dispatch])

  return (
    <Prompt
      cardProps={{marginTop: 3}}
      icon="info-outline"
      layout="column"
      description={
        <Stack space={3}>
          <Text size={2}>Private datasets are not available on your current plan.</Text>
          <Text size={2}>
            <a
              target="_blank"
              rel="noreferrer"
              style={{textDecoration: 'underline', color: 'inherit'}}
              onClick={handleUpgradePlan}
            >
              Upgrade your plan
            </a>{' '}
            to create private datasets
          </Text>
        </Stack>
      }
      tone="primary"
    />
  )
}
