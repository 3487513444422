import {ColorHueKey, hues} from '@sanity/color'
import {SanityMonogram as BaseSanityMonogram} from '@sanity/logos'
import styled, {css} from 'styled-components'

export const SanityMonogram = styled(BaseSanityMonogram)<{hueColor?: ColorHueKey}>((props: {
  hueColor?: ColorHueKey
}) => {
  const hueKey = props.hueColor || 'red'

  return css`
    & > rect {
      fill: ${hues[hueKey][500].hex};
    }

    & > path:nth-child(3),
    & > path:nth-child(4) {
      fill: ${hues[hueKey][200].hex};
    }
  `
})
