import React from 'react'
import {Button, Stack, Text} from '@sanity/ui'
import {CogIcon} from '@sanity/icons'
import {OrgBillingSummary} from './summary'
import {OrgBillingChart} from './chart'
import {PageProps, Tab} from '@/types/index'
import {TabHeader, AppLink} from '@/ui/index'
import {useCurrentScopeContext} from '@/context/index'
import {ArrearsStatusCard} from '@/components/arrears/arrearsStatusCard'
import {getDisplayablePaymentState} from '@/components/arrears/utils'

export function OrgBilling({description, referenceLink, ...pageProps}: PageProps & Tab) {
  const {id: orgId} = pageProps.org
  const {currentPaymentState = []} = useCurrentScopeContext()
  const {currentBillingAdminStatus = new Set<string>()} = useCurrentScopeContext()

  let billingCard: JSX.Element | undefined
  const paymentStateForThisOrg = currentPaymentState.find((s) => s.organizationId === orgId)
  if (paymentStateForThisOrg) {
    const displayPaymentStateForThisOrg = getDisplayablePaymentState(
      paymentStateForThisOrg,
      currentBillingAdminStatus.has(orgId)
    )
    if (displayPaymentStateForThisOrg) {
      billingCard = <ArrearsStatusCard paymentStateDisplay={displayPaymentStateForThisOrg} />
    }
  }
  // We fail silently if we cannot build a display payment state

  return (
    <Stack space={[4, 4, 6]}>
      <TabHeader
        title="Billing costs"
        description={description}
        referenceLink={referenceLink}
        button={
          <AppLink href={`/organizations/${orgId}/settings#billing`}>
            <Button mode="ghost" text="Change billing information" icon={CogIcon} />
          </AppLink>
        }
      />
      {billingCard}
      <OrgBillingSummary />
      <OrgBillingChart />
      <Text as="p" size={1} muted>
        Note: Billing and usage charts are estimates only, and do not account for any changes to
        plans, including any quota adjustments or price concessions applied on any project(s). You
        are only charged amounts reflected in the invoices issued.
      </Text>
    </Stack>
  )
}
