import {timeToMilliseconds} from '@/utils/time'

export const CACHE_TTL = timeToMilliseconds(30, 'minutes')
export const STALE_TTL = timeToMilliseconds(10, 'minutes')

export function getCurrentUserKey() {
  return ['currentUser']
}

export function getUserKey(userId?: string) {
  return ['user', userId]
}
