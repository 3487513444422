import {INITIAL_STATE} from '../constants'
import {Msg, WizardState} from './types'

export function reducer(state: WizardState, msg: Msg): WizardState {
  if (msg.type === 'reset') {
    return msg.value || INITIAL_STATE
  }
  if (msg.type === 'newDatasetWizard/open') {
    return {
      ...state,
      newDatasetWizard: {
        ...state.newDatasetWizard,
        open: true,
      },
    }
  }
  if (msg.type === 'newDatasetWizard/close') {
    return {
      ...state,
      newDatasetWizard: {
        ...state.newDatasetWizard,
        open: false,
      },
    }
  }
  if (msg.type === 'inviteMembersWizard/open') {
    return {
      ...state,
      inviteMembersWizard: {
        ...state.inviteMembersWizard,
        open: true,
      },
    }
  }
  if (msg.type === 'inviteMembersWizard/close') {
    return {
      ...state,
      inviteMembersWizard: {
        ...state.inviteMembersWizard,
        open: false,
      },
    }
  }
  if (msg.type === 'changePlanWizard/open') {
    return {
      ...state,
      changePlanWizard: {
        ...state.changePlanWizard,
        open: true,
      },
    }
  }
  if (msg.type === 'changePlanWizard/close') {
    return {
      ...state,
      changePlanWizard: {
        ...state.changePlanWizard,
        open: false,
      },
    }
  }

  if (msg.type === 'changePlanWizard/carderror') {
    return {
      ...state,
      existingCardDeclined: true,
      forceMoveToBillingStep: true,
    }
  }

  if (msg.type === 'changePlanWizard/carderror/forceToBillingStep') {
    return {
      ...state,
      forceMoveToBillingStep: false,
      stepIndex: msg.value > 0 ? msg.value : 0,
    }
  }

  if (msg.type === 'addBillingWizard/open') {
    return {
      ...state,
      addBillingWizard: {
        ...state.addBillingWizard,
        open: true,
      },
    }
  }

  if (msg.type === 'addBillingWizard/close') {
    return {
      ...state,
      addBillingWizard: {
        ...state.addBillingWizard,
        open: false,
      },
    }
  }

  if (msg.type === 'setSelectedOrgId') {
    return {
      ...state,
      createOrg: undefined,
      cardAdded: false,
      selectOrgId: msg.value || undefined,
    }
  }

  if (msg.type === 'setTransferToOrg') {
    return {
      ...state,
      transferToOrg: msg.value,
    }
  }

  if (msg.type === 'setCreateOrg') {
    return {
      ...state,
      createOrg: msg.value,
    }
  }

  if (msg.type === 'setCreatedOrg') {
    return {
      ...state,
      cardAdded: false,
      createOrg: {...(state.createOrg || {isValidFormData: false}), createdOrg: msg.value},
    }
  }

  if (msg.type === 'newDatasetWizard/setCreateDataset') {
    return {
      ...state,
      newDatasetWizard: {
        ...state.newDatasetWizard,
        dataset: msg.value,
      },
    }
  }

  if (msg.type === 'inviteMembersWizard/setForm') {
    return {
      ...state,
      inviteMembersWizard: {
        ...state.inviteMembersWizard,
        form: msg.value,
      },
    }
  }

  if (msg.type === 'setStepIndex') {
    return {
      ...state,
      stepIndex: msg.value,
    }
  }

  if (msg.type === 'setForceShowTransferStep') {
    return {
      ...state,
      forceShowTransferStep: msg.value,
    }
  }

  if (msg.type === 'setTargetPlan') {
    return {
      ...state,
      targetPlan: msg.value,
    }
  }

  if (msg.type === 'setUseCoupon') {
    return {
      ...state,
      useCoupon: msg.value,
      nextButtonDisabled: true,
    }
  }

  if (msg.type === 'setCouponCode') {
    return {
      ...state,
      couponCode: msg.value,
    }
  }

  if (msg.type === 'setCouponError') {
    return {
      ...state,
      couponError: msg.value,
    }
  }

  if (msg.type === 'setCardAdded') {
    return {
      ...state,
      cardAdded: msg.value || true,
      existingCardDeclined: false,
    }
  }

  if (msg.type === 'setNextButtonDisabled') {
    return {...state, nextButtonDisabled: Boolean(msg.value)}
  }

  if (msg.type === 'setSubscriptionIncomplete') {
    return {
      ...state,
      subscriptionIncomplete: msg.value,
      existingCardDeclined: msg.value,
    }
  }

  // console.warn('unhandled', msg.type)
  return state
}
