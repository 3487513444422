import React from 'react'

export function CreditCardIcon() {
  return (
    <svg
      data-sanity-icon="CreditCardIcon"
      width="1em"
      height="1em"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5 18.5H5.5C4.94772 18.5 4.5 18.0523 4.5 17.5V7.5C4.5 6.94772 4.94772 6.5 5.5 6.5H19.5C20.0523 6.5 20.5 6.94772 20.5 7.5V17.5C20.5 18.0523 20.0523 18.5 19.5 18.5Z"
        stroke="currentColor"
        strokeWidth="1.2"
      />
      <path d="M4.5 10.5H20.5" stroke="currentColor" strokeWidth="1.2" />
      <path d="M4.5 9.5H20.5" stroke="currentColor" strokeWidth="1.2" />
      <path d="M4.5 11.5H20.5" stroke="currentColor" strokeWidth="1.2" />
      <path d="M7 15.5H14" stroke="currentColor" />
    </svg>
  )
}
