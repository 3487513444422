/* eslint-disable complexity */
import React from 'react'

export const AccessDenied = ({color}: {color?: string}) => (
  <svg width="77" height="83" viewBox="0 0 77 83" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M64 63V4C64 2.34315 62.6569 1 61 1H16C14.3431 1 13 2.34315 13 4V63"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M64 63V7C64 5.34315 62.6569 4 61 4H16C14.3431 4 13 5.34315 13 7V63"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <rect
      x="18"
      y="9"
      width="41"
      height="31"
      rx="1"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M19.8714 11.2316C19.9499 11.0763 20.0791 10.9609 20.2413 10.8979C21.2911 10.4902 25.9839 9 38.5 9C51.0161 9 55.7089 10.4902 56.7587 10.8979C56.9209 10.9609 57.0501 11.0763 57.1286 11.2316C57.5704 12.1065 59 15.6228 59 24.5C59 33.3772 57.5704 36.8935 57.1286 37.7684C57.0501 37.9237 56.9209 38.0391 56.7587 38.1021C55.7089 38.5098 51.0161 40 38.5 40C25.9839 40 21.2911 38.5098 20.2413 38.1021C20.0791 38.0391 19.9499 37.9237 19.8714 37.7684C19.4296 36.8935 18 33.3772 18 24.5C18 15.6228 19.4296 12.1065 19.8714 11.2316Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <rect
      x="18"
      y="53"
      width="3"
      height="3"
      rx="1"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M53 49.6H59V48.4H53V49.6ZM59.4 50V51H60.6V50H59.4ZM59 51.4H53V52.6H59V51.4ZM52.6 51V50H51.4V51H52.6ZM53 51.4C52.7791 51.4 52.6 51.2209 52.6 51H51.4C51.4 51.8837 52.1163 52.6 53 52.6V51.4ZM59.4 51C59.4 51.2209 59.2209 51.4 59 51.4V52.6C59.8837 52.6 60.6 51.8837 60.6 51H59.4ZM59 49.6C59.2209 49.6 59.4 49.7791 59.4 50H60.6C60.6 49.1163 59.8837 48.4 59 48.4V49.6ZM53 48.4C52.1163 48.4 51.4 49.1163 51.4 50H52.6C52.6 49.7791 52.7791 49.6 53 49.6V48.4Z"
      fill={color || 'currentColor'}
    />
    <rect
      x="38"
      y="50"
      width="14"
      height="1"
      rx="0.5"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M12 64H4.80842C4.33688 64 3.92941 64.3294 3.83061 64.7905L1.0222 77.8964C1.00744 77.9653 1 78.0355 1 78.1059V81C1 81.5523 1.44771 82 2 82H75C75.5523 82 76 81.5523 76 81V78.1059C76 78.0355 75.9926 77.9653 75.9778 77.8964L73.1694 64.7905C73.0706 64.3294 72.6631 64 72.1916 64H64.5"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <rect
      x="1"
      y="78"
      width="75"
      height="4"
      rx="1"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M12 70C12 69.4477 11.5523 69 11 69H9C8.44772 69 8 69.4477 8 70V72H12V70Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M24 70C24 69.4477 23.5523 69 23 69H21C20.4477 69 20 69.4477 20 70V71H24V70Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M36 70C36 69.4477 35.5523 69 35 69H33C32.4477 69 32 69.4477 32 70V71H36V70Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M48 70C48 69.4477 47.5523 69 47 69H45C44.4477 69 44 69.4477 44 70V71H48V70Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M12 70C12 69.4477 12.4477 69 13 69H15C15.5523 69 16 69.4477 16 70V71H12V70Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M24 70C24 69.4477 24.4477 69 25 69H27C27.5523 69 28 69.4477 28 70V71H24V70Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M36 70C36 69.4477 36.4477 69 37 69H39C39.5523 69 40 69.4477 40 70V71H36V70Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M48 70C48 69.4477 48.4477 69 49 69H51C51.5523 69 52 69.4477 52 70V71H48V70Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M20 70C20 69.4477 19.5523 69 19 69H17C16.4477 69 16 69.4477 16 70V71H20V70Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M32 70C32 69.4477 31.5523 69 31 69H29C28.4477 69 28 69.4477 28 70V71H32V70Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M44 70C44 69.4477 43.5523 69 43 69H41C40.4477 69 40 69.4477 40 70V71H44V70Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M56 70C56 69.4477 55.5523 69 55 69H53C52.4477 69 52 69.4477 52 70V71H56V70Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M22 72C22 71.4477 22.4477 71 23 71H53C53.5523 71 54 71.4477 54 72V74H22V72Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M60 70C60 69.4477 59.5523 69 59 69H57C56.4477 69 56 69.4477 56 70V71H60V70Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M16 72C16 71.4477 16.4477 71 17 71H21C21.5523 71 22 71.4477 22 72V74H16V72Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M54 72C54 71.4477 54.4477 71 55 71H59C59.5523 71 60 71.4477 60 72V74H54V72Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M60 72V70C60 69.4477 60.4477 69 61 69H67C67.5523 69 68 69.4477 68 70V72H64"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M16 72C16 71.4477 15.5523 71 15 71H13C12.4477 71 12 71.4477 12 72V74H16V72Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M64 72C64 71.4477 63.5523 71 63 71H61C60.4477 71 60 71.4477 60 72V74H64V72Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M60 67V66C60 65.4477 60.4477 65 61 65H65.2192C65.6781 65 66.0781 65.3123 66.1894 65.7575L67 69H63.5"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M55 68C55 67.4477 54.5523 67 54 67H52C51.4477 67 51 67.4477 51 68V69H55V68Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M43 68C43 67.4477 42.5523 67 42 67H40C39.4477 67 39 67.4477 39 68V69H43V68Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M30 68C30 67.4477 29.5523 67 29 67H27C26.4477 67 26 67.4477 26 68V69H30V68Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M18 68C18 67.4477 17.5523 67 17 67H15C14.4477 67 14 67.4477 14 68V69H18V68Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M63 68C63 67.4477 62.5523 67 62 67H60C59.4477 67 59 67.4477 59 68V69H63V68Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M51 68C51 67.4477 50.5523 67 50 67H48C47.4477 67 47 67.4477 47 68V69H51V68Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M39 68C39 67.4477 38.5523 67 38 67H36C35.4477 67 35 67.4477 35 68V69H39V68Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M26 68C26 67.4477 25.5523 67 25 67H23C22.4477 67 22 67.4477 22 68V69H26V68Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M14 68C14 67.4477 13.5523 67 13 67H10C9.44772 67 9 67.4477 9 68V69H14V68Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M16 66C16 65.4477 15.5523 65 15 65H11C10.4477 65 10 65.4477 10 66V67H16V66Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M59 68C59 67.4477 58.5523 67 58 67H56C55.4477 67 55 67.4477 55 68V69H59V68Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M47 68C47 67.4477 46.5523 67 46 67H44C43.4477 67 43 67.4477 43 68V69H47V68Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M34 68C34 67.4477 33.5523 67 33 67H31C30.4477 67 30 67.4477 30 68V69H34V68Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M22 68C22 67.4477 21.5523 67 21 67H19C18.4477 67 18 67.4477 18 68V69H22V68Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M56 66C56 65.4477 55.5523 65 55 65H53C52.4477 65 52 65.4477 52 66V67H56V66Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M44 66C44 65.4477 43.5523 65 43 65H41C40.4477 65 40 65.4477 40 66V67H44V66Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M32 66C32 65.4477 31.5523 65 31 65H29C28.4477 65 28 65.4477 28 66V67H32V66Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M20 66C20 65.4477 19.5523 65 19 65H17C16.4477 65 16 65.4477 16 66V67H20V66Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M28 64C28 63.4477 27.5523 63 27 63H25C24.4477 63 24 63.4477 24 64V65H28V64Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M44 64C44 63.4477 43.5523 63 43 63H41C40.4477 63 40 63.4477 40 64V65H44V64Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M60 64C60 63.4477 59.5523 63 59 63H57C56.4477 63 56 63.4477 56 64V65H60V64Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M20 64C20 63.4477 19.5523 63 19 63H17C16.4477 63 16 63.4477 16 64V65H20V64Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M36 64C36 63.4477 35.5523 63 35 63H33C32.4477 63 32 63.4477 32 64V65H36V64Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M52 64C52 63.4477 51.5523 63 51 63H49C48.4477 63 48 63.4477 48 64V65H52V64Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M65 64C65 63.4477 64.5523 63 64 63H62C61.4477 63 61 63.4477 61 64V65H65V64Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M52 66C52 65.4477 51.5523 65 51 65H49C48.4477 65 48 65.4477 48 66V67H52V66Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M40 66C40 65.4477 39.5523 65 39 65H37C36.4477 65 36 65.4477 36 66V67H40V66Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M28 66C28 65.4477 27.5523 65 27 65H25C24.4477 65 24 65.4477 24 66V67H28V66Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M60 66C60 65.4477 59.5523 65 59 65H57C56.4477 65 56 65.4477 56 66V67H60V66Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M48 66C48 65.4477 47.5523 65 47 65H45C44.4477 65 44 65.4477 44 66V67H48V66Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M36 66C36 65.4477 35.5523 65 35 65H33C32.4477 65 32 65.4477 32 66V67H36V66Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M24 66C24 65.4477 23.5523 65 23 65H21C20.4477 65 20 65.4477 20 66V67H24V66Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M16 64C16 63.4477 15.5523 63 15 63H13C12.4477 63 12 63.4477 12 64V65H16V64Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M32 64C32 63.4477 31.5523 63 31 63H29C28.4477 63 28 63.4477 28 64V65H32V64Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M48 64C48 63.4477 47.5523 63 47 63H45C44.4477 63 44 63.4477 44 64V65H48V64Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M24 64C24 63.4477 23.5523 63 23 63H21C20.4477 63 20 63.4477 20 64V65H24V64Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M40 64C40 63.4477 39.5523 63 39 63H37C36.4477 63 36 63.4477 36 64V65H40V64Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M56 64C56 63.4477 55.5523 63 55 63H53C52.4477 63 52 63.4477 52 64V65H56V64Z"
      stroke={color || 'currentColor'}
      strokeWidth="1.2"
    />
    <path
      d="M27 20H29.5V21.5M29.5 21.5V23H27V21.5H29.5ZM33.5 20H31V23H33.5M37.5 20H35V23H37.5M39 21.5H41.5V20H39V23H41.5M43 23H45.5V21.5H43V20H45.5M47 23H49.5V21.5H47V20H49.5M29.5 25.5V26.5M29.5 26.5V28.5H27V26.5H29.5ZM31 27H33.5V25.5H31V28.5H33.5M37.5 28.5V25.5H35V28.5M39 28.5H40.5M41.5 28.5H40.5M40.5 28.5L40.5 27H39.5M43 27H45.5V25.5H43V28.5H45.5M50 25.5V26.5M50 26.5V28.5H47V26.5H50Z"
      stroke={color || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M40 25.25C40 25.1119 40.1119 25 40.25 25H40.75C40.8881 25 41 25.1119 41 25.25V25.75C41 25.8881 40.8881 26 40.75 26H40.25C40.1119 26 40 25.8881 40 25.75V25.25Z"
      fill={color || 'currentColor'}
    />
  </svg>
)
AccessDenied.displayName = 'AccessDenied'
