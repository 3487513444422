import {GettingStarted, Tab} from '@/data/index'
import {useManageScheme} from '@/ui/app/manageSchemeProvider'
import {Flex, Button, Text} from '@sanity/ui'
import Image from 'next/image'
import {useCallback} from 'react'
import styled from 'styled-components'

interface Props {
  tabs: GettingStarted['tabs']
  activeTabSlug: string | null
  onChange: (tabSlug: string) => void
}

const StyledButton = styled(Button)<{isActive: boolean}>`
  height: 44px;
  box-shadow: ${(p) =>
    p.isActive ? 'inset 0 0 0 1px var(--card-focus-ring-color)' : 'none'} !important;
  background-color: ${(p) => (p.isActive ? 'transparent' : 'var(--card-bg-color)')} !important;

  &:focus-visible {
    box-shadow: inset 0 0 0 1px var(--card-focus-ring-color) !important;
  }

  [data-ui='Text'] {
    color: ${(p) => (p.isActive ? p.theme.sanity.color.base.fg : 'var(--card-fg-color)')};
  }
`

export const TabsSwitcher = ({tabs, activeTabSlug, onChange}: Props) => {
  const {resolvedScheme} = useManageScheme()

  const getIconUrl = useCallback(
    (tab: Tab) => {
      const defaultImage = tab.icon?.asset?.url
      const darkModeImage = tab.icon?.darkModeImage?.url
      return resolvedScheme === 'dark' && darkModeImage ? darkModeImage : defaultImage
    },
    [resolvedScheme]
  )

  return (
    <Flex gap={2} overflow="auto" role="tablist">
      {tabs.map((tab) => {
        const isActive = tab.slug.current === activeTabSlug
        return (
          <StyledButton
            aria-selected={isActive}
            isActive={isActive}
            key={tab._key}
            mode="ghost"
            onClick={() => onChange(tab.slug.current)}
            radius={3}
            role="tab"
            width="fill"
          >
            <Flex align="center" gap={2} justify="center">
              <Image src={getIconUrl(tab)} width={16} height={16} alt="" />
              <Text size={1} weight="semibold">
                {tab.title}
              </Text>
            </Flex>
          </StyledButton>
        )
      })}
    </Flex>
  )
}
