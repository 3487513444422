import {getAddOnsCopy} from '../project/plan/addonCopyConfig'
import {ActivityConfig, ActivityEvent, EventCategory} from '@/types/index'
import {generateActionsArray} from '@/utils/activity'

// Type of events
// 1. Organization
// 2. Project
// 3. Studio
export const activityConfig = new Map<string, ActivityConfig>([
  [
    'project.members.invitations.create',
    {
      descriptionTemplate:
        '"{{metadata.email}}" was invited to {{projectDisplayName}} with "{{metadata.role}}" access',
      mainIconName: 'envelope',
      secondaryIconName: 'sent',
    },
  ],
  [
    'project.datasets.delete',
    {
      descriptionTemplate: 'Dataset "{{datasetName}}" was deleted',
      mainIconName: 'database',
      secondaryIconName: 'delete',
    },
  ],
  [
    'project.externalStudioHost.edit',
    {
      descriptionTemplate:
        'The custom studio URL was changed from "https://sanity-design-studio.vercel.app/" to "https://sanity-design-studio.sanity.build/"',
      mainIconName: 'database',
      secondaryIconName: 'edit',
    },
  ],
  // Organization
  [
    'organization.create',
    {
      // Event Trigger: user creates organization
      showAvatar: true,
      secondaryIconName: 'add',
      descriptionTemplate: 'The organization was created',
    },
  ],
  [
    'organization.name.edit',
    {
      // Event Trigger: user changes organization’s name
      mainIconName: 'cog',
      secondaryIconName: 'edit',
      descriptionTemplate:
        'The organization was renamed from "{{metadata.oldOrganizationName}}" to "{{metadata.organizationName}}"',
    },
  ],
  [
    'organization.defaultRoleName.edit',
    {
      // Event Trigger: user changes organization’s default role
      mainIconName: 'cog',
      secondaryIconName: 'edit',
      descriptionTemplate: (event: ActivityEvent) => {
        if (
          (!event.metadata?.oldDefaultRoleName || event.metadata?.oldDefaultRoleName === 'null') &&
          event.metadata?.newDefaultRoleName
        ) {
          return `The default role of the organization was changed to "{{metadata.newDefaultRoleName}}"`
        }

        if (
          event.metadata?.oldDefaultRoleName &&
          (!event.metadata?.newDefaultRoleName || event.metadata?.newDefaultRoleName === 'null')
        ) {
          return `The default role of the organization was removed`
        }

        return `The default role of the organization was changed from "{{metadata.oldDefaultRoleName}}" to "{{metadata.newDefaultRoleName}}"`
      },
    },
  ],
  [
    'organization.slug.edit',
    {
      // Event Trigger: user changes organization’s slug
      mainIconName: 'cog',
      secondaryIconName: 'edit',
      descriptionTemplate: (event: ActivityEvent) => {
        if (
          (!event.metadata?.oldOrganizationSlug ||
            event.metadata?.oldOrganizationSlug === 'null' ||
            event.metadata?.oldOrganizationSlug === '') &&
          event.metadata?.organizationSlug
        ) {
          return `The slug of the organization was changed to "{{metadata.organizationSlug}}"`
        }

        if (
          event.metadata?.oldOrganizationSlug &&
          (!event.metadata?.organizationSlug ||
            event.metadata?.organizationSlug === 'null' ||
            event.metadata?.organizationSlug === '')
        ) {
          return `The slug of the organization was removed`
        }

        return `The slug of the organization was changed from "{{metadata.oldOrganizationSlug}}" to "{{metadata.organizationSlug}}"`
      },
    },
  ],
  [
    'organization.project.create',
    {
      // Event Trigger: user changes organization’s name
      showAvatar: true,
      secondaryIconName: 'edit',
      descriptionTemplate: (event: ActivityEvent) => {
        if (event.metadata?.transferredFromOrganizationId) {
          event.metadata.displayName =
            event.metadata?.transferredFromOrganizationName ||
            event.metadata?.transferredToOrganizationId

          return `The ownership of the project was transferred from the organization "{{metadata.displayName}}"`
        }

        return 'The project was assigned to the organization'
      },
    },
  ],
  [
    'organization.project.add',
    {
      showAvatar: true,
      secondaryIconName: 'edit',
      descriptionTemplate:
        'The ownership of the project was transferred to the organization "{{organizationDisplayName}}"',
    },
  ],
  [
    'organization.project.delete',
    {
      showAvatar: true,
      secondaryIconName: 'delete',
      descriptionTemplate: (event: ActivityEvent) => {
        if (event.metadata?.transferredToOrganizationId) {
          event.metadata.displayName =
            event.metadata?.transferredToOrganizationName ||
            event.metadata?.transferredToOrganizationId
          return `The ownership of the project was transferred to the organization "{{metadata.displayName}}"`
        }

        return 'The project was unassigned from the organization'
      },
    },
  ],
  [
    'organization.billing.address.edit',
    {
      // Event Trigger: user changes billing address
      mainIconName: 'cog',
      secondaryIconName: 'edit',
      descriptionTemplate: 'The billing address of the organization changed',
    },
  ],
  [
    'organization.billing.paymentMethod.create',
    {
      // Event Trigger: user creates payment method
      mainIconName: 'cog',
      secondaryIconName: 'add',
      descriptionTemplate: 'A payment method was created for the organization',
    },
  ],
  [
    'organization.billing.paymentMethod.delete',
    {
      // Event Trigger: user removes payment method
      mainIconName: 'cog',
      secondaryIconName: 'delete',
      descriptionTemplate: 'A payment method was removed from the organization',
    },
  ],
  [
    'organization.billing.paymentMethod.edit',
    {
      // Event Trigger: user changes payment method
      mainIconName: 'cog',
      secondaryIconName: 'edit',
      descriptionTemplate: 'A payment method of from the organization was changed',
    },
  ],
  [
    'organization.contact.euRepresentative.edit',
    {
      // Event Trigger: user changes EU Representative
      mainIconName: 'cog',
      secondaryIconName: 'edit',
      descriptionTemplate: 'The EU Representative of the organization was changed',
    },
  ],
  [
    'organization.contact.dataProtectionOfficer.edit',
    {
      // Event Trigger: user changes Data Protection\nOfficer
      mainIconName: 'cog',
      secondaryIconName: 'edit',
      descriptionTemplate: 'The Data Protection Officer of the organization was changed',
    },
  ],
  [
    'organization.members.role.create',
    {
      // Event Trigger: user changes user’s role
      mainIconName: 'cog',
      secondaryIconName: 'edit',
      descriptionTemplate: (event: ActivityEvent) => {
        event.metadata.displayNameOld = event.metadata?.oldRoleName || event.metadata?.oldRole
        event.metadata.displayName = event.metadata?.roleName || event.metadata?.role

        // return `The organization role of "{{userName}}" changed from "{{metadata.displayNameOld}}" to "{{metadata.displayName}}"`
        return `"{{userName}}" was granted the role "{{metadata.roleName}}" on the organization`
      },
    },
  ],
  [
    'organization.members.role.edit',
    {
      // Event Trigger: user changes user’s role
      mainIconName: 'cog',
      secondaryIconName: 'edit',
      descriptionTemplate: (event: ActivityEvent) => {
        event.metadata.displayNameOld = event.metadata?.oldRoleName || event.metadata?.oldRole
        event.metadata.displayName = event.metadata?.roleName || event.metadata?.role

        return `The organization role of "{{userName}}" changed from "{{metadata.displayNameOld}}" to "{{metadata.displayName}}"`
      },
    },
  ],
  [
    'organization.members.role.delete',
    {
      mainIconName: 'cog',
      secondaryIconName: 'edit',
      descriptionTemplate: (event: ActivityEvent) => {
        event.metadata.displayNameOld = event.metadata?.oldRoleName || event.metadata?.oldRole
        event.metadata.displayName = event.metadata?.roleName || event.metadata?.role

        return `"{{userName}}" was removed from the role "{{metadata.roleName}}" on the organization`
      },
    },
  ],
  [
    'organization.grant.create',
    {
      mainIconName: 'cog',
      secondaryIconName: 'add',
      descriptionTemplate:
        'Grant for organization role "{{metadata.roleName}}" was given "{{metadata.permissionName}}" on "{{metadata.permissionResourceType}}"',
    },
  ],
  [
    'organization.grant.delete',
    {
      mainIconName: 'cog',
      secondaryIconName: 'delete',
      descriptionTemplate:
        'Grant for organization role "{{metadata.roleName}}" was removed "{{metadata.permissionName}}" on "{{metadata.permissionResourceType}}"',
    },
  ],
  // Permission resources
  [
    'organization.permissionResource.create',
    {
      mainIconName: 'cog',
      secondaryIconName: 'add',
      descriptionTemplate: 'Permission resource "{{metadata.title}}" was created',
    },
  ],
  [
    'organization.permissionResource.update',
    {
      mainIconName: 'cog',
      secondaryIconName: 'edit',
      descriptionTemplate: 'Permission resource "{{metadata.title}}" was updated',
    },
  ],
  [
    'organization.permissionResource.delete',
    {
      mainIconName: 'cog',
      secondaryIconName: 'delete',
      descriptionTemplate: 'Permission resource "{{metadata.title}}" was deleted',
    },
  ],
  // Org roles
  [
    'organization.role.create',
    {
      mainIconName: 'cog',
      secondaryIconName: 'add',
      descriptionTemplate: 'Role "{{metadata.title}}" was created',
    },
  ],
  [
    'organization.role.delete',
    {
      mainIconName: 'cog',
      secondaryIconName: 'delete',
      descriptionTemplate: 'Role "{{metadata.title}}" was deleted',
    },
  ],
  // Project roles
  [
    'project.members.role.create',
    {
      mainIconName: 'cog',
      secondaryIconName: 'add',
      descriptionTemplate:
        '"{{userName}}" was granted the role "{{metadata.roleTitle}}" on the project',
    },
  ],
  [
    'project.members.role.delete',
    {
      mainIconName: 'cog',
      secondaryIconName: 'delete',
      descriptionTemplate:
        '"{{userName}}" was removed from the role "{{metadata.roleTitle}}" on the project',
    },
  ],
  // Project role grants
  [
    'project.grant.create',
    {
      mainIconName: 'cog',
      secondaryIconName: 'add',
      descriptionTemplate:
        'Grant for project role "{{metadata.roleName}}" was given on "{{metadata.permissionName}}" on "{{metadata.permissionResourceType}}"',
    },
  ],
  [
    'project.grant.delete',
    {
      mainIconName: 'cog',
      secondaryIconName: 'delete',
      descriptionTemplate:
        'Grant for project role "{{metadata.roleName}}" was removed on "{{metadata.permissionName}}" for "{{metadata.permissionResourceType}}"',
    },
  ],
  // Permission resources
  [
    'project.permissionResource.create',
    {
      mainIconName: 'cog',
      secondaryIconName: 'add',
      descriptionTemplate: 'Permission resource "{{metadata.title}}" was created',
    },
  ],
  [
    'project.permissionResource.update',
    {
      mainIconName: 'cog',
      secondaryIconName: 'edit',
      descriptionTemplate: 'Permission resource "{{metadata.title}}" was updated',
    },
  ],
  [
    'project.permissionResource.delete',
    {
      mainIconName: 'cog',
      secondaryIconName: 'delete',
      descriptionTemplate: 'Permission resource "{{metadata.title}}" was deleted',
    },
  ],
  // Project roles
  [
    'project.role.create',
    {
      mainIconName: 'cog',
      secondaryIconName: 'add',
      descriptionTemplate: 'Role "{{metadata.title}}" was created',
    },
  ],
  [
    'project.role.update',
    {
      mainIconName: 'cog',
      secondaryIconName: 'edit',
      descriptionTemplate: 'Role "{{metadata.title}}" was updated',
    },
  ],
  [
    'project.role.delete',
    {
      mainIconName: 'cog',
      secondaryIconName: 'delete',
      descriptionTemplate: 'Role "{{metadata.title}}" was deleted',
    },
  ],
  // Project tags
  [
    'project.tag.create',
    {
      mainIconName: 'cog',
      secondaryIconName: 'add',
      descriptionTemplate: 'Tag "{{metadata.title}}" was created',
    },
  ],
  [
    'project.tag.delete',
    {
      mainIconName: 'cog',
      secondaryIconName: 'delete',
      descriptionTemplate: 'Tag "{{metadata.title}}" was deleted',
    },
  ],

  [
    'organization.members.delete',
    {
      // Event Trigger: user removes user
      showAvatar: true,
      secondaryIconName: 'delete',
      descriptionTemplate: '"{{userName}}" was removed from the organization',
    },
  ],
  [
    'organization.members.invitations.create',
    {
      // Event Trigger: user invites user(s)
      mainIconName: 'envelope',
      secondaryIconName: 'sent',
      descriptionTemplate:
        '"{{metadata.email}}" was invited to the organization with "{{metadata.role}}" access',
    },
  ],
  [
    'organization.members.create',
    {
      // Event Trigger: user joins organization
      showAvatar: true,
      secondaryIconName: 'add',
      descriptionTemplate: '"{{actorName}}" joined the organization as "{{metadata.role}}"',
    },
  ],
  [
    'organization.members.invitations.delete',
    {
      // Event Trigger: user revokes invitation
      mainIconName: 'envelope',
      secondaryIconName: 'delete',
      descriptionTemplate: 'Invitation for "{{metadata.email}}" to the organization was revoked',
    },
  ],
  // Project
  [
    'project.create',
    {
      showAvatar: true,
      secondaryIconName: 'add',
      descriptionTemplate: 'Project "{{metadata.displayName}}" was created',
    },
  ],
  [
    'project.organization.add',
    {
      showAvatar: true,
      secondaryIconName: 'add',
      descriptionTemplate: 'The project was assigned to the organization',
    },
  ],
  [
    'project.organization.delete',
    {
      showAvatar: true,
      secondaryIconName: 'delete',
      descriptionTemplate: 'The project was unassigned from the organization',
    },
  ],
  [
    'project.organization.delete',
    {
      showAvatar: true,
      secondaryIconName: 'sent',
      descriptionTemplate:
        'The ownership of the project was transferred to the organization "{{metadata.transferTo}}"',
    },
  ],
  [
    'project.organization.add',
    {
      showAvatar: true,
      secondaryIconName: 'sent',
      descriptionTemplate:
        'The ownership of the project was transferred from the organization "{{metadata.transferFrom}}"',
    },
  ],
  [
    'project.name.edit',
    {
      mainIconName: 'cog',
      secondaryIconName: 'edit',
      descriptionTemplate:
        'The project was renamed from "{{metadata.oldName}}" to "{{metadata.name}}"',
    },
  ],
  [
    'project.externalStudioHost.add',
    {
      mainIconName: 'cog',
      secondaryIconName: 'add',
      descriptionTemplate: 'The custom studio URL "{{metadata.externalStudioHost}}" was added',
    },
  ],
  [
    'project.externalStudioHost.delete',
    {
      mainIconName: 'cog',
      secondaryIconName: 'delete',
      descriptionTemplate: 'The custom studio URL "{{metadata.oldExternalStudioHost}}" was deleted',
    },
  ],
  [
    'project.externalStudioHost.edit',
    {
      mainIconName: 'cog',
      secondaryIconName: 'edit',
      descriptionTemplate:
        'The custom studio URL was changed to to "{{metadata.externalStudioHost}}"',
    },
  ],
  [
    'project.plan.create',
    {
      mainIconName: 'cog',
      secondaryIconName: 'add',
      descriptionTemplate: 'Plan "{{metadata.plan}}" initialized',
    },
  ],
  [
    'project.plan.edit',
    {
      mainIconName: 'cog',
      secondaryIconName: 'edit',
      descriptionTemplate:
        'The plan of the project was changed from "{{metadata.oldPlan}}" to "{{metadata.plan}}"',
    },
  ],
  [
    'project.plan.features.edit',
    {
      mainIconName: 'cog',
      secondaryIconName: 'edit',
      descriptionTemplate: (event: ActivityEvent) => {
        const addons: Record<string, string> = getAddOnsCopy().reduce(
          (acc, {featureId, name}) => ({...acc, [featureId]: name}),
          {}
        )

        const addedAddon = addons[event.metadata?.featuresAdded?.trim?.()]
        const removedAddon = addons[event.metadata?.featuresRemoved?.trim?.()]
        if (addedAddon) {
          // prioritize showing new features
          return `The add-on {{${addedAddon}}} was added to the project`
        } else if (removedAddon) {
          return `The add-on {{${removedAddon}}} was removed from the project`
        }
        return 'The add-ons of the project were changed'
      },
    },
  ],
  [
    'project.corsOrigin.create',
    {
      mainIconName: 'api',
      secondaryIconName: 'add',
      descriptionTemplate: 'CORS origin "{{metadata.origin}}" was added to the project',
    },
  ],
  [
    'project.corsOrigin.delete',
    {
      mainIconName: 'api',
      secondaryIconName: 'delete',
      descriptionTemplate: 'CORS origin "{{metadata.origin}}" was removed from the project',
    },
  ],
  [
    'project.webhook.create',
    {
      mainIconName: 'api',
      secondaryIconName: 'add',
      descriptionTemplate: 'Webhook "{{metadata.name}}" was added to the project',
    },
  ],
  [
    'project.webhook.delete',
    {
      mainIconName: 'api',
      secondaryIconName: 'delete',
      descriptionTemplate: 'Webhook "{{metadata.name}}" was removed from the project',
    },
  ],
  [
    'project.token.create',
    {
      mainIconName: 'api',
      secondaryIconName: 'add',
      descriptionTemplate: 'API token "{{metadata.label}}" was added to the project',
    },
  ],
  [
    'project.token.delete',
    {
      mainIconName: 'api',
      secondaryIconName: 'delete',
      descriptionTemplate: 'API token "{{metadata.label}}" was removed from the project',
    },
  ],
  // Project Activity
  [
    'project.activityFeed.enable',
    {
      mainIconName: 'cog',
      secondaryIconName: 'edit',
      descriptionTemplate: 'Project activity feed was enabled"',
    },
  ],
  [
    'project.activityFeed.disable',
    {
      mainIconName: 'cog',
      secondaryIconName: 'edit',
      descriptionTemplate: 'Project activity feed was disabled"',
    },
  ],
  [
    'project.members.invitations.delete',
    {
      mainIconName: 'envelope',
      secondaryIconName: 'delete',
      descriptionTemplate: 'Invitation for "{{metadata.email}}" to the project was revoked',
    },
  ],
  [
    'project.members.create',
    {
      showAvatar: true,
      secondaryIconName: 'add',
      descriptionTemplate: '"{{actorName}}" joined the project with "{{metadata.role}}" access',
    },
  ],
  [
    'project.members.role.edit',
    {
      showAvatar: true,
      secondaryIconName: 'edit',
      descriptionTemplate: (event: ActivityEvent) => {
        event.metadata.displayNameOld = event.metadata?.oldRoleName || event.metadata?.oldRole
        event.metadata.displayName = event.metadata?.roleName || event.metadata?.role

        return `The project role of "{{userName}}" changed from "{{metadata.displayNameOld}}" to "{{metadata.displayName}}"`
      },
    },
  ],
  [
    'project.members.delete',
    {
      showAvatar: true,
      secondaryIconName: 'delete',
      descriptionTemplate: '"{{userName}}" was removed from the project',
    },
  ],
  [
    'project.datasets.create',
    {
      mainIconName: 'database',
      secondaryIconName: 'add',
      descriptionTemplate: 'Dataset "{{datasetName}}" was created',
    },
  ],
  [
    'project.datasets.delete',
    {
      mainIconName: 'database',
      secondaryIconName: 'delete',
      descriptionTemplate: 'Dataset "{{datasetName}}" was deleted',
    },
  ],
  [
    'project.datasets.edit',
    {
      mainIconName: 'database',
      secondaryIconName: 'edit',
      descriptionTemplate: 'Dataset "{{datasetName}}" was edited',
    },
  ],
  [
    'project.datasets.copy',
    {
      descriptionTemplate: 'Dataset "{{datasetName}}" was copied from "{{metadata.sourceDataset}}"',
      mainIconName: 'database',
      secondaryIconName: 'copy',
    },
  ],
  // Dataset tags
  [
    'project.dataset.tag.create',
    {
      descriptionTemplate: 'Tag "{{metadata.tagName}}" was added to the dataset "{{datasetName}}"',
      mainIconName: 'database',
      secondaryIconName: 'add',
    },
  ],
  [
    'project.dataset.tag.delete',
    {
      descriptionTemplate:
        'Tag "{{metadata.tagName}}" was deleted from the dataset "{{datasetName}}"',
      mainIconName: 'database',
      secondaryIconName: 'delete',
    },
  ],
  [
    'project.archive',
    {
      showAvatar: true,
      secondaryIconName: 'archive',
      descriptionTemplate: 'The project was archived',
    },
  ],
  [
    'project.activate',
    {
      showAvatar: true,
      secondaryIconName: 'unarchive',
      descriptionTemplate: 'The project was reactivated',
    },
  ],
  [
    'project.members.group.create',
    {
      mainIconName: 'database',
      secondaryIconName: 'add',
      descriptionTemplate: '"{{userName}}" was added to the group GROUP_NAME',
    },
  ],
  [
    'project.members.group.delete',
    {
      mainIconName: 'database',
      secondaryIconName: 'add',
      descriptionTemplate: '"{{userName}}" was removed from to the group GROUP_NAME',
    },
  ],
  [
    'project.api.graphql.create',
    {
      mainIconName: 'api',
      secondaryIconName: 'add',
      descriptionTemplate: (event: ActivityEvent) => {
        if (event?.metadata?.hadBreakingChanges) {
          return 'GraphQL API for dataset "{{datasetName}}" was deployed with tag name "{{metadata.tag}}". API had breaking changes from previous version.'
        }
        return 'GraphQL API for dataset "{{datasetName}}" was deployed with tag name "{{metadata.tag}}".'
      },
    },
  ],
  [
    'project.api.graphql.delete',
    {
      mainIconName: 'api',
      secondaryIconName: 'delete',
      descriptionTemplate:
        'GraphQL API for dataset "{{datasetName}}" and tag name "{{metadata.tag}}" was deleted.',
    },
  ],
  // Studio
  [
    'document.create',
    {
      mainIconName: 'document',
      secondaryIconName: 'add',
      descriptionTemplate: 'The document "{{documentId}}" was created',
    },
  ],
  [
    'document.delete',
    {
      mainIconName: 'document',
      secondaryIconName: 'delete',
      descriptionTemplate: 'The document "{{documentId}}" was deleted',
    },
  ],
  [
    'document.publish',
    {
      mainIconName: 'document',
      secondaryIconName: 'publish',
      descriptionTemplate: 'The document "{{documentId}}" was published',
    },
  ],
  [
    'document.unpublish',
    {
      mainIconName: 'document',
      secondaryIconName: 'unpublish',
      descriptionTemplate: 'The document "{{documentId}}" was unpublished',
    },
  ],
  [
    'document.edit',
    {
      mainIconName: 'document',
      secondaryIconName: 'edit',
      descriptionTemplate: 'The document "{{documentId}}" was edited',
    },
  ],
  [
    'project.request-logs.bucket.created',
    {
      mainIconName: 'bill',
      secondaryIconName: 'add',
      descriptionTemplate:
        'The bucket {{metadata.bucketType}}/{{metadata.bucketURI}} was attached to the request logs of the project.',
    },
  ],
  [
    'project.request-logs.bucket.updated',
    {
      mainIconName: 'bill',
      secondaryIconName: 'edit',
      descriptionTemplate:
        'The bucket {{metadata.bucketType}}/{{metadata.bucketURI}} was modified.',
    },
  ],
  [
    'project.request-logs.bucket.deleted',
    {
      mainIconName: 'bill',
      secondaryIconName: 'delete',
      descriptionTemplate:
        'The bucket {{metadata.bucketType}}/{{metadata.bucketURI}} was removed from the project.',
    },
  ],
])

export const PROJECT_EVENTS: EventCategory[] = [
  {
    title: 'Project activity',
    prefix: 'project',
    hideTitle: true,
    actions: [
      {
        title: 'Members',
        value: 'members',
        valuePrefix: true,
        filters: [
          'invitations.create',
          'invitations.delete',
          'create',
          'role.edit',
          'delete',
          'group.create',
          'group.delete',
        ],
      },
      {
        title: 'Datasets',
        value: 'datasets',
        valuePrefix: true,
        filters: ['create', 'delete', 'edit', 'copy'],
      },
      {
        title: 'General settings',
        value: 'general-settings',
        filters: [
          'name.edit',
          'externalStudioHost.add',
          'externalStudioHost.edit',
          'request-logs.bucket.created',
          'request-logs.bucket.updated',
          'request-logs.bucket.deleted',
        ],
      },
      {
        title: 'API settings',
        value: 'api-settings',
        filters: [
          'corsOrigin.create',
          'corsOrigin.delete',
          'webhook.create',
          'webhook.delete',
          'token.create',
          'token.delete',
        ],
      },
      {
        title: 'Critical actions',
        value: 'critical-actions',
        hideParentPrefix: true,
        filters: [
          'organization.project.create',
          'organization.project.delete',
          'project.metadata.transferTo',
          'project.metadata.transferFrom',
          'project.plan.edit',
          'project.archive',
          'project.activate',
        ],
      },
      // {
      //   title: 'Documents',
      //   value: 'document',
      //   filters: ['create', 'delete', 'publish', 'unpublish', 'edit'],
      //   hideParentPrefix: true,
      //   valuePrefix: true,
      // },
      {
        title: 'GraphQL',
        value: 'api',
        filters: ['graphql.create', 'graphql.delete'],
        valuePrefix: true,
      },
    ],
  },
]

export const ORG_EVENTS: EventCategory[] = [
  {
    title: 'Organization activity',
    prefix: 'organization',
    actions: [
      {
        title: 'Members',
        value: 'members',
        valuePrefix: true,
        filters: ['role.edit', 'delete', 'invitations.create', 'invitations.delete'],
      },
      {
        title: 'Settings',
        value: 'settings',
        filters: [
          'create',
          'name.edit',
          'billing.address.edit',
          'billing.paymentMethod.create',
          'billing.paymentMethod.delete',
          'billing.paymentMethod.edit',
          'contact.euRepresentative.edit',
          'contact.dataProtectionOfficer.edit',
        ],
      },
    ],
  },
  {...PROJECT_EVENTS[0], hideTitle: false},
]

export const parentEventActions = ORG_EVENTS?.map((event) => ({
  // The parent name
  prefix: event.prefix,
  // All the actions included under this parent
  actions: event.actions.flatMap((action) => generateActionsArray(action, event.prefix)),
}))

export const childEventActions = ORG_EVENTS?.flatMap((event) =>
  event.actions.map((action) => ({
    // Which parent the child belongs to
    parent: event.prefix,
    // The prefix to use for the children
    prefix: action.value,
    // All the actions included under this child
    actions: generateActionsArray(action, event.prefix),
  }))
)

export const findCategoryActions = (
  scope: 'parent' | 'child',
  action: string[]
): {parent?: string; prefix: string; actions: string[]} | undefined => {
  if (!action) {
    return undefined
  }
  if (scope === 'parent') {
    return parentEventActions.find(
      (parentCat) => JSON.stringify(parentCat.actions) === JSON.stringify(action)
    )
  }
  if (scope === 'child') {
    return childEventActions.find(
      (childCat) => JSON.stringify(childCat.actions) === JSON.stringify(action)
    )
  }
  return undefined
}
