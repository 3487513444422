import React from 'react'
import {Box, Checkbox, Flex} from '@sanity/ui'
import {TableCell} from '.'

export const TableCheckbox = ({value, label}: {value: string; label: string}) => {
  return (
    <TableCell>
      <Box paddingRight={4} style={{height: '100%'}} as="span">
        <Flex align="center" style={{height: '100%'}}>
          <Checkbox
            aria-label={label}
            value={value}
            // onChange={onBulkSelect}
            // checked={selectedMembers.includes(member.id)}
          />
        </Flex>
      </Box>
    </TableCell>
  )
}
