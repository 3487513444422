import {stringify as querystringify, ParsedUrlQuery} from 'querystring'
import React, {useCallback, useMemo, useState} from 'react'
import {useRouter} from 'next/router'
import {debounce} from 'lodash'
import Head from 'next/head'

import {Button, Container, Flex, Card, Heading, Stack, useToast, Box} from '@sanity/ui'
import {CopyIcon, PlayIcon} from '@sanity/icons'
import {catchError} from 'rxjs/operators'
import {of} from 'rxjs'
import {DefaultLayout} from '@/ui/app'
import {
  Webhooks2Input,
  useConfirmDialog,
  ApplyWebhookDialog,
  TabHeader,
  Prompt,
} from '@/components/index'
import {DocumentWebhookFields} from '@/types/models'
import {useCurrentScopeContext} from '@/context/index'
import {addWebhook} from '@/data/documentWebhooks'
import {APIError} from '@/data/util/request'
import {sendTrackingEvent} from '@/utils/tracking'

function removeSensitiveHeaders(headers: Record<string, string> | null): Record<string, string> {
  const safeHeaders = Object.assign({}, headers)
  Object.keys(safeHeaders).forEach((key) => {
    if (['PROXY-AUTHORIZATION', 'AUTHORIZATION'].includes(key.toUpperCase())) {
      delete safeHeaders[key]
    }
  })

  return safeHeaders
}

export function fieldsToQuerystring(fields: DocumentWebhookFields): string {
  return querystringify({
    name: fields.name,
    description: fields.description || undefined,
    url: fields.url,
    on: fields.rule?.on,
    filter: fields.rule?.filter,
    projection: fields.rule?.projection,
    httpMethod: fields.httpMethod,
    apiVersion: fields.apiVersion,
    includeDrafts: fields.includeDrafts ? 'true' : undefined,
    headers: JSON.stringify(removeSensitiveHeaders(fields.headers)),
  })
}

// These two helpers are used because ParsedUrlQuery returns either string or array of strings.

function asString(input: string | string[]): string {
  if (Array.isArray(input)) {
    return input[0]
  }
  return input
}

function asArray(input: string | string[]): string[] {
  if (Array.isArray(input)) {
    return input
  }
  return [input]
}

function queryToFields(query: ParsedUrlQuery): DocumentWebhookFields {
  return {
    name: query.name ? asString(query.name) : '',
    description: query.description ? asString(query.description) : null,
    url: query.url ? asString(query.url) : '',
    dataset: '*',
    rule: {
      on: query.on ? (asArray(query.on) as Array<'create' | 'update' | 'delete'>) : [],
      filter: query.filter ? asString(query.filter) : undefined,
      projection: query.projection ? asString(query.projection) : undefined,
    },
    httpMethod: query.httpMethod ? asString(query.httpMethod) : 'POST',
    apiVersion: query.apiVersion ? asString(query.apiVersion) : 'v2021-06-01',
    includeDrafts: !!query.includeDrafts,
    secret: '',
    headers: JSON.parse(query.headers ? asString(query.headers) : '{}'),
  }
}

const EMPTY_ARRAY: never[] = []

export default function ShareWebhook() {
  const router = useRouter()
  const toast = useToast()

  // Triggering `router.replace` on every single change causes big latency due to re-rendering so we debounce it.

  const onChange = useMemo(() => {
    return debounce((fields: DocumentWebhookFields) => {
      router.replace({query: fieldsToQuerystring(fields)}, undefined, {scroll: false})
    }, 500)
  }, [router])

  // This value is only being used on the first render so we intentially have an empty dependency.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialValue = useMemo(() => queryToFields(router.query), [])

  const {Dialog, showDialog} = useConfirmDialog()
  const [data, setData] = useState<DocumentWebhookFields | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onSave = useCallback(
    ({projectId, orgId, dataset}: {projectId: string; orgId: string; dataset: string}) => {
      if (data) {
        const fullData: DocumentWebhookFields = {...data, dataset}
        setLoading(true)
        addWebhook(projectId, fullData)
          .pipe(
            catchError((err) => {
              setLoading(false)
              if (err instanceof APIError) {
                toast.push({
                  title: 'Failed to add webhook',
                  status: 'error',
                  description: err.message,
                })
                return of(undefined)
              }
              throw err
            })
          )
          .subscribe((res) => {
            setLoading(false)
            if (res) {
              const basePath = orgId === 'personal' ? '/manage/personal' : `/organizations/${orgId}`
              router.push(`${basePath}/project/${projectId}/api`)
            }
          })
        sendTrackingEvent('AppliedSharedWebhook', projectId)
      }
    },
    [data, router, toast]
  )

  const openDialog = useCallback(
    (fields: DocumentWebhookFields) => {
      setData(fields)
      showDialog()
    },
    [showDialog]
  )

  const {orgs} = useCurrentScopeContext()!

  const onShare = useCallback(() => {
    if (!window.navigator.clipboard) {
      toast.push({
        status: 'warning',
        title: 'Unable to share link',
        description:
          'Your browser does not support link sharing. Manually copy the URL/link to this page to share this webhook.',
      })
      return
    }

    const url = window.location.toString()

    window.navigator.clipboard.writeText(url).then(() => {
      toast.push({
        status: 'success',
        title: 'Link copied!',
        description: 'A sharable link has been copied to your clipboard.',
      })
    })
  }, [toast])

  const title = initialValue.name && `${initialValue.name} - a Sanity GROQ-Powered Webhook`
  const desc =
    initialValue.description &&
    `Connect your Sanity project to this Webhook recipe powered by GROQ. Description: ${initialValue.description}`

  return (
    <DefaultLayout disableNav>
      <Head>
        {title && (
          <>
            <meta name="title" content={title} />
            <meta property="og:title" content={title} />
            <meta property="twitter:title" content={title} />
          </>
        )}

        {desc && (
          <>
            <meta name="description" content={desc} />
            <meta property="og:description" content={desc} />
            <meta property="twitter:description" content={desc} />
          </>
        )}

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://metatags.io/" />

        <meta property="twitter:url" content="https://metatags.io/" />
      </Head>

      <Container width={2} paddingLeft={3} paddingRight={3}>
        <ApplyWebhookDialog
          onSubmit={onSave}
          organizations={orgs}
          Dialog={Dialog}
          loading={loading}
        />

        <TabHeader
          title="Share webhook"
          description="This page can be used to share a webhook with other projects and organizations."
          referenceLink={{
            label: 'Learn more about webhooks in Sanity Docs',
            url: `https://www.${process.env.host}/docs/webhooks`,
          }}
        />

        <Box marginY={5}>
          <Prompt
            tone="caution"
            icon="info-outline"
            description="All the details listed below will be shared with anyone who gets the sharable link. Remember to remove any sensitive data before sharing!"
          />
        </Box>

        <Webhooks2Input
          initialValue={initialValue}
          datasets={EMPTY_ARRAY}
          existingWebhookNames={EMPTY_ARRAY}
          onSave={openDialog}
          onChange={onChange}
          shareMode
          noPrimaryButton
          before={<SubmitButtons onShare={onShare} />}
          after={<SubmitButtons onShare={onShare} />}
        />
      </Container>
    </DefaultLayout>
  )
}

function SubmitButtons({onShare}: {onShare: () => void}) {
  return (
    <Flex marginTop={4} marginBottom={4}>
      <Card flex={1} radius={2} shadow={1} padding={4} marginRight={4}>
        <Stack space={3}>
          <Heading size={1}>Share</Heading>
          <div>
            <Button mode="ghost" icon={CopyIcon} text="Copy sharable link" onClick={onShare} />
          </div>
        </Stack>
      </Card>
      <Card flex={1} radius={2} shadow={1} padding={4}>
        <Stack space={3}>
          <Heading size={1}>Apply to project</Heading>
          <div>
            <Button type="submit" tone="primary" icon={PlayIcon} text="Apply webhook" />
          </div>
        </Stack>
      </Card>
    </Flex>
  )
}
