import {promiseRequest} from '@/data/util/promiseRequest'
import {Project} from '@/types/index'

const API_VERSION = 'v2024-08-25'

export type SanitySchema = Array<{
  name: string
  type: string
  fields: Array<{
    name: string
    type: string
  }>
}>

export type JourneyProject = {
  projectId: string
  dataset?: string
  displayName?: string
  schema?: SanitySchema
  schemaUrl?: string
}

export type LessonProgress = {
  progress: string[]
}

export interface UserOnboardingData {
  role?: string | null
  technologies?: string[]
  isMigratingCms?: boolean
  hostname?: string | null
  userId?: string | null
  onboardingComplete?: string | null
  javascriptLevel?: 'not_developer' | 'beginner' | 'intermediate' | 'fluent' | null
  team?: string | null
  projectName?: string | null
  project?: Project | null
  projectType?: string | null
  currentCmses?: string[] | null
  schema?: string | null
  onboardingVersion?: number | null
  useCase?: string | null
  companySize?: string | null
}

export interface ProjectOnboardingData {
  variant: string
  disabled: boolean
  framework: string
  templateSlug?: string
}

export function getJourneyProject(projectId: string) {
  return promiseRequest<JourneyProject>(
    `https://api.${process.env.host}/${API_VERSION}/journey/projects/${projectId}`
  )
}

export function getProjectLessonProgress(projectId: string) {
  return promiseRequest<LessonProgress>(
    `https://api.${process.env.host}/${API_VERSION}/journey/learn/projects/${projectId}`
  )
}

export function updateProjectLessonProgress(projectId: string, lesson: string) {
  return promiseRequest<LessonProgress>(
    `https://api.${process.env.host}/${API_VERSION}/journey/learn/projects/${projectId}`,
    {
      method: 'PUT',
      json: {lesson},
    }
  )
}

export function replaceProjectLessonProgress(projectId: string, lessons: string[]) {
  return promiseRequest<LessonProgress>(
    `https://api.${process.env.host}/${API_VERSION}/journey/learn/projects/${projectId}`,
    {
      method: 'POST',
      json: {lessons},
    }
  )
}

export function deleteProjectLessonProgress(projectId: string, lesson: string) {
  return promiseRequest<LessonProgress>(
    `https://api.${process.env.host}/${API_VERSION}/journey/learn/projects/${projectId}`,
    {
      method: 'DELETE',
      json: {lesson},
    }
  )
}

export function getUserOnboardingData() {
  return promiseRequest<UserOnboardingData>(
    `https://api.${process.env.host}/${API_VERSION}/journey/onboarding`
  )
}

export function getProjectOnboardingData(projectId: string) {
  return promiseRequest<ProjectOnboardingData>(
    `https://api.${process.env.host}/${API_VERSION}/journey/onboarding/project/${projectId}`
  )
}

export function updateProjectOnboardingData(
  projectId: string,
  onboardingData: Omit<Partial<ProjectOnboardingData>, 'variant'>
) {
  return promiseRequest<ProjectOnboardingData>(
    `https://api.${process.env.host}/${API_VERSION}/journey/onboarding/project/${projectId}`,
    {
      method: 'POST',
      json: onboardingData,
    }
  )
}
