import React, {useMemo, useState, useCallback, useEffect} from 'react'
import {EditIcon, DatabaseIcon, UsersIcon} from '@sanity/icons'
import {Stack, Box, Text, Card, Flex, Inline, Tooltip, Button, useToast, Grid} from '@sanity/ui'
import {cloneDeep} from 'lodash'
import Link from 'next/link'
import ExternalPermissionResources from './externalPermissionResources'
import {
  addGrantsToResources,
  contentResourceCompare,
  getDocumentFilterAsModePermissions,
  isUserResource,
  isUserRoleResource,
  removeGrantsToResources,
} from './util'
import {ViewContentResource} from './viewContentResource'
import {EditContentResource} from './editContentResource'
import {PermissionButtonProject, TagIcon} from '@/ui/index'
import {
  Dataset,
  PermissionResource,
  PermissionUpdate,
  ProjectMemberRole,
  RoleResource,
  Tag,
} from '@/types/models'
import {grantProjectRolePermissions, revokeProjectRolePermissions} from '@/data/projects'
import {useRoutePath} from '@/context/index'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {getProjectRolesKey} from '@/data/projects/cache'

function Header({
  context,
  role,
  tag,
  dataset,
  readOnly,
  edit,
  loading,
  onEdit,
  onCancel,
}: {
  context: 'role' | 'auto'
  role: ProjectMemberRole
  dataset: Dataset | undefined
  tag: Tag | undefined
  readOnly?: boolean
  edit?: boolean
  loading?: boolean
  onEdit: () => void
  onCancel: () => void
}) {
  const {basePath} = useRoutePath()
  return (
    <Flex justify="space-between" paddingX={4}>
      <Inline space={2}>
        <Text weight="semibold">Permissions in</Text>
        <Box>
          {context === 'role' && (
            <Tooltip
              content={
                <Box padding={2}>
                  <Text muted size={1}>
                    <em>{role.description || role.name}</em>
                  </Text>
                </Box>
              }
            >
              <Link href={`${basePath}/access/roles?role=${role.name}`} passHref legacyBehavior>
                <Card padding={2} radius={2} tone={'transparent'} as="a">
                  <Flex>
                    <Box marginRight={2}>
                      <Text size={1}>
                        <UsersIcon />
                      </Text>
                    </Box>
                    <Text size={1} weight="medium">
                      {role.title}
                    </Text>
                  </Flex>
                </Card>
              </Link>
            </Tooltip>
          )}
          {context === 'auto' && tag && (
            <Tooltip
              content={
                <Box padding={2}>
                  <Text muted size={1}>
                    <em>{tag.description || tag.title}</em>
                  </Text>
                </Box>
              }
            >
              <Link href={`${basePath}/datasets/tags?name=${tag.name}`} passHref legacyBehavior>
                <Card padding={2} radius={2} tone={tag.metadata?.tone || 'transparent'} as="a">
                  <Flex>
                    <Box marginRight={2}>
                      <Text size={1}>
                        <TagIcon />
                      </Text>
                    </Box>
                    <Text size={1} weight="medium">
                      {tag.title}
                    </Text>
                  </Flex>
                </Card>
              </Link>
            </Tooltip>
          )}
          {context === 'auto' && dataset && (
            <Link href={`${basePath}/datasets?name=${dataset.name}`} passHref legacyBehavior>
              <Card padding={2} radius={2} tone="transparent" as="a">
                <Flex>
                  <Box marginRight={2}>
                    <Text size={1}>
                      <DatabaseIcon />
                    </Text>
                  </Box>
                  <Text size={1} weight="medium">
                    {dataset.name}
                  </Text>
                </Flex>
              </Card>
            </Link>
          )}
          {context === 'auto' && !tag && !dataset && (
            <Link href={`${basePath}/datasets`} passHref legacyBehavior>
              <Card padding={2} radius={2} tone="critical" as="a">
                <Text size={1} weight="medium">
                  All datasets
                </Text>
              </Card>
            </Link>
          )}
        </Box>
      </Inline>
      {!readOnly &&
        (edit ? (
          <Button text="Cancel" mode="ghost" onClick={onCancel} disabled={loading} />
        ) : (
          <PermissionButtonProject
            onClick={onEdit}
            title="Edit"
            icon={EditIcon}
            permissions={[{permissionName: 'sanity.project.roles', grantName: 'update'}]}
          />
        ))}
    </Flex>
  )
}

function Footer({
  edit,
  loading,
  dirty,
  onCancel,
  onSave,
}: {
  edit: boolean
  loading: boolean
  dirty: boolean
  onCancel: () => void
  onSave: () => void
}) {
  if (!edit) return <></>
  return (
    <Card tone="default" paddingX={4} paddingY={3} borderTop>
      <Grid columns={2} gap={2} paddingX={4}>
        <Button mode="bleed" onClick={onCancel} text="Cancel" disabled={loading} />
        <Button text="Save changes" tone="primary" onClick={onSave} disabled={loading || !dirty} />
      </Grid>
    </Card>
  )
}

type ResourceOption = {permissionResource: PermissionResource; option: string}

function getGrantedPermissions(
  updates: ResourceOption[],
  tag: Tag | undefined,
  dataset: Dataset | undefined
) {
  return updates.reduce((acc, update) => {
    if (update.option === 'no access') return acc
    if (update.permissionResource.permissionResourceType === 'sanity.document.filter.mode') {
      acc.push({
        permissionResourceId: update.permissionResource.id,
        permissionName: 'mode',
        params: {
          mode: update.option,
          tagName: tag?.name,
          dataset: dataset?.name,
        },
      })
    } else if (
      update.permissionResource.name === 'sanity-document-filter-images' ||
      update.permissionResource.name === 'sanity-document-filter-files'
    ) {
      const permissions = getDocumentFilterAsModePermissions(update.option)
      for (const perm of permissions) {
        acc.push({
          permissionResourceId: update.permissionResource.id,
          permissionName: perm,
          params: {
            tagName: tag?.name,
            dataset: dataset?.name,
          },
        })
      }
    }
    return acc
  }, [] as PermissionUpdate[])
}

function getRevokedPermissions(
  updates: ResourceOption[],
  resources: RoleResource[],
  tag: Tag | undefined,
  dataset: Dataset | undefined
) {
  return updates.reduce((acc, update) => {
    const resource = resources.find((res) => res.id === update.permissionResource.id)
    if (!resource) return acc

    if (
      update.permissionResource.permissionResourceType === 'sanity.document.filter.mode' &&
      update.option === 'no access'
    ) {
      const grant = resource.grants?.find(
        (resourceGrant) =>
          resourceGrant.name === 'mode' &&
          resourceGrant.params?.dataset === dataset?.name &&
          resourceGrant.params?.tagName === tag?.name
      )
      if (grant) {
        acc.push({
          permissionResourceId: update.permissionResource.id,
          permissionName: 'mode',
          params: {
            mode: grant.params.mode,
            history: grant.params.history,
            tagName: tag?.name,
            dataset: dataset?.name,
          },
        })
      }
    } else if (
      update.permissionResource.name === 'sanity-document-filter-images' ||
      update.permissionResource.name === 'sanity-document-filter-files'
    ) {
      const permissions = getDocumentFilterAsModePermissions(update.option, true)
      for (const perm of permissions) {
        const grant = resource.grants?.find(
          (resourceGrant) =>
            resourceGrant.name === perm &&
            resourceGrant.params?.dataset === dataset?.name &&
            resourceGrant.params?.tagName === tag?.name
        )
        acc.push({
          permissionResourceId: update.permissionResource.id,
          permissionName: perm,
          params: {
            mode: grant?.params.mode,
            history: grant?.params.history,
            tagName: tag?.name,
            dataset: dataset?.name,
          },
        })
      }
    }
    return acc
  }, [] as PermissionUpdate[])
}

type Props = {
  tag: Tag | undefined
  dataset: Dataset | undefined
  resources: RoleResource[]
  permissionResources?: PermissionResource[]
  projectId: string
  role: ProjectMemberRole
  readOnly: boolean
  context?: 'auto' | 'role'
}
export function ContentResource({
  tag,
  dataset,
  resources,
  permissionResources,
  projectId,
  role,
  readOnly,
  context = 'auto',
}: Props) {
  const toast = useToast()
  const queryClient = useQueryClient()
  const [edit, setEdit] = useState(false)
  const [dirty, setDirty] = useState(false)
  const [toUpdate, setToUpdate] = useState<Record<string, ResourceOption>>({})
  const [currentResources, setCurrentResources] = useState<RoleResource[]>([])

  const currentContextResources = useMemo(() => {
    return currentResources.sort(contentResourceCompare)
  }, [currentResources])

  const projectResources = useMemo(() => {
    return (
      permissionResources
        ?.filter((resource) => isUserResource(resource))
        ?.sort(contentResourceCompare) || []
    )
  }, [permissionResources])

  useEffect(() => {
    const contextResources = cloneDeep(resources)
      .filter(
        (resource) =>
          isUserRoleResource(resource) &&
          resource.grants.some(
            (g) => g.params.tagName === tag?.name && g.params.dataset === dataset?.name
          )
      )
      .map((resource) => {
        return {
          ...resource,
          grants: resource.grants.filter(
            (g) => g.params.tagName === tag?.name && g.params.dataset === dataset?.name
          ),
        }
      })
    setCurrentResources(contextResources)
  }, [resources, dataset?.name, tag?.name])

  const updateCurrentResources = useCallback(
    (toGrant: PermissionUpdate[], toRevoke: PermissionUpdate[]) => {
      let updatedResources = addGrantsToResources(
        toGrant,
        currentResources,
        permissionResources || []
      )
      updatedResources = removeGrantsToResources(toRevoke, updatedResources)
      setCurrentResources(updatedResources)
    },
    [currentResources, permissionResources]
  )

  const grantMutation = useMutation({
    mutationFn: (addedGrants: PermissionUpdate[]) =>
      grantProjectRolePermissions(projectId, role.name, addedGrants),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: getProjectRolesKey(projectId)})
      toast.push({title: 'Permissions granted successfully', status: 'success'})
    },
    onError: (err: Error) => {
      toast.push({title: 'Error granting permissions', description: err.message, status: 'error'})
    },
  })

  const revokeMutation = useMutation({
    mutationFn: (removedGrants: PermissionUpdate[]) =>
      revokeProjectRolePermissions(projectId, role.name, removedGrants),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: getProjectRolesKey(projectId)})
      toast.push({title: 'Permissions revoked successfully', status: 'success'})
    },
    onError: (err: Error) => {
      toast.push({title: 'Error revoking permissions', description: err.message, status: 'error'})
    },
  })

  const handleOnEdit = useCallback(() => {
    setEdit(true)
  }, [])

  const handleOnCancel = useCallback(() => {
    setEdit(false)
    setDirty(false)
    setToUpdate({})
  }, [])

  const handleOnSave = useCallback(() => {
    const updates = Object.values(toUpdate)
    if (grantMutation.isPending || revokeMutation.isPending || updates.length === 0) return

    const toGrant = getGrantedPermissions(updates, tag, dataset)
    const toRevoke = getRevokedPermissions(updates, currentResources, tag, dataset)

    updateCurrentResources(toGrant, toRevoke)

    if (toGrant.length > 0) {
      grantMutation.mutate(toGrant, {
        onSuccess: () => {
          if (toRevoke.length > 0) {
            revokeMutation.mutate(toRevoke, {
              onSuccess: () => handleOnCancel(),
              onError: (err: Error) => {
                toast.push({
                  title: 'Error revoking permissions',
                  description: err.message,
                  status: 'error',
                })
              },
            })
          } else {
            handleOnCancel()
          }
        },
        onError: (err: Error) => {
          toast.push({
            title: 'Error granting permissions',
            description: err.message,
            status: 'error',
          })
        },
      })
    } else if (toRevoke.length > 0) {
      revokeMutation.mutate(toRevoke, {
        onSuccess: () => handleOnCancel(),
        onError: (err: Error) => {
          toast.push({
            title: 'Error revoking permissions',
            description: err.message,
            status: 'error',
          })
        },
      })
    } else {
      handleOnCancel()
      toast.push({title: 'No changes to update', status: 'info'})
    }
  }, [
    toUpdate,
    grantMutation,
    revokeMutation,
    dataset,
    tag,
    handleOnCancel,
    currentResources,
    updateCurrentResources,
    toast,
  ])

  const setPending = useCallback(
    (permissionResource: PermissionResource, option: string) => {
      setDirty(true)
      setToUpdate({
        ...toUpdate,
        [permissionResource.id]: {
          permissionResource,
          option,
        },
      })
    },
    [toUpdate]
  )

  return (
    <Card radius={2} shadow={edit ? 2 : 1} overflow="hidden">
      <Flex align="center">
        <Stack flex={1} paddingTop={4} space={4}>
          <Header
            context={context}
            role={role}
            tag={tag}
            dataset={dataset}
            readOnly={readOnly}
            edit={edit}
            loading={grantMutation.isPending || revokeMutation.isPending}
            onEdit={handleOnEdit}
            onCancel={handleOnCancel}
          />
          {!edit && <ViewContentResource resources={currentContextResources} />}
          {edit && (
            <EditContentResource
              resources={currentContextResources}
              permissionResources={projectResources}
              setPending={setPending}
            />
          )}
        </Stack>
      </Flex>
      <Footer
        edit={edit}
        loading={grantMutation.isPending || revokeMutation.isPending}
        dirty={dirty}
        onCancel={handleOnCancel}
        onSave={handleOnSave}
      />
      <ExternalPermissionResources resources={resources} tag={tag} dataset={dataset} />
    </Card>
  )
}
