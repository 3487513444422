import {useEffect, useState} from 'react'
import {
  addMonths,
  format,
  differenceInSeconds,
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
  differenceInWeeks,
  differenceInMonths,
  differenceInYears,
  subMonths,
  startOfQuarter,
  endOfQuarter,
  startOfMonth,
  startOfYear,
  addDays,
} from 'date-fns'

interface TimeSpec {
  timestamp: string
  refreshInterval: number
}

const FIVE_SECONDS = 1000 * 5
const TWENTY_SECONDS = 1000 * 20
const ONE_MINUTE = 1000 * 60
const ONE_HOUR = ONE_MINUTE * 60

export interface TimeAgoOpts {
  minimal?: boolean
  agoSuffix?: boolean
}

const getPluralForm = (word: string, count: number) => {
  return count === 1 ? word : `${word}s`
}

const formats: any = {
  second: {
    minimal: 's',
    default: 'second',
  },
  minute: {
    minimal: 'm',
    default: 'minute',
  },
  hour: {
    minimal: 'h',
    default: 'hour',
  },
  day: {
    minimal: 'd',
    default: 'day',
  },
  week: {
    minimal: 'w',
    default: 'week',
  },
  month: {
    minimal: 'mo',
    default: 'month',
  },
}

const formatDiff = (count: number, timeType: string, minimal?: boolean, agoSuffix?: boolean) => {
  const timeFormat = minimal
    ? formats[timeType || 'second'].minimal
    : ` ${getPluralForm(formats[timeType || 'second'].default, count)}`
  return `${count}${timeFormat}${agoSuffix ? ' ago' : ''}`
}

export function formatRelativeTime(
  date: Date | string | undefined,
  opts: TimeAgoOpts = {}
): TimeSpec {
  if (!date) {
    return {timestamp: 'n/a', refreshInterval: FIVE_SECONDS}
  }
  const now = Date.now()
  const parsedDate = date instanceof Date ? date : new Date(date)

  const diffMonths = differenceInMonths(now, parsedDate)
  const diffYears = differenceInYears(now, parsedDate)

  if (diffMonths >= 3 || diffYears) {
    return {
      timestamp: format(parsedDate, 'dd MMM yyyy'),
      refreshInterval: +Infinity,
    }
  }

  if (diffMonths < 3 && diffMonths > 0) {
    return {
      timestamp: formatDiff(diffMonths, 'month', opts.minimal, opts.agoSuffix),
      refreshInterval: +Infinity,
    }
  }

  const diffWeeks = differenceInWeeks(now, parsedDate)
  if (diffWeeks) {
    return {
      timestamp: formatDiff(diffWeeks, 'week', opts.minimal, opts.agoSuffix),
      refreshInterval: ONE_HOUR,
    }
  }

  const diffDays = differenceInDays(now, parsedDate)
  if (diffDays) {
    return {
      timestamp:
        diffDays === 1 ? 'yesterday' : formatDiff(diffDays, 'day', opts.minimal, opts.agoSuffix),
      refreshInterval: ONE_HOUR,
    }
  }

  const diffHours = differenceInHours(now, parsedDate)
  if (diffHours) {
    return {
      timestamp: formatDiff(diffHours, 'hour', opts.minimal, opts.agoSuffix),
      refreshInterval: ONE_MINUTE,
    }
  }

  const diffMins = differenceInMinutes(now, parsedDate)
  if (diffMins) {
    return {
      timestamp: formatDiff(diffMins, 'minute', opts.minimal, opts.agoSuffix),
      refreshInterval: TWENTY_SECONDS,
    }
  }

  const diffSeconds = differenceInSeconds(now, parsedDate)
  if (diffSeconds > 10) {
    return {
      timestamp: formatDiff(diffDays, 'second', opts.minimal, opts.agoSuffix),
      refreshInterval: FIVE_SECONDS,
    }
  }

  return {timestamp: 'just now', refreshInterval: FIVE_SECONDS}
}

export const formatDate = (date: Date | undefined) =>
  date ? format(date, 'yyyy-MM-dd') : undefined

export const useMonthName = (monthFormat = 'MMMM') => {
  const currentMonthName = format(new Date(), monthFormat)
  const previousMonthName = format(subMonths(new Date(), 1), monthFormat)
  return {currentMonthName, previousMonthName}
}

export const getFromDate = (timePeriod: 'monthly' | 'quarterly' | 'yearly') => {
  const today = new Date()
  const quarterStart = startOfQuarter(today)
  const quarterEnd = endOfQuarter(today)
  const monthStart = startOfMonth(today)
  const yearStart = startOfYear(today)
  const date = () => {
    switch (timePeriod) {
      case 'monthly':
        return {
          value: monthStart,
          label: format(monthStart, 'MMMM yyyy'),
        }

      case 'quarterly':
        return {
          value: quarterStart,
          label: `${format(quarterStart, 'MMM')}-${format(quarterEnd, 'MMM')} ${format(
            quarterStart,
            'yyyy'
          )}`,
        }

      case 'yearly':
        return {
          value: yearStart,
          label: format(yearStart, 'yyyy'),
        }

      default:
        return {
          value: monthStart,
          label: format(monthStart, 'MMMM yyyy'),
        }
    }
  }
  return date()
}

export function useTimeAgo(time: Date | string, {minimal, agoSuffix}: TimeAgoOpts = {}): string {
  const [resolved, setResolved] = useState(() => formatRelativeTime(time, {minimal, agoSuffix}))

  useEffect(() => {
    setResolved(formatRelativeTime(time, {minimal, agoSuffix}))
  }, [time, minimal, agoSuffix])

  useEffect(() => {
    const id: number | undefined = Number.isFinite(resolved.refreshInterval)
      ? window.setInterval(
          () => setResolved(formatRelativeTime(time, {minimal, agoSuffix})),
          resolved.refreshInterval
        )
      : undefined

    return () => clearInterval(id)
  }, [time, minimal, resolved.refreshInterval, agoSuffix])

  return resolved.timestamp
}

export function createDateRange(startDate: Date, endDate: Date, interval: string): Date[] {
  if (interval === 'month') {
    const months = differenceInMonths(endDate, startDate)

    return [...Array(months + 1).keys()].map((i) => addMonths(startDate, i))
  }

  const days = differenceInDays(endDate, startDate)

  return [...Array(days + 1).keys()].map((i) => addDays(startDate, i))
}

export function timeToMilliseconds(
  time: number,
  unit: 'seconds' | 'minutes' | 'hours' | 'days' | 'weeks' | 'months' | 'years'
) {
  switch (unit) {
    case 'seconds':
      return time * 1000
    case 'minutes':
      return time * 1000 * 60
    case 'hours':
      return time * 1000 * 60 * 60
    case 'days':
      return time * 1000 * 60 * 60 * 24
    case 'weeks':
      return time * 1000 * 60 * 60 * 24 * 7
    case 'months':
      return time * 1000 * 60 * 60 * 24 * 30
    case 'years':
      return time * 1000 * 60 * 60 * 24 * 365
    default:
      return time
  }
}

export function minutesToMilliseconds(minutes: number) {
  return timeToMilliseconds(minutes, 'minutes')
}

export function hoursToMilliseconds(hours: number) {
  return timeToMilliseconds(hours, 'hours')
}

export function daysToMilliseconds(days: number) {
  return timeToMilliseconds(days, 'days')
}

export function weeksToMilliseconds(weeks: number) {
  return timeToMilliseconds(weeks, 'weeks')
}

export function monthsToMilliseconds(months: number) {
  return timeToMilliseconds(months, 'months')
}
