import {InviteOrgMembers} from './members'
import {SetPermissions} from './members/invite/setPermissions'
import {InviteConfig} from '@/types/index'

export const inviteConfig: InviteConfig = {
  disableNav: true,
  title: 'Add organization members',
  parentTab: {
    title: 'Organization members',
    path: 'members',
  },
  path: 'invite',
  disableTabButtonRender: true,
  component: (props: any) => InviteOrgMembers(props),
  headings: [
    {
      title: 'Add members',
      description:
        'You can invite multiple organization members in one go, and assign them individual organization roles. In the next step you assign project level permissions.',
      isRoot: true,
    },
    {
      title: 'Set permissions',
      path: 'permissions',
      component: SetPermissions,
    },
  ],
}
