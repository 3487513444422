import {Button as UIButton, type ButtonProps as UIButtonProps, ConditionalWrapper} from '@sanity/ui'
import {type ForwardedRef, forwardRef, type HTMLProps, type ReactNode, useCallback} from 'react'
import {styled} from 'styled-components'

import {Tooltip, type TooltipProps} from '../tooltip'

export type BaseButtonProps = Pick<
  UIButtonProps,
  | 'as'
  | 'icon'
  | 'iconRight'
  | 'justify'
  | 'loading'
  | 'mode'
  | 'paddingY'
  | 'selected'
  | 'tone'
  | 'type'
  | 'width'
  | 'fontSize'
  | 'padding'
> &
  Omit<HTMLProps<HTMLButtonElement>, 'as' | 'size' | 'title'>

type ButtonWithText = {
  text: string
  tooltipProps?: TooltipProps | null
  icon?: UIButtonProps['icon']
}

type IconButton = {
  text?: undefined
  icon?: UIButtonProps['icon']
  /**
   * When using a button with an icon, tooltipProps are required to enforce consistency in UI.
   */
  tooltipProps: TooltipProps | null
}

export type ButtonProps = BaseButtonProps & (ButtonWithText | IconButton)

const TooltipButtonWrapper = styled.span`
  display: inline-flex;
`

export const Button = forwardRef(function Button(
  props: ButtonProps,
  ref: ForwardedRef<HTMLButtonElement>
) {
  const {mode = 'default', tone = 'default', tooltipProps, ...rest} = props

  const renderWrapper = useCallback(
    (wrapperChildren: ReactNode) => {
      return (
        <Tooltip {...tooltipProps} content={tooltipProps?.content}>
          {/* This span is needed to make the tooltip work in disabled buttons */}
          <TooltipButtonWrapper>{wrapperChildren}</TooltipButtonWrapper>
        </Tooltip>
      )
    },
    [tooltipProps]
  )

  return (
    <ConditionalWrapper condition={!!tooltipProps} wrapper={renderWrapper}>
      <UIButton {...rest} ref={ref} mode={mode} tone={tone} />
    </ConditionalWrapper>
  )
})
