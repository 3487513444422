import {Stack, Text, TextInput, useToast} from '@sanity/ui'
import React, {useCallback, useEffect, useMemo} from 'react'
import {useForm} from 'react-hook-form'
import {useRouter} from 'next/router'
import {FormField, RadioInput} from '../../form'
import {Li, Ul} from '@/ui/index'
import {Dataset, DatasetAclMode, DialogToast, Project} from '@/types/index'
import {
  useEditDataset,
  datasetNamePattern,
  MAX_DATASET_NAME_LENGTH,
  PRIVATE_DATASET_FEATURE,
} from '@/data/index'
import {DatasetVisibilityWarningPrompt} from '@/ui/views/wizards/newDataset'
import {useProjectHasFeature} from '@/context/index'

type Props = {
  mode: 'edit' | 'create'
  project: Project
  datasets?: Dataset[]
  hideDialog: (dialogToast?: DialogToast) => void
  Dialog: React.ElementType
}

interface FormValues {
  name: string
  aclMode: DatasetAclMode
}

export const EditDatasetDialog = ({
  mode = 'edit',
  project,
  datasets,
  hideDialog,
  Dialog,
}: Props) => {
  const isEditMode = mode === 'edit'
  const {isPending: editLoading, mutate: updateDataset} = useEditDataset(
    project.id,
    datasets?.[0]?.name || ''
  )

  const router = useRouter()
  const toast = useToast()
  const hasPrivateDatasetsFeature = useProjectHasFeature(project, PRIVATE_DATASET_FEATURE)

  const {
    handleSubmit,
    register,
    formState: {errors},
    reset,
  } = useForm<FormValues>({
    defaultValues: {
      name: datasets?.[0]?.name || '',
      aclMode: datasets?.[0]?.aclMode || 'public',
    },
  })

  useEffect(() => {
    return () => reset()
  }, [reset])

  const onSubmit = useCallback(
    (values: FormValues) => {
      updateDataset(
        {aclMode: values.aclMode},
        {
          onSuccess: () => {
            hideDialog({
              title: 'Updated dataset',
              status: 'success',
            })
          },
          onError: (error) => {
            toast.push({
              title: 'Failed to update dataset',
              description: error?.message,
              status: 'error',
            })
          },
        }
      )
    },
    [updateDataset, hideDialog, toast]
  )

  const confirmationButtonProps = useMemo(
    () => ({
      text: 'Update dataset',
      tone: 'primary',
      type: 'submit',
      form: 'create-dataset-form',
    }),
    []
  )

  return (
    <Dialog
      header="Edit dataset"
      confirmButtonProps={confirmationButtonProps}
      loading={editLoading}
    >
      <form onSubmit={handleSubmit(onSubmit)} id="create-dataset-form">
        <Stack space={4}>
          <FormField
            label="Dataset name"
            description={
              <Stack space={1} marginTop={1}>
                <Text size={1} muted>
                  The name needs to be:
                </Text>
                <Ul marginY={2}>
                  <Li>Lowercase</Li>
                  <Li>Between 1-{`${MAX_DATASET_NAME_LENGTH}`} characters</Li>
                  <Li>Start with an alphanumeric character (a-z, 0-9)</Li>
                  <Li>Contain only alphanumeric characters, _ or -</Li>
                </Ul>
              </Stack>
            }
            error={errors.name ? 'The format of the dataset name is invalid' : ''}
          >
            <TextInput
              disabled={isEditMode}
              padding={3}
              maxLength={MAX_DATASET_NAME_LENGTH}
              {...register('name', {required: true, pattern: datasetNamePattern})}
            />
          </FormField>
          <FormField label="Visibility" description="Set visibility of a dataset">
            <RadioInput
              label="Public (accessible to everyone)"
              value="public"
              {...register('aclMode')}
            />
            <RadioInput
              label="Private (accessible with a token or by authenticated users)"
              value="private"
              disabled={!hasPrivateDatasetsFeature}
              {...register('aclMode')}
            />
          </FormField>
          {!hasPrivateDatasetsFeature && (
            <DatasetVisibilityWarningPrompt router={router} project={project} />
          )}
        </Stack>
      </form>
    </Dialog>
  )
}
