import React from 'react'
import {Box, ButtonProps, Card, Flex, Text} from '@sanity/ui'
import {TransferOwnershipDialog, useConfirmDialog} from '../dialog'
import {PermissionButtonProject} from '@/ui/index'
import {useCanTransferProject} from '@/data/projects'
import {Project} from '@/types/models'
import {InfoOutlineIcon} from '@sanity/icons'

type Props = {
  project: Project
  buttonProps?: ButtonProps
}

export function TransferProjectButton({project, buttonProps}: Props) {
  const {canTransfer, isLoading, reason, isCustomPricing} = useCanTransferProject(project)
  const {Dialog: TransferDialog, hideDialog, showDialog} = useConfirmDialog()

  return (
    <>
      {!canTransfer && isCustomPricing && (
        <Card tone="caution" padding={4} radius={2}>
          <Flex align="center">
            <Box>
              <Text size={1}>
                <InfoOutlineIcon />
              </Text>
            </Box>
            <Box marginLeft={4}>
              <Text size={1}>{reason}</Text>
            </Box>
          </Flex>
        </Card>
      )}
      <Flex>
        <PermissionButtonProject
          hasPermission={canTransfer}
          title="Transfer ownership"
          onClick={showDialog}
          loading={isLoading}
          customDescription={reason}
          {...buttonProps}
        />
      </Flex>
      {project && (
        <Card>
          <TransferOwnershipDialog
            Dialog={TransferDialog}
            project={project}
            hideDialog={hideDialog}
          />
        </Card>
      )}
    </>
  )
}
