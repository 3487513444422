import {Stack} from '@sanity/ui'
import React from 'react'
import {EmptyBlock, TabSectionHeader} from '@/ui/index'

export function ActiveSessions({title}: {title: string}) {
  return (
    <Stack space={4}>
      <TabSectionHeader title={title} />
      <EmptyBlock
        illustration="coming-soon"
        title="Coming soon!"
        description="This feature is not yet available in Early Access."
      />
    </Stack>
  )
}
