import {Card, Radio, Text} from '@sanity/ui'
import React from 'react'
import styled from 'styled-components'

const Item = styled(Card)`
  position: relative;
  background: transparent;
  box-shadow: none;

  [data-ui='Radio'] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    outline: none;
    padding: 0;
    margin: 0;
    z-index: 1;

    input:focus:focus-visible {
      opacity: 1;
      box-shadow:
        0 0 0 1px var(--card-bg-color),
        0 0 0 3px var(--card-focus-ring-color);
    }

    span {
      display: none;
    }
  }
`

export type RadioItemType = {
  label: string | number
  value: string | number
  id: string
  name: string
  // eslint-disable-next-line react/no-unused-prop-types
  defaultChecked?: boolean
}

export const RadioItem = ({
  label,
  name,
  value,
  id,
  _checked,
}: RadioItemType & {_checked: boolean}) => {
  return (
    <Item padding={3} radius={2}>
      <Text as="label" htmlFor={id} muted={!_checked} align="center">
        {label}
      </Text>
      <Radio value={value} id={id} name={name} />
    </Item>
  )
}
