/* eslint-disable no-warning-comments */
import React, {useCallback, useMemo} from 'react'
import {
  Button as UIButton,
  Box,
  Text,
  Stack,
  Menu,
  MenuItem,
  MenuButton,
  CardProps,
  Inline,
  Flex,
  Checkbox,
} from '@sanity/ui'
import {ChevronDownIcon, Icon} from '@sanity/icons'
import styled from 'styled-components'
import {useUID} from 'react-uid'

export type MenuSelectOption = {
  label: string
  description?: string | React.ReactNode
  value: string | undefined
}

type Props = {
  disabled?: boolean
  value?: any[]
  children?: React.ReactNode
  options?: MenuSelectOption[]
  onChange: (value: string | number | undefined) => void
  onBlur?: () => void
  loading?: boolean
  defaultValue?: MenuSelectOption[]
  maxWidth?: string | number
  maxMenuWidth?: string | number
  checkbox?: boolean
  isOptionDisabled?: (option: MenuSelectOption) => boolean
  nosort?: boolean
} & CardProps

const Button = styled(UIButton)`
  &:not(:disabled) * {
    color: ${({theme}) => theme.sanity.color.base.fg};
  }
`

export const MenuSelect = ({
  children,
  options,
  value,
  disabled = false,
  onChange,
  onBlur,
  loading,
  defaultValue,
  maxWidth,
  maxMenuWidth = 300,
  checkbox = false,
  isOptionDisabled,
  nosort = false,
}: Props) => {
  const values = useMemo(
    () => (Array.isArray(value) ? value?.map((v) => v.value || v.name) : [value]),
    [value]
  )
  const optionsDoesNotExist = useMemo(
    () => !options?.some((o) => values?.includes(o.value)),
    [values, options]
  )
  const uid = useUID()
  let optionsSorted = options
  if (!nosort) {
    optionsSorted = options?.sort((a, b) => a.label.split('').length - b.label.split('').length)
  }

  const onChangeInternal = useCallback(
    (option: MenuSelectOption) => {
      if (onChange) {
        onChange(option.value)
      }
    },
    [onChange]
  )

  const isMenuItemDisabled = useCallback(
    (option: MenuSelectOption) => {
      if (isOptionDisabled) {
        return isOptionDisabled(option)
      }
      return false
    },
    [isOptionDisabled]
  )

  const textValue = value?.length === 0 ? defaultValue : value || defaultValue
  return (
    <MenuButton
      popover={{
        portal: true,
        placement: 'bottom-start',
        fallbackPlacements: ['right-start'],
        constrainSize: true,
        preventOverflow: true,
        /**
         * This is a workaround to make the popover menu be bound to the dialog backdrop,
         * instead of the nearest parent
         */
        floatingBoundary:
          (document.querySelector("[data-portal]>[aria-modal='true']") as HTMLElement) || undefined,
      }}
      button={
        <Button
          text={textValue?.map((v) => v.title || v.label).join(', ')}
          mode="bleed"
          tone="default"
          iconRight={ChevronDownIcon}
          disabled={loading || disabled}
          style={{cursor: disabled ? 'not-allowed' : 'default', maxWidth: maxWidth}}
          loading={loading}
          fontSize={1}
          paddingX={2}
        />
      }
      id={uid}
      menu={
        <Menu
          style={{maxWidth: maxMenuWidth}}
          onBlur={onBlur}
          disabled={loading || disabled}
          space={1}
        >
          {value && values.length !== 0 && optionsDoesNotExist && (
            <Box padding={3}>
              <Flex align="baseline">
                <Box paddingRight={3}>
                  <Text size={1} muted>
                    <Icon symbol="warning-outline" />
                  </Text>
                </Box>
                <Text muted size={1} as="p">
                  {/* TODO: This should be refactored to a generic message */}
                  The current role{values.length === 1 ? '' : 's'} ({values.join(', ')}) of this
                  member {values.length === 1 ? 'is' : 'are'} no longer available.
                </Text>
              </Flex>
            </Box>
          )}
          {optionsSorted?.map((option) => {
            const selected =
              values?.includes(option.value) || defaultValue?.some((v) => v.value === option.value)
            return (
              <MenuItem
                key={`menuItem-${option.value}`}
                onClick={() => onChangeInternal(option)} // eslint-disable-line react/jsx-no-bind
                tone={selected ? 'primary' : 'default'}
                padding={3}
                disabled={isMenuItemDisabled(option)}
              >
                <Flex align="flex-start" as="label">
                  {checkbox && (
                    <Box marginRight={3}>
                      <Checkbox checked={selected} readOnly />
                    </Box>
                  )}
                  <Stack space={2} flex={1}>
                    <Inline space={2}>
                      <Text weight="semibold">{option.label}</Text>
                      <Text muted size={1}>
                        {option.value}
                      </Text>
                    </Inline>
                    {option.description && (
                      <Text muted size={1}>
                        {option.description}
                      </Text>
                    )}
                  </Stack>
                </Flex>
              </MenuItem>
            )
          })}
          {children}
        </Menu>
      }
      placement="bottom-end"
    />
  )
}
