import React, {forwardRef} from 'react'
import {Radio, Text, Box, Flex, Stack} from '@sanity/ui'

type Props = {
  label: string
  description?: string
}

// eslint-disable-next-line react/display-name
export const RadioInput = forwardRef(
  (
    {label, description, ...restProps}: Props & Omit<React.HTMLProps<HTMLInputElement>, 'ref'>,
    ref: React.Ref<HTMLInputElement>
  ) => {
    return (
      <Flex align="center" as="label">
        <Radio {...restProps} ref={ref} />
        <Box marginLeft={3}>
          <Stack space={2}>
            <Box marginBottom={description ? 1 : 0}>
              <Text size={1}>{label}</Text>
            </Box>
            {description && (
              <Box>
                <Text size={1} muted>
                  {description}
                </Text>
              </Box>
            )}
          </Stack>
        </Box>
      </Flex>
    )
  }
)
