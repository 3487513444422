import {minutesToMilliseconds} from '@/utils/time'

export const STALE_TTL = minutesToMilliseconds(5)

export function getCustomerKey(orgId?: string | null) {
  return ['customers', orgId, 'details']
}

export function getPaymentStateKey(orgId?: string | null) {
  return ['customers', orgId, 'paymentstate']
}
