import {Card, Container, Grid, Skeleton, Stack} from '@sanity/ui'
import styled from 'styled-components'
import {DefaultLayout} from './defaultLayout'

const StyledGrid = styled(Grid)`
  min-height: 50vh;
`

export function PageTabLoading({
  layoutType = 'overview',
}: {
  layoutType?: 'overview' | 'sidebar' | 'singlePage'
}) {
  return (
    <DefaultLayout>
      <Card borderBottom paddingBottom={5} marginTop={-3} marginBottom={4}>
        <Container width={3} paddingX={[2, 3, 4]}>
          <Stack>
            <Skeleton animated muted radius={0} style={{width: '100%', height: '10em'}} />
          </Stack>
        </Container>
      </Card>

      <Container width={3}>
        <Card padding={[2, 3, 4]} paddingTop={[2, 3, 3]}>
          {layoutType === 'overview' && (
            <StyledGrid columns={2} gap={[2, 3, 4]}>
              <Skeleton animated muted radius={0} style={{width: '100%', height: '50%'}} />
              <Skeleton animated muted radius={0} style={{width: '100%', height: '70%'}} />
            </StyledGrid>
          )}
          {layoutType === 'sidebar' && (
            <StyledGrid columns={[1, 1, 12]} gap={[2, 3, 4]}>
              <Stack space={[2, 3, 4]} style={{gridColumn: 'span 2'}}>
                <Skeleton animated muted radius={0} style={{width: '100%', height: '2rem'}} />
                <Skeleton animated muted radius={0} style={{width: '100%', height: '2rem'}} />
                <Skeleton animated muted radius={0} style={{width: '100%', height: '2rem'}} />
              </Stack>
              <Skeleton
                animated
                muted
                radius={0}
                style={{width: '100%', height: '100%', gridColumn: 'span 10'}}
              />
            </StyledGrid>
          )}
          {layoutType === 'singlePage' && (
            <Stack space={[2, 3, 4]}>
              <Skeleton animated muted radius={0} style={{width: '100%', height: '100px'}} />
              <Skeleton animated muted radius={0} style={{width: '100%', height: '200px'}} />
              <Skeleton animated muted radius={0} style={{width: '100%', height: '150px'}} />
            </Stack>
          )}
        </Card>
      </Container>
    </DefaultLayout>
  )
}
