import React from 'react'
import {Card, Stack, Flex, Box, Text} from '@sanity/ui'
import {Illustration} from '@/ui/index'

export function ItemEmpty({text}: {text: string}) {
  return (
    <Card padding={4} paddingTop={6} paddingBottom={6}>
      <Flex align="center" justify="center">
        <Box>
          <Stack space={4}>
            <Flex justify="center">
              <Box>
                <Stack space={3}>
                  <Flex justify="center">
                    <Illustration drawing="package" color="#7B8CA8" />
                  </Flex>
                  <Text weight="semibold" size={1}>
                    {text}
                  </Text>
                </Stack>
              </Box>
            </Flex>
          </Stack>
        </Box>
      </Flex>
    </Card>
  )
}
