import React from 'react'
import {Box, Flex} from '@sanity/ui'
import {CardSingleBrand, BrandType} from './cardSingleBrand'

interface Props {
  brandType?: BrandType
}

type BrandIcon = {
  id: BrandType
  icon?: JSX.Element
}

export const CardBrandList = ({brandType}: Props) => {
  const brandTypeIsNotKnown = !brandType || !['visa', 'mastercard', 'amex'].includes(brandType)
  const BRANDS: BrandIcon[] = [
    {
      id: 'visa',
    },
    {
      id: 'mastercard',
    },
    {
      id: 'amex',
    },
  ]
  return (
    <Box marginBottom={1}>
      <Flex>
        {BRANDS.map((brand) => (
          <Box key={`brand-${brand.id}`} marginRight={2}>
            <CardSingleBrand
              faded={brandType && (brandType !== brand.id || brandTypeIsNotKnown)}
              brandType={brand.id}
            />
          </Box>
        ))}
        <Box key={`brand-other`}>
          <CardSingleBrand faded={brandType && !brandTypeIsNotKnown} brandType="unknown" />
        </Box>
      </Flex>
    </Box>
  )
}
