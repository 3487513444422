import {type ResourceType, type Role} from '@sanity/access-api'
import {
  EllipsisVerticalIcon,
  EyeOpenIcon,
  InfoOutlineIcon,
  RemoveCircleIcon,
  SelectIcon,
} from '@sanity/icons'
import {Box, Flex, Label, Menu, MenuButton, MenuItem, Text} from '@sanity/ui'
import {minBy} from 'lodash'
import Link from 'next/link'
import React, {memo, useMemo} from 'react'
import styled, {css} from 'styled-components'

import {type MembersV2} from '@/types/members_v2'

import {BadgeStack, type BadgeStackProps} from '../../../generic/badge-stack'
import {PermissionTooltip} from '../../../generic/permission-tooltip'
import {
  defineTableVirtualizedCell,
  defineTableVirtualizedColumns,
  TableVirtualized,
  type TableVirtualizedProps,
} from '../../../generic/table-virtualized'
import {Button} from '../../../primitives/button/Button'
import {Tooltip} from '../../../primitives/tooltip'
import {type DisabledMemberSettingsFeatures} from '../../../screens/members-settings-screen/types'
import {type ResourceRoleAddPayload, type ResourceRoleRemovePayload} from '../../../types'
import {renderList} from '../../../utils'
import {RoleSelectMenuButton} from '../../role-select-menu-button'
import {CellContentWrap, MemberAvatarCell, MemberCell} from '../common'
import {TimeCell} from '../common/TimeCell'
import {CELL_MIN_WIDTH, CELL_PADDING} from '../constants'

const EMPTY_ARRAY: [] = []

function estimateSize() {
  return 62
}

const VisuallyAlignedButton = styled(Button)(({theme}) => {
  const space = theme.sanity.space[2]

  return css`
    overflow: auto;

    // If the button mode is "bleed", we need to visually align the button
    // by moving it to the left.
    transform: translateX(-${space}px);
  `
})

export interface MembersOverviewTableProps {
  _debug?: TableVirtualizedProps['_debug']
  basePath?: string
  /**
   * Features that are disabled for the current user.
   */
  disabledFeatures?: DisabledMemberSettingsFeatures
  displayMode?: 'organization-roles' | 'project-access'
  filterByProjectId?: string
  members: MembersV2['member'][]
  onInspectMember: (memberId: string | null) => void
  onMemberRemove: (memberId: string) => void
  onResourceRoleAdd: (payload: ResourceRoleAddPayload) => void
  onResourceRoleRemove: (payload: ResourceRoleRemovePayload) => void
  projectsData: MembersV2['projectData'][]
  resourceId: string
  resourceType: ResourceType
  roles: Role[]
}

export const MembersOverviewTable = memo(function MembersOverviewTable(
  props: MembersOverviewTableProps
) {
  const {
    _debug = false,
    basePath,
    disabledFeatures,
    displayMode,
    filterByProjectId,
    members,
    onInspectMember,
    onMemberRemove,
    onResourceRoleAdd,
    onResourceRoleRemove,
    projectsData,
    resourceId,
    resourceType,
    roles,
  } = props

  const memberAvatarCell = useMemo(() => {
    return defineTableVirtualizedCell<MembersV2['member']>({
      id: 'avatar',
      cellMaxWidth: 80,
      cellMinWidth: 80,
      cellPadding: CELL_PADDING,
      headerPadding: CELL_PADDING,
      header: '',

      cell: (ctx) => {
        const value = ctx.row.original.profile

        return (
          <MemberAvatarCell
            displayName={value?.displayName}
            imageUrl={value?.imageUrl}
            provider={value?.provider}
          />
        )
      },
    })
  }, [])

  const memberCell = useMemo(() => {
    return defineTableVirtualizedCell<MembersV2['member']>({
      header: 'Member',
      id: 'member',
      cellPadding: CELL_PADDING,
      cellMinWidth: 200,
      headerPadding: CELL_PADDING,

      cell: (ctx) => {
        const memberProfile = ctx.row.original.profile

        return (
          <MemberCell
            displayName={memberProfile?.displayName}
            email={memberProfile?.email}
            isCurrentUser={memberProfile?.isCurrentUser}
            onClick={
              resourceType === 'organization'
                ? () => onInspectMember(memberProfile?.id || '')
                : undefined
            }
          />
        )
      },
    })
  }, [onInspectMember, resourceType])

  const memberAddedCell = useMemo(() => {
    return defineTableVirtualizedCell<MembersV2['member']>({
      header: 'Added',
      id: 'added',
      cellMaxWidth: 120,
      cellMinWidth: 120,
      cellPadding: CELL_PADDING,
      headerPadding: CELL_PADDING,

      cell: (ctx) => {
        const earliestMembershipDate = minBy(
          ctx.row.original.memberships.filter(
            (m) => m.resourceType === resourceType && m.resourceId === resourceId
          ),
          (m) => (m.addedAt ? new Date(m.addedAt).getTime() : Infinity)
        )
        const addedAtDate = earliestMembershipDate?.addedAt
          ? new Date(earliestMembershipDate.addedAt)
          : undefined

        return <TimeCell date={addedAtDate} />
      },
    })
  }, [resourceId, resourceType])

  const memberLastSeenCell = useMemo(() => {
    return defineTableVirtualizedCell<MembersV2['member']>({
      header: () => {
        return (
          <Flex align="center" gap={2}>
            <Box>
              <Label align="left" textOverflow="ellipsis">
                Last active
              </Label>
            </Box>

            <Tooltip placement="top" content="Activity within a Studio can be delayed">
              <Text size={1}>
                <InfoOutlineIcon />
              </Text>
            </Tooltip>
          </Flex>
        )
      },
      id: 'lastSeenAt',
      cellMaxWidth: 120,
      cellMinWidth: 120,
      cellPadding: CELL_PADDING,
      headerPadding: CELL_PADDING,

      cell: (ctx) => {
        const earliestMembershipDate = minBy(
          ctx.row.original.memberships.filter(
            (m) => m.resourceType === resourceType && m.resourceId === resourceId
          ),
          (m) => (m.lastSeenAt ? new Date(m.lastSeenAt).getTime() : Infinity)
        )
        const lastSeenAt = earliestMembershipDate?.lastSeenAt
          ? new Date(earliestMembershipDate.lastSeenAt)
          : undefined

        return <TimeCell date={lastSeenAt} />
      },
    })
  }, [resourceId, resourceType])

  const projectsAccessRolesCell = useMemo(() => {
    return defineTableVirtualizedCell<MembersV2['member']>({
      header: 'Project access roles',
      id: 'project-access-roles',
      cellPadding: CELL_PADDING,
      cellMinWidth: CELL_MIN_WIDTH,
      headerPadding: CELL_PADDING,
      cellFlex: 1.5,

      cell: (ctx) => {
        const member = ctx.row.original

        const selectedProject = projectsData.find((p) => p.projectProfile.id === filterByProjectId)
        const roleSchemas = selectedProject?.roleSchemas

        const memberships = member.memberships?.find((m) => m.resourceId === filterByProjectId)
        const roleTitles =
          memberships?.roleNames?.map((roleName) => {
            return roleSchemas?.find((r) => r?.name === roleName)?.title || roleName
          }) || EMPTY_ARRAY

        const list = renderList({
          emptyLabel: '-',
          items: roleTitles,
          label: 'roles',
          type: 'string',
        })

        return (
          <CellContentWrap>
            <Text size={1} muted>
              {list}
            </Text>
          </CellContentWrap>
        )
      },
    })
  }, [filterByProjectId, projectsData])

  const projectRolesCell = useMemo(() => {
    return defineTableVirtualizedCell<MembersV2['member']>({
      header: 'Roles',
      id: 'project-roles',
      cellPadding: CELL_PADDING,
      cellMinWidth: CELL_MIN_WIDTH,
      headerPadding: CELL_PADDING,
      cellFlex: 1.5,

      cell: (ctx) => {
        const member = ctx.row.original

        const project = projectsData.find((p) => p.projectProfile.id === resourceId)
        const roleSchemas = project?.roleSchemas || EMPTY_ARRAY

        const memberships = member.memberships?.find((m) => m.resourceId === resourceId)

        const memberRoles =
          (memberships?.roleNames
            ?.map((roleName) => {
              return roleSchemas?.find((r) => r?.name === roleName)
            })
            .filter(Boolean) as Role[]) || EMPTY_ARRAY

        const roleTitles = memberRoles.map((role) => role?.title || role?.name || '')

        const list = renderList({
          emptyLabel: '-',
          items: roleTitles,
          label: 'roles',
          maxLength: 3,
          type: 'string',
        })

        return (
          <CellContentWrap>
            <RoleSelectMenuButton
              options={roleSchemas}
              selectedRoles={memberRoles}
              onSelect={(role) => {
                if (!member.profile?.id) return
                if (!role.name) return

                const hasRole = memberRoles.some((r) => r.name === role.name)

                if (hasRole) {
                  onResourceRoleRemove({
                    memberId: member.profile.id,
                    resourceId,
                    resourceType,
                    roleName: role.name,
                  })
                }

                if (!hasRole) {
                  onResourceRoleAdd({
                    memberId: member.profile.id,
                    resourceId,
                    resourceType,
                    roleName: role.name,
                  })
                }
              }}
              /**
               * The way popovers work you can't wrap the button in a tooltip
               * because the popover will never render.
               * @see ENTX-2426
               */
              popoverButtonProps={{
                button: disabledFeatures?.editMembers.isDisabled ? (
                  <PermissionTooltip>
                    <VisuallyAlignedButton
                      disabled
                      mode="bleed"
                      padding={2}
                      text={list}
                      iconRight={SelectIcon}
                    />
                  </PermissionTooltip>
                ) : (
                  <VisuallyAlignedButton
                    mode="bleed"
                    padding={2}
                    text={list}
                    iconRight={SelectIcon}
                  />
                ),
              }}
            />
          </CellContentWrap>
        )
      },
    })
  }, [
    disabledFeatures?.editMembers.isDisabled,
    onResourceRoleAdd,
    onResourceRoleRemove,
    projectsData,
    resourceId,
    resourceType,
  ])

  const projectsCell = useMemo(() => {
    return defineTableVirtualizedCell<MembersV2['member']>({
      header: 'Projects',
      id: 'projects',
      cellPadding: CELL_PADDING,
      cellMinWidth: CELL_MIN_WIDTH,
      headerPadding: CELL_PADDING,

      cell: (ctx) => {
        const memberships = ctx.row.original?.memberships || EMPTY_ARRAY

        const projects: BadgeStackProps['items'] = projectsData
          .map((p) => {
            const profile = p.projectProfile
            const roles = p.roleSchemas

            const membership = memberships.find((m) => m.resourceId === p.projectProfile.id)

            if (membership && (membership?.roleNames || EMPTY_ARRAY)?.length > 0) {
              return {
                title: profile.displayName,
                info: membership.roleNames?.map((roleName) => {
                  const roleProfile = roles.find((r) => r?.name === roleName)

                  return roleProfile?.title || roleName
                }),
                href: `${basePath}/project/${p.projectProfile.id}`,
                as: Link,
              }
            }

            if (membership) {
              return {
                title: profile.displayName,
                info: ['No roles'],
                href: `${basePath}/project/${p.projectProfile.id}`,
                as: Link,
              }
            }

            return null
          })
          .filter(Boolean) as BadgeStackProps['items']

        const hasProjects = projects.length > 0

        return (
          <CellContentWrap>
            {hasProjects && <BadgeStack maxLength={3} items={projects} />}

            {!hasProjects && (
              <Text size={1} muted>
                -
              </Text>
            )}
          </CellContentWrap>
        )
      },
    })
  }, [basePath, projectsData])

  const orgRolesCell = useMemo(() => {
    let header = 'Org. roles'

    if (resourceType === 'project') {
      header = 'Project roles'
    }

    return defineTableVirtualizedCell<MembersV2['member']>({
      header,
      id: 'roles',
      cellPadding: CELL_PADDING,
      cellMinWidth: 180,
      headerPadding: CELL_PADDING,
      cellFlex: displayMode === 'organization-roles' ? 1.5 : 1,

      cell: (ctx) => {
        const memberships = ctx.row.original?.memberships || EMPTY_ARRAY
        const resourceMembership = memberships.find(
          (m) => m.resourceType === resourceType && m.resourceId === resourceId
        )
        const membershipRoles = resourceMembership?.roleNames || EMPTY_ARRAY

        const roleProfiles = membershipRoles
          .map((roleName) => {
            return roles.find((r) => r.name === roleName && r.resourceType === resourceType)
          })
          .filter(Boolean) as Role[]

        const roleProfileTitles = roleProfiles.map((role) => role?.title || role?.name || '')

        const list = renderList({
          emptyLabel: '-',
          items: roleProfileTitles,
          label: 'roles',
          type: 'string',
        })

        return (
          <CellContentWrap>
            <Text size={1} muted>
              {list}
            </Text>
          </CellContentWrap>
        )
      },
    })
  }, [displayMode, resourceId, resourceType, roles])

  const memberActionsCell = useMemo(() => {
    return defineTableVirtualizedCell<MembersV2['member']>({
      id: 'actions',
      cellMaxWidth: 50,
      cellMinWidth: 50,
      headerPadding: CELL_PADDING,
      header: '',

      cell: (ctx) => {
        const memberProfile = ctx.row.original.profile
        const defaultRemoveText = `Remove from ${resourceType}`
        return (
          <Flex justify="center" align="center" height="fill" flex={1}>
            <MenuButton
              id="member-tables-row-actions"
              button={
                <Button
                  aria-label="Show actions"
                  icon={EllipsisVerticalIcon}
                  mode="bleed"
                  fontSize={1}
                  padding={2}
                  tooltipProps={{content: 'Show actions'}}
                />
              }
              popover={{animate: true, placement: 'left-start'}}
              menu={
                <Menu>
                  {resourceType === 'organization' && (
                    <MenuItem
                      icon={EyeOpenIcon}
                      onClick={() => onInspectMember(memberProfile?.id || '')}
                      text="Inspect all roles"
                    />
                  )}
                  <PermissionTooltip disabled={!disabledFeatures?.removeMembers.isDisabled}>
                    <MenuItem
                      disabled={disabledFeatures?.removeMembers.isDisabled}
                      icon={RemoveCircleIcon}
                      onClick={() => onMemberRemove(memberProfile?.id || '')}
                      text={defaultRemoveText}
                      tone="critical"
                    />
                  </PermissionTooltip>
                </Menu>
              }
            />
          </Flex>
        )
      },
    })
  }, [disabledFeatures?.removeMembers.isDisabled, onInspectMember, onMemberRemove, resourceType])

  const columns = useMemo(() => {
    if (resourceType === 'project') {
      return defineTableVirtualizedColumns<MembersV2['member']>([
        memberAvatarCell,
        memberCell,
        projectRolesCell,
        memberAddedCell,
        memberLastSeenCell,
        memberActionsCell,
      ])
    }

    if (resourceType === 'organization') {
      if (displayMode === 'project-access') {
        return defineTableVirtualizedColumns<MembersV2['member']>([
          memberAvatarCell,
          memberCell,
          projectsAccessRolesCell,
          memberLastSeenCell,
          memberActionsCell,
        ])
      }

      if (displayMode === 'organization-roles') {
        return defineTableVirtualizedColumns<MembersV2['member']>([
          memberAvatarCell,
          memberCell,
          orgRolesCell,
          memberLastSeenCell,
          memberActionsCell,
        ])
      }

      return defineTableVirtualizedColumns<MembersV2['member']>([
        memberAvatarCell,
        memberCell,
        projectsCell,
        orgRolesCell,
        memberLastSeenCell,
        memberActionsCell,
      ])
    }

    return []
  }, [
    displayMode,
    memberActionsCell,
    memberAvatarCell,
    memberCell,
    orgRolesCell,
    projectRolesCell,
    projectsAccessRolesCell,
    projectsCell,
    memberAddedCell,
    memberLastSeenCell,
    resourceType,
  ])

  return (
    <TableVirtualized
      _debug={_debug}
      columnsDefinitions={columns}
      data={members}
      estimateSize={estimateSize}
    />
  )
})
