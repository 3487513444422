import React, {useCallback, useEffect, useRef} from 'react'
import {Stack, Box, Container, Button, useToast} from '@sanity/ui'
import {useRouter} from 'next/router'
import {useForm, FormProvider, useFieldArray} from 'react-hook-form'
import {InviteConfig} from '@/types/index'
import styled from 'styled-components'
import {TabHeader} from '..'
import {RepeatableTableGroup, InvitationRowComponentOrg} from '.'
import {Organization, OrganizationRole, OrganizationInvitationData} from '@/types/models'
import {useOrgInvitations} from '@/data/invitations'
import {useRoutePath} from '@/context/index'
import {ArrowLeftIcon} from '@sanity/icons'
import {AppLink} from '@/ui/app'

type Props = {
  config: InviteConfig
  id: string // org or project ID
  children?: React.ReactNode
  org: Organization
  roles: OrganizationRole[]
  onFormValidityChanged?: (valid: boolean) => void
  onComplete?: () => void
}

type FormValues = {
  invitations: OrganizationInvitationData[]
}

const ButtonLink = styled.span`
  &:hover {
    background: none;
    box-shadow: none;
  }
`

export function InviteFormOrg({config, id, org, roles, onFormValidityChanged, onComplete}: Props) {
  const {basePath} = useRoutePath()
  const toast = useToast()
  const router = useRouter()
  const firstRoleName = roles?.[0]?.name || 'billing-manager'
  const defaultValues: FormValues = {
    invitations: [
      {
        email: '',
        role: firstRoleName,
      },
    ],
  }
  const methods = useForm<FormValues>({
    defaultValues,
    mode: 'onChange',
  })
  const {getValues, setValue, trigger: triggerValidation, control, formState} = methods
  const {sendInvitations, isSending} = useOrgInvitations(org.id)
  const promiseRef = useRef<{
    resolve: (value?: unknown) => void
    reject: (value?: unknown) => void
  }>()

  // Create the root path for this view using the config
  const rootPath = `${basePath}/${config.path}`
  // Create the parent path of this view using the parent defined in the config
  const parentPath =
    (config.parentTab && `${basePath}/${config.parentTab?.path}/invitations`) || rootPath

  // If there is a next step, create that path
  // If there was a previous step, check if it was the root and use the root path if yes,
  // or create the path to the previous step. If not, fallback to the parent path
  const prevPath = parentPath

  const {fields, append, remove} = useFieldArray({
    control,
    name: 'invitations',
  })

  const isFormValid = formState.isValid

  useEffect(() => {
    if (onFormValidityChanged) {
      onFormValidityChanged(isFormValid)
    }
  }, [isFormValid, onFormValidityChanged])

  const onSubmit = useCallback(
    (data: FormValues) => {
      sendInvitations(data.invitations, {
        onSuccess: (results) => {
          const hasErrors = results.some((invitationStatus) => invitationStatus.error)

          if (hasErrors) {
            const errorMap = new Map()
            const successfulInvitationIndexes: number[] = []

            results.forEach((invitationStatus, idx) => {
              if (invitationStatus.error) {
                const error = invitationStatus.error.response

                if (error) {
                  const {error: errorCode, message} = JSON.parse(error)
                  errorMap.set(errorCode, message)
                } else {
                  const email = invitationStatus.invitation.email
                  errorMap.set(
                    'Something went wrong',
                    `Failed to send an invitation to ${email}. Please try again later.`
                  )
                }
              } else {
                successfulInvitationIndexes.push(idx)
              }
            })

            if (successfulInvitationIndexes.length > 0) {
              remove(successfulInvitationIndexes)
            }

            errorMap.forEach((errorMessage, errorCode) => {
              if (promiseRef.current) {
                promiseRef.current.reject(new Error(errorMessage))
              }
              toast.push({
                title: `${errorCode}`,
                description: `${errorMessage}`,
                status: 'error',
                duration: 10000,
                closable: true,
              })
            })

            return
          }

          if (promiseRef.current) {
            promiseRef.current.resolve()
          }
          toast.push({
            title: `Your ${results.length > 1 ? `invitations` : `invitation`} was sent`,
            status: 'success',
          })

          onComplete?.()
          router.push(parentPath)
        },
      })
      return data.invitations
    },
    [sendInvitations, remove, toast, onComplete, router, parentPath]
  )

  const addInvitationRow = useCallback(() => {
    append({
      email: '',
      role: firstRoleName,
    })
  }, [append, firstRoleName])

  const deleteInvitation = useCallback(
    (index: number) => {
      const {invitations: prevInvitations} = getValues()
      const current = prevInvitations ? [...prevInvitations] : []

      if (current.length === 1) {
        setValue(`invitations.0`, {
          email: '',
          role: firstRoleName,
        })

        return
      }

      remove(index)
      triggerValidation('invitations')
    },
    [getValues, setValue, remove, triggerValidation, firstRoleName]
  )

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
        <Container size={0}>
          <Stack space={[4, 4, 5]} colSpan={1}>
            <Box marginBottom={3}>
              <AppLink href={prevPath ? prevPath : parentPath}>
                <Button
                  as={ButtonLink}
                  mode="bleed"
                  tone="primary"
                  text={config?.parentTab?.title || 'Go back' || id}
                  icon={ArrowLeftIcon}
                  padding={1}
                />
              </AppLink>
            </Box>
            <TabHeader
              title="Invite organization members"
              description="Enter the email and role for the new members. Each member will receive an invitation email with a link to accept the invitation."
            />

            <Box>
              <RepeatableTableGroup
                org={org}
                addButtonText="Add another e-mail address"
                fields={fields}
                rowComponent={InvitationRowComponentOrg}
                onInsert={addInvitationRow}
                onDelete={deleteInvitation}
              />
            </Box>
          </Stack>

          <Box paddingTop={6}>
            <Button type="submit" tone="primary" text={`Send invitations`} loading={isSending} />
          </Box>
        </Container>
      </form>
    </FormProvider>
  )
}
