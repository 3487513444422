import React, {useEffect, useRef} from 'react'
import {Dialog as UIDialog, Card, Grid, Button, Stack, Text, Box, Layer} from '@sanity/ui'
import styled from 'styled-components'
import {useUID} from 'react-uid'

type Props = {
  children?: React.ReactNode
  id: string
  header?: string
  onClose: () => void
  title?: string
  description?: string
  confirmButton: React.ReactNode
  disabled?: boolean
}

const Dialog = styled(UIDialog)`
  & [data-ui='DialogCard'] {
    max-width: 440px;
  }
`

export function ConfirmDialog({
  header,
  children,
  title,
  description,
  onClose,
  id,
  confirmButton,
  disabled,
}: Props) {
  const uId = useUID()
  const childrenWrap = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const {current} = childrenWrap ?? {}
    const focusableElements = current?.querySelectorAll<HTMLElement>(
      'a:not(:disabled), button:not(:disabled), textarea:not(:disabled), input:not(:disabled), select:not(disabled), details:not(disabled), [tabindex]:not([tabindex="-1"] '
    )
    if (!focusableElements) return
    focusableElements[0]?.focus()
  }, [])

  return (
    <Layer zOffset={6} style={{position: 'fixed'}}>
      <Dialog
        id={id || uId}
        header={header}
        onClose={onClose}
        onClickOutside={onClose}
        width={1}
        style={{position: 'fixed'}}
        footer={
          <Card padding={3}>
            <Grid columns={2} gap={2}>
              <Button disabled={disabled} text="Cancel" mode="bleed" onClick={onClose} />
              {confirmButton}
            </Grid>
          </Card>
        }
      >
        <Box paddingX={4} paddingY={3}>
          <Stack space={title || description ? 4 : 3}>
            {(description || title) && (
              <Stack space={4}>
                {typeof title === 'string' ? (
                  <Text size={2} weight="semibold">
                    {title}
                  </Text>
                ) : (
                  title
                )}
                {typeof description === 'string' ? (
                  <Text muted size={1}>
                    {description}
                  </Text>
                ) : (
                  description
                )}
              </Stack>
            )}
            <Box ref={childrenWrap}>{children}</Box>
          </Stack>
        </Box>
      </Dialog>
    </Layer>
  )
}
