import {
  Stack,
  Box,
  Card,
  Grid,
  Flex,
  Label,
  Text,
  Inline,
  Tooltip,
  Badge,
  HeadingSkeleton,
} from '@sanity/ui'
import React from 'react'
import {InfoOutlineIcon} from '@sanity/icons'
import {EmptyBlock} from '../../'
import {DatasetItem} from './'
import {Dataset, Project, Tag} from '@/types/index'

type Props = {
  project: Project
  isLoading: boolean
  error?: Error | null
  datasets: Dataset[]
  tags: Tag[]
}

export function DatasetList(props: Props) {
  const {error, isLoading, datasets, project, tags} = props

  const tagsHasRequiredFeature = project.features.some(
    (feature) => feature === 'advancedRolesManagement'
  )

  if (error) {
    return (
      <EmptyBlock
        title="Something went wrong"
        description={
          <Text muted size={1}>
            Try reloading this page. If that doesn't help, you can ask for help in our{' '}
            <a
              href="https://slack.sanity.io/"
              target="_blank"
              rel="noreferrer"
              style={{color: 'var(--card-link-color)'}}
            >
              slack community
            </a>
          </Text>
        }
        illustration="error"
      />
    )
  }
  const {topLevelDatasets, addons} = datasets.reduce<{
    topLevelDatasets: Dataset[]
    addons: Record<string, Dataset[]>
  }>(
    (acc, dataset) => {
      if (!dataset.addonFor) {
        acc.topLevelDatasets.push(dataset)
        return acc
      }

      if (!acc.addons[dataset.addonFor]) {
        acc.addons[dataset.addonFor] = []
      }
      acc.addons[dataset.addonFor].push(dataset)
      return acc
    },
    {topLevelDatasets: [], addons: {}}
  )

  return (
    <Box marginTop={2}>
      <Card borderBottom marginBottom={2} paddingX={[3, 3, 4, 4]}>
        <Flex paddingBottom={3} paddingTop={4}>
          <Box flex={2}>
            <Label muted size={1}>
              Title
            </Label>
          </Box>
          <Box flex={1}>
            <Inline space={1}>
              <Label muted size={1}>
                Visibility
              </Label>
              <Tooltip
                content={
                  <Box padding={3} style={{maxWidth: 380}}>
                    <Stack space={4}>
                      <Text size={1} muted>
                        Visibility defines how published documents can be accessed by the public.
                        Accessing drafts and documents with a path id will always require
                        authentication.
                      </Text>
                      <Grid style={{gridTemplateColumns: 'auto 1fr'}} gap={4}>
                        <Box>
                          <Badge tone="caution" mode="outline">
                            Private
                          </Badge>
                        </Box>
                        <Text size={1} muted>
                          No documents are available without authentication.
                        </Text>
                        <Box>
                          <Badge tone="positive" mode="outline">
                            Public
                          </Badge>
                        </Box>
                        <Text size={1} muted>
                          Published documents are available without authentication.
                        </Text>
                      </Grid>
                    </Stack>
                  </Box>
                }
              >
                <Text size={0} muted>
                  <InfoOutlineIcon />
                </Text>
              </Tooltip>
            </Inline>
          </Box>
          {tagsHasRequiredFeature && (
            <Box flex={1}>
              <Label muted size={1}>
                Tags
              </Label>
            </Box>
          )}
        </Flex>
      </Card>
      <Stack space={3}>
        {isLoading &&
          [...Array(10)].map((_item, i) => (
            <Card
              // eslint-disable-next-line react/no-array-index-key
              key={`skeleton-${i}`}
              border
              radius={2}
              padding={4}
            >
              <Flex>
                <Box flex={1}>
                  <HeadingSkeleton style={{width: '200px'}} animated />
                </Box>
                <Box flex={0.5}>
                  <HeadingSkeleton animated style={{width: '50px'}} />
                </Box>
                <Box flex={1} />
              </Flex>
            </Card>
          ))}
        {!isLoading &&
          topLevelDatasets.length > 0 &&
          topLevelDatasets.map((dataset) => (
            <DatasetItem
              key={dataset.name}
              dataset={dataset}
              addons={addons}
              tags={tags}
              project={project}
              indent={0}
            />
          ))}
      </Stack>
      {!isLoading && datasets.length === 0 && (
        <EmptyBlock
          title="There are no datasets in this project"
          description="Maybe try creating a new dataset?"
        />
      )}
    </Box>
  )
}
