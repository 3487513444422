import {TrialBadge} from '@/app/navigation/trial/trialBadge'
import {useCurrentScopeContext, useRoutePath} from '@/context/index'
import {useProjectSubscription} from '@/data/projects/useProjectSubscription'
import {filterOrgs} from '@/data/organizations/useOrganizations'
import {AppLink, HelpMenu, QuickDrawer, SlideMenu, UserMenu, useNavbarHeight} from '@/ui/app'
import {CreateProjectDialog} from '@/ui/index'
import {
  getOrgPath,
  getProjectPath,
  sendAmplitudeTrackingEvent,
  useMatchesMedia,
} from '@/utils/index'
import {AddIcon, AddUserIcon, SparklesIcon} from '@sanity/icons'
import {SanityMonogram} from '@sanity/logos'
import {
  Box,
  Button,
  ButtonTone,
  Card,
  Container,
  Flex,
  Inline,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  Stack,
  Text,
} from '@sanity/ui'
import {useSearchParams} from 'next/navigation'
import {useRouter} from 'next/router'
import {useCallback, useMemo, useState} from 'react'

export const CREATE_NEW_ORG_PATH = '/manage/create-new-team'

export function Navbar(props: {disableNav?: boolean}) {
  const {disableNav} = props
  const router = useRouter()
  const {basePath} = useRoutePath()
  const {
    orgs = [],
    org: currentOrg,
    project: currentProject,
    currentUser,
  } = useCurrentScopeContext()
  const {data: subscription} = useProjectSubscription(currentProject?.id)

  const isMobile = useMatchesMedia(2)
  const NAV_BAR_HEIGHT = useNavbarHeight()
  const searchParams = useSearchParams()

  const [showCreateProjectDialog, setShowCreateProjectDialog] = useState(
    searchParams.get('new-project') !== null
  )

  const defaultDrawerData = filterOrgs(orgs).map((org) => ({
    title: org.name,
    selected: org.id === currentOrg?.id,
    handleClick: () => router.push(getOrgPath(org)),
    id: org.id,
    children: org.projects?.map((project) => ({
      title: project.displayName,
      selected: project.id === currentProject?.id,
      id: project.id,
      handleClick: () => router.push(getProjectPath(project)),
    })),
  }))

  const projectsDrawerData = currentOrg?.id
    ? defaultDrawerData.find((data) => data.id === currentOrg.id && !!data.children)?.children
    : []

  const defaultDrawerActions = [
    {
      level: 0,
      text: 'Create new organization',
      handleClick: () => router.push(CREATE_NEW_ORG_PATH),
    },
    {
      level: 1,
      text: 'Create new project',
      handleClick: () => setShowCreateProjectDialog(true),
    },
  ]

  const projectsDrawerActions = [
    {
      level: 0,
      text: 'New project',
      handleClick: () => setShowCreateProjectDialog(true),
    },
    {
      level: 0,
      text: 'Sanity Create',
      icon: <SparklesIcon />,
      tone: 'primary' as ButtonTone,
      description: 'An intuitive AI writing assistant',
      handleClick: () => {
        sendAmplitudeTrackingEvent(
          'Sanity Create Project Switcher CTA Clicked',
          currentProject?.id,
          currentOrg?.id
        )
        window.open('https://www.sanity.io/app/create?ref=manage-navbar', '_blank')
      },
    },
  ]

  const slideMenuButtonText = useMemo(() => {
    if (currentOrg) {
      return currentOrg?.name
    }
    return 'Select project or org'
  }, [currentOrg])

  const onCloseProjectDialog = useCallback(() => {
    setShowCreateProjectDialog(false)
  }, [])

  const gotoCreateOrg = useCallback(() => {
    router.push(CREATE_NEW_ORG_PATH)
  }, [router])

  const onCreateNewProjectDialog = useCallback(() => {
    setShowCreateProjectDialog(true)
  }, [])

  return (
    <>
      <Card shadow={1} as="nav">
        <Container width={3}>
          <Box paddingX={[2, 3, 4]}>
            <Flex align="center" justify="space-between" style={{height: NAV_BAR_HEIGHT}}>
              <Inline space={3}>
                <Card>
                  <Flex align="center">
                    <AppLink href="/manage">
                      <Box marginRight={2} style={{height: 33, width: 33}}>
                        <SanityMonogram style={{width: '100%', height: '100%'}} />
                      </Box>
                    </AppLink>

                    {isMobile && !disableNav && (
                      <SlideMenu
                        data={defaultDrawerData}
                        buttonText={slideMenuButtonText}
                        isSelected={!!currentOrg?.name}
                      />
                    )}

                    {!isMobile && !disableNav && (
                      <QuickDrawer
                        data={defaultDrawerData}
                        actions={defaultDrawerActions}
                        isSelected={!!currentOrg}
                        openButton={currentOrg ? 'default' : 'shortcut'}
                        openButtonText={
                          currentOrg ? currentOrg.name : 'Select a project or organization'
                        }
                        emptyText="This organization does not have any projects yet"
                      />
                    )}
                  </Flex>
                </Card>
                {currentOrg && <Card style={{height: NAV_BAR_HEIGHT}} borderRight={!isMobile} />}
                {projectsDrawerData && currentOrg && !isMobile && !disableNav && (
                  <Card>
                    <QuickDrawer
                      data={projectsDrawerData}
                      actions={projectsDrawerActions}
                      isSelected={!!currentProject}
                      openButtonText={
                        currentProject ? currentProject.displayName : 'Select a project'
                      }
                      emptyText="There are no active projects"
                    />
                  </Card>
                )}
                {!currentOrg && !isMobile && !disableNav && (
                  <Inline space={2}>
                    <MenuButton
                      button={<Button text="New" icon={AddIcon} mode="ghost" />}
                      popover={{
                        placement: 'bottom-start',
                      }}
                      id="create-menu"
                      menu={
                        <Menu padding={2} style={{minWidth: 270}}>
                          <MenuItem paddingX={2} text="Organization" onClick={gotoCreateOrg} />
                          <MenuItem
                            paddingX={2}
                            text="Project"
                            onClick={onCreateNewProjectDialog}
                          />
                          <MenuDivider />
                          <MenuItem
                            paddingX={2}
                            tone="primary"
                            onClick={() => {
                              sendAmplitudeTrackingEvent(
                                'Sanity Create Project Switcher CTA Clicked',
                                undefined,
                                undefined
                              )
                              window.open(
                                'https://www.sanity.io/app/create?ref=manage-navbar',
                                '_blank'
                              )
                            }}
                          >
                            <Flex align="center">
                              <Stack space={2} flex={1}>
                                <Text weight="semibold" size={1}>
                                  Sanity Create
                                </Text>
                                <Text size={1}>An intuitive AI writing assistant</Text>
                              </Stack>
                              <Box marginLeft={2}>
                                <SparklesIcon />
                              </Box>
                            </Flex>
                          </MenuItem>
                        </Menu>
                      }
                    />
                  </Inline>
                )}
              </Inline>

              <Inline space={2}>
                {currentProject && subscription ? (
                  !['enterprise', 'non-profit', 'internal', 'other', 'test', 'growth'].includes(
                    subscription?.plan?.planTypeId || ''
                  ) ? (
                    <TrialBadge />
                  ) : (
                    <Button
                      icon={AddUserIcon}
                      mode="bleed"
                      onClick={() => router.push(`${basePath}/members?invite=true`)}
                      text="Invite members"
                      fontSize={1}
                    />
                  )
                ) : null}

                <Box>
                  <HelpMenu />
                </Box>
                {currentUser && (
                  <Box>
                    <UserMenu />
                  </Box>
                )}
              </Inline>
            </Flex>
          </Box>
        </Container>
      </Card>

      {showCreateProjectDialog && <CreateProjectDialog onClose={onCloseProjectDialog} />}
    </>
  )
}
