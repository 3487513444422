import {Box, Card, Grid, Layer} from '@sanity/ui'
import React, {createContext, useCallback, useContext, useState} from 'react'
import {useRouter} from 'next/router'
import {Navbar, Footer, PageHead} from '..'
import {AnnouncementBannerContainer} from '@/app/AnnouncementBannerContainer'
import {ArrearsBannerContainer} from '@/components/arrears/arrearsBannerContainer'
import {useCurrentScopeContext} from '@/context/index'
import {CompletePaymentBanner} from '@/components/arrears/completePaymentBanner'
import styled from 'styled-components'

const PortalContext = createContext<{
  addPortal: (portal: React.ReactNode) => void
  removePortal: () => void
}>({
  addPortal: () => {},
  removePortal: () => {},
})

export const useLayoutPortal = () => useContext(PortalContext)

const Container = styled(Card)`
  display: grid !important;
  grid-template-rows: auto 1fr auto;
  min-height: 100dvh;
`

const Main = styled(Grid)`
  flex: 1;
  position: relative;
  grid-template-rows: 1fr auto;
`

export function DefaultLayout({
  children,
  disableNav,
}: {
  children: React.ReactNode
  disableNav?: boolean
}) {
  // the Box that wraps children needs to have a key in order to know when
  // to re-mount the page when pushing a new route (done in NavBar).
  const {query} = useRouter()
  const key = (query.projectId ? query.projectId : query.orgId ?? '').toString()

  const projectId = query.projectId as string | undefined
  const orgId = query.orgId as string | undefined

  const {currentPaymentState = []} = useCurrentScopeContext()
  const {currentBillingAdminStatus = new Set<string>()} = useCurrentScopeContext()

  const [layoutPortal, setLayoutPortal] = useState<React.ReactNode | null>(null)

  const addPortal = useCallback((portal: React.ReactNode) => setLayoutPortal(portal), [])
  const removePortal = useCallback(() => setLayoutPortal(null), [])

  return (
    <PortalContext.Provider value={{addPortal, removePortal}}>
      <PageHead />

      <Container>
        <Layer zOffset={200} style={{position: 'sticky', top: 0}} as="header">
          <AnnouncementBannerContainer />
          <ArrearsBannerContainer
            currentPaymentState={currentPaymentState}
            organizationsWeAreBillingAdminsFor={currentBillingAdminStatus}
          />
          <CompletePaymentBanner organizationId={orgId} projectId={projectId} />
          <Navbar disableNav={disableNav} />
        </Layer>

        <Main forwardedAs="main">
          <Box key={key} paddingTop={[3, 4, 5]} paddingBottom={[5, 6, 7]}>
            {children}
          </Box>

          {layoutPortal}
        </Main>

        <Footer />
      </Container>
    </PortalContext.Provider>
  )
}
