import React from 'react'
import {BookIcon, CommentIcon, EarthGlobeIcon, CodeIcon} from '@sanity/icons'
import {LinkList} from '../general'
import {OverviewModule} from './overviewModule'

const helpItems = [
  {
    title: 'Learn Sanity',
    description: 'Courses, videos, and best-practice examples',
    url: `https://www.${process.env.host}/docs/getting-started-with-sanity`,
    icon: <BookIcon />,
  },
  {
    title: 'Browse the docs',
    description: 'Developer documentation and API references',
    url: `https://www.${process.env.host}/docs/getting-started-with-sanity`,
    icon: <CodeIcon />,
  },
  {
    title: 'Find resources',
    description: 'Templates, plugins, code examples, and more',
    url: `https://www.${process.env.host}/exchange`,
    icon: <EarthGlobeIcon />,
  },
  {
    title: 'Join the community',
    description: 'Get help from the Sanity team and other developers',
    url: 'https://slack.sanity.io/',
    icon: <CommentIcon />,
  },
]

export function HelpModule() {
  return (
    <OverviewModule title="Help & support">
      <LinkList items={helpItems} />
    </OverviewModule>
  )
}
