import {useProjectType, useProjectUserRolesList} from '@/context/index'
import {useOrganizationCanPay} from '@/data/organizations'
import {useProjectSubscription} from '@/data/projects/useProjectSubscription'
import {Organization, Project, ProjectInvitation} from '@/types/index'
import {MenuSelect, MenuSelectOption, TableCell, TableRow} from '@/ui/index'
import {emailPattern} from '@/utils/form'
import {TrashIcon} from '@sanity/icons'
import {Button, Card, Text, TextInput} from '@sanity/ui'
import {debounce} from 'lodash'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {Controller, get, useFormContext} from 'react-hook-form'
import styled from 'styled-components'
import {ErrorTooltip} from './errorTooltip'

import {useProjectInvitations} from '@/data/invitations'
import {useUsers} from '@/data/users/useUsers'
import {filterEmptyArray, formatPrice} from '@/utils/general'
import {canPayOverage, doesInviteIncurNonAdminOverage, getProjectMemberState} from '@/utils/usage'

type Props = {
  index: number
  email: string
  project: Project
  org: Organization
  role?: string
  id: string
  onDelete?: (id: number) => void
  borderBottom?: boolean
}

const InputWrapper = styled.div`
  margin-right: 8px;
  width: calc(100% - 32px);

  @media (max-width: ${({theme}) => theme.sanity.media[1]}px) {
    width: calc(100% - 32px);
  }
`

export const InvitationRowComponent = ({
  email,
  role: currentRole,
  index,
  project,
  org,
  onDelete,
  borderBottom,
}: Props) => {
  const {data: subscription} = useProjectSubscription(project?.id)
  const {data: canPay} = useOrganizationCanPay(org?.id)

  const projectType = useProjectType(project)
  const memberState = getProjectMemberState(project, projectType, subscription?.resources, org)
  const hasQuota = memberState.billable < memberState.quota
  const hasOverage = memberState.billable < memberState.max
  const canPayOverageSeat = canPayOverage(subscription, org, canPay)

  const {roles} = useProjectUserRolesList(project.id)

  const [emailWarning, setEmailWarning] = useState<string>('')
  const {data: pendingInvitations = []} = useProjectInvitations(project.id)
  const defaultValueRole =
    roles.find((rol) => rol.name === 'administrator')?.name || roles[roles.length - 1]?.name
  const [selectedRole, setSelectedRole] = useState<string | undefined>(
    currentRole || defaultValueRole
  )
  const {
    control,
    formState: {errors},
    getValues,
    setValue,
    watch,
  } = useFormContext()
  const firstRoleName = roles[0]?.name
  const roleError = get(errors, `invitations[${index}].role.message`)
  const invalidEmailMessage = `Incorrect email address`
  const memberIds = useMemo(
    () => project.members.filter((m) => !m.isRobot).map((m) => m.id),
    [project.members]
  )
  const {data: users = []} = useUsers(memberIds)

  const handleDelete = useCallback(() => {
    if (!onDelete) return
    onDelete(index)
  }, [index, onDelete])
  const checkIfEmailIsNotAlreadyAdded = useCallback(
    (matchEmail: string) => {
      const isExistingMember = users.some(
        (user) => user?.email && user.email.toLowerCase() === matchEmail.toLowerCase()
      )
      const {invitations = []} = getValues()

      if (isExistingMember) {
        return `${matchEmail} is already a member of this project`
      }

      return (
        invitations.filter(
          (invite: ProjectInvitation, matchIndex: number) =>
            matchIndex !== index && invite.email.toLowerCase() === matchEmail.toLowerCase()
        ).length === 0 || 'Email already added'
      )
    },
    [users, getValues, index]
  )

  const roleOptions: MenuSelectOption[] = useMemo(() => {
    return roles
      .map((role) => ({
        value: role.name,
        label: role.title,
        description: role.description,
      }))
      .sort((a, b) => {
        if (a.value === undefined && b.value === undefined) return 0
        if (a.value === undefined) return 1
        if (b.value === undefined) return -1
        return a.value.localeCompare(b.value)
      })
  }, [roles])

  const checkInvitations = useCallback(
    (matchEmail: string) => {
      const hasPendingInvitations = pendingInvitations.some(
        (pendingInvitation) =>
          matchEmail.length > 4 &&
          pendingInvitation.email.toLowerCase() === matchEmail.toLowerCase()
      )

      if (hasPendingInvitations) {
        setEmailWarning(`Invitation to ${matchEmail} has already been sent`)
      }

      if (!hasPendingInvitations && emailWarning !== '') {
        setEmailWarning('')
      }
    },
    [emailWarning, pendingInvitations]
  )
  const debouncedCheckInvitations = debounce(checkInvitations, 500)

  const isRoleDisabled = useCallback(
    (option: MenuSelectOption) => {
      // All roles are enabled if there is quota.
      if (hasQuota) {
        return false
      }

      // All roles are enabled if there is overage and the seat can be paid for.
      if (hasOverage && canPayOverageSeat) {
        return false
      }

      // Only the "target role" is enabled if there's no more quota/overage
      // available, or the seat can't be paid for.
      switch (memberState.resource) {
        case 'non_admin_users':
          return option.value !== 'administrator'
        case 'non_viewer_users':
          return option.value !== 'viewer'
        default:
          return true
      }
    },
    [hasQuota, hasOverage, canPayOverageSeat, memberState.resource]
  )

  const emailError = get(errors, `invitations[${index}].email.message`)
  const emailErrorsAndWarnings = filterEmptyArray([emailError, emailWarning])
  const currentEmail = watch(`invitations[${index}].email`)

  const overageCost = doesInviteIncurNonAdminOverage(project, subscription?.resources, selectedRole)

  useEffect(() => {
    if ((!currentRole || currentRole.length === 0) && roles && roles.length > 0) {
      setValue(`invitations[${index}].role`, firstRoleName)
    }
  }, [currentRole, firstRoleName, index, roles, setValue])

  useEffect(() => {
    if (currentEmail && currentEmail.length > 5) {
      debouncedCheckInvitations(currentEmail)
    }
  }, [currentEmail, debouncedCheckInvitations])

  const render = useCallback(
    ({field}) => {
      const handleChange = (rolName: string | number | undefined) => {
        setSelectedRole(rolName as string | undefined)
        field.onChange(rolName)
      }
      return (
        <MenuSelect
          options={roleOptions}
          defaultValue={roleOptions.filter((opt) => opt.value === selectedRole)}
          onChange={handleChange}
          onBlur={field.onBlur}
          isOptionDisabled={isRoleDisabled}
          nosort
        />
      )
    },
    [roleOptions, selectedRole, isRoleDisabled]
  )
  return (
    <TableRow paddingY={3} borderBottom={borderBottom}>
      <TableCell>
        <InputWrapper>
          <Controller
            control={control}
            name={`invitations[${index}].email`}
            rules={{
              required: invalidEmailMessage,
              pattern: {
                value: emailPattern,
                message: invalidEmailMessage,
              },
              validate: (value) => checkIfEmailIsNotAlreadyAdded(value),
            }}
            defaultValue={email}
            render={({field}) => <TextInput {...field} inputMode="email" />}
          />
        </InputWrapper>

        {emailErrorsAndWarnings.length > 0 && <ErrorTooltip message={emailErrorsAndWarnings} />}
      </TableCell>
      <TableCell>
        {roles && (
          <Controller
            control={control}
            aria-label="Role"
            defaultValue={selectedRole}
            rules={{required: true}}
            name={`invitations[${index}].role`}
            render={render}
          />
        )}

        {roleError && <ErrorTooltip message={roleError} />}
      </TableCell>
      <TableCell $align="left">
        {overageCost !== undefined && (
          <Card border tone="positive" padding={2} radius={2}>
            <Text size={0}>{`+ ${formatPrice(overageCost)}/month`}</Text>
          </Card>
        )}
      </TableCell>
      <TableCell $align="right">
        {onDelete && (
          <Button icon={TrashIcon} padding={2} size={2} mode="bleed" onClick={handleDelete} />
        )}
      </TableCell>
    </TableRow>
  )
}
