import {useQueries, useQuery, UseQueryResult} from '@tanstack/react-query'
import {map} from 'rxjs/operators'
import {endOfMonth, isThisMonth, startOfMonth, subMonths} from 'date-fns'

import {
  Dataset,
  DatasetFeatureSkipBilling,
  Project,
  ProjectUsage,
  ResourceOptions,
  Subscription,
} from '@/types/models'
import {getResources} from '../resources'
import {useProjectSubscription} from './useProjectSubscription'
import {convertSnapshotKeysToResourceIds} from '@/utils/usage'
import {request} from '@/data/util/promiseRequest'

import {
  calculateOverageCost,
  calculateTotalOverageCost,
  getResourcesObject,
  getResourceValue,
  OVERAGE_APPROACHING_LIMIT,
} from '@/utils/index'

import {getProjectUsageKey, getProjectsUsageKey} from './cache'
import {useDatasets} from '../datasets/useDatasets'
import {useCanReadOrganization, useCanReadProject} from '../../context'
import {useCurrentUserPermissions} from '../access/useAccessPermissions'
import {useMemo} from 'react'

export const RANGE_CURRENT_MONTH = {
  fromDate: startOfMonth(new Date()),
  toDate: new Date(),
}
export const RANGE_PREVIOUS_MONTH = {
  fromDate: subMonths(startOfMonth(new Date()), 1),
  toDate: subMonths(endOfMonth(new Date()), 1),
}

const LIVE_DATA = ['users', 'datasets']
const ABSOLUTE_RESOURCES = ['users', 'datasets', 'non_admin_users']

const getLiveResourceUsage = (key: string, project: Project): number | false => {
  if (key === 'users') {
    return project.members.filter((member) => !member.isRobot).length
  }
  if (key === 'datasets' && project.datasets !== undefined) {
    return project.datasets.filter(
      (dataset) => !dataset.features?.includes(DatasetFeatureSkipBilling) && !dataset.isDeleted
    ).length
  }
  return false
}

export function hasOverage(resourceName: string, usage: number, quota: number): boolean {
  if (ABSOLUTE_RESOURCES.includes(resourceName)) {
    return quota ? usage > quota : false
  }
  return quota ? usage >= quota : false
}

export function approachingQuota(resourceName: string, usage: number, quota: number): boolean {
  if (ABSOLUTE_RESOURCES.includes(resourceName)) {
    return false
  }
  return usage && quota ? usage > quota * OVERAGE_APPROACHING_LIMIT : false
}

const getUsageInfo = (data: {
  resourceName: string
  quota: number
  usage: number
  overageAllowed: boolean
}): {approachingQuota: boolean; hasOverage: boolean; hasOverageHardLimit: boolean} => {
  const {resourceName, quota, usage, overageAllowed} = data ?? {}

  return {
    approachingQuota: approachingQuota(resourceName, usage, quota),
    hasOverage: hasOverage(resourceName, usage, quota),
    hasOverageHardLimit: overageAllowed ? false : hasOverage(resourceName, usage, quota),
  }
}

function getProjectCost(
  project: Project,
  subscription?: Subscription,
  options?: ResourceOptions
): Promise<ProjectUsage> {
  const isForCurrentMonth = options?.fromDate ? isThisMonth(options.fromDate) : false
  // Get the resource history for project

  const usage$ = getResources(project, options).pipe(
    map((data) => {
      // Calculate overages
      const {plan, resources, startedAt} = subscription || {}
      const planPrice = plan?.price || 0
      const planStartedAt = startedAt || null
      const usage = convertSnapshotKeysToResourceIds(data?.snapshot?.usage || {})
      const quota = convertSnapshotKeysToResourceIds(data?.snapshot?.quota || {})

      const resourceSummary = Object.getOwnPropertyNames(resources || {}).map((resourceName) => {
        const {overageChunkPrice, overageChunkSize, unit, name, overageAllowed, maxOverageQuota} =
          getResourceValue(resources, resourceName)
        const liveData =
          isForCurrentMonth &&
          LIVE_DATA.includes(resourceName) &&
          getLiveResourceUsage(resourceName, project)
        const currentUsage = liveData || getResourceValue(usage, resourceName)
        const currentQuota = getResourceValue(quota, resourceName)

        const usageInfo = getUsageInfo({
          resourceName: resourceName,
          quota: currentQuota,
          usage: currentUsage,
          overageAllowed: overageAllowed,
        })

        const overageCost = usageInfo.hasOverage
          ? calculateOverageCost(currentUsage, currentQuota, overageChunkSize, overageChunkPrice)
          : 0

        // Return a summary of each resource
        return {
          id: resourceName,
          name,
          usage: currentUsage,
          quota: currentQuota,
          overageChunkPrice,
          overageChunkSize,
          overageCost,
          unit,
          overageAllowed,
          maxOverageQuota,
          ...usageInfo,
        }
      })

      // calculate the total overage costs for all resources
      const totalOverage = calculateTotalOverageCost(resourceSummary)
      // get all the resources approaching quota limit
      const approachingResources = resourceSummary
        .filter((r) => r?.approachingQuota)
        .map((r) => r.id)
      const overageResources = resourceSummary.filter((r) => r?.hasOverage).map((r) => r.id)
      const overageResourcesHardLimit = resourceSummary
        .filter((r) => r?.hasOverageHardLimit)
        .map((r) => r.id)

      return {
        projectId: project.id,
        planCost: planPrice,
        planStartedAt,
        overageCost: totalOverage,
        totalCost: planPrice + totalOverage,
        resources: getResourcesObject(resourceSummary),
        history: data.history,
        snapshot: data.snapshot,
        approachingResources,
        overageResources,
        overageResourcesHardLimit,
        projectCreatedAt: project.createdAt,
        label: project.displayName,
      }
    })
  )

  return usage$.toPromise() as Promise<ProjectUsage>
}

/**
 * Enriches the project with datasets since its missing by default in the project object
 * @param project - The project to enrich
 * @param datasets - The datasets to enrich the project with
 * @returns The enriched project
 */
function enrichProjectWithDatasets(project: Project, datasets: Dataset[] | undefined): Project {
  if (!datasets || datasets.length === 0) {
    return project
  }

  project.datasets = datasets

  return project
}

export function useProjectUsage(
  project?: Project,
  options?: ResourceOptions
): UseQueryResult<ProjectUsage> {
  const {data: subscription} = useProjectSubscription(project?.id)
  const {data: datasets} = useDatasets(project?.id)
  const canReadProject = useCanReadProject(project?.id)
  const canReadOrganization = useCanReadOrganization()

  return useQuery({
    queryKey: getProjectUsageKey(project?.id, subscription?.id, options),
    queryFn: () => {
      if (!project) {
        return Promise.resolve(null)
      }

      return getProjectCost(enrichProjectWithDatasets(project, datasets), subscription, options)
    },

    staleTime: 10 * 60 * 1000,

    enabled: canReadProject || canReadOrganization,
  })
}

export function useProjectsUsage(
  projects?: Project[],
  options?: ResourceOptions
): UseQueryResult<ProjectUsage>[] {
  const {data: userPermissions} = useCurrentUserPermissions()
  const canReadOrganization = useCanReadOrganization()

  const canReadProjectDictionary = useMemo(() => {
    // If we don't have user permissions or projects then you can't read any projects
    if (!userPermissions || !projects) return {}

    return projects.reduce<Record<string, boolean>>((acc, project) => {
      const readPermissionsForProject = userPermissions.filter(
        (permission) =>
          permission.resourceType === 'project' &&
          project.id === permission.resourceId &&
          permission.name === 'sanity.project.read'
      )

      return {
        ...acc,
        [project.id]: readPermissionsForProject.length > 0,
      }
    }, {})
  }, [userPermissions, projects])

  return useQueries({
    queries: (projects || []).map((project) => {
      return {
        queryKey: getProjectsUsageKey(project.id, options),
        queryFn: async () => {
          const subscription = await request<Subscription>({
            url: `https://api.${process.env.host}/v2022-08-11/subscriptions/project/${project.id}`,
          }).then((res) => {
            if (!res.ok) {
              throw new Error(res.body.message)
            }
            return res.body
          })

          return getProjectCost(project, subscription, options)
        },
        enabled: canReadOrganization || canReadProjectDictionary[project.id],
      }
    }),
  })
}
