import {Badge as UIBadge, type BadgeProps as UIBadgeProps} from '@sanity/ui'
import {type ForwardedRef, forwardRef, type HTMLProps} from 'react'

export type BadgeProps = UIBadgeProps

export const Badge = forwardRef(function Badge(
  props: BadgeProps & HTMLProps<HTMLDivElement>,
  ref: ForwardedRef<HTMLDivElement>
) {
  const {children, ...rest} = props

  return (
    <UIBadge ref={ref} radius={2} {...rest}>
      {children}
    </UIBadge>
  )
})
