/**
 * This is a singleton to ensure we only instantiate Stripe once.
 */
import type {Stripe, StripeConstructorOptions} from '@stripe/stripe-js'
import {loadStripe as _loadStripe} from '@stripe/stripe-js'
import getConfig from 'next/config'
import type {Options as RetryOptions} from 'p-retry'
import pRetry from 'p-retry'

const {publicRuntimeConfig} = getConfig()

let stripePromise: Promise<Stripe | null>

const loadStripe = (
  publishableKey: string,
  options?: StripeConstructorOptions & {
    retryOptions?: RetryOptions
  }
) => {
  const {retryOptions, ...rest} = options || {}
  return pRetry(() => _loadStripe(publishableKey, rest), retryOptions)
}

export default function getStripe(): Promise<Stripe | null> {
  if (!stripePromise) {
    stripePromise = loadStripe(publicRuntimeConfig.stripeApiKey, {
      retryOptions: {
        onFailedAttempt(error) {
          console.error(
            `Failed attempt to load Stripe - attempt ${error.attemptNumber} - ${error.message}`
          )
        },
        retries: 3,
        minTimeout: 2000,
        maxTimeout: 10000,
      },
    })
  }

  return stripePromise
}
