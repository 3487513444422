import React, {useState} from 'react'
import {useStripe, useElements, CardNumberElement} from '@stripe/react-stripe-js'
import {useToast} from '@sanity/ui'
import {EditContainer} from './editContainer'
import {CardForm} from './stripe/cardForm'
import {CustomerData, Organization} from '@/types/index'
import {createSetupIntent, setPaymentSourceForCustomer} from '@/data/index'
import {useReCaptchaContext} from '@/ui/recaptcha/ReCaptchaContext'
import {doWithRecaptcha} from '@/ui/recaptcha/recaptchaUtils'
import {ReCaptchaInfo} from '@/ui/recaptcha/ReCaptchaInfo'
import {useCacheInvalidation} from '@/data/base/useCacheInvalidation'

type Props = {
  organization: Organization
  onCancel: () => void
  onSave: (data: CustomerData) => void
}

export function PaymentDetailsInput({onCancel, onSave, organization}: Props) {
  const [loading, setLoading] = useState(false)
  const toast = useToast()
  const stripe = useStripe()
  const elements = useElements()
  const {reCaptchaScript, enabled: recaptchaEnabled} = useReCaptchaContext()
  const {invalidateCustomerData} = useCacheInvalidation()

  const handleSave = async (e: React.SyntheticEvent) => {
    setLoading(true)

    e.preventDefault()

    // Block native form submission.
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }

    try {
      const clientSecret = await doWithRecaptcha(
        recaptchaEnabled,
        reCaptchaScript,
        'setup_intent',
        (recaptchaToken) => {
          return createSetupIntent(organization.id, recaptchaToken)
        }
      )

      // Get a reference to a mounted CardElement. Elements knows how
      // to find your CardElement because there can only ever be one of
      // each type of element.
      const cardElement = elements.getElement(CardNumberElement)

      if (!cardElement || !clientSecret) {
        throw new Error(
          'There was a technical problem with Stripe. Please try again by reloading the page.'
        )
      }

      // Use your card Element with other Stripe.js APIs
      const {error, setupIntent} = await stripe.confirmCardSetup(clientSecret, {
        // eslint-disable-next-line camelcase
        payment_method: {
          card: cardElement,
        },
      })

      if (error) {
        const {message} = error

        throw new Error(message)
      }

      if (!setupIntent?.payment_method) {
        throw new Error(`There was a problem saving your card. Please contact support.`)
      }

      const paymentSourceUpdated = await doWithRecaptcha(
        recaptchaEnabled,
        reCaptchaScript,
        'payment_source',
        (recaptchaToken) => {
          return setPaymentSourceForCustomer(
            organization.id,
            setupIntent.payment_method!,
            recaptchaToken
          )
        }
      )

      onSave(paymentSourceUpdated!)
      invalidateCustomerData(organization.id)

      toast.push({
        title: 'Payment details successfully added.',
        status: 'success',
      })
    } catch (err) {
      toast.push({
        title: (err as Error)?.message,
        status: 'error',
      })
      setLoading(false)
    }
  }

  function handleCancel() {
    onCancel()
  }

  return (
    <EditContainer onSave={handleSave} onCancel={handleCancel} loading={loading}>
      <CardForm />
      <ReCaptchaInfo />
    </EditContainer>
  )
}
