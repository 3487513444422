import React, {useCallback} from 'react'
import {Stack, Box, Flex, useToast} from '@sanity/ui'
import {useProjectData} from '@/data/projects/useProjectData'
import {useRequiredProjectPermissions} from '@/context/index'
import {TabSectionHeader} from '@/components/tabs'
import {EditableField} from '@/components/form'
import {Project} from '@/types/models'

type Props = {
  title: string
  description: string
  project: Project
}

export function ProjectDetails({title, description, project}: Props) {
  const toast = useToast()
  const {data: projectData, patchProjectData} = useProjectData(project)
  const canManageProject = useRequiredProjectPermissions([
    {permissionName: 'sanity.project', grantName: 'update'},
  ])

  const handleSaveName = useCallback(
    (newName: string) => {
      patchProjectData(
        {displayName: newName},
        {
          onSuccess: () => {
            toast.push({
              title: 'Project name updated',
              status: 'success',
            })
          },
          onError: (error) => {
            toast.push({
              title: 'Failed to update project name',
              status: 'error',
              description: error.message,
            })
          },
        }
      )
    },
    [patchProjectData, toast]
  )

  const onSaveName = useCallback((newName: string) => handleSaveName(newName), [handleSaveName])

  return (
    <>
      <Box marginBottom={4}>
        <TabSectionHeader title={title} description={description} />
      </Box>
      <Stack space={[5, 5, 6]}>
        <Flex>
          <Box flex={1}>
            <EditableField
              title="Name"
              description="The project name is used to identify the project within Sanity."
              value={projectData?.displayName || ''}
              onSave={onSaveName}
              disabled={!canManageProject}
            />
          </Box>
        </Flex>
      </Stack>
    </>
  )
}
