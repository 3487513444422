import {useOrganizationCanPay} from '@/data/organizations'
import {BillingDetails, PaymentMethod} from '@/ui/views/org/settings'
import {CardDeclinedWarning} from '@/ui/views/wizards/common/cardDeclinedWarning'
import {sendAmplitudeTrackingEvent} from '@/utils/tracking'
import {Box, Flex, Heading} from '@sanity/ui'
import {useCallback, useMemo} from 'react'
import {useWizardStore} from '../store'
import {WizardParams, WizardStep, WizardStepProps} from '../types'

function AddBillingDetailsStep(props: WizardStepProps) {
  const {params} = props
  const {org, loading} = params
  const {dispatch, state} = useWizardStore()
  const {data: canPay} = useOrganizationCanPay(org?.id)

  const handleCardAdded = useCallback(() => {
    dispatch({type: 'setCardAdded', value: true})
  }, [dispatch])

  const handleCardRemoved = useCallback(() => {
    dispatch({type: 'setCardAdded', value: false})
  }, [dispatch])

  if (loading || !org) {
    return null
  }

  return (
    <Box>
      {state.existingCardDeclined ? (
        <>
          <Box marginBottom={5}>
            <CardDeclinedWarning />
          </Box>
          <Heading as="h1">Updates billing details to {org.name}</Heading>
        </>
      ) : (
        <Heading as="h1">Add billing details to {org.name}</Heading>
      )}
      <Box marginTop={5} marginBottom={4}>
        <Heading as="h2" size={1}>
          Payment details
        </Heading>
      </Box>
      <PaymentMethod
        title={''}
        description={''}
        org={org}
        mode={canPay?.status === true ? 'default' : 'edit'}
        onCardAdded={handleCardAdded}
        onCardRemoved={handleCardRemoved}
      />
      <Flex marginTop={5} style={{cursor: 'default'}}>
        <Heading as="h2" size={1}>
          Billing details
        </Heading>
      </Flex>
      <Box>{org.id !== 'personal' && <BillingDetails org={org} title={''} description={''} />}</Box>
    </Box>
  )
}

export function useAddBillingDetailsStep(params: WizardParams): WizardStep | null {
  const {org, project} = params
  const {state} = useWizardStore()
  const {data: canPaySource} = useOrganizationCanPay(org?.id)
  const {data: canPayTarget} = useOrganizationCanPay(state.transferToOrg?.id)
  const hidden = useMemo(
    () =>
      !state.cardAdded &&
      !state.existingCardDeclined &&
      !state.createOrg &&
      ((!state.transferToOrg && canPaySource?.status === true) || canPayTarget?.status === true),
    [
      canPaySource?.status,
      canPayTarget?.status,
      state.cardAdded,
      state.createOrg,
      state.transferToOrg,
      state.existingCardDeclined,
    ]
  )
  if (hidden) {
    return null
  }
  return {
    id: 'add-billing-details',
    title: 'Add billing details',
    completed: state.cardAdded || canPaySource?.status === true,
    component: AddBillingDetailsStep,
    disabled: canPaySource?.status !== true,
    action: {
      label: <>Continue</>,
    },
    handle() {
      sendAmplitudeTrackingEvent('Switch Plan Step Completed', project?.id, org?.id, {
        stepName: 'Add billing details',
      })
      return Promise.resolve()
    },
  }
}
