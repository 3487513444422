import React, {useCallback, useEffect, useState} from 'react'
import {
  Box,
  Button,
  Card,
  CardTone,
  Flex,
  Inline,
  Stack,
  Text,
  ToastParams,
  useToast,
} from '@sanity/ui'
import {CheckmarkCircleIcon, CircleIcon, Icon, IconSymbol} from '@sanity/icons'
import {formatPrice} from '@/utils/general'
import {ConfirmDialog, ExternalLink, Illustration, PermissionTooltip} from '@/ui/index'
import {Subscription} from '@/types/index'
import {AddOnCopy} from '@/components/project/plan/addonCopyConfig'
import {useConfig, useCurrentScopeContext} from '@/context/index'
import {useOrganizationCanPay, useSubscriptionFeaturesUpdate} from '@/data/index'
import {isSubscriptionFeature, getSubscriptionCustomFeatures} from '@/utils/subscription'
import {sendAmplitudeTrackingEvent} from '@/utils/tracking'
import {ButtonWithExtendedTones} from '../../general/ButtonWithExtendedTones'
import {useRouter} from 'next/router'

function MessageCard({message, tone}: {message: JSX.Element; tone?: CardTone}) {
  let symbol: IconSymbol

  switch (tone) {
    case 'critical':
      symbol = 'error-outline'
      break
    case 'caution':
      symbol = 'warning-outline'
      break
    case 'positive':
      symbol = 'info-outline'
      break
    default:
      symbol = 'info-outline'
  }

  return (
    <Card padding={3} radius={2} tone={tone} border>
      <Flex align="center">
        <Box paddingRight={4}>
          <Text muted>
            <Icon symbol={symbol} />
          </Text>
        </Box>
        <Box>{message}</Box>
      </Flex>
    </Card>
  )
}

type Props = {
  addon: AddOnCopy
  subscription: Subscription
  hasPermission?: boolean
  upsell?: boolean
  onUpdate?: () => void
  onUpsell?: () => void
}

export function AddOnCard({
  addon,
  subscription,
  upsell = false,
  hasPermission = false,
  onUpdate,
  onUpsell,
}: Props) {
  const {
    featureId,
    name,
    price,
    priceTerms,
    short,
    description,
    docsUrl,
    downgradeWarning,
    drawing,
    enabledToast,
  } = addon
  const [showDetails, setShowDetails] = useState(false)
  const {baseUrl} = useConfig()
  const {query, replace} = useRouter()
  const toast = useToast()
  const {org} = useCurrentScopeContext()
  const {data: canPay} = useOrganizationCanPay(org?.id)
  const {
    mutate: setProjectSubscriptionFeatures,
    isPending: loading,
    error,
  } = useSubscriptionFeaturesUpdate(subscription.projectId)

  useEffect(() => {
    // Look for query parameter "addon" with value equal
    // to featureId without the trailing date string (i.e. ?addon=additional-datasets)
    if (query.addon === featureId.replace(/-\d{4}-\d{2}-\d{2}/g, '')) setShowDetails(true)
  }, [query, featureId])

  const toggleDetails = useCallback(() => {
    sendAmplitudeTrackingEvent('Add-On CTA Clicked', subscription.projectId, undefined, {
      addOnName: name,
    })
    // If we're closing the add-on modal (showDetails currently true),
    // clear the query parameter if present
    if (showDetails) {
      const {addon: _, ...otherParams} = query
      replace({
        query: otherParams,
      })
    }
    setShowDetails(!showDetails)
  }, [showDetails, name, subscription.projectId])

  const hasAddOn = isSubscriptionFeature(subscription, featureId)

  const updateSubscriptionFeatures = useCallback(async () => {
    let features = getSubscriptionCustomFeatures(subscription)
    if (hasAddOn) {
      sendAmplitudeTrackingEvent('Add-On Disabled', subscription.projectId, undefined, {
        addOnName: name,
      })
      features = features.filter((feature) => feature !== featureId)
    } else {
      sendAmplitudeTrackingEvent('Add-On Enabled', subscription.projectId, undefined, {
        addOnName: name,
      })
      features.push(featureId)
    }

    setProjectSubscriptionFeatures(features, {
      onSuccess: () => {
        const toastMessage: ToastParams =
          !hasAddOn && enabledToast
            ? enabledToast
            : {
                description: `${name} Add-on ${hasAddOn ? 'disabled' : 'enabled'}`,
                status: 'success',
              }
        toast.push(toastMessage)

        onUpdate?.()
        toggleDetails()
      },
    })
  }, [
    hasAddOn,
    name,
    enabledToast,
    toast,
    toggleDetails,
    featureId,
    setProjectSubscriptionFeatures,
    subscription,
    onUpdate,
  ])

  const handleOnUpsell = useCallback(() => {
    toggleDetails()
    if (onUpsell) {
      sendAmplitudeTrackingEvent('Switch Plan CTA Clicked', subscription.projectId, org?.id, {
        source: 'add_on_modal',
        plan: 'growth',
        hasValidBilling: canPay?.status,
      })
      onUpsell()
    }
  }, [toggleDetails, onUpsell, org, canPay, subscription.projectId])

  return (
    <>
      <Card border padding={3} radius={2}>
        <Flex direction="column" justify="space-between" style={{height: '100%'}}>
          <Stack space={3} flex={1}>
            <Box paddingY={2} paddingBottom={1}>
              <Flex justify="space-between">
                <Text weight="semibold" size={2}>
                  {name}
                </Text>

                <Stack space={2}>
                  <Text align="right">+ {formatPrice(price)}</Text>
                  <Text align="right" size={1} muted>
                    {priceTerms}
                  </Text>
                </Stack>
              </Flex>
            </Box>

            <Card borderBottom />

            <Box paddingY={2}>
              <Text muted size={1}>
                {short}
              </Text>
            </Box>
          </Stack>

          <Box paddingTop={4}>
            {upsell && (
              <Card style={{width: '100%'}} as="a" onClick={toggleDetails}>
                <Flex justify="center" align="center">
                  <Box padding={3}>
                    <Text size={2} weight="semibold" muted>
                      See details
                    </Text>
                  </Box>
                </Flex>
              </Card>
            )}
            {!upsell && hasAddOn && (
              <Card style={{width: '100%'}} as="a" onClick={toggleDetails}>
                <Flex justify="center" align="center">
                  <Box padding={3}>
                    <Inline space={2}>
                      <Text size={2} muted>
                        <CheckmarkCircleIcon />
                      </Text>
                      <Text size={2} weight="semibold" muted>
                        Enabled
                      </Text>
                    </Inline>
                  </Box>
                </Flex>
              </Card>
            )}
            {!upsell && !hasAddOn && (
              <ButtonWithExtendedTones
                tone="purple"
                style={{width: '100%'}}
                mode="ghost"
                onClick={toggleDetails}
                text="See details"
              />
            )}
          </Box>
        </Flex>
      </Card>
      {showDetails && (
        <ConfirmDialog
          header={`${name}`}
          id={`toggle-${featureId}}`}
          onClose={toggleDetails}
          confirmButton={
            <>
              {!upsell && hasAddOn && (
                <PermissionTooltip disabled={hasPermission}>
                  <Button
                    style={{width: '100%'}}
                    tone="critical"
                    mode="ghost"
                    text="Remove add-on"
                    onClick={updateSubscriptionFeatures}
                    disabled={!hasPermission}
                    loading={loading}
                  />
                </PermissionTooltip>
              )}
              {!upsell && !hasAddOn && (
                <PermissionTooltip disabled={hasPermission}>
                  <Button
                    style={{width: '100%'}}
                    tone="primary"
                    text="Enable add-on"
                    onClick={updateSubscriptionFeatures}
                    disabled={!hasPermission}
                    loading={loading}
                  />
                </PermissionTooltip>
              )}
              {upsell && (
                <Button
                  style={{width: '100%'}}
                  tone="primary"
                  text="Upgrade to Growth"
                  onClick={handleOnUpsell}
                  loading={loading}
                />
              )}
            </>
          }
        >
          <Stack space={4} paddingX={2}>
            <Flex justify="center">
              <Box>
                <Illustration drawing={drawing} />
              </Box>
            </Flex>
            <Box>
              <Stack space={4}>
                <Box paddingBottom={1}>
                  <Text size={2} weight="semibold">{`Add-on: ${name}`}</Text>
                </Box>
                {description.map((desc, i) =>
                  typeof desc === 'string' ? (
                    // eslint-disable-next-line react/no-array-index-key
                    <Text key={`${i}-desc`} size={1}>
                      {desc}
                    </Text>
                  ) : (
                    <Stack as="ul" space={4}>
                      {desc.map((item, j) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Flex as="li" align="center" key={`${j}-desc-item`} paddingLeft={2} gap={2}>
                          <CircleIcon fill="black" height={8} width={8} />
                          <Text size={1}>{item}</Text>
                        </Flex>
                      ))}
                    </Stack>
                  )
                )}
                <Box paddingTop={1}>
                  <Text size={1}>
                    <ExternalLink url={`${baseUrl}${docsUrl}`} text={'Learn more'} />
                  </Text>
                </Box>
              </Stack>
            </Box>
            <Card borderBottom />
            <Card>
              <Stack space={3}>
                <Inline space={1}>
                  <Text>+</Text>
                  <Text weight="semibold">{formatPrice(price)}</Text>
                  <Text size={1}>{priceTerms}</Text>
                </Inline>
                <Text muted size={1}>
                  The add-on price will be added to your monthly bill.
                </Text>
              </Stack>
            </Card>
            {!upsell && hasAddOn && (
              <MessageCard
                message={
                  <Inline space={1}>
                    <Text size={1} muted>
                      {downgradeWarning}
                    </Text>
                  </Inline>
                }
                tone="caution"
              />
            )}
            {upsell && (
              <MessageCard
                message={
                  <Inline space={1}>
                    <Text size={1} muted>
                      Add-ons are only available on the Growth plan.{' '}
                      <strong>Upgrade your plan</strong> to enable add-ons or get in touch to
                      explore Enterprise plans.
                    </Text>
                  </Inline>
                }
                tone="primary"
              />
            )}
            {/* The error message should always be at the bottom of the stack */}
            {error && (
              <MessageCard message={<Text size={1}>{error?.message}</Text>} tone="critical" />
            )}
          </Stack>
        </ConfirmDialog>
      )}
    </>
  )
}
