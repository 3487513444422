import {useCurrentScopeContext} from '@/context/index'
import {OrgHeader, ProjectHeader, TabNav, TabSidebar} from '@/ui/index'
import {Tab} from '@/types/index'
import {Box, Container, Grid, Stack} from '@sanity/ui'
import Head from 'next/head'
import React, {useMemo} from 'react'
import {DefaultLayout} from './defaultLayout'
import {useTabs} from '@/ui/views/tabs/hooks/useTabs'

interface Props {
  activeTab?: Tab
  activeSectionPath: string | null
  activeHeadingPath: string | null
  tabs?: Tab[]
  children?: React.ReactNode
  hideSidebar?: boolean
}

export function DefaultTabLayout({
  tabs: tabsProp,
  children,
  activeTab,
  activeSectionPath,
  activeHeadingPath,
  hideSidebar = false,
}: Props) {
  const headings = activeTab?.headings || []
  const tabHasSidebar = !activeTab?.disableNav && headings.length > 0

  // Create the page titles based on project name or org name, with the active tab
  const scope = useCurrentScopeContext()
  const {org, project} = scope || {}
  const pageTitle = useMemo(() => {
    if (!project && !org) return 'Sanity.io'

    return `${project?.displayName || org?.name}${activeTab ? ` – ${activeTab.title}` : ''} | Sanity.io`
  }, [activeTab, org, project])

  const header = project ? <ProjectHeader project={project} org={org} /> : <OrgHeader org={org} />
  const tabStatus = useTabs()
  const tabs = tabStatus.tabs
  const currentTabs = tabsProp || tabs
  const showSidebar = tabHasSidebar && !hideSidebar

  return (
    <DefaultLayout>
      <Head>
        <title>{pageTitle}</title>
      </Head>
      <Stack space={[2, 3, 5]}>
        <Container width={3}>
          <Box paddingX={[2, 3, 4]}>{header}</Box>
        </Container>

        <TabNav currentTab={activeTab} tabs={currentTabs} />

        <Container width={3}>
          <Box paddingX={[3, 3, 4]}>
            <Grid gap={showSidebar ? [2, 2, 2, 6] : 1} columns={showSidebar ? [1, 1, 1, 4] : 1}>
              {showSidebar && activeTab && (
                <Box columnStart={1} columnEnd={2}>
                  <TabSidebar
                    tab={activeTab}
                    activeHeadingPath={activeHeadingPath}
                    activeSectionPath={activeSectionPath}
                  />
                </Box>
              )}
              <Box
                columnStart={showSidebar ? [1, 1, 1, 2] : 1}
                columnEnd={-1}
                marginTop={[4, 4, 4, 0]}
              >
                <Container
                  style={{
                    margin: 0,
                    maxWidth: '100%',
                  }}
                >
                  {children}
                </Container>
              </Box>
            </Grid>
          </Box>
        </Container>
      </Stack>
    </DefaultLayout>
  )
}
