import {LoadingOverlay} from '@/components/checkout/components'

import {DowngradeSurveyFormData} from '@/components/checkout/types'

import {zodResolver} from '@hookform/resolvers/zod'
import {Button, Flex, Stack, Text} from '@sanity/ui'

import {FormProvider, useForm} from 'react-hook-form'
import {FORM_ERRORS} from './constants'
import {lastValueFrom} from 'rxjs'
import {switchProjectPlan} from '@/data/index'
import {useSwitchPlanStore} from './store'
import {useCurrentScopeContext} from '@/context/index'
import {DowngradeSurveyFieldset} from './fieldsets/downgradeSurveyFieldset'

interface Props {
  onSubmit: (formData: DowngradeSurveyFormData) => void
  onSubmitError: (title: string, message: string) => void
  onClose: () => void
  skipSurvey: boolean
}

export function DowngradeSurvey({onSubmit, onSubmitError, onClose, skipSurvey}: Props) {
  const {state} = useSwitchPlanStore()
  const {project} = useCurrentScopeContext()

  const form = useForm<DowngradeSurveyFormData>({
    defaultValues: {
      reason: skipSurvey ? 'skip-survey' : undefined,
    },
    resolver: zodResolver(DowngradeSurveyFormData),
  })

  const pending = false

  const onSubmitHandler = async (formData: DowngradeSurveyFormData) => {
    const planSwitch = await lastValueFrom(switchProjectPlan(project!.id, state.plan!.id, []))
    if (planSwitch?.status === 'incomplete') {
      onSubmitError('Failed to switch plan', 'Please try again later')
      throw new Error(FORM_ERRORS.generic)
    } else {
      onSubmit(formData)
    }
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmitHandler)} aria-busy={pending}>
        <Stack space={5}>
          {!skipSurvey && (
            <>
              <Text weight="semibold">Can you let us know why you're downgrading?</Text>
              <DowngradeSurveyFieldset />
            </>
          )}

          <Flex gap={3}>
            <Button width="fill" padding={3} mode="bleed" text="Cancel" onClick={onClose} />
            <Button
              width="fill"
              padding={3}
              text={skipSurvey ? 'Switch plan' : 'Downgrade'}
              type="submit"
              disabled={!form.formState.isValid}
            />
          </Flex>
        </Stack>

        <LoadingOverlay show={form.formState.isSubmitting} />
      </form>
    </FormProvider>
  )
}
