import {Text, Card, Stack} from '@sanity/ui'
import {useSwitchPlanStore} from '@/components/checkout/store'
import {useState} from 'react'
import {usePreviewProjectPlan} from '@/data/plan/usePreviewPlan'
import {calculateTotalPrice, getNonViewerUsers} from '@/utils/usage'
import {useSwitchPlanOverages} from '../hooks/useSwitchPlanOverages'
import {formatUSD} from '@/utils/number'
import {useCurrentScopeContext} from '@/context/useCurrentScopeContext'

export function Footer() {
  const {state} = useSwitchPlanStore()
  const plan = state.plan!

  const scope = useCurrentScopeContext()
  const {data: planPreview} = usePreviewProjectPlan(plan?.id)

  const {overages} = useSwitchPlanOverages(scope!.project!, state!.plan!)

  /* This case only targets the Growth plan, which only bills per non-viewer users */
  const [seatConsumers] = useState(getNonViewerUsers(scope?.project))
  const seatCount = seatConsumers?.length

  /* We bill the 1st of each month, so we show the price that will be billed now */
  const priceNow = calculateTotalPrice({...plan, price: planPreview?.priceNow ?? 0}, seatCount)

  return (
    <Card hidden={Boolean(overages.length)}>
      <Stack space={4}>
        <Text size={0} muted align="center">
          {formatUSD(priceNow)} + tax will be charged now, with the full amount billed monthly on
          the 1st.
          <br />
          Protected by reCAPTCHA and the Google{' '}
          <a href="https://policies.google.com/privacy" target="_blank">
            Privacy Policy
          </a>{' '}
          and{' '}
          <a href="https://policies.google.com/terms" target="_blank">
            Terms of Service
          </a>{' '}
          apply.
        </Text>
      </Stack>
    </Card>
  )
}
