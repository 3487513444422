import styled from 'styled-components'
import {Button, Card, Flex, Grid, Text} from '@sanity/ui'
import {Lesson, updateProjectOnboardingData} from '@/data/index'
import CircularProgressBar from './ProgressBar'
import {useGettingStartedStore} from '../store'
import {useRouter} from 'next/router'
import {useRoutePath} from '@/context/useRoutePathContext'
import {AnimatePresence, motion} from 'framer-motion'
import {useConfirmDialog} from '@/components/dialog/useConfirmDialog'
import {sendAmplitudeTrackingEvent} from '@/utils/tracking'
import {useCurrentScopeContext} from '@/context/useCurrentScopeContext'

const FooterCard = styled(Card)`
  z-index: 100;
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
`

const Container = styled(motion(Grid))`
  --container-max-width: ${({theme}) => theme.sanity.media[2]}px;

  width: 100%;
  margin-inline: auto;

  @media (max-width: ${({theme}) => theme.sanity.media[1]}px) {
    grid-template-columns: 1fr !important;
    max-width: none !important;
  }
`

const Inner = styled(Flex)`
  max-width: 60rem;

  @media (min-width: ${({theme}) => theme.sanity.media[1]}px) {
    grid-column-start: 2;
  }
`

const Title = styled(motion(Text))`
  flex: 1;
`

const ProgressGrid = styled(Grid)`
  grid-template-columns: 1fr auto;
  column-gap: 0.5rem;
  align-items: center;
  grid-template-areas:
    'percentage spinner'
    'steps spinner';
`

const ProgressContainer = styled.div`
  grid-area: spinner;
`

const Percentage = styled(Text)`
  grid-area: percentage;
  text-align: right;
`

const Steps = styled(Text)`
  grid-area: steps;
`

const CompleteButton = styled(Button)`
  @media (min-width: ${({theme}) => theme.sanity.media[1]}px) {
    margin-left: auto;
  }
`

const containerProps = {
  initial: {opacity: 0, y: 10, filter: 'blur(2px)'},
  animate: {opacity: 1, y: 0, filter: 'blur(0)'},
  exit: {opacity: 0, y: -10, filter: 'blur(2px)'},
  gapY: 3,
  paddingX: 5,
  paddingY: 3,
  transition: {duration: 0.2},
}

const DefaultContainer = ({children}) => (
  <Container
    {...containerProps}
    style={{
      maxWidth: 'none',
      display: 'grid',
      gridTemplateColumns: '1fr minmax(0, var(--container-max-width)) 1fr',
    }}
  >
    {children}
  </Container>
)

const CompletedContainer = ({children}) => (
  <Container
    {...containerProps}
    style={{
      maxWidth: 'var(--container-max-width)',
      display: 'grid',
      gridTemplateColumns: 'auto 1fr auto',
    }}
  >
    {children}
  </Container>
)

const AnimatedContainer = ({isCompleted, children}) => (
  <AnimatePresence mode="wait">
    {isCompleted ? (
      <CompletedContainer key="completed">{children}</CompletedContainer>
    ) : (
      <DefaultContainer key="default">{children}</DefaultContainer>
    )}
  </AnimatePresence>
)

interface Props {
  lessons: Lesson[]
  currentIndex?: number
  numberOfCompletedLessons: number
}

export function ProgressFooter({lessons, currentIndex, numberOfCompletedLessons}: Props) {
  const router = useRouter()
  const {projectId, org} = useCurrentScopeContext()
  const {dispatch} = useGettingStartedStore()
  const {showDialog, Dialog, hideDialog} = useConfirmDialog()
  const currentLesson = typeof currentIndex === 'number' ? lessons[currentIndex] : null
  const isCompleted = numberOfCompletedLessons === lessons.length
  const lessonPercentage = Math.round((numberOfCompletedLessons / lessons.length) * 100)
  const {basePath} = useRoutePath()

  const handleDismiss = () => {
    sendAmplitudeTrackingEvent('Getting Started Page Dismissed', projectId, org?.id, {
      persona: 'developer',
    })
    dispatch({type: 'getStarted/setDismissed', value: true})
    updateProjectOnboardingData(projectId!, {disabled: true})
    router.push(basePath)
  }

  return (
    <>
      <FooterCard borderTop>
        <AnimatedContainer isCompleted={isCompleted}>
          <Inner align="center" gap={3} marginX={[0, 0, isCompleted ? 0 : 5]}>
            <AnimatePresence mode="wait" initial={false}>
              <Title
                key={currentLesson?.shortTitle || currentLesson?.title || 'complete'}
                weight="semibold"
                size={1}
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
              >
                {isCompleted
                  ? 'Project setup complete!'
                  : currentLesson?.shortTitle || currentLesson?.title}
              </Title>
            </AnimatePresence>

            <ProgressGrid style={{opacity: isCompleted ? 0 : 1}}>
              <Percentage weight="semibold" size={0}>
                {lessonPercentage}%
              </Percentage>
              <Steps weight="semibold" size={0} muted>
                {numberOfCompletedLessons}/{lessons.length} steps
              </Steps>
              <ProgressContainer>
                <CircularProgressBar percentage={lessonPercentage} />
              </ProgressContainer>
            </ProgressGrid>
          </Inner>

          <CompleteButton
            mode={isCompleted ? 'default' : 'bleed'}
            padding={2}
            text="Dismiss “Getting started”"
            onClick={showDialog}
          />
        </AnimatedContainer>
      </FooterCard>
      <Dialog
        id="dismiss-getting-started"
        header='Dismiss "Getting started"'
        description='Are you sure you want to hide the "Getting started" section for all members of this project?'
        onClose={hideDialog}
        onConfirm={handleDismiss}
        confirmButtonProps={{tone: 'primary', text: 'Dismiss'}}
      />
    </>
  )
}
