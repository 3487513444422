import React, {useCallback, useRef, useState} from 'react'
import {
  format,
  subYears,
  getYear,
  subMonths,
  getMonth,
  startOfYear,
  endOfYear,
  isThisMonth,
  isThisYear,
  isSameMonth,
  endOfMonth,
  isBefore,
} from 'date-fns'
import {Box, Button, Card} from '@sanity/ui'
import {CloseIcon, DownloadIcon, SortIcon} from '@sanity/icons'
import {UsageHeaderLayout} from './usageHeaderLayout'
import {MonthInput, MonthInputMenuItem, Toggle} from '@/ui/index'
import {useUsageContext} from '@/context/index'
import {getDateRange, monthToDateLabel} from '@/utils/usage'

const today = new Date()

export function UsageHeader({minDate = '1970-01-01'}: {minDate?: string}) {
  const [activeDate, setActiveDate] = useState<Date>(today)
  const {setCurrentRange, setCurrentPeriod, setCompareRange, resetCompare, usageExportString} =
    useUsageContext()
  const [period, setPeriod] = useState<'year' | 'month'>('month')
  const [buttonLabel, setButtonLabel] = useState<string>('Month to date')
  const [compareLabel, setCompareLabel] = useState<string | undefined>()
  const compareButtonRef = useRef<HTMLButtonElement | null>(null)

  const min = new Date(minDate)

  const handleResetCompare = useCallback(() => {
    if (resetCompare) {
      setCompareLabel(undefined)
      resetCompare()
    }
  }, [resetCompare])

  const handleSetCurrentRange = useCallback(
    ({fromDate, toDate, label}: {fromDate: Date; toDate: Date; label: string}, newDate?: Date) => {
      handleResetCompare()
      if (setCurrentRange) {
        if (newDate) {
          setActiveDate(isThisMonth(newDate) ? today : newDate)
        }
        setButtonLabel(label)

        setCurrentRange({fromDate, toDate})
      }
    },
    [setCurrentRange, setButtonLabel]
  )

  const handleSetCompareRange = useCallback(
    ({fromDate, toDate, label}: {fromDate: Date; toDate: Date; label: string}) => {
      if (setCompareRange) {
        setCompareLabel(label)
        setCompareRange({fromDate, toDate})
      }
    },
    [setCurrentRange, setButtonLabel]
  )

  const handleSetPeriod = useCallback(
    (value) => {
      if (setCurrentRange && setCurrentPeriod) {
        const {label, fromDate, toDate} = getDateRange({
          range: value === 'year' ? 'last12Months' : 'monthToDate',
        })
        setPeriod(value)
        setCurrentPeriod(
          value === 'year'
            ? {history: 'month', snapshot: 'year'}
            : {history: 'day', snapshot: 'month'}
        )
        setCurrentRange({fromDate, toDate})
        setButtonLabel(label)
        if (value === 'year') {
          handleResetCompare()
        }
      }
    },
    [setCurrentRange, setPeriod, setButtonLabel]
  )

  // Labels used for buttons when the period is MONTH
  const prevMonthLabel = `${format(subMonths(today, 1), 'MMMM y')}`

  // The menu items when the period is MONTH
  const monthMenuItems: MonthInputMenuItem[] = [
    {
      title: 'Month to date',
      subTitle: monthToDateLabel(today),
      // disabled: isBefore(subMonths(today, 1), min),
      onClick: () =>
        handleSetCurrentRange(getDateRange({range: 'monthToDate', date: today}), today),
      selected: buttonLabel === 'Month to date',
    },
    {
      title: `Previous month`,
      subTitle: prevMonthLabel,
      // disabled: isBefore(subMonths(today, 1), min),
      filterDate: subMonths(activeDate || today, 1),
      onClick: () =>
        handleSetCurrentRange(
          getDateRange({range: 'prevMonth', date: activeDate}),
          subMonths(today, 1)
        ),
      selected: buttonLabel === 'Previous month' || isSameMonth(subMonths(today, 1), activeDate),
    },
  ].filter((item) => !item.filterDate || isBefore(min, item?.filterDate))
  // Labels used for buttons when the period is YEAR
  const last12MonthsLabel = `${format(subMonths(today, 11), 'MMM y')} - ${format(today, 'MMM y')}`
  const thisYearLabel = `${format(startOfYear(today), 'MMM y')} - ${format(
    endOfYear(today),
    'MMM y'
  )}`
  const lastYearLabel = `${format(subYears(startOfYear(today), 1), 'MMM y')} - ${format(
    subYears(endOfYear(today), 1),
    'MMM y'
  )}`

  // Menu items when the period is YEAR
  const yearMenuItems: MonthInputMenuItem[] = [
    {
      title: 'Last 12 months',
      selected: buttonLabel === 'Last 12 months',
      subTitle: last12MonthsLabel,
      // disabled: isBefore(subYears(today, 1), min),
      onClick: () => handleSetCurrentRange(getDateRange({range: 'last12Months', date: today})),
    },
    {
      title: 'This year',
      selected: buttonLabel === 'This year',
      subTitle: thisYearLabel,
      // disabled: isBefore(startOfYear(today), min),
      onClick: () => handleSetCurrentRange(getDateRange({range: 'thisYear', date: today})),
    },
    {
      title: 'Last year',
      selected: buttonLabel === 'Last year',
      filterDate: subYears(today, 1),
      subTitle: lastYearLabel,
      // disabled: isBefore(subYears(startOfYear(today), 1), min),
      onClick: () => handleSetCurrentRange(getDateRange({range: 'lastYear', date: today})),
    },
  ].filter((item) => !item.filterDate || isBefore(min, item?.filterDate))

  const compareMonthMenuItems: MonthInputMenuItem[] = [
    {
      title: 'Previous month',
      subTitle: format(subMonths(activeDate, 1), 'MMMM y'),
      filterDate: subMonths(activeDate, 1),
      onClick: () =>
        handleSetCompareRange(
          getDateRange({range: 'prevMonthToDate', date: endOfMonth(activeDate)})
        ),
    },
    {
      title: 'Same month last year',
      subTitle: format(subYears(activeDate, 1), 'MMMM y'),
      filterDate: subYears(activeDate, 1),
      onClick: () => {
        handleSetCompareRange(
          getDateRange({
            range: 'sameMonthLastYear',
            date: endOfMonth(activeDate),
          })
        )
      },
    },
  ].filter((item) => isBefore(min, item.filterDate))

  const compareButton = compareLabel ? (
    <Card style={{minWidth: 200}} border radius={2} tone="primary">
      <Button
        onClick={handleResetCompare}
        text={compareLabel}
        ref={compareButtonRef}
        icon={
          <Box style={{transform: 'rotate(90deg)'}}>
            <SortIcon />
          </Box>
        }
        iconRight={CloseIcon}
        mode="bleed"
        style={{width: '100%'}}
      />
    </Card>
  ) : (
    <MonthInput
      min={{year: getYear(min), month: getMonth(min)}}
      max={{year: getYear(today), month: getMonth(today)}}
      initialDate={undefined}
      disabledDates={[activeDate]}
      onChange={(newDate) => {
        handleSetCompareRange(
          getDateRange({
            date: newDate,
            range: 'month',
          })
        )
      }}
      buttonProps={{
        text: 'Compare to',
        icon: (
          <Box style={{transform: 'rotate(90deg)'}}>
            <SortIcon />
          </Box>
        ),
      }}
      menuItems={compareMonthMenuItems}
    />
  )

  const isThisPeriod = (date: Date): boolean =>
    period === 'month' ? isThisMonth(date) : isThisYear(date)

  return (
    <UsageHeaderLayout
      button={
        usageExportString && (
          <Button
            icon={DownloadIcon}
            as="a"
            href={usageExportString}
            download="true"
            text="Export"
            mode="bleed"
            tone="positive"
          />
        )
      }
    >
      <Toggle
        onChange={handleSetPeriod}
        options={[
          {
            name: 'period',
            value: 'month',
            label: 'Month',
            id: 'month-period',
            defaultChecked: true,
          },
          {name: 'period', value: 'year', label: 'Year', id: 'year-period'},
        ]}
      />
      <MonthInput
        buttonType="month"
        label="Select starting month"
        min={{year: getYear(min), month: getMonth(min)}}
        max={{year: getYear(today), month: getMonth(today)}}
        initialDate={activeDate}
        onChange={(selectedDate) => {
          const newDate = isThisMonth(selectedDate) ? new Date() : selectedDate
          handleSetCurrentRange(
            getDateRange(
              isThisPeriod(newDate)
                ? {date: newDate, range: period === 'month' ? 'monthToDate' : period}
                : {date: newDate, range: period}
            ),
            newDate
          )
        }}
        buttonProps={{
          text: buttonLabel,
        }}
        menuItems={period === 'month' ? monthMenuItems : yearMenuItems}
      />

      {/* Compare */}
      {period === 'month' && compareButton}
    </UsageHeaderLayout>
  )
}
