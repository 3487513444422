import {Box, Flex, Heading, Label, Text, useToast} from '@sanity/ui'
import React, {useMemo} from 'react'
import {catchError} from 'rxjs/operators'
import {ArrowRightIcon} from '@sanity/icons'
import {OverageAlert} from './overageAlert'
import {Loader, WizardParams, WizardStep, WizardStepProps, PlanPreview} from '@/ui/index'
import {useWizardStore} from '@/components/payment-wizard'
import {switchProjectPlan} from '@/data/plan'

import {useProjectSubscription, useProjectUsage} from '@/data/projects'
import {useDatasets} from '@/data/index'
import {sendAmplitudeTrackingEvent} from '@/utils/tracking'

function ChangePlanConfirmStep(props: WizardStepProps) {
  const {params} = props
  const {state} = useWizardStore()
  const {targetPlan} = state
  const {project} = params

  // hook uses history API based on beancounter hourly crons, will miss recent changes by up to 1hr
  const {data: usage, isLoading: usageIsLoading} = useProjectUsage(project)
  // subscription hook will hold add-ons and provided them in a reasonable timefream
  const {data: subscription, isLoading: subscriptionIsLoading} = useProjectSubscription(project?.id)
  const {data: datasets} = useDatasets(project.id) // get realtime dataset, and users usage

  const allMembers = useMemo(() => (project?.members || []).filter((m) => !m.isRobot), [project])
  const robotMembers = useMemo(() => (project?.members || []).filter((m) => m.isRobot), [project])

  const currentPlan = subscription?.plan
  const currentUsage = usage?.resources

  if (subscriptionIsLoading || usageIsLoading) {
    return <Loader />
  }
  if (!currentPlan) return <>MISSING CURRENT PLAN</>
  if (!targetPlan) return <>MISSING TARGET PLAN</>
  if (!currentUsage) return <>MISSING TARGET PLAN</>

  const {price: currentPlanPrice} = currentPlan
  const {price: targetPlanPrice} = targetPlan

  const isUpgrading =
    (currentPlanPrice !== null &&
      targetPlanPrice !== null &&
      currentPlanPrice <= targetPlanPrice) ||
    (currentPlan.pricingModel === 'flat-fee' && targetPlan.pricingModel === 'per-seat') // per-seat plans are upgrades and targetable for all non-enterprise plans

  return (
    <>
      <Heading as="h1" size={2}>
        {isUpgrading ? <>Upgrade</> : <>Switch</>} <strong>{project.displayName}</strong> to the{' '}
        <strong>{targetPlan.name}</strong> plan?
      </Heading>

      <Flex marginTop={5}>
        <Flex align="stretch" direction="column" flex={1}>
          <Label muted weight="regular">
            Current
          </Label>
          <PlanPreview
            currentPlan={targetPlan}
            targetPlan={currentPlan}
            style={{marginTop: '1rem', flex: 1}}
            project={project}
            isCurrentPlan
          />
        </Flex>

        <Box padding={3} style={{alignSelf: 'center'}}>
          <Text muted>
            <ArrowRightIcon />
          </Text>
        </Box>

        <Flex direction="column" flex={1}>
          <Label muted weight="regular">
            New
          </Label>
          <PlanPreview
            currentPlan={currentPlan}
            targetPlan={targetPlan}
            selected
            style={{marginTop: '1rem', flex: 1}}
            project={project}
          />
        </Flex>
      </Flex>
      <OverageAlert
        currentPlan={currentPlan}
        currentUsage={currentUsage}
        targetPlan={targetPlan}
        datasets={datasets}
        projectMembers={allMembers}
        projectRobotMembers={robotMembers}
        subscription={subscription}
        projectId={project?.id}
        organizationId={project?.organizationId}
      />
    </>
  )
}

export function useChangePlanConfirmStep(params: WizardParams): WizardStep | null {
  const {project, org} = params
  const {dispatch, state} = useWizardStore()
  const {targetPlan} = state
  const toast = useToast()
  const {data: subscription} = useProjectSubscription(project?.id)

  if (state.subscriptionIncomplete) {
    return null
  }

  return {
    id: 'confirm-change-plan',
    title: 'Confirm upgrade',
    completed: true,
    component: ChangePlanConfirmStep,
    disabled: !!state.nextButtonDisabled,
    action: {
      label: <>Switch plan</>,
    },
    async handle() {
      if (targetPlan && targetPlan.id === subscription?.plan.id) {
        return
      }
      if (targetPlan) {
        const subscription = await switchProjectPlan(project.id, targetPlan.id, [])
          .pipe(
            catchError((err) => {
              if (err.response) {
                const data = JSON.parse(err.response.body)
                toast.push({
                  title: `Can't change plan`,
                  description: data.message,
                  status: 'error',
                  duration: 10000,
                  closable: true,
                })

                if (err?.response?.statusCode === 402) {
                  dispatch({type: 'changePlanWizard/carderror'})
                }
              }
              throw err
            })
          )
          .toPromise()

        if (subscription?.status === 'incomplete') {
          dispatch({type: 'setSubscriptionIncomplete', value: true})
          return
        }

        toast.push({
          title: `Changed plan`,
          description: `Successfully changed to the ${targetPlan.name} plan`,
          status: 'success',
        })
        sendAmplitudeTrackingEvent('Switch Plan Step Completed', project?.id, org?.id, {
          stepName: 'Confirm upgrade',
        })
        sendAmplitudeTrackingEvent('Change Plan Flow Completed', project.id, org?.id, {
          targetPlan: targetPlan.id,
          newFlow: false,
        })

        return
      }
      throw new Error('No targetPlan set')
    },
  }
}
