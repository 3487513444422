import {Button, ButtonMode, ButtonTone, Flex} from '@sanity/ui'
import React from 'react'
import {AppLink} from '@/ui/index'
import {useRequiredProjectPermissions, useRequiredOrganizationPermissions} from '@/context/index'
import {PermissionTooltip} from '@/ui/ui-components/generic/permission-tooltip/PermissionTooltip'

type Props = {
  onClick?: (payload?: any) => void
  href?: string
  title?: string
  permissions?: {permissionName: string; grantName: string}[] | undefined
  icon?: React.ComponentType | React.ReactNode
  tone?: ButtonTone
  mode?: ButtonMode
  hasPermission?: boolean
  style?: React.CSSProperties
  disabled?: boolean
  action?: string
  customDescription?: string
  loading?: boolean
}

export function PermissionButtonProject({
  onClick,
  href,
  title,
  icon,
  permissions = [],
  action,
  customDescription,
  hasPermission,
  style,
  disabled,
  loading,
  ...rest
}: Props) {
  const permissionScope = useRequiredProjectPermissions(permissions)

  const isAllowed = !!(hasPermission ?? permissionScope)
  const isDisabled = typeof disabled !== 'undefined' ? disabled : undefined

  return (
    <Flex>
      <PermissionTooltip disabled={isAllowed} action={action} description={customDescription}>
        {href ? (
          <AppLink href={href} disabled={isDisabled ?? !isAllowed}>
            <Button
              onClick={onClick}
              text={title}
              icon={icon}
              mode="default"
              tone="primary"
              style={{cursor: !isAllowed || disabled ? 'not-allowed' : undefined, ...style}}
              disabled={isDisabled ?? !isAllowed}
              loading={loading}
              {...rest}
            />
          </AppLink>
        ) : (
          <Button
            onClick={onClick}
            text={title}
            icon={icon}
            mode="default"
            tone="primary"
            style={{cursor: !isAllowed || disabled ? 'not-allowed' : undefined, ...style}}
            disabled={isDisabled ?? !isAllowed}
            loading={loading}
            {...rest}
          />
        )}
      </PermissionTooltip>
    </Flex>
  )
}

export function PermissionButtonOrg({
  onClick,
  href,
  title,
  icon,
  permissions = [],
  customDescription,
  hasPermission,
  style,
  disabled,
  ...rest
}: Props) {
  const permissionScope = useRequiredOrganizationPermissions(permissions)

  const isAllowed = !!(hasPermission ?? permissionScope)
  return (
    <Flex>
      <PermissionTooltip disabled={isAllowed} description={customDescription}>
        <AppLink href={href} disabled={!isAllowed || disabled}>
          <Button
            onClick={onClick}
            text={title}
            icon={icon}
            mode="ghost"
            style={{cursor: isAllowed ? 'default' : 'not-allowed', ...style}}
            disabled={!isAllowed || disabled}
            {...rest}
          />
        </AppLink>
      </PermissionTooltip>
    </Flex>
  )
}
