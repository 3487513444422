import React, {useEffect, useCallback, forwardRef, useImperativeHandle, useRef} from 'react'
import {Stack, Container, useToast, Box} from '@sanity/ui'
import {useForm, FormProvider, useFieldArray} from 'react-hook-form'
import {RepeatableTableGroup, InvitationRowComponent, Loader} from '@/components/index'
import {Project, Organization, ProjectMemberRole} from '@/types/models'
import {useProjectInvitations} from '@/data/invitations'
import {useProjectSubscription} from '@/data/projects/useProjectSubscription'
import {useOrganizationCanPay} from '@/data/organizations'
import {useProjectRolesList, useProjectType} from '@/context/index'
import {canPayOverage, getProjectMemberState} from '@/utils/usage'
import {NoSeatsAvailableWarning} from '@/components/invitation/noSeatsAvailableWarning'
import {NoQuotaAvailableWarning} from '@/components/invitation/noQuotaAvailableWarning'

type Props = {
  children?: React.ReactNode
  project: Project
  org?: Organization
  onFormValidityChanged?: (valid: boolean) => void
  onComplete?: () => void
}

type InvitationRequest = {
  email: string
  role: string
}

type FormValues = {
  invitations: InvitationRequest[]
}

const Forwarded = forwardRef(
  (
    {project, org, onFormValidityChanged, onComplete, roles}: Props & {roles: ProjectMemberRole[]},
    ref
  ) => {
    const {data: subscription} = useProjectSubscription(project.id)
    const toast = useToast()

    const projectType = useProjectType(project)
    const memberState = getProjectMemberState(project, projectType, subscription?.resources, org)
    const noSeatsAvailable = memberState.billable >= memberState.max
    const noQuotaAvailable =
      memberState.billable >= memberState.quota &&
      subscription?.plan.pricingModel !== 'per-seat' &&
      !noSeatsAvailable

    const defaultRole = 'administrator'

    const firstRoleName = roles.find((r) => r.name === defaultRole)
      ? defaultRole
      : roles[0].name ?? ''
    const defaultValues: FormValues = {
      invitations: [
        {
          email: '',
          role: firstRoleName,
        },
      ],
    }
    const methods = useForm<FormValues>({
      defaultValues,
      mode: 'onChange',
    })
    const {getValues, setValue, trigger: triggerValidation, control, formState} = methods
    const {sendInvitations} = useProjectInvitations(project.id)
    const {data: canPay} = useOrganizationCanPay(org?.id)
    const promiseRef = useRef<{
      resolve: (value?: unknown) => void
      reject: (value?: unknown) => void
    }>()

    let maxRowsToAdd = Infinity
    if (memberState.resource === 'users') {
      const canPayOverageSeat = canPayOverage(subscription, org, canPay)

      if (canPayOverageSeat) {
        maxRowsToAdd = memberState.max - memberState.billable
      } else {
        maxRowsToAdd = memberState.quota - memberState.billable
      }
    }

    const isFormValid = formState.isValid

    useEffect(() => {
      if (onFormValidityChanged) {
        onFormValidityChanged(isFormValid)
      }
    }, [isFormValid, onFormValidityChanged])

    const {fields, append, remove} = useFieldArray({
      control,
      name: 'invitations',
    })

    const onSubmit = (data: {invitations: InvitationRequest[]}) => {
      sendInvitations(data.invitations, {
        onSuccess: (results) => {
          const hasErrors = results.some((invitationStatus) => invitationStatus.error)

          if (hasErrors) {
            const errorMap = new Map()
            const successfulInvitationIndexes: number[] = []

            results.forEach((invitationStatus, idx) => {
              if (invitationStatus.error) {
                const error = invitationStatus.error.response

                if (error) {
                  const {error: errorCode, message} = JSON.parse(error)
                  errorMap.set(errorCode, message)
                } else {
                  const email = invitationStatus.invitation.email
                  errorMap.set(
                    'Something went wrong',
                    `Failed to send an invitation to ${email}. Please try again later.`
                  )
                }
              } else {
                successfulInvitationIndexes.push(idx)
              }
            })

            if (successfulInvitationIndexes.length > 0) {
              remove(successfulInvitationIndexes)
            }

            errorMap.forEach((errorMessage, errorCode) => {
              if (promiseRef.current) {
                promiseRef.current.reject(new Error(errorMessage))
              }
              toast.push({
                title: `${errorCode}`,
                description: `${errorMessage}`,
                status: 'error',
                duration: 10000,
                closable: true,
              })
            })

            return
          }

          if (promiseRef.current) {
            promiseRef.current.resolve()
          }
          toast.push({
            title: `Your ${results.length > 1 ? `invitations` : `invitation`} was sent`,
            status: 'success',
          })

          onComplete?.()
        },
      })
      return data.invitations
    }

    useImperativeHandle(ref, () => ({
      validate() {
        return triggerValidation()
      },
      submit: () => {
        const promise = new Promise((resolve, reject) => {
          promiseRef.current = {resolve, reject}
        })
        return methods
          .handleSubmit(onSubmit)()
          .then(() => promise)
      },
    }))

    const addInvitationRow = useCallback(() => {
      append({
        email: '',
        role: firstRoleName,
      })
    }, [append, firstRoleName])

    const deleteInvitation = useCallback(
      (index: number) => {
        const {invitations: prevInvitations} = getValues()
        const current = prevInvitations ? [...prevInvitations] : []

        if (current.length === 1) {
          setValue(`invitations.0`, {
            email: '',
            role: firstRoleName,
          })

          return
        }

        remove(index)
        triggerValidation('invitations')
      },
      [firstRoleName, getValues, remove, setValue, triggerValidation]
    )

    const handleFormSubmit = useCallback((event) => {
      event.preventDefault()
    }, [])
    return (
      <FormProvider {...methods}>
        <form noValidate onSubmit={handleFormSubmit}>
          <Container size={0}>
            <Stack space={[3, 3, 5]} colSpan={1}>
              <RepeatableTableGroup
                project={project}
                org={org}
                addButtonText="Add another e-mail address"
                fields={fields}
                maxRows={maxRowsToAdd}
                rowComponent={InvitationRowComponent}
                onInsert={addInvitationRow}
                onDelete={deleteInvitation}
              />
            </Stack>

            {noSeatsAvailable && (
              <Box marginTop={4}>
                <NoSeatsAvailableWarning {...memberState} />
              </Box>
            )}

            {noQuotaAvailable && (
              <Box marginTop={4}>
                <NoQuotaAvailableWarning {...memberState} />
              </Box>
            )}
          </Container>
        </form>
      </FormProvider>
    )
  }
)

Forwarded.displayName = 'InviteForm'

const wrapper = forwardRef((props: Props, ref) => {
  const {roles} = useProjectRolesList(props.project.id)
  if (roles.length === 0) {
    return <Loader />
  }

  return <Forwarded ref={ref} roles={roles} {...props} />
})
wrapper.displayName = 'InviteFormWrapper'
export const InviteForm = wrapper
